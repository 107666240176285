import React from 'react';

type InputBlockProps = {
  title: string;
  text: string | JSX.Element;
  children: React.ReactNode;
};

export const InputBlock: React.FC<InputBlockProps> = ({
  title,
  text,
  children,
}) => {
  return (
    <div className="text-s flex flex-col gap-2">
      <div>
        <div className="text-gray-900 font-semibold text-base mb-1.5">
          {title}
        </div>
        <div className="text-gray-600">{text}</div>
      </div>
      {children}
    </div>
  );
};
