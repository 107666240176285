import hljs from 'highlight.js';
// import { createLowlight } from 'lowlight';

const languages = hljs.listLanguages();

export const Languages = languages.reduce((acc, lang) => {
  acc[lang] = lang.charAt(0).toUpperCase() + lang.slice(1);
  return acc;
}, {} as Record<string, string>);

Languages['plaintext'] = 'Plain text';
Languages['html'] = 'Html';

// export const lowlight = createLowlight({});
