import { FC } from 'react';

import { VideoRecordType } from '@distribute/shared/api-types/video-record';

import { TimeAgo } from '../../../../../shared/ui';
import { VideoRecordData } from '../UploadVideo';
import { getMuxImage } from '@distribute/shared/utils';

type Props = {
  record: VideoRecordType;
  handleClick: (data: VideoRecordData) => void;
};

export const UploadRecord: FC<Props> = ({ record, handleClick }) => {
  const { prefix, name, createdAt, playbackId } = record;

  return (
    <>
      <div
        className="flex flex-nowrap gap-x-4 cursor-pointer"
        tabIndex={0}
        onClick={() => handleClick({ prefix })}
      >
        <img
          src={getMuxImage(playbackId || '')}
          alt="poster"
          className="object-cover w-22.5 h-12 block bg-gray-100 rounded-md flex-shrink-0"
        />
        <div className="flex flex-col gap-y-1.5 overflow-hidden">
          <p className="text-md font-semibold text-gray-700 truncate">{name}</p>
          <p className="text-sm text-gray-600 truncate">
            <TimeAgo datetime={createdAt} locale="my" />
          </p>
        </div>
      </div>
      <hr className="hidden [&:not(:last-child)]:block w-full h-px bg-gray-200" />
    </>
  );
};
