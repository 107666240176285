import { put, select } from 'redux-saga/effects';
import { Folder, TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';
import { publicTemplatesModel } from '..';
import { foldersModel } from '../../../folders';

export function* setInitialData() {
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);

  if (teams.length === 1) {
    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );

    yield put(publicTemplatesModel.actions.setFolders(folders));
  }
}
