import { IconMap } from '../../../../../../shared/sprite';
import { Button, Icon } from '../../../../../../shared/ui';
import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createPageModalModel } from '../../../../../../entities/create-page-modal';

type Props = {
  onClose(): void;
};

export const CreatePageModalHeaderNavigation: React.FC<Props> = ({
  onClose,
}) => {
  const dispatch = useDispatch();

  const previousStepsStack = useSelector(
    createPageModalModel.selectors.selectPreviousStepsStack
  );

  const handleGoBack = () => {
    dispatch(createPageModalModel.actions.goBack());
  };

  return (
    <div
      className={cn(
        'flex items-center pt-3.5 pl-6.5 pr-4 absolute top-0 left-0 w-full z-50',
        {
          'justify-between': !!previousStepsStack.length,
          'justify-end': !previousStepsStack.length,
        }
      )}
    >
      {!!previousStepsStack.length && (
        <Button
          variant="icon-text"
          color="transparent"
          iconLeftName={IconMap.ArrowLeft}
          iconLeftWidth={20}
          className="!px-0.5"
          onClick={handleGoBack}
        >
          Back
        </Button>
      )}
      <Button variant="icon" color="transparent" onClick={onClose} size="md">
        <Icon glyph={IconMap.XClose} className="text-gray-400" width={20} />
      </Button>
    </div>
  );
};
