import { call, put } from 'redux-saga/effects';
import { socialModel } from '../..';
import { LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE } from '../../../../../shared/constants';
import { removeLinkedinAuthData } from './helpers';

export function* checkLinkedinAccessTokenExpiration() {
  const linkedinTokenExpirationData = localStorage.getItem(
    LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE
  );

  if (new Date() < new Date(linkedinTokenExpirationData as string)) {
    return false;
  }

  yield call(removeLinkedinAuthData);
  yield put(socialModel.actions.setIsLoggedIntoLinkedin(false));
  return true;
}
