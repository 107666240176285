import React from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from './Icon';

export type SwitchItem = {
  id: string;
  title: string;
  icon?: SpriteGlyph;
};

type Props = {
  selectedItem: string;
  items: SwitchItem[];
  onToggle: (id: string) => void;
};

export const Switch: React.FC<Props> = ({ onToggle, items, selectedItem }) => {
  return (
    <div className="flex gap-1 p-1 rounded-xl bg-base-black-8 font-base w-fit-content">
      {items.map((item) => (
        <button
          key={item.id}
          onClick={() => {
            onToggle(item.id);
          }}
          className={cn(
            'px-3 py-2 text-sm font-semibold rounded-md hover:bg-base-white hover:shadow-sm hover:text-gray-700 flex items-center gap-1.5',
            {
              'text-gray-500': selectedItem !== item.id,
              'text-gray-700 bg-base-white shadow-sm': selectedItem === item.id,
            }
          )}
        >
          {item.icon && (
            <Icon
              glyph={item.icon}
              width={20}
              className={cn({
                'text-gray-700': selectedItem === item.id,
                'text-gray-500': selectedItem !== item.id,
              })}
            />
          )}
          {item.title}
        </button>
      ))}
    </div>
  );
};
