export const DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG = [
  { name: 'Light green', value: 'rgb(245, 245, 245)' },
  { name: 'Light red', value: 'rgb(254, 228, 226)' },
  { name: 'Light orange', value: 'rgb(253, 234, 215)' },
  { name: 'Light yellow', value: 'rgb(254, 240, 199)' },
  { name: 'Light green', value: 'rgb(220, 250, 230)' },
  { name: 'Light blue', value: 'rgb(224, 242, 254)' },
  { name: 'Light indigo', value: 'rgb(224, 234, 255)' },
  { name: 'Light purple', value: 'rgb(235, 233, 254)' },
  { name: 'Light pink', value: 'rgb(252, 231, 246)' },
  { name: 'Light fuchsia', value: 'rgb(251, 232, 255)' },
];
