import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../shared/ui';
import { teamsModel } from '../../teams';
import { authUserModel } from '../../../entities/auth-user';
import { UserToTeamRole } from '@distribute/shared/types';
import { useMemo } from 'react';

export const GuestUserBanner: React.FC = () => {
  const dispatch = useDispatch();
  const handleCreateOwnTeam = () => {
    dispatch(teamsModel.actions.createTeamForGuest());
  };
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const teams = useSelector(teamsModel.selectors.selectTeams);

  const isMemberAtLeastInOneTeam = useMemo(
    () =>
      !!teams.find((team) =>
        team.userToTeams.find(
          (userToTeam) =>
            userToTeam.user?.id === currentUser.id &&
            userToTeam.role !== UserToTeamRole.GUEST
        )
      ),
    [currentUser.id, teams]
  );

  return (
    <div className="p-5 border-2 border-warning-600 rounded-2xl">
      <p className="text-gray-900 font-semibold mb-1">Guest Mode</p>
      <p className="text-gray-700 text-sm">
        {isMemberAtLeastInOneTeam
          ? 'You are a guest of this workspace and your Distribute functionality limited in this team. '
          : `You are a guest of this workspace and your Distribute functionality
        limited in this team. Create your own workspace to use all pro features
        on Distribute.`}
      </p>
      {!isMemberAtLeastInOneTeam && (
        <Button
          color="primary"
          variant="text"
          size="sm"
          onClick={handleCreateOwnTeam}
          className="mt-4"
        >
          Create your team workspace
        </Button>
      )}
    </div>
  );
};
