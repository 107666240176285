import { socialApi } from '../../../../../shared/api/axios/social';
import { call, put } from 'redux-saga/effects';
import {
  TWITTER_TOKEN_SECRET_STORAGE_KEY,
  TWITTER_TOKEN_STORAGE_KEY,
} from '../../../../../shared/constants';
import { socialModel } from '../..';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { removeTwitterAuthData } from './helpers';

export function* revokeTwitterToken() {
  const twitterToken = localStorage.getItem(TWITTER_TOKEN_STORAGE_KEY);
  const twitterTokenSecret = localStorage.getItem(
    TWITTER_TOKEN_SECRET_STORAGE_KEY
  );
  if (!twitterToken || !twitterTokenSecret) return;

  yield put(socialModel.actions.setIsRevokingTwitterToken(true));

  try {
    yield call(socialApi.revokeTwitterToken, twitterToken, twitterTokenSecret);
    yield call(removeTwitterAuthData);
    yield put(socialModel.actions.setIsLoggedIntoTwitter(false));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully disconnected from Twitter')
      )
    );
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to disconnect from Twitter')
      )
    );
  } finally {
    yield put(socialModel.actions.setIsRevokingTwitterToken(false));
  }
}
