import {
  createAction,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  AlertBar,
  CTA,
  GatedContent,
  PopUp,
  RequireEmailToView,
  SqueezePage,
} from '@distribute/shared/types';
import {
  GatedContentEdited,
  RemoveCTAImageAction,
  RemoveImageAction,
  RemovePopUpImageAction,
  UpdateAlertBarAction,
  updateConversionDBAction,
  UpdateCTAAction,
  UpdateGatedContentAction,
  UpdatePopUpAction,
  UpdateRequireEmailToViewAction,
  UpdateSqueezePageAction,
  UploadImageAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type Status = 'never' | 'pending' | 'error' | 'success';

type StatusPayload = {
  status: Status;
  statusType:
    | 'alertBarStatus'
    | 'popUpStatus'
    | 'ctaStatus'
    | 'gatedContentStatus'
    | 'squeezePageStatus'
    | 'requireEmailToViewStatus';
};

type State = {
  alertBar: AlertBar | undefined;
  alertBarEdited: AlertBar | undefined;
  popUp: PopUp | undefined;
  popUpEdited: PopUp | undefined;
  cta: CTA | undefined;
  ctaEdited: CTA | undefined;
  updateAlertBarIsLoading: boolean;
  updatePopUpIsLoading: boolean;
  updateCTAIsLoading: boolean;
  isUploadImageLoading: boolean;
  uploadingCTAImageProgress: number;
  isRemoveCTAImageLoading: boolean;
  uploadingPopUpImageProgress: number;
  isRemovePopUpImageLoading: boolean;
  alertBarStatus: Status;
  popUpStatus: Status;
  CTAStatus: Status;
  gatedContent: GatedContent | undefined;
  gatedContentEdited: GatedContentEdited | undefined;
  gatedContentStatus: Status;
  squeezePage: SqueezePage | undefined;
  squeezePageEdited: SqueezePage | undefined;
  squeezePageStatus: Status;
  updateSqueezePageIsLoading: boolean;
  uploadingSqueezePageImageProgress: number;
  isRemoveSqueezePageImageLoading: boolean;
  requireEmailToView: RequireEmailToView | undefined;
  requireEmailToViewStatus: Status;
  updateRequireEmailToViewIsLoading: boolean;
};

const initialState: State = {
  alertBar: undefined,
  alertBarEdited: undefined,
  popUp: undefined,
  popUpEdited: undefined,
  cta: undefined,
  ctaEdited: undefined,
  updateAlertBarIsLoading: false,
  updatePopUpIsLoading: false,
  updateCTAIsLoading: false,
  isUploadImageLoading: false,
  uploadingCTAImageProgress: 0,
  isRemoveCTAImageLoading: false,
  uploadingPopUpImageProgress: 0,
  isRemovePopUpImageLoading: false,
  alertBarStatus: 'never',
  popUpStatus: 'never',
  CTAStatus: 'never',
  gatedContent: undefined,
  gatedContentEdited: undefined,
  gatedContentStatus: 'never',
  squeezePage: undefined,
  squeezePageEdited: undefined,
  squeezePageStatus: 'never',
  updateSqueezePageIsLoading: false,
  uploadingSqueezePageImageProgress: 0,
  isRemoveSqueezePageImageLoading: false,
  requireEmailToView: undefined,
  requireEmailToViewStatus: 'never',
  updateRequireEmailToViewIsLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'conversionKit',
  initialState,
  reducers: {
    setAlertBar: (state, { payload: alertBar }: PayloadAction<AlertBar>) => {
      return {
        ...state,
        alertBar,
      };
    },
    setAlertBarEdited: (
      state,
      { payload: alertBarEdited }: PayloadAction<AlertBar | undefined>
    ) => {
      return {
        ...state,
        alertBarEdited,
      };
    },
    setUpdateAlertBarIsLoading: (
      state,
      { payload: updateAlertBarIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updateAlertBarIsLoading,
    }),
    setPopUp: (state, { payload: popUp }: PayloadAction<PopUp>) => ({
      ...state,
      popUp,
    }),
    setPopUpEdited: (
      state,
      { payload: popUpEdited }: PayloadAction<PopUp | undefined>
    ) => ({
      ...state,
      popUpEdited,
    }),
    setUpdatePopUpIsLoading: (
      state,
      { payload: updatePopUpIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updatePopUpIsLoading,
    }),
    setCTA: (state, { payload: cta }: PayloadAction<CTA>) => ({
      ...state,
      cta,
    }),
    setCTAEdited: (
      state,
      { payload: ctaEdited }: PayloadAction<CTA | undefined>
    ) => ({
      ...state,
      ctaEdited,
    }),
    setUpdateCTAIsLoading: (
      state,
      { payload: updateCTAIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updateCTAIsLoading,
    }),
    setGatedContent: (
      state,
      { payload: gatedContent }: PayloadAction<GatedContent>
    ) => ({
      ...state,
      gatedContent,
    }),
    setGatedContentEdited: (
      state,
      {
        payload: gatedContentEdited,
      }: PayloadAction<GatedContentEdited | undefined>
    ) => ({
      ...state,
      gatedContentEdited,
    }),
    updateGatedContentPercentage: (
      state,
      { payload: percentage }: PayloadAction<number>
    ) => {
      if (!state.gatedContentEdited) {
        return state;
      }
      return {
        ...state,
        gatedContentEdited: {
          ...state.gatedContentEdited,
          pagePercentage: percentage,
        },
      };
    },
    setUpdateGatedContentIsLoading: (
      state,
      { payload: updateCTAIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updateCTAIsLoading,
    }),
    setIsUploadImageLoading: (
      state,
      { payload: isUploadImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isUploadImageLoading,
    }),
    setUploadCTAImageProgress: (
      state,
      { payload: uploadingCTAImageProgress }: PayloadAction<number>
    ) => ({
      ...state,
      uploadingCTAImageProgress,
    }),
    setIsRemoveCTAImageLoading: (
      state,
      { payload: isRemoveCTAImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isRemoveCTAImageLoading,
    }),
    setUploadPopUpImageProgress: (
      state,
      { payload: uploadingPopUpImageProgress }: PayloadAction<number>
    ) => ({
      ...state,
      uploadingPopUpImageProgress,
    }),
    setIsRemovePopUpImageLoading: (
      state,
      { payload: isRemovePopUpImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isRemovePopUpImageLoading,
    }),
    setConversionKitStatus: (
      state: Draft<State>,
      { payload: { status, statusType } }: PayloadAction<StatusPayload>
    ) => ({
      ...state,
      [statusType]: status,
    }),
    setSqueezePage: (
      state,
      { payload: squeezePage }: PayloadAction<SqueezePage>
    ) => ({
      ...state,
      squeezePage,
    }),
    setSqueezePageEdited: (
      state,
      { payload: squeezePageEdited }: PayloadAction<SqueezePage | undefined>
    ) => ({
      ...state,
      squeezePageEdited,
    }),
    setUpdateSqueezePageIsLoading: (
      state,
      { payload: updateSqueezePageIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updateSqueezePageIsLoading,
    }),
    setUploadSqueezePageImageProgress: (
      state,
      { payload: uploadingSqueezePageImageProgress }: PayloadAction<number>
    ) => ({
      ...state,
      uploadingSqueezePageImageProgress,
    }),
    setIsRemoveSqueezePageImageLoading: (
      state,
      { payload: isRemoveSqueezePageImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isRemoveSqueezePageImageLoading,
    }),
    setRequireEmailToView: (
      state,
      {
        payload: requireEmailToView,
      }: PayloadAction<RequireEmailToView | undefined>
    ) => ({ ...state, requireEmailToView }),
    setUpdateRequireEmailToViewIsLoading: (
      state,
      { payload: updateRequireEmailToViewIsLoading }
    ) => ({ ...state, updateRequireEmailToViewIsLoading }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updateAlertBar: createAction<UpdateAlertBarAction>(
    'conversionKit/updateAlertBar'
  ),
  updatePopUp: createAction<UpdatePopUpAction>('conversionKit/updatePopUp'),
  updateCTA: createAction<UpdateCTAAction>('conversionKit/updateCTA'),
  updateGatedContent: createAction<UpdateGatedContentAction>(
    'conversionKit/updateGatedContent'
  ),
  updateSqueezePage: createAction<UpdateSqueezePageAction>(
    'conversionKit/updateSqueezePage'
  ),
  changePopUpImage: createAction<UploadImageAction>(
    'conversionKit/changePopUpImage'
  ),
  changeCTAImage: createAction<UploadImageAction>(
    'conversionKit/changeCTAImage'
  ),
  changeSqueezePageImage: createAction<UploadImageAction>(
    'conversionKit/changeSqueezePageImage'
  ),
  removeCTAImage: createAction<RemoveCTAImageAction>(
    'conversionKit/removeCTAImage'
  ),
  removePopUpImage: createAction<RemovePopUpImageAction>(
    'conversionKit/removePopUpImage'
  ),
  removeSqueezePageImage: createAction<RemoveImageAction>(
    'conversionKit/removeSqueezePageImage'
  ),
  updateRequireEmailToView: createAction<UpdateRequireEmailToViewAction>(
    'conversionKit/updateRequireEmailToView'
  ),
  updateAlertBarDB: createAction<updateConversionDBAction>(
    'conversionKit/updateAlertBarDB'
  ),
  updateCtaDB: createAction<updateConversionDBAction>(
    'conversionKit/updateCtaDB'
  ),
  updatePopUpDB: createAction<updateConversionDBAction>(
    'conversionKit/updatePopUpDB'
  ),
  updateGatedContentDB: createAction<updateConversionDBAction>(
    'conversionKit/updateGatedContentDB'
  ),
  updateSqueezePageDB: createAction<updateConversionDBAction>(
    'conversionKit/updateSqueezePageDB'
  ),
};
