import { call, put } from 'redux-saga/effects';
import { authModel } from '../../../entities/auth-operation';
import { authApi } from '../../../shared/api';
import { FirebaseUserCredential } from '../../../shared/types';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';
import { auth } from '../../../shared/config';
import { authChannelModel } from '../../../entities/auth-channel';

export function* appsumoSignUp(
  signUpAction: ReturnType<typeof authModel.actions.appsumoSignUpClick>
) {
  const {
    payload: { email, password, fullName },
  } = signUpAction;

  yield call(
    authModel.sagas.authOperation,
    'Sorry, something went wrong. Please try again later',
    function* () {
      yield authApi.onAppsumoRegister({
        email,
        password,
        displayName: fullName,
      });

      const userCredential: FirebaseUserCredential =
        yield signInWithEmailAndPassword(auth, email, password);

      if (!userCredential.user) {
        throw new Error('Something went wrong. User not created.');
      }

      analytics.track(AnalyticsEvents.APPSUMO_SIGN_UP_COMPLETED);

      yield put(authModel.actions.setIsWaitingToRedirectAfterAuth(true));
      yield put(
        authChannelModel.chan,
        authChannelModel.loggedIn({
          redirectTo: 'successfully-created-account',
        })
      );
    }
  );
}
