import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import { Folder, TeamFiltered } from '@distribute/shared/types';
import { TeamsList } from './TeamsList';
import { TeamItem } from './TeamItem';
import { Button } from '../../../shared/ui';
import { FoldersList } from './FoldersList';
import { CreateTeamModal, teamsModel } from '../../teams';
import { authModel } from '../../../entities/auth-operation';
import { publicTemplatesModel } from '../model';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../types';
import { navigate } from '../../../processes/navigation';
import { QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE } from '../config';

export const PublicTemplate = () => {
  const dispatch = useDispatch();
  const { pageId } = useParams<RouteParams>();
  const teams = useSelector(teamsModel.selectors.selectTeams);
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const isUserHasOnlyOneTeam = teams.length === 1;
  const [selectedTeam, setSelectedTeam] = useState<TeamFiltered | null>(
    isUserHasOnlyOneTeam ? teams[0] : null
  );
  const [isShowCreateTeamModal, setIsShowCreateTeamModal] = useState(false);

  const handleSelectFolder = (folder: Folder) => {
    if (!selectedTeam) return;

    dispatch(
      publicTemplatesModel.actions.duplicatePage({
        teamId: selectedTeam?.id,
        folderId: folder.id,
        pageId,
      })
    );
  };

  const handleSelectTeam = (team: TeamFiltered) => {
    dispatch(
      publicTemplatesModel.actions.loadFolders({
        teamId: team.id,
        cb: () => setSelectedTeam(team),
      })
    );
  };

  const handleLoginIntoAnotherAccount = () => {
    dispatch(
      navigate({
        to: `?${QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE}=${pageId}`,
      })
    );
    dispatch(authModel.actions.signOutClick());
  };

  return (
    <div>
      <div className="text-display-md text-gray-900 font-semibold font-display text-center -tracking-2">
        Choose a team and folder to duplicate into
      </div>
      <div className="mt-3 mb-12 text-center font-normal text-md text-gray-600">
        These teams available for your account{' '}
        <span className="font-semibold">{currentUser.email}</span>.
      </div>
      <div className="flex flex-col gap-5">
        {selectedTeam && (
          <TeamItem
            team={selectedTeam}
            onBack={
              isUserHasOnlyOneTeam ? undefined : () => setSelectedTeam(null)
            }
          />
        )}
        {!selectedTeam && <TeamsList onSelectTeam={handleSelectTeam} />}
        {selectedTeam && <FoldersList onSelectFolder={handleSelectFolder} />}
      </div>
      <div className="h-0.25 bg-gray-200 my-8" />
      <div className="flex flex-col gap-5 justify-center">
        <Button
          variant="text"
          color="link"
          className="!h-5 !font-semibold"
          onClick={() => setIsShowCreateTeamModal(true)}
        >
          Create New Team
        </Button>
        <Button
          variant="text"
          color="link"
          className="!h-5 !font-semibold"
          onClick={handleLoginIntoAnotherAccount}
        >
          Log In to Another Account
        </Button>
      </div>
      <CreateTeamModal
        isOpen={isShowCreateTeamModal}
        isSetCreatedTeamAsCurrent={false}
        onClose={() => setIsShowCreateTeamModal(false)}
        onTeamCreated={() => setSelectedTeam(null)}
      />
    </div>
  );
};
