import { Icon, Input } from '../../../../../shared/ui';
import React, { useState, KeyboardEvent } from 'react';
import { PersonalizedLink } from './PersonalizadLink';
import { IconMap } from '../../../../../shared/sprite';
import { PersonalizedGmailBlock } from './PersonalizedGmailBlock';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';

type Props = {
  isActive: boolean;
};

export const PersonalizedLinkMenu: React.FC<Props> = ({ isActive }) => {
  const dispatch = useDispatch();

  const personName = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkPersonName
  );

  const [name, setName] = useState(personName);

  const changePersonName = (name: string) => {
    dispatch(editorSidebarModel.actions.setPersonalizedLinkPersonName(name));
  };

  const generatePersonalizedLink = (name: string) => {
    dispatch(editorSidebarModel.actions.generatePersonalizedLink(name));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      changePersonName(name);
      generatePersonalizedLink(name);
    }
  };

  const isLinkGenerating = useSelector(
    editorSidebarModel.selectors.selectIsPersonalizedLinkGenerating
  );

  if (!isActive) return null;
  return (
    <>
      <p className="text-sm text-gray-700">
        Monitor individual engagement on your page. Enter required information
        and share the personalized link with the specific person.
      </p>
      <div className="relative">
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => {
            if (name !== personName) {
              changePersonName(name);
              generatePersonalizedLink(name);
            }
          }}
          onKeyDown={handleKeyDown}
          heightSize="md"
          label="Name"
          required
          isMandatory
        />
        {name && (
          <Icon
            glyph={IconMap.XCircle}
            width={16}
            className="absolute right-4 bottom-3 text-gray-500 cursor-pointer"
            onClick={() => {
              setName('');
              changePersonName('');
            }}
          />
        )}
      </div>
      {personName && <PersonalizedLink />}
      {personName && !isLinkGenerating && (
        <PersonalizedGmailBlock personName={personName} />
      )}
    </>
  );
};
