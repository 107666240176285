import { END, eventChannel } from 'redux-saga';
import { socialApi } from '../../../../../shared/api/axios/social';
import { APP_HOST, PROTOCOL } from '../../../../../shared/config';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { GetTwitterAuthLinkResponse } from '../../types';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { fetchTwitterAccessToken } from './fetchTwitterAccessToken';
import { socialModel } from '../..';
import { fetchTwitterProfileData } from './fetchTwitterProfileData';
import { openOauthSignupPopup } from '../../../../../utils/openOauthSignupPopup';

const twitterCallbackUrl = `${PROTOCOL}${APP_HOST}/twitter-callback`;

let callbackUrl = '';

export function* connectToTwitter(): any {
  yield put(socialModel.actions.setIsConnectingToTwitter(true));

  const res: GetTwitterAuthLinkResponse = yield call(
    socialApi.getTwitterAuthLink,
    twitterCallbackUrl
  );

  function handleMessage(e: any) {
    callbackUrl = e.url;
    window.removeEventListener('storage', handleMessage);
  }

  window.addEventListener('storage', handleMessage);

  openOauthSignupPopup(res.url);

  const callbackUrlChannel = yield call(checkConnectionModalForAuthCode);

  try {
    while (callbackUrl.length === 0) {
      yield take(callbackUrlChannel);
    }
  } finally {
    if (yield cancelled()) {
      callbackUrlChannel.close();
    }
  }
  const urlParams = new URLSearchParams(
    callbackUrl.substring(callbackUrl.indexOf('?'))
  );
  const oauth_token: string = yield urlParams.get('oauth_token');
  const oauth_verifier: string = yield urlParams.get('oauth_verifier');

  if (!oauth_verifier) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to Twitter')
      )
    );
    yield put(socialModel.actions.setIsConnectingToTwitter(false));
    return;
  }

  yield call(fetchTwitterAccessToken, {
    oauth_token,
    oauth_verifier,
    oauth_token_secret: res.oauth_token_secret,
  });

  yield call(fetchTwitterProfileData);

  yield put(
    snackbarModel.actions.addNotificationAction(
      createNotification('success', 'Successfully connected to Twitter')
    )
  );
  yield put(socialModel.actions.setIsConnectingToTwitter(false));
}

function checkConnectionModalForAuthCode() {
  return eventChannel((emitter) => {
    const checkSignUpPopupInterval = setInterval(() => {
      if (callbackUrl.length) {
        emitter(callbackUrl);
        emitter(END);
      }
      return;
    }, 1000);

    return () => {
      clearInterval(checkSignUpPopupInterval);
    };
  });
}
