import { call, put } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { getNormalizedTextSnippetContent, setSnippetsData } from '../../lib';

export function* changeSnippetTextContent({
  payload: { ...payloadData },
}: ReturnType<typeof snippetsModel.actions.changeSnippetTextContent>) {
  try {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(true));

    const { snippetId, content, ...rest } = payloadData;

    const normalizedContent = getNormalizedTextSnippetContent(content);

    yield call(snippetsApi.updateSnippet, snippetId, {
      ...rest,
      content: normalizedContent,
    });

    yield put(snippetsModel.actions.setUpdatingSnippetData(undefined));

    yield call(setSnippetsData);
  } catch (error) {
    logger.error(error);
  } finally {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(false));
  }
}
