import { RootState } from '../../app';

export const selectWithError =
  <T>(selector: (state: RootState) => T | undefined, selectorTarget: string) =>
  (state: RootState) => {
    const selectedState = selector(state);
    if (selectedState === undefined) {
      throw new Error(`${selectorTarget} is undefined`);
    }
    return selectedState;
  };
