import { call, takeEvery } from 'redux-saga/effects';
import { onboardingModel } from '../../../features/onboarding';
import { actions } from '../../../features/team-brand-settings/model/reducer';
import { searchCompanies } from '../../../features/team-brand-settings/model/sagas';

export function* onboardingWorker() {
  yield takeEvery(
    onboardingModel.actions.skipTeamOnboardingStep,
    onboardingModel.sagas.skipTeamOnboardingStep
  );
  yield takeEvery(
    onboardingModel.actions.inviteTeamMembers,
    onboardingModel.sagas.inviteTeamMembers
  );
  yield takeEvery(
    onboardingModel.actions.createTeam,
    onboardingModel.sagas.createTeam
  );
  yield takeEvery(
    onboardingModel.actions.updateUsagePlan,
    onboardingModel.sagas.updateUsagePlan
  );
  yield takeEvery(
    onboardingModel.actions.finishTeamOnboarding,
    onboardingModel.sagas.finishTeamOnboarding
  );
  yield takeEvery(
    onboardingModel.actions.createSubscriptionTrial,
    onboardingModel.sagas.createSubscriptionTrial
  );
  yield takeEvery(
    onboardingModel.actions.finishExtensionOnboarding,
    onboardingModel.sagas.finishExtensionOnboarding
  );

  yield takeEvery(actions.searchCompanies, searchCompanies);

  yield call(onboardingModel.sagas.getSubdomain);
}
