import { call } from 'redux-saga/effects';
import { teamInfoSettingsModel } from '../../../features/team-info-settings';
import { matchParams } from '../../../shared/lib';

type RouteParams = {
  teamId: string;
  hash: string;
};
export function* confirmDeleteTeamWorker(location: Location, route: string) {
  const params = matchParams<RouteParams>(location.pathname, route);

  if (!params) {
    return;
  }

  yield call(
    teamInfoSettingsModel.sagas.confirmDeleteTeam,
    Number(params.teamId),
    params.hash
  );
}
