import React, { FC, ReactNode } from 'react';
import { CalendarProvider } from '@distribute/shared/types';
import { ColorPicker, Dropdown, Icon, Input } from '../../../../../shared/ui';
import {
  CALENDAR_PROVIDERS,
  CALENDLY_HOW_TO_GET_LINK_URL,
  CHILI_PIPER_HOW_TO_GET_LINK_URL,
} from '../../../lib';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { IconMap } from '../../../../../shared/sprite';
import { LabelToggle } from '../../../../../shared/ui';

export type CalendarForm = {
  calendarProvider: CalendarProvider;
  calendarSchedulingLink: string;
  calendarIsShowCalendlyPageDetails: boolean;
  calendarIsShowCalendlyCookieBanner: boolean;
  calendarCalendlyTextColor: string;
  calendarCalendlyButtonColor: string;
  calendarCalendlyBgColor: string;
};

type IProps = {
  source: 'popup' | 'cta';
  register: UseFormRegister<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<any>;
  popupTitleAndDescriptionFields?: ReactNode;
};

export const CalendarForm: FC<IProps> = ({
  source,
  popupTitleAndDescriptionFields,
  register: registerBase,
  control: controlBase,
  setValue: setValueBase,
  errors: errorsBase,
}) => {
  const control = controlBase as Control<CalendarForm>;
  const register = registerBase as UseFormRegister<CalendarForm>;
  const setValue = setValueBase as UseFormSetValue<CalendarForm>;
  const errors = errorsBase as FieldErrors<CalendarForm>;
  const {
    field: { onChange: changeProvider, value: provider },
  } = useController({
    control,
    name: 'calendarProvider',
  });
  const {
    field: {
      onChange: changeIsShowCalendlyPageDetails,
      value: isShowCalendlyPageDetails,
    },
  } = useController({
    control,
    name: 'calendarIsShowCalendlyPageDetails',
  });
  const {
    field: {
      onChange: changeIsShowCalendlyCookieBanner,
      value: isShowCalendlyCookieBanner,
    },
  } = useController({
    control,
    name: 'calendarIsShowCalendlyCookieBanner',
  });
  const {
    field: { onChange: changeCalendlyTextColor, value: calendlyTextColor },
  } = useController({
    control,
    name: 'calendarCalendlyTextColor',
  });
  const {
    field: { onChange: changeCalendlyButtonColor, value: calendlyButtonColor },
  } = useController({
    control,
    name: 'calendarCalendlyButtonColor',
  });
  const {
    field: { onChange: changeCalendlyBgColor, value: calendlyBgColor },
  } = useController({
    control,
    name: 'calendarCalendlyBgColor',
  });

  return (
    <div>
      <Dropdown
        items={CALENDAR_PROVIDERS}
        isModalMode={false}
        align="start"
        listStyles="!w-[var(--radix-dropdown-menu-trigger-width)]"
        triggerStyles="bg-base-white mt-3"
        currentItemId={provider}
        onItemChange={(value) => {
          changeProvider(value);
          setValue('calendarSchedulingLink', '');
        }}
      />
      <hr className="border-gray-200 my-6" />
      <p className="text-gray-800 font-semibold mb-4">
        {provider === CalendarProvider.CALENDLY
          ? 'Scheduling link'
          : 'Booking link'}
      </p>
      <Input
        {...register('calendarSchedulingLink')}
        className="p-3"
        heightSize="md"
        type="text"
        placeholder="Input link"
        isError={!!errors.calendarSchedulingLink}
      />
      <a
        href={
          provider === CalendarProvider.CALENDLY
            ? CALENDLY_HOW_TO_GET_LINK_URL
            : CHILI_PIPER_HOW_TO_GET_LINK_URL
        }
        target="_blank"
        className="flex items-center gap-x-1 text-primary-700 text-sm font-medium mt-1.5"
        rel="noreferrer"
      >
        {provider === CalendarProvider.CALENDLY
          ? 'How to get your calendly scheduling link'
          : 'How to get Chili Piper booking link'}
        <Icon glyph={IconMap.ArrowSquareUpRight} width={16} />
      </a>
      {source === 'popup' && (
        <>
          <hr className="border-gray-200 my-6" />
          <p className="text-gray-800 font-semibold">Pop-up Components</p>
          {popupTitleAndDescriptionFields}
        </>
      )}
      {provider === CalendarProvider.CALENDLY && (
        <>
          <hr className="border-gray-200 my-6" />
          <p className="text-gray-800 font-semibold">Calendly customisation</p>
          <LabelToggle
            icon={IconMap.Menu02}
            text="Page Details"
            checked={isShowCalendlyPageDetails}
            onChange={() =>
              changeIsShowCalendlyPageDetails(!isShowCalendlyPageDetails)
            }
            className="mt-6"
          />
          <LabelToggle
            icon={IconMap.LayoutBottom}
            text="Cookie Banner"
            checked={isShowCalendlyCookieBanner}
            onChange={() =>
              changeIsShowCalendlyCookieBanner(!isShowCalendlyCookieBanner)
            }
            className="mt-6"
          />
          <hr className="border-gray-200 my-6" />
          <div className="flex flex-wrap gap-x-4 gap-y-6">
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Text Color
              </span>
              <ColorPicker
                value={calendlyTextColor}
                onChange={(color) => changeCalendlyTextColor(color)}
              />
            </div>
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Button & Link Color
              </span>
              <ColorPicker
                value={calendlyButtonColor}
                onChange={(color) => changeCalendlyButtonColor(color)}
              />
            </div>
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Background Color
              </span>
              <ColorPicker
                value={calendlyBgColor}
                onChange={(color) => changeCalendlyBgColor(color)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
