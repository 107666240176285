import axios from 'axios';

import { CLEARBIT_API_URL } from '../../config';

export type ClearbitCompanyInfo = {
  domain: string | null;
  name: string;
  logo: string | null;
};

const clearbitInstance = axios.create({
  baseURL: CLEARBIT_API_URL,
  timeout: 60000,
});

export const clearbitApi = {
  async searchCompanies(searchQuery: string) {
    const { data } = await clearbitInstance.get<ClearbitCompanyInfo[]>('', {
      params: { query: searchQuery },
    });

    return data;
  },
};
