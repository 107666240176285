import { VirtualElement } from '@popperjs/core';

type VirtualElementRectData = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

type CreateVirtualElementFn = (data?: VirtualElementRectData) => VirtualElement;

type GenerateGetBoundingClientRectFn = (
  data?: VirtualElementRectData
) => () => DOMRect;

export const generateGetBoundingClientRect: GenerateGetBoundingClientRectFn =
  ({ x = 0, y = 0, width = 0, height = 0 } = {}) =>
  () => ({
    width,
    height,
    top: y,
    right: x + width,
    bottom: y + height,
    left: x,
    x,
    y,
    toJSON() {
      return null;
    },
  });

export const createVirtualElement: CreateVirtualElementFn = (data) => ({
  getBoundingClientRect: generateGetBoundingClientRect(data),
});
