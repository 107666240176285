import { put } from 'redux-saga/effects';
import { viewersModel } from '../';

export function* onChangeRange({
  payload: range,
}: ReturnType<typeof viewersModel.actions.onChangeRange>) {
  yield put(viewersModel.actions.setRange(range));
  yield put(viewersModel.actions.setDetails({}));
  yield put(viewersModel.actions.getViewersData({}));
}
