import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { Button, Checkbox, Icon, Input, Modal } from '../../../../shared/ui';
import { boolean, mixed, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { snippetsModel } from '../../model';
import { RadioButtonForSnippetTypes } from './RadioButtonForSnippetTypes';
import { IconMap } from '../../../../shared/sprite';
import { SnippetTypesVariant } from '../lib/config';
import {
  SnippetCategoryWithSnippets,
  SnippetType,
} from '@distribute/shared/types';

type CreateOrUpdateCategoryForm = {
  name: string;
  description: string;
  includeSnippetTypes: SnippetTypesVariant;
  text: boolean;
  image: boolean;
  video: boolean;
  file: boolean;
};

const validationSchema = object().shape({
  name: string().required('Category name is required'),
  description: string().optional(),
  types: mixed<SnippetTypesVariant>(),
  text: boolean(),
  image: boolean(),
  video: boolean(),
  file: boolean(),
});

type Props = {
  category?: SnippetCategoryWithSnippets;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateOrEditSnippetCategoryModal: React.FC<Props> = ({
  category,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const isCreateCategoryLoading = useSelector(
    snippetsModel.selectors.selectIsCreateCategoryLoading
  );

  const isUpdateCategoryLoading = useSelector(
    snippetsModel.selectors.selectIsUpdateCategoryLoading
  );

  const defaultIncludeSnippetTypesValue =
    !category ||
    (category?.text && category?.image && category?.video && category?.file)
      ? SnippetTypesVariant.ALL
      : SnippetTypesVariant.SPECIFIC;

  const defaultValues = useMemo(() => {
    return {
      name: category?.name || '',
      description: category?.description || '',
      includeSnippetTypes: defaultIncludeSnippetTypesValue,
      text: category?.text ?? true,
      image: category?.image ?? true,
      video: category?.video ?? true,
      file: category?.file ?? true,
    };
  }, [
    category?.name,
    category?.description,
    category?.video,
    category?.text,
    category?.image,
    category?.file,
    defaultIncludeSnippetTypesValue,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<CreateOrUpdateCategoryForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    field: { value: typesValue, onChange: setTypes },
  } = useController({
    control,
    defaultValue: defaultIncludeSnippetTypesValue,
    name: 'includeSnippetTypes',
  });

  const onChangeTypes = (value: SnippetTypesVariant) => {
    setTypes(value);

    if (value === SnippetTypesVariant.ALL) {
      setValue('text', true, { shouldValidate: true });
      setValue('image', true, { shouldValidate: true });
      setValue('file', true, { shouldValidate: true });
      setValue('video', true, { shouldValidate: true });
    }
  };

  const {
    field: { value: textValue, onChange: setText },
  } = useController({
    control,
    defaultValue: category?.text ?? true,
    name: 'text',
  });

  const onChangeTextValue = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.checked);
  };

  const {
    field: { value: imageValue, onChange: setImage },
  } = useController({
    control,
    defaultValue: category?.image ?? true,
    name: 'image',
  });

  const onChangeImageValue = (e: ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.checked);
  };

  const {
    field: { value: videoValue, onChange: setVideo },
  } = useController({
    control,
    defaultValue: category?.video ?? true,
    name: 'video',
  });

  const onChangeVideoValue = (e: ChangeEvent<HTMLInputElement>) => {
    setVideo(e.target.checked);
  };

  const {
    field: { value: fileValue, onChange: setFile },
  } = useController({
    control,
    defaultValue: category?.file ?? true,
    name: 'file',
  });

  const onChangeFileValue = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.checked);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [reset, isOpen, defaultValues]);

  const handleFormSubmit = (data: CreateOrUpdateCategoryForm) => {
    const { includeSnippetTypes: _, ...restData } = data;

    category
      ? dispatch(
          snippetsModel.actions.updateCategory({
            categoryId: category.id,
            ...restData,
            callback: () => {
              handleClose();
            },
          })
        )
      : dispatch(
          snippetsModel.actions.createCategory({
            ...restData,
            callback: () => {
              handleClose();
            },
          })
        );
  };

  const watchName = watch('name');
  const watchDescription = watch('description');

  const typesCheckboxes = [
    {
      id: SnippetType.TEXT,
      name: 'Text',
      value: textValue,
      onChange: onChangeTextValue,
      icon: IconMap.SnippetText,
    },
    {
      id: SnippetType.IMAGE,
      name: 'Image',
      value: imageValue,
      onChange: onChangeImageValue,
      icon: IconMap.SnippetImage,
    },
    {
      id: SnippetType.VIDEO,
      name: 'Video',
      value: videoValue,
      onChange: onChangeVideoValue,
      icon: IconMap.SnippetVideo,
    },
    {
      id: SnippetType.FILE,
      name: 'File',
      value: fileValue,
      onChange: onChangeFileValue,
      icon: IconMap.SnippetFile,
    },
  ];

  return (
    <Modal
      className="relative overflow-hidden !pb-23.5 w-120 max-h-180"
      isOpen={isOpen}
      onClose={handleClose}
      title={category ? 'Edit Snippet Category' : 'Create Snippet Category'}
      isShowCloseIconInTitle
      description={
        category
          ? 'Update the information and settings for the category.'
          : 'Create category for your team and indicate the type of content that will be included.'
      }
      isShowCancelButton={false}
      disableCloseByOutsideClick
    >
      <div className="pb-2 overflow-scroll max-h-157">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <Input
                autoComplete="off"
                label="Category name *"
                {...register('name')}
                isError={!!errors.name}
                messageText={errors.name?.message}
                type="text"
                heightSize="md"
                counterValue={watchName.length}
                maxCharacters={64}
                isCounterShow={!errors.name}
              />
              <Input
                autoComplete="off"
                label="Description"
                {...register('description')}
                isError={!!errors.description}
                messageText={errors.description?.message}
                type="text"
                isTextArea
                textAreaRows={3}
                textAreaMaxRows={3}
                maxCharacters={240}
                className="!h-23.5 max-h-23.5 min-h-23.5 flex-shrink-0 flex-grow-0"
                counterValue={watchDescription.length}
                isCounterInside
                placeholder="Describe the purpose of the category for your team"
              />
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <label className="block mb-3 font-semibold text-gray-700 text-md">
                  What types of snippets can be included?
                </label>
                <div className="flex items-center gap-4">
                  {Object.values(SnippetTypesVariant).map((value) => (
                    <RadioButtonForSnippetTypes
                      name="snippet-types"
                      key={value}
                      value={value}
                      onChange={() => {
                        onChangeTypes(value);
                      }}
                      defaultChecked={value === typesValue}
                    />
                  ))}
                </div>
              </div>
              {typesValue === SnippetTypesVariant.SPECIFIC && (
                <div>
                  <p className="block mb-3 text-sm font-medium text-gray-700">
                    Snippet types:
                  </p>
                  <div className="flex gap-6">
                    {typesCheckboxes.map((type) => (
                      <Checkbox
                        checked={type.value}
                        onChange={type.onChange}
                        key={type.id}
                        disabled={Boolean(
                          category?.snippets.some((s) => s.type === type.id)
                        )}
                      >
                        <div className="flex items-center font-medium text-gray-700 gap-x-1 text-md">
                          <Icon
                            glyph={type.icon}
                            className="ml-2 text-gray-600"
                          />
                          {type.name}
                        </div>
                      </Checkbox>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 bg-base-white">
            <Button
              fullWidth
              color="secondary"
              variant="text"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="text"
              fullWidth
              loading={isUpdateCategoryLoading || isCreateCategoryLoading}
              disabled={typesCheckboxes.every((type) => !type.value)}
            >
              {category ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
