import { Editor } from '@tiptap/core';
import { EditorEvents } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import tippy, { Instance } from 'tippy.js';

export interface TableMenuPluginProps {
  editor: Editor;
  element: HTMLElement;
}

export type TableMenuViewProps = TableMenuPluginProps;

export class TableMenuView {
  public editor: Editor;
  public element: HTMLElement;
  public tippy: Instance | undefined;
  public isPreventHide = false;
  public isShowed = false;
  public tableNodePosition: number | null = null;

  constructor({ editor, element }: TableMenuViewProps) {
    this.editor = editor;
    this.element = element;

    this.element.remove();
    this.element.style.visibility = 'visible';

    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.documentClickHandler = this.documentClickHandler.bind(this);
    this.updateSelectionHandler = this.updateSelectionHandler.bind(this);
    this.updateMenuPosition = this.updateMenuPosition.bind(this);
    this.transactionHandler = this.transactionHandler.bind(this);

    this.editor.on('focus', this.updateSelectionHandler);
    this.editor.on('selectionUpdate', this.updateSelectionHandler);
    this.editor.on('transaction', this.transactionHandler);
    this.element.addEventListener('mousedown', this.mouseDownHandler);
    document.body.addEventListener('mousedown', this.documentClickHandler);
    document.addEventListener('scroll', this.updateMenuPosition, true);
  }

  transactionHandler({ transaction }: EditorEvents['transaction']) {
    if (transaction.getMeta('isNeedUpdateTableMenuPosition')) {
      this.updateMenuPosition();
    }
  }

  updateMenuPosition() {
    if (this.tableNodePosition === null) return;

    const tableBlockNode = this.editor.view.nodeDOM(this.tableNodePosition);

    if (tableBlockNode) {
      const coordinates = (
        tableBlockNode as HTMLElement
      ).getBoundingClientRect();

      this.tippy?.setProps({
        getReferenceClientRect: () =>
          new DOMRect(
            coordinates.left,
            coordinates.top,
            coordinates.width,
            coordinates.height
          ),
      });
    }
  }

  documentClickHandler(ev: MouseEvent) {
    if (
      !this.element.contains(ev.target as Node) &&
      !this.editor.options.element.contains(ev.target as Node) &&
      // Prevent hide if click on bubble menu related to table
      !(ev.target as HTMLElement).closest('.table-bubble-menu')
    ) {
      this.isPreventHide = false;
      this.hide();
    }
  }

  mouseDownHandler() {
    this.isPreventHide = true;
  }

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom',
      hideOnClick: 'toggle',
      offset: [0, 8],
      maxWidth: 'none',
      zIndex: 0,
    });
  }

  updateSelectionHandler() {
    setTimeout(() => {
      const newTableNodePosition = this.getSelectedTableNodePosition();

      if (
        newTableNodePosition !== null &&
        newTableNodePosition !== this.tableNodePosition
      ) {
        this.hide();
      }

      if (this.checkIsShow()) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  show() {
    if (this.isShowed) return;

    this.isShowed = true;

    const { pos: selectedPosition } = this.editor.view.state.selection.$anchor;
    const domAtSelectedPosition = this.editor.view.domAtPos(selectedPosition);
    const tableBlockNode = this.editor.view.dom.pmViewDesc?.children.find((i) =>
      i.dom.contains(domAtSelectedPosition.node)
    );

    if (!tableBlockNode || tableBlockNode.node?.type.name !== 'table') {
      return;
    }

    const coordinates = (
      tableBlockNode.dom as HTMLElement
    ).getBoundingClientRect();

    this.createTooltip();
    this.tippy?.setProps({
      getReferenceClientRect: () =>
        new DOMRect(
          coordinates.left,
          coordinates.top,
          coordinates.width,
          coordinates.height
        ),
    });
    this.tippy?.show();
    this.updateCurrentTableSelectedStatus(true);
  }

  hide() {
    if (!this.isShowed) return;

    this.isShowed = false;
    this.tippy?.hide();
    this.updateCurrentTableSelectedStatus(false);
  }

  checkIsShow() {
    const isTableSelected = this.editor.isActive('table');

    return isTableSelected && (this.editor.isFocused || this.isPreventHide);
  }

  updateCurrentTableSelectedStatus(isSelected: boolean) {
    let tableNodePosition = this.tableNodePosition;

    if (isSelected) {
      const position = this.getSelectedTableNodePosition();

      if (position !== null) {
        tableNodePosition = position;
        this.tableNodePosition = position;
      }
    } else {
      this.tableNodePosition = null;
    }

    if (tableNodePosition !== null) {
      try {
        this.editor.view.state.doc.nodesBetween(
          tableNodePosition,
          tableNodePosition + 1,
          (node, pos) => {
            if ('table' === node.type.name) {
              const tr = this.editor.view.state.tr
                .setNodeAttribute(pos, 'selected', isSelected)
                .setMeta('addToHistory', false)
                .setMeta('isPreventUpdate', true);
              this.editor.view.dispatch(tr);
            }
          }
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  getSelectedTableNodePosition(): number | null {
    let position = null;

    this.editor.view.state.tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;

      this.editor.view.state.doc.nodesBetween(from, to, (node, pos) => {
        if ('table' === node.type.name) {
          position = pos;
        }
      });
    });

    return position;
  }

  destroy() {
    this.tippy?.destroy();
    this.editor.off('focus', this.updateSelectionHandler);
    this.editor.off('selectionUpdate', this.updateSelectionHandler);
    this.editor.off('transaction', this.transactionHandler);
    this.element.removeEventListener('mousedown', this.mouseDownHandler);
    document.body.removeEventListener('mousedown', this.documentClickHandler);
    document.removeEventListener('scroll', this.updateMenuPosition);
  }
}

export const tableMenuPluginKey = new PluginKey('tableMenu');

export const TableMenuPlugin = (options: TableMenuPluginProps) => {
  return new Plugin({
    key: tableMenuPluginKey,
    view: () => new TableMenuView({ ...options }),
  });
};
