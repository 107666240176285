import { FormattedGongTranscript } from '@distribute/shared/api-types/gong';
import { Checkbox } from '../../../../../../../shared/ui';
import React from 'react';
import moment from 'moment';
import cn from 'classnames';

type Props = {
  transcripts: FormattedGongTranscript[];
  selectedTranscripts: string[];
  toggleCallTranscript(transcriptId: string): void;
  toggleAllTranscripts(): void;
  isError?: boolean;
  messageText?: string;
};

export const CallTranscriptsPicker: React.FC<Props> = ({
  transcripts,
  selectedTranscripts,
  toggleCallTranscript,
  toggleAllTranscripts,
  isError,
  messageText,
}) => {
  return (
    <div className="mb-8">
      <div
        className={cn(
          'border border-gray-200 rounded-xl bg-base-white overflow-hidden flex flex-col',
          { '!border-error-600': isError }
        )}
      >
        <div className="bg-gray-50 h-8.5 flex items-center px-4 border-b border-gray-200 shrink-0">
          <Checkbox
            checked={transcripts.length === selectedTranscripts.length}
            onClick={toggleAllTranscripts}
          >
            <span className="pl-3 text-xs text-gray-600 font-medium">
              Call transcript
            </span>
          </Checkbox>
        </div>
        <ul className="flex-1">
          {transcripts.map((transcript) => (
            <li
              className="flex h-16 items-center gap-4 px-4 border-b last:border-b-0 border-gray-200 cursor-pointer hover:bg-gray-50 transition"
              onClick={() => toggleCallTranscript(transcript.metaData.id)}
            >
              <Checkbox
                checked={selectedTranscripts.includes(transcript.metaData.id)}
                onChange={() => toggleCallTranscript(transcript.metaData.id)}
              />
              <div className="min-w-0">
                <p className="text-sm font-medium text-gray-900 whitespace-nowrap truncate">
                  {transcript.metaData.title}
                </p>
                <p className="text-sm text-gray-600">
                  {moment(transcript.metaData.started).format('MMM DD, YYYY')}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {messageText && (
        <p
          className={cn('mt-1.5', {
            '!text-error-500': isError,
          })}
        >
          {messageText}
        </p>
      )}
    </div>
  );
};
