import React from 'react';
import { Helmet } from 'react-helmet-async';

import CreateAppSumoAccountForm from './components/CreateAppSumoAccountForm';
import { Icon, Link } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { environment } from '../../../environments/environment';

export const SignUpAppSumo = () => {
  return (
    <>
      <Helmet title="Sign Up" />
      <div className="relative flex min-h-screen overflow-hidden bg-bg-100">
        <div className="md:w-full w-[50%] bg-base-white flex-col items-center flex justify-start py-20 md:px-4">
          <Link
            target="_blank"
            rel="noreferrer"
            href={environment.landingUrl}
            size="medium"
            className="flex items-center mb-16 text-base-black"
          >
            <Icon glyph={IconMap.DistributeLogo} />
            <Icon glyph={IconMap.Plus} className="text-base-black" />
            <img
              src="../../../assets/images/app-sumo-logo.png"
              alt="Auth logo"
              width={150}
              className="ml-1"
            />
          </Link>
          <div className="w-110 md:w-full">
            <div className="flex items-center justify-center mb-3">
              <p className="font-medium text-center text-gray-900 text-display-md font-display -tracking-2">
                Let's get the fiesta started!
              </p>
            </div>

            <div className="col-start-8 col-end-12 md:col-start-3 md:col-end-7">
              <CreateAppSumoAccountForm />
            </div>
          </div>
        </div>
        <div className="w-[50%] flex justify-center px-20 md:hidden relative">
          <div className="absolute top-0 left-0 pointer-events-none">
            <img
              src="../../../assets/images/signup-left-bg.svg"
              alt="Auth left img"
              className="w-full md:hidden"
            />
          </div>
          <div className="absolute bottom-0 right-0 pointer-events-none">
            <img
              src="../../../assets/images/signup-right-bg.svg"
              alt="Auth left img"
              className="w-full md:hidden"
            />
          </div>
          <img
            src="../../../assets/images/app-sumo-register.png"
            alt="Auth logo"
            className="relative object-contain w-full -mr-13 -mb-7"
          />
        </div>
      </div>
    </>
  );
};
