import React, { useCallback, useEffect, useState } from 'react';
import { IconMap } from '../../../../shared/sprite';
import { Avatar, Button } from '../../../../shared/ui';
import { Icon } from '../../../../shared/ui';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../../entities/auth-user';
import { profileSettingModel } from '../../model';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

export const ChangePhotoForm = () => {
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const { photoUrl, displayName } = useSelector(
    authUserModel.selectors.selectUserWithError
  );

  const isUploading = useSelector(
    profileSettingModel.selectors.selectIsUploadPhotoLoading
  );

  const isRemoving = useSelector(
    profileSettingModel.selectors.selectIsRemovePhotoLoading
  );

  const uploadProgress = useSelector(
    profileSettingModel.selectors.selectUploadingPhotoProgress
  );

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      const { size, type, name } = file;

      if (!ALLOWED_FILE_TYPES.includes(type)) {
        dispatch(
          profileSettingModel.actions.setUploadPhotoError(
            'File type not allowed'
          )
        );
        return;
      }
      if (size > MAX_FILE_SIZE) {
        dispatch(
          profileSettingModel.actions.setUploadPhotoError(
            'Max file size exceeded'
          )
        );
        return;
      }
      setFileName(name);

      dispatch(profileSettingModel.actions.changePhotoClick({ photo: file }));
    },
    [dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    onValidateAndUpload(file);
  };

  const onDeletePhoto = () => {
    dispatch(profileSettingModel.actions.deletePhotoClick());
  };

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(true);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const file = e.dataTransfer?.files[0];

      return onValidateAndUpload(file);
    };

    document.body.addEventListener('dragover', enterListener);
    document.body.addEventListener('dragleave', leaveListener);
    document.body.addEventListener('drop', dropListener, {});
    return () => {
      document.body.removeEventListener('dragleave', leaveListener);
      document.body.removeEventListener('dragover', enterListener);
      document.body.removeEventListener('drop', dropListener);
    };
  }, [onValidateAndUpload, dispatch]);

  return (
    <div className="mb-5 flex sm:flex-col">
      <p className="w-61 text-gray-700 text-sm mr-8 sm:mb-4">Your photo</p>

      <div className="flex max-w-107 gap-5 w-full">
        <div className="flex-shrink-0 flex flex-col items-center justify-between gap-1.5">
          <Avatar
            displayName={displayName}
            src={photoUrl}
            size="2xl"
            isUserAvatar
          />
          {photoUrl && (
            <Button
              size="sm"
              type="button"
              variant="icon"
              color="link"
              disabled={isRemoving || isUploading}
              title="Delete Photo"
              onClick={onDeletePhoto}
            >
              <Icon glyph={IconMap.Trash} />
            </Button>
          )}
        </div>
        <div className="w-full">
          {isUploading ? (
            <div className="relative overflow-hidden p-4 flex rounded-xl items-center justify-center border border-gray-300 bg-base-white h-full">
              <div
                style={{ transform: `translateX(${uploadProgress}%)` }}
                className="absolute top-0 -left-full w-full h-full bg-gray-50"
              ></div>
              <div className="relative text-center w-full text-gray-700">
                <p className="truncate">{fileName}</p>
                <p>{uploadProgress}% uploaded</p>
              </div>
            </div>
          ) : (
            <label
              className={cn(
                'rounded-xl p-4 flex items-center flex-col text-gray-600 justify-center shadow-border-gray hover:shadow-border-primary cursor-pointer h-full',
                {
                  'shadow-border-primary': isDragging,
                  'shadow-border-gray': !isDragging,
                }
              )}
            >
              <span className="text-s">
                <span className="hover:cursor-pointer font-medium text-primary-700">
                  Click to upload
                </span>{' '}
                or drag and drop
              </span>
              <span className="mt-1">
                <span className="text-xs font-normal text-gray-600">
                  PNG or JPG (max. 5 MB)
                </span>
                <input
                  accept={ALLOWED_FILE_TYPES.join(',')}
                  type="file"
                  onChange={onChange}
                  className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
                />
              </span>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};
