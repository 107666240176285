import { Dispatch, FC, SetStateAction } from 'react';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { IconMap } from '../../../../shared/sprite';
import { Dropdown, Icon } from '../../../../shared/ui';
import { useDispatch } from 'react-redux';
import { snippetsModel } from '../../model';
import { CategoryTypesTag } from './CategoryTypesTag';
import { cn } from '@distribute/frontend/utils';

type Props = {
  category: SnippetCategoryWithSnippets;
  setEditingCategory: Dispatch<
    SetStateAction<SnippetCategoryWithSnippets | undefined>
  >;
  setDeletingCategory: Dispatch<
    SetStateAction<SnippetCategoryWithSnippets | undefined>
  >;
  isDragging?: boolean;
};

export const ManageCategoryRow: FC<Props> = ({
  category,
  setEditingCategory,
  setDeletingCategory,
  isDragging,
}) => {
  const dispatch = useDispatch();

  const options = [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => {
        setEditingCategory(category);
        dispatch(snippetsModel.actions.setIsManageCategoriesModalOpen(false));
      },
      iconName: IconMap.Edit02,
      iconWidth: 16,
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        setDeletingCategory(category);
        dispatch(snippetsModel.actions.setIsManageCategoriesModalOpen(false));
      },
      iconName: IconMap.Delete,
      iconWidth: 16,
    },
  ];

  return (
    <div
      className={cn('flex items-center justify-between px-4 py-3', {
        'cursor-pointer': !isDragging,
      })}
    >
      <div className="flex items-center gap-x-6">
        <Icon glyph={IconMap.Drag} width={20} className="text-gray-600" />
        <span className="font-medium text-gray-700 text-md">
          {category.name}
        </span>
      </div>
      <div className="flex items-center gap-x-4">
        <CategoryTypesTag category={category} />

        <Dropdown
          isModalMode={false}
          listStyles="shadow-lg"
          triggerComponent={
            <DropdownMenu.Trigger className="flex items-center justify-center w-10 h-10 -mr-2 rounded-lg focus:outline-none hover:bg-gray-50">
              <Icon glyph={IconMap.DotsVertical} width={20} />
            </DropdownMenu.Trigger>
          }
          items={options}
        />
      </div>
    </div>
  );
};
