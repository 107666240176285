export const EMAIL_VERIFICATION_TIMESTAMP = 'email-verification-timestamp';
export const LINK_VERIFICATION_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)+[^\s]*$/;

export const PAGE_COVER_DEFAULT_URL =
  'https://storage.googleapis.com/distribute-production-storage/general/personalizedImage.png';
export const PAGE_COVER_DEFAULT_COLOR = '#141414';

export const LINKEDIN_TOKEN_STORAGE_KEY = 'linkedin_access_token';
export const LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE =
  'linkedin_token_expiration_date';

export const TWITTER_TOKEN_STORAGE_KEY = 'twitter-access_token';
export const TWITTER_TOKEN_SECRET_STORAGE_KEY = 'twitter-access-token-secret';

export const TWITTER_USER_NAME_STORAGE_KEY = 'twitter_user_name';
export const TWITTER_USER_PICTURE_URL_STORAGE_KEY = 'twitter_user_photo';

export const GONG_ACCESS_TOKEN_STORAGE_KEY = 'gong_access_token';
export const GONG_ACCESS_TOKEN_EXPIRATION_DATE = 'gong_token_expiration_date';
export const GONG_BASE_URL = 'gong_base_url';
export const GONG_REFRESH_TOKEN_STORAGE_KEY = 'gong_refresh_token';

export const IMAGE_TYPES = ['PNG', 'JPG', 'JPEG'];
export const IMAGE_ACCEPT_STRING = IMAGE_TYPES.map(
  (type) => `image/${type.toLowerCase()}`
).join(',');

export const LOOM_URL_REGEX =
  /^((https?:\/\/)?(www\.)?)?loom\.com([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/g;
export const YOUTUBE_URL_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/;
export const GOOGLE_URL_REGEX =
  /^((https?:\/\/)?(docs.)?)?google\.com([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/g;
export const CALENDLY_URL_REGEX =
  /^((https?:\/\/)?(www\.)?)?calendly\.com([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/g;
