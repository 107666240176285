import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';

import { redirect } from '../../../../entities/history';

import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';

import { createNotification, snackbarModel } from '../../../snackbar';
import { videoRecordsApi } from '../../../../shared/api';
import { actions } from '../reducer';
import {
  selectRecord,
  selectRecordToDelete,
  selectRecords,
} from '../selectors';
import { RT } from '../../../../shared/types';
import { VideoRecordDetailType } from '@distribute/shared/api-types/video-record';
import { teamsModel } from '../../../teams';

export function* deleteRecord() {
  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const records: RT<typeof selectRecords> = yield select(selectRecords);
    const record: RT<typeof selectRecord> = yield select(selectRecord);
    const recordToDelete: RT<typeof selectRecordToDelete> = yield select(
      selectRecordToDelete
    );

    yield put(actions.setIsRecordDeleteLoading(true));
    yield call(videoRecordsApi.delete, recordToDelete.id, { teamId: team.id });

    yield put(
      actions.setRecords(records.filter((r) => r.id !== recordToDelete.id))
    );

    if (record.id === recordToDelete.id) {
      yield call(redirect.toRecordsMy);
      yield put(actions.setRecord({} as VideoRecordDetailType));
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.record.deleteError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
  } finally {
    yield put(actions.setIsRecordDeleteLoading(false));
    yield put(actions.setRecordIdToDelete(null));
  }
}
