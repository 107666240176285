import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useMemo, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';

import { IconMap } from '../../../../shared/sprite';
import { Button, Item, Tooltip } from '../../../../shared/ui';
import './CodeBlock.css';
import {
  CodeBlockLanguageSelect,
  LanguageSelectItem,
} from './CodeBlockLanguageSelect';
import { getSelectOptions } from './constants';
import { useLanguageSelector } from './hooks/useLanguageSelector';
import { CodeBlockAttributes } from './types';
import { useTimer } from '../../../../shared/hooks/useTimer';

export type CodeBlockNode = NodeViewProps['node'] & {
  attrs: CodeBlockAttributes;
};

export type CodeBlockNodeViewProps = NodeViewProps & {
  node: CodeBlockNode;
};

export const CodeBlockNodeView = ({
  node,
  updateAttributes,
}: CodeBlockNodeViewProps) => {
  const { attrs, textContent } = node;
  const [isOpen, setIsOpen] = useTimer(1000);
  const codeRef = useRef<HTMLElement | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const selectOptions = getSelectOptions(attrs.language);

  const selectedOption: Item = useMemo(
    () =>
      attrs.language
        ? selectOptions.find((option) => option.id === attrs.language) ??
          selectOptions[0]
        : selectOptions[0],
    [attrs.language, selectOptions]
  );

  const handleChange = (id: LanguageSelectItem['id']) => {
    updateAttributes({
      selectedLanguage: id,
    });
  };

  const handleSelectOpenClose = useCallback((isOpen: boolean) => {
    setIsSelectOpen(isOpen);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(textContent);
    setIsOpen();
  };

  useLanguageSelector(attrs.selectedLanguage, updateAttributes);

  return (
    <NodeViewWrapper>
      <div className="group/code flex flex-col border p-2 gap-1 rounded-md bg-gray-50 border-gray-300 code-block">
        <div
          className={classNames(
            'flex flex-row items-center group-hover/code:visible',
            {
              invisible: !isSelectOpen,
            }
          )}
        >
          <CodeBlockLanguageSelect
            className="flex-1 h-full"
            onChange={handleChange}
            value={selectedOption.id}
            items={selectOptions}
            contentEditable={false}
            isOpen={isSelectOpen}
            setOpenClose={handleSelectOpenClose}
          />
          <Tooltip
            isOpen={isOpen}
            trigger={
              <Button
                contentEditable={false}
                onClick={handleCopy}
                iconLeftName={IconMap.Copy01}
                iconBlockClassName="mr-1"
                iconLeftWidth={16}
                type="button"
                className="text-xs cursror-pointer !px-1 hover:bg-base-black/[0.04] !text-gray-500 !max-h-6 !font-normal"
                color="transparent"
                variant="icon-text"
              >
                Copy
              </Button>
            }
          >
            Copied!
          </Tooltip>
        </div>
        <pre
          className="pb-6 px-2"
          ref={(el) => {
            if (el?.firstElementChild) {
              codeRef.current = el.firstElementChild as HTMLElement;
            }
          }}
        >
          <NodeViewContent {...attrs} as="code" className="text-md" />
        </pre>
      </div>
    </NodeViewWrapper>
  );
};
