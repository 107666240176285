export const toPrettySeconds = (seconds: number) => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    timeZone: 'Etc/UTC',
    hour12: false,
    minute: '2-digit',
    second: '2-digit',
  });

  const time = formatter.format(new Date(seconds * 1000));
  const [min, sec] = time.split(':');

  return `${min.replace('00', '0')}:${sec}`;
};
