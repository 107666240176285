import {
  GetPopUpResponseType,
  UpdatePopUpRequestType,
  UpdatePopUpResponseType,
} from '@distribute/shared/api-types/pop-up';
import { getUploadProgress } from '../../lib';
import { api } from './instance';

export const popUpApi = {
  async getPopUp(documentContentId: number) {
    const { data } = await api.get<GetPopUpResponseType>(
      `pop-up/${documentContentId}`
    );

    return data;
  },

  async updatePopUp(documentContentId: number, data: UpdatePopUpRequestType) {
    const { data: responseData } = await api.patch<UpdatePopUpResponseType>(
      `pop-up/${documentContentId}`,
      data
    );
    return responseData;
  },

  async updatePopUpImage(
    documentContentId: number,
    image: File,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put(
      `pop-up/${documentContentId}/image-url`,
      { image },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );

    return responseData;
  },

  async removePopUpImage(documentContentId: number) {
    const { data } = await api.delete(`pop-up/${documentContentId}/image-url`);

    return data;
  },
};
