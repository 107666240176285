import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleLayout, Button, Icon, Link } from '../../../shared/ui';
import { environment } from '../../../environments/environment';
import { IconMap } from '../../../shared/sprite';
import { onboardingModel } from '../../../features/onboarding';

export const SuccessfullyCreatedAccount: React.FC = () => {
  const dispatch = useDispatch();
  const isLoadingStartAppsumoOnboarding = useSelector(
    onboardingModel.selectors.selectIsLoadingStartAppsumoOnboarding
  );
  return (
    <>
      <Helmet title="Verify email" />
      <SimpleLayout
        hideLogo
        hideImage
        classNameContent="!w-147.5 py-20 md:px-8 sm:!w-full"
      >
        <Link
          target="_blank"
          rel="noreferrer"
          href={environment.landingUrl}
          size="medium"
          className="flex items-center justify-center mb-16 text-base-black"
        >
          <Icon glyph={IconMap.DistributeLogo} />
          <Icon glyph={IconMap.Plus} className="text-base-black" />
          <img
            src="../../../assets/images/app-sumo-logo.png"
            alt="Auth logo"
            width={150}
            className="ml-1"
          />
        </Link>
        <div className="flex items-center justify-center mb-3">
          <p className="font-medium text-center text-gray-900 text-display-md font-display">
            Your account has been created successfully!
          </p>
        </div>

        <div className="flex flex-col items-center justify-center mb-6 font-normal text-center text-gray-600 text-md ">
          <p>
            Continue with your workspace setup and embark on an incredible
            journey into the world of the Distribute product!
          </p>
        </div>

        <div className="flex items-center justify-center">
          <Button
            variant="text"
            color="primary"
            fullWidth
            loading={isLoadingStartAppsumoOnboarding}
            onClick={() =>
              dispatch(onboardingModel.actions.startAppsumoUserOnboarding())
            }
          >
            Continue
          </Button>
        </div>
      </SimpleLayout>
    </>
  );
};
