import {
  UpdateVideoRecordRequestType,
  UpdateTeamMemberPermissionRequestType,
  CreateUserPermissionResponseType,
  CreateUserPermissionRequestType,
  UpdateUserPermissionRequestType,
  VideoRecordDetailType,
  VideoRecordType,
  VideoRecordTeamIdParamsType,
  VideoRecordStatusAndMetadataType,
  VideoRecordStatusAndMetadataAllType,
  VideoRecordPlaybackType,
} from '@distribute/shared/api-types/video-record';

import { api } from './instance';

export const videoRecordsApi = {
  async getVideoRecordForPlayback(prefix: string) {
    const { data: responseData } = await api.get(
      `video-record/${prefix}/playback`,
      { params: { converted: true } }
    );
    return responseData as VideoRecordPlaybackType;
  },

  async getAll(query: VideoRecordTeamIdParamsType) {
    const { data: responseData } = await api.get('video-record', {
      params: query,
    });
    return responseData as VideoRecordType[];
  },

  async get({ prefix, isPublic }: { prefix: string; isPublic: boolean }) {
    const path = isPublic ? `${prefix}/public` : prefix;

    const { data: responseData } = await api.get(`video-record/${path}`);
    return responseData as VideoRecordDetailType;
  },

  async getStatusAndMetadata(prefix: string) {
    const { data: responseData } = await api
      .get(`video-record/${prefix}/status`)
      .catch(() => ({ data: {} }));

    return responseData as VideoRecordStatusAndMetadataType;
  },

  async getStatusAllAndMetadata(query: VideoRecordTeamIdParamsType) {
    const { data: responseData } = await api
      .get('video-record/status-all', {
        params: query,
      })
      .catch(() => ({ data: {} }));

    return responseData as VideoRecordStatusAndMetadataAllType;
  },

  async update(
    id: string,
    data: UpdateVideoRecordRequestType,
    query: VideoRecordTeamIdParamsType
  ) {
    return api.patch(`video-record/${id}`, data, { params: query });
  },

  async delete(id: string, query: VideoRecordTeamIdParamsType) {
    return api.delete(`video-record/${id}`, { params: query });
  },

  async updateTeamMemberPermission(
    id: string,
    data: UpdateTeamMemberPermissionRequestType,
    query: VideoRecordTeamIdParamsType
  ) {
    return api.patch(`video-record/team-member-permission/${id}`, data, {
      params: query,
    });
  },

  async createUserPermission(
    id: string,
    data: CreateUserPermissionRequestType,
    query: VideoRecordTeamIdParamsType
  ) {
    const response = await api.post(
      `video-record/user-permission/${id}`,
      data,
      {
        params: query,
      }
    );
    return response.data as CreateUserPermissionResponseType;
  },

  async updateUserPermission(
    id: string,
    userPermissionToVideoRecordId: number,
    data: UpdateUserPermissionRequestType,
    query: VideoRecordTeamIdParamsType
  ) {
    return api.patch(
      `video-record/user-permission/${id}/${userPermissionToVideoRecordId}`,
      data,
      {
        params: query,
      }
    );
  },

  async deleteUserPermission(
    id: string,
    userPermissionToVideoRecordId: number,
    query: VideoRecordTeamIdParamsType
  ) {
    return api.delete(
      `video-record/user-permission/${id}/${userPermissionToVideoRecordId}`,
      {
        params: query,
      }
    );
  },
};
