import { call, select } from 'redux-saga/effects';
import { redirect } from '../../../entities/history';
import { authUserModel } from '../../../entities/auth-user';
import type { RT } from '../../../shared/types';
import { recordingsModel } from '../../../features/recordings/model';

export function* recordsSharedWorker() {
  const user: RT<typeof authUserModel.selectors.selectUserWithError> =
    yield select(authUserModel.selectors.selectUserWithError);

  const hasRecordsShared: RT<
    typeof recordingsModel.selectors.selectHasRecordsShared
  > = yield select(recordingsModel.selectors.selectHasRecordsShared(user.id));

  if (!hasRecordsShared) {
    yield call(redirect.toRecordsMy);
  }
}
