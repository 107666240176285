import { call, put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api';
import { Folder, TeamFiltered } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { redirect } from '../../../../entities/history';
import { teamsModel } from '../../../teams';
import { handleError } from '../../../../utils/handleError';

export function* createFolder({
  payload: { title, callback },
}: ReturnType<typeof foldersModel.actions.createFolder>) {
  try {
    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    yield put(actions.setCreateFolderIsLoading(true));
    const folder: Folder = yield foldersApi.addFolder({
      title: title,
      teamId,
    });

    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    yield put(foldersModel.actions.setFolders([...folders, folder]));
    yield callback();

    yield call(redirect.toWorkspaceFolder, {
      sequenceNumber: folder.sequenceNumber,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Failed to create a folder'))
      )
    );
  } finally {
    yield put(actions.setCreateFolderIsLoading(false));
  }
}
