import React, { useState } from 'react';
import { Icon } from '../Icon';
import { IconMap } from '../../sprite';
import { ColorListPickerItem } from './ColorListPickerItem';

type Color = {
  value: string;
  name: string;
};

type Props = {
  onReset(): void;
  onSetColor(color: string): void;
  currentColor: string;
  colorList: Color[];
  isResetVisible?: boolean;
};

export const ColorListPicker: React.FC<Props> = ({
  onReset,
  colorList,
  onSetColor,
  currentColor,
  isResetVisible,
}) => {
  const [isResetBtnVisible, setIsResetBtnVisible] = useState(isResetVisible);
  const handleColorReset = () => {
    onReset();
    setIsResetBtnVisible(isResetVisible);
  };

  const handleColorClick = (color: string) => {
    onSetColor(color);
    setIsResetBtnVisible(currentColor !== color);
  };

  const isColorUpdated = currentColor !== colorList[0].value;

  return (
    <div>
      {isResetBtnVisible || isColorUpdated ? (
        <>
          <div
            className="flex gap-2 items-center text-gray-700 h-5 px-1 cursor-pointer"
            onClick={handleColorReset}
          >
            <Icon glyph={IconMap.DropCross} width={16} />
            <span className="text-sm font-semibold te">Reset color</span>
          </div>
          <hr className="border-gray-200 my-4" />
        </>
      ) : null}

      <div className="flex items-center flex-wrap gap-1.5">
        {colorList.map((color) => (
          <ColorListPickerItem
            key={color.value}
            name={color.name}
            color={color.value}
            onClick={handleColorClick}
            active={currentColor === color.value}
          />
        ))}
      </div>
    </div>
  );
};
