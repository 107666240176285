import { Node } from '@tiptap/core';

export const SnippetBlockExt = Node.create({
  name: 'snippet',
  atom: true,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,

  addAttributes() {
    return {
      'data-media-width': {
        default: null,
      },
      'data-media-height': {
        default: null,
      },
      'data-align': {
        default: 'center',
      },
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'figure[data-type="snippet"]',
        getAttrs(node) {
          return {
            'data-media-width':
              typeof node === 'string'
                ? null
                : node.getAttribute('data-media-width'),
            'data-media-height':
              typeof node === 'string'
                ? null
                : node.getAttribute('data-media-height'),
            'data-align':
              typeof node === 'string' ? null : node.getAttribute('data-align'),
          };
        },
      },
    ];
  },

  renderHTML({ node }) {
    const width = node.attrs['data-media-width'];
    const height = node.attrs['data-media-height'];
    const align = node.attrs['data-align'];

    let flexWrapperClassName = 'snippet-wrapper';

    if (align === 'left') {
      flexWrapperClassName += ' align-start';
    }
    if (align === 'center') {
      flexWrapperClassName += ' align-center';
    }
    if (align === 'right') {
      flexWrapperClassName += ' align-end';
    }

    let contentStyle = 'border: none; outline: none;';
    contentStyle += `${width ? ` width: ${width}px;` : ''} ${
      height ? ` height: ${height}px;` : ''
    }`;

    return [
      'div',
      {
        class: `snippet-wrapper ${flexWrapperClassName}`,
        style: 'border: none; outline: none;',
      },
      [
        'div',
        { style: contentStyle, class: 'snippet-content' },
        `{{ ${node.attrs.id} }}`,
      ],
    ];
  },
});
