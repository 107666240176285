import React, { FC, ReactNode } from 'react';
import { Tooltip } from '.';
import { IconMap } from '../sprite';
import { Icon } from './Icon';
type Props = {
  children: ReactNode;
};
export const InfoTooltip: FC<Props> = ({ children }) => {
  return <Tooltip trigger={<Icon glyph={IconMap.Info} />}>{children}</Tooltip>;
};
