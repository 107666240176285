import { snippetsModel } from '..';
import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';

export function* deleteSnippetCategory({
  payload: { id, name, moveCategoryId, callback },
}: ReturnType<typeof snippetsModel.actions.deleteSnippetCategory>) {
  try {
    yield put(snippetsModel.actions.setIsDeleteSnippetCategoryLoading(true));
    yield call(snippetsApi.deleteSnippetCategory, id, moveCategoryId);

    const { id: currentTeamId } = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const categories: SnippetCategoryWithSnippets[] = yield call(
      snippetsApi.getSnippetsCategories,
      currentTeamId
    );

    yield put(snippetsModel.actions.setCategories(categories));

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          `“${name}” category has been deleted successfully.`
        )
      )
    );

    callback();
  } catch (err) {
    logger.error(err);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete snippet category')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsDeleteSnippetCategoryLoading(false));
  }
}
