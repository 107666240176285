import { Node, mergeAttributes } from '@tiptap/react';

export const ToggleListItemContentExt = Node.create({
  name: 'toggleListItemContent',

  content: 'block+',

  group: 'block',

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'toggle-list-item-content' }, HTMLAttributes),
      0,
    ];
  },
});
