import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { PageCoverHintModal } from './PageCoverHintModal';

type Props = {
  allowedTypes: string[];
  progress?: number;
  isUploading?: boolean;
  disabled?: boolean;
  maxSize: string;
  fileName: string;
  onValidateAndUpload(file?: File): void;
  handleDelete(): void;
  clickHandler(): void;
};

export const PageCoverUploadInput: FC<Props> = ({
  progress,
  allowedTypes,
  isUploading,
  disabled = false,
  maxSize,
  fileName,
  onValidateAndUpload,
  handleDelete,
  clickHandler,
}) => {
  const [isHintShown, setIsHintShown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(false);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const file = e.dataTransfer?.files[0];

      return onValidateAndUpload(file);
    };
    const containerRefElement = containerRef.current;

    containerRefElement?.addEventListener('dragover', enterListener);
    containerRefElement?.addEventListener('dragleave', leaveListener);
    containerRefElement?.addEventListener('drop', dropListener, {});
    return () => {
      containerRefElement?.removeEventListener('dragleave', leaveListener);
      containerRefElement?.removeEventListener('dragover', enterListener);
      containerRefElement?.removeEventListener('drop', dropListener);
    };
  }, [onValidateAndUpload]);

  return (
    <div className="w-full h-full relative" ref={containerRef}>
      {isUploading ? (
        <div className="relative overflow-hidden p-4 pl-16 h-full flex rounded-xl items-center justify-center border border-dashed border-gray-400 bg-base-white">
          <div
            style={{ transform: `translateX(${progress}%)` }}
            className="absolute top-0 -left-full w-full h-full bg-gray-50"
          ></div>
          <div className="relative w-full text-gray-700">
            <p className="truncate">{fileName}</p>

            {progress === 100 ? (
              <p>Processing...</p>
            ) : (
              <p>{progress}% uploaded</p>
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={clickHandler}
          className={classNames(
            'rounded-xl flex items-center flex-col text-gray-600 justify-center h-full relative',
            {
              'border-primary-500': isDragging,
              'border-gray-400 ': !isDragging,
              'cursor-pointer': !disabled,
              'shadow-border-gray bg-gray-50 cursor-default': disabled,
            }
          )}
        >
          <Button
            variant="icon-text"
            color="secondary"
            size="sm"
            iconLeftName={IconMap.ImageX}
            iconLeftWidth={20}
            className="font-semibold !px-3 !absolute right-px -top-px"
            iconBlockClassName="mr-1.5"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            Remove cover
          </Button>
          <span className="text-s">
            <span
              className={classNames(
                'hover:cursor-pointer font-semibold relative flex items-center gap-1 ',
                {
                  'text-gray-300': disabled,
                  'text-primary-700': !disabled,
                }
              )}
            >
              <Icon glyph={IconMap.Paperclip02} width={16} />
              Select file
              <span className="text-gray-600">or drag and drop it here</span>
            </span>
          </span>
          <span className="-mt-1 text-xs">
            <span className="text-xs font-normal text-gray-500">
              {allowedTypes.map((i, idx) => {
                if (allowedTypes.length === 1) {
                  return i;
                }

                if (idx === allowedTypes.length - 2) {
                  return i;
                }
                if (idx === allowedTypes.length - 1) {
                  return ` or ${i}`;
                }
                return `${i}, `;
              })}{' '}
              (max. {maxSize})
            </span>
            <span className="text-xs font-normal text-gray-500">
              , recommended size is 1600px by 540px{' '}
              <Button
                className="underline py-1 !inline !text-gray-500"
                size="xs"
                variant="text"
                color="link-gray"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsHintShown(true);
                }}
              >
                learn more
              </Button>
            </span>
          </span>
        </div>
      )}
      <PageCoverHintModal
        isOpen={isHintShown}
        onClose={() => setIsHintShown(false)}
      />
    </div>
  );
};
