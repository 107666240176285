import { FirebaseError } from '../types';

import { errorMessages } from '../config';

export const getErrorMessage = (
  e: unknown,
  defaultMessage = 'Invalid credentials'
) => {
  const fbError = e as FirebaseError;
  return errorMessages[fbError.code] || fbError.message || defaultMessage;
};
