import {
  FolderTeamMembersPermission,
  UserPermissionToFolderRole,
} from '@distribute/shared/types';
import { RootState } from '../../../app';

import { selectWithError } from '../../../shared/lib';
import { selectUserWithError } from '../../../entities/auth-user/model/selector';

import { getTeamPermissions } from '../../teams/lib';
import { getFolderPermissions } from '../lib';

import { createSelector } from '@reduxjs/toolkit';

import { getPagePermissions } from '../../pages/lib/getPagePermissions';

export const selectFolders = ({ folders }: RootState) => folders.folders;

export const selectCurrentFolder = ({ folders }: RootState) =>
  folders.currentFolder;
export const selectFoldersWithError = selectWithError(selectFolders, 'folders');
export const selectCurrentFolderWithError = selectWithError(
  selectCurrentFolder,
  'currentFolder'
);

export const selectCreateFolderIsLoading = ({ folders }: RootState) =>
  folders.createFolderIsLoading;

export const selectDeleteFolderIsLoading = ({ folders }: RootState) =>
  folders.deleteFolderIsLoading;

export const selectRenameFolderIsLoading = ({ folders }: RootState) =>
  folders.renameFolderIsLoading;

export const selectCreateUserPermissionIsLoading = ({ folders }: RootState) =>
  folders.createUserPermissionIsLoading;

export const selectFoldersStatus = ({ folders }: RootState) =>
  folders.foldersStatus;

export const selectFolderModalOpen = ({ folders }: RootState) =>
  folders.isModalOpen;

export const selectFoldersToEditPage = createSelector(
  [
    ({ folders }: RootState) => folders.folders,
    ({ pages }: RootState) => pages.pages,
    ({ teams }: RootState) => teams.currentTeamMembers,
    selectUserWithError,
  ],
  (folders, pages, teamMembers, user) => {
    const teamPermissions = getTeamPermissions(user, teamMembers);

    return folders.filter((folder) => {
      const folderPermission = getFolderPermissions(
        user,
        folder,
        teamPermissions
      );

      return pages.some(
        (p) =>
          p.folderId === folder.id &&
          getPagePermissions(user, folderPermission, p).isCanEditDocumentContent
      );
    });
  }
);

export const selectFoldersToMove = createSelector(
  [({ folders }: RootState) => folders.folders, selectUserWithError],
  (folders, user) => {
    return folders.filter((f) => {
      if (
        f.userPermissionToFolders.some(
          (uF) =>
            (uF.role === UserPermissionToFolderRole.OWNER ||
              uF.role === UserPermissionToFolderRole.EDITOR) &&
            uF.user.id === user.id
        )
      ) {
        return true;
      }

      if (
        f.userPermissionToFolders.some(
          (uF) =>
            uF.role === UserPermissionToFolderRole.VIEWER &&
            uF.user.id === user.id
        )
      ) {
        return false;
      }

      if (f.teamMembersPermission === FolderTeamMembersPermission.EDIT) {
        return true;
      }

      return false;
    });
  }
);
