import { createLowlight } from 'lowlight';
import hljs from 'highlight.js';
import { Item } from '../../../../shared/ui';

const languages = hljs.listLanguages();

export const Languages = languages.reduce((acc, lang) => {
  acc[lang] = lang.charAt(0).toUpperCase() + lang.slice(1);
  return acc;
}, {} as Record<string, string>);

Languages['plaintext'] = 'Plain text';
Languages['html'] = 'Html';

const customLanguages = [
  {
    id: 'plaintext',
    title: 'Plain text',
  },
  {
    id: 'html',
    title: 'Html',
  },
];
const customLanguagesIds = customLanguages.map((lang) => lang.id);

export const getSelectOptions = (selected?: string): Item<string>[] => {
  const { plaintext: _, html, ...otherLanguages } = Languages;
  const actualList = { ...otherLanguages };

  return [
    ...(selected && customLanguagesIds.includes('selected')
      ? [{ id: selected, title: Languages[selected] }]
      : []),
    ...customLanguages,
    ...Object.entries(actualList).map(([value, label]) => ({
      id: value,
      title: label,
    })),
  ];
};

export const lowlight = createLowlight({});
