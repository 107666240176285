import { FC } from 'react';
import ReactTimeAgo from 'timeago-react';
import * as timeAgo from 'timeago.js';

const myLocale = (diff: number, idx: number): [string, string] => {
  return [
    ['just now', 'right now'],
    ['%ss ago', 'in %ss'],
    ['1m ago', 'in 1m'],
    ['%sm ago', 'in %sm'],
    ['1h ago', 'in 1h'],
    ['%sh ago', 'in %sh'],
    ['1d ago', 'in 1d'],
    ['%sd ago', 'in %sd'],
    ['1w ago', 'in 1w'],
    ['%sw ago', 'in %sw'],
    ['1m ago', 'in 1m'],
    ['%sm ago', 'in %sm'],
    ['1y ago', 'in 1y'],
    ['%sy ago', 'in %sy'],
  ][idx] as [string, string];
};

timeAgo.register('my', myLocale);

type Props = { datetime: string | Date; locale?: string; className?: string };

export const TimeAgo: FC<Props> = (props) => {
  return <ReactTimeAgo {...props} live={false} />;
};
