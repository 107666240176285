import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/core';

export const VideoExt = Node.create({
  name: 'video',
  group: 'media',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      poster: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'video',
      mergeAttributes(
        {
          ...HTMLAttributes,
          controls: true,
          draggable: false,
          controlslist: 'nodownload',
        },
        { style: 'width: 100%' }
      ),
    ];
  },
});
