import React from 'react';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { snippetsModel } from '../../../features/snippets';
import { useSnippetsPermissions } from '../../../features/teams';
import { CreateSnippetButton } from '../../../features/snippets/ui/components';

export const EmptyPageOwnerView: React.FC = () => {
  const dispatch = useDispatch();

  const { isCanCreatePersonalSnippets } = useSnippetsPermissions();

  const openCreateCategoryModal = () => {
    dispatch(snippetsModel.actions.setIsCreateCategoryModalOpen(true));
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full gap-8 overflow-hidden">
      <div className="text-center">
        <p className="font-semibold text-gray-900 text-md mb-1">
          No snippets... yet
        </p>
        <p className="text-sm font-normal text-gray-600">
          Start create snippets for your team.
        </p>
      </div>
      <div className="flex items-stretch justify-center gap-7">
        <button
          className="rounded-xl bg-base-white relative overflow-hidden w-82.5 border border-gray-300 group hover:border-custom-dark-green cursor-pointer hover:bg-gradient-primary-50-to-white-vertical"
          onClick={openCreateCategoryModal}
        >
          <div className="absolute bottom-0 right-0">
            <img
              src="../../../assets/images/bg-dots-part.svg"
              alt="background"
              className="block group-hover:hidden"
            />
            <img
              src="../../../assets/images/bg-dots-part-primary.svg"
              alt="background"
              className="hidden group-hover:block"
            />
          </div>
          <div className="relative h-full p-6 text-left">
            <Icon
              glyph={IconMap.LayoutAlt02}
              className="mb-4 text-gray-800 group-hover:text-primary-600"
            />
            <p className="mb-1 text-lg font-semibold text-gray-900">
              Create snippets categories
            </p>
            <p className="mb-4 text-sm font-normal text-gray-600">
              Begin by establishing categories of snippets for your team.
            </p>
            <span className="flex items-center justify-center px-4 py-2.25 rounded-md border border-gray-200 text-gray-700 bg-base-white w-fit shadow-xs">
              <p className="font-semibold text-md px-0.5">Create Categories</p>
            </span>
          </div>
        </button>

        {isCanCreatePersonalSnippets && (
          <div className="rounded-xl bg-base-white relative overflow-hidden w-82.5 border border-gray-300 group hover:border-custom-dark-green sm:hidden hover:bg-gradient-primary-50-to-white-vertical">
            <div className="absolute bottom-0 right-0">
              <img
                src="../../../assets/images/bg-cells-part.svg"
                alt="background"
                className="block group-hover:hidden"
              />
              <img
                src="../../../assets/images/bg-cells-part-primary.svg"
                alt="background"
                className="hidden group-hover:block"
              />
            </div>
            <div className="relative h-full p-6 text-left">
              <Icon
                glyph={IconMap.Snippets}
                className="mb-4 text-gray-800 group-hover:text-primary-600"
              />
              <p className="mb-1 text-lg font-semibold text-gray-900">
                Create personal snippet
              </p>
              <p className="mb-4 text-sm font-normal text-gray-600">
                Only you can view this snippet for your personal use.
              </p>
              <CreateSnippetButton isEmptyPage />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
