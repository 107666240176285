import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DBUser, User } from '../../../shared/types';
import { globalActions } from '../../../app/model/actions';

type State = {
  firebaseUser?: User;
  user?: DBUser;
  authToken?: string;
  authProviders: string[];
};
const initialState: State = {
  firebaseUser: undefined,
  user: undefined,
  authToken: undefined,
  authProviders: [],
};
export const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      { payload: firebaseUser }: PayloadAction<User | undefined>
    ) => ({
      ...state,
      firebaseUser,
    }),
    setDBUser: (
      state,
      { payload: user }: PayloadAction<DBUser | undefined>
    ) => ({
      ...state,
      user,
    }),
    setAuthToken: (
      state,
      { payload: authToken }: PayloadAction<string | undefined>
    ) => ({ ...state, authToken }),
    setAuthProviders: (
      state,
      { payload: authProviders }: PayloadAction<string[]>
    ) => ({ ...state, authProviders }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
