import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Link } from '../../processes/navigation';
import { INDEX_ROUTE } from '../../entities/history';
import { Icon } from '../../shared/ui';
import { IconMap } from '../../shared/sprite';

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet title="Privacy Policy" />
      <div className="flex flex-grow flex-col items-center">
        <header className="flex flex-col items-center border-b border-r-gray-200 w-full h-16 py-4 md:px-8 sm:px-4">
          <div className="max-w-304.5 w-full">
            <Link to={INDEX_ROUTE}>
              <Icon glyph={IconMap.DistributeLogo} />
            </Link>
          </div>
        </header>
        <div className="max-w-200 md:px-8 sm:px-4">
          <h1 className="text-display-lg font-display font-semibold text-gray-900 text-center py-24 tracking-tight md:text-display-md md:py-16">
            Privacy Policy
          </h1>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            This privacy policy ("Privacy Policy") explains how Distribute Labs,
            Inc. ("we," "us," or "our") collects, uses, and shares information
            about you when you use our software application (the "Application").
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            1. Information We Collect
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We collect information about you when you use the Application. This
            includes:
          </p>
          <ul className="list-disc pl-6 pt-5 text-lg font-normal text-gray-600 md:text-base">
            <li>
              Personal information: This is information that identifies you or
              can be used to identify you, such as your name, email address, and
              phone number.
            </li>
            <li>
              Usage data: This is information about how you use the Application,
              including the features you access and the actions you take.
            </li>
            <li>
              Device information: This is information about the device you use
              to access the Application, including the device's IP address,
              operating system, and browser type.
            </li>
            <li>
              Location information: This is information about your location,
              including your IP address, that may be collected when you use the
              Application.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            2. How We Use Your Information
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We use the information we collect about you to operate, improve, and
            personalize the Application. This includes using the information to:
          </p>
          <ul className="list-disc pl-6 pt-5 text-lg font-normal text-gray-600 md:text-base">
            <li>Provide, maintain, and improve the Application</li>
            <li>Communicate with you about the Application and your account</li>
            <li>Respond to your requests and inquiries</li>
            <li>Protect the security and integrity of the Application</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            3. Sharing Your Information
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We do not sell, rent, or otherwise disclose your personal
            information to third parties for their marketing purposes. We may
            share your information with third parties in the following
            circumstances:
          </p>
          <ul className="list-disc pl-6 pt-5 text-lg font-normal text-gray-600 md:text-base">
            <li>
              With your consent: We may share your information with third
              parties if you have given us your consent to do so.
            </li>
            <li>
              With service providers: We may share your information with
              third-party service providers who perform services on our behalf,
              such as hosting and maintenance, analytics, customer service, and
              marketing. These service providers are required to maintain the
              confidentiality of your information and are not permitted to use
              it for any other purpose.
            </li>
            <li>
              For legal reasons: We may share your information if we believe it
              is necessary to: (a) comply with a legal obligation, (b) protect
              the rights, property, or safety of Distribute Labs, Inc., our
              users, or the public, or (c) enforce the terms of service.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            4. Your Choices
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            You have the following choices regarding the information we collect
            and how we use it:
          </p>
          <ul className="list-disc pl-6 pt-5 text-lg font-normal text-gray-600 md:text-base">
            <li>
              Opting out of marketing communications: You can opt out of
              receiving marketing communications from us by following the
              unsubscribe instructions in the communication. Please note that
              you may still receive non-marketing communications from us, such
              as updates about your account or the Application.
            </li>
            <li>
              Accessing and correcting your information: You have the right to
              access and correct your personal information at any time. You can
              update your information in the Application or by contacting us at{' '}
              <a
                className="text-primary-700 underline"
                href="mailto:hello@distribute.so"
              >
                hello@distribute.so
              </a>
              .
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            5. Data Retention
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We will retain your information for as long as your account is
            active or as needed to provide you with the Application. We may also
            retain and use your information to comply with legal obligations,
            resolve disputes, and enforce our agreements.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            6. Data Security
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We have implemented reasonable and appropriate security measures to
            protect the information we collect from unauthorized access, use, or
            disclosure. However, no method of transmission over the internet or
            method of electronic storage is completely secure, and we cannot
            guarantee the security of your information.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            7. Changes to This Privacy Policy
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            We may update this Privacy Policy from time to time to reflect
            changes to our practices or for other operational, legal, or
            regulatory reasons. We will post any updates on this page and
            encourage you to review the Privacy Policy regularly for any
            changes.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            8. Contact Us
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            If you have any questions or concerns about our collection, use, or
            sharing of your information, or if you would like to exercise any of
            your choices, please contact us at{' '}
            <a
              className="text-primary-700 underline"
              href="mailto:legal@distribute.so"
            >
              legal@distribute.so
            </a>
            . You may also file a complaint with the appropriate regulatory
            authority if you believe that we have violated your privacy rights.
          </p>
        </div>

        <footer className="text-gray-500 border-t border-r-gray-200 pt-16 pb-12 mt-24 w-full text-center md:pt-12 md:mt-16">
          © 2023 Distribute. All rights reserved.
        </footer>
      </div>
    </>
  );
};
