import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { history } from '../../../entities/history';
import {
  actions,
  confirmNavigation,
  navigate,
  NavigationActionHandler,
  NavigationHandler,
} from './reducer';
import { selectNavigationHandlers } from './selectors';
import { QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE } from '../../../features/public-template';
import { getQueryParam } from '../../../shared/lib';

const queryParamsNeedToKeep = [QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE];

function* navigateToSaga({ payload }: ReturnType<typeof navigate>) {
  const { force, state, isKeepQueryParams = true } = payload;
  let { to } = payload;

  if (isKeepQueryParams) {
    const [toPathname, toQueryString] = to.split('?');
    if (toQueryString) {
      const newQueryParams = new URLSearchParams(toQueryString);

      queryParamsNeedToKeep.forEach((param) => {
        const value = getQueryParam(param);

        if (value && !newQueryParams.has(param)) {
          newQueryParams.set(param, value);
        }
      });

      to = `${toPathname}?${newQueryParams.toString()}`;
    } else {
      to = `${toPathname}${window.location.search}`;
    }
  }

  if (force) {
    yield call(history.push.bind(null, to, state));
    yield put(actions.reset());
    return;
  }

  const navigationHandlers: NavigationHandler[] = yield select(
    selectNavigationHandlers
  );

  const needPrevent = navigationHandlers.some((i) => i.type === 'prevent');
  const actionsHandlers = navigationHandlers.filter(
    (i) => i.type === 'action'
  ) as NavigationActionHandler[];

  if (needPrevent) {
    yield put(actions.setIsModalOpened(true));
    const { payload: agree } = (yield take(confirmNavigation)) as ReturnType<
      typeof confirmNavigation
    >;

    yield put(actions.setIsModalOpened(false));
    if (!agree) {
      return;
    }
  }

  yield all(actionsHandlers.map((a) => call(a.action)));

  yield call(history.push.bind(null, to, state));
}

export function* navigationWorker() {
  yield takeEvery(navigate, navigateToSaga);
}
