import { useContext } from 'react';
import { PlayerContext } from '../provider/PlayerContext';

export const usePlayer = () => {
  const context = useContext(PlayerContext);

  if (context === null) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }

  return context;
};
