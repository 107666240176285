import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

export const Loader: FC<{ isFullScreen?: boolean; size?: 'sm' | 'lg' }> = ({
  isFullScreen = true,
}) => {
  return (
    <>
      {isFullScreen && (
        <Helmet titleTemplate="Distribute" defaultTitle="Distribute" />
      )}
      <div className="flex gap-3 items-center justify-center w-full h-full rounded-lg">
        {[1, 2, 3].map((i) => (
          <span
            key={i}
            className={`w-4 h-4 flex rounded-full loading-dot bg-primary-600 loading-dot--${i}`}
          ></span>
        ))}
      </div>
    </>
  );
};
