import { api } from './instance';
import {
  CreateDataEmbeddingResponseType,
  CreateDataEmbeddingRequestType,
  GetDataEmbeddingStatusResponseType,
} from '@distribute/shared/api-types/data-embeddings';

export const dataEmbeddingsApi = {
  async createEmbedding({ context, teamId }: CreateDataEmbeddingRequestType) {
    const { data } = await api.post<CreateDataEmbeddingResponseType>(
      'data-embeddings',
      {
        context,
        teamId,
      }
    );
    return data;
  },

  async getDataEmbeddingStatus(namespace: string) {
    const { data } = await api.get<GetDataEmbeddingStatusResponseType>(
      `data-embeddings/${namespace}`
    );
    return data.ready;
  },
};
