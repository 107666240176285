import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useIntersectionObserver } from '../../../../../shared/hooks/useIntersectionObserver';
import {
  isElementInViewport,
  scrollToElementBottom,
} from '../../../../../shared/lib';
import { useUserScrollHandler } from './useUserScrollHandler';
const AI_MENU_MAX_HEIGHT = 320;
const AI_SCROLL_OFFSET = 20;

export const useAIScroll = (
  isLoading: boolean,
  menuRef: MutableRefObject<HTMLDivElement | null>,
  searchFieldWrapperRef: MutableRefObject<HTMLDivElement | null>
) => {
  const rootElement = useMemo(() => document.getElementById('root'), []);

  const menuObserver = useIntersectionObserver(menuRef, {
    rootMargin: `0px 0px -${AI_MENU_MAX_HEIGHT}px`,
    freezeOnceVisible: true,
  });

  // Initial scroll if menu is not fit in viewport
  useEffect(() => {
    if (menuRef.current && menuObserver && !menuObserver.isIntersecting) {
      scrollToElementBottom(menuRef.current, AI_SCROLL_OFFSET);
    }
  }, [menuObserver, menuRef]);

  const prevLoadingValue: MutableRefObject<boolean | null> = useRef(null);

  // Scroll after writing is finished if menu is not fit in viewport
  useEffect(() => {
    if (prevLoadingValue.current !== null && !isLoading) {
      setTimeout(() => {
        if (menuRef.current && !isElementInViewport(menuRef.current)) {
          scrollToElementBottom(menuRef.current, AI_SCROLL_OFFSET);
        }
      }, 100);
    }
    prevLoadingValue.current = isLoading;
  }, [isLoading, menuRef]);

  // Scroll each 500 ms while writing
  useUserScrollHandler(
    () => {
      scrollToElementBottom(
        searchFieldWrapperRef.current as HTMLDivElement,
        AI_SCROLL_OFFSET
      );
    },
    Boolean(
      isLoading &&
        searchFieldWrapperRef.current &&
        !isElementInViewport(searchFieldWrapperRef.current)
    ),
    rootElement
  );
};
