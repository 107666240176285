type State = {
  start: boolean;
  finish: boolean;
  error: boolean;
  playing: boolean;
  playbackRate: number;
  volume: number;
  playedSeconds: number;
  loaded: number;
  duration: number;
  fullscreen: boolean;
};

const INITIAL_STATE: State = {
  start: false,
  finish: false,
  error: false,
  playing: false,
  playbackRate: 1,
  volume: 0.8,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  fullscreen: false,
};

export enum ActionKind {
  START = 'START',
  FINISH = 'FINISH',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  TOGGLE_PLAY = 'TOGGLE_PLAY',
  PLAYBACK_RATE = 'PLAYBACK_RATE',
  DURATION = 'DURATION',
  SEEK = 'SEEK',
  SEEK_LOADED = 'SEEK_LOADED',
  TOGGLE_VOLUME = 'TOGGLE_VOLUME',
  VOLUME = 'VOLUME',
  ERROR = 'ERROR',
  SET_FULLSCREEN = 'SET_FULLSCREEN',
}

export type Actions =
  | {
      type: ActionKind.START;
    }
  | {
      type: ActionKind.FINISH;
      payload: boolean;
    }
  | {
      type: ActionKind.PLAY;
    }
  | {
      type: ActionKind.PAUSE;
    }
  | {
      type: ActionKind.TOGGLE_PLAY;
    }
  | {
      type: ActionKind.PLAYBACK_RATE;
    }
  | {
      type: ActionKind.DURATION;
      payload: number;
    }
  | {
      type: ActionKind.SEEK;
      payload: number;
    }
  | {
      type: ActionKind.SEEK_LOADED;
      payload: number;
    }
  | {
      type: ActionKind.VOLUME;
      payload: number;
    }
  | {
      type: ActionKind.TOGGLE_VOLUME;
    }
  | {
      type: ActionKind.ERROR;
    }
  | {
      type: ActionKind.SET_FULLSCREEN;
      payload: boolean;
    };

const playbackRates = [0.5, 0.7, 1, 1.2, 1.5, 1.7, 2, 2.5];

const getNextPlaybackRate = (value: number) => {
  const index = playbackRates.findIndex((v) => v === value);
  return playbackRates[index + 1] || 0.5;
};

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionKind.START:
      return { ...state, start: true, playing: true };
    case ActionKind.FINISH:
      return { ...state, finish: action.payload };
    case ActionKind.PLAY:
      return { ...state, playing: true };
    case ActionKind.PAUSE:
      return { ...state, playing: false };
    case ActionKind.TOGGLE_PLAY:
      return { ...state, playing: !state.playing };
    case ActionKind.PLAYBACK_RATE:
      return {
        ...state,
        playbackRate: getNextPlaybackRate(state.playbackRate),
      };
    case ActionKind.DURATION:
      return { ...state, duration: action.payload };
    case ActionKind.SEEK:
      return { ...state, playedSeconds: action.payload };
    case ActionKind.SEEK_LOADED:
      return { ...state, loaded: action.payload };
    case ActionKind.VOLUME:
      return { ...state, volume: action.payload };
    case ActionKind.TOGGLE_VOLUME:
      return {
        ...state,
        volume: state.volume > 0 ? 0 : 0.8,
      };
    case ActionKind.ERROR:
      return { ...state, error: true };
    case ActionKind.SET_FULLSCREEN:
      return { ...state, fullscreen: action.payload };
    default:
      return state;
  }
};

export { reducer, INITIAL_STATE };
