import React from 'react';
import { ChangeBrandInfoForm } from './components';

export const TeamBrandSettings = () => {
  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Company information
      </h1>
      <ChangeBrandInfoForm />
    </div>
  );
};
