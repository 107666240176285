import { navigate } from '../../../../processes/navigation';
import { getQueryParam } from '../../../../shared/lib';
import { put } from 'redux-saga/effects';

export function* checkContinueUrl() {
  const continueUrlParam = getQueryParam('continueUrl');
  if (continueUrlParam) {
    yield put(navigate({ to: continueUrlParam, isKeepQueryParams: false }));
  }
}
