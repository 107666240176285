import { call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { Page, TeamFiltered } from '@distribute/shared/types';
import { pagesModel } from '../../../pages';
import { getFullPageUrl } from '../../../../shared/lib';
import { editorSidebarModel } from '..';
import { personalizedCoverApi } from '../../../../shared/api/axios/personalized-cover';
import { createNotification, snackbarModel } from '../../../snackbar';
import { customDomainsModel } from '../../../custom-domains';

type Props = {
  payload: string;
};

export function* generatePersonalizedLink({ payload: personName }: Props) {
  yield put(editorSidebarModel.actions.setPersonalizedLinkGenerating(true));

  try {
    const { domain }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const customDomain: string | undefined = yield select(
      customDomainsModel.selectors.selectVerifiedCustomDomain
    );

    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );

    const { pageSlug } = currentPage;

    const pageUrl = getFullPageUrl({
      slug: pageSlug,
      domain,
      customDomain,
    });

    const linkHash: string = yield call(
      personalizedCoverApi.createPersonalizedLink,
      currentPage.id,
      personName
    );

    const personalizedLink =
      pageUrl + `/${linkHash}/${encodeURIComponent(personName)}`;

    yield put(editorSidebarModel.actions.setPersonalizedLink(personalizedLink));
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to generate personalized link')
      )
    );
  } finally {
    yield put(editorSidebarModel.actions.setPersonalizedLinkGenerating(false));
  }
}
