import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

const styles = {
  base: 'font-base font-semibold whitespace-nowrap rounded-md flex flex-nowrap gap-x-1.5 items-center justify-center',
  variants: {
    secondary:
      'text-gray-700 bg-base-white hover:bg-gray-50 border border-gray-200 hover:border-gray-300',
    transparent:
      'text-base-white outline-base-white outline-1 hover:bg-base-white/10',
  },
  sizes: {
    sm: 'w-8 h-8 p-1 text-sm',
    sm1: 'h-9 py-2 px-3 text-sm',
    md: 'w-12 h-12 p-1.5 text-xl',
    md1: 'h-12 py-2 px-3 text-xl',
  },
} as const;

type Props = {
  size?: keyof typeof styles.sizes;
  variant?: keyof typeof styles.variants;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PlayerButton: FC<PropsWithChildren<Props>> = ({
  type = 'button',
  children,
  size,
  variant,
  className = '',
  ...props
}) => {
  return (
    <button
      className={cn(
        styles.base,
        size && styles.sizes[size],
        variant && styles.variants[variant],
        className
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};
