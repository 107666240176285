import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Saga } from '../../../shared/types';

export type NavigationPreventHandler = { id: string; type: 'prevent' };
export type NavigationActionHandler = {
  id: string;
  type: 'action';
  action: (() => void | Promise<void>) | Saga;
};

export type NavigationHandler =
  | NavigationActionHandler
  | NavigationPreventHandler;

export type NavigationAction = {
  to: string;
  force?: boolean;
  state?: unknown;
  isKeepQueryParams?: boolean;
};

type State = {
  handlers: NavigationHandler[];
  isModalOpened: boolean;
};

const initialState: State = {
  handlers: [],
  isModalOpened: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    addNavigationHandler: (
      state,
      { payload }: PayloadAction<NavigationHandler>
    ) => {
      const currentHandler = state.handlers.find((i) => i.id === payload.id);

      if (currentHandler) {
        return state;
      }
      return { ...state, handlers: [...state.handlers, payload] };
    },
    removeNavigationHandler: (
      state,
      { payload: id }: PayloadAction<string>
    ) => {
      return {
        ...state,
        handlers: state.handlers.filter((i) => i.id !== id),
      };
    },
    setIsModalOpened: (
      state,
      { payload: isModalOpened }: PayloadAction<boolean>
    ) => ({ ...state, isModalOpened }),
    reset: () => initialState,
  },
});

export const actions = {
  ...reducerActions,
};

export const navigate = createAction<NavigationAction>('navigation/navigate');
export const confirmNavigation = createAction<boolean>('navigation/confirm');
