import React, { FC } from 'react';
import cn from 'classnames';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

const styles = {
  sm: 'px-3 py-2 text-xs',
  md: 'px-4 py-3 text-md',
};

type Props = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  align?: 'center' | 'end' | 'start';
  alignOffset?: number;
  sideOffset?: number;
  hideArrow?: boolean;
  triggerClassNames?: string;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  size?: keyof typeof styles;
  className?: string;
  delayDuration?: number;
};
export const PlayerTooltip: FC<Props> = ({
  trigger,
  children,
  align,
  alignOffset,
  sideOffset,
  triggerClassNames,
  isOpen,
  onOpenChange,
  size,
  className = '',
  delayDuration = 0,
}) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={delayDuration}
        open={isOpen}
        onOpenChange={onOpenChange}
        disableHoverableContent={true}
      >
        <TooltipPrimitive.TooltipTrigger asChild>
          <div className={cn('flex', triggerClassNames)}>{trigger}</div>
        </TooltipPrimitive.TooltipTrigger>
        <TooltipPrimitive.Content
          className={cn(
            'select-none rounded-lg bg-gray-900 text-base-white mb-2 max-w-45 font-base font-semibold z-50 shadow-lg whitespace-nowrap flex flex-nowrap items-center gap-x-2',
            size && styles[size],
            className
          )}
          align={align}
          alignOffset={alignOffset}
          sideOffset={sideOffset}
        >
          {children}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
