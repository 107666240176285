import axios from 'axios';

import { DATA_PROCESSING_API_URL, auth } from '../../config';

export const api = axios.create({
  baseURL: DATA_PROCESSING_API_URL,
  timeout: 60000,
});

api.interceptors.request.use(async (config) => {
  const token = await auth.currentUser?.getIdToken();
  if (!token) {
    return config;
  }

  (config.headers as Record<string, string>)[
    'Authorization'
  ] = `Bearer ${token}`;
  return config;
});
