import { matchParams } from '../../../shared/lib';
import { pagesModel } from '../../../features/pages';
import { call } from 'redux-saga/effects';

export function* publicEditorPageSaga(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);
  yield call(
    pagesModel.sagas.redirectToPublicPage,
    params?.sequenceNumber ?? ''
  );
}
