import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '../../../../entities/auth-operation';
import {
  LEGAL_PRIVACY_POLICY_ROUTE,
  LEGAL_TERMS_OF_USE_ROUTE,
  LOGIN_ROUTE,
  redirectActions,
} from '../../../../entities/history';
import { Input, Button, Link } from '../../../../shared/ui';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getQueryParam } from '../../../../shared/lib';
import {
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../../../features/accept-team-invitation';

const MESSAGE_USER_ALREADY_EXISTS =
  'The email you entered is already associated with account. Try signing in or use a different email';

const validationSchema = object().shape({
  fullName: string().required('Input your full name').trim(),
  email: string()
    .email(
      'The email you entered seems invalid. Please check and enter a valid email address'
    )
    .required('Input your email'),
  password: string()
    .required('Input your password')
    .min(
      8,
      'Your password is too short. It should be at least 8 characters long'
    ),
});

type SignUpForm = {
  email: string;
  fullName: string;
  password: string;
};

const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const authLoading = useSelector(authModel.selectors.selectLoading);
  const isWaitingToRedirectAfterAuth = useSelector(
    authModel.selectors.selectIsWaitingToRedirectAfterAuth
  );
  const emailFromTeamOrPageInvitationLink = useSelector(
    authModel.selectors.selectEmailFromTeamOrPageInvitationLink
  );
  const teamInviteId = getQueryParam(QUERY_PARAM_TEAM_INVITE_ID);
  const pageInviteId = getQueryParam(QUERY_PARAM_PAGE_INVITE_ID);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email:
        teamInviteId || pageInviteId
          ? emailFromTeamOrPageInvitationLink
          : undefined,
    },
  });
  const fullNameFieldProps = register('fullName');
  const emailFieldProps = register('email');
  const isAuthErrorUserAlreadyExist = authError === MESSAGE_USER_ALREADY_EXISTS;
  const isSignUpBtnLoading = authLoading || isWaitingToRedirectAfterAuth;

  useEffect(
    () => () => {
      dispatch(
        authModel.actions.setEmailFromTeamOrPageInvitationLink(undefined)
      );
    },
    [dispatch]
  );

  const handleSubmitForm = (data: SignUpForm) => {
    if (isAuthErrorUserAlreadyExist) return;

    dispatch(
      authModel.actions.signUpClick({
        email: data.email,
        password: data.password,
        fullName: data.fullName,
      })
    );
  };

  const handleLoginClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dispatch(redirectActions.toLoginClick());
  };

  return (
    <>
      <form
        className="mb-6"
        onSubmit={handleSubmit(handleSubmitForm)}
        noValidate
      >
        <div className="mb-5">
          <Input
            {...fullNameFieldProps}
            type="text"
            autoComplete="name"
            label="Full Name"
            isError={!!errors.fullName}
            messageText={errors.fullName?.message}
          />
        </div>

        <div className="mb-5">
          <Input
            {...emailFieldProps}
            onChange={(e) => {
              emailFieldProps.onChange(e);
              if (isAuthErrorUserAlreadyExist) {
                dispatch(authModel.actions.setAuthError(''));
              }
            }}
            type="email"
            autoComplete="username"
            label="Email"
            isError={!!errors.email || isAuthErrorUserAlreadyExist}
            messageText={
              isAuthErrorUserAlreadyExist ? authError : errors.email?.message
            }
            disabled={!!emailFromTeamOrPageInvitationLink}
          />
        </div>

        <Input
          autoComplete="new-password"
          {...register('password')}
          type="password"
          label="Password"
          isError={!!errors.password}
          messageText={
            errors.password?.message || 'Password must be at least 8 characters'
          }
        />

        {!isAuthErrorUserAlreadyExist && authError && (
          <p className="mt-6 px-4 py-1 text-xs rounded bg-error-100 text-error-700">
            {authError}
          </p>
        )}

        <div className="mt-6">
          <Button
            variant="text"
            color="primary"
            disabled={isSignUpBtnLoading}
            loading={isSignUpBtnLoading}
            type="submit"
            fullWidth
          >
            Get Started
          </Button>
        </div>
      </form>

      <p className="text-sm font-medium text-gray-700 mt-6 text-center">
        By signing up, you acknowledge that you have read and understood, and
        agree to{' '}
        <Link
          target="_blank"
          rel="noreferrer"
          href={LEGAL_TERMS_OF_USE_ROUTE}
          size="medium"
        >
          Distribute's Terms
        </Link>{' '}
        and{' '}
        <Link
          target="_blank"
          rel="noreferrer"
          href={LEGAL_PRIVACY_POLICY_ROUTE}
          size="medium"
        >
          Privacy Policy
        </Link>
      </p>

      {!emailFromTeamOrPageInvitationLink && (
        <div className="flex justify-center items-center mt-6">
          <span className="text-sm text-gray-600 mr-1">Already a user?</span>
          <Link href={LOGIN_ROUTE} size="medium" onClick={handleLoginClick}>
            Log In
          </Link>
        </div>
      )}
    </>
  );
};

export default CreateAccountForm;
