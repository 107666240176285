import {
  APP_HOST,
  LINKEDIN_CLIENT_ID,
  PROTOCOL,
} from '../../../../../shared/config';
import { socialApi } from '../../../../../shared/api/axios/social';
import { call, put } from 'redux-saga/effects';

import { socialModel } from '../../../model';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { fetchLinkedinProfileData } from './fetchLinkedinProfileData';
import { GetLinkedinAuthInfoType } from './types';
import { setLinkedinAuthData } from './helpers';

export function* fetchLinkedinAuthTokenBasedOnCode(
  url: string,
  initialStateString: string
) {
  const urlParams = new URLSearchParams(url);
  const code: string = yield urlParams.get('code');
  const returnedStateString: string = yield urlParams.get('state');

  if (initialStateString !== returnedStateString) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to LinkedIn')
      )
    );
    throw new Error('Failed to connect to Linkedin');
  }

  const linkedinCallbackUrl = `${PROTOCOL}${APP_HOST}/linkedin-callback`;

  if (code) {
    const res: GetLinkedinAuthInfoType = yield call(
      socialApi.getLinkedinAccessToken,
      {
        code,
        redirectUri: encodeURIComponent(linkedinCallbackUrl),
        clientId: LINKEDIN_CLIENT_ID as string,
      }
    );
    if (res.access_token) {
      yield call(setLinkedinAuthData, res);
      yield call(fetchLinkedinProfileData);
      yield put(socialModel.actions.setIsLoggedIntoLinkedin(true));
    }
  }
}
