import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Folder,
  FolderTeamMembersPermission,
  UserPermissionToFolderRole,
} from '@distribute/shared/types';
import {
  Button,
  ComboBox,
  ComboBoxItem,
  Dropdown,
  Modal,
  SelectOptionUser,
} from '../../../../shared/ui';
import { teamsModel } from '../../../teams';
import { UserPermissionRow } from './UserPermissionRow';
import { TeamMembersPermissionRow } from './TeamMembersPermissionRow';
import { foldersModel } from '../../model';
import { useFolderPermissions } from '../../hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  folder: Folder;
};

export const ShareFolderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  folder,
}) => {
  const [inviteRole, setInviteRole] = useState(
    UserPermissionToFolderRole.EDITOR
  );
  const [invitedTeamMembers, setInvitedTeamMembers] = useState<ComboBoxItem[]>(
    []
  );
  const dispatch = useDispatch();
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const createUserPermissionIsLoading = useSelector(
    foldersModel.selectors.selectCreateUserPermissionIsLoading
  );
  const { isCanInviteMembers, isCanManageAccess } =
    useFolderPermissions(folder);

  const currentTeamMembersOptions = useMemo<ComboBoxItem[]>(
    () =>
      currentTeamMembers
        .filter(
          (teamMember) =>
            !teamMember.isWaitingForInviteAcceptance &&
            !folder.userPermissionToFolders.some(
              (i) => i.user.id === teamMember.user?.id
            )
        )
        .map(({ user }) => ({
          id: user?.id ?? '',
          value: user?.displayName ?? '',
          optionTitle: (
            <SelectOptionUser
              displayName={user?.displayName ?? ''}
              photoUrl={user?.photoUrl}
            />
          ),
          headerTitle: (
            <SelectOptionUser
              displayName={user?.displayName ?? ''}
              photoUrl={user?.photoUrl}
              avatarSize="xs"
            />
          ),
        })),
    [currentTeamMembers, folder.userPermissionToFolders]
  );

  const inviteRoles = useMemo(
    () => [
      {
        id: UserPermissionToFolderRole.EDITOR,
        label: 'Can Edit',
        onClick: () => setInviteRole(UserPermissionToFolderRole.EDITOR),
      },
      {
        id: UserPermissionToFolderRole.VIEWER,
        label: 'Can View',
        onClick: () => setInviteRole(UserPermissionToFolderRole.VIEWER),
      },
    ],
    []
  );

  const onModalClose = useCallback(() => {
    onClose();
    setInviteRole(UserPermissionToFolderRole.EDITOR);
    setInvitedTeamMembers([]);
  }, [onClose]);

  const handleUpdateTeamMembersPermission = useCallback(
    (permission: FolderTeamMembersPermission) => {
      dispatch(
        foldersModel.actions.updateTeamMemberPermission({
          permission,
          folderId: folder.id,
        })
      );
    },
    [dispatch, folder.id]
  );

  const handleCreateUserPermission = useCallback(() => {
    dispatch(
      foldersModel.actions.createUserPermission({
        folderId: folder.id,
        role: inviteRole,
        userIds: invitedTeamMembers.map((i) => i.id),
        cb: () => {
          setInvitedTeamMembers([]);
        },
      })
    );
  }, [dispatch, folder.id, inviteRole, invitedTeamMembers]);

  const handleUpdateUserPermission = useCallback(
    (role: UserPermissionToFolderRole, userPermissionId: number) => {
      dispatch(
        foldersModel.actions.updateUserPermission({
          role,
          userPermissionId,
          folderId: folder.id,
        })
      );
    },
    [dispatch, folder.id]
  );

  const handleDeleteUserPermission = useCallback(
    (userPermissionId: number) => {
      dispatch(
        foldersModel.actions.deleteUserPermission({
          userPermissionId,
          folderId: folder.id,
        })
      );
    },
    [dispatch, folder.id]
  );

  useEffect(() => {
    if (!isCanManageAccess) {
      onModalClose();
    }
  }, [isCanManageAccess, onModalClose]);

  return (
    <Modal
      isShowCloseIconInTitle
      onClose={onModalClose}
      isOpen={isOpen}
      title={`Share ${folder.title}`}
      isShowCancelButton={false}
      className="!w-160 sm:!w-full !pb-0"
    >
      {isCanInviteMembers && (
        <>
          <div className="flex align-top gap-x-3 sm:flex-col sm:gap-y-3">
            <ComboBox
              items={currentTeamMembersOptions}
              onChange={(members) => setInvitedTeamMembers(members)}
              value={invitedTeamMembers}
              placeholder="Members, comma separated"
            />
            <div className="flex gap-x-3 sm:justify-end">
              <Dropdown
                isModalMode={false}
                listStyles="shadow-lg"
                items={inviteRoles}
                currentItemId={inviteRole}
                itemStyles="!w-27 !min-w-27"
                triggerStyles="!w-30 !h-10 shrink-0 !text-sm !font-semibold !text-gray-600"
              />
              <Button
                color="primary"
                variant="text"
                size="md"
                className="shrink-0"
                onClick={handleCreateUserPermission}
                disabled={
                  createUserPermissionIsLoading ||
                  invitedTeamMembers.length === 0
                }
                loading={createUserPermissionIsLoading}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="h-0.25 border-t border-gray-200 mt-5 -mx-6"></div>
        </>
      )}
      <div className="flex flex-col gap-y-3 py-6 max-h-86 overflow-auto">
        <TeamMembersPermissionRow
          permission={folder.teamMembersPermission}
          onChangePermission={handleUpdateTeamMembersPermission}
        />
        {folder.userPermissionToFolders.map((item) => (
          <UserPermissionRow
            key={item.id}
            userPermission={item}
            folder={folder}
            onChangeUserPermission={handleUpdateUserPermission}
            onDeleteUserPermission={handleDeleteUserPermission}
          />
        ))}
      </div>
    </Modal>
  );
};
