import { put, select } from '@redux-saga/core/effects';

import { snackbarModel } from '..';
import { NotificationItem } from '../reducer';

export function* upsertNotification(
  action: ReturnType<typeof snackbarModel.actions.upsertNotificationAction>
) {
  const notifications: NotificationItem[] = yield select(
    snackbarModel.selectors.selectNotifications
  );
  const isNotificationExist = notifications.some(
    (item) => item.id === action.payload.id
  );

  if (isNotificationExist) {
    const newNotifications = notifications.map<NotificationItem>(
      (notification) =>
        notification.id === action.payload.id ? action.payload : notification
    );
    yield put(snackbarModel.actions.setNotifications(newNotifications));
  } else {
    yield put(
      snackbarModel.actions.setNotifications([...notifications, action.payload])
    );
  }
}
