import { call, fork, put, select, all } from 'redux-saga/effects';
import { templateEditorPageWorker } from './templateEditorPageWorker';
import { templatesModel } from '../..';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { matchParams } from '../../../../shared/lib';
import { redirect } from '../../../../entities/history';
import { FirebaseUser } from '../../../../shared/types';
import { authUserModel, getUserFromFb } from '../../../../entities/auth-user';
import {
  JoinPageResponse,
  websocketClient,
} from '../../../../entities/websocket-client';
import {
  setAlertBarData,
  setCTAData,
  setGatedContentData,
  setPopUpData,
  setRequireEmailToViewData,
  setSqueezePageData,
} from '../../../conversion-kit';

export function* templateEditorPageSaga(location: Location, route: string) {
  yield fork(templateEditorPageWorker);

  const user: FirebaseUser = yield getUserFromFb();
  const token: string = yield user.getIdToken();

  const templates: TemplateExtended[] = yield select(
    templatesModel.selectors.selectTemplates
  );

  const { pathname } = location;
  const params = matchParams(pathname, route);
  const currentTemplate = templates.find(
    (i) => params?.sequenceNumber === i.id.toString()
  );

  if (!currentTemplate) {
    yield call(redirect.toWorkspace);
    return;
  }

  yield all([
    call(setAlertBarData, currentTemplate.content.id),
    call(setPopUpData, currentTemplate.content.id),
    call(setCTAData, currentTemplate.content.id),
    call(setGatedContentData, currentTemplate.content.id),
    call(setSqueezePageData, currentTemplate.content.id),
    call(setRequireEmailToViewData, currentTemplate.content.id),
  ]);

  yield put(templatesModel.actions.setCurrentTemplate(currentTemplate));
  yield put(
    templatesModel.actions.setCurrentTemplateContent(
      currentTemplate.content.contentItems[0]
    )
  );
  yield put(authUserModel.actions.setAuthToken(token));

  const joinPageResponse: JoinPageResponse = yield websocketClient.joinPage(
    currentTemplate.id
  );
  yield put(templatesModel.actions.setEditorBlocked(!joinPageResponse.canEdit));
  if (!joinPageResponse.canEdit) {
    yield put(
      templatesModel.actions.setExistingEditorData({
        userEmail: joinPageResponse.userEmail,
        userName: joinPageResponse.userName,
      })
    );
  }
}
