import { socialApi } from '../../../../../shared/api/axios/social';
import { call, put } from 'redux-saga/effects';
import { LINKEDIN_CLIENT_ID } from '../../../../../shared/config';
import { LINKEDIN_TOKEN_STORAGE_KEY } from '../../../../../shared/constants';
import { socialModel } from '../..';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { removeLinkedinAuthData } from './helpers';

export function* revokeLinkedinToken() {
  const linkedinToken = localStorage.getItem(LINKEDIN_TOKEN_STORAGE_KEY);
  if (!linkedinToken) return;

  yield put(socialModel.actions.setIsRevokingLinkedinToken(true));

  try {
    yield call(
      socialApi.revokeLinkedinToken,
      linkedinToken,
      LINKEDIN_CLIENT_ID as string
    );
    yield call(removeLinkedinAuthData);
    yield put(socialModel.actions.setIsLoggedIntoLinkedin(false));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully disconnected from LinkedIn')
      )
    );
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to disconnect from LinkedIn')
      )
    );
  } finally {
    yield put(socialModel.actions.setIsRevokingLinkedinToken(false));
  }
}
