import { put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api';
import { Folder } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { handleError } from '../../../../utils/handleError';

export function* renameFolder({
  payload: { id, title, callback },
}: ReturnType<typeof foldersModel.actions.renameFolder>) {
  try {
    yield put(actions.setRenameFolderIsLoading(true));
    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );

    const folder: Folder = yield foldersApi.renameFolder(id, title);
    const newFolders = folders.map((i) => {
      if (i.id === folder.id) {
        return folder;
      }
      return i;
    });

    const currentFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );
    if (currentFolder) yield put(foldersModel.actions.setCurrentFolder(folder));

    yield put(foldersModel.actions.setFolders(newFolders));
    callback();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(e, 'Failed to rename the folder')
        )
      )
    );
  } finally {
    yield put(actions.setRenameFolderIsLoading(false));
  }
}
