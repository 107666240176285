import React, { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useDispatch } from 'react-redux';
import {
  SNIPPETS_ROUTE,
  SNIPPETS_TEAM_ROUTE,
  TEAM_SETTINGS_ROUTE,
  TEMPLATES_ROUTE,
  TEMPLATES_TEAM_ROUTE,
  QUICK_RECORDINGS_ROUTE,
  WORKSPACE_ROUTE,
  redirectActions,
  QUICK_RECORDINGS_MY_ROUTE,
} from '../../../entities/history';
import { matchPath, useLocation } from 'react-router-dom';
import { navigate } from '../../../processes/navigation';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../../entities/feature-flag';

export enum ItemId {
  EMPTY = '',
  ALL = 'all',
  ANALYTICS = 'analytics',
  LEADS = 'leads',
  SETTINGS = 'settings',
  QUICK_RECORDINGS = 'quick-recordings',
  TEMPLATES = 'templates',
  SNIPPETS = 'snippets',
}

type Item = {
  id: ItemId;
  label: string;
  icon: keyof typeof IconMap;
  badge?: ReactNode;
  onClick: () => void;
};

export const Navigation: FC = () => {
  const [current, setCurrent] = useState(ItemId.EMPTY);

  const location = useLocation();
  const dispatch = useDispatch();

  const recordsItem: Item[] = environment.featureFlags[FeatureFlags.RECORDS]
    ? [
        {
          id: ItemId.QUICK_RECORDINGS,
          label: 'Quick Recordings',
          icon: 'Records',
          onClick: () => {
            dispatch(
              navigate({
                to: QUICK_RECORDINGS_MY_ROUTE,
              })
            );
            setCurrent(ItemId.QUICK_RECORDINGS);
          },
        },
      ]
    : [];

  const snippetItem: Item[] = environment.featureFlags[FeatureFlags.SNIPPETS]
    ? [
        {
          id: ItemId.SNIPPETS,
          label: 'Snippets',
          icon: 'Snippets',
          onClick: () => {
            dispatch(
              navigate({
                to: SNIPPETS_TEAM_ROUTE,
              })
            );
            setCurrent(ItemId.SNIPPETS);
          },
        },
      ]
    : [];

  const items: Item[] = [
    {
      id: ItemId.ALL,
      label: 'All Pages',
      icon: 'Files',
      onClick: () => {
        dispatch(redirectActions.toWorkspace());
        setCurrent(ItemId.ALL);
      },
    },
    ...recordsItem,
    {
      id: ItemId.TEMPLATES,
      label: 'Templates',
      icon: 'Templates',
      onClick: () => {
        dispatch(
          navigate({
            to: TEMPLATES_TEAM_ROUTE,
          })
        );
        setCurrent(ItemId.TEMPLATES);
      },
    },
    ...snippetItem,
  ];

  const isAll = location.pathname === WORKSPACE_ROUTE;
  const isTeamSettings = matchPath(location.pathname, {
    path: TEAM_SETTINGS_ROUTE,
  });
  const isTemplates = matchPath(location.pathname, {
    path: TEMPLATES_ROUTE,
  });
  const isSnippets = matchPath(location.pathname, {
    path: SNIPPETS_ROUTE,
  });
  const isQuickRecordings = matchPath(location.pathname, {
    path: QUICK_RECORDINGS_ROUTE,
  });

  useEffect(() => {
    setCurrent(ItemId.EMPTY);
    isAll && setCurrent(ItemId.ALL);
    isTeamSettings && setCurrent(ItemId.SETTINGS);
    isTemplates && setCurrent(ItemId.TEMPLATES);
    isSnippets && setCurrent(ItemId.SNIPPETS);
    isQuickRecordings && setCurrent(ItemId.QUICK_RECORDINGS);
  }, [isTeamSettings, isAll, isTemplates, isSnippets, isQuickRecordings]);

  return (
    <div className="flex flex-col mb-5 px-4">
      {items.map((item) => (
        <div
          className={cn(
            'px-3 py-2 rounded-md flex flex-row gap-x-3 cursor-pointer group',
            {
              'hover:bg-light-3 focus:bg-light-5': item.id !== current,
              'bg-gray-200 hover:bg-gray-200 focus:bg-gray-400 focus:ring-4':
                item.id === current,
            }
          )}
          key={item.id}
          onClick={item.onClick}
        >
          <Icon
            glyph={IconMap[item.icon]}
            width={20}
            className={cn({
              'text-gray-500 group-hover:text-gray-600': item.id !== current,
              'text-gray-600': item.id === current,
            })}
          />
          <span
            className={cn('text-sm font-medium', {
              'text-gray-700 group-hover:text-gray-900': item.id !== current,
              'text-gray-800': item.id === current,
            })}
          >
            {item.label}
          </span>
          {item.badge}
        </div>
      ))}
    </div>
  );
};
