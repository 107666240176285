import React, { Dispatch, SetStateAction } from 'react';
import { TemplateSettingsForm } from './TemplateSettingsForm';
import { Button, Icon, Modal } from '../../../../shared/ui';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  handleUpdateTemplate: (saveAs: TemplateCreationPhase) => void;
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const SaveTemplateModal: React.FC<Props> = ({
  handleUpdateTemplate,
  isSaveModalOpen,
  setIsSaveModalOpen,
}) => {
  return (
    <Modal
      className="relative overflow-hidden !p-0 !pb-23.5 w-120 max-h-180"
      isOpen={isSaveModalOpen}
      onClose={() => {
        handleUpdateTemplate(TemplateCreationPhase.DRAFT);
        setIsSaveModalOpen(false);
      }}
      isShowCancelButton={false}
    >
      <div className="p-6 overflow-scroll max-h-157">
        <img
          src="../../../assets/images/bg-save-template-rect-part.svg"
          alt="background"
          className="absolute top-0 left-0"
        />
        <div className="relative z-1">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-gray-100 rounded-full">
              <Icon glyph={IconMap.Templates} className="text-gray-500" />
            </div>
            <Icon
              glyph={IconMap.XClose}
              className="text-gray-500 cursor-pointer shrink-0"
              width={24}
              onClick={() => {
                handleUpdateTemplate(TemplateCreationPhase.DRAFT);
                setIsSaveModalOpen(false);
              }}
            />
          </div>
          <p className="mb-1 font-medium font-display text-display-xs">
            Save Template
          </p>
          <p className="mb-5 text-sm text-gray-600">
            Configure template options and specify visibility.
          </p>
          <TemplateSettingsForm />
        </div>
        <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white">
          <Button
            fullWidth
            color="secondary"
            variant="text"
            type="button"
            onClick={() => {
              handleUpdateTemplate(TemplateCreationPhase.DRAFT);
              setIsSaveModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpdateTemplate(TemplateCreationPhase.READY_TO_USE);
            }}
            type="submit"
            color="primary"
            variant="text"
            fullWidth
          >
            Save template
          </Button>
        </div>
      </div>
    </Modal>
  );
};
