import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { TemplatesConfigEnum } from '../../lib';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { TemplateCategory } from '@distribute/shared/types';

type Props = {
  value: string;
  selected: boolean;
  onClick(): void;
  isEmpty?: boolean;
  itemKey: TemplatesConfigEnum;
  icon?: SpriteGlyph;
  amount?: number;
};

export const TemplatesNavigationItem: React.FC<Props> = ({
  value,
  selected,
  onClick,
  itemKey,
  icon,
  amount,
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (itemKey === TemplatesConfigEnum.ALL) {
      ref.current?.focus();
    }
  }, [itemKey]);

  return (
    <button
      autoFocus={selected}
      ref={ref}
      className={cn(
        'px-3 py-2 rounded-md text-sm font-medium w-full text-left flex items-center justify-between gap-x-3 group',
        {
          'bg-gray-200 hover:bg-gray-200 text-gray-800': selected,
          'text-gray-700 hover:bg-light-4 hover:text-gray-800': !selected,
        }
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-3">
        {icon && (
          <Icon
            glyph={icon}
            width={20}
            className={cn('', {
              'text-gray-600 group-hover:text-gray-600': selected,
              'text-gray-500 group-hover:text-gray-600': !selected,
            })}
          />
        )}
        <span className="truncate">{value}</span>
      </div>
      {Boolean(amount) && itemKey !== TemplateCategory.COMMUNITY && (
        <span>{amount}</span>
      )}
    </button>
  );
};
