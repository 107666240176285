import { JSONContent, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeView } from './NodeView';
import { ResizeableFigureExt } from '@distribute/shared/generate-html';

export type ResizeableFigureAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'float-left'
  | 'float-right';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    resizeableFigure: {
      setResizeableFigure: (options: {
        content: JSONContent[];
        position?: number;
        attrs?: {
          'data-media-width'?: number;
          'data-media-height'?: number;
        };
      }) => ReturnType;
      deleteResizeableFigure: () => ReturnType;
      setResizeableFigureAlign: (align: ResizeableFigureAlign) => ReturnType;
    };
  }
}

export const ResizeableFigureExtension = ResizeableFigureExt.extend({
  addNodeView() {
    return ReactNodeViewRenderer(NodeView);
  },

  addCommands() {
    return {
      setResizeableFigure:
        ({ content, position, attrs }) =>
        ({ commands }) => {
          if (position) {
            return commands.insertContentAt(position, {
              type: this.name,
              content,
              attrs,
            });
          }
          return commands.insertContent({
            type: this.name,
            content,
            attrs,
          });
        },
      deleteResizeableFigure:
        () =>
        ({ chain, state }) =>
          chain()
            .setNodeSelection(state.selection.from)
            .deleteSelection()
            .run(),
      setResizeableFigureAlign:
        (align: ResizeableFigureAlign) =>
        ({ commands }) =>
          commands.updateAttributes(this.name, { 'data-align': align }),
    };
  },
});
