import React, { FC, useCallback, useMemo } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Input, Button, Icon, LinkAsButton } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { pagesModel } from '../../pages';
import { ActionTypeCTA, CTA } from '@distribute/shared/types';
import { subscribePopupModel } from '../../subscribe-popup';
import { PagePermissions } from '../../pages/lib/getPagePermissions';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { ActionBannerCalendar } from './ActionBannerCalendar';
import { conversionKitModel } from '../../conversion-kit';
import { parseHtml } from '../../../shared/lib/parseHtml';

type ActionBannerForm = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

type Props = {
  cta: CTA;
  pagePermissions?: PagePermissions;
};

export const ActionBanner: FC<Props> = ({ cta, pagePermissions }) => {
  const dispatch = useDispatch();
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );
  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().when([], {
          is: () => cta.isFormName,
          then: string(),
          otherwise: string().nullable(),
        }),
        email: string().email().required(),
        phone: string().when([], {
          is: () => cta.isFormPhone,
          then: string(),
          otherwise: string().nullable(),
        }),
        message: string().when([], {
          is: () => cta.isFormMessage,
          then: string(),
          otherwise: string().nullable(),
        }),
      }),
    [cta]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ActionBannerForm>({
    resolver: yupResolver(validationSchema),
  });

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const setIsSubscribePopupOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(true));
  }, [dispatch]);

  const handleFormSubmit = (data: ActionBannerForm) => {
    return data;
  };

  const handleOpenCTASettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.CTA
      )
    );
  };

  const activeConversionSettingsPanel = useSelector(
    editorSidebarModel.selectors.selectActiveConversionSettingsPanel
  );

  const isCTAPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.CTA;
  const isCalendarActionType = cta.actionType === ActionTypeCTA.CALENDLY;
  if (!cta.isActive && !isCTAPanelOpen) {
    return null;
  }

  return (
    <div className="flex w-full pb-10.5 md:px-8 sm:px-4 max-w-212 mx-auto">
      <div
        className={classNames(
          `flex justify-center w-full bg-brand-900 rounded-lg group relative shadow-white hover:shadow-primary-600
              lg:mx-3 md:mx-0
              sm:h-fit-content sm:flex-col-reverse`,
          {
            'group-hover:flex hover:shadow-primary-600': !isEditorPreview,
            '!bg-transparent': cta.actionType === ActionTypeCTA.CALENDLY,
          }
        )}
      >
        {isCalendarActionType ? (
          <ActionBannerCalendar ctaData={cta} />
        ) : (
          <div className="shrink-0 max-w-120 w-full sm:max-w-full p-10 sm:p-6 flex flex-col gap-6">
            <div className="text-brand-text flex flex-col gap-2">
              <h3
                className={classNames(
                  'text-display-sm font-bold font-heading',
                  {
                    'opacity-50': !cta.title,
                  }
                )}
              >
                {cta.title || 'Title'}
              </h3>
              <div
                className={classNames('text-base font-paragraph', {
                  'opacity-50': !cta.description,
                })}
              >
                {cta.description ? parseHtml(cta.description) : 'Description'}
              </div>
            </div>

            {cta.actionType === ActionTypeCTA.FORM && (
              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="flex flex-col gap-2"
              >
                {cta.isFormName && (
                  <Input
                    {...register('name')}
                    placeholder="Name"
                    type="text"
                    isError={!!errors.name}
                    isBrandStyles
                  />
                )}
                <Input
                  {...register('email')}
                  placeholder="Email"
                  type="text"
                  isError={!!errors.email}
                  isBrandStyles
                />
                {cta.isFormPhone && (
                  <Input
                    {...register('phone')}
                    placeholder="Phone"
                    type="text"
                    isError={!!errors.phone}
                    isBrandStyles
                  />
                )}
                {cta.isFormMessage && (
                  <Input
                    {...register('message')}
                    placeholder="Message"
                    type="text"
                    isError={!!errors.message}
                    isBrandStyles
                  />
                )}
                <Button
                  fullWidth
                  type="submit"
                  color="brand"
                  variant="text"
                  fontBold
                >
                  {cta.buttonLabelFormType || 'Subscribe'}
                </Button>
              </form>
            )}

            {cta.actionType === ActionTypeCTA.LINK && (
              <LinkAsButton
                color="brand"
                fontBold
                href={cta.buttonUrl}
                target="_blank"
              >
                {cta.buttonLabelLinkType || 'Subscribe'}
              </LinkAsButton>
            )}

            {cta.actionType === ActionTypeCTA.POPUP && (
              <Button
                color="brand"
                variant="text"
                fontBold
                onClick={popUp.isActive ? setIsSubscribePopupOpen : undefined}
              >
                {cta.buttonLabelPopupType || 'Subscribe'}
              </Button>
            )}
          </div>
        )}
        {pagePermissions?.isCanEditConversionTools &&
          !isAnyConversionPanelOpen && (
            <button
              onClick={handleOpenCTASettings}
              className={classNames(
                'bg-primary-600 pl-0.5 text-base-white w-9 h-9 items-center justify-center rounded-full border-none hover:bg-primary-700 cursor-pointer absolute -top-4 right-28 hidden',
                {
                  'group-hover:flex': !isEditorPreview,
                }
              )}
            >
              <Icon glyph={IconMap.Edit2Icon} width={20} />
            </button>
          )}
        {cta.imageUrl && !isCalendarActionType && (
          <div>
            <img
              className="h-full sm:max-h-42 w-full rounded-tr-lg rounded-br-lg sm:rounded-tl-lg sm:rounded-br-none object-cover"
              src={cta.imageUrl}
              alt="CTA background"
            />
          </div>
        )}
      </div>
    </div>
  );
};
