import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 } from 'uuid';

import { createTypesRegex, parseFileFromUrl } from '../../../../shared/lib';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';

import { TabContent, Tabs } from '../../../../shared/ui';
import { UploadInput } from './UploadInput';

import { mediaUploadModel } from '../../../../features/media-upload';
import { UPLOAD_FORM_WIDTH, uploadingConfig } from './config';
import { UploadFormEmbed } from './UploadFormEmbed';

type UploadedData = {
  fileUrl: string;
  fileName?: string;
  fileSize?: number;
  fileType?: string;
};

type Props = {
  onClose: () => void;
  onUploaded: (data: UploadedData) => void;
};

const tabs: TabContent[] = [
  {
    name: 'upload',
    title: 'Upload File',
  },
  {
    name: 'embed',
    title: 'Embed File',
  },
];

const trimType = (type: string) => type.replace('application/', '');

export const UploadFile: FC<Props> = ({ onClose, onUploaded }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [id, setNewId] = useState(() => v4());
  const [selectedTab, setSelectedTab] = useState<TabContent>(tabs[0]);

  const dispatch = useDispatch();

  const file = useSelector(mediaUploadModel.selectors.selectFile(id));

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    if (file?.status === 'error') {
      setNewId(v4());
    }
  }, [file?.status]);

  const onSubmit = async (fileUrl: string) => {
    const [fileName, fileType] = parseFileFromUrl(fileUrl);
    onUploaded({ fileUrl, fileName, fileType });
  };

  const {
    accept,
    allowedTypes,
    viewAllowedTypes,
    inputMessage,
    regexpType,
    maxSize,
  } = uploadingConfig.file;

  return (
    <div className="w-screen border-0 shadow-transparent bg-transparent flex justify-left z-10">
      <div
        style={{ maxWidth: UPLOAD_FORM_WIDTH }}
        className="w-full"
        tabIndex={1}
      >
        <div
          ref={ref}
          className="bg-base-white rounded-lg border border-gray-200"
        >
          <Tabs
            className="!mb-0 pt-4 px-4"
            tabs={tabs}
            selectedTab={selectedTab}
            onSelectTab={(tab) => setSelectedTab(tab as TabContent)}
          >
            <div className="p-4">
              {selectedTab.name === 'upload' && (
                <UploadInput
                  progress={file?.progress}
                  isUploading={file?.status === 'uploading'}
                  onUpload={(uploadedFile) =>
                    dispatch(
                      mediaUploadModel.actions.uploadFile({
                        file: uploadedFile,
                        id,
                        cb: (fileUrl, fileName) =>
                          onUploaded({
                            fileUrl,
                            fileName,
                            fileSize: uploadedFile.size,
                            fileType: trimType(uploadedFile.type),
                          }),
                      })
                    )
                  }
                  allowedTypes={viewAllowedTypes}
                  validationRegexp={createTypesRegex(allowedTypes, regexpType)}
                  accept={accept}
                  maxSize={maxSize}
                />
              )}
              {selectedTab.name === 'embed' && (
                <UploadFormEmbed
                  onSubmit={onSubmit}
                  message="Embed File"
                  placeholder="Paste URL of file"
                  label={inputMessage}
                />
              )}
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
