import { FC } from 'react';

export const FileSnippet: FC<{ url: string }> = ({ url }) => (
  <div className="w-43.5 shadow-lg bg-base-white rounded-t-lg top-9.5 relative h-36">
    <div className="w-42.5 shadow-drop bg-base-white rounded-t-lg -top-2.5 relative h-full">
      <div className="w-41.5 shadow-md bg-base-white rounded-t-lg -top-2.5 relative h-full">
        <iframe
          src={url}
          className="w-full h-full rounded-t-lg "
          title="snippet file"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-transparent border-2 rounded-t-lg border-base-white" />
      </div>
    </div>
  </div>
);
