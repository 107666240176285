import cn from 'classnames';
import { FC } from 'react';

type Props = {
  selected?: boolean;
  disabled?: boolean;
  value: number;
};

export const TabCounter: FC<Props> = ({
  selected = false,
  disabled = false,
  value,
}) => {
  return (
    <div
      className={cn(
        'w-4 h-4 flex items-center justify-center rounded-full text-xxs font-bold text-base-white mr-2',
        {
          'bg-primary-600': selected,
          'bg-gray-500': !selected,
          'group-hover:bg-primary-700': !disabled,
          'opacity-60': disabled,
        }
      )}
    >
      {value}
    </div>
  );
};
