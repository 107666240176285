import { useEffect, useRef } from 'react';
import { FONTS } from '../../features/fonts-selector/config';

const useSetFontFamily = (
  value: string,
  propertyName: string,
  propertyNamePrev: string,
  ref?: React.MutableRefObject<HTMLDivElement | null>
) => {
  const prevValue = useRef(value);

  useEffect(() => {
    const element = ref?.current || document.body;

    element.style.setProperty(
      propertyName,
      FONTS[value as keyof typeof FONTS].label
    );
    element.style.setProperty(
      propertyNamePrev,
      FONTS[prevValue.current as keyof typeof FONTS].label
    );

    return () => {
      prevValue.current = value;

      element.style.removeProperty(propertyName);
      element.style.removeProperty(propertyNamePrev);
    };
  }, [value, propertyName, propertyNamePrev, ref]);
};

export default useSetFontFamily;
