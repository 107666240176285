import { call } from 'redux-saga/effects';

import { authActionHandler } from '../../../../features/auth/code-operation';
import { history } from '../../../history';

export function* authAction() {
  const { search } = history.location;

  yield call(authActionHandler, { search });
}
