import React from 'react';
import { Dialog } from '@headlessui/react';
import { Button } from './Button';
import classNames from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Props = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
  actionButton?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  isShowCancelButton?: boolean;
  cancelButtonName?: string;
  className?: string;
  isShowCloseIconInTitle?: boolean;
  isActionButtonsAlignEnd?: boolean;
  clearPadding?: boolean;
  dialogClassName?: string;
  cancelButtonClassName?: string;
  titleClassName?: string;
  panelWrapperStyles?: { [key: string]: string };
  disableCloseByOutsideClick?: boolean;
};

export const Modal: React.FC<Props> = ({
  title,
  description,
  children,
  isOpen,
  onClose,
  actionButton,
  isShowCancelButton = true,
  cancelButtonName,
  className,
  isShowCloseIconInTitle,
  isActionButtonsAlignEnd,
  clearPadding,
  dialogClassName,
  cancelButtonClassName,
  titleClassName,
  panelWrapperStyles,
  disableCloseByOutsideClick = false,
}) => {
  return (
    <Dialog
      className={classNames('relative z-50 w-full', dialogClassName)}
      open={isOpen}
      onClose={() => {
        !disableCloseByOutsideClick && onClose();
      }}
    >
      <div
        style={panelWrapperStyles}
        className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200"
      >
        <Dialog.Panel
          className={classNames(
            'sm:w-full w-100 shadow-xl rounded-xl bg-base-white',
            className,
            { 'p-6': !clearPadding }
          )}
        >
          {title && (
            <>
              <Dialog.Title
                className={classNames(
                  'flex justify-between items-center flex-shrink-0 text-gray-900 text-display-xs font-display font-medium w-full truncate',
                  {
                    'mb-4': !description,
                    'mb-1': description,
                  }
                )}
              >
                <span
                  className={classNames(
                    'truncate max-w-full',
                    {
                      'max-w-[calc(100%-40px)]': isShowCloseIconInTitle,
                    },
                    titleClassName
                  )}
                >
                  {title}
                </span>
                {isShowCloseIconInTitle && (
                  <Icon
                    glyph={IconMap.XClose}
                    className="text-gray-400 cursor-pointer shrink-0"
                    width={24}
                    onClick={onClose}
                  />
                )}
              </Dialog.Title>
              {description && (
                <Dialog.Description className="mb-5 text-gray-600 text-sm">
                  {description}
                </Dialog.Description>
              )}
            </>
          )}
          {children}
          {(isShowCancelButton || actionButton) && (
            <div
              className={classNames(
                'flex pt-8 gap-3',
                actionButton && !isActionButtonsAlignEnd
                  ? 'justify-between'
                  : 'justify-end'
              )}
            >
              {isShowCancelButton && (
                <Button
                  fullWidth={!!actionButton && !isActionButtonsAlignEnd}
                  className={classNames(
                    !actionButton && 'min-w-42.5',
                    cancelButtonClassName
                  )}
                  color="secondary"
                  variant="text"
                  type="button"
                  onClick={onClose}
                >
                  {cancelButtonName ?? `Cancel`}
                </Button>
              )}
              {actionButton}
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
