import React, { FC } from 'react';
import cn from 'classnames';

import { CalendarProvider, PopUp } from '@distribute/shared/types';
import { CalendarPreview, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { parseHtml } from '../../../../shared/lib/parseHtml';

type IProps = {
  popupData: PopUp;
};

export const PopUpCalendar: FC<IProps> = ({ popupData }) => {
  const {
    calendarProvider,
    calendarSchedulingLink,
    calendarCalendlyBgColor,
    calendarCalendlyButtonColor,
    calendarCalendlyTextColor,
    calendarIsShowCalendlyCookieBanner,
    calendarIsShowCalendlyPageDetails,
    title,
    description,
  } = popupData;

  if (calendarSchedulingLink) {
    return (
      <div className="flex flex-col gap-2 h-160 rounded-lg overflow-hidden">
        {(title !== null || description !== null) && (
          <div className="flex flex-col gap-2 py-4">
            {title !== null && (
              <div
                className={cn(
                  'text-gray-900 text-display-sm text-center font-bold',
                  {
                    'opacity-50': !title,
                  }
                )}
              >
                {title || 'Title'}
              </div>
            )}
            {description !== null && (
              <div
                className={cn('text-md text-gray-900 text-center', {
                  'opacity-50': !description,
                })}
              >
                {description ? parseHtml(description) : 'Description'}
              </div>
            )}
          </div>
        )}
        <CalendarPreview
          calendarProvider={calendarProvider}
          calendarSchedulingLink={calendarSchedulingLink}
          calendarCalendlyBgColor={calendarCalendlyBgColor}
          calendarCalendlyButtonColor={calendarCalendlyButtonColor}
          calendarCalendlyTextColor={calendarCalendlyTextColor}
          calendarIsShowCalendlyCookieBanner={
            calendarIsShowCalendlyCookieBanner
          }
          calendarIsShowCalendlyPageDetails={calendarIsShowCalendlyPageDetails}
        />
      </div>
    );
  }

  return (
    <div className="flex p-4 h-160">
      <div className="flex items-center justify-center grow-1 rounded-lg bg-gray-100">
        <Icon
          glyph={
            calendarProvider === CalendarProvider.CALENDLY
              ? IconMap.CalendlyLogo
              : IconMap.ChiliPiperLogo
          }
        />
      </div>
    </div>
  );
};
