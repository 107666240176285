export type RequireEmailToView = {
  id: string;
  isActive: boolean;
  isAuthEnabled: boolean;
  hasRestrictions: boolean;
  restrictions: Restriction;
};

export enum RestrictionType {
  Allowed = 'allowed',
  Blocked = 'blocked',
}

export enum RestrictionCriteria {
  Email = 'email',
  Domain = 'domain',
}

export type RestrictionDetails = {
  criteria: RestrictionCriteria;
  value: string;
};

export type Restriction = {
  type: RestrictionType;
  list: Array<RestrictionDetails>;
};
