import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';
import { ToggleListItemContentNodeView } from './ToggleListItemContent.renderer';
import { ToggleListItemContentExt } from '@distribute/shared/generate-html';

export const ToggleListItemContent = ToggleListItemContentExt.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ class: 'toggle-list-item-content' }, HTMLAttributes),
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ToggleListItemContentNodeView);
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const { $anchor, $from } = this.editor.state.selection;
        const currentPos = $anchor.pos;
        let isEnterPressedInToggleItemContentNode = false;

        this.editor.state.selection.ranges.forEach((range) => {
          this.editor.state.doc.nodesBetween(
            range.$from.pos,
            range.$to.pos,
            (node, pos) => {
              if (node.type.name === 'toggleListItemContent') {
                if (currentPos >= pos && currentPos <= pos + node.nodeSize) {
                  isEnterPressedInToggleItemContentNode = true;
                }
              }
            }
          );
        });

        if (
          $from.parent.content.size === 0 &&
          isEnterPressedInToggleItemContentNode
        ) {
          this.editor.commands.insertContentAt($from.pos + 1, {
            type: 'paragraph',
            content: [],
          });

          return true;
        }

        return false;
      },
    };
  },
});
