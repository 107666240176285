import React, { FC, useMemo } from 'react';
import { PageTeamMembersPermission } from '@distribute/shared/types';
import { Avatar, Dropdown } from '../../../../shared/ui';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../teams';

type IProps = {
  permission: PageTeamMembersPermission;
  onChangePermission: (permission: PageTeamMembersPermission) => void;
};

export const TeamMembersPermissionRow: FC<IProps> = ({
  permission,
  onChangePermission,
}) => {
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const options = useMemo(
    () => [
      {
        id: PageTeamMembersPermission.INVITE_ONLY,
        label: 'Invite Only',
        onClick: () =>
          onChangePermission(PageTeamMembersPermission.INVITE_ONLY),
      },
      {
        id: PageTeamMembersPermission.EDIT,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">Edit</p>
            <p className="text-xs text-gray-500">
              Can view, share, edit, but not manage.
            </p>
          </div>
        ),
        headerLabel: 'Can edit',
        onClick: () => onChangePermission(PageTeamMembersPermission.EDIT),
      },
      {
        id: PageTeamMembersPermission.VIEW,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">View</p>
            <p className="text-xs text-gray-500">Can view only</p>
          </div>
        ),
        headerLabel: 'Can view',
        onClick: () => onChangePermission(PageTeamMembersPermission.VIEW),
      },
    ],
    [onChangePermission]
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        <Avatar
          displayName={currentTeam.name}
          src={currentTeam.brandLogoUrl ?? undefined}
          size="md"
          theme="secondary-rect"
          className="shrink-0"
        />
        <div className="overflow-hidden">
          <p className="text-sm text-gray-700 font-medium">Team members</p>
          <p className="text-xs text-gray-500 truncate">
            {currentTeam.teamMembersCount}{' '}
            {currentTeam.teamMembersCount > 1 ? 'members' : 'member'}
          </p>
        </div>
      </div>

      <Dropdown
        isModalMode={false}
        listStyles="shadow-lg w-90"
        items={options}
        currentItemId={permission}
        triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
      />
    </div>
  );
};
