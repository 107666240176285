import cn from 'classnames';
import {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';
import { IconMap } from '../sprite';
import { Icon } from './Icon';

type Props = {
  checked?: boolean;
  isError?: boolean;
  children?: ReactNode;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, Props>(function Checkbox(
  { checked, isError, onChange, children, className, ...rest },
  ref
) {
  const inputStyle = `appearance-none content-checked w-4 h-4 bg-base-white border rounded
    focus:outline-none focus:border-primary-600 cursor-pointer shrink-0`;

  const onFieldChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e);
  };

  return (
    <label
      className={cn('relative flex items-center cursor-pointer', className)}
    >
      <div className="relative h-5 flex items-center">
        <input
          type="checkbox"
          ref={ref}
          onChange={onFieldChange}
          checked={checked}
          {...rest}
          className={cn(inputStyle, {
            'border-gray-300': !checked,
            'bg-gray-50': rest.disabled,
            'bg-primary-600 border-primary-600': checked && !rest.disabled,
            '!border-error-300': isError,
          })}
        />
        {checked && (
          <div
            className={cn('absolute left-0.5 top-1 pointer-events-none', {
              'text-base-white': !rest.disabled,
              'text-gray-300': rest.disabled,
            })}
          >
            {checked && <Icon glyph={IconMap.Check} />}
          </div>
        )}
      </div>

      {children}
    </label>
  );
});
