import { put, select } from 'redux-saga/effects';
import { UpdateUserResponseType } from '@distribute/shared/api-types/auth';
import { authApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { authUserModel } from '../../../../entities/auth-user';
import { DBUser } from '../../../../shared/types';

export function* changeFullName({
  payload: { fullName, cb },
}: ReturnType<typeof actions.changeFullNameClick>) {
  try {
    yield put(actions.setIsChangeFullNameLoading(true));

    const user = (yield select(
      authUserModel.selectors.selectUserWithError
    )) as DBUser;
    if (user.displayName === fullName) {
      return;
    }

    const { displayName } = (yield authApi.updateProfile({
      displayName: fullName,
    })) as UpdateUserResponseType;

    yield put(authUserModel.actions.setDBUser({ ...user, displayName }));
    cb();
  } catch (error: unknown) {
    yield put(actions.setChangeFullNameError((error as Error).message));
  } finally {
    yield put(actions.setIsChangeFullNameLoading(false));
  }
}
