import { put, select } from 'redux-saga/effects';
import { pagesModel } from '..';
import { pagesApi } from '../../../../shared/api';
import { Page } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* deleteUserPermission({
  payload: { pageId, userPermissionId },
}: ReturnType<typeof pagesModel.actions.deleteUserPermission>) {
  try {
    const page: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    yield pagesApi.deleteUserPermission(pageId, userPermissionId);
    yield put(
      pagesModel.actions.setCurrentPage({
        ...page,
        userPermissionToPages: page.userPermissionToPages.filter(
          (i) => i.id !== userPermissionId
        ),
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete user permissions')
      )
    );
  }
}
