import React, { ReactNode } from 'react';
import cn from 'classnames';
import { IconMap } from '../sprite';
import { Icon } from './Icon';

export type NotificationType = 'error' | 'success' | 'warning';

export const Notification = ({
  type,
  children,
  onClick,
}: {
  type: NotificationType;
  children: ReactNode;
  onClick?: () => void;
}) => {
  const style = `flex justify-between items-start mx-auto mb-4
                 text-s p-4 rounded-xl sm:w-full sm:rounded-none`;

  return (
    <div
      className={cn(`${style} w-80 border`, {
        'bg-warning-25 border-warning-600': type === 'warning',
        'bg-error-25 border-error-600': type === 'error',
        'bg-primary-25 border-primary-600': type === 'success',
      })}
    >
      <div
        className={cn(
          'flex items-start font-semibold text-sm break-words text-word-break',
          {
            'text-warning-700': type === 'warning',
            'text-error-700': type === 'error',
            'text-primary-700': type === 'success',
          }
        )}
      >
        {children}
      </div>
      <button
        className="flex items-center w-5 h-5 justify-center"
        onClick={onClick}
      >
        <Icon
          glyph={IconMap.Cross}
          className={cn({
            'text-warning-500': type === 'warning',
            'text-error-500': type === 'error',
            'text-primary-500': type === 'success',
          })}
        />
      </button>
    </div>
  );
};
