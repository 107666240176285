import { SnippetType } from '@distribute/shared/types';
import { FC, useMemo } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../../model';
import { getAvailableCategoriesBySnippetType } from './lib/getAvailableCategoriesBySnippetType';
import { Dropdown } from '../../../../../shared/ui';

type Props = {
  snippetType: SnippetType;
  setCategoryId: (value: number | undefined) => void;
  categoryId?: number;
  errorText?: string;
};

export const SelectCategoryDropdown: FC<Props> = ({
  snippetType,
  setCategoryId,
  categoryId,
  errorText,
}) => {
  const categories = useSelector(snippetsModel.selectors.selectCategories);

  const availableCategories = useMemo(() => {
    return getAvailableCategoriesBySnippetType(categories, snippetType);
  }, [categories, snippetType]);

  const dispatch = useDispatch();

  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  );

  const items = useMemo(() => {
    return availableCategories.map((category) => {
      return {
        id: `${category.id}`,
        name: category.name,
        label: category.name,
        onClick: () => {
          setCategoryId(Number(category.id));

          if (!currentSnippet) return;

          dispatch(
            snippetsModel.actions.setCurrentSnippet({
              ...currentSnippet,
              category: { id: Number(category.id) },
            })
          );
        },
      };
    });
  }, [availableCategories, setCategoryId, currentSnippet, dispatch]);

  const noAvailableCategoriesItems = [
    {
      id: 'no-categories',
      label: 'Please create a category for such a type first',
    },
  ];

  const category = availableCategories.find((c) => c.id === categoryId);

  return (
    <div>
      <Dropdown
        items={
          availableCategories.length > 0 ? items : noAvailableCategoriesItems
        }
        currentItemId={categoryId?.toString()}
        listStyles="!w-[var(--radix-dropdown-menu-trigger-width)] shadow-lg"
        triggerStyles={cn('!h-10 shrink-0 shadow-xs bg-base-white', {
          '!font-normal !text-gray-500 ': !category,
          '!border-error-300': errorText,
        })}
        align="start"
        itemStyles="py-2 px-3"
        arrowStyle="text-gray-600"
        customTriggerText={category ? category.name : 'Select snippet category'}
        isModalMode={false}
      />
      {errorText && (
        <span className="mt-2 text-sm text-error-600">{errorText}</span>
      )}
    </div>
  );
};
