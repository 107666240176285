import {
  UserPermissionToVideoRecord,
  VideoRecordTeamMembersPermission,
} from '@distribute/shared/types';

type Params = {
  userId: string;
  teamMembersPermission: VideoRecordTeamMembersPermission;
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
};

export const checkIsAllowToViewLikeMember = ({
  userId,
  teamMembersPermission,
  userPermissionToVideoRecords,
}: Params) => {
  if (teamMembersPermission !== VideoRecordTeamMembersPermission.INVITE_ONLY) {
    return true;
  }
  return userPermissionToVideoRecords.some((v) => v.user.id === userId);
};
