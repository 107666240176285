import { call, put } from 'redux-saga/effects';

import { authModel } from '../../../../entities/auth-operation';
import { signOut } from '../../log-out';
import { auth } from '../../../../shared/config';
import { createNotification, snackbarModel } from '../../../snackbar';
import { ActionCodeInfo, checkActionCode } from 'firebase/auth';
import { authApi } from '../../../../shared/api';

export function* recoverEmail(code: string) {
  yield call(
    authModel.sagas.authOperationWithAlert,
    'Failed to recover email',
    function* () {
      const codeData: ActionCodeInfo = yield checkActionCode(auth, code);
      if (codeData.data.email) {
        yield call(authApi.updateProfile, { email: codeData.data.email });
      }
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'success',
            'The email has been recovered! You need to login again'
          )
        )
      );

      yield call(signOut);
    }
  );
}
