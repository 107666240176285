import { forwardRef } from 'react';

export type BubbleMenuToolbarProps = {
  children: React.ReactNode;
};

export const BubbleMenuToolbar = forwardRef<
  HTMLDivElement,
  BubbleMenuToolbarProps
>(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="flex items-center gap-1 p-1 h-9 border border-gray-200 rounded-lg bg-base-white shadow-md"
    >
      {children}
    </div>
  );
});
