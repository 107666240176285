import React from 'react';
import { Emoji } from 'emoji-picker-react';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../..//shared/sprite';

type Props = {
  onClick(): void;
};

export const CreateBlankPage: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="p-3 border border-gray-300 cursor-pointer rounded-xl h-fit group hover:shadow-md">
      <div className="h-[164px] border border-light-6 rounded-xl overflow-hidden relative">
        <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100">
          <Button
            variant="text"
            color="primary"
            className="w-45 !h-11 text-md"
            onClick={onClick}
          >
            <Icon glyph={IconMap.Plus} width={20} className="mr-1.5" />
            Blank page
          </Button>
        </div>
        <div className="w-full h-full px-6 pt-5 bg-gray-100 group-hover:blur flex justify-center">
          <div className="relative w-full h-full pt-2 pl-2 shadow-md bg-base-white rounded-t-xl max-w-[216px]">
            <img
              src="../../../assets/images/blank-page-content.png"
              alt="Blank page"
              width={184}
              className="object-contain h-full left-4 md:left-5 top-4 md:top-5"
            />
          </div>
        </div>
      </div>
      <div className="relative flex items-center gap-2 pr-10 mt-3">
        <Emoji unified="1f4c3" size={20} />
        <p className="text-base font-semibold text-gray-900 truncate">
          Blank page
        </p>
      </div>
      <div className="mt-2 text-sm text-gray-700 py-0.5">
        Create an empty page to start simple.
      </div>
    </div>
  );
};
