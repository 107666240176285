import { call, put } from 'redux-saga/effects';

import { alertBarApi } from '../../../shared/api';
import { actions } from '../model/reducer';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { AlertBar } from '@distribute/shared/types';

export function* setAlertBarData(documentContentId: number) {
  try {
    yield put(
      actions.setConversionKitStatus({
        status: 'pending',
        statusType: 'alertBarStatus',
      })
    );

    const alertBar: AlertBar = yield call(
      alertBarApi.getAlertBar,
      documentContentId
    );

    yield put(actions.setAlertBar(alertBar));
    yield put(
      actions.setConversionKitStatus({
        status: 'success',
        statusType: 'alertBarStatus',
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setConversionKitStatus({
        status: 'error',
        statusType: 'alertBarStatus',
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show alert bar`)
      )
    );
  }
}
