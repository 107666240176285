import { Button, Modal } from '../../../../shared/ui';
import React from 'react';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
};

export const DetachSnippetConfirmationModal: React.FC<Props> = ({
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={true}
      title="Detach from snippet?"
      dialogClassName="!z-100"
      actionButton={
        <Button
          onClick={onConfirm}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
        >
          Detach
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600">
        The selected snippet will no longer be synced with the original. Are you
        sure you want to continue?
      </div>
    </Modal>
  );
};
