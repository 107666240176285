import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import classNames from 'classnames';

import { Icon, SpriteGlyph } from './Icon';
import { ButtonColor, ButtonSize, buttonStyles, ButtonVariant } from './utils';

type Props = {
  variant?: ButtonVariant;
  color: ButtonColor;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  iconLeftName?: SpriteGlyph;
  size?: ButtonSize;
  fontBold?: boolean;
};

export const LinkAsButton = ({
  variant = 'text',
  color,
  disabled = false,
  fullWidth = false,
  children,
  className,
  loading,
  iconLeftName,
  size = 'lg',
  fontBold = false,
  ...rest
}: DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  Props) => {
  const styles = buttonStyles({
    className,
    fontBold,
    disabled,
    fullWidth,
    variant,
    size,
    color,
  });

  return (
    <a
      className={classNames(styles, '!no-underline', {
        'bg-primary-100 hover:bg-primary-100': loading && color === 'primary',
        'bg-gray-300 hover:bg-gray-300 border-none':
          loading && color === 'destructive',
        'bg-gray-100 hover:bg-gray-100': loading && color === 'secondary',
      })}
      {...rest}
    >
      {iconLeftName && (
        <div className="mr-2">
          <Icon glyph={iconLeftName} />
        </div>
      )}
      {loading && variant !== 'icon' && (
        <div
          className={classNames(
            'absolute -left-0.25 -top-0.25 flex items-center justify-center w-[calc(100%_+_2px)] h-[calc(100%_+_2px)] rounded-lg',
            {
              'bg-primary-100': color === 'primary',
              'bg-gray-100 text-gray-400': color === 'secondary',
              'text-gray-400': color === 'link',
              'text-gray-600': color === 'tertiary-black',
              'bg-gray-300 border-none': color === 'destructive',
            }
          )}
        >
          {[1, 2, 3].map((i) => (
            <span
              key={i}
              className={`w-2 h-2 flex m-1 bg-primary-800 rounded loading-dot loading-dot--${i}`}
            ></span>
          ))}
        </div>
      )}
      {children}
    </a>
  );
};
