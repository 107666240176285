import React, { ChangeEvent, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import cn from 'classnames';

import { Button, Icon, Input, LoaderDots } from '../../../shared/ui';
import { viewersModel } from '../model';
import {
  DateRange,
  DateRangePicker,
} from '../../../shared/ui/date-range-picker';
import { IconMap } from '../../../shared/sprite';
import { Table } from './components';

export const ViewersTab: FC = () => {
  const dispatch = useDispatch();

  const {
    search,
    loadingDataSource,
    isExportCsvLoading,
    page,
    totalPages,
    data,
    total,
  } = useSelector(viewersModel.selectors.selectViewers);

  const isLeadCategory = useSelector(
    viewersModel.selectors.selectIsLeadCategory
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetViewersOnSearch = useCallback(
    debounce(() => {
      dispatch(viewersModel.actions.setDetails({}));
      dispatch(
        viewersModel.actions.getViewersData({
          loadingDataSource: 'search',
        })
      );
    }, 400),
    [dispatch]
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value;
    dispatch(viewersModel.actions.setSearch(searchVal));
    handleGetViewersOnSearch();
  };

  const handleChangePage = (newPage: number) => {
    dispatch(
      viewersModel.actions.getViewersData({
        page: newPage,
      })
    );
  };

  const handleExportCsv = () => {
    dispatch(viewersModel.actions.exportCsv({}));
  };

  const handleChangeDateRangePicker = (range: DateRange) => {
    dispatch(viewersModel.actions.onChangeRange(range));
  };

  return (
    <>
      <div className="flex row justify-between relative gap-5 sm:flex-col mb-6">
        <div className="flex justify-between gap-x-4 gap-y-3 grow sm:flex-col">
          <div className="flex items-center sm:gap-x-3 gap-5 justify-between grow-1">
            <div className="flex items-center gap-4 w-full">
              <div className="sm:max-w-full max-w-80 w-full relative">
                <Icon
                  glyph={IconMap.SearchLg}
                  width={20}
                  className="absolute left-3.5 top-2.5 z-10 text-gray-500"
                />
                <Input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={onChange}
                  heightSize="md"
                  className="!pl-10.5"
                />
              </div>
              {loadingDataSource === 'search' ? (
                <LoaderDots />
              ) : (
                <p
                  className={cn(
                    'text-gray-700 text-sm font-normal whitespace-nowrap sm:hidden',
                    {
                      hidden: Boolean(loadingDataSource),
                    }
                  )}
                >{`${total} ${isLeadCategory ? 'leads' : 'viewers'}`}</p>
              )}
            </div>
            {isLeadCategory && (
              <Button
                onClick={handleExportCsv}
                loading={isExportCsvLoading}
                color="secondary"
                variant="icon-text"
                size="md"
                iconLeftName={IconMap.DownloadCloud01}
                iconLeftWidth={20}
              >
                <span className="text-sm text-gray-700 font-semibold">
                  Export
                </span>
              </Button>
            )}
          </div>
          <div className="flex gap-x-5">
            <DateRangePicker onChange={handleChangeDateRangePicker} />
          </div>
        </div>
      </div>
      {loadingDataSource === 'global' ? (
        <LoaderDots className="mt-44" />
      ) : (
        <Table
          data={data}
          page={page}
          totalPages={totalPages}
          handleChangePage={handleChangePage}
        />
      )}
    </>
  );
};
