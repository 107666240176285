import React from 'react';
import { Avatar } from '../../../../shared/ui';
import { User } from '@distribute/shared/types';

type Props = {
  owner: User;
};

export const CreatedByRow: React.FC<Props> = ({ owner }) => {
  return (
    <div className="flex items-center gap-2 truncate">
      <Avatar
        displayName={owner.displayName}
        src={owner?.photoUrl}
        size="xs"
        isUserAvatar
        className="border-light-7 border-[0.5px] !bg-base-white"
      />
      <div className="text-sm text-gray-700 truncate">
        Created by{' '}
        <span className="font-semibold text-gray-900">{owner.displayName}</span>
      </div>
    </div>
  );
};
