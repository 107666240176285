import { useSelector } from 'react-redux';
import { teamsModel, useTeamPermissions } from '../../teams';
import { GuestUserBanner } from './GuestUserBanner';
import { GettingStartedStatus } from '../../getting-started';

export const SidebarBannerItems: React.FC = () => {
  const { isGuest } = useTeamPermissions();
  const isGettingStartedAvailable = useSelector(
    teamsModel.selectors.selectIsGettingStartedAvailable
  );

  if (isGuest) {
    return <GuestUserBanner />;
  }

  if (isGettingStartedAvailable) {
    return <GettingStartedStatus />;
  }

  return null;
};
