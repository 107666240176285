import { Page } from '@distribute/shared/types';
import { pagesModel } from '..';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';

export function* changePagePublicAccess({
  payload: { permission },
}: ReturnType<typeof pagesModel.actions.changePagePublicAccess>) {
  try {
    const page: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );

    const updatedPage: Page = yield pagesApi.changePublicAccess(page.id, {
      permission,
    });

    yield put(
      pagesModel.actions.setCurrentPage({
        ...page,
        publicAccess: updatedPage.publicAccess,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update page public access')
      )
    );
  }
}
