import { takeEvery } from 'redux-saga/effects';
import { subscriptionModel } from '..';
import { togglePaywall } from './togglePaywall';
import { getUpcomingInvoice } from './getUpcomingInvoice';
import { createSubscription } from './createSubscription';
import { updatePaymentMethod } from './updatePaymentMethod';

export function* subscriptionGlobalWorker() {
  yield takeEvery(subscriptionModel.actions.togglePaywall, togglePaywall);
  yield takeEvery(
    subscriptionModel.actions.getUpcomingInvoice,
    getUpcomingInvoice
  );
  yield takeEvery(
    subscriptionModel.actions.createSubscription,
    createSubscription
  );
  yield takeEvery(
    subscriptionModel.actions.updatePaymentMethod,
    updatePaymentMethod
  );
}
