export enum Message {
  TemplatesPreview = 'templates:preview',
  TemplatesSelect = 'templates:select',
  TemplatesResize = 'templates:resize',
  TemplatesDevice = 'templates:device',
  TemplatesMenuResize = 'templates:menu:resize',
  TemplatesAskForDevice = 'templates:ask-for-device',
  TemplatesMenuSelect = 'templates:menu:select',
  TemplatesMenuSearch = 'templates:menu:search',
  TemplatesAskForResize = 'templates:ask-for-resize',
  TemplatesPreviewClose = 'templates:preview:close',
  TemplatesChangeParentSize = 'templates:change-parent-size',
}
