import { call, put } from 'redux-saga/effects';
import { teamMembersModel } from '../';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* transferOwnership({
  payload: { teamId, teamMemberId },
}: ReturnType<typeof teamMembersModel.actions.transferOwnership>) {
  try {
    yield call(teamsApi.transferOwnership, teamId, teamMemberId);
    yield call(teamsModel.sagas.loadTeamMembers);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Ownership has been transferred')
      )
    );
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to transfer ownership')
        )
      )
    );
  }
}
