import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import {
  ChangeSnippetTextContentAction,
  CreateSnippetAction,
  CreateSnippetCategoryAction,
  DeleteSnippetAction,
  DeleteSnippetCategoryAction,
  ReorderCategoriesAction,
  ReplaceSnippetAction,
  SearchQueryType,
  SnippetTypeFilterType,
  SortConditionType,
  UpdateSnippetAction,
  UpdateSnippetCategoryAction,
  UpdateSnippetUsedCountAction,
} from './types';
import { Snippet, SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { SnippetsTabEnum, SortState } from '../../../pages/snippets/lib';
import { ConfigureSnippetFormType } from '../ui/components/create-snippet/ConfigureSnippetForm';

type State = {
  categories: SnippetCategoryWithSnippets[];
  isCreateCategoryLoading: boolean;
  isCreateCategoryModalOpen: boolean;
  isManageCategoriesModalOpen: boolean;
  isCreateSnippetLoading: boolean;
  personalSnippets: Snippet[];
  draftSnippets: Snippet[];
  currentTypeFilter: SnippetTypeFilterType;
  currentSortCondition: SortConditionType;
  currentOwnerFilter: string | undefined;
  searchQuery: SearchQueryType;
  isUpdateCategoryLoading: boolean;
  isDeleteSnippetLoading: boolean;
  isDeleteSnippetCategoryLoading: boolean;
  updatingSnippetData: ConfigureSnippetFormType | undefined;
  isUpdateSnippetLoading: boolean;
  currentSnippet: Snippet | undefined;
  isSnippetSavedInEditor: boolean;
  modalSearchQuery: string;
  currentModalFolder: string | number;
};

const initialState: State = {
  categories: [],
  isCreateCategoryLoading: false,
  isCreateCategoryModalOpen: false,
  isManageCategoriesModalOpen: false,
  isCreateSnippetLoading: false,
  personalSnippets: [],
  draftSnippets: [],
  currentTypeFilter: {
    [SnippetsTabEnum.TEAM]: undefined,
    [SnippetsTabEnum.PERSONAL]: undefined,
  },
  currentSortCondition: {
    [SnippetsTabEnum.TEAM]: SortState.LAST_CREATED,
    [SnippetsTabEnum.PERSONAL]: SortState.LAST_CREATED,
    [SnippetsTabEnum.DRAFTS]: SortState.LAST_CREATED,
  },
  currentOwnerFilter: undefined,
  searchQuery: {
    [SnippetsTabEnum.TEAM]: '',
    [SnippetsTabEnum.PERSONAL]: '',
    [SnippetsTabEnum.DRAFTS]: '',
  },
  isUpdateCategoryLoading: false,
  isDeleteSnippetLoading: false,
  isDeleteSnippetCategoryLoading: false,
  updatingSnippetData: undefined,
  isUpdateSnippetLoading: false,
  currentSnippet: undefined,
  isSnippetSavedInEditor: true,
  modalSearchQuery: '',
  currentModalFolder: 'all',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'snippets',
  initialState,
  reducers: {
    setCategories: (
      state,
      { payload: categories }: PayloadAction<SnippetCategoryWithSnippets[]>
    ) => ({
      ...state,
      categories,
    }),
    setIsCreateCategoryLoading: (
      state,
      { payload: isCreateCategoryLoading }
    ) => ({ ...state, isCreateCategoryLoading }),
    setIsCreateCategoryModalOpen: (
      state,
      { payload: isCreateCategoryModalOpen }
    ) => ({ ...state, isCreateCategoryModalOpen }),
    setIsManageCategoriesModalOpen: (
      state,
      { payload: isManageCategoriesModalOpen }
    ) => ({ ...state, isManageCategoriesModalOpen }),
    setIsCreateSnippetLoading: (
      state,
      { payload: isCreateSnippetLoading }
    ) => ({ ...state, isCreateSnippetLoading }),
    setPersonalSnippets: (
      state,
      { payload: personalSnippets }: PayloadAction<Snippet[]>
    ) => ({
      ...state,
      personalSnippets,
    }),
    setDraftSnippets: (
      state,
      { payload: draftSnippets }: PayloadAction<Snippet[]>
    ) => ({
      ...state,
      draftSnippets,
    }),
    setCurrentTypeFilter: (
      state,
      { payload: currentTypeFilter }: PayloadAction<SnippetTypeFilterType>
    ) => ({
      ...state,
      currentTypeFilter,
    }),
    setCurrentSortCondition: (
      state,
      { payload: currentSortCondition }: PayloadAction<SortConditionType>
    ) => ({
      ...state,
      currentSortCondition,
    }),
    setCurrentOwnerFilterType: (
      state,
      { payload: currentOwnerFilter }: PayloadAction<string | undefined>
    ) => ({
      ...state,
      currentOwnerFilter,
    }),
    setSearchQuery: (
      state,
      { payload: searchQuery }: PayloadAction<SearchQueryType>
    ) => ({ ...state, searchQuery }),
    setIsUpdateCategoryLoading: (
      state,
      { payload: isUpdateCategoryLoading }
    ) => ({ ...state, isUpdateCategoryLoading }),
    setIsDeleteSnippetLoading: (
      state,
      { payload: isDeleteSnippetLoading }
    ) => ({ ...state, isDeleteSnippetLoading }),
    setIsDeleteSnippetCategoryLoading: (
      state,
      { payload: isDeleteSnippetCategoryLoading }
    ) => ({ ...state, isDeleteSnippetCategoryLoading }),
    setUpdatingSnippetData: (state, { payload: updatingSnippetData }) => ({
      ...state,
      updatingSnippetData,
    }),
    setIsUpdateSnippetLoading: (
      state,
      { payload: setIsUpdateSnippetLoading }
    ) => ({ ...state, setIsUpdateSnippetLoading }),
    setCurrentSnippet: (state, { payload: currentSnippet }) => ({
      ...state,
      currentSnippet,
    }),
    setIsSnippetSavedInEditor: (
      state,
      { payload: isSnippetSavedInEditor }
    ) => ({
      ...state,
      isSnippetSavedInEditor,
    }),
    setModalSearchQuery: (state, { payload: modalSearchQuery }) => ({
      ...state,
      modalSearchQuery,
    }),
    setCurrentModalFolder: (state, { payload: currentModalFolder }) => ({
      ...state,
      currentModalFolder,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  createCategory: createAction<CreateSnippetCategoryAction>(
    'snippets/createCategory'
  ),
  createSnippet: createAction<CreateSnippetAction>('snippets/createSnippet'),
  updateCategory: createAction<UpdateSnippetCategoryAction>(
    'snippets/updateCategory'
  ),
  deleteSnippet: createAction<DeleteSnippetAction>('snippets/deleteSnippet'),
  deleteSnippetCategory: createAction<DeleteSnippetCategoryAction>(
    'snippets/deleteSnippetCategory'
  ),
  updateSnippet: createAction<UpdateSnippetAction>('snippets/updateSnippet'),
  changeSnippetTextContent: createAction<ChangeSnippetTextContentAction>(
    '/snippets/changeSnippetTextContent'
  ),
  updateSnippetUsedCount: createAction<UpdateSnippetUsedCountAction>(
    'snippets/updateSnippetUsedCount'
  ),
  replaceSnippet: createAction<ReplaceSnippetAction>('snippets/replaceSnippet'),
  reorderCategories: createAction<ReorderCategoriesAction>(
    'snippets/reorderCategories'
  ),
};
