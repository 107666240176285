import React, { FC, useState } from 'react';
import cn from 'classnames';
import { ONBOARDING_VIDEO_URL } from '../../../workspace/config';
import { REVIEWS } from '../../config';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

export const StepSubscriptionImageBlock: FC = () => {
  const [slide, setSlide] = useState(0);
  const review = REVIEWS[slide];

  return (
    <div className="relative flex flex-col items-center justify-center gap-10 grow-1 px-10 py-9.5">
      <img
        className="absolute left-0 top-0 -z-10 w-full h-full object-cover object-right-top"
        src="../../../../assets/images/onboarding/img-step-4.jpg"
        alt="Onboarding step 4"
      />
      <video
        autoPlay
        muted
        src={ONBOARDING_VIDEO_URL}
        controls
        className="max-w-140 w-full"
      ></video>
      <div className="max-w-140 w-full border border-dashed border-gray-300" />
      <div className="max-w-160 px-8">
        <div className="flex flex-col items-center">
          <div className="text-display-xs font-display font-medium text-gray-900 text-center">
            {review.text}
          </div>
          <img src={review.photoUrl} alt="User" className="mt-8 w-10 h-10" />
          <div className="mt-2 text-md font-semibold text-gray-900">
            {review.name}
          </div>
          <div className="mt-1 text-sm font-medium text-gray-600">
            {review.position}
          </div>
        </div>
        <div className="flex justify-center items-center gap-16 mt-8">
          <Button color="tertiary-black" variant="text" size="sm">
            <Icon
              glyph={IconMap.ChevronLeft}
              width={20}
              className="text-gray-500"
              onClick={() =>
                setSlide((i) => (i === 0 ? REVIEWS.length - 1 : i - 1))
              }
            />
          </Button>
          <div className="flex gap-4">
            {REVIEWS.map((_, i) => (
              <button
                key={i}
                className="cursor-pointer"
                onClick={() => setSlide(i)}
              >
                <span
                  className={cn('flex w-2.5 h-2.5 rounded-full  bg-gray-200', {
                    '!bg-primary-700': i === slide,
                  })}
                />
              </button>
            ))}{' '}
          </div>
          <Button
            color="tertiary-black"
            variant="text"
            size="sm"
            onClick={() =>
              setSlide((i) => (i === REVIEWS.length - 1 ? 0 : i + 1))
            }
          >
            <Icon
              glyph={IconMap.ChevronRight}
              width={20}
              className="text-gray-500"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
