import { SNIPPETS_ROUTE } from '../../../../entities/history';
import { snippetsModel } from '../../../../features/snippets';
import { navigate } from '../../../../processes/navigation';
import { Button, Modal } from '../../../../shared/ui';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const LeavePageConfirmationModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  );

  const isLoading = useSelector(
    snippetsModel.selectors.selectIsUpdateSnippetLoading
  );

  const handleSaveSnippet = () => {
    if (!currentSnippet) return;

    dispatch(
      snippetsModel.actions.updateSnippet({
        snippet: currentSnippet,
        snippetId: currentSnippet.id,
        ...currentSnippet,
        callback: () => {
          dispatch(
            navigate({
              to: `${SNIPPETS_ROUTE}/${currentSnippet.sharingAccess}`,
            })
          );
        },
      })
    );

    onClose();
  };

  const onLeave = () => {
    dispatch(
      navigate({ to: `${SNIPPETS_ROUTE}/${currentSnippet?.sharingAccess}` })
    );
    dispatch(snippetsModel.actions.setCurrentSnippet(undefined));
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Save changes?"
      dialogClassName="!z-100"
      isShowCancelButton={false}
    >
      <div className="text-s font-normal text-gray-600">
        Changes in your snippet won’t be saved until you click Save button below
        or on top panel.
      </div>
      <div className="flex pt-8 gap-3">
        <Button
          fullWidth
          color="secondary"
          variant="text"
          type="button"
          onClick={onLeave}
        >
          Leave
        </Button>
        <Button
          onClick={handleSaveSnippet}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Save Snippet
        </Button>
      </div>
    </Modal>
  );
};
