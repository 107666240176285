import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

type Props = {
  onUpload: (file: File) => void;
  maxSize?: number;
  allowedTypes?: string[];
  onError?: (message: string) => void;
  progress?: number;
  isUploading?: boolean;
  disabled?: boolean;
  fileInputStyles?: string;
};

export const FileInput: FC<Props> = ({
  onUpload,
  onError,
  progress,
  maxSize = MAX_FILE_SIZE,
  allowedTypes = ALLOWED_FILE_TYPES,
  isUploading,
  disabled = false,
  fileInputStyles,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      const { size, type, name } = file;

      if (!allowedTypes.includes(type)) {
        onError?.('File type not allowed');
        return;
      }
      if (size > maxSize) {
        onError?.('Max file size exceeded');
        return;
      }
      setFileName(name);

      onUpload(file);
    },
    [onError, onUpload, maxSize, allowedTypes]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    onValidateAndUpload(file);
  };

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(true);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const file = e.dataTransfer?.files[0];

      return onValidateAndUpload(file);
    };

    document.body.addEventListener('dragover', enterListener);
    document.body.addEventListener('dragleave', leaveListener);
    document.body.addEventListener('drop', dropListener, {});
    return () => {
      document.body.removeEventListener('dragleave', leaveListener);
      document.body.removeEventListener('dragover', enterListener);
      document.body.removeEventListener('drop', dropListener);
    };
  }, [onValidateAndUpload]);

  return (
    <div className="w-full relative">
      {isUploading ? (
        <div className="relative min-h-31 overflow-hidden p-4 flex rounded-xl items-center justify-center border border-gray-300 bg-base-white">
          <div
            style={{ transform: `translateX(${progress}%)` }}
            className="absolute top-0 -left-full w-full h-full bg-gray-50"
          ></div>
          <div className="relative w-full text-gray-700">
            <p className="truncate">{fileName}</p>
            <p>{progress}% uploaded</p>
          </div>
        </div>
      ) : (
        <label
          className={classNames(
            ' rounded-xl p-4 flex items-center flex-col text-gray-600 justify-center bg-base-white',
            {
              'shadow-border-primary': isDragging,
              'shadow-border-gray': !isDragging,
              'shadow-border-gray hover:shadow-border-primary cursor-pointer':
                !disabled,
              'shadow-border-gray bg-gray-50 cursor-default': disabled,
            },
            fileInputStyles
          )}
        >
          <span className="text-s">
            <span
              className={classNames('hover:cursor-pointer font-medium', {
                'text-gray-300': disabled,
                'text-primary-700': !disabled,
              })}
            >
              Click to upload
            </span>{' '}
            or drag and drop
          </span>
          <span className="mt-1">
            <span className="text-xs font-normal text-gray-600">
              PNG or JPG (max. 5 MB)
            </span>
            <input
              accept={allowedTypes.join(',')}
              type="file"
              onChange={onChange}
              className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
            />
          </span>
        </label>
      )}
    </div>
  );
};
