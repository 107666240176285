import React from 'react';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import { useDispatch } from 'react-redux';
import { subscriptionModel } from '../../features/subscription';

export const RequirePaidPlanActionBadge: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center gap-1 h-6 px-2 border border-primary-400 rounded-full bg-primary-50 font-base cursor-pointer"
    >
      <Icon glyph={IconMap.Lock01} width={12} className="text-primary-500" />
      <span className="text-sm font-medium text-primary-700">Upgrade plan</span>
    </button>
  );
};
