import React, { FC } from 'react';
import cn from 'classnames';

type IProps = {
  color: string;
};

export const ColorPickerInput: FC<IProps> = ({ color }) => {
  return (
    <div className="flex items-center px-3.5 py-2.5 text-md text-gray-900 bg-base-white border rounded-lg border-gray-300 h-11">
      <span
        className={cn('w-3.75 h-3.75 mr-2.625 rounded-sm', {
          'border-0.5 border-gray-200': color.toLowerCase() === '#ffffff',
        })}
        style={{ backgroundColor: color }}
      />
      {color}
    </div>
  );
};
