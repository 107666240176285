import {
  CreateContactSupportFormRequestType,
  CreateContactSupportFormResponseType,
} from '@distribute/shared/api-types/contact-support-form';
import { api } from './instance';

export const contactSupportFormApi = {
  async contactSupport({
    attachments,
    message,
    requestType,
    subject,
  }: CreateContactSupportFormRequestType) {
    const formData = new FormData();

    formData.append('message', message);
    formData.append('requestType', requestType);
    formData.append('subject', subject);
    attachments.forEach((attachment) =>
      formData.append('attachments', attachment)
    );

    const { data: responseData } =
      await api.post<CreateContactSupportFormResponseType>(
        'contact-support-form',
        formData
      );

    return responseData;
  },
};
