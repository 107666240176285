import cn from 'classnames';

import * as SliderPrimitive from '@radix-ui/react-slider';

import { FC } from 'react';

type Props = {
  id: string;
  variant: (typeof SliderVariant)[keyof typeof SliderVariant];
  size: keyof (typeof styles)['sizes'];
  progressValue?: number;
};

export const SliderVariant = {
  Range: 'range',
  ProgressBar: 'progress-bar',
} as const;

const styles = {
  base: {
    root: 'relative flex items-center select-none touch-none w-full cursor-pointer',
    track: 'relative flex-grow-1 cursor-pointer',
    range: 'absolute h-full',
    thumb: 'block rounded-full outline-none cursor-pointer',
  },
  variants: {
    [SliderVariant.Range]: {
      track: 'bg-base-white/25 rounded-md relative',
      range: 'bg-base-white rounded-md',
      thumb: 'bg-base-white',
    },
    [SliderVariant.ProgressBar]: {
      track: 'bg-base-white/25 rounded-md overflow-hidden',
      range: 'bg-primary-400',
      thumb: 'bg-transparent',
    },
  },
  sizes: {
    sm: {
      track: 'h-1',
      range: 'h-3',
      thumb: 'w-2 h-2',
    },
    md: {
      track: 'h-1.5',
      range: 'h-4',
      thumb: 'w-3 h-3',
    },
  },
} as const;

const Progress = ({ value = 0 }: { value?: number }) => (
  <div
    className="absolute h-full bg-base-white/50"
    style={{ width: `${value}%` }}
  />
);

export const Slider: FC<SliderPrimitive.SliderProps & Props> = ({
  id,
  variant,
  size,
  className,
  progressValue,
  ...props
}) => (
  <SliderPrimitive.Root {...props} className={cn(styles.base.root, className)}>
    <SliderPrimitive.Track
      className={cn(
        styles.base.track,
        styles.variants[variant].track,
        styles.sizes[size].track
      )}
    >
      {variant === SliderVariant.ProgressBar && (
        <Progress value={progressValue} />
      )}
      <SliderPrimitive.Range
        className={cn(
          styles.base.range,
          styles.variants[variant].range,
          styles.sizes[size].range
        )}
      />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className={cn(
        styles.base.thumb,
        styles.variants[variant].thumb,
        styles.sizes[size].thumb
      )}
      key={`${id}-thumb-1`}
    />
  </SliderPrimitive.Root>
);
