import { Plugin } from '@tiptap/pm/state';
import { Editor, Extension } from '@tiptap/react';
import { mediaUploadModel } from '../../../../features/media-upload';
import { Dispatch } from 'react';
import { v4 } from 'uuid';
import {
  IMAGE_TYPES,
  PDF_TYPES,
  VIDEO_TYPES,
} from '../../ui/upload-form/config';

import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import {
  UploadingPlaceholderPlugin,
  findPlaceholder,
} from './UploadingPlaceholderPlugin';
import { EditorView } from '@tiptap/pm/view';

export type DropHandleOptions = {
  dispatch: Dispatch<unknown>;
};

export function getFileType(file: File) {
  const fileType = file?.type.replace(/(.*)\//g, '').toUpperCase();

  const isImageType = IMAGE_TYPES.map((el) => el.toUpperCase()).includes(
    fileType
  );
  const isVideoType = VIDEO_TYPES.map((el) => el.toUpperCase()).includes(
    fileType
  );
  const isPDFType = PDF_TYPES.map((el) => el.toUpperCase()).includes(fileType);

  const type =
    (isImageType && 'image') ||
    (isVideoType && 'video') ||
    (isPDFType && 'pdf');
  return type;
}

export const DropHandleExtension = Extension.create<DropHandleOptions>({
  name: 'dropHandle',

  addStorage() {
    return {
      isLocalDragItem: false,
    };
  },

  addProseMirrorPlugins: function () {
    return [
      UploadingPlaceholderPlugin,
      new Plugin({
        props: {
          handleDOMEvents: {
            dragstart: () => {
              this.storage.isLocalDragItem = true;
            },
            dragend: () => {
              this.storage.isLocalDragItem = false;
            },
            paste: (view, event) => {
              const files = event.clipboardData?.files;
              if (files?.length) {
                insertFiles({
                  files: Array.from(files),
                  view,
                  coordinates: {
                    pos: this.editor.view.state.selection.$anchor.pos,
                    inside: 0,
                  },
                  editor: this.editor as Editor,
                  options: this.options,
                });
              }
            },
            drop: (view, event) => {
              if (!this.storage.isLocalDragItem) {
                event.preventDefault();
              }
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              const files = Array.from(event.dataTransfer?.files ?? []);

              insertFiles({
                files,
                view,
                coordinates,
                options: this.options,
                editor: this.editor as Editor,
              });
            },
          },
        },
      }),
    ];
  },
});

type InsertFilesProps = {
  files: File[];
  view: EditorView;
  coordinates: {
    pos: number;
    inside: number;
  } | null;
  options: DropHandleOptions;
  editor: Editor;
};

const insertFiles = ({
  files,
  view,
  coordinates,
  options,
  editor,
}: InsertFilesProps) => {
  files.forEach((file, index) => {
    if (file) {
      const type = getFileType(file);

      if (!type) {
        options.dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File format is not supported')
          )
        );
        return;
      }

      const id = {};

      const tr = view.state.tr;
      if (!tr.selection.empty) tr.deleteSelection();
      tr.setMeta(UploadingPlaceholderPlugin, {
        add: {
          id,
          pos: coordinates?.pos,
          fileName: file.name,
          fileType: type,
        },
      });
      view.dispatch(tr);

      options.dispatch(
        mediaUploadModel.actions.uploadFile({
          file,
          id: v4(),
          cb: (url, fileName) => {
            const pos = findPlaceholder(view.state, id);
            if (pos === null) return;
            view.dispatch(
              view.state.tr.setMeta(UploadingPlaceholderPlugin, {
                remove: { id },
              })
            );
            if (type === 'pdf') {
              editor.commands.setFilePreview({
                fileName,
                fileSize: file.size,
                fileType: 'pdf',
                fileUrl: url,
                position: coordinates?.pos
                  ? coordinates?.pos + index
                  : undefined,
              });
              return;
            }
            editor
              .chain()
              .setResizeableFigure({
                content: [
                  {
                    type: type,
                    attrs: {
                      src: url,
                      alt: fileName,
                      title: fileName,
                    },
                  },
                ],
                position: coordinates?.pos
                  ? coordinates?.pos + index
                  : undefined,
              })
              .focus()
              .run();
          },
        })
      );
    }
  });
};
