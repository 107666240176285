import { IconMap } from '../../../../../shared/sprite';
import { Button, Icon, Input } from '../../../../../shared/ui';
import React, { useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../../../features/pages';
import { navigate } from '../../../../../processes/navigation';
import { WORKSPACE_LEADS_ROUTE } from '../../../../../entities/history';
import { generatePath } from 'react-router-dom';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';

export const PersonalizedLink: React.FC = () => {
  const dispatch = useDispatch();
  const [isCopied, setCopied] = useState(false);

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const link = useSelector(editorSidebarModel.selectors.selectPersonalizedLink);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <Icon glyph={IconMap.Link} width={20} className="text-gray-700" />
        <p className="text-gray-900 font-semibold">Personalized link</p>
      </div>
      <p className="my-2 text-sm text-gray-700">
        Please copy and share this link with the designated person. You can
        track their actions{' '}
        <span
          className="text-primary-700 font-medium cursor-pointer"
          onClick={() => {
            dispatch(
              navigate({
                to: generatePath(WORKSPACE_LEADS_ROUTE, {
                  sequenceNumber: currentPage.sequenceNumber,
                }),
              })
            );
          }}
        >
          in the viewers section{' '}
          <Icon
            glyph={IconMap.ArrowSquareUpRight}
            width={16}
            className="inline -mt-0.5"
          />
        </span>
      </p>
      <div className="flex gap-1.5">
        <Input value={link} heightSize="md" type="text" disabled />

        <div className="group relative">
          <Button
            variant="icon"
            size="md"
            color="secondary"
            onClick={handleCopy}
          >
            <Icon
              glyph={IconMap.MenuCopy}
              width={20}
              className="text-gray-700"
            />
          </Button>
          <div
            className={cn(
              'invisible group-hover:visible absolute right-0 -top-9.5 w-19 px-3 py-2 text-xs text-base-white bg-gray-900 font-semibold rounded-lg',
              { '!w-17': isCopied }
            )}
          >
            {isCopied ? 'Copied!' : 'Copy link'}
          </div>
        </div>
      </div>
    </div>
  );
};
