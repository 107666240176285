import { useTiptapEditor } from '../lib';
import { EditorContent } from '@tiptap/react';

export const TiptapEditor = ({ className = '' }) => {
  const { editor } = useTiptapEditor();

  if (!editor) {
    return null;
  }

  return <EditorContent className={className} editor={editor} />;
};
