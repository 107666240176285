export const VIMEO_REGEX =
  /^(?:https:\/\/)?(?:www.|player.)?vimeo.com\/(?:video\/)?([0-9]*)(?:.*)$/;
export const VIMEO_REGEX_GLOBAL =
  /^(?:https:\/\/)?(?:www.|player.)?vimeo.com\/(?:video\/)?([0-9]*)(?:.*)$/g;

export const isValidVimeoUrl = (url: string) => {
  return url.match(VIMEO_REGEX);
};

export interface GetEmbedUrlOptions {
  url: string;
}

export const getEmbedUrl = () => {
  return 'https://player.vimeo.com/video/';
};

export const getEmbedUrlFromVimeoUrl = (options: GetEmbedUrlOptions) => {
  const { url } = options;

  // if is already an embed url, return it
  if (url.includes('player.vimeo.com')) {
    return url;
  }

  const regex = VIMEO_REGEX.exec(url);

  if (!regex || !regex[1]) {
    return null;
  }

  const outputUrl = `${getEmbedUrl()}${regex[1]}`;

  return outputUrl;
};
