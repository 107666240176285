import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { aiModel } from '../../../../features/ai';
import { AIErrorType } from '@distribute/shared/api-types/ai';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { RequestTooLargeModal } from './RequestTooLargeModal';

export const AIErrorHandler = () => {
  const dispatch = useDispatch();
  const error = useSelector(aiModel.selectors.selectError);
  const [isShowRequestIsTooLargeModal, setIsShowRequestIsTooLargeModal] =
    useState(false);

  useEffect(() => {
    if (!error) return;

    if (error.type === AIErrorType.TOO_LARGE_REQUEST) {
      setIsShowRequestIsTooLargeModal(true);
    }
    if (error?.type === AIErrorType.UNKNOWN_ERROR) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', error.message)
        )
      );
    }
  }, [dispatch, error]);

  const onCloseRequestIsTooLargeModal = () => {
    setIsShowRequestIsTooLargeModal(false);
    dispatch(aiModel.actions.setError());
  };

  return (
    // TODO: add others errors modal
    <RequestTooLargeModal
      isOpen={isShowRequestIsTooLargeModal}
      onClose={onCloseRequestIsTooLargeModal}
    />
  );
};
