import { call, put, select } from 'redux-saga/effects';

import {
  authUserModel,
  getUserFromFb,
  setUserData,
} from '../../../../entities/auth-user';
import { providers } from '../../../../shared/config/firebase';
import { createNotification, snackbarModel } from '../../../snackbar';
import { FirebaseUser } from '../../../../shared/types';
import {
  reauthenticateWithCredential,
  unlink,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { getErrorMessage } from '../../../../shared/lib';
import { GOOGLE_PROVIDER_ID, PASSWORD_PROVIDER_ID } from '../../lib';

export function* updateUserEmail(
  updateUserEmailClick: ReturnType<typeof actions.changeEmailClick>
) {
  const {
    payload: { email, password, cb },
  } = updateUserEmailClick;

  const user: FirebaseUser = yield getUserFromFb();
  if (email === user.email) {
    yield put(actions.setChangeEmailError('You already have this email'));
    return;
  }

  try {
    yield put(actions.setIsChangeEmailLoading(true));
    const user: FirebaseUser = yield getUserFromFb();

    try {
      const credentials = providers.EmailAuthProvider.credential(
        user.email || '',
        password
      );
      yield reauthenticateWithCredential(user, credentials);
    } catch {
      throw new Error('Invalid password');
    }

    yield verifyBeforeUpdateEmail(user, email);
    const authProviders: string[] = yield select(
      authUserModel.selectors.selectAuthProviders
    );
    if (
      authProviders.includes(GOOGLE_PROVIDER_ID) &&
      authProviders.includes(PASSWORD_PROVIDER_ID)
    ) {
      const updatedUser: FirebaseUser = yield unlink(user, GOOGLE_PROVIDER_ID);
      yield call(setUserData, updatedUser);
    }
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          `Verification email has been sent to ${email}`
        )
      )
    );
    cb && cb();
  } catch (e) {
    logger.error(e);
    yield put(
      actions.setChangeEmailError(
        getErrorMessage(e, 'Something went wrong. Please try again later')
      )
    );
  } finally {
    yield put(actions.setIsChangeEmailLoading(false));
  }
}
