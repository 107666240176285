import React from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
};
export const AuthFormWrapper: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="mx-auto rounded sm:rounded-none text-base-black">
      <h2 className="mb-3 font-medium text-display-md font-display text-gray-900 text-center">
        {title}
      </h2>
      {children}
    </div>
  );
};
