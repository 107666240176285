import { useMemo, useState } from 'react';
import { Editor } from '@tiptap/react';
import { Trigger } from '@radix-ui/react-popover';
import { Icon, Popover } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { RichTextEditorDropdownHeader } from '../rich-text-editor-dropdown';
import { CellAlignmentMenu } from './CellAlignmentMenu';
import { IProps as CellAlignmentMenuItemProps } from './CellAlignmentMenuItem';

export interface IProps {
  editor: Editor;
}

export const TiptapTableStylesDropdown = ({ editor }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const cellAlignmentsMenu = useMemo<CellAlignmentMenuItemProps[]>(
    () => {
      const { tableCellAlign } = editor.getAttributes('tableCell');
      const handleChangeAlignment = (id: string) => {
        editor.commands.setCellAttribute('tableCellAlign', id);
        setIsOpen(false);
      };

      return [
        {
          id: 'left',
          icon: IconMap.AlignLeft,
          tooltip: 'Align left',
          isActive: tableCellAlign === 'left',
          onClick: handleChangeAlignment,
        },
        {
          id: 'center',
          icon: IconMap.AlignCenter,
          tooltip: 'Align center',
          isActive: tableCellAlign === 'center',
          onClick: handleChangeAlignment,
        },
        {
          id: 'right',
          icon: IconMap.AlignRight,
          tooltip: 'Align right',
          isActive: tableCellAlign === 'right',
          onClick: handleChangeAlignment,
        },
        {
          id: 'justify',
          icon: IconMap.AlignJustify,
          tooltip: 'Justify',
          isActive: tableCellAlign === 'justify',
          onClick: handleChangeAlignment,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, editor.state]
  );

  const verticalCellAlignmentsMenu = useMemo<CellAlignmentMenuItemProps[]>(
    () => {
      const { tableCellVerticalAlign } = editor.getAttributes('tableCell');
      const handleChangeAlignment = (id: string) => {
        editor.commands.setCellAttribute('tableCellVerticalAlign', id);
        setIsOpen(false);
      };

      return [
        {
          id: 'top',
          icon: IconMap.AlignVerTop,
          tooltip: 'Align top',
          isActive: tableCellVerticalAlign === 'top',
          onClick: handleChangeAlignment,
        },
        {
          id: 'middle',
          icon: IconMap.AlignVerCenter,
          tooltip: 'Align middle',
          isActive: tableCellVerticalAlign === 'middle',
          onClick: handleChangeAlignment,
        },
        {
          id: 'bottom',
          icon: IconMap.AlignVerBottom,
          tooltip: 'Align bottom',
          isActive: tableCellVerticalAlign === 'bottom',
          onClick: handleChangeAlignment,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, editor.state]
  );

  return (
    <Popover
      open={isOpen}
      handleClickCancel={() => setIsOpen(false)}
      side="bottom"
      align="start"
      sideOffset={4}
      onFocusOutside={(e) => e.preventDefault()}
      contentStyles="z-9999"
      isRenderInPortal={false}
      triggerComponent={
        <Trigger onClick={() => setIsOpen(true)} asChild>
          <div>
            <RichTextEditorDropdownHeader isOpen={isOpen}>
              <Icon
                className="w-6 h-6"
                width={24}
                glyph={IconMap.RollerBrush}
              />
              <span>Style</span>
            </RichTextEditorDropdownHeader>
          </div>
        </Trigger>
      }
    >
      <div className="flex flex-col gap-y-4 p-4 border-gray-200 shadow-lg bg-base-white rounded-lg">
        <div className="flex flex-col gap-y-2">
          <div className="text-sm text-gray-900 font-semibold">
            Cell alignment
          </div>
          <CellAlignmentMenu items={cellAlignmentsMenu} />
          <CellAlignmentMenu items={verticalCellAlignmentsMenu} />
        </div>
      </div>
    </Popover>
  );
};
