import React, { ForwardedRef, Fragment } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type Size = 'md' | 'lg';

type ToggleProps = {
  checked: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
  ref?: ForwardedRef<HTMLButtonElement>;
  size?: Size;
  isUseGeneralBgForAllStatuses?: boolean;
};

const sizesByElement: Record<
  'root' | 'circle' | 'on' | 'off',
  Record<Size, string>
> = {
  root: {
    md: 'h-5 w-12 px-0.5',
    lg: 'h-7 w-14 px-1',
  },
  circle: {
    md: 'h-4 w-4',
    lg: 'h-5 w-5',
  },
  on: {
    md: 'left-0.5',
    lg: 'left-1',
  },
  off: {
    md: 'right-0.5',
    lg: 'right-1',
  },
};

export const OnOffToggle: React.FC<ToggleProps> = ({
  checked,
  disabled,
  onChange,
  ref,
  size = 'md',
  isUseGeneralBgForAllStatuses = false,
}: ToggleProps) => {
  return (
    <Switch checked={checked} onChange={onChange} as={Fragment}>
      {({ checked }) => (
        <button
          ref={ref}
          className={classNames(
            'flex border-none items-center outline-none rounded-full relative',
            sizesByElement.root[size],
            {
              'bg-primary-600 hover:bg-primary-700 justify-end': checked,
              'bg-gray-200 hover:bg-gray-300  justify-start':
                !checked && !isUseGeneralBgForAllStatuses,
              'bg-primary-600 justify-start':
                !checked && isUseGeneralBgForAllStatuses,
              '!bg-gray-300 cursor-default': disabled && checked,
              '!bg-gray-200 cursor-default': disabled && !checked,
            }
          )}
          disabled={disabled}
        >
          {checked && (
            <p
              className={classNames(
                'w-7 absolute font-bold text-xs text-primary-100',
                sizesByElement.on[size]
              )}
            >
              ON
            </p>
          )}
          {!checked && (
            <p
              className={classNames(
                'w-7 absolute font-bold text-xs text-gray-400',
                sizesByElement.off[size]
              )}
            >
              OFF
            </p>
          )}
          <span
            className={classNames(
              'bg-base-white inline-block rounded-full bg-white shadow-sm',
              { 'bg-gray-100': disabled },
              sizesByElement.circle[size]
            )}
          />
        </button>
      )}
    </Switch>
  );
};
