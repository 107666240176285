import { User } from '@distribute/shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { call, put, select } from 'redux-saga/effects';
import { teamsApi } from '../../../../shared/api';
import { onboardingModel } from '..';

export function* getSubdomain() {
  const user: User = yield select(authUserModel.selectors.selectUser);
  const subdomain: string = yield call(teamsApi.getSubdomain, user.displayName);
  yield put(onboardingModel.actions.setSubdomain(subdomain));
}
