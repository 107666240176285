import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import React, { useState } from 'react';

export type DnsRecordInfo = {
  type: string;
  host: string;
  value: string;
};

type Props = {
  record: DnsRecordInfo;
};

export const DNSCard: React.FC<Props> = ({ record }) => {
  const [wasCopied, setCopied] = useState(false);

  const handleCopyRecord = () => {
    navigator.clipboard.writeText(record.value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  return (
    <div className="bg-gray-100 rounded-xl p-4 w-fit">
      <ul className="flex flex-col gap-3 text-base">
        <li className="flex gap-2">
          <p className="w-28">Type:</p>
          <p className="text-gray-900 font-medium">{record.type}</p>
        </li>
        <li className="flex gap-2">
          <p className="w-28">Host:</p>
          <p className="text-gray-900 font-medium">{record.host}</p>
        </li>
        <li className="flex gap-2 items-center">
          <p className="w-28">Value:</p>
          <p className="text-gray-900 font-medium">{record.value}</p>
          <div className="group relative h-5">
            <button onClick={handleCopyRecord}>
              <Icon
                glyph={IconMap.Copy01}
                width={20}
                className="text-gray-500 hover:text-gray-600 cursor-pointer"
              />
            </button>
            <div className="invisible group-hover:visible absolute left-1/2 -translate-x-1/2 -top-9.5 px-3 py-2 text-xs text-base-white bg-gray-900 font-semibold rounded-lg">
              {wasCopied ? 'Copied!' : 'Copy'}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
