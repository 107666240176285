import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Document from '@tiptap/extension-document';
import { TiptapEditorConsumer, TiptapEditorProvider } from '../lib';
import { TiptapEditor } from './Editor';
import { Editor } from '@tiptap/react';
import { useCallback } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import { TiptapTextBubbleMenu } from './minimal-editor/TiptapTextBubbleMenu';
import CharacterCount from '@tiptap/extension-character-count';
import { defaultExtensions } from '../mainEditorExtensions';
import cn from 'classnames';
import { CustomLink } from '../extensions/Link';

export const SimpleDocument = Document.extend({
  content: '(block)*',
});

export type RichTextEditorProps = {
  children?: React.ReactNode;
  content: string;
  onUpdate: (data: string) => void;
  placeholder: string;
  label?: string;
  maxLength?: number;
  isError?: boolean;
  isInline?: boolean;
  disableTextStyling?: boolean;
  hideCharsCount?: boolean;
};

type EditorHandle = {
  scrollIntoViewAndFocus: () => void;
};

export const RichTextEditor = React.forwardRef<
  EditorHandle,
  RichTextEditorProps
>(
  (
    {
      children,
      content,
      label,
      onUpdate,
      placeholder,
      maxLength,
      isError,
      isInline,
      disableTextStyling,
      hideCharsCount,
    }: RichTextEditorProps,
    forwardedRef
  ) => {
    const [richTextEditor, setRichTextEditor] = useState<Editor | null>(null);

    const handleUpdate = useCallback(
      ({ editor }: { editor: Editor }) => {
        const content = editor.getHTML();

        onUpdate(content);
      },
      [onUpdate]
    );

    const handleCreate = useCallback(({ editor }: { editor: Editor }) => {
      setRichTextEditor(editor);
    }, []);

    useEffect(() => {
      richTextEditor?.setOptions({
        editorProps: {
          attributes: {
            class: isInline
              ? 'minimal-inline'
              : cn('minimal bg-base-white', { '!border-error-500': isError }),
          },
        },
      });
    }, [richTextEditor, isError, isInline]);

    useEffect(() => {
      richTextEditor?.setOptions({
        extensions: [
          CharacterCount.configure({
            limit: maxLength || 0,
            mode: 'textSize',
          }),
        ],
        content,
      });
    }, [content, maxLength, richTextEditor]);

    const editorContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      forwardedRef,
      () => ({
        scrollIntoViewAndFocus() {
          editorContainerRef.current?.scrollIntoView({ block: 'center' });
        },
      }),
      []
    );
    const submitButtonRef = useRef<HTMLButtonElement>(null);

    return (
      <div
        ref={editorContainerRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
            submitButtonRef.current?.click();
          }
        }}
        className="relative"
      >
        <button
          className="sr-only"
          tabIndex={-1}
          type="submit"
          ref={submitButtonRef}
        >
          Submit
        </button>
        {label && (
          <label className="text-sm text-gray-700 mb-1.5">{label}</label>
        )}
        <TiptapEditorProvider
          preventClearHistory
          options={{
            extensions: [
              ...defaultExtensions,
              CustomLink.configure({
                isLinkOnly: true,
              }),
              SimpleDocument,
              Placeholder.configure({
                placeholder,
              }),
              CharacterCount.configure({
                limit: maxLength || 0,
                mode: 'textSize',
              }),
            ],
            content,
            onUpdate: handleUpdate as any, // need this hack as the types for tiptap/core and tiptap/react are not the same
            onCreate: handleCreate as any,
            editorProps: {
              attributes: {
                class: 'minimal',
              },
            },
          }}
        >
          <TiptapEditor />
          <TiptapTextBubbleMenu disableTextStyling={disableTextStyling} />
          <div className="mt-1 text-sm text-right text-gray-600">
            <TiptapEditorConsumer>
              {({ editor }) =>
                hideCharsCount ? null : (
                  <span>
                    {editor?.storage.characterCount.characters()} / {maxLength}
                  </span>
                )
              }
            </TiptapEditorConsumer>
          </div>
          {children}
        </TiptapEditorProvider>
      </div>
    );
  }
);
