import { yupResolver } from '@hookform/resolvers/yup';
import { pageSettingsModel } from '../../../../features/page-settings';
import { pagesModel } from '../../../../features/pages';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, object, string } from 'yup';
import {
  DebounceSlugInput,
  Icon,
  ImageInput,
  Input,
} from '../../../../shared/ui';
import { InputBlock } from '../../../../features/page-settings/ui/components';
import cn from 'classnames';

import { debounce } from 'lodash';
import { LabelToggle } from '../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../shared/sprite';
import { editorSidebarModel } from '../../../../features/editor-sidebar';

type PageSettingsForm = {
  isSlugValid: boolean;

  titleTag: string;
  metaDescription: string;
};

type UpdatePageSettingsProps = {
  isSlugValid: boolean;
  titleTag: string;
  metaDescription: string;
  slug: string;
};

const validationSchema = object().shape({
  isSlugValid: boolean(),

  titleTag: string(),
  metaDescription: string(),
});

export const EditorSidebarSettings: React.FC = () => {
  const dispatch = useDispatch();
  const seoConfiguration = useSelector(
    pageSettingsModel.selectors.selectSeoConfigurationWithError
  );
  const isLoading = useSelector(
    pageSettingsModel.selectors.selectUpdateSeoConfigurationIsLoading
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const isUploading = useSelector(
    pageSettingsModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    pageSettingsModel.selectors.selectUploadingImageProgress
  );
  const isRemoving = useSelector(
    pageSettingsModel.selectors.selectIsRemoveImageLoading
  );
  const [slug, setSlug] = useState(currentPage.pageSlug);
  const [validateMessage, setValidateMessage] = useState('');
  const [isViewersCanDuplicateAsTemplate, setIsViewersCanDuplicateAsTemplate] =
    useState(currentPage.isViewersCanDuplicateAsTemplate);

  const [isDistributeBadgeRemoved, setIsDistributeBadgeRemoved] = useState(
    currentPage.isDistributeBadgeRemoved
  );

  const isShowAdvancedSettings = useSelector(
    editorSidebarModel.selectors.selectIsShowAdvancedSettings
  );

  const setIsShowAdvancedSettings = (isOpen: boolean) => {
    dispatch(editorSidebarModel.actions.setIsShowAdvancedSettings(isOpen));
  };

  const { register, watch, setValue } = useForm<PageSettingsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        isSlugValid: true,

        titleTag: seoConfiguration.title,
        metaDescription: seoConfiguration.description,
      }),
      [seoConfiguration.description, seoConfiguration.title]
    ),
  });

  const { titleTag, metaDescription } = watch();
  const titleTagCount = titleTag?.length || 0;
  const metaDescriptionCount = metaDescription?.length || 0;

  const [isPageSlugError, setIsPageSlugError] = useState(false);
  const [isFormSubmittedFirstTime, setIsFormSubmittedFirstTime] =
    useState(false);

  const isPublishSettingsDropdownOpen = useSelector(
    pagesModel.selectors.selectIsPublishSettingsDropdownOpen
  );

  const isSlugValid = watch().isSlugValid;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormSubmit = useCallback(
    debounce((data: UpdatePageSettingsProps) => {
      setIsFormSubmittedFirstTime(true);
      if (validateMessage) return;
      setIsPageSlugError(!data.isSlugValid);
      if (!data.isSlugValid) return;

      dispatch(
        pageSettingsModel.actions.updateSeoConfiguration({
          documentContentId: currentPage.content.id,
          pageId: currentPage.id,
          title: data.titleTag,
          description: data.metaDescription,
          pageSlug: data.slug,
          callback: () => null,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (isPublishSettingsDropdownOpen) return;
    if (
      titleTag !== seoConfiguration.title ||
      metaDescription !== seoConfiguration.description ||
      slug !== currentPage.pageSlug
    ) {
      handleFormSubmit({ titleTag, metaDescription, isSlugValid, slug });
    }
  }, [
    handleFormSubmit,
    isSlugValid,
    metaDescription,
    titleTag,
    slug,
    seoConfiguration.title,
    seoConfiguration.description,
    currentPage.pageSlug,
    isPublishSettingsDropdownOpen,
  ]);

  const onUpdateImage = (image: File) => {
    dispatch(
      pageSettingsModel.actions.changeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
        image,
      })
    );
  };

  const onRemoveImage = () => {
    dispatch(
      pageSettingsModel.actions.removeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
      })
    );
  };

  const handleChangeIsViewersCanDuplicateAsTemplate = (value: boolean) => {
    setIsViewersCanDuplicateAsTemplate(value);
    dispatch(
      pagesModel.actions.updatePage({
        pageId: currentPage.id,
        isViewersCanDuplicateAsTemplate: value,
      })
    );
  };

  const handleChangeIsDistributeBadgeRemoved = (value: boolean) => {
    setIsDistributeBadgeRemoved(value);
    dispatch(
      pagesModel.actions.updatePage({
        pageId: currentPage.id,
        isDistributeBadgeRemoved: value,
      })
    );
  };

  useEffect(() => {
    setIsViewersCanDuplicateAsTemplate(
      currentPage.isViewersCanDuplicateAsTemplate
    );
  }, [currentPage.isViewersCanDuplicateAsTemplate]);

  useEffect(() => {
    setIsDistributeBadgeRemoved(currentPage.isDistributeBadgeRemoved);
  }, [currentPage.isDistributeBadgeRemoved]);

  useEffect(() => {
    setSlug(currentPage.pageSlug);
  }, [currentPage.pageSlug]);

  useEffect(() => {
    if (isFormSubmittedFirstTime) {
      setIsPageSlugError(!isSlugValid);
    }
  }, [isFormSubmittedFirstTime, isSlugValid]);

  return (
    <div className={cn('pt-6 pb-8 px-6 overflow-y-auto h-[calc(100%-64px)]')}>
      <h2 className="text-2xl font-display font-medium text-gray-900">
        Page setup
      </h2>
      <hr className="border-gray-200 mt-5 mb-6" />
      <div className="">
        <div className="pb-6 border-b border-gray-200">
          <p className="text-gray-900 text-md font-semibold mb-3">Slug</p>
          <DebounceSlugInput
            validateMessage={validateMessage}
            setValidateMessage={setValidateMessage}
            slug={slug}
            setSlug={setSlug}
            setIsSlugValid={(val) => setValue('isSlugValid', val)}
            isLoading={isLoading}
            currentSlug={currentPage.pageSlug}
            isError={isPageSlugError}
            showCopy={!!currentPage.published}
          />
        </div>
        <div className="py-6 border-b border-gray-200">
          <p className="text-gray-900 font-semibold mb-3">Settings</p>
          <div className="flex flex-col gap-4">
            <LabelToggle
              icon={IconMap.Copy07}
              text="Allow viewers duplicate page"
              checked={isViewersCanDuplicateAsTemplate}
              onChange={handleChangeIsViewersCanDuplicateAsTemplate}
            />
            <LabelToggle
              icon={IconMap.Star}
              text="Remove “Powered by Distribute” badge"
              checked={isDistributeBadgeRemoved}
              onChange={handleChangeIsDistributeBadgeRemoved}
              availableForPaidPlan
            />
          </div>
        </div>

        <button
          className="pt-6 flex gap-2 text-md text-gray-900 font-semibold"
          onClick={() => setIsShowAdvancedSettings(!isShowAdvancedSettings)}
        >
          <div className="p-0.5">
            <Icon
              glyph={
                isShowAdvancedSettings ? IconMap.ChevronUp : IconMap.ChevronDown
              }
              width={16}
            />
          </div>
          Advanced settings
        </button>
        <div className={cn({ hidden: !isShowAdvancedSettings })}>
          <div className="flex flex-col gap-6 py-6 border-b border-gray-200">
            <InputBlock
              title="Title Tag"
              text="Title the page. Optimal title length is approximately 55 characters."
            >
              <Input
                {...register('titleTag')}
                type="text"
                maxCharacters={55}
                isCounterShow={false}
                messageText={`${titleTagCount} characters`}
                placeholder="Untitled"
              />
            </InputBlock>
            <InputBlock
              title="Meta Description"
              text=" Describe the page. Optimal description length is 155 to 300 characters."
            >
              <Input
                {...register('metaDescription')}
                isTextArea
                textAreaRows={3}
                type="text"
                maxCharacters={300}
                isCounterShow={false}
                messageText={`${metaDescriptionCount} characters`}
              />
            </InputBlock>
          </div>
          <div className="pt-6">
            <InputBlock
              title="Open Graph Image"
              text={
                <>
                  <p>
                    The info that shows up when sharing content on Facebook,
                    Twitter, LinkedIn, and Pinterest.
                  </p>
                  <p>
                    Make sure your images are at least 1200px by 630px and have
                    a 1.91:1 aspect ratio.
                  </p>
                </>
              }
            >
              <ImageInput
                imageUrl={seoConfiguration.ogImage}
                isUploading={isUploading}
                uploadProgress={uploadProgress}
                isRemoving={isRemoving}
                onUpdateImage={onUpdateImage}
                onRemoveImage={onRemoveImage}
                isLabel={false}
                fileInputStyles="h-31"
              />
            </InputBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
