import {
  TWITTER_TOKEN_STORAGE_KEY,
  TWITTER_TOKEN_SECRET_STORAGE_KEY,
  TWITTER_USER_NAME_STORAGE_KEY,
  TWITTER_USER_PICTURE_URL_STORAGE_KEY,
} from '../../../../../shared/constants';
import {
  GetTwitterAccessTokenResponse,
  GetTwitterProfileResponse,
} from '../../types';
import { put } from 'redux-saga/effects';
import { socialModel } from '../..';

export function setTwitterAuthData(data: GetTwitterAccessTokenResponse) {
  localStorage.setItem(TWITTER_TOKEN_STORAGE_KEY, data.accessToken);
  localStorage.setItem(TWITTER_TOKEN_SECRET_STORAGE_KEY, data.accessSecret);
}

export function* removeTwitterAuthData() {
  localStorage.removeItem(TWITTER_TOKEN_STORAGE_KEY);
  localStorage.removeItem(TWITTER_TOKEN_SECRET_STORAGE_KEY);
  yield put(socialModel.actions.setTwitterProfileName(''));
  yield put(socialModel.actions.setTwitterProfilePicture(''));
  sessionStorage.removeItem(TWITTER_USER_NAME_STORAGE_KEY);
  sessionStorage.removeItem(TWITTER_USER_PICTURE_URL_STORAGE_KEY);
}

export function checkIfTwitterProfileDataExists() {
  return !!sessionStorage.getItem(TWITTER_USER_NAME_STORAGE_KEY);
}

export function* setTwitterProfileDataFromStorage() {
  yield put(
    socialModel.actions.setTwitterProfileName(
      sessionStorage.getItem(TWITTER_USER_NAME_STORAGE_KEY) ?? ''
    )
  );
  yield put(
    socialModel.actions.setTwitterProfilePicture(
      sessionStorage.getItem(TWITTER_USER_PICTURE_URL_STORAGE_KEY) ?? ''
    )
  );
}

export function* setTwitterProfileData(data: GetTwitterProfileResponse) {
  sessionStorage.setItem(TWITTER_USER_NAME_STORAGE_KEY, data.data.name);
  sessionStorage.setItem(
    TWITTER_USER_PICTURE_URL_STORAGE_KEY,
    data.data.profile_image_url
  );
  yield put(socialModel.actions.setTwitterProfileName(data.data.name));
  yield put(
    socialModel.actions.setTwitterProfilePicture(data.data.profile_image_url)
  );
}
