export const UPLOAD_FORM_WIDTH = 640;

export const IMAGE_VIEW_TYPES = ['JPG', 'PNG', 'GIF', 'BMP', 'WEBP', 'TIFF'];
export const IMAGE_TYPES = [...IMAGE_VIEW_TYPES, 'JPEG'];
export const IMAGE_ACCEPT_STRING = IMAGE_TYPES.map(
  (type) => `image/${type.toLowerCase()}`
).join(',');

export const PDF_TYPES = ['PDF'];
export const PDF_ACCEPT_STRING = 'application/pdf';

export const VIDEO_VIEW_TYPES = ['MP4', 'MOV', 'WEBM', 'OGG'];
export const VIDEO_TYPES = [...VIDEO_VIEW_TYPES, 'quicktime'];
export const VIDEO_ACCEPT_STRING = VIDEO_TYPES.map(
  (type) => `video/${type.toLowerCase()}`
).join(',');

export const uploadingConfig = {
  file: {
    accept: PDF_ACCEPT_STRING,
    viewAllowedTypes: PDF_TYPES,
    allowedTypes: PDF_TYPES,
    regexpType: 'application',
    inputMessage:
      'Paste the direct URL for a PDF file. Links from Google Drive are not supported.',
    maxSize: '5 MB',
  },
  video: {
    accept: VIDEO_ACCEPT_STRING,
    viewAllowedTypes: VIDEO_VIEW_TYPES,
    allowedTypes: VIDEO_TYPES,
    regexpType: 'video',
    inputMessage: 'Paste the direct URL for a video on YouTube or Vimeo.',
    maxSize: '50 MB',
  },
  image: {
    accept: IMAGE_ACCEPT_STRING,
    viewAllowedTypes: IMAGE_VIEW_TYPES,
    allowedTypes: IMAGE_TYPES,
    regexpType: 'image',
    inputMessage: 'Paste the direct URL for an image found on the internet.',
    maxSize: '5 MB',
  },
} as const;
