import { VideoRecordStatusMp4 } from '@distribute/shared/types';

type Params = {
  urlMp4: string;
  urlMux: string;
  preferMux: boolean;
  statusMp4: VideoRecordStatusMp4;
};

export const getRecordUrl = ({
  urlMp4,
  urlMux,
  preferMux,
  statusMp4,
}: Params) => {
  if (preferMux) return urlMux;
  return [VideoRecordStatusMp4.COMPLETED].includes(statusMp4) ? urlMp4 : urlMux;
};

export const DEFAULT_RATIO = '16/9';
