import React, { FC, useState } from 'react';
import { Popover } from '../Popover';
import { Trigger } from '@radix-ui/react-popover';
import { ColorPickerInput } from './ColorPickerInput';
import { ColorSelector } from './ColorSelector';

type IProps = {
  value: string;
  onChange: (color: string) => void;
};
export const ColorPicker: FC<IProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <Popover
      open={isOpen}
      handleClickCancel={handleClose}
      triggerComponent={
        <Trigger
          className="outline-none w-full"
          onClick={() => setIsOpen(true)}
        >
          <ColorPickerInput color={value} />
        </Trigger>
      }
      side="bottom"
      align="start"
      isRenderInPortal={false}
      contentStyles="!z-10"
    >
      <ColorSelector
        value={value}
        isUseClickOutside={false}
        onChange={onChange}
        onClose={handleClose}
      />
    </Popover>
  );
};
