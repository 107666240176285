import { put, select } from 'redux-saga/effects';
import { pagesModel } from '..';
import { pagesApi } from '../../../../shared/api';
import { Page } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateTeamMemberPermission({
  payload: { pageId, permission },
}: ReturnType<typeof pagesModel.actions.updateTeamMemberPermission>) {
  try {
    const page: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    yield pagesApi.updateTeamMemberPermission(pageId, {
      permission,
    });
    yield put(
      pagesModel.actions.setCurrentPage({
        ...page,
        teamMembersPermission: permission,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team member permission')
      )
    );
  }
}
