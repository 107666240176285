import { VideoQuality } from '@distribute/shared/types';
import { extension } from 'mime-types';

/* Path to the files:

1. record/teamId:/prefix:/video.webm
2. record/teamId:/prefix:/[720p/1080p]/video.mp4
3. record/teamId:/prefix:/poster.jpg

*/

type Params = {
  teamId: number;
  prefix: string;
  slash?: boolean;
};

export class VideoRecordShortcut {
  private readonly baseFolder = 'record';
  private readonly storage: string;

  constructor(storage: string) {
    this.storage = storage;
  }

  createPathToFolder({ teamId, prefix, slash = false }: Params) {
    const path = [this.baseFolder, teamId, prefix].join('/');
    return `${path}${slash ? '/' : ''}`;
  }

  createPathToUploadWebmURL = ({
    teamId,
    prefix,
    uploadId,
  }: Params & { uploadId: string }) => {
    const url = [
      this.storage,
      this.baseFolder,
      teamId,
      prefix,
      `video.${extension('video/webm')}`,
    ].join('/');
    return `${url}?upload_id=${uploadId}`;
  };

  createPathToWebm = ({ teamId, prefix }: Params) => {
    return [
      this.baseFolder,
      teamId,
      prefix,
      `video.${extension('video/webm')}`,
    ].join('/');
  };

  createPathToWebmURL = ({ teamId, prefix }: Params) => {
    return [
      this.storage,
      this.baseFolder,
      teamId,
      prefix,
      `video.${extension('video/webm')}`,
    ].join('/');
  };

  createPathToMp4 = ({
    teamId,
    prefix,
    videoQuality,
  }: Params & { videoQuality: VideoQuality }) => {
    return [
      this.baseFolder,
      teamId,
      prefix,
      videoQuality,
      `video.${extension('video/mp4')}`,
    ].join('/');
  };

  createPathToMp4URL = ({
    teamId,
    prefix,
    videoQuality,
  }: Params & { videoQuality: VideoQuality }) => {
    return [
      this.storage,
      this.baseFolder,
      teamId,
      prefix,
      videoQuality,
      `video.${extension('video/mp4')}`,
    ].join('/');
  };

  createPathToPoster = ({ teamId, prefix }: Params) => {
    return [this.storage, this.baseFolder, teamId, prefix, 'poster.jpg'].join(
      '/'
    );
  };

  getOptionsMp4 = (data: Params & { videoQuality: VideoQuality }) => {
    const src = this.createPathToMp4URL(data);
    const poster = this.createPathToPoster(data);

    return { src, poster };
  };
}
