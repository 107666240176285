import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { AnalyticsEvents, Page } from '@distribute/shared/types';
import { viewersApi } from '../../../../shared/api';
import { downloadFile, logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { viewersModel } from '../';
import moment from 'moment';
import { analytics } from '../../../../entities/analytics';
import { pagesModel } from '../../../pages';

type ExportCsvResponse = {
  data: Blob;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
};

export function* exportCsv({
  payload: { search },
}: ReturnType<typeof viewersModel.actions.exportCsv>) {
  try {
    const { id: pageId }: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );

    const {
      category,
      isShowAnonymous,
      range: { from, to },
    }: ReturnType<typeof viewersModel.selectors.selectViewers> = yield select(
      viewersModel.selectors.selectViewers
    );

    yield put(actions.setIsExportCsvLoading(true));

    const { data, headers }: ExportCsvResponse = yield call(
      viewersApi.exportCsv,
      pageId,
      { search, from, to, category, isShowAnonymous }
    );
    const fileName = `leads-export-${moment(new Date()).format(
      'YYYY_MM_DD_HH_mm_ss'
    )}`;

    downloadFile(data, headers, fileName);

    analytics.track(AnalyticsEvents.LEADS_LIST_EXPORTED, {
      pageId,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Can't export due to error`)
      )
    );
  } finally {
    yield put(actions.setIsExportCsvLoading(false));
  }
}
