import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mixed, number, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Snippet,
  SnippetCategoryWithSnippets,
  SnippetType,
  SnippetsSharingAccess,
} from '@distribute/shared/types';
import { snippetsModel } from '../../../model';
import { useSnippetsPermissions } from '../../../../../features/teams';
import { SelectCategoryDropdown } from './SelectCategoryDropdown';
import { Button, Input } from '../../../../../shared/ui';
import { RadioButtonForPermissions } from '../../../../../widgets/editor-sidebar/ui/components/RadioButtonForPermissions';
import { navigate } from '../../../../../processes/navigation';
import { SNIPPETS_ROUTE } from '../../../../../entities/history';
import { cn } from '@distribute/frontend/utils';
import { isAllowSwitchSharingAccess } from '../../../lib/helpers';

type Props = {
  onClose: () => void;
  category?: SnippetCategoryWithSnippets;
  type: SnippetType;
  url?: string;
  snippet?: Snippet;
  isHideSubmitButtons?: boolean;
  isEditor?: boolean;
  isSnippetsModal?: boolean;
};

export type ConfigureSnippetFormType = {
  name: string;
  description: string;
  sharingAccess: SnippetsSharingAccess;
  categoryId?: number;
};

export const validationSchemaSnippetConfig = object().shape({
  name: string().required('Please input snippet name.'),
  description: string().optional(),
  sharingAccess: mixed<SnippetsSharingAccess>().oneOf(
    Object.values(SnippetsSharingAccess)
  ),
  categoryId: number()
    .optional()
    .when('sharingAccess', {
      is: SnippetsSharingAccess.TEAM,
      then: number().required('Please select snippet category.'),
    }),
});

export const ConfigureSnippetForm: FC<Props> = ({
  category,
  type,
  onClose,
  snippet,
  url,
  isHideSubmitButtons = false,
  isEditor = false,
  isSnippetsModal = false,
}) => {
  const dispatch = useDispatch();

  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  ) as Snippet;

  const isCreateLoading = useSelector(
    snippetsModel.selectors.selectIsCreateSnippetLoading
  );

  const isUpdateLoading = useSelector(
    snippetsModel.selectors.selectIsUpdateSnippetLoading
  );

  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();

  const defaultSharingAccessValue =
    snippet?.sharingAccess ||
    (category || !isCanCreatePersonalSnippets
      ? SnippetsSharingAccess.TEAM
      : SnippetsSharingAccess.PERSONAL);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ConfigureSnippetFormType>({
    resolver: yupResolver(validationSchemaSnippetConfig),
    defaultValues: {
      name: snippet?.name ?? '',
      description: snippet?.description ?? '',
      sharingAccess: defaultSharingAccessValue,
      categoryId: snippet?.category?.id ?? category?.id,
    },
  });

  const {
    field: { value: nameValue, onChange: setName },
  } = useController({
    control,
    defaultValue: snippet?.name ?? '',
    name: 'name',
  });

  const onChangeName = useCallback(
    (value: string) => {
      setName(value);

      if (!currentSnippet) return;

      dispatch(
        snippetsModel.actions.setCurrentSnippet({
          ...currentSnippet,
          name: value,
        })
      );

      dispatch(snippetsModel.actions.setIsSnippetSavedInEditor(false));
    },
    [currentSnippet, dispatch, setName]
  );

  const {
    field: { value: descValue, onChange: setDescription },
  } = useController({
    control,
    defaultValue: snippet?.description ?? '',
    name: 'description',
  });

  const onChangeDescription = useCallback(
    (value: string) => {
      setDescription(value);

      if (!currentSnippet) return;

      dispatch(
        snippetsModel.actions.setCurrentSnippet({
          ...currentSnippet,
          description: value,
        })
      );

      dispatch(snippetsModel.actions.setIsSnippetSavedInEditor(false));
    },
    [currentSnippet, dispatch, setDescription]
  );

  const {
    field: { value: categoryIdValue, onChange: setCategoryId },
  } = useController({
    control,
    defaultValue: snippet?.category?.id ?? category?.id,
    name: 'categoryId',
  });

  const {
    field: { value: sharingAccessValue, onChange: setSharingAccess },
  } = useController({
    control,
    defaultValue: defaultSharingAccessValue,
    name: 'sharingAccess',
  });

  const onChangeSharingAccess = useCallback(
    (value: SnippetsSharingAccess) => {
      setSharingAccess(value);
      if (value === SnippetsSharingAccess.PERSONAL) {
        setCategoryId(undefined);
      }

      if (!currentSnippet) return;

      dispatch(
        snippetsModel.actions.setCurrentSnippet({
          ...currentSnippet,
          sharingAccess: value,
          category:
            value === SnippetsSharingAccess.PERSONAL
              ? null
              : { ...currentSnippet.category },
        })
      );

      dispatch(snippetsModel.actions.setIsSnippetSavedInEditor(false));
    },
    [setCategoryId, setSharingAccess, currentSnippet, dispatch]
  );

  useEffect(() => {
    if (!currentSnippet) {
      return;
    }

    if (
      currentSnippet.sharingAccess === SnippetsSharingAccess.PERSONAL &&
      !isCanCreatePersonalSnippets &&
      isCanManageSnippets
    ) {
      setSharingAccess(SnippetsSharingAccess.TEAM);

      dispatch(
        snippetsModel.actions.setCurrentSnippet({
          ...currentSnippet,
          sharingAccess: SnippetsSharingAccess.TEAM,
          category: null,
        })
      );
    }
  }, [
    dispatch,
    currentSnippet,
    currentSnippet?.sharingAccess,
    isCanCreatePersonalSnippets,
    isCanManageSnippets,
    setSharingAccess,
  ]);

  const isVisibleSharingAccessButtons =
    isCanCreatePersonalSnippets && isCanManageSnippets;

  useEffect(() => {
    if (currentSnippet) {
      setName(currentSnippet.name);
      setDescription(currentSnippet.description);
      setSharingAccess(currentSnippet.sharingAccess);
      setCategoryId(currentSnippet.category?.id);
    }
  }, [currentSnippet]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleCreateFormSubmit = (data: ConfigureSnippetFormType) => {
    if (!url) {
      return;
    }

    if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
      return;
    }

    dispatch(
      snippetsModel.actions.createSnippet({
        ...data,
        url,
        type,
        callback: () => {
          handleClose();
          if (!isSnippetsModal) {
            dispatch(
              navigate({ to: `${SNIPPETS_ROUTE}/${data.sharingAccess}` })
            );
          }
        },
      })
    );
  };

  const handleEditFormSubmit = (data: ConfigureSnippetFormType) => {
    if (!snippet || isHideSubmitButtons) {
      return;
    }

    if (
      data.sharingAccess === SnippetsSharingAccess.TEAM &&
      snippet?.sharingAccess === SnippetsSharingAccess.PERSONAL &&
      !snippet.isDraft
    ) {
      dispatch(snippetsModel.actions.setUpdatingSnippetData(data));

      return;
    }

    dispatch(
      snippetsModel.actions.updateSnippet({
        snippet,
        snippetId: snippet?.id,
        isEditor,
        ...data,
        callback: () => {
          handleClose();

          if (!isEditor) {
            dispatch(
              navigate({ to: `${SNIPPETS_ROUTE}/${data.sharingAccess}` })
            );
          } else {
            dispatch(
              snippetsModel.actions.setCurrentSnippet({
                ...currentSnippet,
                isDraft: false,
              })
            );
          }
        },
      })
    );
  };

  const handleFormSubmit = snippet
    ? handleEditFormSubmit
    : handleCreateFormSubmit;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-4">
          <Input
            autoComplete="off"
            label="Snippet name *"
            isError={!!errors.name}
            messageText={errors.name?.message}
            type="text"
            heightSize="md"
            counterValue={nameValue.length}
            maxCharacters={64}
            isCounterShow={!errors.name}
            onChange={(e) => {
              onChangeName(e.target.value);
            }}
            value={nameValue}
          />
          <Input
            autoComplete="off"
            label="Description"
            isError={!!errors.description}
            messageText={errors.description?.message}
            placeholder="Provide instructions for your team on how to use this snippet. "
            type="text"
            isTextArea
            textAreaRows={3}
            textAreaMaxRows={3}
            maxCharacters={120}
            className="!h-23.5 max-h-23.5 min-h-23.5 flex-shrink-0 flex-grow-0"
            counterValue={descValue.length}
            isCounterInside
            onChange={(e) => {
              onChangeDescription(e.target.value);
            }}
            value={descValue}
          />
        </div>

        {isVisibleSharingAccessButtons && (
          <div>
            <label className="block mb-3 font-semibold text-gray-700 text-md">
              Who can see and use this snippet?
            </label>
            <div className="flex items-center gap-4">
              {Object.values(SnippetsSharingAccess).map((value) => (
                <RadioButtonForPermissions
                  name="sharing-access"
                  key={value}
                  value={value}
                  onChange={() => onChangeSharingAccess(value)}
                  defaultChecked={value === sharingAccessValue}
                  disabled={
                    snippet && !isAllowSwitchSharingAccess(value, snippet)
                  }
                />
              ))}
            </div>
          </div>
        )}

        {sharingAccessValue === SnippetsSharingAccess.TEAM && (
          <SelectCategoryDropdown
            setCategoryId={setCategoryId}
            snippetType={type}
            categoryId={categoryIdValue}
            errorText={errors.categoryId?.message}
          />
        )}
      </div>

      <div
        className={cn(
          'absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white',
          {
            hidden: isHideSubmitButtons,
          }
        )}
      >
        <Button
          fullWidth
          color="secondary"
          variant="text"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="text"
          fullWidth
          onClick={handleSubmit(handleFormSubmit)}
          loading={isCreateLoading || isUpdateLoading}
        >
          {snippet ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
