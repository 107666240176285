/* Path to the files:

1. https://stream.mux.com/{PLAYBACK_ID}.m3u8 - video
2. https://image.mux.com/{PLAYBACK_ID}/thumbnail.png - image
3. https://image.mux.com/{PLAYBACK_ID}/animated.[webp|gif] - gif

*/

const streamPath = 'https://stream.mux.com';
const imagePath = 'https://image.mux.com';

export const getMuxStream = (id: string) => {
  return [streamPath, `${id}.m3u8?min_resolution=720p`].join('/');
};

export const getMuxImage = (id: string) => {
  return [imagePath, id, 'thumbnail.png'].join('/');
};

export const getMuxPreview = (id: string) => {
  return [imagePath, id, 'animated.gif?width=640&end=4'].join('/');
};
