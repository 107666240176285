import { IconMap } from '../../../../shared/sprite';
import { Button } from '../../../../shared/ui';
import React from 'react';

type Props = {
  onClick(): void;
};

export const PageCoverButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="py-3.5 px-4">
      <Button
        variant="icon-text"
        color="secondary"
        size="sm"
        iconLeftName={IconMap.ImagePlus}
        iconLeftWidth={20}
        className="font-semibold !px-3 ml-auto"
        iconBlockClassName="mr-1.5"
        onClick={onClick}
      >
        Add cover
      </Button>
    </div>
  );
};
