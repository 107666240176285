import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegenerateApiKeyAction } from './types';

type State = {
  isZapierLoading: boolean;
};

const initialState: State = {
  isZapierLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIsZapierLoading: (
      state,
      { payload: isZapierLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isZapierLoading,
    }),
  },
});

export const actions = {
  ...reducerActions,
  regenerateApiKey: createAction<RegenerateApiKeyAction>(
    'integrations/regenerateApiKey'
  ),
};
