import { call, put } from 'redux-saga/effects';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* requestDeleteTeam({
  payload: { teamId, cb },
}: ReturnType<typeof actions.requestDeleteTeam>) {
  try {
    yield put(actions.setIsRequestingDeleteTeam(true));
    yield call(teamsApi.requestDelete, teamId);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Please check inbox and follow the link to confirm team deletion'
        )
      )
    );
    yield call(cb);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to delete the team')
        )
      )
    );
  } finally {
    yield put(actions.setIsRequestingDeleteTeam(false));
  }
}
