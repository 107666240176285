import { call, select } from 'redux-saga/effects';
import {
  TeamFiltered,
  TeamOnboardingStatus,
  UserOnboardingStatus,
  UserToTeamFiltered,
} from '@distribute/shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { redirect } from '../../../../entities/history';
import { DBUser } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { getTeamPermissions } from '../../../teams/lib';

export function* handleOnboarding() {
  const dbUser: DBUser = yield select(authUserModel.selectors.selectUser);
  const currentTeam: TeamFiltered | undefined = yield select(
    teamsModel.selectors.selectCurrentTeam
  );
  const currentTeamMembers: UserToTeamFiltered[] = yield select(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const currentTeamPermissions = getTeamPermissions(dbUser, currentTeamMembers);

  if (dbUser.onboardingStatus === UserOnboardingStatus.VERIFY_EMAIL) {
    yield call(redirect.toEmailVerification);

    return false;
  } else if (
    dbUser.onboardingStatus ===
    UserOnboardingStatus.SUCCESSFULLY_CREATED_ACCOUNT
  ) {
    yield call(redirect.toSuccessfullyCreatedAppsumoAccount);
  } else if (
    dbUser.onboardingStatus !== UserOnboardingStatus.FINISHED ||
    (currentTeamPermissions.isOwner &&
      currentTeam?.onboardingStatus !== TeamOnboardingStatus.FINISHED)
  ) {
    yield call(redirect.toOnboarding);

    return false;
  }

  return true;
}
