import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { gettingStartedModel } from '..';
import { teamsModel } from '../../../teams';

export function* closeStatusPanel({
  payload: { teamId },
}: ReturnType<typeof gettingStartedModel.actions.closeStatusPanel>) {
  try {
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );

    yield put(
      teamsModel.actions.setCurrentTeam({
        ...currentTeam,
        currentMember: {
          ...currentTeam.currentMember,
          isGettingStartedClosed: true,
        },
      })
    );
    yield put(
      teamsModel.actions.setTeams(
        teams.map((team) =>
          team.id === currentTeam.id
            ? {
                ...team,
                currentMember: {
                  ...currentTeam.currentMember,
                  isGettingStartedClosed: true,
                },
              }
            : team
        )
      )
    );
    yield call(teamsApi.updateCurrentTeamMember, teamId, {
      isGettingStartedClosed: true,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Can't close due to error`)
      )
    );
  }
}
