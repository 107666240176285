import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { Tooltip } from '../../../../../shared/ui';
import { useOnClickOutside } from '../../../../../shared/hooks/useClickOutside';
import { RichTextEditorDropdownItemType } from './types';
import cn from 'classnames';
import { RichTextEditorDropdownItem } from './RichTextEditorDropdownItem';
import { RichTextEditorDropdownHeader } from './RichTextEditorDropdownHeader';

type Props = {
  items: RichTextEditorDropdownItemType[];
  selectedItemId: string | string[];
  dropdownStyles?: string;
  itemStyles?: string;
  tooltip?: string | ReactNode;
  triggerComponent?: ReactNode;
  isShowIconForSelectedItem?: boolean;
};

export const RichTextEditorDropdownWithTooltip: React.FC<Props> = ({
  tooltip,
  ...props
}) => {
  return (
    <Tooltip
      trigger={<RichTextEditorDropdown {...props} />}
      hideArrow
      sideOffset={8}
    >
      {tooltip}
    </Tooltip>
  );
};

export const RichTextEditorDropdown: React.FC<Props> = ({
  items,
  selectedItemId,
  dropdownStyles,
  itemStyles,
  triggerComponent,
  isShowIconForSelectedItem,
}) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const checkIsEqToSelectedItemId = useCallback(
    (id: string) => {
      if (Array.isArray(selectedItemId)) {
        return selectedItemId.includes(id);
      }

      return id === selectedItemId;
    },
    [selectedItemId]
  );

  const selectedItem = items.find((item) => checkIsEqToSelectedItemId(item.id));

  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  return (
    <div ref={dropdownRef} className="relative">
      <RichTextEditorDropdownHeader
        isOpen={isOpen}
        onClick={() => setOpen((prev) => !prev)}
      >
        {triggerComponent ?? <p>{selectedItem?.value}</p>}
      </RichTextEditorDropdownHeader>
      {isOpen && (
        <div
          className={cn(
            'absolute w-max bg-base-white rounded-lg border border-gray-200 py-1.5 shadow-lg top-9 left-0',
            dropdownStyles
          )}
        >
          {items.map((item) => (
            <RichTextEditorDropdownItem
              key={item.id}
              item={item}
              itemStyles={itemStyles}
              isShowIconForSelectedItem={isShowIconForSelectedItem}
              isSelected={
                isShowIconForSelectedItem && checkIsEqToSelectedItemId(item.id)
              }
              handleClick={() => setOpen(false)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
