import React, {
  FC,
  KeyboardEvent,
  useRef,
  useState,
  ChangeEvent,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { IconMap } from '../../../../../../shared/sprite';
import { Avatar, Icon, SelectOptionUser } from '../../../../../../shared/ui';
import { useOnClickOutside } from '../../../../../../shared/hooks/useClickOutside';
import { nanoid } from '@reduxjs/toolkit';
import { contains } from '../../../../../../utils/string';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export type MemberItemValue = {
  id: string;
  email: string;
  name: string;
  logo?: string;
  isError?: boolean;
};

type Props = {
  selectedItems: MemberItemValue[];
  items: MemberItemValue[];
  isError?: boolean;
  onChange: (value: MemberItemValue[]) => void;
  validateItemValue?: (name: string) => string[] | undefined;
};

export const MemberSearchInput: FC<Props> = ({
  selectedItems,
  items,
  isError,
  onChange,
  validateItemValue,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);

  const inputElement = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLUListElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (![' ', ','].includes(value)) {
      setSearchValue(value);
    }
  };

  const handleFocusInput = () => {
    inputElement.current?.focus();
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Space', 'Comma'].includes(e.code) && searchValue.trim()) {
      const trimmedValue = searchValue.trim();
      const errors = validateItemValue?.(trimmedValue);
      const newItem = {
        id: nanoid(),
        email: trimmedValue,
        name: trimmedValue,
        isError: !!errors,
      };

      onChange([...selectedItems, newItem]);
      setSearchValue('');
      handleFocusInput();
      return;
    }

    if (['Backspace', 'Delete'].includes(e.code) && !searchValue.trim()) {
      onChange(selectedItems.slice(0, -1));
    }
  };

  const handleSelect = (item: MemberItemValue) => {
    const errors = validateItemValue?.(item.email);
    onChange([...selectedItems, { ...item, isError: !!errors }]);
    setSearchValue('');
    handleFocusInput();
  };

  const handleDeleteTag = (id: string) => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    onChange(updatedItems);
  };

  useOnClickOutside(containerRef, () => setOpen(false), undefined, [
    contentRef,
  ]);

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) =>
          contains(item.email, searchValue) &&
          !selectedItems.some((selected) => selected.email === item.email)
      ),
    [searchValue, selectedItems, items]
  );

  const handleToggleDropdown = (isOpen: boolean) => {
    if (!isOpen && inputElement.current !== document.activeElement) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <DropdownMenu.Root
      modal={false}
      open={isOpen}
      onOpenChange={handleToggleDropdown}
    >
      {!!filteredItems.length && (
        <DropdownMenu.Content align="start" className="w-96">
          <ul
            ref={contentRef}
            className="bg-base-white rounded-lg absolute -bottom-1 translate-y-full left-0 right-0 border border-gray-200 shadow-lg py-1 px-1.5"
          >
            {filteredItems.map((item) => (
              <li
                key={item.email}
                className="py-2.5 pl-2 pr-2.5 rounded-md hover:bg-gray-50 cursor-pointer"
                onClick={() => handleSelect(item)}
              >
                {
                  <SelectOptionUser
                    displayName={item.name}
                    photoUrl={item.logo}
                    email={item.email}
                  />
                }
              </li>
            ))}
          </ul>
        </DropdownMenu.Content>
      )}
      <DropdownMenu.Trigger className="w-96 cursor-text">
        <div
          onClick={handleFocusInput}
          className={classNames(
            'group/input flex flex-wrap items-center gap-1.5 border border-gray-300 rounded-lg bg-base-white pl-2 py-[5px] min-h-10 overflow-auto relative',
            { '!border-error-600': isError },
            { '!border-primary-600': isOpen && !isError }
          )}
          data-container
          ref={containerRef}
        >
          {selectedItems.map(({ id, name, logo, isError }) => (
            <div
              key={id}
              className={classNames(
                'flex gap-1.5 items-center bg-base-white border border-gray-300 rounded-md px-1.5 py-0.5 text-sm font-medium text-gray-700 overflow-hidden',
                { '!border-error-600': isError }
              )}
            >
              <Avatar
                isUserAvatar
                displayName={name}
                src={logo}
                size={'3xs'}
                className="shrink-0"
                hideBorder
              />
              <span className="text-gray-900 text-sm font-medium truncate">
                {name}
              </span>
              <Icon
                glyph={IconMap.XClose}
                width={14}
                className="shrink-0 text-gray-400 cursor-pointer"
                onClick={() => handleDeleteTag(id)}
              />
            </div>
          ))}
          <input
            ref={inputElement}
            value={searchValue}
            size={searchValue.length + 10}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className="text-md text-gray-900 py-0.5 outline-none ml-1"
          />
          {!searchValue && !selectedItems.length && (
            <p className="absolute left-4 top-2 -mt-px text-gray-500 pointer-events-none">
              Type an email or member name
            </p>
          )}
        </div>
      </DropdownMenu.Trigger>
    </DropdownMenu.Root>
  );
};
