import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import { teamsModel } from '../model';
import { getTeamPermissions } from '../lib';

export const useTeamPermissions = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const currentTeamMember = useSelector(
    teamsModel.selectors.selectUserTeamMemberData
  );

  const permissions = useMemo(() => {
    return getTeamPermissions(currentUser, currentTeamMembers);
  }, [currentUser, currentTeamMembers]);

  return { ...permissions, currentUserToTeam: currentTeamMember };
};
