export enum ActionTypeAlertBar {
  LINK = 'link',
  POPUP = 'popup',
}

export type AlertBar = {
  id: string;
  isActive: boolean;
  description: string;
  actionType: ActionTypeAlertBar;
  buttonLabelLinkType?: string;
  buttonLabelPopupType?: string;
  buttonUrl?: string;
};
