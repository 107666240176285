import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';

type State = {
  isOpen: boolean;
};

const initialState: State = {
  isOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'gatedContentModel',
  initialState,
  reducers: {
    setIsModalOpen: (state, { payload: isOpen }: PayloadAction<boolean>) => ({
      ...state,
      isOpen,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
};
