import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';

import { Button, Icon, Input } from '.';
import { IconMap } from '../sprite';

import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  onApply: (startDate: Date | null, endDate: Date | null) => void;
  onClose: () => void;
};

export const CustomDate: React.FC<Props> = ({
  startDate,
  endDate,
  onApply,
  onClose,
}) => {
  const [tmpStartDate, setTmpStartDate] = useState(startDate);
  const [tmpEndDate, setTmpEndDate] = useState(endDate);

  const handleChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setTmpStartDate(start);
    setTmpEndDate(end);
  };

  const handleClose = () => {
    setTmpStartDate(startDate);
    setTmpEndDate(endDate);
    onClose();
  };

  const handleApply = () => {
    onApply(tmpStartDate, tmpEndDate);
  };

  return (
    <div className="flex flex-col absolute left-0 w-82 bg-base-white border border-solid border-gray-100 shadow-xl rounded-lg mt-1 z-10">
      <div className="px-6 py-5">
        <DatePicker
          renderCustomHeader={(params) => {
            return (
              <>
                <div className="flex items-center justify-between px-2 py-1.5">
                  <div
                    onClick={params.decreaseMonth}
                    className={classNames('text-gray-500 cursor-pointer', {
                      invisible: params.prevMonthButtonDisabled,
                    })}
                  >
                    <Icon glyph={IconMap.ChevronLeft} width={20} />
                  </div>

                  <div className="text-gray-700 text-base font-semibold select-none">
                    {moment(params.date).format('MMMM YYYY')}
                  </div>
                  <div
                    onClick={params.increaseMonth}
                    className={classNames('text-gray-500 cursor-pointer', {
                      invisible: params.nextMonthButtonDisabled,
                    })}
                  >
                    <Icon glyph={IconMap.ChevronRight} width={20} />
                  </div>
                </div>
                <div className="flex justify-between items-center gap-x-2 text-gray-500 text-base my-3">
                  <Input
                    type="text"
                    value={
                      tmpStartDate
                        ? moment(tmpStartDate).format('MMM D, YYYY')
                        : ''
                    }
                  />
                  <span>–</span>
                  <Input
                    type="text"
                    value={
                      tmpEndDate ? moment(tmpEndDate).format('MMM D, YYYY') : ''
                    }
                  />
                </div>
              </>
            );
          }}
          renderDayContents={(day) => (
            <div className="react-datepicker__day_value">{day}</div>
          )}
          selected={null}
          onChange={handleChangeDate}
          startDate={tmpStartDate}
          endDate={tmpEndDate}
          maxDate={new Date()}
          selectsRange
          inline
        />
      </div>
      <div className="flex gap-3 w-full border-t border-solid border-gray-300 p-4">
        <Button
          className="grow"
          variant="text"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="grow"
          variant="text"
          color="primary"
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
