import { put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api/';
import { Folder } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { reorderFolders } from '../../lib';

export function* reorderFolder({
  payload: { sourceIndex, destinationIndex, folders },
}: ReturnType<typeof foldersModel.actions.reorderFolder>) {
  const allFolders: Folder[] = yield select(
    foldersModel.selectors.selectFolders
  );
  const reorderedFolders = reorderFolders(
    folders,
    sourceIndex,
    destinationIndex
  );
  const updatedAllFolders = allFolders.map((folder) => {
    const folderFromReordered = reorderedFolders.find(
      (reorderedFolder) => reorderedFolder.id === folder.id
    );

    return folderFromReordered ? folderFromReordered : folder;
  });

  yield put(foldersModel.actions.setFolders(updatedAllFolders));
  try {
    yield foldersApi.reorderFolder({
      folders: reorderedFolders,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(foldersModel.actions.setFolders(allFolders));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to reorder folders')
      )
    );
  }
}
