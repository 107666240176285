import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  CalendarProvider,
} from '@distribute/shared/types';
import { IconMap } from '../../../shared/sprite';
import { Icon } from '../../../shared/ui';

export const CONVERSION_TOOLS_TABS = [
  { id: '1', label: 'Native', iconName: IconMap.Target04 },
  {
    id: '2',
    label: 'Integrations',
    iconName: IconMap.PuzzlePiece01,
    disabled: true,
    tooltipText: (
      <div className="text-center text-sm px-2 py-1">
        <p>Stay tuned.</p>
        <p>It will be available soon!</p>
      </div>
    ),
  },
];

export const ALERT_BAR_TABS = [
  {
    id: ActionTypeAlertBar.LINK,
    label: 'Open a Link',
    iconName: IconMap.Link,
    iconWidth: 20,
  },
  {
    id: ActionTypeAlertBar.POPUP,
    label: 'Open a Pop-Up',
    iconName: IconMap.FlexAlignBottom,
    iconWidth: 20,
  },
];

export const CTA_TABS = [
  {
    id: ActionTypeCTA.FORM,
    label: 'Form',
    iconName: IconMap.Rows01,
    iconWidth: 20,
  },
  {
    id: ActionTypeCTA.LINK,
    label: 'Open a Link',
    iconName: IconMap.Link,
    iconWidth: 20,
  },
  {
    id: ActionTypeCTA.POPUP,
    label: 'Open a Pop-Up',
    iconName: IconMap.FlexAlignBottom,
    iconWidth: 20,
  },
  {
    id: ActionTypeCTA.CALENDLY,
    label: 'Calendar',
    iconName: IconMap.CalendarPlus01,
    iconWidth: 20,
  },
];

export const POP_UP_TABS = [
  {
    id: ActionTypePopUp.FORM,
    label: 'Form',
    iconName: IconMap.Rows01,
    iconWidth: 20,
  },
  {
    id: ActionTypePopUp.LINK,
    label: 'Open a Link',
    iconName: IconMap.Link,
    iconWidth: 20,
  },
  {
    id: ActionTypePopUp.CALENDLY,
    label: 'Calendar',
    iconName: IconMap.CalendarPlus01,
    iconWidth: 20,
  },
];

export const CALENDAR_PROVIDERS = [
  {
    id: CalendarProvider.CALENDLY,
    label: (
      <div className="flex items-center gap-x-2">
        <Icon glyph={IconMap.CalendlyLogoSm} width={20} />
        Calendly
      </div>
    ),
  },
  {
    id: CalendarProvider.CHILI_PIPER,
    label: (
      <div className="flex items-center gap-x-2">
        <Icon glyph={IconMap.ChiliPiperLogoSm} width={20} />
        Chili Piper
      </div>
    ),
  },
];

export const CALENDLY_HOW_TO_GET_LINK_URL =
  'https://help.calendly.com/hc/en-us/articles/223193448-Sharing-your-scheduling-link#sharing-your-scheduling-link-0-0';
export const CHILI_PIPER_HOW_TO_GET_LINK_URL =
  'https://help.chilipiper.com/hc/en-us/articles/360056354434-How-to-Use-Booking-Links';
