import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { IconMap } from '../../sprite';
import { Link } from '../Link';
import { environment } from '../../../environments/environment';

type Props = {
  classNameContent?: string;
  hideLogo?: boolean;
  hideImage?: boolean;
};

export const SimpleLayout: FC<PropsWithChildren<Props>> = ({
  children,
  classNameContent,
  hideLogo = false,
  hideImage = false,
}) => {
  return (
    <div className="bg-bg-100 relative min-h-screen overflow-hidden">
      <div className="absolute left-0 pointer-events-none top w-[44%]">
        <img
          src="../../../assets/images/auth-left-bg.png"
          alt="Left img"
          className="w-full md:hidden"
        />
      </div>
      <div className="absolute right-0 pointer-events-none top w-[43.5%]">
        <img
          src="../../../assets/images/auth-right-bg.png"
          alt="Right img"
          className="w-full md:hidden"
        />
      </div>

      <div className="flex flex-col min-h-screen items-center bg-auth-noise bg-repeat md:bg-bg-100 md:bg-none">
        {!hideLogo && (
          <div className="flex self-stretch pt-8 px-8 relative z-10">
            <Link
              target="_blank"
              rel="noreferrer"
              href={environment.landingUrl}
              size="medium"
            >
              <Icon glyph={IconMap.DistributeLogo} />
            </Link>
          </div>
        )}
        <div
          className={classNames(
            'w-110 py-8 flex justify-center flex-col sm:px-4 sm:w-full',
            classNameContent
          )}
        >
          {!hideImage && (
            <div className="flex justify-center pb-8">
              <img
                src="../../../assets/images/auth-logo.png"
                alt="Center img"
                width={84}
              />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
