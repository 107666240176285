import React from 'react';
import { CreatePageWorkflowItem } from './CreatePageWorkflowItem';
import { IconMap } from '../../../../../../shared/sprite';
import { Icon } from '../../../../../../shared/ui';
import { PageCreationWorkflowsEnum } from '../../../../../../entities/create-page-modal/model/types';

export const InitialCreatePageModalStep: React.FC = () => {
  return (
    <div className="flex items-center justify-center flex-col h-full">
      <h2 className="text-display-md text-gray-900 font-medium font-display mb-2 -mt-31 max850:mt-0">
        Create a new page
      </h2>
      <p className="text-gray-600 mb-10">How would you like to get started?</p>
      <div className="flex gap-6 flex-wrap justify-center px-4">
        <CreatePageWorkflowItem
          bgUrl="../../../assets/images/blur-green.png"
          className="create-page-modal-workflow-item-shadows-green"
          icon={
            <Icon
              glyph={IconMap.BlankPageSquare}
              width={24}
              className="text-primary-600"
            />
          }
          name="Start from scratch"
          description="Step out boldly, take a leap, and create something exquisite."
          workflowName={PageCreationWorkflowsEnum.FROM_SCRATCH}
        />
        <CreatePageWorkflowItem
          bgUrl="../../../assets/images/blur-red.png"
          className="create-page-modal-workflow-item-shadows-red"
          icon={
            <img
              src="../../../assets/images/wizard-write.svg"
              alt="Wizard write icon"
              width={24}
            />
          }
          name="Create with AI"
          description="Save time and create pages faster with Al's first draft handling."
          workflowName={PageCreationWorkflowsEnum.WITH_AI}
        />
        <CreatePageWorkflowItem
          bgUrl="../../../assets/images/blur-blue.png"
          className="create-page-modal-workflow-item-shadows-blue"
          icon={
            <Icon
              glyph={IconMap.LayersThree01}
              width={24}
              className="text-blue-600"
            />
          }
          name="Use template"
          description="Utilize one of our variety of templates to design  professional-looking page."
          workflowName={PageCreationWorkflowsEnum.FROM_TEMPLATE}
        />
      </div>
    </div>
  );
};
