import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { teamsModel } from '../../../../teams';
import { DowngradeToFreeFeatures } from '../DowngradeToFreeFeatures';
import { Button, Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';

type Props = {
  onConfirm: () => void;
};

export const Information: FC<Props> = ({ onConfirm }) => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isAppSumoUser = currentTeam.isAppsumo;

  return (
    <div className="flex flex-col gap-5 text-sm text-gray-600">
      {isAppSumoUser ? (
        <div>Your AppSumo lifetime license will be cancelled.</div>
      ) : (
        <div>
          Your plan will be canceled, but is still available until the end of
          your billing period on{' '}
          {moment(stripeSubscription?.currentPeriodEnd).format('MMMM D, YYYY')}.
        </div>
      )}
      <div>
        The following restrictions will apply when plan switches to Free:
      </div>
      <DowngradeToFreeFeatures className="flex-col !gap-2" />
      <div className="flex gap-3 border border-warning-600 bg-warning-25 px-3 py-2 text-warning-700 text-sm rounded-xl">
        <Icon glyph={IconMap.InfoCircle} width={20} />
        <span>
          All your pages will be unpublished and you will be able to publish up
          to 5 pages.
        </span>
      </div>
      {isAppSumoUser ? (
        <div>You won’t be able to renew your AppSumo license.</div>
      ) : (
        <div>If you change your mind, you can renew your subscription.</div>
      )}
      <Button
        fullWidth
        type="submit"
        onClick={onConfirm}
        color="destructive"
        variant="text"
        className="mt-3"
      >
        Continue with cancelation
      </Button>
    </div>
  );
};
