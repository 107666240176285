import { RootState } from '../../../app';

export const selectChangePasswordError = ({
  profileSetting: { changePasswordError },
}: RootState) => changePasswordError;

export const selectIsChangePasswordLoading = ({
  profileSetting: { isChangePasswordLoading },
}: RootState) => isChangePasswordLoading;

export const selectIsChangeFullNameLoading = ({
  profileSetting: { isChangeFullNameLoading },
}: RootState) => isChangeFullNameLoading;

export const selectChangeFullNameError = ({
  profileSetting: { changeFullNameError },
}: RootState) => changeFullNameError;

export const selectUploadPhotoError = ({
  profileSetting: { uploadPhotoError },
}: RootState) => uploadPhotoError;

export const selectIsUploadPhotoLoading = ({
  profileSetting: { isUploadPhotoLoading },
}: RootState) => isUploadPhotoLoading;

export const selectUploadingPhotoProgress = ({
  profileSetting: { uploadingPhotoProgress },
}: RootState) => uploadingPhotoProgress;

export const selectIsRemovePhotoLoading = ({
  profileSetting: { isRemovePhotoLoading },
}: RootState) => isRemovePhotoLoading;

export const selectChangeEmailError = ({
  profileSetting: { changeEmailError },
}: RootState) => changeEmailError;

export const selectIsChangeEmailLoading = ({
  profileSetting: { isChangeEmailLoading },
}: RootState) => isChangeEmailLoading;

export const selectIsUserPasswordCreating = ({
  profileSetting: { isUserPasswordCreating },
}: RootState) => isUserPasswordCreating;

export const selectIsGmailAuthDisconnecting = ({
  profileSetting: { isGmailAuthDisconnecting },
}: RootState) => isGmailAuthDisconnecting;

export const selectIsGmailAuthConnecting = ({
  profileSetting: { isGmailAuthConnecting },
}: RootState) => isGmailAuthConnecting;
