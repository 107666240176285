import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateBrandStylesAction } from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isUpdatingBrandStyles: boolean;
};
const initialState: State = {
  isUpdatingBrandStyles: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'teamBrandStyles',
  initialState,
  reducers: {
    setIsUpdatingBrandStyles: (
      state,
      { payload: isUpdatingBrandStyles }: PayloadAction<boolean>
    ) => ({ ...state, isUpdatingBrandStyles }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updateBrandStyles: createAction<UpdateBrandStylesAction>(
    'teamBrandSettings/updateBrandStyles'
  ),
};
