import { createTypesRegex } from '../../../../../shared/lib';
import { IconMap } from '../../../../../shared/sprite';
import { Button, Icon } from '../../../../../shared/ui';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { v4 } from 'uuid';
import { socialModel } from '../../../../../entities/social-auth';
import {
  IMAGE_ACCEPT_STRING,
  IMAGE_TYPES,
} from '../../../../../shared/constants';

type Props = {
  onUploaded?(url: string): void;
  handleReset?(): void;
};

export const GmailResetUploadCoverDropdown: React.FC<Props> = ({
  onUploaded,
  handleReset,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const validationRegexp = createTypesRegex(IMAGE_TYPES, 'image');
  const dispatch = useDispatch();

  const handleUpload = useCallback(
    (file: File) => {
      dispatch(
        socialModel.actions.uploadPostCover({
          file,
          id: v4(),
          cb: (url) => {
            onUploaded?.(url);
            dispatch(socialModel.actions.setPostCoverLoading(false));
          },
        })
      );
    },
    [dispatch, onUploaded]
  );

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      const { type } = file;

      if (!validationRegexp.test(type)) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File type not allowed')
          )
        );
        return;
      }

      handleUpload(file);
    },
    [validationRegexp, handleUpload, dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (ref.current) ref.current.value = '';

    onValidateAndUpload(file);
  };

  return (
    <div className="group relative cursor-pointer">
      <Button
        variant="icon"
        color="secondary"
        size="sm"
        className="opacity-75 hover:bg-gray-50 group-hover:!opacity-100"
        onClick={() => handleReset?.()}
      >
        <Icon glyph={IconMap.RefreshCw05} width={20} />
      </Button>
      <div className="absolute right-0 top-9 pt-1 h-13 invisible group-hover:visible">
        <ul className="w-36 py-1 px-1.5 rounded-lg border border-gray-200 bg-base-white">
          <li
            className="rounded-lg px-2.5 py-2 hover:bg-gray-50 cursor-pointer flex gap-2 items-center text-gray-700"
            onClick={() => ref.current?.click()}
          >
            <Icon glyph={IconMap.Upload} width={16} />
            <span className="text-sm font-medium">Upload file</span>
          </li>
        </ul>
      </div>
      <input
        ref={ref}
        type="file"
        onChange={onChange}
        accept={IMAGE_ACCEPT_STRING}
        className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
      />
    </div>
  );
};
