import { call, put, select } from 'redux-saga/effects';
import { Pagination } from 'nestjs-typeorm-paginate';
import { viewersApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { viewersModel } from '..';
import { Page, ViewerDetails } from '@distribute/shared/types';
import { ViewerCategory } from '@distribute/shared/api-types/viewers';
import { pagesModel } from '../../../pages';

export function* getViewersData({
  payload: { page = 1, limit = 25, loadingDataSource = 'global' },
}: ReturnType<typeof viewersModel.actions.getViewersData>) {
  const {
    range: { from, to },
    search,
    category,
    isShowAnonymous,
  }: ReturnType<typeof viewersModel.selectors.selectViewers> = yield select(
    viewersModel.selectors.selectViewers
  );

  try {
    const { id: pageId }: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );

    yield put(actions.setPage(page));
    yield put(actions.setLoadingDataSource(loadingDataSource));
    const data: Pagination<ViewerDetails> = yield call(
      viewersApi.getViewers,
      pageId,
      {
        page,
        limit,
        search,
        from,
        to,
        category,
        isShowAnonymous,
      }
    );

    const totalAnonymous: number = yield call(
      viewersApi.getTotalAnonymousViewers,
      pageId
    );

    yield put(
      actions.setViewersData({
        data: data.items,
        totalPages: data.meta.totalPages ?? 0,
        total: data.meta.totalItems ?? 0,
        totalAnonymous,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          `Can't show ${
            category === ViewerCategory.LEAD ? 'leads' : 'recent viewers'
          } due to error`
        )
      )
    );
  } finally {
    yield put(actions.setLoadingDataSource(undefined));
  }
}
