import { object, string } from 'yup';
import { EmbedContentType } from './types';
import { IconMap } from '../../../../shared/sprite';
import { SpriteGlyph } from '../../../../shared/ui/Icon';

export const validationSchema = object({
  link: string().required('Link is required').url('Link must be a valid URL'),
});

type EmbedContentConfigType = {
  [key in EmbedContentType]: {
    title: string;
    description: string;
    placeholder: string;
    icon: SpriteGlyph;
    iconSize?: number;
    marginX?: number;
  };
};

export const EMBED_CONTENT_PICKER_CONFIG: EmbedContentConfigType = {
  [EmbedContentType.IFRAME]: {
    title: 'Embed content',
    description: 'Paste the direct URL for a page found on the internet.',
    placeholder: 'Paste URL of page',
    icon: IconMap.LayoutAlt03,
  },
  [EmbedContentType.YOUTUBE_VIDEO]: {
    title: 'Youtube video',
    description:
      'Paste the link of your Youtube video here to embed it in the page',
    placeholder: 'Paste Youtube video link',
    icon: IconMap.Youtube,
  },
  [EmbedContentType.YOUTUBE_PLAYLIST]: {
    title: 'Youtube video',
    description:
      'Paste the link of your Youtube video here to embed it in the page',
    placeholder: 'Paste Youtube video link',
    icon: IconMap.Youtube,
  },
  [EmbedContentType.GOOGLE_DOCS]: {
    title: 'Google Drive file',
    description:
      'Embed spreadsheets, documents images and more from your drive',
    placeholder: 'Paste Google Drive file link',
    icon: IconMap.GoogleDrive,
  },
  [EmbedContentType.LOOM_VIDEO]: {
    title: 'Loom video',
    description:
      'Paste the link of your Loom video here to embed it in the page',
    placeholder: 'Paste Loom video link',
    icon: IconMap.Loom,
  },
  [EmbedContentType.CALENDLY]: {
    title: 'Calendly',
    description:
      'Add your Calendly Link to allow collaborators to book meetings',
    placeholder: 'Paste Calendly link',
    icon: IconMap.Calendly,
    iconSize: 32,
    marginX: -6,
  },
};
