import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TypeOfWorkForUsagePlanWork,
  UsagePlan,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { onboardingModel } from '../../model';
import { OnboardingWrapper } from './OnboardingWrapper';
import {
  Alert,
  Button,
  Item,
  RadioButton,
  Select,
} from '../../../../shared/ui';
import {
  TYPE_OF_WORK_FOR_USAGE_PLAN_WORK_TO_TEXT,
  USAGE_PLAN_OPTIONS,
} from '../../config';

type UsagePlanForm = {
  usagePlan: UsagePlan;
  typeOfWorkForUsagePlanWork?: TypeOfWorkForUsagePlanWork;
};

const validationSchema = object().shape({
  usagePlan: string().required(
    'Please choose an option that is suitable for you'
  ),
  typeOfWorkForUsagePlanWork: string().when('usagePlan', {
    is: UsagePlan.WORK,
    then: string().required('Choose your option'),
    otherwise: string().nullable(),
  }),
});

export const StepUsagePlan: FC = () => {
  const dispatch = useDispatch();
  const isLoadingUsagePlan = useSelector(
    onboardingModel.selectors.selectIsLoadingUsagePlan
  );
  const { handleSubmit, control } = useForm<UsagePlanForm>({
    resolver: yupResolver(validationSchema),
  });
  const {
    field: { value: usagePlan, onChange: onChangeUsagePlan },
    fieldState: { error: errorUsagePlan },
  } = useController({
    control,
    name: 'usagePlan',
  });
  const {
    field: {
      value: typeOfWorkForUsagePlanWork,
      onChange: onChangeTypeOfWorkForUsagePlanWork,
    },
    fieldState: { error: errorTypeOfWorkForUsagePlanWork },
  } = useController({
    control,
    name: 'typeOfWorkForUsagePlanWork',
  });

  const typeOfWorkForUsagePlanWorkItems = useMemo<Item[]>(
    () =>
      Object.values(TypeOfWorkForUsagePlanWork).map((type) => ({
        id: type,
        title: TYPE_OF_WORK_FOR_USAGE_PLAN_WORK_TO_TEXT[type],
      })),
    []
  );

  const handleSubmitForm = handleSubmit((data) => {
    dispatch(
      onboardingModel.actions.updateUsagePlan({
        usagePlan: data.usagePlan,
        typeOfWorkForUsagePlanWork:
          data.usagePlan === UsagePlan.WORK
            ? data.typeOfWorkForUsagePlanWork
            : undefined,
      })
    );
  });

  return (
    <OnboardingWrapper
      step={UserOnboardingStatus.USAGE_PLAN}
      imageBlockBgSrc="../../../../assets/images/onboarding/bg-img-step-1.jpg"
      imageBlockSrc="../../../../assets/images/onboarding/img-step-1.png"
      imageBlockClassName="translate-x-[3%] translate-y-[15%]"
    >
      <div className="text-display-md text-gray-900 font-medium font-display text-center">
        How are you planning to use Distribute?
      </div>
      {errorUsagePlan && (
        <Alert color="error" className="mt-6">
          {errorUsagePlan.message}
        </Alert>
      )}
      <form onSubmit={handleSubmitForm}>
        <div className="flex flex-col gap-5 mt-6">
          {USAGE_PLAN_OPTIONS.map((item) => (
            <div key={item.id}>
              <RadioButton
                name="usagePlan"
                value={item.id}
                label={item.label}
                defaultChecked={item.id === usagePlan}
                onChange={onChangeUsagePlan}
                className="!text-md"
                uiSize="md"
              />
              {item.id === usagePlan && (
                <div className="text-md text-gray-600 pl-7 mt-0.5">
                  {item.description}
                  {item.id === UsagePlan.WORK && (
                    <div className="mt-2.5">
                      <Select
                        items={typeOfWorkForUsagePlanWorkItems}
                        value={typeOfWorkForUsagePlanWork ?? ''}
                        currentValue={typeOfWorkForUsagePlanWorkItems.find(
                          (i) => i.id === typeOfWorkForUsagePlanWork
                        )}
                        label="What type of work do you do?"
                        onChange={(id) =>
                          onChangeTypeOfWorkForUsagePlanWork(id)
                        }
                        error={errorTypeOfWorkForUsagePlanWork?.message}
                        errorMsg={errorTypeOfWorkForUsagePlanWork?.message}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={() => null}
          disabled={isLoadingUsagePlan}
          loading={isLoadingUsagePlan}
          fullWidth
          className="mt-5"
        >
          Continue
        </Button>
      </form>
    </OnboardingWrapper>
  );
};
