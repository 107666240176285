import React, { FC, useState } from 'react';
import { Button } from '../../../../shared/ui';
import { LeaveTeamConfirmModal } from './LeaveTeamConfirmModal';
import { LeaveTeamNeedUpdateSubscriptionModal } from './LeaveTeamNeedUpdateSubscriptionModal';
import { useTeamPermissions } from '../../../teams';

export const LeaveTeamForm: FC = () => {
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [
    isShowNeedUpdateSubscriptionModal,
    setIsShowNeedUpdateSubscriptionModal,
  ] = useState(false);
  const { currentUserToTeam } = useTeamPermissions();

  const handleCloseLeaveTeamConfirmModal = (
    isOpenNeedUpdateSubscriptionModal: boolean
  ) => {
    setIsShowConfirmModal(false);
    setIsShowNeedUpdateSubscriptionModal(isOpenNeedUpdateSubscriptionModal);
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="text-sm font-medium text-gray-700">
        Leaving the team will result in the revocation of your access to all
        associated pages. Any shared pages or folders owned by you will be
        transferred to the team owner.
      </div>
      <div className="flex items-center gap-5">
        <Button
          variant="text"
          color="secondary"
          className="self-start sm:w-full"
          onClick={() => setIsShowConfirmModal(true)}
          disabled={
            currentUserToTeam?.isDeleteOnUpdateSubscriptionPaymentMethod
          }
        >
          Leave
        </Button>
        {currentUserToTeam?.isDeleteOnUpdateSubscriptionPaymentMethod && (
          <div className="text-md text-warning-600">
            The team's subscription payment method must be confirmed by the new
            owner
          </div>
        )}
      </div>
      <LeaveTeamConfirmModal
        isOpen={isShowConfirmModal}
        onClose={handleCloseLeaveTeamConfirmModal}
      />
      <LeaveTeamNeedUpdateSubscriptionModal
        isOpen={isShowNeedUpdateSubscriptionModal}
        onClose={() => setIsShowNeedUpdateSubscriptionModal(false)}
      />
    </div>
  );
};
