import { EmbedContentAttrs, EmbedContentType } from './types';
import { EmbedContentBlockExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embedContent: {
      insertEmbedContent: (options?: Partial<EmbedContentAttrs>) => ReturnType;
      removeEmbedContent: () => ReturnType;
      insertEmbedContentLoomPicker: () => ReturnType;
      insertEmbedContentYoutubePicker: () => ReturnType;
      insertEmbedContentGoogleDocsPicker: () => ReturnType;
      insertEmbedContentCalendlyPicker: () => ReturnType;
      renderResizeableEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
      renderIFrameEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
      renderLoomVideoEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
      renderYoutubeEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
      renderYoutubePlaylistEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
      renderGoogleDocsEmbedContent: (
        options: Partial<EmbedContentAttrs>
      ) => ReturnType;
    };
  }
}

export const EmbedContentBlock = EmbedContentBlockExt.extend({
  addCommands() {
    return {
      insertEmbedContent:
        (options?: Partial<EmbedContentAttrs>) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
            content: [
              {
                type: 'embedContent',
                attrs: {
                  ...options,
                  opened: true,
                },
              },
            ],
          });
        },
      insertEmbedContentLoomPicker:
        () =>
        ({ commands }) => {
          return commands.insertEmbedContent({
            type: EmbedContentType.LOOM_VIDEO,
          });
        },
      insertEmbedContentYoutubePicker:
        () =>
        ({ commands }) => {
          return commands.insertEmbedContent({
            type: EmbedContentType.YOUTUBE_VIDEO,
          });
        },
      insertEmbedContentGoogleDocsPicker:
        () =>
        ({ commands }) => {
          return commands.insertEmbedContent({
            type: EmbedContentType.GOOGLE_DOCS,
          });
        },
      insertEmbedContentCalendlyPicker:
        () =>
        ({ commands }) => {
          return commands.insertEmbedContent({
            type: EmbedContentType.CALENDLY,
          });
        },
      renderResizeableEmbedContent:
        (data) =>
        ({ commands }) => {
          if (data.type === EmbedContentType.LOOM_VIDEO) {
            return commands.renderLoomVideoEmbedContent(data);
          } else if (data.type === EmbedContentType.YOUTUBE_VIDEO) {
            return commands.renderYoutubeEmbedContent(data);
          } else if (data.type === EmbedContentType.YOUTUBE_PLAYLIST) {
            return commands.renderYoutubePlaylistEmbedContent(data);
          } else if (data.type === EmbedContentType.GOOGLE_DOCS) {
            return commands.renderGoogleDocsEmbedContent(data);
          } else {
            return commands.renderIFrameEmbedContent(data);
          }
        },
      renderIFrameEmbedContent:
        (data) =>
        ({ chain, state, editor }) => {
          const resizableOptions = {
            attrs: {
              'data-media-width': editor.view.dom.offsetWidth,
              'data-media-height': 640,
            },
            content: [
              {
                type: 'iframe',
                attrs: {
                  src: data.link,
                  alt: data.link,
                  title: data.link,
                },
              },
            ],
          };
          return data.noPlaceholderBlock
            ? chain().focus().setResizeableFigure(resizableOptions).run()
            : chain()
                .focus()
                .setNodeSelection(state.selection.from)
                .deleteSelection()
                .setResizeableFigure(resizableOptions)
                .run();
        },
      renderLoomVideoEmbedContent:
        (data) =>
        ({ commands }) => {
          return commands.renderIFrameEmbedContent({
            ...data,
            link: data.link?.replace('/share/', '/embed/'),
          });
        },
      renderGoogleDocsEmbedContent:
        (data) =>
        ({ commands }) => {
          return commands.renderIFrameEmbedContent({
            ...data,
            link: data.link?.replace('/edit', '/preview'),
          });
        },
      renderYoutubePlaylistEmbedContent:
        (data) =>
        ({ chain }) => {
          const linkParams = new URLSearchParams(data.link?.split('?')?.[1]);
          const playlistId = linkParams.get('list');

          return chain()
            .renderYoutubeEmbedContent({
              link: `https://www.youtube.com/embed/?listType=playlist&list=${playlistId}`,
            })
            .run();
        },
      renderYoutubeEmbedContent:
        (data) =>
        ({ chain, state, editor }) => {
          return chain()
            .focus()
            .setNodeSelection(state.selection.from)
            .deleteSelection()
            .setYoutubeVideo({
              src: data.link ?? '',
              width: editor.view.dom.offsetWidth,
            })
            .run();
        },
      removeEmbedContent:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
    };
  },
});
