import { call, delay, put, select } from 'redux-saga/effects';

import { VideoRecordType } from '@distribute/shared/api-types/video-record';

import { selectRecords } from '../selectors';
import { RT } from '../../../../shared/types';

import { videoRecordsApi } from '../../../../shared/api';

import { logger } from '../../../../shared/lib';
import { actions } from '../reducer';
import { teamsModel } from '../../../teams';
import { INVERTAL_DELAY } from '../../config';

import { checkIsRecordPrepared } from '../../lib';

// 1. Use Pusher technology here for the real-time updates.
// 2. Quick solution with ping interval.

export function* watchVideoRecordsStatus() {
  const {
    id: teamId,
  }: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  try {
    while (true) {
      const records: VideoRecordType[] = yield select(selectRecords);

      if (records.every(checkIsRecordPrepared)) {
        break;
      }

      const data: RT<typeof videoRecordsApi.getStatusAllAndMetadata> =
        yield call(videoRecordsApi.getStatusAllAndMetadata, { teamId });

      yield put(
        actions.setRecords(records.map((r) => ({ ...r, ...data[r.id] })))
      );
      yield delay(INVERTAL_DELAY);
    }
  } catch (e: unknown) {
    logger.error(e);
  }
}
