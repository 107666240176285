import { call, put, select } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  TeamFiltered,
  TeamOnboardingStatus,
} from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '..';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';

export function* createTeam({
  payload: { domain, name, isSetCreatedTeamAsCurrent = true, cb },
}: ReturnType<typeof teamsModel.actions.createTeam>) {
  try {
    yield put(teamsModel.actions.setIsCreatingTeam(true));
    const team: TeamFiltered = yield call(teamsApi.createTeam, {
      domain,
      name,
      onboardingStatus: TeamOnboardingStatus.FINISHED,
    });
    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    yield put(teamsModel.actions.setTeams([...teams, team]));

    if (isSetCreatedTeamAsCurrent) {
      yield put(teamsModel.actions.changeCurrentTeam({ teamId: team.id }));
    }

    analytics.track(AnalyticsEvents.TEAM_CREATED, {
      teamId: team.id,
      teamName: team.name,
      teamDomain: team.domain,
    });
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The team has been created successfully')
      )
    );
    yield call(cb, team);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to create the team')
        )
      )
    );
  } finally {
    yield put(teamsModel.actions.setIsCreatingTeam(false));
  }
}
