// todo: reuse from extension
export const getVideoDuration = (originalDuration: number) => {
  const durationInSeconds = Math.round(originalDuration);

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  let durationString = '';

  if (hours > 0) {
    durationString += `${hours} hr`;
  }
  if (minutes > 0 || hours > 0) {
    durationString += `${minutes} min`;
  }
  durationString += `${seconds} sec`;

  return durationString.trim();
};
