import React, { FC, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';

type IProps = {
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
};

export const Input: FC<IProps> = ({ placeholder, className, onChange }) => {
  useEffect(() => () => onChange(''), [onChange]);

  return (
    <Combobox.Input
      className={classNames(
        'text-md text-gray-900 placeholder-gray-500 outline-none',
        className
      )}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
