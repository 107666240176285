import { EmbedContentType } from '../types';

import {
  GOOGLE_URL_REGEX,
  LOOM_URL_REGEX,
  YOUTUBE_URL_REGEX,
} from '../../../../../shared/constants';

export const getLinkContentType = (url: string): EmbedContentType => {
  if (url.match(LOOM_URL_REGEX)) {
    return EmbedContentType.LOOM_VIDEO;
  }
  if (url.match(GOOGLE_URL_REGEX)) {
    return EmbedContentType.GOOGLE_DOCS;
  }

  const youtubeMatch = url.match(YOUTUBE_URL_REGEX);

  if (youtubeMatch && youtubeMatch[5] !== '/embed/') {
    return url.match('/playlist')
      ? EmbedContentType.YOUTUBE_PLAYLIST
      : EmbedContentType.YOUTUBE_VIDEO;
  }

  return EmbedContentType.IFRAME;
};
