import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';

import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';

import { createNotification, snackbarModel } from '../../../snackbar';
import { videoRecordsApi } from '../../../../shared/api';
import { actions } from '../reducer';

import { selectRecord, selectRecords } from '../selectors';

import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { refreshRecordDetail } from './refresh-record-detail';

export function* updateRecordName(
  action: ReturnType<typeof actions.updateRecordName>
) {
  const {
    payload: { id, ...data },
  } = action;

  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const record: RT<typeof selectRecord> = yield select(selectRecord);
    const records: RT<typeof selectRecords> = yield select(selectRecords);

    yield call(videoRecordsApi.update, id, data, {
      teamId: team.id,
    });
    yield put(
      actions.setRecords(
        records.map((r) => (r.id === id ? { ...r, ...data } : r))
      )
    );
    yield call(refreshRecordDetail, { ...record, ...data });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.record.updateError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
  }
}
