import {
  CalendarProvider,
  ConversionKitCalendarData,
} from '@distribute/shared/types';
import React, { FC } from 'react';
import { InlineWidget } from 'react-calendly';

type IProps = ConversionKitCalendarData & {
  styles?: React.CSSProperties;
};

export const CalendarPreview: FC<IProps> = ({
  calendarCalendlyBgColor,
  calendarCalendlyButtonColor,
  calendarCalendlyTextColor,
  calendarIsShowCalendlyCookieBanner,
  calendarIsShowCalendlyPageDetails,
  calendarProvider,
  calendarSchedulingLink,
  styles,
}) => {
  if (!calendarSchedulingLink) return null;

  if (calendarProvider === CalendarProvider.CALENDLY) {
    return (
      <InlineWidget
        url={calendarSchedulingLink}
        pageSettings={{
          hideLandingPageDetails: !calendarIsShowCalendlyPageDetails,
          hideEventTypeDetails: !calendarIsShowCalendlyPageDetails,
          hideGdprBanner: !calendarIsShowCalendlyCookieBanner,
          textColor: calendarCalendlyTextColor,
          primaryColor: calendarCalendlyButtonColor,
          backgroundColor: calendarCalendlyBgColor,
        }}
        styles={styles}
      />
    );
  }

  if (calendarProvider === CalendarProvider.CHILI_PIPER) {
    return (
      <iframe
        src={calendarSchedulingLink}
        className="grow-1"
        title="Chili piper"
        style={styles}
      />
    );
  }

  return null;
};
