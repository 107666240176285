import { actions } from '../reducer';
import { delay, put } from 'redux-saga/effects';

import { pagesModel } from '../index';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

const MAX_RETRIES = 5;

export function* changePageContent({
  payload: { contentJson, pageId, contentItemId },
}: ReturnType<typeof pagesModel.actions.changePageContent>) {
  let successResponse = false;
  let retryCount = 0;

  do {
    try {
      yield put(actions.setChangePageContentIsLoading(true));
      yield put(actions.setCurrentPageStatus('pending'));
      const page: Page = yield pagesApi.updatePage(pageId, {
        contentJson,
        contentItemId,
      });

      successResponse = true;

      if (!page) {
        throw new Error('updatedPage is undefined');
      }
      yield put(pagesModel.actions.setCurrentPage(page));
      yield put(actions.setCurrentPageStatus('success'));
      yield put(actions.setCurrentPageIsError(false));
    } catch (e: unknown) {
      logger.error(e);
      yield put(actions.setCurrentPageStatus('error'));
      yield put(actions.setCurrentPageIsError(true));
      retryCount += 1;
      yield delay(5000);
    } finally {
      yield put(actions.setChangePageContentIsLoading(false));
    }
  } while (retryCount < MAX_RETRIES && !successResponse);

  if (!successResponse) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change page content')
      )
    );
  }
}
