import { RootState } from '../../../app';
import { selectWithError } from '../../../shared/lib';

export const selectUpdateSeoConfigurationIsLoading = ({
  pageSettings,
}: RootState) => pageSettings.updateSeoConfigurationIsLoading;

export const selectSeoConfiguration = ({ pageSettings }: RootState) =>
  pageSettings.seoConfiguration;

export const selectSeoConfigurationWithError = selectWithError(
  selectSeoConfiguration,
  'seoConfiguration'
);

export const selectIsUploadImageLoading = ({ pageSettings }: RootState) =>
  pageSettings.isUploadImageLoading;

export const selectUploadingImageProgress = ({ pageSettings }: RootState) =>
  pageSettings.uploadingImageProgress;

export const selectIsRemoveImageLoading = ({ pageSettings }: RootState) =>
  pageSettings.isRemoveImageLoading;
