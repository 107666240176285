import { IconMap } from '../../../../../shared/sprite';
import debounce from 'debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { string, ValidationError } from 'yup';

import { Input } from '../../../../../shared/ui';
import { teamsApi } from '../../../../../shared/api';
import { APP_ORIGIN } from '../../../../../shared/config';

type Props = {
  setIsSubdomainValid: (val: boolean) => void;
  getValues: () => { isSubdomainValid: boolean };
  setSubdomain: (val: string) => void;
  isLoading: boolean;
  subdomain: string;
  currentSubdomain: string;
  isDisabled?: boolean;
  placeholder?: string;
  isHideLabelInsideInputWhenUntouched?: boolean;
  label?: string;
};

export const DebounceSubdomainInput: FC<Props> = ({
  setIsSubdomainValid,
  getValues,
  setSubdomain,
  isLoading,
  subdomain,
  currentSubdomain,
  isDisabled,
  placeholder,
  isHideLabelInsideInputWhenUntouched,
  label,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [message, setMessage] = useState(
    'Must be alphanumeric (A-Z, 0-9) with dashes between words'
  );
  const [isStartedTyping, setIsStartedTyping] = useState(false);
  const { isSubdomainValid } = getValues();
  const isShowCheckIconAndDomainLabel = isHideLabelInsideInputWhenUntouched
    ? isDirty
    : true;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubdomainValidate = useCallback(
    debounce(async (subdomain: string) => {
      try {
        string()
          .required('Subdomain is required')
          .matches(
            /^[a-zA-Z0-9-]+$/,
            'Must be alphanumeric (A-Z, 0-9) with dashes between words'
          )
          .validateSync(subdomain);
      } catch (e) {
        setMessage((e as ValidationError).message);
        setIsSubdomainValid(false);
        setIsStartedTyping(true);

        return;
      }

      const data = await teamsApi.checkDomain({ domain: subdomain });
      if (!data.isUnique && currentSubdomain !== subdomain) {
        setIsSubdomainValid(false);
        setMessage('Subdomain with this name already exist');
        setIsStartedTyping(true);
        return;
      }

      setIsSubdomainValid(true);
      setMessage('Must be alphanumeric (A-Z, 0-9) with dashes between words');
      setIsStartedTyping(true);
    }, 400),
    [currentSubdomain]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSubdomain = e.target.value.toLowerCase();
    onSubdomainValidate(newSubdomain);
    setSubdomain(newSubdomain);
    setIsDirty(true);
  };
  const getInputIconSrc = () => {
    if (isSubdomainValid === undefined || !isShowCheckIconAndDomainLabel) {
      return;
    }
    if (!isSubdomainValid) {
      return IconMap.CrossCircleRed;
    }
    return IconMap.CheckCircleGreen;
  };

  useEffect(() => {
    if (currentSubdomain) {
      onSubdomainValidate(currentSubdomain);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentSubdomain) {
      if (subdomain) {
        setIsStartedTyping(true);
      }

      if (isStartedTyping) {
        onSubdomainValidate(subdomain);
        setIsDirty(true);
      }
    }
  }, [subdomain, currentSubdomain, isStartedTyping, onSubdomainValidate]);

  return (
    <div className="w-full">
      <Input
        type="text"
        name="Subdomain"
        value={subdomain}
        label={label}
        onChange={onChange}
        isError={isStartedTyping && !isLoading && !isSubdomainValid}
        messageText={message}
        iconSrc={getInputIconSrc()}
        labelInsideInput={
          isShowCheckIconAndDomainLabel ? `.${APP_ORIGIN}` : undefined
        }
        disabled={isDisabled}
        placeholder={placeholder}
      />
    </div>
  );
};
