export enum AIStartedWritingFrom {
  SCRATCH = 'scratch',
  SELECTION = 'selection',
  NEW_LINE = 'new line',
}

export enum AIActionsAfterWriting {
  REPLACE_SELECTION = 'replace-selection',
  INSERT_BELOW = 'insert-below',
  DONE = 'done',
  CONTINUE_WRITING = 'continue-writing',
  MAKE_LONGER = 'make-longer',
  TRY_AGAIN = 'try-again',
  DISCARD = 'discard',
}

export enum EditorWritingMode {
  Editor = 'Editor',
  ReadonlyEditor = 'readonly-editor',
}
