import { useDispatch, useSelector } from 'react-redux';
import { INDEX_ROUTE } from '../../../../../entities/history';
import { Button, DistributeLink } from '../../../../../shared/ui';
import { FC } from 'react';
import { recordingsModel } from '../../../model';
import { navigate } from '../../../../../processes/navigation';

export const RecordHeader: FC = () => {
  const dispatch = useDispatch();
  const record = useSelector(recordingsModel.selectors.selectRecord);

  return (
    <header className="px-4 h-16 border-b border-gray-200 flex items-center gap-x-2">
      <p className="text-sm font-semibold text-gray-700 truncate">
        {record.name}
      </p>
      <div className="ml-auto flex flex-nowrap items-center gap-x-4">
        <Button
          variant="text"
          color="primary"
          size="sm"
          className="font-semibold"
          onClick={() =>
            dispatch(
              navigate({
                to: INDEX_ROUTE,
              })
            )
          }
        >
          Try Distribute for free
        </Button>
        <div className="w-px h-5 bg-gray-200" />
        <DistributeLink />
      </div>
    </header>
  );
};
