import React from 'react';
import { Avatar } from '../../../../shared/ui';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

type Props = {
  template: TemplateExtended;
};

export const CreatedByRow: React.FC<Props> = ({ template }) => {
  return (
    <div className="flex items-center gap-2 truncate">
      {template.isDistribute ? (
        <img
          src="../../../assets/images/distribute-avatar.png"
          alt="Auth logo"
          width={24}
          className="object-contain"
        />
      ) : (
        <Avatar
          displayName={template.owner.displayName}
          src={template.owner?.photoUrl}
          size="xs"
          isUserAvatar
          className="border-light-7 border-[0.5px] !bg-base-white"
        />
      )}
      <div className="text-sm text-gray-700 truncate">
        Created by{' '}
        <span className="font-semibold text-gray-900">
          {template.isDistribute ? 'Distribute' : template.owner.displayName}
        </span>
      </div>
    </div>
  );
};
