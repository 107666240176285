import { templatesApi } from '../../../../shared/api';
import { templatesModel } from '..';
import { put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

export function* deleteTemplate({
  payload: { id, callback },
}: ReturnType<typeof templatesModel.actions.deleteTemplate>) {
  try {
    yield put(templatesModel.actions.setTemplateDeleting(true));
    yield templatesApi.deleteTemplate(id);

    const templates: TemplateExtended[] = yield select(
      templatesModel.selectors.selectTemplates
    );
    const newTemplates = templates.filter((i) => i.id !== id);
    yield put(templatesModel.actions.setTemplates(newTemplates));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The template has been deleted!')
      )
    );

    callback();
  } catch (err) {
    logger.error(err);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete the template')
      )
    );
  } finally {
    yield put(templatesModel.actions.setTemplateDeleting(false));
  }
}
