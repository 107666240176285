import React, { FC } from 'react';
import { TeamFiltered } from '@distribute/shared/types';
import { Avatar, Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type IProps = {
  team: TeamFiltered;
  isLoading?: boolean;
  onSelectTeam?: (team: TeamFiltered) => void;
  onBack?: () => void;
};

export const TeamItem: FC<IProps> = ({
  team,
  isLoading,
  onBack,
  onSelectTeam,
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-3 items-center">
        <Avatar
          displayName={team.name}
          src={team.brandLogoUrl ?? undefined}
          size="md"
          theme={team.brandLogoUrl ? 'primary-rect' : 'secondary-rect'}
          className="border border-gray-200"
        />
        <div className="flex flex-col text-gray-700 min-w-0">
          <span className="text-sm font-semibold truncate">{team.name}</span>
          <span className="text-xs">
            {team.teamMembersCount}{' '}
            {team.teamMembersCount > 1 ? 'members' : 'member'}
          </span>
        </div>
      </div>
      {onSelectTeam && (
        <Button
          color="primary"
          variant="text"
          loading={isLoading}
          onClick={() => onSelectTeam(team)}
        >
          Choose
        </Button>
      )}
      {onBack && (
        <Button
          color="secondary"
          variant="icon-text"
          iconLeftName={IconMap.ArrowLeft}
          iconLeftWidth={20}
          onClick={onBack}
        >
          Back
        </Button>
      )}
    </div>
  );
};
