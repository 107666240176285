import { ExtendedHorizontalRuleExt } from '@distribute/shared/generate-html';
import { InputRule } from '@tiptap/core';

export const ExtendedHorizontalRule = ExtendedHorizontalRuleExt.extend({
  addInputRules() {
    return [
      new InputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        handler: ({ state, range }) => {
          const { tr } = state;
          const start = range.from;
          const end = range.to;

          tr.insert(start - 1, this.type.create({})).delete(
            tr.mapping.map(start),
            tr.mapping.map(end)
          );
        },
      }),
    ];
  },
});
