import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { teamsModel } from '../../../teams';
import { TeamPermissionsDropdown } from './TeamPermissionsDropdown';

export const ManageSnippetsPermissionForm: FC = () => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const userRoleItems = useMemo(() => {
    return [
      {
        id: 'owner',
        name: 'Owner',
        value: true,
        isCanBeDeleted: false,
      },
      {
        id: 'admin',
        name: 'Admin',
        value: currentTeam.teamPermissions.isAdminCanManageSnippets,
        onChange: (value: boolean) => {
          dispatch(
            teamsModel.actions.updateTeamPermissions({
              teamId: currentTeam.id,
              isAdminCanManageSnippets: value,
            })
          );
        },
        isCanBeDeleted: true,
      },
      {
        id: 'member',
        name: 'Member',
        value: currentTeam.teamPermissions.isMemberCanManageSnippets,
        onChange: (value: boolean) => {
          dispatch(
            teamsModel.actions.updateTeamPermissions({
              teamId: currentTeam.id,
              isMemberCanManageSnippets: value,
            })
          );
        },
        isCanBeDeleted: true,
      },
    ];
  }, [dispatch, currentTeam]);

  return (
    <form className="flex gap-x-6 justify-between items-start">
      <div className="flex flex-col">
        <p className="text-gray-700 text-sm font-medium">
          Who can manage snippets for team
        </p>
        <p className="text-gray-500 text-xs font-normal">
          Enable roles to publish, edit and delete team snippets
        </p>
      </div>
      <TeamPermissionsDropdown items={userRoleItems} />
    </form>
  );
};
