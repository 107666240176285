import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, Input, Modal } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { teamsModel } from '../../teams';
import { integrationsModel } from '../model';
import { createNotification, snackbarModel } from '../../snackbar';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ZapierDetailsModal: FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { zapierApiKey } = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isLoading = useSelector(
    integrationsModel.selectors.selectIsZapierLoading
  );

  const handleRegenerateApiKey = () => {
    dispatch(
      integrationsModel.actions.regenerateApiKey({ teamId: currentTeam.id })
    );
  };

  const handleCopyApiKey = async () => {
    await navigator.clipboard.writeText(zapierApiKey);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The API Key has been copied to your clipboard'
        )
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="!w-160 sm:!w-full"
      cancelButtonName="Close"
      cancelButtonClassName="!w-full"
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-4">
          <Icon glyph={IconMap.Zapier} width={42} />
          <div className="text-display-xs font-display text-gray-900 font-medium">
            Zapier Integration
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Connect to Zapier to automatically send leads data to other apps.
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-lg text-gray-900 font-semibold">
            Private API Key
          </div>
          <div className="text-sm text-gray-600">
            You can use this key for 3rd party integrations. This key contains
            private data of your business
          </div>
        </div>
        <div className="flex gap-2">
          <Input
            disabled
            value={zapierApiKey}
            type="text"
            className="!bg-base-white"
          />
          <Button
            color="secondary"
            variant="icon-text"
            iconLeftName={IconMap.MenuCopy}
            iconLeftWidth={20}
            onClick={handleCopyApiKey}
          >
            Copy
          </Button>
        </div>
        <div className="flex flex-col items-start gap-2">
          <Button
            color="link-gray"
            variant="icon-text"
            iconLeftName={IconMap.RefreshCcw05}
            iconLeftWidth={20}
            onClick={handleRegenerateApiKey}
            loading={isLoading}
            className="!h-5.5"
          >
            Create new key
          </Button>
          <div className="text-sm text-gray-600">
            Creating a new key will disconnect your existing integrations
          </div>
        </div>
      </div>
    </Modal>
  );
};
