import { ReactNodeViewRenderer } from '@tiptap/react';
import { RECORD_REGEX } from '../../../../features/recordings/lib/record-link';
import { VideoRecordNodeView } from './VideoRecord.renderer';
import { VideoRecordExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    videoRecord: {
      setVideoRecord: (options: { prefix: string }) => ReturnType;
      deleteVideoRecord: () => ReturnType;
    };
  }
}

export const VideoRecordExtension = VideoRecordExt.extend({
  addCommands() {
    return {
      setVideoRecord:
        (options) =>
        ({ commands }) => {
          return commands.setResizeableFigure({
            content: [
              {
                type: 'videoRecord',
                attrs: {
                  ...options,
                },
              },
            ],
            attrs: {
              'data-media-width': this.editor.view.dom.offsetWidth,
            },
          });
        },
      deleteVideoRecord:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
    };
  },

  addPasteRules() {
    return [
      {
        find: RECORD_REGEX,
        handler: ({ match, range, chain }) => {
          const prefix = match[3];
          chain().focus().deleteRange(range).setVideoRecord({ prefix }).run();
        },
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoRecordNodeView);
  },
});
