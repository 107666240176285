import { ChangeEvent, FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnippetType, UserToTeamFiltered } from '@distribute/shared/types';
import { authUserModel } from '../../../entities/auth-user';
import { IconMap } from '../../../shared/sprite';
import { Avatar, Dropdown, Icon, Input } from '../../../shared/ui';
import { SnippetsTabEnum, SortState } from '../lib';
import { snippetsModel } from '../../../features/snippets';

type Props = {
  usersWithSnippets?: UserToTeamFiltered[];
  tabType: SnippetsTabEnum;
  visibleSnippetsAmount: number;
};

export const FiltersRow: FC<Props> = ({
  usersWithSnippets = [],
  tabType,
  visibleSnippetsAmount,
}) => {
  const dispatch = useDispatch();

  const { id: currentUserId } = useSelector(
    authUserModel.selectors.selectUserWithError
  );

  const currentTypeFilter = useSelector(
    snippetsModel.selectors.selectCurrentTypeFilter
  );

  const currentSortCondition = useSelector(
    snippetsModel.selectors.selectCurrentSortCondition
  );

  const currentOwnerFilter = useSelector(
    snippetsModel.selectors.selectCurrentOwnerFilter
  );

  const searchQuery = useSelector(snippetsModel.selectors.selectSearchQuery);

  const handleSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      snippetsModel.actions.setSearchQuery({
        ...searchQuery,
        [tabType]: e.target.value,
      })
    );
  };

  const handleClear = () => {
    dispatch(
      snippetsModel.actions.setSearchQuery({ ...searchQuery, [tabType]: '' })
    );
  };

  const typeFilterItems = useMemo(() => {
    return tabType !== SnippetsTabEnum.DRAFTS
      ? [
          {
            label: 'All snippets',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentTypeFilter({
                  ...currentTypeFilter,
                  [tabType]: undefined,
                })
              );
            },
            id: 'all-snippets',
          },
          {
            label: 'Text',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentTypeFilter({
                  ...currentTypeFilter,
                  [tabType]: SnippetType.TEXT,
                })
              );
            },
            id: SnippetType.TEXT,
            iconName: IconMap.TypeSquare,
            iconWidth: 16,
          },
          {
            label: 'Image',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentTypeFilter({
                  ...currentTypeFilter,
                  [tabType]: SnippetType.IMAGE,
                })
              );
            },
            id: SnippetType.IMAGE,
            iconName: IconMap.Image01,
            iconWidth: 16,
          },
          {
            label: 'Video',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentTypeFilter({
                  ...currentTypeFilter,
                  [tabType]: SnippetType.VIDEO,
                })
              );
            },
            id: SnippetType.VIDEO,
            iconName: IconMap.PlaySquare,
            iconWidth: 16,
          },
          {
            label: 'File',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentTypeFilter({
                  ...currentTypeFilter,
                  [tabType]: SnippetType.FILE,
                })
              );
            },
            id: SnippetType.FILE,
            iconName: IconMap.BlankPageSquare,
            iconWidth: 16,
          },
        ]
      : [];
  }, [dispatch, currentTypeFilter, tabType]);

  const sortItems = useMemo(() => {
    return [
      {
        label: 'Last Created',
        id: SortState.LAST_CREATED,
        onClick: () => {
          dispatch(
            snippetsModel.actions.setCurrentSortCondition({
              ...currentSortCondition,
              [tabType]: SortState.LAST_CREATED,
            })
          );
        },
      },
      {
        label: 'Most Popular',
        id: SortState.MOST_POPULAR,
        onClick: () => {
          dispatch(
            snippetsModel.actions.setCurrentSortCondition({
              ...currentSortCondition,
              [tabType]: SortState.MOST_POPULAR,
            })
          );
        },
      },
      {
        label: 'Name',
        id: SortState.NAME,
        onClick: () => {
          dispatch(
            snippetsModel.actions.setCurrentSortCondition({
              ...currentSortCondition,
              [tabType]: SortState.NAME,
            })
          );
        },
      },
    ];
  }, [dispatch, currentSortCondition, tabType]);

  const ownerFilterItems = useMemo(() => {
    return tabType === SnippetsTabEnum.TEAM
      ? [
          {
            label: 'All owners',
            onClick: () => {
              dispatch(
                snippetsModel.actions.setCurrentOwnerFilterType(undefined)
              );
            },
            id: 'all-owners',
          },
          ...usersWithSnippets.map((u, index) => {
            return {
              label: (
                <div className="flex items-center gap-3">
                  <Avatar
                    isUserAvatar
                    displayName={u.user?.displayName || ''}
                    src={u.user?.photoUrl ?? undefined}
                    size="xs"
                  />
                  <div className="flex flex-col min-w-0 text-gray-700 group-hover:text-gray-800">
                    <span className="text-sm truncate">
                      {u.user?.displayName}{' '}
                      {u.user?.id === currentUserId ? '(you)' : ''}
                    </span>
                  </div>
                </div>
              ),
              onClick: () => {
                dispatch(
                  snippetsModel.actions.setCurrentOwnerFilterType(
                    u.user?.id || undefined
                  )
                );
              },
              id: u.user?.id || `${index}`,
            };
          }),
        ]
      : [];
  }, [currentUserId, usersWithSnippets, dispatch, tabType]);

  return (
    <div className="flex items-center justify-between gap-4 mb-8 max1140:flex-col max1140:items-start md:flex-row md:items-center max850:flex-col max850:items-start">
      <div className="flex items-center gap-4">
        <div className="relative">
          <Icon
            glyph={IconMap.SearchLg}
            width={20}
            className="absolute left-3.5 top-2.5 z-10 text-gray-500"
          />
          <Input
            type="text"
            placeholder="Search"
            className="!w-80 !h-10 !pl-10.5 !pr-8.5 !py-2.5 !text-md !text-gray-900 font-medium placeholder:font-normal shadow-xs"
            value={searchQuery[tabType]}
            onChange={handleSearchQueryChange}
            id="search"
          />
          {searchQuery[tabType] && (
            <Icon
              glyph={IconMap.XCircle}
              width={20}
              className="absolute right-3.5 top-2.5 z-10 text-gray-700 cursor-pointer"
              onClick={handleClear}
            />
          )}
        </div>
        <div className="text-sm font-normal text-gray-700 sm:hidden">
          {visibleSnippetsAmount}{' '}
          {visibleSnippetsAmount === 1 ? 'snippet' : 'snippets'}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-41 hover:bg-gray-50">
          {tabType !== SnippetsTabEnum.DRAFTS && (
            <Dropdown
              listStyles="shadow-lg w-41"
              items={typeFilterItems}
              currentItemId={
                currentTypeFilter[tabType] || typeFilterItems[0].id
              }
              triggerStyles="w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
              arrowStyle="text-gray-800"
            />
          )}
        </div>

        {usersWithSnippets.length > 1 && tabType === SnippetsTabEnum.TEAM && (
          <div className="w-41 hover:bg-gray-50">
            <Dropdown
              listStyles="shadow-lg w-54"
              items={ownerFilterItems}
              currentItemId={currentOwnerFilter || ownerFilterItems[0].id}
              triggerStyles="min-w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
              arrowStyle="text-gray-800"
            />
          </div>
        )}

        <div className="w-41 hover:bg-gray-50">
          <Dropdown
            listStyles="shadow-lg w-41"
            items={sortItems}
            currentItemId={currentSortCondition[tabType]}
            triggerStyles="min-w-41 sm:min-w-full !text-sm !font-semibold !text-gray-800 shadow-xs"
            arrowStyle="text-gray-800"
          />
        </div>
      </div>
    </div>
  );
};
