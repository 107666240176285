import { all, call, put, select } from 'redux-saga/effects';
import { UpdateSubscriptionResponseType } from '@distribute/shared/api-types/subscriptions';
import { actions } from '../reducer';
import { subscriptionApi } from '../../../../shared/api/axios/subscription';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { subscriptionModel } from '..';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';
import { teamsApi } from '../../../../shared/api';

export function* cancelSubscription(
  action: ReturnType<typeof actions.cancelSubscription>
) {
  const { reasonsText, environment, userEmail, teamId, cb } = action.payload;
  try {
    yield put(subscriptionModel.actions.setIsCancelingSubscription(true));

    const [, stripeSubscription]: [unknown, UpdateSubscriptionResponseType] =
      yield all([
        call(subscriptionApi.sendDowngradeReason, {
          environment,
          email: userEmail,
          reasons: reasonsText,
        }),
        call(subscriptionApi.updateSubscription, {
          teamId,
          isCancelAtPeriodEnd: true,
        }),
      ]);
    const team: TeamFiltered = yield call(teamsApi.getTeam, teamId);
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const isRenewPromoCodeWasCreated =
      !!team.subscriptionRenewPromoCode &&
      currentTeam.subscriptionRenewPromoCode?.id !==
        team.subscriptionRenewPromoCode?.id;

    yield call(teamsModel.sagas.onUpdateCurrentTeam, {
      ...team,
      stripeSubscription,
    });
    yield call(cb, isRenewPromoCodeWasCreated);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to downgrade subscription')
      )
    );
  } finally {
    yield put(subscriptionModel.actions.setIsCancelingSubscription(false));
  }
}
