export type FormInputs = {
  link: string;
};

export enum EmbedContentType {
  LOOM_VIDEO = 'loomVideo',
  YOUTUBE_VIDEO = 'youtubeVideo',
  YOUTUBE_PLAYLIST = 'youtubePlaylist',
  GOOGLE_DOCS = 'googleDocs',
  CALENDLY = 'calendly',
  IFRAME = 'iFrame',
}

export type EmbedContentAttrs = {
  error: string;
  checked: boolean;
  processingLink: string;
  available: boolean;
  opened: boolean;
  type: EmbedContentType;
  noPlaceholderBlock?: boolean;
} & FormInputs;
