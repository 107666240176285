import { UploadVideo, getUploadFormRect } from '../../ui/upload-form';
import { VideoExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      /**
       * Add an image
       */
      setVideo: (options: { src: string; poster?: string }) => ReturnType;
      deleteVideo: () => ReturnType;
      renderVideoUploadForm: (onDestroy?: () => void) => ReturnType;
    };
  }
}

export const VideoExtension = VideoExt.extend({
  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.setResizeableFigure({
            content: [
              {
                type: 'video',
                attrs: {
                  ...options,
                },
              },
            ],
            attrs: {
              'data-media-width': this.editor.view.dom.offsetWidth,
            },
          });
        },
      deleteVideo:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
      renderVideoUploadForm:
        (onDestroy) =>
        ({ editor }) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              UploadVideo,
              {
                onEmbeded: (data) => {
                  destroyComponent();
                  onDestroy?.();

                  editor.chain().renderResizeableEmbedContent(data).run();
                },
                onVideoRecordAdded: (data) => {
                  destroyComponent();
                  onDestroy?.();

                  editor.chain().setVideoRecord(data).run();
                },
                onUploaded: (data) => {
                  destroyComponent();
                  onDestroy?.();

                  editor.chain().setVideo(data).run();
                },
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                },
              },
              { getReferenceClientRect: () => getUploadFormRect(this.editor) }
            );

          return true;
        },
    };
  },
});
