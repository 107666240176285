import React from 'react';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useSelector } from 'react-redux';
import { templatesModel } from '../../../features/templates';

type Props = {
  handleCreateTemplate(): void;
  handleBrowseTemplates(): void;
};

export const NoCustomTemplates: React.FC<Props> = ({
  handleCreateTemplate,
  handleBrowseTemplates,
}) => {
  const createBlankTemplateIsLoading = useSelector(
    templatesModel.selectors.selectCreateBlankTemplateIsLoading
  );

  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full gap-8 overflow-hidden">
      <div className="text-center">
        <p className="font-semibold text-gray-900 text-md">
          No custom templates... yet
        </p>
        <p className="text-sm font-normal text-gray-600">
          Browse our templates to start your page or create your own.
        </p>
      </div>
      <div className="flex items-stretch justify-center gap-8">
        <div
          className="rounded-xl bg-base-white relative overflow-hidden w-82.5 border border-gray-300 group hover:border-custom-dark-green cursor-pointer hover:bg-gradient-primary-50-to-white-vertical"
          onClick={handleBrowseTemplates}
        >
          <div className="absolute bottom-0 right-0">
            <img
              src="../../../assets/images/bg-square-part.svg"
              alt="background"
              className="block group-hover:hidden"
            />
            <img
              src="../../../assets/images/bg-square-part-primary.svg"
              alt="background"
              className="hidden group-hover:block"
            />
          </div>
          <div className="relative h-full p-6">
            <Icon
              glyph={IconMap.SearchRefraction}
              className="mb-4 text-gray-800 group-hover:text-primary-600"
            />
            <p className="mb-1 text-lg font-semibold text-gray-900">
              Browse all templates
            </p>
            <p className="mb-4 text-sm font-normal text-gray-600">
              See what you can create using Distribute or accelerate your
              workflow.
            </p>
            <Button color="secondary" variant="text" size="md">
              Browse templates
            </Button>
          </div>
        </div>

        <div
          className="rounded-xl bg-base-white relative overflow-hidden w-82.5 border border-gray-300 group hover:border-custom-dark-green cursor-pointer sm:hidden hover:bg-gradient-primary-50-to-white-vertical"
          onClick={handleCreateTemplate}
        >
          <div className="absolute bottom-0 right-0">
            <img
              src="../../../assets/images/bg-cells-part.svg"
              alt="background"
              className="block group-hover:hidden"
            />
            <img
              src="../../../assets/images/bg-cells-part-primary.svg"
              alt="background"
              className="hidden group-hover:block"
            />
          </div>
          <div className="relative h-full p-6">
            <Icon
              glyph={IconMap.TemplatesPlus}
              className="mb-4 text-gray-800 group-hover:text-primary-600"
            />
            <p className="mb-1 text-lg font-semibold text-gray-900">
              Create your own template
            </p>
            <p className="mb-4 text-sm font-normal text-gray-600">
              Distribute templates power thousands of teams to do their best
              work.
            </p>
            <Button
              color="secondary"
              variant="text"
              size="md"
              loading={createBlankTemplateIsLoading}
            >
              Create template
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
