import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  children: ReactNode;
  title: string;
  isBottomSection?: boolean;
};

export const PersonalInfoSection: React.FC<Props> = ({
  children,
  title,
  isBottomSection,
}) => {
  return (
    <div>
      <h2 className="text-display-xs font-medium font-display text-gray-900 pb-5 border-b border-gray-200">
        {title}
      </h2>
      <div
        className={classNames('pt-6', {
          'pb-3': !isBottomSection,
          'pb-12': isBottomSection,
        })}
      >
        {children}
      </div>
    </div>
  );
};
