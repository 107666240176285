import React, { FC, FormEvent, ReactNode, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../../features/teams';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon } from '../../../../shared/ui';
import { aiModel } from '../../../../features/ai';

import { EditorContent, JSONContent, useEditor } from '@tiptap/react';

import classNames from 'classnames';
import { useUserScrollHandler } from './hooks';
import { mainEditorExtensions } from '../../mainEditorExtensions';

export type SearchSuggestion = {
  placeholder: string;
  text: string;
};

type IProps = {
  value: string;
  children: ReactNode;
  handleStart: () => void;
  handleStop: () => void;
  variantsCount: number;
  currentVariantIdx: number;
  currentResponseContent: JSONContent | null;
  onGoUnlimited: () => void;
  selectNext: () => void;
  selectPrev: () => void;
  searchSuggestion?: SearchSuggestion;
  isShowCurrentResponse: boolean;
};
export const SearchInputWrapper: FC<IProps> = ({
  value,
  searchSuggestion,
  handleStart,
  handleStop,
  variantsCount,
  currentVariantIdx,
  currentResponseContent,
  onGoUnlimited,
  selectNext,
  selectPrev,
  children,
  isShowCurrentResponse,
}) => {
  const currentVariant = currentVariantIdx + 1;

  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isLoading = useSelector(aiModel.selectors.selectIsLoading);
  const currentTeamMemberData = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const requestsCount = currentTeamMemberData.aiMonthlyResponsesCount;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleStart();
  };

  const readonlyEditor = useEditor({
    extensions: mainEditorExtensions,
    editorProps: {
      attributes: { class: 'main-editor main-editor-readonly' },
    },
    content: currentResponseContent,
    editable: false,
  });

  const ref = useRef<HTMLDivElement>(null);

  useUserScrollHandler(
    () => readonlyEditor?.view.dom.scrollIntoView(false),
    isLoading,
    ref.current
  );

  useEffect(() => {
    readonlyEditor?.commands.setContent(currentResponseContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResponseContent]);

  return (
    <div className="z-10 max-h-80 relative flex flex-col px-3 py-2 border-2 border-base-yellow bg-base-white rounded-lg shadow-md">
      <div
        ref={ref}
        className={classNames('overflow-auto', {
          'h-0': !isShowCurrentResponse,
          'mb-2': isShowCurrentResponse,
        })}
      >
        {isShowCurrentResponse && <EditorContent editor={readonlyEditor} />}
      </div>
      <form
        onSubmit={value.length ? handleSubmit : undefined}
        className="flex items-center gap-x-2"
      >
        {isLoading ? (
          <>
            <span className="w-5 h-5 flex items-center justify-center">
              <img
                src="../../../assets/images/loading.png"
                alt="Wizard write loading"
                className="spin"
              />
            </span>
            <span className="text-md grow-1 font-semibold text-transparent bg-clip-text bg-gradient-orange-to-purple">
              Wizard is writing...
            </span>
            <Button
              variant="icon"
              size="xs"
              type="button"
              color="transparent"
              onClick={() => {
                setTimeout(handleStop, 0);
              }}
            >
              <Icon
                glyph={IconMap.StopCircle}
                width={20}
                className="shrink-0 text-gray-500"
              />
            </Button>
          </>
        ) : (
          <>
            <img
              src="../../../assets/images/wizard-write.svg"
              alt="Wizard write icon"
              width={20}
              className="self-start"
            />
            <div className="flex grow-1 relative bg-base-white">
              {children}
              {searchSuggestion && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center gap-x-1.5">
                  <span className="text-md invisible">{value}</span>
                  <span className="flex items-center gap-x-2 grow-2 text-gray-400 text-md">
                    <span>{searchSuggestion.placeholder}</span>
                    <Icon glyph={IconMap.TabSymbol} width={27} />
                  </span>
                </div>
              )}
            </div>
            <Button
              variant="icon"
              type="submit"
              disabled={!value.length}
              size="xs"
              color="transparent"
              className="self-end"
            >
              <Icon
                glyph={
                  value.length
                    ? IconMap.ArrowCircleRightGreen
                    : IconMap.ArrowCircleRight
                }
                width={20}
                className="shrink-0 text-gray-300"
              />
            </Button>
            {variantsCount > 1 && (
              <div className="flex items-center self-end gap-x-1 border-l border-gray-200 pl-1 text-sm text-gray-600">
                <Button
                  variant="icon"
                  type="button"
                  disabled={currentVariant <= 1}
                  size="xs"
                  color="transparent"
                  onClick={selectPrev}
                >
                  <Icon
                    glyph={IconMap.ChevronLeft}
                    width={20}
                    className="shrink-0"
                  />
                </Button>
                <span>
                  {currentVariant} of {variantsCount}
                </span>
                <Button
                  variant="icon"
                  type="button"
                  disabled={currentVariant >= variantsCount}
                  size="xs"
                  color="transparent"
                  onClick={selectNext}
                >
                  <Icon
                    glyph={IconMap.ChevronRight}
                    width={20}
                    className="shrink-0"
                  />
                </Button>
              </div>
            )}
          </>
        )}
      </form>
      {!stripeSubscription && (
        <div className="absolute -bottom-0.5 right-2 z-0 translate-y-full shadow-lg rounded-b-lg bg-base-white">
          <div className="flex gap-x-2 px-3 py-1 bg-gradient-gray text-sm">
            <span className="text-gray-700">
              {requestsCount
                ? `${requestsCount} AI ${
                    requestsCount > 1 ? 'responses' : 'response'
                  } left.`
                : 'All free AI responses have been used'}
            </span>
            <span
              className="text-primary-700 font-semibold cursor-pointer"
              onClick={onGoUnlimited}
            >
              Go Unlimited
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
