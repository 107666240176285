import React from 'react';

type Props = {
  handleClick(): void;
  description: string;
};

export const PageTypeDescriptionItem: React.FC<Props> = ({
  handleClick,
  description,
}) => {
  return (
    <div
      className="p-3 rounded-lg flex flex-1 gap-2 items-start bg-warning-50 hover:bg-warning-100 cursor-pointer transition"
      onClick={handleClick}
    >
      <img
        src="../../../../../assets/images/stars-01.svg"
        alt="Stars icon"
        width={20}
      />
      <p className="text-sm text-gray-700">{description}</p>
    </div>
  );
};
