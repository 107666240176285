import ListItem from '@tiptap/extension-list-item';

const TOGGLE_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/toggle-icon.svg';

export const ToggleListItemExt = ListItem.extend({
  name: 'toggleListItem',

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        class: 'toggle-item',
      },
      [
        'button',
        { class: 'toggle-item__button' },
        [
          'img',
          {
            class: 'toggle-item__icon',
            src: TOGGLE_ICON,
          },
        ],
      ],
      ['div', { class: 'toggle-list-item-node-view' }, 0],
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'div.toggle-list-item',
      },
    ];
  },
});
