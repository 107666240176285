import { select, call, put } from 'redux-saga/effects';
import { pagesModel } from '..';
import { RT } from '../../../../shared/types';
import { pagesApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { AxiosError } from 'axios';

export function* requestToEditPage({
  payload: { pageId },
}: ReturnType<typeof pagesModel.actions.requestToEditPage>) {
  const currentPage: RT<
    typeof pagesModel.selectors.selectCurrentPageWithError
  > = yield select(pagesModel.selectors.selectCurrentPage);

  try {
    yield call(pagesApi.requestToEditPage, currentPage?.id ?? pageId);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Request to edit has been sent.')
      )
    );
  } catch (error) {
    if ((error as unknown as AxiosError).response?.status === 409) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Request has already been sent.')
        )
      );
    } else {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to send request to edit.')
        )
      );
    }
  }
}
