import { call, put, select } from 'redux-saga/effects';
import { socialModel } from '../..';
import { pagesModel } from '../../../../../features/pages';
import { Page, TeamFiltered } from '@distribute/shared/types';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { teamsModel } from '../../../../../features/teams';
import { getFullPageUrl } from '../../../../../shared/lib';
import { socialApi } from '../../../../../shared/api/axios/social';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { LINKEDIN_TOKEN_STORAGE_KEY } from '../../../../../shared/constants';
import { customDomainsModel } from '../../../../../features/custom-domains';

export function* shareLinkedinPost({
  payload: { text, file, userId },
}: ReturnType<typeof socialModel.actions.shareLinkedinPost>) {
  const currentPage: Page = yield select(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const title: string = yield select(
    editorSidebarModel.selectors.selectPersonalizedLinkTitle
  );

  const { domain }: TeamFiltered = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const customDomain: string | undefined = yield select(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const { pageSlug } = currentPage;

  const pageUrl = getFullPageUrl({
    slug: pageSlug,
    domain,
    customDomain,
  });

  const formData = new FormData();

  formData.append('file', file as Blob);

  const linkedinAccessToken = localStorage.getItem(LINKEDIN_TOKEN_STORAGE_KEY);

  if (linkedinAccessToken) {
    try {
      yield call(socialApi.createLinkedinPost, linkedinAccessToken, {
        requestData: formData,
        title: title ?? currentPage.content.title ?? '',
        text,
        userId,
        contentLandingPage: pageUrl,
      });

      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'success',
            'Your post has been successfully shared to LinkedIn!'
          )
        )
      );
    } catch (_) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to share LinkedIn post')
        )
      );
    } finally {
      yield put(socialModel.actions.setIsSharingLinkedinPost(false));
    }
  }
}
