import { call, put } from 'redux-saga/effects';

import { ctaApi } from '../../../shared/api';
import { actions } from '../model/reducer';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { CTA } from '@distribute/shared/types';

export function* setCTAData(documentContentId: number) {
  try {
    yield put(
      actions.setConversionKitStatus({
        status: 'pending',
        statusType: 'ctaStatus',
      })
    );

    const cta: CTA = yield call(ctaApi.getCTA, documentContentId);

    yield put(actions.setCTA(cta));
    yield put(
      actions.setConversionKitStatus({
        status: 'success',
        statusType: 'ctaStatus',
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setConversionKitStatus({
        status: 'error',
        statusType: 'ctaStatus',
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show CTA`)
      )
    );
  }
}
