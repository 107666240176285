import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Dialog } from '@headlessui/react';
import {
  Button,
  Dropdown,
  Icon,
  Modal,
  Switch,
  SwitchItem,
  Tooltip,
} from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { InviteOthersTab, SharePubliclyTab } from './components';
import { subscriptionModel } from '../../../../../features/subscription';
import { pagesModel } from '../../../../../features/pages';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { Page, PagePublicAccessPermission } from '@distribute/shared/types';
import { redirectActions } from '../../../../../entities/history';
import { APP_HOST, PROTOCOL } from '../../../../../shared/config';
import { useTimer } from '../../../../../shared/hooks/useTimer';

type Props = {
  isOpen: boolean;
  onClose(): void;
  isFolderPage?: boolean;
};

enum SharePageTabs {
  INVITE_OTHERS = 'inviteOthers',
  SHARE_PUBLICLY = 'sharePublicly',
}

export const SharePageModal: React.FC<Props> = ({
  isOpen,
  onClose,
  isFolderPage,
}) => {
  const dispatch = useDispatch();
  const isUpgradeModalOpen = useSelector(
    subscriptionModel.selectors.selectIsShowPaywallModal
  );
  const page: Page = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const changePagePublicAccess = useCallback(
    (permission: PagePublicAccessPermission) => {
      dispatch(
        pagesModel.actions.changePagePublicAccess({
          permission,
        })
      );
    },
    [dispatch]
  );

  const options = useMemo(
    () => [
      {
        id: PagePublicAccessPermission.INVITE_ONLY,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">
              Invite only
            </p>
            <p className="text-xs text-gray-500">Only people added can view</p>
          </div>
        ),
        headerLabel: (
          <p className="flex items-center gap-2 font-normal">
            <Icon width={16} glyph={IconMap.LockFilled} />
            Only people added can view
          </p>
        ),
        onClick: () =>
          changePagePublicAccess(PagePublicAccessPermission.INVITE_ONLY),
      },
      {
        id: PagePublicAccessPermission.EDIT,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">Edit</p>
            <p className="text-xs text-gray-500">
              Can view, share, edit, but not manage.
            </p>
          </div>
        ),
        headerLabel: (
          <p className="flex items-center gap-2 font-normal">
            <Icon width={16} glyph={IconMap.DraftPencil} />
            Anyone with the link can edit
          </p>
        ),
        onClick: () => changePagePublicAccess(PagePublicAccessPermission.EDIT),
      },
      {
        id: PagePublicAccessPermission.VIEW,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">View</p>
            <p className="text-xs text-gray-500">Can view only</p>
          </div>
        ),
        headerLabel: (
          <p className="flex items-center gap-2 font-normal">
            <Icon width={16} glyph={IconMap.EyeBold} />
            Anyone with the link can view
          </p>
        ),
        onClick: () => changePagePublicAccess(PagePublicAccessPermission.VIEW),
      },
    ],
    [changePagePublicAccess]
  );

  const tabs: SwitchItem[] = [
    {
      id: SharePageTabs.INVITE_OTHERS,
      title: 'Invite others',
      icon: IconMap.UserPlus,
    },
    {
      id: SharePageTabs.SHARE_PUBLICLY,
      title: 'Share publicly',
      icon: IconMap.Globe04,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<string>(
    SharePageTabs.INVITE_OTHERS
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const checkRedirectToEditorPage = () => {
    if (!isFolderPage) {
      return;
    }
    dispatch(
      redirectActions.toEditorPage({
        sequenceNumber: currentPage.sequenceNumber,
      })
    );
  };

  const handleEditorPreview = () => {
    onClose();
    dispatch(pagesModel.actions.setIsEditorPreview(true));
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    checkRedirectToEditorPage();
  };

  const [isCopied, setCopied] = useTimer(1500);

  const handleCopyPageLink = () => {
    const url = `${PROTOCOL}${APP_HOST}/editor/${currentPage.sequenceNumber}`;
    navigator.clipboard.writeText(url);
    setCopied();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isShowCancelButton={false}
      className="!w-160 sm:!w-full !p-0 relative h-180"
      dialogClassName={cn({ 'opacity-0': isUpgradeModalOpen })}
    >
      <header className="flex gap-4 justify-between p-6 pb-5 border-b border-gray-200 h-25">
        <div>
          <Dialog.Title className="text-gray-900 text-display-xs font-display font-medium mb-1">
            Share page
          </Dialog.Title>
          <Dropdown
            isModalMode={false}
            listStyles="shadow-lg w-90"
            align="start"
            items={options}
            currentItemId={page.publicAccess}
            checkmarkStyles="self-start"
            triggerStyles="!w-auto !h-10 shrink-0 border-0 !p-0 !h-5 !gap-2 !text-sm !font-semibold !text-gray-600"
          />
        </div>
        <Tooltip
          isOpen={isCopied}
          hideArrow
          sideOffset={4}
          trigger={
            <Button
              variant="icon-text"
              color="primary"
              size="sm"
              iconLeftName={IconMap.Link}
              iconLeftWidth={20}
              className="w-28"
              onClick={handleCopyPageLink}
            >
              Copy link
            </Button>
          }
        >
          <p className="p-1 font-semibold text-xs">Copied!</p>
        </Tooltip>
      </header>
      <main
        className={cn('p-6 pb-4 h-[calc(100%-185px)]', {
          'overflow-y-hidden': selectedTab === SharePageTabs.INVITE_OTHERS,
          'overflow-y-auto': selectedTab !== SharePageTabs.INVITE_OTHERS,
        })}
      >
        <Switch
          items={tabs}
          selectedItem={selectedTab}
          onToggle={setSelectedTab}
        />
        {selectedTab === SharePageTabs.INVITE_OTHERS && (
          <InviteOthersTab closeModal={onClose} />
        )}
        {selectedTab === SharePageTabs.SHARE_PUBLICLY && (
          <SharePubliclyTab
            closeModal={onClose}
            checkRedirectToEditorPage={checkRedirectToEditorPage}
          />
        )}
      </main>
      <footer className="border-t border-gray-200 rounded-b-xl pt-4 px-6 pb-6 flex items-center justify-between absolute bottom-0 left-0 right-0 w-full bg-base-white">
        <Button
          variant="icon-text"
          color="secondary"
          iconLeftName={IconMap.Eye}
          iconLeftWidth={20}
          className="!px-4"
          onClick={handleEditorPreview}
        >
          Preview
        </Button>
        <Button
          variant="text"
          color="secondary"
          className="!px-4"
          onClick={onClose}
        >
          Done
        </Button>
      </footer>
    </Modal>
  );
};
