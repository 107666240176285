import { put, select } from 'redux-saga/effects';
import { GettingStartedStep, TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';

export function* onGettingStartedStepCompleted(step: GettingStartedStep) {
  const currentTeam: TeamFiltered = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);
  const gettingStartedCompletedSteps =
    currentTeam.currentMember.gettingStartedCompletedSteps.includes(step)
      ? currentTeam.currentMember.gettingStartedCompletedSteps
      : [...currentTeam.currentMember.gettingStartedCompletedSteps, step];

  yield put(
    teamsModel.actions.setCurrentTeam({
      ...currentTeam,
      currentMember: {
        ...currentTeam.currentMember,
        gettingStartedCompletedSteps,
      },
    })
  );
  yield put(
    teamsModel.actions.setTeams(
      teams.map((team) =>
        team.id === currentTeam.id
          ? {
              ...team,
              currentMember: {
                ...team.currentMember,
                gettingStartedCompletedSteps,
              },
            }
          : team
      )
    )
  );
}
