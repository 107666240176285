import { call, select } from 'redux-saga/effects';
import {
  Folder,
  Page,
  TeamFiltered,
  User,
  UserPermissionToFolderRole,
  UserPermissionToPageRole,
  UserToTeamRole,
} from '@distribute/shared/types';
import { analytics } from '..';
import { authUserModel } from '../../auth-user';
import { teamsModel } from '../../../features/teams';
import { foldersModel } from '../../../features/folders';
import { pagesModel } from '../../../features/pages';

const getProfileLevel = (
  teams: TeamFiltered[],
  user: User
): UserToTeamRole | undefined => {
  const rolesPriority = [
    UserToTeamRole.OWNER,
    UserToTeamRole.ADMIN,
    UserToTeamRole.MEMBER,
  ];
  const userRoles = teams.map((team) => {
    return team.userToTeams.find((i) => i.user?.id === user.id)?.role;
  });

  for (const role of rolesPriority) {
    if (userRoles.includes(role)) {
      return role;
    }
  }

  return;
};

export function* analyticsIdentify(user: User) {
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);
  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);
  const pages: Page[] = yield select(pagesModel.selectors.selectPages);
  const foldersOwned = folders.filter(
    (folder) =>
      folder.userPermissionToFolders.find(
        (i) => i.role === UserPermissionToFolderRole.OWNER
      )?.user.id === user.id
  );
  const pagesOwned = pages.filter(
    (page) =>
      page.userPermissionToPages.find(
        (i) => i.role === UserPermissionToPageRole.OWNER
      )?.user.id === user.id
  );

  yield analytics.identify(user.id, {
    email: user.email,
    name: user.displayName,
    profileLevel: getProfileLevel(teams, user),
    numberOfTeamsAddedTo: teams.length,
    numberOfTeamsOwned: teams.filter(
      (team) =>
        team.userToTeams.find((i) => i.role === UserToTeamRole.OWNER)?.user
          ?.id === user.id
    ).length,
    numberOfFoldersOwned: foldersOwned.length,
    numberOfPagesOwned: pagesOwned.length,
    numberOfFoldersSharedWith: foldersOwned.filter(
      (folder) => folder.userPermissionToFolders.length > 1
    ).length,
    numberOfPagesSharedWith: pagesOwned.filter(
      (page) => page.userPermissionToPages.length > 1
    ).length,
  });
}

export function* analyticsIdentifyWorker({
  payload,
}: ReturnType<typeof authUserModel.actions.setDBUser>) {
  if (payload) {
    yield call(analyticsIdentify, payload);
  }
}
