import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlanName, SubscriptionPromoCode } from '@distribute/shared/types';
import { teamsModel } from '../../../../teams';
import { Button } from '../../../../../shared/ui';
import { useCurrentPlan, useSubscriptionInfo } from '../../../hooks';
import { getPromoCodeDiscountAmount } from '../../../lib';
import { subscriptionModel } from '../../../model';
import { useSubscriptionTeamMembers } from '../../../hooks/useSubscriptionTeamMembers';

type IProps = {
  onCancelSubscription: () => void;
  onRenewSubscription: () => void;
};

export const UpcomingPaymentStatus: FC<IProps> = ({
  onCancelSubscription,
  onRenewSubscription,
}) => {
  const currentPlan = useCurrentPlan();
  const currentTeamMembers = useSubscriptionTeamMembers();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isAppSumoUser = currentTeam.isAppsumo;
  const isPaymentForAppSumoProUser =
    isAppSumoUser &&
    currentTeamMembers.length > 1 &&
    currentPlan.id === PlanName.PRO;

  const dispatch = useDispatch();
  const {
    id: teamId,
    stripeSubscription,
    subscriptionRenewPromoCode,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isRetryingLatestFailedInvoice = useSelector(
    subscriptionModel.selectors.selectIsRetryingLatestFailedInvoice
  );
  const { isPaymentFailed, isPendingCancelation, isHasRenewPromoCode } =
    useSubscriptionInfo();

  if (!stripeSubscription) return null;

  if (isPaymentFailed) {
    return (
      <div className="flex flex-col items-end self-end gap-6 sm:self-start sm:items-start">
        <div className="flex flex-col items-end">
          <span className="text-sm text-gray-600">Pending Cancelation:</span>
          <span className="text-md font-semibold text-error-700">
            {moment(stripeSubscription.nextPaymentAttempt).format(
              'MMMM D, YYYY'
            )}
          </span>
        </div>
        <Button
          color="secondary"
          variant="text"
          size="sm"
          loading={isRetryingLatestFailedInvoice}
          onClick={() =>
            dispatch(
              subscriptionModel.actions.retryLatestFailedInvoice({ teamId })
            )
          }
        >
          Try again
        </Button>
      </div>
    );
  }

  if (isPendingCancelation) {
    return (
      <div className="flex flex-col items-end self-end gap-6 sm:self-start sm:items-start">
        <div className="flex flex-col items-end">
          <span className="text-sm text-gray-600">Pending Cancelation:</span>
          <span className="text-md font-semibold text-warning-700">
            {moment(stripeSubscription.cancelAt).format('MMMM D, YYYY')}
          </span>
        </div>
        <Button
          color={isHasRenewPromoCode ? 'primary' : 'secondary'}
          variant="text"
          size="sm"
          onClick={onRenewSubscription}
        >
          {isHasRenewPromoCode
            ? `Renew for ${getPromoCodeDiscountAmount(
                subscriptionRenewPromoCode as SubscriptionPromoCode
              )} OFF`
            : 'Renew Subscription'}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-end self-end gap-6 sm:self-start sm:items-start">
      {(!isAppSumoUser || isPaymentForAppSumoProUser) && (
        <div className="flex flex-col items-end">
          <span className="text-sm text-gray-600">Next payment date:</span>
          <span className="text-md font-medium text-gray-700">
            {moment(
              stripeSubscription.upcomingInvoiceNextPaymentAttempt
            ).format('MMMM D, YYYY')}
          </span>
        </div>
      )}
      <Button
        color="secondary"
        variant="text"
        size="sm"
        onClick={onCancelSubscription}
      >
        Cancel Subscription
      </Button>
    </div>
  );
};
