import React, { FC, useState } from 'react';
import { Folder } from '@distribute/shared/types';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type IProps = {
  folders: Folder[];
  groupTitle: string;
  onSelectFolder: (folder: Folder) => void;
};

export const FoldersGroup: FC<IProps> = ({
  folders,
  groupTitle,
  onSelectFolder,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!folders.length) return null;

  return (
    <div className="flex flex-col gap-1">
      <div className="px-1 pt-1 pb-1.5">
        <button
          className="flex gap-1.5 cursor-pointer"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Icon
            glyph={isOpen ? IconMap.ChevronUp : IconMap.ChevronDown}
            width={16}
            className="text-gray-600"
          />
          <span className="text-xs font-semibold to-gray-500">
            {groupTitle}
          </span>
        </button>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-1">
          {folders.map((folder) => (
            <button
              key={folder.id}
              onClick={() => onSelectFolder(folder)}
              className="flex gap-3 px-3 py-1.5 cursor-pointer rounded-md hover:bg-base-black hover:bg-opacity-3"
            >
              <Icon
                glyph={IconMap.MenuFolder}
                width={20}
                className="text-gray-500"
              />
              <span className="text-sm text-gray-900 font-semibold truncate">
                {folder.title}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
