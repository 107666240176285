import { call, put, select } from 'redux-saga/effects';
import { GetInvoicesResponseType } from '@distribute/shared/api-types/subscriptions';
import { createNotification, snackbarModel } from '../../../snackbar';
import { subscriptionApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { subscriptionModel } from '..';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';

export function* loadInvoices() {
  try {
    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    yield put(subscriptionModel.actions.setIsLoadingInvoices(true));
    const invoices: GetInvoicesResponseType = yield call(
      subscriptionApi.getInvoices,
      { teamId }
    );
    yield put(subscriptionModel.actions.setInvoices(invoices));
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load invoices.')
      )
    );
  } finally {
    yield put(subscriptionModel.actions.setIsLoadingInvoices(false));
  }
}
