import { Node, mergeAttributes } from '@tiptap/react';

export type ResizeableFigureAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'float-left'
  | 'float-right';

export const ResizeableFigureExt = Node.create({
  name: 'resizeableFigure',
  group: 'block',

  atom: true,
  content: '(media | iframe){1}',

  addAttributes() {
    return {
      'data-media-width': {
        default: null,
      },
      'data-media-height': {
        default: null,
      },
      'data-align': {
        default: 'center',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'figure[data-type="resizeableFigure"]',
        getAttrs(node) {
          return {
            'data-media-width':
              typeof node === 'string'
                ? null
                : node.getAttribute('data-media-width'),
            'data-media-height':
              typeof node === 'string'
                ? null
                : node.getAttribute('data-media-height'),
            'data-align':
              typeof node === 'string' ? null : node.getAttribute('data-align'),
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const height = node.attrs['data-media-height'];
    const width = node.attrs['data-media-width'];
    const align = node.attrs['data-align'] as ResizeableFigureAlign;
    let sizeStyle = '';

    const isImage = node.firstChild?.type.name === 'image';
    const isVideo = ['video', 'videoRecord'].includes(
      node.firstChild?.type.name || ''
    );

    if (height) {
      sizeStyle +=
        isImage || isVideo ? `height: auto;` : `height: ${height}px; `;
    }

    if (width) {
      sizeStyle += `width: ${width}px; `;
    }

    let alignOutsideStyle = '';

    let alignStyle = '';

    const dictionary = {
      'float-left': {
        alignStyle: 'justify-content: flex-start',
        alignOutsideStyle: 'float: left; margin-right: 16px;',
      },

      'float-right': {
        alignStyle: 'justify-content: flex-end',
        alignOutsideStyle: 'float: right; margin-left: 16px;',
      },

      left: {
        alignStyle: 'justify-content: start',
        alignOutsideStyle: '',
      },
      right: {
        alignStyle: 'justify-content: end',
        alignOutsideStyle: '',
      },
      center: {
        alignStyle: 'justify-content: center',
        alignOutsideStyle: '',
      },
    };

    if (align) {
      alignStyle += dictionary[align]?.alignStyle;
      alignOutsideStyle += dictionary[align]?.alignOutsideStyle;
    }

    return [
      'div',
      mergeAttributes({ style: alignOutsideStyle }),
      [
        'figure',
        mergeAttributes(
          HTMLAttributes,
          { 'data-type': 'resizeableFigure' },
          { style: alignStyle },
          {
            class: 'resizeable-figure',
          }
        ),
        ['div', mergeAttributes({ style: sizeStyle }), 0],
      ],
    ];
  },
});
