import { getUserFromFb } from '../../auth-user';
import { FirebaseUser } from '../../../../src/shared/types';
import { select } from 'redux-saga/effects';
import { teamsModel } from '../../../../src/features/teams';
import { TeamFiltered } from '@distribute/shared/types';
import { websocketClient } from '..';

export function* initWebsocketClient() {
  const user: FirebaseUser = yield getUserFromFb();
  const token: string = yield user.getIdToken();

  const currentTeam: TeamFiltered | undefined = yield select(
    teamsModel.selectors.selectCurrentTeam
  );

  if (currentTeam) {
    yield websocketClient.connect(token, currentTeam.id);
  }
}
