import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { mergeAttributes } from '@tiptap/core';
import { Languages } from './constants';

const COPY_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/copy-01.svg';

export const CodeBlockExt = CodeBlockLowlight.extend({
  addAttributes() {
    return {
      ...this.options.HTMLAttributes,
      language: {
        default: 'html',
      },
      selectedLanguage: {
        default: 'html',
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      {
        class: 'code-block group/code',
      },
      [
        'div',
        {
          class: 'code-block__container group-hover/code:visible',
        },
        [
          'span',
          { class: 'code-block__lang' },
          Languages[node.attrs.language] ?? Languages.html,
        ],
        [
          'button',
          {
            class: 'code-block__copy',
            'data-text': node.textContent,
          },
          [
            'img',
            {
              src: COPY_ICON,
              class: 'code-block__copy-icon',
            },
          ],
          ['span', 'Copy'],
          [
            'div',
            {
              class:
                'code-block__copy-tooltip code-block__copy-tooltip--hidden',
            },
            'Copied',
          ],
        ],
      ],
      [
        'pre',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: 'code-block__text',
        }),
        [
          'code',
          {
            class: node.attrs.language
              ? this.options.languageClassPrefix + node.attrs.language
              : null,
          },
          0,
        ],
      ],
    ];
  },
});
