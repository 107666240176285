export enum CreateWithAIPageType {
  BUSINESS_CASE = 'businessCase',
  CALL_FOLLOW_UP = 'callFFollowUp',
  CASE_STUDY = 'caseStudy',
  MUTUAL_ACTION_PLAN = 'mutualActionPlan',
  POST_DEMO_FOLLOW_UP = 'postDemoFollowUp',
  PROSPECTING_PAGE = 'prospectingPage',
  OTHER = 'other',
}

export type ContextType = {
  id?: string;
  namespace?: string;
};

export type CreateWithAIRequestType = {
  prompt: string;
  isTranscript: boolean;
  teamId: number;
  pageType: CreateWithAIPageType;
  context?: ContextType;
};
