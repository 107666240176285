import { BaseTeam } from './team';
import { GettingStartedStep, User, UserFiltered } from './user';

export enum UserToTeamRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  GUEST = 'guest',
}

export type UserToTeam = {
  id: number;
  createdAt: Date;
  inviteAcceptedAt: Date | null;
  externalUserEmail: string | null;
  user: User | null;
  team: BaseTeam;
  role: UserToTeamRole;
  isWaitingForInviteAcceptance: boolean;
  isDeleteOnUpdateSubscriptionPaymentMethod: boolean;
  isWaitingForUpdateSubscriptionPaymentMethod: boolean;
  isSendInvitationOnFinishTeamOnboarding: boolean;
  isSendInvitationOnFinishUserOnboarding: boolean;
  aiMonthlyResponsesCount: number | null;
  zapierApiKey: string;
  isGettingStartedClosed: boolean;
  gettingStartedCompletedSteps: GettingStartedStep[];
  isClosedAlertBarSubscriptionTrial: boolean;
  isClosedAlertBarSubscriptionPaymentFailed: boolean;
  isClosedAlertBarSubscriptionPaymentMethodExpireSoon: boolean;
  isClosedAlertBarSubscriptionConfirmPaymentMethod: boolean;
  gongApiKey: string | null;
};

export type UserToTeamFiltered = {
  id: number;
  createdAt: Date;
  inviteAcceptedAt: Date | null;
  externalUserEmail: string | null;
  user: UserFiltered | null;
  team: BaseTeam;
  role: UserToTeamRole;
  isWaitingForInviteAcceptance: boolean;
};
