import { LINK_VERIFICATION_REGEX } from '../../../../../../shared/constants';
import {
  PageCreationDataSourcesEnum,
  TranscriptsFilterTypesEnum,
} from '../../../../../../entities/create-page-modal';
import { array, mixed, number, object, string } from 'yup';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';

export const generatePageValidationSchema = object().shape({
  title: string().optional(),
  pageType: mixed<CreateWithAIPageType>()
    .oneOf(Object.values(CreateWithAIPageType))
    .required('Page type is required'),
  transcriptsFilter: mixed<TranscriptsFilterTypesEnum>().when(
    ['selectedDataSource', 'selectedCallerName'],
    (selectedDataSource, selectedCallerName) => {
      if (
        selectedDataSource === PageCreationDataSourcesEnum.GONG &&
        selectedCallerName
      ) {
        return mixed<TranscriptsFilterTypesEnum>()
          .oneOf(Object.values(TranscriptsFilterTypesEnum))
          .required('Please select call transcripts');
      }
      return mixed<TranscriptsFilterTypesEnum>().optional();
    }
  ),
  pageTypeDescription: string().optional(),
  selectedCallerName: string().when(
    ['selectedDataSource'],
    (selectedDataSource) => {
      if (selectedDataSource === PageCreationDataSourcesEnum.GONG) {
        return string().required(
          'Please enter or choose the name of the person'
        );
      }
      return string().optional();
    }
  ),
  selectedTranscripts: array().when(
    ['selectedDataSource', 'transcriptsFilter'],
    (selectedDataSource, transcriptsFilter) => {
      if (
        selectedDataSource === PageCreationDataSourcesEnum.GONG &&
        transcriptsFilter === TranscriptsFilterTypesEnum.SPECIFIC
      ) {
        return array()
          .required()
          .min(1, 'Please select at least one call transcript');
      }
      return array().optional();
    }
  ),
  selectedDataSource: mixed<PageCreationDataSourcesEnum>()
    .oneOf(Object.values(PageCreationDataSourcesEnum))
    .required('Data source is required'),
  text: string().when(['selectedDataSource'], (selectedDataSource) => {
    if (selectedDataSource === PageCreationDataSourcesEnum.TEXT) {
      return string().required(
        'This information is required for AI assistant.'
      );
    }
    return string().nullable();
  }),
  links: array()
    .of(object({ url: string(), data: string() }))
    .when(['selectedDataSource'], (selectedDataSource) => {
      if (selectedDataSource === PageCreationDataSourcesEnum.LINK) {
        return array()
          .of(
            object({
              url: string()
                .required('Link is required')
                .matches(
                  LINK_VERIFICATION_REGEX,
                  'Provide a valid link format (e.g. https://www.example.com/)'
                ),
              status: string().notOneOf(['error'], 'Failed to parse link'),
            })
          )
          .required();
      }
      return array().optional();
    }),
  files: array().when(['selectedDataSource'], (selectedDataSource) => {
    if (selectedDataSource === PageCreationDataSourcesEnum.FILE) {
      return array()
        .of(
          object({
            name: string(),
            size: number(),
            type: string(),
            data: string(),
          })
        )
        .required('Please upload file(s) with information for AI assistant.')
        .min(1, 'Please upload file(s) with information for AI assistant.')
        .max(5, 'Only 5 files are allowed');
    }
    return array().optional();
  }),
});

export const pageTypesList = [
  { id: CreateWithAIPageType.BUSINESS_CASE, title: 'Business case' },
  { id: CreateWithAIPageType.CALL_FOLLOW_UP, title: 'Call follow-up' },
  { id: CreateWithAIPageType.CASE_STUDY, title: 'Case study' },
  { id: CreateWithAIPageType.MUTUAL_ACTION_PLAN, title: 'Mutual action plan' },
  {
    id: CreateWithAIPageType.POST_DEMO_FOLLOW_UP,
    title: 'Post Demo follow-up',
  },
  { id: CreateWithAIPageType.PROSPECTING_PAGE, title: 'Prospecting page' },
  {
    id: CreateWithAIPageType.OTHER,
    title: 'Other ',
    description: '(describe what you need)',
  },
];

export const transcriptsFilterList = [
  { id: TranscriptsFilterTypesEnum.SPECIFIC, title: 'specific calls' },
  { id: TranscriptsFilterTypesEnum.LAST_CALL, title: 'only last call' },
  { id: TranscriptsFilterTypesEnum.LAST_MONTH, title: 'for last month' },
  { id: TranscriptsFilterTypesEnum.LAST_3_MONTHS, title: 'for last 3 months' },
  { id: TranscriptsFilterTypesEnum.LAST_6_MONTHS, title: 'for last 6 months' },
  { id: TranscriptsFilterTypesEnum.LAST_YEAR, title: 'for last year' },
  {
    id: TranscriptsFilterTypesEnum.ALL_AVAILABLE,
    title: 'all available calls',
  },
];
