export enum SidebarStepsEnum {
  CONVERSIONS = 'conversions',
  SETTINGS = 'settings',
  DISTRIBUTE = 'distribute',
}

export enum TemplateSidebarTabsEnum {
  CONVERSIONS = 'conversions',
  SETTINGS = 'settings',
}

export type UpdatePersonalizedCoverAction = {
  imageUrl: string;
  textColor: string;
  isNoDistributeLogo: boolean;
  callback?(): void;
};

export enum ActiveConversionSettingsPanel {
  ALERT_BAR = 'alertBar',
  POP_UP = 'popUp',
  CTA = 'cta',
  GATED_CONTENT = 'gatedContent',
  SQUEEZE_PAGE = 'squeezePage',
}
