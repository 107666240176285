import React, { FC, useState } from 'react';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Icon } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';
import { Checkbox } from '../../../../shared/ui';

type TeamPermissionsDropdownItem = {
  id: string;
  name: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  isCanBeDeleted: boolean;
};

type Props = {
  items: TeamPermissionsDropdownItem[];
};

export const TeamPermissionsDropdown: FC<Props> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={(newState) => setIsOpen(newState)}
      modal={false}
    >
      <DropdownMenu.Trigger
        className={cn(
          'border rounded-lg w-80 h-11 border-gray-300 focus:ring-0 focus:outline-none p-2.5 flex flex-row justify-between items-center cursor-pointer text-md text-gray-900 font-medium shadow-xs',
          {
            'border-primary-600': isOpen,
          }
        )}
      >
        <div className="flex gap-1.5">
          {items
            .filter((item) => item.value)
            .map((item) => (
              <div
                key={item.id}
                className={cn(
                  'flex gap-1.5 items-center bg-base-white border border-gray-300 rounded-md pl-2.25 py-0.25 text-sm font-medium text-gray-700 overflow-hidden',
                  {
                    'pr-2.25': !item.isCanBeDeleted,
                    'pr-1.5': item.isCanBeDeleted,
                  }
                )}
              >
                <span>{item.name}</span>
                {item.isCanBeDeleted && (
                  <Icon
                    glyph={IconMap.XClose}
                    width={14}
                    className="text-gray-400 cursor-pointer shrink-0"
                    onClick={() => {
                      item.onChange?.(false);
                    }}
                  />
                )}
              </div>
            ))}
        </div>
        <Icon
          glyph={IconMap.ArrowDown}
          className={cn('transition-all text-gray-500', {
            'rotate-180': isOpen,
          })}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        align="start"
        sideOffset={4}
        className="border rounded-lg bg-base-white divide-solid border-gray-200 focus:outline-none overflow-hidden py-1.5 z-10 shadow-lg !w-[var(--radix-dropdown-menu-trigger-width)]"
      >
        <>
          {items.map((item) => (
            <DropdownMenu.Item
              key={`${item.id}`}
              onSelect={(e) => {
                e.preventDefault();
              }}
              className="outline-none"
            >
              <div className="px-1.5">
                <div
                  className={cn(
                    'flex flex-row items-center w-full p-0 text-left text-gray-700 transition-colors rounded-md whitespace-nowrap focus:outline-none group',
                    {
                      'hover:bg-gray-50 active:bg-gray-100 hover:cursor-pointer':
                        item.isCanBeDeleted,
                    }
                  )}
                >
                  <div className="flex items-center w-full gap-3 text-gray-700 group-hover:text-gray-800">
                    <Checkbox
                      className="p-2.5 w-full"
                      disabled={!item.isCanBeDeleted}
                      checked={item.value}
                      onChange={(e) => {
                        item.onChange?.(e.target.checked);
                      }}
                    >
                      <p className="flex items-center pl-2 text-sm text-gray-700">
                        {item.name}
                      </p>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </DropdownMenu.Item>
          ))}
        </>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
