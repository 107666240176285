import { AIErrorType } from '@distribute/shared/api-types/ai';
import { AIResponseError } from '../model/types';
import { logger } from '../../../shared/lib';

const messagesDictionary = {
  [AIErrorType.MODERATION_FAILED]:
    "Sorry, your request didn't pass our moderation. AI is committed to promoting a safe, respectful environment for all users. Please revise your request.",
  [AIErrorType.TOO_LARGE_REQUEST]: 'AI request is too large',
  [AIErrorType.UNKNOWN_ERROR]: 'Failed to request AI',
};

export class AIError extends Error implements AIResponseError {
  type: AIErrorType = AIErrorType.UNKNOWN_ERROR;
  constructor(message: string, status?: number) {
    super(message);

    if (status) {
      this.message = messagesDictionary[AIErrorType.UNKNOWN_ERROR];
      logger.error(message);
      return;
    }

    switch (message) {
      case AIErrorType.TOO_LARGE_REQUEST: {
        this.type = AIErrorType.TOO_LARGE_REQUEST;
        break;
      }
      case AIErrorType.MODERATION_FAILED: {
        this.type = AIErrorType.MODERATION_FAILED;
        break;
      }
      default: {
        break;
      }
    }

    this.message = messagesDictionary[this.type];
    logger.error(message);
  }
}
