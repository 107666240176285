import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';

import {
  CompleteResetPasswordAction,
  ResetPasswordAction,
  ChangePasswordAction,
  ChangeFullNameAction,
  ChangeEmailAction,
  ChangePhotoAction,
  CreatePasswordAction,
} from './types';

type State = {
  isChangeFullNameLoading: boolean;
  changeFullNameError: string;
  isChangeEmailLoading: boolean;
  changeEmailError: string;
  isUploadPhotoLoading: boolean;
  uploadPhotoError: string;
  uploadingPhotoProgress: number;
  isRemovePhotoLoading: boolean;
  isChangePasswordLoading: boolean;
  changePasswordError: string;
  isUserPasswordCreating: boolean;
  isGmailAuthDisconnecting: boolean;
  isGmailAuthConnecting: boolean;
};
const initialState: State = {
  isChangeFullNameLoading: false,
  changeFullNameError: '',
  isChangeEmailLoading: false,
  changeEmailError: '',
  uploadingPhotoProgress: 0,
  isUploadPhotoLoading: false,
  isRemovePhotoLoading: false,
  uploadPhotoError: '',
  isChangePasswordLoading: false,
  changePasswordError: '',
  isUserPasswordCreating: false,
  isGmailAuthDisconnecting: false,
  isGmailAuthConnecting: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'profileSetting',
  initialState,
  reducers: {
    setIsChangeFullNameLoading: (
      state,
      { payload: isChangeFullNameLoading }: PayloadAction<boolean>
    ) => ({ ...state, isChangeFullNameLoading }),

    setChangeFullNameError: (
      state,
      { payload: changeFullNameError }: PayloadAction<string>
    ) => ({ ...state, changeFullNameError }),

    setIsChangeEmailLoading: (
      state,
      { payload: isChangeEmailLoading }: PayloadAction<boolean>
    ) => ({ ...state, isChangeEmailLoading }),

    setChangeEmailError: (
      state,
      { payload: changeEmailError }: PayloadAction<string>
    ) => ({ ...state, changeEmailError }),

    setIsUploadPhotoLoading: (
      state,
      { payload: isUploadPhotoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isUploadPhotoLoading }),

    setUploadPhotoError: (
      state,
      { payload: uploadPhotoError }: PayloadAction<string>
    ) => ({ ...state, uploadPhotoError }),

    setUploadPhotoProgress: (
      state,
      { payload: uploadingPhotoProgress }: PayloadAction<number>
    ) => ({ ...state, uploadingPhotoProgress }),

    setIsRemovePhotoLoading: (
      state,
      { payload: isRemovePhotoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isRemovePhotoLoading }),

    setIsChangePasswordLoading: (
      state,
      { payload: isChangePasswordLoading }: PayloadAction<boolean>
    ) => ({ ...state, isChangePasswordLoading }),

    setChangePasswordError: (
      state,
      { payload: changePasswordError }: PayloadAction<string>
    ) => ({ ...state, changePasswordError }),

    setIsUserPasswordCreating: (
      state,
      { payload: isUserPasswordCreating }: PayloadAction<boolean>
    ) => ({ ...state, isUserPasswordCreating }),

    setIsGmailAuthDisconnecting: (
      state,
      { payload: isGmailAuthDisconnecting }: PayloadAction<boolean>
    ) => ({ ...state, isGmailAuthDisconnecting }),

    setIsGmailAuthConnecting: (
      state,
      { payload: isGmailAuthConnecting }: PayloadAction<boolean>
    ) => ({ ...state, isGmailAuthConnecting }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  resetPasswordClick: createAction<ResetPasswordAction>(
    'profileSetting/resetPasswordClick'
  ),
  completeResetPasswordClick: createAction<CompleteResetPasswordAction>(
    'profileSetting/completeResetPasswordClick'
  ),
  changePasswordClick: createAction<ChangePasswordAction>(
    'profileSetting/changePasswordClick'
  ),
  changeFullNameClick: createAction<ChangeFullNameAction>(
    'profileSetting/changeFullName'
  ),
  changeEmailClick: createAction<ChangeEmailAction>(
    'profileSetting/changeEmail'
  ),
  changePhotoClick: createAction<ChangePhotoAction>(
    'profileSetting/changePhoto'
  ),
  deletePhotoClick: createAction('profileSettings/deletePhoto'),

  createUserPassword: createAction<CreatePasswordAction>(
    'profileSettings/createUserPassword'
  ),
  disconnectGmailAuthentication: createAction(
    'profileSettings/unlinkGmailAuthentication'
  ),
  connectGmailAuthentication: createAction(
    'profileSettings/connectGmailAuthentication'
  ),
};
