import { put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api';
import { Folder } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { CreateUserPermissionResponseType } from '@distribute/shared/api-types/folder';
import { actions } from '../reducer';

export function* createUserPermission({
  payload: { folderId, userIds, role, cb },
}: ReturnType<typeof foldersModel.actions.createUserPermission>) {
  try {
    yield put(actions.setCreateUserPermissionIsLoading(true));
    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    const userPermissions: CreateUserPermissionResponseType =
      yield foldersApi.createUserPermission(folderId, {
        userIds,
        role,
      });
    const newFolders = folders.map((i) => {
      if (i.id === folderId) {
        return {
          ...i,
          userPermissionToFolders: [
            ...i.userPermissionToFolders,
            ...userPermissions,
          ],
        };
      }
      return i;
    });
    const currentFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );

    if (currentFolder)
      yield put(
        foldersModel.actions.setCurrentFolder({
          ...currentFolder,
          userPermissionToFolders: [
            ...currentFolder.userPermissionToFolders,
            ...userPermissions,
          ],
        })
      );

    yield put(foldersModel.actions.setFolders(newFolders));
    cb();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          'The user permissions have not been created'
        )
      )
    );
  } finally {
    yield put(actions.setCreateUserPermissionIsLoading(false));
  }
}
