import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useEffect, useState } from 'react';
import { templatesApi } from '../../../../shared/api';

export const usePreview = ({ id }: { id: string }) => {
  const [template, setTemplate] = useState<TemplateExtended>();
  useEffect(() => {
    templatesApi
      .getPublicTemplate({
        id,
      })
      .then((res) => {
        setTemplate(res);
      });
  }, [id]);

  return {
    template,
  };
};
