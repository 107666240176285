import { call, put } from 'redux-saga/effects';
import { Folder } from '@distribute/shared/types';
import { foldersApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { publicTemplatesModel } from '..';

export function* loadFolders({
  payload: { teamId, cb },
}: ReturnType<typeof publicTemplatesModel.actions.loadFolders>) {
  try {
    yield put(publicTemplatesModel.actions.setLoadingFoldersForTeamId(teamId));
    const folders: Folder[] = yield call(foldersApi.getFolders, teamId);
    yield put(publicTemplatesModel.actions.setFolders(folders));
    yield call(cb);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to load folders`)
      )
    );
  } finally {
    yield put(publicTemplatesModel.actions.setLoadingFoldersForTeamId(null));
  }
}
