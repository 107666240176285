import { channel } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { Page, TeamFiltered } from '@distribute/shared/types';
import { GenerateUploadUrlResponse } from '@distribute/shared/api-types/media-uploader';
import { mediaUploadApi } from '../../../shared/api/';
import { pagesModel } from '../../pages';
import { actions, UploadFile } from './reducer';

import { selectFiles } from './selectors';
import { createNotification, snackbarModel } from '../../snackbar';
import { teamsModel } from '../../teams';
import { templatesModel } from '../../templates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { getUploadProgress } from '../../../shared/lib';

const uploadingProgressChannel = channel<{ id: string; progress: number }>();

export function* uploadFile({
  payload,
}: ReturnType<typeof actions.uploadFile>) {
  const { file, id, cb } = payload;
  try {
    const { id: currentTeamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const page: Page = yield select(pagesModel.selectors.selectCurrentPage);
    const currentTemplate: TemplateExtended = yield select(
      templatesModel.selectors.selectCurrentTemplate
    );
    const currentPageOrTemplate = page ?? currentTemplate;
    const currentFiles: UploadFile[] = yield select(selectFiles);

    const newFile: UploadFile = {
      id,
      status: 'uploading',
      progress: 0,
    };
    yield put(actions.setFiles([...currentFiles, newFile]));

    const { uploadUrlConfig, uploadedFileUrl }: GenerateUploadUrlResponse =
      yield call(
        mediaUploadApi.generateUploadUrl,

        {
          pathName: currentPageOrTemplate
            ? `${currentPageOrTemplate.id}`
            : 'snippet',
          teamId: currentTeamId,
          fileMimeType: file.type,
        }
      );
    const formData = new FormData();

    Object.entries(uploadUrlConfig.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('file', file);

    yield call(axios.post, uploadUrlConfig.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (value) => {
        uploadingProgressChannel.put({
          id,
          progress: getUploadProgress(value),
        });
      },
    });

    yield put(
      actions.updateFile({ id, status: 'success', url: uploadedFileUrl })
    );

    cb(uploadedFileUrl, file.name);
  } catch (error: unknown) {
    yield put(
      actions.updateFile({
        id,
        status: 'error',
        errorMessage: (error as Error).message,
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Error by file uploading')
      )
    );
  }
}

export function* uploadingProgressChannelWorker() {
  yield takeEvery(uploadingProgressChannel, function* ({ id, progress }) {
    yield put(actions.updateFile({ id, progress }));
  });
}
