import { User } from './user';

export enum RequestType {
  REPORT_ISSUE = 'reportIssue',
  HELP_USING_DISTRIBUTE = 'helpUsingDistribute',
  HELP_WITH_MY_ACCOUNT = 'helpWithMyAccount',
}

export type ContactSupportForm = {
  id: number;
  createdAt: Date;
  user: User;
  requestType: RequestType;
  subject: string;
  message: string;
};
