import { Page } from '@distribute/shared/types';
import { Avatar, Icon } from '.';
import React from 'react';
import { IconMap } from '../../shared/sprite';
import { Emoji } from 'emoji-picker-react';

type Props = {
  page: Page;
  size?: 'sm' | 'lg';
};

export const PageItemAvatar: React.FC<Props> = ({ page, size = 'lg' }) => {
  const {
    isBranded,
    content: { brandLogo, icon },
  } = page;

  if (isBranded) {
    return brandLogo ? (
      <Avatar src={brandLogo} displayName="" theme="rect" />
    ) : (
      <div className="w-11 h-11 rounded-lg border text-gray-700 border-light-7 flex items-center justify-center">
        <Icon glyph={IconMap.Image05} width={20} />
      </div>
    );
  }
  return <Emoji unified={icon} size={size === 'lg' ? 32 : 20} />;
};
