import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Folder,
  FolderTeamMembersPermission,
  User,
  UserPermissionToFolderRole,
} from '@distribute/shared/types';
import { authUserModel } from '../../../entities/auth-user';

type GroupedFolders = {
  privateFolders: Folder[];
  sharedFolders: Folder[];
};

export function splitFoldersToPrivateShared(
  folders: Folder[],
  currentUser: User
) {
  return folders.reduce<GroupedFolders>(
    (acc, folder) => {
      const folderOwner = folder.userPermissionToFolders.find(
        (i) => i.role === UserPermissionToFolderRole.OWNER
      );

      if (
        folder.teamMembersPermission ===
          FolderTeamMembersPermission.INVITE_ONLY &&
        folder.userPermissionToFolders.length === 1 &&
        folderOwner?.user.id === currentUser.id
      ) {
        acc.privateFolders.push(folder);
      } else {
        acc.sharedFolders.push(folder);
      }

      return acc;
    },
    { privateFolders: [], sharedFolders: [] }
  );
}

export const useSplitFoldersToPrivateShared = (folders: Folder[]) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  return useMemo(() => {
    return splitFoldersToPrivateShared(folders, currentUser);
  }, [folders, currentUser]);
};
