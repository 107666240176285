import { Node } from '@tiptap/core';

export const ImageExt = Node.create({
  name: 'image',
  group: 'media',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', { ...HTMLAttributes, draggable: false }];
  },
});
