import { call, put, select } from 'redux-saga/effects';

import { alertBarApi } from '../../../../shared/api';
import { AlertBar } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectAlertBar } from '../selectors';

export function* updateAlertBarDB({
  payload: { documentContentId },
}: ReturnType<typeof conversionKitModel.actions.updateAlertBarDB>) {
  try {
    const alertBar: AlertBar = yield select(selectAlertBar);

    const { id: _, ...data } = alertBar;

    yield call(alertBarApi.updateAlertBar, documentContentId, data);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update alert bar`)
      )
    );
  }
}
