import { Fragment } from 'react';
import {
  CellAlignmentMenuItem,
  IProps as CellAlignmentMenuItemProps,
} from './CellAlignmentMenuItem';

export type IProps = {
  items: CellAlignmentMenuItemProps[];
};

export const CellAlignmentMenu = ({ items }: IProps) => {
  return (
    <div className="flex border border-gray-300 rounded-lg overflow-hidden w-54">
      {items.map((item, idx) => (
        <Fragment key={item.id}>
          <CellAlignmentMenuItem {...item} />
          {idx !== items.length - 1 && <div className="w-0.25 bg-gray-300" />}
        </Fragment>
      ))}
    </div>
  );
};
