import { IconMap } from '../../../shared/sprite';

export const FONTS = {
  systemUi: {
    id: 'systemUi',
    label: 'system-ui',
  },
  Arial: {
    id: 'Arial',
    label: 'Arial',
  },
  Bitter: {
    id: 'Bitter',
    label: 'Bitter',
  },
  DancingScript: {
    id: 'DancingScript',
    label: 'Dancing Script',
  },
  DroidSans: {
    id: 'DroidSans',
    label: 'Droid Sans',
  },
  Exo: {
    id: 'Exo',
    label: 'Exo',
  },
  Figtree: {
    id: 'Figtree',
    label: 'Figtree',
  },
  Georgia: {
    id: 'Georgia',
    label: 'Georgia',
  },
  Inconsolata: {
    id: 'Inconsolata',
    label: 'Inconsolata',
  },
  Inter: {
    id: 'Inter',
    label: 'Inter',
  },
  Lato: {
    id: 'Lato',
    label: 'Lato',
  },
  Merriweather: {
    id: 'Merriweather',
    label: 'Merriweather',
  },
  Montserrat: {
    id: 'Montserrat',
    label: 'Montserrat',
  },
  OpenSans: {
    id: 'OpenSans',
    label: 'Open Sans',
  },
  Oswald: {
    id: 'Oswald',
    label: 'Oswald',
  },
  PTSans: {
    id: 'PTSans',
    label: 'PT Sans',
  },
  PTSerif: {
    id: 'PTSerif',
    label: 'PT Serif',
  },
  PalatinoLinotype: {
    id: 'PalatinoLinotype',
    label: 'Palatino Linotype',
  },
  Rubik: {
    id: 'Rubik',
    label: 'Rubik',
  },
  Roboto: {
    id: 'Roboto',
    label: 'Roboto',
  },
  Tahoma: {
    id: 'Tahoma',
    label: 'Tahoma',
  },
  TimesNewRoman: {
    id: 'TimesNewRoman',
    label: 'Times New Roman',
  },
  TrebuchetMS: {
    id: 'TrebuchetMS',
    label: 'Trebuchet MS',
  },
  Ubuntu: {
    id: 'Ubuntu',
    label: 'Ubuntu',
  },
  Verdana: {
    id: 'Verdana',
    label: 'Verdana',
  },
  Vollkorn: {
    id: 'Vollkorn',
    label: 'Vollkorn',
  },
};

export const FONTS_OPTIONS = [
  {
    id: FONTS.systemUi.id,
    label: 'System UI',
    iconName: IconMap.ArialFont,
  },
  {
    id: FONTS.Arial.id,
    label: FONTS.Arial.label,
    iconName: IconMap.ArialFont,
    isSeparatedFromTop: true,
  },
  {
    id: FONTS.Bitter.id,
    label: FONTS.Bitter.label,
    iconName: IconMap.BitterFont,
  },
  {
    id: FONTS.DancingScript.id,
    label: FONTS.DancingScript.label,
    iconName: IconMap.DancingScriptFont,
  },
  {
    id: FONTS.DroidSans.id,
    label: FONTS.DroidSans.label,
    iconName: IconMap.DroidSansFont,
  },
  {
    id: FONTS.Exo.id,
    label: FONTS.Exo.label,
    iconName: IconMap.ExoFont,
  },
  {
    id: FONTS.Figtree.id,
    label: FONTS.Figtree.label,
    iconName: IconMap.FigtreeFont,
  },
  {
    id: FONTS.Georgia.id,
    label: FONTS.Georgia.label,
    iconName: IconMap.GeorgiaFont,
  },
  {
    id: FONTS.Inconsolata.id,
    label: FONTS.Inconsolata.label,
    iconName: IconMap.InconsolataFont,
  },
  {
    id: FONTS.Inter.id,
    label: FONTS.Inter.label,
    iconName: IconMap.InterFont,
  },
  {
    id: FONTS.Lato.id,
    label: FONTS.Lato.label,
    iconName: IconMap.LatoFont,
  },
  {
    id: FONTS.Merriweather.id,
    label: FONTS.Merriweather.label,
    iconName: IconMap.MerriWeatherFont,
  },
  {
    id: FONTS.Montserrat.id,
    label: FONTS.Montserrat.label,
    iconName: IconMap.MontserratFont,
  },
  {
    id: FONTS.OpenSans.id,
    label: FONTS.OpenSans.label,
    iconName: IconMap.OpenSansFont,
  },
  {
    id: FONTS.Oswald.id,
    label: FONTS.Oswald.label,
    iconName: IconMap.OswaldFont,
  },
  {
    id: FONTS.PTSans.id,
    label: FONTS.PTSans.label,
    iconName: IconMap.PTSansFont,
  },
  {
    id: FONTS.PTSerif.id,
    label: FONTS.PTSerif.label,
    iconName: IconMap.PTSerifFont,
  },
  {
    id: FONTS.PalatinoLinotype.id,
    label: FONTS.PalatinoLinotype.label,
    iconName: IconMap.PalationFont,
  },
  {
    id: FONTS.Rubik.id,
    label: FONTS.Rubik.label,
    iconName: IconMap.RubikFont,
  },
  {
    id: FONTS.Roboto.id,
    label: FONTS.Roboto.label,
    iconName: IconMap.RobotoFont,
  },
  {
    id: FONTS.Tahoma.id,
    label: FONTS.Tahoma.label,
    iconName: IconMap.TahomaFont,
  },
  {
    id: FONTS.TimesNewRoman.id,
    label: FONTS.TimesNewRoman.label,
    iconName: IconMap.TimesNewRomanFont,
  },
  {
    id: FONTS.TrebuchetMS.id,
    label: FONTS.TrebuchetMS.label,
    iconName: IconMap.TrebuchetMSFont,
  },
  {
    id: FONTS.Ubuntu.id,
    label: FONTS.Ubuntu.label,
    iconName: IconMap.UbuntuFont,
  },
  {
    id: FONTS.Verdana.id,
    label: FONTS.Verdana.label,
    iconName: IconMap.VerdanaFont,
  },
  {
    id: FONTS.Vollkorn.id,
    label: FONTS.Vollkorn.label,
    iconName: IconMap.VollkornFont,
  },
];
