import { FC } from 'react';
import { SidebarBannerItems } from './SidebarBannerItems';
import { RecordStatus } from '../../recordings';

export const SidebarBanner: FC = () => {
  return (
    <div className="pb-6 px-4 absolute bottom-0 w-full">
      <RecordStatus />
      <SidebarBannerItems />
    </div>
  );
};
