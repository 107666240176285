import { GettingStartedStep, Page } from '@distribute/shared/types';
import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { call, put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { gettingStartedModel } from '../../../getting-started/model';
import { AxiosError } from 'axios';

export function* changePublicStatus({
  payload: { pageId, published, callback },
}: ReturnType<typeof pagesModel.actions.changePublicStatus>) {
  try {
    yield pagesApi.changePublicStatus(pageId, { published });

    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPage
    );

    yield put(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        published,
      })
    );

    if (published) {
      yield call(
        gettingStartedModel.sagas.onGettingStartedStepCompleted,
        GettingStartedStep.PUBLISH_PAGE
      );
    }

    callback();
  } catch (e: unknown) {
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPage
    );

    yield put(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        published: !published,
      })
    );
    logger.error(e);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          e instanceof AxiosError && e.response?.status === 403
            ? 'Max published pages limit reached, upgrade plan for publishing new pages'
            : 'Failed to update public status'
        )
      )
    );
  }
}
