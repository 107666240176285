import { fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../reducer';
import { changeLogo, deleteLogo, watchUploadFile } from './changeLogo';
import { updateNameDomain } from './updateNameDomain';
import { requestDeleteTeam } from './requestDeleteTeam';
import { customDomainsModel } from '../../../custom-domains';

export function* teamInfoSettingsWorker() {
  yield takeEvery(actions.changeLogo, changeLogo);
  yield takeEvery(actions.deleteLogo, deleteLogo);
  yield takeEvery(actions.updateNameDomain, updateNameDomain);
  yield takeEvery(actions.requestDeleteTeam, requestDeleteTeam);
  yield takeEvery(
    customDomainsModel.actions.createCustomDomain,
    customDomainsModel.sagas.createCustomDomain
  );
  yield takeEvery(
    customDomainsModel.actions.deleteCustomDomain,
    customDomainsModel.sagas.deleteCustomDomain
  );
  yield takeEvery(
    customDomainsModel.actions.activateCustomDomain,
    customDomainsModel.sagas.activateCustomDomain
  );
  yield fork(watchUploadFile);
}
