import { useDispatch, useSelector } from 'react-redux';
import { useCurrentPlan } from '../../subscription/hooks';
import { checkIsMaxMembersCountForPlanReached } from '../../subscription/lib';
import { useSubscriptionTeamMembers } from '../../subscription/hooks/useSubscriptionTeamMembers';
import { subscriptionModel } from '../../subscription';
import { teamMembersModel } from '../model';
import { useCallback } from 'react';
import { teamsModel } from '../../teams';
import { PlanName } from '@distribute/shared/types';

export const useHandlerInviteTeamMembers = () => {
  const dispatch = useDispatch();
  const { id: currentPlanId } = useCurrentPlan();
  const teamMembers = useSubscriptionTeamMembers();
  const { subscriptionPaymentMethod, isAppsumo } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isProUser = currentPlanId === PlanName.PRO;

  return useCallback(() => {
    if (!subscriptionPaymentMethod && isAppsumo && isProUser) {
      dispatch(
        teamMembersModel.actions.setIsWaitingForTeamInviteMembersModalOpen(true)
      );
      dispatch(
        subscriptionModel.actions.setIsShowUpdatePaymentMethodModal(true)
      );
    } else if (
      checkIsMaxMembersCountForPlanReached(currentPlanId, teamMembers.length)
    ) {
      dispatch(
        subscriptionModel.actions.setIsShowPlanReachedMembersLimitModal(true)
      );
    } else {
      dispatch(teamMembersModel.actions.setIsShowInviteTeamMembersModal(true));
    }
  }, [
    subscriptionPaymentMethod,
    isAppsumo,
    isProUser,
    currentPlanId,
    teamMembers.length,
    dispatch,
  ]);
};
