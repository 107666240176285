import React, { FC } from 'react';
import { Button, Modal } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionModel } from '../../model';

export const ThanksConfirmPaymentMethodModal: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    subscriptionModel.selectors.selectIsShowThanksConfirmPaymentMethod
  );
  const onClose = () => {
    dispatch(
      subscriptionModel.actions.setIsShowThanksConfirmPaymentMethod(false)
    );
  };
  return (
    <Modal
      isShowCancelButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title="Thank you for confirmation!"
      actionButton={
        <Button fullWidth onClick={onClose} color="primary" variant="text">
          Got It
        </Button>
      }
      className="!w-120 sm:!w-full"
    >
      <div className="text-sm text-gray-600">
        Congratulations on confirming your payment method! We are thrilled to
        grant you full ownership of this team.
      </div>
    </Modal>
  );
};
