import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { RequestType } from '@distribute/shared/types';
import {
  Button,
  Icon,
  Input,
  Item,
  Modal,
  Select,
  TagItem,
} from '../../../shared/ui';
import { contactSupportFormModel } from '../model';
import { IconMap } from '../../../shared/sprite';
import { ChangeEvent } from 'react';

type ContactSupportFormSchema = {
  requestType: RequestType;
  subject: string;
  message: string;
};

const nameDomainSchema = object().shape({
  requestType: string().required(),
  subject: string().required(),
  message: string().required(),
});

const requestTypeToText: Record<RequestType, string> = {
  [RequestType.HELP_USING_DISTRIBUTE]: 'Help using Distribute',
  [RequestType.HELP_WITH_MY_ACCOUNT]: 'Help with my account',
  [RequestType.REPORT_ISSUE]: 'Report an issue',
};

const ATTACHMENTS_MAX_COUNT = 4;

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContactSupportFormModal: FC<IProps> = ({ isOpen, onClose }) => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const dispatch = useDispatch();
  const isLoadingContactSupport = useSelector(
    contactSupportFormModel.selectors.selectIsLoadingContactSupport
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactSupportFormSchema>({
    resolver: yupResolver(nameDomainSchema),
  });
  const requestTypeItems = useMemo<Item[]>(
    () =>
      Object.values(RequestType).map((type) => ({
        id: type,
        title: requestTypeToText[type],
      })),
    []
  );

  const handleClose = () => {
    onClose();
    reset();
    setAttachments([]);
  };

  const handleFormSubmit = handleSubmit(
    ({ message, requestType, subject }: ContactSupportFormSchema) => {
      dispatch(
        contactSupportFormModel.actions.contactSupport({
          attachments,
          message,
          subject,
          requestType,
          cb: handleClose,
        })
      );
    }
  );

  const handleChangeAttachments = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      setAttachments(
        [...attachments, ...Array.from(files)].slice(0, ATTACHMENTS_MAX_COUNT)
      );
    }
  };

  const handleDeleteAttachment = (file: File) => {
    setAttachments(attachments.filter((i) => i !== file));
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      title="Contact Support"
      className="w-150"
      isActionButtonsAlignEnd
      actionButton={
        <Button
          type="submit"
          color="primary"
          variant="text"
          loading={isLoadingContactSupport}
          onClick={handleFormSubmit}
        >
          Send
        </Button>
      }
    >
      <form className="flex flex-col gap-3">
        <Controller
          defaultValue={undefined}
          control={control}
          name="requestType"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              items={requestTypeItems}
              value={value}
              currentValue={requestTypeItems.find((i) => i.id === value)}
              label="Request Type"
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <Input
          label="Subject"
          {...register('subject')}
          isError={!!errors.subject}
          type="text"
        />
        <Input
          isTextArea
          label="Message"
          {...register('message')}
          isError={!!errors.message}
          type="text"
        />
        <div className="flex flex-col gap-3">
          {attachments.length > 0 && (
            <div className="flex flex-wrap gap-3">
              {attachments.map((file, index) => (
                <TagItem
                  key={index}
                  title={file.name}
                  onDelete={() => handleDeleteAttachment(file)}
                ></TagItem>
              ))}
            </div>
          )}
          {attachments.length < ATTACHMENTS_MAX_COUNT && (
            <label className="flex items-center gap-2 text-sm font-semibold cursor-pointer text-primary-700">
              <input
                multiple
                accept="image/png, image/gif, image/jpeg, image/webp"
                type="file"
                onChange={handleChangeAttachments}
                className="hidden"
                value=""
              />
              <Icon glyph={IconMap.Attachment02} width={20} />
              <span>Attach Screenshot</span>
            </label>
          )}
        </div>
      </form>
    </Modal>
  );
};
