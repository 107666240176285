import { call, put } from 'redux-saga/effects';

import { SqueezePage } from '@distribute/shared/types';
import { squeezePageApi } from '../../../shared/api';
import { actions } from '../model/reducer';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';

export function* setSqueezePageData(documentContentId: number) {
  try {
    yield put(
      actions.setConversionKitStatus({
        status: 'pending',
        statusType: 'squeezePageStatus',
      })
    );

    const squeezePage: SqueezePage = yield call(
      squeezePageApi.getSqueezePage,
      documentContentId
    );

    yield put(actions.setSqueezePage(squeezePage));
    yield put(
      actions.setConversionKitStatus({
        status: 'success',
        statusType: 'squeezePageStatus',
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setConversionKitStatus({
        status: 'error',
        statusType: 'squeezePageStatus',
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show Squeeze Page`)
      )
    );
  }
}
