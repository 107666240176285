import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* changePageStyle({
  payload: { pageId, ...data },
}: ReturnType<typeof pagesModel.actions.changePageStyle>) {
  const currentPage: Page = yield select(
    pagesModel.selectors.selectCurrentPageWithError
  );

  try {
    yield put(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        content: {
          ...currentPage.content,
          headingFontFamily:
            data.headingFontFamily ?? currentPage.content.headingFontFamily,
          paragraphFontFamily:
            data.paragraphFontFamily ?? currentPage.content.paragraphFontFamily,
        },
      })
    );
    const updatedPage: Page = yield pagesApi.updatePage(pageId, data);

    if (!updatedPage) {
      throw new Error('updatedPage is undefined');
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(pagesModel.actions.setCurrentPage(currentPage));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change page style')
      )
    );
  }
}
