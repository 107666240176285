import { Icon } from '../../../../shared/ui';
import { Editor } from '@tiptap/react';
import { IconMap } from '../../../../shared/sprite';
import { useMemo } from 'react';
import { RichTextEditorDropdownWithTooltip } from './rich-text-editor-dropdown';

const blockDropdownItems = (editor: Editor | null) =>
  editor && [
    {
      id: 'left',
      onClick: () => {
        editor.chain().focus().setTextAlign('left').run();
      },
      value: <Icon glyph={IconMap.AlignLeft} width={20} />,
      tooltip: <p className="text-sm font-medium">Align left</p>,
    },
    {
      id: 'center',
      onClick: () => {
        editor.chain().focus().setTextAlign('center').run();
      },
      value: <Icon glyph={IconMap.AlignCenter} width={20} />,
      tooltip: <p className="text-sm font-medium">Align center</p>,
    },
    {
      id: 'right',
      onClick: () => {
        editor.chain().focus().setTextAlign('right').run();
      },
      value: <Icon glyph={IconMap.AlignRight} width={20} />,
      tooltip: <p className="text-sm font-medium">Align right</p>,
    },
    {
      id: 'justify',
      onClick: () => {
        editor.chain().setTextAlign('justify').focus().run();
      },
      value: <Icon glyph={IconMap.AlignJustify} width={20} />,
      tooltip: <p className="text-sm font-medium">Align justify</p>,
    },
  ];

export interface TiptapTextAlignDropdownProps {
  editor: Editor;
}

export const TiptapTextAlignDropdown = ({
  editor,
}: TiptapTextAlignDropdownProps) => {
  const items = useMemo(() => blockDropdownItems(editor), [editor]);

  const currentItemId = useMemo(() => {
    if (!editor) {
      return 'left';
    }

    if (editor.isActive({ textAlign: 'center' })) {
      return 'center';
    }

    if (editor.isActive({ textAlign: 'right' })) {
      return 'right';
    }

    if (editor.isActive({ textAlign: 'justify' })) {
      return 'justify';
    }

    return 'left';
  }, [editor, editor.state]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!items) {
    return null;
  }

  return (
    <RichTextEditorDropdownWithTooltip
      items={items}
      selectedItemId={currentItemId}
      tooltip={<p className="text-sm font-medium">Text alignment</p>}
    />
  );
};
