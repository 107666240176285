import { put, takeEvery } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';

export function* profileSettingErrorHandlerWorker() {
  yield takeEvery(
    [
      actions.setChangeEmailError,
      actions.setChangeFullNameError,
      actions.setChangePasswordError,
      actions.setUploadPhotoError,
    ],
    function* ({ payload }) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', payload)
        )
      );
    }
  );
}
