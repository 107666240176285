import { SnippetType } from '@distribute/shared/types';

export enum SnippetsConfigBasicEnum {
  ALL = 'all',
  RECENT = 'recent',
  PERSONAL = 'personal',
}

export type SnippetFolderType = SnippetsConfigBasicEnum | number;

export const typeFilters = [
  {
    name: 'All',
    value: undefined,
  },
  { name: 'Text', value: SnippetType.TEXT },
  { name: 'Image', value: SnippetType.IMAGE },
  { name: 'Video', value: SnippetType.VIDEO },
  { name: 'File', value: SnippetType.FILE },
];

export enum SnippetsSwitcherEnum {
  POPULAR = 'popular',
  NEW = 'new',
}

export const switcherButtons = [
  {
    id: SnippetsSwitcherEnum.NEW,
    title: 'New',
  },
  {
    id: SnippetsSwitcherEnum.POPULAR,
    title: 'Most Popular',
  },
];
