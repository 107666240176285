import React, { ChangeEvent, FC, useState, CSSProperties } from 'react';
import classNames from 'classnames';
import { CountryData, default as ReactPhoneInput } from 'react-phone-input-2';
import { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

type Size = 'md' | 'sm';

type Props = PhoneInputProps & {
  label?: string;
  className?: string;
  componentSize?: Size;
  errorText?: string;
};

const isCountryData = (data: object | CountryData): data is CountryData =>
  (data as CountryData).countryCode !== undefined;

export const PhoneInput: FC<Props> = function Input({
  componentSize = 'sm',
  errorText,
  onChange,
  containerStyle,
  containerClass,
  inputClass,
  label,
  className,
  ...rest
}) {
  const [countryCode, setCountryCode] = useState('us');

  const handleChange = (
    value: string,
    data: CountryData | Record<string, never>,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setCountryCode(data.countryCode ?? 'us');
    onChange?.(value, data, event, formattedValue);
  };
  return (
    <div className={classNames('flex flex-col gap-1.5', className)}>
      {label && (
        <div className="text-sm text-gray-700 font-medium">{label}</div>
      )}
      <ReactPhoneInput
        onMount={(_, data) => {
          if (isCountryData(data)) {
            setCountryCode(data.countryCode);
          }
        }}
        placeholder="+1 (555) 000-0000"
        containerClass={classNames(
          'phone-input-container',
          `phone-input-size-${componentSize}`,
          { 'phone-input-error': !!errorText },
          containerClass
        )}
        containerStyle={
          {
            ...containerStyle,
            '--selected-country-code': `'${countryCode}'`,
          } as CSSProperties
        }
        inputClass={classNames('phone-input', inputClass)}
        onChange={handleChange}
        {...rest}
      />
      {errorText && <div className="text-sm text-error-500">{errorText}</div>}
    </div>
  );
};
