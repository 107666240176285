import {
  GONG_ACCESS_TOKEN_EXPIRATION_DATE,
  GONG_ACCESS_TOKEN_STORAGE_KEY,
  GONG_BASE_URL,
  GONG_REFRESH_TOKEN_STORAGE_KEY,
} from '../../../../shared/constants';
import { GetGongAuthInfoType } from '../types';

export function setGongAuthData(data: GetGongAuthInfoType) {
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + data.expires_in);

  localStorage.setItem(GONG_ACCESS_TOKEN_STORAGE_KEY, data.access_token);
  localStorage.setItem(
    GONG_ACCESS_TOKEN_EXPIRATION_DATE,
    expirationDate.toString()
  );
  localStorage.setItem(GONG_REFRESH_TOKEN_STORAGE_KEY, data.refresh_token);
  localStorage.setItem(GONG_BASE_URL, data.api_base_url_for_customer);
}

export function removeGongAuthData() {
  localStorage.removeItem(GONG_ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(GONG_ACCESS_TOKEN_EXPIRATION_DATE);
  localStorage.removeItem(GONG_REFRESH_TOKEN_STORAGE_KEY);
  localStorage.removeItem(GONG_BASE_URL);
}
