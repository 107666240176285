import React, { FC, useCallback, useEffect, useState } from 'react';
import { SidebarMenuContent } from '../../sidebar';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { HeaderLine } from './components';
import { TopBar } from '../../topbar';
import { InviteMembersModal } from '../../../features/team-members';
import { ContactSupportFormModal } from '../../../features/contact-support-form';
import { SelectTeamDropdown } from '../../sidebar/ui/SelectTeamDropdown';
import { WorkspaceAlertBar } from '../../alert-bar';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceModel } from '../../../features/workspace';
import { useSetWorkspaceAlertBarFromSubscription } from '../../../features/subscription/hooks';
import {
  StripeElementsProvider,
  UpdatePaymentMethodModal,
} from '../../../features/subscription';
import { ThanksConfirmPaymentMethodModal } from '../../../features/subscription/ui/components/ThanksConfirmPaymentMethodModal';
import { TeamSettingsNavigation } from '../../sidebar/ui/TeamSettingsNavigation';
import { SettingsNavigation } from '../../sidebar/ui/SettingsNavigation';

export enum SettingsTypes {
  TEAM_SETTINGS = 'team',
  PROFILE_SETTINGS = 'profile',
}

type Props = {
  className?: string;
  children: React.ReactNode;
  settings?: SettingsTypes;
};

export const AppLayout: FC<Props> = ({ children, settings }) => {
  const dispatch = useDispatch();
  const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(false);
  const isShowContactSupportModal = useSelector(
    workspaceModel.selectors.selectIsShowContactSupportModal
  );

  const setOverflow = () => {
    const body = document.body;
    if (body.style.overflow === 'hidden') {
      body.style.overflow = '';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const history = useHistory();

  const onNavMenuClick = () => {
    setIsMobileSidebarVisible((prev) => !prev);
    setOverflow();
  };

  const onCloseMenu = useCallback(() => {
    setIsMobileSidebarVisible(false);
    document.body.style.overflow = '';
  }, []);

  const handleContactSupport = () => {
    dispatch(workspaceModel.actions.setIsShowContactSupportModal(true));
  };

  useEffect(() => {
    const unsubscribe = history.listen(onCloseMenu);
    return () => unsubscribe();
  }, [onCloseMenu, history]);

  useSetWorkspaceAlertBarFromSubscription();

  return (
    <div className="flex flex-col h-screen">
      <WorkspaceAlertBar />
      <div
        className={cn('flex flex-1 min-h-0 bg-base-sidebar md:flex-col', {
          'md:mt-16': isMobileSidebarVisible,
        })}
      >
        <aside>
          <HeaderLine
            onClick={onNavMenuClick}
            onContactSupport={handleContactSupport}
            isMenuOpened={isMobileSidebarVisible}
            settings={settings}
            isBackArrow={!!settings}
          />

          <div
            className={cn(
              'bg-gray-50 z-20 w-80 h-full overflow-auto border-r border-r-gray-200 flex flex-col justify-between',
              'md:fixed  md:h-full left-0 top-0',
              {
                'md:hidden': !isMobileSidebarVisible,
                'md:w-80': !settings,
                'md:w-full': !!settings,
              }
            )}
          >
            <div className="relative min-h-0 md:h-full flex flex-col grow-1">
              <HeaderLine
                onClick={onNavMenuClick}
                onContactSupport={handleContactSupport}
                isMenuOpened={isMobileSidebarVisible}
                settings={settings}
                isBackArrow={!!settings}
              />
              {!settings && (
                <div className="px-4 md:pt-2.5 py-5 md:mt-18">
                  <SelectTeamDropdown />
                </div>
              )}
              {!settings && <SidebarMenuContent />}

              {settings === SettingsTypes.TEAM_SETTINGS && (
                <TeamSettingsNavigation />
              )}
              {settings === SettingsTypes.PROFILE_SETTINGS && (
                <SettingsNavigation />
              )}
            </div>
          </div>
        </aside>

        <div className="relative flex flex-col flex-1 min-w-0 bg-base-white">
          <TopBar onContactSupport={handleContactSupport} />
          <div className="flex flex-1 px-8 pt-8 overflow-auto sm:px-4">
            {children}
          </div>
        </div>
        <StripeElementsProvider>
          <UpdatePaymentMethodModal />
        </StripeElementsProvider>
        <ThanksConfirmPaymentMethodModal />

        <InviteMembersModal />
        <ContactSupportFormModal
          isOpen={isShowContactSupportModal}
          onClose={() =>
            dispatch(workspaceModel.actions.setIsShowContactSupportModal(false))
          }
        />
      </div>
    </div>
  );
};
