import { call, fork, put } from 'redux-saga/effects';

import { matchParams } from '../../../shared/lib';

import { recordingsModel } from '../../../features/recordings';
import { recordActionsWorker } from './recordActionsWorker';

import { foldersModel } from '../../../features/folders';
import { pagesModel } from '../../../features/pages';
import { watchVideoRecordStatus } from '../../../features/recordings/model/sagas';

export function recordDetailsWorker(isPublic: boolean) {
  return function* (location: Location, route: string) {
    yield put(foldersModel.actions.setCurrentFolder(undefined));
    yield put(pagesModel.actions.setCurrentPage(undefined));
    yield put(pagesModel.actions.setCurrentContent(undefined));

    const { pathname } = location;
    const params = matchParams<{ prefix: string }>(pathname, route);

    yield fork(recordActionsWorker);
    yield call(recordingsModel.sagas.setRecordData, {
      prefix: params?.prefix || '',
      isPublic,
    });
    yield fork(watchVideoRecordStatus);
  };
}
