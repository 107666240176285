import { all, call } from 'redux-saga/effects';
import { authModel } from '../../entities/auth-operation';
import { connectivityModel } from '../../entities/connectivity-indicator';
import { mediaUploadModel } from '../../features/media-upload';
import { snackbarModel } from '../../features/snackbar';
import { onAuth } from '../../processes/auth-handling';
import { navigationModel } from '../../processes/navigation';
import { handleSignedIn } from '../../processes/private-worker';
import { handleSignedOut } from '../../processes/public-worker';
import { recordingsModel } from '../../features/recordings';

export function* saga() {
  yield all([
    call(onAuth, handleSignedIn, handleSignedOut),
    call(connectivityModel.saga),
    call(snackbarModel.saga),
    call(authModel.sagas.authAction),
    call(navigationModel.saga),
    call(mediaUploadModel.sagas.uploadingProgressChannelWorker),
    call(recordingsModel.sagas.autoCopyRecordLink),
  ]);
}
