import React from 'react';
import { Section } from '../../../shared/ui/Section';
import { CreatePersonalSnippetsPermissionForm } from './components/CreatePersonalSnippetsPermissionForm';
import { DetachSnippetPermissionForm } from './components/DetachSnippetPermissionForm';
import { ManageSnippetsCategoriesPermissionForm } from './components/ManageSnippetsCategoriesPermissionForm';
import { ManageSnippetsPermissionForm } from './components/ManageSnippetsPermissionForm';

export const TeamPermissionsControl = () => {
  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Permission control
      </h1>
      <Section title="Snippets">
        <div className="flex flex-col gap-y-4 w-176">
          <ManageSnippetsCategoriesPermissionForm />
          <ManageSnippetsPermissionForm />
          <CreatePersonalSnippetsPermissionForm />
          <DetachSnippetPermissionForm />
        </div>
      </Section>
    </div>
  );
};
