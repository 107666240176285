import { Snippet } from '@distribute/shared/types';
import { SortState } from './config';

export const getSortedSnippets = (
  snippets: Snippet[],
  sortCondition?: SortState
) => {
  switch (sortCondition) {
    case SortState.LAST_CREATED:
      return [...snippets].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    case SortState.NAME:
      return [...snippets].sort(
        ({ name: aTitle = 'Untitled' }, { name: bTitle = 'Untitled' }) => {
          if (aTitle < bTitle) {
            return -1;
          }
          if (aTitle > bTitle) {
            return 1;
          }
          return 0;
        }
      );
    case SortState.MOST_POPULAR:
      return [...snippets].sort((a, b) => {
        return b.usedCount - a.usedCount;
      });
    default:
      return snippets;
  }
};
