import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { Avatar, CompanyRecord, Input } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { teamBrandSettingsModel } from '../../model';
import { ClearbitCompanyInfo } from '../../../../shared/api';

type IProps = {
  value: string | null;
  onChange: (value: string) => void;
  handleCompanySelect: (company: ClearbitCompanyInfo) => void;
  withLogo?: boolean;
  logo?: string;
  label?: string;
};

export const CompanyNameSearchInput: FC<IProps> = ({
  value,
  onChange,
  handleCompanySelect,
  withLogo,
  logo = '',
  label,
}) => {
  const dispatch = useDispatch();

  const [isShowSearchResults, setIsShowSearchResults] = useState(false);

  const companies = useSelector(
    teamBrandSettingsModel.selectors.selectCompanies
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchCompanies = useCallback(
    debounce(async (query: string) => {
      dispatch(
        teamBrandSettingsModel.actions.searchCompanies({ searchQuery: query })
      );
    }, 400),
    []
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    handleSearchCompanies(e.target.value);
    setIsShowSearchResults(true);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        setIsShowSearchResults(false);
      }
    };
    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, []);

  const handleSelect = (info: ClearbitCompanyInfo) => {
    handleCompanySelect(info);
    setIsShowSearchResults(false);
    dispatch(teamBrandSettingsModel.actions.setCompanies([]));
  };

  const isAvatarVisible = withLogo && !!value;

  return (
    <div className="flex grow-1 max-w-150 relative" ref={containerRef}>
      <div className="grow-1 relative">
        {isAvatarVisible && (
          <Avatar
            className="absolute z-10 bottom-2 left-2.5"
            src={logo}
            size="xs"
            displayName={value ?? ''}
            theme="rect"
          />
        )}
        <Input
          className={classNames({ 'pl-10': isAvatarVisible })}
          ref={inputRef}
          label={label}
          type="text"
          value={value ?? ''}
          onChange={handleChange}
          heightSize="md"
          onFocus={() => setIsShowSearchResults(true)}
        />
      </div>
      {isShowSearchResults && !!companies.length && (
        <div className="flex flex-col border rounded-lg bg-base-white divide-solid border-gray-200 p-1.5 z-10 absolute -bottom-0.5 left-0 w-full translate-y-full max-h-128.5 overflow-auto md:relative md:border-0 md:rounded-none md:bottom-0 md:py-4 md:translate-y-0 md:flex-grow-1 md:max-h-none">
          {companies.map((company) => (
            <CompanyRecord
              key={company.domain}
              companyInfo={company}
              handleClick={() => handleSelect(company)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
