import { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { Button, ButtonCross, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

import { useDispatch, useSelector } from 'react-redux';
import { extensionModel } from '../model';

import { messages } from '../config';
import { ExtensionOptions } from './ExtensionOptions';

export const InstallExtensionModal = () => {
  const dispatch = useDispatch();

  const { isInstallModalOpen } = useSelector(
    extensionModel.selectors.selectExtension
  );

  const focusRef = useRef(null);

  const handleClose = () =>
    dispatch(extensionModel.actions.setIsInstallModalOpen(false));

  const handleInstall = () => {
    dispatch(extensionModel.actions.extensionInstallClick());
    handleClose();
  };

  return (
    <Dialog
      initialFocus={focusRef}
      className="relative z-50 w-full"
      open={isInstallModalOpen}
      onClose={handleClose}
    >
      <div className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200">
        <Dialog.Panel className="w-full max-w-100 px-6 pt-6 pb-8 shadow-xl rounded-xl bg-base-white relative flex flex-col overflow-hidden">
          <ButtonCross
            onClick={handleClose}
            classNames="absolute right-4 top-4"
          />
          <Icon
            glyph={IconMap.GoogleChrome}
            className="text-primary-700 self-center"
            width={64}
          />
          <Dialog.Title className="text-gray-900 text-display-sm font-medium font-display mt-4 mb-3 text-center">
            {messages.title}
          </Dialog.Title>
          <p className="text-gray-600 text-md mb-4 text-center">
            {messages.description}
          </p>
          <ExtensionOptions />
          <Button
            ref={focusRef}
            variant="text"
            color="primary"
            fullWidth
            onClick={handleInstall}
          >
            Install Distribute Recorder
          </Button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
