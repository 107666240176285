import React, { useState } from 'react';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { ProfileDropdown } from '../../../topbar/ProfileDropdown';
import { WorkspaceSearch } from '../../../topbar/WorkspaceSearch';
import classNames from 'classnames';
import { useTeamPermissions } from '../../../../features/teams';
import { useHandlerInviteTeamMembers } from '../../../../features/team-members';
import { useDispatch } from 'react-redux';
import { SettingsTypes } from '..';
import { matchPath, useLocation } from 'react-router-dom';
import { labelsByRoutes } from '../../lib';
import { redirectActions } from '../../../../entities/history';

type HeaderLineProps = {
  onClick: () => void;
  onContactSupport: () => void;
  isMenuOpened: boolean;
  settings?: SettingsTypes;
  isBackArrow?: boolean;
};

export const HeaderLine: React.FC<HeaderLineProps> = ({
  onClick,
  onContactSupport,
  isMenuOpened,
  isBackArrow = false,
  settings,
}) => {
  const [isShowWorkspaceSearch, setIsShowWorkspaceSearch] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  const menuLabel =
    labelsByRoutes.find((item) =>
      matchPath(location.pathname, { path: item.route })
    )?.label || labelsByRoutes[0].label;

  const { isCanManageAccess } = useTeamPermissions();
  const handlerInviteTeamMembers = useHandlerInviteTeamMembers();

  return (
    <header
      className={classNames(
        'hidden bg-base-white border-b border-r-gray-200 md:visible sm:visible w-full h-16 md:block',
        {
          fixed: isMenuOpened,
          'h-28': settings && !isMenuOpened,
        }
      )}
    >
      <div className="items-center justify-between px-6 py-3 md:px-8 sm:px-4 md:flex sm:flex">
        {isBackArrow && !isMenuOpened ? (
          <button
            onClick={() => {
              dispatch(redirectActions.toWorkspaceByBackUrl());
            }}
            type="button"
            className="flex text-gray-700 rounded-lg"
          >
            <Icon glyph={IconMap.BackArrow} className="mx-1.5" width={18} />
          </button>
        ) : (
          <button
            onClick={onClick}
            type="button"
            className="flex text-gray-700 rounded-lg"
          >
            {isMenuOpened ? (
              <Icon glyph={IconMap.CrossClose} className="text-gray-700" />
            ) : (
              <Icon glyph={IconMap.NavMenuButton} className="ml-1.5" />
            )}
          </button>
        )}
        {isShowWorkspaceSearch && (
          <WorkspaceSearch onClose={() => setIsShowWorkspaceSearch(false)} />
        )}
        <div className="flex flex-row items-center gap-x-3">
          <Icon
            glyph={IconMap.SearchSm}
            width={24}
            className="text-gray-700 cursor-pointer"
            onClick={() => setIsShowWorkspaceSearch(true)}
          />

          {isCanManageAccess && (
            <Icon
              glyph={IconMap.UserPlus}
              width={24}
              className="text-gray-700 cursor-pointer ml-4 mr-2"
              onClick={handlerInviteTeamMembers}
            />
          )}

          <ProfileDropdown onContactSupport={onContactSupport} />
        </div>
      </div>

      {settings && !isMenuOpened && (
        <div className="hidden px-6 py-3 border-t md:px-8 sm:px-4 md:block border-t-gray-200">
          <button
            onClick={onClick}
            className="flex items-center font-semibold text-gray-900 text-md"
          >
            {menuLabel}
            <Icon glyph={IconMap.ArrowDown} className="ml-2" width={20} />
          </button>
        </div>
      )}
    </header>
  );
};
