import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const Tag = ({
  color,
  children,
  className,
  bordered,
  variant = 'text',
  size = 'lg',
}: {
  color: string;
  children: ReactNode;
  className?: string;
  bordered?: boolean;
  variant?: 'text' | 'icon-text';
  size?: 'sm' | 'md' | 'lg';
}) => {
  const style = `rounded-2xl font-normal whitespace-nowrap`;

  return (
    <span
      className={`${classNames(style, className, {
        'bg-blue-50 text-blue-700': color === 'blue',
        'bg-primary-50 text-primary-700': color === 'green',
        'bg-gray-100 text-gray-700': color === 'gray',
        'bg-success-50 text-success-700': color === 'success',
        border: bordered,
        'border-blue-200': bordered && color === 'blue',
        'border-primary-400': bordered && color === 'green',
        'border-gray-200': bordered && color === 'gray',
        'border-success-200': bordered && color === 'success',
        'pl-3 py-1': size === 'lg',
        'pr-3': variant === 'text' && size === 'lg',
        'pr-2.5':
          (variant === 'icon-text' && size === 'lg') ||
          (variant === 'text' && size === 'md'),
        'pl-2.5 py-0.5': size === 'md',
        'pr-2':
          (variant === 'icon-text' && size === 'md') ||
          (variant === 'text' && size === 'sm'),
        'pl-2 py-0.25': size === 'sm',
        'pr-1.5': variant === 'icon-text' && size === 'sm',
        'flex items-center gap-0.5': variant === 'icon-text',
        'text-sm': size === 'lg' || size === 'md',
        'text-xs': size === 'sm',
      })}`}
    >
      {children}
    </span>
  );
};
