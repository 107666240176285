import { call, delay, fork, put } from 'redux-saga/effects';
import { SubscriptionStatus, TeamFiltered } from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { subscriptionApi, teamsApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { subscriptionModel } from '..';
import { teamsModel } from '../../../teams';
import { POLLING_DELAY_INTERVAL } from '../../config';
import { loadInvoices } from './loadInvoices';

export function* retryLatestFailedInvoice({
  payload: { teamId },
}: ReturnType<typeof subscriptionModel.actions.retryLatestFailedInvoice>) {
  try {
    yield put(subscriptionModel.actions.setIsRetryingLatestFailedInvoice(true));
    yield call(subscriptionApi.retryLatestFailedInvoice, { teamId });

    const pollingRetryCount = 3;
    let retryCount = 0;

    while (retryCount < pollingRetryCount) {
      yield delay(POLLING_DELAY_INTERVAL);

      const team: TeamFiltered = yield call(teamsApi.getTeam, teamId);

      if (team.stripeSubscription?.status !== SubscriptionStatus.PAST_DUE) {
        yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
        retryCount = pollingRetryCount;
      }

      retryCount = ++retryCount;
    }

    yield fork(loadInvoices);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to pay the invoice.')
      )
    );
  } finally {
    yield put(
      subscriptionModel.actions.setIsRetryingLatestFailedInvoice(false)
    );
  }
}
