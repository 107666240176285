import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Input } from '../../../../shared/ui';
import { profileSettingModel } from '../../model';
import { createPasswordSchema, CreatePasswordSchema } from '../../lib';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreatePasswordModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<CreatePasswordSchema>({
    resolver: yupResolver(createPasswordSchema),
  });
  const dispatch = useDispatch();
  const { password, confirmPassword } = watch();
  const isFormChanged =
    isDirty && (password?.length > 0 || confirmPassword?.length > 0);

  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsUserPasswordCreating
  );
  const onModalClose = () => {
    onClose();
    reset();
  };

  const handleFormSubmit = (data: CreatePasswordSchema) => {
    dispatch(
      profileSettingModel.actions.createUserPassword({
        ...data,
        cb: () => {
          onModalClose();
        },
      })
    );
  };

  return (
    <Modal
      title="Create Password"
      onClose={onModalClose}
      isOpen={isOpen}
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          loading={isLoading}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          disabled={!isFormChanged}
        >
          Create
        </Button>
      }
    >
      <div className="w-full">
        <p className="text-sm text-gray-600 mb-2">
          Once you set up a password, you will be able to sign into Distribute
          using your email and password.
        </p>
        <p className="text-sm text-gray-600 mb-5">
          Password must be at least 8 characters
        </p>
        {(errors.password || errors.confirmPassword) &&
          password !== confirmPassword && (
            <p className="p-4 border border-error-600 rounded-xl text-error-700 text-sm font-semibold bg-error-25 mb-5">
              The passwords do not match each other.
            </p>
          )}
        {errors.password &&
          (password.length < 8 || confirmPassword.length < 8) && (
            <p className="p-4 border border-error-600 rounded-xl text-error-700 text-sm font-semibold bg-error-25 mb-5">
              Password must be at least 8 characters
            </p>
          )}
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-3"
        >
          <Input
            {...register('password')}
            isError={!!errors.password}
            autoComplete="username"
            messageText={errors.password?.message}
            label="Password"
            type="password"
          />
          <Input
            {...register('confirmPassword')}
            isError={!!errors.confirmPassword}
            messageText={errors.confirmPassword?.message}
            label="Confirm Password"
            type="password"
          />

          <input type="submit" hidden />
        </form>
      </div>
    </Modal>
  );
};
