import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, SignUpLayout } from '../../../shared/ui';
import CreateAccountForm from './components/CreateAccountForm';
import { IconMap } from '../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { authModel } from '../../../entities/auth-operation';

export const SignUp = () => {
  const dispatch = useDispatch();

  const handleGoogleSignup = () => {
    dispatch(authModel.actions.googleSignupClick());
  };
  return (
    <>
      <Helmet title="Sign Up" />
      <SignUpLayout>
        <div className="mb-3 flex justify-center items-center">
          <p className="text-display-md font-medium font-display -tracking-2 text-gray-900 text-center">
            Let's get the fiesta started!
          </p>
        </div>

        <div className="mb-6 flex justify-center items-center">
          <p className="font-normal text-md text-center text-gray-600">
            We recommend using your work email.
          </p>
        </div>

        <div className="flex justify-center items-center">
          <Button
            variant="icon-text"
            color="secondary"
            className="w-full"
            iconLeftName={IconMap.Google}
            onClick={handleGoogleSignup}
          >
            Sign up with Google
          </Button>
        </div>

        <div className="flex items-center justify-between gap-4 my-5">
          <hr className="grow border-gray-200" />
          <p className="text-gray-700 font-medium text-sm">or</p>
          <hr className="grow border-gray-200" />
        </div>

        <div className="col-start-8 col-end-12 md:col-start-3 md:col-end-7">
          <CreateAccountForm />
        </div>
      </SignUpLayout>
    </>
  );
};
