import React from 'react';
import { Icon, Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { CreateSnippetButton } from '../../../features/snippets/ui/components';
import { useDispatch } from 'react-redux';
import { useSnippetsPermissions } from '../../../features/teams';
import { snippetsModel } from '../../../features/snippets';

type Props = {
  message?: string;
  isCreateCategoryButton?: boolean;
  isDraft?: boolean;
  isSnippetsModal?: boolean;
};

export const NoSnippetsFound: React.FC<Props> = ({
  message,
  isCreateCategoryButton,
  isDraft,
  isSnippetsModal = false,
}) => {
  const dispatch = useDispatch();

  const {
    isCanManageSnippets,
    isCanManageCategories,
    isCanCreatePersonalSnippets,
  } = useSnippetsPermissions();

  const handleClickCreateCategories = () => {
    dispatch(snippetsModel.actions.setIsCreateCategoryModalOpen(true));
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full gap-2 overflow-hidden">
      <div
        className="flex flex-col items-center justify-center w-120 h-120"
        style={{ backgroundImage: 'url(../../../assets/images/bg-dots.svg)' }}
      >
        <div className="border border-gray-200 rounded-2.5 p-3 shadow-xs w-12 h-12 bg-base-white relative">
          <Icon glyph={IconMap.Snippets} width={24} className="text-gray-700" />
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          <p className="mb-1 font-semibold text-gray-900 text-md">
            No {isDraft ? 'drafts' : 'snippets'} found
          </p>
          {message && (
            <p className="text-gray-600 text-sm font-normal text-center mb-6 max-w-74">
              {message}
            </p>
          )}
          {isCreateCategoryButton
            ? isCanManageCategories && (
                <Button
                  color="primary"
                  size="md"
                  variant="text"
                  onClick={handleClickCreateCategories}
                >
                  Create Categories
                </Button>
              )
            : (isCanManageSnippets || isCanCreatePersonalSnippets) && (
                <CreateSnippetButton
                  size="lg"
                  isSnippetsModal={isSnippetsModal}
                />
              )}
        </div>
      </div>
    </div>
  );
};
