import { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconMap } from '../../../../shared/sprite';
import { Icon, Loader, SimpleLayout } from '../../../../shared/ui';

import { ChromeExtensionInstall } from './ChromeExtensionInstall';
import { ChromeExtensionGet } from './ChromeExtensionGet';
import { onboardingModel } from '../../../onboarding';

export enum Step {
  Install = 'install',
  Get = 'get',
}

export const ChromeExtensionOnboard: FC = () => {
  const isLoadingSkipExtension = useSelector(
    onboardingModel.selectors.selectIsLoadingSkipExtension
  );

  const [step, setStep] = useState<Step>(Step.Get);

  const dispatch = useDispatch();

  const onFinish = () => {
    dispatch(onboardingModel.actions.finishExtensionOnboarding());
  };

  if (isLoadingSkipExtension) {
    return <Loader />;
  }

  return (
    <SimpleLayout hideLogo hideImage classNameContent="!pt-24 w-140">
      <div className="flex flex-col items-center">
        <Icon
          glyph={IconMap.GoogleChrome}
          className="text-primary-700 self-center"
          width={64}
        />
        {step === Step.Get && (
          <ChromeExtensionGet
            onFinish={onFinish}
            setStep={setStep}
            loading={isLoadingSkipExtension}
          />
        )}
        {step === Step.Install && (
          <ChromeExtensionInstall
            onFinish={onFinish}
            loading={isLoadingSkipExtension}
          />
        )}
      </div>
    </SimpleLayout>
  );
};
