import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { onboardingModel } from '..';
import { finishTeamOnboarding } from './finishTeamOnboarding';
import { subscriptionApi } from '../../../../shared/api';

export function* createSubscriptionTrial({
  payload: { teamId, interval, plan },
}: ReturnType<typeof onboardingModel.actions.createSubscriptionTrial>) {
  try {
    yield put(actions.setIsLoadingCreateSubscriptionTrial(true));
    yield call(subscriptionApi.createSubscription, {
      teamId,
      interval,
      plan,
    });
    yield call(finishTeamOnboarding);
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to start trial.')
      )
    );
  } finally {
    yield put(actions.setIsLoadingCreateSubscriptionTrial(false));
  }
}
