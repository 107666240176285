import { call, cancelled, put, take } from 'redux-saga/effects';
import { gongModel } from '..';
import { v4 } from 'uuid';
import {
  APP_HOST,
  APP_ORIGIN,
  GONG_CLIENT_ID,
  PROTOCOL,
} from '../../../../shared/config';
import { END, eventChannel } from 'redux-saga';
import { fetchGongAuthTokenBasedOnCode } from './fetchGongAccessTokenBasedOnCode';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { openOauthSignupPopup } from '../../../../utils/openOauthSignupPopup';
import { generateGongApiKey } from './generateGongApiKey';

const GONG_AUTH_URL = 'https://us-45696.app.gong.io/oauth2/authorize';
const scope = 'api:calls:read:transcript api:calls:read:extensive';
const gongCallbackUrl = `${PROTOCOL}${APP_HOST}/gong-callback`;

export function* connectToGong({
  payload,
}: ReturnType<typeof gongModel.actions.connectToGong>): any {
  try {
    yield put(gongModel.actions.setIsConnecting(true));

    const state = v4();
    const signUpPopup = openOauthSignupPopup(GONG_AUTH_URL, {
      client_id: GONG_CLIENT_ID,
      response_type: 'code',
      redirect_uri: gongCallbackUrl,
      scope,
      state,
    });

    const callbackUrlChannel = yield call(
      checkConnectionModalForAuthCode,
      signUpPopup
    );

    let callbackUrl = '';

    try {
      while (!callbackUrl.length) {
        callbackUrl = yield take(callbackUrlChannel);
      }
    } catch {
      yield put(gongModel.actions.setIsConnecting(false));
    } finally {
      if (yield cancelled()) {
        callbackUrlChannel.close();
      }
    }

    yield call(fetchGongAuthTokenBasedOnCode, callbackUrl, state);

    yield call(generateGongApiKey);

    payload?.cb();

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully connected to Gong')
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to Gong')
      )
    );
  } finally {
    yield put(gongModel.actions.setIsConnecting(false));
  }
}

function checkConnectionModalForAuthCode(signUpPopup: Window | null) {
  return eventChannel((emitter) => {
    const checkSignUpPopupInterval = setInterval(() => {
      if (!signUpPopup || signUpPopup?.closed) {
        emitter(END);
      }
      try {
        if (signUpPopup?.window.location?.href.includes(APP_ORIGIN)) {
          emitter(signUpPopup.window.location.search);
          signUpPopup.close();
          emitter(END);
        }
        // eslint-disable-next-line no-empty
      } catch {}

      if (!signUpPopup || !signUpPopup.closed) {
        return;
      }
    }, 1000);

    return () => {
      clearInterval(checkSignUpPopupInterval);
    };
  });
}
