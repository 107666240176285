import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { PlanName } from '@distribute/shared/types';
import { teamsModel } from '../../../../teams';
import { Avatar, Icon } from '../../../../../shared/ui';
import { MAX_MEMBERS_TEAM_PLAN } from '../../../config';
import { IconMap } from '../../../../../shared/sprite';
import { useHandlerInviteTeamMembers } from '../../../../team-members';
import { useSubscriptionTeamMembers } from '../../../hooks/useSubscriptionTeamMembers';

export const CurrentPlanInfoMembers: FC = () => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentTeamMembers = useSubscriptionTeamMembers();

  const handlerInviteTeamMembers = useHandlerInviteTeamMembers();

  return (
    <div className="flex flex-wrap items-center">
      <span className="text-md font-medium text-primary-700 mr-1">
        {currentTeamMembers.length}
        {stripeSubscription?.name === PlanName.TEAM && (
          <span> of {MAX_MEMBERS_TEAM_PLAN}</span>
        )}
      </span>
      <span className="text-md text-gray-600 mr-4">
        {stripeSubscription?.name === PlanName.TEAM ||
        currentTeamMembers.length > 1
          ? 'members'
          : 'member'}{' '}
        added
      </span>
      <div className="flex items-center h-6">
        <div className="flex mr-2">
          {currentTeamMembers.map((member) => (
            <div
              key={member.id}
              className="border-2 border-base-white rounded-full -ml-1.5"
            >
              <Avatar
                displayName={
                  member.user?.displayName ?? member.externalUserEmail ?? ''
                }
                src={member.user?.photoUrl}
                size="xs"
                className="!border-0"
              />
            </div>
          ))}
        </div>
        {(stripeSubscription?.name !== PlanName.TEAM ||
          currentTeamMembers.length < MAX_MEMBERS_TEAM_PLAN) && (
          <button
            onClick={handlerInviteTeamMembers}
            className="flex items-center justify-center w-6 h-6 outline-none border border-dashed border-gray-300 bg-base-white rounded-full"
          >
            <Icon glyph={IconMap.Plus} width={16} className="text-gray-400" />
          </button>
        )}
      </div>
    </div>
  );
};
