import React from 'react';
import { Modal, Button, Input } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { Page } from '@distribute/shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  page: Page;
};

type DeletePageModalForm = {
  confirmText: string;
  id: string;
};
const CONFIRMATION_TEXT = 'DELETE PAGE';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to delete.`
    ),
});

export const DeletePageModal: React.FC<Props> = ({ isOpen, onClose, page }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(pagesModel.selectors.selectDeletePageIsLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeletePageModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = () => {
    dispatch(
      pagesModel.actions.deletePage({
        id: page.id,
        callback: () => {
          onModalClose();
        },
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete Page"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Forever
        </Button>
      }
    >
      <div className="text-sm font-normal text-gray-600 mb-5">
        The “{page.content.title || 'Untitled'}” page and its content will be
        deleted permanently. If it has a form, existing submissions will be
        deleted and all future submissions will not be captured.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
