import React, { FC } from 'react';
import cn from 'classnames';
import { PlanName, SubscriptionPlanName } from '@distribute/shared/types';
import { SubscriptionPlan } from '../../types';
import { Icon, RadioButton } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { formatAmountToCurrency } from '../../../../shared/lib';

type IProps = SubscriptionPlan & {
  isSelected: boolean;
  onSelect: (id: SubscriptionPlanName) => void;
};

export const ItemPlanOnboarding: FC<IProps> = ({
  features,
  id,
  name,
  price,
  hint,
  isSelected,
  onSelect,
}) => {
  return (
    <button
      className={cn(
        'flex flex-col justify-between gap-6 basis-67.5 p-6 bg-base-white border-2 border-primary-600 border-opacity-75 rounded-2xl text-left sm:basis-full',
        { 'bg-primary-50': isSelected }
      )}
      onClick={() => onSelect(id)}
    >
      <div className="flex flex-col">
        <div className="flex items-center gap-3 text-display-xs font-display font-medium text-gray-900">
          {name}
          <span className="rounded-2xl bg-primary-50 mix-blend-multiply px-2 py-0.5 text-xs text-primary-700 font-semibold uppercase">
            7-day free
          </span>
        </div>
        <div className="mt-2 text-sm text-gray-600">{hint}</div>
        <div className="mt-2 font-display font-medium text-m text-gray-900">
          {formatAmountToCurrency(price.month)}
        </div>
        <div className="text-sm text-gray-600">
          {id === PlanName.TEAM ? 'per 5 members/month' : 'per member/month'}
        </div>
        <div className="flex flex-col gap-4 mt-7">
          {features.map((feature) => (
            <span
              key={feature.id}
              className="flex items-center gap-2 text-sm text-gray-600"
            >
              <Icon width={20} glyph={IconMap.CheckCircleGreen} />
              {feature.text}
            </span>
          ))}
        </div>
      </div>

      <div className="self-center">
        <RadioButton
          uiType="check"
          uiSize="md"
          onChange={() => onSelect(id)}
          defaultChecked={isSelected}
          className="self-center"
        />
      </div>
    </button>
  );
};
