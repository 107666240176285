import cn from 'classnames';
import { Command } from '../types';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useEffect, useRef } from 'react';

export type MenuButtonProps = {
  command: Command;
  active?: boolean;
  onClick?: () => void;
  className: string;
  onMouseEnter?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    command: Command
  ) => void;
  onMouseLeave?: () => void;
  isArrowPriority?: boolean;
};

export const MenuButton = ({
  active,
  command,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  isArrowPriority,
}: MenuButtonProps) => {
  const buttonClasses = cn(
    `${className} flex group justify-between items-center w-full gap-3 p-2 text-left rounded-lg min-h-9 truncate`,
    active && 'bg-base-black-4',
    command.disabled && 'opacity-50',
    !command.disabled && !active && !isArrowPriority && 'hover:bg-base-black-4'
  );
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [active]);
  const handleMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onMouseEnter) {
      onMouseEnter(event, command);
    }
  };

  return (
    <button
      ref={ref}
      className={buttonClasses}
      disabled={command.disabled}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={cn('flex items-center gap-2 text-left group max-w-full', {
          'text-gray-600 group-hover:text-gray-700':
            !active && !isArrowPriority,
          'text-gray-700': active,
          'text-gray-600 group-hover:text-gray-600': !active && isArrowPriority,
        })}
      >
        {command.icon}
        <div className="flex flex-col truncate">
          <p
            className={cn('text-sm font-medium truncate', {
              'text-gray-700 group-hover:text-gray-800':
                !active && !isArrowPriority,
              'text-gray-800': active,
              'group-hover:text-gray-700 text-gray-700':
                !active && isArrowPriority,
            })}
          >
            {command.name}
          </p>
        </div>
      </div>
      <Icon
        className={cn('text-gray-500', {
          block: active,
          hidden: !active,
        })}
        glyph={IconMap.ShortcutEnter}
        width={18}
      />
    </button>
  );
};
