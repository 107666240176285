import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { teamsModel } from '../../../teams';
import { CARD_ICON_BY_BRAND } from '../../config';
import { Badge, Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useSubscriptionInfo } from '../../hooks';
import { subscriptionModel } from '../../model';

type IProps = {
  onUpdatePaymentMethod: () => void;
  onConfirmPaymentMethod: () => void;
};

export const CurrentPaymentMethod: FC<IProps> = ({
  onUpdatePaymentMethod,
  onConfirmPaymentMethod,
}) => {
  const {
    id: teamId,
    subscriptionPaymentMethod,
    stripeSubscription,
    currentMember: { isWaitingForUpdateSubscriptionPaymentMethod },
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const { isPaymentFailed, isCardExpireSoon } = useSubscriptionInfo();
  const isConfirmingPaymentMethod = useSelector(
    subscriptionModel.selectors.selectIsConfirmingPaymentMethod
  );
  const dispatch = useDispatch();

  const handleConfirmPaymentMethod = () => {
    dispatch(
      subscriptionModel.actions.confirmPaymentMethod({
        teamId,
        cb: onConfirmPaymentMethod,
      })
    );
  };

  if (!stripeSubscription) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-col justify-between basis-90 min-h-48.5 border border-gray-200 rounded-2xl shadow-sm p-8 bg-base-white sm:flex-grow-1',
        { '!border-warning-600': isWaitingForUpdateSubscriptionPaymentMethod }
      )}
    >
      {subscriptionPaymentMethod ? (
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-end">
            <div className="flex items-end gap-2">
              <Icon
                glyph={
                  CARD_ICON_BY_BRAND[subscriptionPaymentMethod.cardBrand] ??
                  CARD_ICON_BY_BRAND.unknown
                }
              />
              <span className="text-lg font-medium text-gray-700">
                •••• {subscriptionPaymentMethod.cardLastNumbers}
              </span>
            </div>
            {isPaymentFailed && (
              <Badge size="md" theme="error" className="mb-0.5">
                Payment failed
              </Badge>
            )}
            {isCardExpireSoon && !isPaymentFailed && (
              <Badge size="md" theme="warning" className="mb-0.5">
                Expire soon
              </Badge>
            )}
          </div>
          <div className="text-md text-gray-600">
            Expires {moment.months(subscriptionPaymentMethod.cardExpMonth - 1)}{' '}
            {subscriptionPaymentMethod.cardExpYear}
          </div>
        </div>
      ) : (
        <div className="text-md text-gray-600">No credit card available</div>
      )}
      <div className="flex justify-between">
        <Button
          color="link-gray"
          variant="icon-text"
          iconLeftName={
            subscriptionPaymentMethod
              ? IconMap.CreditCardRefresh
              : IconMap.CreditCard02
          }
          iconLeftWidth={20}
          onClick={onUpdatePaymentMethod}
          className="!font-semibold"
        >
          {subscriptionPaymentMethod ? 'Update' : 'Add Credit Card'}
        </Button>
        {isWaitingForUpdateSubscriptionPaymentMethod && (
          <Button
            color="link"
            variant="icon-text"
            iconLeftName={IconMap.CheckVerified01}
            iconLeftWidth={20}
            onClick={handleConfirmPaymentMethod}
            loading={isConfirmingPaymentMethod}
            className="!font-semibold"
          >
            Confirm Card
          </Button>
        )}
      </div>
    </div>
  );
};
