import { put, select, takeEvery } from 'redux-saga/effects';
import { AnalyticsEvents, User } from '@distribute/shared/types';

import { authModel } from '../../../entities/auth-operation';
import { analytics } from '../../../entities/analytics';
import { appsumoSignUp } from '../../../features/auth/appsumo-sign-up';
import { redirect, redirectActions } from '../../../entities/history';
import { auth } from '../../../shared/config';
import { globalActions } from '../../../app/model/actions';
import { authUserModel } from '../../../entities/auth-user';

export function* signUpAppSumoWorker() {
  const currentUser: User | undefined = yield select(
    authUserModel.selectors.selectUser
  );
  if (currentUser) {
    yield auth.signOut();
    yield put(globalActions.resetStateAll());
  }
  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(authModel.actions.appsumoSignUpClick, appsumoSignUp);
  yield takeEvery(redirectActions.toSignup, redirect.toSignup);

  analytics.track(AnalyticsEvents.APPSUMO_SIGN_UP_STARTED);
}
