import { call, fork } from 'redux-saga/effects';
import type { Saga } from '../../../shared/types';
import { recordActionsWorker } from './recordActionsWorker';
import { watchVideoRecordsStatus } from '../../../features/recordings/model/sagas';

export function recordsWith(saga: Saga) {
  return function* (location: Location, route: string) {
    yield fork(recordActionsWorker);
    yield fork(watchVideoRecordsStatus);

    yield call(saga, location, route);
  };
}
