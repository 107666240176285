import { FC, useRef } from 'react';
import ReactPlayer from 'react-player';

import { usePlayer } from '../../lib';
import { ActionKind } from '../../provider';

import { PlayerControls } from './PlayerControls';
import { PlayerOverlay } from './PlayerOverlay';
import { PlayerHotKeys } from './PlayerHotKeys';

import { HlsError, HlsErrorTypes } from '../../types';
import { useRetry } from '../../lib';

export const Player: FC = () => {
  const playerRef = useRef<ReactPlayer>(null);
  const { state, config, dispatch } = usePlayer();

  const { handleHlsError } = useRetry(playerRef);

  const { url, aspectRatio, hotKeys } = config;
  const { playing, playbackRate, volume, fullscreen } = state;

  const handlePlay = () => dispatch({ type: ActionKind.PLAY });
  const handlePause = () => dispatch({ type: ActionKind.PAUSE });

  const handleProgress = (progress: {
    playedSeconds: number;
    loaded: number;
  }) => {
    dispatch({ type: ActionKind.SEEK, payload: progress.playedSeconds });
    dispatch({ type: ActionKind.SEEK_LOADED, payload: progress.loaded });
  };

  const handleDuration = (duration: number) => {
    dispatch({ type: ActionKind.DURATION, payload: duration });
  };

  const handleEnded = () => {
    const payload = playerRef.current?.getDuration() || 0;

    dispatch({ type: ActionKind.DURATION, payload });
    dispatch({ type: ActionKind.PAUSE });
    dispatch({ type: ActionKind.FINISH, payload: true });
  };

  const handleError = (error: any, data: any) => {
    if (error !== HlsError) return;

    [HlsErrorTypes.MEDIA_ERROR, HlsErrorTypes.NETWORK_ERROR].includes(
      data?.type
    ) && handleHlsError(data?.type);
  };

  return (
    <>
      <ReactPlayer
        className="relative bg-base-black"
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        playbackRate={playbackRate}
        playing={playing}
        volume={volume}
        onError={handleError}
        onPause={handlePause}
        onPlay={handlePlay}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onEnded={handleEnded}
        config={{
          file: {
            attributes: {
              style: { aspectRatio },
              className: 'w-full bg-base-black',
              controlsList: 'nodownload',
            },
          },
        }}
      />
      <PlayerOverlay playerRef={playerRef} />
      <PlayerControls playerRef={playerRef} />
      {(hotKeys || fullscreen) && <PlayerHotKeys />}
    </>
  );
};
