import { getUploadProgress } from '../../lib';
import { api } from './instance';
import {
  GetSqueezePageResponseType,
  RemoveSqueezePageImageResponseType,
  UpdateSqueezePageImageResponseType,
  UpdateSqueezePageRequestType,
  UpdateSqueezePageResponseType,
} from '@distribute/shared/api-types/squeeze-page';

export const squeezePageApi = {
  async getSqueezePage(documentContentId: number) {
    const { data } = await api.get<GetSqueezePageResponseType>(
      `squeeze-page/${documentContentId}`
    );

    return data;
  },

  async updateSqueezePage(
    documentContentId: number,
    data: UpdateSqueezePageRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateSqueezePageResponseType>(
        `squeeze-page/${documentContentId}`,
        data
      );
    return responseData;
  },

  async updateSqueezePageImage(
    documentContentId: number,
    image: File,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } =
      await api.put<UpdateSqueezePageImageResponseType>(
        `squeeze-page/${documentContentId}/image-url`,
        { image },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (value) => {
            onUploadProgress(getUploadProgress(value));
          },
        }
      );

    return responseData;
  },

  async removeSqueezePageImage(documentContentId: number) {
    const { data } = await api.delete<RemoveSqueezePageImageResponseType>(
      `squeeze-page/${documentContentId}/image-url`
    );
    return data;
  },
};
