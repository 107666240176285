import {
  authUserModel,
  getUserFromFb,
  setUserData,
} from '../../../../entities/auth-user';
import {
  GoogleAuthProvider,
  UserCredential,
  linkWithPopup,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { call, put, select } from 'redux-saga/effects';
import { GOOGLE_PROVIDER_ID } from '../../lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { FirebaseUser } from '../../../../shared/types';
import { profileSettingModel } from '..';

export function* connectGmailAuthentication() {
  const authProviders: string[] = yield select(
    authUserModel.selectors.selectAuthProviders
  );

  if (authProviders.includes(GOOGLE_PROVIDER_ID)) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Google account is already connected')
      )
    );
    return;
  }

  yield put(profileSettingModel.actions.setIsGmailAuthConnecting(true));

  const provider = new GoogleAuthProvider();
  const fbUser: FirebaseUser = yield getUserFromFb();

  try {
    const result: UserCredential = yield linkWithPopup(fbUser, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (!credential) throw new Error();
    const user = result.user;
    reauthenticateWithCredential(user, credential);
    yield call(setUserData);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'You have successfully connected your Google account.'
        )
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to Google account')
      )
    );
  } finally {
    yield put(profileSettingModel.actions.setIsGmailAuthConnecting(false));
  }
}
