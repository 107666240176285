import { call, cancel, put, take, takeEvery } from 'redux-saga/effects';
import { isAxiosError } from 'axios';
import { TeamFiltered } from '@distribute/shared/types';
import { actions } from '../reducer';
import { Task, channel } from 'redux-saga';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';

const uploadFileChannel = channel<number>();

export function* changeLogo({
  payload: { file, teamId },
}: ReturnType<typeof actions.changeLogo>) {
  const controller = new AbortController();
  const cancelUploadingTask: Task = yield takeEvery(
    actions.cancelUploadingFile,
    function* cancelUploadingFile() {
      yield controller.abort();
    }
  );
  try {
    yield put(actions.setIsUploadLogoLoading(true));
    const team: TeamFiltered = yield call(
      teamsApi.updateBrandLogo,
      teamId,
      file,
      controller.signal,
      (progress) => uploadFileChannel.put(progress)
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
  } catch (error: unknown) {
    logger.error(error);
    if (!(isAxiosError(error) && error.name === 'CanceledError')) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to change brand logo')
        )
      );
    }
  } finally {
    yield put(actions.setIsUploadLogoLoading(false));
    uploadFileChannel.put(0);
    yield cancel(cancelUploadingTask);
  }
}

export function* deleteLogo({
  payload: { teamId },
}: ReturnType<typeof actions.deleteLogo>) {
  try {
    yield put(actions.setIsRemoveLogoLoading(true));
    const team: TeamFiltered = yield call(teamsApi.deleteBrandLogo, teamId);
    yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete logo')
      )
    );
  } finally {
    yield put(actions.setIsRemoveLogoLoading(false));
  }
}

export function* watchUploadFile() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;
    yield put(actions.setUploadLogoProgress(progress));
  }
}
