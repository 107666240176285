import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlanName } from '@distribute/shared/types';
import { PromoCodeInput } from './PromoCodeInput';
import { formatAmountToCurrency } from '../../../../../shared/lib';
import { PromoCode, SubscriptionPlan } from '../../../types';
import { teamsModel } from '../../../../teams';
import { useSubscriptionTeamMembers } from '../../../hooks/useSubscriptionTeamMembers';
import { subscriptionModel } from '../../../model';
import { LoaderDots } from '../../../../../shared/ui';

type IProps = {
  plan: SubscriptionPlan;
  promoCode: PromoCode | null;
  prorationDate?: Date;
  onApplyPromoCode: (promoCode: PromoCode) => void;
  onDeletePromoCode: () => void;
};

export const PaymentInfo: FC<IProps> = ({
  plan,
  prorationDate,
  promoCode,
  onApplyPromoCode,
  onDeletePromoCode,
}) => {
  const dispatch = useDispatch();
  const currentTeamMembers = useSubscriptionTeamMembers();
  const { id: teamId, stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const upcomingInvoiceInfo = useSelector(
    subscriptionModel.selectors.selectUpcomingInvoiceInfo
  );
  const isLoadingUpcomingInvoice = useSelector(
    subscriptionModel.selectors.selectIsLoadingUpcomingInvoice
  );

  const trialDaysCount = upcomingInvoiceInfo?.trialEndAt
    ? moment(upcomingInvoiceInfo.trialEndAt).diff(
        moment().hours(0).minutes(0).seconds(0),
        'days'
      )
    : null;
  const upcomingInvoiceTotal = Math.max(0, upcomingInvoiceInfo?.total ?? 0);

  useEffect(() => {
    dispatch(
      subscriptionModel.actions.getUpcomingInvoice({
        teamId,
        interval: plan.interval,
        plan: plan.id as PlanName,
        stripeCouponId: promoCode?.stripeCouponId,
        stripeSubscriptionId: stripeSubscription?.stripeSubscriptionId,
        prorationDate,
      })
    );
  }, [plan, teamId, promoCode, stripeSubscription, prorationDate, dispatch]);

  useEffect(
    () => () => {
      dispatch(subscriptionModel.actions.setUpcomingInvoiceInfo(null));
    },
    [dispatch]
  );

  const getCurrentItemAmount = () => {
    if (!upcomingInvoiceInfo) return;

    if (trialDaysCount) {
      return `${formatAmountToCurrency(
        upcomingInvoiceInfo.currentItemAmount / 100
      )}/month after`;
    }

    return `${formatAmountToCurrency(
      upcomingInvoiceInfo.currentItemAmount / 100
    )} ${plan.id === PlanName.TEAM ? 'all members' : 'each'}`;
  };

  return (
    <div className="flex flex-col">
      <div className="relative px-6 pt-5 pb-3 bg-gray-75 rounded-lg">
        {isLoadingUpcomingInvoice && (
          <div className="absolute top-0 left-0 bg-base-white bg-opacity-50 w-full h-full flex justify-center items-center">
            <LoaderDots />
          </div>
        )}
        <div className="flex justify-between items-center text-md text-gray-900">
          <div className="font-semibold">{plan.name} plan</div>
          <div>
            {trialDaysCount
              ? `${trialDaysCount} day${trialDaysCount > 1 ? 's' : ''} free`
              : `${formatAmountToCurrency(
                  (upcomingInvoiceInfo?.currentAmount ?? 0) / 100
                )}/month`}
          </div>
        </div>
        <div className="flex justify-between items-center mt-2 text-sm text-gray-700">
          <div>
            {currentTeamMembers.length} member
            {currentTeamMembers.length > 1 ? 's' : ''}
          </div>
          <div>{getCurrentItemAmount()}</div>
        </div>
        <div className="flex justify-between items-center mt-6 text-sm text-gray-700">
          <div className="font-medium">Subtotal</div>
          <div className="font-semibold">
            {formatAmountToCurrency(
              (upcomingInvoiceInfo?.currentAmount ?? 0) / 100
            )}
          </div>
        </div>
        <div className="py-5 mt-4 border-y border-gray-200">
          <PromoCodeInput
            promoCode={promoCode}
            onDelete={onDeletePromoCode}
            onApply={onApplyPromoCode}
          />
        </div>
        <div className="flex flex-col gap-2 pt-4">
          {upcomingInvoiceInfo?.proratedAmount !== undefined && (
            <div className="flex justify-between items-center text-sm">
              <div className="font-medium text-gray-700">
                Prorated difference for current month
              </div>
              <div className="text-gray-800 font-semibold">
                {formatAmountToCurrency(
                  upcomingInvoiceInfo.proratedAmount / 100
                )}
              </div>
            </div>
          )}
          {trialDaysCount && (
            <div className="flex justify-between items-center text-sm">
              <div>
                <span className="font-medium text-gray-700">
                  Total after trial
                </span>{' '}
                <span className="text-gray-600 text-xs">
                  (
                  {moment(upcomingInvoiceInfo?.trialEndAt).format(
                    'MMM D, YYYY'
                  )}
                  )
                </span>
              </div>
              <div className="text-gray-800 font-semibold">
                {formatAmountToCurrency(upcomingInvoiceTotal / 100)}
              </div>
            </div>
          )}
          {stripeSubscription && !trialDaysCount && upcomingInvoiceInfo && (
            <div className="flex justify-between items-center text-sm">
              <div>
                <span className="font-medium text-gray-700">
                  Total next renewal
                </span>{' '}
                <span className="text-gray-600 text-xs">
                  (
                  {moment(stripeSubscription.currentPeriodEnd).format(
                    'MMM D, YYYY'
                  )}
                  )
                </span>
              </div>
              <div className="text-gray-800 font-semibold">
                {formatAmountToCurrency(
                  upcomingInvoiceInfo.totalNextRenewal / 100
                )}
              </div>
            </div>
          )}
          <div className="flex justify-between items-center text-md">
            <div className="text-gray-700 font-medium">Total due today</div>
            <div className="text-gray-800 font-semibold">
              {formatAmountToCurrency(
                (upcomingInvoiceInfo?.totalDueNow ?? 0) / 100
              )}
            </div>
          </div>
        </div>
      </div>
      <img
        src="/assets/images/subscription/plan-info-border.png"
        alt="Plan info border"
      />
    </div>
  );
};
