import { call, put } from 'redux-saga/effects';

import { requireEmailToViewApi } from '../../../shared/api';
import { actions } from '../model/reducer';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { RequireEmailToView } from '@distribute/shared/types';

export function* setRequireEmailToViewData(documentContentId: number) {
  try {
    yield put(
      actions.setConversionKitStatus({
        status: 'pending',
        statusType: 'requireEmailToViewStatus',
      })
    );

    const requireEmailToView: RequireEmailToView | null = yield call(
      requireEmailToViewApi.getRequireEmailToView,
      documentContentId
    );

    yield put(actions.setRequireEmailToView(requireEmailToView ?? undefined));
    yield put(
      actions.setConversionKitStatus({
        status: 'success',
        statusType: 'requireEmailToViewStatus',
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setConversionKitStatus({
        status: 'error',
        statusType: 'requireEmailToViewStatus',
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show Require email to view`)
      )
    );
  }
}
