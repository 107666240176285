import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { templatesModel } from '../../model';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import {
  TemplatesConfigEnum,
  TemplatesSwitcherEnum,
  switcherButtons,
} from '../../lib';

type Props = {
  onClose(): void;
  sortMethod: TemplatesSwitcherEnum;
  setSortMethod: Dispatch<SetStateAction<TemplatesSwitcherEnum>>;
};

export const TemplatesModalHeader: React.FC<Props> = ({
  onClose,
  sortMethod,
  setSortMethod,
}) => {
  const currentTemplatesFolder = useSelector(
    templatesModel.selectors.selectCurrentTemplatesFolder
  );

  const searchQuery = useSelector(templatesModel.selectors.selectSearchQuery);

  const headerText = useMemo(() => {
    switch (currentTemplatesFolder) {
      case TemplatesConfigEnum.RECENT:
        return {
          title: 'Recently Used',
          desc: 'Templates that you have recently utilized and may need to reuse in the near future.',
        };

      case TemplatesConfigEnum.TOP:
        return {
          title: 'Top Templates',
          desc: 'Access expertly crafted marketing resources for success.',
        };

      case TemplatesConfigEnum.PERSONAL:
        return {
          title: 'Personal Templates',
          desc: 'Craft templates that match your unique style.',
        };

      case TemplatesConfigEnum.TEAM:
        return {
          title: 'Team Templates',
          desc: 'Templates forged by your team, for your team.',
        };

      case TemplatesConfigEnum.COMMUNITY:
        return {
          title: 'Community Templates',
          desc: 'Collaborate and thrive with community-driven templates.',
        };

      case TemplatesConfigEnum.MEETINGS:
        return {
          title: 'Meetings',
          desc: 'Organize and conduct effective discussions with prospects or clients.',
        };

      case TemplatesConfigEnum.OPEN_PIPELINE:
        return {
          title: 'Open Pipeline',
          desc: 'Manage and track ongoing sales opportunities and progress.',
        };

      case TemplatesConfigEnum.POST_SALE:
        return {
          title: 'Post-Sale',
          desc: 'Nurture relationships and ensure customer satisfaction after purchase.',
        };

      case TemplatesConfigEnum.CUSTOMERS:
        return {
          title: 'Customers',
          desc: 'Manage and develop ongoing customer relationships and retention.',
        };

      case TemplatesConfigEnum.COACHING:
        return {
          title: 'Coaching',
          desc: 'Provide guidance and improvement strategies for sales teams.',
        };

      case TemplatesConfigEnum.BEST_PRACTICES:
        return {
          title: 'Best Practices',
          desc: 'Share successful methods and techniques for sales excellence.',
        };

      case TemplatesConfigEnum.DEAL_STRATEGY:
        return {
          title: 'Deal Strategy',
          desc: 'Develop plans to effectively win and close sales deals.',
        };

      case TemplatesConfigEnum.INTERNAL_UPDATES:
        return {
          title: 'Internal updates',
          desc: 'Templates for sharing progress, updates, and insights with your sales team and other internal stakeholders.',
        };

      case TemplatesConfigEnum.PROSPECTING:
        return {
          title: 'Prospecting',
          desc: 'Resources and materials to help identify and engage potential customers, including outreach templates and qualification checklists.',
        };

      case TemplatesConfigEnum.IN_DISCUSSION:
        return {
          title: 'In Discussion',
          desc: 'Tools for managing ongoing conversations with prospects, such as meeting agendas, follow-up templates, and proposal outlines.',
        };

      case TemplatesConfigEnum.CLOSING:
        return {
          title: 'Closing',
          desc: 'Templates to support the final stages of the sales process, including contract templates, pricing sheets, and objection handling guides.',
        };

      case TemplatesConfigEnum.IMPLEMENTATION:
        return {
          title: 'Implementation',
          desc: 'Resources for smooth customer onboarding and product implementation, including project plans and training materials.',
        };

      case TemplatesConfigEnum.CUSTOMER_SUCCESS:
        return {
          title: 'Customer Success',
          desc: 'Tools to ensure customer satisfaction and retention, such as check-in templates, success metrics tracking, and feedback forms.',
        };

      case TemplatesConfigEnum.UPSELL_AND_CROSS_SELL:
        return {
          title: 'Upsell & Cross-sell',
          desc: 'Materials to help identify and pursue opportunities for expanding business with existing customers.',
        };

      case TemplatesConfigEnum.WINBACK:
        return {
          title: 'Winback',
          desc: 'Templates and strategies for re-engaging former customers and reviving lost opportunities.',
        };

      case TemplatesConfigEnum.ALL:
      default:
        return {
          title: 'All Templates',
          desc: 'Templates that help you learn, work, and collaborate efficiently.',
        };
    }
  }, [currentTemplatesFolder]);

  return (
    <div className="flex items-start justify-between gap-8 pt-4 pb-4 pl-8 pr-4 overflow-hidden font-medium text-gray-900 shrink-0 text-display-xs font-display">
      <div className="flex items-start justify-between flex-grow gap-4 md:flex-col">
        <div className="flex flex-col">
          <p className="mb-1 font-semibold text-display-sm font-display">
            {searchQuery || headerText.title}
          </p>
          <p className="text-sm font-normal text-gray-700 font-base">
            {searchQuery
              ? 'Templates based on your search results.'
              : headerText.desc}
          </p>
        </div>

        {currentTemplatesFolder === TemplatesConfigEnum.ALL && (
          <div className="flex gap-1 p-1 border border-gray-200 rounded-lg bg-gray-50 font-base">
            {switcherButtons.map((button) => (
              <button
                key={button.id}
                onClick={() => {
                  setSortMethod(button.id);
                }}
                className={cn(
                  'px-3 py-2 text-sm font-semibold rounded-md hover:bg-base-white hover:shadow-sm hover:text-gray-700',
                  {
                    'text-gray-500': sortMethod !== button.id,
                    'text-gray-700 bg-base-white shadow-sm':
                      sortMethod === button.id,
                  }
                )}
              >
                {button.title}
              </button>
            ))}
          </div>
        )}
      </div>

      <Button variant="icon" color="transparent" onClick={onClose} size="md">
        <Icon
          glyph={IconMap.XClose}
          className="text-gray-500 cursor-pointer"
          width={20}
        />
      </Button>
    </div>
  );
};
