import { useCallback, useEffect } from 'react';
import { Message } from '../constants/messages';

type MessageData<T = unknown> = {
  type: Message;
  payload: T;
};

type MessageSender = (type: Message, data?: unknown) => void;

export const useWindowMessage = <T extends string>(
  listenToTypes?: T[],
  fn?: (data: MessageData, send: MessageSender) => void
) => {
  const send = useCallback((type: string, data?: unknown) => {
    window.parent.postMessage(
      {
        type,
        payload: data,
      },
      '*'
    );
  }, []);

  const sizeChangeListener = useCallback(
    (event: MessageEvent) => {
      if (!event.data?.type) return;

      if (listenToTypes?.includes(event.data.type)) {
        fn?.(event.data, send);
      }
    },
    [fn, listenToTypes, send]
  );

  useEffect(() => {
    window.addEventListener('message', sizeChangeListener);

    return () => {
      window.removeEventListener('message', sizeChangeListener);
    };
  }, [sizeChangeListener]);

  return {
    send,
  };
};
