import { call, fork, put } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { subscriptionModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';

export function* confirmPaymentMethodSaga(teamId: number) {
  yield call(teamsApi.leaveTeamPaymentMethodUpdated, teamId);
  const team: TeamFiltered = yield call(teamsApi.getTeam, teamId);

  yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
  yield fork(teamsModel.sagas.loadTeamMembers);
}

export function* confirmPaymentMethod({
  payload: { teamId, cb },
}: ReturnType<typeof subscriptionModel.actions.confirmPaymentMethod>) {
  try {
    yield put(subscriptionModel.actions.setIsConfirmingPaymentMethod(true));
    yield call(confirmPaymentMethodSaga, teamId);
    yield call(cb);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to confirm payment method')
      )
    );
  } finally {
    yield put(subscriptionModel.actions.setIsConfirmingPaymentMethod(false));
  }
}
