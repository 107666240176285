import React, { useEffect, useRef } from 'react';
import {
  getArrowSquareUpRightSVG,
  getPoweredByDistributeSVG,
  svgToPng,
} from './helpers';
import { GmailResetUploadCoverDropdown } from './GmailResetUploadCoverDropdown';
import { Loader } from '../../../../../shared/ui';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { socialModel } from '../../../../../entities/social-auth';

type Props = {
  title: string;
  personName: string;
  link: string;
  onUploaded?(url: string): void;
  color: string;
  coverUrl: string;
  isNoLogo: boolean;
  allowReset?: boolean;
  handleReset?(): void;
  noLinkMode?: boolean;
};

export const GmailBlockElement = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      personName,
      title,
      link,
      onUploaded,
      color,
      coverUrl,
      isNoLogo,
      allowReset,
      handleReset,
      noLinkMode,
    },
    ref
  ) => {
    const socialPoweredByDistributeRef = useRef<HTMLImageElement | null>(null);
    const poweredByDistributeRef = useRef<HTMLImageElement | null>(null);
    const arrowSquareUpRightRef = useRef<HTMLImageElement | null>(null);

    const isCoverLoading = useSelector(
      socialModel.selectors.selectIsPostCoverLoading
    );

    useEffect(() => {
      svgToPng(
        getPoweredByDistributeSVG(color),
        (imgData: string) => {
          if (
            poweredByDistributeRef.current &&
            socialPoweredByDistributeRef.current
          ) {
            poweredByDistributeRef.current.src = imgData;
            socialPoweredByDistributeRef.current.src = imgData;
          }
        },
        '1'
      );
      svgToPng(
        getArrowSquareUpRightSVG(color),
        (imgData: string) => {
          if (arrowSquareUpRightRef.current) {
            arrowSquareUpRightRef.current.style.backgroundImage = `url('${imgData}')`;
          }
        },
        '2'
      );
    }, [color, isNoLogo]);

    return (
      <div className="w-full h-56 relative">
        {allowReset && (
          <div className="absolute right-4 top-4">
            <GmailResetUploadCoverDropdown
              onUploaded={onUploaded}
              handleReset={handleReset}
            />
          </div>
        )}
        <div className="absolute -top-[9999px]">
          <div
            className="w-[448px] h-[224px] bg-cover bg-center p-6 relative"
            id="personalized-block"
            style={{
              backgroundImage: `url(${coverUrl})`,
              color,
            }}
          >
            <h1 className="header font-[800] text-2xl m-0 max-w-90 line-clamp-4">
              {title}
            </h1>
            {!isNoLogo && (
              <img
                ref={socialPoweredByDistributeRef}
                className="h-5 w-[139px] absolute bottom-6 left-6"
                alt="Powered by Distribute"
              ></img>
            )}
          </div>
        </div>
        <div ref={ref} className="w-full h-full selection:bg-transparent">
          <center className="wrapper w-full table-fixed">
            <table
              style={{
                color,
                fontFamily:
                  "'Figtree', ui-sans-serif, system-ui, -apple-system",
              }}
              className="main w-full mx-auto my-0 max-w-[448px] text-[#c4c4c4] border-spacing-0 overflow-hidden"
            >
              <tbody className={cn({ relative: isCoverLoading })}>
                <div
                  className={cn(
                    'absolute w-full h-full flex items-center justify-center',
                    { hidden: !isCoverLoading }
                  )}
                >
                  <Loader />
                </div>
                <tr className={cn({ 'opacity-30': isCoverLoading })}>
                  <td
                    className="background h-[224px] w-full bg-cover bg-center bg-no-repeat p-6 align-top overflow-hidden box-border rounded-lg border border-[rgba(0, 0, 0, 0.08)] block"
                    style={{
                      backgroundImage: `url(${coverUrl})`,
                    }}
                  >
                    <table className="content">
                      <tbody>
                        <tr className="h-[154px] align-top">
                          <td>
                            <h1 className="header font-[800] text-2xl m-0 max-w-90 overflow-hidden line-clamp-4">
                              {title}
                            </h1>
                            {!noLinkMode && (
                              <a href={link} target="_blank" rel="noreferrer">
                                <div className="m-0 mt-2 cursor-pointer">
                                  <p className="text-sm font-medium h-5 inline-block">
                                    Link for {personName}
                                  </p>
                                  <div
                                    ref={arrowSquareUpRightRef}
                                    style={{ transform: 'translateY(3px)' }}
                                    className="h-4 w-4 ml-1 inline-block bg-contain"
                                  ></div>
                                </div>
                              </a>
                            )}
                          </td>
                        </tr>
                        {!isNoLogo && (
                          <tr>
                            <td>
                              <div className="h-4.5">
                                <a
                                  href="https://www.distribute.so/"
                                  target="_blank"
                                  className="badge cursor-pointer inline-block"
                                  rel="noreferrer"
                                >
                                  <img
                                    ref={poweredByDistributeRef}
                                    className="h-5 w-full"
                                    alt="Powered by Distribute"
                                  ></img>
                                </a>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </div>
      </div>
    );
  }
);
