import { call, put, select } from 'redux-saga/effects';
import { popUpApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectPopUp } from '../selectors';
import { PopUp } from '@distribute/shared/types';

export function* updatePopUpDB({
  payload: { documentContentId },
}: ReturnType<typeof conversionKitModel.actions.updatePopUpDB>) {
  try {
    const popUp: PopUp = yield select(selectPopUp);

    const { id: _, ...data } = popUp;

    yield call(popUpApi.updatePopUp, documentContentId, data);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update pop up`)
      )
    );
  }
}
