import React from 'react';
import { Emoji } from 'emoji-picker-react';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../..//shared/sprite';

type Props = {
  onClick(): void;
};

export const CreateWithAI: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="p-3 border border-gray-300 cursor-pointer rounded-xl h-fit group hover:shadow-md">
      <div className="h-[164px] border border-light-6 rounded-xl overflow-hidden relative">
        <div className="h-full group-hover:blur bg-custom-light-orange">
          <img
            src="../../../assets/images/create-with-ai-block-cover.svg"
            alt="Blank page"
            className="w-full h-[200px] object-contain absolute -top-[15px]"
          />
        </div>
        <div className="absolute top-0 z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100">
          <Button
            variant="text"
            color="primary"
            className="w-45 !h-11 text-md"
            onClick={onClick}
          >
            <Icon glyph={IconMap.Stars02} width={20} className="mr-1.5" />
            Create with AI
          </Button>
        </div>
      </div>
      <div className="relative flex items-center gap-2 pr-10 mt-3">
        <Emoji unified="2728" size={20} />
        <p className="text-base font-semibold text-gray-900 truncate">
          Create with AI
        </p>
      </div>
      <div className="mt-2 text-sm text-gray-700 py-0.5">
        Generate page with AI Assistant.
      </div>
    </div>
  );
};
