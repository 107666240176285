import { object, ref, string } from 'yup';

export type FullNameSchema = {
  fullName: string;
};

export const fullNameSchema = object().shape({
  fullName: string().required('Full name is required'),
});

export type ChangePasswordSchema = {
  currentPassword: string;
  newPassword: string;
};

export const changePasswordSchema = object().shape({
  currentPassword: string().required('Current password is required'),
  newPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

export type ChangeEmailSchema = {
  password: string;
  email: string;
};

export const changeEmailSchema = object().shape({
  password: string().required('Current password is required'),
  email: string()
    .email('Not valid email')
    .required('For change email new email is required'),
});

export type CreatePasswordSchema = {
  password: string;
  confirmPassword: string;
};

export const createPasswordSchema = object().shape({
  password: string().required('Input your password.').min(8, ''),
  confirmPassword: string()
    .required('Repeat your password.')
    .min(8, '')
    .oneOf([ref('password'), null], ''),
});
