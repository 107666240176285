import { User } from './user';

export enum UserPermissionToPageRole {
  OWNER = 'owner',
  VIEWER = 'viewer',
  EDITOR = 'editor',
}

export type UserPermissionToPage = {
  id: number;
  user: User;
  role: UserPermissionToPageRole;
  externalUserEmail: string | null;
  isWaitingForInviteAcceptance: boolean;
};
