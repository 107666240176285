import { call, put, select } from 'redux-saga/effects';
import { AnalyticsEvents, Page } from '@distribute/shared/types';
import { analyticsApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesModel } from '../../../pages';
import { setAnalyticsData } from './setAnalyticsData';
import { actions } from '../reducer';
import { analytics } from '../../../../entities/analytics';

export function* resetAnalyticsData({
  payload: { cb },
}: ReturnType<typeof actions.resetAnalytics>) {
  yield put(actions.setIsAnalyticsDeleting(true));
  try {
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    yield call(analyticsApi.resetPageAnalytics, currentPage.id);

    yield call(setAnalyticsData, currentPage, false);

    yield call(cb);

    analytics.track(AnalyticsEvents.ANALYTICS_RESET, {
      pageId: currentPage.id,
    });
  } catch (e: unknown) {
    logger.error(e);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to reset analytics`)
      )
    );
  } finally {
    yield put(actions.setIsAnalyticsDeleting(false));
  }
}
