import { RootState } from '../../../app';
import { selectWithError } from '../../../shared/lib';

export const selectFBUser = ({ user }: RootState) => user.firebaseUser;
export const selectUser = ({ user }: RootState) => user.user;

export const selectUserWithError = selectWithError(selectUser, 'user');
export const selectFBUserWithError = selectWithError(
  selectFBUser,
  'firebaseUser'
);

export const selectAuthToken = ({ user }: RootState) => user.authToken;
export const selectAuthTokenWithError = selectWithError(
  selectAuthToken,
  'authToken'
);

export const selectAuthProviders = ({ user }: RootState) => user.authProviders;
