import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Modal, Button, Input } from '../../../../shared/ui';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

type ChangeDomainModalForm = {
  confirmText: string;
};

const CONFIRMATION_TEXT = 'CONFIRM';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
});

export const ChangeDomainModal: FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ChangeDomainModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = handleSubmit(() => {
    onConfirm();
    onModalClose();
  });

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Change Subdomain"
      actionButton={
        <Button
          onClick={handleFormSubmit}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
        >
          Change Subdomain
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        By changing the subdomain, all published pages will become inaccessible
        under the old subdomain and will be available under the new subdomain.
        Are you sure you want to proceed?
      </div>
      <form onSubmit={handleFormSubmit}>
        <Input
          autoComplete="off"
          label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
          {...register('confirmText')}
          isError={!!errors.confirmText}
          type="text"
        />
      </form>
    </Modal>
  );
};
