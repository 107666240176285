import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, string } from 'yup';
import { Button, Input, FormField } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel, useTeamPermissions } from '../../../teams';
import { DebounceSubdomainInput } from '../../../auth/workspace';
import { teamInfoSettingsModel } from '../../model';
import { ChangeDomainModal } from './ChangeDomainModal';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';

type NameDomainSchema = {
  name: string;
  isSubdomainValid: boolean;
};

const nameDomainSchema = object().shape({
  name: string().required('Name is required'),
  isSubdomainValid: boolean().required().oneOf([true]),
});

export const ChangeNameDomainForm: FC = () => {
  const dispatch = useDispatch();
  const {
    id,
    name,
    domain: currentDomain,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isUpdatingNameDomain = useSelector(
    teamInfoSettingsModel.selectors.selectIsUpdatingNameDomain
  );
  const [subdomain, setSubdomain] = useState(currentDomain);
  const [isShowChangeDomainModal, setIsShowChangeDomainModal] = useState(false);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<NameDomainSchema>({
    resolver: yupResolver(nameDomainSchema),
    defaultValues: { name },
  });
  const { isCanEdit } = useTeamPermissions();

  const handleFormSubmit = (data: NameDomainSchema) => {
    if (subdomain !== currentDomain) {
      setIsShowChangeDomainModal(true);
      return;
    }

    dispatch(
      teamInfoSettingsModel.actions.updateNameDomain({
        name: data.name,
        teamId: id,
      })
    );
  };
  const { name: newName } = watch();
  const handleConfirmDomainChange = () => {
    dispatch(
      teamInfoSettingsModel.actions.updateNameDomain({
        teamId: id,
        name: getValues('name'),
        domain: subdomain,
      })
    );
  };

  const isFormChanged = subdomain !== currentDomain || newName !== name;

  useConfirmWindowClose(isFormChanged, 'changeTeamNameAndDomain');

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-col gap-y-5"
      >
        <FormField label="Name">
          <Input
            {...register('name')}
            isError={!!errors.name}
            type="text"
            disabled={!isCanEdit}
          />
        </FormField>
        <FormField label="Subdomain">
          <input {...register('isSubdomainValid')} type="checkbox" hidden />
          <DebounceSubdomainInput
            subdomain={subdomain}
            setSubdomain={setSubdomain}
            setIsSubdomainValid={(val) => setValue('isSubdomainValid', val)}
            isLoading={isUpdatingNameDomain}
            getValues={watch}
            currentSubdomain={currentDomain}
            isDisabled={!isCanEdit}
          />
        </FormField>
        {isCanEdit && (
          <Button
            variant="text"
            color="primary"
            disabled={isUpdatingNameDomain || !isFormChanged}
            loading={isUpdatingNameDomain}
            type="submit"
            className="self-start sm:w-full"
          >
            Save
          </Button>
        )}
      </form>
      <ChangeDomainModal
        isOpen={isShowChangeDomainModal}
        onClose={() => setIsShowChangeDomainModal(false)}
        onConfirm={handleConfirmDomainChange}
      />
    </>
  );
};
