import { profileSettingModel } from '../../../features/profile-settings';
import { Location } from 'history';
import { call, takeEvery } from 'redux-saga/effects';

import { confirmPasswordReset } from '../../../features/auth/code-operation';

export function* completeResetPasswordWorker(location: Location<string>) {
  const { state: code } = location;

  yield takeEvery(
    profileSettingModel.actions.completeResetPasswordClick,
    function* (completeResetPasswordAction) {
      const {
        payload: { password },
      } = completeResetPasswordAction;

      yield call(confirmPasswordReset, code, password);
    }
  );
}
