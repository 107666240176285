import { Severity, captureMessage } from '@sentry/react';

import { authApi } from '../../../shared/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { RT } from '../../../shared/types';
import {
  finishDownloadExtensionStep,
  sendExtensionLogin,
  sendExtensionLogout,
  sendExtensionOpen,
} from '../lib';
import { actions } from './reducer';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';
import { environment } from '../../../environments/environment';
import { logger } from '../../../shared/lib';

export function* extensionLogin() {
  let hasConnection: RT<typeof sendExtensionLogin> = false;

  try {
    yield put(actions.setEventLoginLoading(true));

    const { token }: RT<typeof authApi.createCustomToken> = yield call(
      authApi.createCustomToken
    );

    hasConnection = yield call(sendExtensionLogin, token);
  } catch (e: unknown) {
    captureMessage('Extension Login', {
      level: Severity.Error,
      extra: {
        message: (e as Error)?.message || '',
      },
    });
  } finally {
    yield put(actions.setEventLoginLoading(false));
  }

  if (hasConnection) {
    yield call(finishDownloadExtensionStep);
  }
}

export function* extensionLogout() {
  yield call(sendExtensionLogout);
}

export function* extensionOpen() {
  yield put(actions.setEventOpenLoading(true));

  const hasConnection: RT<typeof sendExtensionOpen> = yield call(
    sendExtensionOpen
  );

  yield put(actions.setIsInstallModalOpen(!hasConnection));
  yield put(actions.setEventOpenLoading(false));
}

export function* extensionInstallClick() {
  try {
    analytics.track(AnalyticsEvents.EXTENSION_INSTALL_CLICK);
    yield call(window.open, environment.extensionWebsiteUrl, '_blank');
  } catch (e: unknown) {
    logger.error(e);
  }
}

export function* extensionWorker() {
  yield takeLatest(actions.extensionInstallClick, extensionInstallClick);
  yield takeLatest(actions.openClick, extensionOpen);
  yield call(extensionLogin);
}
