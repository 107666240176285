import { Folder } from '@distribute/shared/types';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateFolderAction,
  CreateUserPermissionAction,
  DeleteFolderAction,
  DeleteUserPermissionAction,
  RenameFolderAction,
  ReorderFolderAction,
  UpdateTeamMemberPermissionAction,
  UpdateUserPermissionAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type FolderStatus = 'never' | 'pending' | 'error' | 'success';

type State = {
  isModalOpen: boolean;
  folders: Folder[];
  currentFolder: Folder | undefined;
  createFolderIsLoading: boolean;
  deleteFolderIsLoading: boolean;
  renameFolderIsLoading: boolean;
  createUserPermissionIsLoading: boolean;
  foldersStatus: FolderStatus;
};

const initialState: State = {
  isModalOpen: false,
  folders: [],
  currentFolder: undefined,
  createFolderIsLoading: false,
  deleteFolderIsLoading: false,
  renameFolderIsLoading: false,
  createUserPermissionIsLoading: false,
  foldersStatus: 'never',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setModalOpen: (
      state,
      { payload: isModalOpen }: PayloadAction<boolean>
    ) => ({ ...state, isModalOpen }),
    setFolders: (state, { payload: folders }: PayloadAction<Folder[]>) => ({
      ...state,
      folders,
    }),
    setCreateFolderIsLoading: (
      state,
      { payload: createFolderIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      createFolderIsLoading,
    }),
    setCurrentFolder: (
      state,
      { payload: currentFolder }: PayloadAction<Folder | undefined>
    ) => ({
      ...state,
      currentFolder,
    }),
    setDeleteFolderIsLoading: (
      state,
      { payload: deleteFolderIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      deleteFolderIsLoading,
    }),
    setRenameFolderIsLoading: (
      state,
      { payload: renameFolderIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      renameFolderIsLoading,
    }),
    setCreateUserPermissionIsLoading: (
      state,
      { payload: createUserPermissionIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      createUserPermissionIsLoading,
    }),
    setFoldersStatus: (
      state,
      { payload: foldersStatus }: PayloadAction<FolderStatus>
    ) => ({
      ...state,
      foldersStatus,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  createFolder: createAction<CreateFolderAction>('folders/createFolder'),
  reorderFolder: createAction<ReorderFolderAction>('folders/reorderFolder'),
  deleteFolder: createAction<DeleteFolderAction>('folders/deleteFolder'),
  renameFolder: createAction<RenameFolderAction>('folders/renameFolder'),
  updateTeamMemberPermission: createAction<UpdateTeamMemberPermissionAction>(
    'folders/updateTeamMemberPermission'
  ),
  createUserPermission: createAction<CreateUserPermissionAction>(
    'folders/createUserPermission'
  ),
  updateUserPermission: createAction<UpdateUserPermissionAction>(
    'folders/updateUserPermission'
  ),
  deleteUserPermission: createAction<DeleteUserPermissionAction>(
    'folders/deleteUserPermission'
  ),
};
