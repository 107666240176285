import {
  UserPermissionToVideoRecordRole,
  VideoRecordStatusConvert,
  VideoRecordTeamMembersPermission,
} from '@distribute/shared/types';
import { RootState } from '../../../app';
import { VideoRecordLoadingStatus } from './types';
import { VideoRecordType } from '@distribute/shared/api-types/video-record';

const filterByRecordsMy = (records: VideoRecordType[], userId: string) =>
  records.filter((v) =>
    v.userPermissionToVideoRecords.some(
      (uV) =>
        uV.user.id === userId &&
        uV.role === UserPermissionToVideoRecordRole.OWNER
    )
  );

const filterByRecordsConverted = (records: VideoRecordType[]) =>
  records.filter((r) => r.statusConvert === VideoRecordStatusConvert.COMPLETED);

const filterByRecordsShared = (records: VideoRecordType[], userId: string) =>
  records.filter((v) => {
    if (
      v.userPermissionToVideoRecords.some(
        (uV) =>
          uV.user.id === userId &&
          uV.role === UserPermissionToVideoRecordRole.OWNER
      )
    ) {
      return false;
    }

    if (
      v.userPermissionToVideoRecords.some(
        (uV) =>
          uV.user.id === userId &&
          [
            UserPermissionToVideoRecordRole.VIEWER,
            UserPermissionToVideoRecordRole.EDITOR,
          ].includes(uV.role)
      )
    ) {
      return true;
    }

    return (
      v.teamMembersPermission !== VideoRecordTeamMembersPermission.INVITE_ONLY
    );
  });

export const selectRecordsMy =
  (userId: string, converted = false) =>
  ({ recordings }: RootState) => {
    const records = filterByRecordsMy(recordings.records, userId);
    return converted ? filterByRecordsConverted(records) : records;
  };

export const selectHasRecordsMy =
  (userId: string, converted = false) =>
  ({ recordings }: RootState) => {
    const records = filterByRecordsMy(recordings.records, userId);
    return converted
      ? filterByRecordsConverted(records).length > 0
      : records.length > 0;
  };

export const selectRecordsShared =
  (userId: string, converted = false) =>
  ({ recordings }: RootState) => {
    const records = filterByRecordsShared(recordings.records, userId);
    return converted ? filterByRecordsConverted(records) : records;
  };

export const selectHasRecordsShared =
  (userId: string, converted = false) =>
  ({ recordings }: RootState) => {
    const records = filterByRecordsShared(recordings.records, userId);
    return converted
      ? filterByRecordsConverted(records).length > 0
      : records.length > 0;
  };

export const selectHasRecords =
  (userId: string, converted = false) =>
  ({ recordings }: RootState) => {
    return converted
      ? filterByRecordsConverted(recordings.records).length > 0
      : recordings.records.length > 0;
  };

export const selectIsRecordLoading = ({ recordings }: RootState) =>
  [VideoRecordLoadingStatus.Never, VideoRecordLoadingStatus.Pending].includes(
    recordings.recordStatus
  );

export const selectRecords = ({ recordings }: RootState) => recordings.records;
export const selectRecord = ({ recordings }: RootState) => recordings.record;

const recordWithError = (
  record: VideoRecordType | undefined,
  target: string
) => {
  if (!record) {
    throw new Error(`${target} is undefined`);
  }
  return record;
};

export const selectRecordIdToEdit = ({ recordings }: RootState) =>
  recordings.recordIdToEdit;
export const selectRecordToEdit = ({ recordings }: RootState) =>
  recordWithError(
    recordings.records.find((r) => r.id === recordings.recordIdToEdit),
    'recordEdit'
  );

export const selectIsRecordInsertToPageLoading = ({ recordings }: RootState) =>
  recordings.isRecordInsertToPageLoading;

export const selectIsRecordEditLoading = ({ recordings }: RootState) =>
  recordings.isRecordEditLoading;

export const selectRecordIdToDelete = ({ recordings }: RootState) =>
  recordings.recordIdToDelete;
export const selectRecordToDelete = ({ recordings }: RootState) =>
  recordWithError(
    recordings.records.find((r) => r.id === recordings.recordIdToDelete),
    'recordDelete'
  );
export const selectIsRecordDeleteLoading = ({ recordings }: RootState) =>
  recordings.isRecordDeleteLoading;

export const selectRecordIdToShare = ({ recordings }: RootState) =>
  recordings.recordIdToShare;
export const selectRecordToShare = ({ recordings }: RootState) =>
  recordWithError(
    recordings.records.find((r) => r.id === recordings.recordIdToShare),
    'recordShare'
  );
export const selectRecordShareLoading = ({ recordings }: RootState) =>
  recordings.isRecordShareLoading;

export const selectFilterSearch = ({ recordings }: RootState) =>
  recordings.filterSearch;

export const selectFilterOwner = ({ recordings }: RootState) =>
  recordings.filterOwner;

export const selectSort = ({ recordings }: RootState) => recordings.sort;
