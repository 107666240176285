import { SnippetType } from '@distribute/shared/types';
import { Button, Dropdown, Icon, Input } from '../../../../../shared/ui';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

import { IconMap } from '../../../../../shared/sprite';
import {
  AddingMethodEnum,
  addingMethodOptions,
  addingOptionsLabelConfig,
  uploadSnippetConfig,
} from './lib/config';
import { UploadInput } from '../../../../../entities/tiptap-editor/ui/upload-form/UploadInput';
import { useDispatch, useSelector } from 'react-redux';
import { mediaUploadModel } from '../../../../../features/media-upload';
import { checkIfIsUrl } from './lib/helpers';
import {
  LOOM_URL_REGEX,
  YOUTUBE_URL_REGEX,
} from '../../../../../shared/constants';
import { getEmbedUrlFromYoutubeUrl } from '../../../../../entities/tiptap-editor/extensions/YoutubeEmbed/utils';

type Props = {
  type: SnippetType.FILE | SnippetType.VIDEO | SnippetType.IMAGE;
  setUrl: Dispatch<SetStateAction<string | undefined>>;
  url?: string;
  onClose: () => void;
  switchToNextTab?: () => void;
  addingMethod: AddingMethodEnum;
  setAddingMethod: Dispatch<SetStateAction<AddingMethodEnum>>;
  customBottomButtons?: ReactNode;
};

export const UploadSnippetTab: FC<Props> = ({
  type,
  setUrl,
  url,
  onClose,
  switchToNextTab,
  addingMethod,
  setAddingMethod,
  customBottomButtons,
}) => {
  const dispatch = useDispatch();

  const [id, setNewId] = useState(() => v4());
  const file = useSelector(mediaUploadModel.selectors.selectFile(id));

  useEffect(() => {
    if (file?.status === 'error') {
      setNewId(v4());
    }
  }, [file?.status]);

  const [urlInputValue, setUrlInputValue] = useState('');
  const [isUrlInputError, setIsUrlInputError] = useState(false);

  useEffect(() => {
    setUrlInputValue('');
    setIsUrlInputError(false);
  }, [addingMethod]);

  const addingMethodItems = useMemo(() => {
    return addingMethodOptions.map((option) => {
      return {
        id: option.id,
        onClick: () => {
          setAddingMethod(option.id);
        },
        label: (
          <div className="flex items-center gap-2">
            <Icon
              glyph={option.icon}
              width={16}
              className="text-gray-600 group-hover:text-gray-600"
            />
            <div className="flex flex-col min-w-0 text-sm text-gray-700 truncate group-hover:text-gray-800">
              {addingOptionsLabelConfig[type][option.id]}
            </div>
          </div>
        ),
      };
    });
  }, [type, setAddingMethod]);

  const youtube = url?.match(YOUTUBE_URL_REGEX);
  const loom = url?.match(LOOM_URL_REGEX);

  const isIframe = youtube || loom;

  const setUrlFromInput = () => {
    if (checkIfIsUrl(urlInputValue)) {
      setUrl(urlInputValue);
    } else {
      setIsUrlInputError(true);
    }
  };

  return (
    <div>
      <div>
        <div className="mb-5">
          <Dropdown
            listStyles="shadow-lg !w-[var(--radix-dropdown-menu-trigger-width)]"
            items={addingMethodItems}
            currentItemId={addingMethod}
            triggerStyles="w-full"
            arrowStyle="text-gray-500"
            isModalMode={false}
            align="start"
            isHideItemCheck
          />
        </div>
        {!url ? (
          addingMethod === AddingMethodEnum.UPLOAD ? (
            <UploadInput
              type={type}
              allowedTypes={uploadSnippetConfig[type].allowedTypes}
              maxSize={uploadSnippetConfig[type].maxSize}
              accept={uploadSnippetConfig[type].acceptString}
              validationRegexp={uploadSnippetConfig[type].validationRegexp}
              progress={file?.progress}
              isUploading={file?.status === 'uploading'}
              onUpload={(uploadedFile) =>
                dispatch(
                  mediaUploadModel.actions.uploadFile({
                    file: uploadedFile,
                    id,
                    cb: (fileUrl) => setUrl(fileUrl),
                  })
                )
              }
            />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setUrlFromInput();
              }}
            >
              <Input
                placeholder="https://"
                type="text"
                value={urlInputValue}
                onChange={(e) => {
                  setUrlInputValue(e.target.value);
                }}
                isError={isUrlInputError}
                messageText={
                  isUrlInputError &&
                  'Provide a valid link format (e.g. https://www.example.com/)'
                }
                onBlur={() => {
                  setUrlFromInput();
                }}
              />
            </form>
          )
        ) : (
          <div
            className="flex items-center justify-center w-full h-66 border border-light-3 rounded-lg bg-gray-200"
            style={
              [SnippetType.IMAGE, SnippetType.VIDEO].includes(type)
                ? { backgroundImage: 'url(../../../assets/images/checker.svg)' }
                : {}
            }
          >
            {type === SnippetType.IMAGE && (
              <img
                src={url}
                alt="snippet"
                className="object-contain max-w-full max-h-full"
              />
            )}
            {type === SnippetType.VIDEO &&
              (isIframe ? (
                <iframe
                  title="snippet video"
                  src={youtube ? getEmbedUrlFromYoutubeUrl({ url }) || '' : url}
                  className="h-full w-full"
                />
              ) : (
                <video
                  src={url}
                  className="object-contain max-w-full max-h-full"
                  muted
                />
              ))}
            {type === SnippetType.FILE && (
              <iframe
                title="file"
                src={url}
                className="object-contain max-w-full h-full"
              />
            )}
          </div>
        )}
      </div>
      {url && (
        <Button
          className="mt-2"
          iconLeftName={IconMap.RefreshCw05}
          iconLeftWidth={20}
          iconBlockClassName="mr-1"
          variant="icon-text"
          color="secondary"
          onClick={() => {
            setUrl('');
            setUrlInputValue('');
          }}
        >
          Replace {type}
        </Button>
      )}
      {customBottomButtons ||
        (switchToNextTab && (
          <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white">
            <Button
              fullWidth
              color="secondary"
              variant="text"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="text"
              fullWidth
              onClick={() => {
                switchToNextTab();
              }}
              disabled={!url}
            >
              Continue
            </Button>
          </div>
        ))}
    </div>
  );
};
