import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { Button, Input } from '../../../shared/ui';
import { authModel } from '../../../entities/auth-operation';

const validationSchema = object().shape({
  email: string().required('Email is required'),
});

type LogInAsSuperuserForm = {
  email: string;
};

export const LoginAsSuperUser = () => {
  const dispatch = useDispatch();
  const authLoading = useSelector(authModel.selectors.selectLoading);
  const isLogInBtnLoading = authLoading;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInAsSuperuserForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: undefined,
    },
  });

  const handleFormSubmit = (data: LogInAsSuperuserForm) => {
    dispatch(authModel.actions.loginAsSuperuser({ ...data }));
  };

  return (
    <div>
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Log in as Super User
      </h1>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
        className="max-w-107"
      >
        <div className="mb-5">
          <Input
            {...register('email')}
            label="Email"
            type="email"
            isError={!!errors.email}
          />
        </div>

        <div className="mb-6">
          <Button
            variant="text"
            color="primary"
            type="submit"
            fullWidth
            loading={isLogInBtnLoading}
          >
            Log In
          </Button>
        </div>
      </form>
    </div>
  );
};
