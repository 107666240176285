import { Editor } from '@tiptap/core';
import { UPLOAD_FORM_WIDTH } from '../config';

export const getUploadFormRect = (editor: Editor) => {
  const pos = editor.state.selection.from;

  const domNode = editor.view.domAtPos(pos).node as HTMLElement;
  const rootDomNode = domNode.closest('.node-column') || editor.view.dom;
  if (!rootDomNode) {
    throw new Error('Cannot find root block to get upload form rect');
  }
  const domNodeRect = domNode.getBoundingClientRect();
  const rootRect = rootDomNode.getBoundingClientRect();
  const paddingLeft = Number.parseInt(
    getComputedStyle(rootDomNode).paddingLeft
  );
  const horizontalCenter = rootRect.left + (paddingLeft + rootRect.width) / 2;

  const editorRect = editor.view.dom.getBoundingClientRect();

  let x = horizontalCenter - UPLOAD_FORM_WIDTH / 2;

  if (x + UPLOAD_FORM_WIDTH > editorRect.x + editorRect.width) {
    x = editorRect.x + editorRect.width - UPLOAD_FORM_WIDTH;
  }

  if (x < editorRect.x) {
    x = editorRect.x;
  }

  return new DOMRect(
    x,
    domNodeRect.top + domNodeRect.height,
    UPLOAD_FORM_WIDTH,
    0
  );
};
