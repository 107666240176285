import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = { hasInternetConnection: boolean };
const initialState: State = {
  hasInternetConnection: true,
};
export const { reducer, actions } = createSlice({
  name: 'connectivity',
  initialState,
  reducers: {
    setHasInternetConnection: (state, { payload: hasInternetConnection }: PayloadAction<boolean>) => ({
      ...state,
      hasInternetConnection,
    }),
  },
});
