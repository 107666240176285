import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { SETTINGS_ROUTE } from '../../../entities/history';
import { ProfileSettings } from '../../../features/profile-settings';
import { Integrations } from '../../../features/integrations';
import { LoginAsSuperUser } from '../../../features/superuser';
import { useTeamPermissions } from '../../../features/teams';

type SettingsTab = {
  name: string;
  title: string;
  component: JSX.Element;
  isHidden?: boolean;
};

export const SettingsPage = () => {
  const { isGuest } = useTeamPermissions();
  const tabs: SettingsTab[] = useMemo(
    () => [
      {
        name: 'profile',
        title: 'Profile',
        component: <ProfileSettings />,
      },
      {
        name: 'integrations',
        title: 'Integrations',
        component: <Integrations />,
        isHidden: isGuest,
      },
      {
        name: 'superuser',
        title: 'Superuser Panel',
        component: <LoginAsSuperUser />,
      },
    ],
    [isGuest]
  );

  return (
    <>
      <Helmet titleTemplate="Settings - Distribute" />
      <div className="w-full">
        <Switch>
          {tabs.map((tab) => {
            if (tab.isHidden) return null;

            return (
              <Route
                path={`${SETTINGS_ROUTE}/${tab.name}`}
                key={tab.name}
                render={() => tab.component}
              />
            );
          })}
          <Route path={`${SETTINGS_ROUTE}`} render={() => tabs[0].component} />
        </Switch>
      </div>
    </>
  );
};
