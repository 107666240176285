import { call, put } from 'redux-saga/effects';
import { AnalyticsEvents } from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';

export function* confirmDeleteTeam(teamId: number, hash: string) {
  try {
    yield put(actions.setConfirmDeleteTeamStatus('pending'));
    yield call(teamsApi.confirmDelete, teamId, hash);
    yield call(teamsModel.sagas.onDeleteTeam, teamId);
    yield put(actions.setConfirmDeleteTeamStatus('success'));

    analytics.track(AnalyticsEvents.TEAM_DELETED, { teamId });
  } catch (error: unknown) {
    logger.error(error);
    yield put(actions.setConfirmDeleteTeamStatus('error'));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Deletion has not been confirmed')
        )
      )
    );
  }
}
