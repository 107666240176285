import React from 'react';
import { Node } from '@tiptap/pm/model';
import { Editor } from '@tiptap/react';

import { ColorListPicker } from '../../../../../shared/ui/color-list-picker';
import { DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG } from '../config/colors';
import { addButtonHoveringBlockPluginKey } from '../../HoveringBlock';

type Props = {
  editor: Editor;
  node: Node;
};
export const ColorSubMenu: React.FC<Props> = ({ editor, node }) => {
  const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
    editor.state
  );
  const handleReset = () => {
    editor
      .chain()
      .focus((hoveringBlockPluginState?.hoveredBlockPosition ?? 0) + 1)
      .updateAttributes('callout', {
        bgColor: DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG[0].value,
      })
      .run();
  };
  const handleSetColor = (bgColor: string) => {
    editor
      .chain()
      .focus((hoveringBlockPluginState?.hoveredBlockPosition ?? 0) + 1)
      .updateAttributes('callout', {
        bgColor,
      })
      .run();
  };

  if (!editor) return null;

  const currentColor =
    hoveringBlockPluginState?.hoveredBlockNode?.attrs.bgColor ||
    DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG[0].value;

  return (
    <div className="w-36">
      <ColorListPicker
        onReset={handleReset}
        onSetColor={handleSetColor}
        currentColor={currentColor}
        colorList={DEFAULT_EDITOR_CALLOUT_COLORS_CONFIG}
      />
    </div>
  );
};
