import { Message } from '../../constants/messages';
import { useWindowMessage } from '../../hooks/useWindowMessage';

export const useIframeCommunication = ({ id }: { id: string }) => {
  const { send } = useWindowMessage();

  return {
    close: () => send(Message.TemplatesPreviewClose),
    apply: () => send(Message.TemplatesSelect, { id }),
  };
};
