import { takeEvery } from 'redux-saga/effects';
import { contactSupportFormModel } from '..';
import { contactSupport } from './contactSupport';

export function* contactSupportFormGlobalWorker() {
  yield takeEvery(
    contactSupportFormModel.actions.contactSupport,
    contactSupport
  );
}
