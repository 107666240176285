export const radioOptions = [
  {
    id: 1,
    label: 'Option 1',
  },
  {
    id: 2,
    label: 'Option 2',
  },
  {
    id: 3,
    label: 'Option 3',
  },
];

export const checkboxOptions = [
  {
    id: 1,
    label: 'Option 1',
  },
  {
    id: 2,
    label: 'Option 2',
  },
  {
    id: 3,
    label: 'Option 3',
  },
];

export const foldersOptions = [
  {
    id: '1',
    title: 'Folder 1',
  },
  {
    id: '2',
    title: 'Folder 2',
  },
  {
    id: '3',
    title: 'Folder 3',
  },
];
