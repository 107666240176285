import { Node, mergeAttributes } from '@tiptap/react';

export const EmbedContentBlockExt = Node.create({
  name: 'embedContentBlock',
  group: 'block',
  content: 'embedContent',

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
      0,
    ];
  },
});
