import { Snippet } from '@distribute/shared/types';
import { Button, Input, Modal } from '../../../../shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { snippetsModel } from '../../model';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SNIPPETS_ROUTE } from '../../../../entities/history';
import { navigate } from '../../../../processes/navigation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  snippet: Snippet;
  redirectToSnippets?: boolean;
};

type DeleteSnippetModalForm = {
  confirmText: string;
  id: string;
};

const CONFIRMATION_TEXT = 'CONFIRM';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
});

export const DeleteSnippetConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  snippet,
  redirectToSnippets = false,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    snippetsModel.selectors.selectIsDeleteSnippetLoading
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeleteSnippetModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = () => {
    dispatch(
      snippetsModel.actions.deleteSnippet({
        id: snippet.id,
        sharingAccess: snippet.sharingAccess,
        isDraft: Boolean(snippet.isDraft),
        callback: () => {
          onModalClose();
          if (redirectToSnippets) {
            dispatch(
              navigate({
                to: SNIPPETS_ROUTE,
              })
            );
          }
        },
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete Snippet"
      dialogClassName="z-55"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Forever
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        The “{snippet.name}” snippet and its content will be deleted
        permanently. Deleting doesn’t affect any page that was created with this
        snippet before.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
