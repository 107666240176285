import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { UserToTeamFiltered, UserToTeamRole } from '@distribute/shared/types';
import { Icon, Dropdown } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { teamsModel, useTeamPermissions } from '../../../../../features/teams';
import { teamMembersModel } from '../../../model';
import { DeleteTeamMemberConfirmModal } from '../DeleteTeamMemberConfirmModal';

type IProps = {
  teamMember: UserToTeamFiltered;
};

export const MemberProfileMenu: FC<IProps> = ({ teamMember }: IProps) => {
  const [teamMemberToDelete, setTeamMemberToDelete] = useState<
    UserToTeamFiltered | undefined
  >(undefined);
  const dispatch = useDispatch();
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const { isCanTransferOwnership, isCanManageAccess } = useTeamPermissions();

  const handleResendInvite = useCallback(() => {
    dispatch(
      teamMembersModel.actions.resendInvite({
        teamId: currentTeam.id,
        teamMemberId: teamMember.id,
      })
    );
  }, [dispatch, currentTeam.id, teamMember.id]);

  const handleUpdateTeamMemberRole = useCallback(
    (role: UserToTeamRole) => {
      dispatch(
        teamMembersModel.actions.updateTeamMemberRole({
          role,
          teamId: currentTeam.id,
          teamMemberId: teamMember.id,
        })
      );
    },
    [dispatch, currentTeam.id, teamMember.id]
  );

  const handleTransferOwnership = useCallback(() => {
    dispatch(
      teamMembersModel.actions.transferOwnership({
        teamId: currentTeam.id,
        teamMemberId: teamMember.id,
      })
    );
  }, [dispatch, currentTeam.id, teamMember.id]);

  const actions = useMemo(() => {
    const allActions = [
      {
        id: 'resendInvite',
        label: 'Resend Invite',
        onClick: handleResendInvite,
        isShow: isCanManageAccess && teamMember.isWaitingForInviteAcceptance,
      },
      {
        id: 'grantAdminRole',
        label: 'Grant Admin Role',
        onClick: () => handleUpdateTeamMemberRole(UserToTeamRole.ADMIN),
        isShow:
          isCanManageAccess &&
          teamMember.role === UserToTeamRole.MEMBER &&
          !teamMember.isWaitingForInviteAcceptance,
      },
      {
        id: 'revokeAdminRole',
        label: 'Revoke Admin Role',
        onClick: () => handleUpdateTeamMemberRole(UserToTeamRole.MEMBER),
        isShow:
          isCanManageAccess &&
          teamMember.role === UserToTeamRole.ADMIN &&
          !teamMember.isWaitingForInviteAcceptance,
      },
      {
        id: 'transferOwnership',
        label: 'Transfer Ownership',
        onClick: handleTransferOwnership,
        isShow:
          isCanTransferOwnership &&
          !teamMember.isWaitingForInviteAcceptance &&
          !currentTeam.isAppsumo,
      },
      {
        id: 'delete',
        label: 'Delete',
        onClick: () => setTeamMemberToDelete(teamMember),
        isShow: isCanManageAccess && teamMember.role !== UserToTeamRole.OWNER,
      },
    ];

    return allActions.filter((action) => action.isShow);
  }, [
    handleResendInvite,
    isCanManageAccess,
    teamMember,
    handleTransferOwnership,
    isCanTransferOwnership,
    currentTeam.isAppsumo,
    handleUpdateTeamMemberRole,
  ]);

  if (!actions.length) return null;

  return (
    <>
      <Dropdown
        triggerComponent={
          <DropdownMenu.Trigger className="inline-flex focus:outline-none">
            <Icon
              glyph={IconMap.DotsVertical}
              width={20}
              className="cursor-pointer text-gray-600"
            />
          </DropdownMenu.Trigger>
        }
        items={actions}
      />
      <DeleteTeamMemberConfirmModal
        isOpen={!!teamMemberToDelete}
        onClose={() => setTeamMemberToDelete(undefined)}
        teamMember={teamMemberToDelete}
      />
    </>
  );
};
