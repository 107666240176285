import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { CustomDomainType, TeamFiltered } from '@distribute/shared/types';
import { customDomainsModel } from '..';
import { customDomainsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';

export function* deleteCustomDomain({
  payload: { callback },
}: ReturnType<typeof customDomainsModel.actions.deleteCustomDomain>) {
  try {
    yield put(customDomainsModel.actions.setCustomDomainIsDeleting(true));
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const customDomain: CustomDomainType = yield select(
      customDomainsModel.selectors.selectCustomDomainInfo
    );

    if (!customDomain) {
      throw new Error();
    }

    yield call(
      customDomainsApi.deleteCustomDomain,
      customDomain.id,
      currentTeam.id
    );

    yield put(customDomainsModel.actions.setCustomDomainInfo(null));

    callback?.();
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete custom domain')
      )
    );
  } finally {
    yield put(customDomainsModel.actions.setCustomDomainIsDeleting(false));
  }
}
