import { FC, useRef } from 'react';

import { useResizeObserver } from '@distribute/frontend/utils';

import { PlayerSettings } from './types';
import { PlayerProvider } from './provider';

import { PlayerErrorBoundary, PlayerManager } from './ui';
import { type } from './config';

export const Player: FC<PlayerSettings> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useResizeObserver(ref);

  return (
    <div
      ref={ref}
      style={{ aspectRatio: props.aspectRatio }}
      className="overflow-hidden relative bg-base-white cursor-auto outline-none"
      data-type={type}
    >
      <PlayerErrorBoundary>
        <PlayerProvider {...props} entry={entry} wrapperRef={ref}>
          <PlayerManager />
        </PlayerProvider>
      </PlayerErrorBoundary>
    </div>
  );
};
