import { select } from 'redux-saga/effects';

import { logger } from '../../../shared/lib';
import { User } from '../../../shared/types';

import { authUserModel } from '../model';

export function* getUserFromStore() {
  try {
    const user: User | null = yield select(
      authUserModel.selectors.selectFBUser
    );

    if (!user) {
      throw new Error(
        'Something went wrong! There is no logged user in the system!'
      );
    }
    return user;
  } catch (e: unknown) {
    logger.error(e);
    throw e;
  }
}
