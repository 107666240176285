import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../../shared/sprite';
import { snippetsModel } from '../../../../features/snippets';
import { SnippetsNavigationItem } from './SnippetsNavigationItem';
import { SnippetsNavigationGroupItem } from './SnippetsNavigationGroupItem';
import { SnippetsSearch } from './SnippetsSearch';
import { getSnippetsByFolder } from './lib/getSnippetsbyFolder';
import { SnippetsConfigBasicEnum } from './lib/config';
import { useSnippetsPermissions } from '../../../../features/teams';

export const SnippetsNavigation: React.FC = () => {
  const dispatch = useDispatch();

  const currentFolder = useSelector(
    snippetsModel.selectors.selectCurrentModalFolder
  );

  const { isCanCreatePersonalSnippets } = useSnippetsPermissions();

  const categories = useSelector(snippetsModel.selectors.selectCategories);

  const snippetsConfig = [
    {
      name: 'All Snippets',
      key: SnippetsConfigBasicEnum.ALL,
      icon: IconMap.Snippets,
    },
    {
      groupName: 'Team snippets',
      items: categories
        .filter((c) => c.snippets.length > 0)
        .map((category) => {
          return { name: category.name, key: category.id };
        }),
      isHidden: categories.every((c) => c.snippets.length === 0),
    },
    {
      groupName: 'Your snippets',
      items: [
        {
          name: 'Personal',
          key: SnippetsConfigBasicEnum.PERSONAL,
        },
      ],
      isHidden: !isCanCreatePersonalSnippets,
    },
  ];

  const handleSnippetFolderSelect = (folder: string | number) => {
    dispatch(snippetsModel.actions.setCurrentModalFolder(folder));
    dispatch(snippetsModel.actions.setModalSearchQuery(''));
  };

  const snippets = useSelector(snippetsModel.selectors.selectAvailableSnippets);

  return (
    <section className="shrink-0 w-[296px] flex flex-col justify-between">
      <div className="flex-grow py-4 pl-4 overflow-x-hidden">
        <div className="h-full bg-gray-100 rounded-xl">
          <div className="flex flex-col h-full">
            <div className="p-4 overflow-y-auto border-b border-gray-200 min-h-[77px]">
              <SnippetsSearch />
            </div>
            <nav className="px-4 py-4 overflow-y-scroll">
              {snippetsConfig.map((item) => {
                if (item.name) {
                  return (
                    <SnippetsNavigationItem
                      key={item.key}
                      itemKey={item.key}
                      value={item.name}
                      selected={item.key === currentFolder}
                      onClick={() => handleSnippetFolderSelect(item.key)}
                      icon={item.icon}
                    />
                  );
                } else if (item.groupName) {
                  return (
                    <SnippetsNavigationGroupItem
                      value={item.groupName}
                      key={item.groupName}
                      isHidden={item.isHidden}
                    >
                      {item.items.map((groupItem) => (
                        <SnippetsNavigationItem
                          key={groupItem.key}
                          itemKey={groupItem.key}
                          value={groupItem.name}
                          selected={groupItem.key === currentFolder}
                          onClick={() =>
                            handleSnippetFolderSelect(groupItem.key)
                          }
                          amount={
                            getSnippetsByFolder(
                              groupItem.key,
                              snippets.filter((s) => !s.isDraft)
                            ).length
                          }
                        />
                      ))}
                    </SnippetsNavigationGroupItem>
                  );
                } else return null;
              })}
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};
