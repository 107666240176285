import { RootState } from '../../../app';

export const selectIsConnected = ({ gong }: RootState) => gong.isConnected;

export const selectIsConnecting = ({ gong }: RootState) => gong.isConnecting;

export const selectIsLoadingTranscripts = ({ gong }: RootState) =>
  gong.isLoadingTranscripts;

export const selectCallTranscripts = ({ gong }: RootState) =>
  gong.callTranscripts;

export const selectUniqueParties = ({ gong }: RootState) => gong.uniqueParties;

export const selectIsCreatingTeamSecrets = ({ gong }: RootState) =>
  gong.isCreatingTeamSecrets;

export const selectIsDeletingTeamSecrets = ({ gong }: RootState) =>
  gong.isDeletingTeamSecrets;
