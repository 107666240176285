import { useDispatch } from 'react-redux';
import { IconMap } from '../../../../../../../shared/sprite';
import { Button, Icon } from '../../../../../../../shared/ui';
import React, { useState } from 'react';
import {
  TEAM_SETTINGS_BRAND_ROUTE,
  redirectActions,
} from '../../../../../../../entities/history';

export const GENERATE_PAGE_TIP_VISIBILITY_STORAGE_KEY =
  'generatePageTipVisibility';

export const GeneratePageTipElement: React.FC = () => {
  const dispatch = useDispatch();

  const [isTipHidden, setTipHidden] = useState<boolean>(
    Boolean(localStorage.getItem(GENERATE_PAGE_TIP_VISIBILITY_STORAGE_KEY))
  );

  const closeTipElement = () => {
    localStorage.setItem(GENERATE_PAGE_TIP_VISIBILITY_STORAGE_KEY, 'true');
    setTipHidden(true);
  };

  const navigateToSettings = () => {
    dispatch(
      redirectActions.fromWorkspaceWithBackUrl({
        path: TEAM_SETTINGS_BRAND_ROUTE,
      })
    );
  };

  if (isTipHidden) {
    return null;
  }

  return (
    <div className="w-full p-5 bg-warning-50 border-2 border-warning-100 rounded-lg relative max1080:hidden">
      <div className="flex items-center gap-3 mb-3">
        <img
          src="../../../../../assets/images/lightbulb-02.svg"
          alt="Wizard write icon"
          width={20}
        />
        <p className="font-semibold text-gray-700">Tip</p>
      </div>
      <p className="text-sm text-gray-700 w-50 lg:w-full">
        If you wish to keep some recurring information about your company and
        its products and services for use by the AI, you can do so in Team
        Settings in{' '}
        <span
          className="cursor-pointer font-medium text-primary-700"
          onClick={navigateToSettings}
        >
          Company information
          <Icon
            glyph={IconMap.ArrowSquareUpRight}
            width={16}
            className="inline -mt-0.5 ml-px text-primary-600"
          />
        </span>{' '}
        section. The AI will consider this info with each page creation
        response.
      </p>
      <Button
        color="transparent"
        variant="icon"
        className="!absolute top-1 right-1"
        onClick={closeTipElement}
      >
        <Icon glyph={IconMap.XClose} width={20} className="text-gray-400" />
      </Button>
    </div>
  );
};
