import { actions } from '../reducer';
import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* changePageIcon({
  payload: { icon, pageId, callback },
}: ReturnType<typeof pagesModel.actions.changePageIcon>) {
  try {
    yield put(actions.setChangePageIconIsLoading(true));
    const page: Page = yield pagesApi.updatePage(pageId, { icon: icon });

    if (!page) {
      throw new Error('updatedPage is undefined');
    }
    yield put(pagesModel.actions.setCurrentPage(page));
    callback();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change page icon')
      )
    );
  } finally {
    yield put(actions.setChangePageIconIsLoading(false));
  }
}
