import { useCallback, useEffect, useRef, useState } from 'react';
import { useTiptapEditor } from '../../../lib';
import { AIStartedWritingFrom } from '../types';
type Props = {
  startedWritingFrom: AIStartedWritingFrom;
};

export type Selection = { from: number; to: number };

export const useEditorContextSelection = ({ startedWritingFrom }: Props) => {
  const [requestContext, setRequestContext] = useState('');

  const { editor } = useTiptapEditor();
  const caretStartPosition = useRef<number>(editor?.state.selection.from || 0);
  const caretEndPosition = useRef<number>(1);

  const setSelection = useCallback(({ from, to }: Partial<Selection>) => {
    if (from !== undefined) {
      caretStartPosition.current = from;
    }
    if (to !== undefined) {
      caretEndPosition.current = to;
    }
  }, []);

  useEffect(() => {
    if (!editor || startedWritingFrom === AIStartedWritingFrom.SCRATCH) {
      return;
    }

    const { state } = editor;

    const selectionStart =
      startedWritingFrom === AIStartedWritingFrom.SELECTION
        ? state.selection.from
        : 0;
    const selectionEnd = state.selection.to || 0;

    const { content, from, to } =
      editor.extensionManager.commands.getHtmlBetween(
        selectionStart,
        selectionEnd,
        startedWritingFrom === AIStartedWritingFrom.SELECTION
      );

    setRequestContext(content);
    if (startedWritingFrom === AIStartedWritingFrom.SELECTION) {
      setSelection({ from, to });
    } else {
      setSelection({ from: to, to });
    }
  }, [
    caretEndPosition,
    caretStartPosition,
    editor,
    setSelection,
    startedWritingFrom,
  ]);

  return {
    requestContext,
    setSelection,
    selectionFromRef: caretStartPosition,
    selectionToRef: caretEndPosition,
  };
};
