import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import {
  ActionTypeCTA,
  CalendarProvider,
  Page,
} from '@distribute/shared/types';
import { boolean, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Dropdown, ImageInput, Input } from '../../../../../shared/ui';
import { Label } from './Label';
import { CTA_TABS } from '../../../lib';
import { Icon } from '../../../../../shared/ui';
import cn from 'classnames';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';
import { CalendarForm } from './CalendarForm';
import { LINK_VERIFICATION_REGEX } from '../../../../../shared/constants';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
const BUTTON_LABEL_MAX_LENGTH = 40;

export type ActionBannerType = CalendarForm & {
  title: string;
  description: string;
  actionType: ActionTypeCTA;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType: string;
  buttonLabelLinkType: string;
  buttonLabelPopupType: string;
  buttonUrl: string;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const EditorSidebarCTAPanel: React.FC<Props> = ({ currentPage }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setCTAEdited(undefined));
  };

  const cta = useSelector(conversionKitModel.selectors.selectCTAWithError);
  const updateCTAIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateCTAIsLoading
  );
  const [activeTab, setActiveTab] = useState<string>(cta.actionType);
  const [description, setDescription] = useState<string>(cta.description);
  const [actionType, setActionType] = useState<ActionTypeCTA>(
    ActionTypeCTA.FORM
  );
  const [isImageEnabled, setImageEnabled] = useState(!!cta.imageUrl);
  const [isDescriptionEnabled, setDescriptionEnabled] = useState(
    !!cta.description
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      title: string().when([], {
        is: () => activeTab !== ActionTypeCTA.CALENDLY,
        then: string().max(TITLE_MAX_LENGTH).required(),
        otherwise: string().nullable(),
      }),
      isFormName: boolean(),
      isFormPhone: boolean(),
      isFormMessage: boolean(),
      buttonLabelFormType: string().when([], {
        is: () => activeTab === ActionTypeCTA.FORM,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonLabelLinkType: string().when([], {
        is: () => activeTab === ActionTypeCTA.LINK,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonLabelPopupType: string().when([], {
        is: () => activeTab === ActionTypeCTA.POPUP,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonUrl: string().when([], {
        is: () => activeTab === ActionTypeCTA.LINK,
        then: string().matches(LINK_VERIFICATION_REGEX).required(),
        otherwise: string().nullable(),
      }),
      calendarSchedulingLink: string().when(
        ['calendarProvider'],
        (calendarProvider) => {
          if (activeTab !== ActionTypeCTA.CALENDLY) {
            return string().nullable();
          }

          if (calendarProvider === CalendarProvider.CHILI_PIPER) {
            return string()
              .url()
              .matches(/^https:\/\/.+\.chilipiper\.com\/book\/.*/)
              .required();
          }

          if (calendarProvider === CalendarProvider.CALENDLY) {
            return string()
              .url()
              .matches(/^https:\/\/calendly\.com\/.*/)
              .required();
          }

          return string().url().required();
        }
      ),
    });
  }, [activeTab]);

  const isUploading = useSelector(
    conversionKitModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    conversionKitModel.selectors.selectUploadingCTAImageProgress
  );
  const isRemoving = useSelector(
    conversionKitModel.selectors.selectIsRemoveCTAImageLoading
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useForm<ActionBannerType>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title: cta.title,
        isFormName: cta.isFormName,
        isFormPhone: cta.isFormPhone,
        isFormMessage: cta.isFormMessage,
        buttonLabelFormType: cta.buttonLabelFormType,
        buttonLabelLinkType: cta.buttonLabelLinkType,
        buttonLabelPopupType: cta.buttonLabelPopupType,
        buttonUrl: cta.buttonUrl,
        calendarSchedulingLink: cta.calendarSchedulingLink ?? '',
        calendarProvider: cta.calendarProvider ?? CalendarProvider.CALENDLY,
        calendarIsShowCalendlyPageDetails:
          cta.calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner:
          cta.calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor: cta.calendarCalendlyTextColor,
        calendarCalendlyBgColor: cta.calendarCalendlyBgColor,
        calendarCalendlyButtonColor: cta.calendarCalendlyButtonColor,
      }),
      [cta]
    ),
  });

  const {
    title,
    buttonLabelFormType,
    buttonLabelPopupType,
    buttonLabelLinkType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
  } = watch();

  const {
    field: { value: fullNameValue, onChange: onChangeFullName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const {
    field: { value: phoneValue, onChange: onChangePhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const {
    field: { value: messageValue, onChange: onChangeMessage },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormMessage',
  });

  const {
    field: { onChange: changeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const {
    field: { onChange: changeButtonLabelPopupType },
  } = useController({
    control,
    name: 'buttonLabelPopupType',
  });

  const handleButtonLabelChange = (value: string) => {
    changeButtonLabelFormType(value);
    changeButtonLabelLinkType(value);
    changeButtonLabelPopupType(value);
  };

  useEffect(() => {
    switch (activeTab) {
      case ActionTypeCTA.FORM:
        setActionType(ActionTypeCTA.FORM);
        break;
      case ActionTypeCTA.LINK:
        setActionType(ActionTypeCTA.LINK);
        break;
      case ActionTypeCTA.POPUP:
        setActionType(ActionTypeCTA.POPUP);
        break;
      case ActionTypeCTA.CALENDLY:
        setActionType(ActionTypeCTA.CALENDLY);
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setCTAEdited({
        ...cta,
        title,
        description: isDescriptionEnabled ? description : '',
        imageUrl: isImageEnabled ? cta.imageUrl : undefined,
        actionType,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonLabelPopupType,
        buttonUrl,
        calendarProvider,
        calendarSchedulingLink,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyButtonColor,
        calendarCalendlyBgColor,
        isFormName: fullNameValue,
        isFormPhone: phoneValue,
        isFormMessage: messageValue,
      })
    );
  }, [
    title,
    description,
    actionType,
    fullNameValue,
    phoneValue,
    messageValue,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonLabelPopupType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
    cta,
    isDescriptionEnabled,
    isImageEnabled,
    dispatch,
  ]);

  const onChangeTab = (tab: string) => {
    clearErrors();
    setActiveTab(tab);
  };

  const handleFormSubmit = (data: ActionBannerType) => {
    dispatch(
      conversionKitModel.actions.updateCTA({
        documentContentId: currentPage.content.id,
        isRemoveImage: !isImageEnabled && !!cta.imageUrl,
        ...data,
        actionType,
        description: isDescriptionEnabled ? description : '',
        isActive: true,
        buttonLabelFormType: data.buttonLabelFormType || 'Submit',
        buttonLabelLinkType: data.buttonLabelLinkType || 'Submit',
        buttonLabelPopupType: data.buttonLabelPopupType || 'Submit',
        calendarSchedulingLink:
          activeTab === ActionTypeCTA.CALENDLY
            ? data.calendarSchedulingLink
            : undefined,
        callback: handleClose,
      })
    );
  };

  const onUpdateImage = (image: File) => {
    dispatch(
      conversionKitModel.actions.changeCTAImage({
        documentContentId: currentPage.content.id,
        image,
      })
    );
  };

  const onRemoveImage = () => {
    dispatch(
      conversionKitModel.actions.removeCTAImage({
        documentContentId: currentPage.content.id,
      })
    );
  };

  return (
    <>
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          CTA Section
        </h2>
      </header>
      <div className={cn('overflow-y-auto h-[calc(100%-192px)]')}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            <p className="text-gray-800 font-semibold mb-4">Type</p>

            <Dropdown
              items={CTA_TABS}
              isModalMode={false}
              align="start"
              listStyles="!w-[var(--radix-dropdown-menu-trigger-width)]"
              triggerStyles="bg-base-white"
              currentItemId={activeTab}
              onItemChange={onChangeTab}
            />

            {activeTab === ActionTypeCTA.CALENDLY && (
              <CalendarForm
                source="cta"
                control={control}
                register={register}
                setValue={setValue}
                errors={errors}
              />
            )}
            {activeTab !== ActionTypeCTA.CALENDLY && (
              <>
                <hr className="border-gray-200 my-6" />

                <p className="text-gray-800 font-semibold mb-4">Components</p>

                <LabelToggle
                  icon={IconMap.Image01}
                  text="Image"
                  checked={isImageEnabled}
                  onChange={() => setImageEnabled((prev) => !prev)}
                  className="mb-3"
                />
                {isImageEnabled && (
                  <ImageInput
                    imageUrl={cta.imageUrl}
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    isRemoving={isRemoving}
                    onUpdateImage={onUpdateImage}
                    onRemoveImage={onRemoveImage}
                    isLabel={false}
                    fileInputStyles="h-31"
                    className="mb-6"
                  />
                )}
                <LabelToggle
                  icon={IconMap.Type01}
                  text="Title"
                  isRequired
                  checked={true}
                  onChange={() => null}
                  className="mb-3 mt-6"
                  disabled
                />
                <Input
                  {...register('title')}
                  className="p-3"
                  heightSize="md"
                  type="text"
                  placeholder="Add title"
                  maxCharacters={TITLE_MAX_LENGTH}
                  isError={!!errors.title}
                  value={title}
                />
                <LabelToggle
                  icon={IconMap.AlignLeft}
                  text="Description"
                  checked={isDescriptionEnabled}
                  onChange={() => setDescriptionEnabled((prev) => !prev)}
                  className="mb-3 mt-6"
                />
                {isDescriptionEnabled && (
                  <RichTextEditor
                    content={description}
                    placeholder="Add text…"
                    onUpdate={(content) => setDescription(content)}
                    maxLength={DESCRIPTION_MAX_LENGTH}
                  />
                )}
                <hr className="border-gray-200 my-6" />
                {activeTab === ActionTypeCTA.FORM && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">
                      Form Fields
                    </p>
                    <LabelToggle
                      icon={IconMap.User03}
                      text="Name"
                      checked={fullNameValue}
                      onChange={onChangeFullName}
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.EmailSymbol}
                      text="Email"
                      checked={true}
                      onChange={() => null}
                      disabled
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.Phone}
                      text="Phone"
                      checked={phoneValue}
                      onChange={onChangePhone}
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.MessageDotsCircle}
                      text="Message"
                      checked={messageValue}
                      onChange={onChangeMessage}
                    />
                    <hr className="border-gray-200 my-6" />
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelFormType')}
                      isError={!!errors.buttonLabelFormType}
                      value={buttonLabelFormType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
                {activeTab === ActionTypeCTA.LINK && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelLinkType')}
                      isError={!!errors.buttonLabelLinkType}
                      value={buttonLabelLinkType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                    <Label
                      icon={IconMap.Link}
                      text="URL"
                      className="mt-4 mb-3"
                      isRequired
                    />
                    <Input
                      className="p-3"
                      type="text"
                      placeholder="e.g. https://example.com/link"
                      {...register('buttonUrl')}
                      isError={!!errors.buttonUrl}
                      heightSize="md"
                    />
                  </>
                )}
                {activeTab === ActionTypeCTA.POPUP && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelPopupType')}
                      isError={!!errors.buttonLabelPopupType}
                      value={buttonLabelPopupType}
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
          <EditorSidebarConversionsPanelFooter
            onClose={handleClose}
            addBtnTitle={cta.buttonLabelFormType === null ? 'Add' : 'Save'}
            isAddBtnLoading={updateCTAIsLoading}
          ></EditorSidebarConversionsPanelFooter>
        </form>
      </div>
    </>
  );
};
