import { useDispatch, useSelector } from 'react-redux';
import { customDomainsModel } from '../../model';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Button, Icon, Input, Tooltip } from '../../../../shared/ui';
import { CustomDomainVerificationStatus } from '@distribute/shared/types';
import { IconMap } from '../../../../shared/sprite';
import { DomainStatusBadge } from './DomainStatusBadge';
import { useCurrentPlan } from '../../../subscription/hooks';
import { useEffect } from 'react';

type Schema = { customDomain: string };

const inputSchema = object().shape({
  customDomain: string().required(
    "Please input the domain in the appropriate format, such as 'subdomain.yourdomain.com'."
  ),
});

export const CreateCustomDomainSection: React.FC = () => {
  const dispatch = useDispatch();

  const customDomainInfo = useSelector(
    customDomainsModel.selectors.selectCustomDomainInfo
  );
  const isCreatingDomain = useSelector(
    customDomainsModel.selectors.selectCustomDomainIsCreating
  );
  const isDomainVerified =
    customDomainInfo &&
    customDomainInfo.status === CustomDomainVerificationStatus.DNS_RECORD_FOUND;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Schema>({
    resolver: yupResolver(inputSchema),
    defaultValues: {
      customDomain: customDomainInfo?.customDomain ?? '',
    },
  });

  const domainValue = watch('customDomain');

  const currentPlan = useCurrentPlan();

  const handleCreateCustomDomain = handleSubmit(({ customDomain }) => {
    dispatch(customDomainsModel.actions.createCustomDomain(customDomain));
  });

  const handleDeleteCustomDomainConfirmationModalOpen = () => {
    dispatch(customDomainsModel.actions.setDeleteConfirmationModalOpen(true));
  };

  useEffect(() => {
    setValue('customDomain', customDomainInfo?.customDomain ?? '');
  }, [customDomainInfo?.customDomain, setValue]);

  return (
    <form className="flex flex-col gap-5" onSubmit={handleCreateCustomDomain}>
      <p className="text-sm text-gray-700">
        Use your domain when sharing Distribute pages.
      </p>
      <div className="flex items-center gap-4 justify-start">
        <div className="w-107 relative">
          <Input
            type="text"
            placeholder="subdomain.yourdomain.com"
            heightSize="md"
            isError={!!errors.customDomain}
            messageText={errors.customDomain?.message}
            disabled={!!customDomainInfo || currentPlan.id === 'free'}
            {...register('customDomain')}
          />
          {isDomainVerified && (
            <div className="absolute w-5 h-5 top-2.5 right-2.5 cursor-pointer">
              <Tooltip
                trigger={
                  <Icon
                    glyph={IconMap.LinkBroken}
                    className="text-error-600 "
                    width={20}
                    onClick={handleDeleteCustomDomainConfirmationModalOpen}
                  />
                }
                hideArrow
                sideOffset={8}
              >
                <p className="px-1 py-1 font-semibold text-xs">Disconnect</p>
              </Tooltip>
            </div>
          )}
        </div>
        {isDomainVerified && <DomainStatusBadge type="connected" />}
      </div>
      {!customDomainInfo && (
        <Button
          type="submit"
          variant="text"
          color="primary"
          className="w-31"
          loading={isCreatingDomain}
          disabled={!domainValue || currentPlan.id === 'free'}
        >
          Add domain
        </Button>
      )}
    </form>
  );
};
