import { Node } from '@tiptap/react';

export const IframeExt = Node.create({
  name: 'iframe',
  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      height: {
        default: '100%',
      },
      width: {
        default: '100%',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ node }) {
    return ['iframe', node.attrs];
  },
});
