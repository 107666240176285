import React, { ForwardedRef, Fragment } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

type Size = 'md' | 'lg';

type ToggleProps = {
  checked: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
  ref?: ForwardedRef<HTMLButtonElement>;
  size?: Size;
  isUseGeneralBgForAllStatuses?: boolean;
  disabledWithBg?: boolean;
};

const sizesByElement: Record<'root' | 'circle', Record<Size, string>> = {
  root: {
    md: 'h-5 w-9 min-w-9',
    lg: 'h-6 w-11',
  },
  circle: {
    md: 'h-4 w-4',
    lg: 'h-5 w-5',
  },
};

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  disabled,
  onChange,
  ref,
  size = 'md',
  isUseGeneralBgForAllStatuses = false,
  disabledWithBg = false,
}: ToggleProps) => {
  return (
    <Switch checked={checked} onChange={onChange} as={Fragment}>
      {({ checked }) => (
        <button
          ref={ref}
          className={classNames(
            'inline-flex border-none items-center outline-none rounded-full px-0.5',
            sizesByElement.root[size],
            {
              'bg-primary-600 hover:bg-primary-700 justify-end': checked,
              'bg-gray-200 hover:bg-gray-300  justify-start':
                !checked && !isUseGeneralBgForAllStatuses,
              'bg-primary-600 justify-start':
                !checked && isUseGeneralBgForAllStatuses,
              '!bg-gray-300 cursor-default':
                disabled && (checked || disabledWithBg),
              '!bg-gray-200 cursor-default':
                disabled && !checked && !disabledWithBg,
            }
          )}
          disabled={disabled}
        >
          <span
            className={classNames(
              'bg-base-white inline-block rounded-full bg-white',
              { 'bg-gray-100': disabled },
              sizesByElement.circle[size]
            )}
          />
        </button>
      )}
    </Switch>
  );
};
