import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';

import { getEmbedUrlFromVimeoUrl, VIMEO_REGEX_GLOBAL } from './utils';

type SetVimeoVideoOptions = {
  src: string;
  width?: number;
  height?: number;
  start?: number;
};

export const VimeoExt = Node.create({
  name: 'vimeo',
  group: 'media',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      start: {
        default: 0,
      },
      width: {
        default: this.options?.width,
      },
      height: {
        default: this.options?.height,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-vimeo-video] iframe',
      },
    ];
  },

  addPasteRules() {
    if (!this.options?.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: VIMEO_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.groups ? match.groups[0] : match.input };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const embedUrl = getEmbedUrlFromVimeoUrl({
      url: HTMLAttributes.src,
    });

    HTMLAttributes.src = embedUrl;

    return [
      'div',
      { 'data-vimeo-video': '' },
      ['iframe', mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes)],
    ];
  },
});
