import { RootState } from '../../../app';

export const selectIsOpen = ({ createPageModal }: RootState) =>
  createPageModal.isOpen;

export const selectCurrentStep = ({ createPageModal }: RootState) =>
  createPageModal.currentStep;

export const selectPreviousStepsStack = ({ createPageModal }: RootState) =>
  createPageModal.previousStepsStack;

export const selectCreationsWorkflow = ({ createPageModal }: RootState) =>
  createPageModal.creationWorkflow;

export const selectBrandingCompanies = ({ createPageModal }: RootState) =>
  createPageModal.brandingCompanies;

export const selectCompaniesSearchIsLoading = ({
  createPageModal,
}: RootState) => createPageModal.companiesSearchIsLoading;

export const selectSelectedBrandingCompany = ({ createPageModal }: RootState) =>
  createPageModal.selectedBrandingCompany;

export const selectCompaniesSearchQuery = ({ createPageModal }: RootState) =>
  createPageModal.companiesSearchQuery;

export const selectPageType = ({ createPageModal }: RootState) =>
  createPageModal.pageType;

export const selectGongCallTranscriptId = ({ createPageModal }: RootState) =>
  createPageModal.gongCallTranscriptId;
