import {
  GetCTAResponseType,
  UpdateCTARequestType,
  UpdateCTAResponseType,
} from '@distribute/shared/api-types/cta';
import { getUploadProgress } from '../../lib';
import { api } from './instance';

export const ctaApi = {
  async getCTA(documentContentId: number) {
    const { data } = await api.get<GetCTAResponseType>(
      `cta/${documentContentId}`
    );

    return data;
  },

  async updateCTA(documentContentId: number, data: UpdateCTARequestType) {
    const { data: responseData } = await api.patch<UpdateCTAResponseType>(
      `cta/${documentContentId}`,
      data
    );
    return responseData;
  },

  async updateCTAImage(
    documentContentId: number,
    image: File,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put(
      `cta/${documentContentId}/image-url`,
      { image },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );

    return responseData;
  },

  async removeCTAImage(documentContentId: number) {
    const { data } = await api.delete(`cta/${documentContentId}/image-url`);
    return data;
  },
};
