import { Dialog } from '@headlessui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../shared/ui';
import { confirmNavigation } from '../model/reducer';
import { navigationModel } from '../model';

export const NavigationModal = () => {
  const isOpened = useSelector(navigationModel.selectors.selectIsModalOpened);
  const dispatch = useDispatch();
  const onStay = () => dispatch(confirmNavigation(false));
  const onLeave = () => dispatch(confirmNavigation(true));

  return (
    <Dialog className="relative z-100 w-full" open={isOpened} onClose={onStay}>
      <div className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200">
        <Dialog.Panel className="sm:w-full w-100 p-6 shadow-xl rounded-xl bg-base-white">
          <Dialog.Title className="text-gray-900 text-lg font-semibold mb-5 w-full">
            Leave page?
          </Dialog.Title>
          <p className="text-gray-600 text-sm">
            You have unsaved changes on the page. Are you sure you want to leave
            the page?
          </p>
          <div className="flex pt-8 gap-3">
            <Button
              fullWidth
              color="secondary"
              variant="text"
              type="button"
              onClick={onLeave}
            >
              Leave
            </Button>
            <Button fullWidth variant="text" color="primary" onClick={onStay}>
              Stay
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
