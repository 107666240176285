import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RecordsTabs } from './RecordsTabs';
import { RecordFirstVideo } from './RecordFirstVideo';
import { RecordRenameModal } from './RecordRenameModal';
import { RecordDeleteModal } from './RecordDeleteModal';
import { RecordShareModal } from './record-share-modal';

import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

import { recordingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';
import { extensionModel } from '../../../extension';

export const Records: FC = () => {
  const dispatch = useDispatch();

  const isExtensionEventLoading = useSelector(
    extensionModel.selectors.selectIsExtensionEventLoading
  );

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const recordIdToEdit = useSelector(
    recordingsModel.selectors.selectRecordIdToEdit
  );
  const recordIdToDelete = useSelector(
    recordingsModel.selectors.selectRecordIdToDelete
  );
  const recordIdToShare = useSelector(
    recordingsModel.selectors.selectRecordIdToShare
  );

  const hasRecords = useSelector(
    recordingsModel.selectors.selectHasRecords(currentUser.id)
  );
  const hasRecordsMy = useSelector(
    recordingsModel.selectors.selectHasRecordsMy(currentUser.id)
  );

  useEffect(
    () => () => {
      dispatch(recordingsModel.actions.resetFilters());
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      {recordIdToDelete && <RecordDeleteModal />}
      {recordIdToEdit && <RecordRenameModal />}
      {recordIdToShare && <RecordShareModal />}
      <div className="flex flex-col flex-grow-1 sm:gap-y-6 gap-y-8">
        <div className="flex items-center justify-between gap-4">
          <h1 className="font-medium text-gray-900 text-display-sm font-display">
            Quick Recordings
          </h1>
          {hasRecordsMy && (
            <Button
              size="md"
              variant="text"
              color="primary"
              loading={isExtensionEventLoading}
              disabled={isExtensionEventLoading}
              onClick={() => dispatch(extensionModel.actions.openClick())}
              className="sm:hidden"
            >
              <Icon glyph={IconMap.VideoRecorder} width={20} className="mr-2" />
              New Recording
            </Button>
          )}
        </div>
        {hasRecords ? <RecordsTabs /> : <RecordFirstVideo />}
      </div>
    </>
  );
};
