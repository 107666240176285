import { Page } from '@distribute/shared/types';
import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';

export function* updatePage({
  payload: { pageId, ...data },
}: ReturnType<typeof pagesModel.actions.updatePage>) {
  try {
    const page: Page = yield pagesApi.updatePage(pageId, data);

    yield put(
      pagesModel.actions.setCurrentPage({
        ...page,
        ...data,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update page')
      )
    );
  }
}
