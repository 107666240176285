import { put } from 'redux-saga/effects';
import { viewersModel } from '../';

export function* onChangeIsShowAnonymous({
  payload: isShowAnonymous,
}: ReturnType<typeof viewersModel.actions.onChangeIsShowAnonymous>) {
  yield put(viewersModel.actions.setIsShowAnonymous(isShowAnonymous));
  yield put(viewersModel.actions.setDetails({}));
  yield put(viewersModel.actions.getViewersData({}));
}
