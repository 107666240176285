import { put, fork } from 'redux-saga/effects';

import { authChannelModel } from '../../../entities/auth-channel';

import { auth } from '../../../shared/config';
import { globalActions } from '../../../app/model/actions';
import { extensionModel } from '../../extension';

export function* signOut() {
  yield fork(extensionModel.sagas.extensionLogout);
  yield auth.signOut();

  yield put(globalActions.resetStateAll());
  yield put(
    authChannelModel.chan,
    authChannelModel.loggedOut({ redirectTo: 'login' })
  );
}
