import { RootState } from '../../../app';
import { selectWithError } from '../../../shared/lib';

export const selectAlertBar = ({ conversionKit }: RootState) =>
  conversionKit.alertBar;

export const selectAlertBarWithError = selectWithError(
  selectAlertBar,
  'alertBar'
);

export const selectAlertBarEdited = ({ conversionKit }: RootState) =>
  conversionKit.alertBarEdited;

export const selectCTA = ({ conversionKit }: RootState) => conversionKit.cta;

export const selectCTAEdited = ({ conversionKit }: RootState) =>
  conversionKit.ctaEdited;

export const selectCTAWithError = selectWithError(selectCTA, 'cta');

export const selectPopUp = ({ conversionKit }: RootState) =>
  conversionKit.popUp;

export const selectPopUpEdited = ({ conversionKit }: RootState) =>
  conversionKit.popUpEdited;

export const selectPopUpWithError = selectWithError(selectPopUp, 'popUp');

export const selectGatedContent = ({ conversionKit }: RootState) =>
  conversionKit.gatedContent;

export const selectGatedContentEdited = ({ conversionKit }: RootState) =>
  conversionKit.gatedContentEdited;

export const selectGatedContentWithError = selectWithError(
  selectGatedContent,
  'gatedContent'
);

export const selectUpdateAlertBarIsLoading = ({ conversionKit }: RootState) =>
  conversionKit.updateAlertBarIsLoading;

export const selectUpdateCTAIsLoading = ({ conversionKit }: RootState) =>
  conversionKit.updateCTAIsLoading;

export const selectUpdatePopUpIsLoading = ({ conversionKit }: RootState) =>
  conversionKit.updatePopUpIsLoading;

export const selectIsUploadImageLoading = ({ conversionKit }: RootState) =>
  conversionKit.isUploadImageLoading;

export const selectUploadingCTAImageProgress = ({ conversionKit }: RootState) =>
  conversionKit.uploadingCTAImageProgress;

export const selectIsRemoveCTAImageLoading = ({ conversionKit }: RootState) =>
  conversionKit.isRemoveCTAImageLoading;

export const selectUploadingPopUpImageProgress = ({
  conversionKit,
}: RootState) => conversionKit.uploadingPopUpImageProgress;

export const selectIsRemovePopUpImageLoading = ({ conversionKit }: RootState) =>
  conversionKit.isRemovePopUpImageLoading;

export const selectSqueezePage = ({ conversionKit }: RootState) =>
  conversionKit.squeezePage;

export const selectSqueezePageEdited = ({ conversionKit }: RootState) =>
  conversionKit.squeezePageEdited;

export const selectSqueezePageWithError = selectWithError(
  selectSqueezePage,
  'squeezePage'
);

export const selectUpdateSqueezePageIsLoading = ({
  conversionKit,
}: RootState) => conversionKit.updateSqueezePageIsLoading;

export const selectUploadingSqueezePageImageProgress = ({
  conversionKit,
}: RootState) => conversionKit.uploadingSqueezePageImageProgress;

export const selectIsRemoveSqueezePageImageLoading = ({
  conversionKit,
}: RootState) => conversionKit.isRemoveSqueezePageImageLoading;

export const selectRequireEmailToView = ({ conversionKit }: RootState) =>
  conversionKit.requireEmailToView;

export const selectUpdateRequireEmailToViewIsLoading = ({
  conversionKit,
}: RootState) => conversionKit.updateRequireEmailToViewIsLoading;
