import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import { Button, Checkbox, Input } from '../../../../../shared/ui';
import {
  cancelSubscriptionReasons,
  CANCEL_SUBSCRIPTION_OTHER_REASON_ID,
} from '../../../config';
import { subscriptionModel } from '../../../model';

type IProps = {
  onSubmit: (data: CancelSubscriptionForm) => void;
};

export type CancelSubscriptionForm = {
  reasons: string[];
  otherReasonText?: string;
};

const validationSchema = object().shape({
  reasons: array(string()).min(1),
  otherReasonText: string().when('reasons', {
    is: (val: string[]) => val.includes(CANCEL_SUBSCRIPTION_OTHER_REASON_ID),
    then: string().required('Please input your reason.'),
  }),
});

export const Form: FC<IProps> = ({ onSubmit }) => {
  const isCancelingSubscription = useSelector(
    subscriptionModel.selectors.selectIsCancelingSubscription
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CancelSubscriptionForm>({
    defaultValues: { reasons: [] },
    resolver: yupResolver(validationSchema),
  });
  const reasonsValue = useWatch({ control, name: 'reasons' });

  return (
    <>
      <div className="text-sm text-gray-600">
        Your feedback is important to us, as it helps us improve Distribute.
      </div>
      <form className="mt-5">
        <Controller
          control={control}
          name="reasons"
          render={({ field: { onChange, value } }) => (
            <>
              {cancelSubscriptionReasons.map((reason) => (
                <div className="mt-2" key={reason.id}>
                  <Checkbox
                    checked={value.includes(reason.id)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...value, e.target.value]
                        : value.filter((item) => item !== e.target.value);
                      onChange(newValue);
                    }}
                    value={reason.id}
                  >
                    <span className="ml-2 text-sm text-gray-700">
                      {reason.name}
                    </span>
                  </Checkbox>
                </div>
              ))}
            </>
          )}
        />
        {reasonsValue.includes(CANCEL_SUBSCRIPTION_OTHER_REASON_ID) && (
          <Input
            {...register('otherReasonText', { shouldUnregister: false })}
            type="text"
            isError={!!errors.otherReasonText}
            messageText={errors.otherReasonText?.message}
            className="mt-2"
          />
        )}
        <Button
          fullWidth
          type="submit"
          disabled={!reasonsValue.length || isCancelingSubscription}
          loading={isCancelingSubscription}
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="text"
          className="mt-8"
        >
          Submit and cancel my Plan
        </Button>
      </form>
    </>
  );
};
