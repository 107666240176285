import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../../../features/snippets';
import { Modal } from '../../../../shared/ui';
import { SnippetsNavigation } from './SnippetsNavigation';
import { SnippetsModalHeader } from './SnippetsModalHeader';
import { MediaSnippetJSON, SnippetType } from '@distribute/shared/types';
import { JSONContent } from '@tiptap/react';
import { SnippetItem } from '../../../../features/snippets/ui/components';
import { NoSnippetsFound } from '../../../../pages/snippets/ui/NoSnippetsFound';
import {
  SnippetsConfigBasicEnum,
  typeFilters,
  SnippetsSwitcherEnum,
} from './lib/config';
import { noFoundMessages } from '../../../../features/snippets/ui/components/lib/config';
import { useSnippetsPermissions } from '../../../../features/teams';
import { contains } from '../../../../utils/string';

type Props = {
  onClose: () => void;
  onSelect: (
    id: string,
    type: SnippetType,
    content: JSONContent | MediaSnippetJSON
  ) => void;
  offsetWidth: number;
};

export const SnippetsModal: FC<Props> = ({
  onClose,
  onSelect,
  offsetWidth,
}) => {
  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();
  const dispatch = useDispatch();

  const snippets = useSelector(snippetsModel.selectors.selectAvailableSnippets);

  const currentFolder = useSelector(
    snippetsModel.selectors.selectCurrentModalFolder
  );
  const personalSnippets = useSelector(
    snippetsModel.selectors.selectPersonalSnippets
  );

  const searchQuery = useSelector(
    snippetsModel.selectors.selectModalSearchQuery
  );

  const readySnippets = useMemo(() => {
    return snippets.filter((s) => !s.isDraft);
  }, [snippets]);

  const [sortMethod, setSortMethod] = useState<SnippetsSwitcherEnum>(
    SnippetsSwitcherEnum.NEW
  );

  const sortedSnippets = useMemo(() => {
    if (sortMethod === SnippetsSwitcherEnum.POPULAR) {
      return [...readySnippets].sort((a, b) => b.usedCount - a.usedCount);
    }

    if (sortMethod === SnippetsSwitcherEnum.NEW) {
      return [...readySnippets].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }

    return [...readySnippets];
  }, [sortMethod, readySnippets]);

  const snippetsByFolder = useMemo(() => {
    switch (currentFolder) {
      case SnippetsConfigBasicEnum.ALL:
        return sortedSnippets;
      case SnippetsConfigBasicEnum.PERSONAL:
        return personalSnippets;
      default:
        return readySnippets.filter((s) => s.category?.id === currentFolder);
    }
  }, [currentFolder, personalSnippets, readySnippets, sortedSnippets]);

  const [currentTypeFilter, setCurrentTypeFilter] = useState(
    typeFilters[0].value
  );

  const filteredSnippets = useMemo(() => {
    return currentTypeFilter
      ? snippetsByFolder.filter((s) => s.type === currentTypeFilter)
      : snippetsByFolder.filter((s) => contains(s.name, searchQuery));
  }, [currentTypeFilter, snippetsByFolder, searchQuery]);

  useEffect(() => {
    if (searchQuery && currentTypeFilter !== typeFilters[0].value) {
      setCurrentTypeFilter(typeFilters[0].value);
    }
  }, [searchQuery, currentTypeFilter]);

  useEffect(() => {
    setCurrentTypeFilter(typeFilters[0].value);
    if (currentFolder !== SnippetsConfigBasicEnum.ALL) {
      dispatch(snippetsModel.actions.setModalSearchQuery(''));
    }
  }, [currentFolder, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(snippetsModel.actions.setModalSearchQuery(''));
    };
  }, [dispatch]);

  const noSnippetsFoundMessage = useMemo(() => {
    if (currentFolder === SnippetsConfigBasicEnum.PERSONAL) {
      return noFoundMessages.setupDraftSnippets;
    }

    if (isCanCreatePersonalSnippets || isCanManageSnippets) {
      return noFoundMessages.filterSnippetsAndAllowCreation;
    } else {
      return noFoundMessages.filterSnippets;
    }
  }, [currentFolder, isCanCreatePersonalSnippets, isCanManageSnippets]);

  const existingTypeFilters = useMemo(() => {
    return typeFilters.filter(
      (filter) =>
        filter.name === 'All' ||
        snippetsByFolder.some((snippet) => snippet.type === filter.value)
    );
  }, [snippetsByFolder]);

  const isFiltersVisible = useMemo(() => {
    return snippetsByFolder.some((snippet, i) => {
      return i === snippetsByFolder.length - 1
        ? snippet.type !== snippetsByFolder[0].type
        : snippet.type !== snippetsByFolder[i + 1].type;
    });
  }, [snippetsByFolder]);

  return (
    <Modal
      isOpen={true}
      isShowCancelButton={false}
      clearPadding
      onClose={onClose}
      className="w-320 h-[min(720px,100vh-40px)] flex flex-col md:w-182 md:min-w-182"
    >
      <div className="flex flex-row overflow-hidden grow-1">
        <SnippetsNavigation />
        <div className="flex flex-col flex-grow">
          <SnippetsModalHeader
            onClose={onClose}
            sortMethod={sortMethod}
            setSortMethod={setSortMethod}
          />
          {snippetsByFolder.length > 0 && !searchQuery && isFiltersVisible && (
            <div className="flex gap-3 px-8 mb-4">
              {existingTypeFilters.map((filter) => (
                <button
                  className={cn(
                    'w-18 h-9 border rounded-full flex justify-center items-center text-sm font-medium',
                    {
                      'text-gray-900 border-gray-400 bg-gray-200':
                        filter.value === currentTypeFilter,
                      'text-gray-700 border-gray-300 bg-base-white':
                        filter.value !== currentTypeFilter,
                    }
                  )}
                  onClick={() => {
                    setCurrentTypeFilter(filter.value);
                  }}
                >
                  {filter.name}
                </button>
              ))}
            </div>
          )}
          {filteredSnippets.length > 0 ? (
            <div className="flex flex-wrap flex-grow gap-6 px-8 pt-2 pb-4 overflow-x-hidden overflow-y-auto 2xl:grid 2xl:grid-cols-3 max1280:grid-cols-2 sm:grid-cols-1 place-content-start">
              {filteredSnippets.map((snippet) => (
                <SnippetItem
                  key={snippet.id}
                  onSelect={onSelect}
                  snippet={snippet}
                  offsetWidth={offsetWidth}
                />
              ))}
            </div>
          ) : (
            <NoSnippetsFound message={noSnippetsFoundMessage} isSnippetsModal />
          )}
        </div>
      </div>
    </Modal>
  );
};
