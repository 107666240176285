import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../..';

export function* onUpdateCurrentTeam(updatedCurrentTeam: TeamFiltered) {
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);
  const updatedTeams = teams.map((team) =>
    team.id === updatedCurrentTeam.id ? updatedCurrentTeam : team
  );

  yield put(teamsModel.actions.setCurrentTeam(updatedCurrentTeam));
  yield put(teamsModel.actions.setTeams(updatedTeams));
}

export function* onDeleteTeam(teamId: number) {
  const teams: TeamFiltered[] = yield select(teamsModel.selectors.selectTeams);
  const updatedTeams = teams.filter((i) => i.id !== teamId);
  const currentTeam = updatedTeams[0];

  yield put(teamsModel.actions.setCurrentTeam(currentTeam));
  yield put(teamsModel.actions.setTeams(updatedTeams));

  if (!currentTeam) {
    yield call(teamsModel.sagas.handleWhenUserHasNoTeams, false);
  } else {
    yield call(teamsModel.sagas.loadTeamRelatedData);
  }
}
