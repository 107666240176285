import { call, put, select } from 'redux-saga/effects';
import { conversionKitModel } from '..';
import { actions } from '../reducer';
import { requireEmailToViewApi } from '../../../../shared/api';
import { RequireEmailToView, TeamFiltered } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { UpdateRequireEmailToViewActionTypes } from '../types';
import { teamsModel } from '../../../teams';

const errorMessages = {
  [UpdateRequireEmailToViewActionTypes.Activation]:
    'Failed to update Require email to view',
  [UpdateRequireEmailToViewActionTypes.Authentication]:
    'Failed to update Email Authentication',
  [UpdateRequireEmailToViewActionTypes.Restriction]:
    'Failed to update Restrict Access',
};

export function* updateRequireEmailToView({
  payload: { type, documentContentId, callback, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updateRequireEmailToView>) {
  const hasCallback = typeof callback === 'function';
  try {
    yield put(actions.setUpdateRequireEmailToViewIsLoading(true));
    const { id }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const requireEmailToView: RequireEmailToView = yield call(
      requireEmailToViewApi.updateRequireEmailToView,
      {
        type,
        documentContentId,
        data: rest,
        teamId: id,
      }
    );

    yield put(actions.setRequireEmailToView(requireEmailToView));

    if (hasCallback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', errorMessages[type])
      )
    );
    if (hasCallback) {
      yield call(callback, e as Error);
    }
  } finally {
    yield put(actions.setUpdateRequireEmailToViewIsLoading(false));
  }
}
