import { useSelector } from 'react-redux';
import { teamsModel } from '../../teams';

export const useSubscriptionTeamMembers = () => {
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  return currentTeamMembers.filter((i) => !i.isWaitingForInviteAcceptance);
};
