import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowMessage } from '../../hooks/useWindowMessage';
import { Message } from '../../constants/messages';
import { TemplateSize } from '../types';
import { TemplatesConfigEnum } from '../../../../features/templates';
import { Device } from '../../types';

export const useIframeCommunication = ({
  items,
  size,
  onCategoryChange,
  onSearch,
}: {
  items: TemplateExtended[];
  size: TemplateSize;
  onSearch: (search: string) => void;
  onCategoryChange: (category: TemplatesConfigEnum) => void;
}) => {
  const [device, setDevice] = useState<Device | undefined>();
  const { colGap, width, rowGap, height } = size;
  const parentIframeWidth = useRef<number>(0);

  const handleRecalculateSize = useCallback(() => {
    const amountInRow = Math.floor(
      (parentIframeWidth.current + colGap) / (width + colGap)
    );

    const rowsAmount = Math.ceil(items.length / amountInRow) ?? 1;
    const calculatedHeight = rowsAmount * height + (rowsAmount - 1) * rowGap;

    return {
      width: '100%',
      height: calculatedHeight ? calculatedHeight : null,
    };
  }, [colGap, height, items.length, rowGap, width]);

  const { send } = useWindowMessage(
    [
      Message.TemplatesChangeParentSize,
      Message.TemplatesMenuSelect,
      Message.TemplatesDevice,
      Message.TemplatesMenuSearch,
    ],
    (data, send) => {
      switch (data.type) {
        case Message.TemplatesDevice:
          setDevice(
            (
              data.payload as {
                device: Device;
              }
            ).device
          );
          break;
        case Message.TemplatesChangeParentSize:
          parentIframeWidth.current = (
            data.payload as {
              iframeWidth: number;
            }
          ).iframeWidth;
          send(Message.TemplatesResize, handleRecalculateSize());
          break;
        case Message.TemplatesMenuSelect:
          onCategoryChange(
            (
              data.payload as {
                category: string;
              }
            ).category as TemplatesConfigEnum
          );
          break;
        case Message.TemplatesMenuSearch:
          onSearch(
            (
              data.payload as {
                search: string;
              }
            ).search
          );

          break;
      }
    }
  );

  const handleOpenPreview = useCallback(
    (id: number) => {
      send(Message.TemplatesPreview, { id });
    },
    [send]
  );

  const handleApply = useCallback(
    (id: number) => {
      send(Message.TemplatesSelect, { id });
    },
    [send]
  );

  useEffect(() => {
    send(Message.TemplatesAskForResize);
  }, [send, items]);

  useEffect(() => {
    send(Message.TemplatesResize, handleRecalculateSize());
  }, [items, handleRecalculateSize, send]);

  return {
    device,
    preview: handleOpenPreview,
    apply: handleApply,
  };
};
