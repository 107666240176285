import { call, put, select } from 'redux-saga/effects';
import { StripeSubscription, TeamFiltered } from '@distribute/shared/types';

import { createNotification, snackbarModel } from '../../../snackbar';
import { updatePaymentMethodSaga } from './updatePaymentMethod';
import { subscriptionApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { teamsModel } from '../../../teams';
import { subscriptionModel } from '..';
import { CardFormSubmitError, StripeConfirmSetupError } from '../../lib';

export function* createSubscription({
  payload: { teamId, interval, plan, stripePromoCodeId, cb, ...rest },
}: ReturnType<typeof subscriptionModel.actions.createSubscription>) {
  try {
    yield put(subscriptionModel.actions.setIsUpdatingSubscription(true));

    if (rest.isUpdatePaymentMethod) {
      const { elements, stripe, cardFormCustomFieldsForm } = rest;

      yield call(
        updatePaymentMethodSaga,
        teamId,
        elements,
        stripe,
        cardFormCustomFieldsForm
      );
    }

    const stripeSubscription: StripeSubscription = yield call(
      subscriptionApi.createSubscription,
      {
        teamId,
        interval,
        plan,
        stripePromoCodeId,
      }
    );
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, {
      ...currentTeam,
      stripeSubscription,
      subscriptionOnboardingPromoCode:
        stripePromoCodeId ===
        currentTeam.subscriptionOnboardingPromoCode?.stripePromoCodeId
          ? null
          : currentTeam.subscriptionOnboardingPromoCode,
    });
    cb?.();
  } catch (error: unknown) {
    if (
      error instanceof CardFormSubmitError ||
      error instanceof StripeConfirmSetupError
    ) {
      return;
    }

    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create subscription.')
      )
    );
  } finally {
    yield put(subscriptionModel.actions.setIsUpdatingSubscription(false));
  }
}
