import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../../entities/auth-user';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';

import { Button, Input } from '../../../../shared/ui';
import { fullNameSchema, FullNameSchema } from '../../lib';
import { profileSettingModel } from '../../model';

export const ChangeFullNameForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm<FullNameSchema>({
    resolver: yupResolver(fullNameSchema),
  });

  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const isFormChanged = isDirty && user.displayName !== watch().fullName;
  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsChangeFullNameLoading
  );

  const dispatch = useDispatch();
  const handleFormSubmit = (data: FullNameSchema) => {
    dispatch(
      profileSettingModel.actions.changeFullNameClick({ ...data, cb: reset })
    );
  };

  useConfirmWindowClose(isFormChanged, 'changeFullName');

  return (
    <div className="border-b border-gray-200 mb-5">
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex sm:flex-col pb-5"
      >
        <p className="w-61 text-gray-700 text-sm mr-8 sm:mr-0">Full name</p>
        <div className="flex max-w-107 sm:mt-1.5 sm:flex-col w-full sm:max-w-full">
          <Input
            {...register('fullName', { value: user.displayName })}
            isError={!!errors.fullName}
            autoComplete="name"
            messageText={errors.fullName?.message}
            type="text"
          />
          <Button
            className="ml-3 sm:ml-0 sm:mt-3"
            variant="text"
            color="primary"
            type="submit"
            disabled={!isFormChanged}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
