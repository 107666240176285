import { put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api';
import { Folder, UserPermissionToFolderRole } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateUserPermission({
  payload: { folderId, userPermissionId, role },
}: ReturnType<typeof foldersModel.actions.updateUserPermission>) {
  try {
    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    yield foldersApi.updateUserPermission(folderId, userPermissionId, { role });
    const newFolders = folders.map((i) => {
      if (i.id === folderId) {
        let userPermissionToFolders = i.userPermissionToFolders;

        if (role === UserPermissionToFolderRole.OWNER) {
          userPermissionToFolders = userPermissionToFolders.filter(
            (i) => i.role !== UserPermissionToFolderRole.OWNER
          );
        }

        userPermissionToFolders = userPermissionToFolders.map(
          (userPermission) => {
            if (userPermission.id === userPermissionId) {
              return { ...userPermission, role };
            }

            return userPermission;
          }
        );

        return {
          ...i,
          userPermissionToFolders,
        };
      }

      return i;
    });
    const currentFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );

    if (currentFolder)
      yield put(
        foldersModel.actions.setCurrentFolder(
          newFolders.find((i) => i.id === currentFolder.id)
        )
      );

    yield put(foldersModel.actions.setFolders(newFolders));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'The user permission has not been updated')
      )
    );
  }
}
