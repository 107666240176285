import classNames from 'classnames';
import { SpriteGlyph, Icon } from './Icon';
import { Tooltip } from './Tooltip';

type Option = {
  id: string;
  label: string;
  iconName?: SpriteGlyph;
  iconWidth?: number;
  onClick?: () => void;
  disabled?: boolean;
  tooltipText?: JSX.Element;
  isComingSoon?: boolean;
};

type TabsGroupProps = {
  options: Option[];
  setActive: (val: string) => void;
  active: string;
  title?: string;
  className?: string;
  optionClassName?: string;
};

type OptionProps = {
  option: Option;
  active: string;
  index: number;
  options: Option[];
  className?: string;
  setActive: (val: string) => void;
};

const Option: React.FC<OptionProps> = ({
  active,
  option,
  index,
  options,
  className,
  setActive,
}) => {
  return (
    <label
      className={classNames(
        'cursor-pointer py-2 px-5 border-r border-gray-300 h-full flex items-center justify-center',
        {
          'bg-gray-50': active === option.id,
          'bg-base-white': active !== option.id,
          'rounded-tl-lg rounded-bl-lg': index === 0,
          'rounded-tr-lg rounded-br-lg border-none':
            index === options.length - 1,
        },
        className
      )}
      key={option.id}
    >
      {option.iconName && (
        <Icon
          glyph={option.iconName}
          width={option.iconWidth}
          className={classNames('mr-2', {
            'text-gray-500': active !== option.id || option.disabled,
            'text-gray-800': active === option.id,
          })}
        />
      )}
      <span
        className={classNames(' text-s font-medium cursor-pointer', {
          'text-gray-800': !option.disabled,
          'text-gray-700': option.disabled,
        })}
      >
        {option.label}
      </span>
      <input
        checked={option.label === active}
        className="hidden peer"
        id={option.id}
        type="radio"
        value={option.id}
        onChange={() => {
          if (option.disabled) return;

          setActive(option.id);
          if (option.onClick) {
            option.onClick();
          }
        }}
      />
      {option.isComingSoon && (
        <div className="text-xs font-medium text-base-white px-1 pb-0.5 bg-primary-500 rounded-md ml-1.5">
          soon
        </div>
      )}
    </label>
  );
};

export const TabsGroup: React.FC<TabsGroupProps> = ({
  options,
  setActive,
  active,
  title,
  className,
  optionClassName,
}) => {
  return (
    <div>
      {title && <p className="text-s text-gray-700 pb-1.5">{title}</p>}
      <div
        className={classNames(
          'flex items-center border border-gray-300 rounded-lg h-10 flex-shrink-0 w-fit-content',
          className
        )}
      >
        {options.map((option, i) => {
          if (option.tooltipText) {
            return (
              <Tooltip
                key={i}
                trigger={
                  <Option
                    active={active}
                    options={options}
                    option={option}
                    setActive={setActive}
                    index={i}
                    className={optionClassName}
                  />
                }
              >
                <div>{option.tooltipText}</div>
              </Tooltip>
            );
          }
          return (
            <Option
              key={i}
              active={active}
              options={options}
              option={option}
              setActive={setActive}
              index={i}
              className={optionClassName}
            />
          );
        })}
      </div>
    </div>
  );
};
