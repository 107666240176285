import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* renamePage({
  payload: { title, pageId },
}: ReturnType<typeof pagesModel.actions.renamePage>) {
  try {
    const page: Page = yield pagesApi.updatePage(pageId, { title: title });

    if (!page) {
      throw new Error('updatedPage is undefined');
    }
    yield put(pagesModel.actions.setCurrentPage(page));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Page was not renamed!')
      )
    );
  }
}
