import { Node, mergeAttributes } from '@tiptap/core';
import { COLUMN_WIDTH_DATA_ATTRIBUTE } from './config';

export const ColumnExt = Node.create({
  name: 'column',
  group: 'column',
  content: '(paragraph|block)*',
  isolating: true,
  selectable: false,

  addAttributes() {
    return {
      [COLUMN_WIDTH_DATA_ATTRIBUTE]: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="column"]',
        getAttrs(node) {
          return {
            [COLUMN_WIDTH_DATA_ATTRIBUTE]:
              typeof node === 'string'
                ? null
                : node.getAttribute(COLUMN_WIDTH_DATA_ATTRIBUTE),
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const width = node.attrs['data-column-width'];
    let sizeStyle = '';

    if (width) {
      sizeStyle += `width: ${width}%; `;
    }
    const attrs = mergeAttributes(
      HTMLAttributes,
      { class: 'column' },
      { style: sizeStyle }
    );
    return ['div', attrs, 0];
  },
});
