import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TemplatesConfigEnum } from '../../../../features/templates';
import { Message } from '../../constants/messages';
import { useWindowMessage } from '../../hooks/useWindowMessage';
import { Device } from '../../types';
import { isInFrame } from '@distribute/frontend/utils';

export const useIframeCommunication = ({
  ref,
}: {
  ref: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const [device, setDevice] = useState<Device | undefined>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category =
    (queryParams.get('category') as TemplatesConfigEnum) ??
    TemplatesConfigEnum.ALL;
  const search = queryParams.get('search') ?? '';

  const history = useHistory();

  const updateParams = (newParams: Record<string, string>) => {
    const prevParams: Record<string, string> = {};
    new URLSearchParams(location.search).forEach((value, key) => {
      prevParams[key] = value;
    });
    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...prevParams,
        ...newParams,
      }).toString(),
    });
  };

  const { send } = useWindowMessage([Message.TemplatesDevice], (data) => {
    switch (data.type) {
      case Message.TemplatesDevice:
        setDevice(
          (
            data.payload as {
              device: Device;
            }
          ).device
        );
        break;
    }
  });

  const handleMenuResize = useCallback(() => {
    if (ref.current) {
      send(Message.TemplatesMenuResize, {
        height: ref.current.offsetHeight,
      });
    }
  }, [send, ref]);

  useEffect(() => {
    send(Message.TemplatesAskForDevice);
  }, [send]);

  useEffect(() => {
    handleMenuResize();
  }, [send, handleMenuResize, device]);

  useEffect(() => {
    send(Message.TemplatesMenuSearch, { search });
  }, [search, send]);

  return {
    value: search,
    category,
    device,
    isInIframe: isInFrame(),
    select: (category: string) => {
      updateParams({ category });
      send(Message.TemplatesMenuSelect, { category });
    },
    search: (search: string) => {
      updateParams({ search });
      send(Message.TemplatesMenuSearch, { search });
    },
  };
};
