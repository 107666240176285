import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { GeneratePageTipElement } from './components/GeneratePageTipElement';
import {
  Button,
  FileInputRedesigned,
  Icon,
  Input,
  Select,
  Tooltip,
} from '../../../../../../shared/ui';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconMap } from '../../../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import {
  teamsModel,
  useTeamPermissions,
} from '../../../../../../features/teams';
import {
  TEAM_SETTINGS_INFO_ROUTE,
  redirectActions,
} from '../../../../../../entities/history';
import {
  GeneratePageForm,
  LinkTypeForAIStatusEnum,
  TranscriptsFilterTypesEnum,
  createPageModalModel,
} from '../../../../../../entities/create-page-modal';
import { PageCreationDataSourcesEnum } from '../../../../../../entities/create-page-modal';
import { gongModel } from '../../../../../../entities/gong';
import { cn } from '@distribute/frontend/utils';
import {
  generatePageValidationSchema,
  pageTypesList,
  transcriptsFilterList,
} from './config';
import {
  LinkElement,
  DataSourceItem,
  DataSourceSelect,
  GongCallerNameSelect,
  CallTranscriptsPicker,
  PageTypeDescriptionItem,
  FileElement,
} from './components';
import {
  DOCX_FILE_FORMAT,
  DOC_FILE_FORMAT,
  PDF_FILE_FORMAT,
  TXT_FILE_FORMAT,
} from '@distribute/shared/types';

import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import { LINK_VERIFICATION_REGEX } from '../../../../../../shared/constants';
import { pagesModel } from '../../../../../../features/pages';
import { FormattedGongTranscript } from '@distribute/shared/api-types/gong';
import {
  createNotification,
  snackbarModel,
} from '../../../../../../features/snackbar';

type Props = {
  onCreatePage(pageName?: string): void;
};

export const GeneratePageStep: React.FC<Props> = ({ onCreatePage }) => {
  const dispatch = useDispatch();

  const [pageTypeDescriptionTipsOpen, setPageTypeDescriptionTipsOpen] =
    useState(true);

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentTeamMemberData = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const requestsCount = currentTeamMemberData.aiMonthlyResponsesCount;

  const isPersonalGongAccountConnected = useSelector(
    gongModel.selectors.selectIsConnected
  );

  const isTeamGongAccountConnected = currentTeam.isGongConnected;

  const isGongConnected =
    isPersonalGongAccountConnected || isTeamGongAccountConnected;

  const gongCallTranscripts = useSelector(
    gongModel.selectors.selectCallTranscripts
  );

  const isLoadingGongTranscripts = useSelector(
    gongModel.selectors.selectIsLoadingTranscripts
  );

  const isCreatingPage = useSelector(
    pagesModel.selectors.selectCreatePageIsLoading
  );

  const selectedBrandCompany = useSelector(
    createPageModalModel.selectors.selectSelectedBrandingCompany
  );

  const preselectedPageType = useSelector(
    createPageModalModel.selectors.selectPageType
  );

  const preselectedGongCallTranscriptId = useSelector(
    createPageModalModel.selectors.selectGongCallTranscriptId
  );

  const teamPermissions = useTeamPermissions();

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm<GeneratePageForm>({
    resolver: yupResolver(generatePageValidationSchema),
    defaultValues: {
      title: selectedBrandCompany
        ? `${currentTeam.brandCompanyName ?? 'Unlimited horizons'} for ${
            selectedBrandCompany.name
          }`
        : '',
      pageType: preselectedPageType ?? undefined,
      selectedTranscripts: preselectedGongCallTranscriptId
        ? [preselectedGongCallTranscriptId]
        : [],
      selectedCallerName: '',
      transcriptsFilter: preselectedGongCallTranscriptId
        ? TranscriptsFilterTypesEnum.SPECIFIC
        : undefined,
      selectedDataSource: isGongConnected
        ? PageCreationDataSourcesEnum.GONG
        : undefined,
      text: '',
      links: [
        { url: '', data: null, status: LinkTypeForAIStatusEnum.NOT_PARSED_YET },
      ],
    },
  });

  const {
    field: { value: selectedPageType, onChange: handleChangePageType },
  } = useController({
    control,
    name: 'pageType',
    rules: { required: true },
  });

  const {
    field: { value: selectedCallerName, onChange: setSelectedCallerName },
  } = useController({
    control,
    name: 'selectedCallerName',
    rules: { required: true },
  });

  const {
    field: { value: selectedDataSource, onChange: setSelectedDataSource },
  } = useController({
    control,
    name: 'selectedDataSource',
    rules: { required: true },
  });

  const {
    field: {
      value: selectedTranscriptsFilter,
      onChange: handleChangeTranscriptsFilter,
    },
  } = useController({
    control,
    name: 'transcriptsFilter',
    rules: { required: true },
  });

  const {
    field: { value: selectedTranscripts, onChange: setSelectedTranscripts },
  } = useController({
    control,
    name: 'selectedTranscripts',
    rules: { required: true },
  });

  const {
    field: { value: textValue, onChange: setTextValue },
  } = useController({
    control,
    name: 'text',
    rules: { required: true },
  });

  const {
    fields: links,
    append: appendLink,
    remove: removeLink,
    update: updateLink,
  } = useFieldArray({
    control,
    name: 'links',
  });

  const {
    fields: files,
    append: appendFile,
    remove: removeFile,
  } = useFieldArray({
    control,
    name: 'files',
  });

  const {
    field: { onChange: handleChangePageTypeDescription },
  } = useController({ control, name: 'pageTypeDescription' });

  const pageTypeDescription = watch('pageTypeDescription');

  useEffect(() => {
    if (pageTypeDescription && pageTypeDescriptionTipsOpen) {
      setPageTypeDescriptionTipsOpen(false);
    }
  }, [pageTypeDescription, pageTypeDescriptionTipsOpen]);

  const handleGoUnlimited = () => {
    dispatch(createPageModalModel.actions.closePageCreationModal());
    dispatch(redirectActions.toSubscriptionsClick());
  };

  const fillPageTypeDescription = (description: string) => {
    setPageTypeDescriptionTipsOpen(false);
    handleChangePageTypeDescription(description);
  };

  const filteredTranscriptsByName = useMemo(
    () =>
      gongCallTranscripts?.filter(
        (el) => !!el.parties.find((el) => el.name === selectedCallerName)
      ),
    [gongCallTranscripts, selectedCallerName]
  );

  const sourceSelectRef = useRef<HTMLButtonElement>(null);

  const handleGongSelect = () => {
    setSelectedDataSource(PageCreationDataSourcesEnum.GONG);
    sourceSelectRef.current?.click();
  };

  const connectToGong = () => {
    dispatch(
      redirectActions.fromWorkspaceWithBackUrl({
        path: TEAM_SETTINGS_INFO_ROUTE,
      })
    );
  };

  const handleGongClick = (e: MouseEvent) => {
    if (isGongConnected) {
      handleGongSelect();
    } else {
      e.stopPropagation();
      connectToGong();
    }
  };

  const toggleCallTranscript = (transcriptId: string) => {
    const index = selectedTranscripts.indexOf(transcriptId);
    if (index !== -1) {
      setSelectedTranscripts(
        selectedTranscripts.filter((id) => id !== transcriptId)
      );
    } else {
      setSelectedTranscripts([...selectedTranscripts, transcriptId]);
    }
  };

  const toggleAllTranscripts = () => {
    if (selectedTranscripts.length === filteredTranscriptsByName?.length) {
      setSelectedTranscripts([]);
    } else {
      setSelectedTranscripts(
        filteredTranscriptsByName?.map((el) => el.metaData.id)
      );
    }
  };

  const dataSources: DataSourceItem[] = [
    {
      id: PageCreationDataSourcesEnum.TEXT,
      title: 'Input text',
      iconName: IconMap.TextLines,
    },
    {
      id: PageCreationDataSourcesEnum.FILE,
      title: 'Upload file(s)',
      iconName: IconMap.UploadCloud02,
    },
    {
      id: PageCreationDataSourcesEnum.LINK,
      title: 'Add a link',
      iconName: IconMap.Link,
    },
    {
      id: PageCreationDataSourcesEnum.GONG,
      title: 'Gong',
      isIntegration: true,
      iconImageSrc: '../../../assets/images/gong.svg',
      isConnected: isGongConnected,
      handleClick: handleGongClick,
      hasConnectBtn: teamPermissions.isCanConnectIntegrations,
    },
  ];

  const filesLeftToParse = useRef<number>(0);
  const [areFilesUploading, setFilesUploading] = useState(false);

  useEffect(() => {
    if (filesLeftToParse.current === 0) {
      setFilesUploading(false);
    }
  }, [filesLeftToParse.current]);

  const handleFilesUpload = (files: File[]) => {
    files.forEach((file) => {
      dispatch(
        createPageModalModel.actions.parseFileTextContent({
          file,
          cb: (data: string | null) => {
            if (data) {
              appendFile({
                name: file.name,
                size: file.size,
                type: file.type,
                data,
              });
            }
            filesLeftToParse.current -= 1;
          },
        })
      );
    });
  };

  const handleParseLink = (link: string, index: number) => {
    if (!link.match(LINK_VERIFICATION_REGEX)) return;

    updateLink(index, {
      url: link,
      data: null,
      status: LinkTypeForAIStatusEnum.PARSING,
    });

    dispatch(
      createPageModalModel.actions.parseLinkTextContent({
        link,
        cb: (data: string | null) => {
          if (data !== null) {
            updateLink(index, {
              url: link,
              data,
              status: LinkTypeForAIStatusEnum.PARSED,
            });
            return;
          }
          updateLink(index, {
            url: link,
            data: null,
            status: LinkTypeForAIStatusEnum.ERROR,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (isGongConnected && !gongCallTranscripts) {
      dispatch(gongModel.actions.getCallTranscripts());
    }
  }, [dispatch, gongCallTranscripts, isGongConnected]);

  useEffect(() => {
    if (preselectedGongCallTranscriptId && gongCallTranscripts?.length) {
      const currentCallTranscript = gongCallTranscripts.find(
        (item) => item.metaData.id === preselectedGongCallTranscriptId
      );
      if (!currentCallTranscript) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', `Call transcript is not found`)
          )
        );
        return;
      }
      setSelectedCallerName(currentCallTranscript?.parties[0].name);
    }
  }, [
    dispatch,
    gongCallTranscripts,
    gongCallTranscripts?.length,
    preselectedGongCallTranscriptId,
    setSelectedCallerName,
  ]);

  const handlePageGeneration = (data: GeneratePageForm) => {
    if (
      data.selectedDataSource === PageCreationDataSourcesEnum.LINK &&
      data.links.find((link) => link.status !== LinkTypeForAIStatusEnum.PARSED)
    ) {
      // wait for all links to be parsed
      const timer = setInterval(() => {
        if (
          data.selectedDataSource === PageCreationDataSourcesEnum.LINK &&
          !data.links.find(
            (link) => link.status !== LinkTypeForAIStatusEnum.PARSED
          )
        ) {
          clearInterval(timer);

          dispatch(
            createPageModalModel.actions.initializePageGenerationWithAI({
              ...data,
              generatePage: onCreatePage,
            })
          );

          return;
        }
      }, 500);
    } else {
      dispatch(
        createPageModalModel.actions.initializePageGenerationWithAI({
          ...data,
          generatePage: onCreatePage,
        })
      );
    }
  };

  return (
    <div className="relative overflow-hidden h-full">
      <form
        className={cn(
          'flex items-start justify-center gap-6 h-full overflow-y-scroll px-4',
          { 'pb-30': !!stripeSubscription, 'pb-37': !stripeSubscription }
        )}
      >
        <div className="max-w-60 w-full h-full sticky top-0"></div>
        <div className="w-160 shrink-0 flex flex-col min-h-full justify-center">
          <h2 className="text-display-md text-gray-900 font-medium font-display mb-2 text-center mt-4.5">
            Generate
          </h2>
          <p className="text-gray-600 mb-10 text-center">
            What page type do you want to create today?
          </p>
          <Input
            {...register('title')}
            label="Page title"
            type="text"
            placeholder="Untitled"
            isError={!!errors.title}
            className="mb-4"
          />
          <Select
            label="Type of page *"
            onChange={handleChangePageType}
            items={pageTypesList}
            value={selectedPageType}
            currentValue={pageTypesList.find(
              (type) => selectedPageType === type.id
            )}
            error={errors.pageType?.message}
            errorMsg={errors.pageType?.message}
            listStyles="max-h-none !gap-y-0.5 !p-1.5"
            listItemStyles="h-9.5 pl-2.5"
          />
          {selectedPageType === CreateWithAIPageType.OTHER && (
            <div className="mt-4">
              <Input
                type="text"
                label="Describe what page you’d like to make and specify its structure *"
                placeholder="E.g., A page that lists all the advantages of our product. This page should include intro of the product and benefits list."
                isTextArea
                textAreaRows={4}
                className="mb-4"
                {...register('pageTypeDescription')}
                value={pageTypeDescription}
              />
              {pageTypeDescriptionTipsOpen && (
                <div className="flex gap-4 mb-4">
                  <PageTypeDescriptionItem
                    description="A product showcase page with the option  to request a demo for customers."
                    handleClick={() =>
                      fillPageTypeDescription(
                        'A product showcase page with the option  to request a demo for customers.'
                      )
                    }
                  />
                  <PageTypeDescriptionItem
                    description="A guide page for new customers, featuring an integrated feedback form."
                    handleClick={() =>
                      fillPageTypeDescription(
                        'A guide page for new customers, featuring an integrated feedback form.'
                      )
                    }
                  />
                  <PageTypeDescriptionItem
                    description="A page presenting Taco-lovers' survey results and participation invitation."
                    handleClick={() =>
                      fillPageTypeDescription(
                        "A page presenting Taco-lovers' survey results and participation invitation."
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
          <div className="mb-1 mt-3">
            <div className="flex items-center gap-1.5 text-gray-700 font-medium text-sm mb-1.5">
              Select transcript(s) or additional information source *{' '}
              <Tooltip
                trigger={
                  <Icon
                    glyph={IconMap.InfoCircle}
                    width={16}
                    className="text-gray-400 cursor-pointer"
                  />
                }
                hideArrow
                sideOffset={6}
              >
                <p className="px-1.5 py-1 font-semibold text-xs w-47 text-center">
                  Please offer detailed information that will assist in
                  generating the most precise page content for you.
                </p>
              </Tooltip>
            </div>
            <div className="flex gap-2">
              <DataSourceSelect
                items={dataSources}
                onChange={setSelectedDataSource}
                value={selectedDataSource || ''}
                currentValue={dataSources.find(
                  (el) => el.id === selectedDataSource
                )}
                ref={sourceSelectRef}
                className={cn({
                  '!w-60':
                    selectedDataSource === PageCreationDataSourcesEnum.GONG &&
                    gongCallTranscripts,
                })}
                isError={!!errors.selectedDataSource}
                messageText={errors.selectedDataSource?.message}
                isLoading={
                  isLoadingGongTranscripts &&
                  selectedDataSource === PageCreationDataSourcesEnum.GONG
                }
              />
              {selectedDataSource === PageCreationDataSourcesEnum.GONG &&
                gongCallTranscripts && (
                  <GongCallerNameSelect
                    handleSelect={setSelectedCallerName}
                    isError={!!errors.selectedCallerName}
                    messageText={errors.selectedCallerName?.message}
                  />
                )}
            </div>
          </div>
          {selectedDataSource === PageCreationDataSourcesEnum.GONG && (
            <>
              {selectedCallerName && (
                <div className="flex items-start gap-3 whitespace-nowrap mb-3">
                  <p className="text-gray-700 mt-2.5">Call transcript(s)</p>
                  <div className="w-full">
                    <Select
                      label=""
                      onChange={handleChangeTranscriptsFilter}
                      items={transcriptsFilterList}
                      value={selectedTranscriptsFilter}
                      currentValue={transcriptsFilterList.find(
                        (type) => selectedTranscriptsFilter === type.id
                      )}
                      error={errors.transcriptsFilter?.message}
                      errorMsg={errors.transcriptsFilter?.message}
                      listStyles="max-h-60 !gap-y-0.5 !p-1.5"
                      listItemStyles="h-9.5 pl-2.5"
                      className="!h-11"
                      buttonStyles="!h-11"
                      hideLabel
                    />
                  </div>
                </div>
              )}
              {filteredTranscriptsByName &&
                ((selectedCallerName &&
                  selectedTranscriptsFilter ===
                    TranscriptsFilterTypesEnum.SPECIFIC) ||
                  (preselectedGongCallTranscriptId && gongCallTranscripts)) && (
                  <CallTranscriptsPicker
                    transcripts={
                      preselectedGongCallTranscriptId
                        ? (gongCallTranscripts as FormattedGongTranscript[])
                        : filteredTranscriptsByName
                    }
                    selectedTranscripts={selectedTranscripts}
                    toggleCallTranscript={toggleCallTranscript}
                    toggleAllTranscripts={toggleAllTranscripts}
                    isError={!!errors.selectedTranscripts}
                    messageText={errors.selectedTranscripts?.message}
                  />
                )}
            </>
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.TEXT && (
            <Input
              type="text"
              isTextArea
              textAreaRows={5}
              counterValue={textValue.length}
              isCounterOnly
              isCounterInside
              value={textValue}
              onChange={setTextValue}
              placeholder="Provide all available information regarding your page's topic by typing it or copy pasting it from your source."
              isError={!!errors.text}
              messageText={errors.text?.message}
            />
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.LINK && (
            <div>
              {links.map((link, index, arr) => (
                <LinkElement
                  index={index}
                  isAddMore={index === arr.length - 1 && arr.length < 5}
                  isDelete={arr.length > 1}
                  handleAddMore={appendLink}
                  handleDelete={removeLink}
                  handleChange={({ target: { value } }) => {
                    setValue(`links.${index}.url`, value);
                    setValue(
                      `links.${index}.status`,
                      LinkTypeForAIStatusEnum.NOT_PARSED_YET
                    );
                    setValue(`links.${index}.data`, null);
                    clearErrors(`links.${index}`);
                  }}
                  key={link.id}
                  watch={watch}
                  isError={!!errors.links?.[index]}
                  messageText={
                    errors.links?.[index]?.url?.message ||
                    errors.links?.[index]?.status?.message
                  }
                  handleParse={handleParseLink}
                />
              ))}
            </div>
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.FILE && (
            <>
              {files.length < 5 && (
                <FileInputRedesigned
                  onUpload={handleFilesUpload}
                  isError={!!errors.files}
                  isMultiple
                  isUploading={areFilesUploading}
                  selectFilesAmount={(size) => {
                    setFilesUploading(true);
                    filesLeftToParse.current = size;
                  }}
                  allowedTypes={[
                    TXT_FILE_FORMAT,
                    DOCX_FILE_FORMAT,
                    PDF_FILE_FORMAT,
                    DOC_FILE_FORMAT,
                  ]}
                />
              )}
              {errors.files?.message && (
                <p className="mt-1.5 !text-error-500 text-sm">
                  {errors.files?.message}
                </p>
              )}
              {files.map((file, index) => (
                <FileElement
                  file={file}
                  key={file.id}
                  handleDelete={() => {
                    removeFile(index);
                    setTimeout(() => clearErrors('files'), 0);
                  }}
                />
              ))}
            </>
          )}
        </div>
        <div className="max-w-60 w-full h-full sticky top-0 pt-76.5">
          <GeneratePageTipElement />
        </div>
      </form>
      <footer className="w-full py-6 border-t border-gray-200 absolute bottom-0 flex flex-col items-center justify-center gap-2 bg-base-white">
        <Button
          variant="icon-text"
          color="primary"
          className="w-160"
          iconLeftName={IconMap.Star06}
          iconLeftWidth={20}
          onClick={handleSubmit(handlePageGeneration)}
          loading={isCreatingPage}
        >
          Generate page
        </Button>
        {!stripeSubscription && (
          <p className="text-sm font-medium text-gray-500">
            {requestsCount ? (
              <span>
                <span className="font-semibold">{requestsCount}</span> AI{' '}
                {requestsCount > 1 ? 'responses' : 'response'} left.
              </span>
            ) : (
              'All free AI responses have been used'
            )}
            <span
              className="text-primary-700 cursor-pointer"
              onClick={handleGoUnlimited}
            >
              {' '}
              Upgrade now
            </span>
          </p>
        )}
      </footer>
    </div>
  );
};
