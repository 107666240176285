import React from 'react';
import classNames from 'classnames';

export const IconLoader = () => {
  return (
    <div
      className={classNames(
        'absolute -left-0.25 -top-0.25 flex gap-0.75 items-center justify-center w-[calc(100%_+_2px)] h-[calc(100%_+_2px)] rounded-lg'
      )}
    >
      {[1, 2, 3].map((i) => (
        <span
          key={i}
          className={`w-1 h-1 flex rounded loading-dot bg-primary-600 loading-dot--${i}`}
        ></span>
      ))}
    </div>
  );
};
