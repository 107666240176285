import moment from 'moment';

import {
  AnalyticRecord,
  Analytics,
  Page,
  AnalyticsTab,
} from '@distribute/shared/types';
import {
  ChartData,
  ChartDataItem,
  EmptyState,
  TabViews,
  Total,
} from '../model/types';
import { formatMsToTimePassed } from '@distribute/shared/utils';

type AnalyticRecordKeysForChart = keyof Pick<
  Analytics,
  | 'pageViews'
  | 'uniqueVisitors'
  | 'averageTimeOnPage'
  | 'alertBarConversions'
  | 'popupConversions'
  | 'ctaConversions'
  | 'alertBarAverageTimeToConvert'
  | 'popupAverageTimeToConvert'
  | 'ctaAverageTimeToConvert'
  | 'gatedContentConversions'
  | 'gatedContentTimeToConvert'
  | 'gatedContentAverageTimeToConvert'
  | 'squeezePageConversions'
  | 'squeezePageTimeToConvert'
  | 'squeezePageAverageTimeToConvert'
  | 'requiredEmailConversions'
  | 'requiredEmailTimeToConvert'
  | 'requiredEmailAverageTimeToConvert'
>;

type AnalyticTabKeysForChart = keyof Pick<AnalyticsTab, 'views'>;

const AVERAGE_TIME_METRICS = [
  'averageTimeOnPage',
  'ctaAverageTimeToConvert',
  'popupAverageTimeToConvert',
  'alertBarAverageTimeToConvert',
  'gatedContentAverageTimeToConvert',
  'squeezePageAverageTimeToConvert',
  'requiredEmailAverageTimeToConvert',
  'averageTimeOnTab',
];

const DATE_FORMAT = 'YYYY-MM-DD';

export const round = (value: number, precision = 0): number => {
  const multiplier = Math.pow(10, precision);

  return Math.round(value * multiplier) / multiplier;
};

const sum = (data: number[]): number =>
  data.reduce((total, item) => total + item, 0);

const convertMsToSecondsForChart = (ms: number): number => {
  return Math.round(moment.duration(ms).asSeconds());
};

const getDatesFromRange = (startDate: Date, endDate = new Date()): string[] => {
  const start = moment(startDate).hour(0).minutes(0).second(0).millisecond(0);
  const end = moment(endDate);
  const diffDays = end.diff(start, 'days');
  const days = [start.format(DATE_FORMAT)];

  for (let i = 1; i <= diffDays; i++) {
    days.push(moment(start).add(i, 'd').format(DATE_FORMAT));
  }

  return days;
};

const getAnalyticDates = (
  pageCreated: Date,
  pageViews: AnalyticRecord
): string[] => {
  const minDaysCountInRange = 30;
  const [firstVisitHours] = Object.keys(pageViews);
  const firstVisitDate = moment(pageCreated)
    .add(firstVisitHours, 'h')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const now = moment();
  const diffDaysFromFirstVisitToNow = now.diff(firstVisitDate, 'd') + 1;

  if (diffDaysFromFirstVisitToNow < minDaysCountInRange) {
    firstVisitDate.subtract(
      minDaysCountInRange - diffDaysFromFirstVisitToNow,
      'd'
    );
  }

  return getDatesFromRange(firstVisitDate.toDate(), now.toDate());
};

const convertToDateValueDict = (
  analyticsRecord: AnalyticRecord,
  pageCreatedAt: Date,
  isCalcAverage = false
): AnalyticRecord => {
  const createdAt = moment(pageCreatedAt);
  const countInPeriod: Record<string, number> = {};

  const data = Object.keys(analyticsRecord).reduce<AnalyticRecord>(
    (acc, hoursPassed) => {
      const dateKey = moment(createdAt)
        .add(hoursPassed, 'h')
        .format(DATE_FORMAT);
      countInPeriod[dateKey] = (countInPeriod[dateKey] ?? 0) + 1;

      return {
        ...acc,
        [dateKey]: (acc[dateKey] ?? 0) + analyticsRecord[hoursPassed],
      };
    },
    {}
  );

  if (!isCalcAverage) return data;

  return Object.keys(data).reduce<AnalyticRecord>((acc, dateKey) => {
    acc[dateKey] = data[dateKey] / (countInPeriod[dateKey] ?? 1);
    return acc;
  }, {});
};

const calculateConversionRate = (
  conversions: number,
  visitors: number
): number => {
  const value = (conversions * 100) / visitors;

  return Number.isFinite(value) ? value : 0;
};

const checkIsAnalyticRecordEmpty = (data: AnalyticRecord) =>
  Object.values(data).length === 0;

export const calculateEmptyState = (data: Analytics): EmptyState => {
  const isConversionsEmpty =
    checkIsAnalyticRecordEmpty(data.alertBarConversions) &&
    checkIsAnalyticRecordEmpty(data.popupConversions) &&
    checkIsAnalyticRecordEmpty(data.ctaConversions) &&
    checkIsAnalyticRecordEmpty(data.gatedContentConversions) &&
    checkIsAnalyticRecordEmpty(data.squeezePageConversions) &&
    checkIsAnalyticRecordEmpty(data.requiredEmailConversions);

  const isConversionsRateEmpty =
    checkIsAnalyticRecordEmpty(data.alertBarAverageTimeToConvert) &&
    checkIsAnalyticRecordEmpty(data.popupAverageTimeToConvert) &&
    checkIsAnalyticRecordEmpty(data.ctaAverageTimeToConvert) &&
    checkIsAnalyticRecordEmpty(data.gatedContentAverageTimeToConvert) &&
    checkIsAnalyticRecordEmpty(data.squeezePageAverageTimeToConvert) &&
    checkIsAnalyticRecordEmpty(data.requiredEmailAverageTimeToConvert);

  return {
    pageViews: !data.pageViewsCount,
    uniqueVisitors: checkIsAnalyticRecordEmpty(data.uniqueVisitors),
    conversions: isConversionsEmpty,
    conversionRate: isConversionsEmpty,
    averageTimeOnPage: checkIsAnalyticRecordEmpty(data.averageTimeOnPage),
    averageTimeToConvert: isConversionsRateEmpty,
  };
};

export const calculateTotal = (
  data: Analytics,
  emptyState: EmptyState
): Total => {
  const uniqueVisitors = sum(Object.values(data.uniqueVisitors));
  const conversions = sum([
    sum(Object.values(data.alertBarConversions)),
    sum(Object.values(data.popupConversions)),
    sum(Object.values(data.ctaConversions)),
    sum(Object.values(data.gatedContentConversions)),
    sum(Object.values(data.squeezePageConversions)),
    sum(Object.values(data.requiredEmailConversions)),
  ]);
  const conversionRate = calculateConversionRate(
    conversions,
    data.pageViewsCount
  );

  const averageTimeOnPage = Object.values(data.averageTimeOnPage);
  const totalAverageTimeOnPage =
    sum(averageTimeOnPage) / averageTimeOnPage.length || 0;

  const alertBarAverageTimeToConvert = Object.values(
    data.alertBarAverageTimeToConvert
  );
  const popupAverageTimeToConvert = Object.values(
    data.popupAverageTimeToConvert
  );
  const ctaAverageTimeToConvert = Object.values(data.ctaAverageTimeToConvert);
  const gatedContentAverageTimeToConvert = Object.values(
    data.gatedContentAverageTimeToConvert
  );
  const squeezePageAverageTimeToConvert = Object.values(
    data.squeezePageAverageTimeToConvert
  );
  const requiredEmailAverageTimeToConvert = Object.values(
    data.requiredEmailAverageTimeToConvert
  );

  const totalAverageTimeToConvert =
    sum([
      sum(alertBarAverageTimeToConvert),
      sum(popupAverageTimeToConvert),
      sum(ctaAverageTimeToConvert),
      sum(gatedContentAverageTimeToConvert),
      sum(squeezePageAverageTimeToConvert),
      sum(requiredEmailAverageTimeToConvert),
    ]) /
      sum([
        alertBarAverageTimeToConvert.length,
        popupAverageTimeToConvert.length,
        ctaAverageTimeToConvert.length,
        gatedContentAverageTimeToConvert.length,
        squeezePageAverageTimeToConvert.length,
        requiredEmailAverageTimeToConvert.length,
      ]) || 0;

  return {
    uniqueVisitors,
    conversions,
    pageViews: data.pageViewsCount,
    conversionRate: emptyState.conversionRate
      ? '-'
      : `${round(conversionRate, 1)}%`,
    averageTimeOnPage: emptyState.averageTimeOnPage
      ? '-'
      : formatMsToTimePassed(totalAverageTimeOnPage),
    averageTimeToConvert: emptyState.averageTimeToConvert
      ? '-'
      : formatMsToTimePassed(totalAverageTimeToConvert),
  };
};

export const calculateChartData = (data: Analytics, page: Page): ChartData => {
  const dates = getAnalyticDates(page.createdAt, data.pageViews);
  const analyticRecordKeys: AnalyticRecordKeysForChart[] = [
    'pageViews',
    'uniqueVisitors',
    'averageTimeOnPage',
    'alertBarConversions',
    'popupConversions',
    'ctaConversions',
    'gatedContentConversions',
    'squeezePageConversions',
    'alertBarAverageTimeToConvert',
    'popupAverageTimeToConvert',
    'ctaAverageTimeToConvert',
    'gatedContentAverageTimeToConvert',
    'squeezePageAverageTimeToConvert',
    'requiredEmailConversions',
    'requiredEmailAverageTimeToConvert',
  ];

  const analyticTabRecordKeys: AnalyticTabKeysForChart[] = ['views'];

  const convertedData = analyticRecordKeys.reduce((acc, key) => {
    acc[key] = convertToDateValueDict(
      data[key],
      page.createdAt,
      AVERAGE_TIME_METRICS.includes(key)
    );
    return acc;
  }, {} as Record<AnalyticRecordKeysForChart, AnalyticRecord>);

  const hasTabsAnalytics = Array.isArray(data.tabs) && data.tabs.length > 1;
  let convertedTabData: Record<
    AnalyticTabKeysForChart,
    Record<string, TabViews[]>
  > = {
    views: {},
  };

  if (hasTabsAnalytics) {
    convertedTabData = analyticTabRecordKeys.reduce((acc, key) => {
      acc[key as AnalyticTabKeysForChart] = (
        data.tabs as AnalyticsTab[]
      ).reduce((tabAcc, tab) => {
        const bla = convertToDateValueDict(
          tab[key],
          page.createdAt,
          AVERAGE_TIME_METRICS.includes(key)
        );

        for (const date of Object.keys(bla)) {
          const data = {
            id: tab.contentItem.id,
            name: tab.contentItem.name || '',
            value: bla[date],
          };
          if (Array.isArray(tabAcc[date as string])) {
            tabAcc[date as string].push(data);
          } else {
            tabAcc[date as string] = [data];
          }
        }

        return tabAcc;
      }, {} as Record<string, TabViews[]>);

      return acc;
    }, {} as Record<AnalyticTabKeysForChart, Record<string, TabViews[]>>);
  }

  return dates.map<ChartDataItem>((date) => ({
    date,
    pageViews: convertedData.pageViews[date] ?? 0,
    uniqueVisitors: convertedData.uniqueVisitors[date] ?? 0,
    conversions: {
      alertBar: convertedData.alertBarConversions[date] ?? 0,
      cta: convertedData.ctaConversions[date] ?? 0,
      popUp: convertedData.popupConversions[date] ?? 0,
      gatedContent: convertedData.gatedContentConversions[date] ?? 0,
      squeezePage: convertedData.squeezePageConversions[date] ?? 0,
      requiredEmail: convertedData.requiredEmailConversions[date] ?? 0,
    },
    conversionRate: {
      alertBar: calculateConversionRate(
        convertedData.alertBarConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
      cta: calculateConversionRate(
        convertedData.ctaConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
      popUp: calculateConversionRate(
        convertedData.popupConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
      gatedContent: calculateConversionRate(
        convertedData.gatedContentConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
      squeezePage: calculateConversionRate(
        convertedData.squeezePageConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
      requiredEmail: calculateConversionRate(
        convertedData.requiredEmailConversions[date] ?? 0,
        convertedData.pageViews[date] ?? 0
      ),
    },

    averageTimeOnPage: convertMsToSecondsForChart(
      convertedData.averageTimeOnPage[date] ?? 0
    ),
    averageTimeToConvert: {
      alertBar: convertMsToSecondsForChart(
        convertedData.alertBarAverageTimeToConvert[date] ?? 0
      ),
      cta: convertMsToSecondsForChart(
        convertedData.ctaAverageTimeToConvert[date] ?? 0
      ),
      popUp: convertMsToSecondsForChart(
        convertedData.popupAverageTimeToConvert[date] ?? 0
      ),
      gatedContent: convertMsToSecondsForChart(
        convertedData.gatedContentAverageTimeToConvert[date] ?? 0
      ),
      squeezePage: convertMsToSecondsForChart(
        convertedData.squeezePageAverageTimeToConvert[date] ?? 0
      ),
      requiredEmail: convertMsToSecondsForChart(
        convertedData.requiredEmailAverageTimeToConvert[date] ?? 0
      ),
    },
    tabViews: convertedTabData.views[date] ?? [],
  }));
};
