import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Trigger } from '@radix-ui/react-popover';
import { BrandColor } from '@distribute/shared/types';

import { ColorSelector, Popover } from '../../../shared/ui';
import { brandColorsPalette, defaultBrandColor } from '../config';
import { generateColorsPalette } from '../lib/generateColorsPalette';

type PageStylesForm = {
  colorValue: string;
};

type IProps = {
  colorValue: string;
  triggerComponent?: ReactNode;
  contentClassName?: string;
  side?: 'bottom' | 'left' | 'right' | 'top';
  align?: 'start' | 'center' | 'end';
  isDisabled?: boolean;
  onChangeBrandColor: (colorPalette: BrandColor) => void;
};

const colorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

const validationSchema = object().shape({
  colorValue: string()
    .matches(colorRegex, 'Invalid HEX color.')
    .required('HEX color is required'),
});

export const BrandColorPicker: FC<IProps> = ({
  colorValue,
  triggerComponent,
  side = 'right',
  align,
  contentClassName,
  isDisabled,
  onChangeBrandColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [isColorPicker, setIsColorPicker] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState(colorValue);
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<PageStylesForm>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const handleColorPicker = (color: string) => {
    setCurrentColor(color.toUpperCase());
    const newBrandColor = generateColorsPalette(color);
    onChangeBrandColor(newBrandColor);
  };

  const onBlurColorValue = (e: ChangeEvent<HTMLInputElement>) => {
    const currentColorValue = e.target.value;

    if (colorRegex.test(currentColorValue)) {
      const newBrandColor = generateColorsPalette(currentColorValue);

      setCurrentColor(currentColorValue);
      onChangeBrandColor(newBrandColor);
    } else {
      setValue('colorValue', currentColor);
    }

    setIsFocusInput(false);
  };

  const onChangeColorValue = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value && !value.startsWith('#')) {
      value = `#${value}`;
    }

    setCurrentColor(value.toUpperCase());
    onChangeBrandColor(generateColorsPalette(value));
  };

  const handleColorChoose = (colorPalette: BrandColor) => {
    setCurrentColor(colorPalette[500]);
    onChangeBrandColor(colorPalette);
  };

  useEffect(() => {
    setValue('colorValue', currentColor);
  }, [currentColor, setValue]);

  return (
    <Popover
      open={isOpen}
      handleClickCancel={() => setIsOpen(false)}
      triggerComponent={
        <Trigger
          className="outline-none w-full"
          onClick={isDisabled ? undefined : () => setIsOpen(true)}
        >
          {triggerComponent ?? (
            <div className="border-b border-gray-200 pb-3 flex flex-col gap-1">
              <span className="hover:bg-gray-50 p-2 flex gap-2.5 items-center cursor-pointer w-full rounded-md">
                <span
                  style={{ backgroundColor: currentColor }}
                  className="h-3 w-3 bg-primary-500 rounded-sm"
                />
                <span className="text-s text-gray-700">Brand Color</span>
              </span>
              <span className="text-xs text-gray-500 pl-2 text-left">
                Brand color applies to the buttons and all call-to-actions on
                the page.
              </span>
            </div>
          )}
        </Trigger>
      }
      side={side}
      align={align}
      contentStyles={classNames(
        'flex flex-col w-61 h-107 z-50',
        contentClassName
      )}
    >
      <div className="flex flex-col border-b border-gray-200 px-2 pt-5 pb-1.5 gap-4">
        <div className="flex flex-col gap-1.5 px-2">
          <p className="text-s text-gray-700">Use Custom Color</p>
          <div
            className={classNames(
              'flex items-center gap-2.75 border px-3.5 py-0.5 rounded-lg relative h-10',
              {
                'ring-0 border-primary-300 outline-none shadow-xs-primary':
                  isFocusInput,
                'border-gray-200': !isFocusInput,
              }
            )}
          >
            {isColorPicker && (
              <ColorSelector
                value={currentColor}
                onClose={() => setIsColorPicker(false)}
                onChange={handleColorPicker}
                classNames="absolute z-50 -top-6 max850:translate-x-[70%] -right-4 translate-x-full sm:translate-x-1/2 sm:right-0"
              />
            )}
            <button
              style={{ backgroundColor: currentColor }}
              className="outline-none h-4 w-4.5 border-1 rounded border-gray-500"
              onClick={() => setIsColorPicker(true)}
            />
            <form>
              <input
                {...register('colorValue')}
                onBlur={onBlurColorValue}
                onChange={onChangeColorValue}
                onFocus={() => setIsFocusInput(true)}
                className="outline-none w-full text-gray-900"
                placeholder="#AABBCC"
                type="text"
              />
            </form>
          </div>
          {errors.colorValue?.message && (
            <p className="px-2 text-xs text-error-500">
              {errors.colorValue?.message}
            </p>
          )}
        </div>
        <button
          onClick={() => handleColorChoose(defaultBrandColor)}
          className="flex gap-2.5 items-center outline-none py-2 cursor-pointer hover:bg-gray-50 px-4"
        >
          <span className="h-3 w-3 bg-primary-500 rounded-sm" />
          <span className="text-s text-gray-700">Default</span>
        </button>
      </div>
      <div className="p-2 pt-1.5 flex-grow overflow-y-scroll scrollbar-hide">
        {brandColorsPalette.map(({ label, brandColors }) => (
          <button
            onClick={() => handleColorChoose(brandColors)}
            key={label}
            className="flex gap-2.5 items-center outline-none py-2 cursor-pointer hover:bg-gray-50 px-4 w-full rounded-md"
          >
            <span
              style={{ backgroundColor: brandColors[500] }}
              className="h-3 w-3 rounded-sm"
            />
            <p className="text-gray-700 text-s">{label}</p>
          </button>
        ))}
      </div>
    </Popover>
  );
};
