import { Folder } from '@distribute/shared/types';

export const reorderFolders = (
  folders: Folder[],
  sourceIndex: number,
  destinationIndex: number
): Folder[] => {
  const reorderedFolders = [...folders];
  const sourceFolder = reorderedFolders.splice(sourceIndex - 1, 1);

  reorderedFolders.splice(destinationIndex - 1, 0, ...sourceFolder);

  return reorderedFolders.map((folder, index) => ({
    ...folder,
    order: index + 1,
  }));
};
