import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customDomainsModel } from '../../model';
import { Button, Modal } from '../../../../shared/ui';
import { createNotification, snackbarModel } from '../../../snackbar';

export const DeleteCustomDomainConfirmationModal: React.FC = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    customDomainsModel.selectors.selectIsDeleteConfirmationModalOpen
  );
  const isDeleting = useSelector(
    customDomainsModel.selectors.selectCustomDomainIsDeleting
  );

  const onModalClose = () => {
    dispatch(customDomainsModel.actions.setDeleteConfirmationModalOpen(false));
  };

  const handleSubmit = () => {
    dispatch(
      customDomainsModel.actions.deleteCustomDomain({
        callback: () => {
          onModalClose();
          dispatch(
            snackbarModel.actions.addNotificationAction(
              createNotification(
                'success',
                'Custom domain was successfully disconnected'
              )
            )
          );
        },
      })
    );
  };

  return (
    <Modal
      title="Disconnect custom domain"
      onClose={onModalClose}
      isOpen={isOpen}
      titleClassName="!max-w-full"
      actionButton={
        <Button
          loading={isDeleting}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          onClick={handleSubmit}
        >
          Disconnect
        </Button>
      }
    >
      <p className="text-sm text-gray-600 -mt-1">
        Are you sure you want to disconnect your custom domain? All published
        pages will be relocated to the Distribute domain 'distribute.so'.
      </p>
    </Modal>
  );
};
