import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../../entities/auth-user';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import { profileSettingModel } from '../../model';
import { IconMap } from '../../../../shared/sprite';
import { GOOGLE_PROVIDER_ID, PASSWORD_PROVIDER_ID } from '../../lib';
import { DisconnectGmailAuthConfirmationModal } from './DisconnectGmailAuthConfirmationModal';

export const ConnectedAccountsForm: React.FC = () => {
  const dispatch = useDispatch();
  const authProviders = useSelector(
    authUserModel.selectors.selectAuthProviders
  );

  const isDisconnectingFromGmail = useSelector(
    profileSettingModel.selectors.selectIsGmailAuthDisconnecting
  );

  const isConnectingGmailAuth = useSelector(
    profileSettingModel.selectors.selectIsGmailAuthConnecting
  );

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleGoogleDisconnect = () => {
    if (authProviders.includes(PASSWORD_PROVIDER_ID)) {
      setConfirmationModalOpen(true);
    }
  };

  const handleGoogleConnect = () => {
    dispatch(profileSettingModel.actions.connectGmailAuthentication());
  };

  return (
    <div className="border-b border-gray-200 flex mb-5 pb-5 sm:flex-col">
      <p className="w-61 text-gray-700 text-sm mr-8 sm:mr-0">
        Connected accounts
      </p>
      <div className="flex items-center max-w-107 sm:mt-4 sm:max-w-full w-full sm:flex-col">
        <Button
          className="ml-3 sm:ml-0 sm:mt-3 sm:w-full"
          variant="text"
          color="secondary"
          loading={isDisconnectingFromGmail || isConnectingGmailAuth}
          iconLeftName={IconMap.Google}
          onClick={
            authProviders.includes(GOOGLE_PROVIDER_ID)
              ? handleGoogleDisconnect
              : handleGoogleConnect
          }
        >
          {authProviders.includes(GOOGLE_PROVIDER_ID) ? (
            <div className="flex items-center gap-6">
              Google is connected{' '}
              <Tooltip
                trigger={
                  <Icon
                    glyph={IconMap.LinkBroken}
                    width={20}
                    className={
                      authProviders.includes(PASSWORD_PROVIDER_ID)
                        ? 'text-error-700'
                        : 'text-gray-400'
                    }
                  />
                }
                sideOffset={16}
              >
                <div className="px-2 py-2 font-semibold text-xs text-left">
                  Disconnect Google account
                  {!authProviders.includes(PASSWORD_PROVIDER_ID) && (
                    <div className="text-warning-300 mt-1 max-w-50 text-left">
                      <p>Before you disconnect Google </p>
                      <p>account you must create password.</p>
                    </div>
                  )}
                </div>
              </Tooltip>
            </div>
          ) : (
            'Connect Google account'
          )}
        </Button>
      </div>
      <DisconnectGmailAuthConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      />
    </div>
  );
};
