import { fork, takeEvery } from 'redux-saga/effects';
import { aiModel } from '../../../ai';
import { snippetsModel } from '..';

export function* snippetEditorWorker() {
  yield fork(aiModel.sagas.editorAiWorker);

  yield takeEvery(
    snippetsModel.actions.changeSnippetTextContent,
    snippetsModel.sagas.changeSnippetTextContent
  );
}
