import classNames from 'classnames';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { IconMap } from '../sprite';
import { Icon } from './Icon';

export type InfoType = 'info';

export type Props = PropsWithChildren<
  {
    type?: 'info';
  } & HTMLAttributes<HTMLDivElement>
>;

const settings: Record<
  InfoType,
  {
    icon: keyof typeof IconMap;
  }
> = {
  info: {
    icon: 'InfoCircle',
  },
};

export const Info: FC<Props> = ({
  children,
  type = 'info',
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'bg-blue-50 rounded-lg flex py-2 px-4 flex-row gap-6',
        className
      )}
      {...props}
    >
      <div className="text-blue-600 pt-1">
        <Icon glyph={IconMap[settings[type].icon]} />
      </div>
      <div>{children}</div>
    </div>
  );
};
