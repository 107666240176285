import {
  GetRequireEmailToViewResponseType,
  UpdateRequireEmailToViewResponseType,
  UpdateRequireEmailToViewRequestType,
} from '@distribute/shared/api-types/require-email-to-view';
import { api } from './instance';
import { UpdateRequireEmailToViewActionTypes } from '../../../features/conversion-kit/model/types';
import { getQueryString } from '../../lib';

export const requireEmailToViewApi = {
  async getRequireEmailToView(documentContentId: number) {
    const { data } = await api.get<GetRequireEmailToViewResponseType>(
      `require-email-to-view/${documentContentId}`
    );

    return data;
  },

  async updateRequireEmailToView({
    type,
    documentContentId,
    data,
    teamId,
  }: {
    type: UpdateRequireEmailToViewActionTypes;
    documentContentId: number;
    data: UpdateRequireEmailToViewRequestType;
    teamId: number;
  }) {
    const { data: responseData } =
      await api.patch<UpdateRequireEmailToViewResponseType>(
        `require-email-to-view/${documentContentId}/${type}?${getQueryString({
          teamId,
        })}`,
        data
      );
    return responseData;
  },
};
