import { Node } from '@tiptap/react';

const FILE_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/file-05.svg';

const getFileSizeString = (fileSizeInBytes: number) => {
  const isKb = fileSizeInBytes / 1024 < 1024;
  const isMb = fileSizeInBytes / 1024 / 1024 < 1024;
  const isGb = fileSizeInBytes / 1024 / 1024 / 1024 < 1024;

  if (isKb) return `${(fileSizeInBytes / 1024).toFixed(2)} KB`;

  if (isMb) return `${(fileSizeInBytes / 1024 / 1024).toFixed(2)} MB`;

  if (isGb) return `${(fileSizeInBytes / 1024 / 1024 / 1024).toFixed(2)} GB`;

  return `${fileSizeInBytes} B`;
};

export const FilePreviewExt = Node.create({
  name: 'filePreview',

  atom: true,
  draggable: true,
  group: 'block',
  inline: false,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: 'file-preview',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      { class: 'file-preview', href: HTMLAttributes.fileUrl, target: '_blank' },
      [
        'img',
        {
          class: 'file-preview__img-wrapper',
          src: FILE_ICON,
        },
      ],
      [
        'div',
        { class: 'file-preview__info-wrapper' },
        ['div', { class: 'file-preview__name' }, `${HTMLAttributes.fileName}`],
        [
          'div',
          { class: 'file-preview__info' },
          `${
            (HTMLAttributes.fileType
              ? HTMLAttributes.fileType.toUpperCase() + ' '
              : '') +
            (HTMLAttributes.fileSize &&
              getFileSizeString(HTMLAttributes.fileSize))
          }`,
        ],
      ],
    ];
  },

  addAttributes() {
    return {
      fileUrl: {
        default: '',
      },
      fileSize: {
        default: '',
      },
      fileName: {
        default: '',
      },
      fileType: {
        default: '',
      },
    };
  },
});
