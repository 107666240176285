import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Icon } from '../Icon';
import { IconMap } from '../../sprite';
import classNames from 'classnames';
import { Input } from './Input';
import { contains } from '../../../utils/string';

export type ComboBoxItem = {
  id: string;
  value: string;
  optionTitle?: ReactNode;
  headerTitle?: ReactNode;
};

type Props = {
  items: ComboBoxItem[];
  value: ComboBoxItem[];
  placeholder?: string;
  onChange: (value: ComboBoxItem[]) => void;
};

export const ComboBox: FC<Props> = ({
  value,
  items,
  placeholder,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const filteredOptions = useMemo(
    () => items.filter((i) => contains(i.value, searchValue)),
    [items, searchValue]
  );

  const handleDeleteItem = (item: ComboBoxItem) => {
    onChange(value.filter((i) => i.id !== item.id));
  };

  return (
    <div className="w-full relative">
      <div className="w-full">
        <Combobox multiple value={value} onChange={onChange}>
          {({ open }) => (
            <>
              <div
                className={classNames(
                  'flex flex-col rounded-lg w-full border border-gray-300  min-h-10 px-3 py-1.25',
                  {
                    '!border-primary-600': open,
                    'justify-center': open && !value.length,
                  }
                )}
              >
                <Combobox.Button
                  className={classNames('flex', {
                    'grow-1': !open,
                    'items-center': value.length === 0 && placeholder,
                  })}
                >
                  {value.length === 0 && placeholder && !open && (
                    <div className="text-md text-gray-500 text-left flex-shrink-0">
                      {placeholder}
                    </div>
                  )}
                  <div className="flex flex-wrap gap-1.5 font-normal text-md text-gray-900 min-w-0 w-full">
                    {value.map((item) => (
                      <div
                        key={item.id}
                        className="flex gap-1.5 items-center bg-base-white border border-gray-300 rounded-md px-1.5 py-0.75 text-sm font-medium text-gray-700 overflow-hidden"
                      >
                        {item.headerTitle ?? item.optionTitle ?? item.value}
                        <Icon
                          glyph={IconMap.XClose}
                          width={14}
                          className="shrink-0 text-gray-400 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteItem(item);
                          }}
                        />
                      </div>
                    ))}
                    {open && (
                      <Input
                        onChange={setSearchValue}
                        placeholder={value.length > 0 ? undefined : placeholder}
                        className="grow-1"
                      />
                    )}
                  </div>
                </Combobox.Button>
              </div>

              <Combobox.Options className="flex flex-col gap-y-2 p-2 shadow-lg max-h-50 overflow-auto border rounded-lg border-gray-200 absolute z-50 -bottom-1 left-0 w-full translate-y-full bg-base-white">
                {filteredOptions.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        'cursor-pointer hover:bg-gray-50 rounded-md p-2 text-sm font-normal text-gray-700 flex flex-row justify-between',
                        {
                          'bg-gray-50': active,
                        }
                      )
                    }
                    value={item}
                  >
                    {item.optionTitle ?? item.value}
                    {value.some((i) => i.id === item.id) && (
                      <Icon glyph={IconMap.GreenCheckMark} width={20} />
                    )}
                  </Combobox.Option>
                ))}
                {searchValue && filteredOptions.length === 0 && (
                  <div className="text-sm text-gray-700 font-medium px-1.5 py-2.5">
                    No members found
                  </div>
                )}
              </Combobox.Options>
            </>
          )}
        </Combobox>
      </div>
    </div>
  );
};
