import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { authUserModel } from '../../../../entities/auth-user';
import { DBUser } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { Snippet, SnippetType, TeamFiltered } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { setSnippetsData } from '../../lib';
import { navigate } from '../../../../processes/navigation';
import { generatePath } from 'react-router-dom';
import { SNIPPET_EDITOR_ROUTE } from '../../../..//entities/history';

export function* createSnippet({
  payload: { callback, ...payloadData },
}: ReturnType<typeof snippetsModel.actions.createSnippet>) {
  try {
    yield put(snippetsModel.actions.setIsCreateSnippetLoading(true));

    const { id: userId }: DBUser = yield select(
      authUserModel.selectors.selectUser
    );
    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeam
    );

    const snippet: Snippet = yield call(snippetsApi.createSnippet, {
      ...payloadData,
      userId,
      teamId,
    });

    yield call(setSnippetsData);

    if (payloadData.type === SnippetType.TEXT) {
      yield put(
        navigate({
          to: generatePath(SNIPPET_EDITOR_ROUTE, {
            sequenceNumber: snippet.sequenceNumber,
          }),
        })
      );
    }

    callback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create snippet')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsCreateSnippetLoading(false));
  }
}
