import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { BlockingMethodType, Page } from '@distribute/shared/types';
import { boolean, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Input, PanelRadioGroup } from '../../../../../shared/ui';
import { Label } from './Label';

import { Icon } from '../../../../../shared/ui';

import cn from 'classnames';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
const BUTTON_LABEL_MAX_LENGTH = 60;

export type GatedContentType = {
  title: string;
  description: string;
  isFormName: boolean;
  isFormPhone: boolean;
  buttonLabelFormType: string;
  blockingMethod: BlockingMethodType;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

const validationSchema = object().shape({
  title: string().max(TITLE_MAX_LENGTH).required(),
  description: string().max(DESCRIPTION_MAX_LENGTH),
  isFormName: boolean(),
  isFormPhone: boolean(),
  buttonLabelFormType: string(),
  blockingMethod: string()
    .oneOf([BlockingMethodType.OBSCURATION, BlockingMethodType.REMOVAL])
    .required(),
});

const blockingMethodOptions = [
  {
    title: 'Removal',
    description: 'Page content are hidden on page.',
    id: BlockingMethodType.REMOVAL,
  },
  {
    title: 'Obscuration',
    description: 'Page content are visible but blurred.',
    id: BlockingMethodType.OBSCURATION,
  },
];

export const EditorSidebarGatedContentPanel: React.FC<Props> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setGatedContentEdited(undefined));
  };

  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContentWithError
  );
  const gatedContentEdited = useSelector(
    conversionKitModel.selectors.selectGatedContentEdited
  );

  const updateGatedContentIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateCTAIsLoading
  );

  const [isDescriptionEnabled, setDescriptionEnabled] = useState(
    !!gatedContent.description
  );

  const [description, setDescription] = useState<string>(
    gatedContent.description || ''
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = useForm<GatedContentType>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title: gatedContent.title || '',
        isFormName: gatedContent.isFormName,
        isFormPhone: gatedContent.isFormPhone,
        buttonLabelFormType: gatedContent.buttonLabelFormType || '',
        blockingMethod: gatedContent.blockingMethod,
      }),
      [gatedContent]
    ),
  });

  const { title, buttonLabelFormType, blockingMethod } = watch();

  const {
    field: { value: fullNameValue, onChange: onChangeFullName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const {
    field: { value: phoneValue, onChange: onChangePhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const {
    field: { onChange: changeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const {
    field: { onChange: setBlockingMethod },
  } = useController({
    control,
    name: 'blockingMethod',
  });

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setGatedContentEdited({
        ...gatedContent,
        title,
        description,
        isFormName: fullNameValue,
        isFormPhone: phoneValue,
        buttonLabelFormType,
        isDescriptionEnabled,
        blockingMethod,
        pagePercentage:
          gatedContentEdited?.pagePercentage || gatedContent.pagePercentage,
      })
    );
  }, [
    buttonLabelFormType,
    description,
    dispatch,
    fullNameValue,
    gatedContent,
    phoneValue,
    title,
    isDescriptionEnabled,
    blockingMethod,
  ]);

  const handleFormSubmit = (data: GatedContentType) => {
    dispatch(
      conversionKitModel.actions.updateGatedContent({
        documentContentId: currentPage.content.id,
        ...data,
        isActive: true,
        description: isDescriptionEnabled ? description : '',
        buttonLabelFormType: data.buttonLabelFormType || 'Submit',
        pagePercentage: Math.round(
          gatedContentEdited?.pagePercentage || gatedContent.pagePercentage || 0
        ),
        callback: handleClose,
      })
    );
  };

  return (
    <>
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Gated Content
        </h2>
      </header>
      <div className={cn('overflow-y-auto h-[calc(100%-192px)]')}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            <p className="text-gray-800 font-semibold mb-4">Components</p>

            <LabelToggle
              icon={IconMap.Type01}
              text="Title"
              isRequired
              checked={true}
              onChange={() => null}
              className="mb-3 mt-6"
              disabled
            />
            <Input
              {...register('title')}
              className="p-3"
              heightSize="md"
              type="text"
              placeholder="Add title"
              maxCharacters={TITLE_MAX_LENGTH}
              isError={!!errors.title}
              value={title}
            />
            <LabelToggle
              icon={IconMap.AlignLeft}
              text="Description"
              checked={isDescriptionEnabled}
              onChange={() => setDescriptionEnabled((prev) => !prev)}
              className="mb-3 mt-6"
            />
            {isDescriptionEnabled && (
              <RichTextEditor
                content={description}
                placeholder="Add text…"
                onUpdate={(content) => setDescription(content)}
                maxLength={DESCRIPTION_MAX_LENGTH}
              />
            )}
            <hr className="border-gray-200 my-6" />

            <p className="text-gray-800 font-semibold mb-4">Form Fields</p>
            <LabelToggle
              icon={IconMap.User03}
              text="Name"
              checked={fullNameValue}
              onChange={onChangeFullName}
              className="mb-3"
            />
            <LabelToggle
              icon={IconMap.EmailSymbol}
              text="Email"
              checked={true}
              onChange={() => null}
              disabled
              className="mb-3"
            />
            <LabelToggle
              icon={IconMap.Phone}
              text="Phone"
              checked={phoneValue}
              onChange={onChangePhone}
              className="mb-3"
            />
            <hr className="border-gray-200 my-6" />
            <p className="text-gray-800 font-semibold mb-4">Button</p>
            <Label icon={IconMap.TypeSquare} text="Label" className="mb-3" />
            <Input
              maxCharacters={BUTTON_LABEL_MAX_LENGTH}
              className="p-3"
              type="text"
              placeholder="Submit"
              {...register('buttonLabelFormType')}
              isError={!!errors.buttonLabelFormType}
              value={buttonLabelFormType}
              heightSize="md"
              onChange={({ target: { value } }) =>
                changeButtonLabelFormType(value)
              }
            />
            <p className="text-gray-800 font-semibold mb-4 mt-6">
              Blocking method
            </p>
            <PanelRadioGroup<BlockingMethodType>
              options={blockingMethodOptions}
              activeId={blockingMethod}
              onChange={setBlockingMethod}
              name={'blocking-method'}
            />
          </div>
          <EditorSidebarConversionsPanelFooter
            onClose={handleClose}
            addBtnTitle={
              gatedContent.buttonLabelFormType === null ? 'Add' : 'Save'
            }
            isAddBtnLoading={updateGatedContentIsLoading}
          />
        </form>
      </div>
    </>
  );
};
