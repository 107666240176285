import React, { FC } from 'react';
import cn from 'classnames';
import {
  TeamOnboardingStatus,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../../entities/auth-user';

type IProps = {
  step: UserOnboardingStatus | TeamOnboardingStatus;
};

const normalUserSteps = [
  UserOnboardingStatus.USAGE_PLAN,
  UserOnboardingStatus.CREATE_TEAM,
  TeamOnboardingStatus.INVITE_TEAMMATES,
  TeamOnboardingStatus.SUBSCRIPTION,
] as const;

const appsumoUserSteps = [
  UserOnboardingStatus.USAGE_PLAN,
  UserOnboardingStatus.CREATE_TEAM,
] as const;

export const StepsIndicator: FC<IProps> = ({ step }) => {
  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const steps = user.isAppsumo ? appsumoUserSteps : normalUserSteps;
  return (
    <div className="flex justify-center gap-3 py-8 w-full max-w-90 mx-auto">
      {steps.map((item) => (
        <div
          key={item}
          className={cn('grow h-2 rounded bg-gray-100', {
            '!bg-primary-700': item === step,
          })}
        />
      ))}
    </div>
  );
};
