import { RootState } from '../../../app';

export const selectIsLoadingInviteTeamMembers = ({
  teamMembersModel: { isLoadingInviteTeamMembers },
}: RootState) => isLoadingInviteTeamMembers;

export const selectIsLoadingDeleteTeamMember = ({
  teamMembersModel: { isLoadingDeleteTeamMember },
}: RootState) => isLoadingDeleteTeamMember;

export const selectIsShowInviteTeamMembersModal = ({
  teamMembersModel: { isShowInviteTeamMembersModal },
}: RootState) => isShowInviteTeamMembersModal;

export const selectIsWaitingForTeamInviteMembersModalOpen = ({
  teamMembersModel: { isWaitingForTeamInviteMembersModalOpen },
}: RootState) => isWaitingForTeamInviteMembersModalOpen;
