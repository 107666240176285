import { Page } from '@distribute/shared/types';
import { FilterState } from '../ui';

export const getFilteredPages = (pages: Page[], currentFilterState: string) => {
  switch (currentFilterState) {
    case FilterState.Drafts:
      return pages.filter((i) => !i.published);
    case FilterState.Published:
      return pages.filter((i) => i.published);
    default:
      return pages;
  }
};
