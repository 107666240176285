import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { SnippetTypesVariant } from '../lib/config';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const RadioButtonForSnippetTypes = forwardRef<HTMLInputElement, Props>(
  function RadioButton(
    { name, value, onChange, defaultChecked, disabled },
    ref
  ) {
    return (
      <label>
        <input
          type="radio"
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          defaultChecked={defaultChecked}
          ref={ref}
          className="hidden"
        />
        <div
          className={cn(
            `py-2.75 px-3 border rounded-xl flex flex-col items-center cursor-pointer gap-1.5 w-30 bg-base-white`,
            {
              'border-2 border-primary-600 hover:bg-primary-50': defaultChecked,
              'border border-gray-300 hover:bg-gray-50': !defaultChecked,
            }
          )}
        >
          <Icon
            glyph={
              value === SnippetTypesVariant.ALL
                ? IconMap.Shape01
                : IconMap.Shape02
            }
            width={24}
            className={cn({
              'text-primary-700': defaultChecked,
              'text-gray-600': !defaultChecked,
            })}
          />
          <span
            className={cn('text-sm font-semibold', {
              'text-primary-700': defaultChecked,
              'text-gray-700': !defaultChecked,
            })}
          >
            {value === SnippetTypesVariant.ALL ? 'All types' : 'Specific types'}
          </span>
        </div>
      </label>
    );
  }
);
