import { ViewerDetails } from '@distribute/shared/types';
import React, {
  FC,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { VIEWER_COLUMN } from '../../config';
import cn from 'classnames';
import { formatRow, hideColumn } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { viewersModel } from '../../model';
import {
  SectionGeneralInfo,
  SectionSource,
  SectionContact,
  SectionActivity,
  SectionTabActivity,
} from './Section';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  item: ViewerDetails;
  height: number;
};

export const TableRow: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ item, height }, ref) => {
    const rowRef = useRef<HTMLTableRowElement>(null);
    const [columns, setColumns] = useState(0);

    const dispatch = useDispatch();

    const isLeadCategory = useSelector(
      viewersModel.selectors.selectIsLeadCategory
    );
    const { details } = useSelector(viewersModel.selectors.selectViewers);

    useLayoutEffect(() => {
      const handler = () => {
        if (!rowRef.current) return;

        const children = [...rowRef.current.children];

        // Filter by elements that are not hidden
        setColumns(
          //eslint-disable-next-line
          children.filter((child: any) => Boolean(child?.offsetParent)).length
        );
      };

      handler();

      window.addEventListener('resize', handler, { passive: true });

      return () => window.removeEventListener('resize', handler);
    }, []);

    const isOpen = Boolean(details[item.id]);

    return (
      <>
        <tr ref={rowRef} className="hover:bg-gray-50">
          {VIEWER_COLUMN.map((column) => {
            return (
              <td
                key={column.name}
                className={cn(
                  'px-6 border-t border-t-gray-200 whitespace-nowrap truncate',
                  {
                    'text-gray-900 font-medium': column.name === 'email',
                    'lg:hidden': column.name === 'overallActivity',
                    'sm:hidden': column.name !== 'email',
                    hidden: hideColumn(column.name, isLeadCategory),
                  }
                )}
              >
                {formatRow(column.name, item)}
              </td>
            );
          })}
          <td className="p-4 border-t border-t-gray-200">
            <Button
              type="button"
              variant="icon"
              color="transparent"
              size="md"
              className="hover:bg-base-black-4 ml-auto"
              onClick={() => {
                dispatch(
                  viewersModel.actions.setDetails({
                    [item.id]: !details[item.id],
                  })
                );
              }}
            >
              <Icon
                glyph={IconMap.ArrowDown}
                width={20}
                className={cn('transition-all duration-150 text-gray-700', {
                  'rotate-180': isOpen,
                })}
              />
            </Button>
          </td>
        </tr>
        <tr>
          <td colSpan={columns} className="p-0">
            <div
              ref={ref}
              className={cn(
                'viewer-details px-6 overflow-hidden transition-all',
                { 'bg-gray-50 border-y border-gray-200': isOpen }
              )}
              style={{
                maxHeight: `${isOpen ? 'unset' : '0px'}`, // TODO: Ask Valentyn
              }}
            >
              <div className="grid auto-cols-fr grid-flow-col sm:grid-flow-row gap-6 py-6 last:border-0">
                {isLeadCategory ? (
                  <>
                    <SectionGeneralInfo
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                    <SectionSource
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                    <SectionTabActivity
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                  </>
                ) : (
                  <>
                    <SectionContact
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                    <SectionActivity
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                    <SectionTabActivity
                      className="border-r border-black-8 last:border-0 pr-6 last:pr-0"
                      item={item}
                    />
                  </>
                )}
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
);
