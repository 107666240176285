import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../../../../features/editor-sidebar';
import { IconMap } from '../../../../../shared/sprite';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { pagesModel } from '../../../../../features/pages';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  Page,
} from '@distribute/shared/types';
import { subscribePopupModel } from '../../../../../features/subscribe-popup';
import { gatedContentModel } from '../../../../../features/gated-content-block';
import { EditorSidebarConversionItem } from './';
import { squeezePageModel } from '../../../../../features/squeeze-page';
import { RequireEmailToViewConversionItem } from './RequireEmailToViewConversionItem';
import { Icon } from '../../../../../shared/ui';
import { templatesModel } from '../../../../../features/templates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

type Props = {
  isTemplateMode?: boolean;
};

export const EditorSidebarConversions: React.FC<Props> = ({
  isTemplateMode,
}) => {
  const dispatch = useDispatch();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  ) as TemplateExtended;

  const currentPageOrTemplate = isTemplateMode ? currentTemplate : currentPage;

  const alertBar = useSelector(
    conversionKitModel.selectors.selectAlertBarWithError
  );

  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const cta = useSelector(conversionKitModel.selectors.selectCTAWithError);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContentWithError
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePageWithError
  );

  const [isAlertBarActive, setAlertBarActive] = useState(alertBar.isActive);
  const [isPopUpActive, setPopUpActive] = useState(popUp.isActive);
  const [isCTAActive, setCTAActive] = useState(cta.isActive);

  const [isGatedContentActive, setIsGatedContentActive] = useState(
    gatedContent.isActive
  );
  const [isSqueezePageActive, setIsSqueezePageActive] = useState(
    squeezePage.isActive
  );

  useEffect(() => {
    setAlertBarActive(alertBar.isActive);
  }, [alertBar.isActive]);

  useEffect(() => {
    setPopUpActive(popUp.isActive);
  }, [popUp.isActive]);

  useEffect(() => {
    setCTAActive(cta.isActive);
  }, [cta.isActive]);

  useEffect(() => {
    setIsGatedContentActive(gatedContent.isActive);
  }, [gatedContent.isActive]);

  const toggleAlertBarStatus = () => {
    dispatch(
      conversionKitModel.actions.updateAlertBar({
        documentContentId: currentPageOrTemplate.content.id,
        isActive: !isAlertBarActive,
      })
    );

    setAlertBarActive(!isAlertBarActive);
  };

  const togglePopUpStatus = () => {
    dispatch(
      conversionKitModel.actions.updatePopUp({
        documentContentId: currentPageOrTemplate.content.id,
        isActive: !isPopUpActive,
      })
    );
    dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    setPopUpActive(!isPopUpActive);
  };

  const toggleCTAStatus = () => {
    dispatch(
      conversionKitModel.actions.updateCTA({
        documentContentId: currentPageOrTemplate.content.id,
        isActive: !isCTAActive,
      })
    );

    setCTAActive(!isCTAActive);
  };

  const toggleGatedContentStatus = () => {
    dispatch(
      conversionKitModel.actions.updateGatedContent({
        documentContentId: currentPageOrTemplate.content.id,
        isActive: !isGatedContentActive,
      })
    );
    dispatch(gatedContentModel.actions.setIsModalOpen(false));
    setIsGatedContentActive(!isGatedContentActive);
  };

  const toggleSqueezePageStatus = () => {
    dispatch(
      conversionKitModel.actions.updateSqueezePage({
        documentContentId: currentPageOrTemplate.content.id,
        isActive: !isSqueezePageActive,
      })
    );
    dispatch(squeezePageModel.actions.setIsOpen(false));
    setIsSqueezePageActive(!isSqueezePageActive);
  };

  const isShowAdvancedConversionSettings = useSelector(
    editorSidebarModel.selectors.selectIsShowAdvancedConversionSettings
  );

  const setAdvancedConversionSettingsOpen = () => {
    dispatch(
      editorSidebarModel.actions.setIsShowAdvancedConversionSettings(
        !isShowAdvancedConversionSettings
      )
    );
  };

  return (
    <div
      className={cn(
        'pt-6 pb-2 px-6 flex flex-col h-[calc(100%-64px)] overflow-y-auto',
        {
          'bg-gray-50': isTemplateMode,
        }
      )}
    >
      <h2 className="text-2xl font-display font-medium text-gray-900">
        Conversion tools
      </h2>
      <hr className="border-gray-300 mt-5 mb-6" />
      {!isTemplateMode && (
        <>
          <RequireEmailToViewConversionItem />
          <hr className="border-gray-200 my-6" />
        </>
      )}

      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={setAdvancedConversionSettingsOpen}
      >
        <div className="p-1">
          <Icon
            glyph={
              isShowAdvancedConversionSettings
                ? IconMap.ChevronUp
                : IconMap.ChevronDown
            }
            width={16}
            className="text-gray-600"
          />
        </div>

        <p className="text-gray-900 font-semibold">Advanced Conversions</p>
      </div>

      {isShowAdvancedConversionSettings && (
        <div className="flex flex-col flex-grow-1 gap-3 pt-4 pb-6 -mr-4 pr-4">
          <EditorSidebarConversionItem
            title="Alert Bar"
            description="Bar at the top of the page."
            icon={IconMap.AlertBarIcon}
            onClick={() => {
              document
                .getElementById('root')
                ?.scrollTo({ top: 0, behavior: 'smooth' });
              dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  ActiveConversionSettingsPanel.ALERT_BAR
                )
              );
            }}
            shouldDisplayToggle={!!alertBar.description}
            isActive={isAlertBarActive}
            handleToggle={toggleAlertBarStatus}
            isSetupPopupWarning={
              alertBar.actionType === ActionTypeAlertBar.POPUP &&
              (popUp.actionType === ActionTypePopUp.CALENDLY
                ? !popUp.calendarSchedulingLink
                : !popUp.title)
            }
          />
          <EditorSidebarConversionItem
            title="Pop-up"
            description="Timed or mouse-activated modal."
            icon={IconMap.PopupIcon}
            onClick={() =>
              dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  ActiveConversionSettingsPanel.POP_UP
                )
              )
            }
            shouldDisplayToggle={popUp.buttonLabelFormType !== null}
            isActive={isPopUpActive}
            handleToggle={togglePopUpStatus}
            onWarningClick={() =>
              dispatch(
                editorSidebarModel.actions.setSelectedPopupSection('triggers')
              )
            }
            isPopupWarning={
              isPopUpActive &&
              !popUp.isTriggerExit &&
              !popUp.isTriggerTime &&
              (!isAlertBarActive ||
                alertBar.actionType !== ActionTypeAlertBar.POPUP) &&
              (!isCTAActive || cta.actionType !== ActionTypeCTA.POPUP)
            }
          />
          <EditorSidebarConversionItem
            title="CTA Section"
            description="Section at the end of the page."
            icon={IconMap.ActionBannerIcon}
            onClick={() => {
              const root = document.getElementById('root');
              dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  ActiveConversionSettingsPanel.CTA
                )
              );
              setTimeout(() => {
                root?.scrollTo({
                  top: root.scrollHeight,
                  behavior: 'smooth',
                });
              }, 0);
            }}
            shouldDisplayToggle={cta.buttonLabelFormType !== null}
            isActive={isCTAActive}
            handleToggle={toggleCTAStatus}
            isSetupPopupWarning={
              cta.actionType === ActionTypeCTA.POPUP &&
              (popUp.actionType === ActionTypePopUp.CALENDLY
                ? !popUp.calendarSchedulingLink
                : !popUp.title)
            }
          />
          <EditorSidebarConversionItem
            title="Gated Content"
            description="Content is hidden from view."
            icon={IconMap.GatedContentIcon}
            onClick={() =>
              dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  ActiveConversionSettingsPanel.GATED_CONTENT
                )
              )
            }
            shouldDisplayToggle={gatedContent.buttonLabelFormType !== null}
            isActive={isGatedContentActive}
            handleToggle={toggleGatedContentStatus}
          />
          <EditorSidebarConversionItem
            title="Squeeze Page"
            description="Page is hidden from view."
            icon={IconMap.SqueezePageIcon}
            onClick={() =>
              dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  ActiveConversionSettingsPanel.SQUEEZE_PAGE
                )
              )
            }
            shouldDisplayToggle={squeezePage.buttonLabel !== null}
            isActive={isSqueezePageActive}
            handleToggle={toggleSqueezePageStatus}
          />
        </div>
      )}
    </div>
  );
};
