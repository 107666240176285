import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ErrorBoundary } from '../entities/error-boundary';
import { history } from '../entities/history';
import { StagingIndicator } from '../entities/staging-indicator';
import { HelmetProvider } from 'react-helmet-async';
import '../features/sentry-connect';
import { SnackBar } from '../features/snackbar';
import { ErrorPage, Routing } from '../pages';
import { NavigationModal } from '../processes/navigation';

import store from './model';

const App: FC = () => {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
  });

  return (
    <Provider store={store}>
      <HelmetProvider>
        <ErrorBoundary renderError={(error) => <ErrorPage error={error} />}>
          <Router history={history}>
            <Routing />
            <SnackBar />
            <StagingIndicator />
            <NavigationModal />
          </Router>
        </ErrorBoundary>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
export * from './model';
