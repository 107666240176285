import {
  GetViewersQueryParamsType,
  GetViewersResponseType,
  ExportCsvViewersQueryParamsType,
  GetTotalAnonymousViewersResponseType,
} from '@distribute/shared/api-types/viewers';
import { getQueryString } from '../../lib';
import { api } from './instance';

export const viewersApi = {
  async getViewers(
    pageId: string,
    {
      page,
      limit,
      search,
      from,
      to,
      category,
      isShowAnonymous,
    }: GetViewersQueryParamsType
  ) {
    const { data } = await api.get<GetViewersResponseType>(
      `viewers/${pageId}?${getQueryString({
        category,
        isShowAnonymous,
        page,
        limit,
        search,
        from: from?.toISOString(),
        to: to?.toISOString(),
      })}`
    );
    return data;
  },

  async getTotalAnonymousViewers(pageId: string) {
    const { data } = await api.get<GetTotalAnonymousViewersResponseType>(
      `viewers/${pageId}/total-anonymous-viewers`
    );
    return data.total;
  },

  async exportCsv(
    pageId: string,
    {
      search,
      from,
      to,
      category,
      isShowAnonymous,
    }: ExportCsvViewersQueryParamsType
  ) {
    const { data, headers } = await api.get<Blob>(
      `viewers/export-csv/${pageId}?${getQueryString({
        category,
        isShowAnonymous,
        search,
        from: from?.toISOString(),
        to: to?.toISOString(),
      })}`,
      { responseType: 'blob' }
    );

    return { data, headers };
  },
};
