import { call, put } from 'redux-saga/effects';
import { Analytics, Page } from '@distribute/shared/types';
import { analyticsApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import {
  calculateChartData,
  calculateEmptyState,
  calculateTotal,
} from '../../lib';

export function* setAnalyticsData(page: Page, withLoader = true) {
  try {
    if (withLoader) {
      yield put(actions.setIsLoadingData(true));
    }

    const data: Analytics = yield call(analyticsApi.getPageAnalytics, page.id);

    const emptyState = calculateEmptyState(data);
    const chartData = calculateChartData(data, page);
    const total = calculateTotal(data, emptyState);

    yield put(actions.setTotal(total));
    yield put(actions.setChartData(chartData));
    yield put(actions.setEmptyState(emptyState));
  } catch (e: unknown) {
    logger.error(e);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show analytics`)
      )
    );
  } finally {
    yield put(actions.setIsLoadingData(false));
  }
}
