import React from 'react';

import { isFirebaseStaging } from '../../shared/config';

export const StagingIndicator = () => {
  return isFirebaseStaging ? (
    <p
      className="fixed w-16 text-center inset-x-1/2 -translate-x-1/2
        transform top-0 text-xs text-error-700 bg-error-100 py-1 rounded"
    >
      Staging
    </p>
  ) : null;
};
