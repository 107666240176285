import { call, put } from 'redux-saga/effects';
import { editorSidebarModel } from '..';
import { personalizedCoverApi } from '../../../../shared/api/axios/personalized-cover';
import { logger } from '../../../../shared/lib';

export function* updatePersonalizedCover({
  payload: { callback, ...payloadData },
}: ReturnType<typeof editorSidebarModel.actions.updatePersonalizedCover>) {
  try {
    yield put(editorSidebarModel.actions.setPersonalizedCoverUpdating(true));
    yield call(personalizedCoverApi.updatePersonalizedCover, payloadData);
    callback?.();
  } catch (error) {
    logger.error(error);
  } finally {
    yield put(editorSidebarModel.actions.setPersonalizedCoverUpdating(false));
  }
}
