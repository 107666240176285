import React, { FC, useState } from 'react';
import { IconMap } from '../../../shared/sprite';
import { FeatureFlag, FeatureFlags } from '../../../entities/feature-flag';
import { IntegrationItem } from './IntegrationItem';
import { ZapierDetailsModal } from './ZapierDetailsModal';

export const Zapier: FC = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);

  return (
    <FeatureFlag feature={FeatureFlags.ZAPIER}>
      <ZapierDetailsModal
        isOpen={isShowDetails}
        onClose={() => setIsShowDetails(false)}
      />
      <IntegrationItem
        icon={IconMap.Zapier}
        title="Zapier"
        description="Zapier is a powerful automation tool that connects over 3,000+ apps
        for seamless data transfer and task automation without coding. Boost productivity effortlessly."
        buttonText="Learn more"
        onClick={() => setIsShowDetails(true)}
      />
    </FeatureFlag>
  );
};
