import {
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_SUPERUSER_ROUTE,
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_STYLES_ROUTE,
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  TEAM_SETTINGS_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
} from '../../../entities/history';

export const labelsByRoutes = [
  {
    route: TEAM_SETTINGS_INFO_ROUTE,
    label: 'Workspace Settings',
  },
  { route: TEAM_SETTINGS_BRAND_ROUTE, label: 'Company information' },
  { route: TEAM_SETTINGS_STYLES_ROUTE, label: 'Styles' },
  { route: TEAM_SETTINGS_MEMBERS_ROUTE, label: 'Members' },
  {
    route: TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
    label: 'Plans & Billing',
  },
  {
    route: TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
    label: 'Permissions Control',
  },
  {
    route: TEAM_SETTINGS_ROUTE,
    label: 'Workspace Settings',
  },
  {
    route: SETTINGS_PROFILE_ROUTE,
    label: 'Profile Settings',
  },
  {
    route: SETTINGS_INTEGRATIONS_ROUTE,
    label: 'Integrations',
  },
  {
    route: SETTINGS_SUPERUSER_ROUTE,
    label: 'Superuser settings',
  },
  {
    route: SETTINGS_ROUTE,
    label: 'Profile Settings',
  },
];
