import { call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../teams';
import { templatesApi } from '../../../shared/api/axios';
import { GetTemplatesResponseType } from '@distribute/shared/api-types/templates';
import { logger } from '../../../shared/lib';
import { templatesModel } from '../model';

export function* setRecentTemplatesData() {
  try {
    const { id: currentTeamId } = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const templates: GetTemplatesResponseType = yield call(
      templatesApi.getRecentTemplates,
      currentTeamId
    );

    yield put(templatesModel.actions.setRecentTemplates(templates));
  } catch (err) {
    logger.error(err);
  }
}
