import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Folder } from '@distribute/shared/types';
import { DuplicatePageAction, LoadFoldersAction } from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  folders: Folder[];
  loadingFoldersForTeamId: number | null;
  isDuplicatingPage: boolean;
};
const initialState: State = {
  folders: [],
  loadingFoldersForTeamId: null,
  isDuplicatingPage: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'publicTemplates',
  initialState,
  reducers: {
    setFolders: (state, { payload: folders }: PayloadAction<Folder[]>) => ({
      ...state,
      folders,
    }),
    setLoadingFoldersForTeamId: (
      state,
      { payload: loadingFoldersForTeamId }: PayloadAction<number | null>
    ) => ({
      ...state,
      loadingFoldersForTeamId,
    }),
    setIsDuplicatingPage: (
      state,
      { payload: isDuplicatingPage }: PayloadAction<boolean>
    ) => ({
      ...state,
      isDuplicatingPage,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  loadFolders: createAction<LoadFoldersAction>('publicTemplates/loadFolders'),
  duplicatePage: createAction<DuplicatePageAction>(
    'publicTemplates/duplicatePage'
  ),
};
