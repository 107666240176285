import { call, put, select } from 'redux-saga/effects';

import { ctaApi } from '../../../../shared/api';
import { CTA } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectCTA } from '../selectors';

export function* updateCtaDB({
  payload: { documentContentId },
}: ReturnType<typeof conversionKitModel.actions.updateCtaDB>) {
  try {
    const cta: CTA = yield select(selectCTA);

    const { id: _, ...data } = cta;

    yield call(ctaApi.updateCTA, documentContentId, data);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update cta`)
      )
    );
  }
}
