import { yupResolver } from '@hookform/resolvers/yup';
import { createPageModalModel } from '../../../../../../entities/create-page-modal';
import { teamsModel } from '../../../../../../features/teams';
import { Button, Input } from '../../../../../../shared/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { pagesModel } from '../../../../../../features/pages';

type Schema = { pageName: string };

const inputSchema = object().shape({
  pageName: string(),
});

type Props = {
  onCreatePage(pageName?: string): void;
};

export const ChoosePageTitleStep: React.FC<Props> = ({ onCreatePage }) => {
  const selectedCompany = useSelector(
    createPageModalModel.selectors.selectSelectedBrandingCompany
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isPageLoading = useSelector(
    pagesModel.selectors.selectCreatePageIsLoading
  );

  const { register, handleSubmit, watch } = useForm<Schema>({
    resolver: yupResolver(inputSchema),
    defaultValues: {
      pageName: `${currentTeam.brandCompanyName || 'Unlimited horizons'} for ${
        selectedCompany?.name
      }`,
    },
  });

  const pageName = watch('pageName');

  return (
    <form
      className="relative flex flex-col items-center justify-center h-full"
      onSubmit={handleSubmit(() => onCreatePage(pageName))}
    >
      <main className="flex flex-col justify-start items-center h-139 w-140">
        <h2 className="text-display-md text-gray-900 font-medium font-display mb-2">
          Title of the page
        </h2>
        <p className="text-gray-600 mb-10">Name this page effectively</p>
        <Input
          {...register('pageName')}
          type="text"
          placeholder="Page name"
          className="border-none !text-3xl !p-0 h-9.5 !placeholder-gray-300 font-bold rounded-none bg-transparent text-center"
        />
      </main>
      <div className="flex items-center w-full justify-center absolute bottom-0 py-6 border-t border-gray-200">
        <Button
          loading={isPageLoading}
          variant="text"
          color="primary"
          type="submit"
          className="w-160"
          size="xl"
        >
          Create page
        </Button>
      </div>
    </form>
  );
};
