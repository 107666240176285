import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { videoRecordsApi } from '../../../../shared/api';

import cn from 'classnames';

import {
  DEFAULT_RATIO,
  getMuxImage,
  getMuxStream,
} from '@distribute/shared/utils';

import { Player } from '@distribute/frontend/ui/player';

import { useEffect, useState } from 'react';
import { VideoRecordAttrs } from './types';

import { updateAttributesTransaction } from '../../lib';

import { checkIsRecordPrepared } from '../../../../features/recordings/lib';

export type Props = NodeViewProps & {
  node: NodeViewProps['node'] & {
    attrs: VideoRecordAttrs;
  };
};

export const VideoRecordNodeView = ({ node, editor, getPos }: Props) => {
  const { prefix, playbackId, aspectRatio, data } = node.attrs;

  const [loading, setLoading] = useState(() => !node.attrs.playbackId);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (playbackId) return;

    // Migration for playbackId, aspectRatio
    if (data && checkIsRecordPrepared(data)) {
      updateAttributesTransaction(editor, getPos(), {
        ...node.attrs,
        playbackId: data.playbackId,
        aspectRatio: data.aspectRatio,
      });
      setLoading(false);
      return;
    }

    if (!prefix) {
      setLoading(false);
      return;
    }

    let cancelled = false;

    videoRecordsApi
      .getVideoRecordForPlayback(prefix)
      .then((data) => {
        if (cancelled) return;

        updateAttributesTransaction(editor, getPos(), {
          ...node.attrs,
          playbackId: data.playbackId,
          aspectRatio: data.aspectRatio,
        });
        setLoading(false);
      })
      .catch(() => {
        if (cancelled) return;

        setError(true);
      });

    return () => {
      cancelled = true;
    };
    //eslint-disable-next-line
  }, []);

  return (
    <NodeViewWrapper
      style={{ aspectRatio: aspectRatio || DEFAULT_RATIO }}
      className={cn('overflow-hidden !border-none')}
    >
      <Player
        loading={loading}
        error={error || !playbackId}
        aspectRatio={aspectRatio || DEFAULT_RATIO}
        url={getMuxStream(playbackId || '')}
        posterUrl={getMuxImage(playbackId || '')}
      />
    </NodeViewWrapper>
  );
};
