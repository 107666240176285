import { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { Icon, Icons } from '../icons';
import { usePlayer } from '../lib';
import { ActionKind } from '../provider';

const styles = {
  sm: {
    outerBorder: 'border-2',
    button: 'border-2 w-16 h-16',
    icon: 'w-9 h-9',
  },
  md: {
    outerBorder: 'border-4',
    button: 'w-22 h-22 border-4',
    icon: 'w-12 h-12',
  },
} as const;

export const PlayerPreview: FC = () => {
  const { config, dispatch, size } = usePlayer();
  const { posterUrl } = config;

  const [src, setSrc] = useState('');

  useEffect(() => {
    fetch(posterUrl, { method: 'HEAD' })
      .then((response) => {
        if (response.ok) {
          setSrc(posterUrl);
          return;
        }

        if (response.status === 404) {
          dispatch({ type: ActionKind.ERROR });
          return;
        }
      })
      .catch(() => {
        dispatch({ type: ActionKind.ERROR });
      });

    //eslint-disable-next-line
  }, []);

  if (!src) return null;

  return (
    <div
      className="relative flex bg-base-black h-full"
      onClick={() => dispatch({ type: ActionKind.START })}
    >
      <div className="absolute inset-0 grid place-items-center bg-base-black/50">
        <div
          className={cn(
            'border-base-white/50 rounded-full',
            styles[size].outerBorder
          )}
        >
          <button
            type="button"
            className={cn(
              'rounded-full border-base-white bg-primary-500 hover:bg-primary-600 grid place-items-center',
              styles[size].button
            )}
          >
            <Icon
              svg={Icons.PlayPreview}
              className={cn('text-base-white', styles[size].icon)}
            />
          </button>
        </div>
      </div>
      <img
        src={src}
        alt="poster"
        className="block w-full h-full object-contain"
      />
    </div>
  );
};
