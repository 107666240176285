import { RootState } from '../../../app';

export const selectLinkedinProfileId = ({ social }: RootState) =>
  social.linkedinProfileId;

export const selectLinkedinProfileName = ({ social }: RootState) =>
  social.linkedinProfileName;

export const selectLinkedinProfilePicture = ({ social }: RootState) =>
  social.linkedinProfilePicture;

export const selectIsSharingLinkedinPost = ({ social }: RootState) =>
  social.isSharingLinkedinPost;

export const selectIsLoggedIntoLinkedin = ({ social }: RootState) =>
  social.isLoggedIntoLinkedin;

export const selectIsRevokingLinkedinToken = ({ social }: RootState) =>
  social.isRevokingLinkedinToken;

export const selectSocialPostContent = ({ social }: RootState) =>
  social.socialPostContent;

export const selectIsPostCoverLoading = ({ social }: RootState) =>
  social.isPostCoverLoading;

export const selectIsConnectingToLinkedin = ({ social }: RootState) =>
  social.isConnectingToLinkedin;

export const selectIsConnectingToTwitter = ({ social }: RootState) =>
  social.isConnectingToTwitter;

export const selectIsLoggedIntoTwitter = ({ social }: RootState) =>
  social.isLoggedIntoTwitter;

export const selectIsSharingTwitterPost = ({ social }: RootState) =>
  social.isSharingTwitterPost;

export const selectIsRevokingTwitterToken = ({ social }: RootState) =>
  social.isRevokingTwitterToken;

export const selectTwitterProfileName = ({ social }: RootState) =>
  social.twitterProfileName;

export const selectTwitterProfilePicture = ({ social }: RootState) =>
  social.twitterProfilePicture;
