import { SpriteGlyph } from './Icon';
import { Icon, Toggle, UpgradePlanBadge } from '.';
import React from 'react';
import cn from 'classnames';
import { useCurrentPlan } from '../../features/subscription/hooks';
import { FREE_SUBSCRIPTION_PLAN_ID } from '../../features/subscription/config';
import { useDispatch } from 'react-redux';
import { subscriptionModel } from '../../features/subscription';

type Props = {
  text: string;
  checked: boolean;
  onChange(value: boolean): void;
  icon: SpriteGlyph;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  iconClassName?: string;
  textClassName?: string;
  availableForPaidPlan?: boolean;
};

export const LabelToggle: React.FC<Props> = ({
  text,
  checked,
  onChange,
  icon,
  className,
  isRequired,
  disabled,
  iconClassName,
  textClassName,
  availableForPaidPlan = false,
}) => {
  const dispatch = useDispatch();

  const currentPlan = useCurrentPlan();
  const hasFreePlan = currentPlan.id === FREE_SUBSCRIPTION_PLAN_ID;

  const disabledByPlan = availableForPaidPlan && hasFreePlan;

  const handleUpgradeClick = () => {
    if (disabledByPlan) {
      dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
    }
  };

  return (
    <div className={cn('flex justify-between relative', className)}>
      <div className="flex gap-2 items-center">
        <Icon
          glyph={icon}
          width={20}
          className={cn(iconClassName, {
            'text-gray-400': disabledByPlan,
            'text-gray-700': !disabledByPlan,
          })}
        />
        <p
          className={cn('text-sm font-medium', textClassName, {
            'text-gray-500': disabledByPlan,
            'text-gray-700': !disabledByPlan,
          })}
        >
          {text}
          {isRequired && <span className="text-error-500 ml-0.5">*</span>}
        </p>
      </div>

      <div className="flex items-center gap-2">
        {disabledByPlan && <UpgradePlanBadge isShort />}

        <Toggle
          checked={checked}
          onChange={onChange}
          disabled={disabled || disabledByPlan}
          disabledWithBg
        />
      </div>

      {disabledByPlan && (
        <button
          className="absolute top-0 left-0 w-full h-full outline-none cursor-pointer"
          onClick={handleUpgradeClick}
        />
      )}
    </div>
  );
};
