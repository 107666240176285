import { takeEvery } from 'redux-saga/effects';
import { conversionKitModel } from '..';

export function* updateConversionsWorker() {
  yield takeEvery(
    conversionKitModel.actions.updateAlertBar,
    conversionKitModel.sagas.updateAlertBar
  );
  yield takeEvery(
    conversionKitModel.actions.updatePopUp,
    conversionKitModel.sagas.updatePopUp
  );
  yield takeEvery(
    conversionKitModel.actions.updateCTA,
    conversionKitModel.sagas.updateCTA
  );
  yield takeEvery(
    conversionKitModel.actions.updateGatedContent,
    conversionKitModel.sagas.updateGatedContent
  );
  yield takeEvery(
    conversionKitModel.actions.changePopUpImage,
    conversionKitModel.sagas.changePopUpImage
  );
  yield takeEvery(
    conversionKitModel.actions.changeCTAImage,
    conversionKitModel.sagas.changeCTAImage
  );
  yield takeEvery(
    conversionKitModel.actions.removeCTAImage,
    conversionKitModel.sagas.removeCTAImage
  );
  yield takeEvery(
    conversionKitModel.actions.removePopUpImage,
    conversionKitModel.sagas.removePopUpImage
  );
  yield takeEvery(
    conversionKitModel.actions.updateSqueezePage,
    conversionKitModel.sagas.updateSqueezePage
  );
  yield takeEvery(
    conversionKitModel.actions.changeSqueezePageImage,
    conversionKitModel.sagas.changeSqueezePageImage
  );
  yield takeEvery(
    conversionKitModel.actions.removeSqueezePageImage,
    conversionKitModel.sagas.removeSqueezePageImage
  );
  yield takeEvery(
    conversionKitModel.actions.updateAlertBarDB,
    conversionKitModel.sagas.updateAlertBarDB
  );
  yield takeEvery(
    conversionKitModel.actions.updateCtaDB,
    conversionKitModel.sagas.updateCtaDB
  );
  yield takeEvery(
    conversionKitModel.actions.updatePopUpDB,
    conversionKitModel.sagas.updatePopUpDB
  );
  yield takeEvery(
    conversionKitModel.actions.updateGatedContentDB,
    conversionKitModel.sagas.updateGatedContentDB
  );
  yield takeEvery(
    conversionKitModel.actions.updateSqueezePageDB,
    conversionKitModel.sagas.updateSqueezePageDB
  );
  yield takeEvery(
    conversionKitModel.actions.updateRequireEmailToView,
    conversionKitModel.sagas.updateRequireEmailToView
  );
}
