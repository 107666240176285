import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '../../../entities/auth-operation';
import { redirectActions } from '../../../entities/history';

import { Input, Button, SimpleLayout, Link } from '../../../shared/ui';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileSettingModel } from '../../../features/profile-settings';

const validationSchema = object().shape({
  email: string().email('Email is not valid').required('Email is required'),
});

type ForgotPasswordForm = {
  email: string;
};

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(validationSchema),
  });
  const authError = useSelector(authModel.selectors.selectAuthError);
  const loading = useSelector(authModel.selectors.selectLoading);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleFormSubmit = ({ email }: ForgotPasswordForm) => {
    setSubmitted(true);
    dispatch(profileSettingModel.actions.resetPasswordClick({ email }));
  };

  const handleBackToLoginClick = () => {
    dispatch(redirectActions.toLoginClick());
  };

  const formVisible = !submitted || loading || authError;

  return (
    <>
      <Helmet title="Forgot Password" />
      <SimpleLayout>
        <div className="mb-3 flex justify-center items-center">
          <p className="text-display-md font-medium text-gray-900 font-display text-center">
            Forgot password
          </p>
        </div>
        {formVisible ? (
          <>
            <div className="mb-6 flex justify-center items-center">
              <p className="font-normal text-md text-center text-gray-600">
                No worries, we'll send you reset instructions.
              </p>
            </div>

            <form
              className="mb-6"
              onSubmit={handleSubmit(handleFormSubmit)}
              noValidate
            >
              <div>
                <Input
                  {...register('email')}
                  type="email"
                  isError={!!errors.email || !!authError}
                  label="Email"
                />
              </div>

              <div className="my-6">
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  color="primary"
                  variant="text"
                  fullWidth
                >
                  Reset Password
                </Button>
              </div>
            </form>

            <div className="mb-6 flex justify-center items-center">
              <Link
                href="/login"
                size="medium"
                onClick={(e) => {
                  e.preventDefault();
                  handleBackToLoginClick();
                }}
              >
                Back to log in
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="items-center mt-3 text-center">
              <span className="font-normal text-md text-gray-600 text-center">
                If a matching account was found, an email regarding your
                password change has been sent to your email address: <br />
                <strong>{getValues('email')}</strong>
              </span>
            </div>
            <div className="mt-6">
              <Button
                type="button"
                color={'primary'}
                variant="text"
                fullWidth
                onClick={handleBackToLoginClick}
              >
                Back to Log In
              </Button>
            </div>
          </>
        )}
      </SimpleLayout>
    </>
  );
};
