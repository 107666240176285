import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  BlockingMethodType,
  ConversionKitCalendarData,
  GatedContent,
  Restriction,
} from '@distribute/shared/types';

export type UpdateAlertBarAction = {
  documentContentId: number;
  description?: string;
  actionType?: ActionTypeAlertBar;
  buttonLabelLinkType?: string;
  buttonLabelPopupType?: string;
  buttonUrl?: string;
  callback?: () => void;
  isActive?: boolean;
};

export type UpdatePopUpAction = Partial<ConversionKitCalendarData> & {
  documentContentId: number;
  isRemoveImage?: boolean;
  title?: string | null;
  description?: string | null;
  actionType?: ActionTypePopUp;
  isFormName?: boolean;
  isFormPhone?: boolean;
  isFormMessage?: boolean;
  buttonLabelFormType?: string;
  buttonLabelLinkType?: string;
  buttonUrl?: string;
  isTriggerTime?: boolean;
  triggerTimeDelay?: string;
  triggerTimeVisits?: string;
  isTriggerExit?: boolean;
  callback?: () => void;
  isActive?: boolean;
};

export type UpdateCTAAction = Partial<ConversionKitCalendarData> & {
  documentContentId: number;
  isRemoveImage?: boolean;
  imageUrl?: string;
  title?: string;
  description?: string;
  actionType?: ActionTypeCTA;
  isFormName?: boolean;
  isFormPhone?: boolean;
  isFormMessage?: boolean;
  buttonLabelFormType?: string;
  buttonLabelLinkType?: string;
  buttonLabelPopupType?: string;
  buttonUrl?: string;
  isActive?: boolean;
  callback?: () => void;
};
export type UpdateGatedContentAction = {
  documentContentId: number;
  title?: string;
  description?: string;
  isFormName?: boolean;
  isFormPhone?: boolean;
  buttonLabelFormType?: string;
  isActive: boolean;
  blockingMethod?: BlockingMethodType;
  pagePercentage?: number;
  callback?: () => void;
};

export type GatedContentEdited = {
  isDescriptionEnabled: boolean;
} & GatedContent;

export type UpdateStatusAction = {
  documentContentId: number;
  isActive: boolean;
};

export type UploadImageAction = {
  documentContentId: number;
  image: File;
  cb?: (url: string, fileName: string) => void;
};

export type RemoveImageAction = {
  documentContentId: number;
};

export type RemoveCTAImageAction = {
  documentContentId: number;
};

export type RemovePopUpImageAction = {
  documentContentId: number;
};

export type UpdateSqueezePageAction = {
  documentContentId: number;
  isRemoveImage?: boolean;
  isActive: boolean;
  title?: string;
  description?: string | null;
  isFormName?: boolean;
  isFormPhone?: boolean;
  buttonLabel?: string;
  callback?: () => void;
};

export enum UpdateRequireEmailToViewActionTypes {
  Activation = 'activation',
  Authentication = 'authentication',
  Restriction = 'restriction',
}

export type UpdateRequireEmailToViewActivationAction = {
  type: UpdateRequireEmailToViewActionTypes.Activation;
  isActive: boolean;
};
export type UpdateRequireEmailToViewAuthenticationAction = {
  type: UpdateRequireEmailToViewActionTypes.Authentication;
  isAuthEnabled: boolean;
};

export type UpdateRequireEmailToViewRestrictionAction = {
  type: UpdateRequireEmailToViewActionTypes.Restriction;
  hasRestrictions?: boolean;
  restrictions?: Restriction;
};

export type UpdateRequireEmailToViewDataAction =
  | UpdateRequireEmailToViewActivationAction
  | UpdateRequireEmailToViewAuthenticationAction
  | UpdateRequireEmailToViewRestrictionAction;

export type UpdateRequireEmailToViewAction = {
  documentContentId: number;
  isActive: boolean;
  isTemplate?: boolean;
  callback?: (err?: Error) => void;
} & UpdateRequireEmailToViewDataAction;

export type updateConversionDBAction = {
  documentContentId: number;
};
