import React, { forwardRef, useMemo, useCallback } from 'react';
import { Editor } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import * as ContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';

import {
  BASE_LINE_ACTION_MENU_GROUPS,
  LINE_ACTION_MENU_GROUPS,
} from './lib/lineActionMenuGroups';
import { LineActionMenuItem, PartialOptions } from './lib/types';
import { addButtonHoveringBlockPluginKey } from '../HoveringBlock';
import { useSnippetsPermissions } from '../../../../features/teams';
import { getMenuItems } from './helpers/getMenuItems';

type Props = {
  editor: Editor;
  children?: React.ReactNode;
  nodePosition: number | null;
  onOpenChange?: (open: boolean) => void;
};

type ButtonWrapperProps = {
  children?: React.ReactNode;
};

type MenuItemProps = {
  editor: Editor;
  node: Node;
  item: LineActionMenuItem;
  onClick: (item: LineActionMenuItem, options: PartialOptions) => void;
};

const ButtonWrapper = forwardRef<HTMLSpanElement, ButtonWrapperProps>(
  ({ children, ...rest }, ref) => {
    return (
      <span {...rest} ref={ref}>
        {children}
      </span>
    );
  }
);

const MenuItem = ({ item, onClick }: MenuItemProps) => {
  const handleClick = useCallback(() => {
    onClick(item, {} as PartialOptions);
  }, [item]);

  return (
    <ContextMenu.Item
      key={item.key}
      className={classNames(
        'relative group/item flex items-center gap-2 p-2.5 text-sm text-gray-600 hover:bg-base-black-4 hover:text-gray-700 rounded-md cursor-pointer',
        {
          'mt-2': item.isSeparatedFromTop,
        }
      )}
      onClick={handleClick}
    >
      {item.icon}
      <span className="text-gray-700 group-hover/item:text-gray-800">
        {item.name}
      </span>
      {item.isSeparatedFromTop && (
        <div className="h-px bg-gray-200 absolute -top-1 left-0 -ml-1.75 w-[108%]" />
      )}
    </ContextMenu.Item>
  );
};

const SubMenuItem = ({ item, editor, node }: MenuItemProps) => (
  <ContextMenu.Sub key={item.key}>
    <ContextMenu.SubTrigger
      className="group/item flex items-center gap-2 p-2.5 text-sm text-gray-600 hover:bg-base-black-4 hover:text-gray-700 rounded-md cursor-pointer"
      asChild
    >
      <span>
        {item.icon}
        <span className="text-gray-700 group-hover/item:text-gray-800">
          {item.name}
        </span>
      </span>
    </ContextMenu.SubTrigger>
    <ContextMenu.Portal>
      <ContextMenu.SubContent
        hideWhenDetached
        forceMount
        alignOffset={-5}
        sideOffset={10}
        className="min-w-40 left-20 bg-base-white z-[9999] border border-gray-300 rounded-xl p-1.5 shadow-xl"
      >
        {item.subItems?.map((subItem: LineActionMenuItem) => (
          <ContextMenu.Item
            key={subItem.name}
            className={classNames(
              'group/item flex items-center gap-2 p-2.5 text-gray-600 rounded-md cursor-pointer',
              {
                'hover:bg-base-black-4 hover:text-gray-700': !subItem.component,
              }
            )}
          >
            {subItem.component ? (
              <subItem.component editor={editor} node={node} />
            ) : (
              <>
                {subItem.icon}
                <span className="text-gray-700 group-hover/item:text-gray-800">
                  {subItem.name}
                </span>
              </>
            )}
          </ContextMenu.Item>
        ))}
      </ContextMenu.SubContent>
    </ContextMenu.Portal>
  </ContextMenu.Sub>
);

export const LineActionMenu = ({
  editor,
  children,
  nodePosition,
  onOpenChange,
}: Props) => {
  const snippetsPermissions = useSnippetsPermissions();

  const isNodeInEditor =
    nodePosition !== null && nodePosition < editor.state.doc.content.size;
  const node = isNodeInEditor ? editor.state.doc.nodeAt(nodePosition) : null;
  const menuType = node?.type.name;
  const menuItems: LineActionMenuItem[] = useMemo(() => {
    const currentMenuItems = LINE_ACTION_MENU_GROUPS[menuType as string] ?? [];

    return getMenuItems({
      baseMenuItems: BASE_LINE_ACTION_MENU_GROUPS,
      currentMenuItems,
      snippetsPermissions,
      menuType,
    });
  }, [menuType, snippetsPermissions]);

  const handleClick = useCallback(
    (item: LineActionMenuItem, options: PartialOptions) => {
      const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
        editor?.state
      );
      hoveringBlockPluginState?.hoveredBlockNode &&
        item.action?.(editor, {
          node: hoveringBlockPluginState?.hoveredBlockNode,
          nodePosition: hoveringBlockPluginState?.hoveredBlockPosition ?? 0,
          ...options,
        });
    },
    [editor]
  );

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open);
    },
    [onOpenChange]
  );

  return (
    <ContextMenu.Root onOpenChange={handleOpenChange}>
      <ContextMenu.Trigger asChild>
        <ButtonWrapper>{children}</ButtonWrapper>
      </ContextMenu.Trigger>

      <ContextMenu.Portal>
        <ContextMenu.Content
          alignOffset={50}
          className="min-w-40 left-20 bg-base-white z-[9999] border border-gray-300 rounded-xl p-1.5 shadow-xl"
        >
          {menuItems.map((item: LineActionMenuItem) =>
            Array.isArray(item.subItems) ? (
              <SubMenuItem
                item={item}
                key={item.key}
                editor={editor}
                node={node as Node}
                onClick={handleClick}
              />
            ) : (
              <MenuItem
                item={item}
                key={item.key}
                editor={editor}
                node={node as Node}
                onClick={handleClick}
              />
            )
          )}
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};
