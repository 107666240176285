import { redirect } from '../../../../entities/history';
import { authChannelModel } from '../../../../entities/auth-channel';
import { authModel } from '../../../../entities/auth-operation';
import { authApi } from '../../../../shared/api';
import { auth } from '../../../../shared/config';
import { signInWithCustomToken } from 'firebase/auth';
import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* loginAsSuperuser(
  action: ReturnType<typeof authModel.actions.loginAsSuperuser>
) {
  try {
    yield put(authModel.actions.setAuthIsLoading(true));

    const {
      payload: { email },
    } = action;

    const { token } = yield call(authApi.loginAsSuperuser, { email });

    yield auth.signOut();

    yield signInWithCustomToken(auth, token);

    yield put(authChannelModel.chan, authChannelModel.loggedIn());

    yield call(redirect.toWorkspace);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to log in as superuser')
      )
    );
  } finally {
    yield put(authModel.actions.setAuthIsLoading(false));
  }
}
