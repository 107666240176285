import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Button, Modal } from '../../../../../shared/ui';
import { Information } from './Information';
import { CancelSubscriptionForm, Form } from './Form';
import {
  cancelSubscriptionReasons,
  CANCEL_SUBSCRIPTION_OTHER_REASON_ID,
} from '../../../config';
import { useCurrentPlan } from '../../../hooks';
import { subscriptionModel } from '../../../model';
import { teamsModel } from '../../../../teams';
import { environment } from '../../../../../environments/environment';
import { authUserModel } from '../../../../../entities/auth-user';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onShowRenewWithPromoCode: () => void;
};

export const CancelSubscriptionModal: FC<Props> = ({
  isOpen,
  onClose,
  onShowRenewWithPromoCode,
}) => {
  const [step, setStep] = useState<'form' | 'information'>('information');
  const isCancelingSubscription = useSelector(
    subscriptionModel.selectors.selectIsCancelingSubscription
  );
  const { id: teamId } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentPlan = useCurrentPlan();
  const dispatch = useDispatch();
  const isStepForm = step === 'form';

  const handleClose = () => {
    if (isCancelingSubscription) return;

    setStep('information');
    onClose();
  };

  const handleCancelSubscription = (data: CancelSubscriptionForm) => {
    const reasons = cancelSubscriptionReasons
      .filter(
        (item) =>
          data.reasons.includes(item.id) &&
          item.id !== CANCEL_SUBSCRIPTION_OTHER_REASON_ID
      )
      .map((item) => item.name);

    if (data.otherReasonText) reasons.push(data.otherReasonText);

    dispatch(
      subscriptionModel.actions.cancelSubscription({
        teamId,
        reasonsText: reasons.join(', '),
        environment: environment.env,
        userEmail: currentUser.email,
        cb: (isRenewPromoCodeWasCreated) => {
          if (isRenewPromoCodeWasCreated) {
            onShowRenewWithPromoCode();
          }

          handleClose();
        },
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={cn('sm:!w-full', {
        '!w-120': !isStepForm,
        '!w-150': isStepForm,
      })}
      title={
        isStepForm
          ? 'What is the reason for downgrading?'
          : `Cancel ${currentPlan.name} Plan?`
      }
      isShowCancelButton={false}
    >
      {isStepForm ? (
        <Form onSubmit={handleCancelSubscription} />
      ) : (
        <Information onConfirm={() => setStep('form')} />
      )}
      <Button
        fullWidth
        onClick={handleClose}
        color="secondary"
        variant="text"
        className="mt-3"
      >
        I don't want to cancel
      </Button>
    </Modal>
  );
};
