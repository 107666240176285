import { useCallback, useLayoutEffect } from 'react';

type Props = {
  isOpen: boolean;
};

export function repositionGleapBlock(isOpen?: boolean) {
  document.querySelectorAll('.gl-block').forEach((el) => {
    (el as HTMLElement).style.transitionProperty = 'all';
    (el as HTMLElement).style.transitionTimingFunction =
      'cubic-bezier(0.4, 0, 0.2, 1);';
    (el as HTMLElement).style.transitionDuration = '150ms';
  });
  if (isOpen) {
    document.querySelectorAll('.gl-block').forEach((el) => {
      (el as HTMLElement).style.right = '500px';
    });
  } else {
    document.querySelectorAll('.gl-block').forEach((el) => {
      (el as HTMLElement).style.right = '20px';
    });
  }
}

export const useGleapBlockRepositionWithSidebar = ({ isOpen }: Props) => {
  const handleGleapBannerPosition = useCallback(() => {
    setTimeout(() => repositionGleapBlock(isOpen), 0);
  }, [isOpen]);

  useLayoutEffect(() => {
    handleGleapBannerPosition();

    const glBlock = document.querySelector('.gl-block');
    glBlock?.addEventListener('click', handleGleapBannerPosition);
    return () => {
      glBlock?.removeEventListener('click', handleGleapBannerPosition);
      document.querySelectorAll('.gl-block').forEach((el) => {
        (el as HTMLElement).style.right = '20px';
      });
    };
  }, [handleGleapBannerPosition]);
};
