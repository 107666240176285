import { Page } from '@distribute/shared/types';

export const getSortedPages = (pages: Page[], sortCondition?: string) => {
  if (!sortCondition) {
    return pages;
  }

  switch (sortCondition) {
    case 'Last Modified':
      return [...pages].sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
    case 'Last Created':
      return [...pages].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    case 'Name':
      return [...pages].sort(
        (
          { content: { title: aTitle = 'Untitled' } },
          { content: { title: bTitle = 'Untitled' } }
        ) => {
          if (aTitle < bTitle) {
            return -1;
          }
          if (aTitle > bTitle) {
            return 1;
          }
          return 0;
        }
      );
    case 'Most Viewed':
      return [...pages].sort((a, b) => {
        return b.views - a.views;
      });
    default:
      return pages;
  }
};
