import { UserPermissionToFolder } from './user-permission-to-folder';

export enum FolderTeamMembersPermission {
  VIEW = 'view',
  EDIT = 'edit',
  INVITE_ONLY = 'inviteOnly',
}

export type Folder = {
  id: string;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  order: number;
  sequenceNumber: string;
  teamMembersPermission: FolderTeamMembersPermission;
  userPermissionToFolders: UserPermissionToFolder[];
};
