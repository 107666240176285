import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '../../../../shared/ui';
import { teamInfoSettingsModel } from '../../model';

export const LeftTeamModal: FC = () => {
  const dispatch = useDispatch();
  const leftTeam = useSelector(teamInfoSettingsModel.selectors.selectLeftTeam);

  const handleClose = () => {
    dispatch(teamInfoSettingsModel.actions.setLeftTeam(null));
  };

  return (
    <Modal
      isShowCloseIconInTitle
      isShowCancelButton={false}
      onClose={handleClose}
      isOpen={!!leftTeam}
      title={`You’ve Left ${leftTeam?.name}`}
      actionButton={
        <Button onClick={handleClose} fullWidth color="primary" variant="text">
          Got It
        </Button>
      }
      className="!w-150"
    >
      <div className="text-sm font-normal text-gray-600">
        You have successfully left the {leftTeam?.name} team and all your pages
        have been transferred to the new team owner.
      </div>
    </Modal>
  );
};
