import { Modal } from '../../../shared/ui';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  TemplatesModalHeader,
  TemplatesNavigation,
  TemplatesSection,
} from './components';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../model';
import { TemplatesConfigEnum, TemplatesSwitcherEnum } from '../lib';
import { TemplatePreviewFull } from './components/TemplatePreviewFull';

type Props = {
  isOpen: boolean;
  isHideCreateBlankPage?: boolean;
  onChoose(): void;
  onClose(): void;
};

export const TemplatesModal: React.FC<Props> = ({
  isOpen,
  isHideCreateBlankPage,
  onChoose,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(templatesModel.selectors.selectPreviewMode);
  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const handleClose = () => {
    dispatch(templatesModel.actions.setCurrentTemplate(undefined));
    dispatch(templatesModel.actions.setPreviewMode(false));
    dispatch(
      templatesModel.actions.setCurrentTemplatesFolder(TemplatesConfigEnum.ALL)
    );
    onClose();
  };

  const handleChoose = () => {
    dispatch(templatesModel.actions.setPreviewMode(false));
    dispatch(
      templatesModel.actions.setCurrentTemplatesFolder(TemplatesConfigEnum.ALL)
    );
    onChoose();
  };

  useEffect(() => {
    return () => {
      dispatch(templatesModel.actions.setTemplateModalOpen(false));
      dispatch(
        templatesModel.actions.setCurrentTemplatesFolder(
          TemplatesConfigEnum.ALL
        )
      );
    };
  }, [dispatch]);

  const [sortMethod, setSortMethod] = useState<TemplatesSwitcherEnum>(
    TemplatesSwitcherEnum.POPULAR
  );

  return (
    <Modal
      isOpen={isOpen}
      isShowCancelButton={false}
      clearPadding
      onClose={handleClose}
      className={cn(
        'w-320 h-[min(800px,100vh-40px)] flex flex-col md:w-182 md:min-w-182',
        {
          'w-304 max1080:w-full': isPreviewMode && currentTemplate,
          'w-320': !(isPreviewMode && currentTemplate),
        }
      )}
    >
      {isPreviewMode && currentTemplate ? (
        <TemplatePreviewFull
          template={currentTemplate}
          onClose={handleClose}
          onChoose={handleChoose}
        />
      ) : (
        <div className="flex flex-row overflow-hidden grow-1">
          <TemplatesNavigation />
          <div className="flex flex-col flex-grow">
            <TemplatesModalHeader
              onClose={handleClose}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
            />
            <TemplatesSection
              onChoose={handleChoose}
              isHideCreateBlankPage={isHideCreateBlankPage}
              sortMethod={sortMethod}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
