import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Emoji } from 'emoji-picker-react';
import { debounce } from 'lodash';
import { Folder, Page } from '@distribute/shared/types';
import { Button, Icon, Input, LoaderDots } from '../../shared/ui';
import { IconMap } from '../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../features/teams';
import { pagesModel } from '../../features/pages';
import { redirectActions } from '../../entities/history';
import { foldersModel } from '../../features/folders';
import { useWindowWidth } from '@distribute/frontend/utils';
import { contains } from '../../utils/string';

type PageWithFolder = Page & { folder: Folder };

type IProps = {
  onClose?: () => void;
};
export const WorkspaceSearch: FC<IProps> = ({ onClose }) => {
  const { isMobile } = useWindowWidth();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [lastProcessedSearchValue, setLastProcessedSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isShowSearchResults, setIsShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<PageWithFolder[]>([]);
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const pages = useSelector(pagesModel.selectors.selectPages);
  const folders = useSelector(foldersModel.selectors.selectFolders);
  const pagesWithFolder = useMemo<PageWithFolder[]>(() => {
    return pages.map((page) => ({
      ...page,
      folder: folders.find((folder) => folder.id === page.folderId) as Folder,
    }));
  }, [pages, folders]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchPages = useCallback(
    debounce((searchVal: string) => {
      if (!searchVal) {
        setSearchResults([]);
        setIsShowSearchResults(false);
        setLastProcessedSearchValue(searchVal);
        return;
      }
      setIsSearching(true);

      const filteredPages = pagesWithFolder.filter((page) => {
        const title = page.content.title || 'Untitled';
        return contains(title, searchVal);
      });
      setSearchResults(filteredPages);
      setIsShowSearchResults(true);
      setIsSearching(false);
      setLastProcessedSearchValue(searchVal);
    }, 500),
    []
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    handleSearchPages(e.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
    setLastProcessedSearchValue('');
    setIsShowSearchResults(false);
    setSearchResults([]);

    if (isMobile) {
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target as Node)) {
        setIsShowSearchResults(false);
      }
    };
    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, []);

  return (
    <div
      className="flex grow-1 max-w-150 relative md:fixed md:left-0 md:top-0 md:w-screen md:h-screen md:bg-base-white md:max-w-none md:z-10 md:flex-col"
      ref={containerRef}
    >
      <div className="grow-1 md:px-4 md:py-3 md:flex md:items-center md:gap-5 md:border-b md:border-gray-200 md:flex-grow-0 md:shrink-0">
        <div className="relative md:grow-1">
          {isSearching ? (
            <LoaderDots size="sm" className="absolute left-3.5 top-4.5 z-10" />
          ) : (
            <Icon
              glyph={IconMap.SearchSm}
              width={20}
              className="absolute left-3.5 top-2.5 z-10 text-gray-500"
            />
          )}

          <Input
            ref={inputRef}
            type="text"
            className="!h-10 !pl-10.5 !pr-8.5 !text-sm"
            placeholder={`Search ${currentTeam.name}...`}
            value={searchValue}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {(isFocused || searchValue) && (
            <Icon
              glyph={IconMap.XCircle}
              width={12}
              className="absolute right-3.5 top-3.5 z-10 text-gray-500 cursor-pointer"
              onClick={handleClear}
            />
          )}
        </div>
        <Icon
          glyph={IconMap.XClose}
          width={24}
          className="hidden text-gray-500 shrink-0 cursor-pointer md:block"
          onClick={onClose}
        />
      </div>
      {isShowSearchResults && (
        <div className="flex flex-col border rounded-lg bg-base-white divide-solid border-gray-200 py-1.5 z-10 absolute -bottom-0.5 left-0 w-full translate-y-full max-h-128.5 overflow-auto md:relative md:border-0 md:rounded-none md:bottom-0 md:py-4 md:translate-y-0 md:flex-grow-1 md:max-h-none">
          {searchResults.length === 0 && (
            <div className="p-4 text-md text-gray-600 md:hidden">
              No results matching "{lastProcessedSearchValue}"
            </div>
          )}
          {searchResults.length === 0 && (
            <div className="hidden gap-6 flex-col items-center p-4 text-md text-gray-600 max-w-88 m-auto md:flex sm:mt-6">
              <div>
                <div className="text-md font-semibold text-gray-900 text-center">
                  No pages found
                </div>
                <div className="text-sm text-gray-600 text-center">
                  No results matching "{lastProcessedSearchValue}".
                </div>
                <div className="text-sm text-gray-600 text-center">
                  Please try again.
                </div>
              </div>
              <Button color="secondary" variant="text" onClick={handleClear}>
                Clear search
              </Button>
            </div>
          )}
          {searchResults.map((item) => (
            <div
              key={item.id}
              className="flex items-center gap-3 px-4 py-2.25 hover:bg-gray-50 cursor-pointer"
              onClick={() =>
                dispatch(
                  redirectActions.toEditorPage({
                    sequenceNumber: item.sequenceNumber,
                  })
                )
              }
            >
              <div className="shrink-0">
                <Emoji unified={item.content.icon} size={30} />
              </div>
              <div className="flex flex-col min-w-0">
                <span className="text-sm text-gray-700 font-semibold truncate">
                  {item.content.title}
                </span>
                <span className="text-xs text-gray-700 truncate">
                  {item?.folder?.title}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
