import { call, put, select } from 'redux-saga/effects';
import { gongModel } from '..';
import { gongApi } from '../../../../shared/api/axios';

import { removeGongAuthData } from './helpers';
import { teamsModel } from '../../../../features/teams';
import { RT } from '../../../../shared/types';

export function* disconnectFromGong() {
  const currentTeam: RT<
    typeof teamsModel.selectors.selectCurrentTeamWithError
  > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield call(removeGongAuthData);
  yield call(gongApi.deleteApiKey, currentTeam.id);

  yield put(gongModel.actions.setIsConnected(false));
  yield put(gongModel.actions.setCallTranscriptsData(null));
}
