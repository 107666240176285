import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentPlan } from '../../../subscription/hooks';
import { subscriptionLimits } from '@distribute/shared/types';
import { recordingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';

import { RecordsReached } from './RecordGetUnlimited';

export const RecordStatus: FC = () => {
  const plan = useCurrentPlan();

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const records = useSelector(
    recordingsModel.selectors.selectRecordsMy(currentUser.id)
  );

  const { maxVideoRecords } = subscriptionLimits[plan.id];

  const isAlmostReached =
    records.length && records.length / maxVideoRecords >= 0.7;
  const isReached = maxVideoRecords <= records.length;

  return isReached || isAlmostReached ? (
    <RecordsReached
      value={records.length}
      max={maxVideoRecords}
      isReached={isReached}
    />
  ) : null;
};
