import { api } from './data-processing-api-instance';

export const fileParsingApi = {
  async parseFileTextContent(file: File) {
    const { data: responseData } = await api.post(
      'files/parse-text',
      { upload: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return responseData as string;
  },
};
