export enum SortState {
  LAST_CREATED = 'last-created',
  MOST_POPULAR = 'most-popular',
  NAME = 'name',
}

export enum SnippetsTabEnum {
  TEAM = 'team',
  PERSONAL = 'personal',
  DRAFTS = 'drafts',
}
