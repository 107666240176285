import { useForm } from 'react-hook-form';
import { Button, Icon, Input, Link } from '../../../../shared/ui';
import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { gongModel } from '../../../../entities/gong';
import { teamsModel, useTeamPermissions } from '../../../teams';
import { GongDetailsModal } from './GongDetailsModal';

type GongFormSchema = {
  accessKey: string;
  accessKeySecret: string;
};

const gongFormInputSchema = object().shape({
  accessKey: string().required('Please enter Gong Access Key'),
  accessKeySecret: string().required('Please enter Gong Access Key Secret'),
});

export const GongTeamConfigurationForm: React.FC = () => {
  const dispatch = useDispatch();

  const [isShowDetailsModal, setIsShowDetailsModal] = useState(false);

  const teamPermissions = useTeamPermissions();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isCreatingGongTeamSecrets = useSelector(
    gongModel.selectors.selectIsCreatingTeamSecrets
  );

  const isDeletingGongTeamSecrets = useSelector(
    gongModel.selectors.selectIsDeletingTeamSecrets
  );

  const { register, handleSubmit } = useForm<GongFormSchema>({
    resolver: yupResolver(gongFormInputSchema),
  });

  const onSubmit = handleSubmit(({ accessKey, accessKeySecret }) => {
    dispatch(
      gongModel.actions.createGongTeamSecrets({ accessKey, accessKeySecret })
    );
  });

  const handleDisconnect = () => {
    dispatch(gongModel.actions.deleteGongTeamSecrets());
  };

  if (
    !teamPermissions.isCanConnectIntegrations &&
    !currentTeam.isGongConnected
  ) {
    return (
      <p className="text-sm text-gray-700">
        Please contact your team owner to configure Gong.
      </p>
    );
  }

  if (currentTeam.isGongConnected) {
    return (
      <>
        <GongDetailsModal
          isOpen={isShowDetailsModal}
          onClose={() => setIsShowDetailsModal(false)}
        />
        <div className="flex flex-col gap-4">
          <p className="text-sm text-gray-700">
            Gong is successfully connected.
          </p>
          <div className="flex items-center gap-4">
            <Button
              variant="icon-text"
              iconLeftName={IconMap.Info}
              iconLeftWidth={20}
              color="secondary"
              onClick={() => setIsShowDetailsModal(true)}
            >
              Learn More
            </Button>
            <Button
              variant="icon-text"
              iconLeftName={IconMap.LinkBroken}
              iconLeftWidth={20}
              color="secondary-destructive"
              className="w-35"
              onClick={handleDisconnect}
              disabled={!teamPermissions.isCanConnectIntegrations}
              loading={isDeletingGongTeamSecrets}
            >
              Disconnect
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <p className="text-sm text-gray-700">
        Connect your Gong account to Distribute. All team members will be able
        to use call transcripts.
      </p>
      <ol className="text-sm text-gray-700 list-decimal pl-4 py-4">
        <li className="mb-1">
          Go to{' '}
          <Link
            target="_blank"
            rel="noreferrer"
            href={'https://app.gong.io/company/api'}
            size="medium"
            className="inline-flex gap-x-0.5 items-center"
          >
            Gong API Page
            <Icon glyph={IconMap.ArrowSquareUpRight} width={16} />
          </Link>{' '}
          (you must be a technical administrator in Gong).
        </li>
        <li>
          Click "Create" to receive an{' '}
          <span className="font-semibold text-gray-900">Access Key</span> and an{' '}
          <span className="font-semibold text-gray-900">Access Key Secret</span>
          .
        </li>
      </ol>
      <div className="w-107 flex flex-col gap-4 mb-5">
        <Input
          type="text"
          placeholder="Access Key"
          autoComplete="off"
          heightSize="md"
          {...register('accessKey')}
        />
        <Input
          type="password"
          placeholder="Access Key Secret"
          autoComplete="new-password"
          heightSize="md"
          {...register('accessKeySecret')}
        />
      </div>
      <Button
        type="submit"
        variant="text"
        color="primary"
        className="w-31"
        loading={isCreatingGongTeamSecrets}
      >
        Connect
      </Button>
    </form>
  );
};
