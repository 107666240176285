export function openOauthSignupPopup(
  url: string,
  params?: { [key: string]: string }
) {
  const connectionLink = new URL(url);

  if (params) {
    Object.entries(params).map(([key, value]) => {
      connectionLink.searchParams.append(key, value);
    });
  }

  const width = 600;
  const height = 700;
  const leftPosition = window.screen.width / 2 - (width / 2 + 10);
  const topPosition = window.screen.height / 2 - (height / 2 + 50);

  return window.open(
    connectionLink.href,
    'popup',
    `popup=true,width=${width},height=${height},left=${leftPosition},top=${topPosition}`
  );
}
