import React, { FC, useCallback, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import cn from 'classnames';
import { Icon } from '../Icon';
import { IconMap } from '../../sprite';
import { useOnClickOutside } from '../../hooks/useClickOutside';

type IProps = {
  value: string;
  classNames?: string;
  isUseClickOutside?: boolean;
  onChange: (color: string) => void;
  onClose: () => void;
};

export const ColorSelector: FC<IProps> = ({
  value,
  classNames,
  isUseClickOutside = true,
  onChange,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(() => {
    if (isUseClickOutside) {
      onClose();
    }
  }, [isUseClickOutside, onClose]);

  useOnClickOutside(containerRef, handleClickOutside);

  return (
    <div
      ref={containerRef}
      className={cn(
        'border border-gray-200 shadow-lg p-4 pt-1 bg-base-white rounded-lg',
        classNames
      )}
    >
      <div className="flex justify-between items-center mb-0.5">
        <p className="text-s text-gray-600">Choose color</p>
        <button className="px-1 py-1" onClick={onClose} type="button">
          <Icon glyph={IconMap.CrossClose} className="text-gray-500" />
        </button>
      </div>
      <HexColorPicker color={value} onChange={onChange} />
      <div className="flex items-center w-full mt-3 gap-2">
        <div
          className="w-4 h-4 rounded-sm"
          style={{ backgroundColor: value }}
        />
        <p className="text-s text-gray-600">
          Current color: <span className="font-semibold">{value}</span>
        </p>
      </div>
    </div>
  );
};
