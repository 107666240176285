// TODO: separate handlers

export const handleHtmlContentElementClick = (event: MouseEvent) => {
  const targetEl = event.target as HTMLElement;

  // toggle-list handler
  const toggleTarget = targetEl.closest('.toggle-item__button');

  if (toggleTarget) {
    const arrow = event.target as HTMLElement;

    const content = toggleTarget.parentElement?.children[1]
      .children[1] as HTMLElement;

    if (content.style.display === 'block') {
      content.style.display = 'none';
      arrow.style.transform = 'rotate(0)';
    } else {
      content.style.display = 'block';
      arrow.style.transform = 'rotate(90deg)';
    }
  }

  // code-block handler
  const copyCodeTarget = targetEl.closest('.code-block__copy');

  if (copyCodeTarget) {
    const textContent = copyCodeTarget.attributes.getNamedItem('data-text');

    if (textContent) {
      navigator.clipboard.writeText(textContent.value);

      copyCodeTarget.children[2]?.classList.remove(
        'code-block__copy-tooltip--hidden'
      );

      setTimeout(() => {
        copyCodeTarget.children[2]?.classList.add(
          'code-block__copy-tooltip--hidden'
        );
      }, 500);
    }
  }
};
