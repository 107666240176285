import { BaseTeam } from './team';

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
}

export enum PlanName {
  PRO = 'pro',
  TEAM = 'team',
}

export enum SubscriptionInterval {
  MONTH = 'month',
}

export enum SubscriptionInvoiceStatus {
  DELETED = 'deleted',
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}

export type SubscriptionPlanName = PlanName | 'free';

export type StripeSubscription = {
  status: SubscriptionStatus;
  cancelAt?: Date;
  cancelAtPeriodEnd: boolean;
  canceledAt?: Date;
  created: Date;
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
  endedAt?: Date;
  amount: number;
  name: PlanName;
  interval: SubscriptionInterval;
  planId?: string;
  trialEnd?: Date;
  trialStart?: Date;
  stripeSubscriptionId: string;
  upcomingInvoiceAmount: number | null;
  upcomingInvoicePercentOff: number | null;
  upcomingInvoiceAmountOff: number | null;
  upcomingInvoiceNextPaymentAttempt: Date | null;
  nextPaymentAttempt: Date | null;
};

export type SubscriptionPromoCode = {
  id: number;
  stripePromoCodeId: string;
  stripeCouponId: string;
  createdAt: Date;
  expiresAt: Date | null;
  code: string;
  isActive: boolean;
  percentOff: number | null;
  amountOff: number | null;
};

export type SubscriptionPaymentMethod = {
  id: number;
  stripePaymentMethodId: string;
  cardLastNumbers: string;
  cardBrand: string;
  cardExpMonth: number;
  cardExpYear: number;
};

export type SubscriptionInvoice = {
  id: number;
  team: BaseTeam;
  stripeInvoiceId: string;
  stripeSubscriptionId: string;
  pdfUrl: string | null;
  planName: PlanName;
  status: SubscriptionInvoiceStatus;
  createdAt: Date;
  amount: number;
};

export type SubscriptionUpcomingInvoiceInfo = {
  currentAmount: number;
  currentItemAmount: number;
  total: number;
  proratedAmount?: number;
  trialEndAt?: Date;
  totalDueNow: number;
  totalNextRenewal: number;
};
