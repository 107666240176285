import {
  AcceptTeamInvitationResponseType,
  CheckDomainIsUniqueRequestType,
  CheckDomainIsUniqueResponseType,
  CreateTeamRequestType,
  CreateTeamResponseType,
  DeleteLogoResponseType,
  GetTeamResponseType,
  GetTeamUsersQueryParamsType,
  GetTeamUsersResponseType,
  GetTeamsResponseType,
  InviteTeamMembersRequestType,
  InviteTeamMembersResponseType,
  LeaveTeamPaymentMethodUpdatedResponseType,
  LeaveTeamRequestType,
  LeaveTeamResponseType,
  TeamInvitationTeamMemberInfoResponseType,
  UpdateCurrentTeamMemberRequestType,
  UpdateCurrentTeamMemberResponseType,
  UpdateLogoResponseType,
  UpdateTeamMemberRoleRequestType,
  UpdateTeamMemberRoleResponseType,
  UpdateTeamRequestType,
  UpdateTeamResponseType,
  UpdateTeamPermissionsRequestType,
  UpdateTeamPermissionsResponseType,
  UpdateGettingStartedStepType,
  AcceptPageInvitationResponseType,
} from '@distribute/shared/api-types/team';
import { api } from './instance';
import { getQueryString, getUploadProgress } from '../../lib';
import { TeamFiltered } from '@distribute/shared/types';

export const teamsApi = {
  async getTeams() {
    const { data } = await api.get<GetTeamsResponseType>('teams');

    return data;
  },

  async getTeam(teamId: number) {
    const { data } = await api.get<GetTeamResponseType>(`teams/${teamId}`);

    return data;
  },

  async createTeam(data: CreateTeamRequestType): Promise<TeamFiltered> {
    const { data: responseData } = await api.post<CreateTeamResponseType>(
      `teams`,
      data
    );

    return responseData;
  },

  async updateTeam(id: number, data: UpdateTeamRequestType) {
    const { data: responseData } = await api.patch<UpdateTeamResponseType>(
      `teams/${id}`,
      data
    );

    return responseData;
  },

  async updateLogo(
    teamId: number,
    file: File,
    signal: AbortSignal,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put<UpdateLogoResponseType>(
      `teams/${teamId}/logo`,
      { logoImg: file },
      {
        signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );
    return responseData;
  },

  async updateBrandLogo(
    teamId: number,
    file: File,
    signal: AbortSignal,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put<UpdateLogoResponseType>(
      `teams/${teamId}/brandLogo`,
      { logoImg: file },
      {
        signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );
    return responseData;
  },

  async deleteLogo(teamId: number) {
    const { data } = await api.delete<DeleteLogoResponseType>(
      `teams/${teamId}/logo`
    );
    return data;
  },

  async deleteBrandLogo(teamId: number) {
    const { data } = await api.delete<DeleteLogoResponseType>(
      `teams/${teamId}/brandLogo`
    );
    return data;
  },

  async leave(teamId: number, data: LeaveTeamRequestType) {
    const { data: responseData } = await api.post<LeaveTeamResponseType>(
      `teams/${teamId}/leave`,
      data
    );
    return responseData;
  },

  async leaveTeamPaymentMethodUpdated(teamId: number) {
    const { data } = await api.post<LeaveTeamPaymentMethodUpdatedResponseType>(
      `teams/${teamId}/leave/payment-method-updated`
    );
    return data;
  },

  async requestDelete(teamId: number) {
    const { data } = await api.post(`teams/${teamId}/request-delete`);
    return data;
  },

  async confirmDelete(teamId: number, deleteRequestId: string) {
    const { data } = await api.delete(
      `teams/${teamId}/confirm-delete/${deleteRequestId}`
    );
    return data;
  },

  async acceptTeamInvitation(inviteId: string) {
    const { data } = await api.post<AcceptTeamInvitationResponseType>(
      `teams/accept-team-invitation/${inviteId}`
    );

    return data;
  },

  async acceptPageInvitation(pageInviteId: string, teamInviteId: string) {
    const { data } = await api.post<AcceptPageInvitationResponseType>(
      `teams/accept-page-invitation/${pageInviteId}?teamInviteId=${teamInviteId}`
    );

    return data;
  },

  async teamInvitationTeamMemberInfo(inviteId: string) {
    const { data } = await api.post<TeamInvitationTeamMemberInfoResponseType>(
      `teams/accept-team-invitation/${inviteId}/team-member-info`
    );

    return data;
  },

  async pageInvitationTeamMemberInfo(pageInviteId: string) {
    const { data } = await api.post<TeamInvitationTeamMemberInfoResponseType>(
      `teams/accept-page-invitation/${pageInviteId}/team-member-info`
    );

    return data;
  },

  async getTeamUsers(
    teamId: number,
    queryParams?: GetTeamUsersQueryParamsType
  ) {
    const { data } = await api.get<GetTeamUsersResponseType>(
      `teams/${teamId}/team-members?${getQueryString(queryParams ?? {})}`
    );
    return data;
  },

  async inviteTeamMembers(teamId: number, data: InviteTeamMembersRequestType) {
    const { data: responseData } =
      await api.post<InviteTeamMembersResponseType>(
        `teams/${teamId}/team-members/invite`,
        data
      );
    return responseData;
  },

  async resendInvite(teamId: number, teamMemberId: number) {
    const { data } = await api.post<InviteTeamMembersResponseType>(
      `teams/${teamId}/team-members/${teamMemberId}/resend-invite`
    );
    return data;
  },

  async updateTeamMemberRole(
    teamId: number,
    teamMemberId: number,
    data: UpdateTeamMemberRoleRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateTeamMemberRoleResponseType>(
        `teams/${teamId}/team-members/${teamMemberId}/update-role`,
        data
      );
    return responseData;
  },

  async deleteTeamMember(teamId: number, teamMemberId: number) {
    const { data } = await api.delete(
      `teams/${teamId}/team-members/${teamMemberId}`
    );
    return data;
  },

  async transferOwnership(teamId: number, teamMemberId: number) {
    const { data } = await api.post(
      `teams/${teamId}/team-members/${teamMemberId}/transfer-ownership`
    );
    return data;
  },

  async checkDomain(data: CheckDomainIsUniqueRequestType) {
    const { data: responseData } =
      await api.post<CheckDomainIsUniqueResponseType>(
        'teams/check-domain',
        data
      );
    return responseData;
  },

  async getSubdomain(displayName: string) {
    const { data: responseData } = await api.get(
      `teams/subdomain?displayName=${displayName}`
    );
    return responseData;
  },

  async updateCurrentTeamMember(
    teamId: number,
    data: UpdateCurrentTeamMemberRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateCurrentTeamMemberResponseType>(
        `teams/${teamId}/team-members/me`,
        data
      );
    return responseData;
  },

  async updateTeamPermissions(
    teamId: number,
    data: UpdateTeamPermissionsRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateTeamPermissionsResponseType>(
        `teams/${teamId}/update-team-permissions`,
        data
      );
    return responseData;
  },

  async updateGettingStartedStep(
    teamId: number,
    data: UpdateGettingStartedStepType
  ) {
    return api.patch<UpdateTeamPermissionsResponseType>(
      `teams/${teamId}/update-getting-started-step`,
      data
    );
  },
};
