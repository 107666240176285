import './TodoList.css';
import { TodoListExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    todoList: {
      toggleTodoList: () => ReturnType;
    };
  }
}

export const TodoList = TodoListExt.extend({
  addOptions() {
    return {
      itemTypeName: 'todoListItem',
      HTMLAttributes: {
        class: '!list-none !m-0 !p-0',
      },
    };
  },

  addCommands() {
    return {
      toggleTodoList:
        () =>
        ({ commands }) => {
          return commands.toggleList(this.name, this.options.itemTypeName);
        },
    };
  },
});
