import { useDispatch, useSelector } from 'react-redux';
import { SimpleLayout, Button } from '../../shared/ui';
import { redirectActions } from '../../entities/history';
import { teamsModel } from '../../features/teams';

export const TeamLeft = () => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);

  return (
    <SimpleLayout>
      <div>
        <div className="text-display-md text-gray-900 font-semibold font-display text-center">
          You have left the team successfully
        </div>

        <div className="mt-3 text-center font-normal text-md text-gray-600">
          You no longer have access to any pages or templates associated with
          the team.
        </div>
        <Button
          fullWidth
          color="primary"
          variant="text"
          className="mt-6"
          onClick={() =>
            dispatch(
              currentTeam
                ? redirectActions.toWorkspace()
                : redirectActions.toOnboardingClick()
            )
          }
        >
          Continue to Distribute
        </Button>
      </div>
    </SimpleLayout>
  );
};
