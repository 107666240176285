import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { FC, useMemo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../model';
import { Dropdown } from '../../../../../shared/ui';
import { getAvailableCategoriesBySnippetTypesIncluded } from './lib/getAvailableCategoriesBySnippetTypesIncluded';

type Props = {
  category: SnippetCategoryWithSnippets;
  setCategoryId: (value: number | undefined) => void;
  categoryId?: number;
  errorText?: string;
};

export const SelectMovingCategoryDropdown: FC<Props> = ({
  category,
  setCategoryId,
  categoryId,
  errorText,
}) => {
  const categories = useSelector(snippetsModel.selectors.selectCategories);

  const availableCategories = useMemo(() => {
    return getAvailableCategoriesBySnippetTypesIncluded(categories, category);
  }, [categories, category]);

  const items = useMemo(() => {
    return availableCategories.map((category) => {
      return {
        id: `${category.id}`,
        name: category.name,
        label: category.name,
        onClick: () => {
          setCategoryId(Number(category.id));
        },
      };
    });
  }, [availableCategories, setCategoryId]);

  const noAvailableCategoriesItems = [
    {
      id: 'no-categories',
      label: 'Please create a category for such a type first',
    },
  ];

  const selectedCategory = availableCategories.find((c) => c.id === categoryId);

  return (
    <div>
      <Dropdown
        items={
          availableCategories.length > 0 ? items : noAvailableCategoriesItems
        }
        currentItemId={categoryId?.toString()}
        listStyles="!w-[var(--radix-dropdown-menu-trigger-width)] shadow-lg"
        triggerStyles={cn('!h-10 shrink-0 shadow-xs', {
          '!font-normal !text-gray-500 ': !selectedCategory,
          '!border-error-300': errorText,
        })}
        align="start"
        itemStyles="py-2 px-3"
        arrowStyle="text-gray-600"
        customTriggerText={
          selectedCategory ? selectedCategory.name : 'Select new category'
        }
        isModalMode={false}
      />
      {errorText && (
        <span className="mt-2 text-sm text-error-600">{errorText}</span>
      )}
    </div>
  );
};
