import { AICompletionRequestType } from '@distribute/shared/api-types/ai';
import { API_URL, auth } from '../../config';
import { AIError } from '../../../features/ai/lib';
import { CreateWithAIRequestType } from '@distribute/shared/api-types/create-with-ai';

export const aiApi = {
  async getCompletion(
    data: AICompletionRequestType,
    abortSignal: AbortSignal,
    onFulfilled: (text: string) => void
  ) {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await fetch(`${API_URL}/ai/completion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
        signal: abortSignal,
      });

      if (!response.body) {
        throw new Error('Response is empty');
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        const error = getErrorFromStream(value);

        if (error) {
          throw error;
        }

        if (done) break;

        const text = extractStreamDataFromString(value);
        onFulfilled(text);
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }
      throw error;
    }
  },

  async generatePageContent(
    data: CreateWithAIRequestType,
    abortSignal: AbortSignal,
    onFulfilled: (text: string) => void
  ) {
    try {
      const token = await auth.currentUser?.getIdToken();

      const response = await fetch(
        `${API_URL}/create-with-ai?teamId=${data.teamId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
          signal: abortSignal,
        }
      );

      if (!response.body) {
        throw new Error('Response is empty');
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        const error = getErrorFromStream(value);

        if (error) {
          throw error;
        }

        if (done) break;

        const text = extractStreamDataFromString(value);
        onFulfilled(text);
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }
      throw error;
    }
  },
};

function getErrorFromStream(input?: string): AIError | undefined {
  if (!input) {
    return;
  }

  try {
    const errorResponse = JSON.parse(input);
    throw new AIError(errorResponse.message, errorResponse.status);
  } catch (error) {
    if (error instanceof AIError) {
      return error;
    }

    const errorMessage = extractErrorDataFromString(input);
    if (errorMessage) {
      return new AIError(errorMessage);
    }
    return;
  }
}

function extractErrorDataFromString(inputString: string) {
  const lines = inputString
    .split('\n')
    .filter((line: string) => line.trim() !== '');

  const regex = /(event: (\w+))/;
  if (!lines.some((i) => i.match(regex))) {
    return null;
  }
  return extractStreamDataFromString(inputString);
}

function extractStreamDataFromString(inputString: string) {
  let resultString = '';

  const lines = inputString
    .split('\n')
    .filter((line: string) => line.trim() !== '');

  for (const line of lines) {
    const regex = /(id: (\d+))|(event: (\w+))/;
    const match = line.match(regex);
    if (match) {
      continue;
    }

    const message = line.replace(/^data: /, '');
    resultString += message;
  }

  return resultString;
}
