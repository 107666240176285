import { FC } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import { environment } from '../../environments/environment';

const styles = {
  base: 'inline-flex items-center justify-center gap-1.5 text-sm text-gray-600 font-semibold font-base !no-underline whitespace-nowrap',
  image: {
    size: { width: 16, heigh: 16 },
  },
  text: 'sm:hidden',
} as const;

type Props = {
  classNames?: string;
};

export const DistributeLink: FC<Props> = ({ classNames = '' }) => {
  return (
    <a
      className={cn(styles.base, classNames)}
      href={environment.landingUrl}
      target="_blank"
      rel="noreferrer"
    >
      <Icon glyph={IconMap.DistributeLogoLetter} {...styles.image.size} />
      <span className={styles.text}>Powered by Distribute</span>
    </a>
  );
};
