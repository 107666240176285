import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export const downloadFile = (
  data: Blob,
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
  name?: string
) => {
  const blob = new Blob([data], { type: headers['content-type'] });
  const downloadUrl = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  let filename = name || 'filename';

  if (!name) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition'] || '');
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  tempLink.style.display = 'none';
  tempLink.href = downloadUrl;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(downloadUrl);
  }, 100);
};
