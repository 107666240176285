import React, { useMemo, useState } from 'react';
import { Dropdown, Icon } from '../../../../../shared/ui';
import {
  SnippetType,
  SnippetCategoryWithSnippets,
  SnippetsSharingAccess,
} from '@distribute/shared/types';
import { IconMap } from '../../../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useDispatch } from 'react-redux';
import { snippetsModel } from '../../../model';
import { useSnippetsPermissions } from '../../../../../features/teams';
import { CreateMediaSnippetModal } from './CreateMediaSnippetModal';
import { cn } from '@distribute/frontend/utils';
import { navigate } from '../../../../../processes/navigation';
import { SNIPPETS_DRAFTS_ROUTE } from '../../../../../entities/history';

type Props = {
  category?: SnippetCategoryWithSnippets;
  isEmptyPage?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isSnippetsModal?: boolean;
};

export const CreateSnippetButton: React.FC<Props> = ({
  category,
  isEmptyPage = false,
  size = 'md',
  isSnippetsModal = false,
}) => {
  const dispatch = useDispatch();

  const [createSnippetTypeModal, setCreateSnippetTypeModal] = useState<
    SnippetType | undefined
  >(undefined);

  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();

  const snippetTypeButtons = useMemo(() => {
    return [
      {
        id: SnippetType.TEXT,
        label: 'Text',
        iconName: IconMap.TypeSquare,
        iconWidth: 16,
        onClick: () => {
          if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
            return;
          }

          dispatch(
            snippetsModel.actions.createSnippet({
              name: '',
              type: SnippetType.TEXT,
              sharingAccess:
                category || !isCanCreatePersonalSnippets
                  ? SnippetsSharingAccess.TEAM
                  : SnippetsSharingAccess.PERSONAL,
              categoryId: category?.id,
              callback: () => {
                dispatch(navigate({ to: SNIPPETS_DRAFTS_ROUTE }));
              },
            })
          );
        },
      },
      {
        id: SnippetType.IMAGE,
        label: 'Image',
        iconName: IconMap.Image05,
        iconWidth: 16,
        onClick: () => {
          if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
            return;
          }

          setCreateSnippetTypeModal(SnippetType.IMAGE);
        },
      },
      {
        id: SnippetType.VIDEO,
        label: 'Video',
        iconName: IconMap.PlaySquare,
        iconWidth: 16,
        onClick: () => {
          if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
            return;
          }

          setCreateSnippetTypeModal(SnippetType.VIDEO);
        },
      },
      {
        id: SnippetType.FILE,
        label: 'File',
        iconName: IconMap.BlankPageSquare,
        iconWidth: 16,
        onClick: () => {
          if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
            return;
          }

          setCreateSnippetTypeModal(SnippetType.FILE);
        },
      },
    ].filter((type) => !category || category[type.id]);
  }, [dispatch, category, isCanCreatePersonalSnippets, isCanManageSnippets]);

  const closeModal = () => {
    setCreateSnippetTypeModal(undefined);
  };

  return (
    <>
      {createSnippetTypeModal && (
        <CreateMediaSnippetModal
          category={category}
          isOpen={Boolean(createSnippetTypeModal)}
          onClose={closeModal}
          type={createSnippetTypeModal}
          isSnippetsModal={isSnippetsModal}
        />
      )}
      <Dropdown
        listStyles={cn('shadow-lg', {
          'w-43.5': size === 'lg',
          'w-38.25': size === 'md',
          'w-37.5': size === 'sm',
          hidden: snippetTypeButtons.length === 1,
        })}
        triggerComponent={
          isEmptyPage ? (
            <DropdownMenu.Trigger>
              <span className="flex items-center justify-center px-4 py-2.25 rounded-md border border-gray-200 text-gray-700 bg-base-white w-fit shadow-xs">
                <p className="font-semibold text-md px-0.5">Create Snippet</p>
              </span>
            </DropdownMenu.Trigger>
          ) : (
            <DropdownMenu.Trigger
              className={cn({
                'w-43.5': size === 'lg',
                'w-38.25': size === 'md',
                'w-37.5': size === 'sm',
              })}
              onClick={() => {
                if (snippetTypeButtons.length === 1) {
                  snippetTypeButtons[0].onClick();
                }
              }}
            >
              <span
                className={cn(
                  'flex items-center justify-center gap-1 rounded-md bg-primary-700 hover:bg-primary-800 focus:bg-primary-800 text-base-white shadow-xs',
                  {
                    'py-2.5 px-4': size === 'lg',
                    'py-2.5 px-3.5': size === 'md',
                    'py-2 px-3': size === 'sm',
                  }
                )}
              >
                <Icon glyph={IconMap.Snippets} width={20} />
                <p
                  className={cn('font-semibold px-0.5', {
                    'text-md': size === 'lg',
                    'text-sm': size === 'md' || size === 'sm',
                  })}
                >
                  Create Snippet
                </p>
              </span>
            </DropdownMenu.Trigger>
          )
        }
        items={snippetTypeButtons}
        isModalMode={false}
        align="start"
      />
    </>
  );
};
