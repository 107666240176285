import { all, call } from 'redux-saga/effects';
import {
  setAlertBarData,
  setCTAData,
  setGatedContentData,
  setPopUpData,
  setRequireEmailToViewData,
  setSqueezePageData,
} from '../../../conversion-kit';
import { workspaceModel } from '..';

export function* setConversionToolsData({
  payload: { documentId },
}: ReturnType<typeof workspaceModel.actions.setConversionToolsData>) {
  yield all([
    call(setAlertBarData, documentId),
    call(setPopUpData, documentId),
    call(setCTAData, documentId),
    call(setGatedContentData, documentId),
    call(setSqueezePageData, documentId),
    call(setRequireEmailToViewData, documentId),
  ]);
}
