import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../features/snippets';
import { NoSnippetsFound } from './NoSnippetsFound';
import { SnippetsList } from './SnippetsList';
import { noFoundMessages } from '../../../features/snippets/ui/components/lib/config';
import {
  SnippetsTabEnum,
  getFilteredSnippets,
  getSortedSnippets,
} from '../lib';
import { FiltersRow } from './FiltersRow';

export const PersonalSnippetsLayout: React.FC = () => {
  const personalSnippets = useSelector(
    snippetsModel.selectors.selectPersonalSnippets
  );

  const currentTypeFilter = useSelector(
    snippetsModel.selectors.selectCurrentTypeFilter
  );

  const currentSortCondition = useSelector(
    snippetsModel.selectors.selectCurrentSortCondition
  );

  const searchQuery = useSelector(snippetsModel.selectors.selectSearchQuery);

  const currentSnippetsToShow = useMemo(() => {
    const filteredSnippets = getFilteredSnippets(
      personalSnippets,
      searchQuery[SnippetsTabEnum.PERSONAL],
      currentTypeFilter[SnippetsTabEnum.PERSONAL]
    );
    const sortedSnippets = getSortedSnippets(
      filteredSnippets,
      currentSortCondition[SnippetsTabEnum.PERSONAL]
    );

    return sortedSnippets;
  }, [currentTypeFilter, currentSortCondition, personalSnippets, searchQuery]);

  return personalSnippets.length > 0 ? (
    <>
      <FiltersRow
        tabType={SnippetsTabEnum.PERSONAL}
        visibleSnippetsAmount={currentSnippetsToShow.length}
      />
      {currentSnippetsToShow.length > 0 ? (
        <SnippetsList snippets={currentSnippetsToShow} />
      ) : (
        <NoSnippetsFound
          message={noFoundMessages.filterSnippetsAndAllowCreation}
        />
      )}
    </>
  ) : (
    <NoSnippetsFound message={noFoundMessages.setupPersonalSnippets} />
  );
};
