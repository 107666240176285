import {
  PlanName,
  SubscriptionInterval,
  TeamOnboardingStatus,
  TypeOfWorkForUsagePlanWork,
  UsagePlan,
} from '@distribute/shared/types';

export enum SkipOnboardingStepSource {
  INVITE_TEAM_MEMBERS = 'inviteTeamMembers',
}

export type CreateSubscriptionTrialAction = {
  teamId: number;
  plan: PlanName;
  interval: SubscriptionInterval;
};

export type SkipTeamOnboardingStepAction = {
  teamOnboardingStatus: TeamOnboardingStatus;
  source?: SkipOnboardingStepSource;
};

export type InviteTeamMembersAction = {
  teamId: number;
  emails: string[];
};

export type CreateTeamAction = {
  name: string;
  domain: string;
  isAppsumo?: boolean;
  brandLogoUrl?: string;
  brandCompanyName?: string;
};

export type UpdateUsagePlanAction = {
  usagePlan: UsagePlan;
  typeOfWorkForUsagePlanWork?: TypeOfWorkForUsagePlanWork;
};
