import { FC, useMemo } from 'react';

import cn from 'classnames';

import { useSelector } from 'react-redux';
import { recordingsModel } from '../../model';

import { authUserModel } from '../../../../entities/auth-user';

import { RecordFirstVideo } from './RecordFirstVideo';
import { RecordMyCard } from './RecordMyCard';
import { RecordCardInProgress } from './RecordCardInProgress';

import {
  checkIsRecordInProgress,
  getFilterSearchResults,
  getSortResults,
} from '../../lib';
import { RecordCategory } from './types';

import {
  RecordsCount,
  RecordsSearchFilter,
  RecordsSort,
} from './RecordsFilters';

import { RecordsNotFound } from './RecordsNotFound';

export const RecordsMy: FC = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const records = useSelector(
    recordingsModel.selectors.selectRecordsMy(currentUser.id)
  );

  const sort = useSelector(recordingsModel.selectors.selectSort);

  const filterSearch = useSelector(
    recordingsModel.selectors.selectFilterSearch
  );

  const filteredRecords = useMemo(
    () => getSortResults(getFilterSearchResults(records, filterSearch), sort),
    [filterSearch, sort, records]
  );

  if (!records.length) {
    return <RecordFirstVideo />;
  }

  return (
    <>
      <div
        className={cn(
          'mb-8 sm:mb-6 sm:flex-col flex items-center justify-between gap-4',
          { hidden: !records.length }
        )}
      >
        <div className="flex items-center flex-nowrap gap-x-4 w-full">
          <RecordsSearchFilter />
          <RecordsCount value={filteredRecords.length} />
        </div>
        <div className="flex flex-nowrap gap-2 items-center justify-end w-full">
          <RecordsSort />
        </div>
      </div>
      {filteredRecords.length ? (
        <div className="flex flex-col flex-grow-1 mb-8 sm:mb-4">
          <div className="grid gap-5 grid-cols-[repeat(auto-fill,minmax(320px,1fr))]">
            {filteredRecords.map((v) =>
              checkIsRecordInProgress(v) ? (
                <RecordCardInProgress
                  record={v}
                  key={v.id}
                  category={RecordCategory.MY}
                />
              ) : (
                <RecordMyCard record={v} key={v.id} />
              )
            )}
          </div>
        </div>
      ) : (
        <RecordsNotFound />
      )}
    </>
  );
};
