import React, { ReactNode, useEffect, useState } from 'react';
import {
  HoveringBlockPlugin,
  HoveringBlockPluginProps,
  addButtonHoveringBlockPluginKey,
} from './HoveringBlockPlugin';

export type IProps = Omit<HoveringBlockPluginProps, 'element'> & {
  children: ReactNode;
};

export const HoveringBlockMenu = ({
  editor,
  children,
  tags,
  tippyOptions,
  hoveringBlockWidth,
}: IProps) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!element) {
      return;
    }

    if (editor.isDestroyed) {
      return;
    }

    const plugin = HoveringBlockPlugin({
      editor,
      element,
      tippyOptions,
      tags,
      hoveringBlockWidth,
    });
    editor.registerPlugin(plugin);

    return () => editor.unregisterPlugin(addButtonHoveringBlockPluginKey);
  }, [editor, tippyOptions, tags, element, hoveringBlockWidth]);

  return (
    <div ref={setElement} style={{ visibility: 'hidden' }}>
      {children}
    </div>
  );
};
