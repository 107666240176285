import { FC } from 'react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '../../../../shared/ui/select/SelectV2';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { recordingsModel } from '../../model';
import {
  ALL_OWNERS,
  SortType,
  filtersOwnerItems,
  sortItems,
} from '../../config';
import { teamsModel } from '../../../teams';
import { authUserModel } from '../../../../entities/auth-user';

import { Icon, Input, SelectOptionUser } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

export type Props = {
  value: number;
};

export const RecordsCount: FC<Props> = ({ value }) => (
  <p
    className={cn(
      'text-gray-700 text-sm font-normal whitespace-nowrap sm:hidden'
    )}
  >{`${value} ${value === 1 ? 'video' : 'videos'} `}</p>
);

export const RecordsSort = () => {
  const dispatch = useDispatch();
  const sort = useSelector(recordingsModel.selectors.selectSort);

  return (
    <Select
      value={sort}
      onValueChange={(value) =>
        dispatch(recordingsModel.actions.setSort(value as SortType))
      }
    >
      <SelectTrigger className="sm:w-full w-40">
        {sortItems[sort].text}
      </SelectTrigger>
      <SelectContent side="bottom" align="end" sideOffset={4}>
        {Object.keys(sortItems).map((value) => (
          <SelectItem value={value} key={value}>
            {sortItems[value].optionText}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const RecordsOwnerFilter = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const filterOwner = useSelector(recordingsModel.selectors.selectFilterOwner);

  const teamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const selectedMember = teamMembers.find(
    (member) => member.user?.id === filterOwner
  );

  return (
    <Select
      value={filterOwner}
      onValueChange={(value) =>
        dispatch(recordingsModel.actions.setFilterOwner(value))
      }
    >
      <SelectTrigger className="sm:w-full w-40">
        {filterOwner === ALL_OWNERS ? (
          filtersOwnerItems[ALL_OWNERS].text
        ) : (
          <SelectOptionUser
            displayName={selectedMember?.user?.displayName ?? ''}
            photoUrl={selectedMember?.user?.photoUrl}
          />
        )}
      </SelectTrigger>
      <SelectContent side="bottom" align="end" sideOffset={4}>
        <SelectItem value={ALL_OWNERS} key={ALL_OWNERS}>
          {filtersOwnerItems[ALL_OWNERS].optionText}
        </SelectItem>
        {teamMembers
          .filter((member) => member.user?.id !== currentUser.id)
          .map((member) => (
            <SelectItem
              className="!pl-1.5"
              value={member.user?.id || ''}
              key={member.user?.id || ''}
            >
              <SelectOptionUser
                displayName={member.user?.displayName ?? ''}
                photoUrl={member.user?.photoUrl}
              />
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};

export const RecordsSearchFilter = () => {
  const dispatch = useDispatch();

  const filterSearch = useSelector(
    recordingsModel.selectors.selectFilterSearch
  );

  return (
    <div className="sm:max-w-full max-w-80 w-full relative">
      <Icon
        glyph={IconMap.SearchLg}
        width={20}
        className="absolute left-3.5 top-2.5 z-10 text-gray-500"
      />
      <Input
        type="text"
        placeholder="Search"
        value={filterSearch}
        onChange={(e) =>
          dispatch(recordingsModel.actions.setFilterSearch(e.target.value))
        }
        heightSize="md"
        className="!px-10.5"
      />
      {filterSearch && (
        <Icon
          glyph={IconMap.XCircle}
          width={20}
          className="absolute right-3.5 top-2.5 z-10 text-gray-700 cursor-pointer"
          onClick={() => dispatch(recordingsModel.actions.setFilterSearch(''))}
        />
      )}
    </div>
  );
};
