import hljs from 'highlight.js';

export const highlightCodeBlock = () => {
  const codeTag = document.getElementsByTagName('code');
  for (let i = 0; i < codeTag.length; i++) {
    const element = codeTag[i];
    element.classList.add('language-html');
    element.style.whiteSpace = 'pre-wrap';
  }
  hljs.highlightAll();
};
