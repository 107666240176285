import { Dispatch, FC, SetStateAction } from 'react';
import { ExtensionOptions } from '../ExtensionOptions';
import { messages } from '../../config';

import { Button } from '../../../../shared/ui';

import { Step } from './ChromeExtensionOnboard';
import { useDispatch } from 'react-redux';
import { extensionModel } from '../../model';

type Props = {
  loading: boolean;
  setStep: Dispatch<SetStateAction<Step>>;
  onFinish: () => void;
};

export const ChromeExtensionGet: FC<Props> = ({
  setStep,
  onFinish,
  loading,
}) => {
  const dispatch = useDispatch();

  const handleInstall = () => {
    dispatch(extensionModel.actions.extensionInstallClick());
    setStep(Step.Install);
  };

  return (
    <>
      <h2 className="text-gray-900 text-display-md font-medium font-display mt-8 mb-3 text-center">
        {messages.title}
      </h2>
      <p className="text-gray-600 text-md mb-8 text-center max-w-96">
        {messages.description}
      </p>
      <ExtensionOptions />
      <Button variant="text" color="primary" onClick={handleInstall}>
        Install Distribute Recorder
      </Button>
      <Button
        variant="text"
        color="link"
        disabled={loading}
        className="!font-semibold mt-1.5"
        onClick={onFinish}
      >
        I’ll do this later
      </Button>
    </>
  );
};
