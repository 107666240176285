import { call } from 'redux-saga/effects';
import { getQueryParam } from '../../../../shared/lib';
import {
  QUERY_PARAM_IS_DUPLICATE_PUBLIC_TEMPLATE_TO_DEFAULT_FOLDER,
  QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE,
} from '../../config';
import { redirect } from '../../../../entities/history';

export function* handlePublicTemplates(
  isOnboardingFinished: boolean,
  isDuplicatePublicTemplateToDefaultFolder?: boolean
) {
  const pageId = getQueryParam(QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE);

  if (!pageId || !isOnboardingFinished) return;

  yield call(redirect.toPublicTemplates, {
    pageId,
    queryParams: isDuplicatePublicTemplateToDefaultFolder
      ? {
          [QUERY_PARAM_IS_DUPLICATE_PUBLIC_TEMPLATE_TO_DEFAULT_FOLDER]: 'true',
        }
      : undefined,
    isKeepQueryParams: false,
  });
}
