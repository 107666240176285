import { call, fork, takeLatest } from 'redux-saga/effects';

import {
  LOGGED_IN,
  LOGGED_OUT,
  authChannelModel,
} from '../../../entities/auth-channel';
import { getUser } from '../../../entities/auth-user';
import { FirebaseUser } from '../../../shared/types';
import { Saga } from '../../../shared/types';
import { beforeAuth } from './beforeAuth';

export function* onAuth(handleSignedIn: Saga, handleSignedOut: Saga) {
  const currentUser: FirebaseUser | null = yield getUser();

  yield call(authChannelModel.handleUserAuthState, currentUser);

  yield takeLatest(authChannelModel.chan, function* ({ type, actions }) {
    yield fork(beforeAuth, actions);

    switch (type) {
      case LOGGED_IN:
        yield call(handleSignedIn);
        break;
      case LOGGED_OUT:
        yield call(handleSignedOut);
        break;
      default:
        throw new Error('Unexpected value in authChan');
    }
  });
}
