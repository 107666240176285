import { FC, RefObject } from 'react';
import ReactPlayer from 'react-player';

import cn from 'classnames';
import { checkIsInf, usePlayer } from '../../../lib';

import { Slider } from '../../Slider';
import { ActionKind } from '../../../provider';

type Props = {
  playerRef: RefObject<ReactPlayer>;
  loaded: number;
};

export const PlayerProgress: FC<Props> = ({ playerRef, loaded }) => {
  const { state, dispatch } = usePlayer();
  const { fullscreen, duration, playedSeconds } = state;

  const isInf = checkIsInf(duration);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e;

    if (isInf) return;

    if (key === 'ArrowRight') {
      e.preventDefault();

      const val = playedSeconds + 1 * (shiftKey ? 10 : 1);
      handleChangeValue(val > duration ? duration : val);
      return;
    }
    if (key === 'ArrowLeft') {
      e.preventDefault();

      const val = playedSeconds - 1 * (shiftKey ? 10 : 1);
      handleChangeValue(val < 0 ? 0 : val);
      return;
    }
  };

  const handleChangeValue = (value: number) => {
    playerRef.current?.seekTo(value as number);
    dispatch({ type: ActionKind.SEEK, payload: value });
    dispatch({ type: ActionKind.FINISH, payload: false });
  };

  return (
    <div>
      <Slider
        id="progress-video"
        variant="progress-bar"
        progressValue={loaded * 100}
        onKeyDownCapture={handleKeyDown}
        min={0}
        max={isInf ? 1 : duration}
        value={isInf ? [1] : [playedSeconds]}
        disabled={isInf || !duration}
        step={0.1}
        className={cn({ 'h-4': !fullscreen, 'h-6': fullscreen })}
        size={fullscreen ? 'md' : 'sm'}
        onValueChange={([value]) => handleChangeValue(value)}
      />
    </div>
  );
};
