import React from 'react';

import {
  ChangeEmailForm,
  ChangeFullNameForm,
  ChangePhotoForm,
  PersonalInfoSection,
  ChangePasswordForm,
} from './components';
import { ConnectedAccountsForm } from './components/ConnectedAccountsForm';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import { GOOGLE_PROVIDER_ID, PASSWORD_PROVIDER_ID } from '../lib';

export const ProfileSettings = () => {
  const authProviders = useSelector(
    authUserModel.selectors.selectAuthProviders
  );
  return (
    <div>
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Profile
      </h1>
      <PersonalInfoSection title="Personal info">
        <ChangePhotoForm />
        <ChangeFullNameForm />
        <ChangeEmailForm />
        {!(
          authProviders.includes(PASSWORD_PROVIDER_ID) &&
          !authProviders.includes(GOOGLE_PROVIDER_ID)
        ) && <ConnectedAccountsForm />}
      </PersonalInfoSection>
      <PersonalInfoSection title="Password" isBottomSection>
        <ChangePasswordForm />
      </PersonalInfoSection>
    </div>
  );
};
