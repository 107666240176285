import { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPageModalModel } from '../../../entities/create-page-modal';
import { foldersModel } from '../../folders';
import { pagesModel } from '../model';

type Props = {
  renderButton: (onClick: () => void, isLoading: boolean) => ReactNode;
};

export const CreatePage: FC<Props> = ({ renderButton }) => {
  const dispatch = useDispatch();

  const isCreatePageLoading = useSelector(
    pagesModel.selectors.selectCreatePageIsLoading
  );

  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);

  const openCreatePageModal = () => {
    if (currentFolder) {
      dispatch(createPageModalModel.actions.openPageCreationModal());
    } else {
      dispatch(foldersModel.actions.setModalOpen(true));
    }
  };

  return <>{renderButton(openCreatePageModal, isCreatePageLoading)}</>;
};
