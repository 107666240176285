import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import slugify from 'slugify';
import { Severity, captureMessage } from '@sentry/react';

import { UserOnboardingStatus } from '@distribute/shared/types';
import { Input, Button } from '../../../../shared/ui';
import { DebounceSubdomainInput } from '../../../auth/workspace';
import { WorkspaceForm, workspaceSchema } from '../../../auth/workspace';
import { teamsModel } from '../../../teams';
import { onboardingModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';
import { OnboardingWrapper } from './OnboardingWrapper';
import { CompanyNameSearchInput } from '../../../team-brand-settings/ui/components/CompanyNameSearchInput';
import { ClearbitCompanyInfo } from '../../../../shared/api';

export const StepCreateWorkspace: FC = () => {
  const dispatch = useDispatch();
  const isCreatingOrUpdatingTeam = useSelector(
    onboardingModel.selectors.selectIsCreatingOrUpdatingTeam
  );
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);
  const subdomain = useSelector(onboardingModel.selectors.selectSubdomain);
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [hasCompanyLogo, setHasCompanyLogo] = useState(false);

  const setSubdomain = useCallback(
    (subdomain: string) => {
      dispatch(onboardingModel.actions.setSubdomain(subdomain));
    },
    [dispatch]
  );

  const handleCompanyChange = (name: string) => {
    setCompanyName(name);
    setHasCompanyLogo(false);
  };

  const handleCompanySelect = useCallback((info: ClearbitCompanyInfo) => {
    setCompanyName(info.name);
    setCompanyLogo(info.logo ?? '');
    setHasCompanyLogo(true);
  }, []);

  const user = useSelector(authUserModel.selectors.selectUser);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<WorkspaceForm>({
    resolver: yupResolver(workspaceSchema),
    mode: 'onChange',
    defaultValues: {
      name: `${user ? user.displayName.split(' ')[0] + "'s Workspace" : ''}`,
      isSubdomainValid: true,
    },
  });

  const name = watch('name');

  const handleFormSubmit = (data: WorkspaceForm) => {
    const brandCompany = {
      brandCompanyName: companyName,
      brandLogoUrl: companyLogo,
    };
    const actionData = {
      name: data.name,
      domain: subdomain,
      ...(companyName && brandCompany),
    };

    dispatch(onboardingModel.actions.createTeam(actionData));
  };

  useEffect(() => {
    if (!user) {
      slugify.extend({ $: '' });
      setSubdomain(
        slugify(name, {
          lower: true,
          trim: true,
          remove: /[*+~.()'"`!:@/]/g,
          strict: true,
        })
      );
    }
  }, [name, setSubdomain, user]);

  useEffect(() => {
    if (currentTeam) {
      captureMessage('Create Workspace Step', {
        level: Severity.Error,
        extra: {
          teamId: currentTeam.id,
          userId: user?.id,
        },
      });
    }
  }, [currentTeam, user]);

  return (
    <OnboardingWrapper
      step={UserOnboardingStatus.CREATE_TEAM}
      imageBlockBgSrc="../../../../assets/images/onboarding/bg-img-step-2.jpg"
      imageBlockSrc="../../../../assets/images/onboarding/img-step-2.png"
      imageBlockClassName="-translate-x-[2.5%] translate-y-[6%]"
    >
      <div className="mb-3 text-display-md text-gray-900 font-medium font-display text-center">
        Let's create your first workspace!
      </div>

      <div className="mb-8 text-center font-normal text-md text-gray-600">
        No worries, it can be changed later.
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <div className="mb-5">
          <Input
            label="Name *"
            {...register('name')}
            type="text"
            isError={!!errors.name}
            messageText={errors.name?.message}
          />
        </div>

        <div className="mb-5">
          <input {...register('isSubdomainValid')} type="checkbox" hidden />
          <DebounceSubdomainInput
            subdomain={subdomain}
            setSubdomain={setSubdomain}
            setIsSubdomainValid={(val) => setValue('isSubdomainValid', val)}
            isLoading={isCreatingOrUpdatingTeam}
            getValues={watch}
            label="Subdomain *"
            currentSubdomain={currentTeam?.domain ?? ''}
          />
        </div>

        <div className="mb-6">
          <CompanyNameSearchInput
            withLogo={hasCompanyLogo}
            value={companyName}
            logo={companyLogo}
            onChange={handleCompanyChange}
            handleCompanySelect={handleCompanySelect}
            label="Company Name"
          />
        </div>

        <div className="mb-6">
          <Button
            variant="text"
            color="primary"
            disabled={isCreatingOrUpdatingTeam}
            loading={isCreatingOrUpdatingTeam}
            type="submit"
            fullWidth
          >
            Create Workspace
          </Button>
        </div>
      </form>
    </OnboardingWrapper>
  );
};
