import { BrandColor } from '@distribute/shared/types';
import Color from 'color';

type ColorMode = 'dark' | 'light';

type Config = {
  mixColor: string;
  hueAngle: number;
  rotate: number;
  saturation: number;
  colorsAmount: number;
  colorsPalette: number[];
};

export type BrandColorsPalette = {
  [key: number]: string;
};

const LIGHT_FONT_COLOR = '#FFFFFF';
const DARK_FONT_COLOR = '#141414';

const darkConfig: Config = {
  mixColor: 'black',
  hueAngle: 50,
  rotate: -0,
  saturation: 0,
  colorsAmount: 4,
  colorsPalette: [600, 700, 800, 900],
};

const lightConfig: Config = {
  mixColor: 'white',
  hueAngle: 95,
  rotate: 0,
  saturation: 0,
  colorsAmount: 6,
  colorsPalette: [400, 300, 200, 100, 50, 25],
};

const generateConfig = (mode: ColorMode) => {
  if (mode === 'dark') return darkConfig;
  return lightConfig;
};

const generateFontColor = (color: string) => {
  const textColor = Color(color).isDark() ? LIGHT_FONT_COLOR : DARK_FONT_COLOR;
  return textColor;
};

const generateColors = (color: string, mode: ColorMode) => {
  const config = generateConfig(mode);
  const {
    colorsAmount,
    mixColor,
    rotate,
    saturation,
    hueAngle,
    colorsPalette,
  } = config;

  const brandColors: BrandColorsPalette = colorsPalette.reduce(
    (prev, palette, step) => {
      const variableStep: number = (step + 1) / colorsAmount;
      const newColor = Color(color)
        .rotate(variableStep * -rotate)
        .saturate(variableStep * (saturation / 100))
        .mix(Color(mixColor), (hueAngle / 100) * variableStep)
        .hex();
      return {
        ...prev,
        [palette]: newColor,
      };
    },
    {}
  );
  return brandColors;
};

export const generateColorsPalette = (color: string): BrandColor => {
  const defaultColor = {
    500: color,
  };
  const lightColors: BrandColorsPalette = generateColors(color, 'light');
  const darkColors: BrandColorsPalette = generateColors(color, 'dark');
  const fontColor = generateFontColor(darkColors[700]);
  const newBrandColor = {
    ...lightColors,
    ...defaultColor,
    ...darkColors,
    fontColor,
  };
  return newBrandColor as BrandColor;
};
