import { call, put } from 'redux-saga/effects';
import { AnalyticsEvents } from '@distribute/shared/types';

import { authChannelModel } from '../../../../entities/auth-channel';
import { authModel } from '../../../../entities/auth-operation';
import { getUser, getUserFromFb } from '../../../../entities/auth-user';
import { redirect } from '../../../../entities/history';
import { auth } from '../../../../shared/config/firebase';
import { getQueryParam, logger } from '../../../../shared/lib';
import { FirebaseUser } from '../../../../shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { applyActionCode } from 'firebase/auth';
import { analytics } from '../../../../entities/analytics';
import { navigate } from '../../../../processes/navigation';

export function* verifyEmail(code: string) {
  yield call(
    authModel.sagas.authOperationWithAlert,
    'Failed to verify email',
    function* () {
      const continueUrlParam = getQueryParam('continueUrl');

      yield applyActionCode(auth, code);
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('success', 'The email has been verified')
        )
      );

      if (continueUrlParam) {
        const continueUrl = new URL(continueUrlParam);

        yield put(
          navigate({ to: `${continueUrl.pathname}${continueUrl.search}` })
        );
      }
    }
  );
  try {
    const fbUser: FirebaseUser = yield getUserFromFb();
    yield fbUser.reload();
    yield getUser();
    yield put(
      authChannelModel.chan,
      authChannelModel.loggedIn({ redirectTo: 'onboarding' })
    );

    analytics.track(AnalyticsEvents.EMAIL_VERIFIED, { email: fbUser.email });
  } catch (e: unknown) {
    logger.error(e);
    yield call(redirect.toLogin);
  }
}
