import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '..';
import { put, select } from 'redux-saga/effects';
import { templatesApi } from '../../../shared/api';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';

export function* updateTemplateData({
  template,
  isShowSuccessNotification = false,
}: {
  template: TemplateExtended;
  isShowSuccessNotification: boolean;
}) {
  try {
    yield put(templatesModel.actions.setTemplateUpdating(true));

    const templates: TemplateExtended[] = yield select(
      templatesModel.selectors.selectTemplates
    );

    const updatedTemplate: TemplateExtended = yield templatesApi.updateTemplate(
      template.id,
      {
        ...template,
        updatedAt: new Date(),
      }
    );

    const filteredTemplates = templates.filter(
      (el) => el.id !== updatedTemplate.id
    );

    yield put(
      templatesModel.actions.setTemplates([
        updatedTemplate,
        ...filteredTemplates,
      ])
    );

    if (isShowSuccessNotification) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('success', 'The template has been updated!')
        )
      );
    }
  } catch (err) {
    logger.error(err);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update a template!')
      )
    );
  } finally {
    yield put(templatesModel.actions.setTemplateUpdating(false));
  }
}
