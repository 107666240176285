import { call, put, select, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { seoConfigurationsApi } from '../../../../shared/api/';
import { SeoConfiguration, Page } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pageSettingsModel } from '../index';
import { pagesModel } from '../../../pages';
import {
  RemoveSeoConfigurationsImageResponseType,
  UpdateSeoConfigurationsImageResponseType,
} from '@distribute/shared/api-types/seo-configuration';

const uploadFileChannel = channel<number>();

export function* updateSeoConfiguration({
  payload: { documentContentId, pageId, callback, ...rest },
}: ReturnType<typeof pageSettingsModel.actions.updateSeoConfiguration>) {
  try {
    yield put(actions.setUpdateSeoConfigurationIsLoading(true));

    const seoConfiguration: SeoConfiguration = yield call(
      seoConfigurationsApi.updateSeoConfigurations,
      documentContentId,
      pageId,
      rest
    );
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPage
    );

    yield put(
      actions.setSeoConfiguration({
        id: seoConfiguration.id,
        title: seoConfiguration.title,
        description: seoConfiguration.description,
        ogImage: seoConfiguration.ogImage,
      })
    );

    yield put(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        pageSlug: seoConfiguration.pageSlug,
      })
    );
    yield call(callback);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          `Failed to update seo configurations due to error`
        )
      )
    );
  } finally {
    yield put(actions.setUpdateSeoConfigurationIsLoading(false));
  }
}

export function* changeSeoConfigurationsImage({
  payload: { documentContentId, pageId, image },
}: ReturnType<typeof actions.changeSeoConfigurationsImage>) {
  try {
    yield put(actions.setIsUploadImageLoading(true));

    const seoConfiguration: UpdateSeoConfigurationsImageResponseType =
      yield call(
        seoConfigurationsApi.updateSeoConfigurationsImage,
        documentContentId,
        pageId,
        image,
        (progress) => uploadFileChannel.put(progress)
      );

    yield put(
      actions.setSeoConfiguration({
        ...seoConfiguration,
        ogImage: seoConfiguration.ogImage,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          `Failed to update seo configurations due to error`
        )
      )
    );
  } finally {
    yield put(actions.setIsUploadImageLoading(false));
    uploadFileChannel.put(0);
  }
}

export function* removeSeoConfigurationsImage({
  payload: { documentContentId, pageId },
}: ReturnType<typeof actions.removeSeoConfigurationsImage>) {
  try {
    yield put(actions.setIsRemoveImageLoading(true));

    const seoConfiguration: RemoveSeoConfigurationsImageResponseType =
      yield call(
        seoConfigurationsApi.removeSeoConfigurationsImage,
        documentContentId,
        pageId
      );

    yield put(
      actions.setSeoConfiguration({
        ...seoConfiguration,
        ogImage: seoConfiguration.ogImage,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to remove seo configurations`)
      )
    );
  } finally {
    yield put(actions.setIsRemoveImageLoading(false));
  }
}

export function* watchUploadSeoConfigurationImage() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;
    yield put(actions.setUploadImageProgress(progress));
  }
}
