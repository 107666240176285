import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LogInAsSuperUserAction,
  SignInWithEmailAndPasswordAction,
  SignUpAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  authError?: string;
  authIsLoading: boolean;
  emailFromTeamOrPageInvitationLink?: string;
  isWaitingToRedirectAfterAuth: boolean;
};
const initialState: State = {
  authError: undefined,
  authIsLoading: false,
  emailFromTeamOrPageInvitationLink: undefined,
  isWaitingToRedirectAfterAuth: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError: (state, { payload: authError }: PayloadAction<string>) => ({
      ...state,
      authError,
    }),
    setAuthIsLoading: (
      state,
      { payload: authIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      authIsLoading,
    }),
    setEmailFromTeamOrPageInvitationLink: (
      state,
      {
        payload: emailFromTeamOrPageInvitationLink,
      }: PayloadAction<string | undefined>
    ) => ({
      ...state,
      emailFromTeamOrPageInvitationLink,
    }),
    setIsWaitingToRedirectAfterAuth: (
      state,
      { payload: isWaitingToRedirectAfterAuth }: PayloadAction<boolean>
    ) => ({
      ...state,
      isWaitingToRedirectAfterAuth,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  signInWithEmailAndPasswordClick:
    createAction<SignInWithEmailAndPasswordAction>(
      `auth/signInWithEmailAndPasswordClick`
    ),
  appsumoSignUpClick: createAction<SignUpAction>('auth/appsumoSignUpClick'),
  signUpClick: createAction<SignUpAction>('auth/signUpClick'),
  signOutClick: createAction<void>('auth/signOutClick'),
  forgotPasswordClick: createAction<void>('profileSetting/forgotPasswordClick'),
  verifyEmailClick: createAction<void>('auth/verifyEmail'),
  googleSignupClick: createAction('auth/googleSignupClick'),
  loginAsSuperuser: createAction<LogInAsSuperUserAction>(
    'auth/loginAsSuperuser'
  ),
};
