import { call, put, select } from 'redux-saga/effects';
import { UserToTeamFiltered } from '@distribute/shared/types';
import { teamMembersModel } from '../';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';

export function* updateTeamMemberRole({
  payload: { teamMemberId, teamId, role },
}: ReturnType<typeof teamMembersModel.actions.updateTeamMemberRole>) {
  try {
    yield call(teamsApi.updateTeamMemberRole, teamId, teamMemberId, { role });
    const teamMembers: UserToTeamFiltered[] = yield select(
      teamsModel.selectors.selectCurrentTeamMembers
    );
    const updatedTeamMembers = teamMembers.map((teamMember) =>
      teamMember.id === teamMemberId ? { ...teamMember, role } : teamMember
    );

    yield put(teamsModel.actions.setCurrentTeamMembers(updatedTeamMembers));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The user role has been updated')
      )
    );
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update the user role')
      )
    );
  }
}
