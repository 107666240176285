import { matchPath } from 'react-router-dom';

export const matchParams = <
  TParams extends { [K in keyof TParams]?: string } = { sequenceNumber: string }
>(
  pathname: string,
  route: string,
  exact = true
) => {
  const match = matchPath<TParams>(pathname, {
    path: route,
    exact,
  });
  if (match) {
    return { ...match.params };
  }
  return null;
};
