import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AlertBanner } from '..';
import { teamsModel } from '../../../../teams';
import { Button } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { useSubscriptionInfo } from '../../../hooks';

type IProps = {
  onUpdatePaymentMethod: () => void;
};

export const AlertBannerTrialExpireSoon: FC<IProps> = ({
  onUpdatePaymentMethod,
}) => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const { isHasOnboardingPromoCode } = useSubscriptionInfo();

  if (!stripeSubscription) return null;

  return (
    <AlertBanner
      isShowDowngradeToFreeFeatures
      theme="warning"
      title="Your trial will expire soon! 😢"
      description={`You'll be downgraded to our Free plan on ${moment(
        stripeSubscription.trialEnd
      ).format(
        'MMM D, YYYY'
      )} unless you add a payment method before then. With free plan you will miss:`}
    >
      <div className="flex flex-col items-end gap-3">
        <Button
          color="primary"
          variant="icon-text"
          iconLeftName={IconMap.CreditCard02}
          iconLeftWidth={20}
          onClick={onUpdatePaymentMethod}
        >
          Add Credit Card
        </Button>
        {isHasOnboardingPromoCode && (
          <span className="text-xs text-primary-700 text-right">
            Add a credit card and receive 25% off your first bill.
            <br />
            Cancel anytime.
          </span>
        )}
      </div>
    </AlertBanner>
  );
};
