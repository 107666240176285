import { Snippet } from '@distribute/shared/types';
import { FC, useRef } from 'react';
import {
  LOOM_URL_REGEX,
  YOUTUBE_URL_REGEX,
} from '../../../../../shared/constants';
import { getEmbedUrlFromYoutubeUrl } from '../../../../../entities/tiptap-editor/extensions/YoutubeEmbed/utils';

type Props = {
  snippet: Snippet;
};

export const VideoPreview: FC<Props> = ({ snippet }) => {
  const videoEl = useRef<HTMLVideoElement | null>(null);

  const youtube = snippet.content.url?.match(YOUTUBE_URL_REGEX);
  const loom = snippet.content.url?.match(LOOM_URL_REGEX);

  const isIframe = youtube || loom;

  return (
    <div
      className="w-full h-full flex items-center justify-center bg-gray-200 border-light-6 border rounded-2xl"
      style={{ backgroundImage: 'url(../../../assets/images/checker.svg)' }}
    >
      {isIframe ? (
        <iframe
          title="snippet video"
          src={
            youtube
              ? getEmbedUrlFromYoutubeUrl({
                  url: snippet.content.url,
                }) || ''
              : snippet.content.url
          }
          className="w-full h-full"
        />
      ) : (
        <video
          ref={videoEl}
          src={snippet.content.url}
          controls
          className="object-cover w-full h-full rounded-md"
        ></video>
      )}
    </div>
  );
};
