import { call, put } from 'redux-saga/effects';
import { GetUpcomingInvoiceResponseType } from '@distribute/shared/api-types/subscriptions';
import { subscriptionApi } from '../../../../shared/api/axios/subscription';
import { logger } from '../../../../shared/lib';
import { subscriptionModel } from '..';

export function* getUpcomingInvoice({
  payload,
}: ReturnType<typeof subscriptionModel.actions.getUpcomingInvoice>) {
  try {
    yield put(subscriptionModel.actions.setIsLoadingUpcomingInvoice(true));
    const upcomingInvoiceInfo: GetUpcomingInvoiceResponseType = yield call(
      subscriptionApi.getUpcomingInvoice,
      payload
    );

    yield put(
      subscriptionModel.actions.setUpcomingInvoiceInfo(upcomingInvoiceInfo)
    );
  } catch (error: unknown) {
    logger.error(error);
  } finally {
    yield put(subscriptionModel.actions.setIsLoadingUpcomingInvoice(false));
  }
}
