import {
  GetGatedContentResponseType,
  UpdateGatedContentRequestType,
  UpdateGatedContentResponseType,
} from '@distribute/shared/api-types/gated-content';

import { api } from './instance';

export const gatedContentApi = {
  async getGatedContent(documentContentId: number) {
    const { data } = await api.get<GetGatedContentResponseType>(
      `gated-content/${documentContentId}`
    );

    return data;
  },

  async updateGatedContent(
    documentContentId: number,
    data: UpdateGatedContentRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateGatedContentResponseType>(
        `gated-content/${documentContentId}`,
        data
      );
    return responseData;
  },
};
