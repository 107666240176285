import { Node, mergeAttributes } from '@tiptap/core';
import { ColumnExt } from './Column';

export interface ColumnBlockOptions {
  nestedColumns: boolean;
  columnType: Node;
}
const COLUMN_BLOCK_NAME_TYPE = 'columnBlock';

export const ColumnBlockExt = Node.create<ColumnBlockOptions>({
  name: COLUMN_BLOCK_NAME_TYPE,
  group: 'block',
  content: 'column{2,}',
  isolating: true,
  selectable: true,
  addOptions() {
    return {
      nestedColumns: false,
      columnType: ColumnExt,
    };
  },

  renderHTML({ HTMLAttributes }) {
    const style = 'width: 100%; display: flex; gap: 24px; padding: 8px 0;';
    const attrs = mergeAttributes(
      HTMLAttributes,
      { class: 'column-block' },
      { style: style }
    );
    return ['div', attrs, 0];
  },
});
