import { call, put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamInfoSettingsModel } from '..';
import { teamsModel } from '../../../teams';
import {
  TeamFiltered,
  User,
  UserToTeamFiltered,
} from '@distribute/shared/types';
import { redirect } from '../../../../entities/history';
import { getTeamPermissions } from '../../../teams/lib';
import { authUserModel } from '../../../../entities/auth-user';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* leaveTeam({
  payload: { userIdToTransferOwnership, teamId, cb },
}: ReturnType<typeof actions.leaveTeam>) {
  try {
    yield put(actions.setIsLeavingTeam(true));

    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const currentUser: User = yield select(
      authUserModel.selectors.selectUserWithError
    );
    const currentTeamMembers: UserToTeamFiltered[] = yield select(
      teamsModel.selectors.selectCurrentTeamMembers
    );
    const { isOwner } = getTeamPermissions(currentUser, currentTeamMembers);

    yield call(teamsApi.leave, teamId, {
      userIdToTransferOwnership,
    });

    if (currentTeam.stripeSubscription && isOwner) {
      yield call(teamsModel.sagas.loadTeamMembers);
      cb(true);
      return;
    }

    const updatedTeams = teams.filter((i) => i.id !== teamId);
    const newCurrentTeam: TeamFiltered | undefined = updatedTeams[0];

    if (newCurrentTeam) {
      yield put(
        teamsModel.actions.changeCurrentTeam({ teamId: newCurrentTeam.id })
      );
      yield call(redirect.toWorkspace);
    } else {
      yield call(teamsModel.sagas.handleWhenUserHasNoTeams);
      yield put(teamsModel.actions.setCurrentTeam(newCurrentTeam));
    }

    yield put(teamsModel.actions.setTeams(updatedTeams));
    yield put(
      teamInfoSettingsModel.actions.setLeftTeam(
        teams.find((i) => i.id === teamId) ?? null
      )
    );
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to leave the team')
        )
      )
    );
  } finally {
    yield put(actions.setIsLeavingTeam(false));
  }
}
