import { Team } from './team';
import { User } from './user';

export enum UserToTeamLogAction {
  INVITED = 'invited',
  INVITE_ACCEPTED = 'inviteAccepted',
  JOINED = 'joined',
  LEFT = 'left',
  DELETED = 'deleted',
}

export type UserToTeamLog = {
  id: number;
  createdAt: Date;
  externalUserEmail: string | null;
  user: User | null;
  userFirebaseUid: string | null;
  userEmail: string | null;
  userDisplayName: string | null;
  userPhotoUrl: string | null;
  team: Team;
  action: UserToTeamLogAction;
};
