import { call, put } from 'redux-saga/effects';

import { authModel } from '../../../../entities/auth-operation';
import { LOGIN_ROUTE } from '../../../../entities/history';
import { auth } from '../../../../shared/config';
import { createNotification, snackbarModel } from '../../../snackbar';
import { confirmPasswordReset as confirmPasswordResetFb } from 'firebase/auth';
import { navigate } from '../../../../processes/navigation';

export function* confirmPasswordReset(code: string, password: string) {
  yield call(
    authModel.sagas.authOperationWithAlert,
    'Failed to update password',
    function* () {
      yield confirmPasswordResetFb(auth, code, password);
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('success', 'The password has been updated')
        )
      );
      yield put(navigate({ to: LOGIN_ROUTE }));
    }
  );
}
