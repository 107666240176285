import { DOMSerializer, Node } from '@tiptap/pm/model';
import { Content, Extension, ReactRenderer } from '@tiptap/react';
import { ComponentType } from 'react';
import tippy, { Instance, Props as TippyProps } from 'tippy.js';

export type HtmlBetweenResult = {
  from: number;
  to: number;
  content: string;
};

const isListNode = (node: Node | null) => {
  return node?.type.name === 'bulletList' || node?.type.name === 'orderedList';
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    utils: {
      getHtmlBetween(
        selectionStart: number,
        selectionEnd: number,
        updateSelection?: boolean
      ): HtmlBetweenResult;
      replaceSelection(options: {
        from: number;
        to: number;
        content: Content;
      }): ReturnType;
      insertBelow(options: { position: number; content: Content }): ReturnType;
      renderReactComponentWithTippy<P extends Record<string, unknown>>(
        component: ComponentType<P>,
        componentProps: P,
        tippyProps?: Partial<TippyProps>
      ): {
        popup: Instance<TippyProps>;
        renderedComponent: ReactRenderer<unknown, P>;
        destroyComponent: () => void;
      };
      setHighlightSelection(): ReturnType;
      unsetHighlightSelection(): ReturnType;
      unsetTableSelection(): ReturnType;
    };
  }
}

export const UtilsExtension = Extension.create({
  name: 'utils',
  addCommands() {
    return {
      getHtmlBetween: (
        selectionStart: number,
        selectionEnd: number,
        updateSelection = false
      ): HtmlBetweenResult => {
        const editor = this.editor;
        const { state } = editor;

        const nodesArray: string[] = [];

        let selectionFrom = selectionStart;
        let selectionTo = selectionEnd;

        state.doc.nodesBetween(
          selectionStart,
          selectionEnd,
          (node, pos, parent) => {
            if (isListNode(node)) {
              return;
            }

            if (pos <= selectionFrom) {
              selectionFrom = pos;
            }
            const endOfNode = pos + node.nodeSize - 1;
            if (endOfNode >= selectionTo) {
              selectionTo = endOfNode;
            }

            if (parent !== state.doc && !isListNode(parent)) {
              return;
            }

            const serializer = DOMSerializer.fromSchema(editor.schema);
            const dom = serializer.serializeNode(node);
            const tempDiv = document.createElement('div');
            tempDiv.appendChild(dom);
            nodesArray.push(tempDiv.innerHTML);
          }
        );

        if (updateSelection) {
          setTimeout(
            editor.chain().focus().setTextSelection({
              from: selectionFrom,
              to: selectionTo,
            }).run,
            0
          );
        }

        return {
          content: nodesArray.join(''),
          from: selectionFrom,
          to: selectionTo,
        };
      },
      replaceSelection:
        ({ from, to, content }) =>
        ({ chain }) => {
          const fromPos = from > 0 ? from - 1 : 0;
          return chain()
            .deleteRange({
              from: fromPos,
              to,
            })
            .insertContentAt(fromPos, content, { updateSelection: true })
            .run();
        },
      renderReactComponentWithTippy: (
        component,
        componentProps,
        tippyProps
      ) => {
        const editor = this.editor;

        const scrollHandler = () => {
          const { left, top } = tippyProps?.getReferenceClientRect
            ? tippyProps.getReferenceClientRect()
            : editor.view.coordsAtPos(editor.view.state.selection.$anchor.pos);

          popup.popper.style.transform = `translate(${left}px, ${top}px)`;
        };
        const root = document.getElementById('root');

        const [popup] = tippy('body', {
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          theme: 'trigger-menu',
          duration: 0,
          zIndex: 10,
          popperOptions: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
          getReferenceClientRect: () => {
            const coords = editor.view.coordsAtPos(
              editor.view.state.selection.$anchor.pos
            );

            return new DOMRect(coords.left, coords.bottom, 0, 0);
          },
          appendTo: () => document.body,
          onCreate: () => {
            root?.addEventListener('scroll', scrollHandler);
          },
          onDestroy: () => {
            root?.removeEventListener('scroll', scrollHandler);
          },
          ...tippyProps,
        });
        const renderedComponent = new ReactRenderer(component, {
          editor,
          props: componentProps,
        });

        function handleScroll() {
          popup.setProps({
            getReferenceClientRect: tippyProps?.getReferenceClientRect,
          });
        }

        document.addEventListener('scroll', handleScroll, true);
        popup.setContent(renderedComponent.element);
        popup.show();

        return {
          popup,
          renderedComponent,
          destroyComponent: () => {
            document.removeEventListener('scroll', handleScroll, true);
            popup.destroy();
            renderedComponent.destroy();
          },
        };
      },
      setHighlightSelection:
        () =>
        ({ chain }) => {
          return chain().setMeta('addToHistory', false).setHighlight().run();
        },
      unsetHighlightSelection:
        () =>
        ({ chain, state }) => {
          const currentSelection = {
            from: state.selection.from,
            to: state.selection.to,
          };
          return chain()
            .setMeta('addToHistory', false)
            .selectAll()
            .unsetHighlight()
            .setTextSelection(currentSelection)
            .run();
        },

      unsetTableSelection:
        () =>
        ({ state, tr, dispatch }) => {
          let isChanged = false;

          if (!dispatch) {
            return false;
          }

          state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
            if (node.type.name === 'table' && node.attrs.selected) {
              const transaction = tr
                .setNodeAttribute(pos, 'selected', false)
                .setMeta('addToHistory', false)
                .setMeta('isPreventUpdate', true);
              dispatch(transaction);
              isChanged = true;
            }
          });
          return isChanged;
        },
    };
  },
});
