import { call } from 'redux-saga/effects';

import { redirect } from '../../../../entities/history';

import {
  RECOVER_EMAIL_TYPE,
  RESET_PASSWORD_TYPE,
  VERIFY_AND_CHANGE_EMAIL_TYPE,
  VERIFY_EMAIL_TYPE,
} from '../config';

import { recoverEmail } from './recoverEmail';
import { verifyAndChangeEmail } from './verifyAndChangeEmail';
import { verifyEmail } from './verifyEmail';

export function* authActionHandler({ search }: { search: string }) {
  const params = new URLSearchParams(search);
  const code = params.get('oobCode') || '';
  const mode = params.get('mode');

  if (!code || !mode) {
    return;
  }

  switch (mode) {
    case RECOVER_EMAIL_TYPE:
      yield call(recoverEmail, code);
      break;
    case VERIFY_EMAIL_TYPE:
      yield call(verifyEmail, code);
      break;
    case VERIFY_AND_CHANGE_EMAIL_TYPE:
      yield call(verifyAndChangeEmail, code);
      break;
    case RESET_PASSWORD_TYPE:
      yield call(redirect.toCompletePassword, code);
      break;
    default:
      yield call(redirect.toWorkspace);
  }
}
