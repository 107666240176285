import { createContext, useContext, useEffect, useMemo } from 'react';
import { Editor, EditorOptions, useEditor } from '@tiptap/react';
import { history } from '@tiptap/pm/history';

export type TiptapEditorContextValue = {
  editor: Editor | null;
  isEditorReady: boolean;
};

export const TiptapEditorContext = createContext<TiptapEditorContextValue>({
  editor: null,
  isEditorReady: false,
});

export type TiptapEditorProviderProps = {
  children: React.ReactNode;
  options?: Partial<EditorOptions>;
  preventClearHistory?: boolean;
};

export const TiptapEditorProvider = ({
  children,
  options,
  preventClearHistory,
}: TiptapEditorProviderProps) => {
  const editorOptions: Partial<EditorOptions> = useMemo(() => {
    return {
      ...options,
      extensions: options?.extensions || [],
      content: options?.content || '',
      editorProps: {
        ...options?.editorProps,
        handleDOMEvents: {
          ...options?.editorProps?.handleDOMEvents,

          dragstart: (_, e) => {
            e.preventDefault();
          },
        },
      },
    };
  }, [options]);

  const editor = useEditor(editorOptions);

  // Clear selection highlighting when the user closes the browser during AI writing.
  // Clear selection table when the user closes the browser during table updating.
  useEffect(() => {
    editor?.commands.unsetHighlightSelection();
    editor?.commands.unsetTableSelection();
  }, [editor]);

  useEffect(() => {
    if (editor && options?.content && !preventClearHistory) {
      editor.commands.setContent(options.content, false);
      editor.unregisterPlugin('history');
      editor.registerPlugin(history());
    }
  }, [options?.content, preventClearHistory]);

  return (
    <TiptapEditorContext.Provider value={{ editor, isEditorReady: !!editor }}>
      {children}
    </TiptapEditorContext.Provider>
  );
};

export const TiptapEditorConsumer = TiptapEditorContext.Consumer;

export const useTiptapEditor = () => useContext(TiptapEditorContext);
