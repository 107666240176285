import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { recordingsModel } from '../../../model';

import {
  UserPermissionToVideoRecordRole,
  VideoRecordTeamMembersPermission,
} from '@distribute/shared/types';

import {
  DEFAULT_RATIO,
  getMuxImage,
  getMuxStream,
} from '@distribute/shared/utils';

import { RecordDescription } from './RecordDescription';
import { RecordPlayer } from '../RecordPlayer';

export const RecordContent: FC = () => {
  const {
    playbackId,
    status,
    statusConvert,
    createdAt,
    teamMembersPermission,
    userPermissionToVideoRecords,
    userPermissionToVideoRecordsCount,
    metadata,
  } = useSelector(recordingsModel.selectors.selectRecord);

  const owner = userPermissionToVideoRecords.find(
    (uV) => uV.role === UserPermissionToVideoRecordRole.OWNER
  );

  return (
    <div
      className={cn(
        'flex flex-col sm:flex-col-reverse sm:gap-y-4 gap-y-8 py-8 sm:pt-0',
        'mx-auto max-w-240'
      )}
    >
      <div className="px-8 sm:px-4">
        <RecordDescription
          createdAt={createdAt}
          displayName={owner?.user?.displayName || ''}
          photoUrl={owner?.user?.photoUrl || ''}
          isSharedWithTeam={
            teamMembersPermission !==
            VideoRecordTeamMembersPermission.INVITE_ONLY
          }
          membersCount={userPermissionToVideoRecordsCount}
        />
      </div>
      <div className="px-8 sm:px-0">
        <RecordPlayer
          status={status}
          statusConvert={statusConvert}
          url={getMuxStream(playbackId || '')}
          aspectRatio={metadata?.mux?.aspectRatio || DEFAULT_RATIO}
          posterUrl={getMuxImage(playbackId || '')}
        />
      </div>
    </div>
  );
};
