type RGBColor = [number, number, number];

const MAX_VALUE_FOR_8_BIT_COLOR = 255;
const S_RGB_COLOR_SPACE = 0.04045;
const LINEAR_TRANSFORMATION_DIVISOR = 12.92;
const EXPONENTIAL_TRANSFORMATION_ADDITION = 0.055;
const EXPONENTIAL_TRANSFORMATION_DIVISOR = 1.055;
const EXPONENTIAL_TRANSFORMATION = 2.4;
const RED_COEFFICIENT = 0.2126;
const GREEN_COEFFICIENT = 0.7152;
const BLUE_COEFFICIENT = 0.0722;

export function calculateRelativeLuminance(colorText: string): number {
  const rgb = getRGBFromText(colorText);

  if (!rgb) {
    return 0;
  }

  const [r, g, b] = rgb.map((value) => {
    const sRGB = value / MAX_VALUE_FOR_8_BIT_COLOR;
    return sRGB <= S_RGB_COLOR_SPACE
      ? sRGB / LINEAR_TRANSFORMATION_DIVISOR
      : ((sRGB + EXPONENTIAL_TRANSFORMATION_ADDITION) /
          EXPONENTIAL_TRANSFORMATION_DIVISOR) **
          EXPONENTIAL_TRANSFORMATION;
  });

  return RED_COEFFICIENT * r + GREEN_COEFFICIENT * g + BLUE_COEFFICIENT * b;
}

function getRGBFromText(textColor: string): RGBColor | null {
  const isRGBColor = isRGB(textColor);
  const isHexColor = isHex(textColor);

  if (isRGBColor) {
    return getRGBFromRGBText(textColor);
  } else if (isHexColor) {
    return getRGBFromHex(textColor);
  } else {
    return null;
  }
}

function getRGBFromHex(hex: string): RGBColor | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

export function getRGBFromRGBText(text: string): RGBColor | null {
  const result = text.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/i);
  return result
    ? [
        parseInt(result[1], 10),
        parseInt(result[2], 10),
        parseInt(result[3], 10),
      ]
    : null;
}

function isRGB(text: string): boolean {
  const regex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/i;
  return regex.test(text);
}

function isHex(text: string): boolean {
  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  return regex.test(text);
}
