import { put } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { authApi } from '../../../../shared/api';
import { DBUser } from '../../../../shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { onboardingModel } from '..';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';

export function* updateUsagePlan({
  payload,
}: ReturnType<typeof onboardingModel.actions.updateUsagePlan>) {
  try {
    yield put(onboardingModel.actions.setIsLoadingUsagePlan(true));

    const user: DBUser = yield authApi.updateProfile({
      ...payload,
      onboardingStatus: UserOnboardingStatus.CREATE_TEAM,
    });

    yield put(authUserModel.actions.setDBUser(user));

    analytics.track(AnalyticsEvents.ONBOARDING_USAGE_PLAN, payload);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(error, 'Failed to update'))
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsLoadingUsagePlan(false));
  }
}
