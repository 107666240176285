import { noop } from 'lodash';
import { FC } from 'react';
import hint from '../../../../assets/images/cover-size-hint.svg';
import { Icon, Modal } from '../../../../shared/ui';

import { Info } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

export type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const sizes: {
  title: string;
  icon: keyof typeof IconMap;
  description: React.ReactNode;
}[] = [
  {
    title: 'Desktop',
    icon: 'Laptop01',
    description: (
      <p>
        For Desktops or laptops screens, use a cover size of{' '}
        <span className="font-semibold">1600px x 540px</span>.
      </p>
    ),
  },
  {
    title: 'Tablet',
    icon: 'Tablet01',
    description: (
      <p>
        On iPad, a cover size is{' '}
        <span className="font-semibold">1170px x 250px</span> due to cropping.
      </p>
    ),
  },
  {
    title: 'Mobile',
    icon: 'Phone01',
    description: (
      <p>
        For smartphones, aim for{' '}
        <span className="font-semibold">1170px x 540px</span> cover sizeto fit
        diverse screens.
      </p>
    ),
  },
];

export const PageCoverHintModal: FC<Props> = ({ isOpen, onClose = noop }) => {
  return (
    <Modal
      clearPadding={true}
      isShowCancelButton={false}
      isOpen={isOpen}
      className="flex flex-col w-200 p-1 max-h-[calc(100vh-10%)] overflow-hidden"
      onClose={onClose}
    >
      <div className="overflow-y-scroll rounded-lg">
        <div className="h-88 relative">
          <img
            alt="cover size hint"
            className="pointer-events-none rounded-tl-lg rounded-tr-lg top-0 left-0"
            src={hint}
          />
        </div>
        <div className="p-11 flex flex-col gap-8">
          <p className="text-md text-gray-600">
            The optimal dimensions of a page's cover image change depending on
            the device it is viewed on. Here is a brief summary:
          </p>
          <div className="flex flex-row gap-8 text-gray-600">
            {sizes.map(({ title, icon, description }) => (
              <div key={title} className="flex flex-col gap-2 w-1/3">
                <div className="flex flex-row gap-3 items-center">
                  <Icon glyph={IconMap[icon]} />
                  <span className="text-md font-semibold">{title}</span>
                </div>
                <div className="text-md">{description}</div>
              </div>
            ))}
          </div>
          <Info>
            <p className="text-gray-700">
              Consider{' '}
              <span className="font-semibold">
                using a canvas of 1600px by 540px
              </span>{' '}
              for the cover image section,{' '}
              <span className="font-semibold">
                with the image positioned at 1170px by 280px
              </span>{' '}
              to ensure display on all devices.
            </p>
          </Info>
        </div>
      </div>
    </Modal>
  );
};
