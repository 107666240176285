import { Snippet } from '@distribute/shared/types';
import { FC } from 'react';

type Props = {
  snippet: Snippet;
};

export const ImagePreview: FC<Props> = ({ snippet }) => {
  return (
    <div
      className="w-full h-full flex items-center justify-center bg-gray-200 border-light-6 border rounded-2xl"
      style={{ backgroundImage: 'url(../../../assets/images/checker.svg)' }}
    >
      <img
        src={snippet.content.url}
        alt="snippet content"
        className="object-contain max-w-full max-h-full"
      />
    </div>
  );
};
