import { Snippet, SnippetType } from '@distribute/shared/types';
import { Button, Icon, Modal, RadioButton } from '../../../../../shared/ui';
import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { IconMap } from '../../../../../shared/sprite';
import { UploadSnippetTab } from '../create-snippet/UploadSnippetTab';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../../model';
import {
  AddingMethodEnum,
  snippetModalTitleByType,
} from '../create-snippet/lib/config';
import { updateOptions } from './config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  snippet: Snippet;
};

export type ReplaceSnippetForm = {
  url: string;
  updateOnlyNew?: boolean;
};

export const ReplaceSnippetModal: FC<Props> = ({
  isOpen,
  onClose,
  snippet,
}) => {
  const isLoading = useSelector(
    snippetsModel.selectors.selectIsUpdateSnippetLoading
  );

  const [url, setUrl] = useState<string | undefined>(undefined);

  const [addingMethod, setAddingMethod] = useState(AddingMethodEnum.UPLOAD);

  const dispatch = useDispatch();

  useEffect(() => {
    setUrl('');
  }, [addingMethod]);

  const [isUpdateVariantModalOpen, setIsUpdateVariantModalOpen] =
    useState(false);

  const [updateOnlyNew, setUpdateOnlyNew] = useState(0);

  const handleClose = () => {
    setUrl('');
    setAddingMethod(AddingMethodEnum.UPLOAD);
    setIsUpdateVariantModalOpen(false);
    onClose();
  };

  const handleSubmit = () => {
    if (!url) {
      return;
    }

    if (snippet.usedCount > 0 && !isUpdateVariantModalOpen) {
      setIsUpdateVariantModalOpen(true);
      return;
    }

    dispatch(
      snippetsModel.actions.replaceSnippet({
        snippetId: snippet.id,
        url,
        updateOnlyNew: Boolean(updateOnlyNew),
        callback: handleClose,
      })
    );
  };

  return (
    <Modal
      onClose={handleClose}
      dialogClassName="z-55"
      isOpen={isOpen}
      className={cn('relative overflow-hidden !p-0 !pb-23.5 ', {
        'min-h-160 w-120': !isUpdateVariantModalOpen,
        'w-100': isUpdateVariantModalOpen,
      })}
      isShowCancelButton={false}
    >
      <div className="px-6 pt-6 pb-2">
        {isUpdateVariantModalOpen ? (
          <div>
            <p className="mb-4 font-medium font-display text-display-xs">
              Snippet Update
            </p>
            <p className="text-sm text-gray-600 mb-4">
              Do you want to update the current snippet on all existing pages,
              or save the changes only for new pages?
            </p>
            <div className="flex flex-col gap-2">
              {updateOptions.map((option) => (
                <RadioButton
                  name="update-options"
                  label={option.label}
                  key={option.id}
                  value={option.value}
                  onChange={() => setUpdateOnlyNew(option.value)}
                  defaultChecked={option.value === updateOnlyNew}
                />
              ))}
            </div>

            <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 bg-base-white">
              <Button
                fullWidth
                color="secondary"
                variant="text"
                type="button"
                onClick={onClose}
              >
                Undo Replacement
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="text"
                fullWidth
                onClick={handleSubmit}
                disabled={!url}
                loading={isLoading}
              >
                Apply
              </Button>
            </div>
          </div>
        ) : (
          <>
            <img
              src="../../../assets/images/bg-top-dots-part.svg"
              alt="background"
              className="absolute top-0 left-0"
            />
            <div className="relative mb-5 z-1">
              <div className="flex items-center justify-between mb-4">
                <div className="p-3 bg-gray-100 rounded-full">
                  <Icon
                    glyph={snippetModalTitleByType[snippet.type].icon}
                    className="text-gray-500"
                  />
                </div>
                <Icon
                  glyph={IconMap.XClose}
                  className="text-gray-500 cursor-pointer shrink-0"
                  width={24}
                  onClick={handleClose}
                />
              </div>
              <p className="mb-1 font-medium font-display text-display-xs">
                Replace {snippetModalTitleByType[snippet.type].title}
              </p>
            </div>
            {snippet.type !== SnippetType.TEXT && (
              <UploadSnippetTab
                type={snippet.type}
                url={url}
                setUrl={setUrl}
                addingMethod={addingMethod}
                setAddingMethod={setAddingMethod}
                onClose={handleClose}
                customBottomButtons={
                  <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white">
                    <Button
                      fullWidth
                      color="secondary"
                      variant="text"
                      type="button"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="text"
                      fullWidth
                      onClick={handleSubmit}
                      disabled={!url}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
