import { SeoConfiguration } from '@distribute/shared/types';
import { seoConfigurationsApi } from '../../../shared/api/';
import { call, put } from 'redux-saga/effects';
import { pageSettingsModel } from '../model';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';

export function* setPageSettingsData(documentContentId: number) {
  try {
    yield put(pageSettingsModel.actions.setPageSettingsStatus('pending'));

    const seoConfiguration: SeoConfiguration = yield call(
      seoConfigurationsApi.getSeoConfigurations,
      documentContentId
    );

    yield put(pageSettingsModel.actions.setSeoConfiguration(seoConfiguration));
    yield put(pageSettingsModel.actions.setPageSettingsStatus('success'));
  } catch (e: unknown) {
    logger.error(e);
    yield put(pageSettingsModel.actions.setPageSettingsStatus('error'));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show seo configurations`)
      )
    );
  }
}
