import { FC } from 'react';
import { formatMsToTimePassed } from '@distribute/shared/utils';
import cn from 'classnames';

import { VideoRecordType } from '@distribute/shared/api-types/video-record';
import {
  VideoRecordTeamMembersPermission,
  UserPermissionToVideoRecordRole,
} from '@distribute/shared/types';

import { DEFAULT_NAME } from '../../config';

import { RecordCategory } from './types';

import { RecordMemberStatus } from '../shared';
import { TimeAgo, Avatar } from '../../../../shared/ui';

type Props = {
  record: VideoRecordType;
  category: RecordCategory;
};

export const RecordCardDescription: FC<Props> = ({ category, record }) => (
  <div className="mt-auto flex flex-col gap-y-1.5">
    {category === 'shared' && <RecordCreatedBy {...record} />}
    <h6 className="font-semibold text-gray-700 text-md truncate">
      {record.name || DEFAULT_NAME}
    </h6>
    <div className="flex flex-nowrap gap-x-4 items-center justify-between text-sm text-gray-600">
      <p>
        <TimeAgo datetime={record.createdAt} locale="my" />
      </p>
      {category === RecordCategory.MY && (
        <RecordMemberStatus
          isSharedWithTeam={
            record.teamMembersPermission !==
            VideoRecordTeamMembersPermission.INVITE_ONLY
          }
          membersCount={record.userPermissionToVideoRecords.length}
        />
      )}
    </div>
  </div>
);

export const RecordCardDuration: FC<{ duration: number }> = ({ duration }) =>
  duration ? (
    <div
      className={cn(
        'bg-gray-900/[0.75] backdrop-blur-[10px] rounded-2xl text-sm text-base-white font-medium',
        'px-2.5 py-0.5',
        'absolute bottom-2 right-2'
      )}
    >
      {formatMsToTimePassed(duration)}
    </div>
  ) : null;

export const RecordCardPoster: FC<{ posterUrl: string }> = ({ posterUrl }) => (
  <img
    src={posterUrl}
    alt="poster"
    className="object-cover w-full h-full block bg-gray-100"
  />
);

export const RecordCreatedBy: FC<VideoRecordType> = (record) => {
  const data = record.userPermissionToVideoRecords.find(
    (uV) => uV.role === UserPermissionToVideoRecordRole.OWNER
  );

  return (
    <div className="flex items-center gap-2 truncate">
      <Avatar
        displayName={data?.user?.displayName || ''}
        src={data?.user?.photoUrl}
        size="3xs"
        isUserAvatar
        className="border-light-7 border-[0.5px] !bg-base-white"
      />
      <div className="text-xs text-gray-700 truncate">
        by{' '}
        <span className="font-semibold">{data?.user?.displayName || ''}</span>
      </div>
    </div>
  );
};
