import { FC, useEffect, useState } from 'react';
import { useGettingStartedSteps } from '../hooks';
import { GettingStartedStep, GettingStartedStepItem } from './components';

const findFirstUncompletedStep = (steps: GettingStartedStepItem[]) =>
  steps.find((i) => !i.isCompleted && !i.isComingSoon)?.id;

export const GettingStarted: FC = () => {
  const steps = useGettingStartedSteps();
  const [selectedStep, setSelectedStep] = useState<string | undefined>(
    findFirstUncompletedStep(steps)
  );

  useEffect(() => {
    setSelectedStep(findFirstUncompletedStep(steps));

    //eslint-disable-next-line
  }, []);

  return (
    <div className="grow-1">
      <div className="font-display text-display-sm text-gray-900 font-medium">
        Getting Started
      </div>
      <div className="text-md text-gray-600 mt-4">
        Follow this step-by-step guide to learn how to create, publish and share
        your page.
      </div>
      <div className="flex flex-col gap-6 mt-8 max-w-176">
        {steps.map((step) => (
          <GettingStartedStep
            key={step.id}
            {...step}
            isSelected={step.id === selectedStep}
            onClick={(id) =>
              setSelectedStep((step) => (step === id ? undefined : id))
            }
          />
        ))}
      </div>
    </div>
  );
};
