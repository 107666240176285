import { SnippetCategoryWithSnippets } from '@distribute/shared/types';

export const getAvailableCategoriesBySnippetTypesIncluded = (
  categories: SnippetCategoryWithSnippets[],
  deletingCategory: SnippetCategoryWithSnippets
) => {
  return categories.filter((c) => {
    if (c.id === deletingCategory.id) {
      return false;
    }

    if (
      (deletingCategory.image && !c.image) ||
      (deletingCategory.video && !c.video) ||
      (deletingCategory.file && !c.file) ||
      (deletingCategory.text && !c.text)
    ) {
      return false;
    }

    return true;
  });
};
