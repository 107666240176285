import {
  VideoRecordStatus,
  VideoRecordStatusConvert,
  VideoRecordStatusMp4,
} from '@distribute/shared/types';

type RecordInProgressParams = {
  status: VideoRecordStatus;
  statusConvert: VideoRecordStatusConvert;
};

export const checkIsRecordInProgress = ({
  status,
  statusConvert,
}: RecordInProgressParams) => {
  if (
    [VideoRecordStatus.UPLOADING, VideoRecordStatus.FAILED].includes(status)
  ) {
    return true;
  }

  return [
    VideoRecordStatusConvert.IDLE,
    VideoRecordStatusConvert.CONVERTING,
    VideoRecordStatusConvert.FAILED,
  ].includes(statusConvert);
};

export const checkIsRecordConverting = (
  statusConvert: VideoRecordStatusConvert
) => {
  return [
    VideoRecordStatusConvert.IDLE,
    VideoRecordStatusConvert.CONVERTING,
  ].includes(statusConvert);
};

export const checkIsRecordConvertingFailed = ({
  status,
  statusConvert,
}: RecordInProgressParams) => {
  return (
    [VideoRecordStatusConvert.FAILED].includes(statusConvert) ||
    [VideoRecordStatus.FAILED].includes(status)
  );
};

type ConvertingFinishedParams = {
  statusMp4: VideoRecordStatusMp4;
  statusConvert: VideoRecordStatusConvert;
};

export const checkIsRecordPrepared = ({
  statusConvert,
  statusMp4,
}: ConvertingFinishedParams) => {
  if (
    [
      VideoRecordStatusConvert.IDLE,
      VideoRecordStatusConvert.CONVERTING,
    ].includes(statusConvert)
  ) {
    return false;
  }

  if ([VideoRecordStatusConvert.FAILED].includes(statusConvert)) {
    return true;
  }

  return [VideoRecordStatusMp4.FAILED, VideoRecordStatusMp4.COMPLETED].includes(
    statusMp4
  );
};
