import { useParams } from 'react-router-dom';
import { TemplatesPage } from './TemplatesPage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../processes/navigation';
import { NOT_FOUND_ROUTE } from '../../../entities/history';

export const TemplatesApplyPage = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const dispatch = useDispatch();
  const numId = Number(templateId);

  useEffect(() => {
    if (isNaN(numId)) {
      dispatch(navigate({ to: NOT_FOUND_ROUTE }));
    }
  }, [templateId, dispatch, numId]);

  return <TemplatesPage templateId={numId} />;
};
