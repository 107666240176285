import {
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
} from '../../../entities/history';

const REDIRECT_LOCAL_STORAGE_KEY = 'redirectUrl';

export const saveRedirectUrl = () => {
  const { pathname } = new URL(window.location.href);

  if (![RESET_PASSWORD_ROUTE, REGISTER_ROUTE, LOGIN_ROUTE].includes(pathname)) {
    localStorage.setItem(REDIRECT_LOCAL_STORAGE_KEY, pathname);
  }
};

export const getRedirectUrlAndClear = () => {
  const url = localStorage.getItem(REDIRECT_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REDIRECT_LOCAL_STORAGE_KEY);
  return url;
};
