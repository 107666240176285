import { call, put, select } from 'redux-saga/effects';

import { customDomainsApi } from '../../../shared/api';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { CustomDomainType, TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../teams';
import { customDomainsModel } from '../model';

export function* setCustomDomainInfo() {
  try {
    yield put(customDomainsModel.actions.setCustomDomainCreating(true));

    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const customDomainInfo: CustomDomainType | null = yield call(
      customDomainsApi.getCustomDomainInfo,
      currentTeam.id
    );

    yield put(customDomainsModel.actions.setCustomDomainInfo(customDomainInfo));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to load custom domain data`)
      )
    );
  } finally {
    yield put(customDomainsModel.actions.setCustomDomainCreating(false));
  }
}
