import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../../teams';
import { formatAmountToCurrency } from '../../../../../shared/lib';
import { Badge } from '../../../../../shared/ui';

export const UpcomingPaymentInfo: FC = () => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isAppSumoUser = currentTeam.isAppsumo;

  if (!stripeSubscription) return null;

  return (
    <div className="flex flex-col">
      <span className="text-sm text-gray-600">Upcoming payment</span>
      <div className="flex items-center gap-2">
        <span className="text-gray-900 text-display-md font-display font-medium -tracking-2">
          {formatAmountToCurrency(
            (stripeSubscription.upcomingInvoiceAmount ?? 0) / 100
          )}
        </span>
        {stripeSubscription.upcomingInvoiceAmountOff && !isAppSumoUser && (
          <Badge size="md" theme="success">
            {formatAmountToCurrency(
              stripeSubscription.upcomingInvoiceAmountOff / 100
            )}{' '}
            discount applied!
          </Badge>
        )}
        {stripeSubscription.upcomingInvoicePercentOff && !isAppSumoUser && (
          <Badge size="md" theme="success">
            {stripeSubscription.upcomingInvoicePercentOff} % discount applied!
          </Badge>
        )}
      </div>
    </div>
  );
};
