import { put } from 'redux-saga/effects';
import {
  CreatePageStepsEnum,
  PageCreationWorkflowsEnum,
  createPageModalModel,
} from '../../../../entities/create-page-modal';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';

export function* createPageFromGongTranscript(gongCallId: string) {
  yield put(
    createPageModalModel.actions.openPageCreationModal({
      currentStep: CreatePageStepsEnum.BRANDING,
      pageType: CreateWithAIPageType.CALL_FOLLOW_UP,
      creationWorkflow: PageCreationWorkflowsEnum.WITH_AI,
      gongCallTranscriptId: gongCallId,
    })
  );
  yield;
}
