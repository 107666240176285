import { IconMap } from '../../../shared/sprite';
import { Icon } from '../../../shared/ui';
import { FC } from 'react';
import { messages } from '../config';

export const ExtensionOptions: FC = () => (
  <div className="flex flex-col gap-4 text-sm text-gray-600 mb-8 self-center">
    {messages.items.map(({ id, text }) => (
      <div className="flex flex-nowrap items-center gap-2" key={id}>
        <Icon
          glyph={IconMap.CheckCircle}
          className="text-primary-600 flex-shrink-0"
          width={20}
        />
        <p className="truncate">{text}</p>
      </div>
    ))}
  </div>
);
