import React from 'react';
import cn from 'classnames';
import { Modal } from '../../../../shared/ui';
import {
  ChoosePageBrandingStep,
  ChoosePageTitleStep,
  CreatePageModalHeaderNavigation,
  InitialCreatePageModalStep,
} from '.';
import { useSelector } from 'react-redux';
import { createPageModalModel } from '../../../../entities/create-page-modal';
import { CreatePageStepsEnum } from '../../../../entities/create-page-modal/model/types';
import { GeneratePageStep } from './components/generate-page-step';

type Props = {
  onCreatePage(pageName?: string): void;
  onClose(): void;
};

export const CreatePageModal: React.FC<Props> = ({ onCreatePage, onClose }) => {
  const isOpen = useSelector(createPageModalModel.selectors.selectIsOpen);

  const currentStep = useSelector(
    createPageModalModel.selectors.selectCurrentStep
  );

  return (
    <Modal
      isOpen={isOpen}
      isShowCancelButton={false}
      clearPadding
      onClose={() => null}
      className={cn(
        'w-320 h-[min(800px,100vh-40px)] flex flex-col md:min-w-182 relative overflow-hidden'
      )}
    >
      <img
        src="../../../assets/images/create-page-modal-background.png"
        alt="background"
        className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none"
      />
      <CreatePageModalHeaderNavigation onClose={onClose} />
      {currentStep === CreatePageStepsEnum.INITIAL && (
        <InitialCreatePageModalStep />
      )}
      {currentStep === CreatePageStepsEnum.BRANDING && (
        <ChoosePageBrandingStep onCreatePage={onCreatePage} />
      )}
      {currentStep === CreatePageStepsEnum.TITLE && (
        <ChoosePageTitleStep onCreatePage={onCreatePage} />
      )}
      {currentStep === CreatePageStepsEnum.AI && (
        <GeneratePageStep onCreatePage={onCreatePage} />
      )}
    </Modal>
  );
};
