import { FC } from 'react';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import cn from 'classnames';

const styles = {
  base: 'flex items-center justify-center rounded-lg text-gray-400 hover:text-gray-500 hover:bg-gray-50 outline-gray-400',
  sizes: {
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-11 h-11',
  },
} as const;

type Props = {
  size?: keyof (typeof styles)['sizes'];
  classNames?: string;
  onClick: () => void;
};

export const ButtonCross: FC<Props> = ({
  size = 'lg',
  classNames = '',
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={cn(styles.base, styles.sizes[size], classNames)}
  >
    <Icon glyph={IconMap.XClose} width={24} />
  </button>
);
