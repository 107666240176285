import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Icon } from '../../shared/ui/Icon';
import { IconMap } from '../../shared/sprite';
import { workspaceModel } from '../../features/workspace';

export const WorkspaceAlertBar: FC = () => {
  const alertBar = useSelector(workspaceModel.selectors.selectAlertBar);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(workspaceModel.actions.setAlertBarData());
    alertBar?.onClose?.();
  };

  if (!alertBar) {
    return null;
  }

  return (
    <div
      className={cn('flex items-center justify-between gap-2 px-6 py-3', {
        'bg-primary-700': alertBar.theme === 'success',
        'bg-error-100': alertBar.theme === 'error',
        'bg-warning-100': alertBar.theme === 'warning',
      })}
    >
      <div
        className={cn('flex grow-1 text-sm font-medium', {
          'text-primary-25': alertBar.theme === 'success',
          'text-error-700': alertBar.theme === 'error',
          'text-warning-700': alertBar.theme === 'warning',
        })}
      >
        {alertBar.title}
      </div>
      <div
        className={cn('flex gap-6 shrink-0 text-sm font-semibold', {
          'text-base-white': alertBar.theme === 'success',
          'text-gray-600':
            alertBar.theme === 'error' || alertBar.theme === 'warning',
        })}
      >
        <button className="outline-none" onClick={alertBar.onClickBtn}>
          {alertBar.btnText}
        </button>
        <button onClick={handleClose}>
          <Icon glyph={IconMap.XClose} width={20} />
        </button>
      </div>
    </div>
  );
};
