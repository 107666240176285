import { Snippet } from '@distribute/shared/types';
import { FC } from 'react';

type Props = {
  snippet: Snippet;
};

export const FilePreview: FC<Props> = ({ snippet }) => {
  return (
    <div className="flex items-center justify-center w-full h-full bg-gray-200 border border-gray-300 rounded-2xl">
      <iframe
        src={snippet.content.url}
        title="snippet file"
        className="h-full max-w-full w-180"
      />
    </div>
  );
};
