import { takeEvery } from 'redux-saga/effects';
import { redirect, redirectActions } from '../../../entities/history';
import { onboardingModel } from '../../../features/onboarding';

export function* successfullyCreatedAccountWorker() {
  yield takeEvery(redirectActions.toWorkspace, redirect.toWorkspace);
  yield takeEvery(
    onboardingModel.actions.startAppsumoUserOnboarding,
    onboardingModel.sagas.startAppsumoUserOnboarding
  );
}
