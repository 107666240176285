import React from 'react';
import { useSelector } from 'react-redux';
import { Section } from '../../../shared/ui/Section';
import { Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { MembersTable } from './components';
import { teamsModel, useTeamPermissions } from '../../teams';
import { useHandlerInviteTeamMembers } from '../hooks';

export const TeamMembers = () => {
  const teamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const { isCanManageAccess } = useTeamPermissions();
  const handlerInviteTeamMembers = useHandlerInviteTeamMembers();

  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Members
      </h1>
      <Section
        title={`${teamMembers.length} member${
          teamMembers.length > 1 ? 's' : ''
        }`}
        actions={
          isCanManageAccess ? (
            <Button
              color="primary"
              variant="icon-text"
              size="md"
              iconLeftName={IconMap.Plus}
              iconLeftWidth={20}
              onClick={handlerInviteTeamMembers}
            >
              Invite Members
            </Button>
          ) : undefined
        }
      >
        <MembersTable />
      </Section>
    </div>
  );
};
