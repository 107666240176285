import React, { FC } from 'react';
import { IconMap } from '../sprite';
import { Icon } from './Icon';

export const ErrorBlock: FC<{ error: string }> = ({ error }) => {
  return (
    <div className="mb-6 flex flex-row justify-start items-center p-4 w-full bg-error-25 border border-error-300 rounded-xl">
      <div className="w-5 h-5 mr-3 flex items-center justify-center text-error-500">
        <Icon glyph={IconMap.AlertCircle} />
      </div>
      <p className="text-s font-semibold text-error-700">{error}</p>
    </div>
  );
};
