import React, { FC, ReactNode } from 'react';
import { Icon, SpriteGlyph } from '../../../shared/ui/Icon';
import { Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import cn from 'classnames';

type IProps = {
  icon: SpriteGlyph;
  title: ReactNode;
  description: ReactNode;
  buttonText: ReactNode;
  isLoading?: boolean;
  isComingSoon?: boolean;
  isDisconnect?: boolean;
  onClick?: () => void;
  hasSecondaryBtn?: boolean;
  secondaryButtonText?: ReactNode;
  onSecondaryBtnClick?: () => void;
};

export const IntegrationItem: FC<IProps> = ({
  icon,
  title,
  description,
  buttonText,
  isLoading,
  isComingSoon,
  isDisconnect,
  onClick,
  hasSecondaryBtn,
  secondaryButtonText,
  onSecondaryBtnClick,
}) => {
  return (
    <div className="flex flex-col justify-between bg-base-white border border-solid border-gray-200 rounded-xl grow-1">
      <div className="flex flex-col gap-6 grow-1 p-6">
        <div className="flex flex-row items-center gap-3">
          <Icon glyph={icon} />
          <h3 className="text-gray-900 font-medium">{title}</h3>
        </div>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      <div
        className={cn(
          'flex align-center border-t border-solid border-gray-200 px-6 py-4',
          {
            'justify-end': !hasSecondaryBtn,
            'justify-between': hasSecondaryBtn,
          }
        )}
      >
        {hasSecondaryBtn && (
          <Button
            color={'link'}
            variant={'text'}
            size="md"
            className={'!font-semibold !p-0 !h-auto !leading-5'}
            onClick={onSecondaryBtnClick}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          color={isDisconnect ? 'link-destructive' : 'link'}
          variant={isDisconnect ? 'icon-text' : 'text'}
          size="md"
          className={'!font-semibold !p-0 !h-auto !leading-5'}
          onClick={onClick}
          disabled={isComingSoon}
          loading={isLoading}
          iconLeftName={isDisconnect ? IconMap.LinkBroken : null}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
