import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import { createRoot } from 'react-dom/client';

export function findPlaceholder(state: EditorState, id: unknown) {
  const decorations = UploadingPlaceholderPlugin.getState(state);
  const found = decorations?.find(
    undefined,
    undefined,
    (spec) => spec.id === id
  );
  return found?.length ? found[0].from : null;
}

export const uploadingPlaceholderPluginKey = new PluginKey(
  'uploadingPlaceholderPlugin'
);

export const UploadingPlaceholderPlugin = new Plugin({
  key: uploadingPlaceholderPluginKey,
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set) {
      set = set.map(tr.mapping, tr.doc);
      const action = tr.getMeta(uploadingPlaceholderPluginKey);

      if (action && action.add) {
        const widget = document.createElement('placeholder');
        const root = createRoot(widget);
        root.render(
          <div className="bg-gray-50 w-full h-17 block rounded-xl border border-gray-200 mt-4 py-3 px-4">
            <div className="flex gap-3 h-6 items-center">
              <Icon
                glyph={
                  (action.add.fileType === 'image' && IconMap.Image01) ||
                  (action.add.fileType === 'pdf' && IconMap.File04) ||
                  (action.add.fileType === 'video' && IconMap.Film02)
                }
                width={20}
                className="text-gray-600"
              />

              <p className="font-medium text-gray-700 !text-base !m-0 truncate max-w-4/5">
                {action?.add.fileName}
              </p>
              <div className="ml-auto flex items-center">
                {[1, 2, 3].map((i) => (
                  <span
                    key={i}
                    className={`w-2 h-2 flex m-1 bg-primary-800 rounded loading-dot loading-dot--${i}`}
                  ></span>
                ))}
              </div>
            </div>
          </div>
        );

        const deco = Decoration.widget(
          action.add.pos,
          widget as unknown as HTMLElement,
          {
            id: action.add.id,
          }
        );
        set = set.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        set = set.remove(
          set.find(undefined, undefined, (spec) => spec.id === action.remove.id)
        );
      }
      return set;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
});
