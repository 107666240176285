import {
  GetPageAnalyticsResponseType,
  DeletePageAnalyticsResponseType,
} from '@distribute/shared/api-types/analytics';
import { api } from './instance';

export const analyticsApi = {
  async getPageAnalytics(pageId: string) {
    const { data } = await api.get<GetPageAnalyticsResponseType>(
      `analytics/${pageId}`
    );

    return data;
  },
  async resetPageAnalytics(pageId: string) {
    const { data } = await api.delete<DeletePageAnalyticsResponseType>(
      `analytics/${pageId}`
    );

    return data;
  },
};
