import React, { FC } from 'react';
import cn from 'classnames';
import { ViewerDetails } from '@distribute/shared/types';
import { Pagination } from './Pagination';
import { VIEWER_COLUMN } from '../../config';
import { hideColumn } from '../utils';
import { useSelector } from 'react-redux';
import { viewersModel } from '../../model';
import { TableRow } from './TableRow';
import { useGetHeightObjects } from '../../hooks/useGetHeightObjects';

type Props = {
  data: ViewerDetails[];
  page: number;
  totalPages: number;
  handleChangePage: (page: number) => void;
};

export const Table: FC<Props> = ({
  data,
  page,
  totalPages,
  handleChangePage,
}) => {
  const { getObjectRef, getObjectHeight } =
    useGetHeightObjects<HTMLDivElement>();

  const isLeadCategory = useSelector(
    viewersModel.selectors.selectIsLeadCategory
  );

  if (!data.length) {
    return (
      <div className="flex flex-col items-center justify-center gap-8 w-full h-full">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-m leading-8 font-semibold text-gray-900">
            No data
          </h2>
          <p className="text-gray-600">We couldn't find any data.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-12">
      <div className="flex flex-col border border-solid border-gray-200 rounded-xl overflow-hidden">
        <table className="table-fixed w-full text-left text-gray-600 border-separate border-spacing-0">
          <thead className="bg-gray-50">
            <tr className="text-xs">
              {VIEWER_COLUMN.map((column) => {
                if (column.name === 'email') {
                  return (
                    <th
                      key={column.name}
                      className={cn(
                        'px-6 py-3 text-left font-medium whitespace-nowrap',
                        'w-1/4'
                      )}
                    >
                      {isLeadCategory ? column.header : 'Viewer'}
                    </th>
                  );
                }

                return (
                  <th
                    key={column.name}
                    className={cn(
                      'px-6 py-3 text-left font-medium whitespace-nowrap sm:hidden',
                      {
                        'w-1/6': [
                          'source',
                          'views',
                          'overallActivity',
                        ].includes(column.name),
                        'w-1/4': ['createdAt', 'lastVisitAt'].includes(
                          column.name
                        ),
                        'lg:hidden': column.name === 'overallActivity',
                        hidden: hideColumn(column.name, isLeadCategory),
                      }
                    )}
                  >
                    {column.header}
                  </th>
                );
              })}
              <th className={cn('px-6 py-3 w-1/12')}></th>
            </tr>
          </thead>
          <tbody className="text-sm text-gray-600 font-normal">
            {data.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                //eslint-disable-next-line
                //@ts-ignore
                ref={getObjectRef(`${item.id}`)}
                height={getObjectHeight(`${item.id}`)}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          isHideIfOnlyOnePage
          className="px-6 py-3.5 border-t border-t-solid border-t-gray-200 "
          currentPage={page}
          lastPage={totalPages}
          maxLength={7}
          setCurrentPage={handleChangePage}
        />
      </div>
    </div>
  );
};
