import React, { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { gongModel } from '../../../../../../../entities/gong';
import { GongCallPartyType } from '@distribute/shared/api-types/gong';
import { Input } from '../../../../../../../shared/ui';
import { useOnClickOutside } from '../../../../../../../shared/hooks/useClickOutside';
import { contains } from '../../../../../../../utils/string';

type Props = {
  isError?: boolean;
  messageText?: string;
  handleSelect: (value: string) => void;
};

export const GongCallerNameSelect: FC<Props> = ({
  isError,
  messageText,
  handleSelect,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const uniqueGongParties = useSelector(
    gongModel.selectors.selectUniqueParties
  ) as GongCallPartyType[];

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const selectItem = (item: GongCallPartyType) => {
    handleSelect(item.name);
    setSearchValue(item.name);
    setOpen(false);
  };

  const filteredItems = useMemo(
    () =>
      uniqueGongParties.filter((item) =>
        contains(item.name ?? '', searchValue)
      ),
    [uniqueGongParties, searchValue]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (filteredItems[0]) {
        selectItem(filteredItems[0]);
      }
    }
  };

  useOnClickOutside(containerRef, () => {
    setOpen(false);
  });

  return (
    <div className="flex relative grow-1" ref={containerRef}>
      <Input
        ref={inputRef}
        type="text"
        className="w-full placeholder-gray-500"
        placeholder="Start typing the caller's name"
        value={searchValue}
        onChange={handleChange}
        onFocus={() => setOpen(true)}
        onKeyDown={handleKeyDown}
        isError={isError}
        messageText={messageText}
      />
      {isOpen && (
        <div className="flex flex-col border rounded-lg bg-base-white border-gray-200 py-1 px-1.5 z-10 absolute -bottom-0.5 left-0 w-full translate-y-full max-h-60 overflow-y-scroll overflow-x-hidden">
          {(uniqueGongParties.length === 0 || !filteredItems.length) && (
            <div className="px-2.5 py-2.25 text-sm text-gray-600 md:hidden">
              No results
            </div>
          )}
          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center gap-3 px-2.5 py-2.25 hover:bg-gray-50 cursor-pointer rounded-md"
              onClick={() => selectItem(item)}
            >
              <span className="text-sm text-gray-700 font-medium truncate">
                {item.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
