import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Input } from '../../../../shared/ui';
import React, { useRef, useCallback, useLayoutEffect, useEffect } from 'react';
import { LinkNodeType } from './CustomLink';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';
import { Editor } from '@tiptap/react';
import cn from 'classnames';
type Props = {
  node: LinkNodeType;
  hrefValue: string;
  setHrefValue(value: string): void;
  handleRemoveLink(pos?: number): void;
  handleLinkEditSubmit(): void;
  handleEditMenuClose(): void;
  editor: Editor;
  linkPos: number;
  isError: boolean;
};

export const CustomLinkEditMenu: React.FC<Props> = ({
  node,
  hrefValue,
  setHrefValue,
  handleLinkEditSubmit,
  handleRemoveLink,
  handleEditMenuClose,
  editor,
  linkPos,
  isError,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(menuRef, () => {
    handleEditMenuClose();

    if (!node.attrs.href) {
      handleRemoveLink(linkPos);
    }
  });

  const handleResize = useCallback(() => {
    if (!menuRef.current) return;

    const menuRect = menuRef.current.getBoundingClientRect();
    const editorRect = (
      editor.view.dom.firstChild as HTMLElement
    )?.getBoundingClientRect?.();

    if (!menuRect || !editorRect) return;

    if (editorRect.width < 400) {
      menuRef.current.style.width = '220px';
    }

    if (menuRect.left < editorRect.left) {
      menuRef.current.style.left = '0px';
      menuRef.current.style.transform = 'none';
    } else if (menuRect.right > editorRect.right) {
      menuRef.current.style.left = 'unset';
      menuRef.current.style.right = '0px';
      menuRef.current.style.transform = 'none';
    }
    menuRef.current.style.opacity = '1';
  }, [editor.view.dom.firstChild]);

  useLayoutEffect(() => {
    setTimeout(handleResize, 0);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useLayoutEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleEditMenuClose();
        if (!node.attrs.href) {
          handleRemoveLink();
        }
      }
      if (e.key === 'Enter') {
        handleLinkEditSubmit();
      }
    },
    [
      handleEditMenuClose,
      handleLinkEditSubmit,
      handleRemoveLink,
      node.attrs.href,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      ref={menuRef}
      className="h-11 w-115 pt-2 absolute -bottom-11 left-1/2 -translate-x-1/2 z-100 font-normal opacity-5"
    >
      <div
        className={cn(
          'w-full h-9 bg-base-white rounded-lg border  pl-3 flex items-center overflow-hidden',
          { 'border-error-600': isError, 'border-gray-200': !isError }
        )}
      >
        <Input
          type="text"
          className="bg-transparent h-5 text-sm border-0 !p-0 !rounded-none !text-gray-700"
          height={36}
          placeholder="Type or paste URL"
          value={hrefValue}
          onChange={(e) => setHrefValue(e.target.value)}
          ref={inputRef}
        />
        {hrefValue && (
          <Button
            variant="icon"
            color="transparent"
            size="sm"
            className="border-gray-200 !border-solid rounded-none border-y-0 border-r-0"
            onClick={() => handleRemoveLink()}
          >
            <Icon
              glyph={node.attrs.href ? IconMap.LinkBroken : IconMap.Trash}
              width={20}
              className="text-error-700"
            />
          </Button>
        )}
        <Button
          variant="icon"
          color="transparent"
          size="sm"
          disabled={!hrefValue.length}
          className="border-gray-200 !border-solid rounded-none border-y-0 border-r-0"
          onClick={handleLinkEditSubmit}
        >
          <Icon
            glyph={IconMap.CheckCircle}
            width={20}
            className={cn('text-gray-700', {
              'text-gray-200': !hrefValue.length,
            })}
          />
        </Button>
      </div>
    </div>
  );
};
