import { call, put, select } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  TeamFiltered,
  TeamOnboardingStatus,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { authApi, teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { DBUser } from '../../../../shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { onboardingModel } from '..';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';
import { navigate } from '../../../../processes/navigation';
import { GETTING_STARTED_ROUTE } from '../../../../entities/history';
import { CreateTeamRequestType } from '@distribute/shared/api-types/team';

export function* createTeam({
  payload,
}: ReturnType<typeof onboardingModel.actions.createTeam>) {
  const dbUser: DBUser = yield select(authUserModel.selectors.selectUser);
  const { brandCompanyName = null, brandLogoUrl = null } = payload;
  const brandCompanyPayload = brandCompanyName
    ? { brandCompanyName, brandLogoUrl }
    : {};

  try {
    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    yield put(onboardingModel.actions.setIsCreatingOrUpdatingTeam(true));

    const { domain, name } = payload;
    const createdTeam: TeamFiltered = yield call(teamsApi.createTeam, {
      domain,
      name,
      onboardingStatus: dbUser.isAppsumo
        ? TeamOnboardingStatus.FINISHED
        : TeamOnboardingStatus.INVITE_TEAMMATES,
      ...brandCompanyPayload,
    } as CreateTeamRequestType);

    yield put(teamsModel.actions.setTeams([...teams, createdTeam]));
    yield put(teamsModel.actions.setCurrentTeam(createdTeam));
    yield call(teamsModel.sagas.loadTeamRelatedData);

    analytics.track(AnalyticsEvents.ONBOARDING_TEAM_CREATED, {
      teamId: createdTeam.id,
      teamName: createdTeam.name,
      teamDomain: createdTeam.domain,
    });

    const user: DBUser = yield authApi.updateProfile({
      onboardingStatus: UserOnboardingStatus.INSTALL_EXTENSION,
    });

    yield put(authUserModel.actions.setDBUser(user));
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to create/update the team')
        )
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsCreatingOrUpdatingTeam(false));
    if (dbUser.isAppsumo) {
      yield put(navigate({ to: GETTING_STARTED_ROUTE }));
    }
  }
}
