import { cn } from '@distribute/frontend/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Popover from '@radix-ui/react-popover';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Input } from '../../../../shared/ui';
import { EMBED_CONTENT_PICKER_CONFIG, validationSchema } from './constants';
import { useEmbedContent } from './hooks/useEmbedContent';
import { EmbedContentAttrs, EmbedContentType, FormInputs } from './types';
import { useFakeLoading } from './hooks/use-fake-loading';

export type EmbedContentNodeType = NodeViewProps['node'] & {
  attrs: Partial<EmbedContentAttrs>;
};

export type EmbedContentViewProps = NodeViewProps & {
  node: EmbedContentNodeType;
  htmlContent?: string;
};

export const EmbedContentView = ({
  node,
  editor,
  updateAttributes,
}: EmbedContentViewProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const blockRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { check, isLoading: isCheckPending, data } = useEmbedContent();
  const {
    startLoading,
    percentage,
    isLoading,
    reset: resetLoading,
  } = useFakeLoading();

  const { opened, processingLink, error, link } = node.attrs;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!data) return;

    if (data.available) {
      editor.commands.renderResizeableEmbedContent(data);
      return;
    }

    updateAttributes({
      available: data?.available,
      error: data?.available ? '' : 'Invalid URL',
    });
  }, [data, updateAttributes, editor]);

  useEffect(() => {
    if (isCheckPending) {
      startLoading(200, 70);
    } else {
      resetLoading();
    }
  }, [isCheckPending, startLoading, resetLoading]);

  const handleToggleOpenState = useCallback(
    (value?: boolean) => {
      updateAttributes({
        opened: value ?? !opened,
      });
    },
    [opened, updateAttributes]
  );

  useOnClickOutside(
    blockRef,
    () => {
      handleToggleOpenState(false);
    },
    undefined,
    [contentRef]
  );

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [opened]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    updateAttributes({
      processingLink: data.link,
      available: null,
      error: '',
    });

    handleToggleOpenState(false);

    check(data.link);
  };

  const { ref: inputRegisterRef, ...inputOtherRegisterProps } = register(
    'link',
    {
      value: link ?? processingLink,
    }
  );

  const embedContentType = node.attrs.type || EmbedContentType.IFRAME;

  return (
    <NodeViewWrapper
      data-type="embedContent"
      className="!border-0 flex flex-row gap-3 pb-6 select-none"
    >
      <div contentEditable={false} ref={blockRef} className="w-full">
        <Popover.Root open={opened}>
          <Popover.Anchor asChild className="w-full">
            <div
              onClick={() => {
                handleToggleOpenState();
              }}
              className={cn(
                'flex flex-col relative bg-gray-75 text-md border-gray-200 overflow-x-hidden border justify-center rounded-lg embed-content-wrapper',
                {
                  'bg-error-50 border-error-200': error,
                }
              )}
            >
              {isLoading && (
                <div
                  className="absolute w-full h-full bg-gray-100 rounded-lg left-0"
                  style={{
                    transform: `translateX(-${100 - percentage}%)`,
                  }}
                />
              )}
              <div
                className={cn('flex z-10 flex-col px-4 justify-center', {
                  'h-12': !isLoading,
                  'h-18': isLoading,
                })}
              >
                <div
                  className={cn(
                    'flex flex-row gap-3 items-center text-gray-700',
                    {
                      'text-error-600': error,
                    }
                  )}
                >
                  <Icon
                    className="min-w-5 min-h-5"
                    width={
                      EMBED_CONTENT_PICKER_CONFIG[embedContentType].iconSize ??
                      20
                    }
                    glyph={EMBED_CONTENT_PICKER_CONFIG[embedContentType].icon}
                    style={{
                      marginLeft:
                        EMBED_CONTENT_PICKER_CONFIG[embedContentType].marginX ??
                        0,
                      marginRight:
                        EMBED_CONTENT_PICKER_CONFIG[embedContentType].marginX ??
                        0,
                    }}
                  />
                  <span className="font-medium flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden">
                    {processingLink
                      ? processingLink
                      : EMBED_CONTENT_PICKER_CONFIG[embedContentType].title}
                  </span>
                </div>
                {isLoading && (
                  <div className="pl-8 text-gray-600">
                    <span>uploading...</span>
                  </div>
                )}
                {error && (
                  <span className="text-error-700 font-medium pl-8 text-sm">
                    We've encountered an issue with the provided link. Please
                    try another one.
                  </span>
                )}
              </div>
            </div>
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content
              ref={contentRef}
              hideWhenDetached={true}
              side="bottom"
              align="start"
              style={{
                width: 'max(var(--radix-popper-anchor-width), 400px)',
              }}
              className="bg-base-white shadow-lg border-gray-200 rounded-lg w-full border flex flex-col"
              sideOffset={4}
            >
              <div className="border-b-gray-200 border-b flex flex-row items-start p-4 pb-3 h-13">
                <span className="w-full text-sm font-semibold text-gray-700 mt-1 ml-2">
                  {EMBED_CONTENT_PICKER_CONFIG[embedContentType].title}
                </span>
                <button onClick={() => handleToggleOpenState(false)}>
                  <Icon glyph={IconMap.XClose} className="text-gray-600" />
                </button>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col p-4 gap-4"
              >
                <div className="flex flex-col gap-1.5">
                  <Input
                    {...inputOtherRegisterProps}
                    ref={(e) => {
                      inputRegisterRef(e);
                      inputRef.current = e;
                    }}
                    value={link}
                    isError={!!errors.link}
                    type="text"
                    placeholder={
                      EMBED_CONTENT_PICKER_CONFIG[embedContentType].placeholder
                    }
                    heightSize="md"
                  />
                  <span className="text-sm text-gray-600">
                    {EMBED_CONTENT_PICKER_CONFIG[embedContentType].description}
                  </span>
                </div>
                <div className="flex flex-row justify-end">
                  <Button
                    type="submit"
                    variant="text"
                    size="sm"
                    color="primary"
                  >
                    Embed
                  </Button>
                </div>
              </form>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </NodeViewWrapper>
  );
};
