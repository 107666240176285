import { matchParams } from '../../../../shared/lib';
import {
  ACCEPT_REQUEST_TO_EDIT_PAGE_ROUTE,
  history,
  redirect,
} from '../../../../entities/history';
import { RouteParams } from '../types';
import { pagesApi } from '../../../../shared/api';
import { call, put } from 'redux-saga/effects';
import { AcceptRequestToEditPageResponseType } from '@distribute/shared/api-types/pages';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* handleAcceptRequestToEditPageSignedIn() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_REQUEST_TO_EDIT_PAGE_ROUTE
    );

    if (!params) return;

    const { pageSequenceNumber }: AcceptRequestToEditPageResponseType =
      yield call(pagesApi.acceptRequestToEditPage, params.requestId);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Request has been approved.')
      )
    );

    yield call(redirect.toEditorPage, {
      sequenceNumber: pageSequenceNumber,
    });
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to approve request.')
      )
    );
    yield call(redirect.toWorkspace);
  }
}
