import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { boolean, object, string } from 'yup';
import { teamsModel } from '../../teams';

export type CardFormCustomFieldsForm = {
  phone: string;
  isSendPromoMessages: boolean;
  isAgreed: boolean;
};
export type CardFormCustomFields = {
  form: UseFormReturn<CardFormCustomFieldsForm>;
  isShowAgreedCheckbox: boolean;
};

export const useCardFormCustomFields = (
  isShowAgreedCheckbox = false
): CardFormCustomFields => {
  const { phone } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const form = useForm<CardFormCustomFieldsForm>({
    resolver: yupResolver(
      useMemo(
        () =>
          object().shape({
            isAgreed: boolean().when([], {
              is: () => isShowAgreedCheckbox,
              then: boolean().oneOf(
                [true],
                'Please check the box above to confirm your subscription.'
              ),
              otherwise: boolean(),
            }),
            phone: string()
              .required('This field is required.')
              .matches(
                /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                'Enter a valid phone number.'
              ),
          }),
        [isShowAgreedCheckbox]
      )
    ),
    defaultValues: {
      phone: phone ?? '',
      isSendPromoMessages: true,
      isAgreed: false,
    },
  });

  return { form, isShowAgreedCheckbox };
};
