import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import { useEffect } from 'react';
import { lowlight } from '../constants';
import { Language } from '../types';

export const useLanguageSelector = (
  language: Language,
  updateAttributes: (attrs: { language: Language }) => void
) => {
  useEffect(() => {
    const lngModule = hljs.getLanguage(language);
    if (language && lngModule && !lowlight.registered(language)) {
      lowlight.register({
        [language]: () => lngModule,
      });
    }

    setTimeout(() => {
      updateAttributes({
        language,
      });
    });
  }, [language, updateAttributes]);
};
