import { DBUser } from '../../../../shared/types';
import { authApi } from '../../../../shared/api';
import { call, put } from 'redux-saga/effects';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { redirect } from '../../../../entities/history';

export function* createTeamForGuest() {
  const updatedUser: DBUser = yield call(authApi.updateProfile, {
    onboardingStatus: UserOnboardingStatus.USAGE_PLAN,
  });

  yield put(authUserModel.actions.setDBUser(updatedUser));

  yield call(redirect.toOnboarding);
}
