import { TemplateCategory } from '@distribute/shared/types';
import { IconMap } from '../../../shared/sprite';

export enum TemplatesConfigBasicEnum {
  ALL = 'all',
  RECENT = 'recent',
  TOP = 'top',
  PERSONAL = 'personal',
  TEAM = 'team',
}

export type TemplatesConfigEnum = TemplatesConfigBasicEnum | TemplateCategory;
export const TemplatesConfigEnum = {
  ...TemplatesConfigBasicEnum,
  ...TemplateCategory,
};

export const templatesConfig = [
  {
    name: 'All Templates',
    key: TemplatesConfigEnum.ALL,
    icon: IconMap.Templates,
  },
  {
    name: 'Recently Used',
    key: TemplatesConfigEnum.RECENT,
    icon: IconMap.TimeLoadingBack,
  },
  {
    name: 'Top Templates',
    key: TemplatesConfigEnum.TOP,
    icon: IconMap.Trophy,
  },
  {
    groupName: 'USE CASES',
    items: [
      {
        name: 'Internal updates',
        key: TemplatesConfigEnum.INTERNAL_UPDATES,
      },
      {
        name: 'Prospecting',
        key: TemplatesConfigEnum.PROSPECTING,
      },
      {
        name: 'In Discussion',
        key: TemplatesConfigEnum.IN_DISCUSSION,
      },
      {
        name: 'Closing',
        key: TemplatesConfigEnum.CLOSING,
      },
      {
        name: 'Implementation',
        key: TemplatesConfigEnum.IMPLEMENTATION,
      },
      {
        name: 'Customer Success',
        key: TemplatesConfigEnum.CUSTOMER_SUCCESS,
      },
      {
        name: 'Upsell & Cross-sell',
        key: TemplatesConfigEnum.UPSELL_AND_CROSS_SELL,
      },
      {
        name: 'Winback',
        key: TemplatesConfigEnum.WINBACK,
      },
    ],
  },
  {
    groupName: 'YOUR WORKSPACE',
    items: [
      {
        name: 'Personal',
        key: TemplatesConfigEnum.PERSONAL,
      },
      {
        name: 'Team',
        key: TemplatesConfigEnum.TEAM,
      },
    ],
  },
  {
    groupName: 'DISTROVERSE',
    items: [
      {
        name: 'Community',
        key: TemplatesConfigEnum.COMMUNITY,
      },
    ],
  },
];

const ignoreGroups = ['CUSTOM TEMPLATES'];
const ignoreItems = ['Recently Used'];

export const publicTemplatesConfig = [
  ...templatesConfig.filter((item) => {
    if (item.groupName) {
      return !ignoreGroups.includes(item.groupName);
    }

    return !ignoreItems.includes(item.name as unknown as string);
  }),
];

export enum TemplatesSwitcherEnum {
  POPULAR = 'popular',
  NEW = 'new',
}

export const switcherButtons = [
  {
    id: TemplatesSwitcherEnum.POPULAR,
    title: 'Most Popular',
  },
  {
    id: TemplatesSwitcherEnum.NEW,
    title: 'New',
  },
];

export const templateBgStyles = {
  error: {
    bgColor: 'bg-error-100',
    colorName: 'Light red',
    checkColor: 'text-error-700',
  },
  orange: {
    bgColor: 'bg-orange-100',
    colorName: 'Light orange',
    checkColor: 'text-orange-700',
  },
  warning: {
    bgColor: 'bg-warning-100',
    colorName: 'Light yellow',
    checkColor: 'text-warning-700',
  },
  success: {
    bgColor: 'bg-success-100',
    colorName: 'Light green',
    checkColor: 'text-success-700',
  },
  primary: {
    bgColor: 'bg-primary-100',
    colorName: 'Light teal',
    checkColor: 'text-primary-700',
  },
  blue: {
    bgColor: 'bg-blue-100',
    colorName: 'Light blue',
    checkColor: 'text-blue-700',
  },
  indigo: {
    bgColor: 'bg-indigo-100',
    colorName: 'Light indigo',
    checkColor: 'text-indigo-700',
  },
  purple: {
    bgColor: 'bg-purple-100',
    colorName: 'Light purple',
    checkColor: 'text-purple-700',
  },
  fuchsia: {
    bgColor: 'bg-fuchsia-100',
    colorName: 'Light fuchsia',
    checkColor: 'text-fuchsia-700',
  },
  pink: {
    bgColor: 'bg-pink-100',
    colorName: 'Light pink',
    checkColor: 'text-pink-700',
  },
} as const;
