import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* changePageCoverImage({
  payload: { coverImageUrl, pageId },
}: ReturnType<typeof pagesModel.actions.changeCoverImageUrl>) {
  try {
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPage
    );

    if (!coverImageUrl) {
      yield pagesApi.deletePageCoverImage(currentPage.id);
    }

    const page: Page = yield pagesApi.updatePage(pageId, { coverImageUrl });

    if (!page) {
      throw new Error('updatedPage is undefined');
    }
    yield put(pagesModel.actions.setCurrentPage(page));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change page cover')
      )
    );
  }
}
