import { put } from 'redux-saga/effects';

import { getUserFromFb } from '../../../../entities/auth-user';

import { providers } from '../../../../shared/config/firebase';
import { FirebaseUser } from '../../../../shared/types';
import {
  reauthenticateWithCredential,
  updatePassword as updatePasswordFb,
} from 'firebase/auth';

import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { getErrorMessage, logger } from '../../../../shared/lib';

export function* updatePassword(
  updatePasswordAction: ReturnType<typeof actions.changePasswordClick>
) {
  yield put(actions.setIsChangePasswordLoading(true));
  const {
    payload: { currentPassword, newPassword, cb },
  } = updatePasswordAction;

  try {
    const user: FirebaseUser = yield getUserFromFb();

    try {
      const credentials = providers.EmailAuthProvider.credential(
        user.email || '',
        currentPassword
      );
      yield reauthenticateWithCredential(user, credentials);
    } catch (e: unknown) {
      throw new Error('Invalid password');
    }

    yield updatePasswordFb(user, newPassword);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Password has been successfully changed')
      )
    );

    cb();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setChangePasswordError(
        getErrorMessage(e, 'Something went wrong. Please try again later')
      )
    );
  } finally {
    yield put(actions.setIsChangePasswordLoading(false));
  }
}
