import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../features/snippets';
import { teamsModel, useSnippetsPermissions } from '../../../features/teams';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { CategoryItem } from './CategoryItem';
import { NoSnippetsFound } from './NoSnippetsFound';
import { noFoundMessages } from '../../../features/snippets/ui/components/lib/config';
import {
  SnippetsTabEnum,
  getFilteredSnippets,
  getSortedSnippets,
} from '../lib';
import { FiltersRow } from './FiltersRow';
import { DeleteSnippetCategoryConfirmationModal } from '../../../features/snippets/ui/components/delete-snippet-category';
import { CreateOrEditSnippetCategoryModal } from '../../../features/snippets/ui/components';

export const CategoriesLayout: React.FC = () => {
  const snippetCategories = useSelector(
    snippetsModel.selectors.selectCategories
  );

  const {
    isCanManageSnippets,
    isCanManageCategories,
    isCanCreatePersonalSnippets,
  } = useSnippetsPermissions();

  const isCategoryEmpty = (category: SnippetCategoryWithSnippets) =>
    category.snippets.length === 0;

  const isCategoryVisible = (category: SnippetCategoryWithSnippets) =>
    !isCategoryEmpty(category) || isCanManageSnippets;

  const currentTypeFilter = useSelector(
    snippetsModel.selectors.selectCurrentTypeFilter
  );

  const currentSortCondition = useSelector(
    snippetsModel.selectors.selectCurrentSortCondition
  );

  const currentOwnerFilter = useSelector(
    snippetsModel.selectors.selectCurrentOwnerFilter
  );

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);

  const usersWithSnippets = useMemo(() => {
    return teamUsers
      .filter((u) => u.user?.id && u.user?.displayName)
      .filter((teamUser) =>
        snippetCategories.find((c) =>
          c.snippets.find((s) => s.owner.id === teamUser.user?.id)
        )
      );
  }, [snippetCategories, teamUsers]);

  const searchQuery = useSelector(snippetsModel.selectors.selectSearchQuery);

  const currentSnippetsToShow = useMemo(() => {
    const filteredSnippets = snippetCategories
      .map((category) => {
        return {
          ...category,
          snippets: getFilteredSnippets(
            category.snippets,
            searchQuery[SnippetsTabEnum.TEAM],
            currentTypeFilter[SnippetsTabEnum.TEAM],
            currentOwnerFilter
          ),
        };
      })
      .filter(
        (c) =>
          (!currentTypeFilter[SnippetsTabEnum.TEAM] &&
            !currentOwnerFilter &&
            !searchQuery[SnippetsTabEnum.TEAM]) ||
          c.snippets.length > 0
      );

    const sortedSnippets = filteredSnippets.map((c) => {
      return {
        ...c,
        snippets: getSortedSnippets(
          c.snippets,
          currentSortCondition[SnippetsTabEnum.TEAM]
        ),
      };
    });

    return sortedSnippets;
  }, [
    currentTypeFilter,
    snippetCategories,
    currentSortCondition,
    currentOwnerFilter,
    searchQuery,
  ]);

  const currentSnippetsToShowLength = useMemo(() => {
    return currentSnippetsToShow.reduce(
      (acc, currentCategory) => acc + currentCategory.snippets.length,
      0
    );
  }, [currentSnippetsToShow]);

  const [deletingCategory, setDeletingCategory] = useState<
    SnippetCategoryWithSnippets | undefined
  >(undefined);

  const [editingCategory, setEditingCategory] = useState<
    SnippetCategoryWithSnippets | undefined
  >(undefined);

  return snippetCategories.length > 0 ? (
    <>
      {deletingCategory && (
        <DeleteSnippetCategoryConfirmationModal
          isOpen={!!deletingCategory}
          onClose={() => {
            setDeletingCategory(undefined);
          }}
          category={deletingCategory}
        />
      )}
      {editingCategory && (
        <CreateOrEditSnippetCategoryModal
          isOpen={!!editingCategory}
          onClose={() => {
            setEditingCategory(undefined);
          }}
          category={editingCategory}
        />
      )}
      <FiltersRow
        tabType={SnippetsTabEnum.TEAM}
        usersWithSnippets={usersWithSnippets}
        visibleSnippetsAmount={currentSnippetsToShowLength}
      />

      {currentSnippetsToShow.length ? (
        <div className="flex flex-col gap-y-8">
          {currentSnippetsToShow.map(
            (category) =>
              isCategoryVisible(category) && (
                <CategoryItem
                  category={category}
                  key={category.id}
                  setDeletingCategory={setDeletingCategory}
                  setEditingCategory={setEditingCategory}
                />
              )
          )}
        </div>
      ) : (
        <NoSnippetsFound
          isCreateCategoryButton={false}
          message={
            isCanManageSnippets || isCanCreatePersonalSnippets
              ? noFoundMessages.filterSnippetsAndAllowCreation
              : noFoundMessages.filterSnippets
          }
        />
      )}
    </>
  ) : (
    <NoSnippetsFound
      isCreateCategoryButton={isCanManageCategories}
      message={
        isCanManageCategories
          ? noFoundMessages.setupCategories
          : noFoundMessages.setupTeamSnippets
      }
    />
  );
};
