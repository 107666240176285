import { FC } from 'react';

import { RecordHeader } from './RecordHeader';
import { RecordContent } from './RecordContent';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { recordingsModel } from '../../../model';

export const RecordDetailsPrivate: FC = () => {
  const { name } = useSelector(recordingsModel.selectors.selectRecord);

  return (
    <>
      <Helmet titleTemplate={`${name} - Distribute`} />
      <RecordHeader />
      <RecordContent />
    </>
  );
};
