import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserToTeamFiltered } from '@distribute/shared/types';
import { Table, Columns, Avatar } from '../../../../../shared/ui';
import { authUserModel } from '../../../../../entities/auth-user';
import { MemberProfileMenu } from './MemberProfileMenu';
import { teamsModel } from '../../../../../features/teams';

export const MembersTable = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const teamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const columns = useMemo<Columns<UserToTeamFiltered>[]>(
    () => [
      {
        id: 'name',
        name: 'Name',
        getValue: (item) => {
          const displayName =
            item.user?.displayName ?? item.externalUserEmail ?? '';

          return (
            <div className="flex items-center gap-x-3">
              <Avatar
                isUserAvatar
                displayName={displayName}
                src={item.user?.photoUrl}
                size="md"
                theme="secondary-circle"
                className="shrink-0"
              />
              <div className="flex flex-col w-[calc(100%-52px)]">
                <span className="text-gray-700 text-sm font-semibold truncate">
                  {displayName}
                </span>
                {item.isWaitingForInviteAcceptance && (
                  <span className="text-gray-700 text-sm font-normal">
                    Pending
                  </span>
                )}
              </div>
            </div>
          );
        },
        tdClassName: 'w-1/4',
      },
      {
        id: 'role',
        name: 'Role',
        getValue: (item) => <span className="capitalize">{item.role}</span>,
        tdClassName: 'w-1/4',
      },
      {
        id: 'email',
        name: 'Email address',
        getValue: (item) => item.user?.email ?? item.externalUserEmail,
        tdClassName: 'w-1/4',
      },
      {
        id: 'actions',
        name: '',
        getValue: (item) =>
          item.user?.id === currentUser.id ? null : (
            <MemberProfileMenu teamMember={item} />
          ),
        tdClassName: '!text-right w-1/4',
      },
    ],
    [currentUser]
  );

  const getItemId = useCallback((item: UserToTeamFiltered) => item.id, []);

  return (
    <div className="overflow-auto">
      <Table
        data={teamMembers}
        columns={columns}
        getItemId={getItemId}
        className="table-fixed sm:table-auto"
      />
    </div>
  );
};
