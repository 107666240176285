import React from 'react';
import { TemplateSettingsForm } from './TemplateSettingsForm';

export const EditorSidebarTemplateSettings: React.FC = () => {
  return (
    <div className="bg-gray-50 h-[calc(100vh-112px)] overflow-scroll">
      <div className="px-4 pt-6">
        <h2 className="text-2xl font-medium text-gray-900">
          Template settings
        </h2>
        <hr className="mt-5 mb-6 border-gray-200" />
      </div>

      <div className="px-4 pb-4">
        <TemplateSettingsForm />
      </div>
    </div>
  );
};
