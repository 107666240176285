import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { authModel } from '../../entities/auth-operation';
import { authUserModel } from '../../entities/auth-user';
import { connectivityModel } from '../../entities/connectivity-indicator';
import { customDomainsModel } from '../../features/custom-domains';
import { snackbarModel } from '../../features/snackbar';

import { saga } from './saga';
import { sentryReduxEnhancer } from '../../features/sentry-connect';
import { workspaceModel } from '../../features/workspace';
import { profileSettingModel } from '../../features/profile-settings';
import { foldersModel } from '../../features/folders';
import { pagesModel } from '../../features/pages';
import { navigationModel } from '../../processes/navigation';
import { mediaUploadModel } from '../../features/media-upload';
import { pageSettingsModel } from '../../features/page-settings';
import { conversionKitModel } from '../../features/conversion-kit';
import { subscribePopupModel } from '../../features/subscribe-popup';
import { subscriptionModel } from '../../features/subscription';
import { onboardingModel } from '../../features/onboarding';
import { viewersModel } from '../../features/viewers';
import { analyticsModel } from '../../features/analytics';
import { teamsModel } from '../../features/teams';
import { teamInfoSettingsModel } from '../../features/team-info-settings';
import { teamBrandSettingsModel } from '../../features/team-brand-settings';
import { teamBrandStylesModel } from '../../features/team-brand-styles';
import { teamMembersModel } from '../../features/team-members';
import { templatesModel } from '../../features/templates';
import { contactSupportFormModel } from '../../features/contact-support-form';
import { editorSidebarModel } from '../../features/editor-sidebar';
import { aiModel } from '../../features/ai';
import { publicTemplatesModel } from '../../features/public-template';
import { gatedContentModel } from '../../features/gated-content-block';
import { integrationsModel } from '../../features/integrations';
import { socialModel } from '../../entities/social-auth';
import { squeezePageModel } from '../../features/squeeze-page';
import { createPageModalModel } from '../../entities/create-page-modal';
import { snippetsModel } from '../../features/snippets';
import { gongModel } from '../../entities/gong';
import { recordingsModel } from '../../features/recordings';
import { extensionModel } from '../../features/extension';

const rootReducer = combineReducers({
  snackbar: snackbarModel.reducer,
  connectivity: connectivityModel.reducer,
  customDomains: customDomainsModel.reducer,
  user: authUserModel.reducer,
  auth: authModel.reducer,
  workspace: workspaceModel.reducer,
  folders: foldersModel.reducer,
  pages: pagesModel.reducer,
  profileSetting: profileSettingModel.reducer,
  navigation: navigationModel.reducer,
  fileUploading: mediaUploadModel.reducer,
  pageSettings: pageSettingsModel.reducer,
  conversionKit: conversionKitModel.reducer,
  subscribePopup: subscribePopupModel.reducer,
  subscription: subscriptionModel.reducer,
  onboarding: onboardingModel.reducer,
  viewers: viewersModel.reducer,
  analytics: analyticsModel.reducer,
  teams: teamsModel.reducer,
  templates: templatesModel.reducer,
  teamInfoSettings: teamInfoSettingsModel.reducer,
  teamBrandSettings: teamBrandSettingsModel.reducer,
  teamBrandStyles: teamBrandStylesModel.reducer,
  teamMembersModel: teamMembersModel.reducer,
  contactSupportForm: contactSupportFormModel.reducer,
  editorSidebar: editorSidebarModel.reducer,
  ai: aiModel.reducer,
  publicTemplates: publicTemplatesModel.reducer,
  gatedContent: gatedContentModel.reducer,
  integrations: integrationsModel.reducer,
  social: socialModel.reducer,
  squeezePage: squeezePageModel.reducer,
  createPageModal: createPageModalModel.reducer,
  snippets: snippetsModel.reducer,
  gong: gongModel.reducer,
  recordings: recordingsModel.reducer,
  extension: extensionModel.reducer,
});

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  sagaMiddleware,
];
const enhancers = [sentryReduxEnhancer];
const store = configureStore({ reducer: rootReducer, middleware, enhancers });
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(saga);
