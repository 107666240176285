import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import {
  CreatePageStepsEnum,
  GeneratePageForm,
  PageCreationWorkflowsEnum,
  ParseFileAction,
  ParseLinkAction,
  SearchCompanyBrandsAction,
} from './types';
import { ClearbitCompanyInfo } from '../../../shared/api';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';

type State = {
  isOpen: boolean;
  currentStep: CreatePageStepsEnum;
  previousStepsStack: Array<CreatePageStepsEnum>;
  creationWorkflow: PageCreationWorkflowsEnum | null;
  companiesSearchQuery: string;
  brandingCompanies: ClearbitCompanyInfo[];
  companiesSearchIsLoading: boolean;
  selectedBrandingCompany: ClearbitCompanyInfo | null;
  pageType: CreateWithAIPageType | null;
  gongCallTranscriptId: string | null;
};

const initialState: State = {
  isOpen: false,
  currentStep: CreatePageStepsEnum.INITIAL,
  previousStepsStack: [],
  creationWorkflow: null,
  companiesSearchQuery: '',
  brandingCompanies: [],
  companiesSearchIsLoading: false,
  selectedBrandingCompany: null,
  pageType: null,
  gongCallTranscriptId: null,
};

const getPageWorkflowSteps = (
  creationWorkflow: PageCreationWorkflowsEnum | null
) => {
  switch (creationWorkflow) {
    case PageCreationWorkflowsEnum.FROM_SCRATCH:
      return [
        CreatePageStepsEnum.INITIAL,
        CreatePageStepsEnum.BRANDING,
        CreatePageStepsEnum.TITLE,
      ];
    case PageCreationWorkflowsEnum.FROM_TEMPLATE:
      return [
        CreatePageStepsEnum.INITIAL,
        CreatePageStepsEnum.TEMPLATE,
        CreatePageStepsEnum.BRANDING,
        CreatePageStepsEnum.TITLE,
      ];
    case PageCreationWorkflowsEnum.WITH_AI:
      return [
        CreatePageStepsEnum.INITIAL,
        CreatePageStepsEnum.BRANDING,
        CreatePageStepsEnum.AI,
      ];
    default:
      return [];
  }
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'createPageModal',
  initialState,
  reducers: {
    openPageCreationModal: (
      state,
      { payload: newState }: PayloadAction<Partial<State | undefined>>
    ) => ({ ...state, isOpen: true, ...newState }),
    closePageCreationModal: (state) => ({
      ...state,
      isOpen: false,
      currentStep: CreatePageStepsEnum.INITIAL,
      previousStepsStack: [],
      brandingCompanies: [],
      selectedBrandingCompany: null,
      companiesSearchQuery: '',
      selectedDataSource: null,
      pageType: null,
      gongCallTranscriptId: null,
    }),

    setOpen: (state, { payload: isOpen }: PayloadAction<boolean>) => ({
      ...state,
      isOpen,
    }),
    setCurrentStep: (
      state,
      { payload: currentStep }: PayloadAction<CreatePageStepsEnum>
    ) => ({
      ...state,
      currentStep,
    }),
    setPreviousStepsStack: (
      state,
      { payload: previousStepsStack }: PayloadAction<CreatePageStepsEnum[]>
    ) => ({ ...state, previousStepsStack }),
    popPreviousStepsStack: (state) => ({
      ...state,
      previousStepsStack: state.previousStepsStack.slice(0, -1),
    }),
    setCreationWorkflow: (
      state,
      { payload: creationWorkflow }: PayloadAction<PageCreationWorkflowsEnum>
    ) => ({
      ...state,
      creationWorkflow,
    }),
    goNext: (
      state,
      {
        payload: creationWorkflow,
      }: PayloadAction<PageCreationWorkflowsEnum | undefined>
    ) => {
      const currentWorkflowSteps = getPageWorkflowSteps(
        creationWorkflow ?? state.creationWorkflow
      );
      const currentStepIndex = currentWorkflowSteps.findIndex(
        (step) => step === state.currentStep
      );

      return {
        ...state,
        creationWorkflow: creationWorkflow ?? state.creationWorkflow,
        currentStep: currentWorkflowSteps[currentStepIndex + 1],
        previousStepsStack: [...state.previousStepsStack, state.currentStep],
      };
    },
    goBack: (state) => {
      return {
        ...state,
        currentStep:
          state.previousStepsStack[state.previousStepsStack.length - 1],
        previousStepsStack: state.previousStepsStack.slice(0, -1),
      };
    },
    setCompaniesSearchQuery: (
      state,
      { payload: companiesSearchQuery }: PayloadAction<string>
    ) => ({ ...state, companiesSearchQuery }),
    setBrandingCompanies: (
      state,
      { payload: brandingCompanies }: PayloadAction<ClearbitCompanyInfo[]>
    ) => ({ ...state, brandingCompanies }),
    setCompaniesSearchIsLoading: (
      state,
      { payload: companiesSearchIsLoading }: PayloadAction<boolean>
    ) => ({ ...state, companiesSearchIsLoading }),
    setSelectedBrandingCompany: (
      state,
      {
        payload: selectedBrandingCompany,
      }: PayloadAction<ClearbitCompanyInfo | null>
    ) => ({ ...state, selectedBrandingCompany }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  searchCompanyBrands: createAction<SearchCompanyBrandsAction>(
    'createPageModal/searchCompanyBrands'
  ),
  parseFileTextContent: createAction<ParseFileAction>(
    'createPageModal/parseFileTextContent'
  ),
  parseLinkTextContent: createAction<ParseLinkAction>(
    'createPageModal/parseLinkTextContent'
  ),
  initializePageGenerationWithAI: createAction<
    GeneratePageForm & { generatePage: (title: string) => void }
  >('createPageModal/initializePageGenerationWithAI'),
};
