import { call, put, select } from 'redux-saga/effects';
import { gongApi } from '../../../../shared/api/axios';
import { teamsModel } from '../../../../features/teams';
import { RT } from '../../../../shared/types';
import { UpdateCurrentTeamMemberResponseType } from '@distribute/shared/api-types/team';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';

export function* generateGongApiKey() {
  try {
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const currentMember: UpdateCurrentTeamMemberResponseType = yield call(
      gongApi.generateApiKey,
      currentTeam.id
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, {
      ...currentTeam,
      currentMember,
    });
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to generate Gong webhook api key')
      )
    );
  }
}
