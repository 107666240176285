import { PlanName, SubscriptionInterval } from '@distribute/shared/types';
import { SpriteGlyph } from '../../../shared/ui/Icon';
import { IconMap } from '../../../shared/sprite';
import { DowngradeSubscriptionReason, SubscriptionPlan } from '../types';

export const FREE_SUBSCRIPTION_PLAN_ID = 'free';
export const MAX_MEMBERS_TEAM_PLAN = 5;
export const CANCEL_SUBSCRIPTION_OTHER_REASON_ID = '5';
export const POLLING_DELAY_INTERVAL = 2000;

export const CARD_ICON_BY_BRAND: Record<string, SpriteGlyph> = {
  visa: IconMap.VisaLogo,
  unionpay: IconMap.UnionPayLogo,
  mastercard: IconMap.MastercardLogo,
  jcb: IconMap.JcbLogo,
  dinners: IconMap.DinnersLogo,
  amex: IconMap.AmexLogo,
  unknown: IconMap.UnknownCardBrand,
};

export const subscriptionPlans: SubscriptionPlan[] = [
  {
    id: FREE_SUBSCRIPTION_PLAN_ID,
    interval: SubscriptionInterval.MONTH,
    name: 'Free',
    price: { month: 0 },
    features: [
      { id: 0, text: '5 Published Pages' },
      { id: 1, text: '20 Wizard Writes' },
    ],
  },
  {
    id: PlanName.PRO,
    interval: SubscriptionInterval.MONTH,
    name: 'Pro',
    price: {
      month: 49,
    },
    features: [
      { id: 0, text: 'Unlimited Published Pages' },
      { id: 1, text: 'Unlimited AI responses' },
      { id: 2, text: 'No Distribute Branding' },
    ],
    hint: 'This plain is best for marketers,  individuals and small teams.',
  },
  {
    id: PlanName.TEAM,
    interval: SubscriptionInterval.MONTH,
    name: 'Team',
    price: {
      month: 149,
    },
    features: [
      { id: 0, text: 'All Pro plan features' },
      { id: 1, text: 'Customer Success Manager' },
      { id: 2, text: 'Team Training' },
    ],
    hint: 'This plain is best for sales and customer-facing teams.',
  },
];

export const cancelSubscriptionReasons: DowngradeSubscriptionReason[] = [
  { id: '0', name: 'Not using it enough' },
  { id: '1', name: 'Switching to another tool' },
  { id: '2', name: 'Too expensive' },
  { id: '3', name: 'Too difficult to use' },
  { id: '4', name: 'Upgraded by mistake' },
  { id: CANCEL_SUBSCRIPTION_OTHER_REASON_ID, name: 'Other' },
];
