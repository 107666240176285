import React, { ReactNode } from 'react';
import { Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import cn from 'classnames';

type IProps = {
  isOpen: boolean;
  children: ReactNode;
  onClick?: () => void;
};

export const RichTextEditorDropdownHeader: React.FC<IProps> = ({
  isOpen,
  children,
  onClick,
}) => {
  return (
    <div
      className={cn(
        'flex items-center gap-1 p-1 rounded text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-800 cursor-pointer transition-all',
        { 'bg-gray-100': isOpen }
      )}
      onClick={onClick}
    >
      {children}
      <Icon
        glyph={isOpen ? IconMap.ChevronUp : IconMap.ChevronDown}
        width={16}
      />
    </div>
  );
};
