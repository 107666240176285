import { ReactNodeViewRenderer } from '@tiptap/react';
import { NodeView } from './Column.renderer';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { ColumnExt } from '@distribute/shared/generate-html';

export const Column = ColumnExt.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
            if (event.code === 'Backspace') {
              this.editor.commands.deleteOneColumn(event);
            }
          },
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeView);
  },
});
