import { call, put } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { messages } from '../../config';
import { recordingsModel } from '..';
import { redirect } from '../../../../entities/history';
import { pagesModel } from '../../../pages';

export function* insertVideoToPage(
  action: ReturnType<typeof actions.insertVideoToPage>
) {
  const { payload } = action;
  const { cb, page, ...data } = payload;

  try {
    yield put(recordingsModel.actions.setIsRecordInsertToPageLoading(true));
    yield call(pagesModel.sagas.changePageContentItem, {
      type: pagesModel.actions.updatePageContentItem.type,
      payload: { ...data, pageId: page.id },
    });

    yield call(redirect.toEditorPage, {
      sequenceNumber: page.sequenceNumber,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', messages.record.insertVideoToPageError)
      )
    );
  } finally {
    cb?.();
    yield put(recordingsModel.actions.setIsRecordInsertToPageLoading(false));
  }
}
