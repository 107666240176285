import { call, put, select } from 'redux-saga/effects';
import { SetupIntentResult, StripeElements } from '@stripe/stripe-js';
import { Stripe } from '@stripe/stripe-js/types/stripe-js';
import { SetupIntentResponseType } from '@distribute/shared/api-types/subscriptions';
import {
  StripeSubscription,
  SubscriptionPaymentMethod,
  TeamFiltered,
} from '@distribute/shared/types';

import { subscriptionApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import {
  SubmitReactHookFormResult,
  logger,
  submitReactHookForm,
} from '../../../../shared/lib';
import { subscriptionModel } from '..';
import { teamsModel } from '../../../teams';
import { CardFormSubmitError, StripeConfirmSetupError } from '../../lib';
import { confirmPaymentMethodSaga } from './confirmPaymentMethod';
import { CardFormCustomFields, CardFormCustomFieldsForm } from '../../hooks';

export function* updatePaymentMethodSaga(
  teamId: number,
  elements: StripeElements,
  stripe: Stripe,
  cardFormCustomFieldsForm: CardFormCustomFields['form']
) {
  const { error: submitError } = yield call(elements.submit);
  const {
    errors: submitCustomFieldsError,
    data: cardFormCustomFieldsData,
  }: SubmitReactHookFormResult<CardFormCustomFieldsForm> = yield call<
    typeof submitReactHookForm<CardFormCustomFieldsForm>
  >(submitReactHookForm, cardFormCustomFieldsForm);

  if (submitError || submitCustomFieldsError) {
    throw new CardFormSubmitError(submitError);
  }

  const { clientSecret }: SetupIntentResponseType = yield call(
    subscriptionApi.setupIntent,
    { teamId }
  );
  const { error, setupIntent }: SetupIntentResult = yield stripe.confirmSetup({
    elements,
    clientSecret,
    confirmParams: {
      return_url: window.location.href,
    },
    redirect: 'if_required',
  });

  if (error) {
    yield put(
      subscriptionModel.actions.setUpdatePaymentMethodError(error.message)
    );

    throw new StripeConfirmSetupError(error);
  } else {
    const subscriptionPaymentMethod: SubscriptionPaymentMethod = yield call(
      subscriptionApi.updatePaymentMethod,
      {
        teamId,
        stripePaymentMethodId: setupIntent.payment_method as string,
        phone: cardFormCustomFieldsData.phone,
        isSendPromoMessages: cardFormCustomFieldsData.isSendPromoMessages,
      }
    );
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, {
      ...currentTeam,
      subscriptionPaymentMethod,
      currentMember: {
        ...currentTeam.currentMember,
        isClosedAlertBarSubscriptionPaymentMethodExpireSoon: false,
      },
      phone: cardFormCustomFieldsData.phone,
      isSendPromoMessages: cardFormCustomFieldsData.isSendPromoMessages,
    });

    if (currentTeam.currentMember.isWaitingForUpdateSubscriptionPaymentMethod) {
      yield call(confirmPaymentMethodSaga, teamId);
    }
  }
}

export function* updatePaymentMethod({
  payload: {
    teamId,
    elements,
    stripe,
    cardFormCustomFieldsForm,
    isApplyOnboardingPromoCode,
    cb,
  },
}: ReturnType<typeof subscriptionModel.actions.updatePaymentMethod>) {
  try {
    yield put(subscriptionModel.actions.setIsUpdatingPaymentMethod(true));
    yield call(
      updatePaymentMethodSaga,
      teamId,
      elements,
      stripe,
      cardFormCustomFieldsForm
    );

    if (isApplyOnboardingPromoCode) {
      const currentTeam: TeamFiltered = yield select(
        teamsModel.selectors.selectCurrentTeamWithError
      );
      const stripeSubscription: StripeSubscription = yield call(
        subscriptionApi.updateSubscription,
        {
          teamId,
          stripePromoCodeId:
            currentTeam.subscriptionOnboardingPromoCode?.stripePromoCodeId,
        }
      );

      yield call(teamsModel.sagas.onUpdateCurrentTeam, {
        ...currentTeam,
        stripeSubscription,
        subscriptionOnboardingPromoCode: null,
      });
    }

    yield call(cb);
  } catch (error: unknown) {
    if (
      error instanceof CardFormSubmitError ||
      error instanceof StripeConfirmSetupError
    ) {
      return;
    }

    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update payment method.')
      )
    );
  } finally {
    yield put(subscriptionModel.actions.setIsUpdatingPaymentMethod(false));
  }
}
