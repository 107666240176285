import { fork, takeEvery } from 'redux-saga/effects';
import { profileSettingModel } from '../../../features/profile-settings';
import { integrationsModel } from '../../../features/integrations';

export function* settingsWorker() {
  yield fork(profileSettingModel.sagas.profileSettingErrorHandlerWorker);
  yield fork(profileSettingModel.sagas.watchDownloadFileChannel);
  yield fork(integrationsModel.sagas.integrationsWorker);

  yield takeEvery(
    profileSettingModel.actions.changeFullNameClick,
    profileSettingModel.sagas.changeFullName
  );

  yield takeEvery(
    profileSettingModel.actions.changePasswordClick,
    profileSettingModel.sagas.updatePassword
  );

  yield takeEvery(
    profileSettingModel.actions.changePhotoClick,
    profileSettingModel.sagas.changePhoto
  );

  yield takeEvery(
    profileSettingModel.actions.deletePhotoClick,
    profileSettingModel.sagas.deletePhoto
  );
  yield takeEvery(
    profileSettingModel.actions.changeEmailClick,
    profileSettingModel.sagas.updateUserEmail
  );
  yield takeEvery(
    profileSettingModel.actions.createUserPassword,
    profileSettingModel.sagas.createUserPassword
  );
  yield takeEvery(
    profileSettingModel.actions.disconnectGmailAuthentication,
    profileSettingModel.sagas.disconnectGmailAuthentication
  );
  yield takeEvery(
    profileSettingModel.actions.connectGmailAuthentication,
    profileSettingModel.sagas.connectGmailAuthentication
  );
}
