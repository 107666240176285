import React from 'react';
import cn from 'classnames';

type Props = {
  children: (JSX.Element | false)[] | JSX.Element;
  isEditorSidebarOpen: boolean;
};

export const EditorsPageWrapper: React.FC<Props> = ({
  children,
  isEditorSidebarOpen,
}) => {
  return (
    <div
      className={cn('transition-all relative z-0', {
        'w-[calc(100%-480px)] md:w-full': isEditorSidebarOpen,
        'w-full': !isEditorSidebarOpen,
      })}
    >
      {children}
    </div>
  );
};
