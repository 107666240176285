import { call } from 'redux-saga/effects';
import { redirect } from '../../../entities/history';
import { LoggedActions } from '../../../shared/types';
import { getRedirectUrlAndClear } from '../../routing/lib';

export function* beforeAuth(actions?: LoggedActions) {
  if (!actions) {
    return;
  }

  const redirectUrl = getRedirectUrlAndClear();
  if (redirectUrl) {
    yield call(redirect.toCustomRedirect, { url: redirectUrl });
    return;
  }

  switch (actions.redirectTo) {
    case 'successfully-created-account':
      yield call(redirect.toSuccessfullyCreatedAppsumoAccount);
      break;
    case 'login':
      yield call(redirect.toLogin, true);
      break;
    case 'workspace':
      yield call(redirect.toWorkspaceDefaultFolder);
      break;
    case 'onboarding':
      yield call(redirect.toOnboarding);
      break;
    case 'accept-team-invitation':
      yield call(redirect.toAcceptTeamInvitation, {
        inviteId: actions.data?.teamInviteId ?? '',
      });
      break;
    case 'accept-page-invitation':
      yield call(redirect.toAcceptPageInvitation, {
        pageInviteId: actions.data?.pageInviteId ?? '',
        teamInviteId: actions.data?.teamInviteId,
      });
      break;
    default:
      return;
  }
}
