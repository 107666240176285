import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { IconMap } from '../../sprite';
import { Link } from '../Link';
import { environment } from '../../../environments/environment';

type Props = {
  classNameContent?: string;
  children: ReactNode;
};

export const SignUpLayout: FC<Props> = ({ children, classNameContent }) => {
  return (
    <div className="bg-bg-100 relative min-h-screen overflow-hidden flex items-stretch">
      <div className="flex flex-col w-1/2 md:w-full min-h-screen items-center bg-repeat bg-base-white">
        <div className="flex self-stretch pt-8 px-8 relative z-10">
          <Link
            target="_blank"
            rel="noreferrer"
            href={environment.landingUrl}
            size="medium"
          >
            <Icon glyph={IconMap.DistributeLogo} />
          </Link>
        </div>
        <div
          className={classNames(
            'w-110 pt-16 pb-8 flex justify-center flex-col sm:px-4 sm:w-full',
            classNameContent
          )}
        >
          {children}
        </div>
      </div>
      <div className="w-1/2 bg-auth-noise min-h-screen relative md:hidden">
        <div className="absolute left-0 top-0 pointer-events-none">
          <img
            src="../../../assets/images/signup-left-bg.svg"
            alt="Auth left img"
            className="w-full md:hidden"
          />
        </div>
        <div className="absolute right-0 bottom-0 pointer-events-none">
          <img
            src="../../../assets/images/signup-right-bg.svg"
            alt="Auth left img"
            className="w-full md:hidden"
          />
        </div>
        <div className="absolute left-1/2 pointer-events-none top-1/2 -translate-x-1/2 -translate-y-1/2 w-110">
          <img
            src="../../../assets/images/signup-customer-rating.png"
            alt="Auth left img"
            className="w-full md:hidden"
          />
        </div>
      </div>
    </div>
  );
};
