import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../../features/teams';
import { call, put, select } from 'redux-saga/effects';
import { gongModel } from '..';
import { gongApi } from '../../../../shared/api';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { Buffer } from 'buffer';
import { TeamFiltered } from '@distribute/shared/types';

export function* createGongTeamSecrets({
  payload,
}: ReturnType<typeof gongModel.actions.createGongTeamSecrets>) {
  try {
    yield put(gongModel.actions.setIsCreatingTeamSecrets(true));
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const accessToken = Buffer.from(
      `${payload.accessKey}:${payload.accessKeySecret}`
    ).toString('base64');

    const updatedTeam: TeamFiltered = yield call(
      gongApi.createGongTeamSecrets,
      accessToken,
      currentTeam.id
    );

    yield put(teamsModel.actions.setCurrentTeam(updatedTeam));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully saved Gong secrets')
      )
    );
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to save Gong secrets')
      )
    );
  } finally {
    yield put(gongModel.actions.setIsCreatingTeamSecrets(false));
  }
}
