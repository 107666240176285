import { call, select, takeEvery } from 'redux-saga/effects';
import { gettingStartedModel } from '../../../features/getting-started/model';
import { teamsModel } from '../../../features/teams';
import { RT } from '../../../shared/types';
import { redirect } from '../../../entities/history';

export function* gettingStartedWorker() {
  yield takeEvery(
    gettingStartedModel.actions.closeStatusPanel,
    gettingStartedModel.sagas.closeStatusPanel
  );

  const isAvailable: RT<
    typeof teamsModel.selectors.selectIsGettingStartedAvailable
  > = yield select(teamsModel.selectors.selectIsGettingStartedAvailable);

  if (!isAvailable) {
    yield call(redirect.toWorkspace);
  }
}
