export enum CalendarProvider {
  CALENDLY = 'calendly',
  CHILI_PIPER = 'chili-piper',
}

export type ConversionKitCalendarData = {
  calendarProvider: CalendarProvider | null;
  calendarSchedulingLink: string | null;
  calendarIsShowCalendlyPageDetails: boolean;
  calendarIsShowCalendlyCookieBanner: boolean;
  calendarCalendlyTextColor: string;
  calendarCalendlyButtonColor: string;
  calendarCalendlyBgColor: string;
};

export const CALENDAR_PROVIDER_NAME = {
  [CalendarProvider.CALENDLY]: 'Calendly',
  [CalendarProvider.CHILI_PIPER]: 'Chili Piper',
} as const;
