import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FeaturedIcon, Modal } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { teamsModel } from '../../../teams';
import { subscriptionModel } from '../../model';
import { getPromoCodeDiscountAmount } from '../../lib';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const RenewPlanWithPromoCodeModal: FC<IProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { id: teamId, subscriptionRenewPromoCode } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isUpdatingSubscription = useSelector(
    subscriptionModel.selectors.selectIsUpdatingSubscription
  );

  const handleRenew = () => {
    if (!subscriptionRenewPromoCode) {
      return;
    }

    dispatch(
      subscriptionModel.actions.updateSubscription({
        teamId,
        isUpdatePaymentMethod: false,
        isCancelAtPeriodEnd: false,
        stripePromoCodeId: subscriptionRenewPromoCode.stripePromoCodeId,
        cb: onClose,
      })
    );
  };

  if (!subscriptionRenewPromoCode) {
    return null;
  }

  const discountAmount = getPromoCodeDiscountAmount(subscriptionRenewPromoCode);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelButtonName="I don't want discount"
      actionButton={
        <Button
          fullWidth
          loading={isUpdatingSubscription}
          onClick={handleRenew}
          color="primary"
          variant="text"
        >
          Get {discountAmount} OFF Today
        </Button>
      }
      className="!w-120 sm:!w-full"
      title={
        <div className="flex flex-col gap-4">
          <FeaturedIcon icon={IconMap.Sale03} />
          <div className="truncate">
            Renew for {discountAmount} OFF your next month.
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-5">
        <div className="text-sm text-gray-600">
          Your subscription is cancelled, but this doesn't have to be the end of
          your creative journey.
        </div>
        <div className="text-sm text-gray-600">
          Take advantage of this exclusive offer - Get {discountAmount} off your
          next month of Distribute. Offer expires in 24 hours.
        </div>
      </div>
    </Modal>
  );
};
