import { call, put } from 'redux-saga/effects';
import { TeamInvitationTeamMemberInfoResponseType } from '@distribute/shared/api-types/team';
import {
  history,
  ACCEPT_TEAM_INVITATION_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
} from '../../../../entities/history';
import { logger, matchParams } from '../../../../shared/lib';
import { teamsApi } from '../../../../shared/api';
import { RouteParams } from '../types';
import { navigate } from '../../../../processes/navigation';
import { QUERY_PARAM_TEAM_INVITE_ID } from '../../config';
import { authModel } from '../../../../entities/auth-operation';

export function* handleAcceptTeamInvitationSignedOut() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_TEAM_INVITATION_ROUTE
    );

    if (!params) return;

    const {
      isUserExistsInSystem,
      email,
    }: TeamInvitationTeamMemberInfoResponseType = yield call(
      teamsApi.teamInvitationTeamMemberInfo,
      params.inviteId
    );

    yield put(authModel.actions.setEmailFromTeamOrPageInvitationLink(email));

    if (isUserExistsInSystem) {
      yield put(
        navigate({
          to: `${LOGIN_ROUTE}?${QUERY_PARAM_TEAM_INVITE_ID}=${params.inviteId}`,
        })
      );
    } else {
      yield put(
        navigate({
          to: `${REGISTER_ROUTE}?${QUERY_PARAM_TEAM_INVITE_ID}=${params.inviteId}`,
        })
      );
    }
  } catch (error) {
    logger.error(error);
  }
}
