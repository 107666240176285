import { put } from 'redux-saga/effects';
import { onboardingModel } from '..';
import { DBUser } from '../../../../shared/types';
import { authApi } from '../../../../shared/api';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { redirectActions } from '../../../../entities/history';

export function* startAppsumoUserOnboarding() {
  yield put(onboardingModel.actions.setIsLoadingStartAppsumoOnboarding(true));

  const user: DBUser = yield authApi.updateProfile({
    onboardingStatus: UserOnboardingStatus.USAGE_PLAN,
  });

  yield put(authUserModel.actions.setDBUser(user));

  yield put(onboardingModel.actions.setIsLoadingStartAppsumoOnboarding(false));
  yield put(redirectActions.toOnboardingClick());
}
