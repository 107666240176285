import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Link } from '../../../../processes/navigation';
import { GETTING_STARTED_ROUTE } from '../../../../entities/history';
import { useGettingStartedSteps, useProgress } from '../../hooks';
import { gettingStartedModel } from '../../model';
import { teamsModel } from '../../../teams';
import { messages } from '../../config';

export const GettingStartedStatus: FC = () => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const steps = useGettingStartedSteps();
  const { progress, isFinished } = useProgress({ steps });

  return (
    <>
      {isFinished && (
        <div className="absolute top-4 right-8 z-10">
          <Button
            variant="text"
            color="tertiary-black"
            size="sm"
            onClick={() =>
              dispatch(
                gettingStartedModel.actions.closeStatusPanel({
                  teamId: currentTeam.id,
                })
              )
            }
          >
            <Icon glyph={IconMap.XClose} width={20} className="text-gray-600" />
          </Button>
        </div>
      )}
      <Link
        to={GETTING_STARTED_ROUTE}
        className="relative flex flex-col p-6 border-2 border-primary-600 rounded-2xl shadow-sidebar-banner !no-underline bg-base-white"
      >
        <Icon
          glyph={isFinished ? IconMap.Party : IconMap.Rocket02}
          width={24}
          className="text-primary-700"
        />
        <h5 className="text-md font-semibold text-gray-900 mt-2">
          {isFinished ? messages.finished : messages.inProgress}
        </h5>
        <p className="text-sm text-gray-600 mt-1">
          {isFinished ? messages.finishedText : messages.inProgressText}
        </p>
        <div className="flex gap-3 items-center mt-4">
          <div className="relative grow-1 bg-gray-200 h-2 rounded">
            <div
              className="absolute top-0 left-0 h-full bg-primary-600 rounded"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="text-sm font-medium">{progress}%</div>
        </div>
      </Link>
    </>
  );
};
