import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Snippet } from '@distribute/shared/types';
import { navigate } from '../../../../processes/navigation';
import {
  SNIPPETS_DRAFTS_ROUTE,
  SNIPPETS_ROUTE,
} from '../../../../entities/history';
import { useDispatch, useSelector } from 'react-redux';
import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { snippetsModel } from '../../../../features/snippets';
import { SnippetStatusTag } from './SnippetStatusTag';
import { SnippetSettingsModal } from '../../../../features/snippets/ui/components/SnippetSettingsModal';
import { LeavePageConfirmationModal } from './LeavePageConfirmationModal';
import { SnippetSettingsDropdown } from './SnippetSettingsDropdown';

type EditorHeaderProps = {
  currentSnippet: Snippet;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isSidebarOpen: boolean;
};

export const SnippetEditorHeader: React.FC<EditorHeaderProps> = ({
  currentSnippet,
  setIsSidebarOpen,
  isSidebarOpen,
}) => {
  const dispatch = useDispatch();

  const { editor } = useTiptapEditor();

  const undo = () => {
    editor?.chain().focus().undo().run();
  };

  const redo = () => {
    editor?.chain().focus().redo().run();
  };

  const isSaved = useSelector(
    snippetsModel.selectors.selectIsSnippetSavedInEditor
  );

  const isDraft = currentSnippet?.isDraft;

  const isSnippetUpdating = useSelector(
    snippetsModel.selectors.selectIsUpdateSnippetLoading
  );

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const handleUpdateSnippet = () => {
    dispatch(
      snippetsModel.actions.updateSnippet({
        snippet: currentSnippet,
        snippetId: currentSnippet.id,
        ...currentSnippet,
        categoryId: currentSnippet.category?.id,
        callback: () => {
          return;
        },
      })
    );
  };

  const [isLeavePageModalOpen, setIsLeavePageModalOpen] = useState(false);

  const onBackClick = () => {
    if (!isDraft && !isSaved) {
      setIsLeavePageModalOpen(true);
      return;
    }

    dispatch(
      navigate({
        to: currentSnippet.isDraft
          ? SNIPPETS_DRAFTS_ROUTE
          : `${SNIPPETS_ROUTE}/${currentSnippet.sharingAccess}`,
      })
    );

    dispatch(snippetsModel.actions.setCurrentSnippet(undefined));
  };

  const toggleEditorSidebarOpen = () => {
    setIsSidebarOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div className="sticky top-0 z-50" id="top-bar">
      {!isDraft && (
        <LeavePageConfirmationModal
          isOpen={isLeavePageModalOpen}
          onClose={() => {
            setIsLeavePageModalOpen(false);
          }}
        />
      )}
      {isDraft && (
        <SnippetSettingsModal
          isEditor
          isOpen={isSaveModalOpen}
          snippet={currentSnippet}
          onClose={() => {
            setIsSaveModalOpen(false);
          }}
        />
      )}
      <header className="flex justify-between items-center px-4 pl-3 py-3 sm:py-2 bg-base-white border-b border-gray-200 transition-all h-16">
        <div className="flex justify-between items-center max-w-1/2">
          <div className="pr-2 border-r border-gray-200">
            <Button
              className="md:pl-2"
              size="sm"
              color="tertiary-black"
              variant="icon-text"
              onClick={onBackClick}
            >
              <span className="flex items-center gap-2">
                <Icon glyph={IconMap.ChevronLeft} width={20} />
                <span className="text-s max-w-50 truncate md:hidden">
                  Snippets
                </span>
              </span>
            </Button>
          </div>
          <div className="flex items-center gap-2 pl-3 flex-1 min-w-0">
            <SnippetStatusTag />
          </div>
        </div>
        {
          <div className="flex items-center gap-4 shrink-0">
            <div className="flex items-center">
              <Tooltip
                trigger={
                  <Button
                    variant="icon"
                    color="tertiary-black"
                    disabled={!editor?.can().undo()}
                    onClick={undo}
                    size="sm"
                  >
                    <Icon glyph={IconMap.ReverseLeft} />
                  </Button>
                }
                sideOffset={4}
              >
                <p className="px-2 py-1 font-semibold text-xs">Undo</p>
              </Tooltip>

              <Tooltip
                trigger={
                  <Button
                    variant="icon"
                    color="tertiary-black"
                    disabled={!editor?.can().redo()}
                    onClick={redo}
                    size="sm"
                  >
                    <Icon glyph={IconMap.ReverseRight} />
                  </Button>
                }
                sideOffset={4}
              >
                <p className="px-2 py-1 font-semibold text-xs">Redo</p>
              </Tooltip>
            </div>

            <div className="w-0.25 h-5.5 bg-gray-200" />

            <SnippetSettingsDropdown setIsSidebarOpen={setIsSidebarOpen} />

            {isSaved && !isDraft ? (
              <div className="py-2 px-3 bg-primary-50 flex items-center gap-1.5 text-primary-700 text-sm font-semibold rounded-lg">
                <Icon glyph={IconMap.CheckCircle} width={20} />
                Snippet saved
              </div>
            ) : (
              <Button
                variant="text"
                color="primary"
                size="sm"
                className="font-semibold shadow-xs"
                onClick={
                  isDraft
                    ? () => {
                        setIsSaveModalOpen(true);
                        setIsSidebarOpen(false);
                      }
                    : handleUpdateSnippet
                }
                loading={isSnippetUpdating && !isDraft}
              >
                <Icon
                  glyph={IconMap.ArrowCircleDown}
                  width={20}
                  className="mr-1.5"
                />
                {isDraft ? 'Save Snippet' : 'Save changes'}
              </Button>
            )}

            <Tooltip
              trigger={
                <Button
                  variant="icon"
                  color="secondary"
                  size="sm"
                  className="bg-gray-50"
                  onClick={toggleEditorSidebarOpen}
                >
                  <Icon
                    glyph={IconMap.Sidebar}
                    width={20}
                    className="text-gray-700"
                  />
                </Button>
              }
              sideOffset={8}
              align="end"
              alignOffset={-8}
              hideArrow
            >
              <p className="px-2 py-1 font-semibold text-xs">
                {isSidebarOpen ? 'Hide sidebar' : 'Open sidebar'}
              </p>
            </Tooltip>
          </div>
        }
      </header>
    </div>
  );
};
