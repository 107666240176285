import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../features/snippets';
import { NoSnippetsFound } from './NoSnippetsFound';
import { SnippetsList } from './SnippetsList';
import { noFoundMessages } from '../../../features/snippets/ui/components/lib/config';
import {
  SnippetsTabEnum,
  getFilteredSnippets,
  getSortedSnippets,
} from '../lib';
import { FiltersRow } from './FiltersRow';

export const DraftSnippetsLayout: React.FC = () => {
  const draftSnippets = useSelector(
    snippetsModel.selectors.selectDraftSnippets
  );

  const currentSortCondition = useSelector(
    snippetsModel.selectors.selectCurrentSortCondition
  );

  const searchQuery = useSelector(snippetsModel.selectors.selectSearchQuery);

  const currentSnippetsToShow = useMemo(() => {
    const filteredSnippets = getFilteredSnippets(
      draftSnippets,
      searchQuery[SnippetsTabEnum.DRAFTS]
    );

    return getSortedSnippets(
      filteredSnippets,
      currentSortCondition[SnippetsTabEnum.DRAFTS]
    );
  }, [currentSortCondition, searchQuery, draftSnippets]);

  return draftSnippets.length > 0 ? (
    <>
      <FiltersRow
        tabType={SnippetsTabEnum.DRAFTS}
        visibleSnippetsAmount={currentSnippetsToShow.length}
      />
      {currentSnippetsToShow.length > 0 ? (
        <SnippetsList snippets={currentSnippetsToShow} />
      ) : (
        <NoSnippetsFound
          isDraft
          message={noFoundMessages.filterSnippetsAndAllowCreation}
        />
      )}
    </>
  ) : (
    <NoSnippetsFound isDraft message={noFoundMessages.setupDraftSnippets} />
  );
};
