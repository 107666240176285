import classNames from 'classnames';
import { Fragment, useRef, useState } from 'react';
import { publicTemplatesConfig } from '../../../../features/templates';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import { useIframeCommunication } from '../hooks/useIframeCommunication';

export const TemplatesIframeMenuContentPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [inputFocused, setInputFocused] = useState(false);

  const { search, value, select, category, device, isInIframe } =
    useIframeCommunication({
      ref,
    });

  const isMobile = device === 'mobile';

  return (isInIframe && device) || !isInIframe ? (
    <div
      ref={ref}
      className={classNames('flex flex-col gap-6', {
        'bg-base-white sm:py-6': isMobile,
      })}
    >
      <div
        className={classNames({
          'px-4': isMobile,
        })}
      >
        <label
          className={classNames(
            'flex flex-row gap-2 w-full items-center border rounded-lg bg-base-white border-gray-100 px-3.5 py-2.5',
            {
              'border-primary-600': inputFocused,
            }
          )}
        >
          <Icon
            className="cursor-pointer text-gray-500"
            glyph={IconMap.SearchSm}
          />
          <input
            className="focus:border-none flex-1 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus:ring-offset-transparent"
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            id="template-search"
            value={value}
            onChange={(e) => search(e.target.value)}
          />
          <Icon
            className="cursor-pointer text-gray-500"
            glyph={IconMap.XCircle}
            width={12}
            height={12}
            onClick={() => search('')}
          />
        </label>
      </div>
      <div className="flex flex-1 flex-col overflow-x-auto scrollbar-hide">
        <div
          className={classNames('flex flex-col', {
            '!flex-row !flex-nowrap min-w-fit-content px-4': isMobile,
          })}
        >
          {publicTemplatesConfig.map((item) => {
            if (item.name) {
              return (
                <div
                  key={item.key}
                  className={classNames(
                    'px-4 py-1.5 pb-1 cursor-pointer text-gray-700 rounded-lg [&:hover:not(.selected)]:bg-primary-500 hover:text-base-white',
                    {
                      'bg-primary-600 !text-base-white selected':
                        category === item.key,
                      'min-w-fit-content': isMobile,
                    }
                  )}
                  onClick={() => select(item.key)}
                >
                  <span className="text-sm font-semibold">{item.name}</span>
                </div>
              );
            } else if (item.groupName) {
              return (
                <Fragment key={item.groupName}>
                  {!isMobile && (
                    <div className="pt-6 pb-1">
                      <span className="text-gray-400 text-xs px-4 py-1.5 font-bold pb-1">
                        {item.groupName}
                      </span>
                    </div>
                  )}
                  {item.items.map((groupItem) => (
                    <div
                      className={classNames(
                        'px-4 py-1.5 pb-1 cursor-pointer rounded-lg text-gray-700 [&:hover:not(.selected)]:bg-primary-500 hover:text-base-white',
                        {
                          'bg-primary-600 !text-base-white selected':
                            category === groupItem.key,
                          'min-w-fit-content': isMobile,
                        }
                      )}
                      onClick={() => select(groupItem.key)}
                      key={groupItem.key}
                    >
                      <span className="text-sm font-semibold">
                        {groupItem.name}
                      </span>
                    </div>
                  ))}
                </Fragment>
              );
            } else return null;
          })}
        </div>
      </div>
    </div>
  ) : null;
};
