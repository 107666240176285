import {
  UserPermissionToVideoRecord,
  UserPermissionToVideoRecordRole,
} from '@distribute/shared/types';

type UpdateParams = {
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
  role: UserPermissionToVideoRecordRole;
  id: number;
};

type CreateParams = {
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
  newUserPermissionToVideoRecords: UserPermissionToVideoRecord[];
};

type DeleteParams = {
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
  id: number;
};

export const mapUpdateUserPermissionsToVideoRecords = ({
  userPermissionToVideoRecords,
  role,
  id,
}: UpdateParams) => ({
  userPermissionToVideoRecords: userPermissionToVideoRecords.map((uV) =>
    uV.id === id ? { ...uV, role } : { ...uV }
  ),
});

export const mapCreateUserPermissionsToVideoRecords = ({
  userPermissionToVideoRecords,
  newUserPermissionToVideoRecords,
}: CreateParams) => ({
  userPermissionToVideoRecords: [
    ...userPermissionToVideoRecords,
    ...newUserPermissionToVideoRecords,
  ],
});

export const mapDeleteUserPermissionsToVideoRecords = ({
  userPermissionToVideoRecords,
  id,
}: DeleteParams) => ({
  userPermissionToVideoRecords: userPermissionToVideoRecords.filter(
    (uV) => uV.id !== id
  ),
});
