import { VideoRecordTeamMembersPermission } from '@distribute/shared/types';
import { teamsModel } from '../../../../../features/teams';
import { Avatar, Dropdown } from '../../../../../shared/ui';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordingsModel } from '../../../model';

export const TeamMembersPermissionRow: FC = () => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const record = useSelector(recordingsModel.selectors.selectRecordToShare);

  const options = useMemo(
    () => [
      {
        id: VideoRecordTeamMembersPermission.INVITE_ONLY,
        label: 'Invite Only',
        onClick: () =>
          dispatch(
            recordingsModel.actions.updateTeamMemberPermission({
              teamMembersPermission:
                VideoRecordTeamMembersPermission.INVITE_ONLY,
            })
          ),
      },
      {
        id: VideoRecordTeamMembersPermission.EDIT,
        label: 'Can Edit',
        onClick: () =>
          dispatch(
            recordingsModel.actions.updateTeamMemberPermission({
              teamMembersPermission: VideoRecordTeamMembersPermission.EDIT,
            })
          ),
      },
      {
        id: VideoRecordTeamMembersPermission.VIEW,
        label: 'Can View',
        onClick: () =>
          dispatch(
            recordingsModel.actions.updateTeamMemberPermission({
              teamMembersPermission: VideoRecordTeamMembersPermission.VIEW,
            })
          ),
      },
    ],
    []
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        <Avatar
          displayName={currentTeam.name}
          src={currentTeam.brandLogoUrl ?? undefined}
          size="md"
          theme="secondary-rect"
          className="shrink-0"
        />
        <span className="text-gray-700 text-sm font-medium truncate">
          Members of {currentTeam.name}
        </span>
      </div>
      <Dropdown
        isModalMode={false}
        listStyles="shadow-lg"
        items={options}
        currentItemId={record.teamMembersPermission}
        itemStyles="!w-32 !min-w-32"
        triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
      />
    </div>
  );
};
