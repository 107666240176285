import { logger } from '../../../../shared/lib';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { put } from 'redux-saga/effects';
import { ClearbitCompanyInfo, clearbitApi } from '../../../../shared/api/';
import { createPageModalModel } from '..';

export function* searchCompanyBrands({
  payload: { searchQuery },
}: ReturnType<typeof createPageModalModel.actions.searchCompanyBrands>) {
  if (!searchQuery.length) {
    yield put(createPageModalModel.actions.setBrandingCompanies([]));
    return;
  }

  try {
    yield put(createPageModalModel.actions.setCompaniesSearchIsLoading(true));
    const data: ClearbitCompanyInfo[] = yield clearbitApi.searchCompanies(
      searchQuery
    );

    if (
      !data.length ||
      data[0].name.toLowerCase() !== searchQuery.toLowerCase()
    ) {
      yield put(
        createPageModalModel.actions.setBrandingCompanies([
          getUnknownCompanyRecord(searchQuery),
          ...data,
        ])
      );
    } else {
      yield put(createPageModalModel.actions.setBrandingCompanies(data));
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to find companies')
      )
    );
  } finally {
    yield put(createPageModalModel.actions.setCompaniesSearchIsLoading(false));
  }
}

function getUnknownCompanyRecord(searchQuery: string) {
  return {
    name: searchQuery,
    logo: null,
    domain: 'unknown website',
  };
}
