import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, LoaderDots } from '../../../../shared/ui';
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { templatesModel } from '../../model';
import { DeleteTemplateConfirmationModal } from './DeleteTemplateConfirmationModal';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { TemplateSettingsModal } from './TemplateSettingsModal';

type Props = {
  className?: string;
  handleEdit(): void;
  template: TemplateExtended;
};

export const TemplateItemSettingsDropdown: React.FC<Props> = ({
  className,
  handleEdit,
  template,
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const isTemplateDeleting = useSelector(
    templatesModel.selectors.selectTemplateIsDeleting
  );

  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState(false);

  const [templateSettingsModalOpen, setTemplateSettingsModalOpen] =
    useState(false);

  return (
    <>
      <DeleteTemplateConfirmationModal
        isOpen={deleteTemplateModalOpen}
        onClose={() => setDeleteTemplateModalOpen(false)}
        template={template}
      />
      <TemplateSettingsModal
        isOpen={templateSettingsModalOpen}
        onClose={() => {
          setTemplateSettingsModalOpen(false);
        }}
        template={template}
      />
      <div className={cn('relative overflow-visible', className)}>
        <Button
          variant="icon"
          color="transparent"
          size="xs"
          onClick={() => setOpen((prev) => !prev)}
          className="!w-fit !min-w-fit !h-fit !min-h-fit "
        >
          <Icon glyph={IconMap.DotsVertical} width={20} />
        </Button>
        {isOpen && (
          <ul
            ref={ref}
            className="w-37 min-h-23 absolute right-0 text-gray-700 bg-base-white shadow-lg p-1.5 rounded-lg border border-gray-300 z-20"
          >
            <li
              className="flex gap-2 items-center h-10 hover:bg-gray-100 rounded-lg pl-2.5 cursor-pointer"
              onClick={handleEdit}
            >
              <Icon glyph={IconMap.Edit02} width={16} />{' '}
              <span className="text-sm font-medium">Edit</span>
            </li>
            <li
              className="flex gap-2 items-center h-10 hover:bg-gray-100 rounded-lg pl-2.5 cursor-pointer"
              onClick={() => setTemplateSettingsModalOpen(true)}
            >
              <Icon glyph={IconMap.Settings} width={16} />{' '}
              <span className="text-sm font-medium">Settings</span>
            </li>
            <li
              className="relative flex items-center pl-2 rounded-lg cursor-pointer h-10 hover:bg-gray-100"
              onClick={() => setDeleteTemplateModalOpen(true)}
            >
              {isTemplateDeleting ? (
                <LoaderDots size="sm" className="mr-1" />
              ) : (
                <Icon
                  glyph={IconMap.Delete}
                  width={16}
                  className="mr-2 ml-0.5"
                />
              )}
              <span className="text-sm font-medium">Delete</span>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};
