import { QUICK_RECORDING_ROUTE } from '../../../entities/history';
import { navigate } from '../../../processes/navigation';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';

export const useRecordOpen = (prefix: string) => {
  const dispatch = useDispatch();

  const handleOpen = () =>
    dispatch(
      navigate({
        to: generatePath(QUICK_RECORDING_ROUTE, { prefix }),
      })
    );

  return { handleOpen };
};
