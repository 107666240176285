import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authModel } from '../../entities/auth-operation';
import { redirectActions } from '../../entities/history';
import { IconMap } from '../../shared/sprite';
import { Avatar, Dropdown } from '../../shared/ui';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { authUserModel } from '../../entities/auth-user';
import { SETTINGS_PROFILE_ROUTE } from '../../entities/history';

type ProfileDropdownProps = {
  onContactSupport: () => void;
};

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  onContactSupport,
}) => {
  const dispatch = useDispatch();
  const { displayName, email, photoUrl } = useSelector(
    authUserModel.selectors.selectUserWithError
  );

  const options = useMemo(
    () => [
      {
        id: 'Settings',
        label: 'Profile Settings',
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: SETTINGS_PROFILE_ROUTE,
            })
          );
        },
        iconName: IconMap.Settings01,
        iconWidth: 16,
        extraBlock: (
          <div className="flex flex-row gap-x-3 mb-5 w-61">
            <Avatar displayName={displayName} src={photoUrl} isUserAvatar />
            <div className="w-full truncate">
              <p className="font-medium text-s text-gray-700 truncate">
                {displayName}
              </p>
              <p className="text-gray-600 text-s truncate">{email}</p>
            </div>
          </div>
        ),
      },
      {
        id: 'Help',
        label: 'Help',
        onClick: onContactSupport,
        iconName: IconMap.HelpCircle,
        iconWidth: 16,
      },
      {
        id: 'LogOut',
        label: 'Log Out',
        onClick: () => dispatch(authModel.actions.signOutClick()),
        iconName: IconMap.Logout,
        iconWidth: 16,
        isSeparatedFromTop: true,
      },
    ],
    [dispatch, displayName, email, photoUrl, onContactSupport]
  );
  return (
    <Dropdown
      listStyles="w-70"
      triggerComponent={
        <DropdownMenu.Trigger className="flex items-start justify-center focus:outline-none h-10 w-10">
          <Avatar displayName={displayName} src={photoUrl} isUserAvatar />
        </DropdownMenu.Trigger>
      }
      items={options}
      isModalMode={false}
    />
  );
};
