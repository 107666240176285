import {
  GetAlertBarResponseType,
  UpdateAlertBarRequestType,
  UpdateAlertBarResponseType,
} from '@distribute/shared/api-types/alert-bar';
import { api } from './instance';

export const alertBarApi = {
  async getAlertBar(documentContentId: number) {
    const { data } = await api.get<GetAlertBarResponseType>(
      `alert-bar/${documentContentId}`
    );

    return data;
  },

  async updateAlertBar(
    documentContentId: number,
    data: UpdateAlertBarRequestType
  ) {
    const { data: responseData } = await api.patch<UpdateAlertBarResponseType>(
      `alert-bar/${documentContentId}`,
      data
    );
    return responseData;
  },
};
