import { useCallback, useRef, useState } from 'react';
import { embedContentApi } from '../../../../../shared/api/axios/embed-content';
import { EmbedContentAttrs, EmbedContentType } from '../types';
import { getLinkContentType } from '../lib/getLinkContentType';

export const useEmbedContent = () => {
  const abortControllerRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Partial<EmbedContentAttrs>>();

  const handleCheck = useCallback((link?: string) => {
    if (!link) return;

    const linkContentType = getLinkContentType(link);

    if (linkContentType !== EmbedContentType.IFRAME) {
      setData({ link, available: true, type: linkContentType });
      return;
    }

    abortControllerRef.current?.abort();
    setIsLoading(true);
    const { request, controller } = embedContentApi.checkLink(link);

    abortControllerRef.current = controller;

    request
      .then((response) => response.data)
      .then((data) => {
        setIsLoading(false);
        setData(data);
      })
      .catch(() => {
        setIsLoading(false);
        setData({
          link,
          available: false,
        });
      });
  }, []);

  return {
    isLoading,
    check: handleCheck,
    data,
  };
};
