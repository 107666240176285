import axios from 'axios';
import { logger } from '../../lib';

export const api = axios.create({
  timeout: 60000,
});

export const videoRecordCheckStatusApi = (path: string) => {
  return api
    .head(path, {
      validateStatus: (status: number) => status >= 200 && status <= 404,
    })
    .then((res) => res.status)
    .catch((err) => {
      logger.error(err);
      return 500;
    });
};
