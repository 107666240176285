import { JSONContent } from '@tiptap/react';

type TableMatrix = (JSONContent | undefined)[][];

const emptyTableCell = {
  type: 'tableCell',
  content: [
    {
      type: 'paragraph',
    },
  ],
};

export const normalizeWritingContent = (content: JSONContent): JSONContent => {
  if (content.type === 'doc' && content.content) {
    return {
      ...content,
      content: content.content.map(normalizeWritingContent),
    };
  }
  if (content.type === 'table') {
    const tableMatrix = tableToMatrix(content);

    return { ...content, content: matrixToTable(tableMatrix) };
  } else {
    return content;
  }
};

function tableToMatrix(table: JSONContent): TableMatrix {
  const matrix: (JSONContent | undefined)[][] = [];

  if (!table.content) {
    return [];
  }
  // Get the number of rows and columns for the matrix
  const numRows = table.content.length;
  let numCols = 0;
  for (let i = 0; i < numRows; i++) {
    numCols = Math.max(numCols, table.content[i].content?.length || 0);
  }

  // Initialize the matrix with empty strings
  for (let i = 0; i < numRows; i++) {
    matrix[i] = new Array(numCols).fill(undefined);
  }

  // Fill the matrix with cell values while considering colspan and rowspan
  for (let i = 0; i < numRows; i++) {
    for (let j = 0; j < numCols; j++) {
      const cells = table.content[i].content;
      if (!cells) {
        continue;
      }
      const cell = cells[j];

      const cellValue = cell;

      if (cell) {
        const rowspan = cell.attrs?.rowspan || 1;
        const colspan = cell.attrs?.colspan || 1;

        // Check if the cell has a rowspan or colspan
        for (let r = 0; r < rowspan; r++) {
          for (let c = 0; c < colspan; c++) {
            const rowIndex = i + r;
            const colIndex = j + c;
            if (rowIndex < numRows && colIndex < numCols) {
              // Only set the cell value if the cell is within the matrix boundaries
              if (!matrix[rowIndex][colIndex]) {
                matrix[rowIndex][colIndex] = cellValue;
              } else {
                let newColIndex = colIndex;

                while (matrix[rowIndex][newColIndex]) {
                  if (newColIndex === numCols + 1) {
                    throw new Error('Col index is out of range');
                  }
                  newColIndex++;
                }
                matrix[rowIndex][newColIndex] = cellValue;
              }
            }
          }
        }
      }
    }
  }

  return matrix;
}

function matrixToTable(matrix: TableMatrix): JSONContent[] {
  const numRows = matrix.length;
  const tableContent: JSONContent[] = [];

  for (let i = 0; i < numRows; i++) {
    const row = matrix[i];
    if (!row || row.length === 0) {
      continue;
    }

    const numCols = row.length;
    const cells: JSONContent[] = [];

    for (let j = 0; j < numCols; j++) {
      const cellValue = matrix[i][j];

      if (cellValue !== undefined) {
        let rowspan = 1;
        let colspan = 1;

        for (let r = i + 1; r < numRows; r++) {
          if (matrix[r][j] === cellValue) {
            rowspan = r - i + 1;
          } else {
            break;
          }
        }

        for (let c = j + 1; c < numCols; c++) {
          if (matrix[i][c] === cellValue) {
            colspan = c - j + 1;
          } else {
            break;
          }
        }
        cells.push({
          ...cellValue,
          attrs: {
            rowspan: rowspan > 1 ? rowspan : undefined,
            colspan: colspan > 1 ? colspan : undefined,
          },
        });

        // Skip additional cells covered by rowspan and colspan
        j += colspan - 1;
      } else {
        cells.push(emptyTableCell);
      }
    }

    if (cells.length > 0) {
      tableContent.push({ content: cells, type: 'tableRow' });
    }
  }

  return tableContent;
}
