import { io, Socket } from 'socket.io-client';
import { WEBSOCKET_SERVER_URL } from '../../shared/config';

export type JoinPageResponse = {
  canEdit: boolean;
  userName: string;
  userEmail: string;
};

export class WebsocketClient {
  private socket: Socket | null = null;

  connect(token: string, teamId: number) {
    this.socket?.disconnect();
    this.socket = io(`${WEBSOCKET_SERVER_URL}/${teamId}`, { auth: { token } });
  }

  joinPage(page: string | number): Promise<JoinPageResponse> {
    return new Promise((resolve) => {
      this.socket?.emit('joinPage', { page }, (response: JoinPageResponse) => {
        resolve(response);
      });
    });
  }

  leavePage(page: string | number) {
    this.socket?.emit('leavePage', { page });
  }
}
