import { FC } from 'react';
import cn from 'classnames';

import { VideoRecordType } from '@distribute/shared/api-types/video-record';
import {
  VideoRecordStatus,
  VideoRecordStatusConvert,
} from '@distribute/shared/types';

import { styles } from './config';

import { RecordCategory } from './types';

import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

import { RecordCardDescription } from './RecordDetails';
import { RecordInProgressStatus } from './RecordUploadingStatus';

import { useRecordOpen, useRecordPermissions } from '../../hooks';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../model/reducer';
import { authUserModel } from '../../../../entities/auth-user';

import {
  checkIsRecordConverting,
  checkIsRecordConvertingFailed,
} from '../../lib';

const Delete: FC<{
  id: string;
  status: VideoRecordStatus;
  statusConvert: VideoRecordStatusConvert;
}> = ({ id, status, statusConvert }) => {
  const dispatch = useDispatch();

  return (
    <button
      onClick={() => dispatch(actions.setRecordIdToDelete(id))}
      type="button"
      className={cn(
        'flex items-center justify-center w-9 h-9 rounded-md focus:outline-none',
        'backdrop-blur-sm',
        {
          'text-gray-700 bg-base-white/[0.75] border border-base-black/10':
            checkIsRecordConverting(statusConvert),
          'text-base-white': checkIsRecordConvertingFailed({
            status,
            statusConvert,
          }),
        }
      )}
    >
      <Icon glyph={IconMap.Trash} width={20} />
    </button>
  );
};

type Props = {
  record: VideoRecordType;
  category: RecordCategory;
};

export const RecordCardInProgress: FC<Props> = ({ record, category }) => {
  const { handleOpen } = useRecordOpen(record.prefix);

  const isRecordConvertingFailed = checkIsRecordConvertingFailed({
    status: record.status,
    statusConvert: record.statusConvert,
  });

  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const { isCanDelete } = useRecordPermissions({
    userId: user.id,
    userPermissionToVideoRecords: record.userPermissionToVideoRecords,
    teamMembersPermission: record.teamMembersPermission,
  });

  const handleClick = () => {
    if (isRecordConvertingFailed) return;

    handleOpen();
  };

  return (
    <div
      tabIndex={0}
      className={cn(styles.base, 'group', {
        'h-64': category === RecordCategory.MY,
        'h-70': category === RecordCategory.SHARED,
        'cursor-pointer': !isRecordConvertingFailed,
      })}
      onClick={handleClick}
    >
      {isCanDelete && (
        <div
          className="absolute top-5 right-5 z-[5] invisible group-hover:visible"
          onClick={(e) => e.stopPropagation()}
        >
          <Delete
            id={record.id}
            status={record.status}
            statusConvert={record.statusConvert}
          />
        </div>
      )}
      <div className={cn(styles.poster)}>
        <RecordInProgressStatus
          status={record.status}
          statusConvert={record.statusConvert}
        />
      </div>
      <RecordCardDescription record={record} category={category} />
    </div>
  );
};
