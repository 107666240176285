import { Node } from '@tiptap/core';

export const CalloutExt = Node.create({
  name: 'callout',

  content: 'paragraph*',
  isolating: true,
  group: 'block',

  addAttributes() {
    return {
      emoji: {
        default: '💡',
      },
      bgColor: {
        default: 'rgb(245, 245, 245)',
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="callout"]' }];
  },

  renderHTML({ node }) {
    return [
      'div',
      { class: 'callout', style: `background-color: ${node.attrs.bgColor}` },
      ['span', { class: 'callout__emoji' }, node.attrs.emoji],
      ['div', { class: 'callout__text' }, 0],
    ];
  },
});
