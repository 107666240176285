import { take, takeEvery } from 'redux-saga/effects';
import { createTeam } from './createTeam';
import { teamsModel } from '..';
import { changeCurrentTeamWorker } from './changeCurrentTeam';
import { updateLastSelectedTeam } from './updateLastSelectedTeam';
import { channel } from 'redux-saga';
import { refreshTeamRelatedData } from './loadTeamRelatedData';
import { authModel } from '../../../../entities/auth-operation';
import { updateCurrentTeamCurrentMember } from './updateCurrentTeamCurrentMember';
import { createTeamForGuest } from './createTeamForGuest';

const refreshChannel = channel();
const MILLISECONDS_IN_MINUTE = 1000 * 60;

export function* teamsGlobalWorker() {
  yield takeEvery(teamsModel.actions.createTeam, createTeam);
  yield takeEvery(teamsModel.actions.createTeamForGuest, createTeamForGuest);
  yield takeEvery(
    teamsModel.actions.updateCurrentTeamCurrentMember,
    updateCurrentTeamCurrentMember
  );
  yield takeEvery(
    teamsModel.actions.changeCurrentTeam,
    changeCurrentTeamWorker
  );
  yield takeEvery(teamsModel.actions.setCurrentTeam, updateLastSelectedTeam);
}

export function* refreshTeamDataWatcher() {
  yield takeEvery(refreshChannel, refreshTeamRelatedData);

  while (true) {
    yield take(teamsModel.actions.setTeams);
    const interval = setInterval(
      () => refreshChannel.put('REFRESH'),
      MILLISECONDS_IN_MINUTE * 3
    );

    yield take(authModel.actions.signOutClick);
    clearInterval(interval);
  }
}
