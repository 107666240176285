import React, { useMemo } from 'react';
import { DNSCard } from './DNSCard';
import { Button } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { customDomainsModel } from '../../model';
import { DomainStatusBadge } from './DomainStatusBadge';
import { DomainStatusNotificationMessage } from './DomainStatusNotificationMessage';
import moment from 'moment';
import { CustomDomainVerificationStatus } from '@distribute/shared/types';

export const ActivateCustomDomainSection: React.FC = () => {
  const dispatch = useDispatch();
  const customDomainInfo = useSelector(
    customDomainsModel.selectors.selectCustomDomainInfo
  );
  const isDeletingDomain = useSelector(
    customDomainsModel.selectors.selectCustomDomainIsDeleting
  );
  const isActivatingDomain = useSelector(
    customDomainsModel.selectors.selectCustomDomainIsActivating
  );

  const shouldShowWarningMessage = useMemo(() => {
    if (!customDomainInfo) return false;
    const hours = moment().diff(moment(customDomainInfo?.createdAt), 'hours');
    return (
      customDomainInfo?.status ===
        CustomDomainVerificationStatus.DNS_RECORD_NOT_FOUND && hours < 24
    );
  }, [customDomainInfo]);

  const shouldShowErrorMessage = useMemo(() => {
    if (!customDomainInfo) return false;
    const hours = moment().diff(moment(customDomainInfo?.createdAt), 'hours');
    return (
      customDomainInfo?.status ===
        CustomDomainVerificationStatus.DNS_RECORD_NOT_FOUND && hours >= 24
    );
  }, [customDomainInfo]);

  const handleDeleteDomain = () => {
    dispatch(customDomainsModel.actions.deleteCustomDomain({}));
  };
  const handleActivateDomain = () => {
    dispatch(customDomainsModel.actions.activateCustomDomain());
  };

  return (
    <div className="text-sm text-gray-700 flex flex-col gap-5">
      <p>Update your DNS settings at your domain provider. </p>
      <p className="max-w-203">
        Below you can find the DNS settings you need to include on your domain
        registrar (GoDaddy, Namecheap, Google Domains, etc.) or DNS provider's
        (Cloudflare, Netlify, etc.) website.
      </p>
      <p>For subdomain:</p>
      <DNSCard
        record={{
          type: 'CNAME',
          host: '(your subdomain)',
          value: 'in.saascustomdomains.com',
        }}
      />
      <p>For apex domain:</p>
      <div className="flex items-center gap-4">
        <DNSCard
          record={{
            type: 'A',
            host: '@',
            value: '99.83.186.151',
          }}
        />
        <DNSCard
          record={{
            type: 'A',
            host: '@',
            value: '75.2.96.173',
          }}
        />
      </div>
      <p className="whitespace-pre">
        {
          "After you've confirmed that the DNS records have been created, hit the button below.\nIt may take up to 20 minutes to verify your domain, so this is a good time for a drink and a stretch!"
        }
      </p>
      {shouldShowWarningMessage && (
        <DomainStatusNotificationMessage type="warning" />
      )}
      {shouldShowErrorMessage && (
        <DomainStatusNotificationMessage type="error" />
      )}
      <div className="flex gap-4 items-center">
        <Button
          variant="text"
          color="secondary"
          onClick={handleDeleteDomain}
          loading={isDeletingDomain}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleActivateDomain}
          disabled={isActivatingDomain}
        >
          Activate custom domain
        </Button>
        {isActivatingDomain && <DomainStatusBadge type="verifying" />}
        {(shouldShowWarningMessage || shouldShowErrorMessage) &&
          !isActivatingDomain && <DomainStatusBadge type="unverified" />}
      </div>
    </div>
  );
};
