import { selectWithError } from '../../../shared/lib';
import { RootState } from '../../../app';

export const selectTemplates = ({ templates }: RootState) => templates.data;
export const selectRecentTemplates = ({ templates }: RootState) =>
  templates.recentTemplates;
export const selectTopTemplates = ({ templates }: RootState) =>
  templates.topTemplates;
export const selectCurrentTemplate = ({ templates }: RootState) =>
  templates.currentTemplate;
export const selectCurrentTemplateContent = ({ templates }: RootState) =>
  templates.currentContent;
export const selectCurrentTemplateWithError = selectWithError(
  selectCurrentTemplate,
  'currentTemplate'
);
export const selectCurrentTemplatesFolder = ({ templates }: RootState) =>
  templates.currentTemplatesFolder;
export const selectTemplateIsDeleting = ({ templates }: RootState) =>
  templates.isTemplateDeleting;
export const selectTemplateIsUpdating = ({ templates }: RootState) =>
  templates.isTemplateUpdating;
export const selectPreviewMode = ({ templates }: RootState) =>
  templates.isPreviewMode;
export const selectSavePageAsTemplateIsLoading = ({ templates }: RootState) =>
  templates.savePageAsTemplateIsLoading;
export const selectSearchQuery = ({ templates }: RootState) =>
  templates.searchQuery;
export const selectTemplatesModalOpen = ({ templates }: RootState) =>
  templates.isTemplateModalOpen;
export const selectIsEditorBlocked = ({ templates }: RootState) =>
  templates.isEditorBlocked;
export const selectExistingEditorData = ({ templates }: RootState) =>
  templates.existingEditorData;
export const selectCreateBlankTemplateIsLoading = ({ templates }: RootState) =>
  templates.createBlankTemplateIsLoading;
export const selectIsTemplateSavedInEditor = ({ templates }: RootState) =>
  templates.isTemplateSavedInEditor;
export const selectRenderedTemplates = ({ templates }: RootState) =>
  templates.renderedTemplates;
