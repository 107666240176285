import {
  UserPermissionToVideoRecord,
  UserPermissionToVideoRecordRole,
  VideoRecordTeamMembersPermission,
} from '@distribute/shared/types';

import { useMemo } from 'react';

const permissions = {
  [UserPermissionToVideoRecordRole.OWNER]: {
    isCanDelete: true,
    isCanEdit: true,
    isCanShare: true,
    isCanDownload: true,
  },
  [UserPermissionToVideoRecordRole.EDITOR]: {
    isCanDelete: false,
    isCanEdit: true,
    isCanShare: true,
    isCanDownload: true,
  },
  [UserPermissionToVideoRecordRole.VIEWER]: {
    isCanDelete: false,
    isCanEdit: false,
    isCanShare: false,
    isCanDownload: false,
  },
} as const;

type Props = {
  userId: string;
  userPermissionToVideoRecords: UserPermissionToVideoRecord[];
  teamMembersPermission: VideoRecordTeamMembersPermission;
};

export const useRecordPermissions = ({
  userId,
  userPermissionToVideoRecords,
  teamMembersPermission,
}: Props) => {
  return useMemo(() => {
    const { role } =
      userPermissionToVideoRecords.find((uV) => uV.user.id === userId) || {};

    if (role) {
      return { ...permissions[role] };
    }

    if (teamMembersPermission === VideoRecordTeamMembersPermission.VIEW) {
      return { ...permissions[UserPermissionToVideoRecordRole.VIEWER] };
    }

    if (teamMembersPermission === VideoRecordTeamMembersPermission.EDIT) {
      return { ...permissions[UserPermissionToVideoRecordRole.EDITOR] };
    }

    return { ...permissions[UserPermissionToVideoRecordRole.VIEWER] };
  }, [userId, userPermissionToVideoRecords, teamMembersPermission]);
};
