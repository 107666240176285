import { Snippet, SnippetType } from '@distribute/shared/types';
import { FC } from 'react';
import cn from 'classnames';
import {
  LOOM_URL_REGEX,
  YOUTUBE_URL_REGEX,
} from '../../../../../shared/constants';
import { FileSnippet } from './FileSnippet';
import { VideoSnippet } from './VideoSnippet';
import { IframeSnippet } from './IframeSnippet';
import { ImageSnippet } from './ImageSnippet';
import { TextSnippet } from './TextSnippet';

type Props = {
  snippet: Snippet;
};

export const SnippetPreview: FC<Props> = ({ snippet }) => {
  const youtube = snippet.content.url?.match(YOUTUBE_URL_REGEX);
  const loom = snippet.content.url?.match(LOOM_URL_REGEX);

  const isIframe = youtube || loom;

  return (
    <div
      className={cn(
        `relative w-full h-41 group-hover:blur sm:group-hover:blur-none flex items-center justify-center bg-gray-200 border-light-6 border rounded-md overflow-hidden`,
        {
          'bg-gray-200': ![SnippetType.IMAGE, SnippetType.VIDEO].includes(
            snippet.type
          ),
        }
      )}
      style={
        [SnippetType.IMAGE, SnippetType.VIDEO].includes(snippet.type)
          ? { backgroundImage: 'url(../../../assets/images/checker.svg)' }
          : {}
      }
    >
      {snippet.type === SnippetType.TEXT && <TextSnippet snippet={snippet} />}

      {snippet.type === SnippetType.IMAGE && (
        <ImageSnippet url={snippet.content.url} />
      )}

      {snippet.type === SnippetType.VIDEO && !isIframe && (
        <VideoSnippet url={snippet.content.url} />
      )}
      {snippet.type === SnippetType.VIDEO && isIframe && (
        <IframeSnippet url={snippet.content.url} />
      )}

      {snippet.type === SnippetType.FILE && (
        <FileSnippet url={snippet.content.url} />
      )}
    </div>
  );
};
