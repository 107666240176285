import React from 'react';
import cn from 'classnames';
import { useSnippetsPermissions } from '../../../features/teams';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../../features/snippets';
import {
  CreateSnippetButton,
  CreateOrEditSnippetCategoryModal,
  ManageCategoriesModal,
} from '../../../features/snippets/ui/components';
import { FeatureFlag, FeatureFlags } from '../../../entities/feature-flag';
import { EmptySnippetsPage } from './EmptySnippetsPage';
import { SnippetsContent } from './SnippetsContent';
import { Button } from '../../../shared/ui';
import { useSubscriptionLimits } from '../../../features/subscription/hooks';
import { UpgradePlanFromSnippetsScreen } from './UpgradePlanFromSnippetsScreen';
import { Helmet } from 'react-helmet-async';

export const SnippetsPage: React.FC = () => {
  const dispatch = useDispatch();

  const { snippets: allowSnippets } = useSubscriptionLimits();

  const {
    isCanCreatePersonalSnippets,
    isCanManageSnippets,
    isCanManageCategories,
  } = useSnippetsPermissions();

  const categories = useSelector(snippetsModel.selectors.selectCategories);
  const personalSnippets = useSelector(
    snippetsModel.selectors.selectPersonalSnippets
  );
  const draftSnippets = useSelector(
    snippetsModel.selectors.selectDraftSnippets
  );

  const isPersonalSnippetsExist =
    isCanCreatePersonalSnippets && personalSnippets.length > 0;

  const isDraftSnippetsExist =
    (isCanManageSnippets || isCanCreatePersonalSnippets) &&
    draftSnippets.length > 0;

  const isCategoriesExist =
    categories.length > 0 &&
    (categories.some((c) => c.snippets.length > 0) || isCanManageSnippets);

  const isDataExist =
    isCategoriesExist || isPersonalSnippetsExist || isDraftSnippetsExist;

  const handleClickManageCategories = () => {
    dispatch(snippetsModel.actions.setIsManageCategoriesModalOpen(true));
  };

  const isCreateCategoryLoading = useSelector(
    snippetsModel.selectors.selectIsCreateCategoryLoading
  );

  const isCreateCategoryModalOpen = useSelector(
    snippetsModel.selectors.selectIsCreateCategoryModalOpen
  );

  const handleCloseCreateCategoryModal = () => {
    dispatch(snippetsModel.actions.setIsCreateCategoryModalOpen(false));
  };

  return (
    <FeatureFlag feature={FeatureFlags.SNIPPETS}>
      <Helmet titleTemplate="Snippets - Distribute" />
      <div className="flex flex-col min-w-0 flex-grow-1">
        <CreateOrEditSnippetCategoryModal
          isOpen={isCreateCategoryModalOpen}
          onClose={handleCloseCreateCategoryModal}
        />
        <ManageCategoriesModal />

        <div className="flex items-center justify-between gap-4 mb-6">
          <h1 className="font-medium text-gray-900 text-display-sm font-display">
            Snippets
          </h1>
          <div
            className={cn('flex items-center gap-4 sm:hidden', {
              hidden: !isDataExist || !allowSnippets,
            })}
          >
            {isCanManageCategories && (
              <Button
                variant="text"
                color="secondary"
                size="md"
                onClick={handleClickManageCategories}
                loading={isCreateCategoryLoading}
                className="font-semibold shadow-xs"
              >
                Manage Categories
              </Button>
            )}
            {(isCanCreatePersonalSnippets || isCanManageSnippets) && (
              <CreateSnippetButton size="sm" />
            )}
          </div>
        </div>

        {!allowSnippets ? (
          <UpgradePlanFromSnippetsScreen />
        ) : isDataExist ? (
          <SnippetsContent />
        ) : (
          <EmptySnippetsPage />
        )}
      </div>
    </FeatureFlag>
  );
};
