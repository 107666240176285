import { AnalyticsEventType, ConversionSource } from './analytics';
import { Conversion } from './conversion';
import { TabActivity } from './tab-activity';

export type ViewerPageSession = {
  sessionId: number;
  createdAt: Date;
  lastPing: Date;
  isClosed: boolean;
  events: AnalyticsEventType;
};

export enum ViewerPersonType {
  LEAD = 'Lead',
  ANONYMOUS = 'Anonymous',
}

export type Location = {
  continentCode: string | null;
  continentName: string | null;
  countryCode: string | null;
  countryName: string | null;
  stateProv: string | null;
  stateCode: string | null;
  city: string | null;
  zipcode: string | null;
  isEu: string | null;
};

export type Viewer = {
  id: number;
  email?: string;
  name?: string;
  phone?: string;
  source?: ConversionSource;
  message?: string;
  ip?: string;
  location?: Location;
  personType: ViewerPersonType;
  isEmailVerified: boolean;
  isLocationProcessed: boolean;
  isProcessed: boolean;
  overallActivity: number;
  views: number;
  lastVisitAt: Date;
  createdAt: Date;
  updatedAt: Date;
  conversions?: Conversion[];
};

export type ViewerDetails = Viewer & {
  conversions: Conversion[];
  tabActivity: TabActivity[];
};
