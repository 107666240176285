import cn from 'classnames';
import { Button, Icon, Modal } from '../../../../shared/ui';
import { snippetModalTitleByType } from './create-snippet/lib/config';
import { IconMap } from '../../../../shared/sprite';
import { Snippet } from '@distribute/shared/types';
import { FC } from 'react';
import { ConfigureSnippetForm } from './create-snippet/ConfigureSnippetForm';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../model';
import { navigate } from '../../../../processes/navigation';
import { SNIPPETS_ROUTE } from '../../../../entities/history';

type Props = {
  snippet: Snippet;
  isOpen: boolean;
  onClose: () => void;
  isEditor?: boolean;
  isSnippetsModal?: boolean;
};

export const SnippetSettingsModal: FC<Props> = ({
  snippet,
  isOpen,
  onClose,
  isEditor = false,
  isSnippetsModal = false,
}) => {
  const dispatch = useDispatch();
  const updatingSnippetsData = useSelector(
    snippetsModel.selectors.selectUpdatingSnippetsData
  );

  const isLoading = useSelector(
    snippetsModel.selectors.selectIsUpdateSnippetLoading
  );

  const handleChangePermissionsConfirmationClose = () => {
    dispatch(snippetsModel.actions.setUpdatingSnippetData(undefined));
  };

  const updateSnippetFromPermissionConfirmation = () => {
    if (!updatingSnippetsData) {
      return;
    }
    dispatch(
      snippetsModel.actions.updateSnippet({
        snippet: snippet,
        snippetId: snippet?.id,
        ...updatingSnippetsData,
        callback: () => {
          onClose();
          if (!isSnippetsModal) {
            dispatch(
              navigate({
                to: `${SNIPPETS_ROUTE}/${updatingSnippetsData.sharingAccess}`,
              })
            );
          }
        },
      })
    );
  };

  return (
    <Modal
      disableCloseByOutsideClick={!isEditor}
      onClose={
        updatingSnippetsData
          ? handleChangePermissionsConfirmationClose
          : onClose
      }
      isOpen={isOpen}
      dialogClassName="z-55"
      className={cn({
        'relative overflow-hidden !p-0 !pb-23.5 w-120 min-h-180':
          !updatingSnippetsData,
      })}
      isShowCancelButton={Boolean(updatingSnippetsData)}
      title={updatingSnippetsData && 'Change Permissions'}
      actionButton={
        updatingSnippetsData && (
          <Button
            onClick={updateSnippetFromPermissionConfirmation}
            fullWidth
            type="submit"
            color="primary"
            variant="text"
            loading={isLoading}
          >
            Apply
          </Button>
        )
      }
    >
      {updatingSnippetsData ? (
        <p className="font-normal text-gray-600 text-s">
          Are you sure that you want to change the permissions of this snippet
          to public? Once done, it cannot be reverted back to personal.
        </p>
      ) : (
        <div className="px-6 pt-6 pb-2">
          <img
            src="../../../assets/images/bg-top-dots-part.svg"
            alt="background"
            className="absolute top-0 left-0"
          />
          <div className="relative mb-5 z-1">
            <div className="flex items-center justify-between mb-4">
              <div className="p-3 bg-gray-100 rounded-full">
                <Icon
                  glyph={snippetModalTitleByType[snippet.type].icon}
                  className="text-gray-500"
                />
              </div>
              <Icon
                glyph={IconMap.XClose}
                className="text-gray-500 cursor-pointer shrink-0"
                width={24}
                onClick={onClose}
              />
            </div>
            <p className="mb-1 font-medium font-display text-display-xs">
              {isEditor
                ? `Save ${snippetModalTitleByType[snippet.type].title}`
                : `${snippetModalTitleByType[snippet.type].title} Settings`}
            </p>
          </div>
          <ConfigureSnippetForm
            snippet={snippet}
            onClose={onClose}
            type={snippet.type}
            isEditor={isEditor}
            isSnippetsModal={isSnippetsModal}
          />
        </div>
      )}
    </Modal>
  );
};
