import { Snippet } from '@distribute/shared/types';
import { SnippetFolderType, SnippetsConfigBasicEnum } from './config';

export const getSnippetsByFolder = (
  folder: SnippetFolderType,
  readySnippets: Snippet[]
) => {
  switch (folder) {
    case SnippetsConfigBasicEnum.ALL:
      return readySnippets;
    case SnippetsConfigBasicEnum.PERSONAL:
      return readySnippets.filter((s) => !s.category);
    default:
      return readySnippets.filter((s) => s.category?.id === folder);
  }
};
