import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../../entities/auth-user';
import { Button, Tooltip } from '../../../../shared/ui';
import { profileSettingModel } from '../../model';
import { ChangeEmailModal } from './ChangeEmailModal';
import { GOOGLE_PROVIDER_ID, PASSWORD_PROVIDER_ID } from '../../lib';

// TODO: add logic after modal implementation
export const ChangeEmailForm: React.FC = () => {
  const { email } = useSelector(authUserModel.selectors.selectUserWithError);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsChangeEmailLoading
  );

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };
  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const authProviders = useSelector(
    authUserModel.selectors.selectAuthProviders
  );

  return (
    <div className="border-b border-gray-200 flex mb-5 pb-5 sm:flex-col">
      <ChangeEmailModal isOpen={modalIsOpen} onClose={handleModalClose} />
      <p className="w-61 text-gray-700 text-sm mr-8 sm:mr-0">Email address</p>
      <div className="flex items-center max-w-107 sm:mt-4 sm:max-w-full w-full sm:flex-col">
        <p className="text-gray-700 text-base w-full mr-3 sm:mr-0">{email}</p>
        <Tooltip
          trigger={
            <Button
              className="sm:ml-0 sm:mt-3 sm:w-full"
              variant="text"
              color="primary"
              type="submit"
              onClick={handleModalOpen}
              loading={isLoading}
              disabled={
                authProviders.includes(GOOGLE_PROVIDER_ID) &&
                !authProviders.includes(PASSWORD_PROVIDER_ID)
              }
            >
              Change Email
            </Button>
          }
          triggerClassNames="sm:w-full"
          sideOffset={4}
          className={
            authProviders.includes(PASSWORD_PROVIDER_ID) ? 'hidden' : ''
          }
        >
          <div className="px-2 py-2 font-semibold text-xs text-left">
            Please create a password
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
