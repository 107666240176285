import { User } from './user';

export enum UserPermissionToFolderRole {
  OWNER = 'owner',
  VIEWER = 'viewer',
  EDITOR = 'editor',
}

export type UserPermissionToFolder = {
  id: number;
  user: User;
  role: UserPermissionToFolderRole;
};
