import {
  RECORDINGS_ROUTE,
  TEMPLATES_IFRAME_DEMO_ROUTE,
  TEMPLATES_IFRAME_MENU_ROUTE,
  TEMPLATES_IFRAME_PREVIEW_ROUTE,
  TEMPLATES_IFRAME_TEMPLATES_ROUTE,
} from '../../../entities/history';
import { nil } from '../../../shared/lib';

export const sharedRouteHandlers = [
  { route: TEMPLATES_IFRAME_MENU_ROUTE, saga: nil },
  { route: TEMPLATES_IFRAME_PREVIEW_ROUTE, saga: nil },
  { route: TEMPLATES_IFRAME_TEMPLATES_ROUTE, saga: nil },
  { route: TEMPLATES_IFRAME_DEMO_ROUTE, saga: nil },
  { route: RECORDINGS_ROUTE, saga: nil },
];
