import React, { useCallback } from 'react';
import { gatedContentModel } from '../model';
import { useDispatch, useSelector } from 'react-redux';

import { conversionKitModel } from '../../conversion-kit';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type IProps = {
  isDisabled: boolean;
};

export const GatedContentControls: React.FC<IProps> = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  );
  const isBlockShows = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const toggleIsGatedContentOpen = useCallback(() => {
    dispatch(gatedContentModel.actions.setIsModalOpen(!isBlockShows));
  }, [dispatch, isBlockShows]);

  const handleOpenPopUpSettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.GATED_CONTENT
      )
    );
  };

  if (!gatedContent?.isActive || isAnyConversionPanelOpen) return null;

  return (
    <ConversionControlItem
      title="Gated content"
      icon={IconMap.GatedContentIcon}
      isOpened={isBlockShows}
      isDisabled={isDisabled}
      onEdit={handleOpenPopUpSettings}
      onPreview={toggleIsGatedContentOpen}
    />
  );
};
