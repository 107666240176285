import { takeEvery } from 'redux-saga/effects';
import { snippetsModel } from '..';

export function* snippetsWorker() {
  yield takeEvery(
    snippetsModel.actions.createCategory,
    snippetsModel.sagas.createCategory
  );
  yield takeEvery(
    snippetsModel.actions.createSnippet,
    snippetsModel.sagas.createSnippet
  );
  yield takeEvery(
    snippetsModel.actions.updateCategory,
    snippetsModel.sagas.updateCategory
  );
  yield takeEvery(
    snippetsModel.actions.deleteSnippet,
    snippetsModel.sagas.deleteSnippet
  );

  yield takeEvery(
    snippetsModel.actions.deleteSnippetCategory,
    snippetsModel.sagas.deleteSnippetCategory
  );

  yield takeEvery(
    snippetsModel.actions.updateSnippet,
    snippetsModel.sagas.updateSnippet
  );

  yield takeEvery(
    snippetsModel.actions.changeSnippetTextContent,
    snippetsModel.sagas.changeSnippetTextContent
  );

  yield takeEvery(
    snippetsModel.actions.replaceSnippet,
    snippetsModel.sagas.replaceSnippet
  );

  yield takeEvery(
    snippetsModel.actions.reorderCategories,
    snippetsModel.sagas.reorderCategories
  );
}
