import React, { Dispatch, SetStateAction } from 'react';
import { Helmet } from 'react-helmet-async';
import { Snippet } from '@distribute/shared/types';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useUserAgentDevices } from '../../../../shared/hooks/useUserAgentDevices';
import { SnippetEditorHeader } from './SnippetEditorHeader';
import { SnippetEditorSidebar } from './SnippetEditorSidebar';

type Props = {
  children: React.ReactNode;
  currentSnippet: Snippet;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export const SnippetEditorWrapper: React.FC<Props> = ({
  children,
  currentSnippet,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  return (
    <div className="flex flex-col relative min-h-screen">
      <Helmet
        titleTemplate={`${currentSnippet?.name || 'Untitled'} - Distribute`}
      />

      <SnippetEditorHeader
        currentSnippet={currentSnippet}
        setIsSidebarOpen={setIsSidebarOpen}
        isSidebarOpen={isSidebarOpen}
      />
      {children}
      {!isMobile && !isTablet && (
        <SnippetEditorSidebar isOpen={isSidebarOpen} />
      )}
    </div>
  );
};
