import React, { FC } from 'react';
import cn from 'classnames';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type IProps = {
  className?: string;
  theme?: 'warning' | 'error';
};

const features = [
  {
    id: 1,
    from: 'Unlimited Published Pages',
    to: '5 Published Pages',
  },
  { id: 2, from: 'Unlimited Wizard Writes', to: '20 AI responses' },
];

export const DowngradeToFreeFeatures: FC<IProps> = ({
  className,
  theme = 'warning',
}) => {
  return (
    <div className={cn('flex gap-6', className)}>
      {features.map((item) => (
        <div key={item.id} className="flex items-center gap-2 text-sm">
          <span className="text-gray-600 line-through">{item.from}</span>
          <Icon glyph={IconMap.ArrowRight} width={20} className="shrink-0" />
          <span
            className={cn({
              'text-warning-600': theme === 'warning',
              'text-error-600': theme === 'error',
            })}
          >
            {item.to}
          </span>
        </div>
      ))}
    </div>
  );
};
