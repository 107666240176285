import { call, put } from 'redux-saga/effects';
import { Page } from '@distribute/shared/types';
import { publicTemplatesModel } from '..';
import { publicTemplatesApi } from '../../../../shared/api/axios/public-templates';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { handleError } from '../../../../utils/handleError';
import { teamsModel } from '../../../teams';
import { redirect } from '../../../../entities/history';
import { editorSidebarModel } from '../../../editor-sidebar';

type DuplicatePageInput = {
  teamId: number;
  folderId: string;
  pageId: string;
};

export function* duplicatePage({
  teamId,
  folderId,
  pageId,
}: DuplicatePageInput) {
  try {
    yield put(publicTemplatesModel.actions.setIsDuplicatingPage(true));
    const page: Page = yield call(publicTemplatesApi.duplicatePage, pageId, {
      folderId,
    });
    yield call(teamsModel.sagas.changeCurrentTeam, teamId, false);
    yield put(editorSidebarModel.actions.setSidebarOpen(true));
    yield call(redirect.toEditorPage, { sequenceNumber: page.sequenceNumber });
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Failed to duplicate page'))
      )
    );
  } finally {
    yield put(publicTemplatesModel.actions.setIsDuplicatingPage(false));
  }
}

export function* duplicatePageWorker({
  payload: { teamId, folderId, pageId },
}: ReturnType<typeof publicTemplatesModel.actions.duplicatePage>) {
  yield call(duplicatePage, { teamId, folderId, pageId });
}
