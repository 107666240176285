import { Location } from 'history';
import { call } from 'redux-saga/effects';
import { redirect } from '../../entities/history';
import { onHistory, onNavigate, publicRouteHandlers } from '../routing';
import { acceptTeamInvitationModel } from '../../features/accept-team-invitation';
import { hideGleapBlock } from '../../entities/gleap';
import { saveRedirectUrl } from '../routing/lib';
import { acceptPageInvitationModel } from '../../features/accept-page-invitation';

export function* handleSignedOut() {
  yield call(hideGleapBlock);

  saveRedirectUrl();

  yield call(
    acceptTeamInvitationModel.sagas.handleAcceptTeamInvitationSignedOut
  );
  yield call(
    acceptPageInvitationModel.sagas.handleAcceptPageInvitationSignedOut
  );
  yield call(
    onHistory,
    function* (location: Location) {
      yield call(onNavigate, location, publicRouteHandlers, redirect.toLogin);
    },
    'Public'
  );
}
