import {
  Button,
  CompanyRecord,
  Icon,
  Input,
  Loader,
} from '../../../../../../shared/ui';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce';
import { ClearbitCompanyInfo } from '../../../../../../shared/api';
import { IconMap } from '../../../../../../shared/sprite';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPageModalModel } from '../../../../../../entities/create-page-modal';
import { PageCreationWorkflowsEnum } from '../../../../../../entities/create-page-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { pagesModel } from '../../../../../../features/pages';

type Props = {
  onCreatePage(pageName?: string): void;
};

type Schema = { searchQuery: string };

const inputSchema = object().shape({
  searchQuery: string(),
});

export const ChoosePageBrandingStep: React.FC<Props> = ({ onCreatePage }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const companies = useSelector(
    createPageModalModel.selectors.selectBrandingCompanies
  );

  const companiesSearchQuery = useSelector(
    createPageModalModel.selectors.selectCompaniesSearchQuery
  );

  const creationWorkflow = useSelector(
    createPageModalModel.selectors.selectCreationsWorkflow
  );

  const { register, handleSubmit, watch } = useForm<Schema>({
    resolver: yupResolver(inputSchema),
    defaultValues: {
      searchQuery: companiesSearchQuery,
    },
  });

  const searchQuery = watch('searchQuery');

  const isLoading = useSelector(
    createPageModalModel.selectors.selectCompaniesSearchIsLoading
  );

  const isCreatingPage = useSelector(
    pagesModel.selectors.selectCreatePageIsLoading
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(
    debounce(async (query: string) => {
      dispatch(
        createPageModalModel.actions.searchCompanyBrands({ searchQuery: query })
      );
    }, 400),
    []
  );

  const handleFirstCompanySelect = () => {
    if (!companies.length) return;
    dispatch(
      createPageModalModel.actions.setSelectedBrandingCompany(companies[0])
    );
  };

  const handleCompanySelect = (company: ClearbitCompanyInfo) => {
    dispatch(createPageModalModel.actions.setSelectedBrandingCompany(company));
    dispatch(createPageModalModel.actions.setCompaniesSearchQuery(searchQuery));
    dispatch(createPageModalModel.actions.goNext());
  };

  const handleGeneralPageCreate = () => {
    if (creationWorkflow === PageCreationWorkflowsEnum.WITH_AI) {
      dispatch(createPageModalModel.actions.goNext());
    } else {
      onCreatePage();
    }
  };

  useEffect(() => {
    searchCompanies(searchQuery);
  }, [searchCompanies, searchQuery]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('gongCallId')) {
      queryParams.delete('gongCallId');
      history.replace({ search: queryParams.toString() });
    }
  });

  return (
    <form
      onSubmit={handleSubmit(handleFirstCompanySelect)}
      className="relative flex items-center justify-center h-full"
    >
      <div className="flex h-139 flex-col mt-21">
        <main className="flex items-center flex-col">
          <h2 className="text-display-md text-gray-900 font-medium font-display mb-2">
            Brand of the page
          </h2>
          <p className="text-gray-600 mb-10">
            Which company should this page be for?
          </p>
          <Input
            {...register('searchQuery')}
            type="text"
            placeholder="Company name"
            className="border-none !text-3xl !p-0 h-9.5 !placeholder-gray-300 !bg-transparent font-bold rounded-none text-center"
            autoComplete="off"
          />
          {isLoading && !companies.length && (
            <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-10">
              <Loader isFullScreen={false} size="sm" />
            </div>
          )}
          <ul className="flex flex-col gap-0.5 mt-10 w-full">
            {companies.map((company: ClearbitCompanyInfo) => (
              <CompanyRecord
                key={company.domain}
                companyInfo={company}
                handleClick={() => handleCompanySelect(company)}
              />
            ))}
          </ul>
        </main>
        <div className="mt-auto w-140">
          {!companies.length && (
            <Button
              variant="text"
              color="link"
              className="!px-0 m-auto"
              onClick={handleGeneralPageCreate}
              loading={isCreatingPage}
            >
              No, I'd like to set up a general page
              <Icon
                glyph={IconMap.ArrowRight}
                className="ml-2"
                width={20}
              ></Icon>
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};
