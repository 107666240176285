import { useState, useEffect, useCallback } from 'react';
import debounce from 'debounce';
const lgScreen = 1023;
const smScreen = 767;

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSizes = useCallback(
    debounce(() => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', updateSizes);
    updateSizes();
    return () => window.removeEventListener('resize', updateSizes);
  }, [updateSizes]);

  const isDesktop = windowWidth > lgScreen;
  const isTabletOrMobile = windowWidth < lgScreen;
  const isDesktopOrTablet = windowWidth > smScreen;
  const isTablet = windowWidth > smScreen && windowWidth < lgScreen;
  const isMobile = !isDesktopOrTablet;

  return {
    windowWidth,
    windowHeight,
    isDesktopOrTablet,
    isDesktop,
    isTabletOrMobile,
    isMobile,
    isTablet,
  };
};
