import {
  CreateFolderRequestType,
  CreateFolderResponseType,
  GetFoldersResponseType,
  RenameFolderResponseType,
  DeleteFolderResponseType,
  ReorderFolderResponseType,
  ReorderFolderRequestType,
  UpdateTeamMemberPermissionRequestType,
  UpdateTeamMemberPermissionResponseType,
  CreateUserPermissionRequestType,
  CreateUserPermissionResponseType,
  UpdateUserPermissionRequestType,
  UpdateUserPermissionResponseType,
  DeleteUserPermissionResponseType,
} from '@distribute/shared/api-types/folder';
import { api } from './instance';

export const foldersApi = {
  async getFolders(teamId: number) {
    const { data } = await api.get<GetFoldersResponseType>(
      `folders?teamId=${teamId}`
    );
    return data;
  },

  async addFolder(data: CreateFolderRequestType) {
    const { data: responseData } = await api.post<CreateFolderResponseType>(
      'folders',
      data
    );

    return responseData;
  },

  async deleteFolder(id: string) {
    const { data: responseData } = await api.delete<DeleteFolderResponseType>(
      `folders/${id}`
    );
    return responseData;
  },

  async reorderFolder(data: ReorderFolderRequestType) {
    const { data: responseData } = await api.put<ReorderFolderResponseType>(
      'folders',
      { folders: data.folders }
    );
    return responseData;
  },

  async renameFolder(id: string, title: string) {
    const { data: responseData } = await api.patch<RenameFolderResponseType>(
      `folders/${id}`,
      {
        title: title,
      }
    );
    return responseData;
  },

  async updateTeamMemberPermission(
    id: string,
    data: UpdateTeamMemberPermissionRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateTeamMemberPermissionResponseType>(
        `folders/${id}/team-member-permission`,
        data
      );

    return responseData;
  },
  async createUserPermission(
    id: string,
    data: CreateUserPermissionRequestType
  ) {
    const { data: responseData } =
      await api.post<CreateUserPermissionResponseType>(
        `folders/${id}/user-permission`,
        data
      );

    return responseData;
  },

  async updateUserPermission(
    folderId: string,
    userPermissionId: number,
    data: UpdateUserPermissionRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateUserPermissionResponseType>(
        `folders/${folderId}/user-permission/${userPermissionId}`,
        data
      );

    return responseData;
  },

  async deleteUserPermission(folderId: string, userPermissionId: number) {
    const { data } = await api.delete<DeleteUserPermissionResponseType>(
      `folders/${folderId}/user-permission/${userPermissionId}`
    );

    return data;
  },
};
