import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  JSONContent,
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
  generateHTML,
} from '@tiptap/react';
import { Node } from '@tiptap/pm/model';

import '../ResizeableFigure/ResizeableFigure.css';
import { FragmentWithContent } from '../MultipleColumns';
import { SnippetAlignmentDropdown } from './SnippetAlignmentDropdown';
import { SnippetAlign } from './SnippetBlock';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import {
  MediaSnippetJSON,
  Snippet,
  SnippetType,
} from '@distribute/shared/types';

import { YOUTUBE_URL_REGEX } from '../../../../shared/constants';
import { TextSnippetView } from './TextSnippetView';

export type SnippetNodeType = NodeViewProps['node'] & {
  attrs: {
    id: string;
  };
};

export type SnippetNodeViewProps = NodeViewProps & {
  node: SnippetNodeType;
};

type Props = SnippetNodeViewProps;

export const SnippetBlockNodeView = (props: Props) => {
  const [currentSnippet, setCurrentSnippet] = useState<undefined | Snippet>(
    undefined
  );

  const getSnippet = useCallback(async () => {
    const snippet = await snippetsApi.getSnippetData(props.node.attrs.id);
    setCurrentSnippet(snippet);
    return snippet;
  }, [props.node.attrs.id]);

  useEffect(() => {
    getSnippet();
  }, [getSnippet]);

  const contentRef = useRef<HTMLDivElement>(null);

  const htmlContent = useMemo(() => {
    const youtubeMatch = currentSnippet?.content.url?.match(YOUTUBE_URL_REGEX);

    switch (currentSnippet?.type) {
      case SnippetType.IMAGE:
        return {
          type: 'doc',
          content: [
            {
              type: 'image',
              attrs: {
                alt: 'snippet image',
                src: currentSnippet.content.url,
                title: 'snippet image',
              },
            },
          ],
        };
      case SnippetType.VIDEO:
        return youtubeMatch
          ? {
              type: 'doc',
              content: [
                {
                  type: 'youtube',
                  attrs: {
                    src: currentSnippet.content.url,
                  },
                },
              ],
            }
          : {
              type: 'doc',
              content: [
                {
                  type: 'video',
                  attrs: {
                    src: currentSnippet.content.url,
                    poster: null,
                  },
                },
              ],
            };
      case SnippetType.FILE:
        return {
          type: 'doc',
          content: [
            {
              type: 'iframe',
              attrs: {
                src: currentSnippet.content.url,
                alt: currentSnippet.name,
                title: currentSnippet.name,
              },
            },
          ],
        };
      case SnippetType.TEXT:
        // Need to separate logic without returning an empty value
        return { type: 'doc', content: [] };
      default:
        return {};
    }
  }, [currentSnippet?.content, currentSnippet?.name, currentSnippet?.type]);

  const content = currentSnippet
    ? generateHTML(htmlContent, props.editor.extensionManager.extensions)
    : `<p>loading...</p>`;

  useEffect(() => {
    if (content && contentRef && contentRef.current) {
      contentRef.current.innerHTML = content;
    }
  }, [content]);

  const { editor, node, updateAttributes, selected, getPos } = props;
  const mediaWidth =
    node.attrs['data-media-width'] ??
    (node.content as FragmentWithContent)?.content?.[0]?.attrs.width;
  const mediaHeight =
    node.attrs['data-media-height'] ??
    (node.content as FragmentWithContent)?.content?.[0]?.attrs.height;
  const align: SnippetAlign = node.attrs['data-align'];
  const ContentRef = useRef<HTMLDivElement | null>(null);

  const handleHorizontalResize = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      direction: 'right' | 'left'
    ) => {
      e.stopPropagation();
      e.preventDefault();

      editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
        iframe.style.pointerEvents = 'none';
      });

      const startX = e.pageX;
      const startWidth = ContentRef.current?.getBoundingClientRect().width || 0;

      const handleMouseMove = (e: MouseEvent) => {
        const width =
          direction === 'left'
            ? startWidth - e.pageX + startX
            : startWidth + e.pageX - startX;

        const maxWidth =
          ContentRef.current?.closest('.node-column')?.getBoundingClientRect()
            .width || editor.view.dom.getBoundingClientRect().width;

        updateAttributes({
          'data-media-width': Math.max(Math.min(width, maxWidth), 100),
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
          iframe.style.pointerEvents = '';
        });
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateAttributes, editor]
  );

  const handleResize = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      direction: 'nw' | 'ne' | 'sw' | 'se'
    ) => {
      e.stopPropagation();
      e.preventDefault();

      editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
        iframe.style.pointerEvents = 'none';
      });

      const startY = e.pageY;
      const startX = e.pageX;
      const startHeight =
        ContentRef.current?.getBoundingClientRect().height || 0;
      const startWidth = ContentRef.current?.getBoundingClientRect().width || 0;

      const handleMouseMove = (e: MouseEvent) => {
        const height =
          direction === 'nw' || direction === 'ne'
            ? startHeight - e.pageY + startY
            : startHeight + e.pageY - startY;
        const width =
          direction === 'ne' || direction === 'se'
            ? startWidth - e.pageX + startX
            : startWidth + e.pageX - startX;

        const maxWidth =
          ContentRef.current?.closest('.node-column')?.getBoundingClientRect()
            .width || editor.view.dom.getBoundingClientRect().width;

        updateAttributes({
          'data-media-height': Math.max(Math.min(height), 100),
          'data-media-width': Math.max(Math.min(width, maxWidth), 100),
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        editor.view.dom.querySelectorAll('iframe').forEach((iframe) => {
          iframe.style.pointerEvents = '';
        });
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateAttributes, editor]
  );

  const isVideo = currentSnippet?.type === SnippetType.VIDEO;
  const isImage = currentSnippet?.type === SnippetType.IMAGE;

  const wrapperClass = classNames(
    'relative group cursor-pointer z-10 max-w-full',
    selected && 'outline-4 outline-brand-500'
  );

  const nodeViewClassName = classNames(
    'node--resizeableFigure !border-0',
    selected && 'selected',
    align === 'float-left' && 'float-left mr-4',
    align === 'float-right' && 'float-right ml-4'
  );

  const flexWrapperClassName = classNames(
    'flex items-center relative',
    align === 'left' && 'justify-start mt-7 mb-7 w-full',
    align === 'center' && 'justify-center mt-7 mb-7 w-full',
    align === 'right' && 'justify-end mt-7 mb-7 w-full',
    align === 'float-left' && 'justify-start',
    align === 'float-right' && 'justify-end'
  );

  const handleElementClick = () => {
    const pos = getPos();
    editor.chain().setNodeSelection(pos).focus().run();
  };

  const deleteSnippetNode = useCallback(() => {
    const pos = getPos();

    const rangeToDelete = {
      from: pos,
      to: pos + node.nodeSize,
    };

    const nodesBetween: Array<{ node: Node; pos: number }> = [];

    editor.view.state.doc.nodesBetween(pos, pos, (node, pos) => {
      nodesBetween.push({ node, pos });
    });

    for (let i = nodesBetween.length - 1; i >= 0; i--) {
      const { node, pos } = nodesBetween[i];
      if (node.isBlock && node.childCount <= 1) {
        rangeToDelete.from = pos;
        rangeToDelete.to = pos + node.nodeSize;
      } else {
        break;
      }
    }

    editor.chain().focus(pos).deleteRange(rangeToDelete).blur().run();
  }, [editor, getPos, node.nodeSize]);

  useEffect(() => {
    if (!currentSnippet) return;

    if (!currentSnippet.isActive) {
      const pos = getPos();
      if (currentSnippet?.type === SnippetType.TEXT) {
        const content = currentSnippet.content as JSONContent;

        content.content?.reverse().forEach((contentItem) =>
          editor
            .chain()
            .focus(pos)
            .insertContentAt(pos + node.nodeSize, { ...contentItem })
            .focus()
            .run()
        );

        deleteSnippetNode();
      }

      if (currentSnippet?.type === SnippetType.IMAGE) {
        const content = currentSnippet.content as MediaSnippetJSON;

        editor
          .chain()
          .focus(pos)
          .insertContentAt(pos + node.nodeSize, {
            type: 'resizeableFigure',
            content: [
              {
                type: 'image',
                attrs: {
                  src: content.url,
                  alt: currentSnippet.name,
                  title: currentSnippet.name,
                },
              },
            ],
            attrs: node.attrs,
          })
          .blur()
          .run();

        deleteSnippetNode();
      }

      if (currentSnippet?.type === SnippetType.VIDEO) {
        const content = currentSnippet.content as MediaSnippetJSON;
        const youtubeMatch = content.url.match(YOUTUBE_URL_REGEX);

        editor
          .chain()
          .focus(pos)
          .insertContentAt(pos + node.nodeSize, {
            type: 'resizeableFigure',
            content: [
              {
                type: youtubeMatch ? 'youtube' : 'video',
                attrs: {
                  src: content.url,
                },
              },
            ],
            attrs: node.attrs,
          })
          .blur()
          .run();

        deleteSnippetNode();
      }

      if (currentSnippet?.type === SnippetType.FILE) {
        const content = currentSnippet.content as MediaSnippetJSON;

        editor
          .chain()
          .focus(pos)
          .insertContentAt(pos + node.nodeSize, {
            type: 'resizeableFigure',
            content: [
              {
                type: 'iframe',
                attrs: {
                  src: content.url,
                  alt: currentSnippet.name,
                  title: currentSnippet.name,
                },
              },
            ],
            attrs: node.attrs,
          })
          .blur()
          .run();

        deleteSnippetNode();
      }
    }
  }, [
    currentSnippet,
    editor,
    deleteSnippetNode,
    node.attrs,
    node.nodeSize,
    getPos,
  ]);

  return (
    <NodeViewWrapper className={nodeViewClassName}>
      <figure
        data-drag-handle
        draggable="true"
        className={
          currentSnippet?.type === SnippetType.TEXT
            ? 'my-5 -ml-1'
            : flexWrapperClassName
        }
        contentEditable={false}
      >
        <div
          className={classNames('iframe-content-wrapper group', wrapperClass)}
        >
          <div
            style={{
              width:
                mediaWidth && currentSnippet?.type !== SnippetType.TEXT
                  ? `${mediaWidth}px`
                  : 'auto',
              height:
                mediaHeight && !isImage && !isVideo
                  ? `${mediaHeight}px`
                  : 'auto',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            className={classNames(
              'outline-2-outline-offset-2 z-10 outline-primary-500 group-hover:outline-dashed',
              {
                'outline-dashed': selected,
                'bg-blue-dark-100':
                  currentSnippet?.type === SnippetType.TEXT && selected,
                'rounded-lg': currentSnippet?.type === SnippetType.TEXT,
              }
            )}
            onClick={handleElementClick}
            ref={(el) => {
              ContentRef.current = el as HTMLDivElement;
            }}
          >
            {currentSnippet?.type === SnippetType.TEXT ? (
              <TextSnippetView snippet={currentSnippet} />
            ) : (
              <div ref={contentRef} className="w-full h-full"></div>
            )}
            <NodeViewContent />
          </div>
          {currentSnippet?.type !== SnippetType.TEXT && (
            <>
              <div
                draggable="false"
                onMouseDown={(e) => handleHorizontalResize(e, 'left')}
                className="absolute top-1.5 z-10 invisible w-2 h-[calc(100%-12px)] transition-all opacity-0 -left-1 group-hover:visible group-hover:opacity-70 bg-transparent cursor-col-resize"
              ></div>
              <div
                draggable="false"
                onMouseDown={(e) => handleHorizontalResize(e, 'right')}
                className="absolute top-1.5 z-10 invisible w-2 h-[calc(100%-12px)] transition-all opacity-0 -right-1 group-hover:visible group-hover:opacity-70 bg-transparent cursor-col-resize"
              ></div>
              <div
                className={classNames(
                  'absolute w-2.5 h-2.5 bg-primary-500 -left-1 -top-1 cursor-nw-resize border border-base-white rounded-sm group-hover:visible resize-rect',
                  { invisible: !selected }
                )}
                onMouseDown={(e) => handleResize(e, 'ne')}
              ></div>
              <div
                className={classNames(
                  'absolute w-2.5 h-2.5 bg-primary-500 -right-1 -top-1 cursor-ne-resize border border-base-white rounded-sm  group-hover:visible resize-rect',
                  { invisible: !selected }
                )}
                onMouseDown={(e) => handleResize(e, 'nw')}
              ></div>
              <div
                className={classNames(
                  'absolute w-2.5 h-2.5 bg-primary-500 -left-1 -bottom-1 cursor-sw-resize border border-base-white rounded-sm  group-hover:visible resize-rect',
                  { invisible: !selected }
                )}
                onMouseDown={(e) => handleResize(e, 'se')}
              ></div>
              <div
                className={classNames(
                  'absolute w-2.5 h-2.5 bg-primary-500 -right-1 -bottom-1 cursor-se-resize border border-base-white rounded-sm group-hover:visible resize-rect',
                  { invisible: !selected }
                )}
                onMouseDown={(e) => handleResize(e, 'sw')}
              ></div>
              <div className="absolute transition-all opacity-0 top-2 right-2 group-hover:opacity-100 align-rect">
                <SnippetAlignmentDropdown
                  updateAttributes={updateAttributes}
                  align={align}
                />
              </div>
            </>
          )}
        </div>
      </figure>
    </NodeViewWrapper>
  );
};
