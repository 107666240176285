import { FC } from 'react';

import { useDispatch } from 'react-redux';
import { Button } from '../../../../shared/ui';

import cn from 'classnames';

import {
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
  redirectActions,
} from '../../../../entities/history';

type Props = {
  isReached: boolean;
  value: number;
  max: number;
};

export const RecordsReached: FC<Props> = ({ value, max, isReached }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={cn(
        'relative flex flex-col p-6 border-2 rounded-2xl shadow-sidebar-banner bg-base-white',
        {
          'border-primary-600': !isReached,
          'border-warning-500': isReached,
        }
      )}
    >
      <h5 className="text-md font-semibold text-gray-900">
        {isReached
          ? 'Distribute video limit is reached'
          : 'Get Unlimited Video'}
      </h5>
      <p className="text-sm text-gray-600 mt-1 mb-3">
        {isReached
          ? 'Upgrade for unlimited videos and more.'
          : `You have ${value}/${max} videos left. Upgrade for unlimited videos and more`}
      </p>
      <Button
        color="primary"
        variant="text"
        className="self-start"
        size="sm"
        onClick={() =>
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
            })
          )
        }
      >
        Upgrade Plan
      </Button>
    </div>
  );
};
