export const updateOptions = [
  {
    id: 'update-all',
    label: 'Update all snippet instances',
    value: 0,
  },
  {
    id: 'update-new',
    label: 'Save only for new pages',
    value: 1,
  },
];
