import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '../../../../shared/ui';
import { subscriptionModel } from '../../model';
import { workspaceModel } from '../../../workspace';

export const PlanReachedMembersLimitModal: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    subscriptionModel.selectors.selectIsShowPlanReachedMembersLimitModal
  );

  const handleClose = () => {
    dispatch(
      subscriptionModel.actions.setIsShowPlanReachedMembersLimitModal(false)
    );
  };

  const handleContactSales = () => {
    handleClose();
    dispatch(workspaceModel.actions.setIsShowContactSupportModal(true));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Team Plan has reached its capacity"
      actionButton={
        <Button
          fullWidth
          onClick={handleContactSales}
          color="primary"
          variant="text"
        >
          Contact Sales
        </Button>
      }
      className="!w-120 sm:!w-full"
    >
      <div className="flex flex-col gap-5 text-sm text-gray-600">
        <div>
          The maximum number of members for team plan are already added. If you
          need to add more than five members, please get in touch with our sales
          team, and we will create a special offer just for you.
        </div>
        <div>
          In the meantime, you can switch to the Pro plan and continue to add
          more members to your workspace.
        </div>
      </div>
    </Modal>
  );
};
