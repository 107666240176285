import debounce from 'debounce';
import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import { string, ValidationError } from 'yup';

import { Input, Icon, Tooltip, Button } from '.';
import { pagesApi } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../features/teams';
import { createNotification, snackbarModel } from '../../features/snackbar';
import { getFullPageUrl, getPageUrl } from '../lib';
import { IconMap } from '../sprite';
import { customDomainsModel } from '../../features/custom-domains';
import { editorSidebarModel } from '../../features/editor-sidebar';
import { createPageQueryRandomValue } from '../../features/pages/lib';

type Props = {
  validateMessage: string;
  setValidateMessage: (message: string) => void;
  setIsSlugValid: (val: boolean) => void;
  setSlug: (val: string) => void;
  isLoading: boolean;
  slug: string;
  currentSlug?: string;
  disabled?: boolean;
  showCopy?: boolean;
  showGlobe?: boolean;
  isError?: boolean;
};

export const DebounceSlugInput: FC<Props> = ({
  validateMessage,
  setValidateMessage,
  setIsSlugValid,
  setSlug,
  isLoading,
  slug,
  currentSlug,
  disabled,
  showCopy = true,
  showGlobe = false,
  isError,
}) => {
  const dispatch = useDispatch();

  const [isStartedTyping, setIsStartedTyping] = useState(false);

  const { id: currentTeamId, domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const shouldFocusSlugInput = useSelector(
    editorSidebarModel.selectors.selectShouldFocusSlugInput
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!shouldFocusSlugInput) return;

    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
    });

    return () => clearTimeout(timeoutId);
  }, [shouldFocusSlugInput]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSlugValidate = useCallback(
    debounce(async (slug: string, currentPageUrl: string) => {
      try {
        string()
          .required('')
          .matches(/^[a-zA-Z0-9-]+$/, currentPageUrl)
          .validateSync(slug);
      } catch (e) {
        setValidateMessage((e as ValidationError).message);
        setIsSlugValid(false);

        return;
      }

      const data = await pagesApi.checkPageSlug({
        pageSlug: slug,
        teamId: currentTeamId,
      });
      if (!data.isUnique && currentSlug !== slug) {
        setIsSlugValid(false);
        setValidateMessage(
          `Page with this URL already exists: ${currentPageUrl}`
        );
        return;
      }

      setIsSlugValid(true);
      setValidateMessage('');
    }, 400),
    []
  );

  useEffect(() => {
    if (slug !== '') {
      setIsStartedTyping(true);
    }

    if (isStartedTyping) {
      onSlugValidate(
        slug,
        getPageUrl({ slug, domain: currentTeamDomain, customDomain })
      );
    }
  }, [slug, onSlugValidate, isStartedTyping, currentTeamDomain, customDomain]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = e.target.value.replace(/\s/g, '-');
    setSlug(newSlug);
  };

  const onCopy = () => {
    const pageUrl = getFullPageUrl({
      slug,
      domain: currentTeamDomain,
      customDomain,
    });

    navigator.clipboard.writeText(`${pageUrl}?${createPageQueryRandomValue()}`);

    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  };

  const renderInput = () => {
    return (
      <Input
        ref={inputRef}
        type="text"
        value={
          !disabled
            ? slug
            : getPageUrl({ slug, domain: currentTeamDomain, customDomain })
        }
        className={disabled ? 'pl-10 relative' : ''}
        messageText={
          disabled ? null : (
            <span className="flex justify-between gap-x-1">
              <span className="truncate">
                {showGlobe && (
                  <Icon
                    glyph={IconMap.Globe03}
                    width={14}
                    className="text-gray-500 inline-block -mt-0.5 mr-1.5"
                  />
                )}
                {validateMessage ||
                  getPageUrl({ slug, domain: currentTeamDomain, customDomain })}
              </span>
            </span>
          )
        }
        onChange={onChange}
        isError={isError && !isLoading}
        disabled={disabled}
        heightSize="md"
      />
    );
  };

  return (
    <div className="flex items-start gap-2 relative grow">
      <div className="overflow-hidden grow">
        {disabled ? (
          <Tooltip
            trigger={renderInput()}
            hideArrow
            sideOffset={4}
            className="!max-w-100"
          >
            <p className="px-2 py-1 font-semibold text-xs">
              {getPageUrl({ slug, domain: currentTeamDomain, customDomain })}
            </p>
          </Tooltip>
        ) : (
          renderInput()
        )}
      </div>

      {disabled && (
        <Icon
          glyph={IconMap.Globe03}
          width={20}
          className="text-gray-500 absolute left-3 top-2.5"
        />
      )}
      {showCopy && (
        <Tooltip
          trigger={
            <Button
              type="button"
              variant="icon"
              color="secondary"
              title="Copy"
              size="md"
              onClick={onCopy}
            >
              <Icon
                glyph={IconMap.Copy01}
                width={20}
                className="text-gray-600"
              />
            </Button>
          }
          sideOffset={4}
        >
          <p className="px-2 py-1 font-semibold text-xs">Copy link</p>
        </Tooltip>
      )}
    </div>
  );
};
