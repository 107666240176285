import { JSONContent } from '@tiptap/react';
import { BaseTeam } from './team';
import { User } from './user';

export enum SnippetType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  FILE = 'file',
}

export type MediaSnippetJSON = {
  url: string;
};

export enum SnippetsSharingAccess {
  PERSONAL = 'personal',
  TEAM = 'team',
}

export type SnippetCategory = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  owner: User;
  team: BaseTeam;
  image?: boolean;
  video?: boolean;
  file?: boolean;
  text?: boolean;
  order: number;
};

export type Snippet = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  owner: User;
  team: BaseTeam;
  type: SnippetType;
  category: SnippetCategory;
  content: JSONContent | MediaSnippetJSON;
  htmlContent: string;
  isDraft: boolean | null;
  isActive: boolean | null;
  usedCount: number;
  sharingAccess: SnippetsSharingAccess;
  sequenceNumber: string;
};

export type SnippetCategoryWithSnippets = SnippetCategory & {
  snippets: Snippet[];
};
