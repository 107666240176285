import { actions, reducer } from './reducer';
import { createPageModalWorker as worker } from './worker';
import * as selectors from './selectors';
import * as sagas from './sagas';
export * from './types';

export const createPageModalModel = {
  actions,
  reducer,
  worker,
  selectors,
  sagas,
};
