import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import React from 'react';

type Props = {
  userName: string;
  userEmail: string;
};

export const BlockedPageBar: React.FC<Props> = ({ userEmail, userName }) => {
  return (
    <div className="flex items-center justify-center gap-2 bg-warning-50 border-b border-gray-200 h-13 min-h-13">
      <Icon glyph={IconMap.Lock01} width={20} className="text-warning-700" />
      <p className="text-sm text-warning-700 font-medium">
        This page is currently being edited by {userName} ({userEmail}). Please
        return later if you wish to make changes.
      </p>
    </div>
  );
};
