import { useMemo } from 'react';
import { GettingStartedStepItem } from '../ui/components';

type Props = {
  steps: GettingStartedStepItem[];
};

export const useProgress = ({ steps }: Props) => {
  const progress = useMemo(() => {
    const totalSteps = steps.filter((i) => !i.isComingSoon).length;
    const competedSteps = steps.filter((i) => i.isCompleted).length;

    return Math.floor((competedSteps / totalSteps) * 100);
  }, [steps]);

  const isFinished = progress === 100;

  return { progress, isFinished };
};
