import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import { FeaturedIcon } from './FeaturedIcon';
import { formatFileSizeToHuman } from '../lib';

type IProps = {
  displayName: string;
  isRemoving: boolean;
  isUploading: boolean;
  uploadProgress: number;
  photoUrl?: string;
  onDeletePhoto: () => void;
  onChange: (file?: File) => void;
  onCancelUploadPhoto: () => void;
  hideIconIfEmpty?: boolean;
};

export const ALLOWED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const UploadFile: FC<IProps> = ({
  displayName,
  photoUrl,
  isRemoving,
  isUploading,
  uploadProgress,
  onDeletePhoto,
  onChange,
  onCancelUploadPhoto,
  hideIconIfEmpty,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const isUploadProgressEq100 = uploadProgress === 100;

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    setFile(file);
    onChange(file);
  };

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(true);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const file = e.dataTransfer?.files[0];
      setFile(file);

      return onChange(file);
    };

    document.body.addEventListener('dragover', enterListener);
    document.body.addEventListener('dragleave', leaveListener);
    document.body.addEventListener('drop', dropListener, {});
    return () => {
      document.body.removeEventListener('dragleave', leaveListener);
      document.body.removeEventListener('dragover', enterListener);
      document.body.removeEventListener('drop', dropListener);
    };
  }, [onChange]);

  return (
    <div className="flex gap-x-5 h-24">
      {!(hideIconIfEmpty && !photoUrl) && (
        <div className="flex-shrink-0 flex flex-col items-center justify-between gap-y-3">
          <Avatar
            displayName={displayName}
            src={photoUrl}
            size="2xl"
            theme="secondary-rect"
          />

          {photoUrl && (
            <Button
              size="sm"
              type="button"
              variant="icon"
              color="link"
              disabled={isRemoving || isUploading}
              title="Delete Photo"
              onClick={onDeletePhoto}
              className="!w-5 !h-5 !min-w-5 !min-h-5"
            >
              <Icon glyph={IconMap.Trash} />
            </Button>
          )}
        </div>
      )}
      <div className="flex grow-1 min-w-0">
        {isUploading ? (
          <div className="flex grow-1 min-w-0 gap-x-3.5 p-4 rounded-xl border border-gray-200 bg-base-white">
            <FeaturedIcon
              size="sm"
              icon={IconMap.File04}
              classNames="flex-shrink-0"
            />
            <div className="flex flex-col grow-1 min-w-0 gap-y-2.5">
              <div>
                <div className="flex justify-between">
                  <p className="text-gray-700 text-sm font-medium truncate">
                    {file?.name}
                  </p>
                  <div
                    onClick={
                      isUploadProgressEq100 ? undefined : onCancelUploadPhoto
                    }
                    className={classNames('flex-shrink-0', {
                      'text-gray-500 cursor-pointer': !isUploadProgressEq100,
                    })}
                  >
                    <Icon
                      glyph={
                        isUploadProgressEq100
                          ? IconMap.CheckCircleGreen
                          : IconMap.Trash
                      }
                    />
                  </div>
                </div>
                <div className="text-gray-600 text-sm">
                  {formatFileSizeToHuman(file?.size ?? 0)}{' '}
                </div>
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="grow-1 bg-gray-200 rounded-lg relative h-2">
                  <div
                    className="absolute top-0 left-0 h-full bg-primary-600 rounded-lg"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <div className="shrink-0 text-gray-700 text-sm font-medium">
                  {uploadProgress}%
                </div>
              </div>
            </div>
          </div>
        ) : (
          <label
            className={classNames(
              'border rounded-xl py-3 px-6 hover:border-primary-600 flex items-center flex-col grow-1 text-gray-600 font-normal text-sm justify-center cursor-pointer',
              {
                'border-primary-600': isDragging,
                'border-gray-200': !isDragging,
              }
            )}
          >
            <span>
              <span className="text-primary-700 font-medium hover:text-primary-600 active:text-primary-500 hover:cursor-pointer">
                Click to upload
              </span>{' '}
              or drag and drop
            </span>
            <span className="mb-1.5">
              <span className="text-xs font-normal text-gray-600">
                PNG or JPG (max. 5 MB)
              </span>
              <input
                accept={ALLOWED_FILE_TYPES.join(',')}
                type="file"
                onChange={onChangeFile}
                className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
              />
            </span>
          </label>
        )}
      </div>
    </div>
  );
};
