import React, { FC, ReactNode } from 'react';

type IProps = {
  label: string;
  children: ReactNode;
};

export const FormField: FC<IProps> = ({ children, label }) => {
  return (
    <div className="flex align-top gap-8 sm:flex-col sm:gap-1.5">
      <div className="font-medium text-sm text-gray-700 w-61 sm:w-auto">
        {label}
      </div>
      <div className="w-107 sm:w-auto">{children}</div>
    </div>
  );
};
