import { FC } from 'react';
import { Snippet } from '@distribute/shared/types';
import { TextSnippetView } from '../SnippetItemTextPreview';

type Props = {
  snippet: Snippet;
};

export const TextSnippet: FC<{ snippet: Snippet }> = ({ snippet }) => (
  <div className="w-55 border border-dashed border-base-white h-32 p-0.5 rounded-2.5 overflow-hidden">
    <div className="relative w-full h-full overflow-hidden rounded-lg bg-base-white">
      <div
        style={{
          transform: `scale(0.22, 0.22)`,
          transformOrigin: '0 0',
        }}
        className="!pt-0 absolute w-212 left-4 md:left-5 top-4 md:top-5 h-full !overflow-visible"
      >
        <TextSnippetView snippet={snippet} />
      </div>
      <div className="absolute w-full h-full bg-gradient-to-r from-transparent to-base-white" />
    </div>
  </div>
);
