import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import {
  TeamOnboardingStatus,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { Icon, Link } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { StepsIndicator } from './StepsIndicator';
import { environment } from '../../../../environments/environment';

type IProps = {
  step: UserOnboardingStatus | TeamOnboardingStatus;
  children: ReactNode;
  imageBlockBgSrc?: string;
  imageBlockSrc?: string;
  imageBlockClassName?: string;
  customImageBlock?: ReactNode;
};

export const OnboardingWrapper: FC<IProps> = ({
  step,
  children,
  imageBlockBgSrc,
  imageBlockSrc,
  imageBlockClassName,
  customImageBlock,
}) => {
  return (
    <div className="flex min-h-screen">
      <div className="flex flex-col justify-between w-1/2 bg-base-white px-8 sm:w-auto sm:px-4 sm:grow-1">
        <div>
          <div className="py-8">
            <Link
              target="_blank"
              rel="noreferrer"
              href={environment.landingUrl}
              size="medium"
            >
              <Icon glyph={IconMap.DistributeLogo} />
            </Link>
          </div>
          <div className="flex justify-center py-8">
            <div className="grow-1 max-w-140">{children}</div>
          </div>
        </div>
        <StepsIndicator step={step} />
      </div>
      <div className="flex relative w-1/2 overflow-hidden sm:hidden">
        {customImageBlock ?? (
          <>
            <img
              className="absolute left-0 top-0 -z-10 w-full h-full object-cover object-right-top"
              src={imageBlockBgSrc}
              alt="Onboarding bg"
            />
            <div className="flex items-center px-15 relative">
              <img
                className={cn('w-full object-contain', imageBlockClassName)}
                src={imageBlockSrc}
                alt="Onboarding"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
