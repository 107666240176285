import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { TemplatePreviewRaw } from '../../../../features/templates/ui/components';
import { IconMap } from '../../../..//shared/sprite';
import { Avatar, Button, Icon } from '../../../../shared/ui';
import classNames from 'classnames';
import { Device } from '../../types';

type Props = {
  template: TemplateExtended;
  onChoose: () => void;
  onPreview: () => void;
  searchQuery?: string;
  index: number;
  device?: Device;
  size: {
    width: number;
    height: number;
    colGap: number;
    rowGap: number;
  };
};

export const TemplateItem = ({
  template,
  size,
  device,
  onChoose,
  onPreview,
}: Props) => {
  const { width, height, rowGap } = size;

  return (
    <div
      className={`gap-${rowGap / 4} flex flex-col gap-4`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div className="h-[260px] group relative rounded-xl border border-gray-200 bg-base-white">
        <TemplatePreviewRaw
          style={{
            transform: `scale(0.22, 0.22)`,
            transformOrigin: '0 0',
          }}
          template={template}
          className="!pt-0 absolute w-212 left-4 md:left-5 top-4 md:top-5 h-full !overflow-visible"
        />
        <div className="absolute hidden group-hover:flex rounded-lg bg-base-white-90 w-full h-full flex-col gap-2 justify-center items-center">
          <Button
            onClick={onChoose}
            type="button"
            variant="text"
            color="primary"
          >
            Use Template
          </Button>
          {device !== 'mobile' && (
            <Button
              onClick={onPreview}
              type="button"
              variant="text"
              color="secondary"
            >
              Preview
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1 flex-1">
        <div className="flex-1 flex-col flex gap-1">
          <div className="flex flex-row gap-2 font-semibold text-md">
            {template.content.icon && (
              <span>
                {String.fromCodePoint(
                  ...template.content.icon
                    .split('-')
                    .map((code) => Number(`0x${code}`))
                )}
              </span>
            )}
            <span>{template.content.title ?? 'Untitled'}</span>
          </div>
          <div className="text-gray-700 text-sm">{template.description}</div>
        </div>
        <div>
          {template.isDistribute ? (
            <div className="flex flex-row gap-1 bg-gray-100 items-center px-2 rounded-md w-fit-content">
              <Icon
                glyph={IconMap.DistributeLogoLetter}
                width={16}
                height={16}
              />
              <span>Distribute template</span>
            </div>
          ) : (
            <div className={classNames('flex flex-row gap-3')}>
              <Avatar
                size={'xs'}
                src={template.owner.photoUrl}
                displayName={template.owner.displayName}
              />
              <span>{template.owner.displayName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
