import { Dispatch, createContext } from 'react';

import { INITIAL_STATE, Actions } from './state';

import { PlayerEntry, PlayerSettings } from '../types';
import { Size } from '../config';

export const PlayerContext = createContext<{
  state: typeof INITIAL_STATE;
  config: PlayerEntry & PlayerSettings;
  dispatch: Dispatch<Actions>;
  size: (typeof Size)[keyof typeof Size];
} | null>(null);
