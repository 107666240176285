import { FC, RefObject } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import { usePlayer } from '../../lib';
import { ActionKind } from '../../provider';
import { Icon, Icons } from '../../icons';
import { PlayerButton } from './PlayerControls/PlayerButton';

type Props = {
  playerRef: RefObject<ReactPlayer>;
};

export const PlayerOverlay: FC<Props> = ({ playerRef }) => {
  const { state, dispatch, size } = usePlayer();

  const { playing, finish } = state;

  const handleToggleClick = () => {
    dispatch({ type: ActionKind.TOGGLE_PLAY });
  };

  const handleRetryClick = () => {
    playerRef.current?.seekTo(0);

    dispatch({ type: ActionKind.SEEK, payload: 0 });
    dispatch({ type: ActionKind.START });
    dispatch({ type: ActionKind.FINISH, payload: false });
  };

  return (
    <div
      onClick={finish ? undefined : handleToggleClick}
      className={cn(
        'absolute inset-0 flex flex-col gap-y-4 items-center justify-center',
        {
          'bg-base-black/50': !playing,
        }
      )}
    >
      {finish && (
        <>
          <PlayerOverlayWatch onClick={handleRetryClick} />
          {size === 'md' && <PlayerOverlayDownload />}
        </>
      )}
    </div>
  );
};

const PlayerOverlayWatch: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { state } = usePlayer();
  const { fullscreen } = state;

  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        'rounded-[100px] backdrop-blur-[5px] bg-base-white/25 cursor-pointer flex flex-nowrap items-center gap-x-2 text-base-white font-semibold',
        {
          'text-xl py-3 px-6': fullscreen,
          'text-md py-2 px-4': !fullscreen,
        }
      )}
    >
      <Icon svg={Icons.Retry} size={fullscreen ? 'sm' : 'xs'} />
      <span>Watch again?</span>
    </button>
  );
};

const chromeUrl =
  'https://chromewebstore.google.com/u/0/detail/distribute-screen-recorde/mmfiaikmjhpggojgalplfnlplbkglbjc';

const PlayerOverlayDownload: FC = () => {
  const { state } = usePlayer();
  const { fullscreen } = state;

  const onClick = () => window.open(chromeUrl, '_blank');

  return (
    <div
      className={cn(
        'p-3 rounded-xl backdrop-blur-[5px] bg-base-white/25 flex flex-col gap-y-3',
        {
          'max-w-[280px]': fullscreen,
          'max-w-[230px]': !fullscreen,
        }
      )}
    >
      <span
        className={cn('text-base-white font-medium', {
          'text-md': fullscreen,
          'text-sm': !fullscreen,
        })}
      >
        Want to record your own video? Add our muy bueno extensionto get
        started!
      </span>
      <PlayerButton
        size={fullscreen ? 'md1' : 'sm1'}
        variant="secondary"
        onClick={onClick}
      >
        <Icon svg={Icons.Chrome} size={fullscreen ? 'sm' : 'xs'} />
        <span>Add to Chrome</span>
      </PlayerButton>
    </div>
  );
};
