import { IconMap } from '../../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../../shared/ui';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { GmailBlockElement } from './GmailBlockElement';
import { pagesModel } from '../../../../../features/pages';
import {
  PAGE_COVER_DEFAULT_COLOR,
  PAGE_COVER_DEFAULT_URL,
} from '../../../../../shared/constants';

type Props = {
  personName: string;
};

export const PersonalizedGmailBlock: React.FC<Props> = ({ personName }) => {
  const dispatch = useDispatch();
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [wasCopied, setCopied] = useState(false);

  const handleCopy = () => {
    const node = blockRef.current;
    if (!node) return;

    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection?.removeAllRanges();
      selection?.addRange(range);
      document.execCommand('copy');
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } else {
      console.warn('Could not select text in node: Unsupported browser.');
    }
  };

  const handleEdit = () => {
    dispatch(editorSidebarModel.actions.setDistributeEditCoverPanelOpen(true));
  };

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const personalizedLink = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLink
  );

  const personalizedLinkTitle = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkTitle
  );

  const color = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkColor
  );

  const coverUrl = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkCover
  );

  const isNoLogo = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkNoLogo
  );

  const handleReset = () => {
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkTitle(
        currentPage.content.title ?? ''
      )
    );
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkColor(
        PAGE_COVER_DEFAULT_COLOR
      )
    );
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkCover(
        PAGE_COVER_DEFAULT_URL
      )
    );
    dispatch(editorSidebarModel.actions.setPersonalizedLinkNoLogo(false));

    dispatch(
      editorSidebarModel.actions.updatePersonalizedCover({
        textColor: PAGE_COVER_DEFAULT_COLOR,
        imageUrl: PAGE_COVER_DEFAULT_URL,
        isNoDistributeLogo: false,
      })
    );
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <Icon glyph={IconMap.Mail02} width={20} className="text-gray-700" />
        <p className="text-gray-900 font-semibold">
          Personalized block for Gmail
        </p>
      </div>
      <p className="my-2 text-sm text-gray-700">
        Please copy and share this link with the designated person. You can
        track their actions in the leads section.
      </p>
      <div className="flex items-center justify-between mb-2">
        <p className="text-sm text-gray-500 font-semibold">Preview</p>
        <Button
          iconLeftName={IconMap.RefreshCcw05}
          iconLeftWidth={20}
          color="transparent"
          variant="icon-text"
          size="sm"
          onClick={handleReset}
        >
          Reset cover
        </Button>
      </div>
      <GmailBlockElement
        personName={personName}
        title={
          personalizedLinkTitle !== null
            ? personalizedLinkTitle
            : currentPage.content.title ?? ''
        }
        ref={blockRef}
        link={personalizedLink}
        color={color}
        coverUrl={coverUrl}
        isNoLogo={isNoLogo}
      />

      <div className="flex justify-between mt-4">
        <Button
          variant="icon-text"
          color="secondary"
          onClick={handleEdit}
          className="!px-4 !text-sm !font-semibold"
          iconLeftName={IconMap.Edit02}
          iconLeftWidth={20}
          size="md"
        >
          Edit cover
        </Button>
        <Tooltip
          trigger={
            <Button
              variant="icon-text"
              color="secondary"
              onClick={handleCopy}
              className="!px-4 !text-sm !font-semibold"
              iconLeftName={IconMap.Gmail2}
              iconLeftWidth={20}
              size="md"
            >
              Copy for Gmail
            </Button>
          }
          isOpen={wasCopied}
        >
          <p className="px-2 py-1 font-semibold text-xs">Copied!</p>
        </Tooltip>
      </div>
    </div>
  );
};
