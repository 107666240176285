import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel, useTeamPermissions } from '../../../teams';
import { teamBrandStylesModel } from '../../model';
import { Button, FormField } from '../../../../shared/ui';
import { FontOption, FontsSelector } from '../../../fonts-selector';
import { BrandColorPicker } from '../../../page-style';
import classNames from 'classnames';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';

export const ChangeBrandStylesForm: FC = () => {
  const dispatch = useDispatch();
  const { id, headingFontFamily, paragraphFontFamily, brandColor } =
    useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isUpdatingBrand = useSelector(
    teamBrandStylesModel.selectors.selectIsUpdatingBrandStyles
  );
  const [fontValue, setFontValue] = useState({
    headingFontFamily,
    paragraphFontFamily,
  });
  const [brandColorValue, setBrandColorValue] = useState(brandColor);
  const { isCanEdit } = useTeamPermissions();

  const handleChangeFont = useCallback(
    (fontOption: FontOption, fontId: string) => {
      setFontValue((data) => ({ ...data, [fontOption]: fontId }));
    },
    []
  );

  const handleSaveBrand = () => {
    dispatch(
      teamBrandStylesModel.actions.updateBrandStyles({
        teamId: id,
        brandColor: brandColorValue,
        headingFontFamily: fontValue.headingFontFamily,
        paragraphFontFamily: fontValue.paragraphFontFamily,
      })
    );
  };
  const isBrandColorSame = brandColorValue[500] === brandColor[500];

  const isFormChanged =
    fontValue.headingFontFamily !== headingFontFamily ||
    fontValue.paragraphFontFamily !== paragraphFontFamily ||
    !isBrandColorSame;

  useConfirmWindowClose(isFormChanged, 'changeTeamBrand');

  return (
    <div className="flex flex-col gap-y-5">
      <FormField label="Brand color">
        <BrandColorPicker
          colorValue={brandColorValue[500]}
          onChangeBrandColor={setBrandColorValue}
          side="bottom"
          align="start"
          isDisabled={!isCanEdit}
          triggerComponent={
            <div
              className={classNames(
                'flex items-center px-3.5 py-2.5 text-base text-gray-900 bg-base-white border rounded-lg border-gray-300 h-11',
                { '!bg-gray-50 !text-gray-500': !isCanEdit }
              )}
            >
              <span
                className="w-3.75 h-3.75 mr-2.625 rounded-sm"
                style={{ backgroundColor: brandColorValue[500] }}
              />
              {brandColorValue[500]}
            </div>
          }
        />
      </FormField>
      <FormField label="Brand heading font">
        <FontsSelector
          isDisabled={!isCanEdit}
          value={fontValue.headingFontFamily}
          fontOption="headingFontFamily"
          onChange={handleChangeFont}
          isCustomTriggerComponent={false}
        />
      </FormField>
      <FormField label="Brand paragraph font">
        <FontsSelector
          isDisabled={!isCanEdit}
          value={fontValue.paragraphFontFamily}
          fontOption="paragraphFontFamily"
          onChange={handleChangeFont}
          isCustomTriggerComponent={false}
        />
      </FormField>
      {isCanEdit && (
        <Button
          variant="text"
          color="primary"
          disabled={isUpdatingBrand || !isFormChanged}
          loading={isUpdatingBrand}
          type="submit"
          className="self-start sm:w-full"
          onClick={handleSaveBrand}
        >
          Save
        </Button>
      )}
    </div>
  );
};
