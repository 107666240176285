import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AlertBanner } from '..';
import { teamsModel } from '../../../../teams';
import { getPromoCodeDiscountAmount } from '../../../lib';

export const AlertBannerOnboardingPromoCodeAvailable: FC = () => {
  const { subscriptionOnboardingPromoCode } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  if (!subscriptionOnboardingPromoCode) return null;

  return (
    <AlertBanner
      theme="warning"
      title={`Want to get paid plan with ${getPromoCodeDiscountAmount(
        subscriptionOnboardingPromoCode
      )} discount? 😎`}
      description={`You has been downgraded to Free plan, but you still have a chance to return to Pro or Team plan with ${getPromoCodeDiscountAmount(
        subscriptionOnboardingPromoCode
      )} discount til ${moment(
        subscriptionOnboardingPromoCode?.expiresAt
      ).format('MMMM D, YYYY')}. Don't miss your chance!`}
    />
  );
};
