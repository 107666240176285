import parse, { Element } from 'html-react-parser';

export const parseHtml = (html: string) => {
  return parse(html, {
    replace: (domNode) => {
      const element = domNode as Element;
      if (element.tagName === 'customlink') {
        return <a href={element.attribs.href || ''}>{element.attribs.text}</a>;
      }

      return domNode;
    },
  });
};
