import React, { FC } from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from './Icon';

type Size = 'lg' | 'sm';

type Theme = 'primary';

type Props = {
  icon: SpriteGlyph;
  classNames?: string;
  size?: Size;
  theme?: 'primary';
};

const iconSize: Record<Size, number> = {
  lg: 24,
  sm: 16,
};

const classNameBySize: Record<Size, string> = {
  lg: 'w-12 h-12 border-8',
  sm: 'w-8 h-8 border-4',
};

const classNameByTheme: Record<Theme, string> = {
  primary: 'bg-primary-100 text-primary-600',
};

export const FeaturedIcon: FC<Props> = ({
  icon,
  classNames,
  size = 'lg',
  theme = 'primary',
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-full border-primary-50',
        classNameBySize[size],
        classNameByTheme[theme],
        classNames
      )}
    >
      <Icon glyph={icon} width={iconSize[size]} />
    </div>
  );
};
