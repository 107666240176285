import { call, cancelled, put, take } from 'redux-saga/effects';
import {
  APP_HOST,
  APP_ORIGIN,
  LINKEDIN_CLIENT_ID,
  PROTOCOL,
} from '../../../../../shared/config';
import { v4 } from 'uuid';

import { END, eventChannel } from 'redux-saga';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { fetchLinkedinAuthTokenBasedOnCode } from './fetchLinkedinAuthTokenBasedOnCode';
import { socialModel } from '../..';
import { openOauthSignupPopup } from '../../../../../utils/openOauthSignupPopup';

const scope = 'openid profile w_member_social email';
const linkedinCallbackUrl = `${PROTOCOL}${APP_HOST}/linkedin-callback`;

export function* connectToLinkedin(): any {
  yield put(socialModel.actions.setIsConnectingToLinkedin(true));
  const state = v4();

  const signUpPopup = openOauthSignupPopup(
    'https://www.linkedin.com/oauth/v2/authorization',
    {
      response_type: 'code',
      client_id: LINKEDIN_CLIENT_ID,
      redirect_uri: linkedinCallbackUrl,
      state,
      scope,
    }
  );

  const callbackUrlChannel = yield call(
    checkConnectionModalForAuthCode,
    signUpPopup
  );

  let callbackUrl = '';

  try {
    while (!callbackUrl.length) {
      callbackUrl = yield take(callbackUrlChannel);
    }
  } finally {
    if (yield cancelled()) {
      callbackUrlChannel.close();
    }
  }

  yield call(fetchLinkedinAuthTokenBasedOnCode, callbackUrl, state);

  yield put(
    snackbarModel.actions.addNotificationAction(
      createNotification('success', 'Successfully connected to LinkedIn')
    )
  );
  yield put(socialModel.actions.setIsConnectingToLinkedin(false));
}

function checkConnectionModalForAuthCode(signUpPopup: Window | null) {
  return eventChannel((emitter) => {
    const checkSignUpPopupInterval = setInterval(() => {
      if (!signUpPopup || signUpPopup?.closed) {
        emitter(END);
      }
      try {
        if (signUpPopup?.window.location?.href.includes(APP_ORIGIN)) {
          emitter(signUpPopup.window.location.search);
          signUpPopup.close();
          emitter(END);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}

      if (!signUpPopup || !signUpPopup.closed) {
        return;
      }
    }, 1000);

    return () => {
      clearInterval(checkSignUpPopupInterval);
    };
  });
}
