import { call, put } from 'redux-saga/effects';

import { DBUser, FirebaseUser, User } from '../../../shared/types';

import { authUserModel } from '../model';

import { getUserProfile } from '.';
import { authApi } from '../../../shared/api';
import { auth } from '../../../shared/config';

export function* setUserData(userData?: FirebaseUser) {
  const user: User = yield call(getUserProfile, userData);

  const dbUser: DBUser = yield call(authApi.getUserData);

  yield put(authUserModel.actions.setUser(user));
  yield put(authUserModel.actions.setDBUser(dbUser));

  const authProviders =
    auth.currentUser?.providerData.map((userInfo) => userInfo.providerId) ?? [];
  yield put(authUserModel.actions.setAuthProviders(authProviders));
}
