import { SnippetType } from '@distribute/shared/types';
import { IconMap } from '../../../../../../shared/sprite';
import { createTypesRegex } from '../../../../../../shared/lib';

export const snippetModalTitleByType = {
  [SnippetType.IMAGE]: {
    title: 'Image Snippet',
    icon: IconMap.Image05,
    name: 'image',
  },
  [SnippetType.VIDEO]: {
    title: 'Video Snippet',
    icon: IconMap.PlaySquare,
    name: 'video',
  },
  [SnippetType.FILE]: {
    title: 'File Snippet',
    icon: IconMap.BlankPageSquare,
    name: 'file',
  },
  [SnippetType.TEXT]: {
    title: 'Text Snippet',
    icon: IconMap.TypeSquare,
    name: 'file',
  },
};

export enum CreateSnippetTabsName {
  UPLOAD = 'upload',
  CONFIGURE = 'configure',
}

export enum AddingMethodEnum {
  UPLOAD = 'upload',
  URL = 'url',
}

export const addingMethodOptions = [
  {
    id: AddingMethodEnum.UPLOAD,
    icon: IconMap.UploadCloud02,
  },
  {
    id: AddingMethodEnum.URL,
    icon: IconMap.Link,
  },
];

export const addingOptionsLabelConfig = {
  [SnippetType.IMAGE]: {
    [AddingMethodEnum.UPLOAD]: 'Upload image',
    [AddingMethodEnum.URL]: 'Image URL',
  },
  [SnippetType.VIDEO]: {
    [AddingMethodEnum.UPLOAD]: 'Upload video',
    [AddingMethodEnum.URL]: 'Video URL',
  },
  [SnippetType.FILE]: {
    [AddingMethodEnum.UPLOAD]: 'Upload file',
    [AddingMethodEnum.URL]: 'File URL',
  },
};

const allowedTypes = {
  [SnippetType.IMAGE]: ['JPG', 'PNG', 'GIF', 'JPEG'],
  [SnippetType.VIDEO]: ['MP4', 'MOV', 'WEBM', 'OGG'],
  [SnippetType.FILE]: ['PDF'],
};

const regexpType = {
  [SnippetType.IMAGE]: 'image',
  [SnippetType.VIDEO]: 'video',
  [SnippetType.FILE]: 'application',
};

export const uploadSnippetConfig = {
  [SnippetType.IMAGE]: {
    allowedTypes: allowedTypes[SnippetType.IMAGE],
    acceptString: allowedTypes[SnippetType.IMAGE]
      .map((type) => `image/${type.toLowerCase()}`)
      .join(','),
    validationRegexp: createTypesRegex(
      allowedTypes[SnippetType.IMAGE],
      regexpType[SnippetType.IMAGE]
    ),
    maxSize: '5 MB',
  },

  [SnippetType.VIDEO]: {
    allowedTypes: allowedTypes[SnippetType.VIDEO],
    acceptString: allowedTypes[SnippetType.VIDEO]
      .map((type) => `video/${type.toLowerCase()}`)
      .join(','),
    validationRegexp: createTypesRegex(
      allowedTypes[SnippetType.VIDEO],
      regexpType[SnippetType.VIDEO]
    ),
    maxSize: '50 MB',
  },

  [SnippetType.FILE]: {
    allowedTypes: allowedTypes[SnippetType.FILE],
    acceptString: 'application/pdf',
    validationRegexp: createTypesRegex(
      allowedTypes[SnippetType.FILE],
      regexpType[SnippetType.FILE]
    ),
    maxSize: '5 MB',
  },
};
