import { put, select } from 'redux-saga/effects';
import { CreateUserPermissionResponseType } from '@distribute/shared/api-types/pages';
import { Page } from '@distribute/shared/types';
import { pagesModel } from '..';
import { pagesApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

import { actions } from '../reducer';

export function* createUserPermission({
  payload: { pageId, userEmails, role, cb },
}: ReturnType<typeof pagesModel.actions.createUserPermission>) {
  try {
    yield put(pagesModel.actions.setCreateUserPermissionIsLoading(true));
    const page: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    const userPermissions: CreateUserPermissionResponseType =
      yield pagesApi.createUserPermission(pageId, {
        userEmails,
        role,
      });

    yield put(
      pagesModel.actions.setCurrentPage({
        ...page,
        userPermissionToPages: [
          ...page.userPermissionToPages,
          ...userPermissions,
        ],
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your invitation to join the page has been sent successfully!'
        )
      )
    );
    cb();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          'The user permissions have not been created'
        )
      )
    );
  } finally {
    yield put(actions.setCreateUserPermissionIsLoading(false));
  }
}
