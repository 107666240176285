import { call } from 'redux-saga/effects';
import { GONG_REFRESH_TOKEN_STORAGE_KEY } from '../../../../shared/constants';
import { disconnectFromGong } from './disconnectFromGong';
import { gongApi } from '../../../../shared/api';
import { GONG_CLIENT_ID } from '../../../../shared/config';
import { GetGongAuthInfoType } from '../types';
import { setGongAuthData } from './helpers';

export function* refreshGongAccessToken() {
  yield;
  const refreshToken = localStorage.getItem(GONG_REFRESH_TOKEN_STORAGE_KEY);
  if (!refreshToken) {
    yield call(disconnectFromGong);
    throw new Error('Gong authorization data is missing');
  }
  const res: GetGongAuthInfoType = yield call(gongApi.refreshAccessToken, {
    clientId: GONG_CLIENT_ID,
    refreshToken,
  });

  yield call(setGongAuthData, res);
}
