import './ToggleList.css';
import { ToggleListExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    toggleListExtension: {
      toggleToggleList: () => ReturnType;
    };
  }
}

export const ToggleList = ToggleListExt.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      itemTypeName: 'toggleListItem',
    };
  },

  addCommands() {
    return {
      toggleToggleList: this.parent?.().toggleBulletList,
    };
  },
});
