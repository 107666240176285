import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Button, Input } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';
import { teamInfoSettingsModel } from '../../model';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

type DeleteTeamConfirmModalForm = {
  confirmText: string;
  userIdToTransferOwnership: string;
};

const CONFIRMATION_TEXT = 'DELETE TEAM';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
});

export const DeleteTeamConfirmModal: FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isRequestingDeleteTeam = useSelector(
    teamInfoSettingsModel.selectors.selectIsRequestingDeleteTeam
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeleteTeamConfirmModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = handleSubmit(() => {
    dispatch(
      teamInfoSettingsModel.actions.requestDeleteTeam({
        teamId: id,
        cb: onModalClose,
      })
    );
  });

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete a Team"
      actionButton={
        <Button
          onClick={handleFormSubmit}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          disabled={isRequestingDeleteTeam}
          loading={isRequestingDeleteTeam}
        >
          Delete Team
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        By deleting the team, all of its pages will be permanently removed, and
        users will be disbanded without the possibility of reversal. Are you
        sure you want to proceed?
      </div>
      <form onSubmit={handleFormSubmit}>
        <Input
          autoComplete="off"
          label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
          {...register('confirmText')}
          isError={!!errors.confirmText}
          type="text"
        />
      </form>
    </Modal>
  );
};
