import React, { FC, useMemo } from 'react';
import {
  Folder,
  UserPermissionToFolder,
  UserPermissionToFolderRole,
} from '@distribute/shared/types';
import { Avatar, Dropdown } from '../../../../shared/ui';
import { useFolderPermissions } from '../../hooks';

type IProps = {
  userPermission: UserPermissionToFolder;
  folder: Folder;
  onChangeUserPermission: (
    role: UserPermissionToFolderRole,
    userPermissionId: number
  ) => void;
  onDeleteUserPermission: (userPermissionId: number) => void;
};

export const UserPermissionRow: FC<IProps> = ({
  userPermission,
  folder,
  onChangeUserPermission,
  onDeleteUserPermission,
}) => {
  const folderPermissions = useFolderPermissions(folder);
  const options = useMemo(
    () =>
      [
        {
          id: UserPermissionToFolderRole.OWNER,
          label: 'Owner',
          isShow: folderPermissions.isCanTransferOwnership,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToFolderRole.OWNER,
              userPermission.id
            ),
        },
        {
          id: UserPermissionToFolderRole.EDITOR,
          label: 'Can Edit',
          isShow: true,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToFolderRole.EDITOR,
              userPermission.id
            ),
        },
        {
          id: UserPermissionToFolderRole.VIEWER,
          label: 'Can View',
          isShow: true,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToFolderRole.VIEWER,
              userPermission.id
            ),
        },
        {
          id: 'remove',
          label: 'Remove',
          isSeparatedFromTop: true,
          isShow: true,
          onClick: () => onDeleteUserPermission(userPermission.id),
        },
      ].filter((i) => i.isShow),
    [
      userPermission.id,
      folderPermissions.isCanTransferOwnership,
      onChangeUserPermission,
      onDeleteUserPermission,
    ]
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        <Avatar
          isUserAvatar
          displayName={userPermission.user.displayName}
          src={userPermission.user.photoUrl}
          size="md"
          className="shrink-0"
        />
        <span className="text-gray-700 text-sm font-medium truncate">
          {userPermission.user.displayName}
        </span>
      </div>
      {userPermission.role === UserPermissionToFolderRole.OWNER ? (
        <span className="text-sm font-semibold text-gray-600">Owner</span>
      ) : (
        <Dropdown
          isModalMode={false}
          listStyles="shadow-lg"
          items={options}
          currentItemId={userPermission.role}
          itemStyles="!w-32 !min-w-32"
          triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
        />
      )}
    </div>
  );
};
