import {
  TWITTER_TOKEN_SECRET_STORAGE_KEY,
  TWITTER_TOKEN_STORAGE_KEY,
} from '../../../../../shared/constants';
import { socialModel } from '../..';
import { call, put } from 'redux-saga/effects';
import { socialApi } from '../../../../../shared/api/axios/social';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';

export function* shareTwitterPost({
  payload: { text, file },
}: ReturnType<typeof socialModel.actions.shareTwitterPost>) {
  const formData = new FormData();

  formData.append('file', file as Blob);

  const twitterAccessToken = localStorage.getItem(TWITTER_TOKEN_STORAGE_KEY);
  const twitterAccessTokenSecret = localStorage.getItem(
    TWITTER_TOKEN_SECRET_STORAGE_KEY
  );

  if (!twitterAccessToken || !twitterAccessTokenSecret) {
    return;
  }

  try {
    yield call(
      socialApi.createTwitterPost,
      twitterAccessToken,
      twitterAccessTokenSecret,
      {
        requestData: formData,
        text,
      }
    );

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your post has been successfully shared to Twitter!'
        )
      )
    );
  } catch (_) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to share Twitter post')
      )
    );
  } finally {
    yield put(socialModel.actions.setIsSharingTwitterPost(false));
  }
}
