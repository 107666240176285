import {
  CreateCustomDomainResponseType,
  GetCustomDomainResponseType,
  VerifyCustomDomainResponseType,
} from '@distribute/shared/api-types/custom-domains';
import { api } from './instance';

export const customDomainsApi = {
  async getCustomDomainInfo(teamId: number) {
    const { data } = await api.get<GetCustomDomainResponseType>(
      `custom-domains/team/${teamId}`
    );
    return data;
  },

  async createCustomDomain(teamId: number, customDomain: string) {
    const { data } = await api.post<CreateCustomDomainResponseType>(
      `custom-domains/team/${teamId}`,
      {
        customDomain,
      }
    );
    return data;
  },

  async deleteCustomDomain(domainId: string, teamId: number) {
    const { data } = await api.delete(
      `custom-domains/${domainId}/team/${teamId}`
    );
    return data;
  },

  async activateCustomDomain(teamId: number, domainId: string) {
    const { data } = await api.post<VerifyCustomDomainResponseType>(
      `custom-domains/verify/${domainId}/team/${teamId}`
    );
    return data;
  },
};
