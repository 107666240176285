import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AICompletionAction,
  AIResponse,
  AIResponseError,
  AIStopCompletionAction,
  AIUpdateTextAction,
  GeneratePageAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isLoading: boolean;
  error?: AIResponseError;
  responses: AIResponse[];
  isAIInputRendered: boolean;
};

const initialState: State = {
  isLoading: false,
  error: undefined,
  responses: [],
  isAIInputRendered: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading;
    },
    setError: (
      state,
      { payload: error }: PayloadAction<AIResponseError | undefined>
    ) => {
      state.error = error;
    },
    setResponses: (
      state,
      { payload: responses }: PayloadAction<AIResponse[]>
    ) => {
      state.responses = responses;
    },
    setIsAIInputRendered: (
      state,
      { payload: isAIInputRendered }: PayloadAction<boolean>
    ) => {
      state.isAIInputRendered = isAIInputRendered;
    },
    updateResponseText: (
      state,
      { payload: { id, resultText } }: PayloadAction<AIUpdateTextAction>
    ) => {
      state.responses = state.responses.map((i) => {
        if (i.id === id) {
          i.resultText += resultText;
        }
        return i;
      });
    },
    clearFlow: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  startCompletion: createAction<AICompletionAction>('ai/startCompletion'),
  stopCompletion: createAction<AIStopCompletionAction>('ai/stopCompletion'),
  generatePageWithAI: createAction<GeneratePageAction>('ai/generatePageWithAI'),
  stopGeneratePageWithAI: createAction<AIStopCompletionAction>(
    'ai/stopGeneratePageWithAI'
  ),
};
