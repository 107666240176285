import { Categories } from 'emoji-picker-react';

export const categories = [
  {
    category: Categories.SUGGESTED,
    name: 'Frequently Used',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Smileys & People',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Animals & Nature',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Food & Drink',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Travel & Places',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Activities',
  },
  {
    category: Categories.OBJECTS,
    name: 'Objects',
  },
  {
    category: Categories.FLAGS,
    name: 'Flags',
  },
  {
    category: Categories.SYMBOLS,
    name: 'Symbols',
  },
];
