import { ClearbitCompanyInfo } from '../../shared/api';
import React from 'react';
import { Avatar } from '.';

type Props = {
  companyInfo: ClearbitCompanyInfo;
  handleClick: () => void;
};

export const CompanyRecord: React.FC<Props> = ({
  companyInfo,
  handleClick,
}) => {
  return (
    <li
      className="rounded-xl p-2 flex items-center gap-4 hover:bg-gray-100 cursor-pointer transition-all"
      onClick={handleClick}
    >
      <Avatar
        src={companyInfo.logo ?? ''}
        displayName={companyInfo.name}
        theme="rect"
      />
      <p className="font-medium text-gray-700 grow-1">{companyInfo.name}</p>
      <p className="text-gray-700">{companyInfo.domain ?? 'unknown website'}</p>
    </li>
  );
};
