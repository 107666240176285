import { snippetsModel } from '..';
import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import {
  Snippet,
  SnippetCategoryWithSnippets,
  SnippetsSharingAccess,
} from '@distribute/shared/types';

export function* deleteSnippet({
  payload: { id, sharingAccess, isDraft, callback },
}: ReturnType<typeof snippetsModel.actions.deleteSnippet>) {
  try {
    yield put(snippetsModel.actions.setIsDeleteSnippetLoading(true));
    yield call(snippetsApi.deleteSnippet, id);

    if (isDraft) {
      const draftSnippets: Snippet[] = yield select(
        snippetsModel.selectors.selectDraftSnippets
      );

      const newDraftSnippets = draftSnippets.filter((s) => s.id !== id);

      yield put(snippetsModel.actions.setDraftSnippets(newDraftSnippets));
    } else if (sharingAccess === SnippetsSharingAccess.PERSONAL) {
      const personalSnippets: Snippet[] = yield select(
        snippetsModel.selectors.selectPersonalSnippets
      );

      const newPersonalSnippets = personalSnippets.filter((s) => s.id !== id);

      yield put(snippetsModel.actions.setPersonalSnippets(newPersonalSnippets));
    } else {
      const categories: SnippetCategoryWithSnippets[] = yield select(
        snippetsModel.selectors.selectCategories
      );

      const categoryWithDeletedSnippet = categories.find((c) =>
        c.snippets.some((s) => s.id === id)
      );

      if (categoryWithDeletedSnippet) {
        const index = categories.indexOf(categoryWithDeletedSnippet);

        const newCategories = [...categories];

        newCategories[index] = {
          ...newCategories[index],
          snippets: categoryWithDeletedSnippet.snippets.filter(
            (s) => s.id !== id
          ),
        };

        yield put(snippetsModel.actions.setCategories(newCategories));
      }
    }
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The snippet has been deleted!')
      )
    );

    callback();
  } catch (err) {
    logger.error(err);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete the snippet')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsDeleteSnippetLoading(false));
  }
}
