import { call, put } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import type { RT } from '../../../../shared/types';
import { VideoRecordLoadingStatus } from '../types';

import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';

import { createNotification, snackbarModel } from '../../../snackbar';
import { videoRecordsApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { redirect } from '../../../../entities/history';

type Params = {
  prefix: string;
  isPublic: boolean;
};

export function* setRecordData({ prefix, isPublic }: Params) {
  try {
    yield put(actions.setRecordStatus(VideoRecordLoadingStatus.Pending));

    const record: RT<typeof videoRecordsApi.get> = yield call(
      videoRecordsApi.get,
      { prefix, isPublic }
    );

    yield put(actions.setRecord(record));
    yield put(actions.setRecordStatus(VideoRecordLoadingStatus.Success));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.record.getError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
    yield call(redirect.toRoot);
    yield put(actions.setRecordStatus(VideoRecordLoadingStatus.Error));
  }
}
