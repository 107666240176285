import { useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import {
  RichTextEditorDropdown,
  RichTextEditorDropdownItemType,
} from './rich-text-editor-dropdown';

export interface IProps {
  editor: Editor;
}

export const TiptapCellOptionsDropdown = ({ editor }: IProps) => {
  const tableRowsCellsCount = useMemo(() => {
    let tableNode: Node | null = null;

    editor.view.state.tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;

      editor.view.state.doc.nodesBetween(from, to, (node) => {
        if (node.type.name === 'table') {
          tableNode = node;
        }
      });
    });

    if (tableNode !== null) {
      const cellsCount: number[] = [];

      (tableNode as Node).forEach((tr) => cellsCount.push(tr.childCount));

      return {
        rows: (tableNode as Node).childCount,
        cells: Math.max(...cellsCount),
      };
    }

    return { rows: 0, cells: 0 };
  }, [editor.view.state]);

  const items = useMemo<RichTextEditorDropdownItemType[]>(
    () => [
      {
        id: 'insertColumnRight',
        value: 'Insert column right',
        iconName: IconMap.AddColumn,
        onClick: () => {
          editor.commands.addColumnAfter();
        },
      },
      {
        id: 'insertRowBelow',
        value: 'Insert row below',
        iconName: IconMap.AddRow,
        onClick: () => {
          editor.commands.addRowAfter();
          editor.view.dispatch(
            editor.view.state.tr.setMeta('isNeedUpdateTableMenuPosition', true)
          );
        },
      },
      {
        id: 'mergeCells',
        value: 'Merge cells',
        iconName: IconMap.MergeCells,
        isSeparatedFromTop: true,
        isDisabled: !editor.can().mergeCells(),
        onClick: () => {
          editor.commands.mergeCells();
        },
      },
      {
        id: 'splitCell',
        value: 'Split cell(s)',
        iconName: IconMap.SplitCell,
        isDisabled: !editor.can().splitCell(),
        onClick: () => {
          editor.commands.splitCell();
        },
      },
      {
        id: 'removeColumn',
        value: 'Delete column(s)',
        iconName: IconMap.RemoveColumn,
        isSeparatedFromTop: true,
        isDisabled: tableRowsCellsCount.cells < 2,
        onClick: () => {
          editor.commands.deleteColumn();
        },
      },
      {
        id: 'removeRow',
        value: 'Delete row(s)',
        iconName: IconMap.RemoveRow,
        isDisabled: tableRowsCellsCount.rows < 2,
        onClick: () => {
          editor.commands.deleteRowAndRefocus();
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, editor.state, tableRowsCellsCount]
  );

  return (
    <RichTextEditorDropdown
      isShowIconForSelectedItem
      items={items}
      selectedItemId={''}
      dropdownStyles="min-w-50"
      triggerComponent={
        <>
          <Icon className="w-6 h-6" width={24} glyph={IconMap.Table} />
          <span>Cell options</span>
        </>
      }
    />
  );
};
