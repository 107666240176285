import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import moment from 'moment';
import {
  PlanName,
  SubscriptionPlanName,
  SubscriptionPromoCode,
} from '@distribute/shared/types';
import { SubscriptionPlan } from '../../types';
import { useCurrentPlan, useSubscriptionInfo } from '../../hooks';
import { Button, ButtonColor, Icon } from '../../../../shared/ui';
import { FREE_SUBSCRIPTION_PLAN_ID } from '../../config';
import { IconMap } from '../../../../shared/sprite';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../teams';
import {
  checkIsPlanGtCurrentPlan,
  getPromoCodeDiscountAmount,
} from '../../lib';

type IProps = SubscriptionPlan & {
  onUpdate: (planId: SubscriptionPlanName) => void;
};

type ButtonOptions = {
  text: string;
  theme: ButtonColor;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const ItemPlan: FC<IProps> = ({
  features,
  id,
  name,
  price,
  onUpdate,
}) => {
  const currentPlan = useCurrentPlan();
  const { isShowAlertBannerOnboardingPromoCodeAvailable } =
    useSubscriptionInfo();
  const { subscriptionOnboardingPromoCode, stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isCurrentPlan = currentPlan.id === id;

  const buttonOptions = useMemo<ButtonOptions>(() => {
    if (isCurrentPlan) {
      return { text: 'Current Plan', theme: 'secondary', isDisabled: true };
    }

    return {
      text: checkIsPlanGtCurrentPlan(id, currentPlan.id)
        ? 'Upgrade Plan'
        : 'Downgrade Plan',
      theme: 'primary',
      isDisabled: !!stripeSubscription?.cancelAt,
      onClick: () => onUpdate(id),
    };
  }, [currentPlan, isCurrentPlan, id, stripeSubscription, onUpdate]);

  return (
    <div
      className={cn(
        'flex flex-col basis-84 min-w-0 bg-base-white border border-gray-200 rounded-2xl sm:basis-full',
        { 'border-2 border-primary-600': isCurrentPlan }
      )}
    >
      <div className="flex flex-col gap-4 p-8">
        <span className="text-lg font-semibold text-gray-900">{name}</span>
        <div className="flex flex-wrap items-end gap-1">
          <span className="text-display-md font-medium font-display -tracking-2 text-gray-900">
            ${price.month}
          </span>
          <span className="text-md text-gray-600 mb-1">
            {id === PlanName.TEAM ? 'per 5 members/month' : 'per member/month'}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <Button
            color={buttonOptions.theme}
            variant="text"
            disabled={buttonOptions.isDisabled}
            onClick={buttonOptions.onClick}
          >
            {buttonOptions.text}
          </Button>
          {isShowAlertBannerOnboardingPromoCodeAvailable &&
            id !== FREE_SUBSCRIPTION_PLAN_ID && (
              <div className="text-center rounded-lg bg-warning-50 px-4 py-2 text-sm text-warning-700">
                Get{' '}
                <span className="font-semibold">
                  {getPromoCodeDiscountAmount(
                    subscriptionOnboardingPromoCode as SubscriptionPromoCode
                  )}{' '}
                  discount
                </span>{' '}
                til{' '}
                {moment(subscriptionOnboardingPromoCode?.expiresAt).format(
                  'MMM D, YYYY'
                )}
              </div>
            )}
        </div>
      </div>
      <div className="h-0.25 bg-gray-200" />
      <div className="flex flex-col gap-4 p-8">
        <span className="text-sm font-semibold text-gray-600">Features</span>
        <div className="flex flex-col gap-4">
          {features.map((feature) => (
            <span
              key={feature.id}
              className="flex items-center gap-3 text-md text-gray-600"
            >
              <Icon width={24} height={24} glyph={IconMap.CheckCircleGreen} />
              {feature.text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
