import { put, select } from 'redux-saga/effects';
import { snippetsModel } from '..';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { getReorderedCategories } from '../../lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';

export function* reorderCategories({
  payload: { sourceIndex, destinationIndex },
}: ReturnType<typeof snippetsModel.actions.reorderCategories>) {
  const categories: SnippetCategoryWithSnippets[] = yield select(
    snippetsModel.selectors.selectCategories
  );
  const reorderedCategories = getReorderedCategories(
    categories,
    sourceIndex,
    destinationIndex
  );

  yield put(snippetsModel.actions.setCategories(reorderedCategories));

  try {
    yield snippetsApi.reorderCategories({
      categories: reorderedCategories,
    });
  } catch (e: unknown) {
    logger.error(e);
    yield put(snippetsModel.actions.setCategories(categories));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to reorder categories')
      )
    );
  }
}
