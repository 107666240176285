import { put, takeEvery } from 'redux-saga/effects';
import { resetPassword } from '../../../features/auth/reset-password';
import { authModel } from '../../../entities/auth-operation';
import { redirect, redirectActions } from '../../../entities/history';
import { profileSettingModel } from '../../../features/profile-settings';

export function* resetPasswordWorker() {
  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(
    profileSettingModel.actions.resetPasswordClick,
    resetPassword
  );
  yield takeEvery(redirectActions.toLoginClick, redirect.toLogin);
}
