import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { setSnippetsData } from '../../lib';

export function* replaceSnippet({
  payload: { snippetId, url, callback, updateOnlyNew = false },
}: ReturnType<typeof snippetsModel.actions.replaceSnippet>) {
  try {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(true));

    yield call(snippetsApi.replaceSnippet, snippetId, { url, updateOnlyNew });

    yield call(setSnippetsData);

    callback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to replace snippet')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(false));
  }
}
