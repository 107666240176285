import { SnippetsPermissions } from '../../../../../features/teams/lib';
import { LineActionMenuItem } from '../lib/types';

export const getMenuItems = ({
  baseMenuItems,
  currentMenuItems,
  snippetsPermissions,
  menuType,
}: {
  baseMenuItems: LineActionMenuItem[];
  currentMenuItems: LineActionMenuItem[];
  snippetsPermissions: SnippetsPermissions;
  menuType?: string;
}) => {
  if (!menuType) {
    return baseMenuItems;
  }

  if (menuType === 'snippet') {
    return !snippetsPermissions.isAllowToDetachSnippet
      ? baseMenuItems
      : [...baseMenuItems, ...currentMenuItems];
  }

  return [...currentMenuItems, ...baseMenuItems];
};
