import React, { useMemo } from 'react';

import { IconMap } from '../../sprite';
import { Icon } from '../Icon';
import { calculateRelativeLuminance } from './lib/luminance';
import { Tooltip } from '../Tooltip';

type Props = {
  color: string;
  onClick(color: string): void;
  active: boolean;
  name: string;
};

export const ColorListPickerItem: React.FC<Props> = ({
  color,
  onClick,
  active,
  name,
}) => {
  const luminance = useMemo(() => calculateRelativeLuminance(color), [color]);
  const colorClassName =
    luminance > 0.5 ? 'text-base-black' : 'text-base-white';

  const handleColorClick = () => {
    onClick(color);
  };

  return (
    <Tooltip
      hideArrow
      align="center"
      sideOffset={8}
      trigger={
        <div
          style={{ backgroundColor: color }}
          key={color}
          className="w-6 h-6 min-w-6 min-h-6 rounded-full flex items-center justify-center cursor-pointer border border-base-black-8 hover:shadow-bright-green"
          onClick={handleColorClick}
        >
          {active && (
            <Icon
              className={colorClassName}
              glyph={IconMap.ColorCheck}
              width={16}
            />
          )}
        </div>
      }
    >
      {name}
    </Tooltip>
  );
};
