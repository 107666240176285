import {
  LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE,
  LINKEDIN_TOKEN_STORAGE_KEY,
} from '../../../../../shared/constants';
import { GetLinkedinAuthInfoType } from './types';

export function setLinkedinAuthData(data: GetLinkedinAuthInfoType) {
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + data.expires_in);

  localStorage.setItem(LINKEDIN_TOKEN_STORAGE_KEY, data.access_token);
  localStorage.setItem(
    LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE,
    expirationDate.toString()
  );
}

export function removeLinkedinAuthData() {
  localStorage.removeItem(LINKEDIN_TOKEN_STORAGE_KEY);
  localStorage.removeItem(LINKEDIN_ACCESS_TOKEN_EXPIRATION_DATE);
}
