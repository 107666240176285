import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../../shared/ui';
import { teamsModel } from '../../../../teams';
import { teamMembersModel } from '../../../model';
import { InviteMembersForm } from './InviteMembersForm';

export const InviteMembersModal: FC = () => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isLoadingInviteTeamMembers = useSelector(
    teamMembersModel.selectors.selectIsLoadingInviteTeamMembers
  );
  const isShowInviteTeamMembersModal = useSelector(
    teamMembersModel.selectors.selectIsShowInviteTeamMembersModal
  );

  const handleCloseModal = () => {
    dispatch(teamMembersModel.actions.setIsShowInviteTeamMembersModal(false));
  };

  const handleSendInvitations = (emails: string[]) => {
    dispatch(
      teamMembersModel.actions.inviteTeamMembers({
        emails,
        teamId: currentTeam.id,
        cb: handleCloseModal,
      })
    );
  };

  return (
    <Modal
      isShowCloseIconInTitle
      isOpen={isShowInviteTeamMembersModal}
      onClose={handleCloseModal}
      title={`Invite to ${currentTeam.name}`}
      isShowCancelButton={false}
      className="w-150"
    >
      <InviteMembersForm
        isLoading={isLoadingInviteTeamMembers}
        onInviteMembers={handleSendInvitations}
      />
    </Modal>
  );
};
