import React, { FC } from 'react';
import cn from 'classnames';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

type Props = {
  keepOpenOnTrigger?: boolean;
  trigger: React.ReactNode;
  children: React.ReactNode;
  align?: 'center' | 'end' | 'start';
  alignOffset?: number;
  sideOffset?: number;
  hideArrow?: boolean;
  triggerClassNames?: string;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  className?: string;
  delayDuration?: number;
};
export const Tooltip: FC<Props> = ({
  keepOpenOnTrigger = false,
  trigger,
  children,
  align,
  alignOffset,
  sideOffset,
  hideArrow,
  triggerClassNames,
  isOpen,
  onOpenChange,
  className,
  delayDuration = 0,
}) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={delayDuration}
        open={isOpen}
        onOpenChange={onOpenChange}
        disableHoverableContent={true}
      >
        <TooltipPrimitive.TooltipTrigger
          asChild
          onClick={(event) => keepOpenOnTrigger && event.preventDefault()}
        >
          <div className={triggerClassNames}>{trigger}</div>
        </TooltipPrimitive.TooltipTrigger>
        <TooltipPrimitive.Content
          onPointerDownOutside={(e) => keepOpenOnTrigger && e.preventDefault()}
          className={cn(
            'rounded-lg bg-base-black text-base-white px-2 py-1 max-w-72 text-xs z-50',
            className
          )}
          align={align}
          alignOffset={alignOffset}
          sideOffset={sideOffset}
        >
          {children}
          {!hideArrow && <TooltipPrimitive.Arrow offset={4} />}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
