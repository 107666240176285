import { call, put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { videoRecordsApi } from '../../../../shared/api';
import { selectRecord, selectRecordToShare, selectRecords } from '../selectors';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';
import { authUserModel } from '../../../../entities/auth-user';
import {
  checkIsAllowToViewLikeMember,
  mapUpdateUserPermissionsToVideoRecords,
} from '../../lib';
import { refreshRecordDetail } from './refresh-record-detail';

export function* updateUserPermission(
  action: ReturnType<typeof actions.updateUserPermission>
) {
  const { payload } = action;
  const { userPermissionToVideoRecordId, ...data } = payload;

  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const {
      id: userId,
    }: RT<typeof authUserModel.selectors.selectUserWithError> = yield select(
      authUserModel.selectors.selectUserWithError
    );

    const record: RT<typeof selectRecord> = yield select(selectRecord);
    const records: RT<typeof selectRecords> = yield select(selectRecords);
    const recordToShare: RT<typeof selectRecordToShare> = yield select(
      selectRecordToShare
    );

    yield call(
      videoRecordsApi.updateUserPermission,
      recordToShare.id,
      userPermissionToVideoRecordId,
      data,
      {
        teamId: team.id,
      }
    );

    yield put(
      actions.setRecords(
        records.map((r) =>
          r.id === recordToShare.id
            ? {
                ...r,
                ...mapUpdateUserPermissionsToVideoRecords({
                  userPermissionToVideoRecords: r.userPermissionToVideoRecords,
                  role: data.role,
                  id: userPermissionToVideoRecordId,
                }),
              }
            : r
        )
      )
    );

    if (record.id === recordToShare.id) {
      yield call(refreshRecordDetail, {
        ...record,
        ...mapUpdateUserPermissionsToVideoRecords({
          ...record,
          role: data.role,
          id: userPermissionToVideoRecordId,
        }),
      });
    }

    const recordToShareUpdated: RT<typeof selectRecordToShare> = yield select(
      selectRecordToShare
    );

    if (!checkIsAllowToViewLikeMember({ userId, ...recordToShareUpdated })) {
      yield put(actions.setRecordIdToShare(null));
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.record.shareError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
  }
}
