import { RootState } from '../../../app';

export const selectIsSidebarOpen = ({ editorSidebar }: RootState) =>
  editorSidebar.isSidebarOpen;

export const selectSidebarStep = ({ editorSidebar }: RootState) =>
  editorSidebar.selectedSidebarStep;

export const selectTemplateSidebarTab = ({ editorSidebar }: RootState) =>
  editorSidebar.selectedTemplateSidebarTab;

export const selectIsAnyConversionPanelOpen = ({ editorSidebar }: RootState) =>
  editorSidebar.activeConversionSettingsPanel !== null;

export const selectSelectedPopupSection = ({ editorSidebar }: RootState) =>
  editorSidebar.selectedPopupSection;

export const selectDistributeEditCoverPanelOpen = ({
  editorSidebar,
}: RootState) => editorSidebar.isDistributeEditCoverPanelOpen;

export const selectPersonalizedLinkPersonName = ({
  editorSidebar,
}: RootState) => editorSidebar.personalizedLinkPersonName;

export const selectPersonalizedLink = ({ editorSidebar }: RootState) =>
  editorSidebar.personalizedLink;

export const selectPersonalizedLinkColor = ({ editorSidebar }: RootState) =>
  editorSidebar.personalizedLinkColor;

export const selectPersonalizedLinkTitle = ({ editorSidebar }: RootState) =>
  editorSidebar.personalizedLinkTitle;

export const selectPersonalizedLinkCover = ({ editorSidebar }: RootState) =>
  editorSidebar.personalizedLinkCover;

export const selectPersonalizedLinkNoLogo = ({ editorSidebar }: RootState) =>
  editorSidebar.personalizedLinkNoLogo;

export const selectIsPersonalizedCoverUpdating = ({
  editorSidebar,
}: RootState) => editorSidebar.isPersonalizedCoverUpdating;

export const selectSelectedSocialPlatform = ({ editorSidebar }: RootState) =>
  editorSidebar.selectedSocialPlatform;

export const selectSelectedDistributeTab = ({ editorSidebar }: RootState) =>
  editorSidebar.selectedDistributeTab;

export const selectIsPersonalizedLinkGenerating = ({
  editorSidebar,
}: RootState) => editorSidebar.isPersonalizedLinkGenerating;

export const selectIsShowAdvancedSettings = ({ editorSidebar }: RootState) =>
  editorSidebar.isShowAdvancedSettings;

export const selectIsShowAdvancedConversionSettings = ({
  editorSidebar,
}: RootState) => editorSidebar.isShowAdvancedConversionSettings;

export const selectActiveConversionSettingsPanel = ({
  editorSidebar,
}: RootState) => editorSidebar.activeConversionSettingsPanel;

export const selectShouldFocusSlugInput = ({ editorSidebar }: RootState) =>
  editorSidebar.shouldFocusSlugInput;
