import { takeEvery } from 'redux-saga/effects';
import { createPageModalModel } from '.';

export function* createPageModalWorker() {
  yield takeEvery(
    createPageModalModel.actions.searchCompanyBrands,
    createPageModalModel.sagas.searchCompanyBrands
  );

  yield takeEvery(
    createPageModalModel.actions.parseFileTextContent,
    createPageModalModel.sagas.parseFileTextContent
  );

  yield takeEvery(
    createPageModalModel.actions.parseLinkTextContent,
    createPageModalModel.sagas.parseLinkTextContent
  );

  yield takeEvery(
    createPageModalModel.actions.initializePageGenerationWithAI,
    createPageModalModel.sagas.initializePageGenerationWithAI
  );
}
