import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Dropdown, Icon } from '../../../shared/ui';
import {
  CreateTeamModal,
  teamsModel,
  useTeamPermissions,
} from '../../../features/teams';
import { IconMap } from '../../../shared/sprite';
import { useCurrentPlan } from '../../../features/subscription/hooks';
import {
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  redirectActions,
  TEAM_SETTINGS_STYLES_ROUTE,
} from '../../../entities/history';
import {
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
} from '../../../entities/history';
import { environment } from '../../../environments/environment';
import { FeatureFlags } from '../../../entities/feature-flag';
import cn from 'classnames';
import { UserToTeamRole } from '@distribute/shared/types';
import { authUserModel } from '../../../entities/auth-user';

export const SelectTeamDropdown: FC = () => {
  const dispatch = useDispatch();
  const teams = useSelector(teamsModel.selectors.selectTeams);
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const membersCount = currentTeam.userToTeams.filter(
    (i) => !i.isWaitingForInviteAcceptance && i.role !== UserToTeamRole.GUEST
  ).length;
  const currentPlan = useCurrentPlan();
  const isShowCreateNewTeamModal = useSelector(
    teamsModel.selectors.selectIsShowCreateNewTeamModal
  );

  const setCreateNewTeamModalOpen = useCallback(
    (isOpen: boolean) => {
      if (currentUser.isOwnTeamWasCreated) {
        dispatch(teamsModel.actions.setIsShowCreateNewTeamModal(isOpen));
      } else {
        dispatch(teamsModel.actions.createTeamForGuest());
      }
    },
    [currentUser.isOwnTeamWasCreated, dispatch]
  );

  const { isCanManageSubscription, isOwner, isGuest } = useTeamPermissions();

  const items = useMemo(() => {
    const navigationInfo = [
      {
        id: 'workspace-settings',
        name: 'Workspace Settings',
        icon: IconMap.WorkspaceSettings,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_INFO_ROUTE,
            })
          );
        },
        isHide: isGuest,
      },
      {
        id: 'permissions-control',
        name: 'Permission Control',
        icon: IconMap.Shield02,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
            })
          );
        },
        isHide:
          !environment.featureFlags[FeatureFlags.SNIPPETS] ||
          !isOwner ||
          isGuest,
      },
      {
        id: 'team-brand',
        name: 'Company information',
        icon: IconMap.BookClosed,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_BRAND_ROUTE,
            })
          );
        },
        isHide: isGuest,
      },
      {
        id: 'team-styles',
        name: 'Styles',
        icon: IconMap.Palette,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_STYLES_ROUTE,
            })
          );
        },
        isHide: isGuest,
      },
      {
        id: 'members',
        name: 'Members',
        icon: IconMap.Members,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_MEMBERS_ROUTE,
            })
          );
        },
        isHide: isGuest,
      },
      {
        id: 'plans-billing',
        name: 'Plans & Billing',
        icon: IconMap.Award,
        onClick: () => {
          dispatch(
            redirectActions.fromWorkspaceWithBackUrl({
              path: TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
            })
          );
        },
        isHide: !isCanManageSubscription || isGuest,
      },
    ].filter((i) => !i.isHide);

    const navigationOptions = navigationInfo.map((navItem, i) => {
      return {
        id: navItem.id.toString(),
        label: (
          <div className="flex items-center gap-3">
            <Icon
              glyph={navItem.icon}
              width={16}
              className="text-gray-500 group-hover:text-gray-600"
            />
            <div className="flex flex-col min-w-0 text-sm text-gray-700 truncate group-hover:text-gray-800">
              {navItem.name}
            </div>
          </div>
        ),
        nameLabel: i === 0 && (
          <div className="text-xs font-bold text-gray-500 uppercase cursor-auto px-3 pt-2.5 pb-2 truncate">
            {currentTeam.name}
          </div>
        ),
        onClick: navItem.onClick,
      };
    });

    const teamOptions = teams.map((team, i) => {
      return {
        id: team.id.toString(),
        label: (
          <div className="flex items-center gap-3">
            <Avatar
              displayName={team.name}
              src={team.brandLogoUrl ?? undefined}
              theme={team.brandLogoUrl ? 'primary-rect' : 'secondary-rect'}
              size="xs"
            />
            <div className="flex flex-col min-w-0 text-gray-700 group-hover:text-gray-800">
              <span className="text-sm truncate">{team.name}</span>
            </div>
          </div>
        ),
        headerLabel: (
          <div className="flex items-center gap-3">
            <Avatar
              displayName={team.name}
              src={team.brandLogoUrl ?? undefined}
              theme={team.brandLogoUrl ? 'primary-rect' : 'secondary-rect'}
              size="lg"
            />
            <div className="flex flex-col items-start min-w-0 gap-1 text-gray-700">
              <span className="w-full font-semibold text-left truncate text-md">
                {team.name}
              </span>
              <span className="text-sm font-normal">
                {currentPlan.name}
                {' • '}
                {membersCount} {membersCount > 1 ? 'members' : 'member'}
              </span>
            </div>
          </div>
        ),
        nameLabel: i === 0 && (
          <div
            className={cn(
              'px-3 pb-2 text-xs font-bold text-gray-500 uppercase cursor-auto',
              { 'pt-2.5': isGuest }
            )}
          >
            Teams
          </div>
        ),
        isSeparatedFromTop: i === 0 && !isGuest,
        onClick: () =>
          dispatch(teamsModel.actions.changeCurrentTeam({ teamId: team.id })),
      };
    });

    const createTeamOption = {
      id: 'createTeam',
      isSeparatedFromTop: false,
      label: (
        <div className="flex items-center gap-3 text-gray-700 group-hover:text-gray-800">
          <Icon
            glyph={IconMap.PlusLight}
            width={20}
            className="text-gray-500 group-hover:text-gray-600"
          />
          <span className="text-sm truncate">Add Team</span>
        </div>
      ),
      onClick: () => setCreateNewTeamModalOpen(true),
    };

    return [...navigationOptions, ...teamOptions, createTeamOption];
  }, [
    isGuest,
    isOwner,
    isCanManageSubscription,
    teams,
    dispatch,
    currentTeam.name,
    currentPlan.name,
    membersCount,
    setCreateNewTeamModalOpen,
  ]);

  return (
    <>
      <CreateTeamModal
        isOpen={isShowCreateNewTeamModal}
        onClose={() => setCreateNewTeamModalOpen(false)}
      />
      <Dropdown
        items={items}
        currentItemId={currentTeam.id.toString()}
        listStyles="!w-[var(--radix-dropdown-menu-trigger-width)] shadow-lg"
        triggerStyles="!w-full bg-base-white !p-3 !text-gray-700 !font-semibold h-fit shadow-xs hover:bg-gray-50"
        align="start"
        isDoubleArrow
        itemStyles="py-2 px-3"
        arrowStyle="text-gray-600"
      />
    </>
  );
};
