import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SubscriptionInvoice,
  SubscriptionUpcomingInvoiceInfo,
} from '@distribute/shared/types';

import { globalActions } from '../../../app/model/actions';
import {
  CancelSubscriptionAction,
  ConfirmPaymentMethodAction,
  CreateSubscriptionAction,
  GetUpcomingInvoiceAction,
  RetryLatestFailedInvoiceAction,
  TogglePaywallAction,
  UpdatePaymentMethodAction,
  UpdateSubscriptionAction,
} from './types';

type State = {
  upcomingInvoiceInfo: SubscriptionUpcomingInvoiceInfo | null;
  isLoadingUpcomingInvoice: boolean;
  isUpdatingSubscription: boolean;
  isUpdatingPaymentMethod: boolean;
  isCancelingSubscription: boolean;
  isRetryingLatestFailedInvoice: boolean;
  isShowPlanReachedMembersLimitModal: boolean;
  isLoadingInvoices: boolean;
  invoices: SubscriptionInvoice[];
  updatePaymentMethodError?: string;
  isConfirmingPaymentMethod: boolean;
  isShowPaywallModal: boolean;
  isShowUpdatePaymentMethodModal: boolean;
  isShowThanksConfirmPaymentMethod: boolean;
};
const initialState: State = {
  upcomingInvoiceInfo: null,
  isLoadingUpcomingInvoice: false,
  isUpdatingSubscription: false,
  isUpdatingPaymentMethod: false,
  isCancelingSubscription: false,
  isRetryingLatestFailedInvoice: false,
  isShowPlanReachedMembersLimitModal: false,
  isLoadingInvoices: false,
  invoices: [],
  isConfirmingPaymentMethod: false,
  isShowPaywallModal: false,
  isShowUpdatePaymentMethodModal: false,
  isShowThanksConfirmPaymentMethod: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setIsUpdatingSubscription: (
      state,
      { payload: isUpdatingSubscription }: PayloadAction<boolean>
    ) => ({
      ...state,
      isUpdatingSubscription,
    }),
    setIsLoadingUpcomingInvoice: (
      state,
      { payload: isLoadingUpcomingInvoice }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingUpcomingInvoice,
    }),
    setUpcomingInvoiceInfo: (
      state,
      {
        payload: upcomingInvoiceInfo,
      }: PayloadAction<SubscriptionUpcomingInvoiceInfo | null>
    ) => ({
      ...state,
      upcomingInvoiceInfo,
    }),
    setIsUpdatingPaymentMethod: (
      state,
      { payload: isUpdatingPaymentMethod }: PayloadAction<boolean>
    ) => ({
      ...state,
      isUpdatingPaymentMethod,
    }),
    setUpdatePaymentMethodError: (
      state,
      { payload: updatePaymentMethodError }: PayloadAction<string | undefined>
    ) => ({
      ...state,
      updatePaymentMethodError,
    }),
    setIsCancelingSubscription: (
      state,
      { payload: isCancelingSubscription }: PayloadAction<boolean>
    ) => ({
      ...state,
      isCancelingSubscription,
    }),
    setIsRetryingLatestFailedInvoice: (
      state,
      { payload: isRetryingLatestFailedInvoice }: PayloadAction<boolean>
    ) => ({
      ...state,
      isRetryingLatestFailedInvoice,
    }),
    setIsShowPlanReachedMembersLimitModal: (
      state,
      { payload: isShowPlanReachedMembersLimitModal }: PayloadAction<boolean>
    ) => ({
      ...state,
      isShowPlanReachedMembersLimitModal,
    }),
    setIsLoadingInvoices: (
      state,
      { payload: isLoadingInvoices }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingInvoices,
    }),
    setInvoices: (
      state,
      { payload: invoices }: PayloadAction<SubscriptionInvoice[]>
    ) => ({
      ...state,
      invoices,
    }),
    setIsShowPaywallModal: (
      state,
      { payload: isShowPaywallModal }: PayloadAction<boolean>
    ) => ({ ...state, isShowPaywallModal }),
    setIsConfirmingPaymentMethod: (
      state,
      { payload: isConfirmingPaymentMethod }: PayloadAction<boolean>
    ) => ({ ...state, isConfirmingPaymentMethod }),
    setIsShowUpdatePaymentMethodModal: (
      state,
      { payload: isShowUpdatePaymentMethodModal }: PayloadAction<boolean>
    ) => ({ ...state, isShowUpdatePaymentMethodModal }),
    setIsShowThanksConfirmPaymentMethod: (
      state,
      { payload: isShowThanksConfirmPaymentMethod }: PayloadAction<boolean>
    ) => ({ ...state, isShowThanksConfirmPaymentMethod }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  updatePaymentMethod: createAction<UpdatePaymentMethodAction>(
    'subscription/updatePaymentMethod'
  ),
  getUpcomingInvoice: createAction<GetUpcomingInvoiceAction>(
    'subscription/getUpcomingInvoice'
  ),
  createSubscription: createAction<CreateSubscriptionAction>(
    'subscription/createSubscription'
  ),
  updateSubscription: createAction<UpdateSubscriptionAction>(
    'subscription/updateSubscription'
  ),
  cancelSubscription: createAction<CancelSubscriptionAction>(
    'subscription/cancelSubscription'
  ),
  retryLatestFailedInvoice: createAction<RetryLatestFailedInvoiceAction>(
    'subscription/retryLatestFailedInvoice'
  ),
  togglePaywall: createAction<TogglePaywallAction>(
    'subscription/togglePaywall'
  ),
  confirmPaymentMethod: createAction<ConfirmPaymentMethodAction>(
    'subscription/confirmPaymentMethod'
  ),
};
