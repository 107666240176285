import { isAxiosError } from 'axios';

const errorConfig = [
  { status: 400, message: 'Invalid input. Please check and retry.' },
  { status: 401, message: 'Login required to access this page.' },
  {
    status: 403,
    message: `Sorry, but you don't have permission to access this content.`,
  },
  {
    status: 404,
    message: `We couldn't find the page you're looking for. Check URL or return home.`,
  },
  {
    status: 409,
    message:
      'Conflict detected. This file is being edited by someone else at the moment. Please try again later.',
  },
];

export function handleError(error: unknown, defaultMessage: string) {
  if (isAxiosError(error) && error.response) {
    const message = errorConfig.find(
      (i) => i.status === error.response?.status
    )?.message;
    return message ?? defaultMessage;
  }
  return defaultMessage;
}
