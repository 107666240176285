import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '../../../../shared/ui';
import { profileSettingModel } from '../../model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DisconnectGmailAuthConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsGmailAuthDisconnecting
  );

  const onModalClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    dispatch(profileSettingModel.actions.disconnectGmailAuthentication());
    onClose();
  };

  return (
    <Modal
      title="Disconnect Google account"
      onClose={onModalClose}
      isOpen={isOpen}
      titleClassName="!max-w-full"
      actionButton={
        <Button
          loading={isLoading}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          onClick={handleSubmit}
        >
          Disconnect
        </Button>
      }
    >
      <div className="w-full">
        <p className="text-sm text-gray-600 mb-2">
          Are you sure you want to remove this google account from your
          Distribute account?
        </p>
      </div>
    </Modal>
  );
};
