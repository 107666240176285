import {
  BrandColor,
  Page,
  PageTeamMembersPermission,
  UserPermissionToPageRole,
  ActionPreview,
  PagePublicAccessPermission,
} from '@distribute/shared/types';
import type { JSONContent } from '@tiptap/react';
import type { DocumentContentItem } from '@distribute/shared/types';

export type CreatePageAction = {
  title?: string;
  slug?: string;
  folderId: string;
  folderName?: string;
  brandLogo?: string | null;
  brandCompanyDomain?: string | null;
  cb?(): void;
};

export type DeletePageAction = {
  id: string;
  callback: () => void;
};

export type MovePageToFolderAction = {
  folderId: string;
  pageId: string;
  folderName?: string;
  callback: () => void;
};

export type RenamePageAction = {
  title: string;
  pageId: string;
};

export type ChangePageIconAction = {
  icon: string;
  pageId: string;
  callback: () => void;
};

export type ChangePageCoverImageAction = {
  coverImageUrl: string | null;
  pageId: string;
};

export type CreatePageContentItemAction = {
  pageId: string;
  callback?: (err?: unknown) => void;
};

export type DuplicatePageContentItemAction = {
  pageId: string;
  contentItemId: number;
  callback?: (err?: unknown) => void;
};

export type DeletePageContentItemAction = {
  pageId: string;
  contentItemId: number;
  callback?: (err?: unknown) => void;
};

export type UpdatePageContentItemAction = {
  pageId: string;
  contentItemId: number;
  data: Partial<DocumentContentItem>;
  callback?: (err?: unknown) => void;
};

export type BulkUpdatePageContentItemAction = {
  pageId: string;
  data: Partial<DocumentContentItem>[];
  callback?: (err?: unknown) => void;
};

export type ChangePageContentAction = {
  contentItemId: number;
  contentJson: JSONContent;
  pageId: string;
};

export type SetCurrentContentItemAction = {
  contentItem: DocumentContentItem;
  shouldUpdateQuery?: boolean;
};

export type ChangeBrandColorAction = {
  brandColor: BrandColor;
  pageId: string;
};

export type ChangePageBrandLogoAction = {
  brandLogo: string | null;
  pageId: string;
};

export type ChangePageStyle = {
  pageId: string;
  headingFontFamily?: string;
  paragraphFontFamily?: string;
};

export type DuplicatePageAction = {
  id: string;
  folderId: string;
  source: 'workspace' | 'editor';
};

export type UpdateTeamMemberPermissionAction = {
  pageId: string;
  permission: PageTeamMembersPermission;
};

export type ChangePagePublicAccessAction = {
  permission: PagePublicAccessPermission;
};

export type CreateUserPermissionAction = {
  pageId: string;
  userEmails: string[];
  role: UserPermissionToPageRole;
  cb: () => void;
};

export type ResendUserPermissionInvitationAction = {
  pageId: string;
  userPermissionId: number;
};

export type UpdateUserPermissionAction = {
  role: UserPermissionToPageRole;
  pageId: string;
  userPermissionId: number;
};

export type DeleteUserPermissionAction = {
  pageId: string;
  userPermissionId: number;
};

export type GetPagePublicInfoAction = {
  sequenceNumber: string;
};

export enum EditorPreviewModeEnum {
  DESKTOP = 'Desktop',
  TABLET = 'Tablet',
  MOBILE = 'Mobile',
}

export type UpdatePageAction = {
  pageId: string;
  title?: string;
  icon?: string;
  brandColor?: BrandColor;
  headingFontFamily?: string;
  paragraphFontFamily?: string;
  coverImageUrl?: string | null;
  contentJson?: JSONContent;
  contentItemId?: number;
  isViewersCanDuplicateAsTemplate?: boolean;
  isDistributeBadgeRemoved?: boolean;
  cb?: (page: Page) => void;
};

export type ChangePublicStatusAction = {
  pageId: string;
  published: boolean;
  callback: () => void;
};

export type SearchCompanyBrandsAction = {
  searchQuery: string;
};

export type SetActionPreviewAction = {
  actionPreview: ActionPreview;
  position: {
    top: number;
    left: number;
    right: number;
    height: number;
    scrollTop: number;
    scrollLeft: number;
  };
};

export type RequestToEditPageAction = {
  pageId?: string;
};
