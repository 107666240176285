import React, { FC } from 'react';

import { Button, Icon } from '../../shared/ui';
import { IconMap } from '../../shared/sprite';
import { SpriteGlyph } from './Icon';

type IProps = {
  title: string;
  isOpened: boolean;
  isDisabled?: boolean;
  icon: SpriteGlyph;
  onEdit: () => void;
  onPreview: () => void;
};

export const ConversionControlItem: FC<IProps> = ({
  title,
  isOpened,
  isDisabled,
  icon,
  onEdit,
  onPreview,
}) => {
  return (
    <div className="relative w-74 h-17 p-4 flex items-center rounded-lg border border-gray-200 shadow-md bg-base-white">
      <Icon glyph={icon} width={36} />
      <p className="font-semibold text-gray-900 ml-4 mr-auto">{title}</p>
      <Button
        variant="icon"
        color="transparent"
        size="sm"
        className="mr-2"
        onClick={onEdit}
      >
        <Icon glyph={IconMap.Edit02} width={20} />
      </Button>
      <Button variant="icon" color="secondary" size="sm" onClick={onPreview}>
        <Icon glyph={isOpened ? IconMap.XClose : IconMap.Eye} width={20} />
      </Button>
      {isDisabled && (
        <div className="absolute z-10 w-full h-full top-0 left-0 rounded-lg bg-opacity-70 bg-base-white"></div>
      )}
    </div>
  );
};
