import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

type Size = 'sm' | 'md';
type Theme = 'warning' | 'success' | 'error' | 'informative';

type IProps = {
  size: Size;
  theme: Theme;
  children: ReactNode;
  className?: string;
};

const classNameBySize: Record<Size, string> = {
  sm: 'py-0.5 px-2 rounded-2xl text-xs',
  md: 'py-0.5 px-2.5 rounded-2xl text-sm',
};

const classNameByTheme: Record<Theme, string> = {
  warning: 'bg-warning-50 text-warning-700',
  success: 'bg-success-50 text-success-700',
  error: 'bg-error-50 text-error-700',
  informative: 'bg-blue-50 text-blue-700',
};

export const Badge: FC<IProps> = ({ size, theme, className, children }) => {
  return (
    <div
      className={classNames(
        'flex justify-center font-medium mix-blend-multiply',
        classNameBySize[size],
        classNameByTheme[theme],
        className
      )}
    >
      {children}
    </div>
  );
};
