import { pagesModel } from '../../../../features/pages';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { PagePublishingHeaderControls } from './PagePublishingHeaderControls';
import { templatesModel } from '../../../../features/templates';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { SaveTemplateModal } from '../../../../widgets/editor-sidebar/ui/components/SaveTemplateModal';
import { TemplateSettingsDropdown } from './TemplateSettingsDropdown';
import { conversionKitModel } from '../../../../features/conversion-kit';

type Props = {
  isBlocked?: boolean;
  isTemplateMode: boolean;
};

export const EditorHeaderToolbar: React.FC<Props> = ({
  isBlocked,
  isTemplateMode,
}) => {
  const dispatch = useDispatch();

  const currentPageIsError = useSelector(
    pagesModel.selectors.selectCurrentPageIsError
  );
  const currentPageChanging = useSelector(
    pagesModel.selectors.selectChangePageContentIsLoading
  );

  const [status, setStatus] = useState('Changes Saved');

  const isTemplateSaved = useSelector(
    templatesModel.selectors.selectIsTemplateSavedInEditor
  );

  const isTemplateUpdating = useSelector(
    templatesModel.selectors.selectTemplateIsUpdating
  );

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const isTemplateDraft =
    currentTemplate?.creationPhase === TemplateCreationPhase.DRAFT;

  const alertBar = useSelector(conversionKitModel.selectors.selectAlertBar);
  const popup = useSelector(conversionKitModel.selectors.selectPopUp);
  const cta = useSelector(conversionKitModel.selectors.selectCTA);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePage
  );

  const handleUpdateTemplate = (saveAs: TemplateCreationPhase) => {
    if (!currentTemplate) return;

    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        creationPhase: saveAs,
      })
    );
    dispatch(templatesModel.actions.updateTemplateFromEditor());

    if (alertBar) {
      dispatch(
        conversionKitModel.actions.updateAlertBarDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (popup) {
      dispatch(
        conversionKitModel.actions.updatePopUpDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (cta) {
      dispatch(
        conversionKitModel.actions.updateCtaDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (gatedContent) {
      dispatch(
        conversionKitModel.actions.updateGatedContentDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (squeezePage) {
      dispatch(
        conversionKitModel.actions.updateSqueezePageDB({
          documentContentId: currentTemplate.content.id,
        })
      );
    }

    if (saveAs === TemplateCreationPhase.READY_TO_USE) {
      dispatch(templatesModel.actions.setIsTemplateSavedInEditor(true));
    }
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  };

  useEffect(() => {
    currentPageChanging && setStatus('Changes Saving');
    currentPageIsError && setStatus('Trying to connect');
    !currentPageIsError && !currentPageChanging && setStatus('Changes Saved');
  }, [currentPageChanging, currentPageIsError]);

  const isEditorSidebarOpen = useSelector(
    editorSidebarModel.selectors.selectIsSidebarOpen
  );

  const toggleEditorSidebarOpen = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(!isEditorSidebarOpen));
  };

  const { editor } = useTiptapEditor();

  const undo = () => {
    editor?.chain().focus().undo().run();
  };

  const redo = () => {
    editor?.chain().focus().redo().run();
  };

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const isEditorBlocked = useSelector(
    pagesModel.selectors.selectIsEditorBlocked
  );

  const handleEditorPreview = () => {
    dispatch(pagesModel.actions.setIsEditorPreview(true));
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
  };

  return (
    <>
      {isTemplateDraft && (
        <SaveTemplateModal
          handleUpdateTemplate={handleUpdateTemplate}
          setIsSaveModalOpen={setIsSaveModalOpen}
          isSaveModalOpen={isSaveModalOpen}
        />
      )}
      <div className="flex items-center gap-4 shrink-0">
        <div
          className={classNames(
            'flex items-center gap-1 sm:pl-2 h-8 text-gray-600 sm:hidden',
            {
              hidden: isTemplateMode,
            }
          )}
        >
          {!currentPageChanging && (
            <Icon
              width={12}
              glyph={
                currentPageIsError ? IconMap.CloudOffIcon : IconMap.Checked
              }
              className="shrink-0"
            />
          )}
          <p
            className={classNames('text-s lg:hidden shrink-0', {
              'text-warning-600': currentPageIsError,
            })}
          >
            {status}
          </p>
        </div>

        {!isTemplateMode && <div className="w-0.25 h-5.5 bg-gray-200" />}

        <div className="flex items-center">
          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="tertiary-black"
                disabled={!editor?.can().undo()}
                onClick={undo}
                size="sm"
              >
                <Icon glyph={IconMap.ReverseLeft} />
              </Button>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">Undo</p>
          </Tooltip>

          <Tooltip
            trigger={
              <Button
                variant="icon"
                color="tertiary-black"
                disabled={!editor?.can().redo()}
                onClick={redo}
                size="sm"
              >
                <Icon glyph={IconMap.ReverseRight} />
              </Button>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">Redo</p>
          </Tooltip>
        </div>

        <div className="w-0.25 h-5.5 bg-gray-200" />

        {!isTemplateMode && <PagePublishingHeaderControls />}

        {isTemplateMode && (
          <>
            <TemplateSettingsDropdown listStyles="mt-4" />
            <Button
              variant="icon-text"
              color="secondary"
              title="Preview"
              onClick={handleEditorPreview}
              disabled={isEditorBlocked}
              size="sm"
              className="hidden"
            >
              <Icon
                glyph={IconMap.Eye}
                width={20}
                className="mr-2 text-gray-700"
              />
              <span className="text-gray-700 font-semibold">Preview</span>
            </Button>
          </>
        )}

        {isTemplateMode &&
          (isTemplateSaved && !isTemplateDraft ? (
            <div className="py-2 px-3 bg-primary-50 flex items-center gap-1.5 text-primary-700 text-sm font-semibold rounded-lg">
              <Icon glyph={IconMap.CheckCircle} width={20} />
              Template saved
            </div>
          ) : (
            <Button
              variant="text"
              color="primary"
              size="sm"
              className="font-semibold shadow-xs"
              onClick={() => {
                isTemplateDraft
                  ? setIsSaveModalOpen(true)
                  : handleUpdateTemplate(TemplateCreationPhase.READY_TO_USE);
              }}
              loading={isTemplateUpdating && !isTemplateDraft}
            >
              <Icon
                glyph={IconMap.ArrowCircleDown}
                width={20}
                className="mr-1.5"
              />
              {isTemplateDraft ? 'Save Template' : 'Update'}
            </Button>
          ))}

        <Tooltip
          trigger={
            <Button
              variant="icon"
              color="secondary"
              size="sm"
              className="bg-gray-50"
              onClick={toggleEditorSidebarOpen}
              disabled={isBlocked}
            >
              <Icon
                glyph={IconMap.Sidebar}
                width={20}
                className={classNames({
                  'text-gray-700': !isBlocked,
                  'text-gray-200': isBlocked,
                })}
              />
            </Button>
          }
          sideOffset={8}
          align="end"
          alignOffset={-8}
          hideArrow
        >
          <p className="px-2 py-1 font-semibold text-xs">
            {isEditorSidebarOpen ? 'Hide sidebar' : 'Open sidebar'}
          </p>
        </Tooltip>
      </div>
    </>
  );
};
