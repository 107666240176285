import { JSONContent } from '@tiptap/react';

export const getNormalizedTextSnippetContent = (content: JSONContent) => {
  const contentArray = content.content;
  if (contentArray) {
    const normalizedContent = [...contentArray];

    let i = contentArray.length - 1;

    while (contentArray[i].type === 'paragraph' && !contentArray[i].content) {
      normalizedContent.pop();
      i--;
    }

    return { ...content, content: normalizedContent };
  }

  return content;
};
