import { SimpleLayout } from '../../../shared/ui';
import { PublicTemplate } from '../../../features/public-template';

export const PublicTemplatePage = () => {
  return (
    <SimpleLayout classNameContent="!w-143">
      <PublicTemplate />
    </SimpleLayout>
  );
};
