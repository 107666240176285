import React from 'react';
import { Avatar } from '../../../../shared/ui';
import { Page } from '@distribute/shared/types';

type Props = {
  page: Page;
};

export const CreatedByRow: React.FC<Props> = ({ page }) => {
  const ownerInfo = page.userPermissionToPages.find((u) => u.role === 'owner');

  return (
    <div className="flex items-center gap-2 truncate">
      <Avatar
        displayName={ownerInfo?.user.displayName || ''}
        src={ownerInfo?.user.photoUrl}
        size="xs"
        isUserAvatar
        className="border-light-7 border-[0.5px] !bg-base-white"
      />
      <div className="text-sm text-gray-700 truncate">
        Owned by{' '}
        <span className="font-semibold text-gray-900">
          {ownerInfo?.user.displayName || ''}
        </span>
      </div>
    </div>
  );
};
