import {
  TemplatesConfigEnum,
  templatesModel,
} from '../../../../features/templates';
import { useMemo } from 'react';
import { useTemplatesApi } from './useTemplatesApi';
import { useSelector } from 'react-redux';
import { useDebounce } from '@distribute/frontend/utils';

type UseTemplatesProps = {
  search?: string;
  category?: TemplatesConfigEnum;
};

export const useTemplates = ({
  search = '',
  category = TemplatesConfigEnum.ALL,
}: UseTemplatesProps) => {
  const topTemplates = useSelector(templatesModel.selectors.selectTopTemplates);
  const debouncedQuery = useDebounce(search, 500);
  const debouncedCategory = useDebounce(category, 500);

  const { templates } = useTemplatesApi({
    category: debouncedCategory,
    shouldRequest: debouncedCategory !== TemplatesConfigEnum.TOP,
    query: debouncedQuery,
  });

  const actualTemplates = useMemo(() => {
    if (category === TemplatesConfigEnum.TOP) {
      return topTemplates.filter((template) =>
        new RegExp(search, 'ig').test(template.name)
      );
    }

    return templates;
  }, [category, search, templates, topTemplates]);

  return {
    templates: actualTemplates,
  };
};
