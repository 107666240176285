import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { Folder } from '@distribute/shared/types';
import { useSelector } from 'react-redux';
import { foldersModel } from '../../../features/folders';
import { generatePath, useLocation } from 'react-router-dom';
import { WORKSPACE_FOLDER_ROUTE } from '../../../entities/history';

type Props = {
  onClick: () => void;
  folderData: Folder;
  quantity: number;
  isDragging?: boolean;
};

export const FolderItem: FC<Props> = ({
  onClick,
  folderData,
  quantity,
  isDragging,
}) => {
  const folders = useSelector(foldersModel.selectors.selectFolders);
  const showDragIcon = folders.length >= 2;

  const location = useLocation();
  const folderPath = generatePath(WORKSPACE_FOLDER_ROUTE, {
    sequenceNumber: folderData.sequenceNumber,
  });
  const isFolderSelected = location.pathname === folderPath && !isDragging;

  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex justify-between w-full rounded-md px-3 py-2 group',
        {
          'hover:bg-light-3 focus:bg-light-5': !isFolderSelected,
          'bg-gray-200 hover:bg-gray-200 focus:bg-gray-400 focus:ring-4':
            isFolderSelected,
          'cursor-pointer': !isDragging,
        }
      )}
    >
      <p
        className={classNames('font-medium text-sm max-w-50 truncate', {
          'text-gray-700 hover:text-gray-900': !isFolderSelected,
          'text-gray-800': isFolderSelected,
        })}
      >
        {folderData?.title}
      </p>
      <div className="flex flex-row items-center group">
        {showDragIcon && (
          <Icon
            glyph={IconMap.Drag}
            width={20}
            className="mr-4 hidden group-hover:flex group-active:flex text-gray-500"
          />
        )}
        <p
          className={classNames('font-normal text-xs py-0.5 rounded-2xl', {
            ' !text-gray-700 mix-blend-multiply': !isFolderSelected,
            ' !text-gray-800': isFolderSelected,
          })}
        >
          {quantity}
        </p>
      </div>
    </div>
  );
};
