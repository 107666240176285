import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  TeamOnboardingStatus,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { StepCreateWorkspace } from './components/StepCreateWorkspace';
import { authUserModel } from '../../../entities/auth-user';
import { StepSubscription } from './components/StepSubscription';
import { StepInviteTeamMembers } from './components/StepInviteTeamMembers';
import { StepUsagePlan } from './components/StepUsagePlan';
import { teamsModel } from '../../teams';
import { onboardingModel } from '../model';
import { Loader } from '../../../shared/ui';

import { ChromeExtensionOnboard } from '../../extension';

export const OnboardingSteps: FC = () => {
  const { onboardingStatus: userOnboardingStatus } = useSelector(
    authUserModel.selectors.selectUserWithError
  );
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);
  const isLoadingFinishTeamOnboarding = useSelector(
    onboardingModel.selectors.selectIsLoadingFinishTeamOnboarding
  );

  if (isLoadingFinishTeamOnboarding) {
    return <Loader />;
  }

  if (userOnboardingStatus === UserOnboardingStatus.USAGE_PLAN) {
    return <StepUsagePlan />;
  }

  if (userOnboardingStatus === UserOnboardingStatus.CREATE_TEAM) {
    return <StepCreateWorkspace />;
  }

  if (currentTeam?.onboardingStatus === TeamOnboardingStatus.INVITE_TEAMMATES) {
    return <StepInviteTeamMembers />;
  }

  if (currentTeam?.onboardingStatus === TeamOnboardingStatus.SUBSCRIPTION) {
    return <StepSubscription />;
  }

  if (userOnboardingStatus === UserOnboardingStatus.INSTALL_EXTENSION) {
    return <ChromeExtensionOnboard />;
  }

  return null;
};
