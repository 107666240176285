import React from 'react';
import cn from 'classnames';

import { Button, FileInput, Icon } from '.';
import { IconMap } from '../sprite';

type Props = {
  imageUrl: string | undefined;
  isUploading: boolean;
  uploadProgress: number;
  isRemoving: boolean;
  onUpdateImage: (image: File) => void;
  onRemoveImage: () => void;
  isLabel?: boolean;
  className?: string;
  fileInputStyles?: string;
  imageClassName?: string;
};

export const ImageInput: React.FC<Props> = ({
  imageUrl,
  isUploading,
  uploadProgress,
  isRemoving,
  onUpdateImage,
  onRemoveImage,
  isLabel = true,
  className,
  fileInputStyles,
  imageClassName,
}) => (
  <div className={className}>
    {isLabel && <p className="text-gray-700 text-s pb-1.5">Image</p>}
    {imageUrl ? (
      <div className="relative">
        <img
          className={cn(
            'w-full h-43.5 object-cover rounded-xl',
            imageClassName
          )}
          src={imageUrl}
          alt="background"
        />
        <Button
          className="!absolute top-3.5 right-4.5 opacity-75 hover:opacity-100 transition-all"
          size="sm"
          onClick={onRemoveImage}
          variant="icon"
          color="secondary"
          disabled={isRemoving || isUploading}
        >
          <Icon glyph={IconMap.Trash} className="text-gray-600" />
        </Button>
      </div>
    ) : (
      <FileInput
        onUpload={onUpdateImage}
        isUploading={isUploading}
        progress={uploadProgress}
        fileInputStyles={fileInputStyles}
      />
    )}
  </div>
);
