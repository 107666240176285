import { RootState } from '../../../app';

export const selectIsUploadLogoLoading = ({
  teamInfoSettings: { isUploadLogoLoading },
}: RootState) => isUploadLogoLoading;

export const selectUploadingLogoProgress = ({
  teamInfoSettings: { uploadingLogoProgress },
}: RootState) => uploadingLogoProgress;

export const selectIsRemoveLogoLoading = ({
  teamInfoSettings: { isRemoveLogoLoading },
}: RootState) => isRemoveLogoLoading;

export const selectIsUpdatingNameDomain = ({
  teamInfoSettings: { isUpdatingNameDomain },
}: RootState) => isUpdatingNameDomain;

export const selectIsLeavingTeam = ({
  teamInfoSettings: { isLeavingTeam },
}: RootState) => isLeavingTeam;

export const selectIsRequestingDeleteTeam = ({
  teamInfoSettings: { isRequestingDeleteTeam },
}: RootState) => isRequestingDeleteTeam;

export const selectConfirmDeleteTeamStatus = ({
  teamInfoSettings: { confirmDeleteTeamStatus },
}: RootState) => confirmDeleteTeamStatus;

export const selectLeftTeam = ({ teamInfoSettings: { leftTeam } }: RootState) =>
  leftTeam;
