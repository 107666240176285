import { useDispatch, useSelector } from 'react-redux';
import { SimpleLayout, Button, Loader } from '../../shared/ui';
import { redirectActions } from '../../entities/history';
import { teamInfoSettingsModel } from '../../features/team-info-settings';
import { teamsModel } from '../../features/teams';

export const ConfirmDeleteTeam = () => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);

  const confirmDeleteTeamStatus = useSelector(
    teamInfoSettingsModel.selectors.selectConfirmDeleteTeamStatus
  );

  if (
    confirmDeleteTeamStatus === 'never' ||
    confirmDeleteTeamStatus === 'pending'
  ) {
    return <Loader />;
  }

  if (confirmDeleteTeamStatus === 'error') {
    return null;
  }

  return (
    <SimpleLayout>
      <div>
        <div className="text-display-md text-gray-900 font-semibold font-display text-center">
          Your team has been successfully deleted
        </div>

        <div className="mt-3 text-center font-normal text-md text-gray-600">
          All associated pages and templates have been permanently removed.
        </div>
        <Button
          fullWidth
          color="primary"
          variant="text"
          className="mt-6"
          onClick={() =>
            dispatch(
              currentTeam
                ? redirectActions.toWorkspace()
                : redirectActions.toOnboardingClick()
            )
          }
        >
          Continue to Distribute
        </Button>
      </div>
    </SimpleLayout>
  );
};
