import classNames from 'classnames';
import React, { MouseEvent, forwardRef, useMemo } from 'react';

import { Icon, SpriteGlyph } from './Icon';
import { ButtonColor, ButtonSize, ButtonVariant, buttonStyles } from './utils';

type ButtonType = 'button' | 'reset' | 'submit';

export type Props = {
  children?: React.ReactNode;
  variant: ButtonVariant;
  color: ButtonColor;
  type?: ButtonType;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => unknown;
  title?: string;
  className?: string;
  loading?: boolean;
  iconLeftName?: SpriteGlyph;
  iconLeftWidth?: number;
  iconBlockClassName?: string;
  size?: ButtonSize;
  fontBold?: boolean;
  id?: string;
  contentEditable?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant,
      color,
      type = 'button',
      disabled = false,
      iconBlockClassName = 'mr-2',
      fullWidth = false,
      onClick,
      title,
      children,
      className,
      loading,
      iconLeftName,
      iconLeftWidth,
      size = 'lg',
      fontBold = false,
      id,
      contentEditable,
      ...props
    },
    ref
  ) => {
    const styles = useMemo(
      () =>
        buttonStyles({
          className,
          fontBold,
          disabled,
          fullWidth,
          variant,
          size,
          color,
        }),
      [className, fontBold, disabled, fullWidth, variant, size, color]
    );

    return (
      <button
        {...props}
        contentEditable={contentEditable}
        ref={ref}
        id={id}
        type={type}
        disabled={disabled || loading}
        className={classNames(styles, 'outline-none', {
          'bg-primary-100 hover:bg-primary-100 border-none cursor-default':
            loading && color === 'primary',
          'bg-gray-300 hover:bg-gray-300 border-none cursor-default':
            loading && color === 'destructive',
          'bg-gray-100 hover:bg-gray-100 border-none cursor-default':
            loading && color === 'secondary',
          'bg-base-white border-none border-gray-50 cursor-default':
            loading && color === 'link-gray',
        })}
        title={title}
        onClick={onClick}
      >
        {iconLeftName && (
          <div className={iconBlockClassName}>
            <Icon glyph={iconLeftName} width={iconLeftWidth} />
          </div>
        )}
        {loading && variant !== 'icon' && (
          <div
            className={classNames(
              'absolute -left-0.25 -top-0.25 flex items-center justify-center w-[calc(100%_+_2px)] h-[calc(100%_+_2px)] rounded-lg',
              {
                'bg-primary-100': color === 'primary',
                'bg-primary-50': color === 'primary-50',
                'bg-gray-100 text-gray-400': color === 'secondary',
                'text-gray-400 bg-base-white w-[calc(100%_-_2px)] h-[calc(100%_-_2px)] left-px top-px':
                  color === 'link' || color === 'link-gray',
                'bg-gray-100 text-gray-600': color === 'tertiary-black',
                'bg-gray-300 border-none': color === 'destructive',
              }
            )}
          >
            {[1, 2, 3].map((i) => (
              <span
                key={i}
                className={`w-2 h-2 flex m-1 bg-primary-800 rounded loading-dot loading-dot--${i}`}
              ></span>
            ))}
          </div>
        )}
        {children}
      </button>
    );
  }
);
