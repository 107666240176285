import { RootState } from '../../../app';

export const selectIsLoadingSkipTeamOnboardingStep = ({
  onboarding: { isLoadingSkipTeamOnboardingStep },
}: RootState) => isLoadingSkipTeamOnboardingStep;

export const selectIsLoadingInviteTeamMembers = ({
  onboarding: { isLoadingInviteTeamMembers },
}: RootState) => isLoadingInviteTeamMembers;

export const selectIsCreatingOrUpdatingTeam = ({
  onboarding: { isCreatingOrUpdatingTeam },
}: RootState) => isCreatingOrUpdatingTeam;

export const selectIsLoadingUsagePlan = ({
  onboarding: { isLoadingUsagePlan },
}: RootState) => isLoadingUsagePlan;

export const selectIsLoadingCreateSubscriptionTrial = ({
  onboarding: { isLoadingCreateSubscriptionTrial },
}: RootState) => isLoadingCreateSubscriptionTrial;

export const selectIsLoadingFinishTeamOnboarding = ({
  onboarding: { isLoadingFinishTeamOnboarding },
}: RootState) => isLoadingFinishTeamOnboarding;

export const selectIsLoadingStartAppsumoOnboarding = ({
  onboarding: { isLoadingStartAppsumoOnboarding },
}: RootState) => isLoadingStartAppsumoOnboarding;

export const selectIsLoadingSkipExtension = ({
  onboarding: { isLoadingSkipExtension },
}: RootState) => isLoadingSkipExtension;

export const selectSubdomain = ({ onboarding: { subdomain } }: RootState) =>
  subdomain;
