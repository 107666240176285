import { call, fork, select, takeEvery } from 'redux-saga/effects';
import { Folder, TeamFiltered } from '@distribute/shared/types';
import {
  QUERY_PARAM_IS_DUPLICATE_PUBLIC_TEMPLATE_TO_DEFAULT_FOLDER,
  QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE,
  RouteParams,
  publicTemplatesModel,
} from '../../../features/public-template';
import { authUserModel } from '../../../entities/auth-user';
import { DBUser } from '../../../shared/types';
import { redirect } from '../../../entities/history';
import { getQueryParam, matchParams } from '../../../shared/lib';
import { teamsModel } from '../../../features/teams';
import { foldersModel } from '../../../features/folders';

export function* publicTemplateWorker(location: Location, route: string) {
  const params = matchParams<RouteParams>(location.pathname, route);

  const user: DBUser | undefined = yield select(
    authUserModel.selectors.selectUser
  );

  if (!params) return;

  if (user) {
    const isDuplicatePublicTemplateToDefaultFolder = getQueryParam(
      QUERY_PARAM_IS_DUPLICATE_PUBLIC_TEMPLATE_TO_DEFAULT_FOLDER
    );

    if (isDuplicatePublicTemplateToDefaultFolder === 'true') {
      const team: TeamFiltered = yield select(
        teamsModel.selectors.selectCurrentTeamWithError
      );
      const folders: Folder[] = yield select(
        foldersModel.selectors.selectFoldersWithError
      );

      yield call(publicTemplatesModel.sagas.duplicatePage, {
        pageId: params.pageId,
        folderId: folders[0]?.id,
        teamId: team.id,
      });

      return;
    }

    yield fork(publicTemplatesModel.sagas.setInitialData);
    yield takeEvery(
      publicTemplatesModel.actions.loadFolders,
      publicTemplatesModel.sagas.loadFolders
    );
    yield takeEvery(
      publicTemplatesModel.actions.duplicatePage,
      publicTemplatesModel.sagas.duplicatePageWorker
    );
  } else {
    yield call(redirect.toPublicTemplates, {
      pageId: params.pageId,
      queryParams: { [QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE]: params.pageId },
    });
    yield call(redirect.toLogin);
  }
}
