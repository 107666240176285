import { useParams } from 'react-router-dom';
import { TemplatePreviewRaw } from '../../../../features/templates/ui/components';
import { IconMap } from '../../../../shared/sprite';
import { Button } from '../../../../shared/ui';
import { useIframeCommunication } from '../hooks/useIframeCommunication';
import { usePreview } from '../hooks/usePreview';

export const TemplatesIframePreviewContentPage = () => {
  const { id } = useParams<{ id: string }>();
  const { template } = usePreview({ id });

  const { close, apply } = useIframeCommunication({ id });

  return (
    <div
      className="absolute top-0 left-0 w-full h-full"
      style={{
        backgroundColor: 'rgba(1,15,13,0.66)',
      }}
      onClick={close}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute bg-base-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-gray-300 rounded-xl"
        style={{
          width: 'calc(100vw - 20%)',
          height: 'calc(100vh - 40%)',
        }}
      >
        <div className="flex flex-row border-b gap-5 border-b-gray-200 px-8 py-5">
          <Button
            onClick={close}
            type="button"
            variant="icon"
            color="transparent"
            iconLeftName={IconMap.ArrowLeft}
            iconLeftWidth={20}
            className="text-gray-600 -mt-1"
          />
          <div className="flex flex-1 flex-col gap-1">
            <h1 className="text-2xl text-gray-900 font-medium">
              {template?.content?.title ?? 'Untitled'}
            </h1>
            <div className="text-sm text-gray-700">
              {template?.description ?? 'No description'}
            </div>
          </div>
          <div className="flex flex-row gap-8">
            <Button
              onClick={apply}
              type="button"
              variant="text"
              color="primary"
            >
              Start with this Template
            </Button>
            <Button
              onClick={close}
              type="button"
              variant="icon"
              color="transparent"
              iconLeftName={IconMap.CrossClose}
              iconLeftWidth={24}
              className="text-gray-600"
            />
          </div>
        </div>
        <div>
          {template && (
            <TemplatePreviewRaw className="md:px-10" template={template} />
          )}
        </div>
      </div>
    </div>
  );
};
