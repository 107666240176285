import { foldersModel } from '../../../folders/model/index';
import { Folder, Page } from '@distribute/shared/types';
import { put, call, select } from 'redux-saga/effects';
import { pagesModel } from '..';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { redirect } from '../../../../entities/history';
import { pagesApi } from '../../../../shared/api/';
import { handleError } from '../../../../utils/handleError';

export function* deletePage({
  payload: { id, callback },
}: ReturnType<typeof pagesModel.actions.deletePage>) {
  try {
    yield put(actions.setDeletePageIsLoading(true));
    yield pagesApi.deletePage(id);

    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    const newPages = pages.filter((i) => i.id !== id);
    yield put(pagesModel.actions.setPages(newPages));

    const currentFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );
    if (currentFolder) {
      yield call(redirect.toWorkspaceFolder, {
        sequenceNumber: currentFolder.sequenceNumber,
      });
    } else {
      yield call(redirect.toWorkspace);
    }
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Page has been deleted successfully')
      )
    );
    callback();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Failed to delete the page'))
      )
    );
  } finally {
    yield put(actions.setDeletePageIsLoading(false));
  }
}
