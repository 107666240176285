import React from 'react';
import { useSnippetsPermissions } from '../../../features/teams';
import { NoSnippetsFound } from './NoSnippetsFound';
import { noFoundMessages } from '../../../features/snippets/ui/components/lib/config';

export const EmptyPageMemberView: React.FC = () => {
  const { isCanCreatePersonalSnippets } = useSnippetsPermissions();

  return (
    <NoSnippetsFound
      message={
        isCanCreatePersonalSnippets
          ? noFoundMessages.setupPersonalSnippets
          : noFoundMessages.waitForSnippets
      }
    />
  );
};
