import React, { PropsWithChildren } from 'react';
import { Icon, Icons } from '../icons';

type State = { hasError: boolean };

export class PlayerErrorBoundary extends React.Component<
  PropsWithChildren,
  State
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error('Uncaught player error:', error, errorInfo);
  }

  override render() {
    return this.state.hasError ? <PlayerError /> : this.props.children;
  }
}

export const PlayerError = () => {
  return (
    <div className="bg-base-black flex flex-col md:gap-y-2 gap-y-4 items-center justify-center h-full">
      <Icon
        svg={Icons.Error}
        className="text-base-white w-6.5 h-6.5 flex-shrink-0"
      />
      <span className="text-base-white !text-md font-semibold !m-0 whitespace-nowrap">
        This video is unavailable
      </span>
    </div>
  );
};
