import { Button } from '../../../../../shared/ui';
import React from 'react';
import { IconMap } from '../../../../../shared/sprite';

type Props = {
  onClose(): void;
  isAddBtnLoading?: boolean;
  addBtnTitle?: string;
  onSubmit?(): void;
};

export const EditorSidebarConversionsPanelFooter: React.FC<Props> = ({
  onClose,
  isAddBtnLoading,
  addBtnTitle,
  onSubmit,
}) => {
  return (
    <footer className="h-19 fixed border-t border-gray-100 bg-base-white w-120 flex justify-between items-center p-4 bottom-0">
      <Button
        variant="icon-text"
        iconLeftName={IconMap.ArrowLeft}
        iconLeftWidth={20}
        color="secondary"
        onClick={onClose}
      >
        Back
      </Button>
      <Button
        variant="text"
        color="primary"
        type="submit"
        loading={isAddBtnLoading}
        onClick={() => onSubmit?.()}
      >
        {addBtnTitle ?? 'Add'}
      </Button>
    </footer>
  );
};
