import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown } from '../../../../../../shared/ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Page,
  PageTeamMembersPermission,
  UserPermissionToPageRole,
  UserToTeamRole,
} from '@distribute/shared/types';
import {
  pagesModel,
  usePagePermissions,
} from '../../../../../../features/pages';
import { teamsModel } from '../../../../../../features/teams';
import { UserPermissionRow } from '../../../../../../features/pages/ui/share-page-modal/UserPermissionRow';
import { TeamMembersPermissionRow } from '../../../../../../features/pages/ui/share-page-modal/TeamMembersPermissionRow';
import { MemberItemValue, MemberSearchInput } from './MemberSearchInput';
import { ValidationError } from 'yup';
import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import { validateEmailSchema } from './config';

type Props = {
  closeModal(): void;
};

export const InviteOthersTab: React.FC<Props> = ({ closeModal }) => {
  const page: Page = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const [inviteRole, setInviteRole] = useState(UserPermissionToPageRole.EDITOR);
  const [invitedTeamMembers, setInvitedTeamMembers] = useState<
    MemberItemValue[]
  >([]);
  const dispatch = useDispatch();
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const createUserPermissionIsLoading = useSelector(
    pagesModel.selectors.selectCreateUserPermissionIsLoading
  );
  const { isCanInviteMembers, isCanManageAccess } = usePagePermissions(page);

  const currentTeamMembersOptions = useMemo<MemberItemValue[]>(
    () =>
      currentTeamMembers
        .filter(
          (teamMember) =>
            !teamMember.isWaitingForInviteAcceptance &&
            !page.userPermissionToPages.some(
              (i) => i.user?.email === teamMember.user?.email
            )
        )
        .map(({ user }) => ({
          id: user?.id ?? nanoid(),
          email: user?.email ?? '',
          name: user?.displayName ?? '',
          logo: user?.photoUrl,
        })),
    [currentTeamMembers, page.userPermissionToPages]
  );

  const inviteRoles = useMemo(
    () => [
      {
        id: UserPermissionToPageRole.EDITOR,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">Edit</p>
            <p className="text-xs text-gray-500">
              Can view, share, edit, but not manage.
            </p>
          </div>
        ),
        headerLabel: 'Can edit',
        onClick: () => setInviteRole(UserPermissionToPageRole.EDITOR),
      },
      {
        id: UserPermissionToPageRole.VIEWER,
        label: (
          <div>
            <p className="text-sm font-medium text-gray-700 mb-0.5">View</p>
            <p className="text-xs text-gray-500">Can view only</p>
          </div>
        ),
        headerLabel: 'Can view',
        onClick: () => setInviteRole(UserPermissionToPageRole.VIEWER),
      },
    ],
    []
  );

  const handleUpdateTeamMembersPermission = useCallback(
    (permission: PageTeamMembersPermission) => {
      dispatch(
        pagesModel.actions.updateTeamMemberPermission({
          permission,
          pageId: page.id,
        })
      );
    },
    [dispatch, page.id]
  );

  const handleCreateUserPermission = useCallback(() => {
    dispatch(
      pagesModel.actions.createUserPermission({
        pageId: page.id,
        role: inviteRole,
        userEmails: invitedTeamMembers.map((i) => i.email),
        cb: () => {
          setInvitedTeamMembers([]);
        },
      })
    );
  }, [dispatch, page.id, inviteRole, invitedTeamMembers]);

  const handleUpdateUserPermission = useCallback(
    (role: UserPermissionToPageRole, userPermissionId: number) => {
      dispatch(
        pagesModel.actions.updateUserPermission({
          role,
          userPermissionId,
          pageId: page.id,
        })
      );
    },
    [dispatch, page.id]
  );

  const handleResendUserPermissionInvitation = useCallback(
    (userPermissionId: number) => {
      dispatch(
        pagesModel.actions.resendUserPermissionInvitation({
          userPermissionId,
          pageId: page.id,
        })
      );
    },
    [dispatch, page.id]
  );

  const handleDeleteUserPermission = useCallback(
    (userPermissionId: number) => {
      dispatch(
        pagesModel.actions.deleteUserPermission({
          userPermissionId,
          pageId: page.id,
        })
      );
    },
    [dispatch, page.id]
  );

  const validateMemberEmail = (email: string) => {
    try {
      validateEmailSchema.validateSync(email);
      return;
    } catch (error) {
      return (error as ValidationError).errors;
    }
  };

  const membersToInviteHaveError = invitedTeamMembers.some(
    (item) => item.isError
  );

  useEffect(() => {
    if (!isCanManageAccess) {
      closeModal();
    }
  }, [isCanManageAccess, closeModal]);

  const usersToTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const userPermissionsToPageAndTeam = useMemo(() => {
    return page.userPermissionToPages.map((permission) => ({
      userToPage: permission,
      userToTeam: usersToTeam.find(
        (item) =>
          (item.user?.email ?? item.externalUserEmail) ===
          (permission.user?.email ?? permission.externalUserEmail)
      ),
    }));
  }, [page.userPermissionToPages, usersToTeam]);

  return (
    <div
      className={cn('flex flex-col', {
        'pt-5 h-[calc(100%-28px)]': isCanInviteMembers,
        'h-full': !isCanInviteMembers,
      })}
    >
      {isCanInviteMembers && (
        <>
          <div className="flex align-top gap-x-3 sm:flex-col sm:gap-y-3">
            <MemberSearchInput
              selectedItems={invitedTeamMembers}
              items={currentTeamMembersOptions}
              onChange={setInvitedTeamMembers}
              validateItemValue={validateMemberEmail}
              isError={membersToInviteHaveError}
            />
            <div className="flex gap-x-3 sm:justify-end grow-1">
              <Dropdown
                isModalMode={false}
                listStyles="shadow-lg w-90"
                items={inviteRoles}
                currentItemId={inviteRole}
                checkmarkStyles="self-start"
                triggerStyles="!w-30 !h-10 shrink-0 !text-sm !font-semibold !text-gray-600"
              />
              <Button
                color="primary"
                variant="text"
                size="md"
                className="shrink-0 grow-1"
                onClick={handleCreateUserPermission}
                disabled={
                  createUserPermissionIsLoading ||
                  invitedTeamMembers.length === 0 ||
                  membersToInviteHaveError
                }
                loading={createUserPermissionIsLoading}
              >
                Add
              </Button>
            </div>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            The page can be interacted with, by inviting your team members or
            external guests.
          </p>
        </>
      )}
      <hr className="border-gray-200 mt-6" />
      <div className="flex flex-col gap-y-5 pt-5 pb-4 overflow-y-scroll w-[calc(100%+16px)] pr-4">
        <TeamMembersPermissionRow
          permission={page.teamMembersPermission}
          onChangePermission={handleUpdateTeamMembersPermission}
        />
        {userPermissionsToPageAndTeam.map((item) => (
          <UserPermissionRow
            key={item.userToPage.id}
            page={page}
            isGuest={
              item.userToTeam
                ? item.userToTeam?.role === UserToTeamRole.GUEST
                : true
            }
            userPermission={item.userToPage}
            onChangeUserPermission={handleUpdateUserPermission}
            onDeleteUserPermission={handleDeleteUserPermission}
            onResendUserPermissionInvitation={
              handleResendUserPermissionInvitation
            }
          />
        ))}
      </div>
    </div>
  );
};
