import { call, put } from 'redux-saga/effects';

import { popUpApi } from '../../../shared/api';
import { actions } from '../model/reducer';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { PopUp } from '@distribute/shared/types';

export function* setPopUpData(documentContentId: number) {
  try {
    yield put(
      actions.setConversionKitStatus({
        status: 'pending',
        statusType: 'popUpStatus',
      })
    );

    const popUp: PopUp = yield call(popUpApi.getPopUp, documentContentId);

    yield put(actions.setPopUp(popUp));
    yield put(
      actions.setConversionKitStatus({
        status: 'success',
        statusType: 'popUpStatus',
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      actions.setConversionKitStatus({
        status: 'error',
        statusType: 'popUpStatus',
      })
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show pop-up`)
      )
    );
  }
}
