import { call, delay, put, select } from 'redux-saga/effects';
import { PageCreationDataSourcesEnum, createPageModalModel } from '..';
import { getCreatePageWithAIPrompt, getPageGenerationContext } from '../config';
import { authUserModel } from '../../../auth-user';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../../features/teams';
import { gongModel } from '../../../gong';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { pagesModel } from '../../../../features/pages';
import { dataEmbeddingsApi } from '../../../../shared/api/axios/data-embeddings';
import { CreateDataEmbeddingResponseType } from '@distribute/shared/api-types/data-embeddings';

export function* initializePageGenerationWithAI({
  payload: generatePageData,
}: ReturnType<
  typeof createPageModalModel.actions.initializePageGenerationWithAI
>) {
  try {
    yield put(pagesModel.actions.setCreatePageIsLoading(true));

    const user: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const gongCallTranscripts: RT<
      typeof gongModel.selectors.selectCallTranscripts
    > = yield select(gongModel.selectors.selectCallTranscripts);

    if (
      generatePageData.selectedDataSource ===
        PageCreationDataSourcesEnum.GONG &&
      !gongCallTranscripts
    ) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'error',
            'Failed to generate a page. No transcripts were found'
          )
        )
      );
      return;
    }

    const context = getPageGenerationContext(
      generatePageData,
      gongCallTranscripts
    );

    const isTranscript =
      generatePageData.selectedDataSource === PageCreationDataSourcesEnum.GONG;

    const shouldEmbed = !isTranscript || context.length > 1;

    const prompt = getCreatePageWithAIPrompt({
      userName: user.displayName,
      companyName: currentTeam.brandCompanyName ?? 'company',
      aboutCompany: currentTeam.brandCompanyInfo ?? 'further context',
      pageTitle: generatePageData.title,
      pageType: generatePageData.pageType,
      pageTypeDescription: generatePageData.pageTypeDescription,
      prospectName: generatePageData.selectedCallerName,
      isTranscript,
      shouldEmbed,
      context,
    });

    let embeddingResponse: CreateDataEmbeddingResponseType;

    if (shouldEmbed) {
      embeddingResponse = yield call(dataEmbeddingsApi.createEmbedding, {
        context,
        teamId: currentTeam.id,
      });

      const namespace = embeddingResponse.namespace;
      while (true) {
        try {
          const isReady: boolean = yield call(
            dataEmbeddingsApi.getDataEmbeddingStatus,
            namespace
          );

          if (isReady) {
            break;
          }
          yield put(delay(1000));
        } catch (err) {
          break;
        }
      }

      yield put(
        pagesModel.actions.setGeneratePagePromptData({
          teamId: currentTeam.id,
          pageType: generatePageData.pageType,
          isTranscript: false,
          context: {
            id: embeddingResponse.id,
            namespace: embeddingResponse.namespace,
          },
          prompt,
        })
      );
    } else {
      yield put(
        pagesModel.actions.setGeneratePagePromptData({
          teamId: currentTeam.id,
          pageType: generatePageData.pageType,
          isTranscript,
          prompt,
        })
      );
    }

    yield call(generatePageData.generatePage, generatePageData.title);
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to generate page')
      )
    );
  } finally {
    yield put(pagesModel.actions.setCreatePageIsLoading(false));
  }
}
