import { DocumentContent } from '@distribute/shared/types';
import useSetFontFamily from './useSetFontFamily';
import { useEffect } from 'react';

export const useSetDocumentContentStyles = (
  { headingFontFamily, paragraphFontFamily, brandColor }: DocumentContent,
  ref?: React.MutableRefObject<HTMLDivElement | null>
) => {
  useSetFontFamily(
    headingFontFamily,
    '--editor-heading-font-family',
    '--editor-previous-heading-font-family',
    ref
  );

  useSetFontFamily(
    paragraphFontFamily,
    '--editor-paragraph-font-family',
    '--editor-previous-paragraph-font-family',
    ref
  );

  useEffect(() => {
    if (!brandColor) return;

    const element = ref?.current || document.body;

    Object.entries(brandColor).forEach(([number, color]) => {
      element.style.setProperty(`--brand-color-${number}`, `${color}`);
    });

    return () => {
      Object.entries(brandColor).forEach(([number]) => {
        element.style.removeProperty(`--brand-color-${number}`);
      });
    };
  }, [brandColor, ref]);
};
