import { api } from './instance';

type CreateLinkedinPostProps = {
  requestData: FormData;
  text: string;
  title: string;
  contentLandingPage: string;
  userId: string;
};

type CreateTwitterPostProps = {
  requestData: FormData;
  text: string;
};

export const socialApi = {
  async getLinkedinAccessToken({
    code,
    redirectUri,
    clientId,
  }: {
    code: string;
    redirectUri: string;
    clientId: string;
  }) {
    const { data } = await api.get('social/linkedin/access-token', {
      params: {
        code,
        clientId,
        redirectUri,
      },
    });
    return data;
  },

  async getLinkedinProfileData(token: string) {
    const { data } = await api.get('social/linkedin/profile', {
      headers: { 'linkedin-token': token },
    });
    return data;
  },

  async createLinkedinPost(
    token: string,
    {
      requestData,
      title,
      text,
      userId,
      contentLandingPage,
    }: CreateLinkedinPostProps
  ) {
    const { data } = await api.post('social/linkedin/post', requestData, {
      headers: { 'linkedin-token': token },
      params: {
        text,
        userId,
        contentLandingPage,
        title,
      },
    });
    return data;
  },

  async revokeLinkedinToken(token: string, clientId: string) {
    await api.post('social/linkedin/logout', null, {
      headers: { 'linkedin-token': token },
      params: {
        clientId,
      },
    });
  },

  async getTwitterAuthLink(callbackUrl: string) {
    const { data } = await api.get('social/twitter/link', {
      params: {
        callbackUrl,
      },
    });
    return data;
  },

  async getTwitterAccessToken(
    oauth_token: string,
    oauth_verifier: string,
    oauth_token_secret: string
  ) {
    const { data } = await api.get('social/twitter/token', {
      params: {
        oauth_token,
        oauth_verifier,
        oauth_token_secret,
      },
    });
    return data;
  },

  async createTwitterPost(
    token: string,
    tokenSecret: string,
    { requestData, text }: CreateTwitterPostProps
  ) {
    const { data } = await api.post('social/twitter/post', requestData, {
      headers: { 'twitter-token': token, 'twitter-token-secret': tokenSecret },
      params: {
        text,
      },
    });
    return data;
  },

  async getTwitterProfileData(token: string, tokenSecret: string) {
    const { data } = await api.get('social/twitter/profile', {
      headers: { 'twitter-token': token, 'twitter-token-secret': tokenSecret },
    });
    return data;
  },

  async revokeTwitterToken(token: string, tokenSecret: string) {
    await api.post('social/twitter/logout', null, {
      headers: { 'twitter-token': token, 'twitter-token-secret': tokenSecret },
    });
  },
};
