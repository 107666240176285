import { select, put } from 'redux-saga/effects';
import { Folder, TeamFiltered } from '@distribute/shared/types';
import { foldersApi } from '../../../../shared/api/';
import { foldersModel } from '../../../folders';
import { teamsModel } from '../../../teams';

export function* generateFolderId(folderId: string, folderName?: string) {
  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);
  if (folderId === 'newFolder' && folderName) {
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const newFolder: Folder = yield foldersApi.addFolder({
      title: folderName,
      teamId: currentTeam.id,
    });
    yield put(foldersModel.actions.setFolders([...folders, newFolder]));
    yield put(foldersModel.actions.setCurrentFolder(newFolder));
    return newFolder.id;
  }
  const folder: Folder = yield folders.find((i) => i.id === folderId);
  yield put(foldersModel.actions.setCurrentFolder(folder));
  return folderId;
}
