import React, { FC } from 'react';
import classNames from 'classnames';

import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

export type Props = {
  className?: string;
  currentPage: number;
  lastPage: number;
  maxLength: number;
  setCurrentPage: (page: number) => void;
  isHideIfOnlyOnePage?: boolean;
};

const getPaginationItems = (
  currentPage: number,
  lastPage: number,
  maxLength: number
) => {
  const result: Array<number> = [];

  if (lastPage <= maxLength) {
    for (let i = 1; i <= lastPage; i++) {
      result.push(i);
    }
  } else {
    const firstPage = 1;
    const confirmedPagesCount = 3;
    const deductedMaxLength = maxLength - confirmedPagesCount;
    const sideLength = deductedMaxLength / 2;

    if (
      currentPage - firstPage < sideLength ||
      lastPage - currentPage < sideLength
    ) {
      for (let j = 1; j <= sideLength + firstPage; j++) {
        result.push(j);
      }

      result.push(NaN);

      for (let k = lastPage - sideLength; k <= lastPage; k++) {
        result.push(k);
      }
    } else if (
      currentPage - firstPage >= deductedMaxLength &&
      lastPage - currentPage >= deductedMaxLength
    ) {
      const deductedSideLength = sideLength - 1;

      result.push(1);
      result.push(NaN);

      for (
        let l = currentPage - deductedSideLength;
        l <= currentPage + deductedSideLength;
        l++
      ) {
        result.push(l);
      }

      result.push(NaN);
      result.push(lastPage);
    } else {
      const isNearFirstPage = currentPage - firstPage < lastPage - currentPage;
      let remainingLength = maxLength;

      if (isNearFirstPage) {
        for (let m = 1; m <= currentPage + 1; m++) {
          result.push(m);
          remainingLength -= 1;
        }

        result.push(NaN);
        remainingLength -= 1;

        for (let n = lastPage - (remainingLength - 1); n <= lastPage; n++) {
          result.push(n);
        }
      } else {
        for (let o = lastPage; o >= currentPage - 1; o--) {
          result.unshift(o);
          remainingLength -= 1;
        }

        result.unshift(NaN);
        remainingLength -= 1;

        for (let p = remainingLength; p >= 1; p--) {
          result.unshift(p);
        }
      }
    }
  }

  return result;
};

export const Pagination: FC<Props> = ({
  className,
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
  isHideIfOnlyOnePage,
}) => {
  if (isHideIfOnlyOnePage && lastPage <= 1) return null;

  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  return (
    <nav className={classNames('flex flex-wrap justify-between', className)}>
      <Button
        size="sm"
        color="secondary"
        variant="icon-text"
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <span className="flex items-center gap-3">
          <Icon width={20} glyph={IconMap.ArrowLeft} />
          <span>Previous</span>
        </span>
      </Button>
      <div className="flex sm:hidden">
        {pageNums.map((pageNum, idx) => (
          <Button
            key={idx}
            className={classNames('w-10 leading-9', {
              'bg-gray-50 border-0 shadow-transparent': currentPage === pageNum,
            })}
            size="md"
            color={currentPage === pageNum ? 'secondary' : 'link-gray'}
            variant="text"
            disabled={isNaN(pageNum)}
            onClick={() => setCurrentPage(pageNum)}
          >
            {!isNaN(pageNum) ? pageNum : '...'}
          </Button>
        ))}
      </div>
      <Button
        className="flex md:hidden sm:flex"
        size="sm"
        color="secondary"
        variant="icon-text"
        disabled={currentPage === lastPage}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <span className="flex items-center gap-3">
          <span>Next</span>
          <Icon width={20} glyph={IconMap.ArrowRight} />
        </span>
      </Button>
    </nav>
  );
};
