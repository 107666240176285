import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_WRITE_KEY, isDevelopment } from '../../shared/config';
import { IAnalytics, IdentifyTraits } from './types';
import { AnalyticsEvents, EventProperties } from '@distribute/shared/types';

export class Analytics implements IAnalytics {
  private client: AnalyticsBrowser | null = isDevelopment
    ? null
    : AnalyticsBrowser.load({
        writeKey: SEGMENT_WRITE_KEY,
      });

  track(event: AnalyticsEvents, properties?: EventProperties) {
    this.client?.track(event, properties);
  }

  identify(userId: string, traits: IdentifyTraits) {
    this.client?.identify(userId, traits);
  }
}
