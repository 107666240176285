import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { TemplateBgColor } from '@distribute/shared/types';
import { templateBgStyles } from '../../../../features/templates';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  checkClassName?: 'string';
  colorValue: TemplateBgColor;
} & InputHTMLAttributes<HTMLInputElement>;

export const RadioButtonForBgColor = forwardRef<HTMLInputElement, Props>(
  function RadioButton(
    { name, value, onChange, defaultChecked, disabled, colorValue },
    ref
  ) {
    return (
      <Tooltip
        sideOffset={4}
        className="-top-1"
        hideArrow
        trigger={
          <label className="flex flex-row items-center cursor-pointer gap-x-2">
            <span className="relative flex items-center justify-center">
              <input
                type="radio"
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChange}
                defaultChecked={defaultChecked}
                ref={ref}
                className={`border border-light-3 rounded-full appearance-none focus:outline-none cursor-pointer w-8 h-8 hover:border-2 hover:border-custom-bright-green ${templateBgStyles[colorValue].bgColor}`}
              />
              <div
                className={cn(
                  'absolute top-0 left-0 flex justify-center items-center text-primary-700 w-full h-full rounded-full',
                  {
                    hidden: !defaultChecked,
                  }
                )}
              >
                <Icon
                  glyph={IconMap.Check}
                  className={`w-4 h-4 ${templateBgStyles[colorValue].checkColor}`}
                />
              </div>
            </span>
          </label>
        }
      >
        <div className="p-1 text-xs font-semibold">
          {templateBgStyles[colorValue].colorName}
        </div>
      </Tooltip>
    );
  }
);
