import { TemplatesConfigEnum } from '..';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import {
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';

export const getCustomTemplates = (
  templates: TemplateExtended[],
  groupItemKey: TemplatesConfigEnum
) => {
  return templates.filter(
    (el) =>
      el.sharingAccess ===
        (groupItemKey === TemplatesConfigEnum.PERSONAL
          ? TemplateSharingAccess.PERSONAL
          : TemplateSharingAccess.TEAM) &&
      el.category === null &&
      el.creationPhase !== TemplateCreationPhase.DRAFT
  );
};
