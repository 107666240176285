import React, { FC } from 'react';
import { Button, Modal } from '../../../../shared/ui';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
};

export const DiscardResponseModal: FC<IProps> = ({
  isOpen,
  onClose,
  onDiscard,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Discard the AI response"
      actionButton={
        <Button
          fullWidth
          color="destructive"
          variant="text"
          onClick={onDiscard}
        >
          Discard
        </Button>
      }
    >
      <div className="text-sm text-gray-600">
        Are you sure you want to discard AI response? If you proceed with
        discarding, the text produced will be permanently deleted.
      </div>
    </Modal>
  );
};
