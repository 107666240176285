import { FC, useMemo } from 'react';
import {
  UserPermissionToVideoRecord,
  UserPermissionToVideoRecordRole,
} from '@distribute/shared/types';
import { Avatar, Dropdown } from '../../../../../shared/ui';

type Props = {
  userPermissionToVideoRecord: UserPermissionToVideoRecord;
  onUpdateUserPermission: ({
    role,
    userPermissionToVideoRecordId,
  }: {
    role: UserPermissionToVideoRecordRole;
    userPermissionToVideoRecordId: number;
  }) => void;
  onDeleteUserPermission: (userPermissionToVideoRecordId: number) => void;
};

export const UserPermissionRow: FC<Props> = ({
  userPermissionToVideoRecord,
  onUpdateUserPermission,
  onDeleteUserPermission,
}) => {
  const options = useMemo(
    () =>
      [
        {
          id: UserPermissionToVideoRecordRole.EDITOR,
          label: 'Can Edit',
          isShow: true,
          onClick: () =>
            onUpdateUserPermission({
              userPermissionToVideoRecordId: userPermissionToVideoRecord.id,
              role: UserPermissionToVideoRecordRole.EDITOR,
            }),
        },
        {
          id: UserPermissionToVideoRecordRole.VIEWER,
          label: 'Can View',
          isShow: true,
          onClick: () =>
            onUpdateUserPermission({
              userPermissionToVideoRecordId: userPermissionToVideoRecord.id,
              role: UserPermissionToVideoRecordRole.VIEWER,
            }),
        },
        {
          id: 'remove',
          label: 'Remove',
          isSeparatedFromTop: true,
          isShow: true,
          onClick: () => onDeleteUserPermission(userPermissionToVideoRecord.id),
        },
      ].filter((i) => i.isShow),

    //eslint-disable-next-line
    [userPermissionToVideoRecord]
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        <Avatar
          isUserAvatar
          displayName={userPermissionToVideoRecord.user.displayName}
          src={userPermissionToVideoRecord.user.photoUrl}
          size="md"
          className="shrink-0"
        />
        <span className="text-gray-700 text-sm font-medium truncate">
          {userPermissionToVideoRecord.user.displayName}
        </span>
      </div>
      {userPermissionToVideoRecord.role ===
      UserPermissionToVideoRecordRole.OWNER ? (
        <span className="text-sm font-semibold text-gray-600">Owner</span>
      ) : (
        <Dropdown
          isModalMode={false}
          listStyles="shadow-lg"
          items={options}
          currentItemId={userPermissionToVideoRecord.role}
          itemStyles="!w-32 !min-w-32"
          triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
        />
      )}
    </div>
  );
};
