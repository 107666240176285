import { socialApi } from '../../../../../shared/api/axios/social';
import { call, put } from 'redux-saga/effects';
import { GetTwitterAccessTokenResponse } from '../../types';
import { setTwitterAuthData } from './helpers';
import { socialModel } from '../..';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';

type Props = {
  oauth_token: string;
  oauth_verifier: string;
  oauth_token_secret: string;
};

export function* fetchTwitterAccessToken({
  oauth_token,
  oauth_verifier,
  oauth_token_secret,
}: Props) {
  try {
    const data: GetTwitterAccessTokenResponse = yield call(
      socialApi.getTwitterAccessToken,
      oauth_token,
      oauth_verifier,
      oauth_token_secret
    );

    yield call(setTwitterAuthData, data);
    yield put(socialModel.actions.setIsConnectingToTwitter(false));
    yield put(socialModel.actions.setIsLoggedIntoTwitter(true));
  } catch (_) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connected to Twitter')
      )
    );
  }
}
