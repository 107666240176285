import { call, put, select } from 'redux-saga/effects';
import {
  GONG_ACCESS_TOKEN_STORAGE_KEY,
  GONG_BASE_URL,
} from '../../../../shared/constants';
import { gongApi } from '../../../../shared/api';
import { checkGongAccessTokenExpiration } from './checkGongAccessTokenExpiration';
import { GetFormattedCallTranscriptsResponseType } from '@distribute/shared/api-types/gong';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { gongModel } from '..';
import { teamsModel } from '../../../../features/teams';
import { RT } from '../../../../shared/types';

export function* getCallTranscripts() {
  try {
    yield put(gongModel.actions.setIsLoadingTranscripts(true));
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    if (!currentTeam.isGongConnected) {
      yield call(checkGongAccessTokenExpiration);
    }

    const token = localStorage.getItem(GONG_ACCESS_TOKEN_STORAGE_KEY) as string;
    const url = localStorage.getItem(GONG_BASE_URL) as string;
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);
    const toDate = new Date();

    const transcripts: GetFormattedCallTranscriptsResponseType = yield call(
      gongApi.getCallTranscripts,
      {
        url,
        toDate,
        userToken: token,
        fromDate,
        teamId: currentTeam.id,
      }
    );
    yield put(gongModel.actions.setCallTranscriptsData(transcripts));
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to get Gong call transcripts')
      )
    );
  } finally {
    yield put(gongModel.actions.setIsLoadingTranscripts(false));
  }
}
