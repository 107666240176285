import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { Tooltip } from './Tooltip';

type Props = {
  readonly?: boolean;
  placeholder: string;
  valueReadonly: string;
  value: string;
  onChange?: (val: string) => void;
  classNames?: string;
  variant: keyof (typeof styles)['read'];
};

enum Mode {
  Read = 'read',
  Write = 'write',
}

const styles = {
  base: 'truncate',
  editable: 'cursor-pointer',

  read: {
    title: {
      base: 'font-medium text-gray-700 hover:text-gray-800 text-sm inline leading-1 outline-none',
      underline:
        'border-b border-transparent hover:border-gray-800 focus:border-gray-800',
    },
    text: {
      base: 'text-display-sm font-display font-medium inline leading-10 outline-none',
      empty: 'text-gray-400',
      fill: 'text-gray-900',
    },
  },

  write: {
    input:
      'text-gray-900 text-md h-10 px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-lg focus:ring-0 focus:border-primary-600 focus:outline-none w-full',
  },
} as const;

export const SelectableInput: FC<Props> = ({
  readonly = false,
  placeholder,
  valueReadonly,
  value,
  classNames = '',
  onChange,
  variant,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [mode, setMode] = useState<Mode>(Mode.Read);

  const handleChangeToWriteMode = () => {
    setMode(Mode.Write);
    setTimeout(() => ref?.current?.focus(), 0);
  };

  const handleChangeToReadMode = () => {
    setMode(Mode.Read);
  };

  if (mode === Mode.Read) {
    return (
      <Tooltip
        isOpen={readonly || variant === 'text' ? false : undefined}
        hideArrow
        triggerClassNames="truncate"
        trigger={
          <div className={cn(styles.base, classNames)}>
            <div
              tabIndex={0}
              className={cn({
                [styles.read.title.base]: variant === 'title',
                [styles.read.title.underline]: !readonly && variant === 'title',

                [styles.editable]: !readonly,

                [styles.read.text.base]: variant === 'text',
                [styles.read.text.fill]: !!value && variant === 'text',
                [styles.read.text.empty]: !value && variant === 'text',
              })}
              onClick={() => !readonly && handleChangeToWriteMode()}
              onKeyDown={(e) =>
                e.key === 'Enter' && !readonly && handleChangeToWriteMode()
              }
            >
              {valueReadonly}
            </div>
          </div>
        }
        sideOffset={4}
      >
        <p className="p-1 font-semibold text-xs">Rename recording</p>
      </Tooltip>
    );
  }

  return (
    <div className={cn(styles.base, classNames)}>
      <input
        defaultValue={value}
        className={cn(styles.write.input)}
        ref={ref}
        onChange={(e) => onChange?.(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleChangeToReadMode()}
        onBlur={handleChangeToReadMode}
        placeholder={placeholder}
      />
    </div>
  );
};
