import { isDevelopment } from '../../shared/config';

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, EmailAuthProvider } from 'firebase/auth';
import { environment } from '../../environments/environment';

const firebaseApp = initializeApp(environment.firebaseConfig);

const auth = getAuth(firebaseApp);

const providers = {
  EmailAuthProvider: EmailAuthProvider,
};

if (isDevelopment) {
  connectAuthEmulator(auth, 'http://0.0.0.0:9099');
}

export { auth, providers };
