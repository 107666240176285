import { select } from 'redux-saga/effects';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { templatesModel } from '../../templates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

export function* checkIsDraftTemplate(contentId: number) {
  const templates: TemplateExtended[] = yield select(
    templatesModel.selectors.selectTemplates
  );

  const template = templates.find((t) => t.content.id === contentId);

  if (!template) {
    return false;
  }

  return template.creationPhase === TemplateCreationPhase.DRAFT;
}
