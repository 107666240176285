import { Snippet, SnippetType } from '@distribute/shared/types';

export const getFilteredSnippets = (
  snippets: Snippet[],
  searchQuery?: string,
  type?: SnippetType,
  ownerId?: string
) => {
  return snippets.filter((snippet) => {
    const matchesSearch =
      !searchQuery || new RegExp(searchQuery, 'ig').test(snippet.name);
    const matchesType = !type || snippet.type === type;
    const matchesOwner = !ownerId || snippet.owner?.id === ownerId;

    return matchesSearch && matchesType && matchesOwner;
  });
};
