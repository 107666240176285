import { IconMap } from '../../../../../shared/sprite';
import { TabsGroup } from '../../../../../shared/ui';
import React from 'react';
import { PersonalizedLinkMenu } from './PersonalizedLinkMenu';
import { SocialLinkMenu } from './social';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { DistributeTabOptions } from '../../../../../features/editor-sidebar/model/reducer';
import {
  FeatureFlag,
  FeatureFlags,
} from '../../../../../entities/feature-flag';

const DISTRIBUTE_TABS = [
  {
    id: DistributeTabOptions.PERSONALIZED,
    label: 'Personalized link',
    iconName: IconMap.UserCircle,
    iconWidth: 20,
  },
  {
    id: DistributeTabOptions.SOCIAL,
    label: 'Social link',
    iconName: IconMap.Share07,
    iconWidth: 20,
  },
];

export const EditorSidebarDistribute: React.FC = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    editorSidebarModel.selectors.selectSelectedDistributeTab
  );
  const setActiveTab = (tab: DistributeTabOptions) => {
    dispatch(editorSidebarModel.actions.setSelectedDistributeTab(tab));
  };

  return (
    <div className="pt-6 px-6 h-[calc(100%-64px)] overflow-y-auto overflow-x-hidden">
      <h2 className="text-2xl font-display font-medium text-gray-900">
        Distribute
      </h2>
      <hr className="border-gray-200 mt-5 mb-6" />
      <FeatureFlag feature={FeatureFlags.PERSONALIZED_LINKS}>
        <TabsGroup
          active={activeTab}
          setActive={(value) => setActiveTab(value as DistributeTabOptions)}
          options={DISTRIBUTE_TABS}
          className="mb-6"
        />
      </FeatureFlag>
      <div className="pb-6 flex flex-col gap-6">
        <PersonalizedLinkMenu
          isActive={activeTab === DistributeTabOptions.PERSONALIZED}
        />
        <SocialLinkMenu isActive={activeTab === DistributeTabOptions.SOCIAL} />
      </div>
    </div>
  );
};
