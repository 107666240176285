import React, { FC } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';

export type AIMenuItem = {
  id: string;
  label: string;
  text?: string;
  icon?: SpriteGlyph;
  subItems?: AIMenuItem[];
  onClick?: (item: AIMenuItem) => void;
};

type IProps = AIMenuItem & {
  onSelect?: (item: AIMenuItem) => void;
};

const MenuItemContent: FC<AIMenuItem> = ({ label, icon, subItems }) => (
  <div className="flex items-center gap-x-3 p-2 bg-base-white text-sm font-medium text-gray-700 rounded-lg cursor-pointer group-data-[highlighted]:bg-gray-75 group-data-[state='open']:bg-gray-75">
    {icon && (
      <Icon glyph={icon} width={20} className="text-gray-500 shrink-0" />
    )}
    <span className="text-sm font-medium text-gray-700 grow-1 truncate">
      {label}
    </span>
    {subItems ? (
      <Icon
        glyph={IconMap.ChevronRight}
        width={16}
        className="text-gray-400 shrink-0"
      />
    ) : (
      <Icon
        glyph={IconMap.EnterKey}
        width={16}
        className="text-gray-400 shrink-0 hidden group-data-[highlighted]:flex"
      />
    )}
  </div>
);

const CONTENT_MARGIN = 9;
const ITEM_HEIGHT = 36;

export const MenuItem: FC<IProps> = ({ onSelect, ...data }) => {
  const { subItems, onClick } = data;

  if (subItems) {
    return (
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger className="group outline-none px-2">
          <MenuItemContent {...data} />
        </DropdownMenu.SubTrigger>
        <DropdownMenu.SubContent
          className="bg-base-white border-2 border-base-yellow rounded-lg shadow-lg w-50 max-h-50 overflow-auto"
          sideOffset={6}
          alignOffset={
            subItems.length > 2
              ? -(ITEM_HEIGHT + CONTENT_MARGIN * 2)
              : -CONTENT_MARGIN
          }
        >
          <div className="py-2 bg-base-white border border-gray-100 rounded-md">
            {subItems.map((subItem) => (
              <MenuItem {...subItem} key={subItem.id} onSelect={onSelect} />
            ))}
          </div>
        </DropdownMenu.SubContent>
      </DropdownMenu.Sub>
    );
  }

  return (
    <DropdownMenu.Item
      onKeyDown={(e) => {
        if (e.code === 'Space') {
          e.preventDefault();
        }
      }}
      className="group outline-none px-2"
      onSelect={() => {
        onSelect?.(data);
        onClick?.(data);
      }}
    >
      <MenuItemContent {...data} />
    </DropdownMenu.Item>
  );
};
