import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Modal } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';
import { CardForm } from './CardForm';
import { subscriptionModel } from '../../model';
import {
  useCardFormCustomFields,
  useCurrentPlan,
  useSubscriptionInfo,
} from '../../hooks';
import { getPromoCodeDiscountAmount } from '../../lib';
import { PlanName, SubscriptionPromoCode } from '@distribute/shared/types';
import { submitReactHookForm } from '../../../../shared/lib';
import { teamMembersModel } from '../../../team-members';

export const UpdatePaymentMethodModal: FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const {
    id: teamId,
    subscriptionPaymentMethod,
    subscriptionOnboardingPromoCode,
    currentMember,
    isAppsumo,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isUpdatingPaymentMethod = useSelector(
    subscriptionModel.selectors.selectIsUpdatingPaymentMethod
  );
  const { isHasOnboardingPromoCode, isTrial } = useSubscriptionInfo();
  const cardFormCustomFields = useCardFormCustomFields(
    isTrial && !subscriptionPaymentMethod
  );
  const isApplyOnboardingPromoCode =
    isTrial && isHasOnboardingPromoCode && !subscriptionPaymentMethod;

  const currentPlan = useCurrentPlan();

  const isProUser = currentPlan.id === PlanName.PRO;

  const isAppSumoProText = isAppsumo && isProUser;

  const isShowUpdatePaymentMethodModal = useSelector(
    subscriptionModel.selectors.selectIsShowUpdatePaymentMethodModal
  );

  const isWaitingForTeamInviteMembersModalOpen = useSelector(
    teamMembersModel.selectors.selectIsWaitingForTeamInviteMembersModalOpen
  );

  const onClose = () => {
    dispatch(
      subscriptionModel.actions.setIsShowUpdatePaymentMethodModal(false)
    );
  };

  const onConfirmPaymentMethod = () => {
    dispatch(
      subscriptionModel.actions.setIsShowThanksConfirmPaymentMethod(true)
    );
  };

  const handleUpdatePaymentMethod = async () => {
    if (!elements || !stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    const { errors: cardFormCustomFieldsErrors } = await submitReactHookForm(
      cardFormCustomFields.form
    );

    if (submitError || cardFormCustomFieldsErrors) {
      return;
    }

    dispatch(
      subscriptionModel.actions.updatePaymentMethod({
        elements,
        stripe,
        teamId,
        isApplyOnboardingPromoCode,
        cardFormCustomFieldsForm: cardFormCustomFields.form,
        cb: () => {
          onClose();
          if (isWaitingForTeamInviteMembersModalOpen) {
            dispatch(
              teamMembersModel.actions.setIsWaitingForTeamInviteMembersModalOpen(
                false
              )
            );
            dispatch(
              teamMembersModel.actions.setIsShowInviteTeamMembersModal(true)
            );
          }
          if (currentMember.isWaitingForUpdateSubscriptionPaymentMethod) {
            onConfirmPaymentMethod();
          }
        },
      })
    );
  };

  return (
    <Modal
      isOpen={isShowUpdatePaymentMethodModal}
      isShowCloseIconInTitle
      onClose={onClose}
      title={`${subscriptionPaymentMethod ? 'Update' : 'Add'} payment method`}
      className="!w-120 !max-h-[calc(100vh-32px)] !flex !flex-col sm:!w-full"
      actionButton={
        <Button
          fullWidth
          loading={isUpdatingPaymentMethod}
          onClick={handleUpdatePaymentMethod}
          color="primary"
          variant="text"
        >
          {subscriptionPaymentMethod ? 'Update' : 'Add'}
        </Button>
      }
    >
      <div className="flex flex-col min-h-0">
        <div className="-mx-6 border-t border-gray-200" />
        <div className="flex flex-col gap-4 pt-6 pb-2 -mx-6 px-6 min-h-0 overflow-auto">
          {isApplyOnboardingPromoCode && (
            <div className="flex items-center justify-center p-2 bg-custom-yellow rounded-lg text-md text-gray-900">
              <span>
                Add your credit card today to get a{' '}
                <span className="font-semibold">
                  {getPromoCodeDiscountAmount(
                    subscriptionOnboardingPromoCode as SubscriptionPromoCode
                  )}
                </span>{' '}
                discount!
              </span>
            </div>
          )}
          {isAppSumoProText && !subscriptionPaymentMethod && (
            <div className="flex flex-col mb-2 gap-y-2">
              <p className="text-sm font-normal text-gray-600">
                Your AppSumo lifetime license is limited to one member account.
                If you would like to add additional team mates, you must add a
                credit card. Each team member added will cost an additional
                $49.00 per month.
              </p>
              <p className="text-sm font-normal text-gray-600">
                You can also upgrade to the Team Plan or downgrade to the Free
                Plan, however, this will switch your account to the general
                billing conditions.
              </p>
            </div>
          )}
          <CardForm
            cardFormCustomFields={cardFormCustomFields}
            onSubmit={handleUpdatePaymentMethod}
          />
        </div>
      </div>
    </Modal>
  );
};
