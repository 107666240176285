import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { AnalyticsEvents, Page } from '@distribute/shared/types';
import { matchParams } from '../../../shared/lib';
import { pagesModel } from '../../../features/pages';
import {
  redirect,
  WORKSPACE_ANALYTICS_ROUTE,
  WORKSPACE_LEADS_ROUTE,
  WORKSPACE_RECENT_VIEWERS_ROUTE,
} from '../../../entities/history';
import { viewersModel } from '../../../features/viewers';
import { analyticsModel } from '../../../features/analytics';
import { analytics } from '../../../entities/analytics';
import { ViewerCategory } from '@distribute/shared/api-types/viewers';

export function* analyticsWorker(location: Location, route: string) {
  const params = matchParams(location.pathname, route);
  const pages: Page[] = yield select(pagesModel.selectors.selectPages);
  const page = pages.find((i) => params?.sequenceNumber === i.sequenceNumber);

  if (!page) {
    yield call(redirect.toWorkspace);
    return;
  }

  yield put(pagesModel.actions.setCurrentPage(page));
  yield put(viewersModel.actions.setIsDisplayAnonymousToggle(false));

  switch (route) {
    case WORKSPACE_ANALYTICS_ROUTE:
      yield fork(analyticsModel.sagas.setAnalyticsData, page);

      analytics.track(AnalyticsEvents.PAGE_ANALYTIC_OPENED, {
        pageId: page.id,
      });
      break;
    case WORKSPACE_RECENT_VIEWERS_ROUTE:
    case WORKSPACE_LEADS_ROUTE:
      yield takeEvery(
        viewersModel.actions.onChangeRange,
        viewersModel.sagas.onChangeRange
      );
      yield takeEvery(
        viewersModel.actions.onChangeIsShowAnonymous,
        viewersModel.sagas.onChangeIsShowAnonymous
      );
      yield takeEvery(
        viewersModel.actions.onChangeCategory,
        viewersModel.sagas.onChangeCategory
      );
      yield takeLatest(
        viewersModel.actions.getViewersData,
        viewersModel.sagas.getViewersData
      );
      yield takeLatest(
        viewersModel.actions.exportCsv,
        viewersModel.sagas.exportCsv
      );

      //eslint-disable-next-line
      const isLead = route === WORKSPACE_LEADS_ROUTE;

      yield put(
        viewersModel.actions.onChangeCategory(
          isLead ? ViewerCategory.LEAD : ViewerCategory.RECENT_VIEWER
        )
      );
      yield put(viewersModel.actions.setIsDisplayAnonymousToggle(!isLead));

      analytics.track(
        AnalyticsEvents[
          isLead ? 'PAGE_LEADS_OPENED' : 'PAGE_RECENT_VIEWERS_OPENED'
        ],
        {
          pageId: page.id,
        }
      );
      break;
    default:
      return;
  }

  yield takeEvery(
    analyticsModel.actions.resetAnalytics,
    analyticsModel.sagas.resetAnalyticsData
  );
}
