import { mixed, object, string } from 'yup';
import { IconMap } from '../../../../shared/sprite';

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ButtonTextAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export const ModalAlignment = {
  [ButtonTextAlignment.Left]: 'start',
  [ButtonTextAlignment.Center]: 'center',
  [ButtonTextAlignment.Right]: 'end',
} as const;

export const ButtonSizeVariants = {
  [ButtonSize.Small]: 'sm',
  [ButtonSize.Medium]: 'md',
  [ButtonSize.Large]: 'lg',
} as const;

export const ButtonTypeVariants = {
  [ButtonType.Primary]: 'primary',
  [ButtonType.Secondary]: 'secondary',
} as const;

export const ButtonTextAlignmentGlyphs = {
  [ButtonTextAlignment.Left]: IconMap.AlignLeft,
  [ButtonTextAlignment.Center]: IconMap.AlignCenter,
  [ButtonTextAlignment.Right]: IconMap.AlignRight,
} as const;

export const linkRegExp =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export const ButtonSchema = object().shape({
  icon: string(),
  label: string(),
  type: mixed<ButtonType>().oneOf(Object.values(ButtonType)).required(),
  size: mixed<ButtonSize>().oneOf(Object.values(ButtonSize)).required(),
  alignment: mixed<ButtonTextAlignment>()
    .oneOf(Object.values(ButtonTextAlignment))
    .required(),
  link: string()
    .required('This button has no link.')
    .url('This button has wrong link format.'),
});
