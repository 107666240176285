export const messages = {
  permission: 'Permission denied',
  records: {
    getError: 'Failed to show records',
  },
  record: {
    shareError: 'Failed to share record',
    getError: 'Failed to show record',
    updateError: 'Failed to update record',
    deleteError: 'Failed to delete record',
    downloadError: 'Failed to download record',
    copyLink: 'The link has been copied to your clipboard.',
    insertVideoToPageError: 'Failed to add video to the page.',
  },
};

export const formatError = (message: string, status?: number) =>
  status === 403 ? messages.permission : message;

export const DEFAULT_NAME = 'Untitled';

export const INVERTAL_DELAY = 3_000;

export enum SortType {
  LAST_CREATED = 'last-created',
  NAME = 'name',
}

type DropdownItems = Record<string, { text: string; optionText: string }>;

export const sortItems: DropdownItems = {
  [SortType.LAST_CREATED]: { text: 'Last created', optionText: 'Last Created' },
  [SortType.NAME]: { text: 'Name', optionText: 'Name' },
};

export const ALL_OWNERS = 'all-owners';

export const filtersOwnerItems: DropdownItems = {
  [ALL_OWNERS]: { text: 'All owners', optionText: 'All Owners' },
};
