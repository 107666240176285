import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ShareLinkedinPostAction,
  ShareTwitterPostAction,
  UploadPostCoverProps,
} from './types';
import {
  LINKEDIN_TOKEN_STORAGE_KEY,
  TWITTER_TOKEN_STORAGE_KEY,
} from '../../../shared/constants';

type State = {
  linkedinProfileId: string;
  linkedinProfileName: string;
  linkedinProfilePicture: string;
  isSharingLinkedinPost: boolean;
  isRevokingLinkedinToken: boolean;
  isLoggedIntoLinkedin: boolean;
  socialPostContent: string;
  isPostCoverLoading: boolean;
  isConnectingToLinkedin: boolean;
  isConnectingToTwitter: boolean;
  isLoggedIntoTwitter: boolean;
  isSharingTwitterPost: boolean;
  isRevokingTwitterToken: boolean;
  twitterProfileName: string;
  twitterProfilePicture: string;
};

const linkedinAccessToken = localStorage.getItem(LINKEDIN_TOKEN_STORAGE_KEY);
const twitterAccessToken = localStorage.getItem(TWITTER_TOKEN_STORAGE_KEY);

const initialState: State = {
  linkedinProfileId: '',
  linkedinProfileName: '',
  linkedinProfilePicture: '',
  isSharingLinkedinPost: false,
  isRevokingLinkedinToken: false,
  isLoggedIntoLinkedin: !!linkedinAccessToken,
  socialPostContent: '',
  isPostCoverLoading: false,
  isConnectingToLinkedin: false,
  isConnectingToTwitter: false,
  isLoggedIntoTwitter: !!twitterAccessToken,
  isSharingTwitterPost: false,
  isRevokingTwitterToken: false,
  twitterProfileName: '',
  twitterProfilePicture: '',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'social',
  initialState,
  reducers: {
    setLinkedinProfileId: (
      state,
      { payload: linkedinProfileId }: PayloadAction<string>
    ) => ({ ...state, linkedinProfileId }),
    setLinkedinProfileName: (
      state,
      { payload: linkedinProfileName }: PayloadAction<string>
    ) => ({ ...state, linkedinProfileName }),
    setLinkedinProfilePicture: (
      state,
      { payload: linkedinProfilePicture }: PayloadAction<string>
    ) => ({ ...state, linkedinProfilePicture }),
    setIsSharingLinkedinPost: (
      state,
      { payload: isSharingLinkedinPost }: PayloadAction<boolean>
    ) => ({ ...state, isSharingLinkedinPost }),
    setIsLoggedIntoLinkedin: (
      state,
      { payload: isLoggedIntoLinkedin }: PayloadAction<boolean>
    ) => ({ ...state, isLoggedIntoLinkedin }),
    setIsRevokingLinkedinToken: (
      state,
      { payload: isRevokingLinkedinToken }: PayloadAction<boolean>
    ) => ({ ...state, isRevokingLinkedinToken }),
    setSocialPostContent: (
      state,
      { payload: socialPostContent }: PayloadAction<string>
    ) => ({ ...state, socialPostContent }),
    setPostCoverLoading: (
      state,
      { payload: isPostCoverLoading }: PayloadAction<boolean>
    ) => ({ ...state, isPostCoverLoading }),
    setIsConnectingToLinkedin: (
      state,
      { payload: isConnectingToLinkedin }: PayloadAction<boolean>
    ) => ({ ...state, isConnectingToLinkedin }),
    setIsConnectingToTwitter: (
      state,
      { payload: isConnectingToTwitter }: PayloadAction<boolean>
    ) => ({ ...state, isConnectingToTwitter }),
    setIsLoggedIntoTwitter: (
      state,
      { payload: isLoggedIntoTwitter }: PayloadAction<boolean>
    ) => ({ ...state, isLoggedIntoTwitter }),
    setIsSharingTwitterPost: (
      state,
      { payload: isSharingTwitterPost }: PayloadAction<boolean>
    ) => ({ ...state, isSharingTwitterPost }),
    setIsRevokingTwitterToken: (
      state,
      { payload: isRevokingTwitterToken }: PayloadAction<boolean>
    ) => ({ ...state, isRevokingTwitterToken }),
    setTwitterProfileName: (
      state,
      { payload: twitterProfileName }: PayloadAction<string>
    ) => ({ ...state, twitterProfileName }),
    setTwitterProfilePicture: (
      state,
      { payload: twitterProfilePicture }: PayloadAction<string>
    ) => ({ ...state, twitterProfilePicture }),
  },
});

export const actions = {
  ...reducerActions,
  connectToLinkedin: createAction('social/connectToLinkedin'),
  shareLinkedinPost: createAction<ShareLinkedinPostAction>(
    'social/shareLinkedinPost'
  ),
  revokeLinkedinToken: createAction('social/revokeLinkedinToken'),
  fetchLinkedinProfileData: createAction('social/fetchLinkedinProfileData'),
  uploadPostCover: createAction<UploadPostCoverProps>('social/uploadPostCover'),

  connectToTwitter: createAction('social/connectToTwitter'),
  shareTwitterPost: createAction<ShareTwitterPostAction>(
    'social/shareTwitterPost'
  ),
  revokeTwitterToken: createAction('social/revokeTwitterToken'),
};
