import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

const styles = {
  sm: 'w-5 h-4.5 text-xs',
  md: 'w-7.5 h-6 text-md',
} as const;

type Props = {
  size?: keyof typeof styles;
  className?: string;
};

export const PlayerKey: FC<PropsWithChildren<Props>> = ({
  size,
  className = '',
  children,
}) => {
  return (
    <span
      className={cn(
        'flex items-center justify-center overflow-hidden font-base rounded-md border border-base-white/10 bg-base-white/10 text-base-white/75 font-bold',
        size && styles[size],
        className
      )}
    >
      {children}
    </span>
  );
};
