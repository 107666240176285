import React from 'react';
import { useSnippetsPermissions } from '../../../features/teams';
import { CategoriesLayout } from './CategoriesLayout';
import { SnippetsTabs } from './SnippetsTabs';

export const SnippetsContent: React.FC = () => {
  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();

  const isTabView = isCanCreatePersonalSnippets || isCanManageSnippets;

  return (
    <div className="pb-4 flex-grow flex flex-col">
      {isTabView ? <SnippetsTabs /> : <CategoriesLayout />}
    </div>
  );
};
