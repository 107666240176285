import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Color = 'error';

type IProps = {
  color: Color;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
};

const classesByColor: Record<Color, string> = {
  error: 'bg-error-25 text-error-700 border-error-600',
};
const closeIconClassesByColor: Record<Color, string> = {
  error: 'text-error-500',
};

export const Alert: FC<IProps> = ({ color, className, children, onClose }) => {
  return (
    <div
      className={cn(
        'p-4 rounded-xl border font-semibold text-sm',
        classesByColor[color],
        className
      )}
    >
      <div className="flex justify-between items-start">
        {children}
        {onClose && (
          <button onClick={onClose}>
            <Icon
              glyph={IconMap.XClose}
              width={20}
              className={closeIconClassesByColor[color]}
            />
          </button>
        )}
      </div>
    </div>
  );
};
