import { TeamFiltered } from '@distribute/shared/types';
import { teamsApi } from '../../../../shared/api';
import { call, put } from 'redux-saga/effects';
import { actions } from '../reducer';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { handleError } from '../../../../utils/handleError';
import { brandfetchApi } from '../../../../shared/api/axios/brandfetch';
import { generateColorsPalette } from '../../../page-style/lib/generateColorsPalette';

export function* updateTeamBrandInfo({
  payload: { teamId, companyDomain, ...data },
}: ReturnType<typeof actions.updateTeamBrandInfo>) {
  try {
    yield put(actions.setIsUpdatingBrandInfo(true));

    const color: string = yield call(
      brandfetchApi.getBrandColor,
      companyDomain
    );

    const team: TeamFiltered = yield call(teamsApi.updateTeam, teamId, {
      ...data,
      brandColor: color ? generateColorsPalette(color) : undefined,
    });

    yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to update team brand info')
        )
      )
    );
  } finally {
    yield put(actions.setIsUpdatingBrandInfo(false));
  }
}
