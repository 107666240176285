import { call, fork, put } from 'redux-saga/effects';
import { GettingStartedStep } from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { updateTeamsData } from './updateTeamsData';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { gettingStartedModel } from '../../../getting-started/model';

export function* inviteTeamMembersSaga(
  emails: string[],
  teamId: number,
  cb?: () => void
) {
  yield call(teamsApi.inviteTeamMembers, teamId, { emails });
  yield put(
    snackbarModel.actions.addNotificationAction(
      createNotification(
        'success',
        'Your invitations to join the team have been sent successfully'
      )
    )
  );
  cb?.();
  yield call(teamsModel.sagas.loadTeamMembers);
  yield call(
    gettingStartedModel.sagas.onGettingStartedStepCompleted,
    GettingStartedStep.INVITE_TEAM_MEMBER
  );
  yield fork(updateTeamsData);
}

export function* inviteTeamMembers({
  payload: { emails, teamId, cb },
}: ReturnType<typeof actions.inviteTeamMembers>) {
  try {
    yield put(actions.setIsLoadingInviteTeamMembers(true));
    yield call(inviteTeamMembersSaga, emails, teamId, cb);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Invitation sending failed')
        )
      )
    );
  } finally {
    yield put(actions.setIsLoadingInviteTeamMembers(false));
  }
}
