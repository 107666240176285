import React from 'react';
import cn from 'classnames';
import { RichTextEditorDropdownItemType } from './types';
import { Icon, Tooltip } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';

type Props = {
  item: RichTextEditorDropdownItemType;
  itemStyles?: string;
  isShowIconForSelectedItem?: boolean;
  isSelected?: boolean;
  handleClick(): void;
};

export const RichTextEditorDropdownItem: React.FC<Props> = ({
  item,
  ...props
}) => {
  if (item.tooltip) {
    return (
      <Tooltip
        trigger={<RichTextEditorDropdownItemComponent item={item} {...props} />}
        hideArrow
        sideOffset={8}
      >
        {item.tooltip}
      </Tooltip>
    );
  }

  return <RichTextEditorDropdownItemComponent item={item} {...props} />;
};

const RichTextEditorDropdownItemComponent: React.FC<Props> = ({
  item,
  handleClick,
  itemStyles,
  isShowIconForSelectedItem,
  isSelected,
}) => {
  const handleClickItem = () => {
    item.onClick();
    handleClick();
  };

  return (
    <>
      {item.isSeparatedFromTop && (
        <div className="h-0.25 w-full bg-gray-200 my-1" />
      )}
      <div className="px-1.5">
        <div
          className={cn(
            'text-gray-700 text-sm font-medium hover:text-gray-800 hover:bg-gray-100 p-2.25 rounded cursor-pointer transition-all flex items-center gap-x-2',
            {
              '!text-gray-300 hover:!text-gray-300 hover:!bg-transparent !cursor-default':
                item.isDisabled,
            },
            itemStyles
          )}
          onClick={item.isDisabled ? undefined : handleClickItem}
        >
          {item.iconName && <Icon glyph={item.iconName} width={16} />}
          {item.value}
          {isShowIconForSelectedItem && isSelected && (
            <Icon glyph={IconMap.GreenCheckMark} className="ml-auto" />
          )}
        </div>
      </div>
    </>
  );
};
