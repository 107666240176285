import React from 'react';
import { Modal, Button, Input } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { Page } from '@distribute/shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsModel } from '../../../features/analytics';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  page: Page;
};

type ResetAnalyticsModalForm = {
  confirmText: string;
};
const CONFIRMATION_TEXT = 'RESET ANALYTICS';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to delete.`
    ),
});

export const ResetAnalyticsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  page,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    analyticsModel.selectors.selectIsAnalyticsDeleting
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetAnalyticsModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = () => {
    dispatch(
      analyticsModel.actions.resetAnalytics({
        cb: onModalClose,
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Reset Analytics"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Reset Analytics
        </Button>
      }
    >
      <div className="text-sm font-normal text-gray-600 mb-5">
        Are you sure you want to reset analytics for the “
        {page.content.title || 'Untitled'}” page? You will permanently erase all
        analytics data. Your leads will remain.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Type ${CONFIRMATION_TEXT} to confirm`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
