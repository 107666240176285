import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  allowedTypes: string[];
  validationRegexp: RegExp;
  progress?: number;
  isUploading?: boolean;
  onUpload: (file: File) => void;
  accept: string;
  disabled?: boolean;
  maxSize: string;
  type?: string;
};
export const UploadInput: FC<Props> = ({
  progress,
  allowedTypes,
  isUploading,
  onUpload,
  validationRegexp,
  accept,
  disabled = false,
  maxSize,
  type,
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const dispatch = useDispatch();

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      const { type, name } = file;

      if (!validationRegexp.test(type)) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File type not allowed')
          )
        );
        return;
      }

      setFileName(name);
      onUpload(file);
    },
    [validationRegexp, onUpload, dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    onValidateAndUpload(file);
  };

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(true);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const file = e.dataTransfer?.files[0];

      return onValidateAndUpload(file);
    };

    document.body.addEventListener('dragover', enterListener);
    document.body.addEventListener('dragleave', leaveListener);
    document.body.addEventListener('drop', dropListener, {});
    return () => {
      document.body.removeEventListener('dragleave', leaveListener);
      document.body.removeEventListener('dragover', enterListener);
      document.body.removeEventListener('drop', dropListener);
    };
  }, [onValidateAndUpload]);

  return (
    <div className="w-full relative">
      {isUploading ? (
        <div
          className={classNames(
            'relative overflow-hidden p-4 flex rounded-xl items-center justify-center border border-gray-300',
            {
              'bg-gray-50': type,
              'bg-base-white': !type,
            }
          )}
        >
          <div
            style={{ transform: `translateX(${progress}%)` }}
            className="absolute top-0 -left-full w-full h-full bg-gray-50"
          ></div>
          <div className="relative w-full text-gray-700">
            <p className="truncate">{fileName}</p>

            {progress === 100 ? (
              <p>Processing...</p>
            ) : (
              <p>{progress}% uploaded</p>
            )}
          </div>
        </div>
      ) : (
        <label
          className={classNames(
            'rounded-xl p-4 py-5.5 flex items-center flex-col text-gray-600 justify-center',
            {
              'shadow-border-primary': isDragging,
              'shadow-border-gray': !isDragging,
              'shadow-border-gray hover:shadow-border-primary cursor-pointer':
                !disabled,
              'shadow-border-gray bg-gray-50 cursor-default': disabled,
            }
          )}
        >
          {!type && <Icon glyph={IconMap.Upload} width={20} className="mb-3" />}
          <div className="flex">
            {type && (
              <Icon
                glyph={IconMap.Paperclip02}
                width={16}
                className={classNames('hover:cursor-pointer ml-0.5', {
                  'text-gray-300': disabled,
                  'text-primary-700': !disabled,
                })}
              />
            )}
            <span className="text-s">
              <span
                className={classNames('hover:cursor-pointer font-semibold', {
                  'text-gray-300': disabled,
                  'text-primary-700': !disabled,
                  'font-medium': type,
                })}
              >
                {type ? `Select ${type}` : 'Click to upload'}
              </span>{' '}
              or drag and drop {type && 'it here'}
            </span>
          </div>
          <span
            className={classNames('text-xs', {
              'mt-2': type,
              'mt-1': !type,
            })}
          >
            <span className="text-xs font-normal text-gray-600">
              {!type && 'Supports '}
              {allowedTypes.map((i, idx) => {
                if (allowedTypes.length === 1) {
                  return i;
                }

                if (idx === allowedTypes.length - 2) {
                  return i;
                }
                if (idx === allowedTypes.length - 1) {
                  return ` or ${i}`;
                }
                return `${i}, `;
              })}{' '}
              ({type ? 'max file size' : 'max.'} {maxSize})
            </span>
            <input
              ref={ref}
              accept={accept}
              type="file"
              onChange={onChange}
              className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
            />
          </span>
        </label>
      )}
    </div>
  );
};
