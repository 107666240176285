import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  TemplateSidebarTabsEnum,
  editorSidebarModel,
} from '../../../../features/editor-sidebar';

type Props = {
  text: string;
  sidebarKey: TemplateSidebarTabsEnum;
};

export const TemplateEditorSidebarHeaderItem: React.FC<Props> = ({
  text,
  sidebarKey,
}) => {
  const dispatch = useDispatch();
  const selectedTemplateSidebarTab = useSelector(
    editorSidebarModel.selectors.selectTemplateSidebarTab
  );

  const isActive = selectedTemplateSidebarTab === sidebarKey;

  const handleClick = () => {
    dispatch(
      editorSidebarModel.actions.setSelectedTemplateSidebarTab(sidebarKey)
    );
  };

  return (
    <div
      className={cn('h-8 px-2 cursor-pointer', {
        'border-b-2 border-primary-700': isActive,
      })}
      onClick={handleClick}
    >
      <span
        className={cn('text-sm font-semibold', {
          'text-primary-700': isActive,
          'text-gray-500': !isActive,
        })}
      >
        {text}
      </span>
    </div>
  );
};
