import { call, put, select } from 'redux-saga/effects';
import { customDomainsModel } from '..';
import { createNotification, snackbarModel } from '../../../snackbar';
import { CustomDomainType, TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../../teams';
import { customDomainsApi } from '../../../../shared/api';

export function* activateCustomDomain() {
  try {
    yield put(customDomainsModel.actions.setCustomDomainActivating(true));

    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const customDomainInfo: CustomDomainType = yield select(
      customDomainsModel.selectors.selectCustomDomainInfo
    );

    if (!customDomainInfo) {
      throw new Error();
    }

    const updatedCustomDomain: CustomDomainType = yield call(
      customDomainsApi.activateCustomDomain,
      currentTeam.id,
      customDomainInfo.id
    );
    yield put(
      customDomainsModel.actions.setCustomDomainInfo(updatedCustomDomain)
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to activate custom domain')
      )
    );
  } finally {
    yield put(customDomainsModel.actions.setCustomDomainActivating(false));
  }
}
