import React, { FC } from 'react';
import cn from 'classnames';
import { useCurrentPlan, useSubscriptionInfo } from '../../../hooks';
import { Badge } from '../../../../../shared/ui';
import { CurrentPlanInfoMembers } from './CurrentPlanInfoMembers';
import { UpcomingPaymentInfo } from './UpcomingPaymentInfo';
import { UpcomingPaymentStatus } from './UpcomingPaymentStatus';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../../../features/teams';

type IProps = {
  onCancelSubscription: () => void;
  onRenewSubscription: () => void;
};

export const CurrentPlanInfo: FC<IProps> = ({
  onCancelSubscription,
  onRenewSubscription,
}) => {
  const { isPaymentFailed, isPendingCancelation, isTrial } =
    useSubscriptionInfo();
  const currentPlan = useCurrentPlan();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isAppSumoUser = currentTeam.isAppsumo;

  return (
    <div
      className={cn(
        'flex justify-between grow-1 border border-gray-200 bg-base-white rounded-2xl shadow-sm p-8 sm:flex-col sm:gap-4',
        {
          'border-2 border-warning-500 bg-warning-50': isPendingCancelation,
          'border-2 border-error-500 bg-error-50': isPaymentFailed,
        }
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-xl text-gray-900 font-semibold">
            {currentPlan.name} Plan
          </span>
          {isTrial && !isAppSumoUser && (
            <Badge size="md" theme="warning">
              Trial
            </Badge>
          )}
          {isPaymentFailed && (
            <Badge size="md" theme="error">
              Expired soon
            </Badge>
          )}
          {isAppSumoUser && (
            <Badge size="md" theme="informative">
              AppSumo
            </Badge>
          )}
        </div>
        <UpcomingPaymentInfo />
        <CurrentPlanInfoMembers />
      </div>
      <UpcomingPaymentStatus
        onCancelSubscription={onCancelSubscription}
        onRenewSubscription={onRenewSubscription}
      />
    </div>
  );
};
