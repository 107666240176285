import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* changeBrandColor({
  payload: { brandColor, pageId },
}: ReturnType<typeof pagesModel.actions.changeBrandColor>) {
  const currentPage: Page = yield select(
    pagesModel.selectors.selectCurrentPageWithError
  );

  try {
    yield put(
      pagesModel.actions.setCurrentPage({
        ...currentPage,
        content: {
          ...currentPage.content,
          brandColor,
        },
      })
    );
    const page: Page = yield pagesApi.updatePage(pageId, {
      brandColor: brandColor,
    });

    if (!page) {
      throw new Error('updatedPage is undefined');
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(pagesModel.actions.setCurrentPage(currentPage));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change brand color')
      )
    );
  }
}
