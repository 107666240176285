import BulletList from '@tiptap/extension-bullet-list';
import { mergeAttributes } from '@tiptap/react';

export const ToggleListExt = BulletList.extend({
  name: 'toggleList',

  addOptions() {
    return {
      ...this.parent?.(),
      itemTypeName: 'toggleListItem',
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="toggleList"]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': 'toggleList',
      }),
      0,
    ];
  },
});
