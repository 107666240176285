import React, { Fragment, forwardRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AIMenuItem, MenuItem } from './MenuItem';
import { escapeString } from '../../../../shared/lib';

export type AIMenuCategory = {
  id: string;
  items: AIMenuItem[];
  category?: string;
  isSeparatedFromBottom?: boolean;
};
type IProps = {
  isOpen: boolean;
  items: AIMenuCategory[];
  classNames?: string;
  onChange?: (item: AIMenuItem) => void;
  searchValue: string;
  onFocus: () => void;
};

export const Menu = forwardRef<HTMLDivElement, IProps>(
  ({ items, isOpen, classNames, onChange, searchValue, onFocus }, ref) => {
    const searchRegex = useMemo(
      () => new RegExp(escapeString(searchValue), 'ig'),
      [searchValue]
    );

    const filter = useCallback(
      (i: AIMenuItem): boolean => {
        let hasValue =
          searchRegex.test(i.id.toLowerCase()) ||
          searchRegex.test(i.label.toLowerCase());

        if (i.text) {
          hasValue = hasValue || searchRegex.test(i.text.toLowerCase());
        }

        if (!i.subItems) {
          return hasValue;
        }
        return hasValue || i.subItems.filter(filter).length > 0;
      },
      [searchRegex]
    );

    const filteredMenuItems = useMemo(() => {
      return items
        .filter((i) => {
          return (i.items as AIMenuItem[]).filter(filter).length > 0;
        })
        .map((i) => ({
          ...i,
          items: i.items.filter(filter),
          isSeparatedFromBottom:
            searchValue.length > 0 ? false : i.isSeparatedFromBottom,
        }));
    }, [items, filter, searchValue]);

    const hasItems = useMemo(() => {
      return (
        filteredMenuItems.length > 0 &&
        filteredMenuItems.every((i) => i.items.length > 0)
      );
    }, [filteredMenuItems]);

    return (
      <DropdownMenu.Root open={isOpen && hasItems} modal={false}>
        <DropdownMenu.Trigger asChild>
          <div className="absolute top-0 left-0 right-0 bottom-0" />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          ref={ref}
          asChild
          loop
          align="start"
          sideOffset={4}
          onFocus={onFocus}
          avoidCollisions={false}
        >
          <div
            className={cn(
              'bg-base-white border-2 border-base-yellow rounded-lg shadow-lg w-80 max-h-80 overflow-auto',
              classNames
            )}
          >
            <div className="py-2 bg-base-white border border-gray-100 rounded-md">
              {filteredMenuItems.map((item) => (
                <Fragment key={item.id}>
                  {item.category && (
                    <div className="mt-3 mx-4.5 mb-1 text-xs font-medium text-gray-600">
                      {item.category}
                    </div>
                  )}
                  {item.items.map((menuItem) => (
                    <MenuItem
                      key={menuItem.id}
                      {...menuItem}
                      onSelect={onChange}
                    />
                  ))}
                  {item.isSeparatedFromBottom && (
                    <div className="h-0.25 bg-gray-200 mt-2" />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  }
);
