import { history } from '../../entities/history';

export const getQueryParams = () => {
  const { search } = history.location;
  return new URLSearchParams(search);
};

export const getQueryParam = (key: string) => {
  const searchParams = getQueryParams();

  return searchParams.get(key);
};
