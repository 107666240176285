import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button } from '../../shared/ui';
import { IconMap } from '../../shared/sprite';
import { Icon } from '../../shared/ui';

export const ErrorPage: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <>
      <Helmet title="Error" />
      <div
        className="flex flex-col justify-start min-h-full h-fit-content
        bg-primary-50 px-10 py-10 sm:py-6 sm:px-0 md:px-6"
      >
        <div className="flex justify-center">
          <Icon glyph={IconMap.DistributeLogo} />
        </div>
        <div className="flex-grow text-center flex flex-col justify-center items-center">
          <h1 className="mb-4 font-semibold text-l text-center">Error</h1>
          <p className="mb-4 text-s text-center">{error.message}</p>
          <Button
            variant="text"
            onClick={() => document.location.reload()}
            color="primary"
          >
            Reload
          </Button>
        </div>
      </div>
    </>
  );
};
