import { TaskList } from '@tiptap/extension-task-list';

export const TodoListExt = TaskList.extend({
  name: 'todoList',

  addOptions() {
    return {
      itemTypeName: 'todoListItem',
      HTMLAttributes: {
        class: 'todo-list',
      },
    };
  },
});
