import { ReactNode, memo } from 'react';
import cn from 'classnames';

export type Columns<TData> = {
  id: string;
  name: ReactNode;
  thClassName?: string;
  tdClassName?: string;
  getValue: (item: TData) => ReactNode;
};

type IProps<TData> = {
  data: TData[];
  columns: Columns<TData>[];
  className?: string;
  getItemId: (item: TData) => string | number;
};

function TableComponent<TData>({
  data,
  columns,
  className,
  getItemId,
}: IProps<TData>) {
  return (
    <table
      className={cn(
        'w-full border border-gray-200 rounded-xl border-separate border-spacing-0 inline-table overflow-hidden',
        className
      )}
    >
      <thead>
        <tr className="text-xs font-semibold text-gray-600">
          {columns.map((column) => (
            <th
              key={column.id}
              className={cn('px-6 py-3 text-left', column.thClassName)}
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="text-sm text-gray-700 font-normal">
        {data.map((item) => (
          <tr key={getItemId(item)} className="hover:bg-gray-50">
            {columns.map((column) => (
              <td
                key={column.id}
                className={cn(
                  'px-6 py-4 border-t border-t-solid border-t-gray-200',
                  column.tdClassName
                )}
              >
                {column.getValue(item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export const Table = memo(TableComponent) as typeof TableComponent;
