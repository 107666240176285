import { IframeExt } from '@distribute/shared/generate-html';

export const Iframe = IframeExt.extend({
  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ node }) {
    return ['iframe', node.attrs];
  },
});
