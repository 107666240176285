import React, { FC, useMemo } from 'react';
import { FolderTeamMembersPermission } from '@distribute/shared/types';
import { Avatar, Dropdown } from '../../../../shared/ui';
import { useSelector } from 'react-redux';
import { teamsModel } from '../../../teams';

type IProps = {
  permission: FolderTeamMembersPermission;
  onChangePermission: (permission: FolderTeamMembersPermission) => void;
};

export const TeamMembersPermissionRow: FC<IProps> = ({
  permission,
  onChangePermission,
}) => {
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const options = useMemo(
    () => [
      {
        id: FolderTeamMembersPermission.INVITE_ONLY,
        label: 'Invite Only',
        onClick: () =>
          onChangePermission(FolderTeamMembersPermission.INVITE_ONLY),
      },
      {
        id: FolderTeamMembersPermission.EDIT,
        label: 'Can Edit',
        onClick: () => onChangePermission(FolderTeamMembersPermission.EDIT),
      },
      {
        id: FolderTeamMembersPermission.VIEW,
        label: 'Can View',
        onClick: () => onChangePermission(FolderTeamMembersPermission.VIEW),
      },
    ],
    [onChangePermission]
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        <Avatar
          displayName={currentTeam.name}
          src={currentTeam.brandLogoUrl ?? undefined}
          size="md"
          theme="secondary-rect"
          className="shrink-0"
        />
        <span className="text-gray-700 text-sm font-medium truncate">
          Members of {currentTeam.name}
        </span>
      </div>

      <Dropdown
        isModalMode={false}
        listStyles="shadow-lg"
        items={options}
        currentItemId={permission}
        itemStyles="!w-32 !min-w-32"
        triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
      />
    </div>
  );
};
