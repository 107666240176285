import { teamBrandSettingsModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put } from 'redux-saga/effects';
import { ClearbitCompanyInfo, clearbitApi } from '../../../../shared/api';

export function* searchCompanies({
  payload: { searchQuery },
}: ReturnType<typeof teamBrandSettingsModel.actions.searchCompanies>) {
  if (!searchQuery.length) {
    yield put(teamBrandSettingsModel.actions.setCompanies([]));
    return;
  }

  try {
    const data: ClearbitCompanyInfo[] = yield clearbitApi.searchCompanies(
      searchQuery
    );

    if (
      !data.length ||
      data[0].name.toLowerCase() !== searchQuery.toLowerCase()
    ) {
      yield put(
        teamBrandSettingsModel.actions.setCompanies([
          getUnknownCompanyRecord(searchQuery),
          ...data,
        ])
      );
    } else {
      yield put(teamBrandSettingsModel.actions.setCompanies(data));
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to find companies')
      )
    );
  }
}

function getUnknownCompanyRecord(searchQuery: string) {
  return {
    name: searchQuery,
    logo: null,
    domain: 'unknown website',
  };
}
