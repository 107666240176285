import { YOUTUBE_URL_REGEX } from '../../../../../shared/constants';
import { getEmbedUrlFromYoutubeUrl } from '../../../../../entities/tiptap-editor/extensions/YoutubeEmbed/utils';
import { FC } from 'react';

export const IframeSnippet: FC<{ url: string }> = ({ url }) => {
  const youtube = url.match(YOUTUBE_URL_REGEX);
  const embedUrl = youtube ? getEmbedUrlFromYoutubeUrl({ url }) || '' : url;

  return (
    <iframe title="snippet video" src={embedUrl} className="w-full h-full" />
  );
};
