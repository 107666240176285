import React from 'react';
import { useSelector } from 'react-redux';
import { customDomainsModel } from '../model';
import { CustomDomainVerificationStatus } from '@distribute/shared/types';
import {
  ActivateCustomDomainSection,
  CreateCustomDomainSection,
} from './components';
import { DeleteCustomDomainConfirmationModal } from './components/DeleteCustomDomainConfirmationModal';

export const CustomDomainSettings: React.FC = () => {
  const customDomainInfo = useSelector(
    customDomainsModel.selectors.selectCustomDomainInfo
  );

  return (
    <>
      {!customDomainInfo ||
      (customDomainInfo &&
        customDomainInfo.status ===
          CustomDomainVerificationStatus.DNS_RECORD_FOUND) ? (
        <CreateCustomDomainSection />
      ) : (
        <ActivateCustomDomainSection />
      )}
      <DeleteCustomDomainConfirmationModal />
    </>
  );
};
