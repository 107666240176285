import React, { FC } from 'react';
import classNames from 'classnames';

type Size = 'md' | 'sm';

type Props = {
  size?: Size;
  className?: string;
};

const dotClassNameBySize: Record<Size, string> = {
  md: 'w-2 h-2 mx-0.75',
  sm: 'w-1 h-1 mx-0.375',
};

export const LoaderDots: FC<Props> = ({ size = 'md', className }) => (
  <div className={classNames('flex justify-center', className)}>
    {[1, 2, 3].map((i) => (
      <span
        key={i}
        className={classNames(
          `flex bg-primary-600 rounded loading-dot loading-dot--${i}`,
          dotClassNameBySize[size]
        )}
      ></span>
    ))}
  </div>
);
