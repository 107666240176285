import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AlertBanner } from '..';
import { teamsModel } from '../../../../teams';
import { Button } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';

type IProps = {
  onRenew: () => void;
};
export const AlertBannerPaymentFailed: FC<IProps> = ({ onRenew }) => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  if (!stripeSubscription) return null;

  return (
    <AlertBanner
      isShowDowngradeToFreeFeatures
      theme="error"
      title="Your payment failed to go through"
      description={`You'll be downgraded to our Free plan on ${moment(
        stripeSubscription.currentPeriodEnd
      ).format(
        'MMMM D, YYYY'
      )} unless you update a payment method before then. With free plan you will miss:`}
    >
      <Button
        color="primary"
        variant="icon-text"
        iconLeftName={IconMap.CreditCard02}
        iconLeftWidth={20}
        onClick={onRenew}
      >
        Update Payment Method
      </Button>
    </AlertBanner>
  );
};
