import { call, put, select } from 'redux-saga/effects';
import { authUserModel } from '../../../../entities/auth-user';
import { teamsModel } from '..';
import { authApi } from '../../../../shared/api';
import { logger } from '../../../../shared/lib';
import { RT } from '../../../../shared/types';

export function* updateLastSelectedTeam({
  payload: currentTeam,
}: ReturnType<typeof teamsModel.actions.setCurrentTeam>) {
  try {
    if (!currentTeam) return;

    const currentUser: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    if (currentUser.lastSelectedTeamId !== currentTeam.id) {
      yield call(authApi.updateLastSelectedTeam, { teamId: currentTeam.id });
      yield put(
        authUserModel.actions.setDBUser({
          ...currentUser,
          lastSelectedTeamId: currentTeam.id,
        })
      );
    }
  } catch (error: unknown) {
    logger.error(error);
  }
}
