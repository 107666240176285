import { call, delay, put, select } from 'redux-saga/effects';

import { selectRecord } from '../selectors';
import { RT } from '../../../../shared/types';

import { videoRecordsApi } from '../../../../shared/api';

import { logger } from '../../../../shared/lib';
import { actions } from '../reducer';
import { INVERTAL_DELAY } from '../../config';

import { checkIsRecordPrepared } from '../../lib';

export function* watchVideoRecordStatus() {
  try {
    while (true) {
      const record: RT<typeof selectRecord> = yield select(selectRecord);

      if (!record) {
        yield delay(INVERTAL_DELAY);
      }

      if (
        checkIsRecordPrepared({
          statusConvert: record.statusConvert,
          statusMp4: record.statusMp4,
        })
      ) {
        break;
      }

      const data: RT<typeof videoRecordsApi.getStatusAndMetadata> = yield call(
        videoRecordsApi.getStatusAndMetadata,
        record.prefix
      );

      yield put(actions.setRecord({ ...record, ...data }));
      yield delay(INVERTAL_DELAY);
    }
  } catch (e: unknown) {
    logger.error(e);
  }
}
