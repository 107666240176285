import { SubscriptionPlanName } from './subscription';
import { VideoQuality, VideoSessionLimit } from './video-record';

export type PermissionSubscriptionLimitConfig = {
  maxPublishedPagesCount: number;
  maxAIMonthlyResponsesCount: number;
  maxVideoRecords: number;
  maxVideoRecordDuration: number;
  maxVideoRecordSize: number;
  allowVideoDownload: boolean;
  videoSessionLimit: number;
  videoRecordQuality: VideoQuality[];
  videoRecordWatermark: boolean;
  snippets: boolean;
};

export type PermissionSubscriptionLimits = Record<
  SubscriptionPlanName,
  PermissionSubscriptionLimitConfig
>;

export const subscriptionLimits: PermissionSubscriptionLimits = {
  free: {
    maxPublishedPagesCount: 5,
    maxAIMonthlyResponsesCount: 20,
    maxVideoRecords: 10,
    maxVideoRecordDuration: 5, //5min
    maxVideoRecordSize: 1024 * 1024 * 100, //100MB
    allowVideoDownload: false,
    videoSessionLimit: VideoSessionLimit['15'], //15min
    videoRecordQuality: [VideoQuality.HD],
    videoRecordWatermark: true,
    snippets: false,
  },
  pro: {
    maxPublishedPagesCount: Number.POSITIVE_INFINITY,
    maxAIMonthlyResponsesCount: Number.POSITIVE_INFINITY,
    maxVideoRecords: 200,
    maxVideoRecordDuration: 60, //1hour
    maxVideoRecordSize: 1024 * 1024 * 1024, //1GB
    allowVideoDownload: true,
    videoSessionLimit: VideoSessionLimit['90'], //90min
    videoRecordQuality: [VideoQuality.HD, VideoQuality.FULLHD],
    videoRecordWatermark: false,
    snippets: true,
  },
  team: {
    maxPublishedPagesCount: Number.POSITIVE_INFINITY,
    maxAIMonthlyResponsesCount: Number.POSITIVE_INFINITY,
    maxVideoRecords: 200,
    maxVideoRecordDuration: 60, //1hour
    maxVideoRecordSize: 1024 * 1024 * 1024, //1GB
    allowVideoDownload: true,
    videoSessionLimit: VideoSessionLimit['90'], //90min
    videoRecordQuality: [VideoQuality.HD, VideoQuality.FULLHD],
    videoRecordWatermark: false,
    snippets: true,
  },
};
