import { RT } from '../../../shared/types';

import { AnalyticsEvents, GettingStartedStep } from '@distribute/shared/types';
import { call, take } from 'redux-saga/effects';
import { gettingStartedModel } from '../../getting-started/model';
import { logger } from '../../../shared/lib';
import { teamsApi } from '../../../shared/api';
import { analytics } from '../../../entities/analytics';
import { teamsModel } from '../../teams';

export function* finishDownloadExtensionStep() {
  try {
    const {
      payload: currentTeam,
    }: RT<typeof teamsModel.actions.setCurrentTeam> = yield take(
      teamsModel.actions.setCurrentTeam
    );

    if (!currentTeam) return;

    const steps = currentTeam.currentMember.gettingStartedCompletedSteps || [];

    if (steps.includes(GettingStartedStep.DOWNLOAD_CHROME_EXTENSION)) {
      return;
    }

    yield call(teamsApi.updateGettingStartedStep, currentTeam.id, {
      step: GettingStartedStep.DOWNLOAD_CHROME_EXTENSION,
    });

    yield call(
      gettingStartedModel.sagas.onGettingStartedStepCompleted,
      GettingStartedStep.DOWNLOAD_CHROME_EXTENSION
    );

    analytics.track(AnalyticsEvents.EXTENSION_INSTALLED);
  } catch (e) {
    logger.error(e);
  }
}
