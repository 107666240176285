import {
  GenerateUploadUrlResponse,
  GenerateUploadUrlRequest,
} from '@distribute/shared/api-types/media-uploader';
import { getUploadProgress } from '../../lib';
import { api } from './instance';

export const mediaUploadApi = {
  async uploadMedia(
    file: File,
    onUploadProgress: (progress: number) => void,
    pageId: string,
    teamId: number
  ) {
    const { data: responseData } = await api.post(
      'media',
      { upload: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          pageId,
          teamId,
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );
    return responseData as { url: string };
  },

  async generateUploadUrl(data: GenerateUploadUrlRequest) {
    const { data: responseData } = await api.post<GenerateUploadUrlResponse>(
      'media/generate-upload-url',
      data
    );

    return responseData;
  },
};
