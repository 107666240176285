import React, { FC } from 'react';
import { useTiptapEditor } from '../lib';
import { useSelector } from 'react-redux';
import { gatedContentModel } from '../../../features/gated-content-block';

const EDITOR_PADDING_BOTTOM = 32;

export const EditorInsertBottomLine: FC = () => {
  const { editor } = useTiptapEditor();
  const gatedContentEditing = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );

  if (!editor || gatedContentEditing) {
    return null;
  }

  const getHeight = () => {
    const lastElement = editor.view.dom.pmViewDesc?.children.at(-1)?.dom;

    if (lastElement) {
      const lastElementMarginBottom =
        parseInt(
          getComputedStyle(lastElement as Element).getPropertyValue(
            'margin-bottom'
          )
        ) || 0;

      return EDITOR_PADDING_BOTTOM + lastElementMarginBottom;
    }

    return 0;
  };

  return (
    <div
      style={{
        height: getHeight(),
        top: editor?.view.dom.offsetTop + editor.view.dom.scrollHeight - 32,
      }}
      className="absolute left-0 w-full"
      onClick={() =>
        editor
          .chain()
          .insertContentAt(editor.state.doc.content.size, '<p></p>')
          .focus()
          .run()
      }
    />
  );
};
