export enum CronJobName {
  ANALYTICS = 'Analytics',
  LOCATION = 'Location',
  VIDEO_RECORD = 'VideoRecord',
  PAGE_GIF = 'PageGif',
}

export type CronJobManager = {
  id: number;
  isRunning: boolean;
  isActive: boolean;
  name: CronJobName;
  createdAt: Date;
  updatedAt: Date;
};
