import { Plugin } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Editor } from '@tiptap/react';
import { DropColumnPosition } from './DropCursor';
import { addButtonHoveringBlockPluginKey } from '../HoveringBlock';

export interface MultipleColumnsDropCursorOptions {
  color?: string | false;
  width?: number;
  class?: string;
}

const PERMISSIBLE_OFFSET_IN_THE_COLUMN_DROP_POSITION = 20;

type ColumnsDropCursorPositionProps = {
  left: number;
  top: number;
  height: number;
};

export function multipleColumnsDropCursor(
  options: MultipleColumnsDropCursorOptions = {},
  editor: Editor
): Plugin {
  return new Plugin({
    view(editorView) {
      return new MultipleColumnsDropCursorView(editorView, options, editor);
    },
  });
}

class MultipleColumnsDropCursorView {
  width: number;
  color: string | undefined;
  class: string | undefined;
  targetElement: HTMLElement | undefined;
  cursorElement: HTMLElement | undefined;
  draggedElement: Node | undefined;
  editorHandlers: { name: string; handler: (event: Event) => void }[];
  targetNodeHandlers: { name: string; handler: (event: Event) => void }[] = [];
  documentHandlers: { name: string; handler: (event: Event) => void }[];

  constructor(
    readonly editorView: EditorView,
    options: MultipleColumnsDropCursorOptions,
    private readonly editor: Editor
  ) {
    this.width = options.width ?? 1;
    this.color = options.color === false ? undefined : options.color || 'black';
    this.class = `${options.class} column-drop-cursor`;

    this.editorHandlers = this.addEventListeners(
      ['dragover'],
      editorView.dom,
      'Editor'
    );

    this.documentHandlers = this.addEventListeners(
      ['dragend', 'drop'],
      document,
      'Document'
    );
  }

  addEventListeners(
    events: (keyof DocumentEventMap)[],
    element: HTMLElement | Document,
    category: string
  ) {
    return events.map((name) => {
      const handler = (e: Event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[`${name}${category}`](e);
      };

      element.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.editorHandlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler)
    );
    this.documentHandlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler)
    );
    this.removeTargetElementHandlers();
    this.editor.commands.setDropColumnTarget(null);
  }

  clear() {
    this.targetElement = undefined;
    this.removeTargetElementHandlers();
    this.draggedElement = undefined;
  }

  removeTargetElementHandlers() {
    this.targetNodeHandlers.forEach(({ name, handler }) =>
      this.targetElement?.removeEventListener(name, handler)
    );
  }

  removeCursorElement() {
    this.editor.commands.setDropColumnTarget(null);
    if (this.cursorElement) {
      this.cursorElement.parentNode?.removeChild(this.cursorElement);
      this.cursorElement = undefined;
    }
  }

  updateOverlay(e: DragEvent) {
    if (!this.targetElement) {
      return;
    }

    this.editor.commands.setDropColumnTarget(this.targetElement);

    const { left, top, bottom } = this.targetElement.getBoundingClientRect();

    if (e.clientY < top || e.clientY > bottom) return;

    const dropColumnPosition = this.findDropColumnIndex(this.targetElement, e);

    const draggedNodePosition = addButtonHoveringBlockPluginKey.getState(
      this.editor.state
    );

    if (!draggedNodePosition?.hoveredBlockPosition) return;

    const draggedElement = this.editor.view.nodeDOM(
      draggedNodePosition.hoveredBlockPosition
    );

    if (!draggedElement) return;

    if (!this.draggedElement) {
      this.draggedElement = draggedElement;
    }

    if (
      this.targetElement.childElementCount === 4 &&
      this.draggedElement &&
      !Array.from(this.targetElement.childNodes).includes(
        this.draggedElement as ChildNode
      )
    ) {
      return;
    }

    if (!this.cursorElement) {
      const parent = this.editorView.dom.offsetParent as HTMLElement;

      this.cursorElement = parent.appendChild(document.createElement('div'));
      this.cursorElement.style.cssText =
        'position: fixed; z-index: 50; pointer-events: none;';

      if (this.class) this.cursorElement.className = this.class;
      if (this.color) {
        this.cursorElement.style.backgroundColor = this.color;
      }
    }

    this.editor.commands.setDropColumnDirection(
      e.clientX < left ? 'left' : 'right'
    );

    this.editor.commands.setDropColumnPosition(dropColumnPosition);
    this.setColumnsDropCursorPosition(
      this.targetElement,
      dropColumnPosition,
      e.clientX < left,
      this.width
    );
  }

  setColumnsDropCursorPosition(
    columnsRootElement: HTMLElement,
    dropColumnPosition: DropColumnPosition,
    isLeft: boolean,
    cursorWidth: number
  ) {
    if (!this.cursorElement) {
      return;
    }
    if (dropColumnPosition === null) {
      this.removeCursorElement();
      return;
    }

    const { left, top, height } =
      dropColumnPosition === -1
        ? this.getNodeDropCursorPositionProps(
            columnsRootElement,
            isLeft,
            cursorWidth
          )
        : this.getColumnsDropCursorPositionProps(
            columnsRootElement,
            dropColumnPosition,
            cursorWidth
          );

    this.cursorElement.style.left = `${left}px`;
    this.cursorElement.style.top = `${top}px`;
    this.cursorElement.style.height = `${height}px`;
    this.cursorElement.style.width = `${this.width}px`;
  }

  getNodeDropCursorPositionProps(
    nodeElement: HTMLElement,
    isLeft: boolean,
    cursorWidth: number
  ): ColumnsDropCursorPositionProps {
    const { top, left, right, height } = nodeElement.getBoundingClientRect();
    return {
      top,
      height,
      left: isLeft ? left - cursorWidth : right + cursorWidth,
    };
  }

  getColumnsDropCursorPositionProps(
    columnsRootElement: HTMLElement,
    dropColumnPosition: number,
    cursorWidth: number
  ): ColumnsDropCursorPositionProps {
    const columns = Array.from(columnsRootElement.childNodes);

    if (dropColumnPosition === columns.length) {
      const { top, right, height } = columnsRootElement.getBoundingClientRect();
      return {
        left: right + cursorWidth,
        top,
        height,
      };
    }

    const { top, left, height } = (
      columns[dropColumnPosition] as HTMLElement
    ).getBoundingClientRect();
    return { top, left: left - cursorWidth, height };
  }

  findDropColumnIndex(targetElement: HTMLElement, e: DragEvent) {
    if (!targetElement.classList.contains('column-block')) {
      return -1;
    }
    const columns = targetElement.childNodes;
    const columnsRects = Array.from(columns).map((columnElement) =>
      (columnElement as HTMLDivElement).getBoundingClientRect()
    );

    return columnsRects.reduce(
      (foundIndex: null | number, columnRect, index, columnRectsArray) => {
        if (foundIndex !== null) return foundIndex;

        if (
          index === 0 &&
          e.clientX <=
            columnRect.x + PERMISSIBLE_OFFSET_IN_THE_COLUMN_DROP_POSITION
        ) {
          return 0;
        }

        if (
          index === columnRectsArray.length - 1 &&
          e.clientX >=
            columnRect.right - PERMISSIBLE_OFFSET_IN_THE_COLUMN_DROP_POSITION
        ) {
          return columnRectsArray.length;
        }

        if (
          e.clientX <=
            columnRect.x + PERMISSIBLE_OFFSET_IN_THE_COLUMN_DROP_POSITION &&
          e.clientX >=
            columnRectsArray[index - 1].right -
              PERMISSIBLE_OFFSET_IN_THE_COLUMN_DROP_POSITION
        ) {
          return index;
        }

        return null;
      },
      null
    );
  }

  dragoverEditor(event: DragEvent) {
    if (!this.editorView.editable) return;

    const pos = this.editorView.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });
    const contentFirstLevelNodes = this.editorView.dom.pmViewDesc?.children;

    if (!pos || !contentFirstLevelNodes) return;

    let targetElement: HTMLElement | undefined;

    this.editorView.state.doc.nodesBetween(pos.pos, pos.pos, (node, pos) => {
      if (contentFirstLevelNodes.some((i) => i.node === node)) {
        const domElement = this.editorView.nodeDOM(pos);

        if (domElement) {
          targetElement = domElement as HTMLElement;
        }
      }
    });

    if (this.targetElement === targetElement) return;
    if (!targetElement?.classList.contains('column-block')) {
      this.removeCursorElement();
    }

    this.removeTargetElementHandlers();
    this.targetElement = targetElement;

    if (this.targetElement) {
      this.targetNodeHandlers = this.addEventListeners(
        ['dragleave'],
        this.targetElement,
        'TargetElement'
      );
    }
  }

  dragleaveTargetElement(e: DragEvent) {
    this.updateOverlay(e);
    this.targetElement = undefined;
  }

  dragendDocument() {
    this.clear();
    this.removeCursorElement();
  }

  dropDocument() {
    this.clear();
    this.removeCursorElement();
  }
}
