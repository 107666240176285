import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactSupportAction } from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isLoadingContactSupport: boolean;
};

const initialState: State = {
  isLoadingContactSupport: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'contactSupportForm',
  initialState,
  reducers: {
    setIsLoadingContactSupport: (
      state,
      { payload: isLoadingContactSupport }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingContactSupport,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  contactSupport: createAction<ContactSupportAction>(
    'contactSupportForm/contactSupport'
  ),
};
