export const formatFileSizeToHuman = (bytes: number) => {
  const step = 1024;

  if (bytes < step) return bytes + ' B';

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = -1;

  do {
    bytes /= step;
    ++unitIndex;
  } while (bytes >= step);

  return bytes.toFixed(1) + ' ' + units[unitIndex];
};
