import React, { FC, ReactNode } from 'react';
import { RequirePaidPlanActionBadge } from './RequirePaidPlanActionBadge';

type IProps = {
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  requirePaidPlan?: boolean;
};

export const Section: FC<IProps> = ({
  children,
  title,
  actions,
  requirePaidPlan,
}) => {
  return (
    <div className="mt-8">
      <div className="flex justify-between items-center font-display font-medium text-display-xs text-gray-900 pb-5 border-b border-gray-200">
        <div className="flex items-center gap-2">
          <span>{title}</span>
          {requirePaidPlan && <RequirePaidPlanActionBadge />}
        </div>
        {actions}
      </div>
      <div className="pt-6">{children}</div>
    </div>
  );
};
