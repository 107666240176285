import { EmbedContentCheckLinkResponseType } from '@distribute/shared/api-types/embed-content';
import { api } from './instance';

export const embedContentApi = {
  checkLink: (link: string) => {
    const controller = new AbortController();
    const request = api.post<EmbedContentCheckLinkResponseType>(
      `embed-content/check-link`,
      {
        link,
      },
      {
        signal: controller.signal,
      }
    );

    return {
      request,
      controller,
    };
  },
};
