import React from 'react';

export const NoData = () => (
  <div className="flex flex-col items-center pt-30">
    <div className="text-xl font-medium text-gray-900">No data</div>
    <div className="text-md font-normal text-gray-600 mt-2">
      We couldn't find any data for that date range
    </div>
  </div>
);
