import { call, put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { profileSettingModel } from '..';
import {
  EmailAuthProvider,
  linkWithCredential,
  reauthenticateWithCredential,
} from 'firebase/auth';
import {
  authUserModel,
  getUserFromFb,
  setUserData,
} from '../../../../entities/auth-user';
import { User } from '@distribute/shared/types';
import { FirebaseUser } from '../../../../shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* createUserPassword(
  createUserPasswordData: ReturnType<typeof actions.createUserPassword>
) {
  const {
    payload: { password, confirmPassword, cb },
  } = createUserPasswordData;

  if (password !== confirmPassword) {
    throw new Error('The passwords do not match!');
  }

  yield put(profileSettingModel.actions.setIsUserPasswordCreating(true));
  const { email }: User = yield select(
    authUserModel.selectors.selectFBUserWithError
  );

  const fbUser: FirebaseUser = yield getUserFromFb();
  try {
    const credentials = EmailAuthProvider.credential(email, password);
    yield linkWithCredential(fbUser, credentials);
    yield reauthenticateWithCredential(fbUser, credentials);
    yield call(setUserData);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your password has been successfully created.'
        )
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create new password.')
      )
    );
  }

  yield put(profileSettingModel.actions.setIsUserPasswordCreating(false));
  cb();
}
