import { RefObject, useEffect, useState } from 'react';

export function useResizeObserver(
  elementRef: RefObject<Element>
): ResizeObserverEntry | undefined {
  const [entry, setEntry] = useState<ResizeObserverEntry>();

  useEffect(() => {
    const node = elementRef?.current;
    const hasSupport = !!window.ResizeObserver;

    if (!hasSupport || !node) return;

    const observer = new ResizeObserver(([entry]) => setEntry(entry));
    observer.observe(node);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return entry;
}
