import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useLayoutEffect,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import cn from 'classnames';

export type SearchSuggestion = {
  placeholder: string;
  text: string;
};

type IProps = {
  value: string;
  searchSuggestion?: SearchSuggestion;
  onChange: (value: string) => void;
  isShowActionAfterWriting: boolean;
  isShowCurrentResponse: boolean;
  onFocus: () => void;
  onSubmit: () => void;
};

const INITIAL_INPUT_HEIGHT = 24;

export const SearchInput = forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      value,
      searchSuggestion,
      onChange,
      onFocus,
      onSubmit,
      isShowActionAfterWriting,
      isShowCurrentResponse,
    },
    ref
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = `${INITIAL_INPUT_HEIGHT}px`;
        textAreaRef.current.style.height = `${
          textAreaRef.current.scrollHeight || INITIAL_INPUT_HEIGHT
        }px`;
      }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Tab' && searchSuggestion) {
        e.preventDefault();
        onChange(`${value}${searchSuggestion.text}`);
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        if (value.length) {
          onSubmit();
        }
      }
    };

    return (
      <textarea
        ref={mergeRefs([textAreaRef, ref])}
        onFocus={onFocus}
        className={cn(
          'w-full text-md text-gray-800 placeholder:text-gray-400 outline-none bg-transparent relative z-10 h-6  resize-none',
          {
            'max-h-76': !isShowCurrentResponse,
            'max-h-38': isShowCurrentResponse,
          }
        )}
        placeholder={
          isShowActionAfterWriting
            ? 'Tell wizard what to do next'
            : 'Ask me anything, or choose an option below...'
        }
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    );
  }
);
