import { Extension } from '@tiptap/core';

import { ColumnExt } from './Column';
import { ColumnBlockExt } from './ColumnBlock';
export interface ColumnExtensionOptions {
  column?: boolean;
  columnBlock?: boolean;
}

export const ColumnsExt = Extension.create<ColumnExtensionOptions>({
  name: 'columnExtension',

  addExtensions() {
    const extensions = [];

    if (this.options.column !== false) {
      extensions.push(ColumnExt);
    }

    if (this.options.columnBlock !== false) {
      extensions.push(ColumnBlockExt);
    }

    return extensions;
  },
});

export { ColumnExt, ColumnBlockExt };
