import { call, put, select } from 'redux-saga/effects';
import { profileSettingModel } from '..';
import {
  authUserModel,
  getUserFromFb,
  setUserData,
} from '../../../../entities/auth-user';
import { GOOGLE_PROVIDER_ID, PASSWORD_PROVIDER_ID } from '../../lib';
import { unlink } from 'firebase/auth';
import { FirebaseUser } from '../../../../shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* disconnectGmailAuthentication() {
  const authProviders: string[] = yield select(
    authUserModel.selectors.selectAuthProviders
  );

  if (
    !(
      authProviders.includes(GOOGLE_PROVIDER_ID) &&
      authProviders.includes(PASSWORD_PROVIDER_ID)
    )
  ) {
    return;
  }

  yield put(profileSettingModel.actions.setIsGmailAuthDisconnecting(true));
  const fbUser: FirebaseUser = yield getUserFromFb();

  try {
    const updatedUser: FirebaseUser = yield unlink(fbUser, GOOGLE_PROVIDER_ID);
    yield call(setUserData, updatedUser);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'You have successfully disconnected your Google account.'
        )
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to disconnect from Google')
      )
    );
  } finally {
    yield put(profileSettingModel.actions.setIsGmailAuthDisconnecting(false));
  }
}
