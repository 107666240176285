import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { isCancel } from 'axios';
import cn from 'classnames';
import { PromoCode } from '../../../types';
import { Button, Icon, Input } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { subscriptionApi } from '../../../../../shared/api';
import { teamsModel } from '../../../../teams';
import { getPromoCodeDiscountAmount } from '../../../lib';

type IProps = {
  promoCode: PromoCode | null;
  onDelete: () => void;
  onApply: (promoCode: PromoCode) => void;
};

export const PromoCodeInput: FC<IProps> = ({
  promoCode,
  onDelete,
  onApply,
}) => {
  const [isShowInput, setIsShowInput] = useState(false);
  const [promoCodeValue, setValidatedPromoCodeValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validatedPromoCode, setValidatedPromoCode] =
    useState<PromoCode | null>(null);
  const { id: teamId } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const abortController = useRef<AbortController | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleValidatePromoCode = useCallback(
    debounce(async (code: string) => {
      abortController.current?.abort();
      abortController.current = new AbortController();

      try {
        setIsValidating(true);

        const promoCode = await subscriptionApi.validatePromoCode(
          { code, teamId },
          abortController.current.signal
        );

        setValidatedPromoCode(promoCode);
        setIsInvalid(false);
        setIsValidating(false);
      } catch (error) {
        if (!isCancel(error)) {
          setValidatedPromoCode(null);
          setIsInvalid(true);
          setIsValidating(false);
        }
      }
    }, 400),
    [teamId, isShowInput]
  );

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValidatedPromoCodeValue(value);
    handleValidatePromoCode(value);
  };

  const handleReset = () => {
    abortController.current?.abort();
    setValidatedPromoCodeValue('');
    setIsInvalid(false);
    setValidatedPromoCode(null);
  };

  const handleCancel = () => {
    setIsShowInput(false);
    handleReset();
  };

  const handleApply = () => {
    if (!validatedPromoCode) return;

    onApply(validatedPromoCode);
    handleCancel();
  };

  if (promoCode) {
    return (
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <div className="text-sm font-medium">Promo Code:</div>
          <div className="flex px-2 py-0.75 bg-warning-100 rounded-md text-gray-700">
            <Icon glyph={IconMap.Tag02} width={16} />
            <span className="text-xs font-medium ml-1 mr-2 uppercase">
              {promoCode.stripeCode}
            </span>
            <button className="outline-none" onClick={onDelete}>
              <Icon glyph={IconMap.XClose} width={16} />
            </button>
          </div>
        </div>
        <div className="text-sm font-semibold text-gray-800">
          -{getPromoCodeDiscountAmount(promoCode)}
        </div>
      </div>
    );
  }

  if (!isShowInput) {
    return (
      <button
        className="text-sm font-semibold text-primary-700 outline-none"
        onClick={() => setIsShowInput(true)}
      >
        Add Promo Code
      </button>
    );
  }

  return (
    <div className="flex gap-2">
      <div className="relative grow-1">
        <Input
          type="text"
          heightSize="sm"
          className={cn('uppercase', { '!pr-7': promoCodeValue })}
          value={promoCodeValue}
          onChange={handleChange}
          isError={isInvalid}
          iconSrc={promoCodeValue ? IconMap.XCircle : undefined}
          iconRightClassNames="!text-gray-700"
          onClickIconRight={handleReset}
          messageText={isInvalid ? 'This code is invalid.' : undefined}
        />
      </div>
      <div className="flex gap-4">
        <Button
          disabled={isInvalid || isValidating}
          variant="icon"
          color="primary"
          size="sm"
          onClick={handleApply}
        >
          <Icon glyph={IconMap.Check} width={20} />
        </Button>
        <Button
          variant="icon"
          color="secondary"
          size="sm"
          onClick={handleCancel}
        >
          <Icon glyph={IconMap.XClose} width={20} />
        </Button>
      </div>
    </div>
  );
};
