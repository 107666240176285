import { FC } from 'react';

import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';

import { useHotkeys as hotKeys } from 'react-hotkeys-hook';
import { usePlayer } from '../../lib/use-player';
import { ActionKind } from '../../provider';

export const PlayerHotKeys: FC = () => {
  const { dispatch, config } = usePlayer();
  const { wrapperRef } = config;

  hotKeys('m', () => dispatch({ type: ActionKind.TOGGLE_VOLUME }));
  hotKeys('s', () => dispatch({ type: ActionKind.PLAYBACK_RATE }));
  hotKeys('f', () => {
    screenfull.isEnabled &&
      screenfull.toggle(findDOMNode(wrapperRef.current) as Element);
  });

  return null;
};
