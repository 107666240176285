import { call, put, select } from 'redux-saga/effects';

import { squeezePageApi } from '../../../../shared/api';
import { SqueezePage } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectSqueezePage } from '../selectors';

export function* updateSqueezePageDB({
  payload: { documentContentId },
}: ReturnType<typeof conversionKitModel.actions.updateSqueezePageDB>) {
  try {
    const squeezePage: SqueezePage = yield select(selectSqueezePage);

    const { id: _, ...data } = squeezePage;

    yield call(squeezePageApi.updateSqueezePage, documentContentId, data);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update squeeze page`)
      )
    );
  }
}
