export enum AIWritingActionType {
  DRAFT_WRITE = 'DRAFT_WRITE',
  CONTINUE_WRITING = 'CONTINUE_WRITING',
  MAKE_LONGER = 'MAKE_LONGER',
  MAKE_SHORTER = 'MAKE_SHORTER',
  IMPROVE_WRITING = 'IMPROVE_WRITING',
  FIX_WRITING = 'FIX_WRITING',
  CHANGE_TONE = 'CHANGE_TON',
  SIMPLIFY = 'SIMPLIFY',
  SUMMARIZE = 'SUMMARIZE',
}

export enum AITone {
  PROFESSIONAL = 'professional',
  CASUAL = 'casual',
  STRAIGHTFORWARD = 'straightforward',
  CONFIDENT = 'confident',
  FRIENDLY = 'friendly',
}
export type AiCompletionRequestPayloadType =
  | DraftRequest
  | ChangeToneRequest
  | RequestsWithContext;

export type AICompletionRequestType = {
  teamId: number;
} & AiCompletionRequestPayloadType;

type DraftRequest = {
  type: AIWritingActionType.DRAFT_WRITE;
  input: string;
  context?: string;
};

type ChangeToneRequest = {
  type: AIWritingActionType.CHANGE_TONE;
  tone: AITone;
  context: string;
  input?: string;
};
export type ContextActionType =
  | AIWritingActionType.CONTINUE_WRITING
  | AIWritingActionType.MAKE_LONGER
  | AIWritingActionType.MAKE_SHORTER
  | AIWritingActionType.SIMPLIFY
  | AIWritingActionType.SUMMARIZE
  | AIWritingActionType.IMPROVE_WRITING
  | AIWritingActionType.FIX_WRITING;

type RequestsWithContext = {
  type: ContextActionType;
  context: string;
  input?: string;
};

export const contextRequestTypes = [
  AIWritingActionType.CONTINUE_WRITING,
  AIWritingActionType.MAKE_LONGER,
  AIWritingActionType.MAKE_SHORTER,
  AIWritingActionType.SIMPLIFY,
  AIWritingActionType.SUMMARIZE,
  AIWritingActionType.IMPROVE_WRITING,
  AIWritingActionType.FIX_WRITING,
];

export enum AIErrorType {
  MODERATION_FAILED = 'MODERATION_FAILED',
  TOO_LARGE_REQUEST = 'TOO_LARGE_REQUEST',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
