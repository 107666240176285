import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { TemplateCreationPhase } from '@distribute/shared/types';
import {
  TEMPLATES_DRAFTS_ROUTE,
  TEMPLATES_ROUTE,
} from '../../../entities/history';

export const getTemplatesRoute = (template?: TemplateExtended) => {
  if (!template) {
    return TEMPLATES_ROUTE;
  }

  if (template.creationPhase !== TemplateCreationPhase.READY_TO_USE) {
    return TEMPLATES_DRAFTS_ROUTE;
  }

  return `${TEMPLATES_ROUTE}/${template.sharingAccess}`;
};
