import { call, put } from 'redux-saga/effects';
import { auth } from '../../../shared/config';
import {
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { createNotification, snackbarModel } from '../../snackbar';
import { authApi } from '../../../shared/api';
import { AnalyticsEvents } from '@distribute/shared/types';
import { analytics } from '../../../entities/analytics';
import { getQueryParam } from '../../../shared/lib';
import { authModel } from '../../../entities/auth-operation';
import { FirebaseError } from 'firebase/app';
import { QUERY_PARAM_PARTNER_STACK_XID } from '../config';
import { redirectAfterSignUp } from '../helpers/redirectAfterSignUp';

export function* googleSignUp() {
  try {
    yield;
    const provider = new GoogleAuthProvider();
    const signupResult: UserCredential = yield call(
      signInWithPopup,
      auth,
      provider
    );

    const { email, displayName } = signupResult.user;

    if (!email || !displayName) {
      return;
    }
    const partnerStackXID =
      getQueryParam(QUERY_PARAM_PARTNER_STACK_XID) ?? undefined;

    yield authApi.onGoogleRegister({
      email,
      displayName,
      partnerStackXID: partnerStackXID,
    });

    analytics.track(AnalyticsEvents.GMAIL_SIGN_UP_COMPLETED);

    yield put(authModel.actions.setIsWaitingToRedirectAfterAuth(true));

    yield call(redirectAfterSignUp, 'onboarding');
  } catch (e) {
    if (!(e as FirebaseError).message.includes('popup-closed-by-user')) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to Sign Up with Google')
        )
      );
    }
  }
}
