import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GettingStarted } from '../../../features/getting-started';

export const GettingStartedPage = () => {
  return (
    <>
      <Helmet title="Getting started" />
      <GettingStarted />
    </>
  );
};
