import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';
import { FC } from 'react';

type Props = {
  isSharedWithTeam: boolean;
  membersCount: number;
};

export const RecordMemberStatus: FC<Props> = ({
  isSharedWithTeam,
  membersCount,
}) => {
  if (isSharedWithTeam) {
    return (
      <div className="flex flex-nowrap gap-x-1 items-center">
        <Icon glyph={IconMap.Users01} width={16} />
        <p>All members</p>
      </div>
    );
  }

  if (membersCount <= 1) {
    return <p>Not shared</p>;
  }

  return (
    <div className="flex flex-nowrap gap-x-1 items-center">
      <Icon glyph={IconMap.Users01} width={16} />
      <p>{`${membersCount} members`}</p>
    </div>
  );
};
