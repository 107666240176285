import { Icon } from '../../../../shared/ui';
import classNames from 'classnames';
import React from 'react';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { EditorPreviewModeEnum } from '../../../../features/pages/model/types';
import { pagesModel } from '../../../../features/pages';

const options = [
  {
    id: '1',
    label: EditorPreviewModeEnum.DESKTOP,
    value: 'desktop',
    iconName: IconMap.DesktopIcon,
  },
  {
    id: '2',
    label: EditorPreviewModeEnum.TABLET,
    value: 'tablet',
    iconName: IconMap.TabletIcon,
  },
  {
    id: '3',
    label: EditorPreviewModeEnum.MOBILE,
    value: 'mobile',
    iconName: IconMap.MobileIcon,
  },
];

export const PreviewBar: React.FC = () => {
  const dispatch = useDispatch();
  const activePreview = useSelector(
    pagesModel.selectors.selectEditorPreviewMode
  );
  const setActivePreview = (mode: EditorPreviewModeEnum) => {
    dispatch(pagesModel.actions.setEditorPreviewMode(mode));
  };

  return (
    <div className="flex-1 flex justify-center shrink-0">
      <div
        className={classNames(
          'h-9 flex items-center border border-gray-300 -m-px rounded-lg flex-shrink-0 w-fit-content'
        )}
      >
        {options.map(({ label, value, iconName }) => (
          <label
            className={classNames(
              'cursor-pointer py-2.5 px-4 border-r border-gray-300 h-full hover:bg-gray-50',
              {
                'md:hidden rounded-tl-lg rounded-bl-lg': label === 'Desktop',
                'bg-gray-50': activePreview === label,
                'bg-base-white': activePreview !== label,
                'md:rounded-tl-lg md:rounded-bl-lg': label === 'Tablet',
                'rounded-tr-lg rounded-br-lg border-none': label === 'Mobile',
                'flex items-center justify-center': iconName,
              }
            )}
            key={value}
          >
            {iconName && (
              <Icon
                glyph={iconName}
                className={classNames('mr-2', {
                  'text-gray-500': activePreview !== label,
                  'text-gray-800': activePreview === label,
                })}
              />
            )}
            <span className="text-gray-800 text-s font-semibold cursor-pointer">
              {label}
            </span>
            <input
              checked={label === activePreview}
              className="hidden peer"
              id={value}
              type="radio"
              value={value}
              onChange={() => {
                setActivePreview(label);
              }}
            />
          </label>
        ))}
      </div>
    </div>
  );
};
