import { call, put, select } from 'redux-saga/effects';

import { ActionTypeAlertBar, AlertBar, PopUp } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectPopUp } from '../selectors';
import { checkIsDraftTemplate, getPageIdByContentId } from '../../lib';
import { templatesModel } from '../../../templates';
import { updateAlertBarDB } from './updateAlertBarDB';

export function* updateAlertBar({
  payload: { documentContentId, callback, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updateAlertBar>) {
  try {
    yield put(actions.setUpdateAlertBarIsLoading(true));

    const alertBar: AlertBar = yield select(
      conversionKitModel.selectors.selectAlertBar
    );

    yield put(actions.setAlertBar({ ...alertBar, ...rest }));

    const pageId: string | undefined = yield call(
      getPageIdByContentId,
      documentContentId
    );

    const isDraftTemplate: boolean = yield call(
      checkIsDraftTemplate,
      documentContentId
    );

    if (!pageId && !isDraftTemplate) {
      yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      yield call(
        updateAlertBarDB,
        actions.updateAlertBarDB({ documentContentId })
      );
    }

    if (rest.isActive && rest.actionType === ActionTypeAlertBar.POPUP) {
      const popUp: PopUp = yield select(selectPopUp);

      yield put(actions.setPopUp({ ...popUp, isActive: true }));
    }
    if (callback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update alert bar`)
      )
    );
  } finally {
    yield put(actions.setUpdateAlertBarIsLoading(false));
  }
}
