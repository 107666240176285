import { call, fork, put, select } from 'redux-saga/effects';
import { snippetsModel } from '../..';
import { matchParams } from '../../../../shared/lib';
import { redirect } from '../../../../entities/history';
import { snippetEditorWorker } from './snippetEditorWorker';
import { Snippet, SnippetType } from '@distribute/shared/types';
import { snippetsWorker } from './snippetsWorker';

export function* snippetEditorSaga(location: Location, route: string) {
  yield fork(snippetEditorWorker);
  yield fork(snippetsWorker);

  const snippets: Snippet[] = yield select(
    snippetsModel.selectors.selectAvailableSnippets
  );

  const { pathname } = location;
  const params = matchParams(pathname, route);
  const currentSnippet = snippets.find(
    (s) => params?.sequenceNumber === s.sequenceNumber
  );

  if (!currentSnippet || currentSnippet.type !== SnippetType.TEXT) {
    yield call(redirect.toWorkspace);
    return;
  }

  yield put(snippetsModel.actions.setCurrentSnippet(currentSnippet));
}
