import { Avatar, TimeAgo } from '../../../../../shared/ui';
import { FC } from 'react';
import { RecordTitle } from './RecordTitle';

type Props = {
  photoUrl: string;
  displayName: string;
  createdAt: Date;
};

export const RecordDescription: FC<Props> = ({
  displayName,
  photoUrl,
  createdAt,
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <RecordTitle />
      <div className="flex flex-wrap gap-x-2 items-center text-gray-600 text-sm">
        <Avatar
          displayName={displayName}
          src={photoUrl}
          size="2xs"
          isUserAvatar
          className="border-light-7 border-[0.5px] !bg-base-white"
        />
        <p>{displayName}</p>
        <p>•</p>
        <TimeAgo datetime={createdAt} locale="my" />
      </div>
    </div>
  );
};
