import { call, put, select } from 'redux-saga/effects';
import { GatedContent } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { checkIsDraftTemplate, getPageIdByContentId } from '../../lib';
import { templatesModel } from '../../../templates';
import { updateGatedContentDB } from './updateGatedContentDB';

export function* updateGatedContent({
  payload: { documentContentId, callback, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updateGatedContent>) {
  try {
    yield put(actions.setUpdateGatedContentIsLoading(true));

    const gatedContent: GatedContent = yield select(
      conversionKitModel.selectors.selectGatedContent
    );

    yield put(actions.setGatedContent({ ...gatedContent, ...rest }));

    const pageId: string | undefined = yield call(
      getPageIdByContentId,
      documentContentId
    );

    const isDraftTemplate: boolean = yield call(
      checkIsDraftTemplate,
      documentContentId
    );

    if (!pageId && !isDraftTemplate) {
      yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      yield call(
        updateGatedContentDB,
        actions.updateGatedContentDB({ documentContentId })
      );
    }

    if (callback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update gated-content`)
      )
    );
  } finally {
    yield put(actions.setUpdateGatedContentIsLoading(false));
  }
}
