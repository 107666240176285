import { StripeError } from '@stripe/stripe-js';
import { FieldErrors } from 'react-hook-form';
import { CardFormCustomFieldsForm } from '../hooks';

interface IStripeError {
  stripeError: StripeError;
}

interface ICardFormSubmitError {
  stripeError?: StripeError;
  customFieldErrors?: FieldErrors<CardFormCustomFieldsForm>;
}

export class CardFormSubmitError extends Error implements ICardFormSubmitError {
  stripeError?: StripeError;
  customFieldErrors?: FieldErrors<CardFormCustomFieldsForm>;

  constructor(
    stripeError?: StripeError,
    customFieldErrors?: FieldErrors<CardFormCustomFieldsForm>
  ) {
    super('Card form submit error.');

    this.stripeError = stripeError;
    this.customFieldErrors = customFieldErrors;
  }
}

export class StripeConfirmSetupError extends Error implements IStripeError {
  stripeError: StripeError;

  constructor(stripeError: StripeError) {
    super('Stripe confirm setup error.');

    this.stripeError = stripeError;
  }
}
