export enum CustomDomainVerificationStatus {
  PENDING_CHECK = 'pending_check',
  DNS_RECORD_FOUND = 'dns_records_found',
  DNS_RECORD_NOT_FOUND = 'dns_records_not_found',
  DNS_CHECK_UNNECESSARY = 'dns_check_unnecessary',
}

export type CustomDomainUpstreamType = {
  id: string;
  distributeHost: string;
  createdAt: Date;
  domains: CustomDomainType[];
};

export type CustomDomainType = {
  id: string;
  customDomain: string;
  status: CustomDomainVerificationStatus;
  createdAt: Date;
  customDomainsUpstream: CustomDomainUpstreamType;
};
