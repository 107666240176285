import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { FC } from 'react';
import { getCategoryTypesTagConfig } from './lib/getCategoryTypesTagConfig';
import { Tooltip } from '../../../../shared/ui';

type Props = { category: SnippetCategoryWithSnippets };

export const CategoryTypesTag: FC<Props> = ({ category }) => {
  const config = getCategoryTypesTagConfig(category);

  return config.tooltipItems ? (
    <Tooltip
      className="px-3 py-2"
      hideArrow
      trigger={
        <span className="text-sm text-gray-700 border-b border-dashed border-gray-700">
          {config.text}
        </span>
      }
    >
      {config.tooltipItems}
    </Tooltip>
  ) : (
    <span className="text-sm text-gray-700">{config.text}</span>
  );
};
