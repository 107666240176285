import TableCell, { TableCellOptions } from '@tiptap/extension-table-cell';

const defaultTextAlignment = 'left';
const defaultTextVerticalAlignment = 'middle';

export const CustomTableCellExt = TableCell.extend<TableCellOptions>({
  addAttributes() {
    return {
      ...this.parent?.(),
      tableCellAlign: {
        default: defaultTextAlignment,
        parseHTML: (element) => element.style.textAlign || defaultTextAlignment,
        renderHTML: (attributes) => {
          if (attributes.tableCellAlign === defaultTextAlignment) {
            return {};
          }

          return { style: `text-align: ${attributes.tableCellAlign}` };
        },
      },
      tableCellVerticalAlign: {
        default: defaultTextVerticalAlignment,
        parseHTML: (element) =>
          element.style.verticalAlign || defaultTextVerticalAlignment,
        renderHTML: (attributes) => {
          if (
            attributes.tableCellVerticalAlign === defaultTextVerticalAlignment
          ) {
            return {};
          }

          return {
            style: `vertical-align: ${attributes.tableCellVerticalAlign}`,
          };
        },
      },
    };
  },
});
