import React from 'react';
import { useSnippetsPermissions } from '../../../features/teams';
import { EmptyPageOwnerView } from './EmptyPageOwnerView';
import { EmptyPageMemberView } from './EmptyPageMemberView';

export const EmptySnippetsPage: React.FC = () => {
  const { isCanManageCategories } = useSnippetsPermissions();

  return isCanManageCategories ? (
    <EmptyPageOwnerView />
  ) : (
    <EmptyPageMemberView />
  );
};
