import { IconMap } from '../../../../shared/sprite';
import { Button, Icon } from '../../../../shared/ui';
import React, { useLayoutEffect, useRef, useCallback } from 'react';
import { LinkNodeType } from './CustomLink';
import { CustomLinkDivider } from './CustomLinkDivider';
import { Editor } from '@tiptap/react';

type Props = {
  node: LinkNodeType;
  handleCopy(): void;
  handleEditMenuOpen(): void;
  handleRemoveLink(pos?: number): void;
  editor: Editor;
};

export const CustomLinkHoveringMenu: React.FC<Props> = ({
  node,
  handleCopy,
  handleEditMenuOpen,
  handleRemoveLink,
  editor,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (!menuRef.current) return;

    const menuRect = menuRef.current.getBoundingClientRect();

    const editorRect = (
      editor.view.dom.firstChild as HTMLElement
    )?.getBoundingClientRect?.();

    if (!menuRect || !editorRect) return;

    if (menuRect.left < editorRect.left) {
      menuRef.current.style.left = '0px';
      menuRef.current.style.transform = 'none';
    } else if (menuRect.right > editorRect.right) {
      menuRef.current.style.left = 'unset';
      menuRef.current.style.right = '0px';
      menuRef.current.style.transform = 'none';
    }
    menuRef.current.style.opacity = '1';
  }, [editor.view.dom.firstChild]);

  useLayoutEffect(() => {
    setTimeout(handleResize, 0);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <div
      ref={menuRef}
      className="absolute min-w-50 max-w-80 h-11 pt-2 invisible group-hover:visible -bottom-11 left-1/2 -translate-x-1/2 z-30 font-normal"
    >
      <div className="w-full h-9 bg-gray-900 rounded-lg flex items-center py-2 px-3">
        <span className="text-sm text-base-white grow-1 truncate">
          {node.attrs.href}
        </span>
        <CustomLinkDivider />
        <Button
          variant="icon"
          color="transparent"
          size="xs"
          className="!w-5 !h-5 !min-w-5 !min-h-5"
          onClick={handleCopy}
        >
          <Icon
            glyph={IconMap.MenuCopy}
            width={20}
            className="text-base-white"
          />
        </Button>
        <CustomLinkDivider />
        <Button
          variant="icon"
          color="transparent"
          size="xs"
          className="!w-5 !h-5 !min-w-5 !min-h-5"
          onClick={handleEditMenuOpen}
        >
          <Icon glyph={IconMap.Edit02} width={20} className="text-base-white" />
        </Button>
        <CustomLinkDivider />
        <Button
          variant="icon"
          color="transparent"
          size="xs"
          className="!w-5 !h-5 !min-w-5 !min-h-5"
          onClick={() => handleRemoveLink()}
        >
          <Icon
            glyph={IconMap.LinkBroken}
            width={20}
            className="text-base-white"
          />
        </Button>
      </div>
    </div>
  );
};
