import { IPaginationOptions, Pagination } from 'nestjs-typeorm-paginate';
import { Viewer } from '@distribute/shared/types';

export enum ViewerCategory {
  LEAD = 'lead',
  RECENT_VIEWER = 'recent-viewer',
}

export type GetViewersQueryParamsType = IPaginationOptions & {
  search?: string;
  from?: Date;
  to?: Date;
  category?: ViewerCategory;
  isShowAnonymous?: boolean;
};

export type ExportCsvViewersQueryParamsType = {
  search?: string;
  from?: Date;
  to?: Date;
  category: ViewerCategory;
  isShowAnonymous: boolean;
};

export type GetViewersResponseType = Pagination<Viewer>;

export type GetTotalAnonymousViewersResponseType = {
  total: number;
};
