import { TemplateExtended } from '@distribute/shared/api-types/templates';

export const getFilteredTemplates = (
  templates: TemplateExtended[],
  currentFilterState: string
) => {
  if (currentFilterState === 'all-owners') {
    return templates;
  }

  return templates.filter((t) => t.owner?.id === currentFilterState);
};
