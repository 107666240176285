import React, { useMemo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Icon, Dropdown } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { FONTS_OPTIONS } from '../config';
import { FontOption } from '../types';

const FONT_OPTIONS = {
  headingFontFamily: {
    label: 'Headings',
    icon: IconMap.Headings,
    fonts: FONTS_OPTIONS,
  },
  paragraphFontFamily: {
    label: 'Paragraphs',
    icon: IconMap.Paragraphs,
    fonts: FONTS_OPTIONS,
  },
};

type Props = {
  value: string;
  fontOption: FontOption;
  onChange: (fontOption: FontOption, fontId: string) => void;
  isCustomTriggerComponent?: boolean;
  isDisabled?: boolean;
};

export const FontsSelector: React.FC<Props> = ({
  value,
  fontOption,
  onChange,
  isCustomTriggerComponent = true,
  isDisabled,
}) => {
  const fontsItems = useMemo(
    () =>
      FONT_OPTIONS[fontOption].fonts.map((font) => ({
        ...font,
        onClick: () => {
          onChange(fontOption, font.id);
        },
      })),
    [fontOption, onChange]
  );

  return (
    <Dropdown
      isDisabled={isDisabled}
      currentItemId={value}
      isSeparatedItems
      listStyles="shadow-lg h-72 w-64 overflow-y-scroll -mt-1"
      align="start"
      triggerComponent={
        isCustomTriggerComponent ? (
          <DropdownMenu.Trigger className="focus:outline-none text-gray-600 h-9 px-3.5">
            <span className="flex items-center gap-1">
              <Icon glyph={FONT_OPTIONS[fontOption].icon} />
              <span className="text-s text-gray-600 font-medium">
                {FONT_OPTIONS[fontOption].label}
              </span>
              <Icon glyph={IconMap.ArrowDown} />
            </span>
          </DropdownMenu.Trigger>
        ) : undefined
      }
      items={fontsItems}
    />
  );
};
