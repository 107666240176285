import { put, take } from 'redux-saga/effects';
import { UpdateUserPhotoResponseType } from '@distribute/shared/api-types/auth';
import { authUserModel } from '../../../../entities/auth-user';
import { authApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { channel } from 'redux-saga';
const uploadFileChannel = channel<number>();

export function* changePhoto({
  payload: { photo },
}: ReturnType<typeof actions.changePhotoClick>) {
  try {
    yield put(actions.setIsUploadPhotoLoading(true));

    const user = (yield authApi.updateProfilePhoto(photo, (progress) =>
      uploadFileChannel.put(progress)
    )) as UpdateUserPhotoResponseType;

    yield put(authUserModel.actions.setDBUser(user));
  } catch (error: unknown) {
    yield put(actions.setUploadPhotoError((error as Error).message));
  } finally {
    yield put(actions.setIsUploadPhotoLoading(false));
    uploadFileChannel.put(0);
  }
}
export function* deletePhoto() {
  try {
    yield put(actions.setIsRemovePhotoLoading(true));

    const user = (yield authApi.removePhoto()) as UpdateUserPhotoResponseType;

    yield put(authUserModel.actions.setDBUser(user));
  } catch (error: unknown) {
    yield put(actions.setUploadPhotoError((error as Error).message));
  } finally {
    yield put(actions.setIsRemovePhotoLoading(false));
  }
}

export function* watchDownloadFileChannel() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;
    yield put(actions.setUploadPhotoProgress(progress));
  }
}
