import * as Popover from '@radix-ui/react-popover';
import { NodeViewProps } from '@tiptap/react';
import classNames from 'classnames';
import EmojiPicker, {
  EmojiClickData,
  EmojiStyle,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import { MutableRefObject, useState } from 'react';
import { categories } from '../../../../features/icon-picker';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Input, Select } from '../../../../shared/ui';
import {
  ButtonSize,
  ButtonTextAlignment,
  ButtonTextAlignmentGlyphs,
  ButtonType,
} from './constants';

export type Props = NodeViewProps & {
  linkRef?: MutableRefObject<HTMLInputElement | null>;
  labelRef?: MutableRefObject<HTMLInputElement | null>;
};

const inputStyles = `border rounded-lg text-base text-gray-900 border-gray-300
focus:ring-0 focus:border-primary-600 focus:outline-none
disabled:bg-gray-50 disabled:text-gray-500
placeholder-gray-500 px-3 text-ellipsis h-10`;

export const ButtonSettings = ({
  node,
  updateAttributes,
  linkRef,
  labelRef,
}: Props) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const onEmojiChange = (emoji: EmojiClickData) => {
    updateAttributes({ icon: emoji.emoji });
  };
  const onEmojiClear = () =>
    updateAttributes({
      icon: '',
      label: node.attrs.label ? node.attrs.label : 'New Buton',
    });

  return (
    <div className="shadow-lg rounded-xl bg-base-white border-gray-200 border p-5 w-[440px]">
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-3">
          <Popover.Root onOpenChange={setIsEmojiPickerOpen}>
            <div className="flex flex-col">
              <label
                htmlFor="emoji-picker-button"
                className="pb-1 text-sm font-regular text-gray-700"
              >
                Icon
              </label>
              <div className="flex flex-row items-end gap-1 h-10">
                <Popover.Trigger
                  className={classNames(
                    inputStyles,
                    'inline-flex flex-row gap-1 justify-between items-center'
                  )}
                  id="emoji-picker-button"
                >
                  {node.attrs.icon ? (
                    <span className="font-[sans-serif] text-2xl pt-0.5 text-center w-8">
                      {node.attrs.icon}
                    </span>
                  ) : (
                    <span className="w-8 inline-flex justify-center">
                      <Icon
                        width={20}
                        height={20}
                        className="text-gray-500"
                        glyph={IconMap.SlashCircle01}
                      />
                    </span>
                  )}
                  <Icon
                    className="text-gray-500"
                    glyph={
                      isEmojiPickerOpen
                        ? IconMap.ChevronUp
                        : IconMap.ChevronDown
                    }
                  />
                </Popover.Trigger>{' '}
                {node.attrs.icon && (
                  <Button
                    className="!h-10 !min-h-10"
                    variant="icon"
                    onClick={onEmojiClear}
                    color="secondary"
                  >
                    <Icon
                      className="text-gray-500"
                      glyph={IconMap.SlashCircle01}
                      width={20}
                      height={20}
                    />
                  </Button>
                )}
              </div>
              <Popover.Portal>
                <Popover.Content sideOffset={4} side="bottom" align="start">
                  <EmojiPicker
                    lazyLoadEmojis={true}
                    theme={Theme.LIGHT}
                    skinTonesDisabled
                    previewConfig={{
                      showPreview: false,
                    }}
                    defaultSkinTone={SkinTones.NEUTRAL}
                    emojiVersion="2.0"
                    emojiStyle={EmojiStyle.APPLE}
                    onEmojiClick={onEmojiChange}
                    width={336}
                    height={420}
                    categories={categories}
                  />
                </Popover.Content>
              </Popover.Portal>
            </div>
          </Popover.Root>

          <div className="flex flex-col w-full">
            <label className="pb-1 text-sm font-regular text-gray-700">
              Label
            </label>
            <div className="relative w-full h-full inline">
              <input
                className={classNames(
                  inputStyles,
                  'button-extension-label-input w-full pr-14'
                )}
                type="text"
                ref={labelRef}
                value={node.attrs.label}
                placeholder="New Button"
                onChange={(e) => updateAttributes({ label: e.target.value })}
              />
              <div className="p-3 absolute right-0 top-0 h-full border-l flex items-center border-l-gray-300 text-gray-400">
                <button
                  disabled={!node.attrs.icon || !node.attrs.label}
                  className="button-extension-label-input-clear h-5 w-5"
                  onClick={() => labelRef?.current?.focus()}
                >
                  <Icon
                    onClick={() => updateAttributes({ label: '' })}
                    width={20}
                    height={20}
                    glyph={IconMap.XCircle}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex flex-col flex-1">
            <Select
              className="!h-16"
              buttonStyles="!h-10 !min-h-10 !mb-0"
              truncate={false}
              label="Type"
              items={Object.values(ButtonType).map((type) => ({
                id: type,
                title: type,
              }))}
              onChange={(id) => updateAttributes({ type: id })}
              value={node.attrs.type}
            />
          </div>
          <div className="flex flex-col flex-1">
            <Select
              className="!h-16"
              buttonStyles="!h-10 !min-h-10 !mb-0"
              truncate={false}
              label="Size"
              items={Object.values(ButtonSize).map((size) => ({
                id: size,
                title: size,
              }))}
              onChange={(id) => updateAttributes({ size: id })}
              value={node.attrs.size}
            />
          </div>
          <div className="flex flex-col w-1/5">
            <Select
              className="!h-16"
              listStyles="w-fit-content"
              labelClassName="max-w-full"
              buttonStyles="w-auto !h-10 !min-h-10 !mb-0"
              truncate={false}
              label="Alignment"
              items={Object.values(ButtonTextAlignment).map((alignment) => ({
                id: alignment,
                title: (
                  <div className="flex flex-row gap-2 items-center">
                    <Icon
                      width={20}
                      height={20}
                      glyph={ButtonTextAlignmentGlyphs[alignment]}
                    />
                    {alignment}
                  </div>
                ),
              }))}
              onChange={(id) => updateAttributes({ alignment: id })}
              currentValue={{
                title: '',
                id: node.attrs.alignment,
                iconName:
                  ButtonTextAlignmentGlyphs[
                    node.attrs.alignment as ButtonTextAlignment
                  ],
              }}
              value={node.attrs.alignment}
            />
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <Input
            required
            placeholder="https://"
            label="Link *"
            isError={!!Object.keys(node.attrs?.errors).length}
            messageText={
              !!Object.keys(node.attrs?.errors).length &&
              'Provide a valid link format (e.g. https://www.example.com/)'
            }
            ref={linkRef}
            value={node.attrs.link}
            className="button-link-input !h-10 !min-h-10"
            onChange={(e) =>
              updateAttributes({
                link: e.target.value,
              })
            }
            type="text"
          />
        </div>
      </div>
    </div>
  );
};
