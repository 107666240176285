import { call, put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { videoRecordsApi } from '../../../../shared/api';
import { selectRecord, selectRecordToShare, selectRecords } from '../selectors';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';
import { mapCreateUserPermissionsToVideoRecords } from '../../lib';
import { refreshRecordDetail } from './refresh-record-detail';

export function* createUserPermission(
  action: ReturnType<typeof actions.createUserPermission>
) {
  const { payload } = action;
  const { cb, ...data } = payload;

  try {
    const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const record: RT<typeof selectRecord> = yield select(selectRecord);
    const records: RT<typeof selectRecords> = yield select(selectRecords);
    const recordToShare: RT<typeof selectRecordToShare> = yield select(
      selectRecordToShare
    );

    yield put(actions.setIsRecordShareLoading(true));

    const newUserPermissionToVideoRecords: RT<
      typeof videoRecordsApi.createUserPermission
    > = yield call(
      videoRecordsApi.createUserPermission,
      recordToShare.id,
      data,
      {
        teamId: team.id,
      }
    );

    yield put(
      actions.setRecords(
        records.map((r) =>
          r.id === recordToShare.id
            ? {
                ...r,
                ...mapCreateUserPermissionsToVideoRecords({
                  userPermissionToVideoRecords: r.userPermissionToVideoRecords,
                  newUserPermissionToVideoRecords,
                }),
              }
            : r
        )
      )
    );

    if (record.id === recordToShare.id) {
      yield call(refreshRecordDetail, {
        ...record,
        ...mapCreateUserPermissionsToVideoRecords({
          userPermissionToVideoRecords: record.userPermissionToVideoRecords,
          newUserPermissionToVideoRecords,
        }),
      });
    }
    cb();
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.record.shareError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
  } finally {
    yield put(actions.setIsRecordShareLoading(false));
  }
}
