import { IconMap } from '../../../../../shared/sprite';
import { Icon, Tooltip } from '../../../../../shared/ui';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick?(): void;
};

export const ConversionWarningItem: React.FC<Props> = ({
  children,
  onClick,
}) => {
  return (
    <Tooltip
      align="end"
      alignOffset={-70}
      trigger={
        <Icon
          glyph={IconMap.AlertTriangle}
          width={20}
          className="text-warning-500 cursor-pointer"
          onClick={onClick}
        />
      }
    >
      <div className="text-center text-xs px-3 py-2">{children}</div>
    </Tooltip>
  );
};
