import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from '@distribute/shared/types';
import {
  Modal,
  Button,
  Input,
  Select,
  SelectOptionUser,
  Item,
} from '../../../../shared/ui';
import { teamsModel, useTeamPermissions } from '../../../teams';
import { teamInfoSettingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';

type IProps = {
  isOpen: boolean;
  onClose: (isOpenNeedUpdateSubscriptionModal: boolean) => void;
};

type LeaveTeamConfirmModalForm = {
  confirmText: string;
  userIdToTransferOwnership: string;
};

const CONFIRMATION_TEXT = 'LEAVE TEAM';

export const LeaveTeamConfirmModal: FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [userToTransferOwnership, setUserToTransferOwnership] = useState<
    Item | undefined
  >();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const { id } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isLeavingTeam = useSelector(
    teamInfoSettingsModel.selectors.selectIsLeavingTeam
  );
  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const { isOwner } = useTeamPermissions();
  const teamUserItems = useMemo<Item[]>(
    () =>
      teamUsers
        .filter(
          (teamUser) =>
            teamUser.user &&
            teamUser.user.id !== currentUser.id &&
            !teamUser.isWaitingForInviteAcceptance
        )
        .map(({ user, externalUserEmail }) => {
          return {
            id: (user as User).id.toString(),
            title: (
              <SelectOptionUser
                displayName={user?.displayName ?? externalUserEmail ?? ''}
                photoUrl={user?.photoUrl}
              />
            ),
          };
        }),
    [teamUsers, currentUser]
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        userIdToTransferOwnership: string().when([], {
          is: () => isOwner,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        confirmText: string()
          .required(`Type ${CONFIRMATION_TEXT} is required`)
          .oneOf(
            [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
            `Please type ${CONFIRMATION_TEXT} to confirm.`
          ),
      }),
    [isOwner]
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<LeaveTeamConfirmModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = (isOpenNeedUpdateSubscriptionModal: boolean) => {
    reset();
    onClose(isOpenNeedUpdateSubscriptionModal);
    setUserToTransferOwnership(undefined);
  };

  const handleFormSubmit = handleSubmit((data) => {
    dispatch(
      teamInfoSettingsModel.actions.leaveTeam({
        teamId: id,
        userIdToTransferOwnership: data.userIdToTransferOwnership,
        cb: onModalClose,
      })
    );
  });

  return (
    <Modal
      onClose={() => onModalClose(false)}
      isOpen={isOpen}
      title="Leave a Team"
      actionButton={
        <Button
          onClick={handleFormSubmit}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          disabled={isLeavingTeam}
          loading={isLeavingTeam}
        >
          Leave Team
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        Leaving the team will result in the revocation of your access to all
        associated pages. Any shared pages or folders owned by you will be
        transferred to the team owner. Are you sure you want to proceed?
      </div>
      <form onSubmit={handleFormSubmit} className="flex flex-col gap-y-5">
        {isOwner && (
          <Controller
            defaultValue=""
            control={control}
            name="userIdToTransferOwnership"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                items={teamUserItems}
                value={value}
                currentValue={userToTransferOwnership}
                label="Choose new team owner"
                onChange={(userId) => {
                  onChange(userId);
                  setUserToTransferOwnership(
                    teamUserItems.find((i) => i.id === userId)
                  );
                }}
                error={error?.message}
              />
            )}
          />
        )}
        <Input
          autoComplete="off"
          label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
          {...register('confirmText')}
          isError={!!errors.confirmText}
          type="text"
        />
      </form>
    </Modal>
  );
};
