import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { contactSupportFormApi } from '../../../../shared/api';
import { contactSupportFormModel } from '..';

export function* contactSupport({
  payload: { cb, ...data },
}: ReturnType<typeof contactSupportFormModel.actions.contactSupport>) {
  try {
    yield put(contactSupportFormModel.actions.setIsLoadingContactSupport(true));
    yield call(contactSupportFormApi.contactSupport, data);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your message has been successfully sent to the support team. We will get back to you as soon as possible'
        )
      )
    );
    cb();
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          'Sorry, something went wrong. Please try again later'
        )
      )
    );
  } finally {
    yield put(
      contactSupportFormModel.actions.setIsLoadingContactSupport(false)
    );
  }
}
