import React, { useRef, useState, useMemo, useCallback } from 'react';
import cn from 'classnames';
import {
  MediaSnippetJSON,
  Snippet,
  SnippetType,
  SnippetsSharingAccess,
} from '@distribute/shared/types';
import { CreatedByRow } from './CreatedByRow';
import { Button, Dropdown, Icon } from '../../../../shared/ui';
import { snippetItemIconByType } from './lib/config';
import { getVideoDuration } from './lib/getVideoDuration';
import { useSnippetsPermissions } from '../../../teams';
import { IconMap } from '../../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DeleteSnippetConfirmationModal } from './DeleteSnippetConfirmationModal';
import { SnippetSettingsModal } from './SnippetSettingsModal';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../../processes/navigation';
import { generatePath } from 'react-router-dom';
import { SNIPPET_EDITOR_ROUTE } from '../../../../entities/history';
import { snippetsModel } from '../../model';
import { JSONContent } from '@tiptap/react';
import { SnippetPreviewModal } from './snippet-preview-modal/SnippetPreviewModal';
import { ReplaceSnippetModal } from './replace-snippet/ReplaceSnippetModal';
import { SnippetPreview } from './snippet-preview/SnippetPreview';

type Props = {
  snippet: Snippet;
  onSelect?: (
    id: string,
    type: SnippetType,
    content: JSONContent | MediaSnippetJSON,
    attrs?: {
      'data-media-width'?: number;
      'data-media-height'?: number;
    }
  ) => void;
  offsetWidth?: number;
};

export const SnippetItem: React.FC<Props> = ({
  snippet,
  onSelect,
  offsetWidth,
}) => {
  const dispatch = useDispatch();

  const [isDeleteSnippetModalOpen, setIsDeleteSnippetModalOpen] =
    useState(false);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const videoEl = useRef<HTMLVideoElement | null>(null);
  const playVideo = () => {
    videoEl.current?.play();
  };
  const pauseVideo = () => {
    videoEl.current?.pause();
  };

  const [duration, setDuration] = useState('');

  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;

    setDuration(getVideoDuration(video.duration));
  };

  const { isCanManageSnippets } = useSnippetsPermissions();

  const isSettingsDropdownAvailable =
    snippet.sharingAccess === SnippetsSharingAccess.PERSONAL ||
    isCanManageSnippets;

  const [isReplaceSnippetModal, setIsReplaceSnippetModal] = useState(false);

  const handleEditSnippet = useCallback(() => {
    if (snippet.type === SnippetType.TEXT) {
      dispatch(snippetsModel.actions.setCurrentSnippet(snippet));
      dispatch(
        navigate({
          to: generatePath(SNIPPET_EDITOR_ROUTE, {
            sequenceNumber: snippet.sequenceNumber,
          }),
        })
      );
    } else {
      setIsReplaceSnippetModal(true);
    }
  }, [dispatch, snippet]);

  const options = useMemo(() => {
    return [
      {
        id: 'edit',
        label: snippet.type === SnippetType.TEXT ? 'Edit' : 'Replace',
        iconName:
          snippet.type === SnippetType.TEXT
            ? IconMap.Edit02
            : IconMap.RefreshCw05,
        iconWidth: 16,
        onClick: handleEditSnippet,
      },
      {
        id: 'settings',
        label: 'Settings',
        onClick: () => {
          setIsSettingsModalOpen(true);
        },
        iconName: IconMap.Settings,
        iconWidth: 16,
        isHide: snippet.isDraft,
      },
      {
        id: 'delete',
        label: 'Delete',
        onClick: () => {
          if (snippet.isDraft) {
            dispatch(
              snippetsModel.actions.deleteSnippet({
                id: snippet.id,
                sharingAccess: snippet.sharingAccess,
                isDraft: Boolean(snippet.isDraft),
                callback: () => {
                  return;
                },
              })
            );
            return;
          }

          setIsDeleteSnippetModalOpen(true);
        },
        iconName: IconMap.Delete,
        iconWidth: 16,
      },
    ].filter((i) => !i.isHide);
  }, [
    snippet.type,
    snippet.isDraft,
    handleEditSnippet,
    dispatch,
    snippet.id,
    snippet.sharingAccess,
  ]);

  const [isPreviewModal, setIsPreviewModal] = useState(false);

  return (
    <>
      {isPreviewModal && (
        <SnippetPreviewModal
          snippet={snippet}
          onClose={() => {
            setIsPreviewModal(false);
          }}
          onSelect={onSelect}
          offsetWidth={offsetWidth}
          setIsDeleteSnippetModal={setIsDeleteSnippetModalOpen}
          setIsReplaceSnippetModal={setIsReplaceSnippetModal}
        />
      )}
      <DeleteSnippetConfirmationModal
        isOpen={isDeleteSnippetModalOpen}
        onClose={() => setIsDeleteSnippetModalOpen(false)}
        snippet={snippet}
      />
      <SnippetSettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        snippet={snippet}
        isSnippetsModal={Boolean(onSelect)}
      />
      <ReplaceSnippetModal
        snippet={snippet}
        onClose={() => {
          setIsReplaceSnippetModal(false);
        }}
        isOpen={isReplaceSnippetModal}
      />
      <div
        className={cn(
          '2xl:w-auto p-3 border border-gray-300 rounded-xl h-fit group w-1/3 hover:shadow-md',
          {
            'max-w-[290px] max1280:max-w-[400px]': onSelect,
            'max-w-[400px]': !onSelect,
          }
        )}
      >
        <div
          className={cn(
            'relative flex items-center justify-center mb-3 overflow-hidden h-41 rounded-md'
          )}
        >
          <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100 sm:hidden">
            {onSelect ? (
              <>
                <Button
                  variant="text"
                  color="primary"
                  className="w-45 !h-11 text-md"
                  onClick={() => {
                    dispatch(
                      snippetsModel.actions.updateSnippetUsedCount({
                        snippetId: snippet.id,
                      })
                    );
                    onSelect(
                      snippet.id,
                      snippet.type,
                      snippet.content,
                      snippet.type === SnippetType.FILE ||
                        snippet.type === SnippetType.VIDEO
                        ? {
                            'data-media-width': offsetWidth,
                            'data-media-height': 640,
                          }
                        : undefined
                    );
                  }}
                >
                  Insert Snippet
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  className="w-45 !h-9 text-sm"
                  onClick={() => {
                    setIsPreviewModal(true);
                  }}
                >
                  <Icon glyph={IconMap.Eye} width={20} className="mr-1.5" />
                  Preview
                </Button>
              </>
            ) : snippet.isDraft ? (
              <Button
                variant="text"
                color="primary"
                className="w-45 !h-11 text-md"
                onClick={handleEditSnippet}
              >
                <Icon glyph={IconMap.Edit02} width={20} className="mr-1.5" />
                Edit draft
              </Button>
            ) : (
              <Button
                variant="text"
                color="secondary"
                className="w-45 !h-9 text-sm"
                onClick={() => {
                  setIsPreviewModal(true);
                }}
              >
                <Icon glyph={IconMap.Eye} width={20} className="mr-1.5" />
                Preview
              </Button>
            )}
          </div>
          <SnippetPreview snippet={snippet} />
        </div>

        <div className="flex items-center mb-2 gap-x-2">
          <Icon
            glyph={snippetItemIconByType[snippet.type]}
            width={20}
            className="text-gray-600 min-w-5"
          />
          <p className="font-semibold truncate text-md">
            {snippet.name || 'Untitled'}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <CreatedByRow owner={snippet.owner} />
          {isSettingsDropdownAvailable && (
            <Dropdown
              isModalMode={false}
              listStyles="shadow-lg z-55"
              triggerComponent={
                <DropdownMenu.Trigger className="flex items-center justify-center w-5 h-5 focus:outline-none hover:bg-gray-50">
                  <Icon glyph={IconMap.DotsVertical} width={20} />
                </DropdownMenu.Trigger>
              }
              items={options}
            />
          )}
        </div>
      </div>
    </>
  );
};
