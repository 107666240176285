import { useEffect, useRef } from 'react';
import '../../coordinator.cjs';

const link = window.location.origin;

export const TemplatesIframeDemoPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /** @ts-expect-error window types. */
    window.coordinator.setServiceUrl(link);
    /** @ts-expect-error window types. */
    window.coordinator.setRelatedTopContent(['sticky-header']);
    if (ref.current) {
      /** @ts-expect-error window types. */
      window.coordinator.coordinate(ref.current, link);
    }

    return () => {
      /** @ts-expect-error window types. */
      window.coordinator.destroy();
    };
  });
  return (
    <div>
      <div id="sticky-header" className="h-20 fixed top-0">
        Sticky
      </div>
      <div className="mt-20">
        <div ref={ref} className="mt-20" id="iframe-block"></div>
      </div>
    </div>
  );
};
