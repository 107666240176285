import React from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { Toggle, Tooltip, UpgradePlanBadge } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { ConversionWarningItem } from '../../../../widgets/editor-sidebar/ui/components/conversion-tab/ConversionWarningItem';
import { useCurrentPlan } from '../../../../features/subscription/hooks';
import { FREE_SUBSCRIPTION_PLAN_ID } from '../../../../features/subscription/config';
import { useDispatch } from 'react-redux';
import { subscriptionModel } from '../../../../features/subscription';

type Props = {
  text: string;
  checked: boolean;
  onChange(value: boolean): void;
  icon: SpriteGlyph;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  handleEdit?(): void;
  hideToggle?: boolean;
  isSetupPopupWarning?: boolean;
  isPopupWarning?: boolean;
  onWarningClick?(): void;
  isEditByHover?: boolean;
  availableForPaidPlan?: boolean;
};

export const ConversionLabelToggle: React.FC<Props> = ({
  text,
  checked,
  onChange,
  icon,
  className,
  isRequired,
  disabled,
  handleEdit,
  hideToggle,
  isSetupPopupWarning,
  isPopupWarning,
  onWarningClick,
  isEditByHover = false,
  availableForPaidPlan = false,
}) => {
  const dispatch = useDispatch();

  const currentPlan = useCurrentPlan();
  const hasFreePlan = currentPlan.id === FREE_SUBSCRIPTION_PLAN_ID;

  const disabledByPlan = availableForPaidPlan && hasFreePlan;

  const handleUpgradeClick = () => {
    if (disabledByPlan) {
      dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
    }
  };

  return (
    <div className="relative">
      <div
        className={cn('px-3 rounded-xl bg-gray-100 group', {
          'py-2.75': disabledByPlan,
          'py-3': !disabledByPlan,
        })}
      >
        <div className={cn('flex justify-between', className)}>
          <div className="flex items-center gap-2">
            <Icon
              glyph={icon}
              width={20}
              className={cn({
                'text-gray-400': disabledByPlan,
                'text-gray-800': !disabledByPlan,
              })}
            />
            <p
              className={cn('text-sm font-normal', {
                'text-gray-500': disabledByPlan,
                'text-gray-900': !disabledByPlan,
              })}
            >
              {text}
              {isRequired && <span className="text-error-500 ml-0.5">*</span>}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {disabledByPlan && <UpgradePlanBadge />}
            <div
              className={cn('flex items-center gap-2', {
                'flex-row-reverse': isEditByHover,
              })}
            >
              {isSetupPopupWarning && (
                <ConversionWarningItem onClick={onWarningClick}>
                  <p>Don’t forget to set up pop-up,</p>
                  <p>as it's required for the Alert Bar</p>
                  <p>button to function properly.</p>
                </ConversionWarningItem>
              )}
              {isPopupWarning && (
                <ConversionWarningItem onClick={onWarningClick}>
                  <p>Don’t forget to set up pop-up</p>
                  <p>triggers or add Alert Bar or CTA</p>
                  <p>Section with pop-up type.</p>
                </ConversionWarningItem>
              )}
              {handleEdit &&
                (isEditByHover ? (
                  <Tooltip
                    trigger={
                      <Icon
                        glyph={IconMap.Edit02}
                        width={16}
                        className="hidden text-gray-700 cursor-pointer group-hover:block"
                        onClick={handleEdit}
                      />
                    }
                    sideOffset={4}
                  >
                    <p className="px-2 py-1 text-xs font-semibold">
                      Edit {text}
                    </p>
                  </Tooltip>
                ) : (
                  <Icon
                    glyph={IconMap.Edit02}
                    width={16}
                    className="text-gray-700 cursor-pointer"
                    onClick={handleEdit}
                  />
                ))}
            </div>
            {!hideToggle && (
              <Toggle
                checked={checked}
                onChange={onChange}
                disabled={disabledByPlan || disabled}
                disabledWithBg
              />
            )}
          </div>
        </div>
      </div>
      {disabledByPlan && (
        <button
          className="absolute top-0 left-0 w-full h-full outline-none"
          onClick={handleUpgradeClick}
        />
      )}
    </div>
  );
};
