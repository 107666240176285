export const COLUMNS_GAP_WIDTH = 24;

export const generateWidthCalcFunction = (
  gapWidth: number,
  columnWidth: number
) => {
  return `calc((100% - ${gapWidth}px) * ${(columnWidth / 100).toFixed(4)})`;
};

export const COLUMN_WIDTH_DATA_ATTRIBUTE = 'data-column-width';
