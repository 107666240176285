import { Snippet } from '@distribute/shared/types';
import { TextSnippetView } from '../../../../../entities/tiptap-editor/extensions/SnippetBlock/TextSnippetView';
import { FC, useRef } from 'react';

type Props = {
  snippet: Snippet;
};

export const TextPreview: FC<Props> = ({ snippet }) => {
  const reviewRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-full px-8 pt-12 pb-4 overflow-auto border border-gray-300 rounded-2xl">
      <div ref={reviewRef} className="overflow-auto">
        <div className="m-auto h-full w-180 max1080:w-[630px] md:w-full">
          <div>
            <TextSnippetView snippet={snippet} />
          </div>
        </div>
      </div>
    </div>
  );
};
