import { selectWithError } from '../../../shared/lib';
import { RootState } from '../../../app';
import { checkIsGettingStartedAvailable } from '../../getting-started/lib';

export const selectCurrentTeam = ({ teams }: RootState) => teams.currentTeam;

export const selectCurrentTeamWithError = selectWithError(
  selectCurrentTeam,
  'currentTeam'
);

export const selectTeamsStatus = ({ teams }: RootState) => teams.teamsStatus;

export const selectTeams = ({ teams }: RootState) => teams.teams;

export const selectCurrentTeamMembers = ({ teams }: RootState) =>
  teams.currentTeamMembers;

export const selectIsCreatingTeam = ({ teams }: RootState) =>
  teams.isCreatingTeam;

export const selectUserTeamMemberData = ({ teams }: RootState) =>
  teams.currentTeam?.currentMember;

export const selectUserTeamMemberDataWithError = selectWithError(
  ({ teams }: RootState) => teams.currentTeam?.currentMember,
  'currentUserTeamData'
);

export const selectTeamPermissions = ({ teams }: RootState) =>
  teams.currentTeam?.teamPermissions;

export const selectTeamPermissionsWithError = selectWithError(
  selectTeamPermissions,
  'currentTeam'
);

export const selectIsGettingStartedAvailable = (state: RootState) => {
  const team = selectCurrentTeamWithError(state);
  return checkIsGettingStartedAvailable(team);
};

export const selectIsShowCreateNewTeamModal = ({ teams }: RootState) =>
  teams.isShowCreateNewTeamModal;
