import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserPermissionToVideoRecordRole } from '@distribute/shared/types';
import {
  Button,
  ButtonCross,
  ComboBox,
  ComboBoxItem,
  Dropdown,
  SelectOptionUser,
} from '../../../../../shared/ui';
import { teamsModel } from '../../../../teams';
import { TeamMembersPermissionRow } from './TeamMembersPermissionRow';

import { Dialog } from '@headlessui/react';
import { recordingsModel } from '../../../model';
import { UserPermissionRow } from './UserPermissionRow';

export const RecordShareModal: FC = () => {
  const [inviteRole, setInviteRole] = useState(
    UserPermissionToVideoRecordRole.VIEWER
  );
  const [invitedTeamMembers, setInvitedTeamMembers] = useState<ComboBoxItem[]>(
    []
  );
  const dispatch = useDispatch();

  const record = useSelector(recordingsModel.selectors.selectRecordToShare);

  const isLoading = useSelector(
    recordingsModel.selectors.selectIsRecordDeleteLoading
  );

  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const onClose = () => {
    dispatch(recordingsModel.actions.setRecordIdToShare(null));
  };

  const currentTeamMembersOptions = useMemo<ComboBoxItem[]>(
    () =>
      currentTeamMembers
        .filter(
          (teamMember) =>
            !teamMember.isWaitingForInviteAcceptance &&
            !record.userPermissionToVideoRecords.some(
              (i) => i.user.id === teamMember.user?.id
            )
        )
        .map(({ user }) => ({
          id: user?.id ?? '',
          value: user?.displayName ?? '',
          optionTitle: (
            <SelectOptionUser
              displayName={user?.displayName ?? ''}
              photoUrl={user?.photoUrl}
            />
          ),
          headerTitle: (
            <SelectOptionUser
              displayName={user?.displayName ?? ''}
              photoUrl={user?.photoUrl}
              avatarSize="3xs"
            />
          ),
        })),
    [currentTeamMembers, record.userPermissionToVideoRecords]
  );

  const inviteRoles = useMemo(
    () => [
      {
        id: UserPermissionToVideoRecordRole.EDITOR,
        label: 'Can Edit',
        onClick: () => setInviteRole(UserPermissionToVideoRecordRole.EDITOR),
      },
      {
        id: UserPermissionToVideoRecordRole.VIEWER,
        label: 'Can View',
        onClick: () => setInviteRole(UserPermissionToVideoRecordRole.VIEWER),
      },
    ],
    []
  );

  const handleCreateUserPermission = () => {
    dispatch(
      recordingsModel.actions.createUserPermission({
        role: inviteRole,
        userIds: invitedTeamMembers.map((i) => i.id),
        cb: () => setInvitedTeamMembers([]),
      })
    );
  };

  const handleUpdateUserPermission = ({
    role,
    userPermissionToVideoRecordId,
  }: {
    role: UserPermissionToVideoRecordRole;
    userPermissionToVideoRecordId: number;
  }) => {
    dispatch(
      recordingsModel.actions.updateUserPermission({
        role,
        userPermissionToVideoRecordId,
      })
    );
  };

  const handleDeleteUserPermission = (
    userPermissionToVideoRecordId: number
  ) => {
    dispatch(
      recordingsModel.actions.deleteUserPermission({
        userPermissionToVideoRecordId,
      })
    );
  };

  return (
    <Dialog className="relative z-50 w-full" open onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200">
        <Dialog.Panel className="w-full max-w-160 px-6 pt-6 pb-8 shadow-xl rounded-xl bg-base-white relative flex flex-col overflow-hidden">
          <ButtonCross onClick={onClose} classNames="absolute right-4 top-4" />
          <Dialog.Title className="text-gray-900 text-display-xs font-medium font-display pr-9 mb-5">
            {`Share ${record.name}`}
          </Dialog.Title>
          <>
            {currentTeamMembersOptions.length > 0 && (
              <div className="flex gap-3 mb-5 sm:flex-col">
                <ComboBox
                  items={currentTeamMembersOptions}
                  onChange={(members) => setInvitedTeamMembers(members)}
                  value={invitedTeamMembers}
                  placeholder="Members, comma separated"
                />
                <div className="flex gap-x-3 sm:justify-end">
                  <Dropdown
                    isModalMode={false}
                    listStyles="shadow-lg"
                    items={inviteRoles}
                    currentItemId={inviteRole}
                    itemStyles="!w-27 !min-w-27"
                    triggerStyles="!w-30 !h-10 shrink-0 !text-sm !font-semibold !text-gray-600"
                  />
                  <Button
                    color="primary"
                    variant="text"
                    size="md"
                    className="shrink-0"
                    onClick={handleCreateUserPermission}
                    disabled={isLoading || !invitedTeamMembers.length}
                    loading={isLoading}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
            <div className="h-0.25 border-t border-gray-200 -mx-6"></div>
            <div className="flex flex-col gap-y-3 mt-5 max-h-86 overflow-auto">
              <TeamMembersPermissionRow />
              {record.userPermissionToVideoRecords.map(
                (userPermissionToVideoRecord) => (
                  <UserPermissionRow
                    key={userPermissionToVideoRecord.id}
                    userPermissionToVideoRecord={userPermissionToVideoRecord}
                    onUpdateUserPermission={handleUpdateUserPermission}
                    onDeleteUserPermission={handleDeleteUserPermission}
                  />
                )
              )}
            </div>
          </>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
