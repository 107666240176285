import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import { CustomDate, Dropdown } from '..';

export type DateRange = { from?: Date; to?: Date };

type IProps = {
  onChange: (range: DateRange) => void;
  getRangePeriod?: (period: string) => void;
  defaultValue?: DateRangePeriod;
};

export type DateRangePeriod =
  | 'today'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year'
  | 'all'
  | 'custom';

type PresetedDateRangePeriod = Exclude<DateRangePeriod, 'custom'>;

type DateRangeItem = {
  id: DateRangePeriod;
  label: string;
  headerLabel?: string;
  isSeparatedFromTop?: boolean;
};

const getTodayWithZeroTime = () =>
  moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

const rangeByPeriod: Record<PresetedDateRangePeriod, () => DateRange> = {
  all: () => ({}),
  today: () => ({
    from: getTodayWithZeroTime().toDate(),
    to: moment().toDate(),
  }),
  week: () => ({
    from: getTodayWithZeroTime().subtract(7, 'd').toDate(),
    to: moment().toDate(),
  }),
  month: () => ({
    from: getTodayWithZeroTime().subtract(30, 'd').toDate(),
    to: moment().toDate(),
  }),
  quarter: () => ({
    from: getTodayWithZeroTime().subtract(90, 'd').toDate(),
    to: moment().toDate(),
  }),
  year: () => ({
    from: getTodayWithZeroTime().subtract(365, 'd').toDate(),
    to: moment().toDate(),
  }),
};

const getRangeByPeriod = (period: PresetedDateRangePeriod) => {
  return rangeByPeriod[period]();
};

export const DateRangePicker: FC<IProps> = ({
  onChange,
  getRangePeriod,
  defaultValue,
}) => {
  const [currentPeriod, setCurrentPeriod] = useState<DateRangePeriod>(
    defaultValue ?? 'all'
  );
  const [startCustomDate, setStartCustomDate] = useState<Date | null>(null);
  const [endCustomDate, setEndCustomDate] = useState<Date | null>(null);
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = useState(false);

  const periodItems = useMemo<DateRangeItem[]>(
    () => [
      {
        id: 'today',
        label: 'Today',
      },
      {
        id: 'week',
        label: 'Last 7 days',
      },
      {
        id: 'month',
        label: 'Last 30 days',
      },
      {
        id: 'quarter',
        label: 'Last 90 days',
      },
      {
        id: 'year',
        label: 'Last 365 days',
      },
      {
        id: 'all',
        label: 'All Time',
      },
      {
        id: 'custom',
        label: 'Custom',
        headerLabel: `${
          startCustomDate && endCustomDate
            ? `${moment(startCustomDate).format('MMM D, YYYY')} - ${moment(
                endCustomDate
              ).format('MMM D, YYYY')}`
            : 'Custom'
        }`,
        isSeparatedFromTop: true,
      },
    ],
    [endCustomDate, startCustomDate]
  );

  const onApplyCustomDate = (startDate: Date | null, endDate: Date | null) => {
    const endDateToTheEndOfDay = moment(endDate).add(1, 'd').toDate();
    setStartCustomDate(startDate);
    setEndCustomDate(endDateToTheEndOfDay);
    setIsCustomDatePickerOpen(false);
    setCurrentPeriod('custom');
    onChange({
      from: startDate ?? undefined,
      to: endDateToTheEndOfDay ?? undefined,
    });
  };

  const onCancelCustomDate = () => {
    setIsCustomDatePickerOpen(false);
  };

  const handleChangePeriod = (period: string) => {
    getRangePeriod?.(period);
    if (period === 'custom') {
      setIsCustomDatePickerOpen(true);
      return;
    }
    setCurrentPeriod(period as DateRangePeriod);
    setStartCustomDate(null);
    setEndCustomDate(null);
    onChange(getRangeByPeriod(period as PresetedDateRangePeriod));
  };

  return (
    <div className="relative w-70 sm:w-full hover:bg-gray-50 sm:grow-1">
      <Dropdown
        items={periodItems}
        onItemChange={handleChangePeriod}
        currentItemId={isCustomDatePickerOpen ? 'custom' : currentPeriod}
        itemStyles="!min-w-70 sm:min-w-full"
        triggerStyles="min-w-70 sm:min-w-full h-10 text-sm !text-gray-700 !font-semibold"
        align="start"
      />
      {isCustomDatePickerOpen && (
        <CustomDate
          startDate={startCustomDate}
          endDate={endCustomDate}
          onApply={onApplyCustomDate}
          onClose={onCancelCustomDate}
        />
      )}
    </div>
  );
};
