import * as Popover from '@radix-ui/react-popover';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import { ButtonComponent } from './ButtonComponent';
import { ButtonSettings } from './ButtonSettings';
import { ButtonTextAlignment, ModalAlignment } from './constants';
import { ButtonAttrs } from './types';
import { validate } from './utils';

export type ButtonNodeType = NodeViewProps['node'] & {
  attrs: ButtonAttrs;
};

export type CalloutNodeViewProps = NodeViewProps & {
  node: ButtonNodeType;
};

type Props = CalloutNodeViewProps;

export const ButtonNodeView = ({ node, updateAttributes, ...props }: Props) => {
  const linkRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { icon, label, type, size, alignment, link, errors } = node.attrs;

  const onSettingsClick = () => {
    setTimeout(() => {
      labelRef.current?.focus();
    });
  };

  const onButtonClick = () => {
    if (!node.attrs.edited) {
      setIsOpen(true);
      onSettingsClick();
    } else {
      if (link && !errors.link?.length) {
        window.open(link, '_blank');
      } else {
        updateAttributes({
          errors: validate(node.attrs),
        });
      }
    }
  };

  const onOpenChange = (state: boolean) => {
    setIsOpen(state);
    updateAttributes({
      edited: true,
    });
    if (!state) {
      updateAttributes({
        label: node.attrs.label
          ? node.attrs.label
          : node.attrs.icon
          ? ''
          : 'New Button',
        errors: validate(node.attrs),
      });
    }
  };

  useEffect(() => {
    // This is a hack to force the popover to re-render when the alignment changes
    setKey((prev) => (prev > 10 ? 0 : prev + 1));
  }, [alignment]);

  return (
    <NodeViewWrapper data-type="button" className="!border-none mb-5">
      <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
        <div
          contentEditable={false}
          className={classNames('flex flex-row', {
            'justify-end': alignment === ButtonTextAlignment.Right,
            'justify-center': alignment === ButtonTextAlignment.Center,
            'justify-start': alignment === ButtonTextAlignment.Left,
          })}
        >
          <Popover.Anchor asChild>
            <div
              contentEditable={false}
              className={classNames('relative', {
                'flex-row-reverse': alignment === ButtonTextAlignment.Right,
              })}
            >
              <ButtonComponent
                onClick={onButtonClick}
                variant="icon-text"
                className="!px-4"
                icon={icon}
                label={label}
                color={type}
                size={size}
              />
              <div
                className={classNames(
                  'flex flex-row button-extenstion-actions absolute top-1/2 -translate-y-1/2',
                  {
                    'flex-row-reverse': alignment === ButtonTextAlignment.Right,
                    'left-full ml-1': alignment !== ButtonTextAlignment.Right,
                    'right-full mr-1': alignment === ButtonTextAlignment.Right,
                  }
                )}
              >
                {!!Object.keys(errors).length && (
                  <Tooltip
                    hideArrow
                    className="text-center py-2"
                    sideOffset={5}
                    triggerClassNames="flex flex-col items-center"
                    trigger={
                      <Popover.Trigger asChild>
                        <Button
                          className="w-9 h-9 !min-h-9 !min-w-9"
                          onClick={() => {
                            setTimeout(() => {
                              linkRef.current?.focus();
                            });
                          }}
                          variant="icon"
                          color="transparent"
                        >
                          <Icon
                            className="text-error-600"
                            width={20}
                            height={20}
                            glyph={IconMap.AlertCircle}
                          />
                        </Button>
                      </Popover.Trigger>
                    }
                  >
                    <div>
                      {Object.entries(errors).map(([key, value]) => (
                        <div key={key}>{value}</div>
                      ))}
                      <div>Click to edit button.</div>
                    </div>
                  </Tooltip>
                )}
                <Popover.Trigger asChild>
                  <Button
                    variant="icon"
                    color="transparent-with-actions"
                    className="w-9 h-9 !min-h-9 !min-w-9"
                    onClick={onSettingsClick}
                  >
                    <Icon width={20} height={20} glyph={IconMap.Settings01} />
                  </Button>
                </Popover.Trigger>
              </div>
            </div>
          </Popover.Anchor>
          <Popover.Portal key={key}>
            <Popover.Content
              sticky="always"
              sideOffset={4}
              side="bottom"
              align={ModalAlignment[alignment] ?? 'start'}
            >
              <ButtonSettings
                linkRef={linkRef}
                labelRef={labelRef}
                node={node}
                updateAttributes={updateAttributes}
                {...props}
              />
            </Popover.Content>
          </Popover.Portal>
        </div>
      </Popover.Root>
    </NodeViewWrapper>
  );
};
