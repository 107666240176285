import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Folder } from '@distribute/shared/types';
import { authUserModel } from '../../../entities/auth-user';
import { getFolderPermissions } from '../lib';
import { useTeamPermissions } from '../../teams';

export const useFolderPermissions = (folder?: Folder) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const teamPermissions = useTeamPermissions();

  const permissions = useMemo(() => {
    return getFolderPermissions(currentUser, folder, teamPermissions);
  }, [currentUser, folder, teamPermissions]);

  return permissions;
};
