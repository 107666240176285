import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { SortState } from '../ui/TemplatesPageList';

export const getSortedTemplates = (
  templates: TemplateExtended[],
  sortCondition?: SortState
) => {
  if (!sortCondition) {
    return templates;
  }

  switch (sortCondition) {
    case SortState.LAST_CREATED:
      return [...templates].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    case SortState.NAME:
      return [...templates].sort(
        (
          { content: { title: aTitle = 'Untitled' } },
          { content: { title: bTitle = 'Untitled' } }
        ) => {
          if (aTitle < bTitle) {
            return -1;
          }
          if (aTitle > bTitle) {
            return 1;
          }
          return 0;
        }
      );
    case SortState.MOST_POPULAR:
      return [...templates].sort((a, b) => {
        return b.usedCount - a.usedCount;
      });
    default:
      return templates;
  }
};
