import cn from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { formatNumber } from '../lib';
import { Icon, SpriteGlyph } from './Icon';
import { TabCounter } from './TabCounter';

export type AdditionalDataValueItem = Array<{
  id: number | string;
  name: string;
  value: number;
}>;

export type AdditionalData = {
  name: string;
  title: string;
  value?: AdditionalDataValueItem[];
};

export type TabContent = {
  name: string;
  title: string;
  badge?: ReactNode;
  value?: number | string;
  component?: JSX.Element;
  icon?: SpriteGlyph;
  disabled?: boolean;
  additionalData?: AdditionalData;
};

export const Tabs: FC<{
  tabs: TabContent[];
  onSelectTab: (tab: TabContent, i: number) => void;
  selectedTab: TabContent;
  className?: string;
  tabClassName?: string;
  children: React.ReactNode;
  CustomComponent?: ReactElement;
  isShowIndex?: boolean;
}> = ({
  tabs,
  onSelectTab,
  children,
  selectedTab,
  className = '',
  tabClassName,
  CustomComponent,
  isShowIndex = false,
}) => {
  const style = `relative font-semibold text-s border-b-2 border-solid pt-0.25 pb-2.25 px-1
    inline-block transition-colors duration-300 ease-in-out cursor-pointer relative !no-underline group`;

  return (
    <>
      <div
        className={cn(
          'mb-7.5 border-b border-gray-200 w-full flex gap-x-4',
          className
        )}
      >
        {tabs.map((tab, i) => (
          <a
            href={`#${tab.name}`}
            key={tab.name}
            onClick={(e) => {
              e.preventDefault();
              if (tab.disabled) return;
              onSelectTab(tab, i);
            }}
            className={cn(
              style,
              {
                'text-primary-700 border-primary-700 active':
                  selectedTab.name === tab.name,
                'text-gray-500 border-transparent':
                  selectedTab.name !== tab.name,
                'hover:text-primary-700 hover:border-primary-700':
                  !tab.disabled,
                'opacity-60 hover:cursor-auto hover:text-gray-500 hover:border-transparent':
                  tab.disabled,
              },
              tabClassName
            )}
          >
            <div className="flex items-center">
              {isShowIndex && (
                <TabCounter
                  selected={selectedTab.name === tab.name}
                  disabled={tab.disabled}
                  value={i + 1}
                />
              )}
              {tab.icon && (
                <Icon
                  className="inline mr-2 mb-0.5"
                  width={16}
                  glyph={tab.icon}
                />
              )}
              {tab.title}
              {tab.badge}
            </div>
            {tab.value !== undefined && (
              <h4 className="mt-2 mb-3 text-gray-900 text-display-sm font-display text-medium sm:text-display-xs">
                {typeof tab.value === 'string'
                  ? tab.value
                  : formatNumber(tab.value)}
              </h4>
            )}
          </a>
        ))}
        {CustomComponent}
      </div>
      {selectedTab.component || children}
    </>
  );
};
