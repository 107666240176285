import { useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { RichTextEditorDropdown } from './rich-text-editor-dropdown';

export interface IProps {
  editor: Editor;
}

export const TiptapTableOptionsDropdown = ({ editor }: IProps) => {
  const items = useMemo(
    () => [
      {
        id: 'headerRow',
        value: 'Header row',
        iconName: IconMap.LayoutTop,
        onClick: () => {
          editor.commands.toggleHeaderRow();
        },
      },
      {
        id: 'headerColumn',
        value: 'Header column',
        iconName: IconMap.LayoutLeft,
        onClick: () => {
          editor.commands.toggleHeaderColumn();
        },
      },
    ],
    [editor]
  );

  const currentItemIds = useMemo(() => {
    const values: string[] = [];

    editor.view.state.tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;

      editor.view.state.doc.nodesBetween(from, to, (node) => {
        if ('table' === node.type.name) {
          const rowCells: Node[] = [];
          const columnCells: Node[] = [];

          node.content?.firstChild?.content?.forEach((th) => rowCells.push(th));
          node.content.forEach(
            (row) => row.firstChild && columnCells.push(row.firstChild)
          );

          if (rowCells.every((cell) => cell.type.name === 'tableHeader')) {
            values.push('headerRow');
          }

          if (columnCells.every((cell) => cell.type.name === 'tableHeader')) {
            values.push('headerColumn');
          }
        }
      });
    });

    return values;
  }, [editor.view.state]);

  return (
    <RichTextEditorDropdown
      isShowIconForSelectedItem
      items={items}
      selectedItemId={currentItemIds}
      dropdownStyles="min-w-50"
      triggerComponent={
        <>
          <Icon className="w-6 h-6" width={24} glyph={IconMap.Table} />
          <span>Table options</span>
        </>
      }
    />
  );
};
