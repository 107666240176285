import { call, put } from 'redux-saga/effects';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { handleError } from '../../../../utils/handleError';

export function* resendInvite({
  payload: { teamMemberId, teamId },
}: ReturnType<typeof actions.resendInvite>) {
  try {
    yield call(teamsApi.resendInvite, teamId, teamMemberId);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your invitations to join the team have been resent successfully.'
        )
      )
    );
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Invitation sending failed')
        )
      )
    );
  }
}
