import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Icon, OnOffToggle, Checkbox, Toggle } from '../../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { pagesModel } from '../../../../../features/pages';
import { Page, Restriction, RestrictionType } from '@distribute/shared/types';
import { IconMap } from '../../../../../shared/sprite';
import { RestrictedEmailsForm } from '../../../../../shared/ui';
import { subscriptionModel } from '../../../../../features/subscription';
import { FREE_SUBSCRIPTION_PLAN_ID } from '../../../../../features/subscription/config';
import { useCurrentPlan } from '../../../../../features/subscription/hooks';
import {
  UpdateRequireEmailToViewAction,
  UpdateRequireEmailToViewActionTypes,
  UpdateRequireEmailToViewDataAction,
} from '../../../../../features/conversion-kit/model/types';

type Props = {
  isShareModal?: boolean;
};

export const RequireEmailToViewConversionItem: React.FC<Props> = ({
  isShareModal = false,
}) => {
  const dispatch = useDispatch();
  const requireEmailToView = useSelector(
    conversionKitModel.selectors.selectRequireEmailToView
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  const [isRequireEmailToViewActive, setRequireEmailToViewActive] = useState(
    requireEmailToView?.isActive ?? false
  );
  const [shouldAuthenticateEmail, setShouldAuthenticateEmail] = useState(
    requireEmailToView?.isAuthEnabled ?? false
  );
  const [shouldRestrictEmail, setShouldRestrictEmail] = useState(
    requireEmailToView?.hasRestrictions ?? false
  );
  const currentPlan = useCurrentPlan();
  const hasFreePlan = currentPlan.id === FREE_SUBSCRIPTION_PLAN_ID;

  const updateRequireEmailToView = (
    data: UpdateRequireEmailToViewDataAction,
    cb?: (err?: Error) => void
  ) => {
    const { type, ...rest } = data;

    dispatch(
      conversionKitModel.actions.updateRequireEmailToView({
        type,
        documentContentId: currentPage.content.id,
        callback: cb,
        ...rest,
      } as UpdateRequireEmailToViewAction)
    );
  };

  const handleChange = (isActive: boolean) => {
    updateRequireEmailToView(
      { type: UpdateRequireEmailToViewActionTypes.Activation, isActive },
      (err) => {
        if (err) {
          setRequireEmailToViewActive(requireEmailToView?.isActive ?? false);
        }
      }
    );
    setRequireEmailToViewActive(isActive);
  };

  const handleUpgradeClick = () => {
    if (hasFreePlan) {
      dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
    }
  };

  const handleAuthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isAuthEnabled = event.target.checked;
    updateRequireEmailToView(
      {
        type: UpdateRequireEmailToViewActionTypes.Authentication,
        isAuthEnabled,
      },
      (err) => {
        if (err) {
          setShouldAuthenticateEmail(
            requireEmailToView?.isAuthEnabled ?? false
          );
        }
      }
    );
    setShouldAuthenticateEmail(isAuthEnabled);
  };

  const handleRestrictChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hasRestrictions = event.target.checked;
    updateRequireEmailToView(
      {
        type: UpdateRequireEmailToViewActionTypes.Restriction,
        hasRestrictions,
      },
      (err) => {
        if (err) {
          setShouldRestrictEmail(requireEmailToView?.hasRestrictions ?? false);
        }
      }
    );
    setShouldRestrictEmail(hasRestrictions);
  };

  const handleRestrictionTypeChange = useCallback(
    (type: RestrictionType, callBack: (err?: Error) => void) => {
      updateRequireEmailToView(
        {
          type: UpdateRequireEmailToViewActionTypes.Restriction,
          restrictions: {
            ...requireEmailToView?.restrictions,
            type,
          } as Restriction,
        },
        callBack
      );
    },
    [requireEmailToView?.restrictions]
  );

  const handleRestrictionDetailsChange = useCallback(
    (restrictions: Restriction) => {
      updateRequireEmailToView({
        type: UpdateRequireEmailToViewActionTypes.Restriction,
        restrictions,
      });
    },
    []
  );

  useEffect(() => {
    setRequireEmailToViewActive(requireEmailToView?.isActive ?? false);
  }, [requireEmailToView?.isActive]);

  useEffect(() => {
    setShouldAuthenticateEmail(requireEmailToView?.isAuthEnabled ?? false);
  }, [requireEmailToView?.isAuthEnabled]);

  useEffect(() => {
    setShouldRestrictEmail(requireEmailToView?.hasRestrictions ?? false);
  }, [requireEmailToView?.hasRestrictions]);

  return (
    <div
      className={classNames({
        'cursor-pointer': hasFreePlan,
      })}
      onClick={handleUpgradeClick}
    >
      <div
        className={classNames('flex items-center justify-between', {
          'mb-4': !isShareModal || isRequireEmailToViewActive,
        })}
      >
        <div>
          <p
            className={classNames('font-semibold text-gray-900', {
              'text-md': isShareModal,
              'flex gap-2 items-center': hasFreePlan,
            })}
          >
            Require email to view page
            {hasFreePlan ? (
              <span
                className="flex items-center justify-center rounded-xl border box-border font-base font-medium border-primary-400 bg-primary-50 !text-primary-700 leading-10 px-2 text-s gap-2 h-7 outline-none"
                color="primary-50"
              >
                <Icon
                  glyph={IconMap.Lock01}
                  width={20}
                  className="text-primary-500"
                />
                Upgrade
              </span>
            ) : null}
          </p>
          <p className="text-sm text-gray-500">
            Viewers must enter an email to view your document.
          </p>
        </div>
        <div
          className={classNames({
            'pointer-events-none': hasFreePlan,
          })}
        >
          {isShareModal ? (
            <Toggle
              disabled={hasFreePlan}
              checked={isRequireEmailToViewActive}
              onChange={handleChange}
              size="md"
            />
          ) : (
            <OnOffToggle
              disabled={hasFreePlan}
              checked={isRequireEmailToViewActive}
              onChange={handleChange}
              size="lg"
            />
          )}
        </div>
      </div>
      <div
        className={classNames('text-gray-500', {
          hidden: !isRequireEmailToViewActive,
        })}
      >
        <p
          className={classNames(
            'flex gap-2 items-center text-gray-700 font-semibold text-sm pb-4',
            {
              hidden: isShareModal,
            }
          )}
        >
          Access Settings
        </p>
        <div
          className={classNames({
            'mb-2 p-2 bg-gray-100 rounded-lg': isShareModal,
            'mb-4': !isShareModal,
          })}
        >
          <Checkbox
            className="pb-1"
            disabled={hasFreePlan}
            checked={shouldAuthenticateEmail}
            onChange={handleAuthChange}
          >
            <p
              className={classNames(
                'flex items-center gap-2 pl-2 font-semibold text-sm',
                {
                  'text-gray-700': !hasFreePlan,
                }
              )}
            >
              Email Authentication
            </p>
          </Checkbox>
          <p className="text-sm pl-6">
            Viewers must authenticate their identity through a secure link sent
            to their email before they can access your content.
          </p>
        </div>
        <div
          className={classNames({
            'p-2 bg-gray-100 rounded-lg': isShareModal,
          })}
        >
          <Checkbox
            className="pb-1"
            disabled={hasFreePlan}
            checked={shouldRestrictEmail}
            onChange={handleRestrictChange}
          >
            <p
              className={classNames(
                'flex items-center gap-2 pl-2 font-semibold text-sm',
                {
                  'text-gray-700': !hasFreePlan,
                }
              )}
            >
              Restrict Access
            </p>
          </Checkbox>
          <div className="pl-6 text-sm">
            <p
              className={classNames('text-sm', { 'mb-3': shouldRestrictEmail })}
            >
              Specify which visitors have viewing access or are restricted from
              your page by email addresses or domains.
            </p>
            {shouldRestrictEmail ? (
              <RestrictedEmailsForm
                restrictions={requireEmailToView?.restrictions as Restriction}
                onRestrictionChange={handleRestrictionDetailsChange}
                onRestrictedTypeChange={handleRestrictionTypeChange}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
