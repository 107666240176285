import { createAction } from '@reduxjs/toolkit';
import { environment } from '../../../environments/environment';
import { USE_LOCAL_EXTENSION } from '../config';

enum ExternalEvent {
  loginWithCustomToken = 'externalEvent/loginWithCustomToken',
  logout = 'externalEvent/logout',
  open = 'externalEvent/open',
}

const externalActions = {
  eventLoginWithCustomToken: createAction<string>(
    ExternalEvent.loginWithCustomToken
  ),
  eventLogout: createAction(ExternalEvent.logout),
  eventOpen: createAction(ExternalEvent.open),
};

export const getExtensionId = () => {
  if (environment.env === 'production') {
    return environment.extensionId;
  }

  const useLocalExtension = localStorage.getItem(USE_LOCAL_EXTENSION);

  return useLocalExtension
    ? environment.extensionLocalId
    : environment.extensionId;
};

export const sendExtensionOpen = async () => {
  const extensionId = getExtensionId();

  try {
    const response = await chrome.runtime.sendMessage(
      extensionId,
      externalActions.eventOpen()
    );
    return Boolean(response);
  } catch {
    return false;
  }
};

export const sendExtensionLogout = async () => {
  const extensionId = getExtensionId();

  try {
    const response = await chrome.runtime.sendMessage(
      extensionId,
      externalActions.eventLogout()
    );
    return Boolean(response);
  } catch {
    return false;
  }
};

export const sendExtensionLogin = async (token: string) => {
  const extensionId = getExtensionId();

  try {
    const response = await chrome.runtime.sendMessage(
      extensionId,
      externalActions.eventLoginWithCustomToken(token)
    );
    return Boolean(response);
  } catch {
    return false;
  }
};
