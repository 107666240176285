import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Folder } from '@distribute/shared/types';
import {
  getFolderPermissions,
  useSplitFoldersToPrivateShared,
} from '../../folders';
import { authUserModel } from '../../../entities/auth-user';
import { useTeamPermissions } from '../../teams';
import { FoldersGroup } from './FoldersGroup';
import { publicTemplatesModel } from '../model';

type IProps = {
  onSelectFolder: (folder: Folder) => void;
};

export const FoldersList: FC<IProps> = ({ onSelectFolder }) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const folders = useSelector(publicTemplatesModel.selectors.selectFolders);
  const teamPermissions = useTeamPermissions();
  const { privateFolders, sharedFolders } =
    useSplitFoldersToPrivateShared(folders);
  const sharedFoldersWithCreatePagePermission = useMemo<Folder[]>(
    () =>
      sharedFolders.filter(
        (folder) =>
          getFolderPermissions(currentUser, folder, teamPermissions)
            .isCanCreatePages
      ),
    [sharedFolders, currentUser, teamPermissions]
  );

  return (
    <div className="flex flex-col gap-5">
      <FoldersGroup
        folders={privateFolders}
        groupTitle="Private"
        onSelectFolder={onSelectFolder}
      />
      <FoldersGroup
        folders={sharedFoldersWithCreatePagePermission}
        groupTitle="Shared"
        onSelectFolder={onSelectFolder}
      />
    </div>
  );
};
