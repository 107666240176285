import { eventChannel } from '@redux-saga/core';
import { takeEvery } from '@redux-saga/core/effects';

import { handleChangeConnectivity } from './handleChangeConnectivity';

export function* saga() {
  const connectivityChanel = eventChannel(emitter => {
    window.addEventListener('online', emitter);
    window.addEventListener('offline', emitter);

    return () => {
      window.removeEventListener('offline', emitter);
      window.removeEventListener('online', emitter);
    };
  });
  yield takeEvery(connectivityChanel, handleChangeConnectivity);
}
