import { useWindowWidth } from '@distribute/frontend/utils';
import {
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Ref<T> = {
  ref: Record<string, RefObject<T>>;
};

type Height = Record<string, number>;

export const useGetHeightObjects = <T extends HTMLElement>() => {
  const { windowWidth } = useWindowWidth();
  const { current } = useRef<Ref<T>>({ ref: {} });

  const [height, setHeight] = useState<Height>({});

  const getObjectRef = useCallback(
    (id: string) => (current.ref[id] = current.ref[id] || createRef<T>()),
    [current]
  );

  const getObjectHeight = useCallback((id: string) => height[id], [height]);

  useEffect(() => {
    // Can't use height with overflow hidden inside the table tr
    setHeight(
      Object.entries(current.ref).reduce<Height>(
        (acc, [id, ref]) => ({ ...acc, [id]: ref?.current?.scrollHeight || 0 }),
        {}
      )
    );
  }, [windowWidth, current]);

  return {
    getObjectRef,
    getObjectHeight,
  };
};
