import React from 'react';
import { Modal, Button, Input } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { foldersModel } from '..';
import { Folder } from '@distribute/shared/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  folder: Folder;
};

type DeleteFolderModalForm = {
  confirmText: string;
  id: string;
};

const CONFIRMATION_TEXT = 'DELETE FOLDER';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
});

export const DeleteFolderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  folder,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    foldersModel.selectors.selectDeleteFolderIsLoading
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeleteFolderModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = () => {
    dispatch(
      foldersModel.actions.deleteFolder({
        id: folder.id,
        callback: () => {
          onModalClose();
        },
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete Folder"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Folder
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        Are you sure you want to delete the “{folder.title}” folder? All pages
        in this folder will also be deleted. You will not be able to restore
        them.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Type ${CONFIRMATION_TEXT} to confirm`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
