import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlanName,
  SubscriptionInterval,
  TeamOnboardingStatus,
} from '@distribute/shared/types';
import { Button, Icon } from '../../../../shared/ui';
import { onboardingModel } from '../../model';
import { teamsModel } from '../../../teams';
import { OnboardingWrapper } from './OnboardingWrapper';
import { StepSubscriptionImageBlock } from './StepSubscriptionImageBlock';
import {
  FREE_SUBSCRIPTION_PLAN_ID,
  subscriptionPlans,
} from '../../../subscription/config';
import {
  ItemPlanOnboarding,
  StripeElementsProvider,
  UpdatePlanModal,
} from '../../../subscription';
import { IconMap } from '../../../../shared/sprite';
import { getPromoCodeDiscountAmount } from '../../../subscription/lib';

export const StepSubscription: FC = () => {
  const [selectedPlanName, setSelectedPlanName] = useState(PlanName.PRO);
  const [isShowUpdatePlanModal, setIsShowUpdatePlanModal] = useState(false);
  const { id: teamId, subscriptionOnboardingPromoCode } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const dispatch = useDispatch();
  const plans = subscriptionPlans.filter(
    (i) => i.id !== FREE_SUBSCRIPTION_PLAN_ID
  );

  const handleStartTrial = () => {
    dispatch(
      onboardingModel.actions.createSubscriptionTrial({
        teamId,
        plan: selectedPlanName,
        interval: SubscriptionInterval.MONTH,
      })
    );
  };

  const handleFinishTeamOnboarding = () => {
    dispatch(onboardingModel.actions.finishTeamOnboarding());
  };

  return (
    <StripeElementsProvider>
      <OnboardingWrapper
        step={TeamOnboardingStatus.SUBSCRIPTION}
        customImageBlock={<StepSubscriptionImageBlock />}
      >
        <div className="text-display-md font-display text-gray-900 font-medium text-center">
          Join the Fiesta: Say Adiós to Whitepapers Forever!
        </div>
        <div className="mt-3 text-center font-normal text-md text-gray-600">
          Select a plan to trial for 7 days. You can add your credit card now
          for a seamless transition and discount after your trial (recommended).
        </div>
        {subscriptionOnboardingPromoCode && (
          <div className="flex items-center justify-between mt-6 px-6 py-2 bg-custom-yellow rounded-lg">
            <span className="text-md text-gray-900">
              Add your credit card today to get a{' '}
              <span className="font-semibold">
                {getPromoCodeDiscountAmount(subscriptionOnboardingPromoCode)}{' '}
                discount!
              </span>
            </span>
            <Button
              size="sm"
              color="primary"
              variant="text"
              onClick={() => setIsShowUpdatePlanModal(true)}
            >
              Add Credit Card
            </Button>
          </div>
        )}
        <div className="flex mt-6 gap-5">
          {plans.map((i) => (
            <ItemPlanOnboarding
              key={i.id}
              {...i}
              isSelected={i.id === selectedPlanName}
              onSelect={(id) => setSelectedPlanName(id as PlanName)}
            />
          ))}
        </div>
        <Button
          variant="text"
          color="primary"
          onClick={() => setIsShowUpdatePlanModal(true)}
          fullWidth
          className="mt-5"
        >
          Continue with 7-Day Free Trial
          <Icon glyph={IconMap.ArrowNarrowRight} width={20} className="ml-2" />
        </Button>
        <Button
          fullWidth
          variant="text"
          color="link"
          onClick={handleFinishTeamOnboarding}
          className="mt-2"
        >
          Skip this - I don't want a trial, I want a free plan only
        </Button>
        {isShowUpdatePlanModal && (
          <UpdatePlanModal
            isFromOnboarding
            planName={selectedPlanName}
            onClose={() => setIsShowUpdatePlanModal(false)}
            onUpdated={handleFinishTeamOnboarding}
            onSkip={handleStartTrial}
          />
        )}
      </OnboardingWrapper>
    </StripeElementsProvider>
  );
};
