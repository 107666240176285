import { FC, ReactNode } from 'react';
import { FeatureFlags } from '../types';
import { environment } from '../../../environments/environment';

type IProps = {
  feature: FeatureFlags;
  children: ReactNode;
  isForceEnabled?: boolean;
};

export const FeatureFlag: FC<IProps> = ({
  feature,
  children,
  isForceEnabled,
}) => {
  const isFeatureFlagEnabled = environment.featureFlags[feature];

  if (!isFeatureFlagEnabled && !isForceEnabled) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
