export const getQueryString = (
  queryParamsObj: Record<string, string | number | boolean | undefined>
) => {
  const urlSearchParams = new URLSearchParams();

  for (const paramName in queryParamsObj) {
    const value = queryParamsObj[paramName];

    if (value === undefined) continue;

    urlSearchParams.append(paramName, value.toString());
  }

  return urlSearchParams.toString();
};
