import { fork, takeEvery } from 'redux-saga/effects';
import { updateSubscription } from './updateSubscription';
import { cancelSubscription } from './cancelSubscription';
import { retryLatestFailedInvoice } from './retryLatestFailedInvoice';
import { loadInvoices } from './loadInvoices';
import { confirmPaymentMethod } from './confirmPaymentMethod';
import { subscriptionModel } from '..';

export function* subscriptionPageWorker() {
  yield fork(loadInvoices);
  yield takeEvery(
    subscriptionModel.actions.updateSubscription,
    updateSubscription
  );
  yield takeEvery(
    subscriptionModel.actions.cancelSubscription,
    cancelSubscription
  );
  yield takeEvery(
    subscriptionModel.actions.retryLatestFailedInvoice,
    retryLatestFailedInvoice
  );
  yield takeEvery(
    subscriptionModel.actions.confirmPaymentMethod,
    confirmPaymentMethod
  );
}
