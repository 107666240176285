import { UploadImage } from '../../ui/upload-form/UploadImage';

import { getUploadFormRect } from '../../ui/upload-form';
import { ImageExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      /**
       * Add an image
       */
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
      }) => ReturnType;

      deleteImage: () => ReturnType;
      renderImageUploadForm: (onDestroy?: () => void) => ReturnType;
    };
  }
}

export const ImageExtension = ImageExt.extend({
  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },

      deleteImage:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
      renderImageUploadForm:
        (onDestroy) =>
        ({ editor }) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              UploadImage,
              {
                onUploaded: ({ fileUrl, fileName = 'untitled' }) => {
                  destroyComponent();
                  onDestroy?.();
                  editor
                    .chain()
                    .setResizeableFigure({
                      content: [
                        {
                          type: 'image',
                          attrs: {
                            src: fileUrl,
                            alt: fileName,
                            title: fileName,
                          },
                        },
                      ],
                    })
                    .focus()
                    .run();
                },
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                },
              },
              {
                getReferenceClientRect: () => {
                  return getUploadFormRect(this.editor);
                },
              }
            );

          return true;
        },
    };
  },
});
