import { gongApi } from '../../../../shared/api';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { teamsModel } from '../../../../features/teams';
import { RT } from '../../../../shared/types';
import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { gongModel } from '..';

export function* deleteGongTeamSecrets() {
  try {
    yield put(gongModel.actions.setIsDeletingTeamSecrets(true));
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const updatedTeam: TeamFiltered = yield call(
      gongApi.deleteGongTeamSecrets,
      currentTeam.id
    );

    yield put(teamsModel.actions.setCurrentTeam(updatedTeam));

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully disconnected from Gong')
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to disconnect from Gong')
      )
    );
  } finally {
    yield put(gongModel.actions.setIsDeletingTeamSecrets(false));
  }
}
