import { UpdatePersonalizedCoverRequestType } from '@distribute/shared/api-types/personalized-cover';
import { api } from './instance';

export const personalizedCoverApi = {
  async updatePersonalizedCover(settings: UpdatePersonalizedCoverRequestType) {
    const { data } = await api.patch<UpdatePersonalizedCoverRequestType>(
      'personalized-cover',
      settings
    );
    return data;
  },

  async getPersonalizedCover() {
    const { data } = await api.get<UpdatePersonalizedCoverRequestType | null>(
      'personalized-cover'
    );
    return data;
  },

  async createPersonalizedLink(pageId: string, personName: string) {
    const { data } = await api.post('personalized-cover/personalized-link', {
      pageId,
      personName,
    });
    return data;
  },
};
