import { isAxiosError } from 'axios';
import { logger, matchParams } from '../../../../shared/lib';
import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { handleError } from '../../../../utils/handleError';
import {
  ACCEPT_PAGE_INVITATION_ROUTE,
  history,
  redirect,
} from '../../../../entities/history';
import { RouteParams } from '../types';
import { RT } from '../../../../shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { teamsApi } from '../../../../shared/api';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { auth } from '../../../../shared/config';
import { AcceptPageInvitationResponseType } from '@distribute/shared/api-types/team';
import { sendEmailVerification } from '../../../auth/email-verification';

export function* handleAcceptPageInvitationSignedIn() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_PAGE_INVITATION_ROUTE
    );

    if (!params) return;

    const dbUser: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const {
      team,
      pageSequenceNumber,
      shouldVerifyEmail,
    }: AcceptPageInvitationResponseType = yield call(
      teamsApi.acceptPageInvitation,
      params.pageInviteId,
      params.teamInviteId
    );

    if (shouldVerifyEmail) {
      yield call(sendEmailVerification, `/editor/${pageSequenceNumber}`);
    }

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Invite has been accepted')
      )
    );

    yield put(
      authUserModel.actions.setDBUser({
        ...dbUser,
        onboardingStatus: shouldVerifyEmail
          ? UserOnboardingStatus.VERIFY_EMAIL
          : UserOnboardingStatus.FINISHED,
      })
    );

    yield auth.currentUser?.reload();

    return { team, pageSequenceNumber, shouldVerifyEmail };
  } catch (e) {
    logger.error(e);
    if (isAxiosError(e)) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'error',
            handleError(e, 'Failed to accept page invitation')
          )
        )
      );
      yield call(redirect.toWorkspace);
    }
    return;
  }
}
