import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Page } from '@distribute/shared/types';
import { authUserModel } from '../../../entities/auth-user';
import { foldersModel, useFolderPermissions } from '../../folders';
import { getPagePermissions } from '../lib/getPagePermissions';

export const usePagePermissions = (page?: Page) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);
  const folderPermissions = useFolderPermissions(currentFolder);

  const permissions = useMemo(() => {
    return getPagePermissions(currentUser, folderPermissions, page);
  }, [currentUser, folderPermissions, page]);

  return permissions;
};
