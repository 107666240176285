import { call, select, takeEvery } from 'redux-saga/effects';
import { User } from '@distribute/shared/types';

import { authModel } from '../../../entities/auth-operation';
import { authUserModel } from '../../../entities/auth-user';
import { redirect } from '../../../entities/history';
import { loginAsSuperuser } from './sagas';

export function* superuserSettingsWorker() {
  const currentUser: User = yield select(
    authUserModel.selectors.selectUserWithError
  );

  if (!currentUser.isAdmin) {
    yield call(redirect.toWorkspace);
  }

  yield takeEvery(authModel.actions.loginAsSuperuser, loginAsSuperuser);
}
