import { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';

export type SubmitReactHookFormResult<T extends FieldValues> =
  | {
      data: T;
      errors: undefined;
    }
  | {
      data: undefined;
      errors: FieldErrors<T>;
    };

export const submitReactHookForm = <T extends FieldValues>(
  reactHookFormInstance: UseFormReturn<T>
): Promise<SubmitReactHookFormResult<T>> => {
  return new Promise((resolve) => {
    reactHookFormInstance.handleSubmit(
      (data) => resolve({ data, errors: undefined }),
      (errors) => resolve({ errors, data: undefined })
    )();
  });
};
