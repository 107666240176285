import { call, put } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* updateNameDomain({
  payload: { teamId, domain, name },
}: ReturnType<typeof actions.updateNameDomain>) {
  try {
    yield put(actions.setIsUpdatingNameDomain(true));
    const updatedTeam: TeamFiltered = yield call(teamsApi.updateTeam, teamId, {
      domain,
      name,
    });
    yield call(teamsModel.sagas.onUpdateCurrentTeam, updatedTeam);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to update name/domain')
        )
      )
    );
  } finally {
    yield put(actions.setIsUpdatingNameDomain(false));
  }
}
