export type AIDraftItem = {
  id: string;
  label: string;
  text: string;
};

export const AIDraftsData: AIDraftItem[] = [
  {
    id: 'sales-email',
    label: 'Sales email...',
    text: 'Write a sales email to ',
  },
  {
    id: 'sales-email-sequence',
    label: 'Sales email sequence...',
    text: 'Write a sales email sequence for ',
  },
  {
    id: 'linkedin-post',
    label: 'Linkedin post...',
    text: 'Write a Linkedin post about ',
  },
  {
    id: 'meeting-agenda',
    label: 'Meeting agenda...',
    text: 'Write a meeting agenda about ',
  },
  {
    id: 'competitive-guide',
    label: 'Competitive guide...',
    text: 'Write a comparison guide on ',
  },
  {
    id: 'mutual-action-plan',
    label: 'Mutual action plan...',
    text: 'Write a mutual action plan about ',
  },
  {
    id: 'case-study',
    label: 'Case study...',
    text: 'Write a case study on ',
  },
  {
    id: 'pitch-deck',
    label: 'Pitch deck...',
    text: 'Write a pitch on ',
  },
];
