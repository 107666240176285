import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 } from 'uuid';

import { createTypesRegex } from '../../../../shared/lib';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';

import { TabContent, Tabs } from '../../../../shared/ui';
import { UploadInput } from './UploadInput';

import { recordingsModel } from '../../../../features/recordings';
import { mediaUploadModel } from '../../../../features/media-upload';
import { authUserModel } from '../../../auth-user';

import { UPLOAD_FORM_WIDTH, uploadingConfig } from './config';
import { UploadFormEmbed } from './UploadFormEmbed';
import { UploadRecords } from './upload-records';

import { EmbedContentAttrs } from '../../extensions/EmbedContent/types';
import { getLinkContentType } from '../../extensions/EmbedContent/lib';

export type UploadedData = {
  src: string;
  poster?: string;
};

export type VideoRecordData = {
  prefix: string;
};

type Props = {
  onClose: () => void;
  onVideoRecordAdded: (data: VideoRecordData) => void;
  onUploaded: (data: UploadedData) => void;
  onEmbeded: (data: Partial<EmbedContentAttrs>) => void;
};

export const UploadVideo: FC<Props> = ({
  onClose,
  onUploaded,
  onEmbeded,
  onVideoRecordAdded,
}) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const hasRecords = useSelector(
    recordingsModel.selectors.selectHasRecords(currentUser.id, true)
  );

  const tabs: TabContent[] = useMemo(
    () => [
      ...(hasRecords
        ? [
            {
              name: 'recordings',
              title: 'Quick Recordings',
            },
          ]
        : []),
      {
        name: 'upload',
        title: 'Upload Video',
      },
      {
        name: 'embed',
        title: 'Embed Video',
      },
    ],
    [hasRecords]
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const [id, setNewId] = useState(() => v4());
  const [selectedTab, setSelectedTab] = useState<TabContent>(tabs[0]);

  const dispatch = useDispatch();

  const file = useSelector(mediaUploadModel.selectors.selectFile(id));

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    if (file?.status === 'error') {
      setNewId(v4());
    }
  }, [file?.status]);

  const onSubmit = (link: string) => {
    const type = getLinkContentType(link);
    onEmbeded({
      link,
      type,
      available: true,
      noPlaceholderBlock: true,
    });
  };

  const {
    accept,
    allowedTypes,
    viewAllowedTypes,
    inputMessage,
    regexpType,
    maxSize,
  } = uploadingConfig.video;

  return (
    <div className="w-screen border-0 shadow-transparent bg-transparent flex justify-left z-10">
      <div
        style={{ maxWidth: UPLOAD_FORM_WIDTH }}
        className="w-full shadow-lg"
        tabIndex={1}
      >
        <div
          ref={ref}
          className="bg-base-white rounded-lg border border-gray-200"
        >
          <Tabs
            className="!mb-0 pt-4 px-4"
            tabs={tabs}
            selectedTab={selectedTab}
            onSelectTab={(tab) => setSelectedTab(tab as TabContent)}
          >
            <div className="p-4">
              {selectedTab.name === 'upload' && (
                <UploadInput
                  progress={file?.progress}
                  isUploading={file?.status === 'uploading'}
                  onUpload={(uploadedFile) =>
                    dispatch(
                      mediaUploadModel.actions.uploadFile({
                        file: uploadedFile,
                        id,
                        cb: (url) => onUploaded({ src: url }),
                      })
                    )
                  }
                  allowedTypes={viewAllowedTypes}
                  validationRegexp={createTypesRegex(allowedTypes, regexpType)}
                  accept={accept}
                  maxSize={maxSize}
                />
              )}
              {selectedTab.name === 'embed' && (
                <UploadFormEmbed
                  onSubmit={onSubmit}
                  message="Embed Video"
                  placeholder="Paste URL of video"
                  label={inputMessage}
                />
              )}
              {selectedTab.name === 'recordings' && (
                <UploadRecords handleClick={onVideoRecordAdded} />
              )}
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
