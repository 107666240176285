import { formatMsToTimePassed } from '@distribute/shared/utils';
import { DateRange } from '../../../shared/ui/date-range-picker';
import { round } from '../lib';
import { ChartData } from '../model/types';
import {
  ConversionSource,
  ConversionLine,
  AnalyticsConversionMetrics,
  AnalyticsConversionSource,
} from '../types';

export const getCumulativeArray = (array: number[]): number[] =>
  array.map(
    (
      (sum) => (value: number) =>
        (sum += value)
    )(0)
  );

const analyticsConversionFields = [
  'conversions',
  'conversionRate',
  'averageTimeToConvert',
];

const defaultConversionSources: ConversionLine[] = [
  { id: 'requiredEmail', label: 'Required email', isShow: true },
  { id: 'alertBar', label: 'Alert Bar', isShow: true },
  { id: 'popUp', label: 'Pop-Up', isShow: true },
  { id: 'cta', label: 'CTA Section', isShow: true },
  { id: 'gatedContent', label: 'Gated Content', isShow: true },
  { id: 'squeezePage', label: 'Squeeze Page', isShow: true },
];

export const checkIsAnalyticsConversionMetric = (
  metric: string
): metric is AnalyticsConversionMetrics =>
  analyticsConversionFields.includes(metric);

export const getCurrentConversionSourcesCharts = (
  analyticsData: ChartData
): ConversionLine[] => {
  const visibleConversionSources: Partial<
    Record<ConversionSource['id'], boolean>
  > = {};

  analyticsData.forEach((data) => {
    const selectedConversion = data['conversions'];
    const selectedConversionKeys = Object.keys(
      selectedConversion
    ) as AnalyticsConversionSource[];

    selectedConversionKeys.forEach((key) => {
      visibleConversionSources[key] =
        visibleConversionSources[key] || Boolean(selectedConversion[key]);
    });
  });

  return defaultConversionSources.filter((c) =>
    Boolean(visibleConversionSources[c.id])
  );
};

export const getRangeData = (
  analyticsData: ChartData,
  dateRange: DateRange | undefined
): ChartData => {
  const isDateRange = dateRange && dateRange.from && dateRange.to;

  return isDateRange
    ? analyticsData.filter(
        (item) =>
          dateRange.from &&
          dateRange.to &&
          new Date(item.date) > dateRange?.from &&
          new Date(item.date) <= dateRange?.to
      )
    : analyticsData;
};

export const getConversionFormatValue = (
  value: number,
  type: AnalyticsConversionMetrics
) => {
  switch (type) {
    case 'conversionRate':
      return `${round(value, 1)}%`;
    case 'averageTimeToConvert':
      return formatMsToTimePassed(value * 1000);
    case 'conversions':
      return value;
    default:
      return '-';
  }
};
