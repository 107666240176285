import React, { FC } from 'react';
import cn from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { Toggle } from '../../../../shared/ui';
import { viewersModel } from '../../model';

export const ToggleAnonymousUsers: FC = () => {
  const dispatch = useDispatch();

  const { isShowAnonymous, totalAnonymous } = useSelector(
    viewersModel.selectors.selectViewers
  );
  const { isDisplayAnonymousToggle } = useSelector(
    viewersModel.selectors.selectViewers
  );

  const handleChange = () => {
    dispatch(viewersModel.actions.onChangeIsShowAnonymous(!isShowAnonymous));
  };

  return (
    <div
      className={cn('sm:hidden ml-auto flex flex-nowrap gap-x-4', {
        hidden: !isDisplayAnonymousToggle,
      })}
    >
      <Toggle checked={isShowAnonymous} onChange={handleChange} />
      <p className="text-sm font-medium text-gray-700">
        {`Show anonymous viewers (${totalAnonymous})`}
      </p>
    </div>
  );
};
