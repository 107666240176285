import React from 'react';
import { IconMap } from '../../../../../shared/sprite';
import { Icon, OnOffToggle } from '../../../../../shared/ui';
import { SpriteGlyph } from '../../../../../shared/ui/Icon';
import { ConversionWarningItem } from './ConversionWarningItem';

type Props = {
  title: string;
  description: string;
  onClick(): void;
  icon: SpriteGlyph;
  shouldDisplayToggle: boolean;
  isActive: boolean;
  handleToggle(): void;
  isSetupPopupWarning?: boolean;
  isPopupWarning?: boolean;
  onWarningClick?(): void;
};

export const EditorSidebarConversionItem: React.FC<Props> = ({
  title,
  description,
  onClick,
  icon,
  shouldDisplayToggle,
  isActive,
  handleToggle,
  isSetupPopupWarning,
  isPopupWarning,
  onWarningClick,
}) => {
  return (
    <div
      className="bg-base-white rounded-xl border border-gray-200 flex justify-between items-center p-4 pl-3 pr-4 h-19 group hover:shadow-md transition-all cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <Icon glyph={icon} width={44} height={40} />
        <div>
          <p className="text-md font-medium text-gray-900">{title}</p>
          <p className="text-gray-600 text-s">{description}</p>
        </div>
      </div>
      <div className="self-center">
        {shouldDisplayToggle ? (
          <div className="flex gap-6 items-center">
            <Icon
              glyph={IconMap.Edit02}
              width={20}
              className="text-gray-600 opacity-0 transition-all group-hover:opacity-100"
            />
            {isSetupPopupWarning && (
              <ConversionWarningItem onClick={onWarningClick}>
                <p>Don’t forget to set up pop-up,</p>
                <p>as it's required for the Alert Bar</p>
                <p>button to function properly.</p>
              </ConversionWarningItem>
            )}
            {isPopupWarning && (
              <ConversionWarningItem onClick={onWarningClick}>
                <p>Don’t forget to set up pop-up</p>
                <p>triggers or add Alert Bar or CTA</p>
                <p>Section with pop-up type.</p>
              </ConversionWarningItem>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <OnOffToggle checked={!!isActive} onChange={handleToggle} />
            </div>
          </div>
        ) : (
          <Icon
            glyph={IconMap.ArrowNarrowRight}
            className="text-primary-700 mr-2 transition-all group-hover:mr-0"
            width={24}
          />
        )}
      </div>
    </div>
  );
};
