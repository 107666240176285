import React, { useEffect, useState } from 'react';
import { TableMenuPlugin, tableMenuPluginKey } from './TableMenuPlugin';
import { useTiptapEditor } from '../../lib';
import { TiptapTableOptionsDropdown } from '../../ui/basic-editor/TiptapTableOptionsDropdown';
import { TiptapCellOptionsDropdown } from '../../ui/basic-editor/TiptapCellOptionsDropdown';
import { TiptapTableStylesDropdown } from '../../ui/basic-editor/table-styles-dropdown';
import { BubbleMenuIconButton } from '../../ui';
import { IconMap } from '../../../../shared/sprite';

export const TableMenu = () => {
  const [isTableCopied, setIsTableCopied] = useState(false);
  const [isShowCopyTableTooltip, setIsShowCopyTableTooltip] = useState(false);
  const { editor } = useTiptapEditor();
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!element || !editor) {
      return;
    }

    if (editor.isDestroyed) {
      return;
    }

    const plugin = TableMenuPlugin({
      editor,
      element,
    });
    editor.registerPlugin(plugin);

    return () => editor.unregisterPlugin(tableMenuPluginKey);
  }, [editor, element]);

  const handleTooltipOpenChange = (isOpen: boolean) => {
    setIsShowCopyTableTooltip(isOpen);
    setIsTableCopied(false);
  };

  const handleCopyTable = () => {
    editor?.commands.copyTable(() => {
      setIsShowCopyTableTooltip(true);
      setIsTableCopied(true);
    });
  };

  if (!editor) {
    return null;
  }

  return (
    <div
      ref={setElement}
      style={{ visibility: 'hidden' }}
      onClick={() => editor.commands.focus()}
    >
      <div className="flex gap-2 border border-gray-200 px-3 py-1.5 shadow-md bg-base-white rounded-lg">
        <TiptapTableOptionsDropdown editor={editor} />
        <div className="w-px bg-gray-200"></div>
        <TiptapCellOptionsDropdown editor={editor} />
        <div className="w-px bg-gray-200"></div>
        <TiptapTableStylesDropdown editor={editor} />
        <div className="w-px bg-gray-200"></div>
        <div className="flex gap-x-2">
          <BubbleMenuIconButton
            iconName={IconMap.TypeStrikethrough}
            tooltip="Remove formatting"
            onClick={editor.commands.removeTableCellFormatting}
          />
          <BubbleMenuIconButton
            iconName={IconMap.Eraser}
            tooltip="Clear cell(s)"
            onClick={editor.commands.clearTableCellContent}
          />
        </div>
        <div className="w-px bg-gray-200"></div>
        <BubbleMenuIconButton
          isTooltipOpen={isShowCopyTableTooltip}
          onTooltipOpenChange={handleTooltipOpenChange}
          iconName={IconMap.MenuCopy}
          tooltip={isTableCopied ? 'Copied!' : 'Copy table'}
          onClick={handleCopyTable}
        />
        <div className="w-px bg-gray-200"></div>
        <BubbleMenuIconButton
          iconName={IconMap.Trash}
          tooltip="Delete table"
          onClick={editor.commands.deleteTable}
        />
      </div>
    </div>
  );
};
