import { RootState } from '../../../app';

export const selectAuthError = ({ auth: { authError } }: RootState) =>
  authError;
export const selectLoading = ({ auth: { authIsLoading } }: RootState) =>
  authIsLoading;
export const selectEmailFromTeamOrPageInvitationLink = ({
  auth: { emailFromTeamOrPageInvitationLink },
}: RootState) => emailFromTeamOrPageInvitationLink;
export const selectIsWaitingToRedirectAfterAuth = ({
  auth: { isWaitingToRedirectAfterAuth },
}: RootState) => isWaitingToRedirectAfterAuth;
