import { Attrs } from '@tiptap/pm/model';
import { NodeViewProps } from '@tiptap/react';

export const updateAttributesTransaction = (
  editor: NodeViewProps['editor'],
  pos: number,
  attr: Attrs
) => {
  const tr = editor.state.tr;

  tr.setMeta('addToHistory', false);
  tr.setNodeMarkup(pos, undefined, attr);

  editor.view.dispatch(tr);
};
