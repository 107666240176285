import { takeLatest } from 'redux-saga/effects';

import { recordingsModel } from '../../../features/recordings';

export function* recordActionsWorker() {
  yield takeLatest(
    recordingsModel.actions.insertVideoToPage,
    recordingsModel.sagas.insertVideoToPage
  );
  yield takeLatest(
    recordingsModel.actions.updateRecordName,
    recordingsModel.sagas.updateRecordName
  );
  yield takeLatest(
    recordingsModel.actions.downloadRecord,
    recordingsModel.sagas.downloadRecord
  );
  yield takeLatest(
    recordingsModel.actions.updateRecord,
    recordingsModel.sagas.updateRecord
  );
  yield takeLatest(
    recordingsModel.actions.deleteRecord,
    recordingsModel.sagas.deleteRecord
  );
  yield takeLatest(
    recordingsModel.actions.createUserPermission,
    recordingsModel.sagas.createUserPermission
  );
  yield takeLatest(
    recordingsModel.actions.updateUserPermission,
    recordingsModel.sagas.updateUserPermission
  );
  yield takeLatest(
    recordingsModel.actions.deleteUserPermission,
    recordingsModel.sagas.deleteUserPermission
  );
  yield takeLatest(
    recordingsModel.actions.updateTeamMemberPermission,
    recordingsModel.sagas.updateTeamMemberPermission
  );
}
