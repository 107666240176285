import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import {
  SnippetsSharingAccess,
  TemplateSharingAccess,
} from '@distribute/shared/types';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkClassName?: 'string';
} & InputHTMLAttributes<HTMLInputElement>;

export const RadioButtonForPermissions = forwardRef<HTMLInputElement, Props>(
  function RadioButton(
    { name, value, onChange, defaultChecked, disabled },
    ref
  ) {
    return (
      <label>
        <input
          type="radio"
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          checked={defaultChecked}
          ref={ref}
          className="hidden"
        />
        <Tooltip
          sideOffset={4}
          hideArrow
          className={cn('w-33 py-2 px-3', { hidden: !disabled })}
          trigger={
            <div
              className={cn(
                `py-3 px-4 border relative rounded-xl flex flex-col items-center cursor-pointer gap-1.5 w-30 bg-base-white`,
                {
                  'border-2 border-primary-600 hover:bg-primary-50':
                    defaultChecked,
                  'border-2 border-gray-300 hover:bg-gray-50':
                    !defaultChecked && !disabled,
                  'bg-gray-50 hover:bg-gray-50 border border-gray-300':
                    !defaultChecked && disabled,
                }
              )}
            >
              <Icon
                glyph={
                  value === TemplateSharingAccess.TEAM ||
                  value === SnippetsSharingAccess.TEAM
                    ? IconMap.Members
                    : IconMap.CrossedEye
                }
                width={24}
                className={cn({
                  'text-primary-700': defaultChecked,
                  'text-gray-600': !defaultChecked && !disabled,
                  'text-gray-400': !defaultChecked && disabled,
                })}
              />
              <span
                className={cn('text-sm font-semibold', {
                  'text-primary-700': defaultChecked,
                  'text-gray-700': !defaultChecked && !disabled,
                  'text-gray-400': !defaultChecked && disabled,
                })}
              >
                {value === TemplateSharingAccess.TEAM ||
                value === SnippetsSharingAccess.TEAM
                  ? 'All team'
                  : 'Only me'}
              </span>
            </div>
          }
        >
          <div className=" font-semibold text-xs text-center">
            The team snippet cannot be switched back to personal.
          </div>
        </Tooltip>
      </label>
    );
  }
);
