import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, string } from 'yup';
import slugify from 'slugify';
import { TeamFiltered } from '@distribute/shared/types';
import { Button, Input, Modal } from '../../../shared/ui';
import { DebounceSubdomainInput } from '../../auth/workspace';
import { teamsModel } from '../model';

type IProps = {
  isOpen: boolean;
  isSetCreatedTeamAsCurrent?: boolean;
  onClose: () => void;
  onTeamCreated?: (team: TeamFiltered) => void;
};

type CreateTeamSchema = {
  name: string;
  isSubdomainValid: boolean;
};

const nameDomainSchema = object().shape({
  name: string().required('Name is required'),
  isSubdomainValid: boolean().required().oneOf([true]),
});

export const CreateTeamModal: FC<IProps> = ({
  isOpen,
  isSetCreatedTeamAsCurrent = true,
  onClose,
  onTeamCreated,
}) => {
  const [subdomain, setSubdomain] = useState('');
  const dispatch = useDispatch();
  const isCreatingTeam = useSelector(teamsModel.selectors.selectIsCreatingTeam);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateTeamSchema>({
    resolver: yupResolver(nameDomainSchema),
    defaultValues: { name: '', isSubdomainValid: true },
  });
  const name = watch('name');

  const handleClose = () => {
    onClose();
    setSubdomain('');
    reset();
  };

  const handleFormSubmit = handleSubmit((data: CreateTeamSchema) => {
    dispatch(
      teamsModel.actions.createTeam({
        name: data.name,
        domain: subdomain,
        isSetCreatedTeamAsCurrent,
        cb: (team) => {
          handleClose();
          onTeamCreated?.(team);
        },
      })
    );
  });

  useEffect(() => {
    slugify.extend({ $: '' });
    setSubdomain(
      slugify(name, {
        lower: true,
        trim: true,
        remove: /[*+~.()'"`!:@/]/g,
        strict: true,
      })
    );
  }, [name]);

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      title="Add Team"
      className="w-150"
      isActionButtonsAlignEnd
      actionButton={
        <Button
          type="submit"
          color="primary"
          variant="text"
          loading={isCreatingTeam}
          onClick={handleFormSubmit}
        >
          Create
        </Button>
      }
    >
      <form className="flex flex-col gap-5">
        <Input
          label="Name"
          {...register('name')}
          isError={!!errors.name}
          type="text"
          placeholder="Workspace’s Name"
        />

        <div>
          <input {...register('isSubdomainValid')} type="checkbox" hidden />
          <DebounceSubdomainInput
            isHideLabelInsideInputWhenUntouched
            subdomain={subdomain}
            setSubdomain={setSubdomain}
            setIsSubdomainValid={(val) =>
              setValue('isSubdomainValid', val, { shouldValidate: true })
            }
            isLoading={isCreatingTeam}
            getValues={watch}
            currentSubdomain={''}
            placeholder="subdomain"
          />
        </div>
      </form>
    </Modal>
  );
};
