import { ViewerDetails } from '@distribute/shared/types';
import { ViewerCategory } from '@distribute/shared/api-types/viewers';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExportCsvAction,
  GetViewersDataAction,
  LoadingDataSource,
} from './types';
import { globalActions } from '../../../app/model/actions';
import { DateRange } from '../../../shared/ui/date-range-picker';

type State = {
  loadingDataSource: LoadingDataSource;
  data: ViewerDetails[];
  page: number;
  totalPages: number;
  totalAnonymous: number;
  total: number;
  category: ViewerCategory;
  isDisplayAnonymousToggle: boolean;
  isShowAnonymous: boolean;
  isExportCsvLoading: boolean;
  search: string;
  range: DateRange;
  details: Record<string, boolean>;
};

const initialState: State = {
  loadingDataSource: undefined,
  data: [],
  page: 1,
  totalPages: 0,
  totalAnonymous: 0,
  total: 0,
  category: ViewerCategory.LEAD,
  isDisplayAnonymousToggle: false,
  isShowAnonymous: true,
  isExportCsvLoading: false,
  search: '',
  range: {},
  details: {},
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'viewers',
  initialState,
  reducers: {
    setViewersData: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<State, 'data' | 'total' | 'totalPages' | 'totalAnonymous'>
      >
    ) => ({
      ...state,
      ...payload,
    }),
    setLoadingDataSource: (
      state,
      { payload: loadingDataSource }: PayloadAction<State['loadingDataSource']>
    ) => ({
      ...state,
      loadingDataSource,
    }),
    setPage: (state, { payload: page }: PayloadAction<State['page']>) => ({
      ...state,
      page,
    }),
    setDetails: (
      state,
      { payload: details }: PayloadAction<State['details']>
    ) => ({
      ...state,
      details,
    }),
    setRange: (state, { payload: range }: PayloadAction<State['range']>) => ({
      ...state,
      range,
    }),
    setSearch: (
      state,
      { payload: search }: PayloadAction<State['search']>
    ) => ({
      ...state,
      search,
    }),
    setCategory: (
      state,
      { payload: category }: PayloadAction<State['category']>
    ) => ({
      ...state,
      category,
    }),
    setIsShowAnonymous: (
      state,
      { payload: isShowAnonymous }: PayloadAction<State['isShowAnonymous']>
    ) => ({
      ...state,
      isShowAnonymous,
    }),
    setIsDisplayAnonymousToggle: (
      state,
      {
        payload: isDisplayAnonymousToggle,
      }: PayloadAction<State['isDisplayAnonymousToggle']>
    ) => ({
      ...state,
      isDisplayAnonymousToggle,
    }),
    setIsExportCsvLoading: (
      state,
      {
        payload: isExportCsvLoading,
      }: PayloadAction<State['isExportCsvLoading']>
    ) => ({
      ...state,
      isExportCsvLoading,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  getViewersData: createAction<GetViewersDataAction>('viewers/getViewersData'),
  exportCsv: createAction<ExportCsvAction>('viewers/exportCsv'),
  onChangeIsShowAnonymous: createAction<boolean>(
    'viewers/onChangeIsShowAnonymous'
  ),
  onChangeCategory: createAction<State['category']>('viewers/onChangeCategory'),
  onChangeRange: createAction<State['range']>('viewers/onChangeRange'),
};
