import { RedirectToLoggedActions } from '../../../shared/types';
import { getQueryParam } from '../../../shared/lib';
import {
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../accept-team-invitation';
import { authChannelModel } from '../../../entities/auth-channel';
import { put } from 'redux-saga/effects';

export function* redirectAfterSignUp(defaultStep: RedirectToLoggedActions) {
  const teamInviteId = getQueryParam(QUERY_PARAM_TEAM_INVITE_ID);
  const pageInviteId = getQueryParam(QUERY_PARAM_PAGE_INVITE_ID);

  if (pageInviteId) {
    yield put(
      authChannelModel.chan,
      authChannelModel.loggedIn({
        redirectTo: 'accept-page-invitation',
        data: { pageInviteId, teamInviteId: teamInviteId ?? '' },
      })
    );
    return;
  }

  yield put(
    authChannelModel.chan,
    authChannelModel.loggedIn({
      redirectTo: teamInviteId ? 'accept-team-invitation' : defaultStep,
      data: teamInviteId ? { teamInviteId } : undefined,
    })
  );
}
