import { Node, mergeAttributes } from '@tiptap/core';

export const VideoRecordExt = Node.create({
  name: 'videoRecord',
  group: 'media',

  atom: true,

  addAttributes() {
    return {
      prefix: {
        default: null,
      },
      playbackId: {
        default: null,
      },
      aspectRatio: {
        default: null,
      },

      // Deprecated [loading, error, data]
      loading: {
        default: true,
      },
      error: {
        default: false,
      },
      data: {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'video-record',
      mergeAttributes({
        'playback-id': HTMLAttributes?.playbackId,
        'aspect-ratio': HTMLAttributes?.aspectRatio,
        data: JSON.stringify(HTMLAttributes?.data || {}),
      }),
    ];
  },
});
