import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlanName, SubscriptionPlanName } from '@distribute/shared/types';
import { Section } from '../../../shared/ui';
import {
  AlertBannerOnboardingPromoCodeAvailable,
  CurrentPlanInfo,
  InvoicesTable,
  ItemPlan,
  CurrentPaymentMethod,
  UpdatePlanModal,
  CancelSubscriptionModal,
  RenewPlanModal,
  AlertBannerTrialExpireSoon,
  RenewPlanWithPromoCodeModal,
  AlertBanner,
} from './components';
import { FREE_SUBSCRIPTION_PLAN_ID, subscriptionPlans } from '../config';
import { useSubscriptionInfo } from '../hooks';
import { StripeElementsProvider } from './components/StripeElementsProvider';
import { AlertBannerPaymentFailed } from './components/AlertBanners/AlertBannerPaymentFailed';
import { checkIsMaxMembersCountForPlanExceed } from '../lib';
import { useSubscriptionTeamMembers } from '../hooks/useSubscriptionTeamMembers';
import { subscriptionModel } from '../model';
import { teamsModel } from '../../teams';

export const Subscription: FC = () => {
  const dispatch = useDispatch();
  const [isShowCancelSubscriptionModal, setIsShowCancelSubscriptionModal] =
    useState(false);
  const [isShowRenewSubscriptionModal, setIsShowRenewSubscriptionModal] =
    useState(false);
  const [isShowRenewWithPromoCodeModal, setIsShowRenewWithPromoCodeModal] =
    useState(false);
  const [planNameToUpdate, setPlanNameToUpdate] = useState<PlanName | null>(
    null
  );
  const setIsShowUpdatePaymentMethodModal = (isOpen: boolean) => {
    dispatch(
      subscriptionModel.actions.setIsShowUpdatePaymentMethodModal(isOpen)
    );
  };

  const setIsShowThanksConfirmPaymentMethod = (isOpen: boolean) => {
    dispatch(
      subscriptionModel.actions.setIsShowThanksConfirmPaymentMethod(isOpen)
    );
  };

  const { subscriptionPaymentMethod } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isAppSumoUser = currentTeam.isAppsumo;

  const {
    isShowAlertBannerOnboardingPromoCodeAvailable,
    isPaymentFailed,
    isShowAlertBannerTrialExpireSoon,
    isNeedConfirmPaymentMethod,
  } = useSubscriptionInfo();
  const teamMembers = useSubscriptionTeamMembers();

  const handleUpdatePlan = useCallback(
    (planName: SubscriptionPlanName) => {
      if (planName === FREE_SUBSCRIPTION_PLAN_ID) {
        return setIsShowCancelSubscriptionModal(true);
      }
      if (checkIsMaxMembersCountForPlanExceed(planName, teamMembers.length)) {
        return dispatch(
          subscriptionModel.actions.setIsShowPlanReachedMembersLimitModal(true)
        );
      }

      setPlanNameToUpdate(planName);
    },
    [teamMembers.length, dispatch]
  );

  return (
    <StripeElementsProvider>
      <div className="pb-12">
        <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
          Plans & Billing
        </h1>
        <Section title="Your Subscription">
          <div className="flex flex-col gap-6 max-w-268">
            {isNeedConfirmPaymentMethod && (
              <AlertBanner
                theme="warning"
                title="Finish transferring ownership to you by confirming payment method."
                description="Once you've confirmed the payment method, the ownership transfer process will be finalized, and you'll be in full control of the team."
              />
            )}
            {isShowAlertBannerOnboardingPromoCodeAvailable && (
              <AlertBannerOnboardingPromoCodeAvailable />
            )}
            {isPaymentFailed && (
              <AlertBannerPaymentFailed
                onRenew={() => setIsShowUpdatePaymentMethodModal(true)}
              />
            )}
            {isShowAlertBannerTrialExpireSoon && !isAppSumoUser && (
              <AlertBannerTrialExpireSoon
                onUpdatePaymentMethod={() =>
                  setIsShowUpdatePaymentMethodModal(true)
                }
              />
            )}
            <div className="flex gap-6 sm:flex-wrap">
              <CurrentPlanInfo
                onCancelSubscription={() =>
                  setIsShowCancelSubscriptionModal(true)
                }
                onRenewSubscription={() =>
                  setIsShowRenewSubscriptionModal(true)
                }
              />
              {(!isAppSumoUser ||
                (isAppSumoUser && subscriptionPaymentMethod)) && (
                <CurrentPaymentMethod
                  onUpdatePaymentMethod={() =>
                    setIsShowUpdatePaymentMethodModal(true)
                  }
                  onConfirmPaymentMethod={() =>
                    setIsShowThanksConfirmPaymentMethod(true)
                  }
                />
              )}
            </div>
          </div>
        </Section>
        <Section title="Plans">
          <div className="flex gap-8 max-w-268 sm:flex-wrap">
            {subscriptionPlans.map((plan) => (
              <ItemPlan key={plan.id} {...plan} onUpdate={handleUpdatePlan} />
            ))}
          </div>
        </Section>
        <Section title="Invoice history">
          <div className="max-w-268">
            <InvoicesTable />
          </div>
        </Section>
      </div>
      <CancelSubscriptionModal
        isOpen={isShowCancelSubscriptionModal}
        onClose={() => setIsShowCancelSubscriptionModal(false)}
        onShowRenewWithPromoCode={() => setIsShowRenewWithPromoCodeModal(true)}
      />
      {planNameToUpdate && (
        <UpdatePlanModal
          isAppSumo={isAppSumoUser}
          planName={planNameToUpdate}
          onClose={() => setPlanNameToUpdate(null)}
          onUpdated={() => setPlanNameToUpdate(null)}
        />
      )}
      <RenewPlanModal
        isOpen={isShowRenewSubscriptionModal}
        onClose={() => setIsShowRenewSubscriptionModal(false)}
      />
      <RenewPlanWithPromoCodeModal
        isOpen={isShowRenewWithPromoCodeModal}
        onClose={() => setIsShowRenewWithPromoCodeModal(false)}
      />
    </StripeElementsProvider>
  );
};
