import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { templatesApi } from '../../../../shared/api/axios/templates';
import { authUserModel } from '../../../../entities/auth-user';
import { DBUser } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import {
  AnalyticsEvents,
  TeamFiltered,
  Template,
} from '@distribute/shared/types';
import { templatesModel } from '..';
import { logger } from '../../../../shared/lib';
import { setTemplatesData } from '../../lib';
import { analytics } from '../../../../entities/analytics';
import { redirect } from '../../../../entities/history';

export function* createBlankTemplate({
  payload: { ...payloadData },
}: ReturnType<typeof templatesModel.actions.createBlankTemplate>) {
  try {
    yield put(templatesModel.actions.setCreateBlankTemplateIsLoading(true));

    const { id: userId }: DBUser = yield select(
      authUserModel.selectors.selectUser
    );

    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeam
    );

    const template: Template = yield templatesApi.createBlankTemplate({
      ...payloadData,
      userId,
      teamId,
    });

    yield call(setTemplatesData);

    yield put(templatesModel.actions.setTemplateModalOpen(false));

    yield call(redirect.toTemplateEditorPage, {
      sequenceNumber: template.id.toString(),
    });

    analytics.track(AnalyticsEvents.TEMPLATE_IS_CREATED, {
      templateId: template.id,
      templateName: template.name,
    });
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create a template')
      )
    );
  } finally {
    yield put(templatesModel.actions.setCreateBlankTemplateIsLoading(false));
  }
}
