import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { globalActions } from '../../../app/model/actions';

type State = {
  eventLoginLoading: boolean;
  eventOpenLoading: boolean;
  isInstallModalOpen: boolean;
};

const initialState: State = {
  eventLoginLoading: false,
  eventOpenLoading: false,
  isInstallModalOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'extension',
  initialState,
  reducers: {
    setEventLoginLoading: (
      state,
      { payload }: PayloadAction<State['eventLoginLoading']>
    ) => ({
      ...state,
      eventLoginLoading: payload,
    }),
    setEventOpenLoading: (
      state,
      { payload }: PayloadAction<State['eventOpenLoading']>
    ) => ({
      ...state,
      eventOpenLoading: payload,
    }),
    setIsInstallModalOpen: (
      state,
      { payload }: PayloadAction<State['isInstallModalOpen']>
    ) => ({
      ...state,
      isInstallModalOpen: payload,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  closeInstallModalClick: createAction('extension/closeInstallModalClick'),
  extensionInstallClick: createAction('extension/installClick'),
  openClick: createAction('extension/openClick'),
};
