import { TeamFiltered } from '@distribute/shared/types';
import { customDomainsApi } from '../../../../shared/api';
import { call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../../teams';
import { CreateCustomDomainResponseType } from '@distribute/shared/api-types/custom-domains';
import { createNotification, snackbarModel } from '../../../snackbar';
import { customDomainsModel } from '..';

export function* createCustomDomain({
  payload: customDomain,
}: {
  payload: string;
}) {
  try {
    yield put(customDomainsModel.actions.setCustomDomainCreating(true));
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const data: CreateCustomDomainResponseType = yield call(
      customDomainsApi.createCustomDomain,
      currentTeam.id,
      customDomain
    );

    yield put(customDomainsModel.actions.setCustomDomainInfo(data));
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create a custom domain')
      )
    );
  } finally {
    yield put(customDomainsModel.actions.setCustomDomainCreating(false));
  }
}
