import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useRef,
  useState,
  ChangeEvent,
} from 'react';
import classNames from 'classnames';
import { nanoid } from '@reduxjs/toolkit';

import { Icon } from './Icon';
import { IconMap } from '../sprite';

export type ItemValue = {
  id: string;
  value: string;
  isError?: boolean;
};

type Props = {
  value: ItemValue[];
  label: ReactNode;
  placeholder?: string;
  isError?: boolean;
  onChange: (value: ItemValue[]) => void;
  validateItemValue?: (name: string) => string[] | undefined;
};

export const TagsInput: FC<Props> = ({
  value,
  label,
  placeholder = '',
  isError,
  onChange,
  validateItemValue,
}) => {
  const [currentTagValue, setCurrentTagValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputElement = useRef<HTMLInputElement>(null);
  const isPlaceholderVisible = placeholder && !value.length && !currentTagValue;

  const handleFocusInput = (e: MouseEvent<HTMLElement>) => {
    const element = e.currentTarget as HTMLElement;

    if (element.hasAttribute('data-container')) {
      inputElement.current?.focus();
    }
  };

  const handleDeleteTag = (id: string) => {
    onChange(value.filter((i) => i.id !== id));
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.code === 'Enter' || e.code === 'Space' || e.code === 'Comma') &&
      !!currentTagValue.trim()
    ) {
      const errors = validateItemValue?.(currentTagValue.trim());
      const newItem: ItemValue = {
        id: nanoid(),
        value: currentTagValue.trim(),
        isError: !!errors,
      };

      onChange([...value, newItem]);
      setCurrentTagValue('');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === ' ' || value === ',') return;
    setCurrentTagValue(value);
  };

  return (
    <div className="flex flex-col gap-y-1.5">
      <div className="text-sm font-medium text-gray-700">{label}</div>
      <div
        onClick={handleFocusInput}
        className={classNames(
          'group/input flex flex-wrap items-start gap-1.5 border border-gray-300 rounded-lg bg-base-white p-3 h-23.5 overflow-auto',
          { '!border-error-600': isError },
          { '!border-primary-600': isFocused && !isError }
        )}
        data-container
      >
        {value.map(({ id, value, isError }) => (
          <div
            key={id}
            className={classNames(
              'flex gap-1.5 items-center bg-base-white border border-gray-300 rounded-md pl-2.25 pr-1.5 py-0.75 text-sm font-medium text-gray-700 overflow-hidden',
              {
                '!border-error-600': isError,
              }
            )}
          >
            <span className="truncate">{value}</span>
            <Icon
              glyph={IconMap.XClose}
              width={14}
              className="shrink-0 text-gray-400 cursor-pointer"
              onClick={() => handleDeleteTag(id)}
            />
          </div>
        ))}
        {isPlaceholderVisible ? (
          <span className="group-focus-within/input:hidden">{placeholder}</span>
        ) : null}
        <input
          ref={inputElement}
          value={currentTagValue}
          size={currentTagValue.length + 2}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="text-md text-gray-900 py-0.5 outline-none"
        />
      </div>
    </div>
  );
};
