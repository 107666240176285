import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useTiptapEditor } from '../../../lib';
import { JSONContent, generateJSON } from '@tiptap/react';
import { EditorWritingMode } from '../types';
import { Selection } from './useEditorContextSelection';

import { logger } from '../../../../../shared/lib';
import { normalizeWritingContent } from '../lib';

type Props = {
  selectionFromRef: MutableRefObject<number>;
  selectionToRef: MutableRefObject<number>;
  setSelection: (selection: Partial<Selection>) => void;
  resultText: string;
  editorWritingMode: EditorWritingMode;
  setFocus: () => void;
  setSearchValue: (val: string) => void;
};

export const useEditorWriting = ({
  resultText,
  editorWritingMode,
  selectionToRef,
  selectionFromRef,
  setSelection,
  setFocus,
  setSearchValue,
}: Props) => {
  const { editor } = useTiptapEditor();

  const [currentRequestContent, setCurrentRequestContent] =
    useState<JSONContent | null>(null);

  const from = selectionFromRef.current;
  const to = selectionToRef.current;

  const onInsertEditorContent = useCallback(
    (content: JSONContent | null, replaceSelection?: boolean) => {
      if (!editor) {
        return;
      }
      if (replaceSelection) {
        editor.commands.replaceSelection({
          from,
          to,
          content,
        });
      } else {
        editor
          .chain()
          .insertContentAt(to, content, { updateSelection: true })
          .run();
      }
    },
    [editor, from, to]
  );

  useEffect(() => {
    try {
      if (!editor) {
        return;
      }
      if (resultText.trim().length) {
        const content = normalizeWritingContent(
          generateJSON(resultText, editor.extensionManager.extensions)
        );

        if (editorWritingMode === EditorWritingMode.Editor) {
          editor.storage.ai.isShowInline = false;
          onInsertEditorContent(content, true);
          setSelection({ to: editor.state.selection.to });
        } else {
          setCurrentRequestContent(content);
          setSelection({ to: editor.state.selection.to });
        }
      }
      setFocus();
      setSearchValue('');
    } catch (e) {
      logger.error(e);
      return;
    }
  }, [
    editor,
    onInsertEditorContent,
    editorWritingMode,
    resultText,
    setSelection,
    setFocus,
    setSearchValue,
  ]);

  return {
    currentRequestContent,
    onInsertEditorContent,
  };
};
