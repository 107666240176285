import React, { FC } from 'react';
import { Tag } from './Tag';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Props = {
  isShort?: boolean;
};

export const UpgradePlanBadge: FC<Props> = ({ isShort }) => {
  return (
    <Tag
      color="green"
      className="rounded-full font-medium"
      size="sm"
      variant="icon-text"
      bordered
    >
      <Icon glyph={IconMap.Lock01} width={12} className="text-primary-500" />
      {isShort ? 'Upgrade' : 'Upgrade Plan'}
    </Tag>
  );
};
