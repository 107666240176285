import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useEffect, useState } from 'react';
import { TemplatesConfigEnum } from '../../../../features/templates';
import { templatesApi } from '../../../../shared/api';

export const useTemplatesApi = ({
  category = TemplatesConfigEnum.ALL,
  query = '',
  shouldRequest = true,
}: {
  category?: TemplatesConfigEnum;
  query?: string;
  shouldRequest?: boolean;
}) => {
  const [templates, setTemplates] = useState<TemplateExtended[]>([]);
  useEffect(() => {
    if (!shouldRequest) {
      return;
    }
    templatesApi
      .getPublicTemplates({
        category: category,
        query: query,
      })
      .then((res) => {
        setTemplates(res);
      });
  }, [category, query, shouldRequest]);

  return {
    templates,
  };
};
