export const USE_LOCAL_EXTENSION = 'USE_LOCAL_EXTENSION';

export const messages = {
  title: 'Get Chrome extension',
  description:
    'Create speedy recordings for your pages and import existing content effortlessly.',
  items: [
    { id: 1, text: 'It’s fully free' },
    { id: 2, text: 'Record over any window' },
    { id: 3, text: 'Import your existing content to Distribute' },
  ],
} as const;
