import { put, select } from 'redux-saga/effects';
import { Page, Template } from '@distribute/shared/types';
import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesApi } from '../../../../shared/api';
import { templatesModel } from '../../../templates';

export function* updatePageFromTemplate({
  payload: { pageId, contentItemId, cb },
}: ReturnType<typeof pagesModel.actions.updatePageFromTemplate>) {
  const currentPage: Page = yield select(
    pagesModel.selectors.selectCurrentPageWithError
  );

  try {
    const {
      id: templateId,
      content: { id: _, ...templateContent },
    }: Template = yield select(
      templatesModel.selectors.selectCurrentTemplateWithError
    );

    cb?.({
      ...currentPage,
      content: { ...currentPage.content, ...templateContent },
    });

    const page: Page = yield pagesApi.updatePage(pageId, {
      templateId,
      contentItemId,
    });

    yield put(pagesModel.actions.setCurrentPage(page));
  } catch (e: unknown) {
    cb?.(currentPage);
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update page')
      )
    );
  }
}
