import { Node, mergeAttributes } from '@tiptap/react';

export const EmbedContentExt = Node.create({
  name: 'embedContent',
  group: 'block',
  addAttributes() {
    return {
      opened: {
        default: false,
      },
      link: {
        default: null,
      },
      available: {
        default: null,
      },
      error: {
        default: null,
      },
      processingLink: {
        default: null,
      },
      type: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
    ];
  },
});
