import React, { useCallback, useEffect } from 'react';
import { subscribePopupModel } from '../model';
import { useDispatch, useSelector } from 'react-redux';

import { conversionKitModel } from '../../conversion-kit';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type IProps = {
  isDisabled: boolean;
};
export const SubscribePopup: React.FC<IProps> = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const isSubscribeModalOpen = useSelector(
    subscribePopupModel.selectors.selectSubscribePopupIsModalOpen
  );
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const toggleIsSubscribePopupOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(!isSubscribeModalOpen));
  }, [dispatch, isSubscribeModalOpen]);

  const handleOpenPopUpSettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.POP_UP
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    };
  }, [dispatch]);

  if (!popUp.isActive || isAnyConversionPanelOpen) return null;

  return (
    <ConversionControlItem
      title="Pop-up"
      icon={IconMap.PopupIcon}
      isOpened={isSubscribeModalOpen}
      isDisabled={isDisabled}
      onEdit={handleOpenPopUpSettings}
      onPreview={toggleIsSubscribePopupOpen}
    />
  );
};
