import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InviteTeamMembersAction,
  CreateTeamAction,
  SkipTeamOnboardingStepAction,
  UpdateUsagePlanAction,
  CreateSubscriptionTrialAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isLoadingCreateSubscriptionTrial: boolean;
  isLoadingSkipTeamOnboardingStep: boolean;
  isLoadingInviteTeamMembers: boolean;
  isCreatingOrUpdatingTeam: boolean;
  isLoadingUsagePlan: boolean;
  isLoadingFinishTeamOnboarding: boolean;
  isLoadingStartAppsumoOnboarding: boolean;
  isLoadingSkipExtension: boolean;
  subdomain: string;
};
const initialState: State = {
  isLoadingCreateSubscriptionTrial: false,
  isLoadingSkipTeamOnboardingStep: false,
  isLoadingInviteTeamMembers: false,
  isCreatingOrUpdatingTeam: false,
  isLoadingUsagePlan: false,
  isLoadingFinishTeamOnboarding: false,
  isLoadingStartAppsumoOnboarding: false,
  isLoadingSkipExtension: false,
  subdomain: '',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setIsLoadingSkipTeamOnboardingStep: (
      state,
      { payload: isLoadingSkipTeamOnboardingStep }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingSkipTeamOnboardingStep,
    }),
    setIsLoadingInviteTeamMembers: (
      state,
      { payload: isLoadingInviteTeamMembers }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingInviteTeamMembers,
    }),
    setIsCreatingOrUpdatingTeam: (
      state,
      { payload: isCreatingOrUpdatingTeam }: PayloadAction<boolean>
    ) => ({
      ...state,
      isCreatingOrUpdatingTeam,
    }),
    setIsLoadingUsagePlan: (
      state,
      { payload: isLoadingUsagePlan }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingUsagePlan,
    }),
    setIsLoadingSkipExtension: (
      state,
      { payload: isLoadingSkipExtension }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingSkipExtension,
    }),
    setIsLoadingCreateSubscriptionTrial: (
      state,
      { payload: isLoadingCreateSubscriptionTrial }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingCreateSubscriptionTrial,
    }),
    setIsLoadingFinishTeamOnboarding: (
      state,
      { payload: isLoadingFinishTeamOnboarding }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingFinishTeamOnboarding,
    }),
    setIsLoadingStartAppsumoOnboarding: (
      state,
      { payload: isLoadingStartAppsumoOnboarding }: PayloadAction<boolean>
    ) => ({ ...state, isLoadingStartAppsumoOnboarding }),
    setSubdomain: (state, { payload: subdomain }: PayloadAction<string>) => ({
      ...state,
      subdomain,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  skipTeamOnboardingStep: createAction<SkipTeamOnboardingStepAction>(
    'onboarding/skipTeamOnboardingStep'
  ),
  inviteTeamMembers: createAction<InviteTeamMembersAction>(
    'onboarding/inviteTeamMembers'
  ),
  createTeam: createAction<CreateTeamAction>('onboarding/createTeam'),
  updateUsagePlan: createAction<UpdateUsagePlanAction>(
    'onboarding/updateUsagePlan'
  ),
  createSubscriptionTrial: createAction<CreateSubscriptionTrialAction>(
    'onboarding/createSubscriptionTrial'
  ),
  finishExtensionOnboarding: createAction(
    'onboarding/finishExtensionOnboarding'
  ),
  finishTeamOnboarding: createAction<void>('onboarding/finishTeamOnboarding'),
  startAppsumoUserOnboarding: createAction(
    'onboarding/startAppsumoUserOnboarding'
  ),
};
