import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../../shared/sprite';
import { Button, Icon } from '../../../../shared/ui';
import { FC } from 'react';
import { extensionModel } from '../../../extension';

export const RecordsNotFound: FC = () => {
  const dispatch = useDispatch();

  const isExtensionEventLoading = useSelector(
    extensionModel.selectors.selectIsExtensionEventLoading
  );

  return (
    <div className="grid place-items-center flex-grow-1">
      <div className="max-w-80 flex flex-col items-center text-center">
        <h5 className="text-xl font-medium text-gray-900 mb-2">
          No recordings found
        </h5>
        <p className="text-md text-gray-600 mb-8">
          We cannot find the recording you need, but you can always create your
          own.
        </p>
        <Button
          variant="text"
          color="primary"
          size="lg"
          loading={isExtensionEventLoading}
          disabled={isExtensionEventLoading}
          onClick={() => dispatch(extensionModel.actions.openClick())}
        >
          <Icon glyph={IconMap.VideoRecorder} width={20} className="mr-2" />
          New Recording
        </Button>
      </div>
    </div>
  );
};
