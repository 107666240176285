import { put } from 'redux-saga/effects';

import { FirebaseUser } from '../../../shared/types';

import { loggedIn, loggedOut, chan } from '.';

export function* handleUserAuthState(user: FirebaseUser | null) {
  if (user) {
    yield put(chan, loggedIn());
  } else {
    yield put(chan, loggedOut());
  }
}
