import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../../shared/sprite';
import { Icon, Input } from '../../../../shared/ui';
import React, { useEffect, useRef } from 'react';
import { snippetsModel } from '../../../../features/snippets';
import { SnippetsConfigBasicEnum } from './lib/config';

export const SnippetsSearch: React.FC = () => {
  const dispatch = useDispatch();

  const searchQuery = useSelector(
    snippetsModel.selectors.selectModalSearchQuery
  );
  const currentFolder = useSelector(
    snippetsModel.selectors.selectCurrentModalFolder
  );

  const handleClear = () => {
    dispatch(snippetsModel.actions.setModalSearchQuery(''));
  };

  const handleSearchQueryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(snippetsModel.actions.setModalSearchQuery(value));
    if (currentFolder !== SnippetsConfigBasicEnum.ALL) {
      dispatch(
        snippetsModel.actions.setCurrentModalFolder(SnippetsConfigBasicEnum.ALL)
      );
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.blur();
  }, []);

  return (
    <div className="relative">
      <Icon
        glyph={IconMap.SearchLg}
        width={20}
        className="absolute left-3.5 top-3 z-10 text-gray-500"
      />
      <Input
        type="text"
        placeholder="Search snippets"
        className="!pl-10.5 !pr-8.5 !py-2.5 !text-md !text-gray-900 font-medium placeholder:font-normal shadow-xs"
        value={searchQuery}
        onChange={handleSearchQueryChange}
        id="search"
      />
      {searchQuery && (
        <Icon
          glyph={IconMap.XCircle}
          width={20}
          className="absolute right-3.5 top-3 z-10 text-gray-700 cursor-pointer"
          onClick={handleClear}
        />
      )}
    </div>
  );
};
