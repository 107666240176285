import { RichTextEditorDropdownWithTooltip } from './rich-text-editor-dropdown';
import { Editor } from '@tiptap/react';
import { useMemo } from 'react';

const blockDropdownItems = (editor: Editor | null) =>
  editor && [
    {
      id: 'paragraph',
      value: 'Paragraph',
      onClick: () => editor.chain().setParagraph().focus().run(),
    },
    {
      id: 'heading1',
      value: 'Heading 1',
      onClick: () => editor.chain().setHeading({ level: 2 }).focus().run(),
    },
    {
      id: 'heading2',
      value: 'Heading 2',
      onClick: () => editor.chain().setHeading({ level: 3 }).focus().run(),
    },
    {
      id: 'heading3',
      value: 'Heading 3',
      onClick: () => editor.chain().setHeading({ level: 4 }).focus().run(),
    },
  ];

export interface TiptapBlockDropdownProps {
  editor: Editor;
  currentItemId: 'paragraph' | 'heading1' | 'heading2' | 'heading3';
}

export const TiptapTextFormatDropdown = ({
  editor,
  currentItemId,
}: TiptapBlockDropdownProps) => {
  const items = useMemo(() => blockDropdownItems(editor), [editor]);

  if (!items) {
    return null;
  }

  return (
    <RichTextEditorDropdownWithTooltip
      items={items}
      selectedItemId={currentItemId}
      itemStyles="px-2.5 h-9.5"
      dropdownStyles="left-2/4 -translate-x-2/4"
      tooltip={<p className="text-sm font-medium">Turn into</p>}
    />
  );
};
