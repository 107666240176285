import { APP_HOST, GONG_CLIENT_ID, PROTOCOL } from '../../../../shared/config';
import { gongApi } from '../../../../shared/api/axios';
import { call, put } from 'redux-saga/effects';

import { GetGongAuthInfoType } from '../types';
import { setGongAuthData } from './helpers';
import { gongModel } from '..';

export function* fetchGongAuthTokenBasedOnCode(
  url: string,
  initialStateString: string
) {
  const urlParams = new URLSearchParams(url);
  const code: string = yield urlParams.get('code');
  const returnedStateString: string = yield urlParams.get('state');

  if (initialStateString !== returnedStateString) {
    throw new Error('Failed to connect to Gong');
  }

  const gongCallbackUrl = `${PROTOCOL}${APP_HOST}/gong-callback`;

  if (code) {
    const res: GetGongAuthInfoType = yield call(gongApi.getAccessToken, {
      code,
      redirectUri: encodeURIComponent(gongCallbackUrl),
      clientId: GONG_CLIENT_ID,
    });

    if (res.access_token) {
      yield call(setGongAuthData, res);
      yield put(gongModel.actions.setIsConnected(true));
    } else {
      throw new Error('Failed to connect to Gong');
    }
  }
}
