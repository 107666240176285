import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import {
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_SUPERUSER_ROUTE,
} from '../../../entities/history';
import classNames from 'classnames';
import { matchPath, useLocation } from 'react-router-dom';
import { redirectActions } from '../../../entities/history';
import { navigate } from '../../../processes/navigation';
import { authUserModel } from '../../../entities/auth-user';
import { SpriteGlyph } from '../../../shared/ui/Icon';
import { useTeamPermissions } from '../../../features/teams';

type NavigationInfoType = {
  id: string;
  name: string;
  icon: SpriteGlyph;
  route: string;
  isHidden?: boolean;
};

export const SettingsNavigation: FC = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const { isGuest } = useTeamPermissions();

  const navigationInfo: NavigationInfoType[] = useMemo(
    () =>
      [
        {
          id: 'profile',
          name: 'Profile',
          icon: IconMap.User,
          route: SETTINGS_PROFILE_ROUTE,
        },
        {
          id: 'integrations',
          name: 'Integrations',
          icon: IconMap.PuzzlePiece01,
          route: SETTINGS_INTEGRATIONS_ROUTE,
          isHidden: isGuest,
        },
        {
          id: 'superuser',
          name: 'Superuser Panel',
          icon: IconMap.Users,
          route: SETTINGS_SUPERUSER_ROUTE,
          isHidden: !currentUser.isAdmin,
        },
      ].filter((item) => !item.isHidden),
    [currentUser.isAdmin, isGuest]
  );

  const currentRouteId =
    navigationInfo.find((navItem) =>
      matchPath(location.pathname, { path: navItem.route })
    )?.id || navigationInfo[0].id;

  return (
    <div className="px-4 py-5">
      <Button
        color="link-gray"
        variant="icon-text"
        size="sm"
        onClick={() => {
          dispatch(redirectActions.toWorkspaceByBackUrl());
        }}
        className="!px-3 !font-semibold md:hidden"
      >
        <Icon glyph={IconMap.BackArrow} className="mr-2" />
        Back
      </Button>

      <div className="px-3 my-4 text-lg font-bold text-gray-900 md:mt-18">
        Settings
      </div>
      {navigationInfo.map((navItem) => (
        <button
          key={navItem.id}
          className={classNames(
            'flex justify-start items-center w-full rounded-md px-3 py-2 group cursor-pointer group text-left whitespace-nowrap transition-colors focus:outline-none flex-row hover:cursor-pointer text-gray-700 gap-3 text-sm',
            {
              'hover:bg-light-3 focus:bg-light-5':
                currentRouteId !== navItem.id,
              'bg-gray-200 hover:bg-gray-200': currentRouteId === navItem.id,
            }
          )}
          onClick={() => {
            dispatch(
              navigate({
                to: navItem.route,
                isKeepQueryParams: true,
              })
            );
          }}
        >
          <Icon
            glyph={navItem.icon}
            className="text-gray-500 group-hover:text-gray-600"
          />
          {navItem.name}
        </button>
      ))}
    </div>
  );
};
