import {
  TeamPermissions,
  User,
  UserToTeamFiltered,
} from '@distribute/shared/types';
import { getUserRoles } from './getUserRoles';

export type SnippetsPermissions = {
  isCanManageCategories: boolean;
  isCanManageSnippets: boolean;
  isCanCreatePersonalSnippets: boolean;
  isAllowToDetachSnippet: boolean;
};

export const getSnippetsPermissions = (
  currentUser: User,
  currentTeamMembers: UserToTeamFiltered[],
  teamPermissions: TeamPermissions
): SnippetsPermissions => {
  const currentUserToTeam = currentTeamMembers.find(
    (item) => item.user?.id === currentUser.id
  );
  const { isOwner, isAdmin, isMember } = getUserRoles(currentUserToTeam?.role);

  return {
    isCanManageCategories:
      isOwner ||
      (isAdmin && teamPermissions.isAdminCanManageSnippetsCategories),
    isCanManageSnippets:
      isOwner ||
      (isAdmin && teamPermissions.isAdminCanManageSnippets) ||
      (isMember && teamPermissions.isMemberCanManageSnippets),
    isCanCreatePersonalSnippets: teamPermissions.isAllowCreatePersonalSnippet,
    isAllowToDetachSnippet: !teamPermissions.isDetachSnippetDisabled,
  };
};
