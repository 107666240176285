export const getFileSizeString = (fileSizeInBytes: number) => {
  const isKb = fileSizeInBytes / 1024 < 1024;
  const isMb = fileSizeInBytes / 1024 / 1024 < 1024;
  const isGb = fileSizeInBytes / 1024 / 1024 / 1024 < 1024;

  if (isKb) return `${(fileSizeInBytes / 1024).toFixed(2)} KB`;

  if (isMb) return `${(fileSizeInBytes / 1024 / 1024).toFixed(2)} MB`;

  if (isGb) return `${(fileSizeInBytes / 1024 / 1024 / 1024).toFixed(2)} GB`;

  return `${fileSizeInBytes} B`;
};
