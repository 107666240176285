import { call, put } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { messages } from '../../config';
import { recordShortCut } from '../../lib';

export function* downloadRecord(
  action: ReturnType<typeof actions.downloadRecord>
) {
  try {
    const { payload } = action;

    yield call(
      window.open,
      recordShortCut.createPathToMp4URL(payload),
      '_blank'
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', messages.record.downloadError)
      )
    );
  }
}
