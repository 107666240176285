import moment from 'moment';
import {
  PlanName,
  SubscriptionPaymentMethod,
  SubscriptionPlanName,
} from '@distribute/shared/types';
import { formatAmountToCurrency } from '../../../shared/lib';
import { MAX_MEMBERS_TEAM_PLAN, subscriptionPlans } from '../config';

type GetPromoCodeDiscountAmountInput = {
  amountOff: number | null;
  percentOff: number | null;
};
export const getPromoCodeDiscountAmount = (
  promoCode: GetPromoCodeDiscountAmountInput
) =>
  promoCode.amountOff !== null
    ? formatAmountToCurrency(promoCode.amountOff / 100)
    : `${promoCode?.percentOff}%`;

export const checkIsPlanGtCurrentPlan = (
  planId: SubscriptionPlanName,
  currentPlanId: SubscriptionPlanName
) =>
  subscriptionPlans.findIndex((i) => i.id === planId) >
  subscriptionPlans.findIndex((i) => i.id === currentPlanId);

export const checkIsPlanWithLimitedMembersCount = (
  plan: SubscriptionPlanName
) => {
  return plan === PlanName.TEAM;
};

export const checkIsMaxMembersCountForPlanReached = (
  plan: SubscriptionPlanName,
  teamMembersCount: number
) => {
  if (
    checkIsPlanWithLimitedMembersCount(plan) &&
    teamMembersCount >= MAX_MEMBERS_TEAM_PLAN
  ) {
    return true;
  }

  return false;
};

export const checkIsMaxMembersCountForPlanExceed = (
  plan: SubscriptionPlanName,
  teamMembersCount: number
) => {
  if (
    checkIsPlanWithLimitedMembersCount(plan) &&
    teamMembersCount > MAX_MEMBERS_TEAM_PLAN
  ) {
    return true;
  }

  return false;
};

export const checkIsCardExpireSoon = (
  subscriptionPaymentMethod: SubscriptionPaymentMethod | null
) => {
  return moment().isSame(
    moment(
      `${subscriptionPaymentMethod?.cardExpYear} ${subscriptionPaymentMethod?.cardExpMonth}`,
      'YYYY MM'
    ),
    'month'
  );
};
