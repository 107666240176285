import React, { FC, useState, useEffect } from 'react';

import { Button, Icon, Link, Modal, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../../teams';
import { API_URL } from '../../../../shared/config';
import { authUserModel } from '../../../../entities/auth-user';
import { gongModel } from '../../../../entities/gong';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

const GONG_WEBHOOK_SUPPORT_URL =
  'https://help.gong.io/hc/en-us/articles/360041630212-Create-a-webhook-rule';

export const GongDetailsModal: FC<IProps> = ({ isOpen, onClose }) => {
  const [isCopied1, setCopied1] = useState(false);
  const [isCopied2, setCopied2] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const currentTeamMember = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const isConnectedToGong = currentTeam.isGongConnected;

  const webhookUrl = `${API_URL}/gong/webhook/${currentUser.id}/${currentTeamMember.gongApiKey}`;

  useEffect(() => {
    if (isConnectedToGong && currentTeamMember.gongApiKey === null) {
      dispatch(gongModel.actions.generateGongApiKey());
    }
  }, [currentTeamMember.gongApiKey, isConnectedToGong, dispatch]);

  const handleCopy1 = () => {
    navigator.clipboard.writeText(webhookUrl);
    setCopied1(true);
    setTimeout(() => {
      setCopied1(false);
    }, 3000);
  };

  const handleCopy2 = () => {
    navigator.clipboard.writeText(webhookUrl);
    setCopied2(true);
    setTimeout(() => {
      setCopied2(false);
    }, 3000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="!w-160 sm:!w-full relative overflow-hidden max-h-[calc(100vh-5%)] !p-0 !pb-22.5"
      isShowCancelButton={false}
    >
      <div className="flex flex-col gap-5 h-full overflow-y-scroll p-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-start justify-between w-full">
            <Icon glyph={IconMap.GongLg} width={48} />
            <Button
              color="transparent"
              variant="icon"
              onClick={onClose}
              size="sm"
            >
              <Icon
                glyph={IconMap.XClose}
                width={24}
                className="text-gray-400"
              />
            </Button>
          </div>

          <div className="text-display-xs font-display text-gray-900 font-medium">
            Power Up Gong Integration
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Set up an automation rule in Gong to simplify the process of creating
          a follow-up page in Distribute after each call.
        </div>

        <div>
          <p className="text-sm text-gray-600 mb-3">
            Simply follow these steps:
          </p>
          <ol className="text-gray-600 text-sm list-decimal ml-4.5 flex flex-col gap-2">
            <li>
              Click{' '}
              <span className="font-semibold">
                Company settings {'>'} Ecosystem {'>'} Automation Rules.
              </span>
            </li>
            <li>
              Click <span className="font-semibold">+ Add Rule</span> to display
              the create new rule page.
            </li>
            <li>
              Select the <span className="font-semibold">Fire webhook</span>{' '}
              action.
            </li>
            <li>
              Enter the{' '}
              <span className="font-semibold relative">
                destination URL
                <Tooltip
                  isOpen={isCopied1}
                  triggerClassNames="absolute -right-7 -top-1"
                  hideArrow
                  sideOffset={6}
                  trigger={
                    <Button
                      variant="icon"
                      size="xs"
                      color="transparent"
                      onClick={handleCopy1}
                    >
                      <Icon glyph={IconMap.Copy01} width={20} />
                    </Button>
                  }
                >
                  <p className="p-1 font-semibold text-xs">Copied!</p>
                </Tooltip>
              </span>
            </li>
            <li>
              Select the authentication method{' '}
              <span className="font-semibold">URL includes key.</span>
            </li>
            <li>Provide a rule name and description and then test the rule.</li>
          </ol>
        </div>
      </div>
      <footer className="border-t border-gray-200 pt-5 px-6 pb-6 flex items-center justify-between absolute bottom-0 left-0 right-0 w-full bg-base-white">
        <Link
          target="_blank"
          rel="noreferrer"
          href={GONG_WEBHOOK_SUPPORT_URL}
          size="large"
          className="inline-flex gap-x-0.5 items-center"
        >
          Check Gong help center article
          <Icon
            glyph={IconMap.ArrowSquareUpRight}
            width={16}
            className="mt-px"
          />
        </Link>
        <Tooltip
          isOpen={isCopied2}
          hideArrow
          sideOffset={6}
          trigger={
            <Button
              variant="icon-text"
              color="secondary"
              iconLeftName={IconMap.Link}
              iconLeftWidth={20}
              onClick={handleCopy2}
            >
              Copy destination URL
            </Button>
          }
        >
          <p className="p-1 font-semibold text-xs">Copied!</p>
        </Tooltip>
      </footer>
    </Modal>
  );
};
