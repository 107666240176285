import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import { SetConversionToolsAction, WorkspaceAlertBarData } from '../types';

type State = {
  isShowContactSupportModal: boolean;
  alertBarData?: WorkspaceAlertBarData;
};

const initialState: State = {
  isShowContactSupportModal: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setIsShowContactSupportModal: (
      state,
      { payload: isShowContactSupportModal }: PayloadAction<boolean>
    ) => ({
      ...state,
      isShowContactSupportModal,
    }),
    setAlertBarData: (
      state,
      {
        payload: alertBarData,
      }: PayloadAction<WorkspaceAlertBarData | undefined>
    ) => ({
      ...state,
      alertBarData,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  closeWalkthroughModal: createAction<void>('workspace/closeWalkthroughModal'),
  closeMobileWalkthroughModal: createAction<void>(
    'workspace/closeMobileWalkthroughModal'
  ),
  setConversionToolsData: createAction<SetConversionToolsAction>(
    'workspace/setConversionToolsData'
  ),
};
