import { call, put } from 'redux-saga/effects';

import { getErrorMessage, logger } from '../../../../shared/lib';
import { Saga } from '../../../../shared/types';

import { actions } from '../reducer';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { isAxiosError } from 'axios';

export function* authOperation(unknownError: string, saga: Saga) {
  try {
    yield put(actions.setAuthIsLoading(true));
    yield put(actions.setAuthError(''));
    yield call(saga);
  } catch (e: unknown) {
    const error = isAxiosError(e) ? e.response?.data : e;

    yield put(actions.setAuthError(getErrorMessage(error, unknownError)));
  } finally {
    yield put(actions.setAuthIsLoading(false));
  }
}

export function* authOperationWithAlert(defaultMessage: string, saga: Saga) {
  try {
    yield call(saga);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', defaultMessage)
      )
    );
  }
}
