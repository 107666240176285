import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Notification } from '../../shared/ui';

import { actions } from './model/reducer';
import { selectNotifications } from './model/selectors';

import cn from 'classnames';

export const SnackBar = () => {
  const messages = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const timers = useRef<Record<string, NodeJS.Timeout>>({});

  const isTopBar = document.getElementById('top-bar');

  useEffect(() => {
    Object.keys(timers.current).forEach((id) => {
      clearTimeout(timers.current[id]);
    });
  }, []);

  const onDeleteMessage = (id: number) => {
    dispatch(actions.removeNotificationAction(id));
  };
  return (
    <TransitionGroup
      style={{ zIndex: 9999 }}
      className={cn('fixed sm:w-full right-6 top-6 sm:left-0', {
        'sm:top-16': isTopBar,
        'sm:top-0': !isTopBar,
      })}
    >
      {messages.map(({ id, type, message, isResetTimer }) => {
        const ref = React.createRef<HTMLDivElement>();
        const setTimer = () => {
          timers.current[id] = setTimeout(() => {
            onDeleteMessage(id);
          }, 5000);
        };

        if (isResetTimer) {
          clearTimeout(timers.current[id]);
          setTimer();
          dispatch(
            actions.upsertNotificationAction({
              id,
              type,
              message,
              isResetTimer: false,
            })
          );
        }

        return (
          <CSSTransition
            key={id}
            timeout={300}
            classNames="show"
            nodeRef={ref}
            appear={true}
            onEnter={setTimer}
          >
            <div ref={ref}>
              <Notification onClick={() => onDeleteMessage(id)} type={type}>
                {message}
              </Notification>
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
