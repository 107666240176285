import { call, put, select } from 'redux-saga/effects';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { authApi } from '../../../../shared/api';
import { redirect } from '../../../../entities/history';
import { DBUser, User } from '../../../../shared/types';
import { authUserModel, getUserProfile } from '../../../../entities/auth-user';

export function* handleWhenUserHasNoTeams(isRedirectToOnboarding = true) {
  const fbUser: User = yield call(getUserProfile);
  const currentUser: DBUser = yield select(
    authUserModel.selectors.selectUserWithError
  );

  if (
    fbUser.isVerified &&
    currentUser.onboardingStatus ===
      UserOnboardingStatus.SUCCESSFULLY_CREATED_ACCOUNT
  ) {
    return;
  } else if (
    fbUser.isVerified &&
    currentUser.onboardingStatus !== UserOnboardingStatus.USAGE_PLAN
  ) {
    const user: DBUser = yield authApi.updateProfile({
      onboardingStatus: UserOnboardingStatus.CREATE_TEAM,
    });

    yield put(authUserModel.actions.setDBUser(user));

    if (isRedirectToOnboarding) {
      yield call(redirect.toOnboarding);
    }
  }
}
