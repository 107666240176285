import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Node } from '@tiptap/pm/model';
import {
  COLUMNS_GAP_WIDTH,
  COLUMN_WIDTH_DATA_ATTRIBUTE,
  generateWidthCalcFunction,
} from './config';
import { FragmentWithContent } from './utils';

export const NodeView = (props: NodeViewProps) => {
  const { editor, node, getPos } = props;
  const columnWidth = node.attrs[COLUMN_WIDTH_DATA_ATTRIBUTE];

  const [hasResizing, setHasResizing] = useState<boolean>(false);
  const [resizeLineHeight, setResizeLineHeight] = useState(0);
  const [itemIdx, setItemIdx] = useState(0);
  const [columnsNodes, setColumnsNodes] = useState<Node[]>([]);

  const [isResizing, setIsResizing] = useState(false);
  const ContentRef = useRef<HTMLDivElement>(null);

  const gapWidth = (columnsNodes.length - 1) * COLUMNS_GAP_WIDTH;
  const width = generateWidthCalcFunction(gapWidth, columnWidth);

  useEffect(() => {
    const updateResizePosition = () => {
      try {
        const pos = getPos();
        const columnBlock = editor.state.doc.resolve(pos).parent;
        const columns: Node[] = [];
        columnBlock.forEach((currentNode, __, idx) => {
          columns.push(currentNode);
          if (node !== currentNode) {
            return;
          }

          setItemIdx(idx);

          setHasResizing(
            idx !==
              (columnBlock.content as FragmentWithContent).content.length - 1
          );
        });
        setColumnsNodes(columns);
      } catch {
        // nothing to do
      }
    };

    updateResizePosition();
    editor.on('update', updateResizePosition);
    return () => {
      editor.off('update', updateResizePosition);
    };
  }, [getPos, editor, node]);

  useEffect(() => {
    const updateResizeLineHeight = () => {
      const pos = getPos();
      const domNode = editor.view.domAtPos(pos);
      if (domNode.node.nodeType !== 1) {
        return;
      }

      setResizeLineHeight(
        (domNode.node as Element).getBoundingClientRect().height
      );
    };

    updateResizeLineHeight();
    editor.on('update', updateResizeLineHeight);
    return () => {
      editor.off('update', updateResizeLineHeight);
    };
  }, [editor, getPos]);

  const handleHorizontalResize = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      setIsResizing(true);
      const currentDomNode = ContentRef.current?.parentElement;
      const startX = e.pageX;
      const currentColumnStartWidth =
        currentDomNode?.getBoundingClientRect().width || 0;

      const currentColumn = columnsNodes[itemIdx];

      const pos = getPos();

      const rightColumnPos = pos + currentColumn.nodeSize;

      const { node: rightResolvedDomNode } = editor.view.domAtPos(
        rightColumnPos + 1
      );

      const rightDomNode = (rightResolvedDomNode as Element).closest(
        '.node-column'
      );

      const rightColumnStartWidth =
        rightDomNode?.getBoundingClientRect().width || 0;

      const tr = editor.state.tr;

      const handleMouseMove = (e: MouseEvent) => {
        const fullWidth = editor.view.dom.getBoundingClientRect().width;
        const widthDelta = startX - e.pageX;
        const width = currentColumnStartWidth - widthDelta;
        const currentColumnWidth = (width * 100) / (fullWidth - gapWidth);

        const rightNodeWidth = rightColumnStartWidth + widthDelta;
        const rightColumnWidth =
          (rightNodeWidth * 100) / (fullWidth - gapWidth);

        (currentDomNode as HTMLElement).style.width = generateWidthCalcFunction(
          gapWidth,
          currentColumnWidth
        );
        (rightDomNode as HTMLElement).style.width = generateWidthCalcFunction(
          gapWidth,
          rightColumnWidth
        );

        tr.setNodeAttribute(
          rightColumnPos,
          COLUMN_WIDTH_DATA_ATTRIBUTE,
          rightColumnWidth
        );
        tr.setNodeAttribute(
          pos,
          COLUMN_WIDTH_DATA_ATTRIBUTE,
          currentColumnWidth
        );
      };

      const handleMouseUp = () => {
        editor.view.dispatch(tr);
        setIsResizing(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [columnsNodes, itemIdx, getPos, editor.view, editor.state.tr, gapWidth]
  );

  useEffect(() => {
    if (ContentRef.current && ContentRef.current.parentElement) {
      ContentRef.current.parentElement.style.width = width;
    }
  }, [width]);
  return (
    <NodeViewWrapper
      ref={ContentRef}
      className={classNames('node--column !border-0 w-full')}
    >
      <div className={classNames('relative z-10 w-full')}>
        <div className="w-full">
          <NodeViewContent />
        </div>
        {hasResizing && (
          <div
            draggable="false"
            style={{ height: resizeLineHeight }}
            onMouseDown={handleHorizontalResize}
            className={classNames(
              'absolute z-10 w-6 transition-all -right-6 -top-2 hover:opacity-100 cursor-col-resize flex items-start justify-center',
              {
                'opacity-100': isResizing,
                'opacity-0': !isResizing,
              }
            )}
          >
            <span className="w-0.5 h-full transition-all -left-1 rounded-md shadow-drag bg-primary-500 cursor-col-resize"></span>
          </div>
        )}
      </div>
    </NodeViewWrapper>
  );
};
