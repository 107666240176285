import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from './Icon';
import { IconMap } from '../sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export type DropdownItem = {
  id: string;
  onClick?: () => void;
  label: ReactNode;
  headerLabel?: ReactNode;
  iconName?: SpriteGlyph;
  iconWidth?: number;
  isSeparatedFromTop?: boolean;
  extraBlock?: ReactNode;
  disabled?: boolean;
  style?: string;
  nameLabel?: ReactNode;
};

type Props = {
  triggerComponent?: ReactNode;
  triggerStyles?: string;
  items: DropdownItem[];
  itemStyles?: string;
  listStyles?: string;
  align?: 'end' | 'start' | 'center';
  isSeparatedItems?: boolean;
  currentItemId?: string | string[];
  isDisabled?: boolean;
  isModalMode?: boolean;
  onItemChange?: (itemId: string) => void;
  isDoubleArrow?: boolean;
  arrowStyle?: string;
  customTriggerText?: string;
  isHideItemCheck?: boolean;
  checkmarkStyles?: string;
};

export const Dropdown: FC<Props> = ({
  triggerComponent,
  items,
  itemStyles,
  triggerStyles,
  listStyles,
  align = 'end',
  isSeparatedItems = false,
  currentItemId,
  isDisabled,
  isModalMode = true,
  onItemChange,
  isDoubleArrow = false,
  arrowStyle = 'text-gray-500',
  customTriggerText,
  isHideItemCheck = false,
  checkmarkStyles,
}) => {
  const isEqToCurrentItemId = useCallback(
    (id: string) => {
      if (Array.isArray(currentItemId)) {
        return currentItemId.includes(id);
      }

      return id === currentItemId;
    },
    [currentItemId]
  );

  const currentItem = useMemo(() => {
    return items.find(({ id }) => isEqToCurrentItemId(id));
  }, [items, isEqToCurrentItemId]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={(newState) => setIsOpen(newState)}
      modal={isModalMode}
    >
      {triggerComponent ?? (
        <DropdownMenu.Trigger
          disabled={isDisabled}
          className={cn(
            `border rounded-lg w-full h-10 border-gray-300 focus:ring-0 focus:outline-none
      py-2.5 px-4 flex flex-row justify-between items-center cursor-pointer text-md text-gray-900 font-medium`,
            triggerStyles,
            {
              '!bg-gray-50 !text-gray-500': isDisabled,
            }
          )}
        >
          <div className="min-w-0">
            {customTriggerText ??
              currentItem?.headerLabel ??
              currentItem?.label}
          </div>
          <div
            className={cn(
              { arrowStyle },
              {
                'p-2': isDoubleArrow,
              }
            )}
          >
            <Icon
              glyph={
                isDoubleArrow
                  ? IconMap.ChevronSelectorVerticalBold
                  : IconMap.ArrowDown
              }
              className={cn('transition-all', {
                'rotate-180': isOpen && !isDoubleArrow,
              })}
            />
          </div>
        </DropdownMenu.Trigger>
      )}
      <DropdownMenu.Content
        align={align}
        sideOffset={4}
        className={cn(
          `border rounded-lg bg-base-white divide-solid border-gray-200 focus:outline-none overflow-hidden py-1.5 z-10`,
          listStyles
        )}
      >
        <>
          {items.map((item) => (
            <React.Fragment key={item.id}>
              {item.nameLabel && (
                <>
                  {item.isSeparatedFromTop && (
                    <div className="h-0.25 w-full bg-gray-200 my-4" />
                  )}
                  <DropdownMenu.Label>
                    <div
                      className="text-left whitespace-nowrap w-full px-1.5 rounded-md
                    flex flex-row items-center text-gray-700"
                    >
                      {item.nameLabel}
                    </div>
                  </DropdownMenu.Label>
                </>
              )}
              <DropdownMenu.Item
                key={`${item.id} + ${item.label}`}
                onSelect={() => {
                  item.onClick?.();
                  onItemChange && onItemChange(item.id);
                }}
                className={cn('outline-none', item.style)}
                disabled={item.disabled}
              >
                {item.isSeparatedFromTop && !item.nameLabel && (
                  <div className="h-0.25 w-full bg-gray-200 my-1" />
                )}
                <div className="px-1.5">
                  <button
                    type="button"
                    disabled={item.disabled}
                    className={cn(
                      !!item.label && 'min-w-34',
                      `text-left whitespace-nowrap w-full p-2.5 transition-colors focus:outline-none rounded-md
                    flex flex-row items-center hover:cursor-pointer text-gray-700 group disabled:cursor-not-allowed`,
                      itemStyles,
                      {
                        'hover:bg-gray-50 active:bg-gray-100': !item.disabled,
                      }
                    )}
                  >
                    <div className="flex flex-col min-w-0 flex-grow-1">
                      {item.extraBlock}
                      <div className="flex flex-row items-center">
                        {item.iconName && (
                          <Icon
                            glyph={item.iconName}
                            className={cn('mr-2', {
                              'text-gray-700': !item.disabled,
                              'text-gray-300': item.disabled,
                            })}
                            width={item.iconWidth}
                          />
                        )}
                        {isSeparatedItems && (
                          <span className="w-0.25 h-3 bg-gray-200 mr-2"></span>
                        )}
                        <div
                          className={cn('font-normal text-sm min-w-0', {
                            'text-gray-300': item.disabled,
                          })}
                        >
                          {item.label}
                        </div>
                        {isEqToCurrentItemId(item.id) && !isHideItemCheck && (
                          <span className={cn('ml-auto', checkmarkStyles)}>
                            <Icon glyph={IconMap.GreenCheckMark} />
                          </span>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
              </DropdownMenu.Item>
            </React.Fragment>
          ))}
        </>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
