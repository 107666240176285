import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../../teams';
import { onboardingModel } from '../../model';
import { TeamOnboardingStatus } from '@distribute/shared/types';
import { InviteMembersForm } from '../../../team-members';
import { SkipOnboardingStepSource } from '../../model/types';
import { OnboardingWrapper } from './OnboardingWrapper';

export const StepInviteTeamMembers: FC = () => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isLoadingInviteTeamMembers = useSelector(
    onboardingModel.selectors.selectIsLoadingInviteTeamMembers
  );

  const handleSkip = () => {
    dispatch(
      onboardingModel.actions.skipTeamOnboardingStep({
        teamOnboardingStatus: TeamOnboardingStatus.SUBSCRIPTION,
        source: SkipOnboardingStepSource.INVITE_TEAM_MEMBERS,
      })
    );
  };

  const handleFormSubmit = (emails: string[]) => {
    dispatch(
      onboardingModel.actions.inviteTeamMembers({
        emails,
        teamId: currentTeam.id,
      })
    );
  };

  return (
    <OnboardingWrapper
      step={TeamOnboardingStatus.INVITE_TEAMMATES}
      imageBlockBgSrc="../../../../assets/images/onboarding/bg-img-step-3.jpg"
      imageBlockSrc="../../../../assets/images/onboarding/img-step-3.png"
      imageBlockClassName="-translate-y-[9%]"
    >
      <div className="text-display-md text-gray-900 font-medium font-display text-center">
        Invite teammates <br /> to co-create together
      </div>
      <div className="flex flex-col gap-y-5 mt-6">
        <InviteMembersForm
          isLoading={isLoadingInviteTeamMembers}
          onInviteMembers={handleFormSubmit}
          className="!gap-y-5"
        />
        <div
          onClick={handleSkip}
          className="self-center text-sm text-primary-700 font-semibold cursor-pointer"
        >
          Skip For Now
        </div>
      </div>
    </OnboardingWrapper>
  );
};
