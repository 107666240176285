import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';

export function* updateCategory({
  payload: { callback, ...payloadData },
}: ReturnType<typeof snippetsModel.actions.updateCategory>) {
  try {
    yield put(snippetsModel.actions.setIsUpdateCategoryLoading(true));

    const { categoryId, ...rest } = payloadData;

    const updatedCategory: SnippetCategoryWithSnippets = yield call(
      snippetsApi.updateCategory,
      categoryId,
      { ...rest }
    );

    const snippetCategories: SnippetCategoryWithSnippets[] = yield select(
      snippetsModel.selectors.selectCategories
    );

    const filteredCategories = snippetCategories.filter(
      (el) => el.id !== updatedCategory.id
    );

    yield put(
      snippetsModel.actions.setCategories([
        updatedCategory,
        ...filteredCategories,
      ])
    );

    callback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update snippet category')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsUpdateCategoryLoading(false));
  }
}
