import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { IconMap } from '../../../../../shared/sprite';
import { Checkbox, ColorPicker, Icon, Input } from '../../../../../shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GmailBlockElement } from './GmailBlockElement';
import { pagesModel } from '../../../../../features/pages';
import { EditorSidebarConversionsPanelFooter } from '../conversion-panels/EditorSidebarConversionsPanelFooter';
import { redirectActions } from '../../../../../entities/history';
import {
  PAGE_COVER_DEFAULT_COLOR,
  PAGE_COVER_DEFAULT_URL,
} from '../../../../../shared/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { boolean, object, string } from 'yup';
import { DistributeTabOptions } from '../../../../../features/editor-sidebar/model/reducer';
import { teamsModel } from '../../../../../features/teams';

export const EditorSidebarDistributeEditCoverPanel: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setDistributeEditCoverPanelOpen(false));
  };

  const personName = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkPersonName
  );

  const color = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkColor
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const personalizedLink = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLink
  );

  const personalizedLinkTitle = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkTitle
  );

  const coverUrl = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkCover
  );

  const isPersonalizedCoverUpdating = useSelector(
    editorSidebarModel.selectors.selectIsPersonalizedCoverUpdating
  );

  const isNoLogo = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkNoLogo
  );

  type DistributeCoverSchema = {
    title: string;
    coverUrl: string;
    color: string;
    isNoLogo: boolean;
  };

  const distributeCoverSchema = object().shape({
    title: string().min(1).required(),
    coverUrl: string().required(),
    color: string().required(),
    isNoLogo: boolean().required(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<DistributeCoverSchema>({
    resolver: yupResolver(distributeCoverSchema),
    defaultValues: {
      title: personalizedLinkTitle ?? currentPage.content.title,
      coverUrl,
      color,
      isNoLogo,
    },
  });

  const {
    field: { onChange: setLocalTitle, value: localTitle },
  } = useController({
    control,
    name: 'title',
  });

  const {
    field: { onChange: setLocalCoverUrl, value: localCoverUrl },
  } = useController({
    control,
    name: 'coverUrl',
  });

  const {
    field: { onChange: setLocalColor, value: localColor },
  } = useController({
    control,
    name: 'color',
  });

  const {
    field: { onChange: setLocalNoLogo, value: localNoLogo },
  } = useController({
    control,
    name: 'isNoLogo',
  });

  const onUploaded = (url: string) => {
    setLocalCoverUrl(url);
  };

  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const handleSave = () => {
    dispatch(editorSidebarModel.actions.setPersonalizedLinkTitle(localTitle));
    dispatch(editorSidebarModel.actions.setPersonalizedLinkColor(localColor));
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkCover(localCoverUrl)
    );
    dispatch(editorSidebarModel.actions.setPersonalizedLinkNoLogo(localNoLogo));

    dispatch(
      editorSidebarModel.actions.updatePersonalizedCover({
        textColor: localColor,
        imageUrl: localCoverUrl,
        isNoDistributeLogo: localNoLogo,
        callback: handleClose,
      })
    );
  };

  const handleReset = () => {
    setLocalTitle(currentPage.content.title ?? '');
    setLocalColor(PAGE_COVER_DEFAULT_COLOR);
    setLocalCoverUrl(PAGE_COVER_DEFAULT_URL);
    setLocalNoLogo(false);

    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkTitle(
        currentPage.content.title ?? ''
      )
    );
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkColor(
        PAGE_COVER_DEFAULT_COLOR
      )
    );
    dispatch(
      editorSidebarModel.actions.setPersonalizedLinkCover(
        PAGE_COVER_DEFAULT_URL
      )
    );
    dispatch(editorSidebarModel.actions.setPersonalizedLinkNoLogo(false));

    dispatch(
      editorSidebarModel.actions.updatePersonalizedCover({
        textColor: PAGE_COVER_DEFAULT_COLOR,
        imageUrl: PAGE_COVER_DEFAULT_URL,
        isNoDistributeLogo: false,
      })
    );
  };

  const activeDistributeTab = useSelector(
    editorSidebarModel.selectors.selectSelectedDistributeTab
  );

  const navigateToSubscription = () => {
    dispatch(redirectActions.toSubscriptionsClick());
  };

  return (
    <form
      onSubmit={handleSubmit(handleSave)}
      className="h-full overflow-hidden"
    >
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Edit Cover
        </h2>
      </header>
      <div className="flex flex-col gap-6 py-6 px-4 h-[calc(100%-192px)] overflow-y-auto overflow-x-hidden">
        <p className="text-sm text-gray-700">
          Enhance the appeal of this block by modifying the image, title text,
          and text color.
        </p>
        <div>
          <GmailBlockElement
            personName={personName}
            link={personalizedLink}
            title={
              localTitle !== null ? localTitle : currentPage.content.title ?? ''
            }
            onUploaded={onUploaded}
            color={localColor}
            coverUrl={localCoverUrl}
            isNoLogo={localNoLogo}
            allowReset
            handleReset={handleReset}
            noLinkMode={activeDistributeTab === DistributeTabOptions.SOCIAL}
          />
          <p className="text-sm font-medium text-gray-500 mt-2">
            The recommended dimensions for the cover image are 900px x 450px and
            more.
          </p>
        </div>

        <Input
          isTextArea
          textAreaRows={3}
          type="text"
          maxCharacters={96}
          isCounterShow
          label="Title"
          isMandatory
          labelIcon={IconMap.Type01}
          value={localTitle !== null ? localTitle : currentPage.content.title}
          onChange={(e) => setLocalTitle(e.target.value)}
          isError={!!errors?.title?.message}
        />
        <div>
          <span className="text-gray-700 text-sm font-medium">Text Color</span>
          <ColorPicker value={localColor} onChange={setLocalColor} />
        </div>
        <div>
          <Checkbox
            disabled={!stripeSubscription?.name}
            checked={localNoLogo}
            onChange={(e) => {
              setLocalNoLogo(e.target.checked);
            }}
          >
            <p className="ml-2 text-sm font-medium text-gray-700">
              Remove Distribute logo
            </p>
          </Checkbox>
          {!stripeSubscription?.name && (
            <p className="text-gray-600 text-sm ml-6">
              Available only for Pro subscribers.{' '}
              <span
                className="font-medium text-primary-700 cursor-pointer"
                onClick={navigateToSubscription}
              >
                Become a Pro
              </span>
            </p>
          )}
        </div>
      </div>
      <EditorSidebarConversionsPanelFooter
        isAddBtnLoading={isPersonalizedCoverUpdating}
        addBtnTitle={'Save'}
        onClose={handleClose}
      ></EditorSidebarConversionsPanelFooter>
    </form>
  );
};
