import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import type { RT } from '../../../../shared/types';
import { VideoRecordLoadingStatus } from '../types';

import { formatError, messages } from '../../config';
import { AxiosError } from 'axios';

import { createNotification, snackbarModel } from '../../../snackbar';
import { videoRecordsApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { teamsModel } from '../../../teams';

export function* setRecordsData(setLoading = true) {
  try {
    const {
      id: teamId,
    }: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
      yield select(teamsModel.selectors.selectCurrentTeamWithError);

    if (setLoading) {
      yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Pending));
    }
    const records: RT<typeof videoRecordsApi.getAll> = yield call(
      videoRecordsApi.getAll,
      { teamId }
    );

    yield put(actions.setRecords(records));
    yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Success));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          formatError(
            messages.records.getError,
            (e as AxiosError)?.response?.status
          )
        )
      )
    );
    yield put(actions.setRecordsStatus(VideoRecordLoadingStatus.Error));
  }
}
