import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toggle } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';

export const CreatePersonalSnippetsPermissionForm: FC = () => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const handleChangeIsAllowCreatePersonalSnippet = (value: boolean) => {
    dispatch(
      teamsModel.actions.updateTeamPermissions({
        teamId: currentTeam.id,
        isAllowCreatePersonalSnippet: value,
      })
    );
  };
  return (
    <form className="flex gap-x-6 justify-between items-start">
      <div className="flex flex-col">
        <p className="text-gray-700 text-sm font-medium">Personal snippets</p>
        <p className="text-gray-500 text-xs font-normal">
          Enable or disable if users can create snippets for personal usage
        </p>
      </div>

      <Toggle
        checked={currentTeam.teamPermissions.isAllowCreatePersonalSnippet}
        onChange={handleChangeIsAllowCreatePersonalSnippet}
      />
    </form>
  );
};
