import React, { FC, ReactNode } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { environment } from '../../../../environments/environment';

type IProps = {
  children: ReactNode;
};

const stripePromise = loadStripe(environment.stripePublishableKey);

export const StripeElementsProvider: FC<IProps> = ({ children }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'usd',
        payment_method_types: ['card'],
        appearance: {
          disableAnimations: true,
          variables: {
            fontFamily: 'Figtree',
            spacingGridRow: '16px',
            colorDanger: '#F04438',
            borderRadius: '8px',
          },
          rules: {
            '.Label': {
              fontSize: '14px',
              marginBottom: '6px',
              color: '#424242',
              fontWeight: '500',
              '-webkit-font-smoothing': 'auto',
            },
            '.Input': {
              color: '#141414',
              boxShadow: 'none',
            },
            '.Input:focus': {
              boxShadow: 'none',
              borderColor: '#0E9384',
            },
            '.Input::placeholder': {
              color: '#737373',
            },
            '.Input--invalid': {
              borderWidth: '1px',
              borderColor: '#D92D20',
              color: '#141414',
              boxShadow: 'none',
            },
            '.Error': {
              fontSize: '14px',
              marginTop: '6px',
              lineHeight: '20px',
              fontWeight: '400',
            },
          },
        },
      }}
    >
      {children}
    </Elements>
  );
};
