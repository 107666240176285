import { call } from 'redux-saga/effects';
import { GONG_ACCESS_TOKEN_EXPIRATION_DATE } from '../../../../shared/constants';
import { refreshGongAccessToken } from './refreshGongAccessToken';
import { disconnectFromGong } from './disconnectFromGong';

export function* checkGongAccessTokenExpiration() {
  const expirationDateString = localStorage.getItem(
    GONG_ACCESS_TOKEN_EXPIRATION_DATE
  );
  if (!expirationDateString) {
    yield call(disconnectFromGong);
    throw new Error('Gong authorization data is missing');
  }
  const expirationDate = new Date(expirationDateString);
  if (expirationDate < new Date()) {
    yield call(refreshGongAccessToken);
  }
}
