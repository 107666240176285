import { FC, useRef, useState } from 'react';
import { getVideoDuration } from '../lib/getVideoDuration';

export const VideoSnippet: FC<{ url: string }> = ({ url }) => {
  const videoEl = useRef<HTMLVideoElement | null>(null);
  const [duration, setDuration] = useState('');
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    setDuration(getVideoDuration(video.duration));
  };

  return (
    <>
      <video
        ref={videoEl}
        src={url}
        autoPlay={false}
        controls={false}
        muted
        onLoadedMetadata={handleLoadedMetadata}
        className="object-cover w-full h-full rounded-md"
      ></video>
      <div className="absolute bg-base-black-50 py-0.5 px-2.5 bottom-2 right-2 rounded-2xl text-base-white text-sm font-medium">
        {duration}
      </div>
    </>
  );
};
