import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PlanName } from '@distribute/shared/types';
import { Button, Modal } from '../../../../../shared/ui';
import { PaymentInfo } from './PaymentInfo';
import { PaymentMethod } from './PaymentMethod';
import { teamsModel } from '../../../../teams';
import { subscriptionPlans } from '../../../config';
import {
  useCardFormCustomFields,
  useCurrentPlan,
  useSubscriptionInfo,
} from '../../../hooks';
import {
  checkIsPlanGtCurrentPlan,
  getPromoCodeDiscountAmount,
} from '../../../lib';
import { PromoCode, SubscriptionPlan } from '../../../types';
import { subscriptionModel } from '../../../model';
import { submitReactHookForm } from '../../../../../shared/lib';
import { onboardingModel } from '../../../../onboarding';

type IProps = {
  planName: PlanName;
  isFromOnboarding?: boolean;
  onClose: () => void;
  onUpdated: () => void;
  onSkip?: () => void;
  isAppSumo?: boolean;
};

export const UpdatePlanModal: FC<IProps> = ({
  planName,
  isFromOnboarding,
  onClose,
  onUpdated,
  onSkip,
  isAppSumo = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const cardFormCustomFields = useCardFormCustomFields(isFromOnboarding);
  const dispatch = useDispatch();
  const currentPlan = useCurrentPlan();
  const { isHasOnboardingPromoCode } = useSubscriptionInfo();

  const isLoadingCreateSubscriptionTrial = useSelector(
    onboardingModel.selectors.selectIsLoadingCreateSubscriptionTrial
  );
  const {
    id: teamId,
    subscriptionPaymentMethod,
    stripeSubscription,
    subscriptionOnboardingPromoCode,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isLoadingUpgrade = useSelector(
    subscriptionModel.selectors.selectIsUpdatingSubscription
  );
  const [isUpdatePaymentMethod, setIsUpdatePaymentMethod] = useState(
    !subscriptionPaymentMethod
  );
  const [prorationDate] = useState(stripeSubscription ? new Date() : undefined);
  const [promoCode, setPromoCode] = useState<PromoCode | null>(
    isHasOnboardingPromoCode && subscriptionOnboardingPromoCode
      ? {
          stripeCode: subscriptionOnboardingPromoCode.code,
          stripeCouponId: subscriptionOnboardingPromoCode.stripeCouponId,
          stripePromoCodeId: subscriptionOnboardingPromoCode.stripePromoCodeId,
          amountOff: subscriptionOnboardingPromoCode.amountOff,
          percentOff: subscriptionOnboardingPromoCode.percentOff,
        }
      : null
  );
  const newPlan = subscriptionPlans.find(
    (i) => i.id === planName
  ) as SubscriptionPlan;
  const isPlanGtCurrentPlan = checkIsPlanGtCurrentPlan(
    newPlan.id,
    currentPlan.id
  );

  const getTitleText = () => {
    if (isFromOnboarding && subscriptionOnboardingPromoCode) {
      return `Subscribe with ${getPromoCodeDiscountAmount(
        subscriptionOnboardingPromoCode
      )} discount`;
    }

    return `${isPlanGtCurrentPlan ? 'Upgrade' : 'Downgrade'} to ${
      newPlan?.name
    } Plan`;
  };

  const getInformativeTextForAppSumoUser = () => {
    return `${
      isPlanGtCurrentPlan
        ? 'Your AppSumo lifetime license is only valid for the Pro single member account. If you would like to upgrade to the Team plan, your account will be moved to the standard billing terms, costing $149.00 per month for up to 5 members.'
        : 'Your AppSumo lifetime license is only valid for the Team plan. If you would like to downgrade to the Pro plan, your account will be moved to the standard billing terms, costing $49.00 per month per member.'
    }`;
  };

  const getSaveButtonText = () => {
    if (isFromOnboarding) {
      return 'Save card and continue';
    }

    return `${isPlanGtCurrentPlan ? 'Upgrade' : 'Downgrade'} to ${
      newPlan.name
    } Plan`;
  };

  const handleUpdate = async () => {
    if (!stripe || !elements) return;

    const { error: submitError } = isUpdatePaymentMethod
      ? await elements.submit()
      : { error: undefined };
    const { errors: cardFormCustomFieldsErrors } = isUpdatePaymentMethod
      ? await submitReactHookForm(cardFormCustomFields.form)
      : { errors: undefined };

    if (submitError || cardFormCustomFieldsErrors) {
      return;
    }

    const data = {
      teamId,
      isUpdatePaymentMethod,
      plan: planName,
      interval: newPlan.interval,
      stripePromoCodeId: promoCode?.stripePromoCodeId,
      elements,
      stripe,
      cardFormCustomFieldsForm: cardFormCustomFields.form,
      cb: onUpdated,
    };

    if (stripeSubscription) {
      dispatch(subscriptionModel.actions.updateSubscription(data));
    } else {
      dispatch(subscriptionModel.actions.createSubscription(data));
    }
  };

  return (
    <Modal
      isOpen
      isShowCloseIconInTitle
      onClose={onClose}
      title={getTitleText()}
      isShowCancelButton={false}
      className="!w-120 !max-h-[calc(100vh-32px)] !flex !flex-col"
    >
      <div className="flex flex-col min-h-0">
        <div className="-mx-6 border-t border-gray-200" />
        <div className="flex flex-col gap-6 -mx-6 px-6 py-6 min-h-0 overflow-auto">
          {isAppSumo && (
            <p className="text-sm text-gray-600 font-normal mb-2">
              {getInformativeTextForAppSumoUser()}
            </p>
          )}
          {isFromOnboarding && (
            <span className="text-md text-gray-700">
              Add a credit card today to receive a seamless transition and a
              discount after your trial period ends.
            </span>
          )}
          <PaymentInfo
            plan={newPlan}
            prorationDate={prorationDate}
            promoCode={promoCode}
            onDeletePromoCode={() => setPromoCode(null)}
            onApplyPromoCode={(promoCode) => setPromoCode(promoCode)}
          />
          <PaymentMethod
            isUpdatePaymentMethod={isUpdatePaymentMethod}
            onChangeIsUpdatePaymentMethod={(val) =>
              setIsUpdatePaymentMethod(val)
            }
            onUpdatePaymentMethod={handleUpdate}
            cardFormCustomFields={cardFormCustomFields}
          />
        </div>
        <div className="-mx-6 border-t border-gray-200" />
        <div className="flex flex-col gap-2 pt-6">
          <Button
            onClick={handleUpdate}
            fullWidth
            color="primary"
            variant="text"
            loading={isLoadingUpgrade}
          >
            {getSaveButtonText()}
          </Button>
          {isFromOnboarding && (
            <Button
              color="link"
              variant="text"
              disabled={isLoadingCreateSubscriptionTrial}
              loading={isLoadingCreateSubscriptionTrial}
              onClick={onSkip}
            >
              Skip - I don't want the discount
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
