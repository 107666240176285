import { nodePasteRule } from '@tiptap/core';

import { isValidVimeoUrl, VIMEO_REGEX_GLOBAL } from './utils';
import { VimeoExt } from '@distribute/shared/generate-html';

type SetVimeoVideoOptions = {
  src: string;
  width?: number;
  height?: number;
  start?: number;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    vimeo: {
      /**
       * Insert a youtube video
       */
      setVimeoVideo: (options: SetVimeoVideoOptions) => ReturnType;
    };
  }
}

export const VimeoExtension = VimeoExt.extend({
  addCommands() {
    return {
      setVimeoVideo:
        (options: SetVimeoVideoOptions) =>
        ({ commands }) => {
          if (!isValidVimeoUrl(options.src)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: VIMEO_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.groups ? match.groups[0] : match.input };
        },
      }),
    ];
  },
});
