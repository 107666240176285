import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toggle } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';

export const DetachSnippetPermissionForm: FC = () => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const handleChangeIsDetachSnippetDisabled = (value: boolean) => {
    dispatch(
      teamsModel.actions.updateTeamPermissions({
        teamId: currentTeam.id,
        isDetachSnippetDisabled: value,
      })
    );
  };

  return (
    <form className="flex gap-x-6 justify-between items-start">
      <div className="flex flex-col">
        <p className="text-gray-700 text-sm font-medium">
          Disable ability to detach snippets
        </p>
        <p className="text-gray-500 text-xs font-normal">
          Enable or disable if regular members can detach snippet instances
        </p>
      </div>

      <Toggle
        checked={currentTeam.teamPermissions.isDetachSnippetDisabled}
        onChange={handleChangeIsDetachSnippetDisabled}
      />
    </form>
  );
};
