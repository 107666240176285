import React from 'react';
import { Icon, Button } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { useTeamPermissions } from '../../../features/teams';
import { subscriptionModel } from '../../../features/subscription';

export const UpgradePlanFromSnippetsScreen: React.FC = () => {
  const dispatch = useDispatch();

  const handleUpgradeClick = () => {
    dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
  };

  const { isCanManageSubscription } = useTeamPermissions();

  return (
    <div className="relative flex flex-col items-center justify-center flex-grow w-full gap-2 overflow-hidden">
      <div className="relative z-10 flex flex-col items-center justify-center">
        <div className="absolute bottom-0 right-0">
          <img src="../../../assets/images/bg-dots.svg" alt="background" />
        </div>
        <div className="border border-gray-200 rounded-2.5 p-3 shadow-xs w-12 h-12 bg-base-white relative">
          <Icon glyph={IconMap.Snippets} width={24} className="text-gray-700" />
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center mt-4">
          <p className="mb-1 font-semibold text-gray-900 text-md">
            Upgrade to use snippets in your team
          </p>
          <p className="text-gray-600 text-sm font-normal max-w-[352px] text-center mb-6">
            {isCanManageSubscription
              ? 'Upgrade to any paid plan to enjoy unlimited snippets.'
              : 'Wait for the team admin(s) to upgrade subscription for your team'}
          </p>
          {isCanManageSubscription && (
            <Button
              color="primary"
              size="md"
              variant="text"
              onClick={handleUpgradeClick}
            >
              Upgrade now
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
