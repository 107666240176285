import { call, fork } from 'redux-saga/effects';
import { snippetsWorker } from './snippetsWorker';
import { environment } from '../../../../environments/environment';
import { FeatureFlags } from '../../../../entities/feature-flag';
import { redirect } from '../../../../entities/history';

export function* snippetsSaga() {
  const isSnippetsEnabled = environment.featureFlags[FeatureFlags.SNIPPETS];

  if (!isSnippetsEnabled) {
    yield call(redirect.toWorkspace);
    return;
  }

  yield fork(snippetsWorker);
}
