import {
  SnippetCategoryWithSnippets,
  SnippetType,
} from '@distribute/shared/types';

export const getCategoryTypesTagConfig = (
  category: SnippetCategoryWithSnippets
) => {
  const types = [category.text, category.image, category.file, category.video];

  const allowedTypes = types.filter((type) => type);

  if (allowedTypes.length === 4) {
    return {
      text: 'All types',
    };
  }

  if (allowedTypes.length === 1) {
    const currentType = Object.values(SnippetType).find((v) => category[v]);
    return {
      text: `${
        currentType && currentType[0].toUpperCase() + currentType?.slice(1)
      } only`,
    };
  }

  return {
    text: `${allowedTypes.length} types`,
    tooltipItems: Object.values(SnippetType)
      .filter((v) => category[v])
      .map((item) => item[0].toUpperCase() + item.slice(1))
      .join(', '),
  };
};
