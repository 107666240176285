import React from 'react';
import { Modal, Button } from '../../../shared/ui';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  buttonText: string;
};

export const WarningPageModal: React.FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  onConfirm,
  title,
  description,
  buttonText,
}) => (
  <Modal
    onClose={onClose}
    isOpen={isOpen}
    title={title}
    className="w-120"
    actionButton={
      <Button
        onClick={onConfirm}
        fullWidth
        type="submit"
        color="destructive"
        variant="text"
        loading={isLoading}
      >
        {buttonText}
      </Button>
    }
  >
    <div className="text-sm font-normal text-gray-600">{description}</div>
  </Modal>
);
