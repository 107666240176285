import { Snippet, SnippetType } from '@distribute/shared/types';

export const getSnippetIdByRegex = (text: string) => {
  const regex = /\{\{\s*([^}\s]+)\s*\}\}/;
  const match = text.match(regex);

  if (match) {
    const id = match[1];
    return id;
  } else {
    return '';
  }
};

export const insertSnippetsIntoHTML = (
  htmlContent: string,
  snippetsInfo: { id: string; template: string; info: Snippet }[]
) => {
  let html = htmlContent;

  for (const snippet of snippetsInfo) {
    const id = snippet.id;

    const currentSnippet = snippetsInfo.find((s) => s.id === id);
    const snippetInfo = currentSnippet?.info;

    if (snippetInfo) {
      if (snippetInfo.type === SnippetType.TEXT) {
        html = html.replace(`${snippet.template}`, snippetInfo.htmlContent);
      } else {
        const insertedContent = `<div class="media-snippet">${snippetInfo.htmlContent}</div>`;

        html = html.replace(`${snippet.template}`, insertedContent);
      }
    }
  }

  return html;
};
