import { TeamFiltered, User } from '@distribute/shared/types';

type GetCurrentTeamInput = {
  teams: TeamFiltered[];
  currentUser: User;
  acceptedTeam?: TeamFiltered;
  currentTeamId?: number;
};

export const getCurrentTeam = ({
  teams,
  currentUser,
  acceptedTeam,
  currentTeamId,
}: GetCurrentTeamInput): TeamFiltered => {
  if (acceptedTeam) return acceptedTeam;

  if (currentTeamId) {
    const team = teams.find((team) => team.id === currentTeamId);

    if (team) return team;
  }

  if (currentUser.lastSelectedTeamId) {
    const team = teams.find(
      (team) => team.id === currentUser.lastSelectedTeamId
    );

    if (team) return team;
  }

  return teams[0];
};
