import { call, put, select } from 'redux-saga/effects';
import { DBUser, User } from '../../../shared/types';
import { getUserProfile } from './getUserFromFb';
import { authUserModel } from '../model';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { navigate } from '../../../processes/navigation';
import { VERIFY_EMAIL_ROUTE } from '../../history';
import { authApi } from '../../../shared/api';

export function* checkEmailVerification() {
  const user: User = yield call(getUserProfile);

  const dbUser: DBUser = yield select(authUserModel.selectors.selectUser);

  if (!user.isVerified) {
    yield put(navigate({ to: VERIFY_EMAIL_ROUTE }));
  }
  if (
    user.isVerified &&
    dbUser.onboardingStatus === UserOnboardingStatus.VERIFY_EMAIL
  ) {
    if (dbUser.shouldCreateOwnTeam) {
      const updatedUser: DBUser = yield call(authApi.updateProfile, {
        onboardingStatus: UserOnboardingStatus.USAGE_PLAN,
      });

      yield put(authUserModel.actions.setDBUser(updatedUser));
    } else {
      const updatedUser: DBUser = yield call(authApi.updateProfile, {
        onboardingStatus: UserOnboardingStatus.FINISHED,
      });

      yield put(authUserModel.actions.setDBUser(updatedUser));
    }
  }
}
