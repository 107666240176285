import { useCallback, useEffect, useState, RefObject } from 'react';
import debounce from 'lodash/debounce';

export const useNativeScroll = (containerRef: RefObject<HTMLElement>) => {
  const [isStartScrolling, setIsStartScroling] = useState(true);
  const [isEndScrolling, setIsEndScrolling] = useState(false);

  const getIsStartScrolling = () => {
    const containerElement = containerRef.current as HTMLElement;
    return containerElement?.scrollLeft === 0;
  };

  const getIsEndScrolling = () => {
    const containerElement = containerRef.current as HTMLElement;
    return (
      containerElement?.clientWidth + containerElement?.scrollLeft >=
      containerElement?.scrollWidth - 1
    ); // Subtract 1 from scrollWidth to account for sub-pixel rendering;
  };

  const handleScroll = useCallback(
    debounce(() => {
      setIsStartScroling(getIsStartScrolling());
      setIsEndScrolling(getIsEndScrolling());
    }, 200),
    [containerRef.current]
  );

  const recalculateScroll = useCallback(() => {
    setIsStartScroling(getIsStartScrolling());
    setIsEndScrolling(getIsEndScrolling());
  }, [containerRef.current]);

  const setScrollPosition = useCallback(
    (scrollPosition: number) => {
      const containerElement = containerRef.current as HTMLElement;
      containerElement.scrollTo({
        top: 0,
        left: scrollPosition,
        behavior: 'smooth',
      });

      const nextScroll = Math.min(
        scrollPosition,
        containerElement.scrollWidth - containerElement.clientWidth
      );

      setIsStartScroling(nextScroll === 0);
      setIsEndScrolling(
        containerElement.clientWidth + nextScroll >=
          containerElement.scrollWidth - 1
      );
    },
    [containerRef.current]
  );

  useEffect(() => {
    const containerElement = containerRef.current as HTMLElement;

    if (!containerElement) return;
    setIsStartScroling(getIsStartScrolling());
    setIsEndScrolling(getIsEndScrolling());

    containerElement.addEventListener('scroll', handleScroll);

    return () => containerElement.removeEventListener('scroll', handleScroll);
  }, [containerRef.current, handleScroll]);

  return {
    isStartScrolling,
    isEndScrolling,
    setScrollPosition,
    recalculateScroll,
  };
};
