import React from 'react';
import cn from 'classnames';

type StatusType = 'warning' | 'error';

type Props = {
  type: StatusType;
};

const notificationStyles: { [key in StatusType]: string } = {
  warning: 'border-warning-600 bg-warning-50 text-warning-600',
  error: 'border-error-600 bg-error-50 text-error-600',
};

export const DomainStatusNotificationMessage: React.FC<Props> = ({ type }) => {
  return (
    <div
      className={cn(
        'border rounded-xl p-4 text-sm font-semibold',
        notificationStyles[type]
      )}
    >
      {type === 'warning' ? (
        <span>
          Verification can take up to 48-hours to propagate your new custom
          domain. If you think something is wrong, please check your setup.
        </span>
      ) : (
        <span>
          Your domain has not been verified. If you think something is wrong,
          please check your setup.
        </span>
      )}
    </div>
  );
};
