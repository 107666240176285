import { CustomDomainVerificationStatus } from '@distribute/shared/types';
import { RootState } from '../../../app';

export const selectCustomDomainInfo = ({ customDomains }: RootState) =>
  customDomains.customDomainInfo;
export const selectCustomDomainIsCreating = ({ customDomains }: RootState) =>
  customDomains.isCreating;
export const selectCustomDomainIsDeleting = ({ customDomains }: RootState) =>
  customDomains.isDeleting;
export const selectCustomDomainIsActivating = ({ customDomains }: RootState) =>
  customDomains.isActivating;
export const selectIsDeleteConfirmationModalOpen = ({
  customDomains,
}: RootState) => customDomains.isDeleteConfirmationModalOpen;
export const selectVerifiedCustomDomain = ({ customDomains }: RootState) =>
  customDomains.customDomainInfo?.status ===
  CustomDomainVerificationStatus.DNS_RECORD_FOUND
    ? customDomains.customDomainInfo.customDomain
    : undefined;
