import { Snippet, SnippetsSharingAccess } from '@distribute/shared/types';

export const isSwitchFromPersonalToTeamConfirmation = (
  from: SnippetsSharingAccess,
  to: SnippetsSharingAccess,
  isDraft?: boolean
) => {
  if (
    to === SnippetsSharingAccess.TEAM &&
    from === SnippetsSharingAccess.PERSONAL &&
    !isDraft
  ) {
    return true;
  }

  return false;
};

export const isAllowSwitchSharingAccess = (
  to: SnippetsSharingAccess,
  snippet: Snippet
) => {
  if (
    to === SnippetsSharingAccess.PERSONAL &&
    snippet.sharingAccess === SnippetsSharingAccess.TEAM &&
    snippet.usedCount > 0
  ) {
    return false;
  }

  return true;
};
