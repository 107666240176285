import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { globalActions } from '../../../app/model/actions';
import { CustomDomainType } from '@distribute/shared/types';
import { DeleteCustomDomainAction } from './types';

type State = {
  customDomainInfo: CustomDomainType | null;
  isCreating: boolean;
  isActivating: boolean;
  isDeleting: boolean;
  isDeleteConfirmationModalOpen: boolean;
};

const initialState: State = {
  customDomainInfo: null,
  isCreating: false,
  isActivating: false,
  isDeleting: false,
  isDeleteConfirmationModalOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'customDomains',
  initialState,
  reducers: {
    setCustomDomainInfo: (
      state,
      { payload: customDomainInfo }: PayloadAction<CustomDomainType | null>
    ) => ({ ...state, customDomainInfo }),
    setCustomDomainCreating: (
      state,
      { payload: isCreating }: PayloadAction<boolean>
    ) => ({ ...state, isCreating }),
    setCustomDomainActivating: (
      state,
      { payload: isActivating }: PayloadAction<boolean>
    ) => ({ ...state, isActivating }),
    setCustomDomainIsDeleting: (
      state,
      { payload: isDeleting }: PayloadAction<boolean>
    ) => ({ ...state, isDeleting }),
    setDeleteConfirmationModalOpen: (
      state,
      { payload: isDeleteConfirmationModalOpen }
    ) => ({ ...state, isDeleteConfirmationModalOpen }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  loadCustomDomainInfo: createAction('customDomains/loadCustomDomainInfo'),
  createCustomDomain: createAction<string>('customDomains/createCustomDomain'),
  deleteCustomDomain: createAction<DeleteCustomDomainAction>(
    'customDomains/deleteCustomDomain'
  ),
  activateCustomDomain: createAction('customDomains/activateCustomDomain'),
};
