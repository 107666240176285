export type EditorColor = {
  name: string;
  value: string;
};

export const DEFAULT_EDITOR_COLORS_CONFIG: EditorColor[] = [
  { name: 'Black', value: 'rgb(20, 20, 20)' },
  { name: 'Dark grey', value: 'rgb(115, 115, 115)' },
  { name: 'Light grey', value: 'rgb(214, 214, 214)' },
  { name: 'Red', value: 'rgb(217, 45, 32)' },
  { name: 'Orange', value: 'rgb(224, 79, 22)' },
  { name: 'Yellow', value: 'rgb(247, 144, 9)' },
  { name: 'Green', value: 'rgb(7, 148, 85)' },
  { name: 'Blue', value: 'rgb(0, 134, 201)' },
  { name: 'Pink', value: 'rgb(221, 37, 144)' },
  { name: 'Violet', value: 'rgb(105, 56, 239)' },
];
