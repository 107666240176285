import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';
import React, { useState } from 'react';
import cn from 'classnames';
import { Tooltip } from '../../../../shared/ui';
import { Editor } from '@tiptap/react';
import { ResizeableFigureAlign } from './ResizeableFigure';

type Props = {
  editor: Editor;
};

type AlignmentDropdownOption = {
  key: ResizeableFigureAlign;
  icon: SpriteGlyph;
  tooltip: string;
};

const ALIGNMENT_DROPDOWN_OPTIONS: AlignmentDropdownOption[] = [
  {
    key: 'left',
    icon: IconMap.PhotoLeft,
    tooltip: 'Align left',
  },
  {
    key: 'center',
    icon: IconMap.PhotoCenter,
    tooltip: 'Align center',
  },
  {
    key: 'right',
    icon: IconMap.PhotoRight,
    tooltip: 'Align right',
  },
];

export const AlignmentDropdown: React.FC<Props> = ({ editor }) => {
  const [selectedAlignmentOption, setAlignmentOption] =
    useState<AlignmentDropdownOption>(ALIGNMENT_DROPDOWN_OPTIONS[1]);
  const [isOpen, setOpen] = useState(false);

  const handleOptionSelect = (option: AlignmentDropdownOption) => {
    editor.chain().setResizeableFigureAlign(option.key).focus().run();
    setAlignmentOption(option);
    setOpen(false);
  };

  return (
    <div className="relative">
      <Tooltip
        trigger={
          <div
            className={cn(
              'w-9 h-9 bg-base-white opacity-75 border border-gray-300 rounded-lg hover:opacity-100 flex items-center justify-center cursor-pointer',
              { 'opacity-100': isOpen }
            )}
            onClick={() => setOpen((prev) => !prev)}
          >
            <Icon
              glyph={selectedAlignmentOption.icon}
              width={20}
              className={cn('text-gray-600', { 'text-gray-700': isOpen })}
            />
          </div>
        }
        hideArrow
        sideOffset={8}
      >
        <span className="text-sm font-medium">Align</span>
      </Tooltip>

      {isOpen && (
        <ul className="w-10 absolute top-10 right-0 !p-1.5 rounded-lg border border-gray-200 bg-base-white shadow-lg">
          {ALIGNMENT_DROPDOWN_OPTIONS.map((option) => (
            <Tooltip
              key={option.key}
              trigger={
                <li
                  className="w-7 h-7 flex items-center justify-center rounded hover:bg-gray-100 cursor-pointer transition-all"
                  onClick={() => handleOptionSelect(option)}
                >
                  <Icon
                    glyph={option.icon}
                    width={20}
                    className="text-gray-700"
                  />
                </li>
              }
              hideArrow
              sideOffset={8}
            >
              <span className="text-sm font-medium">{option.tooltip}</span>
            </Tooltip>
          ))}
        </ul>
      )}
    </div>
  );
};
