import { FC } from 'react';
import { Avatar, Size } from '../Avatar';

type IProps = {
  displayName: string;
  photoUrl?: string;
  avatarSize?: Size;
  email?: string;
};

export const SelectOptionUser: FC<IProps> = ({
  displayName,
  photoUrl,
  avatarSize = 'xs',
  email,
}) => {
  return (
    <div className="flex items-center gap-x-2 min-w-0">
      <Avatar
        isUserAvatar
        displayName={displayName}
        src={photoUrl}
        size={avatarSize}
        className="shrink-0"
      />
      <span className="text-gray-900 text-sm font-medium truncate">
        {displayName}
      </span>
      {email && <span className="text-sm text-gray-600 truncate">{email}</span>}
    </div>
  );
};
