export enum DateUnitState {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum ChartTypeState {
  LINEAR = 'linear',
  CUMULATIVE = 'cumulative',
}
