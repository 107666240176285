import { VideoRecordType } from '@distribute/shared/api-types/video-record';
import { ALL_OWNERS, SortType } from '../config';
import { UserPermissionToVideoRecordRole } from '@distribute/shared/types';
import { contains } from '../../../utils/string';

export const getFilterSearchResults = (
  records: VideoRecordType[],
  search: string
) => {
  return records.filter((r) => contains(r.name, search));
};

export const getFilterOwnerResults = (
  records: VideoRecordType[],
  owner: string
) => {
  if (owner === ALL_OWNERS) return records;

  return records.filter((r) =>
    r.userPermissionToVideoRecords.some(
      (uV) =>
        uV.user.id === owner &&
        uV.role === UserPermissionToVideoRecordRole.OWNER
    )
  );
};

export const getSortResults = (records: VideoRecordType[], sort: SortType) => {
  switch (sort) {
    case SortType.LAST_CREATED:
      return records;
    case SortType.NAME:
      return [...records].sort(({ name: aName }, { name: bName }) => {
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
    default:
      return records;
  }
};
