import { call, fork, put, select } from 'redux-saga/effects';
import { UserToTeamFiltered } from '@distribute/shared/types';
import { teamMembersModel } from '../';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { updateTeamsData } from './updateTeamsData';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* deleteTeamMember({
  payload: { teamId, teamMemberId },
}: ReturnType<typeof teamMembersModel.actions.deleteTeamMember>) {
  try {
    yield put(teamMembersModel.actions.setIsLoadingDeleteTeamMember(true));
    yield call(teamsApi.deleteTeamMember, teamId, teamMemberId);
    const teamMembers: UserToTeamFiltered[] = yield select(
      teamsModel.selectors.selectCurrentTeamMembers
    );
    const updatedTeamMembers = teamMembers.filter(
      (teamMember) => teamMember.id !== teamMemberId
    );

    yield put(teamsModel.actions.setCurrentTeamMembers(updatedTeamMembers));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The user has been deleted')
      )
    );
    yield fork(updateTeamsData);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to delete the team member')
        )
      )
    );
  } finally {
    yield put(teamMembersModel.actions.setIsLoadingDeleteTeamMember(false));
  }
}
