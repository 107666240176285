import { SnippetType } from '@distribute/shared/types';
import { IconMap } from '../../../../../shared/sprite';

export const snippetItemIconByType = {
  [SnippetType.IMAGE]: IconMap.Image01,
  [SnippetType.VIDEO]: IconMap.PlaySquare,
  [SnippetType.FILE]: IconMap.BlankPageSquare,
  [SnippetType.TEXT]: IconMap.TypeSquare,
};

export const snippetMenuIconByType = {
  [SnippetType.IMAGE]: IconMap.SnippetMenuImg,
  [SnippetType.VIDEO]: IconMap.SnippetMenuVideo,
  [SnippetType.FILE]: IconMap.BlankPageSquare,
  [SnippetType.TEXT]: IconMap.SnippetMenuText,
};

export const noFoundMessages = {
  setupCategories:
    'Begin by establishing categories of snippets for your team.',
  setupTeamSnippets:
    'Wait for the team admin(s) to set up snippets for your team. In the meantime, you can begin creating  snippets for personal use.',
  setupPersonalSnippets: 'Tailor snippets to suit your specific needs.',
  setupDraftSnippets: 'Create snippets to streamline your workflow.',
  waitForSnippets:
    'Wait for the team admin(s) to set up snippets for your team.',
  filterSnippetsAndAllowCreation:
    'We cannot find the snippet you need, but you can always create your own.',
  filterSnippets: 'We cannot find the snippet you need.',
};
