import { FC } from 'react';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Input } from '../../../../shared/ui';

type Schema = { url: string };

const inputSchema = object().shape({
  url: string().required().url(),
});

type Props = {
  onSubmit: (url: string) => void;
  label: string;
  placeholder: string;
  message: string;
};

export const UploadFormEmbed: FC<Props> = ({
  onSubmit,
  label,
  placeholder,
  message,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: yupResolver(inputSchema),
  });

  return (
    <form
      onSubmit={handleSubmit(({ url }) => onSubmit(url))}
      className="h-32 flex flex-col justify-between"
    >
      <div>
        <Input
          type="text"
          {...register('url')}
          isError={!!errors.url}
          placeholder={placeholder}
          heightSize="md"
        />
        <p className="text-sm text-gray-600 mt-1">{label}</p>
      </div>

      <div className="flex justify-end">
        <Button type="submit" variant="text" color="primary">
          {message}
        </Button>
      </div>
    </form>
  );
};
