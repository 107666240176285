import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VideoRecordDetailType } from '@distribute/shared/api-types/video-record';

import { Loader } from '../../../../shared/ui';

import { recordingsModel } from '../../model';

import { RecordDetailsPrivate } from './record-details-private';
import { RecordDetailsPublic } from './record-details-public';

export const RecordDetails: FC = () => {
  const dispatch = useDispatch();

  const { isAllowToViewLikeMember } = useSelector(
    recordingsModel.selectors.selectRecord
  );
  const isRecordLoading = useSelector(
    recordingsModel.selectors.selectIsRecordLoading
  );

  useEffect(
    () => () => {
      dispatch(recordingsModel.actions.setRecord({} as VideoRecordDetailType));
    },
    //eslint-disable-next-line
    []
  );

  if (isRecordLoading) {
    return <Loader />;
  }

  if (isAllowToViewLikeMember) {
    return <RecordDetailsPrivate />;
  }

  return <RecordDetailsPublic />;
};
