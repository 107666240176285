import { call, put } from 'redux-saga/effects';
import { logger } from '../../../shared/lib';
import { personalizedCoverApi } from '../../../shared/api/axios/personalized-cover';
import { editorSidebarModel } from '../model';
import { UpdatePersonalizedCoverRequestType } from '@distribute/shared/api-types/personalized-cover';

export function* setPersonalizedCoverData() {
  try {
    const personalizedCover: UpdatePersonalizedCoverRequestType = yield call(
      personalizedCoverApi.getPersonalizedCover
    );

    if (!personalizedCover) {
      return;
    }

    yield put(
      editorSidebarModel.actions.setPersonalizedLinkColor(
        personalizedCover.textColor
      )
    );
    yield put(
      editorSidebarModel.actions.setPersonalizedLinkCover(
        personalizedCover.imageUrl
      )
    );
    yield put(
      editorSidebarModel.actions.setPersonalizedLinkNoLogo(
        personalizedCover.isNoDistributeLogo
      )
    );
  } catch (err) {
    logger.error(err);
  }
}
