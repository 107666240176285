import React, { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  iconGlyph: SpriteGlyph;
} & InputHTMLAttributes<HTMLInputElement>;

export const MenuSwitcherRadioButton: FC<Props> = ({
  name,
  iconGlyph,
  checked,
  onChange,
  value,
}) => {
  return (
    <label className="w-1/2">
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        className="hidden"
      />
      <div
        className={cn(
          'px-1.5 py-2 rounded-lg flex gap-1.5 items-center text-sm font-medium cursor-pointer justify-center',
          {
            'bg-base-white text-gray-700 shadow-sm': checked,
            'bg-transparent text-gray-500 hover:bg-base-white hover:text-gray-700 hover:shadow-sm':
              !checked,
          }
        )}
      >
        <Icon glyph={iconGlyph} width={20} />
        <span>{name}</span>
      </div>
    </label>
  );
};
