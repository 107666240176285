import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconMap } from '../../../shared/sprite';
import {
  AddFolderModal,
  foldersModel,
  useSplitFoldersToPrivateShared,
} from '../../../features/folders';
import { SidebarBanner } from '../../../features/sidebar-banner';

import { Navigation } from './Navigation';
import { Button, Icon, Tooltip } from '../../../shared/ui';

import { teamsModel, useTeamPermissions } from '../../../features/teams';
import { Folders } from './Folders';

export const SidebarMenuContent: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [openShared, setOpenShared] = useState(true);
  const [openPrivate, setOpenPrivate] = useState(true);
  const folders = useSelector(foldersModel.selectors.selectFolders);
  const teamPermissions = useTeamPermissions();
  const { privateFolders, sharedFolders } =
    useSplitFoldersToPrivateShared(folders);
  const teamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };
  const handleModalClose = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="flex flex-col justify-between overflow-auto bg-gray-50 md:h-full grow-1">
      <AddFolderModal isOpen={modalIsOpen} onClose={handleModalClose} />
      <div className="overflow-auto">
        {!teamPermissions.isGuest && <Navigation />}
        <div className="w-full h-px mb-5 bg-gray-200" />
        <div className="px-4">
          {!teamPermissions.isGuest && (
            <div className="mb-2 md:px-2 sm:px-0">
              <div className="flex flex-row items-center justify-between w-full pr-2 rounded-md hover:bg-light-3 h-fit">
                <Tooltip
                  hideArrow
                  className="px-3 py-2 mb-1"
                  triggerClassNames="flex-grow"
                  trigger={
                    <button
                      className="flex flex-row items-center flex-grow pl-2 py-2.5 w-full"
                      onClick={() => setOpenPrivate(!openPrivate)}
                    >
                      <div className="flex items-center">
                        <Icon
                          glyph={
                            openPrivate ? IconMap.ChevronUp : IconMap.ArrowDown
                          }
                          className="mr-3 text-gray-600"
                          width={16}
                        />
                        <span className="flex items-center gap-2 text-xs font-bold text-gray-500">
                          <Icon
                            glyph={IconMap.Lock}
                            className="text-gray-500"
                          />
                          PRIVATE
                        </span>
                      </div>
                    </button>
                  }
                >
                  Folders available only for you
                </Tooltip>

                {teamPermissions.isCanCreateFolders && (
                  <Tooltip
                    trigger={
                      <Button
                        color="link-gray"
                        variant="icon"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModalOpen();
                        }}
                        className="!p-1 !w-6 !min-w-6 !h-6 !min-h-6 rounded-md hover:bg-gray-200"
                      >
                        <Icon glyph={IconMap.Plus} width={16} />
                      </Button>
                    }
                    hideArrow
                    className="px-3 py-2 mb-3"
                  >
                    Create folder
                  </Tooltip>
                )}
              </div>
              <div className="w-full" />
              <Folders
                droppableId="droppablePrivate"
                isOpen={openPrivate}
                folders={privateFolders}
              />
            </div>
          )}
          {teamMembers.length > 1 && sharedFolders.length > 0 && (
            <div className="md:px-2 sm:px-0">
              <Tooltip
                hideArrow
                className="px-3 py-2 mb-1"
                triggerClassNames="w-full"
                trigger={
                  <div className="flex flex-row items-center w-full rounded-md h-fit hover:bg-light-3">
                    <Button
                      color="link-gray"
                      variant="icon-text"
                      size="sm"
                      onClick={() => setOpenShared(!openShared)}
                      className="!justify-start w-full !px-2 !py-2.5"
                    >
                      <Icon
                        glyph={
                          openShared ? IconMap.ChevronUp : IconMap.ArrowDown
                        }
                        className="mr-3 text-gray-600"
                        width={16}
                      />
                      <span className="flex items-center gap-2 text-xs font-bold text-gray-500">
                        <Icon glyph={IconMap.Users} className="text-gray-500" />
                        SHARED
                      </span>
                    </Button>
                  </div>
                }
              >
                Shared with you and another member(s)
              </Tooltip>

              <Folders
                droppableId="droppableShared"
                isOpen={openShared}
                folders={sharedFolders}
              />
            </div>
          )}
        </div>
      </div>
      <SidebarBanner />
    </div>
  );
};
