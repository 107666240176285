import { call, put, select } from 'redux-saga/effects';
import { Folder, Page } from '@distribute/shared/types';
import { pagesApi } from '../../../../shared/api';
import { pagesModel } from '../../../pages';
import { foldersModel } from '../../../folders';

export function* getOrCreateFirstPage() {
  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);
  const folder: Folder = folders[0];
  const pages: Page[] = yield select(pagesModel.selectors.selectPages);
  let page: Page | undefined = pages[0];

  if (!page) {
    page = yield call(pagesApi.createPrefilledPage, { folderId: folder.id });

    yield put(pagesModel.actions.setPages([...pages, page as Page]));
  }

  return page;
}
