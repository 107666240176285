import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { RegenerateApiKeyResponse } from '@distribute/shared/api-types/zapier';
import { createNotification, snackbarModel } from '../../../snackbar';
import { integrationsModel } from '..';
import { logger } from '../../../../shared/lib';
import { teamsModel } from '../../../teams';
import { integrationsApi } from '../../../../shared/api';

export function* regenerateApiKey({
  payload: { teamId },
}: ReturnType<typeof integrationsModel.actions.regenerateApiKey>) {
  try {
    yield put(integrationsModel.actions.setIsZapierLoading(true));
    const { zapierApiKey }: RegenerateApiKeyResponse = yield call(
      integrationsApi.regenerateApiKey,
      { teamId }
    );

    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    const currentTeam = teams.find((team) => team.id === teamId);

    if (currentTeam) {
      yield put(
        teamsModel.actions.setCurrentTeam({
          ...currentTeam,
          currentMember: { ...currentTeam.currentMember, zapierApiKey },
        })
      );
    }
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect.')
      )
    );
  } finally {
    yield put(integrationsModel.actions.setIsZapierLoading(false));
  }
}
