import React from 'react';
import { Helmet } from 'react-helmet-async';
import { IconMap } from '../../shared/sprite';
import { Icon } from '../../shared/ui';

export const NotFound: React.FC = () => {
  return (
    <>
      <Helmet title="Not Found" />
      <div
        className="flex flex-col justify-start min-h-full px-10 py-10 sm:py-6 sm:px-0
                  md:px-6 h-fit-content bg-primary-50"
      >
        <div className="grid grid-cols-12 sm:block md:grid-cols-8 gap-x-4">
          <div className="col-start-5 col-end-9 md:col-start-3 md:col-end-7">
            <div className="flex justify-center">
              <Icon glyph={IconMap.DistributeLogo} />
            </div>
            <div className="p-6 mx-auto my-6 rounded sm:rounded-none text-gray-900 bg-base-white">
              <h2 className="mb-4 font-semibold text-l text-center">
                Page not found!
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
