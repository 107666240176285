import { channel, Channel } from 'redux-saga';
import { LoggedActions, LoggedState } from '../../../shared/types';

import { LOGGED_IN, LOGGED_OUT } from '../config';

export const loggedIn = (actions?: LoggedActions): LoggedState => ({
  type: LOGGED_IN,
  actions,
});
export const loggedOut = (actions?: LoggedActions): LoggedState => ({
  type: LOGGED_OUT,
  actions,
});

export const chan: Channel<LoggedState> = channel();
