import React, { FC, useState } from 'react';
import { Button } from '../../../../shared/ui';
import { DeleteTeamConfirmModal } from './DeleteTeamConfirmModal';

export const DeleteTeamForm: FC = () => {
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="text-sm font-medium text-gray-700">
        By deleting the team, all of its pages will be permanently removed, and
        users will be disbanded without the possibility of reversal.
      </div>
      <Button
        variant="text"
        color="secondary-destructive"
        className="self-start sm:w-full"
        onClick={() => setIsShowConfirmModal(true)}
      >
        Delete Team
      </Button>
      <DeleteTeamConfirmModal
        isOpen={isShowConfirmModal}
        onClose={() => setIsShowConfirmModal(false)}
      />
    </div>
  );
};
