import { templatesModel } from '..';
import { call } from 'redux-saga/effects';
import { updateTemplateData } from '../../lib/updateTemplateData';

export function* updateTemplateFromSettings({
  payload: { template, callback },
}: ReturnType<typeof templatesModel.actions.updateTemplateFromSettings>) {
  yield call(updateTemplateData, { template, isShowSuccessNotification: true });
  callback();
}
