import { NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import { ToggleListItemNodeView } from './ToggleListItem.renderer';
import { ToggleListItemExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    toggleListItemExtension: {
      onOpenToggleListItem: (
        node: NodeViewProps['node'],
        position: number
      ) => ReturnType;
    };
  }
}

export const ToggleListItem = ToggleListItemExt.extend({
  addNodeView() {
    return ReactNodeViewRenderer(ToggleListItemNodeView);
  },

  addCommands() {
    return {
      ...this.parent?.(),
      onOpenToggleListItem:
        (node, position) =>
        ({ commands }) => {
          let isToggleListItemHasContent = false;

          node.content.forEach((node) => {
            if (node.type.name === 'toggleListItemContent') {
              isToggleListItemHasContent = true;
            }
          });

          if (isToggleListItemHasContent) return true;

          return commands.insertContentAt(position, {
            type: 'toggleListItemContent',
            content: [
              {
                type: 'paragraph',
              },
            ],
          });
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      Tab: () => true,
      Enter: () => {
        const { $anchor, $from } = this.editor.state.selection;
        const currentPos = $anchor.pos;
        let isEnterPressedInToggleItemContentNode = false;
        let isEnterPressedInToggleListItemNode = false;
        let positionForNewItem: number | undefined;

        this.editor.state.selection.ranges.forEach((range) => {
          this.editor.state.doc.nodesBetween(
            range.$from.pos,
            range.$to.pos,
            (node, pos) => {
              if (node.type.name === 'toggleListItemContent') {
                if (currentPos >= pos && currentPos <= pos + node.nodeSize) {
                  isEnterPressedInToggleItemContentNode = true;
                }
              }

              if (node.type.name === 'toggleListItem') {
                positionForNewItem = pos + node.nodeSize;
                if (currentPos >= pos && currentPos <= pos + node.nodeSize) {
                  isEnterPressedInToggleListItemNode = true;
                }
              }
            }
          );
        });

        if (
          !isEnterPressedInToggleListItemNode ||
          isEnterPressedInToggleItemContentNode ||
          positionForNewItem === undefined
        ) {
          return false;
        }

        if ($from.parent.content.size === 0) {
          this.editor.commands.toggleList('toggleList', 'toggleListItem');

          return true;
        }

        this.editor.commands.insertContentAt(positionForNewItem, {
          type: 'toggleListItem',
          content: [
            {
              type: 'paragraph',
            },
          ],
        });

        return true;
      },
    };
  },
});
