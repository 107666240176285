import { call, put, select } from 'redux-saga/effects';

import { gatedContentApi } from '../../../../shared/api';
import { GatedContent } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectGatedContent } from '../selectors';

export function* updateGatedContentDB({
  payload: { documentContentId },
}: ReturnType<typeof conversionKitModel.actions.updateCtaDB>) {
  try {
    const gatedContent: GatedContent = yield select(selectGatedContent);

    const { id: _, ...data } = gatedContent;

    yield call(gatedContentApi.updateGatedContent, documentContentId, data);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update gated content`)
      )
    );
  }
}
