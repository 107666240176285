import { call, put, select } from 'redux-saga/effects';
import { Page, TeamFiltered } from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { pagesModel } from '../../../pages';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* updateBrandStyles({
  payload: { teamId, ...data },
}: ReturnType<typeof actions.updateBrandStyles>) {
  try {
    yield put(actions.setIsUpdatingBrandStyles(true));
    const team: TeamFiltered = yield call(teamsApi.updateTeam, teamId, data);
    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    const updatedPages = pages.map((page) => ({
      ...page,
      content: { ...page.content, ...data },
    }));
    yield put(pagesModel.actions.setPages(updatedPages));
    yield call(teamsModel.sagas.onUpdateCurrentTeam, team);
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to update brand')
        )
      )
    );
  } finally {
    yield put(actions.setIsUpdatingBrandStyles(false));
  }
}
