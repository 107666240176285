import { FC } from 'react';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  loading: boolean;
  onFinish: () => void;
};

export const ChromeExtensionInstall: FC<Props> = ({ onFinish, loading }) => {
  return (
    <>
      <h2 className="text-gray-900 text-display-md font-medium font-display my-8 text-center">
        Installing the Chrome Extension
      </h2>
      <ol className="list-inside list-decimal text-gray-600 text-lg font-medium flex flex-col gap-y-3 mb-2">
        <li>Go to the Distribute extension in Chrome store.</li>
        <li>Click "Add to Chrome" to start the installation.</li>
        <li>Follow the steps to install the extension.</li>
      </ol>
      <Button
        variant="text"
        color="link"
        className="!font-semibold mt-1.5"
        disabled={loading}
        onClick={onFinish}
      >
        Continue to workspace
        <Icon
          glyph={IconMap.ArrowNarrowRight}
          width={20}
          className="shrink-0 ml-2"
        />
      </Button>
    </>
  );
};
