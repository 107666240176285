import React, { ReactNode, useState } from 'react';
import cn from 'classnames';
import { Icon } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  value: string;
  children: ReactNode;
  isHidden?: boolean;
};

export const SnippetsNavigationGroupItem: React.FC<Props> = ({
  value,
  children,
  isHidden,
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState<boolean>(true);

  return (
    <>
      <button
        className={cn(
          'flex flex-row items-center flex-grow p-2 w-full rounded-md hover:bg-light-3 mt-2',
          {
            hidden: isHidden,
          }
        )}
        onClick={() => setIsCategoryOpen(!isCategoryOpen)}
      >
        <div className="flex items-center">
          <div className="p-1 mr-3">
            <Icon
              glyph={isCategoryOpen ? IconMap.ChevronUp : IconMap.ArrowDown}
              className="text-gray-600"
              width={16}
            />
          </div>
          <span className="gap-2 text-xs font-bold text-gray-500 uppercase">
            {value}
          </span>
        </div>
      </button>
      {isCategoryOpen && !isHidden && children}
    </>
  );
};
