import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import { profileSettingModel } from '../../../features/profile-settings';
import {
  Input,
  Button,
  SimpleLayout,
  AuthFormWrapper,
} from '../../../shared/ui';
import { Helmet } from 'react-helmet-async';

type CreatePasswordForm = {
  password: string;
};

const validationSchema = object().shape({
  password: string()
    .required('Password is required.')
    .min(8, 'Password has to be at least 8 characters.'),
});

export const CreatePassword = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePasswordForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = ({ password }: CreatePasswordForm) => {
    dispatch(
      profileSettingModel.actions.completeResetPasswordClick({ password })
    );
  };

  return (
    <SimpleLayout>
      <AuthFormWrapper title="Create Password">
        <Helmet title="Create Password" />
        <div className="mb-6">
          <p className="text-md font-normal text-gray-600 text-center">
            It’s a good idea to use strong password that you don’t use
            elsewhere.
          </p>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div>
            <Input
              autoComplete="new-password"
              type="password"
              label="Password"
              {...register('password')}
              isError={!!errors.password}
              messageText={errors.password?.message}
            />
          </div>

          <div className="mt-6">
            <Button type="submit" color="primary" variant="text" fullWidth>
              Create Password
            </Button>
          </div>
        </form>
      </AuthFormWrapper>
    </SimpleLayout>
  );
};
