import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Modal } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';
import { useCurrentPlan, useSubscriptionInfo } from '../../hooks';
import { subscriptionModel } from '../../model';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const RenewPlanModal: FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const {
    id: teamId,
    stripeSubscription,
    subscriptionRenewPromoCode,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);
  const isUpdatingSubscription = useSelector(
    subscriptionModel.selectors.selectIsUpdatingSubscription
  );
  const currentPlan = useCurrentPlan();
  const { isHasRenewPromoCode } = useSubscriptionInfo();

  const handleRenew = () => {
    dispatch(
      subscriptionModel.actions.updateSubscription({
        teamId,
        isUpdatePaymentMethod: false,
        isCancelAtPeriodEnd: false,
        stripePromoCodeId: isHasRenewPromoCode
          ? subscriptionRenewPromoCode?.stripePromoCodeId
          : undefined,
        cb: onClose,
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Renew ${currentPlan.name} Plan?`}
      actionButton={
        <Button
          fullWidth
          onClick={handleRenew}
          color="primary"
          variant="text"
          loading={isUpdatingSubscription}
        >
          Renew
        </Button>
      }
    >
      <div className="text-sm text-gray-600">
        This plan will no longer be canceled. It will renew on{' '}
        {moment(stripeSubscription?.currentPeriodEnd).format('MMMM D, YYYY')}.
      </div>
    </Modal>
  );
};
