import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import format from 'string-template';
import {
  GeneratePageForm,
  GetCreatePageWithAIPromptProps,
  PageCreationDataSourcesEnum,
  PromptsConfigType,
  TranscriptsFilterTypesEnum,
} from './types';
import { FormattedGongTranscript } from '@distribute/shared/api-types/gong';

const PROMPTS_CONFIG: PromptsConfigType = {
  [CreateWithAIPageType.BUSINESS_CASE]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Business Case Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Business Page should include:
      - Introduction: Overview of the business problem that requires a solution.
      - Problem Statement: A detailed description of the problem including how it impacts the business.
      - Proposed Solution: Outline of the proposed product/service that could solve the problem.
      - Benefits/ROI: Detailed benefits of implementing the proposed solution including ROI and TCO calculations.
      - Implementation Timeline: A proposed timeline for the solution roll-out.
      - Conclusion: Summary and next steps.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Business Case Page for {companyName}, which is known for {aboutCompany}.
    Structure the page using the points from presented information:
    {context}
    The Business Page should include:
      - Introduction: Overview of the business problem that requires a solution.
      - Problem Statement: A detailed description of the problem including how it impacts the business.
      - Proposed Solution: Outline of the proposed product/service that could solve the problem.
      - Benefits/ROI: Detailed benefits of implementing the proposed solution including ROI and TCO calculations.
      - Implementation Timeline: A proposed timeline for the solution roll-out.
      - Conclusion: Summary and next steps.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.CALL_FOLLOW_UP]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Call Follow-up Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Call Follow-up Page should include:
      - Meeting Purpose: A brief overview of the call's purpose and the main topics discussed.
      - Key Takeaways: Important points and recommendations mentioned during the call.
      - Topics: Detailed breakdown of main points discussed, each with a header and supporting bullet points.
      - Next Steps: Suggested follow-up actions or meetings.
    Be friendly and professional. Your goal is to create a page content that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Call Follow-up Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with a client that needs to be documented.
    Structure the page using the points from presented information:
    {context}
    The Call Follow-up Page should include:
      - Meeting Purpose: A brief overview of the call's purpose and the main topics discussed.
      - Key Takeaways: Important points and recommendations mentioned during the call.
      - Topics: Detailed breakdown of main points discussed, each with a header and supporting bullet points.
      - Next Steps: Suggested follow-up actions or meetings.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.POST_DEMO_FOLLOW_UP]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Post Demo Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Post Demo Page should include:
      - Introduction: Recap the demo and its purpose.
      - Key Highlights: Present the standout features or aspects demonstrated.
      - Answered Queries: Respond to questions raised during the demo.
      - Additional Information: Include any more details or resources concerning the product/service.
      - Next steps: Propose subsequent actions like another meeting or a trial period.
      - Appreciation: Express gratitude for their time and interest.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Post Demo Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with a client that needs to be documented.
    Structure the page using the points from presented information:
    {context}
    The Post Demo Page should include:
      - Introduction: Recap the demo and its purpose.
      - Key Highlights: Present the standout features or aspects demonstrated.
      - Answered Queries: Respond to questions raised during the demo.
      - Additional Information: Include any more details or resources concerning the product/service.
      - Next steps: Propose subsequent actions like another meeting or a trial period.
      - Appreciation: Express gratitude for their time and interest.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.PROSPECTING_PAGE]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Prospecting Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with other clients and you want to use this conversations to explain your company benefits for your new prospect before a call.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Prospecting Page should include:
      - Introduction: Briefly introduce the purpose of the page and your company.
      - Value Proposition: Clearly state what your product/service offers to prospects.
      - Customer’s Pain Points: Identify common pain points that your product/service can resolve.
      - Product Benefits: Present a compelling list of the key benefits your product/service offers, focusing on the results the prospect can expect rather than just features. Use bullet points for readability.
      - Client Testimonials: Share testimonials from satisfied customers to generate trust and present proof of successful product usage.
      - Call to Action: Invite the prospect to engage further, such as arranging a meeting or a call.
      - Contact Information: Provide ways for the prospect to reach you.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of your discussions with clients, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Prospecting Page for {companyName}, which is known for {aboutCompany}.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Prospecting Page should include:
      - Introduction: Briefly introduce the purpose of the page and your company.
      - Value Proposition: Clearly state what your product/service offers to prospects.
      - Customer’s Pain Points: Identify common pain points that your product/service can resolve.
      - Product Benefits: Present a compelling list of the key benefits your product/service offers, focusing on the results the prospect can expect rather than just features. Use bullet points for readability.
      - Client Testimonials: Share testimonials from satisfied customers to generate trust and present proof of successful product usage.
      - Call to Action: Invite the prospect to engage further, such as arranging a meeting or a call.
      - Contact Information: Provide ways for the prospect to reach you.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.CASE_STUDY]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Case Study Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Case Study should include:
      - Introduction: Briefly introduce the client and the problem they faced.
      - Challenge: Detail the challenges the client faced before using your solution.
      - Solution: Detail how your product/service provided a solution.
      - Results: Describe the impact your solution had on the client’s business with quantifiable metrics.
      - Testimonial/Quote: Include a quote from the client about their experience.
      - Conclusion: A wrap-up of the case study and an invitation for readers to explore your product/service.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Case Study Page for {companyName}, which is known for {aboutCompany}.
    Structure the page using the points from presented information:
    {context}
    The Case Study should include:
      - Introduction: Briefly introduce the client and the problem they faced.
      - Challenge: Detail the challenges the client faced before using your solution.
      - Solution: Detail how your product/service provided a solution.
      - Results: Describe the impact your solution had on the client’s business with quantifiable metrics.
      - Testimonial/Quote: Include a quote from the client about their experience.
      - Conclusion: A wrap-up of the case study and an invitation for readers to explore your product/service.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.MUTUAL_ACTION_PLAN]: {
    transcriptPrompt: `{userName}, you're tasked with creating a Mutual Action Plan Page for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    The Mutual Action Plan Page should include:
      - Introduction: Outline the premise of the action plan.
      - Shared Vision: Detail the common objectives between your company and the customer.
      - Milestones: Specify key milestones with targeted completion dates.
      - Responsibilities: Assign tasks and responsibilities to respective parties.
      - Review Process: Clarify the plan's review and adaptation strategies.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a Mutual Action Plan Page for {companyName}, which is known for {aboutCompany}.
    Structure the page using the points from presented information:
    {context}
    The Mutual Action Plan Page should include:
      - Introduction: Outline the premise of the action plan.
      - Shared Vision: Detail the common objectives between your company and the customer.
      - Milestones: Specify key milestones with targeted completion dates.
      - Responsibilities: Assign tasks and responsibilities to respective parties.
      - Review Process: Clarify the plan's review and adaptation strategies.
    Be friendly and professional. Your goal is to create a page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
  [CreateWithAIPageType.OTHER]: {
    transcriptPrompt: `{userName}, you're tasked with creating a {pageTypeDescription}.
    Page is for {companyName}, which is known for {aboutCompany}.
    You've had a recent discussion with {prospectName} that needs to be documented.
    Structure the page using the points from your conversations, which are recorded in these transcripts ordered from oldest to the most recent:
    {context}
    Sound friendly and professional. Your goal is to create a structured page that is concise and captures the essence of your discussions with {prospectName}, while also highlighting {companyName}'s strengths.`,
    contentPrompt: `{userName}, you're tasked with creating a {pageTypeDescription}.
    Page is for {companyName}, which is known for {aboutCompany}.
    Structure the page using the points from presented information:
    {context}
    Sound friendly and professional. Your goal is to create a structured page that is concise and captures the essence of the provided content, while also highlighting {companyName}'s strengths.`,
  },
};

export const TranscriptsFilterDateMonths = {
  [TranscriptsFilterTypesEnum.LAST_MONTH]: 1,
  [TranscriptsFilterTypesEnum.LAST_3_MONTHS]: 3,
  [TranscriptsFilterTypesEnum.LAST_6_MONTHS]: 6,
  [TranscriptsFilterTypesEnum.LAST_YEAR]: 12,
};

function getSelectedCallTranscripts(
  data: GeneratePageForm,
  transcripts: FormattedGongTranscript[]
): string[] {
  const transcriptsFilteredByCaller = transcripts.filter(
    (item) =>
      !!item.parties.find((party) => party.name === data.selectedCallerName)
  );

  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.ALL_AVAILABLE) {
    return transcriptsFilteredByCaller.map((item) => item.transcript);
  }

  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.SPECIFIC) {
    return data.selectedTranscripts
      .map(
        (id) => transcripts.find((item) => item.metaData.id === id)?.transcript
      )
      .filter(Boolean) as string[];
  }
  if (data.transcriptsFilter === TranscriptsFilterTypesEnum.LAST_CALL) {
    return [transcriptsFilteredByCaller[0]?.transcript];
  }

  const pastDate = new Date();
  pastDate.setMonth(
    pastDate.getMonth() - TranscriptsFilterDateMonths[data.transcriptsFilter]
  );

  return transcriptsFilteredByCaller
    .filter((item) => item.metaData.started > pastDate)
    .map((item) => item.transcript);
}

export function getPageGenerationContext(
  data: GeneratePageForm,
  transcripts: FormattedGongTranscript[] | null
) {
  if (data.selectedDataSource === PageCreationDataSourcesEnum.GONG) {
    return getSelectedCallTranscripts(
      data,
      transcripts as FormattedGongTranscript[]
    );
  } else if (data.selectedDataSource === PageCreationDataSourcesEnum.FILE) {
    return data.files.map((file) => file.data);
  } else if (data.selectedDataSource === PageCreationDataSourcesEnum.LINK) {
    return data.links.map((link) => link.data as string);
  } else {
    return [data.text];
  }
}

export function getCreatePageWithAIPrompt({
  pageType,
  isTranscript,
  userName,
  companyName,
  aboutCompany,
  pageTypeDescription,
  pageTitle,
  prospectName,
  context,
  shouldEmbed,
}: GetCreatePageWithAIPromptProps) {
  const formattedPrompt = format(
    PROMPTS_CONFIG[pageType][
      isTranscript ? 'transcriptPrompt' : 'contentPrompt'
    ],
    {
      userName,
      companyName,
      aboutCompany,
      pageTypeDescription,
      pageTitle,
      prospectName,
      context: context
        .map((item, index) => (!shouldEmbed ? `"${item}"` : `"{${index}}"`))
        .join(',\n'),
    }
  );
  return formattedPrompt;
}
