import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';

export type SearchCompanyBrandsAction = {
  searchQuery: string;
};

export type ParseFileAction = {
  file: File;
  cb(data: string | null): void;
};

export type ParseLinkAction = {
  link: string;
  cb(data: string | null): void;
};

export enum CreatePageStepsEnum {
  INITIAL = 'initial',
  BRANDING = 'branding',
  TITLE = 'title',
  TEMPLATE = 'template',
  AI = 'ai',
}

export enum PageCreationWorkflowsEnum {
  FROM_SCRATCH = 'fromScratch',
  FROM_TEMPLATE = 'fromTemplate',
  WITH_AI = 'withAI',
}

export enum PageCreationDataSourcesEnum {
  TEXT = 'text',
  FILE = 'file',
  LINK = 'link',
  GONG = 'gong',
}

export enum TranscriptsFilterTypesEnum {
  LAST_CALL = 'lastCall',
  LAST_MONTH = 'lastMonth',
  LAST_3_MONTHS = 'last3Months',
  LAST_6_MONTHS = 'last6Months',
  LAST_YEAR = 'lastYear',
  ALL_AVAILABLE = 'allAvailable',
  SPECIFIC = 'specific',
}

export enum LinkTypeForAIStatusEnum {
  NOT_PARSED_YET = 'not-parsed-yet',
  PARSING = 'parsing',
  PARSED = 'parsed',
  ERROR = 'error',
}

export type LinkTypeForAI = {
  url: string;
  data: string | null;
  status: LinkTypeForAIStatusEnum;
};

export type FileTypeForAI = {
  name: string;
  size: number;
  type: string;
  data: string;
};

export type GeneratePageForm = {
  title: string;
  pageType: CreateWithAIPageType;
  transcriptsFilter: TranscriptsFilterTypesEnum;
  pageTypeDescription: string;
  selectedCallerName: string;
  selectedTranscripts: string[];
  selectedDataSource: PageCreationDataSourcesEnum | null;
  text: string;
  links: LinkTypeForAI[];
  files: FileTypeForAI[];
};

export type GetCreatePageWithAIPromptProps = {
  pageType: CreateWithAIPageType;
  userName: string;
  companyName?: string;
  aboutCompany?: string;
  pageTitle?: string;
  prospectName?: string;
  pageTypeDescription?: string;
  isTranscript: boolean;
  shouldEmbed: boolean;
  context: string[];
};

export type PromptsConfigType = {
  [key in CreateWithAIPageType]: {
    transcriptPrompt: string;
    contentPrompt: string;
  };
};
