import {
  GetTeamResponseType,
  UpdateCurrentTeamMemberResponseType,
} from '@distribute/shared/api-types/team';
import { api } from './instance';
import {
  GetCallTranscriptsDataRequestType,
  GetGongAccessTokenDataRequestType,
  RefreshGongAccessTokenDataRequestType,
} from '@distribute/shared/api-types/gong';

export const gongApi = {
  async getCallTranscripts(requestData: GetCallTranscriptsDataRequestType) {
    const { data } = await api.post('gong/transcripts', requestData);
    return data;
  },

  async getAccessToken({
    code,
    redirectUri,
    clientId,
  }: GetGongAccessTokenDataRequestType) {
    const { data } = await api.post('gong/access-token', {
      code,
      clientId,
      redirectUri,
    });
    return data;
  },

  async refreshAccessToken({
    clientId,
    refreshToken,
  }: RefreshGongAccessTokenDataRequestType) {
    const { data } = await api.post('gong/refresh-token', {
      clientId,
      refreshToken,
    });
    return data;
  },

  async generateApiKey(
    teamId: number
  ): Promise<UpdateCurrentTeamMemberResponseType> {
    const { data } = await api.post<UpdateCurrentTeamMemberResponseType>(
      `gong/api-key/${teamId}`
    );

    return data;
  },

  deleteApiKey(teamId: number) {
    return api.delete(`gong/api-key/${teamId}`);
  },

  async createGongTeamSecrets(accessToken: string, teamId: number) {
    const { data } = await api.post<GetTeamResponseType>(
      `gong/team-access-token/${teamId}`,
      {
        accessToken,
      }
    );
    return data;
  },

  async deleteGongTeamSecrets(teamId: number) {
    const { data } = await api.delete<GetTeamResponseType>(
      `gong/team-access-token/${teamId}`
    );

    return data;
  },
};
