import React, { FC, useMemo } from 'react';
import {
  UserPermissionToPageRole,
  UserPermissionToPage,
  Page,
} from '@distribute/shared/types';
import { Avatar, Dropdown, Icon } from '../../../../shared/ui';
import { usePagePermissions } from '../../hooks';
import { IconMap } from '../../../../shared/sprite';
import { authUserModel } from '../../../../entities/auth-user';
import { useSelector } from 'react-redux';

type IProps = {
  page: Page;
  userPermission: UserPermissionToPage;
  isGuest?: boolean;
  onChangeUserPermission: (
    role: UserPermissionToPageRole,
    userPermissionId: number
  ) => void;
  onDeleteUserPermission: (userPermissionId: number) => void;
  onResendUserPermissionInvitation: (userPermissionId: number) => void;
};

export const UserPermissionRow: FC<IProps> = ({
  page,
  userPermission,
  isGuest,
  onChangeUserPermission,
  onDeleteUserPermission,
  onResendUserPermissionInvitation,
}) => {
  const pagePermissions = usePagePermissions(page);
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const options = useMemo(
    () =>
      [
        {
          id: UserPermissionToPageRole.OWNER,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">
                Owner (full access)
              </p>
              <p className="text-xs text-gray-500">
                Can edit, share, manage, view analytics, etc.
              </p>
            </div>
          ),
          headerLabel: 'Owner',
          isShow:
            pagePermissions.isCanTransferOwnership &&
            !isGuest &&
            !userPermission.isWaitingForInviteAcceptance,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToPageRole.OWNER,
              userPermission.id
            ),
        },
        {
          id: UserPermissionToPageRole.EDITOR,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">Edit</p>
              <p className="text-xs text-gray-500">
                Can view, share, edit, but not manage.
              </p>
            </div>
          ),
          headerLabel: 'Can edit',
          isShow: true,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToPageRole.EDITOR,
              userPermission.id
            ),
        },
        {
          id: UserPermissionToPageRole.VIEWER,
          label: (
            <div>
              <p className="text-sm font-medium text-gray-700 mb-0.5">View</p>
              <p className="text-xs text-gray-500">Can view only</p>
            </div>
          ),
          headerLabel: 'Can view',
          isShow: true,
          onClick: () =>
            onChangeUserPermission(
              UserPermissionToPageRole.VIEWER,
              userPermission.id
            ),
        },
        {
          id: 'resend',
          label: (
            <div className="flex gap-2 items-center text-gray-700">
              <Icon width={16} glyph={IconMap.Mail01} />
              <p className="text-sm font-medium">Resend invite</p>
            </div>
          ),
          isSeparatedFromTop: true,
          isShow: userPermission.isWaitingForInviteAcceptance,
          onClick: () => onResendUserPermissionInvitation(userPermission.id),
        },
        {
          id: 'remove',
          label: (
            <div className="flex gap-2 items-center text-gray-700">
              <Icon width={16} glyph={IconMap.Trash} />
              <p className="text-sm font-medium">Delete</p>
            </div>
          ),
          isSeparatedFromTop: !userPermission.isWaitingForInviteAcceptance,
          isShow: true,
          onClick: () => onDeleteUserPermission(userPermission.id),
        },
      ].filter((i) => i.isShow),
    [
      pagePermissions.isCanTransferOwnership,
      userPermission.isWaitingForInviteAcceptance,
      userPermission.id,
      onChangeUserPermission,
      onDeleteUserPermission,
      onResendUserPermissionInvitation,
      isGuest,
    ]
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2 min-w-0">
        {userPermission.isWaitingForInviteAcceptance ? (
          <div className="w-10 h-10 rounded-full p-2 bg-base-black-4 flex items-center justify-center ">
            <Icon glyph={IconMap.Mail02} width={20} className="text-gray-600" />
          </div>
        ) : (
          <Avatar
            isUserAvatar
            displayName={userPermission.user?.displayName ?? ''}
            src={userPermission.user?.photoUrl}
            size="md"
            className="shrink-0"
          />
        )}
        <div className="overflow-hidden">
          <p className="text-gray-700 text-sm font-medium truncate mb-0.5">
            {userPermission.isWaitingForInviteAcceptance
              ? userPermission.user?.email ?? userPermission.externalUserEmail
              : userPermission.user?.displayName}
            {userPermission.user?.email === currentUser.email ? ' (You)' : ''}
            {isGuest && !userPermission.isWaitingForInviteAcceptance && (
              <p className="inline-block text-xs font-normal text-warning-600 bg-warning-100 px-1.5 py-px rounded-2.5xl ml-1.5">
                Guest
              </p>
            )}
          </p>
          <p className="text-xs text-gray-500">
            {userPermission.isWaitingForInviteAcceptance
              ? 'Invite sent'
              : userPermission.user?.email ?? userPermission.externalUserEmail}
          </p>
        </div>
      </div>
      {userPermission.role === UserPermissionToPageRole.OWNER ? (
        <span className="text-sm font-semibold text-gray-600">Owner</span>
      ) : (
        <Dropdown
          isModalMode={false}
          listStyles="shadow-lg w-90"
          items={options}
          currentItemId={userPermission.role}
          triggerStyles="!w-auto !h-10 shrink-0 border-0 pr-0 !gap-2 !text-sm !font-semibold !text-gray-600"
        />
      )}
    </div>
  );
};
