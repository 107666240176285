import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { teamsModel, useTeamPermissions } from '../../teams';
import { useSubscriptionInfo } from './useSubscriptionInfo';
import { workspaceModel } from '../../workspace';
import { redirectActions } from '../../../entities/history';
import { IconMap } from '../../../shared/sprite';
import { AlertBarTitle } from '../../../widgets/alert-bar/AlertBarTitle';

const CARD_EXPIRE_SOON_ID = 'cardExpireSoon';
const PAYMENT_FAILED_ID = 'paymentFailed';
const TRIAL_ID = 'trialId';
const CONFIRM_PAYMENT_METHOD_ID = 'confirmPaymentMethod';

export const useSetWorkspaceAlertBarFromSubscription = () => {
  const dispatch = useDispatch();
  const { isCanManageSubscription } = useTeamPermissions();
  const { id: teamId, stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const {
    isCardExpireSoon,
    isPaymentFailed,
    isTrial,
    isNeedConfirmPaymentMethod,
  } = useSubscriptionInfo();
  const {
    isClosedAlertBarSubscriptionPaymentMethodExpireSoon,
    isClosedAlertBarSubscriptionPaymentFailed,
    isClosedAlertBarSubscriptionTrial,
    isClosedAlertBarSubscriptionConfirmPaymentMethod,
  } = useSelector(teamsModel.selectors.selectUserTeamMemberDataWithError);
  const alertBar = useSelector(workspaceModel.selectors.selectAlertBar);

  useEffect(() => {
    if (
      (!isCardExpireSoon && alertBar?.id === CARD_EXPIRE_SOON_ID) ||
      (!isPaymentFailed && alertBar?.id === PAYMENT_FAILED_ID) ||
      (!isTrial && alertBar?.id === TRIAL_ID) ||
      (!isNeedConfirmPaymentMethod &&
        alertBar?.id === CONFIRM_PAYMENT_METHOD_ID)
    ) {
      dispatch(workspaceModel.actions.setAlertBarData());
    }
  }, [
    isCardExpireSoon,
    isPaymentFailed,
    isTrial,
    isNeedConfirmPaymentMethod,
    alertBar?.id,
    dispatch,
  ]);

  useEffect(() => {
    if (!isCanManageSubscription) return;

    if (isPaymentFailed && !isClosedAlertBarSubscriptionPaymentFailed) {
      dispatch(
        workspaceModel.actions.setAlertBarData({
          id: PAYMENT_FAILED_ID,
          btnText: 'Go to Plans and Billing',
          theme: 'error',
          title: (
            <AlertBarTitle
              title="Your payment failed to go through. Please update your payment method and try again."
              icon={IconMap.AlertCircle}
            />
          ),
          onClickBtn: () => {
            dispatch(redirectActions.toSubscriptionsClick());
          },
          onClose: () => {
            dispatch(
              teamsModel.actions.updateCurrentTeamCurrentMember({
                teamId,
                isClosedAlertBarSubscriptionPaymentFailed: true,
              })
            );
          },
        })
      );
      return;
    }

    if (
      isCardExpireSoon &&
      !isClosedAlertBarSubscriptionPaymentMethodExpireSoon
    ) {
      dispatch(
        workspaceModel.actions.setAlertBarData({
          id: CARD_EXPIRE_SOON_ID,
          btnText: 'Go to Plans and Billing',
          theme: 'warning',
          title: (
            <AlertBarTitle
              title="Your credit card will expire soon. Please update your payment
          method before next subscription payment."
              icon={IconMap.AlertTriangle}
            />
          ),
          onClickBtn: () => {
            dispatch(redirectActions.toSubscriptionsClick());
          },
          onClose: () => {
            dispatch(
              teamsModel.actions.updateCurrentTeamCurrentMember({
                teamId,
                isClosedAlertBarSubscriptionPaymentMethodExpireSoon: true,
              })
            );
          },
        })
      );
      return;
    }

    if (
      isNeedConfirmPaymentMethod &&
      !isClosedAlertBarSubscriptionConfirmPaymentMethod
    ) {
      dispatch(
        workspaceModel.actions.setAlertBarData({
          id: CONFIRM_PAYMENT_METHOD_ID,
          btnText: 'Go to Plans and Billing',
          theme: 'warning',
          title: (
            <AlertBarTitle
              title="Please confirm or update your payment method to finish ownership transferring"
              icon={IconMap.AlertTriangle}
            />
          ),
          onClickBtn: () => {
            dispatch(redirectActions.toSubscriptionsClick());
          },
          onClose: () => {
            dispatch(
              teamsModel.actions.updateCurrentTeamCurrentMember({
                teamId,
                isClosedAlertBarSubscriptionConfirmPaymentMethod: true,
              })
            );
          },
        })
      );
      return;
    }

    if (isTrial && !isClosedAlertBarSubscriptionTrial) {
      const trialDaysCount = moment(stripeSubscription?.trialEnd).diff(
        moment().hours(0).minutes(0).seconds(0),
        'days'
      );
      dispatch(
        workspaceModel.actions.setAlertBarData({
          id: TRIAL_ID,
          btnText: 'Go to Plans and Billing',
          theme: 'success',
          title: (
            <AlertBarTitle
              title={`You have ${trialDaysCount} days left on your rapid trial!`}
              icon={IconMap.Rocket02}
            />
          ),
          onClickBtn: () => {
            dispatch(redirectActions.toSubscriptionsClick());
          },
          onClose: () => {
            dispatch(
              teamsModel.actions.updateCurrentTeamCurrentMember({
                teamId,
                isClosedAlertBarSubscriptionTrial: true,
              })
            );
          },
        })
      );
      return;
    }
  }, [
    teamId,
    isCanManageSubscription,
    stripeSubscription?.trialEnd,
    isCardExpireSoon,
    isPaymentFailed,
    isTrial,
    isNeedConfirmPaymentMethod,
    isClosedAlertBarSubscriptionPaymentFailed,
    isClosedAlertBarSubscriptionPaymentMethodExpireSoon,
    isClosedAlertBarSubscriptionTrial,
    isClosedAlertBarSubscriptionConfirmPaymentMethod,
    dispatch,
  ]);
};
