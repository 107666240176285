import { FC } from 'react';

import { Modal, Button, Input } from '../../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { recordingsModel } from '../../model';

type UpdateRecordSchema = {
  name: string;
};

const validationSchema = object().shape({
  name: string().required('Video Name is required'),
});

export const RecordRenameModal: FC = () => {
  const dispatch = useDispatch();

  const record = useSelector(recordingsModel.selectors.selectRecordToEdit);
  const isLoading = useSelector(
    recordingsModel.selectors.selectIsRecordEditLoading
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateRecordSchema>({
    defaultValues: {
      name: record?.name || '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onClose = () => {
    dispatch(recordingsModel.actions.setRecordIdToEdit(null));
  };

  const onSubmit = (data: UpdateRecordSchema) => {
    dispatch(recordingsModel.actions.updateRecord(data));
  };

  return (
    <Modal
      onClose={onClose}
      isOpen
      title="Rename Video"
      actionButton={
        <Button
          type="submit"
          fullWidth
          color="primary"
          variant="text"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Rename
        </Button>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <Input
            autoFocus
            label="Video Name"
            isError={Boolean(errors.name)}
            messageText={errors.name?.message}
            type="text"
            {...register('name')}
          />
        </div>
      </form>
    </Modal>
  );
};
