import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { navigate } from '../model/reducer';

export const Link: typeof RouterLink = ({ to, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <RouterLink
      to={to}
      onClick={(e) => {
        e.preventDefault();
        dispatch(navigate({ to: to as string }));
      }}
      className={rest.className}
    >
      {rest.children}
    </RouterLink>
  );
};
