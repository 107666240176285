import { actions } from '../reducer';
import { put } from 'redux-saga/effects';

import { pagesModel } from '../index';
import { history } from '../../../../entities/history';
import { getQueryParams } from '../../../../shared/lib';
import { QUERY_PARAM_CURRENT_OPENED_TAB } from '../../config';

export function* setCurrentContentItem({
  payload: { contentItem, shouldUpdateQuery = true },
}: ReturnType<typeof pagesModel.actions.setCurrentContentItem>) {
  if (shouldUpdateQuery) {
    const searchParams = getQueryParams();
    searchParams.set(QUERY_PARAM_CURRENT_OPENED_TAB, String(contentItem.id));
    history.replace({ search: searchParams.toString() });
  }
  yield put(actions.setCurrentContent(contentItem));
}
