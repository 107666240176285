import { RootState } from '../../../app';

export const selectFolders = ({ publicTemplates: { folders } }: RootState) =>
  folders;

export const selectLoadingFoldersForTeamId = ({
  publicTemplates: { loadingFoldersForTeamId },
}: RootState) => loadingFoldersForTeamId;

export const selectIsDuplicatingPage = ({
  publicTemplates: { isDuplicatingPage },
}: RootState) => isDuplicatingPage;
