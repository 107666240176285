import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { Button, Icon, Input } from '../../../../shared/ui';
import {
  BlockingMethodType,
  CTA,
  GatedContent as GatedContentType,
} from '@distribute/shared/types';
import { gatedContentModel } from '../../model';
import { GatedContentEdited } from '../../../conversion-kit/model/types';
import { IconMap } from '../../../../shared/sprite';
import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { conversionKitModel } from '../../../conversion-kit';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../../editor-sidebar';
import { parseHtml } from '../../../../shared/lib/parseHtml';
import { ActionBanner } from '../../../action-banner';

type SubscribeGatedContentForm = {
  name: string;
  email: string;
  phone: string;
};

type Props = {
  gatedContent: GatedContentType | GatedContentEdited;
};

export const GatedContent: React.FC<Props> = ({ gatedContent }) => {
  const isBlockShows = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );
  const activeConversionSettingsPanel = useSelector(
    editorSidebarModel.selectors.selectActiveConversionSettingsPanel
  );
  const isEditing =
    activeConversionSettingsPanel ===
    ActiveConversionSettingsPanel.GATED_CONTENT;

  const {
    isDescriptionEnabled,
    description,
    pagePercentage,
    title,
    isFormName,
    isFormPhone,
    buttonLabelFormType,
    blockingMethod,
  } = gatedContent as GatedContentEdited;

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().when([], {
          is: () => isFormName,
          then: string(),
          otherwise: string().nullable(),
        }),
        email: string().email().required(),
        phone: string().when([], {
          is: () => isFormPhone,
          then: string(),
          otherwise: string().nullable(),
        }),
      }),
    [isFormName, isFormPhone]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubscribeGatedContentForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = useCallback((data: SubscribeGatedContentForm) => {
    return data;
  }, []);

  const { editor } = useTiptapEditor();

  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();

  const [blockedContentPercentage, setBlockedContentPercentage] = useState(
    pagePercentage || 0
  );
  const [mouseOffset, setMouseOffset] = useState(0);

  useEffect(() => {
    if (isBlockShows) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ block: 'end' });
      }, 100);
    }
  }, [isBlockShows]);

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const mouseMoveListener = (e: MouseEvent) => {
      if (isDragging) {
        const offset = e.clientY - mouseOffset;
        if (Math.abs(offset) <= 5) {
          return;
        }
        setMouseOffset(e.clientY);
        const percentageOffset =
          (offset * 100) / (editor?.view.dom.scrollHeight || 0);

        setBlockedContentPercentage((prev) => {
          const newOffset = prev - percentageOffset;
          if (newOffset >= 100) {
            return 100;
          }
          if (newOffset <= 0) {
            return 0;
          }
          return newOffset;
        });
      }
    };
    const mouseUpListener = () => {
      setIsDragging(false);
    };
    document.addEventListener('mousemove', mouseMoveListener);
    document.addEventListener('mouseup', mouseUpListener);
    return () => {
      document.removeEventListener('mouseup', mouseUpListener);
      document.removeEventListener('mousemove', mouseMoveListener);
    };
  }, [editor?.view.dom.scrollHeight, isDragging, mouseOffset]);

  useEffect(() => {
    if (editor && isBlockShows) {
      if (blockingMethod === BlockingMethodType.REMOVAL) {
        const visiblePercentage = 100 - blockedContentPercentage;
        const visibleEditorPixels =
          (editor.view.dom.scrollHeight * visiblePercentage) / 100;

        const formHeight = formRef.current?.clientHeight || 0;

        editor.view.dom.style.overflow = 'hidden';
        editor.view.dom.style.height = `${visibleEditorPixels + formHeight}px`;
      } else {
        editor.view.dom.style.overflow = 'auto';
        editor.view.dom.style.height = 'auto';
      }
    }

    return () => {
      if (editor) {
        editor.view.dom.style.overflow = 'auto';
        editor.view.dom.style.height = 'auto';
      }
    };
  }, [
    blockedContentPercentage,
    editor,
    blockingMethod,
    isBlockShows,
    isFormName,
    isFormPhone,
    description,
    isDescriptionEnabled,
  ]);

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.updateGatedContentPercentage(
        blockedContentPercentage
      )
    );
  }, [dispatch, blockedContentPercentage, isFormName, isFormPhone]);

  useEffect(() => {
    if (gatedContent.pagePercentage === null && isBlockShows) {
      const editorHeight = editor?.view.dom.scrollHeight || 0;
      const editorTopOffset = editor?.view.dom.offsetTop || 0;
      const formHeight = formRef.current?.clientHeight || 0;
      const bottomPaddingOffset = 40;
      const availableViewHeight =
        window.innerHeight - editorTopOffset - formHeight - bottomPaddingOffset;

      let percentage = 100;
      if (editorHeight > availableViewHeight) {
        percentage = 100 - (availableViewHeight * 100) / editorHeight;
      }

      setBlockedContentPercentage(percentage);
    }
  }, [
    dispatch,
    editor?.view.dom.scrollHeight,
    editor?.view.dom.offsetTop,
    gatedContent.pagePercentage,
    isBlockShows,
  ]);

  const [removalBlockingDragLineBottom, setRemovalBlockingDragLineBottom] =
    useState(formRef.current?.clientHeight || 0);

  useEffect(() => {
    setRemovalBlockingDragLineBottom(formRef.current?.clientHeight || 0);
  }, [gatedContent]);

  const [blockHeight, setBlockHeight] = useState(
    blockingMethod === BlockingMethodType.REMOVAL
      ? editor?.view.dom.clientHeight
      : editor?.view.dom.scrollHeight
  );

  useEffect(() => {
    setBlockHeight(
      blockingMethod === BlockingMethodType.REMOVAL
        ? editor?.view.dom.clientHeight
        : editor?.view.dom.scrollHeight
    );
  }, [
    blockingMethod,
    editor?.view.dom.clientHeight,
    editor?.view.dom.scrollHeight,
    isBlockShows,
    isFormName,
    isFormPhone,
    description,
    isDescriptionEnabled,
  ]);

  useEffect(() => {
    return () => {
      dispatch(gatedContentModel.actions.setIsModalOpen(false));
    };
  }, [dispatch]);

  const cta = useSelector(conversionKitModel.selectors.selectCTA) as CTA;
  const ctaEdited = useSelector(conversionKitModel.selectors.selectCTAEdited);

  if (!isBlockShows) {
    return null;
  }

  const isShowDescription =
    typeof isDescriptionEnabled === 'boolean'
      ? isDescriptionEnabled
      : !!description;

  return (
    <div
      style={{
        height: `${blockHeight}px`,
        top: `${editor?.view.dom.offsetTop}px`,
      }}
      className={classNames('absolute left-0 w-full flex z-10 flex-col')}
      ref={containerRef}
    >
      <div
        style={{
          bottom:
            blockingMethod === BlockingMethodType.REMOVAL
              ? `${removalBlockingDragLineBottom}px`
              : `${blockedContentPercentage}%`,
          width: `${editor?.view.dom.clientWidth}px`,
        }}
        className={classNames(
          'absolute h-0.5 left-1/2 transform -translate-x-1/2 z-20 rounded-md bg-primary-500 shadow-drag',
          {
            hidden: !isEditing,
          }
        )}
      >
        <button
          onMouseDown={(e) => {
            setMouseOffset(e.clientY);
            setIsDragging(true);
          }}
          className="absolute active:cursor-grabbing text-primary-700 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-grab w-6 h-6 rounded-2.5 border border-gray-75 bg-base-white z-10 flex items-center justify-center shadow-drag-button"
        >
          <Icon glyph={IconMap.ChevronSelectorVertical} />
        </button>
      </div>
      <div
        style={{
          background: `rgba(255,255,255, ${
            blockingMethod === BlockingMethodType.OBSCURATION ? '0.75' : '1'
          })`,
          height:
            blockingMethod === BlockingMethodType.REMOVAL
              ? 'auto'
              : `${blockedContentPercentage}%`,
        }}
        className="backdrop-blur relative z-10 flex flex-col -mb-px mt-auto"
      >
        <span
          style={{
            background:
              blockingMethod === BlockingMethodType.OBSCURATION
                ? 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.75) 100%'
                : 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 1) 100%)',
          }}
          className="flex absolute w-full h-20 transform -translate-y-full z-10"
        />
        <form
          ref={formRef}
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-3 mx-auto pb-16 w-full max-w-120 pt-10"
        >
          <div className="flex items-center text-center mb-3 flex-col gap-2">
            <p className="text-display-sm font-bold font-heading">
              {title || 'Title'}
            </p>
            {isShowDescription && (
              <div className="text-gray-600 text-base font-paragraph">
                {parseHtml(description || 'Description')}
              </div>
            )}
          </div>
          {isFormName && (
            <Input
              {...register('name')}
              placeholder="Name"
              type="text"
              isError={!!errors.name}
              isBrandStyles
            />
          )}
          <Input
            {...register('email')}
            placeholder="Email"
            type="text"
            isError={!!errors.email}
            isBrandStyles
          />
          {isFormPhone && (
            <Input
              {...register('phone')}
              placeholder="Phone"
              type="text"
              isError={!!errors.phone}
              isBrandStyles
            />
          )}

          <Button type="submit" color="brand" variant="text" fontBold fullWidth>
            {buttonLabelFormType || 'Submit'}
          </Button>

          <div ref={ref}></div>
        </form>
        {blockingMethod === BlockingMethodType.OBSCURATION && (
          <ActionBanner cta={ctaEdited ?? cta} />
        )}
      </div>
    </div>
  );
};
