import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetSeoConfigurationAction,
  RemoveSeoConfigurationImageAction,
  SeoConfigurationType,
  UpdateSeoConfigurationAction,
  UploadSeoConfigurationImageAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type PageSettingsStatus = 'never' | 'pending' | 'error' | 'success';

type State = {
  seoConfiguration: SeoConfigurationType | undefined;
  updateSeoConfigurationIsLoading: boolean;
  isUploadImageLoading: boolean;
  uploadingImageProgress: number;
  isRemoveImageLoading: boolean;
  pageSettingsStatus: PageSettingsStatus;
};

const initialState: State = {
  seoConfiguration: undefined,
  updateSeoConfigurationIsLoading: false,
  isUploadImageLoading: false,
  uploadingImageProgress: 0,
  isRemoveImageLoading: false,
  pageSettingsStatus: 'never',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'pageSettings',
  initialState,
  reducers: {
    setSeoConfiguration: (
      state,
      { payload: seoConfiguration }: PayloadAction<SeoConfigurationType>
    ) => ({
      ...state,
      seoConfiguration,
    }),
    setUpdateSeoConfigurationIsLoading: (
      state,
      { payload: updateSeoConfigurationIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      updateSeoConfigurationIsLoading,
    }),
    setIsUploadImageLoading: (
      state,
      { payload: isUploadImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isUploadImageLoading,
    }),
    setUploadImageProgress: (
      state,
      { payload: uploadingImageProgress }: PayloadAction<number>
    ) => ({
      ...state,
      uploadingImageProgress,
    }),
    setIsRemoveImageLoading: (
      state,
      { payload: isRemoveImageLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      isRemoveImageLoading,
    }),
    setPageSettingsStatus: (
      state,
      { payload: pageSettingsStatus }: PayloadAction<PageSettingsStatus>
    ) => ({
      ...state,
      pageSettingsStatus,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  getSeoConfiguration: createAction<GetSeoConfigurationAction>(
    'pageSettings/getSeoConfiguration'
  ),
  updateSeoConfiguration: createAction<UpdateSeoConfigurationAction>(
    'pageSettings/updateSeoConfiguration'
  ),
  changeSeoConfigurationsImage: createAction<UploadSeoConfigurationImageAction>(
    'pageSettings/changeSeoConfigurationsImage'
  ),
  removeSeoConfigurationsImage: createAction<RemoveSeoConfigurationImageAction>(
    'pageSettings/removeSeoConfigurationsImage'
  ),
};
