import React, { FC, useMemo } from 'react';
import { Tag } from '../../../../shared/ui';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../../features/snippets';

export const SnippetStatusTag: FC = () => {
  const isSaved = useSelector(
    snippetsModel.selectors.selectIsSnippetSavedInEditor
  );

  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  );

  const isDraft = currentSnippet?.isDraft;

  const tagConfig = useMemo(() => {
    if (isDraft) {
      return {
        text: 'Draft',
        color: 'gray',
        borderColor: 'border-gray-200',
      };
    }
    if (isSaved) {
      return {
        text: 'Saved',
        color: 'success',
        borderColor: 'border-success-200',
      };
    }
    return {
      text: 'Unsaved changes',
      color: 'blue',
      borderColor: 'border-blue-200',
    };
  }, [isSaved, isDraft]);

  return (
    <Tag
      className={`!py-0.25 !px-2.5 rounded-full font-medium border ${tagConfig.borderColor}`}
      color={tagConfig.color}
    >
      {tagConfig.text}
    </Tag>
  );
};
