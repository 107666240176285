import Table, { TableOptions } from '@tiptap/extension-table';

import { mergeAttributes } from '@tiptap/react';

export const CustomTableExt = Table.extend<TableOptions>({
  addAttributes() {
    return {
      ...this.parent?.(),
      selected: {
        default: false,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    const { selected: _, ...restHTMLAttributes } = HTMLAttributes;

    return [
      'div',
      mergeAttributes({
        class: 'custom-table-wrapper',
      }),
      [
        'table',
        mergeAttributes(this.options.HTMLAttributes, restHTMLAttributes, {
          class: 'custom-table',
        }),
        ['tbody', 0],
      ],
    ];
  },
});
