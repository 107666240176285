import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { Page } from '@distribute/shared/types';

export function* updatePages({
  payload,
}: ReturnType<typeof pagesModel.actions.updatePages>) {
  try {
    if (payload) {
      const pages: Page[] = yield select(pagesModel.selectors.selectPages);

      const newPages = pages.map((page) =>
        page.id === payload.id ? payload : page
      );

      yield put(pagesModel.actions.setPages(newPages));
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Can't update pages due to error`)
      )
    );
  }
}
