import { put, select } from 'redux-saga/effects';
import { pagesModel } from '..';
import { pagesApi } from '../../../../shared/api';
import { Page, UserPermissionToPageRole } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateUserPermission({
  payload: { pageId, userPermissionId, role },
}: ReturnType<typeof pagesModel.actions.updateUserPermission>) {
  try {
    const page: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    yield pagesApi.updateUserPermission(pageId, userPermissionId, { role });

    let userPermissionToPages = page.userPermissionToPages;

    if (role === UserPermissionToPageRole.OWNER) {
      userPermissionToPages = userPermissionToPages.filter(
        (i) => i.role !== UserPermissionToPageRole.OWNER
      );
    }

    userPermissionToPages = userPermissionToPages.map((userPermission) => {
      if (userPermission.id === userPermissionId) {
        return { ...userPermission, role };
      }

      return userPermission;
    });

    yield put(
      pagesModel.actions.setCurrentPage({ ...page, userPermissionToPages })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'The user permission has not been updated')
      )
    );
  }
}
