import { JSONContent } from '@tiptap/react';

type CreateVideoRecordAttrs = {
  prefix: string;
  playbackId: string;
  aspectRatio: string;
};

export const JSONContentFactory = {
  DEFAULT(): JSONContent {
    return JSONContentFactory.createDoc([JSONContentFactory.createParagraph()]);
  },
  createDoc(content?: JSONContent[]): JSONContent {
    return { type: 'doc', content };
  },
  createParagraph(content?: JSONContent[]): JSONContent {
    return {
      type: 'paragraph',
      attrs: {
        textAlign: 'left',
      },
      content,
    };
  },
  createVideoRecord(attrs: CreateVideoRecordAttrs): JSONContent {
    return {
      type: 'resizeableFigure',
      attrs: {
        'data-media-width': 1008, // max container width
        'data-media-height': 'auto',
        'data-align': 'center',
      },
      content: [
        {
          type: 'videoRecord',
          attrs,
        },
      ],
    };
  },
};
