import { put } from 'redux-saga/effects';
import { viewersModel } from '../';

export function* onChangeCategory({
  payload: category,
}: ReturnType<typeof viewersModel.actions.onChangeCategory>) {
  yield put(viewersModel.actions.setCategory(category));
  yield put(viewersModel.actions.setSearch(''));
  yield put(viewersModel.actions.setPage(1));
  yield put(viewersModel.actions.setRange({}));
  yield put(viewersModel.actions.setDetails({}));

  yield put(viewersModel.actions.getViewersData({}));
}
