import { Location } from '@distribute/shared/types';

export const formatLocation = (location: Location | null) => {
  if (!location) {
    return '';
  }

  const { stateCode, countryName, stateProv, city } = location;

  return stateCode
    ? [countryName, city, stateCode].filter(Boolean).join(', ')
    : [countryName, stateProv, city].filter(Boolean).join(', ');
};
