import { Snippet } from '@distribute/shared/types';
import { SnippetItem } from '../../../features/snippets/ui/components';
import { FC } from 'react';

type Props = {
  snippets: Snippet[];
};

export const SnippetsList: FC<Props> = ({ snippets }) => {
  return (
    <div className="flex flex-wrap 2xl:grid flex-grow gap-6 overflow-x-hidden overflow-y-auto 2xl:grid-cols-3 max1280:grid-cols-2 sm:grid-cols-1 place-content-start">
      {snippets.map((snippet) => (
        <SnippetItem key={snippet.id} snippet={snippet} />
      ))}
    </div>
  );
};
