import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import { FC } from 'react';

type PanelRadioButtonProps = {
  title: string;
  description: string;
  isActive: boolean;
  onChange: () => void;
  name: string;
};
export const PanelRadioButton: FC<PanelRadioButtonProps> = ({
  title,
  description,
  isActive,
  onChange,
  name,
}) => {
  return (
    <label
      onChange={onChange}
      className={cn(
        'rounded-xl flex items-start justify-between cursor-pointer p-4 group hover:border-success-600 hover:bg-primary-50',
        {
          'border-2 border-success-600 bg-primary-50': isActive,
          'border border-gray-200 bg-base-white': !isActive,
        }
      )}
    >
      <div className="">
        <p
          className={cn('font-medium text-sm group-hover:text-success-800', {
            'text-success-800': isActive,
            'text-gray-700': !isActive,
          })}
        >
          {title}
        </p>
        <p
          className={cn('text-sm group-hover:text-success-700', {
            'text-success-700': isActive,
            'text-gray-600': !isActive,
          })}
        >
          {description}
        </p>
      </div>
      <input name={name} type="radio" className="visually-hidden" />
      {isActive ? (
        <Icon
          glyph={IconMap.CheckGreenBg}
          width={16}
          className="flex-shrink-0"
        />
      ) : (
        <span className="w-4 h-4 border border-gray-300 rounded-full flex flex-shrink-0" />
      )}
    </label>
  );
};
type Option<T extends string | number> = {
  id: T;
  title: string;
  description: string;
};
type PanelRadioGroupProps<T extends string | number> = {
  options: Option<T>[];
  activeId: T;
  onChange: (value: T) => void;
  name: string;
};

export function PanelRadioGroup<T extends string | number>({
  options,
  activeId,
  onChange,
  name,
}: PanelRadioGroupProps<T>) {
  return (
    <div className="flex items-center gap-x-3">
      {options.map(({ title, description, id }) => (
        <PanelRadioButton
          key={id}
          title={title}
          description={description}
          isActive={activeId === id}
          name={name}
          onChange={() => onChange(id)}
        />
      ))}
    </div>
  );
}
