import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { IconMap } from '../../sprite';
import { Icon, SpriteGlyph } from '../Icon';

export type Item<T = ReactNode> = {
  id: string;
  title: T;
  iconName?: SpriteGlyph;
  description?: string;
};

type Props = {
  label: string;
  className?: string;
  labelClassName?: string;
  value?: string;
  currentValue?: Item;
  error?: string;
  errorMsg?: string;
  onChange: (id: string) => void;
  items: Item[];
  buttonStyles?: string;
  listStyles?: string;
  listItemStyles?: string;
  truncate?: boolean;
  hideLabel?: boolean;
};

export const Select: FC<Props> = ({
  label,
  value,
  currentValue,
  labelClassName,
  className,
  onChange,
  items,
  error,
  errorMsg,
  buttonStyles,
  listStyles,
  listItemStyles,
  truncate = true,
  hideLabel = false,
}) => {
  return (
    <>
      <div className={classNames('w-full relative h-17.5', className)}>
        {!hideLabel && (
          <p className="pb-1 text-sm font-medium text-gray-700">{label}</p>
        )}
        <div className="relative w-full rounded-lg">
          <Listbox value={value} onChange={onChange}>
            {!value && (
              <p className="absolute left-3.5 top-2.5 mt-px text-gray-500 pointer-events-none">
                Select
              </p>
            )}
            <Listbox.Button
              className={classNames(
                'mb-1 rounded-lg w-full border border-gray-300 bg-base-white flex items-center justify-between py-2.5 px-3.5 focus:border-primary-600 min-h-11',
                {
                  '!border-error-600': !!error,
                },
                buttonStyles
              )}
            >
              <p
                className={classNames(
                  'font-normal text-md text-gray-900 min-w-0 truncate max-w-[calc(100%-40px)] flex items-center gap-2',
                  labelClassName
                )}
              >
                {currentValue?.iconName && (
                  <Icon glyph={currentValue.iconName} width={20} />
                )}
                {currentValue?.title ?? value}
              </p>
              <Icon
                glyph={IconMap.ArrowDown}
                width={20}
                className="text-gray-500 ui-not-open:rotate-0 ui-open:rotate-180"
              />
            </Listbox.Button>
            <Listbox.Options
              className={classNames(
                'flex flex-col absolute z-50 w-full gap-y-2 p-2 shadow-lg max-h-50 overflow-auto border rounded-lg border-gray-200 bg-base-white',
                listStyles
              )}
            >
              {items.map((item) => (
                <Listbox.Option
                  className={classNames(
                    'cursor-pointer hover:bg-gray-50 rounded-md p-2 text-sm font-normal text-gray-700 flex flex-row justify-between',
                    listItemStyles
                  )}
                  key={item.id}
                  value={item.id}
                >
                  <div
                    className={classNames('flex items-center gap-2', {
                      'max-w-[calc(100%-40px)] truncate': truncate,
                    })}
                  >
                    {item?.iconName && (
                      <Icon glyph={item.iconName} width={20} />
                    )}
                    {item.title}
                    {item.description && (
                      <span className="text-gray-500 -ml-1.5">
                        {item.description}
                      </span>
                    )}
                  </div>
                  {item.title === currentValue?.title && (
                    <Icon glyph={IconMap.GreenCheckMark} width={20} />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      </div>
      {errorMsg && (
        <div className="mt-0.5 text-sm text-error-500">{errorMsg}</div>
      )}
    </>
  );
};
