import { call, put, select } from 'redux-saga/effects';
import { AnalyticsEvents, TeamFiltered } from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { analytics } from '../../../../entities/analytics';
import { SkipOnboardingStepSource } from '../types';
import { teamsModel } from '../../../teams';

export function* skipTeamOnboardingStep({
  payload: { teamOnboardingStatus, source },
}: ReturnType<typeof actions.skipTeamOnboardingStep>) {
  try {
    yield put(actions.setIsLoadingSkipTeamOnboardingStep(true));

    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const updatedTeam: TeamFiltered = yield call(
      teamsApi.updateTeam,
      currentTeam.id,
      {
        onboardingStatus: teamOnboardingStatus,
      }
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, updatedTeam);

    if (source === SkipOnboardingStepSource.INVITE_TEAM_MEMBERS) {
      analytics.track(AnalyticsEvents.INVITE_TEAMMATES_STEP_SKIPPED);
    }
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team')
      )
    );
  } finally {
    yield put(actions.setIsLoadingSkipTeamOnboardingStep(false));
  }
}
