import { call } from 'redux-saga/effects';

import { authModel } from '../../../entities/auth-operation';
import { APP_HOST, PROTOCOL } from '../../../shared/config';
import { authApi } from '../../../shared/api';
import { profileSettingModel } from '../../profile-settings';

export function* resetPassword(
  resetPasswordAction: ReturnType<
    typeof profileSettingModel.actions.resetPasswordClick
  >
) {
  const {
    payload: { email },
  } = resetPasswordAction;

  yield call(
    authModel.sagas.authOperationWithAlert,
    'Failed to send reset password email',
    function* () {
      yield authApi.sendResetPassword({
        email: email,
        redirectUrl: PROTOCOL + APP_HOST + '/complete-reset-password',
      });
    }
  );
}
