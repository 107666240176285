import { takeEvery } from 'redux-saga/effects';
import { teamsModel } from '..';
import { updateTeamPermissions } from './updateTeamPermissions';

export function* teamPermissionsWorker() {
  yield takeEvery(
    teamsModel.actions.updateTeamPermissions,
    updateTeamPermissions
  );
}
