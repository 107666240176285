import React, { Fragment, RefObject, forwardRef } from 'react';
import { Root, Content, Portal, Arrow } from '@radix-ui/react-popover';
import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';

type Props = {
  triggerComponent: React.ReactNode;
  open?: boolean;
  children: React.ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
  contentStyles?: string;
  side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
  handleClickCancel?: () => void;
  portalContainerElement?: HTMLElement | null;
  isShowArrow?: boolean;
  isRenderInPortal?: boolean;
  onOpenAutoFocus?: (e: Event) => void;
  onFocusOutside?: (e: Event) => void;
};

export const Popover = forwardRef<HTMLDivElement, Props>(function Popover(
  {
    children,
    onMouseLeave,
    contentStyles,
    side,
    sideOffset,
    align,
    open,
    handleClickCancel,
    triggerComponent,
    portalContainerElement,
    isShowArrow = false,
    onOpenAutoFocus,
    onFocusOutside,
    isRenderInPortal = true,
  },
  contentRef
) {
  const ContentWrapper = isRenderInPortal ? Portal : Fragment;
  const contentWrapperProps = isRenderInPortal
    ? { container: portalContainerElement }
    : {};

  return (
    <Root
      onOpenChange={(isOpen) => {
        if (!handleClickCancel) return;
        if (!isOpen) {
          handleClickCancel();
        }
      }}
      open={open}
    >
      {triggerComponent}
      <ContentWrapper {...contentWrapperProps}>
        <Content
          onFocusOutside={onFocusOutside}
          onOpenAutoFocus={onOpenAutoFocus}
          align={align}
          sideOffset={sideOffset}
          side={side}
          className={cn(
            contentStyles,
            'bg-base-white rounded-lg shadow-xl border border-gray-200'
          )}
          ref={contentRef}
          onMouseLeave={onMouseLeave}
        >
          {children}
          {isShowArrow && (
            <Arrow asChild>
              <div className="transform -rotate-90 -translate-y-1">
                <Icon glyph={IconMap.TooltipArrow} />
              </div>
            </Arrow>
          )}
        </Content>
      </ContentWrapper>
    </Root>
  );
});
