import cn from 'classnames';
import { Icon, Tooltip } from '../../../../../shared/ui';
import { SpriteGlyph } from '../../../../../shared/ui/Icon';
import { ReactNode } from 'react';

export type IProps = {
  id: string;
  icon: SpriteGlyph;
  tooltip: ReactNode;
  isActive: boolean;
  onClick: (id: string) => void;
};

export const CellAlignmentMenuItem = ({
  id,
  icon,
  tooltip,
  isActive,
  onClick,
}: IProps) => {
  return (
    <Tooltip
      triggerClassNames="grow-1"
      trigger={
        <div
          className={cn(
            'flex items-center justify-center py-2.25 bg-base-white cursor-pointer text-gray-700 hover:bg-gray-50',
            {
              '!bg-gray-50': isActive,
            }
          )}
          onClick={() => onClick(id)}
        >
          <Icon glyph={icon} width={20} />
        </div>
      }
    >
      {tooltip}
    </Tooltip>
  );
};
