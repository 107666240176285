import cn from 'classnames';

import { FC, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../../../../processes/navigation';
import { TabContent, Tabs } from '../../../../shared/ui';
import { QUICK_RECORDINGS_ROUTE } from '../../../../entities/history';
import { RecordsMy } from './RecordsMy';
import { RecordsShared } from './RecordsShared';

import { recordingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';

export const RecordsTabs: FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const locationTabName = location.pathname.split('/')[2];

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const hasRecordsShared = useSelector(
    recordingsModel.selectors.selectHasRecordsShared(currentUser.id)
  );
  const hasRecordsMy = useSelector(
    recordingsModel.selectors.selectHasRecordsMy(currentUser.id)
  );

  const hasRecordsMyOnly = hasRecordsMy && !hasRecordsShared;

  const tabs: TabContent[] = useMemo(
    () => [
      {
        name: 'my',
        title: 'My recordings',
        component: <RecordsMy />,
      },
      ...(hasRecordsShared
        ? [
            {
              name: 'shared',
              title: 'Shared with me',

              component: <RecordsShared />,
            },
          ]
        : []),
    ],
    [hasRecordsShared]
  );

  const [selectedTab, setSelectedTab] = useState(
    () => tabs.find((tab) => tab.name === locationTabName) || tabs[0]
  );

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => tab.name === locationTabName) || tabs[0]);
  }, [tabs, locationTabName]);

  return (
    <div className="flex flex-col flex-grow-1">
      <Tabs
        selectedTab={selectedTab}
        tabs={tabs}
        onSelectTab={(tab) =>
          dispatch(navigate({ to: `${QUICK_RECORDINGS_ROUTE}/${tab.name}` }))
        }
        className={cn('!mb-8 sm:!mb-6', { hidden: hasRecordsMyOnly })}
      >
        <BrowserRouter basename={QUICK_RECORDINGS_ROUTE}>
          <Switch>
            {tabs.map((tab) => {
              return (
                <Route
                  path={tab.name}
                  key={tab.name}
                  render={() => tab.component}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </Tabs>
    </div>
  );
};
