import React, { MouseEventHandler, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '../../../entities/auth-operation';
import {
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  redirectActions,
} from '../../../entities/history';
import {
  Input,
  Button,
  Link,
  SimpleLayout,
  ErrorBlock,
} from '../../../shared/ui';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getQueryParam } from '../../../shared/lib';
import {
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../../features/accept-team-invitation';
import { IconMap } from '../../../shared/sprite';

const validationSchema = object().shape({
  email: string().required('Email is required'),
  password: string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
});

type SignInForm = {
  email: string;
  password: string;
};

export const SignIn = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const authLoading = useSelector(authModel.selectors.selectLoading);
  const isWaitingToRedirectAfterAuth = useSelector(
    authModel.selectors.selectIsWaitingToRedirectAfterAuth
  );
  const emailFromTeamInvitationLink = useSelector(
    authModel.selectors.selectEmailFromTeamOrPageInvitationLink
  );
  const teamInviteId = getQueryParam(QUERY_PARAM_TEAM_INVITE_ID);
  const pageInviteId = getQueryParam(QUERY_PARAM_PAGE_INVITE_ID);
  const isLogInBtnLoading = authLoading || isWaitingToRedirectAfterAuth;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email:
        teamInviteId || pageInviteId ? emailFromTeamInvitationLink : undefined,
    },
  });

  useEffect(
    () => () => {
      dispatch(
        authModel.actions.setEmailFromTeamOrPageInvitationLink(undefined)
      );
    },
    [dispatch]
  );

  const handleFormSubmit = (data: SignInForm) => {
    dispatch(
      authModel.actions.signInWithEmailAndPasswordClick({
        email: data.email,
        password: data.password,
      })
    );
  };

  const handleSignUpClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dispatch(redirectActions.toRegisterClick());
  };

  const handleForgotPasswordClick: MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
    dispatch(authModel.actions.forgotPasswordClick());
  };

  const handleGoogleSignup = () => {
    dispatch(authModel.actions.googleSignupClick());
  };

  return (
    <>
      <Helmet title="Login" />
      <SimpleLayout>
        <div className="mb-6 flex justify-center items-center">
          <p className="text-display-md font-display font-medium text-center">
            Log in to your account
          </p>
        </div>

        {authError && <ErrorBlock error={authError} />}

        <div className="flex justify-center items-center">
          <Button
            variant="icon-text"
            color="secondary"
            className="w-full"
            iconLeftName={IconMap.Google}
            onClick={handleGoogleSignup}
          >
            Sign in with Google
          </Button>
        </div>

        <div className="flex items-center justify-between gap-4 my-5">
          <hr className="grow border-gray-200" />
          <p className="text-gray-700 font-medium text-sm">or</p>
          <hr className="grow border-gray-200" />
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <div className="mb-5">
            <Input
              {...register('email')}
              label="Email"
              type="email"
              isError={!!errors.email}
              disabled={!!emailFromTeamInvitationLink}
            />
          </div>
          <div className="mb-5">
            <Input
              label="Password"
              {...register('password')}
              type="password"
              isError={!!errors.password}
              messageText={errors.password?.message}
            />
          </div>

          <div className="mb-6">
            <Button
              variant="text"
              color="primary"
              disabled={isLogInBtnLoading}
              loading={isLogInBtnLoading}
              type="submit"
              fullWidth
            >
              Log In
            </Button>
          </div>
        </form>

        <div className="mb-6 flex justify-center items-center">
          <Link
            href={RESET_PASSWORD_ROUTE}
            size="medium"
            onClick={handleForgotPasswordClick}
          >
            Forgot password
          </Link>
        </div>
        {!emailFromTeamInvitationLink && (
          <div className="flex justify-center items-center">
            <span className="text-sm text-gray-600 mr-1">
              Don't have an account?
            </span>
            <Link
              href={REGISTER_ROUTE}
              size="medium"
              onClick={handleSignUpClick}
            >
              Sign Up
            </Link>
          </div>
        )}
      </SimpleLayout>
    </>
  );
};
