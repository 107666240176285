import { all, call, put, select } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  TeamFiltered,
  TeamOnboardingStatus,
} from '@distribute/shared/types';
import { teamsApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { onboardingModel } from '..';
import { teamMembersModel } from '../../../team-members';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';
import { teamsModel } from '../../../teams';

export function* inviteTeamMembers({
  payload: { emails, teamId },
}: ReturnType<typeof actions.inviteTeamMembers>) {
  try {
    yield put(onboardingModel.actions.setIsLoadingInviteTeamMembers(true));
    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    const [team]: [TeamFiltered, void] = yield all([
      call(teamsApi.updateTeam, teamId, {
        onboardingStatus: TeamOnboardingStatus.SUBSCRIPTION,
      }),
      call(teamMembersModel.sagas.inviteTeamMembersSaga, emails, teamId),
    ]);

    yield put(
      teamsModel.actions.setTeams(
        teams.map((i) => (i.id === teamId ? team : i))
      )
    );
    yield put(teamsModel.actions.setCurrentTeam(team));

    analytics.track(AnalyticsEvents.ONBOARDING_INVITATION_SENT, {
      teamId,
      invitedUserEmails: emails,
    });
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to send team invites')
        )
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsLoadingInviteTeamMembers(false));
  }
}
