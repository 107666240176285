import { takeEvery } from 'redux-saga/effects';
import { actions } from '../reducer';
import { resendInvite } from './resendInvite';
import { updateTeamMemberRole } from './updateTeamMemberRole';
import { deleteTeamMember } from './deleteTeamMember';
import { transferOwnership } from './transferOwnership';

export function* teamMembersPageWorker() {
  yield takeEvery(actions.resendInvite, resendInvite);
  yield takeEvery(actions.updateTeamMemberRole, updateTeamMemberRole);
  yield takeEvery(actions.deleteTeamMember, deleteTeamMember);
  yield takeEvery(actions.transferOwnership, transferOwnership);
}
