import { call, put } from 'redux-saga/effects';
import { AnalyticsEvents } from '@distribute/shared/types';

import { authModel } from '../../../entities/auth-operation';

import { auth } from '../../../shared/config/firebase';
import { FirebaseUserCredential } from '../../../shared/types';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { sendEmailVerification } from '../email-verification';

import { authApi } from '../../../shared/api';
import {
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../accept-team-invitation';
import { getQueryParam } from '../../../shared/lib';
import { analytics } from '../../../entities/analytics';
import { QUERY_PARAM_PARTNER_STACK_XID } from '../config';
import { redirectAfterSignUp } from '../helpers/redirectAfterSignUp';

export function* signUp(
  signUpAction: ReturnType<typeof authModel.actions.signUpClick>
) {
  const {
    payload: { email, password, fullName },
  } = signUpAction;

  yield call(
    authModel.sagas.authOperation,
    'Sorry, something went wrong. Please try again later',
    function* () {
      const partnerStackXID =
        getQueryParam(QUERY_PARAM_PARTNER_STACK_XID) ?? undefined;
      yield authApi.onRegister({
        email,
        password,
        displayName: fullName,
        partnerStackXID,
      });

      const userCredential: FirebaseUserCredential =
        yield signInWithEmailAndPassword(auth, email, password);

      if (!userCredential.user) {
        throw new Error('Something went wrong. User not created.');
      }

      analytics.track(AnalyticsEvents.SIGN_UP_COMPLETED);

      const teamInviteId = getQueryParam(QUERY_PARAM_TEAM_INVITE_ID);
      const pageInviteId = getQueryParam(QUERY_PARAM_PAGE_INVITE_ID);

      if (!teamInviteId && !pageInviteId) {
        yield call(sendEmailVerification);
      }

      yield put(authModel.actions.setIsWaitingToRedirectAfterAuth(true));
      yield call(redirectAfterSignUp, 'verify-email');
    }
  );
}
