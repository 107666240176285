import React, { FC } from 'react';
import { Modal, Button } from '../../../../shared/ui';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LeaveTeamNeedUpdateSubscriptionModal: FC<IProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      isShowCloseIconInTitle
      isShowCancelButton={false}
      onClose={onClose}
      isOpen={isOpen}
      title="Almost done!"
      actionButton={
        <Button onClick={onClose} fullWidth color="primary" variant="text">
          Got It
        </Button>
      }
      className="!w-120 sm:!w-full"
    >
      <div className="text-sm font-normal text-gray-600">
        The <b>team subscription payment method</b> needs to be confirmed or
        changed by the new owner before your leaving the team can be finalized.
      </div>
    </Modal>
  );
};
