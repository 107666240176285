import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { getNormalizedTextSnippetContent, setSnippetsData } from '../../lib';
import { isSwitchFromPersonalToTeamConfirmation } from '../../lib/helpers';
import { ConfigureSnippetFormType } from '../../ui/components/create-snippet/ConfigureSnippetForm';
import { SnippetType } from '@distribute/shared/types';

export function* updateSnippet({
  payload: { callback, snippet, isEditor = false, ...payloadData },
}: ReturnType<typeof snippetsModel.actions.updateSnippet>) {
  try {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(true));

    const { snippetId, ...rest } = payloadData;

    const isShowConfirmation = isSwitchFromPersonalToTeamConfirmation(
      snippet.sharingAccess,
      payloadData.sharingAccess,
      Boolean(snippet.isDraft)
    );

    const updatingSnippetData: ConfigureSnippetFormType | undefined =
      yield select(snippetsModel.selectors.selectUpdatingSnippetsData);

    if (isShowConfirmation && !updatingSnippetData) {
      yield put(snippetsModel.actions.setUpdatingSnippetData(payloadData));
      return;
    }

    const { content } = rest;

    if (snippet.type === SnippetType.TEXT && content) {
      const normalizedContent = getNormalizedTextSnippetContent(content);

      yield call(snippetsApi.updateSnippet, snippetId, {
        ...rest,
        content: normalizedContent,
      });
    } else {
      yield call(snippetsApi.updateSnippet, snippetId, { ...rest });
    }

    yield call(setSnippetsData);

    if (!isEditor) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('success', 'The snippet was updated')
        )
      );
    }

    yield put(snippetsModel.actions.setUpdatingSnippetData(undefined));
    yield put(snippetsModel.actions.setIsSnippetSavedInEditor(true));

    callback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update snippet')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsUpdateSnippetLoading(false));
  }
}
