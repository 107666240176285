import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ButtonTextAlignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export const ModalAlignment = {
  [ButtonTextAlignment.Left]: 'start',
  [ButtonTextAlignment.Center]: 'center',
  [ButtonTextAlignment.Right]: 'end',
} as const;

export const ButtonSizeVariants = {
  [ButtonSize.Small]: 'sm',
  [ButtonSize.Medium]: 'md',
  [ButtonSize.Large]: 'lg',
} as const;

export const ButtonTypeVariants = {
  [ButtonType.Primary]: 'primary',
  [ButtonType.Secondary]: 'secondary',
} as const;

type Variant = 'icon-only' | 'default';

const additionalButtonSizeStyles: Record<
  ButtonSize,
  Record<Variant, string>
> = {
  [ButtonSize.Small]: {
    default: 'button-sm--additional',
    'icon-only': 'button-sm--additional-icon',
  },
  [ButtonSize.Medium]: {
    default: 'button-md--additional',
    'icon-only': 'button-md--additional-icon',
  },
  [ButtonSize.Large]: {
    default: 'button-lg--additional',
    'icon-only': 'button-lg--additional-icon',
  },
};

const additionalIconSizeStyles: Record<ButtonSize, Record<Variant, string>> = {
  [ButtonSize.Small]: {
    default: 'button__icon--sm',
    'icon-only': 'button__icon--sm',
  },
  [ButtonSize.Medium]: {
    default: 'button__icon--md',
    'icon-only': 'button__icon--md',
  },
  [ButtonSize.Large]: {
    default: 'button__icon--lg',
    'icon-only': 'button__icon--lg',
  },
};

export const ButtonExt = Node.create({
  name: 'button',
  content: 'inline*',
  group: 'block',

  addAttributes() {
    return {
      icon: {
        default: '',
      },
      label: {
        default: 'New Button',
      },
      type: {
        default: ButtonType.Primary,
      },
      size: {
        default: ButtonSize.Small,
      },
      alignment: {
        default: ButtonTextAlignment.Left,
      },
      link: {
        default: '',
      },
      errors: {
        default: {},
      },
      edited: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="button"]' }];
  },

  renderHTML({ HTMLAttributes, node }) {
    const { icon, label, type, size, alignment, link, errors } = node.attrs;
    const variantKey = label ? 'default' : 'icon-only';

    const stringSize = size as ButtonSize;

    const alignmentClass =
      alignment === ButtonTextAlignment.Right
        ? 'align-end'
        : alignment === ButtonTextAlignment.Center
        ? 'align-center'
        : 'align-start';

    const sizeClass =
      size === ButtonSize.Small
        ? 'button-sm'
        : size === ButtonSize.Medium
        ? 'button-md'
        : 'button-lg';

    const availableColorClass =
      type === ButtonType.Primary ? 'button--primary' : 'button--secondary';

    const disabledColorClass = ButtonType.Primary
      ? 'button--primary-disabled'
      : 'button--secondary-disabled';

    const colorClass = link ? availableColorClass : disabledColorClass;

    return [
      'div',
      { class: `button-wrapper ${alignmentClass}` },
      [
        'a',
        mergeAttributes(
          HTMLAttributes,
          { href: link, target: '_blank' },
          {
            class: `button ${sizeClass} ${colorClass} ${
              additionalButtonSizeStyles[stringSize ?? ButtonSize.Small][
                variantKey
              ]
            }`,
          }
        ),
        icon
          ? [
              'span',
              {
                class: `button__icon ${label ? 'button__icon--label' : ''} ${
                  additionalIconSizeStyles[stringSize ?? ButtonSize.Small][
                    variantKey
                  ]
                }`,
              },
              icon,
            ]
          : '',
        label ? ['span', label] : '',
      ],
    ];
  },
});
