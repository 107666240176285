import { ReactNodeViewRenderer } from '@tiptap/react';
import { TodoListView } from './TodoListItem.renderer';
import { TodoListItemExt } from '@distribute/shared/generate-html';

export const TodoListItem = TodoListItemExt.extend({
  addNodeView() {
    return ReactNodeViewRenderer(TodoListView, {
      as: 'li',
      attrs: {
        ...this.options.HTMLAttributes,
      },
    });
  },
});
