export const VIEWER_COLUMN = [
  {
    header: 'Email',
    name: 'email',
  },
  {
    header: 'Views',
    name: 'views',
  },
  {
    header: 'Source',
    name: 'source',
  },
  {
    header: 'Overall activity',
    name: 'overallActivity',
  },
  {
    header: 'Date added',
    name: 'createdAt',
  },
  {
    header: 'Last Visit',
    name: 'lastVisitAt',
  },
] as const;

export type Column = (typeof VIEWER_COLUMN)[number]['name'];
