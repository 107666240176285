import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { string, ValidationError } from 'yup';
import { nanoid } from '@reduxjs/toolkit';

import { RadioButton } from './RadioButton';
import { ItemValue, TagsInput } from './TagsInput';
import {
  Restriction,
  RestrictionType,
  RestrictionDetails,
  RestrictionCriteria,
} from '@distribute/shared/types';

type Props = {
  restrictions: Restriction;
  onRestrictionChange: (restrictions: Restriction) => void;
  onRestrictedTypeChange: (
    type: RestrictionType,
    cb: (err?: Error) => void
  ) => void;
};

const domainRegex = /^@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
const validateEmailSchema = string().required().email();
const validateDomainSchema = string().required().matches(domainRegex);
const RestrictedOptions = [
  {
    id: RestrictionType.Allowed,
    label: 'Allow viewers',
  },
  {
    id: RestrictionType.Blocked,
    label: 'Block viewers',
  },
];

const allowedPlaceholder =
  'Input address(es)/domain(s) which should be allowed...';
const blockedPlaceholder =
  'Input address(es)/domain(s) which should be blocked...';

function generateItemValue(items: RestrictionDetails[]) {
  return items.map(({ value }) => ({
    id: nanoid(),
    value,
    isError: false,
  }));
}

export const RestrictedEmailsForm = ({
  restrictions,
  onRestrictionChange,
  onRestrictedTypeChange,
}: Props) => {
  const [restrictedType, setRestrictedType] = useState<RestrictionType>(
    restrictions?.type ?? RestrictionType.Allowed
  );
  const placeholder =
    restrictedType === RestrictionType.Allowed
      ? allowedPlaceholder
      : blockedPlaceholder;
  const [restrictedDetails, setRestrictedDetails] = useState<ItemValue[]>(
    function () {
      const list = restrictions?.list ?? [];
      return generateItemValue(list);
    }
  );
  const isError = useMemo(() => {
    return restrictedDetails.some(({ isError }) => isError);
  }, [restrictedDetails]);
  const validateEmail = (name: string) => {
    try {
      validateEmailSchema.validateSync(name);
      return;
    } catch (error) {
      return (error as ValidationError).errors;
    }
  };

  const validateDomain = (name: string) => {
    try {
      validateDomainSchema.validateSync(name);
      return;
    } catch (error) {
      return (error as ValidationError).errors;
    }
  };

  const validateRestrictionDetails = (name: string) => {
    const isDomain = name.startsWith('@');
    return isDomain ? validateDomain(name) : validateEmail(name);
  };

  const handleRestrictedTypeChange = (type: RestrictionType) => {
    setRestrictedType(type);
    onRestrictedTypeChange(type, (err) => {
      if (err) {
        setRestrictedType(restrictions?.type ?? RestrictionType.Allowed);
      }
    });
  };

  const handleRestrictedDetailsChange = useCallback(
    (details: ItemValue[]) => {
      const restrictionDetails: Restriction = {
        type: restrictedType,
        list: [],
      };

      const hasError = details.some(({ isError }) => isError);

      details.forEach(({ value, isError }) => {
        if (isError) return;
        const criteria = value.startsWith('@')
          ? RestrictionCriteria.Domain
          : RestrictionCriteria.Email;
        restrictionDetails.list.push({
          value,
          criteria,
        });
      });

      setRestrictedDetails(details);

      !hasError && onRestrictionChange(restrictionDetails);
    },
    [restrictions, restrictedType]
  );

  useEffect(() => {
    setRestrictedType(restrictions?.type ?? RestrictionType.Allowed);
  }, [restrictions?.type]);

  useEffect(() => {
    setRestrictedDetails(generateItemValue(restrictions?.list ?? []));
  }, [restrictions?.list]);

  return (
    <>
      <div className="flex items-center gap-8">
        {RestrictedOptions.map(({ id, label }) => (
          <RadioButton
            key={id}
            name="restricted-options"
            label={label}
            defaultChecked={restrictedType === id}
            onChange={() => handleRestrictedTypeChange(id)}
          />
        ))}
      </div>
      <div className="pb-1.5">
        <TagsInput
          label=""
          isError={isError}
          placeholder={placeholder}
          value={restrictedDetails}
          onChange={handleRestrictedDetailsChange}
          validateItemValue={validateRestrictionDetails}
        />
      </div>
      <p className="text-sm">
        Please input domains as "@domain.com" and emails as "example@domain.com"
        format.
      </p>
    </>
  );
};
