import React from 'react';
import cn from 'classnames';

type DomainBadgeStatus = 'verifying' | 'connected' | 'unverified';

type Props = {
  type: DomainBadgeStatus;
};

const badgeStyles: { [key in DomainBadgeStatus]: string } = {
  verifying: 'before:bg-warning-600 ellipsis-dots',
  connected: 'before:bg-success-600',
  unverified: 'before:bg-error-600',
};

const badgeLabel: { [key in DomainBadgeStatus]: string } = {
  verifying: 'Verifying DNS',
  connected: 'Connected',
  unverified: 'Unverified',
};

export const DomainStatusBadge: React.FC<Props> = ({ type }) => {
  return (
    <p
      className={cn(
        'relative pl-4.5 text-sm text-gray-600 font-semibold before:absolute before:w-2 before:h-2 before:left-px before:top-1.5 before:rounded-full',
        badgeStyles[type]
      )}
    >
      {badgeLabel[type]}
    </p>
  );
};
