import React from 'react';
import { ChangeBrandStylesForm } from './components';

export const TeamBrandStyles = () => {
  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Styles
      </h1>
      <ChangeBrandStylesForm />
    </div>
  );
};
