import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '../../teams';
import { TeamItem } from './TeamItem';
import { publicTemplatesModel } from '../model';

type IProps = {
  onSelectTeam: (team: TeamFiltered) => void;
};

export const TeamsList: FC<IProps> = ({ onSelectTeam }) => {
  const teams = useSelector(teamsModel.selectors.selectTeams);
  const loadingFoldersForTeamId = useSelector(
    publicTemplatesModel.selectors.selectLoadingFoldersForTeamId
  );

  return (
    <div className="flex flex-col gap-5">
      {teams.map((team) => (
        <TeamItem
          team={team}
          isLoading={loadingFoldersForTeamId === team.id}
          onSelectTeam={onSelectTeam}
          key={team.id}
        />
      ))}
    </div>
  );
};
