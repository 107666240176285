import { api } from './instance';

export const brandfetchApi = {
  async getBrandColor(companyName?: string | null) {
    if (!companyName) return null;

    const { data: responseData } = await api.get<string>(
      `brandfetch/color/${companyName}`
    );

    return responseData;
  },
};
