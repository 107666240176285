import { FC } from 'react';

import { Modal, Button, Input } from '../../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { recordingsModel } from '../../model';

const CONFIRMATION_TEXT = 'CONFIRM';

type DeleteRecordSchema = {
  confirmText: string;
};

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to delete.`
    ),
});

export const RecordDeleteModal: FC = () => {
  const dispatch = useDispatch();

  const record = useSelector(recordingsModel.selectors.selectRecordToDelete);

  const isLoading = useSelector(
    recordingsModel.selectors.selectIsRecordDeleteLoading
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeleteRecordSchema>({
    resolver: yupResolver(validationSchema),
  });

  const onClose = () => {
    dispatch(recordingsModel.actions.setRecordIdToDelete(null));
  };

  const onSubmit = () => {
    dispatch(recordingsModel.actions.deleteRecord());
  };

  return (
    <Modal
      onClose={onClose}
      isOpen
      title="Delete Video"
      actionButton={
        <Button
          type="submit"
          fullWidth
          color="destructive"
          variant="text"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Delete Forever
        </Button>
      }
    >
      <div className="text-sm font-normal text-gray-600 mb-5">
        The video "{record?.name || 'Untitled'}" will be deleted permanently
        here and all its associated pages. If it has a form, any existing
        submissions will be removed, and no future submissions will be captured.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
