import * as yup from 'yup'; // import Yup

import { VideoQuality } from '@distribute/shared/types';
import { APP_ORIGIN } from '../../../shared/config';

enum RecordLinkQueryParams {
  QUALITY = 'q',
  TEAM_ID = 't',
}

export const createRecordLink = (prefix: string) => {
  return `https://recordings.${APP_ORIGIN}/r/${prefix}`;
};

export const RECORD_REGEX =
  /^(https?:\/\/).+(\/quick-recording\/|\/r\/)(\w+)$/g;

const recordParamsSchema = yup.object({
  teamId: yup.number().positive().integer().required(),
  videoQuality: yup.mixed().oneOf(Object.values(VideoQuality)).required(),
});

export const parseRecordLinkParams = (text: string) => {
  try {
    const url = new URL(text);

    const videoQuality = url.searchParams.get(RecordLinkQueryParams.QUALITY);
    const teamId = url.searchParams.get(RecordLinkQueryParams.TEAM_ID);

    const response = recordParamsSchema.validateSync({
      videoQuality,
      teamId,
    });
    return response;
  } catch {
    return null;
  }
};
