import {
  SetupIntentRequestType,
  SetupIntentResponseType,
  UpdatePaymentMethodRequestType,
  UpdatePaymentMethodResponseType,
  ValidatePromoCodeRequestType,
  ValidatePromoCodeResponseType,
  GetUpcomingInvoiceQueryParamsType,
  GetUpcomingInvoiceResponseType,
  CreateSubscriptionRequestType,
  CreateSubscriptionResponseType,
  UpdateSubscriptionRequestType,
  UpdateSubscriptionResponseType,
  RetryLatestFailedInvoiceRequestType,
  GetInvoicesResponseType,
  GetInvoicesRequestQueryParamsType,
} from '@distribute/shared/api-types/subscriptions';
import { api } from './instance';
import { getQueryString } from '../../lib';

export const subscriptionApi = {
  async sendDowngradeReason(data: {
    reasons: string;
    environment: string;
    email: string;
  }) {
    return api.post(
      'https://hooks.slack.com/triggers/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',
      data,
      {
        transformRequest(data, headers) {
          delete headers['Authorization'];
          delete headers['Content-Type'];
          return JSON.stringify(data);
        },
      }
    );
  },

  async setupIntent(data: SetupIntentRequestType) {
    const { data: responseData } = await api.post<SetupIntentResponseType>(
      'subscription/setup-intent',
      data
    );

    return responseData;
  },

  async updatePaymentMethod(data: UpdatePaymentMethodRequestType) {
    const { data: responseData } =
      await api.post<UpdatePaymentMethodResponseType>(
        'subscription/payment-method',
        data
      );

    return responseData;
  },

  async getUpcomingInvoice(data: GetUpcomingInvoiceQueryParamsType) {
    const { data: responseData } =
      await api.get<GetUpcomingInvoiceResponseType>(
        `subscription/invoices/upcoming?${getQueryString({
          ...data,
          prorationDate: data.prorationDate?.toISOString(),
        })}`
      );

    return responseData;
  },

  async validatePromoCode(
    data: ValidatePromoCodeRequestType,
    signal: AbortSignal
  ) {
    const { data: responseData } = await api.get<ValidatePromoCodeResponseType>(
      `subscription/promo-code/validate?${getQueryString(data)}`,
      { signal }
    );

    return responseData;
  },

  async createSubscription(data: CreateSubscriptionRequestType) {
    const { data: responseData } =
      await api.post<CreateSubscriptionResponseType>('subscription', data);

    return responseData;
  },

  async updateSubscription(data: UpdateSubscriptionRequestType) {
    const { data: responseData } =
      await api.patch<UpdateSubscriptionResponseType>('subscription', data);

    return responseData;
  },

  async retryLatestFailedInvoice(data: RetryLatestFailedInvoiceRequestType) {
    const { data: responseData } = await api.post(
      'subscription/invoices/retry-failed',
      data
    );

    return responseData;
  },

  async getInvoices(data: GetInvoicesRequestQueryParamsType) {
    const { data: responseData } = await api.get<GetInvoicesResponseType>(
      `subscription/invoices?${getQueryString(data)}`
    );

    return responseData;
  },
};
