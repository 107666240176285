import Gleap from 'gleap';
import { changeGleapBlockVisibility } from '../../utils';
import { GLEAP_API_KEY } from '../../../../shared/config';
import { select } from 'redux-saga/effects';
import { authUserModel } from '../../../auth-user';
import { DBUser } from '../../../../shared/types';
import { repositionGleapBlock } from '../../hooks/useGleapBlockRepositionWithSidebar';

export function* initGleapBlock() {
  if (!Gleap.getInstance()?.initialized) {
    setTimeout(() => {
      Gleap.on('initialized', () => {
        const sidebarElement = document.getElementById('editor-sidebar');
        const isSidebarOpen = sidebarElement?.classList.contains('right-0');
        repositionGleapBlock(!!isSidebarOpen);
      });

      Gleap.initialize(GLEAP_API_KEY);
    }, 100);
  }

  const currentUser: DBUser = yield select(
    authUserModel.selectors.selectUserWithError
  );

  Gleap.identify(currentUser.id, {
    name: currentUser.displayName,
    email: currentUser.email,
  });

  yield changeGleapBlockVisibility('visible');
}
