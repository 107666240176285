import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserToTeamFiltered } from '@distribute/shared/types';
import { Modal, Button } from '../../../../shared/ui';
import { teamsModel } from '../../../teams';
import { teamMembersModel } from '../../model';

type IProps = {
  isOpen: boolean;
  teamMember?: UserToTeamFiltered;
  onClose: () => void;
};

export const DeleteTeamMemberConfirmModal: FC<IProps> = ({
  isOpen,
  teamMember,
  onClose,
}) => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isDeletingTeamMember = useSelector(
    teamMembersModel.selectors.selectIsLoadingDeleteTeamMember
  );

  const handleDeleteTeamMember = () => {
    if (!teamMember) return;

    dispatch(
      teamMembersModel.actions.deleteTeamMember({
        teamId: currentTeam.id,
        teamMemberId: teamMember.id,
        cb: onClose,
      })
    );
  };

  if (!teamMember) return null;

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Delete User"
      actionButton={
        <Button
          onClick={handleDeleteTeamMember}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          disabled={isDeletingTeamMember}
          loading={isDeletingTeamMember}
        >
          Delete User
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        Removing {teamMember.user?.displayName ?? teamMember.externalUserEmail}{' '}
        from the team will revoke their access to all associated pages. Any
        shared pages or folders owned by them will be transferred to the team
        owner. Are you sure you want to proceed?
      </div>
    </Modal>
  );
};
