import { ButtonHTMLAttributes, ReactNode, Ref } from 'react';
import { Tooltip, Icon } from '../../../shared/ui';
import { SpriteGlyph } from '../../../shared/ui/Icon';
import classNames from 'classnames';
import React from 'react';

export type BubbleMenuIconButtonProps = {
  active?: boolean;
  iconName?: SpriteGlyph;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip: string | ReactNode;
  size?: 'sm' | 'md';
  buttonContent?: ReactNode;
  className?: string;
  hideArrow?: boolean;
  isTooltipOpen?: boolean;
  onTooltipOpenChange?: (isOpen: boolean) => void;
  delayDuration?: number;
};

const BubbleMenuIconButtonComponent = (
  {
    active,
    iconName,
    onClick,
    tooltip,
    size = 'sm',
    hideArrow = true,
    className,
    isTooltipOpen,
    buttonContent,
    onTooltipOpenChange,
    delayDuration,
    ...rest
  }: BubbleMenuIconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
  ref: Ref<HTMLButtonElement>
) => {
  const buttonClass = classNames(
    [
      'p-1 flex items-center justify-center rounded gap-x-1',
      !active && 'text-gray-700 hover:text-gray-800 hover:bg-gray-100',
      active && 'text-base-white bg-primary-500',
      !buttonContent && 'w-8 h-7',
    ],
    className
  );

  const iconClass = classNames([
    size === 'md' && 'w-6 h-6',
    size === 'sm' && 'w-5 h-5',
  ]);

  return (
    <Tooltip
      delayDuration={delayDuration}
      trigger={
        <button
          ref={ref}
          {...rest}
          type="button"
          className={buttonClass}
          onClick={onClick}
        >
          {iconName && (
            <Icon className={iconClass} glyph={iconName} width={20} />
          )}
          {buttonContent}
        </button>
      }
      hideArrow={hideArrow}
      sideOffset={8}
      isOpen={isTooltipOpen}
      onOpenChange={onTooltipOpenChange}
    >
      {tooltip}
    </Tooltip>
  );
};

export const BubbleMenuIconButton = React.forwardRef(
  BubbleMenuIconButtonComponent
);
