import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TeamFiltered } from '@distribute/shared/types';
import {
  ChangeLogoAction,
  DeleteLogoAction,
  LeaveTeamAction,
  RequestDeleteTeamAction,
  UpdateNameDomainAction,
} from './types';
import { LoadingStatus } from '../../../shared/types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isUploadLogoLoading: boolean;
  uploadingLogoProgress: number;
  isRemoveLogoLoading: boolean;
  isUpdatingNameDomain: boolean;
  isLeavingTeam: boolean;
  isRequestingDeleteTeam: boolean;
  confirmDeleteTeamStatus: LoadingStatus;
  leftTeam: TeamFiltered | null;
};
const initialState: State = {
  isUploadLogoLoading: false,
  uploadingLogoProgress: 0,
  isRemoveLogoLoading: false,
  isUpdatingNameDomain: false,
  isLeavingTeam: false,
  isRequestingDeleteTeam: false,
  confirmDeleteTeamStatus: 'never',
  leftTeam: null,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'teamInfoSettings',
  initialState,
  reducers: {
    setIsUploadLogoLoading: (
      state,
      { payload: isUploadLogoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isUploadLogoLoading }),

    setUploadLogoProgress: (
      state,
      { payload: uploadingLogoProgress }: PayloadAction<number>
    ) => ({ ...state, uploadingLogoProgress }),

    setIsRemoveLogoLoading: (
      state,
      { payload: isRemoveLogoLoading }: PayloadAction<boolean>
    ) => ({ ...state, isRemoveLogoLoading }),

    setIsUpdatingNameDomain: (
      state,
      { payload: isUpdatingNameDomain }: PayloadAction<boolean>
    ) => ({ ...state, isUpdatingNameDomain }),

    setIsLeavingTeam: (
      state,
      { payload: isLeavingTeam }: PayloadAction<boolean>
    ) => ({ ...state, isLeavingTeam }),

    setIsRequestingDeleteTeam: (
      state,
      { payload: isRequestingDeleteTeam }: PayloadAction<boolean>
    ) => ({ ...state, isRequestingDeleteTeam }),

    setLeftTeam: (
      state,
      { payload: leftTeam }: PayloadAction<TeamFiltered | null>
    ) => ({ ...state, leftTeam }),

    setConfirmDeleteTeamStatus: (
      state,
      { payload: confirmDeleteTeamStatus }: PayloadAction<LoadingStatus>
    ) => ({ ...state, confirmDeleteTeamStatus }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});
export const actions = {
  ...reducerActions,
  changeLogo: createAction<ChangeLogoAction>('teamInfoSettings/changeLogo'),
  cancelUploadingFile: createAction('teamInfoSettings/cancelUploadingFile'),
  deleteLogo: createAction<DeleteLogoAction>('teamInfoSettings/deleteLogo'),
  updateNameDomain: createAction<UpdateNameDomainAction>(
    'teamInfoSettings/updateNameDomain'
  ),
  leaveTeam: createAction<LeaveTeamAction>('teamInfoSettings/leaveTeam'),
  requestDeleteTeam: createAction<RequestDeleteTeamAction>(
    'teamInfoSettings/requestDeleteTeam'
  ),
};
