import {
  SnippetCategoryWithSnippets,
  SnippetType,
  SnippetsSharingAccess,
} from '@distribute/shared/types';
import {
  Button,
  Icon,
  Input,
  Modal,
  TabContent,
  Tabs,
} from '../../../../../shared/ui';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IconMap } from '../../../../../shared/sprite';
import {
  AddingMethodEnum,
  CreateSnippetTabsName,
  snippetModalTitleByType,
} from './lib/config';
import {
  ConfigureSnippetFormType,
  validationSchemaSnippetConfig,
} from './ConfigureSnippetForm';
import { UploadSnippetTab } from './UploadSnippetTab';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../../model';
import { useSnippetsPermissions } from '../../../../../features/teams';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '../../../../../processes/navigation';
import { SNIPPETS_ROUTE } from '../../../../../entities/history';
import { RadioButtonForPermissions } from '../../../../../widgets/editor-sidebar/ui/components/RadioButtonForPermissions';
import { SelectCategoryDropdown } from './SelectCategoryDropdown';

type Props = {
  category?: SnippetCategoryWithSnippets;
  isOpen: boolean;
  onClose: () => void;
  type: SnippetType;
  isSnippetsModal?: boolean;
};

export const CreateMediaSnippetModal: FC<Props> = ({
  category,
  type,
  isOpen,
  onClose,
  isSnippetsModal = false,
}) => {
  const dispatch = useDispatch();

  const [url, setUrl] = useState<string | undefined>(undefined);
  const [addingMethod, setAddingMethod] = useState(AddingMethodEnum.UPLOAD);

  const isCreateLoading = useSelector(
    snippetsModel.selectors.selectIsCreateSnippetLoading
  );

  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();

  const defaultSharingAccessValue =
    category || !isCanCreatePersonalSnippets
      ? SnippetsSharingAccess.TEAM
      : SnippetsSharingAccess.PERSONAL;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm<ConfigureSnippetFormType>({
    resolver: yupResolver(validationSchemaSnippetConfig),
    defaultValues: {
      name: '',
      description: '',
      sharingAccess: defaultSharingAccessValue,
      categoryId: category?.id,
    },
  });

  const {
    field: { value: categoryIdValue, onChange: setCategoryId },
  } = useController({
    control,
    defaultValue: category?.id,
    name: 'categoryId',
  });

  const {
    field: { value: sharingAccessValue, onChange: setSharingAccess },
  } = useController({
    control,
    defaultValue: defaultSharingAccessValue,
    name: 'sharingAccess',
  });

  const onChangeSharingAccess = useCallback(
    (value: SnippetsSharingAccess) => {
      setSharingAccess(value);
      if (value === SnippetsSharingAccess.PERSONAL) {
        setCategoryId(undefined);
      }
    },
    [setCategoryId, setSharingAccess]
  );

  const isVisibleSharingAccessButtons =
    isCanCreatePersonalSnippets && isCanManageSnippets;

  const watchName = watch('name');
  const watchDescription = watch('description');

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleCreateFormSubmit = (data: ConfigureSnippetFormType) => {
    if (!url) {
      return;
    }

    if (!isCanCreatePersonalSnippets && !isCanManageSnippets) {
      return;
    }

    dispatch(
      snippetsModel.actions.createSnippet({
        ...data,
        url,
        type,
        callback: () => {
          handleClose();
          if (!isSnippetsModal) {
            dispatch(
              navigate({ to: `${SNIPPETS_ROUTE}/${data.sharingAccess}` })
            );
          }
        },
      })
    );
  };

  const tabs = [
    {
      id: CreateSnippetTabsName.UPLOAD,
      name: 'upload',
      title: `Add ${snippetModalTitleByType[type].name}`,
    },
    {
      id: CreateSnippetTabsName.CONFIGURE,
      name: 'configure',
      title: 'Configure snippet',
      disabled: !url,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<TabContent>(tabs[0]);

  useEffect(() => {
    setUrl('');
  }, [addingMethod]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className="relative overflow-hidden !p-0 !pb-23.5 w-120 min-h-180"
      isShowCancelButton={false}
      disableCloseByOutsideClick
    >
      <div className="px-6 pt-6 pb-2">
        <img
          src="../../../assets/images/bg-top-dots-part.svg"
          alt="background"
          className="absolute top-0 left-0"
        />
        <div className="relative mb-5 z-1">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-gray-100 rounded-full">
              <Icon
                glyph={snippetModalTitleByType[type].icon}
                className="text-gray-500"
              />
            </div>
            <Icon
              glyph={IconMap.XClose}
              className="text-gray-500 cursor-pointer shrink-0"
              width={24}
              onClick={onClose}
            />
          </div>
          <p className="mb-1 font-medium font-display text-display-xs">
            Create {snippetModalTitleByType[type].title}
          </p>
        </div>
        <Tabs
          tabClassName="w-1/2 px-2"
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectTab={(tab) => setSelectedTab(tab)}
          className="!mb-0 !gap-x-0"
          isShowIndex
        >
          <div className="pt-6">
            {selectedTab.name === CreateSnippetTabsName.UPLOAD &&
              type !== SnippetType.TEXT && (
                <UploadSnippetTab
                  type={type}
                  setUrl={setUrl}
                  url={url}
                  onClose={onClose}
                  addingMethod={addingMethod}
                  setAddingMethod={setAddingMethod}
                  switchToNextTab={() => {
                    setSelectedTab(tabs[1]);
                  }}
                />
              )}
            {selectedTab.name === CreateSnippetTabsName.CONFIGURE && (
              <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-4">
                    <Input
                      autoComplete="off"
                      label="Snippet name *"
                      {...register('name')}
                      isError={!!errors.name}
                      messageText={errors.name?.message}
                      type="text"
                      heightSize="md"
                      counterValue={watchName.length}
                      maxCharacters={64}
                      isCounterShow={!errors.name}
                    />
                    <Input
                      autoComplete="off"
                      label="Description"
                      {...register('description')}
                      isError={!!errors.description}
                      messageText={errors.description?.message}
                      placeholder="Provide instructions for your team on how to use this snippet. "
                      type="text"
                      isTextArea
                      textAreaRows={3}
                      textAreaMaxRows={3}
                      maxCharacters={120}
                      className="!h-23.5 max-h-23.5 min-h-23.5 flex-shrink-0 flex-grow-0"
                      counterValue={watchDescription.length}
                      isCounterInside
                    />
                  </div>

                  {isVisibleSharingAccessButtons && (
                    <div>
                      <label className="block mb-3 font-semibold text-gray-700 text-md">
                        Who can see and use this snippet?
                      </label>
                      <div className="flex items-center gap-4">
                        {Object.values(SnippetsSharingAccess).map((value) => (
                          <RadioButtonForPermissions
                            name="sharing-access"
                            key={value}
                            value={value}
                            onChange={() => onChangeSharingAccess(value)}
                            defaultChecked={value === sharingAccessValue}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {sharingAccessValue === SnippetsSharingAccess.TEAM && (
                    <SelectCategoryDropdown
                      setCategoryId={setCategoryId}
                      snippetType={type}
                      categoryId={categoryIdValue}
                      errorText={errors.categoryId?.message}
                    />
                  )}
                </div>

                <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white">
                  <Button
                    fullWidth
                    color="secondary"
                    variant="text"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={handleSubmit(handleCreateFormSubmit)}
                    loading={isCreateLoading}
                  >
                    Create
                  </Button>
                </div>
              </form>
            )}
          </div>
        </Tabs>
      </div>
    </Modal>
  );
};
