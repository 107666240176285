import React, { FC } from 'react';
import { IconMap } from '../../shared/sprite';
import { Button } from '../../shared/ui';
import { ProfileDropdown } from './ProfileDropdown';
import { WorkspaceSearch } from './WorkspaceSearch';
import { useHandlerInviteTeamMembers } from '../../features/team-members';
import { useTeamPermissions } from '../../features/teams';
import cn from 'classnames';

type IProps = {
  onContactSupport: () => void;
};

export const TopBar: FC<IProps> = ({ onContactSupport }: IProps) => {
  const { isCanManageAccess, isGuest } = useTeamPermissions();
  const handlerInviteTeamMembers = useHandlerInviteTeamMembers();

  return (
    <div
      id="top-bar"
      className={cn(
        'sticky w-full flex flex-row px-8 py-3 border-b border-gray-200 z-10 bg-base-white md:hidden',
        { 'justify-between': !isGuest, 'justify-end': isGuest }
      )}
    >
      {!isGuest && <WorkspaceSearch />}
      <div className="flex flex-row items-center gap-x-4">
        {isCanManageAccess && (
          <Button
            variant="icon-text"
            color="tertiary-black"
            size="md"
            iconLeftName={IconMap.UserPlus}
            onClick={(e) => {
              e.currentTarget.blur();
              handlerInviteTeamMembers();
            }}
          >
            Invite Members
          </Button>
        )}
        <ProfileDropdown onContactSupport={onContactSupport} />
      </div>
    </div>
  );
};
