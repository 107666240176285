import { Snippet } from '@distribute/shared/types';
import { RootState } from '../../../app';

export const selectCategories = ({ snippets }: RootState) =>
  snippets.categories;

export const selectIsCreateCategoryLoading = ({ snippets }: RootState) =>
  snippets.isCreateCategoryLoading;

export const selectIsCreateCategoryModalOpen = ({ snippets }: RootState) =>
  snippets.isCreateCategoryModalOpen;

export const selectIsManageCategoriesModalOpen = ({ snippets }: RootState) =>
  snippets.isManageCategoriesModalOpen;

export const selectIsCreateSnippetLoading = ({ snippets }: RootState) =>
  snippets.isCreateSnippetLoading;

export const selectPersonalSnippets = ({ snippets }: RootState) =>
  snippets.personalSnippets;

export const selectDraftSnippets = ({ snippets }: RootState) =>
  snippets.draftSnippets;

export const selectCurrentTypeFilter = ({ snippets }: RootState) =>
  snippets.currentTypeFilter;

export const selectCurrentOwnerFilter = ({ snippets }: RootState) =>
  snippets.currentOwnerFilter;

export const selectCurrentSortCondition = ({ snippets }: RootState) =>
  snippets.currentSortCondition;

export const selectSearchQuery = ({ snippets }: RootState) =>
  snippets.searchQuery;

export const selectIsUpdateCategoryLoading = ({ snippets }: RootState) =>
  snippets.isUpdateCategoryLoading;

export const selectIsDeleteSnippetLoading = ({ snippets }: RootState) =>
  snippets.isDeleteSnippetLoading;

export const selectIsDeleteSnippetCategoryLoading = ({ snippets }: RootState) =>
  snippets.isDeleteSnippetCategoryLoading;

export const selectUpdatingSnippetsData = ({ snippets }: RootState) =>
  snippets.updatingSnippetData;

export const selectIsUpdateSnippetLoading = ({ snippets }: RootState) =>
  snippets.isUpdateSnippetLoading;

export const selectCurrentSnippet = ({ snippets }: RootState) =>
  snippets.currentSnippet;

export const selectAvailableSnippets = ({ snippets }: RootState) => {
  const snippetsFromCategories = snippets.categories.reduce(
    (acc: Snippet[], cur) => {
      acc.push(...cur.snippets);
      return acc;
    },
    []
  );

  return [
    ...snippets.draftSnippets,
    ...snippets.personalSnippets,
    ...snippetsFromCategories,
  ];
};

export const selectIsSnippetSavedInEditor = ({ snippets }: RootState) =>
  snippets.isSnippetSavedInEditor;

export const selectModalSearchQuery = ({ snippets }: RootState) =>
  snippets.modalSearchQuery;

export const selectCurrentModalFolder = ({ snippets }: RootState) =>
  snippets.currentModalFolder;
