import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DeleteTeamMemberAction,
  InviteTeamMembersAction,
  ResendInviteAction,
  TransferOwnershipAction,
  UpdateTeamMemberRoleAction,
} from './types';
import { globalActions } from '../../../app/model/actions';

type State = {
  isLoadingInviteTeamMembers: boolean;
  isLoadingDeleteTeamMember: boolean;
  isShowInviteTeamMembersModal: boolean;
  isWaitingForTeamInviteMembersModalOpen: boolean;
};
const initialState: State = {
  isLoadingInviteTeamMembers: false,
  isLoadingDeleteTeamMember: false,
  isShowInviteTeamMembersModal: false,
  isWaitingForTeamInviteMembersModalOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {
    setIsLoadingInviteTeamMembers: (
      state,
      { payload: isLoadingInviteTeamMembers }: PayloadAction<boolean>
    ) => ({ ...state, isLoadingInviteTeamMembers }),

    setIsLoadingDeleteTeamMember: (
      state,
      { payload: isLoadingDeleteTeamMember }: PayloadAction<boolean>
    ) => ({ ...state, isLoadingDeleteTeamMember }),

    setIsShowInviteTeamMembersModal: (
      state,
      { payload: isShowInviteTeamMembersModal }: PayloadAction<boolean>
    ) => ({ ...state, isShowInviteTeamMembersModal }),
    setIsWaitingForTeamInviteMembersModalOpen: (
      state,
      {
        payload: isWaitingForTeamInviteMembersModalOpen,
      }: PayloadAction<boolean>
    ) => ({ ...state, isWaitingForTeamInviteMembersModalOpen }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  inviteTeamMembers: createAction<InviteTeamMembersAction>(
    'teamMembers/inviteTeamMembers'
  ),
  resendInvite: createAction<ResendInviteAction>('teamMembers/resendInvite'),
  updateTeamMemberRole: createAction<UpdateTeamMemberRoleAction>(
    'teamMembers/updateTeamMemberRole'
  ),
  deleteTeamMember: createAction<DeleteTeamMemberAction>(
    'teamMembers/deleteTeamMember'
  ),
  transferOwnership: createAction<TransferOwnershipAction>(
    'teamMembers/transferOwnership'
  ),
};
