import React, { ReactNode } from 'react';

type Props = {
  value: string;
  children: ReactNode;
};

export const TemplatesNavigationGroupItem: React.FC<Props> = ({
  value,
  children,
}) => {
  return (
    <>
      <p className="py-3 px-2 text-xs font-extrabold text-gray-600">{value}</p>
      {children}
    </>
  );
};
