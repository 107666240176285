import { Chart, TooltipModel } from 'chart.js';
import moment from 'moment';
import { formatMsToTimePassed } from '@distribute/shared/utils';
import { createPopper, Instance } from '@popperjs/core';
import { AnalyticsMetric, ConversionLine } from '../types';
import {
  createVirtualElement,
  generateGetBoundingClientRect,
} from '../../../shared/lib';
import {
  checkIsAnalyticsConversionMetric,
  getConversionFormatValue,
} from '../ui/utils';

const CHART_POINT_WIDTH = 20;
const CHART_POINT_HEIGHT = 20;

type AdditionalDataValueItem = Array<{
  id: number | string;
  name: string;
  value: number;
}>;

type AdditionalData = {
  name: string;
  title: string;
  value?: AdditionalDataValueItem[];
};

const renderValue = (
  values: number[],
  analyticMetric: AnalyticsMetric,
  conversionLines: ConversionLine[]
) => {
  if (checkIsAnalyticsConversionMetric(analyticMetric)) {
    return conversionLines
      .map((conversionLine, idx) => {
        const value = `<span>${getConversionFormatValue(
          values[idx] || 0,
          analyticMetric
        )}</span>`;

        return `<p>${conversionLine.label}: ${value}</p>`;
      })
      .join('');
  }

  if (analyticMetric === 'averageTimeOnPage') {
    return values.map((item) => formatMsToTimePassed(item * 1000)).join(', ');
  }

  return values.join(', ');
};

let popperInstance: Instance | null;
const popperVirtualElement = createVirtualElement();

export function renderTooltip(
  context: {
    chart: Chart;
    tooltip: TooltipModel<'line'>;
  },
  analyticMetricTitle: string,
  analyticMetric: AnalyticsMetric,
  conversionLines: ConversionLine[],
  additionalData?: AdditionalData
) {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.style.backgroundColor = '#ffffff';
    tooltipEl.style.border = '1px solid #F5F5F5';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.padding = '12px';
    tooltipEl.style.display = 'block';
    tooltipEl.style.minWidth = '191px';
    tooltipEl.style.boxShadow = '0px 12px 16px -4px rgba(16, 24, 40, 0.08)';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.display = 'none';
    if (popperInstance) {
      popperInstance.destroy();
      popperInstance = null;
    }
    return tooltipEl;
  }

  // Set Content
  if (tooltipModel.body) {
    const tooltipBodyEl = document.createElement('div');
    tooltipBodyEl.style.display = 'flex';

    const [dataPoint] = tooltipModel.dataPoints;
    const values = tooltipModel.dataPoints.map((item) => item.raw) as number[];
    const sideLineEl = document.createElement('div');
    const color = context.tooltip.dataPoints[0].dataset.borderColor as string;
    sideLineEl.style.backgroundColor = color;
    sideLineEl.style.width = '4px';
    sideLineEl.style.borderRadius = '2px';
    sideLineEl.style.marginRight = '12px';

    const contentContainerEl = document.createElement('div');
    contentContainerEl.style.display = 'flex';
    contentContainerEl.style.flexFlow = 'column';
    contentContainerEl.style.gap = '2px';
    contentContainerEl.style.color = '#424242';
    contentContainerEl.style.fontFamily = 'Open Sans';

    const metricTitleEl = document.createElement('div');
    metricTitleEl.style.fontWeight = '600';
    metricTitleEl.style.fontSize = '14px';
    metricTitleEl.style.lineHeight = '20px';
    metricTitleEl.textContent = analyticMetricTitle;

    contentContainerEl.appendChild(metricTitleEl);

    const labelTitleEl = document.createElement('div');
    labelTitleEl.style.fontWeight = '400';
    labelTitleEl.style.fontSize = '12px';
    labelTitleEl.style.lineHeight = '18px';
    labelTitleEl.textContent = moment(new Date(dataPoint.label)).format(
      'MMM DD, YYYY'
    );

    contentContainerEl.appendChild(labelTitleEl);

    const valueEl = document.createElement('div');
    valueEl.classList.add('chartjs-tooltip-value');
    valueEl.innerHTML = renderValue(values, analyticMetric, conversionLines);

    contentContainerEl.appendChild(valueEl);

    const tabContainerEl = document.createElement('div');
    const additionDataValue = additionalData?.value?.[dataPoint.dataIndex];
    const shouldRenderAdditionalData =
      Array.isArray(additionDataValue) && additionDataValue.length > 0;

    if (shouldRenderAdditionalData) {
      const tabTitleEl = document.createElement('div');
      const currentData = additionDataValue;
      tabTitleEl.classList.add('chartjs-tab-title');
      tabTitleEl.textContent = additionalData?.title as string;

      tabContainerEl.appendChild(tabTitleEl);
      tooltipBodyEl.style.borderBottom = '1px solid #E5E5E5';
      tooltipBodyEl.style.paddingBottom = '12px';

      for (const item of currentData) {
        const tabContentEl = document.createElement('div');
        tabContentEl.classList.add('chartjs-tab-content');
        tabContentEl.style.display = 'flex';
        tabContentEl.style.justifyContent = 'space-between';
        tabContentEl.style.alignItems = 'baseline';
        tabContentEl.style.gap = '12px';

        const tabContentTitleEl = document.createElement('div');
        tabContentTitleEl.classList.add('chartjs-tab-content-title');
        tabContentTitleEl.style.color = '#424242';
        tabContentTitleEl.textContent = item.name;
        tabContentEl.appendChild(tabContentTitleEl);

        const tabSeparatorEl = document.createElement('div');
        tabSeparatorEl.classList.add('chartjs-tab-separator');
        tabSeparatorEl.style.borderBottom = '1px dotted #A3A3A3';
        tabSeparatorEl.style.flexGrow = '1';
        tabContentEl.appendChild(tabSeparatorEl);

        const tabContentValueEl = document.createElement('div');
        tabContentValueEl.classList.add('chartjs-tab-content-value');
        tabContentValueEl.style.color = '#141414';
        tabContentValueEl.textContent = String(item.value);
        tabContentEl.appendChild(tabContentValueEl);

        tabContainerEl.appendChild(tabContentEl);
      }
    }

    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    tooltipBodyEl.appendChild(sideLineEl);
    tooltipBodyEl.appendChild(contentContainerEl);
    tooltipBodyEl.appendChild(contentContainerEl);

    tooltipEl.appendChild(tooltipBodyEl);
    tooltipEl.appendChild(tabContainerEl);
  }

  const position = context.chart.canvas.getBoundingClientRect();
  const xPosition =
    position.left +
    window.pageXOffset +
    tooltipModel.caretX -
    CHART_POINT_WIDTH / 2;
  const yPosition =
    position.top +
    window.pageYOffset +
    tooltipModel.caretY -
    CHART_POINT_HEIGHT / 2;

  tooltipEl.style.display = 'block';
  tooltipEl.style.pointerEvents = 'none';

  popperVirtualElement.getBoundingClientRect = generateGetBoundingClientRect({
    x: xPosition,
    y: yPosition,
    width: CHART_POINT_WIDTH,
    height: CHART_POINT_HEIGHT,
  });

  if (popperInstance) {
    popperInstance.update();
  } else {
    popperInstance = createPopper(popperVirtualElement, tooltipEl, {
      placement: 'right',
      modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
    });
  }

  return tooltipEl;
}

export const renderPoint = (color: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = CHART_POINT_WIDTH;
  canvas.height = CHART_POINT_HEIGHT;

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.beginPath();
  ctx.arc(10, 10, 8, 0, 2 * Math.PI);
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();

  ctx.beginPath();
  ctx.arc(10, 10, 5, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();

  return canvas;
};
