import React, { useState } from 'react';
import { CreatePageModal } from './components/CreatePageModal';
import { TemplatesModal, templatesModel } from '../../../features/templates';
import { ChooseFolderModal, pagesModel } from '../../../features/pages';
import { useDispatch, useSelector } from 'react-redux';
import { foldersModel } from '../../../features/folders';
import { createPageModalModel } from '../../../entities/create-page-modal';
import { CreatePageStepsEnum } from '../../../entities/create-page-modal/model/types';

export type ChosenFolderData = {
  folderId: string;
  folderName?: string;
};

export const CreatePageWorkflow: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedFolderData, setSelectedFolderData] =
    useState<ChosenFolderData | null>(null);

  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);

  const templatesModalOpen = useSelector(
    templatesModel.selectors.selectTemplatesModalOpen
  );

  const modalChooseFolderOpen = useSelector(
    foldersModel.selectors.selectFolderModalOpen
  );

  const selectedBrandCompany = useSelector(
    createPageModalModel.selectors.selectSelectedBrandingCompany
  );

  const currentStep = useSelector(
    createPageModalModel.selectors.selectCurrentStep
  );

  const previousStepsStack = useSelector(
    createPageModalModel.selectors.selectPreviousStepsStack
  );

  const setModalChooseFolderOpen = (isOpen: boolean) => {
    dispatch(foldersModel.actions.setModalOpen(isOpen));
  };

  const closeChooseFolderModal = () => {
    dispatch(foldersModel.actions.setModalOpen(false));
  };

  const closeCreatePageModal = () => {
    dispatch(createPageModalModel.actions.closePageCreationModal());
    dispatch(templatesModel.actions.setTemplateModalOpen(false));
    setSelectedFolderData(null);
  };

  const setTemplatesModalOpen = (isOpen: boolean) => {
    dispatch(templatesModel.actions.setTemplateModalOpen(isOpen));
  };

  const handleClickChooseTemplate = () => {
    setTemplatesModalOpen(false);

    if (currentFolder) {
      dispatch(
        createPageModalModel.actions.openPageCreationModal({
          currentStep: CreatePageStepsEnum.BRANDING,
          previousStepsStack: [
            ...previousStepsStack,
            CreatePageStepsEnum.TEMPLATE,
          ],
        })
      );
      return;
    }

    if (selectedFolderData) {
      dispatch(
        createPageModalModel.actions.openPageCreationModal({
          currentStep: CreatePageStepsEnum.BRANDING,
          previousStepsStack: [
            ...previousStepsStack,
            CreatePageStepsEnum.TEMPLATE,
          ],
        })
      );
      return;
    }

    dispatch(
      createPageModalModel.actions.openPageCreationModal({
        currentStep: CreatePageStepsEnum.BRANDING,
        previousStepsStack: [CreatePageStepsEnum.TEMPLATE],
        isOpen: false,
      })
    );
    setModalChooseFolderOpen(true);
  };

  const onChooseFolder = (data: ChosenFolderData) => {
    setSelectedFolderData(data);
    setModalChooseFolderOpen(false);
    dispatch(createPageModalModel.actions.openPageCreationModal());
  };

  const onCreatePage = (pageName?: string) => {
    if (selectedFolderData) {
      dispatch(
        pagesModel.actions.createPage({
          ...selectedFolderData,
          title: pageName,
          brandLogo: selectedBrandCompany?.logo,
          brandCompanyDomain: selectedBrandCompany?.domain,
          cb: closeCreatePageModal,
        })
      );
      setSelectedFolderData(null);
      return;
    }

    if (currentFolder) {
      dispatch(
        pagesModel.actions.createPage({
          folderId: currentFolder.id,
          title: pageName,
          brandLogo: selectedBrandCompany?.logo,
          brandCompanyDomain: selectedBrandCompany?.domain,
          cb: closeCreatePageModal,
        })
      );
      return;
    }

    throw new Error('Invalid folder');
  };

  return (
    <>
      {currentStep !== CreatePageStepsEnum.TEMPLATE && (
        <CreatePageModal
          onCreatePage={onCreatePage}
          onClose={closeCreatePageModal}
        />
      )}
      <TemplatesModal
        isOpen={
          templatesModalOpen || currentStep === CreatePageStepsEnum.TEMPLATE
        }
        onChoose={handleClickChooseTemplate}
        onClose={closeCreatePageModal}
      />
      <ChooseFolderModal
        isOpen={modalChooseFolderOpen}
        onClose={closeChooseFolderModal}
        onChoose={onChooseFolder}
      />
      <div></div>
    </>
  );
};
