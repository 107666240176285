import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { GetTeamUsersResponseType } from '@distribute/shared/api-types/team';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';

export function* loadTeamMembers() {
  try {
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );
    const teamMembers: GetTeamUsersResponseType = yield call(
      teamsApi.getTeamUsers,
      currentTeam.id
    );

    yield put(actions.setCurrentTeamMembers(teamMembers));
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to display team members')
        )
      )
    );
  }
}
