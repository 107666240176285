import { pagesApi } from '../../../../shared/api';
import { call } from 'redux-saga/effects';
import { RT } from '../../../../shared/types';
import { redirect } from '../../../../entities/history';

export function* redirectToPublicPage(sequenceNumber: string) {
  const publicInfo: RT<typeof pagesApi.getPagePublicInfo> = yield call(
    pagesApi.getPagePublicInfo,
    sequenceNumber
  );
  if (publicInfo.publishedPageUrl) {
    window.location.href = publicInfo.publishedPageUrl;
  } else {
    yield call(redirect.toWorkspace);
  }
}
