import { VideoRecordDetailType } from '@distribute/shared/api-types/video-record';
import { put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { checkIsAllowToViewLikeMember } from '../../lib';
import { RT } from '../../../../shared/types';
import { authUserModel } from '../../../../entities/auth-user';

export function* refreshRecordDetail(record: VideoRecordDetailType) {
  const { id: userId }: RT<typeof authUserModel.selectors.selectUserWithError> =
    yield select(authUserModel.selectors.selectUserWithError);

  yield put(
    actions.setRecord({
      ...record,
      isAllowToViewLikeMember: checkIsAllowToViewLikeMember({
        teamMembersPermission: record.teamMembersPermission,
        userPermissionToVideoRecords: record.userPermissionToVideoRecords,
        userId,
      }),
    })
  );
}
