import { CalloutExt } from './extensions/Callout/Callout';
import { SnippetBlockExt } from './extensions/SnippetBlock/SnippetBlock';

import Color from '@tiptap/extension-color';
import { CustomTableExt } from './extensions/Table';
import { CustomTableCellExt } from './extensions/TableCell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextStyle from '@tiptap/extension-text-style';
import { FilePreviewExt } from './extensions/FilePreview';
import { ResizeableFigureExt } from './extensions/ResizeableFigure';
import { VideoExt } from './extensions/Video';
import { ImageExt } from './extensions/Image';
import { VimeoExt } from './extensions/VimeoEmbed';
import { YoutubeExt } from './extensions/YoutubeEmbed';
import Document from '@tiptap/extension-document';
import Underline from '@tiptap/extension-underline';
import { AnyExtension } from '@tiptap/react';
import Focus from '@tiptap/extension-focus';
import { Highlight } from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';
import { ExtendedHorizontalRuleExt } from './extensions/HorizontalRule/ExtendedHorizontalRule';
import { ButtonExt } from './extensions/Button';
import { CustomLinkExt } from './extensions/Link';
import { ColumnsExt } from './extensions/MultipleColumns';
import { TodoListExt, TodoListItemExt } from './extensions/TodoList';
import {
  ToggleListExt,
  ToggleListItemExt,
  ToggleListItemContentExt,
} from './extensions/ToggleList';
import {
  EmbedContentExt,
  EmbedContentBlockExt,
} from './extensions/EmbedContent';
import { IframeExt } from './extensions/Iframe';
import { VideoRecordExt } from './extensions/VideoRecord';
import { CodeBlockExt } from './extensions/CodeBlock';

export const EditorDocument = Document.extend({
  content: 'block+',
});

export const sharedExtensions = [
  StarterKit.configure({
    document: false,
    horizontalRule: false,
    codeBlock: false,
    heading: { levels: [2, 3, 4, 5, 6] },
    dropcursor: {
      width: 0,
      class: 'hidden',
    },
  }),
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'listItem'],
  }),
  Focus,
  ExtendedHorizontalRuleExt,
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlighted',
    },
  }),
] as AnyExtension[];

export const sharedMainEditorExtensions = [
  ToggleListExt,
  ToggleListItemExt,
  ToggleListItemContentExt,
  ...sharedExtensions,
  CustomLinkExt,
  CalloutExt,
  SnippetBlockExt,
  EditorDocument,
  CustomTableExt,
  CustomTableCellExt,
  TableHeader,
  TableRow,
  FilePreviewExt,
  ImageExt,
  VideoExt,
  VideoRecordExt,
  ResizeableFigureExt,
  YoutubeExt.configure({ controls: true, allowFullscreen: true }),
  VimeoExt.configure({ controls: true, allowFullscreen: true }),
  Color,
  TextStyle,
  ColumnsExt,
  TodoListItemExt.configure({
    nested: false,
  }),
  TodoListExt,
  ButtonExt,
  EmbedContentBlockExt,
  EmbedContentExt,
  IframeExt,
  CodeBlockExt,
];
