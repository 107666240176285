import React, { FC } from 'react';
import { Button, Icon, Modal } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type IProps = {
  isOpen: boolean;
  videoUrl: string;
  onClose: () => void;
};

export const WatchVideoModal: FC<IProps> = ({ isOpen, videoUrl, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      isShowCancelButton={false}
      onClose={() => null}
      className="!w-240 !h-140 !p-0 relative flex max-h-screen"
    >
      <Button
        color={'secondary'}
        variant="icon"
        className="text-gray-500 !absolute z-10 -right-11 top-0 md:!right-4 md:!top-4"
        onClick={onClose}
        size="sm"
      >
        <Icon glyph={IconMap.XClose} width={24} />
      </Button>
      <div className="relative w-full h-full overflow-hidden rounded-xl flex">
        <video
          controls
          autoPlay
          src={videoUrl}
          className="grow-1 h-full bg-base-black"
        ></video>
      </div>
    </Modal>
  );
};
