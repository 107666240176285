import { SnippetCategoryWithSnippets } from '@distribute/shared/types';

export const getReorderedCategories = (
  categories: SnippetCategoryWithSnippets[],
  sourceIndex: number,
  destinationIndex: number
): SnippetCategoryWithSnippets[] => {
  const reorderedCategories = [...categories];
  const sourceCategory = reorderedCategories.splice(sourceIndex - 1, 1);

  reorderedCategories.splice(destinationIndex - 1, 0, ...sourceCategory);

  return reorderedCategories.map((category, index) => ({
    ...category,
    order: index + 1,
  }));
};
