import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  SidebarStepsEnum,
  editorSidebarModel,
} from '../../../../features/editor-sidebar';

type Props = {
  index: number;
  text: string;
  sidebarKey: SidebarStepsEnum;
};

export const EditorSidebarHeaderItem: React.FC<Props> = ({
  index,
  text,
  sidebarKey,
}) => {
  const dispatch = useDispatch();
  const selectedSidebarStep = useSelector(
    editorSidebarModel.selectors.selectSidebarStep
  );

  const isActive = selectedSidebarStep === sidebarKey;

  const handleClick = () => {
    dispatch(editorSidebarModel.actions.setSelectedSidebarStep(sidebarKey));
  };

  return (
    <div
      className={cn('h-8 px-2 cursor-pointer', {
        'border-b-2 border-primary-700': isActive,
      })}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <div
          className={cn(
            'w-4 h-4 flex items-center justify-center rounded-full text-xxs font-bold',
            {
              'text-base-white bg-primary-600': isActive,
              'text-base-white bg-gray-500': !isActive,
            }
          )}
        >
          {index}
        </div>
        <span
          className={cn('text-sm font-semibold', {
            'text-primary-700': isActive,
            'text-gray-500': !isActive,
          })}
        >
          {text}
        </span>
      </div>
    </div>
  );
};
