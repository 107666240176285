(function () {
  'use strict';
  var i = ((n) => (
    (n.TemplatesPreview = 'templates:preview'),
    (n.TemplatesSelect = 'templates:select'),
    (n.TemplatesResize = 'templates:resize'),
    (n.TemplatesDevice = 'templates:device'),
    (n.TemplatesMenuResize = 'templates:menu:resize'),
    (n.TemplatesAskForDevice = 'templates:ask-for-device'),
    (n.TemplatesMenuSelect = 'templates:menu:select'),
    (n.TemplatesMenuSearch = 'templates:menu:search'),
    (n.TemplatesAskForResize = 'templates:ask-for-resize'),
    (n.TemplatesPreviewClose = 'templates:preview:close'),
    (n.TemplatesChangeParentSize = 'templates:change-parent-size'),
    n
  ))(i || {});
  window.coordinator = (function () {
    let n = '';
    const d = () => Math.random().toString(36).substring(2, 15),
      D = `templates-preview-distribute-${d()}`,
      y = `templates-preview-overlay-distribute-${d()}`,
      B = `templates-distribute-${d()}`,
      L = `templates-menu-distribute-${d()}`,
      g = `templates-wrapper-${d()}`,
      b = 768,
      T = 594,
      k = 64;
    let c,
      v,
      m,
      s,
      E = [];
    const l = { mobile: 'mobile', desktop: 'desktop' };
    let u = l.desktop;
    const f = (e, t, { style: a, ...o }) => {
        const r = document.createElement('iframe');
        return (
          (r.id = e),
          (r.src = t),
          a &&
            Object.entries(a).forEach(([p, h]) => {
              r.style[p] = h;
            }),
          Object.entries(o).forEach(([p, h]) => {
            r[p] = h;
          }),
          (r.style.border = 'none'),
          r
        );
      },
      P = (e, t) => {
        window.open(`${t}/templates/apply/${e}`, '_blank');
      },
      W = (e) => {
        w([c], i.TemplatesMenuSelect, { category: e });
      },
      A = (e) => {
        w([c], i.TemplatesMenuSearch, { search: e });
      },
      I = () => {
        const t = s.getBoundingClientRect().width < b;
        w([c, m, v], i.TemplatesDevice, { device: t ? l.mobile : l.desktop });
      },
      $ = () => {
        const e = s.getBoundingClientRect().width,
          t = e < b,
          a = c.getBoundingClientRect().width;
        w([c], i.TemplatesChangeParentSize, {
          iframeWidth: a,
          wrapperWidth: e,
        });
        const o = t ? l.mobile : l.desktop;
        return (
          u !== o && ((u = o), I()),
          { iframeWidth: a, wrapperWidth: e, isMobileView: t }
        );
      },
      z = (e) => {
        var a;
        if (e.origin !== n) return;
        const { data: t } = e;
        if (t.type)
          switch (t.type) {
            case i.TemplatesPreview:
              document.body.append(H(t.payload.id));
              break;
            case i.TemplatesSelect:
              P(t.payload.id, n);
              break;
            case i.TemplatesResize:
              const { width: o, height: r } = t.payload,
                p = parseInt(m.style.height, 10),
                h = u === l.mobile ? r + p + k : r;
              (s.style.height = `${Math.max(p, h)}px`),
                (c.style.height = r ? `${Math.max(p, r)}px` : 'auto'),
                (s.style.width = o);
              break;
            case i.TemplatesMenuResize:
              const { height: M } = t.payload,
                j = parseInt(s.style.height, 10);
              (s.style.height = `${Math.max(j, M)}px`),
                (m.style.height = `${M}px`);
              break;
            case i.TemplatesAskForDevice:
              I();
              break;
            case i.TemplatesMenuSelect:
              W(t.payload.category);
              break;
            case i.TemplatesMenuSearch:
              A(t.payload.search);
              break;
            case i.TemplatesAskForResize:
              const { isMobileView: U } = $();
              R(U);
              break;
            case i.TemplatesPreviewClose:
              (a = document.getElementById(y)) == null || a.remove();
              break;
          }
      },
      S = () => {
        const { isMobileView: e } = $();
        R(e);
      },
      x = () => {
        m.style.top = `${C()}px`;
      },
      R = (e) => {
        (u = e ? l.mobile : l.desktop),
          (s.style.flexDirection = e ? 'column' : 'row'),
          (m.style.width = e ? '100%' : `${T}px`);
      },
      w = (e, t, a) => {
        e.forEach((o) => {
          var r;
          (r = o == null ? void 0 : o.contentWindow) == null ||
            r.postMessage({ type: t, payload: a }, n);
        });
      },
      F = () => {
        var e, t;
        window.removeEventListener('message', z),
          window.removeEventListener('resize', S),
          window.removeEventListener('scroll', x),
          (e = document.getElementById(g)) == null || e.remove(),
          (t = document.getElementById(y)) == null || t.remove();
      },
      H = (e) => {
        const t = document.createElement('div');
        return (
          (t.id = y),
          (t.style.position = 'fixed'),
          (t.style.top = '0'),
          (t.style.left = '0'),
          (t.style.width = '100%'),
          (t.style.height = '100%'),
          (v = f(D, `${n}/iframe/templates-iframe/preview/${e}`, {
            style: { width: '100%', height: '100%' },
          })),
          t.append(v),
          t
        );
      },
      C = () =>
        E.reduce((e, t) => {
          var a, o;
          return (
            e +
            (((o =
              typeof t == 'string'
                ? (a = document.getElementById(t)) == null
                  ? void 0
                  : a.getBoundingClientRect()
                : t.getBoundingClientRect()) == null
              ? void 0
              : o.height) ?? 0)
          );
        }, 0),
      O = () =>
        f(L, `${n}/iframe/templates-iframe/menu`, {
          style: {
            position: 'sticky',
            top: `${C()}px`,
            width: `${T}px`,
            height: '100%',
          },
        }),
      V = () =>
        f(B, `${n}/iframe/templates-iframe/templates`, {
          style: { width: '100%' },
        });
    return {
      coordinate: (e) => {
        if (!e) throw new Error('Missing element to render templates');
        window.addEventListener('message', z),
          window.addEventListener('resize', S),
          window.addEventListener('scroll', x),
          (s = document.createElement('div')),
          (s.id = g),
          (s.style.position = 'relative'),
          (s.style.display = 'flex'),
          (s.style.flexDirection = 'row'),
          (s.style.width = '100%'),
          (s.style.height = '100%'),
          (s.style.gap = `${k}px`),
          s.append((m = O())),
          s.append((c = V())),
          e.appendChild(s);
      },
      destroy: F,
      setServiceUrl: function (e) {
        n = e;
      },
      setRelatedTopContent: function (e) {
        E = e;
      },
    };
  })();
})();
