import { IconMap } from '../../../shared/sprite';
import { Icon, Tooltip } from '../../../shared/ui';
import React from 'react';

type Props = {
  distributeTeamLogo: string | null;
  isReadonly: boolean;
};

export const DistributeTeamLogo: React.FC<Props> = ({
  distributeTeamLogo,
  isReadonly,
}) => {
  return (
    <Tooltip
      trigger={
        <div className="w-16 h-16 relative cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            className="svg-shadow-sm"
          >
            <mask id="distribute-team-mask" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64 10.4332C64 4.67109 59.3289 0 53.5668 0H12C5.37258 0 0 5.37258 0 12V52C0 58.6274 5.37258 64 12 64H53.5668C59.3289 64 64 59.3289 64 53.5668C64 48.1373 59.1649 43.8053 55.72 39.6086C54.0203 37.5379 53 34.8881 53 32C53 29.1119 54.0203 26.4621 55.72 24.3914C59.1649 20.1947 64 15.8627 64 10.4332Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64 10.4332C64 4.67109 59.3289 0 53.5668 0H12C5.37258 0 0 5.37258 0 12V52C0 58.6274 5.37258 64 12 64H53.5668C59.3289 64 64 59.3289 64 53.5668C64 48.1373 59.1649 43.8053 55.72 39.6086C54.0203 37.5379 53 34.8881 53 32C53 29.1119 54.0203 26.4621 55.72 24.3914C59.1649 20.1947 64 15.8627 64 10.4332Z"
              fill={distributeTeamLogo ? 'url(#pattern1)' : 'white'}
            />
            <path
              d="M55.72 24.3914L54.9471 23.7569L55.72 24.3914ZM55.72 39.6086L54.9471 40.2431L55.72 39.6086ZM12 1H53.5668V-1H12V1ZM1 52V12H-1V52H1ZM53.5668 63H12V65H53.5668V63ZM56.493 38.9741C54.9346 37.0757 54 34.6483 54 32H52C52 35.1279 53.1059 38.0001 54.9471 40.2431L56.493 38.9741ZM54 32C54 29.3517 54.9346 26.9243 56.493 25.0259L54.9471 23.7569C53.1059 25.9999 52 28.8721 52 32H54ZM56.493 25.0259C58.1323 23.0288 60.3086 20.7667 61.9645 18.4573C63.6539 16.1013 65 13.4534 65 10.4332H63C63 12.8424 61.9286 15.0753 60.3392 17.2919C58.7164 19.555 56.7526 21.5573 54.9471 23.7569L56.493 25.0259ZM65 53.5668C65 50.5466 63.6539 47.8987 61.9645 45.5427C60.3086 43.2333 58.1323 40.9712 56.493 38.9741L54.9471 40.2431C56.7526 42.4427 58.7164 44.445 60.3392 46.7081C61.9286 48.9247 63 51.1576 63 53.5668H65ZM53.5668 65C59.8812 65 65 59.8812 65 53.5668H63C63 58.7766 58.7766 63 53.5668 63V65ZM-1 52C-1 59.1797 4.8203 65 12 65V63C5.92487 63 1 58.0751 1 52H-1ZM53.5668 1C58.7766 1 63 5.22338 63 10.4332H65C65 4.11881 59.8812 -1 53.5668 -1V1ZM12 -1C4.8203 -1 -1 4.8203 -1 12H1C1 5.92487 5.92487 1 12 1V-1Z"
              fill="black"
              fillOpacity="0.08"
              mask="url(#distribute-team-mask)"
            />
            {distributeTeamLogo && (
              <defs>
                <pattern
                  id="pattern1"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#distribute-team-image"
                    transform="scale(0.003125)"
                  />
                </pattern>
                <image
                  id="distribute-team-image"
                  width="320"
                  height="320"
                  xlinkHref={distributeTeamLogo}
                  preserveAspectRatio="xMidYMid slice"
                />
              </defs>
            )}
          </svg>
          {!distributeTeamLogo && (
            <Icon
              glyph={IconMap.Building07}
              width={20}
              className="text-gray-700 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          )}
        </div>
      }
      hideArrow
      sideOffset={4}
      {...{ isOpen: isReadonly || distributeTeamLogo ? false : undefined }}
    >
      <div className="px-1 py-2">
        <p className="text-xs font-semibold mb-1 text-base-white">
          Your brand logo
        </p>
        <p className="text-gray-300">Your brand logo can be added in</p>
        <p className="text-gray-300">{'Team workspace settings > '}</p>
        <p className="text-gray-300">Team Brand section</p>
      </div>
    </Tooltip>
  );
};
