import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { Button, Input, Modal, RadioButton } from '../../../../../shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mixed, number, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectMovingCategoryDropdown } from './SelectMovingCategoryDropdown';
import { snippetsModel } from '../../../model';
import {
  SnippetDestinyEnum,
  snippetDestinyRadioButtonText,
} from './lib/config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  category: SnippetCategoryWithSnippets;
};

type DeleteSnippetCategoryModalForm = {
  confirmText: string;
  snippetDestiny: SnippetDestinyEnum;
  moveCategoryId: number;
};

const CONFIRMATION_TEXT = 'CONFIRM';

const validationSchema = object().shape({
  confirmText: string()
    .required(`This field is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
  snippetDestiny: mixed<SnippetDestinyEnum>().oneOf(
    Object.values(SnippetDestinyEnum)
  ),
  moveCategoryId: number()
    .optional()
    .when('snippetDestiny', {
      is: SnippetDestinyEnum.MOVE,
      then: number().required('This field is required'),
    }),
});

export const DeleteSnippetCategoryConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  category,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    snippetsModel.selectors.selectIsDeleteSnippetCategoryLoading
  );

  const isCategoryEmpty = category.snippets.length === 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<DeleteSnippetCategoryModalForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      confirmText: isCategoryEmpty ? CONFIRMATION_TEXT : '',
      snippetDestiny: isCategoryEmpty
        ? SnippetDestinyEnum.DELETE
        : SnippetDestinyEnum.MOVE,
      moveCategoryId: undefined,
    },
  });

  const {
    field: { value: snippetDestinyValue, onChange: setSnippetDestiny },
  } = useController({
    control,
    defaultValue: isCategoryEmpty
      ? SnippetDestinyEnum.DELETE
      : SnippetDestinyEnum.MOVE,
    name: 'snippetDestiny',
  });

  const {
    field: { value: moveCategoryIdValue, onChange: setMoveCategoryId },
  } = useController({
    control,
    defaultValue: undefined,
    name: 'moveCategoryId',
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = (data: DeleteSnippetCategoryModalForm) => {
    dispatch(
      snippetsModel.actions.deleteSnippetCategory({
        id: category.id,
        name: category.name,
        moveCategoryId: data.moveCategoryId,
        callback: onModalClose,
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete Category"
      dialogClassName="!z-100"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Forever
        </Button>
      }
    >
      {isCategoryEmpty ? (
        <p className="font-normal text-gray-600 text-s">
          The “{category.name}” category will be deleted permanently.
        </p>
      ) : (
        <p className="mb-4 font-normal text-gray-600 text-s">
          The “{category.name}” category will be deleted permanently. Deleting
          doesn’t affect any page that included snippets from this category.
        </p>
      )}

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {!isCategoryEmpty && (
          <div>
            <p className="mb-2 font-normal text-gray-600 text-s">
              What should we do with snippets from this category:
            </p>
            <div className="flex flex-col gap-2">
              {Object.values(SnippetDestinyEnum).map((value) => (
                <RadioButton
                  name="snippet-destiny"
                  label={snippetDestinyRadioButtonText[value]}
                  key={value}
                  value={value}
                  onChange={() => setSnippetDestiny(value)}
                  defaultChecked={value === snippetDestinyValue}
                />
              ))}
            </div>
            {snippetDestinyValue === SnippetDestinyEnum.MOVE && (
              <div className="pl-6 mt-2">
                <SelectMovingCategoryDropdown
                  setCategoryId={setMoveCategoryId}
                  category={category}
                  categoryId={moveCategoryIdValue}
                  errorText={errors.moveCategoryId?.message}
                />
              </div>
            )}
            <div className="w-full h-px mt-4 bg-gray-200" />
            <div className="mt-4">
              <Input
                autoComplete="off"
                label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
                {...register('confirmText')}
                isError={!!errors.confirmText}
                messageText={errors.confirmText?.message}
                type="text"
              />
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};
