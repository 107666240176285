import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Input } from '../../../../shared/ui';
import { profileSettingModel } from '../../model';
import { changeEmailSchema, ChangeEmailSchema } from '../../lib';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeEmailModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<ChangeEmailSchema>({
    resolver: yupResolver(changeEmailSchema),
  });
  const dispatch = useDispatch();
  const { email, password } = watch();
  const isFormChanged = isDirty && (email?.length > 0 || password?.length > 0);

  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsChangeEmailLoading
  );
  const onModalClose = () => {
    onClose();
    reset();
  };

  const handleFormSubmit = (data: ChangeEmailSchema) => {
    dispatch(
      profileSettingModel.actions.changeEmailClick({
        ...data,
        cb: () => {
          onModalClose();
        },
      })
    );
  };

  useConfirmWindowClose(isFormChanged, 'changeEmail');

  return (
    <Modal
      title="Change Email"
      onClose={onModalClose}
      isOpen={isOpen}
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          loading={isLoading}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          disabled={!isFormChanged}
        >
          Change Email
        </Button>
      }
    >
      <div className="w-full">
        <p className="text-s text-gray-600 mb-5">
          Please enter a new email and current password.
        </p>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-3"
        >
          <Input
            {...register('email')}
            isError={!!errors.email}
            autoComplete="username"
            messageText={errors.email?.message}
            label="New Email"
            type="email"
          />
          <Input
            {...register('password')}
            isError={!!errors.password}
            messageText={errors.password?.message}
            label="Current Password"
            type="password"
          />

          <input type="submit" hidden />
        </form>
      </div>
    </Modal>
  );
};
