import { call, put, select, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { SqueezePage } from '@distribute/shared/types';

import { squeezePageApi } from '../../../../shared/api';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../';
import { UpdateSqueezePageImageResponseType } from '@distribute/shared/api-types/squeeze-page';
import { checkIsDraftTemplate, getPageIdByContentId } from '../../lib';
import { templatesModel } from '../../../templates';
import { selectSqueezePage } from '../selectors';
import { updateSqueezePageDB } from './updateSqueezePageDB';

const uploadFileChannel = channel<number>();

export function* updateSqueezePage({
  payload: { documentContentId, callback, isRemoveImage, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updateSqueezePage>) {
  try {
    yield put(actions.setUpdateSqueezePageIsLoading(true));

    const squeezePage: SqueezePage = yield select(
      conversionKitModel.selectors.selectSqueezePage
    );

    yield put(
      actions.setSqueezePage({
        ...squeezePage,
        ...rest,
        imageUrl: isRemoveImage ? null : squeezePage.imageUrl,
      })
    );

    if (isRemoveImage) {
      yield call(squeezePageApi.removeSqueezePageImage, documentContentId);
    }

    const pageId: string | undefined = yield call(
      getPageIdByContentId,
      documentContentId
    );

    const isDraftTemplate: boolean = yield call(
      checkIsDraftTemplate,
      documentContentId
    );

    if (!pageId && !isDraftTemplate) {
      yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      yield call(
        updateSqueezePageDB,
        actions.updateSqueezePageDB({ documentContentId })
      );
    }

    if (callback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update Squeeze Page`)
      )
    );
  } finally {
    yield put(actions.setUpdateSqueezePageIsLoading(false));
  }
}

export function* changeSqueezePageImage({
  payload: { documentContentId, image },
}: ReturnType<typeof actions.changeSqueezePageImage>) {
  try {
    yield put(actions.setIsUploadImageLoading(true));

    const squeezePage = (yield squeezePageApi.updateSqueezePageImage(
      documentContentId,
      image,
      (progress) => uploadFileChannel.put(progress)
    )) as UpdateSqueezePageImageResponseType;

    const squeezePageFromState: SqueezePage = yield select(selectSqueezePage);

    yield put(
      actions.setSqueezePage({
        ...squeezePageFromState,
        imageUrl: squeezePage.imageUrl,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update Squeeze Page image`)
      )
    );
  } finally {
    yield put(actions.setIsUploadImageLoading(false));
    uploadFileChannel.put(0);
  }
}

export function* removeSqueezePageImage({
  payload: { documentContentId },
}: ReturnType<typeof actions.removeSqueezePageImage>) {
  try {
    yield put(actions.setIsRemoveSqueezePageImageLoading(true));

    yield call(squeezePageApi.removeSqueezePageImage, documentContentId);

    const squeezePageFromState: SqueezePage = yield select(selectSqueezePage);

    yield put(
      actions.setSqueezePage({
        ...squeezePageFromState,
        imageUrl: null,
      })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to remove Squeeze Page image`)
      )
    );
  } finally {
    yield put(actions.setIsRemoveSqueezePageImageLoading(false));
  }
}

export function* watchUploadSqueezePageImage() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;

    yield put(actions.setUploadSqueezePageImageProgress(progress));
  }
}
