import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import cn from 'classnames';
import { useOnClickOutside } from '../../../../../shared/hooks/useClickOutside';
import { Editor } from '@tiptap/react';
import { BubbleMenuColorPickerDropdown } from './BubbleMenuColorPickerDropdown';
import { DEFAULT_EDITOR_COLORS_CONFIG } from './config';

type Props = {
  editor: Editor;
};

export const BubbleMenuColorPicker: React.FC<Props> = ({ editor }) => {
  const [isOpen, setOpen] = useState(false);

  const currentColor =
    editor.getAttributes('textStyle').color ||
    DEFAULT_EDITOR_COLORS_CONFIG[0].value;

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const colorPickerIconRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!colorPickerIconRef.current) return;
    colorPickerIconRef.current.style.backgroundColor =
      currentColor ?? DEFAULT_EDITOR_COLORS_CONFIG[0].value;
  }, [currentColor]);

  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  return (
    <Tooltip
      hideArrow
      sideOffset={8}
      trigger={
        <div className="relative">
          <button
            type="button"
            onClick={() => setOpen((prev) => !prev)}
            className={cn(
              'p-1 text-gray-500 w-12 h-7 flex items-center justify-center gap-1 rounded hover:bg-gray-100'
            )}
          >
            <div
              ref={colorPickerIconRef}
              className="w-5 h-5 rounded-full bg-gray-900"
            ></div>
            <Icon glyph={IconMap.ArrowDown} width={16} />
          </button>
          {isOpen && (
            <BubbleMenuColorPickerDropdown
              editor={editor}
              currentColor={currentColor}
              ref={dropdownRef}
            />
          )}
        </div>
      }
    >
      <p className="text-sm font-medium">Font color</p>
    </Tooltip>
  );
};
