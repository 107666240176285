import { put, select } from 'redux-saga/effects';
import { foldersModel } from '..';
import { foldersApi } from '../../../../shared/api';
import { Folder } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateTeamMemberPermission({
  payload: { folderId, permission },
}: ReturnType<typeof foldersModel.actions.updateTeamMemberPermission>) {
  try {
    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    yield foldersApi.updateTeamMemberPermission(folderId, {
      permission,
    });
    const newFolders = folders.map((i) => {
      if (i.id === folderId) {
        return { ...i, teamMembersPermission: permission };
      }
      return i;
    });
    const currentFolder: Folder = yield select(
      foldersModel.selectors.selectCurrentFolder
    );
    if (currentFolder)
      yield put(
        foldersModel.actions.setCurrentFolder({
          ...currentFolder,
          teamMembersPermission: permission,
        })
      );

    yield put(foldersModel.actions.setFolders(newFolders));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team member permission')
      )
    );
  }
}
