import { User } from './user';

export enum UserPermissionToVideoRecordRole {
  OWNER = 'owner',
  VIEWER = 'viewer',
  EDITOR = 'editor',
}

export type UserPermissionToVideoRecord = {
  id: number;
  user: Pick<User, 'id' | 'email' | 'displayName' | 'photoUrl'>;
  role: UserPermissionToVideoRecordRole;
};
