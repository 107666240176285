import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

export const useOnClickOutside = <
  T extends HTMLElement = HTMLElement,
  U extends HTMLElement = HTMLElement
>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  btnId?: string,
  additionalRefs: RefObject<U>[] = []
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const els = [ref.current, ...additionalRefs.map((r) => r.current)];
      if (
        !els.length ||
        els.some((el) => el?.contains(event?.target as Node))
      ) {
        return;
      }
      const secondEl = btnId ? document.getElementById(btnId) : null;
      if (secondEl?.contains(event?.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, btnId, additionalRefs]);
};
