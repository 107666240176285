import React, { useState } from 'react';
import cn from 'classnames';
import { Modal, Button } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { snippetsModel } from '../../model';
import { ManageCategoryRow } from './ManageCategoryRow';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { DeleteSnippetCategoryConfirmationModal } from './delete-snippet-category';
import { CreateOrEditSnippetCategoryModal } from './CreateOrEditSnippetCategoryModal';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

export const ManageCategoriesModal: React.FC = () => {
  const dispatch = useDispatch();
  const snippetCategories = useSelector(
    snippetsModel.selectors.selectCategories
  );
  const isOpen = useSelector(
    snippetsModel.selectors.selectIsManageCategoriesModalOpen
  );

  const onModalClose = () => {
    dispatch(snippetsModel.actions.setIsManageCategoriesModalOpen(false));
  };

  const handleAddCategory = () => {
    dispatch(snippetsModel.actions.setIsManageCategoriesModalOpen(false));
    dispatch(snippetsModel.actions.setIsCreateCategoryModalOpen(true));
  };

  const [deletingCategory, setDeletingCategory] = useState<
    SnippetCategoryWithSnippets | undefined
  >(undefined);

  const [editingCategory, setEditingCategory] = useState<
    SnippetCategoryWithSnippets | undefined
  >(undefined);

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch(
      snippetsModel.actions.reorderCategories({
        destinationIndex: +result.destination.index,
        sourceIndex: +result.source.index,
      })
    );
  };

  return (
    <>
      <Modal
        onClose={onModalClose}
        isOpen={isOpen}
        title="Manage Categories"
        description="Organize snippets categories for your team."
        isShowCloseIconInTitle
        isShowCancelButton={false}
        className="w-120"
        actionButton={
          <Button
            onClick={handleAddCategory}
            type="submit"
            color="primary"
            variant="text"
            className="text-sm -mt-5"
            size="md"
          >
            {snippetCategories.length > 0
              ? 'Add another category'
              : 'Add category'}
          </Button>
        }
      >
        <div className="border border-gray-200 rounded-xl">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableSnippetCategories">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {snippetCategories.map((category, index) => (
                    <Draggable
                      isDragDisabled={snippetCategories.length === 1}
                      key={category.id}
                      draggableId={`${category.id}`}
                      index={index + 1}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            key={category.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            className={cn('border-b border-gray-200', {
                              'border-none':
                                index === snippetCategories.length - 1 ||
                                snapshot.isDragging,
                            })}
                          >
                            <ManageCategoryRow
                              category={category}
                              key={category.id}
                              setDeletingCategory={setDeletingCategory}
                              setEditingCategory={setEditingCategory}
                              isDragging={snapshot.isDragging}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>

      {deletingCategory && (
        <DeleteSnippetCategoryConfirmationModal
          isOpen={!!deletingCategory}
          onClose={() => {
            setDeletingCategory(undefined);
          }}
          category={deletingCategory}
        />
      )}
      {editingCategory && (
        <CreateOrEditSnippetCategoryModal
          isOpen={!!editingCategory}
          onClose={() => {
            dispatch(
              snippetsModel.actions.setIsManageCategoriesModalOpen(true)
            );
            setEditingCategory(undefined);
          }}
          category={editingCategory}
        />
      )}
    </>
  );
};
