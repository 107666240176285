import { SubscriptionStatus, TeamFiltered } from '@distribute/shared/types';

export const checkIsGettingStartedAvailable = (team: TeamFiltered) => {
  if (team.isAppsumo) {
    return !team.currentMember.isGettingStartedClosed;
  }

  return (
    !team.currentMember.isGettingStartedClosed &&
    team?.stripeSubscription?.status === SubscriptionStatus.TRIALING
  );
};
