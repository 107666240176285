import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GettingStartedStep } from '@distribute/shared/types';
import { GettingStartedStepItem } from '../ui/components';
import { IconMap } from '../../../shared/sprite';
import { CreatePage } from '../../pages';
import { Button, Icon } from '../../../shared/ui';
import { redirectActions } from '../../../entities/history';
import { useHandlerInviteTeamMembers } from '../../team-members';
import { teamsModel } from '../../teams';
import { extensionModel } from '../../extension';

export const useGettingStartedSteps = () => {
  const dispatch = useDispatch();
  const currentTeamMemberData = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );
  const isExtensionEventLoading = useSelector(
    extensionModel.selectors.selectIsExtensionEventLoading
  );

  const handlerInviteTeamMembers = useHandlerInviteTeamMembers();

  return useMemo<GettingStartedStepItem[]>(
    () => [
      {
        id: GettingStartedStep.CREATE_ACCOUNT,
        title: 'Create Account',
        isCompleted: true,
        isDisabled: true,
      },
      {
        id: GettingStartedStep.DOWNLOAD_CHROME_EXTENSION,
        title: 'Download Chrome Extension',
        description:
          'The Distribute Chrome Extension simplifies recording short, personalised videos from any webpage, enabling you to quickly and seamlessly create visually appealing pages with video content.',
        isCompleted:
          currentTeamMemberData.gettingStartedCompletedSteps.includes(
            GettingStartedStep.DOWNLOAD_CHROME_EXTENSION
          ),
        time: '1 min',
        actionBtn: (
          <Button
            variant="text"
            size="sm"
            color="primary"
            onClick={() =>
              dispatch(extensionModel.actions.extensionInstallClick())
            }
          >
            Get Chrome Extension
          </Button>
        ),
      },
      {
        id: GettingStartedStep.RECORD_FIRST_VIDEO,
        title: 'Record your first video',
        description:
          'Record a video of yourself or your solution to be featured on your future page.',
        isCompleted:
          currentTeamMemberData.gettingStartedCompletedSteps.includes(
            GettingStartedStep.RECORD_FIRST_VIDEO
          ),
        time: '3 min',
        actionBtn: (
          <Button
            variant="text"
            color="primary"
            size="sm"
            loading={isExtensionEventLoading}
            disabled={isExtensionEventLoading}
            onClick={() => dispatch(extensionModel.actions.openClick())}
          >
            <Icon glyph={IconMap.VideoRecorder} width={20} className="mr-2" />
            New Recording
          </Button>
        ),
      },
      {
        id: GettingStartedStep.CREATE_PAGE,
        title: 'Create your first page',
        description:
          'Create your first page using a customizable template or from scratch. Take advantage of the Distribute editor, AI assistant, and conversion tools.',
        actionBtn: (
          <CreatePage
            renderButton={(onClick, isLoading) => (
              <Button
                variant="icon-text"
                color="primary"
                type="button"
                onClick={onClick}
                loading={isLoading}
                size="sm"
                iconLeftName={IconMap.Plus}
                iconLeftWidth={20}
              >
                Create Page
              </Button>
            )}
          />
        ),
        videoUrl:
          'https://storage.googleapis.com/distribute-production-storage/general/gettingStartedCreatePage.mp4',
        isCompleted:
          currentTeamMemberData.gettingStartedCompletedSteps.includes(
            GettingStartedStep.CREATE_PAGE
          ),
        time: '3 min',
      },
      {
        id: GettingStartedStep.PUBLISH_PAGE,
        title: 'Share publicly',
        description:
          'Customize your page options, share it publicly and test your conversion tools.',
        actionBtnIcon: IconMap.File02,
        actionBtnTitle: 'Go to All  Pages',
        onClickAction: () => dispatch(redirectActions.toWorkspace()),
        videoUrl:
          'https://storage.googleapis.com/distribute-production-storage/general/gettingStartedPublish.mp4',
        isCompleted:
          currentTeamMemberData.gettingStartedCompletedSteps.includes(
            GettingStartedStep.PUBLISH_PAGE
          ),
        time: '4 min',
      },
      {
        id: GettingStartedStep.INVITE_TEAM_MEMBER,
        title: 'Invite a team member',
        description:
          'You can invite your teammates and experiment with various permissions.',
        actionBtnIcon: IconMap.UserPlus,
        actionBtnTitle: 'Invite Members',
        onClickAction: handlerInviteTeamMembers,
        videoUrl:
          'https://storage.googleapis.com/distribute-production-storage/general/gettingStartedInviteTeamMember.mp4',
        isCompleted:
          currentTeamMemberData.gettingStartedCompletedSteps.includes(
            GettingStartedStep.INVITE_TEAM_MEMBER
          ),
        time: '2 min',
      },
    ],
    [
      dispatch,
      handlerInviteTeamMembers,
      currentTeamMemberData.gettingStartedCompletedSteps,
      isExtensionEventLoading,
    ]
  );
};
