import { call, put } from 'redux-saga/effects';
import { Page } from '@distribute/shared/types';
import { redirect } from '../../../../entities/history';
import { getOrCreateFirstPage } from './getOrCreateFirstPage';
import { getQueryParam, logger } from '../../../../shared/lib';
import { QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE } from '../../../public-template';
import { publicTemplatesModel } from '../../../public-template';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* finishOnboarding() {
  try {
    const publicTemplatePageId = getQueryParam(
      QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE
    );

    if (publicTemplatePageId) {
      yield call(publicTemplatesModel.sagas.handlePublicTemplates, true, true);
    } else {
      const page: Page = yield call(getOrCreateFirstPage);

      yield call(redirect.toEditorPage, {
        sequenceNumber: page.sequenceNumber,
      });
    }
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to finish onboarding.')
      )
    );
  }
}
