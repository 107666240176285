import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isOpen: boolean;
};

const initialState: State = {
  isOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'squeezePageModel',
  initialState,
  reducers: {
    setIsOpen: (state, { payload: isOpen }: PayloadAction<boolean>) => ({
      ...state,
      isOpen,
    }),
  },
});

export const actions = {
  ...reducerActions,
};
