import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { squeezePageModel } from '../model';
import { conversionKitModel } from '../../conversion-kit';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type IProps = {
  isDisabled: boolean;
};

export const SqueezePageConversionControl: React.FC<IProps> = ({
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePageWithError
  );
  const isOpen = useSelector(squeezePageModel.selectors.selectIsOpen);
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const toggleIsSubscribeSqueezePage = useCallback(() => {
    dispatch(squeezePageModel.actions.setIsOpen(!isOpen));
  }, [dispatch, isOpen]);

  const handleOpenSqueezePageSettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.SQUEEZE_PAGE
      )
    );
  };

  if (!squeezePage.isActive || isAnyConversionPanelOpen) return null;

  return (
    <ConversionControlItem
      title="Squeeze Page"
      icon={IconMap.SqueezePageIcon}
      isOpened={isOpen}
      isDisabled={isDisabled}
      onEdit={handleOpenSqueezePageSettings}
      onPreview={toggleIsSubscribeSqueezePage}
    />
  );
};
