import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { OnboardingSteps, onboardingModel } from '../../../features/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import {
  TeamOnboardingStatus,
  UserOnboardingStatus,
} from '@distribute/shared/types';
import { redirectActions } from '../../../entities/history';
import { teamsModel, useTeamPermissions } from '../../../features/teams';

export const Onboarding = () => {
  const isLoadingFinishTeamOnboarding = useSelector(
    onboardingModel.selectors.selectIsLoadingFinishTeamOnboarding
  );
  const { onboardingStatus: userOnboardingStatus } = useSelector(
    authUserModel.selectors.selectUserWithError
  );
  const currentTeam = useSelector(teamsModel.selectors.selectCurrentTeam);
  const currentTeamPermissions = useTeamPermissions();
  const dispatch = useDispatch();

  const isOnboardingFinished =
    userOnboardingStatus === UserOnboardingStatus.FINISHED &&
    (!currentTeamPermissions.isOwner ||
      currentTeam?.onboardingStatus === TeamOnboardingStatus.FINISHED);

  useEffect(() => {
    if (!isLoadingFinishTeamOnboarding && isOnboardingFinished) {
      dispatch(redirectActions.toWorkspace());
    }
  }, [dispatch, isLoadingFinishTeamOnboarding, isOnboardingFinished]);

  return (
    <>
      <Helmet title="Onboarding" />
      <OnboardingSteps />
    </>
  );
};
