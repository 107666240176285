import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../entities/auth-user';
import { teamsModel } from '../model';
import { getSnippetsPermissions } from '../lib';

export const useSnippetsPermissions = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const teamPermissions = useSelector(
    teamsModel.selectors.selectTeamPermissionsWithError
  );

  const permissions = useMemo(() => {
    return getSnippetsPermissions(
      currentUser,
      currentTeamMembers,
      teamPermissions
    );
  }, [currentUser, currentTeamMembers, teamPermissions]);

  return { ...permissions };
};
