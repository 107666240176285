import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { GONG_ACCESS_TOKEN_STORAGE_KEY } from '../../../shared/constants';
import { ConnectToGongAction, CreateGongTeamSecretsAction } from './types';
import {
  FormattedGongTranscript,
  GetFormattedCallTranscriptsResponseType,
  GongCallPartyType,
} from '@distribute/shared/api-types/gong';

type State = {
  isConnected: boolean;
  isConnecting: boolean;
  isLoadingTranscripts: boolean;
  callTranscripts: FormattedGongTranscript[] | null;
  uniqueParties: GongCallPartyType[] | null;
  isCreatingTeamSecrets: boolean;
  isDeletingTeamSecrets: boolean;
};

const gongAccessToken = localStorage.getItem(GONG_ACCESS_TOKEN_STORAGE_KEY);

const initialState: State = {
  isConnected: !!gongAccessToken,
  isConnecting: false,
  callTranscripts: null,
  uniqueParties: null,
  isLoadingTranscripts: false,
  isCreatingTeamSecrets: false,
  isDeletingTeamSecrets: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'gong',
  initialState,
  reducers: {
    setIsConnected: (
      state,
      { payload: isConnected }: PayloadAction<boolean>
    ) => ({ ...state, isConnected }),
    setIsConnecting: (
      state,
      { payload: isConnecting }: PayloadAction<boolean>
    ) => ({ ...state, isConnecting }),
    setIsLoadingTranscripts: (
      state,
      { payload: isLoadingTranscripts }: PayloadAction<boolean>
    ) => ({ ...state, isLoadingTranscripts }),
    setCallTranscriptsData: (
      state,
      {
        payload: callTranscriptsData,
      }: PayloadAction<GetFormattedCallTranscriptsResponseType | null>
    ) => ({
      ...state,
      callTranscripts: callTranscriptsData?.formattedTranscripts ?? null,
      uniqueParties: callTranscriptsData?.uniqueParties ?? null,
    }),
    setIsCreatingTeamSecrets: (
      state,
      { payload: isCreatingTeamSecrets }: PayloadAction<boolean>
    ) => ({ ...state, isCreatingTeamSecrets }),
    setIsDeletingTeamSecrets: (
      state,
      { payload: isDeletingTeamSecrets }: PayloadAction<boolean>
    ) => ({ ...state, isDeletingTeamSecrets }),
  },
});

export const actions = {
  ...reducerActions,
  connectToGong: createAction<ConnectToGongAction>('gong/connectToGong'),
  disconnectFromGong: createAction('gong/disconnectFromGong'),
  getCallTranscripts: createAction('gong/getCallTranscripts'),
  generateGongApiKey: createAction('gong/generateApiKey'),
  createGongTeamSecrets: createAction<CreateGongTeamSecretsAction>(
    'gong/createGongTeamSecrets'
  ),
  deleteGongTeamSecrets: createAction('gong/deleteGongTeamSecrets'),
};
