import { Node, RawCommands } from '@tiptap/core';
import type { CommandProps, Editor } from '@tiptap/core';
import { Node as ProseMirrorNode, NodeType } from 'prosemirror-model';
import { NodeSelection } from 'prosemirror-state';
import { Column } from './Column';
import { ColumnSelection } from './ColumnSelection';
import {
  buildColumn,
  buildNColumns,
  buildColumnBlock,
  findParentNodeClosestToPos,
  NoAncestorFoundError,
} from './utils';
import type { FragmentWithContent, Predicate } from './utils';
import { JSONContent } from '@tiptap/react';
import { COLUMN_WIDTH_DATA_ATTRIBUTE } from './config';
import { TextSelection } from '@tiptap/pm/state';
import { buildParagraph } from './utils';
import { ColumnBlockExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    columnBlock: {
      setColumns: (columns: JSONContent[]) => ReturnType;
      insertColumns: (
        count: number,
        keepContent?: boolean,
        columns?: JSONContent[]
      ) => ReturnType;
      unsetColumns: (keepContent?: boolean) => ReturnType;
      deleteOneColumn: (event?: KeyboardEvent) => ReturnType;
      duplicateOneColumn: (
        column: ProseMirrorNode,
        position: number
      ) => ReturnType;
    };
  }
}

export interface ColumnBlockOptions {
  nestedColumns: boolean;
  columnType: Node;
}
const COLUMN_BLOCK_NAME_TYPE = 'columnBlock';

const unsetColumnsIfOneLeft = (editor: Editor) => {
  editor.state.doc.forEach((node, offset) => {
    if (
      node.type.name === COLUMN_BLOCK_NAME_TYPE &&
      node.childCount === 2 &&
      (node.lastChild?.content.size === 0 ||
        node.firstChild?.content.size === 0)
    ) {
      editor
        .chain()
        .focus()
        .setTextSelection(offset + 1)
        .unsetColumns()
        .run();
    }
  });
};

const updateColumnsWidth = (editor: Editor) => {
  editor.state.doc.forEach((node, offset) => {
    if (node.type.name !== COLUMN_BLOCK_NAME_TYPE) {
      return;
    }
    if (node.childCount <= 1) {
      return;
    }

    const childNodes: ProseMirrorNode[] = [];
    node.forEach((node) => childNodes.push(node));

    const nodesWidth = childNodes.reduce((acc, i) => {
      acc += i.attrs[COLUMN_WIDTH_DATA_ATTRIBUTE] || 0;
      return acc;
    }, 0);

    const tr = editor.state.tr;
    tr.setMeta('addToHistory', false);

    let pos = offset + 1;

    const isColumnAdded = childNodes.some(
      (i) => !i.attrs[COLUMN_WIDTH_DATA_ATTRIBUTE]
    );

    if (nodesWidth < 99 || isColumnAdded) {
      const allowedWidth = isColumnAdded
        ? nodesWidth - 100 / childNodes.length
        : nodesWidth;

      childNodes.forEach((i) => {
        const nodeWidth = i.attrs[COLUMN_WIDTH_DATA_ATTRIBUTE] || 0;
        const isColumnNode =
          editor.state.doc.nodeAt(pos)?.type.name === 'column';

        if (isColumnNode) {
          const widthValue = (() => {
            if (nodeWidth === 0) {
              return 100 / childNodes.length;
            }
            if (nodesWidth < 99) {
              return (nodeWidth * 100) / nodesWidth;
            }
            return (nodeWidth * allowedWidth) / 100;
          })();

          tr.setNodeAttribute(pos, COLUMN_WIDTH_DATA_ATTRIBUTE, widthValue);
        }
        pos += i.nodeSize;
      });
    }

    editor.view.dispatch(tr);
  });
};

export const ColumnBlock = ColumnBlockExt.extend<ColumnBlockOptions>({
  onUpdate() {
    unsetColumnsIfOneLeft(this.editor);
    updateColumnsWidth(this.editor);
  },
  addOptions() {
    return {
      nestedColumns: false,
      columnType: Column,
    };
  },

  addCommands(): Partial<RawCommands> {
    const unsetColumns =
      (keepContent = true) =>
      ({ tr, dispatch }: CommandProps) => {
        try {
          if (!dispatch) {
            return;
          }

          // find the first ancestor
          const pos = tr.selection.$from;
          const where: Predicate = ({ node }) => {
            if (!this.options.nestedColumns && node.type == this.type) {
              return true;
            }
            return node.type == this.type;
          };
          const firstAncestor = findParentNodeClosestToPos(pos, where);
          if (firstAncestor === undefined) {
            return;
          }

          // find the content inside of all the columns
          let nodes: Array<ProseMirrorNode> = [];
          firstAncestor.node.descendants((node, _, parent) => {
            if (parent?.type.name === Column.name) {
              nodes.push(node);
            }
          });
          nodes = nodes.reverse().filter((node) => node.content.size > 0);

          // resolve the position of the first ancestor
          const resolvedPos = tr.doc.resolve(firstAncestor.pos);
          const sel = new NodeSelection(resolvedPos);

          // insert the content inside of all the columns and remove the column layout
          tr = tr.setSelection(sel);

          let selectionTo = firstAncestor.pos;

          nodes.forEach((node) => {
            tr = tr.insert(firstAncestor.pos, node);
            selectionTo += node.nodeSize;
          });

          tr = tr.deleteSelection();
          if (!keepContent) {
            tr = tr.insertText('\n', firstAncestor.pos, selectionTo);
            tr = tr.setSelection(
              new NodeSelection(tr.doc.resolve(firstAncestor.pos + 1))
            );
          }

          return dispatch(tr);
        } catch (error) {
          if (error instanceof NoAncestorFoundError) return;

          console.error(error);
        }
      };

    const deleteOneColumn =
      (event?: KeyboardEvent) =>
      ({ commands, view }: CommandProps) => {
        try {
          const pos = view.state.selection.$from;
          const columnNodePredicate: Predicate = ({ node }) => {
            if (!this.options.nestedColumns && node.type.name === 'column') {
              return true;
            }
            return node.type.name === 'column';
          };

          const columnBlockPredicate: Predicate = ({ node }) => {
            if (!this.options.nestedColumns && node.type === this.type) {
              return true;
            }
            return node.type === this.type;
          };

          const columnNode = findParentNodeClosestToPos(
            pos,
            columnNodePredicate
          );
          const columnBlock = findParentNodeClosestToPos(
            pos,
            columnBlockPredicate
          );

          if (!columnBlock || !columnNode) {
            return false;
          }

          if (columnNode.node.content.size === 2) {
            event?.preventDefault();

            if (
              (columnBlock.node.content as FragmentWithContent).content
                .length === 2
            ) {
              commands.deleteNode('column');
            } else {
              commands.deleteNode('column');
              commands.setTextSelection(pos.pos - 4);
            }
            return true;
          }
        } catch (error) {
          if (error instanceof NoAncestorFoundError) return false;

          console.error(error);
        }
        return false;
      };

    const insertColumns =
      (n: number, keepContent = false, columns: JSONContent[] = []) =>
      ({ tr, dispatch }: CommandProps) => {
        try {
          const { doc, selection } = tr;
          if (!dispatch) {
            console.error('no dispatch');
            return;
          }

          const sel = new ColumnSelection(selection);
          sel.expandSelection(doc);

          const { openStart, openEnd } = sel.content();
          if (openStart !== openEnd) {
            console.warn('failed depth check');
            return;
          }

          // create columns and put old content in the first column
          let columnBlock;
          if (keepContent) {
            const content = sel.content().toJSON();
            const firstColumn = buildColumn(content);
            const otherColumns = columns.length
              ? columns
              : buildNColumns(n - 1);
            columnBlock = buildColumnBlock({
              content: [firstColumn, ...otherColumns],
            });
          } else {
            const columns = buildNColumns(n);
            columnBlock = buildColumnBlock({ content: columns });
          }
          const newNode = doc.type.schema.nodeFromJSON(columnBlock);
          if (newNode === null) {
            return;
          }

          const parent = sel.$anchor.parent.type;
          const canAcceptColumnBlockChild = (par: NodeType) => {
            if (!par.contentMatch.matchType(this.type)) {
              return false;
            }

            if (!this.options.nestedColumns && par.name === Column.name) {
              return false;
            }

            return true;
          };
          if (!canAcceptColumnBlockChild(parent)) {
            console.warn('content not allowed');
            return;
          }

          tr = tr.setSelection(sel);
          tr = tr.replaceSelectionWith(newNode, false);
          return dispatch(tr);
        } catch (error) {
          console.error(error);
        }
      };

    const setColumns =
      (columns: JSONContent[] = []) =>
      ({ tr, dispatch }: CommandProps) => {
        try {
          const { doc, selection } = tr;
          if (!dispatch) {
            console.error('no dispatch');
            return;
          }

          const sel = new ColumnSelection(selection);
          sel.expandSelection(doc);

          const { openStart, openEnd } = sel.content();
          if (openStart !== openEnd) {
            console.warn('failed depth check');
            return;
          }

          const columnBlock = buildColumnBlock({
            content: columns,
          });

          const newNode = doc.type.schema.nodeFromJSON(columnBlock);
          if (newNode === null) {
            return;
          }

          const parent = sel.$anchor.parent.type;
          const canAcceptColumnBlockChild = (par: NodeType) => {
            if (!par.contentMatch.matchType(this.type)) {
              return false;
            }

            if (!this.options.nestedColumns && par.name === Column.name) {
              return false;
            }

            return true;
          };
          if (!canAcceptColumnBlockChild(parent)) {
            console.warn('content not allowed');
            return;
          }

          tr = tr.setSelection(sel);
          tr = tr.replaceSelectionWith(newNode, false);
          tr.setSelection(TextSelection.near(tr.doc.resolve(sel.from)));
          return dispatch(tr);
        } catch (error) {
          console.error(error);
        }
      };

    const duplicateOneColumn =
      (column: ProseMirrorNode, position: number) =>
      ({ tr, dispatch }: CommandProps) => {
        try {
          const { doc, selection } = tr;
          const pos = selection.$from;

          if (!dispatch) {
            console.error('no dispatch');
            return;
          }

          const columnBlockPredicate: Predicate = ({ node, ...rest }) => {
            if (!this.options.nestedColumns && node.type === this.type) {
              return true;
            }
            return node.type === this.type;
          };

          const columnBlockNode = findParentNodeClosestToPos(
            pos,
            columnBlockPredicate
          );

          if (!columnBlockNode || !column) {
            return false;
          }
          const newColumns: JSONContent[] = [];

          columnBlockNode.node.content.forEach((node, offset) => {
            const isEqual = columnBlockNode.start + offset === position;
            if (isEqual) {
              newColumns.push(buildColumn(column.toJSON())); // The selected column
            } else {
              const content = [buildParagraph({})];
              newColumns.push(buildColumn({ content })); // An empty column
            }
          });

          const newColumnBlock = buildColumnBlock({
            content: newColumns,
          });
          const newNode = doc.type.schema.nodeFromJSON(newColumnBlock);
          const newPosition =
            columnBlockNode.pos + columnBlockNode.node.nodeSize;

          tr.insert(newPosition, newNode);
          return dispatch(tr);
        } catch (error) {
          console.error(error);
          return false;
        }

        return false;
      };

    return {
      unsetColumns,
      setColumns,
      insertColumns,
      deleteOneColumn,
      duplicateOneColumn,
    };
  },
});
