import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered, TeamOnboardingStatus } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { teamsModel } from '../../../teams';
import { teamsApi } from '../../../../shared/api';
import { onboardingModel } from '..';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* finishTeamOnboarding() {
  try {
    yield put(onboardingModel.actions.setIsLoadingFinishTeamOnboarding(true));

    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const updatedTeam: TeamFiltered = yield call(
      teamsApi.updateTeam,
      currentTeam.id,
      {
        onboardingStatus: TeamOnboardingStatus.FINISHED,
      }
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, updatedTeam);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to finish onboarding.')
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsLoadingFinishTeamOnboarding(false));
  }
}
