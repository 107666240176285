import { call, put } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';

export function* updateTeamsData() {
  const teams: TeamFiltered[] = yield call(teamsApi.getTeams);

  yield put(teamsModel.actions.setTeams(teams));
}
