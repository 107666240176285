import React from 'react';
import { IconMap } from '../../../shared/sprite';
import { Zapier } from './Zapier';
import { IntegrationItem } from './IntegrationItem';

export const Integrations = () => {
  return (
    <>
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Integrations
      </h1>
      <div className="grid gap-8 grid-cols-3 xl:grid-cols-2 sm:grid-cols-1">
        <Zapier />
        <IntegrationItem
          icon={IconMap.Salesforce}
          title="Salesforce"
          description="Salesforce is a customer relationship management (CRM) platform that
      helps businesses manage their sales, customer service, and marketing
      efforts."
          buttonText="Coming soon"
          isComingSoon
        />
        <IntegrationItem
          icon={IconMap.Hubspot}
          title="HubSpot"
          description="Hubspot is an all-in-one marketing, sales, and service software that
      helps businesses grow their audience and increase conversions."
          buttonText="Coming soon"
          isComingSoon
        />
      </div>
    </>
  );
};
