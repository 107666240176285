import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  generatePath,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import {
  Button,
  Dropdown,
  Icon,
  Tabs,
  TabContent,
  PageItemAvatar,
} from '../../../shared/ui';
import { pagesModel } from '../../../features/pages';
import { navigate } from '../../../processes/navigation';
import {
  redirectActions,
  WORKSPACE_ANALYTICS_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_ROUTE,
} from '../../../entities/history';
import { AnalyticsTab } from '../../../features/analytics';
import { IconMap } from '../../../shared/sprite';
import { foldersModel } from '../../../features/folders';
import { ViewersTab } from '../../../features/viewers';
import { createNotification, snackbarModel } from '../../../features/snackbar';
import { getFullPageUrl } from '../../../shared/lib';
import { ResetAnalyticsModal } from './ResetAnalyticsModal';
import { teamsModel } from '../../../features/teams';
import { customDomainsModel } from '../../../features/custom-domains';
import { ToggleAnonymousUsers } from '../../../features/viewers/ui/components';
import { createPageQueryRandomValue } from '../../../features/pages/lib';

const tabs: TabContent[] = [
  {
    name: 'recent-viewers',
    title: 'Recent viewers',
    component: <ViewersTab key="recent-viewers" />,
    icon: IconMap.RecentViewers,
  },
  {
    name: 'leads',
    title: 'Leads',
    component: <ViewersTab key="leads" />,
    icon: IconMap.Lead,
  },
  {
    name: 'analytics',
    title: 'Analytics',
    component: <AnalyticsTab />,
    icon: IconMap.PieChartFilled,
  },
];

export const Analytics: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { sequenceNumber }: { sequenceNumber: string } = useParams();
  const currentLocationTabName = location.pathname.split('/')[3];
  const initialTab =
    tabs.find((tab) => tab.name === currentLocationTabName) || tabs[0];
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const { domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );
  const page = useSelector(pagesModel.selectors.selectCurrentPageWithError);
  const folder = useSelector(foldersModel.selectors.selectCurrentFolder);

  const pageUrl = getFullPageUrl({
    slug: page.pageSlug,
    domain: currentTeamDomain,
    customDomain,
  });

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(`${pageUrl}?${createPageQueryRandomValue()}`);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  }, [dispatch, pageUrl]);

  const onOpenInNewTab = useCallback(() => {
    window.open(`${pageUrl}?${createPageQueryRandomValue()}`, '_blank');
  }, [pageUrl]);

  const onEdit = useCallback(() => {
    dispatch(
      redirectActions.toEditorPage({ sequenceNumber: page.sequenceNumber })
    );
  }, [dispatch, page.sequenceNumber]);

  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const onCloseResetModal = () => {
    setIsResetModalOpen(false);
  };

  const onOpenResetModal = useCallback(() => {
    setIsResetModalOpen(true);
  }, []);

  const pageOptions = useMemo(
    () => [
      {
        id: 'newTab',
        label: 'Open Page in New Tab',
        onClick: onOpenInNewTab,
        iconName: IconMap.LinkExternal,
        iconWidth: 16,
      },
      {
        id: 'editor',
        label: 'Editor',
        onClick: onEdit,
        iconName: IconMap.EditIcon,
        iconWidth: 16,
        style: 'sm:hidden',
      },
      {
        id: 'copyLink',
        label: 'Copy Link',
        onClick: onCopy,
        iconName: IconMap.Link,
        iconWidth: 16,
      },
      {
        id: 'resetAnalytics',
        label: 'Reset Analytics',
        onClick: onOpenResetModal,
        iconName: IconMap.RefreshCcw,
        iconWidth: 16,
      },
    ],
    [onCopy, onEdit, onOpenInNewTab, onOpenResetModal]
  );

  const unpublishedPageOptions = pageOptions.filter(
    (item) => item.id !== 'copyLink'
  );

  const pageItems = page.published ? pageOptions : unpublishedPageOptions;

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  const onBackClick = () => {
    dispatch(
      navigate({
        to: folder
          ? generatePath(WORKSPACE_FOLDER_ROUTE, {
              sequenceNumber: folder.sequenceNumber,
            })
          : WORKSPACE_ROUTE,
      })
    );
  };

  return (
    <>
      <Helmet titleTemplate="Analytics - Distribute" />
      <ResetAnalyticsModal
        isOpen={isResetModalOpen}
        onClose={onCloseResetModal}
        page={page}
      />
      <div className="flex flex-col flex-1 w-full">
        <div className="mb-3">
          <Button
            className="md:pl-2"
            size="sm"
            color="link-gray"
            variant="icon-text"
            onClick={onBackClick}
          >
            <span className="flex items-center gap-3">
              <Icon glyph={IconMap.BackArrow} />
              <span className="text-s font-medium text-gray-600 max-w-50 truncate">
                {folder ? folder.title : 'All pages'}
              </span>
            </span>
          </Button>
        </div>

        <div className="flex gap-3 items-center justify-between mb-6 relative">
          <div className="flex flex-1 gap-3 items-center min-w-0">
            <div className="sm:hidden shrink-0">
              <PageItemAvatar page={page} size="sm" />
            </div>

            <h1 className="text-display-sm font-display font-medium text-gray-900 truncate sm:line-clamp-2 sm:whitespace-normal">
              {page.content.title || 'Untitled'}
            </h1>
          </div>

          <Dropdown
            listStyles="shadow-lg z-20"
            triggerComponent={
              <DropdownMenu.Trigger className="flex justify-center items-center border border-solid border-gray-300 shadow-xs focus:outline-none rounded-lg w-10 h-10 hover:bg-gray-50">
                <Icon glyph={IconMap.DotsVertical} width={20} />
              </DropdownMenu.Trigger>
            }
            items={pageItems}
          />
        </div>
        <Tabs
          key={location.pathname}
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectTab={(tab) =>
            dispatch(
              navigate({
                to: generatePath(`${WORKSPACE_FOLDER_ROUTE}/${tab.name}`, {
                  sequenceNumber,
                }),
              })
            )
          }
          className="!mb-6"
          CustomComponent={<ToggleAnonymousUsers />}
        >
          <BrowserRouter basename={WORKSPACE_ANALYTICS_ROUTE}>
            <Switch>
              {tabs.map((tab) => (
                <Route
                  path={tab.name}
                  key={tab.name}
                  render={() => tab.component}
                />
              ))}
            </Switch>
          </BrowserRouter>
        </Tabs>
      </div>
    </>
  );
};
