import React, { FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Input } from '../../../../../shared/ui';
import { ConversionPopup, TITLE_MAX_LENGTH } from './EditorSidebarPopUpPanel';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';

const DESCRIPTION_MAX_LENGTH = 200;

type IProps = {
  register: UseFormRegister<ConversionPopup>;
  errors: FieldErrors<ConversionPopup>;
  isDescriptionEnabled: boolean;
  isTitleEnabled: boolean;
  isTitleToggleDisabled: boolean;
  description: string;
  title: string;
  onChangeDescriptionEnabled: () => void;
  onChangeTitleEnabled: () => void;
  onChangeDescription: (content: string) => void;
};

export const PopupTitleAndDescriptionFields: FC<IProps> = ({
  register,
  errors,
  isDescriptionEnabled,
  isTitleEnabled,
  isTitleToggleDisabled,
  description,
  title,
  onChangeTitleEnabled,
  onChangeDescriptionEnabled,
  onChangeDescription,
}) => {
  return (
    <>
      <LabelToggle
        icon={IconMap.Type01}
        text="Title"
        isRequired={isTitleToggleDisabled}
        checked={isTitleEnabled}
        onChange={onChangeTitleEnabled}
        className="mb-3 mt-6"
        disabled={isTitleToggleDisabled}
      />
      {isTitleEnabled && (
        <Input
          {...register('title')}
          className="p-3"
          heightSize="md"
          type="text"
          placeholder="Add title"
          maxCharacters={TITLE_MAX_LENGTH}
          isError={!!errors.title}
          value={title}
        />
      )}
      <LabelToggle
        icon={IconMap.AlignLeft}
        text="Description"
        checked={isDescriptionEnabled}
        onChange={onChangeDescriptionEnabled}
        className="mb-3 mt-6"
      />
      {isDescriptionEnabled && (
        <RichTextEditor
          content={description}
          placeholder="Add text…"
          onUpdate={onChangeDescription}
          maxLength={DESCRIPTION_MAX_LENGTH}
        />
      )}
    </>
  );
};
