import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import { useDispatch } from 'react-redux';
import { createNotification, snackbarModel } from '../../features/snackbar';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

type Props = {
  onUpload: (files: File[]) => void;
  maxSize?: number;
  allowedTypes: string[];
  progress?: number;
  isUploading?: boolean;
  disabled?: boolean;
  fileInputStyles?: string;
  isError?: boolean;
  messageText?: string;
  isMultiple?: boolean;
  selectFilesAmount(items: number): void;
};

export const FileInputRedesigned: FC<Props> = ({
  onUpload,
  maxSize = MAX_FILE_SIZE,
  allowedTypes,
  isUploading,
  disabled = false,
  fileInputStyles,
  isError,
  messageText,
  isMultiple,
  selectFilesAmount,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();

  const onValidateAndUpload = useCallback(
    (files?: FileList | null) => {
      if (!files) {
        return;
      }

      try {
        [...files].forEach((file) => {
          const { size, type } = file;

          if (!allowedTypes.includes(type)) {
            throw new Error('File type is not allowed');
          }
          if (size > maxSize) {
            throw new Error('Max file size exceeded');
          }
        });

        onUpload([...files]);
      } catch (error: any) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', error.message)
          )
        );
      }
    },
    [onUpload, allowedTypes, maxSize, dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files) {
      return;
    }
    selectFilesAmount(files.length);

    onValidateAndUpload(files);

    e.target.value = '';
  };

  const uploadZoneRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const enterListener = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const leaveListener = () => {
      setIsDragging(false);
    };

    const dropListener = (e: DragEvent) => {
      setIsDragging(false);

      e.preventDefault();

      const files = e.dataTransfer?.files;

      return onValidateAndUpload(files);
    };

    const ref = uploadZoneRef.current;

    ref?.addEventListener('dragover', enterListener);
    ref?.addEventListener('dragleave', leaveListener);
    ref?.addEventListener('drop', dropListener, {});
    return () => {
      ref?.removeEventListener('dragleave', leaveListener);
      ref?.removeEventListener('dragover', enterListener);
      ref?.removeEventListener('drop', dropListener);
    };
  }, [onValidateAndUpload]);

  const handleInputError = () => {
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to load files')
      )
    );
  };

  return (
    <>
      <div className="w-full relative">
        {isUploading ? (
          <div className="relative min-h-31 overflow-hidden p-4 flex rounded-xl items-center justify-center border border-gray-300 bg-primary-50">
            <div className="relative text-primary-700 font-medium ellipsis-dots">
              Processing files
            </div>
          </div>
        ) : (
          <label
            ref={uploadZoneRef}
            className={cn(
              'rounded-xl px-6 h-32 flex items-center flex-col gap-2 text-gray-600 justify-center bg-gray-50 border-1.5 border-dotted border-gray-300 cursor-pointer',
              { '!bg-primary-50': isDragging },
              { '!border-error-600': isError },
              fileInputStyles
            )}
          >
            <span
              className={cn(
                'hover:cursor-pointer font-semibold relative flex items-center gap-1 text-sm',
                {
                  'text-gray-300': disabled,
                  'text-primary-700': !disabled,
                }
              )}
            >
              <Icon glyph={IconMap.Paperclip02} width={16} />
              Select file(s)
              <span className="text-gray-600 font-medium">
                or drag and drop it here
              </span>
            </span>
            <span className="text-xs font-normal text-gray-600">
              TXT, DOC, PDF, or DOCX (max file size 10MB)
            </span>
            <input
              accept={allowedTypes.join(',')}
              type="file"
              onChange={onChange}
              multiple={isMultiple}
              className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
              onError={handleInputError}
            />
          </label>
        )}
      </div>
      {messageText && (
        <p
          className={cn('mt-1.5', {
            '!text-error-500': isError && !disabled,
          })}
        >
          {messageText}
        </p>
      )}
    </>
  );
};
