import { Folder } from '@distribute/shared/types';
import { foldersApi } from '../../../shared/api';
import { call, put, select } from 'redux-saga/effects';
import { foldersModel } from '../model';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { teamsModel } from '../../teams';

export function* setFoldersData(setLoading = true) {
  try {
    const { id: currentTeamId } = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    if (setLoading) {
      yield put(foldersModel.actions.setFoldersStatus('pending'));
    }

    const folders: Folder[] = yield call(foldersApi.getFolders, currentTeamId);
    yield put(foldersModel.actions.setFolders(folders));
    yield put(foldersModel.actions.setFoldersStatus('success'));
  } catch (e: unknown) {
    logger.error(e);
    yield put(foldersModel.actions.setFoldersStatus('error'));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show folder`)
      )
    );
  }
}
