import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { socialModel } from '../..';
import { mediaUploadModel } from '../../../../../features/media-upload';
import { put } from 'redux-saga/effects';
import { UploadPostCoverProps } from '../../types';

type Props = {
  payload: UploadPostCoverProps;
};

export function* uploadPostCover({ payload: { file, id, cb } }: Props) {
  yield put(socialModel.actions.setPostCoverLoading(true));
  try {
    yield put(mediaUploadModel.actions.uploadFile({ file, id, cb }));
  } catch (_) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change post cover image')
      )
    );
  }
}
