import React, { FC, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { Folder } from '@distribute/shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../features/pages';
import { redirectActions } from '../../../entities/history';
import { FolderItem } from './FolderItem';
import { foldersModel } from '../../../features/folders';

type IProps = {
  droppableId: string;
  folders: Folder[];
  isOpen: boolean;
};

export const Folders: FC<IProps> = ({ droppableId, folders, isOpen }) => {
  const dispatch = useDispatch();
  const pages = useSelector(pagesModel.selectors.selectPages);
  const folderQuantities = useMemo(() => {
    const folderQuantitiesMap: { [key: string]: number } = {};

    for (const folder of folders) {
      folderQuantitiesMap[folder.id] = 0;
    }

    pages.forEach((i) => {
      if (!folderQuantitiesMap[i.folderId]) {
        folderQuantitiesMap[i.folderId] = 1;
      } else {
        folderQuantitiesMap[i.folderId] += 1;
      }
    });

    return folderQuantitiesMap;
  }, [pages, folders]);

  const sortedFolders = useMemo<Folder[]>(() => {
    return [...folders]
      .sort((folder1, folder2) => {
        return folder1.order - folder2.order;
      })
      .map((folder, index) => ({ ...folder, order: index + 1 }));
  }, [folders]);

  const handleFolderClick = (currentFolder: Folder) => {
    dispatch(
      redirectActions.toWorkspaceFolder({
        sequenceNumber: currentFolder.sequenceNumber,
      })
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch(
      foldersModel.actions.reorderFolder({
        destinationIndex: +result.destination.index,
        sourceIndex: +result.source.index,
        folders: sortedFolders,
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {isOpen &&
              sortedFolders.map((item, index) => (
                <Draggable
                  isDragDisabled={sortedFolders.length === 1}
                  key={item.id}
                  draggableId={item.id}
                  index={index + 1}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        key={item.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        className="mb-1"
                      >
                        <FolderItem
                          folderData={item}
                          quantity={
                            folderQuantities[
                              item.id as keyof typeof folderQuantities
                            ]
                          }
                          isDragging={snapshot.isDragging}
                          onClick={() => handleFolderClick(item)}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
