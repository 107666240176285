import { authUserModel } from '../../../../../../entities/auth-user';
import { pagesModel } from '../../../../../../features/pages';
import { Avatar } from '../../../../../../shared/ui';
import React, {
  ElementRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { GmailBlockElement } from '../GmailBlockElement';
import { editorSidebarModel } from '../../../../../../features/editor-sidebar';
import { socialModel } from '../../../../../../entities/social-auth';
import { SOCIAL_PLATFORMS_ENUM } from '../../../../../../features/editor-sidebar/model/reducer';
import { RichTextEditor } from '../../../../../../entities/tiptap-editor';

type Props = {
  postContent: string;
  setPostContent(value: string): void;
  maxCharacters?: number;
  getPostText(): string;
};

export const SocialPostBlock: React.FC<Props> = ({
  postContent,
  setPostContent,
  maxCharacters,
  getPostText,
}) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const personalizedLinkTitle = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkTitle
  );

  const color = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkColor
  );

  const coverUrl = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkCover
  );

  const isNoLogo = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkNoLogo
  );

  const selectedSocialPlatform = useSelector(
    editorSidebarModel.selectors.selectSelectedSocialPlatform
  );

  const linkedinProfilePicture = useSelector(
    socialModel.selectors.selectLinkedinProfilePicture
  );
  const linkedinProfileName = useSelector(
    socialModel.selectors.selectLinkedinProfileName
  );

  const isLoggedIntoLinkedin = useSelector(
    socialModel.selectors.selectIsLoggedIntoLinkedin
  );

  const [personData, setPersonData] = useState({
    name: currentUser.displayName,
    photo: currentUser.photoUrl,
  });

  const twitterProfilePicture = useSelector(
    socialModel.selectors.selectTwitterProfilePicture
  );

  const twitterProfileName = useSelector(
    socialModel.selectors.selectTwitterProfileName
  );

  const isLoggedIntoTwitter = useSelector(
    socialModel.selectors.selectIsLoggedIntoTwitter
  );

  useEffect(() => {
    if (
      selectedSocialPlatform === SOCIAL_PLATFORMS_ENUM.LINKEDIN &&
      isLoggedIntoLinkedin
    ) {
      setPersonData({
        name: linkedinProfileName,
        photo: linkedinProfilePicture || '',
      });
    } else if (
      selectedSocialPlatform === SOCIAL_PLATFORMS_ENUM.TWITTER &&
      isLoggedIntoTwitter
    ) {
      setPersonData({
        name: twitterProfileName,
        photo: twitterProfilePicture || '',
      });
    } else {
      setPersonData({
        name: currentUser.displayName,
        photo: currentUser.photoUrl,
      });
    }
  }, [
    currentUser.displayName,
    currentUser.photoUrl,
    isLoggedIntoLinkedin,
    isLoggedIntoTwitter,
    linkedinProfileName,
    linkedinProfilePicture,
    selectedSocialPlatform,
    twitterProfileName,
    twitterProfilePicture,
  ]);

  const textEditorRef = useRef<ElementRef<typeof RichTextEditor>>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      const contentInput = document.getElementsByClassName(
        'minimal-inline'
      )[0] as HTMLDivElement;
      contentInput?.focus();
    }, 500);
  }, []);

  return (
    <div className="p-4 rounded-lg border-b border-gray-200 bg-base-white shadow-md2">
      <div className="flex gap-4 mb-4 pb-4 relative">
        <Avatar
          displayName={personData.name}
          src={personData.photo}
          size="md"
          isUserAvatar
        />
        <div className="grow">
          <p className="text-xs font-semibold text-gray-700">
            {personData.name}
          </p>
          {postContent && (
            <RichTextEditor
              ref={textEditorRef}
              content={postContent}
              placeholder="Add your text..."
              onUpdate={(content) => setPostContent(content)}
              maxLength={maxCharacters}
              isError={false}
              isInline
              disableTextStyling
              hideCharsCount
            ></RichTextEditor>
          )}
        </div>
        {maxCharacters && (
          <p className="absolute bottom-0 right-2 text-sm text-gray-700 font-medium">
            {getPostText().length}/{maxCharacters}
          </p>
        )}
      </div>
      {selectedSocialPlatform !== SOCIAL_PLATFORMS_ENUM.GMAIL && (
        <GmailBlockElement
          personName={''}
          title={
            personalizedLinkTitle !== null
              ? personalizedLinkTitle
              : currentPage.content.title ?? ''
          }
          link={''}
          color={color}
          coverUrl={coverUrl}
          isNoLogo={isNoLogo}
          noLinkMode
        />
      )}
    </div>
  );
};
