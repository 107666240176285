import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { DowngradeToFreeFeatures } from './DowngradeToFreeFeatures';

type IProps = {
  title: ReactNode;
  description: ReactNode;
  theme: 'warning' | 'error';
  isShowDowngradeToFreeFeatures?: boolean;
  children?: ReactNode;
};

export const AlertBanner: FC<IProps> = ({
  description,
  theme,
  title,
  children,
  isShowDowngradeToFreeFeatures,
}) => {
  return (
    <div
      className={cn('flex justify-between items-end gap-8 p-6 rounded-xl', {
        'bg-warning-100': theme === 'warning',
        'bg-error-100': theme === 'error',
      })}
    >
      <div className="flex flex-col grow-1">
        <div className="text-xl text-gray-700 font-semibold">{title}</div>
        <div className="text-md text-gray-700 mt-2">{description}</div>
        {isShowDowngradeToFreeFeatures && (
          <DowngradeToFreeFeatures className="mt-3" theme={theme} />
        )}
      </div>
      {children && <div className="flex-shrink-0 basis-70">{children}</div>}
    </div>
  );
};
