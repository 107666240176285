import { BrandColor } from './brand-color';
import {
  StripeSubscription,
  SubscriptionPaymentMethod,
  SubscriptionPromoCode,
} from './subscription';
import { TeamConfig } from './team-config';
import { TeamPermissions } from './team-permissions';
import { UserToTeam, UserToTeamFiltered } from './user-to-team';

export type BaseTeam = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  logoUrl: string | null;
  domain: string;
  brandColor: BrandColor;
  brandLogoUrl: string | null;
  brandCompanyName: string | null;
  brandCompanyInfo: string | null;
  headingFontFamily: string;
  paragraphFontFamily: string;
  stripeCustomerId: string;
  stripeSubscription: StripeSubscription | null;
  trialWasUsed: boolean;
  isAppsumo: boolean;
  onboardingStatus: TeamOnboardingStatus;
  subscriptionOnboardingPromoCode: SubscriptionPromoCode | null;
  subscriptionRenewPromoCode: SubscriptionPromoCode | null;
  subscriptionPaymentMethod: SubscriptionPaymentMethod | null;
  isRenewPromoCodeWasUsed: boolean;
  countSubscriptionPaidPeriods: number;
  phone: string | null;
  isSendPromoMessages: boolean;
};

export type Team = BaseTeam & {
  teamMembersCount: number;
  userToTeams: UserToTeam[];
  teamPermissions: TeamPermissions;
  teamConfig: TeamConfig;
};

export type TeamFiltered = BaseTeam & {
  teamMembersCount: number;
  userToTeams: UserToTeamFiltered[];
  currentMember: UserToTeam;
  teamPermissions: TeamPermissions;
  isGongConnected: boolean;
};

export enum TeamOnboardingStatus {
  INVITE_TEAMMATES = 'inviteTeammates',
  SUBSCRIPTION = 'subscription',
  COLLABORATE_WITH_TEAMMATES = 'collaborateWithTeammates',
  FINISHED = 'finished',
}
