import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';

export type UploadStatus = 'uploading' | 'error' | 'success';
export type UploadFile = {
  id: string;
  status: UploadStatus;
  url?: string;
  progress: number;
  errorMessage?: string;
};
type State = {
  files: UploadFile[];
};

const initialState: State = {
  files: [],
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'fileUploading',
  initialState,
  reducers: {
    setFiles: (state, { payload: files }: PayloadAction<State['files']>) => ({
      ...state,
      files,
    }),
    updateFile: (
      state,
      { payload: file }: PayloadAction<Partial<UploadFile>>
    ) => ({
      ...state,
      files: state.files.map((i) => {
        if (i.id === file.id) {
          return { ...i, ...file };
        }
        return i;
      }),
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export type UploadFileAction = {
  file: File;
  id: string;
  cb: (url: string, fileName: string) => void;
  isTemplateMode?: boolean;
};

export const actions = {
  ...reducerActions,
  uploadFile: createAction<UploadFileAction>('fileUploading/upload'),
};
