import { pagesModel } from '../../../../features/pages';
import { pageSettingsModel } from '../../../../features/page-settings';

import { fork, takeEvery } from 'redux-saga/effects';
import { templatesModel } from '../../../../features/templates';
import { aiModel } from '../../../ai';
import { conversionKitModel } from '../../../conversion-kit';
import { editorSidebarModel } from '../../../editor-sidebar';
import { snippetsModel } from '../../../snippets';

export function* templateEditorPageWorker() {
  yield fork(aiModel.sagas.editorAiWorker);

  yield fork(conversionKitModel.sagas.watchUploadPopUpImage);

  yield fork(snippetsModel.sagas.snippetsWorker);

  yield takeEvery(
    pagesModel.actions.changePageIcon,
    pagesModel.sagas.changePageIcon
  );
  yield takeEvery(
    pagesModel.actions.changePageContent,
    pagesModel.sagas.changePageContent
  );
  yield takeEvery(
    pagesModel.actions.changeBrandColor,
    pagesModel.sagas.changeBrandColor
  );
  yield takeEvery(
    pagesModel.actions.changePageStyle,
    pagesModel.sagas.changePageStyle
  );
  yield takeEvery(
    pageSettingsModel.actions.updateSeoConfiguration,
    pageSettingsModel.sagas.updateSeoConfiguration
  );
  yield takeEvery(
    editorSidebarModel.actions.setActiveConversionSettingsPanel,
    editorSidebarModel.sagas.onActiveConversionPanelOpen
  );
  yield takeEvery(
    templatesModel.actions.updateTemplateFromEditor,
    templatesModel.sagas.updateTemplateFromEditor
  );

  yield takeEvery(
    snippetsModel.actions.updateSnippetUsedCount,
    snippetsModel.sagas.updateSnippetUsedCount
  );
}
