import { APP_ORIGIN, PROTOCOL } from '../config';

type GetFullPageUrlProps = {
  slug: string;
  domain: string;
  customDomain?: string;
};

export const getPageUrl = ({
  slug,
  domain,
  customDomain,
}: GetFullPageUrlProps) =>
  customDomain ? `${customDomain}/${slug}` : `${domain}.${APP_ORIGIN}/${slug}`;

export const getFullPageUrl = ({
  slug,
  domain,
  customDomain,
}: GetFullPageUrlProps) => {
  return `${PROTOCOL}${getPageUrl({
    slug,
    domain,
    customDomain,
  })}`;
};
