import { FC, PropsWithChildren, useMemo, useReducer } from 'react';
import { INITIAL_STATE, reducer } from './state';
import { PlayerContext } from './PlayerContext';

import { PlayerEntry, PlayerSettings } from '../types';
import { Size } from '../config';

export const PlayerProvider: FC<
  PropsWithChildren<PlayerEntry & PlayerSettings>
> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const size = useMemo(() => {
    const width = props.entry?.contentRect?.width || 0;

    return width > 640 ? Size.MEDIUM : Size.SMALL;
  }, [props.entry]);

  return (
    <PlayerContext.Provider value={{ state, dispatch, config: props, size }}>
      {children}
    </PlayerContext.Provider>
  );
};
