import { IconMap } from '../../../shared/sprite';

export const ONBOARDING_VIDEO_URL =
  'https://storage.googleapis.com/distribute-production-storage/general/VideoForOnboarding.mp4';

export const walkthroughModalData = [
  {
    title: 'Create high-converting pages',
    description:
      'Creating your first pages can be done quickly and effortlessly in just a matter of minutes. Utilize a wide range of tools to streamline your workflow, including:',
    features: [
      { id: 'templates', title: 'Templates', icon: IconMap.Template },
      {
        id: 'advance-editor',
        title: 'Advanced Editor Features',
        icon: IconMap.AdvancedEditor,
      },
      {
        id: 'ai',
        title: 'AI-Powered Assistant',
        icon: '../../../assets/images/wizard-write.svg',
      },
    ],
  },
  {
    title: 'Unleash the power of every content piece!',
    description:
      'Take your content from good to great, catalyzing business growth in every corner of the web. Convert traffic into leads and escalate sales with our inclusive conversion toolkit, including:',
    features: [
      { id: 'popup', title: 'Pop-up', icon: IconMap.Menubar },
      { id: 'alert-bar', title: 'Alert Bar', icon: IconMap.ChatMessage },
      { id: 'cta', title: 'CTA-Section and more!', icon: IconMap.CursorButton },
    ],
  },
  {
    title: 'Get the insights you need & leads to grow',
    description:
      'Effortlessly elevate every facet of your marketing campaigns. Harness the power of advanced tools and insights to transform your web traffic into a sales magnet with:',
    features: [
      {
        id: 'analytics',
        title: 'Real-time Analytics',
        icon: IconMap.BrowserWebGraphAnalytics,
      },
      { id: 'leads', title: 'Leads List', icon: IconMap.PhotoPictureUser },
    ],
  },
] as const;
