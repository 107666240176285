import { put } from 'redux-saga/effects';
import { ActiveConversionSettingsPanel, editorSidebarModel } from '..';
import { gatedContentModel } from '../../../gated-content-block';
import { subscribePopupModel } from '../../../subscribe-popup';
import { squeezePageModel } from '../../../squeeze-page';

export function* onActiveConversionPanelOpen({
  payload,
}: ReturnType<
  typeof editorSidebarModel.actions.setActiveConversionSettingsPanel
>) {
  yield put(gatedContentModel.actions.setIsModalOpen(false));
  yield put(subscribePopupModel.actions.setIsModalOpen(false));
  yield put(squeezePageModel.actions.setIsOpen(false));

  if (payload === ActiveConversionSettingsPanel.POP_UP) {
    yield put(subscribePopupModel.actions.setIsModalOpen(true));
    return;
  }

  if (payload === ActiveConversionSettingsPanel.GATED_CONTENT) {
    yield put(gatedContentModel.actions.setIsModalOpen(true));
    return;
  }

  if (payload === ActiveConversionSettingsPanel.SQUEEZE_PAGE) {
    yield put(squeezePageModel.actions.setIsOpen(true));
    return;
  }
}
