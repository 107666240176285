import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, Link } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { extensionModel } from '../../../extension';
import { environment } from '../../../../environments/environment';

export const RecordFirstVideo: FC = () => {
  const dispatch = useDispatch();

  const isExtensionEventLoading = useSelector(
    extensionModel.selectors.selectIsExtensionEventLoading
  );

  return (
    <div className="grid place-items-center flex-grow-1">
      <div className="max-w-80 flex flex-col items-center text-center">
        <h5 className="text-xl font-medium text-gray-900 mb-2">
          Record your first video
        </h5>
        <p className="text-md text-gray-600 mb-8">
          Use{' '}
          <Link
            target="_blank"
            rel="noreferrer"
            href={environment.extensionWebsiteUrl}
            size="large"
            className="inline-flex gap-x-0.5 items-center"
          >
            Distribute Extension
            <Icon
              glyph={IconMap.ArrowSquareUpRight}
              width={16}
              className="mt-0.5"
            />
          </Link>{' '}
          to record video or click button below.
        </p>
        <Button
          variant="text"
          color="primary"
          size="lg"
          loading={isExtensionEventLoading}
          disabled={isExtensionEventLoading}
          onClick={() => dispatch(extensionModel.actions.openClick())}
        >
          <Icon glyph={IconMap.VideoRecorder} width={20} className="mr-2" />
          New Recording
        </Button>
      </div>
    </div>
  );
};
