import React, { FC, useCallback } from 'react';
import {
  UploadFile,
  ALLOWED_FILE_TYPES,
  MAX_FILE_SIZE,
  Avatar,
  FormField,
} from '.';
import { useDispatch } from 'react-redux';
import { useTeamPermissions } from '../../features/teams';
import { createNotification, snackbarModel } from '../../features/snackbar';

type Props = {
  name: string;
  logoUrl: string | null;
  isUploading: boolean;
  isRemoving: boolean;
  uploadProgress: number;
  handleChangeLogo: (file: File) => void;
  handleDeleteLogo: () => void;
  handleCancelUploadPhoto: () => void;
  hideIconIfEmpty?: boolean;
};

export const ChangeLogoForm: FC<Props> = ({
  isUploading,
  isRemoving,
  uploadProgress,
  handleChangeLogo,
  handleDeleteLogo,
  handleCancelUploadPhoto,
  name,
  logoUrl,
  hideIconIfEmpty,
}) => {
  const dispatch = useDispatch();

  const { isCanEdit } = useTeamPermissions();

  const handleValidateAndUpload = useCallback(
    (file?: File) => {
      if (!file) {
        return;
      }
      const { size, type } = file;

      if (!ALLOWED_FILE_TYPES.includes(type)) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File type not allowed')
          )
        );

        return;
      }
      if (size > MAX_FILE_SIZE) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'Max file size exceeded')
          )
        );

        return;
      }

      handleChangeLogo(file);
    },
    [dispatch, handleChangeLogo]
  );

  return (
    <FormField label="Logo">
      {isCanEdit ? (
        <UploadFile
          displayName={name}
          isRemoving={isRemoving}
          isUploading={isUploading}
          photoUrl={logoUrl ?? undefined}
          uploadProgress={uploadProgress}
          onChange={handleValidateAndUpload}
          onDeletePhoto={handleDeleteLogo}
          onCancelUploadPhoto={handleCancelUploadPhoto}
          hideIconIfEmpty={hideIconIfEmpty}
        />
      ) : (
        <Avatar
          displayName={name}
          src={logoUrl ?? undefined}
          size="2xl"
          theme={logoUrl ? 'primary-rect' : 'secondary-rect'}
        />
      )}
    </FormField>
  );
};
