import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../shared/ui';
import { CreatePage } from '../../../../features/pages';
import { FilterState } from '..';
import {
  foldersModel,
  useFolderPermissions,
} from '../../../../features/folders';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useTeamPermissions } from '../../../../features/teams';

type EmptyPageContentProps = {
  title: string;
  text: string;
};

const EmptyPageContent: React.FC<EmptyPageContentProps> = ({ title, text }) => {
  return (
    <div className="flex-1 flex flex-col items-center justify-center gap-8 w-full h-full">
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-xl font-medium text-gray-900">{title}</h2>
        <p className="text-md font-normal text-gray-600">{text}</p>
      </div>
    </div>
  );
};

const EmptyPageAllContent: React.FC = () => {
  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);
  const folderPermissions = useFolderPermissions(currentFolder);
  const { isGuest } = useTeamPermissions();
  const { isMobile } = useWindowWidth();

  return (
    <div className="flex flex-col items-center justify-center gap-8 w-full h-full">
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-xl font-medium text-gray-900">No pages found</h2>
        {folderPermissions.isCanCreatePages && currentFolder && !isMobile && (
          <p className="text-md font-normal text-gray-600">
            Create your first page in this folder.
          </p>
        )}
      </div>
      {folderPermissions.isCanCreatePages && !isMobile && !isGuest && (
        <div className="inline-block">
          <CreatePage
            renderButton={(onClick, isLoading) => (
              <Button
                variant="text"
                color="primary"
                type="button"
                fullWidth
                onClick={onClick}
                loading={isLoading}
              >
                Create Page
              </Button>
            )}
          />
        </div>
      )}
    </div>
  );
};

type EmptyPageProps = {
  currentFilter: string;
};

export const EmptyPage: React.FC<EmptyPageProps> = ({ currentFilter }) => {
  switch (currentFilter) {
    case FilterState.All:
      return <EmptyPageAllContent />;
    case FilterState.Published:
      return (
        <EmptyPageContent
          title="No published pages found"
          text="Publish a page to see it here."
        />
      );
    case FilterState.Drafts:
      return (
        <EmptyPageContent
          title="No pages found"
          text="Unpublish a page to see it here."
        />
      );
    default:
      throw new Error('Unknown Filter State');
  }
};
