import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CardForm } from '../CardForm';
import { Button, Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { teamsModel } from '../../../../teams';
import { CARD_ICON_BY_BRAND } from '../../../config';
import { CardFormCustomFields } from '../../../hooks';

type IProps = {
  isUpdatePaymentMethod: boolean;
  cardFormCustomFields: CardFormCustomFields;
  onChangeIsUpdatePaymentMethod: (value: boolean) => void;
  onUpdatePaymentMethod: () => void;
};

export const PaymentMethod: FC<IProps> = ({
  isUpdatePaymentMethod,
  cardFormCustomFields,
  onChangeIsUpdatePaymentMethod,
  onUpdatePaymentMethod,
}) => {
  const { subscriptionPaymentMethod } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  if (subscriptionPaymentMethod && !isUpdatePaymentMethod) {
    return (
      <div className="flex flex-col gap-4 mt-2">
        <div className="flex justify-between items-center">
          <div className="font-bold text-gray-500 text-xs uppercase">
            Payment method
          </div>
          <Button
            color="link-gray"
            variant="icon-text"
            iconLeftName={IconMap.CreditCardRefresh}
            iconLeftWidth={20}
            onClick={() => onChangeIsUpdatePaymentMethod(true)}
            className="!font-semibold"
          >
            Update
          </Button>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-end gap-2">
            <Icon
              glyph={
                CARD_ICON_BY_BRAND[subscriptionPaymentMethod.cardBrand] ??
                CARD_ICON_BY_BRAND.unknown
              }
            />
            <span className="text-lg font-medium text-gray-700">
              •••• {subscriptionPaymentMethod.cardLastNumbers}
            </span>
          </div>
          <div className="text-md text-gray-600">
            Expires {moment.months(subscriptionPaymentMethod.cardExpMonth - 1)}{' '}
            {subscriptionPaymentMethod.cardExpYear}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 mt-2">
      <CardForm
        cardFormCustomFields={cardFormCustomFields}
        onSubmit={onUpdatePaymentMethod}
      />
    </div>
  );
};
