import { FC, useEffect } from 'react';
import '../../../../shared/styles/generate-html.css';
import {
  handleHtmlContentElementClick,
  highlightCodeBlock,
} from '@distribute/shared/generate-html';

type Props = {
  htmlContent: string;
};

export const EditorHtmlContent: FC<Props> = ({ htmlContent }) => {
  useEffect(() => {
    window.addEventListener('click', handleHtmlContentElementClick);

    highlightCodeBlock();

    return () => {
      window.removeEventListener('click', handleHtmlContentElementClick);
    };
  }, []);

  return (
    <div
      className="generate-html"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};
