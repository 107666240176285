import React, { forwardRef, useCallback, useMemo } from 'react';
import { AIMenuCategory, Menu } from './Menu';
import { IconMap } from '../../../../shared/sprite';
import { AIStartedWritingFrom, AIActionsAfterWriting } from './types';
import { AIWritingActionType } from '@distribute/shared/api-types/ai';
import { AIResponse } from '../../../../features/ai/model/types';
import { useDispatch } from 'react-redux';
import { aiModel } from '../../../../features/ai';

type IProps = {
  isOpen: boolean;
  startedWritingFrom: AIStartedWritingFrom;
  AIWritingAction: AIWritingActionType | null;
  onDone: () => void;
  onReplaceSelection: () => void;
  onInsertBelow: () => void;
  onDiscard: () => void;
  currentResponse: AIResponse;
  searchValue: string;
  onFocus: () => void;
};

export const ActionsAfterWriting = forwardRef<HTMLDivElement, IProps>(
  (
    {
      isOpen,
      startedWritingFrom,
      AIWritingAction,
      onDone,
      currentResponse,
      onReplaceSelection,
      onInsertBelow,
      onDiscard,
      searchValue,
      onFocus,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const onTryAgain = useCallback(() => {
      dispatch(
        aiModel.actions.startCompletion({
          ...currentResponse.request,
        })
      );
    }, [currentResponse.request, dispatch]);

    const onContinueWriting = useCallback(() => {
      dispatch(
        aiModel.actions.startCompletion({
          type: AIWritingActionType.CONTINUE_WRITING,
          context: currentResponse.resultText,
          resultText: currentResponse.resultText,
        })
      );
    }, [currentResponse.resultText, dispatch]);

    const onMakeLonger = useCallback(() => {
      dispatch(
        aiModel.actions.startCompletion({
          type: AIWritingActionType.MAKE_LONGER,
          context: currentResponse.resultText,
        })
      );
    }, [currentResponse.resultText, dispatch]);

    const menuItems = useMemo<AIMenuCategory[]>(() => {
      if (!AIWritingAction) return [];

      const AIWritingActionsForSelectedTextWhenWriteFromNewLine = [
        AIWritingActionType.IMPROVE_WRITING,
        AIWritingActionType.FIX_WRITING,
        AIWritingActionType.MAKE_LONGER,
        AIWritingActionType.MAKE_SHORTER,
        AIWritingActionType.CHANGE_TONE,
        AIWritingActionType.SIMPLIFY,
      ];
      const isWritingForSelectedText =
        startedWritingFrom === AIStartedWritingFrom.SELECTION ||
        (startedWritingFrom === AIStartedWritingFrom.NEW_LINE &&
          AIWritingActionsForSelectedTextWhenWriteFromNewLine.includes(
            AIWritingAction
          ));

      return [
        {
          id: 'ai-drafts',
          items: [
            {
              id: AIActionsAfterWriting.REPLACE_SELECTION,
              label: 'Replace selection',
              icon: IconMap.SwitchHorizontal01,
              isShow: isWritingForSelectedText,
              onClick: onReplaceSelection,
            },
            {
              id: AIActionsAfterWriting.INSERT_BELOW,
              label: 'Insert below',
              icon: IconMap.LeftIndent03,
              isShow: isWritingForSelectedText,
              onClick: onInsertBelow,
            },
            {
              id: AIActionsAfterWriting.DONE,
              label: 'Done',
              icon: IconMap.CheckCircle,
              isShow: !isWritingForSelectedText,
              onClick: onDone,
            },
            {
              id: AIActionsAfterWriting.CONTINUE_WRITING,
              label: 'Continue writing',
              icon: IconMap.Edit04,
              onClick: onContinueWriting,
            },
            {
              id: AIActionsAfterWriting.MAKE_LONGER,
              label: 'Make longer',
              icon: IconMap.AlignLeft,
              onClick: onMakeLonger,
            },
            {
              id: AIActionsAfterWriting.TRY_AGAIN,
              label: 'Try again',
              icon: IconMap.RefreshCcw04,
              onClick: onTryAgain,
            },
            {
              id: AIActionsAfterWriting.DISCARD,
              label: 'Discard',
              icon: IconMap.Trash,
              isShow: isWritingForSelectedText,
              onClick: onDiscard,
            },
          ],
        },
      ].map((i) => ({
        ...i,
        items: i.items.filter((subItem) => subItem.isShow !== false),
      }));
    }, [
      AIWritingAction,
      startedWritingFrom,
      onDone,
      onContinueWriting,
      onTryAgain,
      onMakeLonger,
      onReplaceSelection,
      onDiscard,
      onInsertBelow,
    ]);

    return (
      <Menu
        ref={ref}
        isOpen={isOpen}
        searchValue={searchValue}
        items={menuItems}
        onFocus={onFocus}
      />
    );
  }
);
