import { FC } from 'react';
import { VideoRecordData } from '../UploadVideo';
import { useSelector } from 'react-redux';
import { authUserModel } from '../../../../../entities/auth-user';
import { recordingsModel } from '../../../../../features/recordings';
import { UploadRecord } from './UploadRecord';

type Props = {
  handleClick: (data: VideoRecordData) => void;
};

export const UploadRecords: FC<Props> = ({ handleClick }) => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const records = useSelector(
    recordingsModel.selectors.selectRecordsMy(currentUser.id, true)
  );
  const recordsShared = useSelector(
    recordingsModel.selectors.selectRecordsShared(currentUser.id, true)
  );

  return (
    <div className="flex flex-col gap-y-4 h-70 -my-4 py-4 overflow-y-auto">
      {records.length > 0 && (
        <div className="flex flex-col gap-y-4">
          <p className="text-sm font-semibold text-gray-500">My recordings</p>
          <div className="flex flex-col gap-y-4">
            {records.map((r) => (
              <UploadRecord key={r.id} record={r} handleClick={handleClick} />
            ))}
          </div>
        </div>
      )}
      {recordsShared.length > 0 && (
        <div className="flex flex-col gap-y-4">
          <p className="text-sm font-semibold text-gray-500">Shared with me</p>
          <div className="flex flex-col gap-y-4">
            {recordsShared.map((r) => (
              <UploadRecord key={r.id} record={r} handleClick={handleClick} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
