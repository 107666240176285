import { Node } from '@tiptap/core';
import { AIStartedWritingFrom } from './types';
import { AI } from './AI';
import Suggestion, { SuggestionProps } from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import { findParentNodeClosestToPos } from '@tiptap/react';
import { checkIsEmptyContent } from '../../utils';

const EDITOR_LINE_HEIGHT = 28;
const AI_LINE_HEIGHT = 44;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ai: {
      renderAI: (onDestroy?: () => void) => ReturnType;
      getAIWritingFrom: () => AIStartedWritingFrom;
    };
  }
}
const extensionName = 'ai';
const triggerCharacter = ' ';
export const AIExtension = Node.create({
  name: extensionName,

  addStorage() {
    return {
      isShowInline: true,
      isRendered: false,
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: triggerCharacter,
        allowSpaces: true,
        startOfLine: true,
        pluginKey: new PluginKey(extensionName),
        allow: ({ state, range, editor }) => {
          const $from = state.doc.resolve(range.from);
          const isRootDepth = $from.depth === 1;
          const isParagraph = $from.parent.type.name === 'paragraph';
          const isStartOfNode =
            $from.parent.textContent?.charAt(0) === triggerCharacter;
          const isRendered = editor.storage.ai.isRendered;
          const isTextContainsOnlyTrigger =
            $from.parent.textContent === triggerCharacter;

          const resolvedPos = state.doc.resolve($from.pos);
          const isInsideColumn = !!findParentNodeClosestToPos(
            resolvedPos,
            (node) => {
              return node.type.name === 'column';
            }
          );

          return (
            (isRootDepth || isInsideColumn) &&
            isParagraph &&
            isStartOfNode &&
            !isRendered &&
            isTextContainsOnlyTrigger
          );
        },
        render: () => {
          return {
            onStart: ({ editor }: SuggestionProps) => {
              const {
                view: {
                  state,
                  state: {
                    selection: { $head, $from },
                  },
                  dispatch,
                },
              } = editor;

              const end = $from.pos;
              const from = $head?.nodeBefore
                ? end -
                  ($head.nodeBefore.text?.substring(
                    $head.nodeBefore.text?.indexOf(triggerCharacter)
                  ).length ?? 0)
                : $from.start();

              const tr = state.tr.deleteRange(from, end);
              dispatch(tr);

              editor.commands.renderAI();
            },
          };
        },
      }),
    ];
  },

  addCommands() {
    return {
      renderAI:
        (onDestroy) =>
        ({ editor }) => {
          const getReferenceClientRect = () => {
            const isShowInline = editor.storage.ai.isShowInline;

            const editorCoord = editor.view.dom.getBoundingClientRect();
            const paddingLeft = Number.parseInt(
              getComputedStyle(this.editor.view.dom).paddingLeft
            );

            const selectionToCoord = editor.view.coordsAtPos(
              editor.view.state.selection.to
            );

            return new DOMRect(
              editorCoord.left + paddingLeft,
              isShowInline ? selectionToCoord.top : selectionToCoord.bottom,
              0,
              isShowInline ? -(AI_LINE_HEIGHT - EDITOR_LINE_HEIGHT) / 2 : 0
            );
          };

          const startedWritingFrom =
            editor.extensionManager.commands.getAIWritingFrom();

          const { popup, destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              AI,
              {
                startedWritingFrom,
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                  editor.off('update', handleScroll);
                  editor.off('selectionUpdate', selectionUpdateHandler);
                  editor.storage.ai.isRendered = false;

                  editor.commands.unsetHighlightSelection();
                },
              },
              {
                zIndex: 49,
                offset: [0, 8],
                hideOnClick: false,
                duration: [200, 0],
                maxWidth: 'none',
                appendTo: () => document.getElementById('root') as HTMLElement,
                getReferenceClientRect,
              }
            );

          function handleScroll() {
            popup.setProps({
              getReferenceClientRect,
            });
          }

          function selectionUpdateHandler() {
            handleScroll();
            if (startedWritingFrom === AIStartedWritingFrom.SELECTION) {
              editor.commands.setHighlightSelection();
            }
          }

          editor.on('update', handleScroll);
          editor.on('selectionUpdate', selectionUpdateHandler);
          editor.storage.ai.isRendered = true;
          return true;
        },
      getAIWritingFrom: () => {
        const editor = this.editor;

        const isEmpty = editor.state.selection.empty;

        if (!isEmpty) {
          return AIStartedWritingFrom.SELECTION;
        }

        return checkIsEmptyContent(editor)
          ? AIStartedWritingFrom.SCRATCH
          : AIStartedWritingFrom.NEW_LINE;
      },
    };
  },
});
