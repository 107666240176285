import {
  GetSeoConfigurationResponseType,
  UpdateSeoConfigurationRequestType,
  UpdateSeoConfigurationResponseType,
} from '@distribute/shared/api-types/seo-configuration';
import { getUploadProgress } from '../../lib';
import { api } from './instance';

export const seoConfigurationsApi = {
  async getSeoConfigurations(documentContentId: number) {
    const { data } = await api.get<GetSeoConfigurationResponseType>(
      `seo-configurations/${documentContentId}`
    );

    return data;
  },

  async updateSeoConfigurations(
    documentContentId: number,
    pageId: string,
    data: UpdateSeoConfigurationRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateSeoConfigurationResponseType>(
        `seo-configurations/${documentContentId}/page/${pageId}`,
        data
      );
    return responseData;
  },

  async updateSeoConfigurationsImage(
    documentContentId: number,
    pageId: string,
    image: File,
    onUploadProgress: (progress: number) => void
  ) {
    const { data: responseData } = await api.put(
      `seo-configurations/${documentContentId}/page/${pageId}/image-url`,
      { image },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (value) => {
          onUploadProgress(getUploadProgress(value));
        },
      }
    );

    return responseData;
  },

  async removeSeoConfigurationsImage(
    documentContentId: number,
    pageId: string
  ) {
    const { data } = await api.delete(
      `seo-configurations/${documentContentId}/page/${pageId}/image-url`
    );

    return data;
  },
};
