import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { foldersModel } from '../../folders';
import { pagesModel } from '../model';
import { templatesModel } from '../../templates';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { createPageModalModel } from '../../../entities/create-page-modal';

export const CreatePageButtons: React.FC = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);
  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);

  const setTemplatesModalOpen = (isOpen: boolean) => {
    dispatch(templatesModel.actions.setTemplateModalOpen(isOpen));
  };

  const openCreatePageModal = () => {
    dispatch(createPageModalModel.actions.openPageCreationModal());
  };

  const openChooseFolderModal = () => {
    dispatch(foldersModel.actions.setModalOpen(true));
  };

  return (
    <div className="flex items-center gap-4 sm:hidden">
      <Button
        variant="text"
        color="secondary"
        size="md"
        onClick={() => {
          setTemplatesModalOpen(true);
        }}
        disabled={isLoading}
        className="font-semibold shadow-xs"
      >
        Browse Templates
      </Button>
      <Button
        variant="text"
        color="primary"
        size="md"
        className="font-semibold shadow-xs"
        onClick={() => {
          if (!currentFolder) {
            openChooseFolderModal();
          } else {
            openCreatePageModal();
          }
        }}
        loading={isLoading}
      >
        <Icon glyph={IconMap.Plus} width={20} className="mr-1.5" />
        Create new page
      </Button>
    </div>
  );
};
