import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Switch } from '@headlessui/react';
import { useSnippetsPermissions } from '../../../features/teams';
import { CategoriesLayout } from './CategoriesLayout';
import { PersonalSnippetsLayout } from './PersonalSnippetsLayout';
import { DraftSnippetsLayout } from './DraftSnippetsLayout';
import { IconMap } from '../../../shared/sprite';
import { Tabs } from '../../../shared/ui';
import { navigate } from '../../../processes/navigation';
import { SNIPPETS_ROUTE } from '../../../entities/history';
import { SnippetsTabEnum } from '../lib';

type SnippetTab = {
  name: SnippetsTabEnum;
  title: string;
  component: JSX.Element;
};

export const SnippetsTabs: React.FC = () => {
  const dispatch = useDispatch();

  const { isCanCreatePersonalSnippets, isCanManageSnippets } =
    useSnippetsPermissions();

  const tabs: SnippetTab[] = useMemo(
    () =>
      [
        {
          name: SnippetsTabEnum.TEAM,
          title: 'Team',
          icon: IconMap.TeamAccess,
          component: <CategoriesLayout />,
        },
        {
          name: SnippetsTabEnum.PERSONAL,
          title: 'Personal',
          icon: IconMap.EyeCrossedBold,
          component: <PersonalSnippetsLayout />,
          isHide: !isCanCreatePersonalSnippets,
        },
        {
          name: SnippetsTabEnum.DRAFTS,
          title: 'Drafts',
          icon: IconMap.DraftPencil,
          component: <DraftSnippetsLayout />,
          isHide: !isCanCreatePersonalSnippets && !isCanManageSnippets,
        },
      ].filter((tab) => !tab.isHide),
    [isCanManageSnippets, isCanCreatePersonalSnippets]
  );

  const location = useLocation();

  const currentLocationTabName = location.pathname.split('/')[2];

  const initialTab =
    tabs.find((tab) => tab.name === currentLocationTabName) || tabs[0];

  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  return (
    <Tabs
      selectedTab={selectedTab}
      tabs={tabs}
      onSelectTab={(tab) =>
        dispatch(navigate({ to: `${SNIPPETS_ROUTE}/${tab.name}` }))
      }
      className="!mb-6"
      tabClassName="px-2 pb-3 !pt-0"
    >
      <BrowserRouter basename={SNIPPETS_ROUTE}>
        <Switch>
          {tabs.map((tab) => {
            return (
              <Route
                path={`${SNIPPETS_ROUTE}/${tab.name}`}
                key={tab.name}
                render={() => tab.component}
              />
            );
          })}
          <Route path={SNIPPETS_ROUTE} render={() => <CategoriesLayout />} />
        </Switch>
      </BrowserRouter>
    </Tabs>
  );
};
