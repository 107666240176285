import { useState } from 'react';
import { NoTemplatesFound } from '../../../../features/templates/ui/components/NoTemplatesFound';
import { useIframeCommunication } from '../hooks/useIframeCommunication';
import { useTemplates } from '../hooks/useTemplates';
import { TemplateItem } from './TemplateItem';
import { TemplatesConfigEnum } from '../../../../features/templates';
import { size } from '../constants';
import classNames from 'classnames';

export const TemplatesIframeContentPage = () => {
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<TemplatesConfigEnum>(
    TemplatesConfigEnum.ALL
  );
  const { templates } = useTemplates({
    category,
    search,
  });

  const { preview, apply, device } = useIframeCommunication({
    items: templates,
    size,
    onCategoryChange: setCategory,
    onSearch: setSearch,
  });

  if (search && !templates?.length) {
    return <NoTemplatesFound type="search" />;
  }

  if (!templates?.length) {
    return <NoTemplatesFound type="other" />;
  }

  return (
    <div
      className={classNames(`flex flex-row flex-wrap gap-${size.colGap / 4}`, {
        'justify-center': device === 'mobile',
      })}
    >
      {templates.map((template, i) => (
        <TemplateItem
          device={device}
          size={size}
          template={template}
          key={template.id}
          onChoose={() => apply(template.id)}
          onPreview={() => preview(template.id)}
          searchQuery={search}
          index={i}
        />
      ))}
    </div>
  );
};
