import React from 'react';
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  EmojiClickData,
} from 'emoji-picker-react';
import { categories } from '../../../../../features/icon-picker';
import { Editor } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import { addButtonHoveringBlockPluginKey } from '../../HoveringBlock';

type Props = {
  editor: Editor;
  node: Node;
};

export const IconSubMenu: React.FC<Props> = ({ editor, node }) => {
  const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
    editor?.state
  );
  const handleEmojiChange = (emoji: EmojiClickData) => {
    editor
      ?.chain()
      .focus((hoveringBlockPluginState?.hoveredBlockPosition ?? 0) + 1)
      .updateAttributes('callout', {
        emoji: emoji.emoji,
      })
      .run();
  };

  return (
    <div className="-m-4">
      <EmojiPicker
        lazyLoadEmojis
        theme={Theme.LIGHT}
        skinTonesDisabled
        previewConfig={{
          showPreview: false,
        }}
        defaultSkinTone={SkinTones.NEUTRAL}
        emojiVersion="2.0"
        emojiStyle={EmojiStyle.APPLE}
        onEmojiClick={handleEmojiChange}
        width={336}
        height={336}
        categories={categories}
      />
    </div>
  );
};
