import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { setSnippetsData } from '../../lib';

export function* updateSnippetUsedCount({
  payload: { snippetId },
}: ReturnType<typeof snippetsModel.actions.updateSnippetUsedCount>) {
  try {
    yield call(snippetsApi.updateSnippetUsedCount, snippetId);

    yield call(setSnippetsData);
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update snippet')
      )
    );
  }
}
