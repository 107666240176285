import { put } from 'redux-saga/effects';
import { AnalyticsEvents } from '@distribute/shared/types';
import { analytics } from '../../../../entities/analytics';
import { subscriptionModel } from '..';

export function* togglePaywall({
  payload,
}: ReturnType<typeof subscriptionModel.actions.togglePaywall>) {
  yield put(
    subscriptionModel.actions.setIsShowPaywallModal(payload?.isShow ?? false)
  );

  if (payload) {
    analytics.track(AnalyticsEvents.ONBOARDING_PAYWALL_SHOWED, {
      source: payload.source,
    });
  }
}
