import { pagesModel } from '..';
import { call, put } from 'redux-saga/effects';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesApi } from '../../../../shared/api';

export function* resendUserPermissionInvitation({
  payload: { pageId, userPermissionId },
}: ReturnType<typeof pagesModel.actions.resendUserPermissionInvitation>) {
  try {
    yield call(
      pagesApi.resendUserPermissionInvitation,
      pageId,
      userPermissionId
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'Your invitation to join the page has been resent successfully!'
        )
      )
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          'Failed to resend the invitation to join the page'
        )
      )
    );
  }
}
