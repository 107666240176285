import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';

import { Button, Input } from '../../../../shared/ui';
import {
  changePasswordSchema,
  ChangePasswordSchema,
  PASSWORD_PROVIDER_ID,
} from '../../lib';
import { profileSettingModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';
import { CreatePasswordModal } from './';

export const ChangePasswordForm = () => {
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<ChangePasswordSchema>({
    resolver: yupResolver(changePasswordSchema),
  });

  const isLoading = useSelector(
    profileSettingModel.selectors.selectIsChangePasswordLoading
  );
  const { currentPassword, newPassword } = watch();

  const isFormChanged =
    isDirty && (currentPassword?.length > 0 || newPassword?.length > 0);

  const dispatch = useDispatch();

  const handleFormSubmit = (data: ChangePasswordSchema) => {
    dispatch(
      profileSettingModel.actions.changePasswordClick({ ...data, cb: reset })
    );
  };

  useConfirmWindowClose(isFormChanged, 'changePassword');

  const authProviders = useSelector(
    authUserModel.selectors.selectAuthProviders
  );

  const [isCreatePasswordModalOpen, setCreatePasswordModalOpen] =
    useState(false);

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {!authProviders.includes(PASSWORD_PROVIDER_ID) ? (
          <div>
            <p className="text-sm text-gray-600 mb-6">
              Secure your account with additional protection. Your password will
              be linked to your existing email address.
            </p>
            <Button
              className="sm:w-full"
              color="primary"
              variant="text"
              onClick={() => setCreatePasswordModalOpen(true)}
            >
              Create Password
            </Button>
          </div>
        ) : (
          <>
            <div className="flex sm:flex-col mb-5">
              <p className="w-61 text-gray-700 text-sm mr-8 sm:mr-0 sm:mb-1.5">
                Current password
              </p>
              <div className="flex max-w-107 w-full sm:max-w-full">
                <Input
                  autoComplete="current-password"
                  {...register('currentPassword')}
                  isError={!!errors.currentPassword}
                  messageText={errors.currentPassword?.message}
                  type="password"
                  className="pr-10"
                />
              </div>
            </div>
            <div className="flex sm:flex-col mb-5">
              <p className="w-61 text-gray-700 text-sm mr-8 sm:mr-0 sm:mb-1.5">
                New password
              </p>
              <div className="flex max-w-107 w-full sm:max-w-full">
                <Input
                  autoComplete="new-password"
                  {...register('newPassword')}
                  type="password"
                  isError={!!errors.newPassword}
                  messageText={
                    errors.newPassword?.message ||
                    'Password must be at least 8 characters'
                  }
                  className="pr-10"
                />
              </div>
            </div>
            <Button
              className="sm:w-full"
              loading={isLoading}
              disabled={!isFormChanged}
              type="submit"
              color="primary"
              variant="text"
            >
              Update Password
            </Button>
          </>
        )}
      </form>
      <CreatePasswordModal
        isOpen={isCreatePasswordModalOpen}
        onClose={() => setCreatePasswordModalOpen(false)}
      />
    </>
  );
};
