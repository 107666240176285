import React, { Dispatch, SetStateAction, useCallback } from 'react';
import cn from 'classnames';
import { Button, Icon, Modal } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import {
  MediaSnippetJSON,
  Snippet,
  SnippetType,
  SnippetsSharingAccess,
} from '@distribute/shared/types';
import { JSONContent } from '@tiptap/react';
import { CreatedByRow } from '../CreatedByRow';
import { snippetModalTitleByType } from '../create-snippet/lib/config';
import { snippetsModel } from '../../../model';
import { useDispatch } from 'react-redux';
import { TextPreview } from './TextPreview';
import { ImagePreview } from './ImagePreview';
import { VideoPreview } from './VideoPreview';
import { FilePreview } from './FilePreview';
import { useSnippetsPermissions } from '../../../../../features/teams';
import { navigate } from '../../../../../processes/navigation';
import { generatePath } from 'react-router-dom';
import { SNIPPET_EDITOR_ROUTE } from '../../../../../entities/history';

type Props = {
  snippet: Snippet;
  onSelect?: (
    id: string,
    type: SnippetType,
    content: JSONContent | MediaSnippetJSON,
    attrs?: {
      'data-media-width'?: number;
      'data-media-height'?: number;
    }
  ) => void;
  onClose: () => void;
  offsetWidth?: number;
  setIsDeleteSnippetModal: Dispatch<SetStateAction<boolean>>;
  setIsReplaceSnippetModal: Dispatch<SetStateAction<boolean>>;
};

export const SnippetPreviewModal: React.FC<Props> = ({
  snippet,
  onClose,
  onSelect,
  offsetWidth,
  setIsDeleteSnippetModal,
  setIsReplaceSnippetModal,
}) => {
  const dispatch = useDispatch();

  const { isCanManageSnippets } = useSnippetsPermissions();

  const handleGoBack = () => {
    onClose();
  };

  const tags = [
    snippet.category ? snippet.category.name : 'Personal',
    snippet.type,
  ];

  const isEditingButtonsAvailable =
    snippet.sharingAccess === SnippetsSharingAccess.PERSONAL ||
    isCanManageSnippets;

  const handleEditSnippet = useCallback(() => {
    if (snippet.type === SnippetType.TEXT) {
      dispatch(snippetsModel.actions.setCurrentSnippet(snippet));
      dispatch(
        navigate({
          to: generatePath(SNIPPET_EDITOR_ROUTE, {
            sequenceNumber: snippet.sequenceNumber,
          }),
        })
      );
    } else {
      setIsReplaceSnippetModal(true);
    }
  }, [dispatch, snippet, setIsReplaceSnippetModal]);

  return (
    <Modal
      isOpen={true}
      isShowCancelButton={false}
      clearPadding
      onClose={onClose}
      className="w-320 h-[min(720px,100vh-40px)] flex flex-col md:w-182 md:min-w-182"
    >
      <div className="relative flex h-full">
        <div className={cn('py-4 pl-4 w-74 min-w-74 max1080:w-64 md:hidden')}>
          <div className="flex flex-col justify-between h-full bg-gray-100 rounded-xl">
            <div>
              <div className="p-4 border-b border-gray-200">
                <Button
                  color="link-gray"
                  variant="icon-text"
                  size="sm"
                  onClick={handleGoBack}
                  className="!px-3 !font-semibold"
                >
                  <Icon glyph={IconMap.BackArrow} className="mr-2" />
                  Back
                </Button>
              </div>
              <div className="p-6">
                <div className="flex items-center justify-center w-8 h-8 border rounded-md bg-base-white border-light-7">
                  <Icon
                    glyph={snippetModalTitleByType[snippet.type].icon}
                    className="text-gray-600"
                    width={20}
                  />
                </div>
                <p className="mt-4 text-lg font-bold text-gray-900 truncate">
                  {snippet.name}
                </p>
                <p className="mt-2 mb-4 text-sm font-normal text-gray-700 break-words">
                  {snippet.description}
                </p>
                <div className="flex flex-wrap gap-1 mt-4">
                  {tags.map((tag) => (
                    <div className="capitalize px-2 py-0.75 bg-base-white rounded-md border border-gray-300 text-gray-700 text-xs font-medium">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="px-6 pb-6">
                <CreatedByRow owner={snippet.owner} />
              </div>
              {isEditingButtonsAvailable && (
                <div className="flex gap-4 p-4 border-t border-gray-200">
                  <Button
                    variant="icon-text"
                    color="secondary"
                    onClick={handleEditSnippet}
                    iconLeftName={
                      snippet.type === SnippetType.TEXT
                        ? IconMap.Edit02
                        : IconMap.RefreshCw05
                    }
                    iconLeftWidth={20}
                    size="md"
                    className="shadow-xs"
                    fullWidth
                  >
                    {snippet.type === SnippetType.TEXT ? 'Edit' : 'Replace'}
                  </Button>
                  <Button
                    className="shadow-xs"
                    variant="icon-text"
                    color="secondary-destructive"
                    onClick={() => setIsDeleteSnippetModal(true)}
                    size="md"
                    iconLeftName={IconMap.Trash}
                    iconLeftWidth={20}
                    fullWidth
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-grow">
          <div className="flex items-center justify-between py-4 pl-8 pr-4">
            <div className="flex items-center">
              <Button
                variant="icon"
                color="transparent"
                className="hidden text-gray-600 md:block"
                size="sm"
                onClick={handleGoBack}
              >
                <Icon glyph={IconMap.ArrowLeft} width={20} />
              </Button>
              <p className="font-semibold text-gray-900 text-display-sm font-display">
                Preview
              </p>
            </div>
            <div className="flex items-center gap-7 shrink-0 w-46">
              {onSelect && (
                <Button
                  variant="text"
                  color="primary"
                  className="w-30 !h-10 text-sm"
                  onClick={() => {
                    dispatch(
                      snippetsModel.actions.updateSnippetUsedCount({
                        snippetId: snippet.id,
                      })
                    );
                    onSelect(
                      snippet.id,
                      snippet.type,
                      snippet.content,
                      snippet.type === SnippetType.FILE ||
                        snippet.type === SnippetType.VIDEO
                        ? {
                            'data-media-width': offsetWidth,
                            'data-media-height': 640,
                          }
                        : undefined
                    );
                  }}
                >
                  Insert Snippet
                </Button>
              )}
              <Button
                variant="icon"
                color="transparent"
                onClick={onClose}
                size="md"
              >
                <Icon
                  glyph={IconMap.XClose}
                  className="text-gray-500 cursor-pointer"
                  width={20}
                />
              </Button>
            </div>
          </div>
          <div className="flex-grow px-8 pb-4 overflow-x-hidden">
            {snippet.type === SnippetType.TEXT && (
              <TextPreview snippet={snippet} />
            )}
            {snippet.type === SnippetType.IMAGE && (
              <ImagePreview snippet={snippet} />
            )}
            {snippet.type === SnippetType.VIDEO && (
              <VideoPreview snippet={snippet} />
            )}
            {snippet.type === SnippetType.FILE && (
              <FilePreview snippet={snippet} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
