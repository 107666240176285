import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import {
  ActiveConversionSettingsPanel,
  SidebarStepsEnum,
  TemplateSidebarTabsEnum,
  UpdatePersonalizedCoverAction,
} from './types';
import {
  PAGE_COVER_DEFAULT_COLOR,
  PAGE_COVER_DEFAULT_URL,
} from '../../../shared/constants';
import { globalActions } from '../../../app/model/actions';

export enum SOCIAL_PLATFORMS_ENUM {
  'LINKEDIN' = 'LINKEDIN',
  'FACEBOOK' = 'FACEBOOK',
  'TWITTER' = 'TWITTER',
  'GMAIL' = 'GMAIL',
}

export enum DistributeTabOptions {
  PERSONALIZED = 'personalized',
  SOCIAL = 'social',
}

type State = {
  isSidebarOpen: boolean;
  selectedSidebarStep: SidebarStepsEnum;
  selectedTemplateSidebarTab: TemplateSidebarTabsEnum;
  selectedPopupSection: 'content' | 'triggers';
  isDistributeEditCoverPanelOpen: boolean;
  personalizedLinkPersonName: string;
  personalizedLink: string;
  personalizedLinkColor: string;
  personalizedLinkTitle: string | null;
  personalizedLinkCover: string;
  personalizedLinkNoLogo: boolean;
  isPersonalizedLinkGenerating: boolean;
  isPersonalizedCoverUpdating: boolean;
  selectedSocialPlatform: SOCIAL_PLATFORMS_ENUM;
  selectedDistributeTab: DistributeTabOptions;
  isShowAdvancedSettings: boolean;
  isShowAdvancedConversionSettings: boolean;
  activeConversionSettingsPanel: ActiveConversionSettingsPanel | null;
  shouldFocusSlugInput: boolean;
};

const initialState: State = {
  isSidebarOpen: true,
  selectedSidebarStep: SidebarStepsEnum.CONVERSIONS,
  selectedTemplateSidebarTab: TemplateSidebarTabsEnum.CONVERSIONS,
  selectedPopupSection: 'content',
  isDistributeEditCoverPanelOpen: false,
  personalizedLinkPersonName: '',
  personalizedLink: '',
  personalizedLinkColor: PAGE_COVER_DEFAULT_COLOR,
  personalizedLinkTitle: null,
  personalizedLinkCover: PAGE_COVER_DEFAULT_URL,
  personalizedLinkNoLogo: false,
  isPersonalizedLinkGenerating: false,
  isPersonalizedCoverUpdating: false,
  selectedSocialPlatform: SOCIAL_PLATFORMS_ENUM.LINKEDIN,
  isShowAdvancedSettings: false,
  selectedDistributeTab: DistributeTabOptions.SOCIAL,
  isShowAdvancedConversionSettings: false,
  activeConversionSettingsPanel: null,
  shouldFocusSlugInput: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'editorSidebar',
  initialState,
  reducers: {
    setSidebarOpen: (
      state,
      { payload: isSidebarOpen }: PayloadAction<boolean>
    ) => ({ ...state, isSidebarOpen }),
    setSelectedSidebarStep: (
      state,
      { payload: selectedSidebarStep }: PayloadAction<SidebarStepsEnum>
    ) => ({ ...state, selectedSidebarStep }),
    setSelectedTemplateSidebarTab: (
      state,
      {
        payload: selectedTemplateSidebarTab,
      }: PayloadAction<TemplateSidebarTabsEnum>
    ) => ({ ...state, selectedTemplateSidebarTab }),
    setSelectedPopupSection: (
      state,
      { payload: selectedPopupSection }: PayloadAction<'content' | 'triggers'>
    ) => ({ ...state, selectedPopupSection }),
    setDistributeEditCoverPanelOpen: (
      state,
      { payload: isDistributeEditCoverPanelOpen }: PayloadAction<boolean>
    ) => ({ ...state, isDistributeEditCoverPanelOpen }),
    setPersonalizedLinkPersonName: (
      state,
      { payload: personalizedLinkPersonName }: PayloadAction<string>
    ) => ({ ...state, personalizedLinkPersonName }),
    setPersonalizedLink: (
      state,
      { payload: personalizedLink }: PayloadAction<string>
    ) => ({ ...state, personalizedLink }),
    setPersonalizedLinkColor: (state, { payload: personalizedLinkColor }) => ({
      ...state,
      personalizedLinkColor,
    }),
    setPersonalizedLinkTitle: (
      state,
      { payload: personalizedLinkTitle }: PayloadAction<string | null>
    ) => ({ ...state, personalizedLinkTitle }),
    setPersonalizedLinkCover: (
      state,
      { payload: personalizedLinkCover }: PayloadAction<string>
    ) => ({ ...state, personalizedLinkCover }),
    setPersonalizedLinkNoLogo: (
      state,
      { payload: personalizedLinkNoLogo }: PayloadAction<boolean>
    ) => ({ ...state, personalizedLinkNoLogo }),
    setPersonalizedLinkGenerating: (
      state,
      { payload: isPersonalizedLinkGenerating }: PayloadAction<boolean>
    ) => ({ ...state, isPersonalizedLinkGenerating }),
    setPersonalizedCoverUpdating: (
      state,
      { payload: isPersonalizedCoverUpdating }: PayloadAction<boolean>
    ) => ({ ...state, isPersonalizedCoverUpdating }),
    setSelectedSocialPlatform: (
      state,
      { payload: selectedSocialPlatform }
    ) => ({ ...state, selectedSocialPlatform }),
    setSelectedDistributeTab: (
      state,
      { payload: selectedDistributeTab }: PayloadAction<DistributeTabOptions>
    ) => ({
      ...state,
      selectedDistributeTab,
    }),
    setIsShowAdvancedSettings: (
      state,
      { payload: isShowAdvancedSettings }: PayloadAction<boolean>
    ) => ({ ...state, isShowAdvancedSettings }),
    setIsShowAdvancedConversionSettings: (
      state,
      { payload: isShowAdvancedConversionSettings }: PayloadAction<boolean>
    ) => ({ ...state, isShowAdvancedConversionSettings }),
    setActiveConversionSettingsPanel: (
      state,
      {
        payload: activeConversionSettingsPanel,
      }: PayloadAction<ActiveConversionSettingsPanel | null>
    ) => ({ ...state, activeConversionSettingsPanel }),
    setShouldFocusSlugInput: (
      state,
      { payload: shouldFocusSlugInput }: PayloadAction<boolean>
    ) => ({ ...state, shouldFocusSlugInput }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updatePersonalizedCover: createAction<UpdatePersonalizedCoverAction>(
    'editorSidebar/updatePersonalizedCover'
  ),
  generatePersonalizedLink: createAction<string>(
    'editorSidebar/generatePersonalizedLink'
  ),
};
