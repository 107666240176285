import { put, takeEvery } from 'redux-saga/effects';

import { authModel } from '../../../entities/auth-operation';
import { redirect, redirectActions } from '../../../entities/history';
import { signInWithEmailAndPassword } from '../../../features/auth/sign-in';
import { googleSignUp } from '../../../features/auth/google-sign-up';

export function* signInWorker() {
  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(
    authModel.actions.signInWithEmailAndPasswordClick,
    signInWithEmailAndPassword
  );
  yield takeEvery(authModel.actions.googleSignupClick, googleSignUp);
  yield takeEvery(redirectActions.toRegisterClick, redirect.toRegister);
  yield takeEvery(
    authModel.actions.forgotPasswordClick,
    redirect.toResetPassword
  );
}
