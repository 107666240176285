import { init, reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { matchPath } from 'react-router-dom';
import { history } from '../../entities/history';
import { environment } from '../../environments/environment';
import { routes } from '../../pages/config';
import { sentryDSN } from '../../shared/config';

const sentryRoutes = Object.values(routes).flatMap((obj) => {
  return Object.keys(obj).map((key) => ({ path: key }));
});
if (environment.env !== 'development') {
  init({
    dsn: sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(
          history,
          sentryRoutes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    environment: environment.env,
  });
}

export * from './lib';
