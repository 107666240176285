import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { FC } from 'react';

export type IProps = NodeViewProps;

export const ToggleListItemContentNodeView: FC<IProps> = ({ node }) => {
  return (
    <NodeViewWrapper className="!border-none toggle-list-item-content">
      <div className="w-full relative overflow-hidden">
        {node.firstChild?.textContent?.trim().length === 0 &&
          node.content.childCount === 1 && (
            <div className="absolute top-0 left-0 -z-10 text-lg text-gray-400 whitespace-nowrap">
              Empty toggle. Click or drop blocks inside.
            </div>
          )}
        <NodeViewContent />
      </div>
    </NodeViewWrapper>
  );
};
