import { CustomTableCellExt } from '@distribute/shared/generate-html';
import { TableCellOptions } from '@tiptap/extension-table-cell';
import { CellSelection, cellAround } from '@tiptap/pm/tables';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customTableCell: {
      removeTableCellFormatting: () => ReturnType;
      clearTableCellContent: () => ReturnType;
    };
  }
}

const defaultTextAlignment = 'left';
const defaultTextVerticalAlignment = 'middle';

export const CustomTableCell = CustomTableCellExt.extend<TableCellOptions>({
  addCommands() {
    return {
      ...this.parent?.(),
      removeTableCellFormatting:
        () =>
        ({ commands, tr, view }) => {
          const { selection } = tr;

          if (selection instanceof CellSelection) {
            commands.unsetAllMarks();
          } else {
            const cellPos = cellAround(selection.$anchor);

            if (cellPos) {
              const cellNode = view.state.doc.nodeAt(cellPos.pos);

              if (cellNode) {
                tr.removeMark(cellPos.pos, cellPos.pos + cellNode.nodeSize);
              }
            }
          }

          return true;
        },
      clearTableCellContent:
        () =>
        ({ commands, tr, view }) => {
          const { selection } = tr;

          if (selection instanceof CellSelection) {
            commands.deleteSelection();
          } else {
            const cellPos = cellAround(selection.$anchor);

            if (cellPos) {
              const cellNode = view.state.doc.nodeAt(cellPos.pos);

              if (cellNode) {
                tr.delete(cellPos.pos + 1, cellPos.pos + cellNode.nodeSize);
                commands.focus(cellPos.pos);
              }
            }
          }

          return true;
        },
    };
  },
});
