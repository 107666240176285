import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsApi } from '../../../../shared/api';
import { teamsModel } from '../../../teams';
import { logger } from '../../../../shared/lib';
import { UpdateCurrentTeamMemberResponseType } from '@distribute/shared/api-types/team';

export function* updateCurrentTeamCurrentMember({
  payload: { teamId, ...data },
}: ReturnType<typeof teamsModel.actions.updateCurrentTeamCurrentMember>) {
  try {
    const currentMember: UpdateCurrentTeamMemberResponseType = yield call(
      teamsApi.updateCurrentTeamMember,
      teamId,
      data
    );
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    yield call(teamsModel.sagas.onUpdateCurrentTeam, {
      ...currentTeam,
      currentMember,
    });
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team member.')
      )
    );
  }
}
