import React, { useState } from 'react';
import cn from 'classnames';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { Emoji } from 'emoji-picker-react';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../model';
import { TemplatePreviewRaw } from './TemplatePreviewRaw';
import { CreatedByRow } from './';
import { generatePath } from 'react-router-dom';
import {
  TEMPLATE_EDITOR_PAGE_ROUTE,
  redirectActions,
} from '../../../../entities/history';
import { DeleteTemplateConfirmationModal } from './DeleteTemplateConfirmationModal';
import {
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';

type Props = {
  template: TemplateExtended;
  onClose(): void;
  onChoose(): void;
};

export const TemplatePreviewFull: React.FC<Props> = ({
  template,
  onClose,
  onChoose,
}) => {
  const dispatch = useDispatch();

  const handleGoBack = () => {
    dispatch(templatesModel.actions.setCurrentTemplate(undefined));
    dispatch(templatesModel.actions.setPreviewMode(false));
  };

  const handleTemplateEdit = () => {
    dispatch(
      redirectActions.fromWorkspaceWithBackUrl({
        path: generatePath(TEMPLATE_EDITOR_PAGE_ROUTE, {
          sequenceNumber: template.id,
        }),
      })
    );
  };
  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState(false);

  const tagsByCategoryDictionary = {
    community: ['Distroverse', 'Community Templates'],
    leadGeneration: ['Use Cases', 'Lead Generation'],
    salesRooms: ['Use Cases', 'Sales Rooms'],
    caseStudies: ['Use Cases', 'Case Studies'],
    socialSelling: ['Use Cases', 'Social Selling'],
    meetingFollowUp: ['Use Cases', 'Meeting Follow-up'],
    businessCases: ['Use Cases', 'Business Case'],
    onboarding: ['Use Cases', 'Onboarding'],
    events: ['Use Cases', 'Events'],
    salesAssets: ['Use Cases', 'Sales Assets'],
    leadMagnets: ['Use Cases', 'Lead Magnets'],
    salesProposal: ['Use Cases', 'Sales Proposal'],
    mutualActionPlan: ['Use Cases', 'Mutual Action Plan'],
    competitiveComparison: ['Use Cases', 'Competitive Comparison'],
    customerTestimonials: ['Use Cases', 'Customer Testimonials'],
    pricingAndPackaging: ['Use Cases', 'Pricing & Packaging'],
    productOverview: ['Use Cases', 'Product Overview'],
    serviceAndSupport: ['Use Cases', 'Service & Support'],
    preCall: ['Use Cases', 'Pre Call'],
    postDemo: ['Use Cases', 'Post Demo'],
    internalDealReview: ['Use Cases', 'Internal Deal Review'],
    postCall: ['Use Cases', 'Post Call'],
    meetings: ['External', 'Meetings'],
    openPipeline: ['External', 'Open Pipeline'],
    postSale: ['External', 'Post-Sale'],
    customers: ['External', 'Customers'],
    coaching: ['Internal', 'Coaching'],
    bestPractices: ['Internal', 'Best Practices'],
    dealStrategy: ['Internal', 'Deal Strategy'],
    internalUpdates: ['Internal updates'],
    prospecting: ['Prospecting'],
    inDiscussion: ['In Discussion'],
    closing: ['Closing'],
    implementation: ['External', 'Implementation'],
    customerSuccess: ['Customer Success'],
    upsellAndCrossSell: ['Upsell & Cross-sell'],
    winback: ['Winback'],
  };

  const getCategoryTags = () => {
    const tags = [];
    if (!template.isDistribute) {
      tags.push('Custom Templates');
    }

    if (template.category) {
      tags.push(...tagsByCategoryDictionary[template.category]);
    } else {
      tags.push(
        template.sharingAccess === TemplateSharingAccess.PERSONAL
          ? 'Personal Templates'
          : 'Team Templates'
      );
    }

    if (template.isDistribute) {
      tags.push('Distribute Templates');
    }

    return tags.filter((tag) => !!tag);
  };

  const renderedTemplates = useSelector(
    templatesModel.selectors.selectRenderedTemplates
  );

  const renderedTemplatesWithIndex = renderedTemplates.map((t, i) => {
    return { ...t, index: i };
  });

  const currentRenderedTemplate = renderedTemplatesWithIndex.find(
    (t) => t.id === template.id
  );

  const handleClickNextTemplate = () => {
    dispatch(
      templatesModel.actions.setCurrentTemplate(
        renderedTemplates[(currentRenderedTemplate?.index || 0) + 1]
      )
    );
  };

  const handleClickPreviousTemplate = () => {
    dispatch(
      templatesModel.actions.setCurrentTemplate(
        renderedTemplates[
          (currentRenderedTemplate?.index || renderedTemplates.length - 1) - 1
        ]
      )
    );
  };

  return (
    <div className="relative flex h-full">
      {renderedTemplatesWithIndex.length > 1 && (
        <>
          <div className="absolute top-[calc(50%-20px)] -left-[48px] max1280:-left-4 border border-gray-200 rounded-lg bg-base-white shadow-xs w-10 h-10 flex justify-center items-center">
            <Button
              variant="icon"
              color="transparent"
              className="text-gray-700"
              size="md"
              onClick={handleClickPreviousTemplate}
              disabled={currentRenderedTemplate?.index === 0}
            >
              <Icon glyph={IconMap.ArrowLeft} width={20} />
            </Button>
          </div>
          <div className="absolute top-[calc(50%-20px)] -right-[48px] max1280:-right-4 border border-gray-200 rounded-lg bg-base-white shadow-xs w-10 h-10 flex justify-center items-center">
            <Button
              variant="icon"
              color="transparent"
              className="text-gray-700"
              size="md"
              onClick={handleClickNextTemplate}
              disabled={
                currentRenderedTemplate?.index ===
                renderedTemplatesWithIndex.length - 1
              }
            >
              <Icon glyph={IconMap.ArrowRight} width={20} />
            </Button>
          </div>
        </>
      )}
      <DeleteTemplateConfirmationModal
        isOpen={deleteTemplateModalOpen}
        onClose={() => setDeleteTemplateModalOpen(false)}
        template={template}
      />
      <div className={cn('py-4 pl-4 w-[296px] max1080:w-64 md:hidden')}>
        <div className="flex flex-col justify-between h-full bg-gray-100 rounded-xl">
          <div>
            <div className="p-4 border-b border-gray-200">
              <Button
                color="link-gray"
                variant="icon-text"
                size="sm"
                onClick={handleGoBack}
                className="!px-3 !font-semibold"
              >
                <Icon glyph={IconMap.BackArrow} className="mr-2" />
                Back
              </Button>
            </div>
            <div className="p-6">
              <Emoji unified={template.content.icon} size={32} />
              <p className="mt-4 text-lg font-bold text-gray-900 truncate">
                {template.name}
              </p>
              <p className="mt-2 mb-4 text-sm font-normal text-gray-700 break-words">
                {template.description}
              </p>
              <div className="flex flex-wrap gap-1 mt-4">
                {getCategoryTags().map((tag) => (
                  <div className="px-2 py-0.75 bg-base-white rounded-md border border-gray-300 text-gray-700 text-xs font-medium">
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="px-6 pb-6">
              <CreatedByRow template={template} />
            </div>
            {!template.isDistribute && template.isOwner && (
              <div className="flex gap-4 p-4 border-t border-gray-200">
                <Button
                  variant="icon-text"
                  color="secondary"
                  onClick={handleTemplateEdit}
                  iconLeftName={IconMap.Edit02}
                  iconLeftWidth={20}
                  size="md"
                  className="shadow-xs"
                  fullWidth
                >
                  Edit
                </Button>
                <Button
                  className="shadow-xs"
                  variant="icon-text"
                  color="secondary-destructive"
                  onClick={() => setDeleteTemplateModalOpen(true)}
                  size="md"
                  iconLeftName={IconMap.Trash}
                  iconLeftWidth={20}
                  fullWidth
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="flex items-center justify-between py-4 pl-8 pr-4">
          <div className="flex items-center">
            <Button
              variant="icon"
              color="transparent"
              className="hidden text-gray-600 md:block"
              size="sm"
              onClick={handleGoBack}
            >
              <Icon glyph={IconMap.ArrowLeft} width={20} />
            </Button>
            <p className="font-semibold text-gray-900 text-display-sm font-display">
              Preview
            </p>
          </div>
          <div className="flex items-center gap-7 shrink-0 w-46">
            {template.creationPhase !== TemplateCreationPhase.DRAFT && (
              <Button
                variant="text"
                color="primary"
                className="w-30 !h-10 text-sm"
                onClick={onChoose}
              >
                Use Template
              </Button>
            )}
            <Button
              variant="icon"
              color="transparent"
              onClick={onClose}
              size="md"
            >
              <Icon
                glyph={IconMap.XClose}
                className="text-gray-500 cursor-pointer"
                width={20}
              />
            </Button>
          </div>
        </div>
        <div className="flex-grow px-8 pb-4 overflow-x-hidden">
          <div className="h-full px-8 pt-12 pb-4 overflow-auto border border-gray-300 rounded-2xl">
            <TemplatePreviewRaw
              isFullPreview
              template={template}
              className="!overflow-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
