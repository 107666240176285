import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered, UserOnboardingStatus } from '@distribute/shared/types';
import {
  ACCEPT_TEAM_INVITATION_ROUTE,
  history,
  redirect,
} from '../../../../entities/history';
import { matchParams } from '../../../../shared/lib';
import { teamsApi } from '../../../../shared/api';
import { isAxiosError } from 'axios';
import { createNotification, snackbarModel } from '../../../snackbar';
import { RouteParams } from '../types';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { auth } from '../../../../shared/config';
import { authUserModel } from '../../../../entities/auth-user';
import { DBUser } from '../../../../shared/types';

export function* handleAcceptTeamInvitationSignedIn() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_TEAM_INVITATION_ROUTE
    );

    if (!params) return;

    const dbUser: DBUser = yield select(authUserModel.selectors.selectUser);
    const team: TeamFiltered = yield call(
      teamsApi.acceptTeamInvitation,
      params.inviteId
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Invite has been accepted')
      )
    );

    yield put(
      authUserModel.actions.setDBUser({
        ...dbUser,
        ...(dbUser.onboardingStatus !== UserOnboardingStatus.FINISHED
          ? { onboardingStatus: UserOnboardingStatus.INSTALL_EXTENSION }
          : {}),
      })
    );

    yield auth.currentUser?.reload();

    return team;
  } catch (error: unknown) {
    logger.error(error);
    if (isAxiosError(error)) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'error',
            handleError(error, 'Failed to accept the invite')
          )
        )
      );
      yield call(redirect.toWorkspace);
    }
    return;
  }
}
