import { call, put } from 'redux-saga/effects';

import { authModel } from '../../../entities/auth-operation';
import { getUserFromFb } from '../../../entities/auth-user';
import { FirebaseUser } from '../../../shared/types';
import { createNotification, snackbarModel } from '../../../features/snackbar';
import { authApi } from '../../../shared/api';
import { APP_HOST, PROTOCOL } from '../../../shared/config';
import { EMAIL_VERIFICATION_TIMESTAMP } from '../../../shared/constants';
import { getQueryParam } from '../../../shared/lib';
import { QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE } from '../../public-template';

export function* sendEmailVerification(continueUrl?: string) {
  yield call(
    authModel.sagas.authOperationWithAlert,
    'Failed to send verification email',
    function* () {
      const user: FirebaseUser = yield getUserFromFb();
      const duplicatePublicTemplatePageId = getQueryParam(
        QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE
      );
      const searchParams = new URLSearchParams(
        duplicatePublicTemplatePageId
          ? {
              [QUERY_PARAM_PAGE_ID_FOR_PUBLIC_TEMPLATE]:
                duplicatePublicTemplatePageId,
            }
          : continueUrl
          ? { continueUrl }
          : {}
      );

      yield authApi.sendEmailVerification({
        email: user.email || '',
        redirectUrl: `${PROTOCOL}${APP_HOST}?${searchParams.toString()}`,
        name: user.displayName || '',
      });

      sessionStorage.setItem(
        EMAIL_VERIFICATION_TIMESTAMP,
        JSON.stringify(
          new Date(new Date().setMinutes(new Date().getMinutes() + 1))
        )
      );

      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'success',
            "We've sent an email to verify your account!"
          )
        )
      );
    }
  );
}
