import { Node, mergeAttributes, Editor } from '@tiptap/react';

export type LinkNodeType = {
  attrs: {
    href: string;
    text: string;
  };
};

export type LinkComponentProps = {
  selected: boolean;
  node: LinkNodeType;
  className?: string;
  editor: Editor;
  getPos(): number;
  updateAttributes(attributes: { [key: string]: string }): void;
  extension: Node;
};

export const CustomLinkExt = Node.create({
  name: 'customLink',
  atom: true,
  inline: true,
  selectable: true,
  group: 'inline',

  parseHTML() {
    return [
      {
        tag: 'customLink',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'a',
      mergeAttributes(HTMLAttributes, { class: 'custom-link' }),
      node.attrs.text,
    ];
  },

  addAttributes() {
    return {
      href: {
        default: '',
      },
      text: {
        default: '',
      },
    };
  },
});
