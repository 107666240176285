import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useController } from 'react-hook-form';
import moment from 'moment';
import { Alert, Checkbox, Icon, PhoneInput } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { subscriptionModel } from '../../model';
import { CardFormCustomFields } from '../../hooks';
import { teamsModel } from '../../../teams';

type IProps = {
  cardFormCustomFields: CardFormCustomFields;
  onSubmit: () => void;
};

export const CardForm: FC<IProps> = ({ cardFormCustomFields, onSubmit }) => {
  const isAgreedCheckboxRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const updatePaymentMethodError = useSelector(
    subscriptionModel.selectors.selectUpdatePaymentMethodError
  );
  const upcomingInvoiceInfo = useSelector(
    subscriptionModel.selectors.selectUpcomingInvoiceInfo
  );
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const nextBillingDate =
    upcomingInvoiceInfo?.trialEndAt ?? stripeSubscription?.trialEnd;
  const {
    isShowAgreedCheckbox,
    form: { control, clearErrors },
  } = cardFormCustomFields;

  const {
    field: { onChange: onChangeIsAgreed, value: isAgreed },
    fieldState: { error: errorIsAgreed },
  } = useController({
    control,
    name: 'isAgreed',
  });

  const {
    field: {
      onChange: onChangeIsSendPromoMessages,
      value: isSendPromoMessages,
    },
  } = useController({
    control,
    name: 'isSendPromoMessages',
  });
  const {
    field: { value: phone, onChange: onChangePhone },
    fieldState: { error: errorPhone },
  } = useController({
    control,
    name: 'phone',
  });

  useEffect(
    () => () => {
      dispatch(subscriptionModel.actions.setUpdatePaymentMethodError());
    },
    [dispatch]
  );

  useEffect(() => {
    if (errorIsAgreed) {
      isAgreedCheckboxRef.current?.scrollIntoView();
    }
  }, [errorIsAgreed]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <div className="font-bold text-gray-500 text-xs uppercase">
        Payment method
      </div>
      {updatePaymentMethodError && (
        <Alert
          color="error"
          onClose={() =>
            dispatch(subscriptionModel.actions.setUpdatePaymentMethodError())
          }
        >
          {updatePaymentMethodError}
        </Alert>
      )}
      <form onSubmit={handleSubmit} className="relative">
        {isFormLoaded && (
          <div className="flex gap-1 text-xs text-gray-400 absolute top-0 right-0.5 ">
            Powered by <Icon glyph={IconMap.StripeText} />
          </div>
        )}
        <PaymentElement
          onReady={() => setIsFormLoaded(true)}
          options={{ terms: { card: 'never' } }}
        />
        {isFormLoaded && (
          <div className="flex flex-col gap-4">
            <PhoneInput
              label="Phone number"
              className="mt-4"
              value={phone}
              onChange={(value) => onChangePhone(value)}
              errorText={errorPhone?.message}
            />
            <div className="flex flex-col gap-6">
              <Checkbox
                className="!items-start gap-2"
                checked={isSendPromoMessages}
                onChange={(e) => onChangeIsSendPromoMessages(e.target.checked)}
              >
                <div className="flex flex-col -mt-0.5">
                  <span className="text-sm font-medium text-gray-700">
                    Text me to enter T-shirt giveaway. ¡Ay, caramba!
                  </span>
                  <span className="text-xs text-gray-600">
                    Distribute may use your phone number to send promotional and
                    personalized marketing text messages.
                  </span>
                </div>
              </Checkbox>
              {isShowAgreedCheckbox && (
                <Checkbox
                  ref={isAgreedCheckboxRef}
                  className="!items-start gap-2"
                  checked={isAgreed}
                  onChange={(e) => onChangeIsAgreed(e.target.checked)}
                  isError={!!errorIsAgreed}
                >
                  <span className="text-sm font-medium text-gray-700 -mt-0.5">
                    I understand I'll be billed on{' '}
                    {moment(nextBillingDate).format('MMM D, YYYY')} unless I
                    cancel before then.
                  </span>
                </Checkbox>
              )}
              {errorIsAgreed && (
                <Alert color="error" onClose={() => clearErrors('isAgreed')}>
                  {errorIsAgreed.message}
                </Alert>
              )}
            </div>
          </div>
        )}
        <button type="submit" hidden />
      </form>
    </>
  );
};
