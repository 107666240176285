import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigationModel } from '../../processes/navigation';

export const useConfirmWindowClose = (
  needHandler: boolean,
  handlerId: string
) => {
  const listener = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (needHandler) {
      dispatch(
        navigationModel.actions.addNavigationHandler({
          type: 'prevent',
          id: handlerId,
        })
      );
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
      dispatch(navigationModel.actions.removeNavigationHandler(handlerId));
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
      dispatch(navigationModel.actions.removeNavigationHandler(handlerId));
    };
  }, [needHandler, listener, dispatch, handlerId]);
};
