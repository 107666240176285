import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { TemplatesConfigEnum } from '../lib';
import {
  ChangeTemplateCoverImageAction,
  CreateBlankTemplateAction,
  DeleteTemplateAction,
  SavePageAsTemplateAction,
  UpdateTemplateAction,
  UpdateTemplateContentAction,
} from './types';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { ExistingEditorData } from '../../../shared/types';
import { globalActions } from '../../../app/model/actions';
import type { DocumentContentItem } from '@distribute/shared/types';

type State = {
  data: TemplateExtended[];
  recentTemplates: TemplateExtended[];
  topTemplates: TemplateExtended[];
  currentTemplate: TemplateExtended | undefined;
  currentContent: DocumentContentItem | undefined;
  currentTemplatesFolder: TemplatesConfigEnum;
  isTemplateDeleting: boolean;
  isTemplateUpdating: boolean;
  savePageAsTemplateIsLoading: boolean;
  isPreviewMode: boolean;
  searchQuery: string;
  isTemplateModalOpen: boolean;
  isEditorBlocked: boolean | undefined;
  existingEditorData: ExistingEditorData;
  createBlankTemplateIsLoading: boolean;
  isTemplateSavedInEditor: boolean;
  renderedTemplates: TemplateExtended[];
};

const initialState: State = {
  data: [],
  recentTemplates: [],
  topTemplates: [],
  currentTemplate: undefined,
  currentContent: undefined,
  currentTemplatesFolder: TemplatesConfigEnum.ALL,
  isTemplateDeleting: false,
  isTemplateUpdating: false,
  savePageAsTemplateIsLoading: false,
  isPreviewMode: false,
  searchQuery: '',
  isTemplateModalOpen: false,
  isEditorBlocked: undefined,
  existingEditorData: null,
  createBlankTemplateIsLoading: false,
  isTemplateSavedInEditor: true,
  renderedTemplates: [],
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates: (
      state,
      { payload: templates }: PayloadAction<TemplateExtended[]>
    ) => ({
      ...state,
      data: templates,
    }),

    setRecentTemplates: (state, { payload: recentTemplates }) => ({
      ...state,
      recentTemplates,
    }),

    setTopTemplates: (
      state,
      { payload: topTemplates }: PayloadAction<TemplateExtended[]>
    ) => ({
      ...state,
      topTemplates,
    }),

    setCurrentTemplate: (
      state,
      { payload: currentTemplate }: PayloadAction<TemplateExtended | undefined>
    ) => ({
      ...state,
      currentTemplate,
    }),

    setCurrentTemplateContent: (
      state,
      {
        payload: currentContent,
      }: PayloadAction<DocumentContentItem | undefined>
    ) => ({
      ...state,
      currentContent,
    }),

    setCurrentTemplatesFolder: (
      state,
      { payload: currentTemplatesFolder }: PayloadAction<TemplatesConfigEnum>
    ) => ({
      ...state,
      currentTemplatesFolder,
    }),

    setTemplateDeleting: (state, { payload: isTemplateDeleting }) => ({
      ...state,
      isTemplateDeleting,
    }),

    setTemplateUpdating: (state, { payload: isTemplateUpdating }) => ({
      ...state,
      isTemplateUpdating,
    }),

    setSavePageAsTemplateIsLoading: (
      state,
      { payload: savePageAsTemplateIsLoading }
    ) => ({ ...state, savePageAsTemplateIsLoading }),

    setPreviewMode: (
      state,
      { payload: isPreviewMode }: PayloadAction<boolean>
    ) => ({
      ...state,
      isPreviewMode,
    }),

    setSearchQuery: (
      state,
      { payload: searchQuery }: PayloadAction<string>
    ) => ({ ...state, searchQuery }),

    setTemplateModalOpen: (
      state,
      { payload: isTemplateModalOpen }: PayloadAction<boolean>
    ) => ({ ...state, isTemplateModalOpen }),

    setEditorBlocked: (
      state,
      { payload: isEditorBlocked }: PayloadAction<boolean | undefined>
    ) => ({
      ...state,
      isEditorBlocked,
    }),

    setExistingEditorData: (
      state,
      { payload: existingEditorData }: PayloadAction<ExistingEditorData>
    ) => ({ ...state, existingEditorData }),

    setCreateBlankTemplateIsLoading: (
      state,
      { payload: createBlankTemplateIsLoading }
    ) => ({ ...state, createBlankTemplateIsLoading }),

    setIsTemplateSavedInEditor: (
      state,
      { payload: isTemplateSavedInEditor }: PayloadAction<boolean>
    ) => ({
      ...state,
      isTemplateSavedInEditor,
    }),

    setRenderedTemplates: (
      state,
      { payload: renderedTemplates }: PayloadAction<TemplateExtended[]>
    ) => ({ ...state, renderedTemplates }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  deleteTemplate: createAction<DeleteTemplateAction>(
    'templates/deleteTemplate'
  ),
  savePageAsTemplate: createAction<SavePageAsTemplateAction>(
    'templates/savePageAsTemplate'
  ),
  createBlankTemplate: createAction<CreateBlankTemplateAction>(
    'templates/createBlankTemplate'
  ),
  changeCoverImageUrl: createAction<ChangeTemplateCoverImageAction>(
    'templates/changeCoverImageUrl'
  ),
  updateTemplateFromSettings: createAction<UpdateTemplateAction>(
    'templates/updateTemplateFromSettings'
  ),
  updateTemplateFromEditor: createAction('templates/updateTemplateFromEditor'),
  updateTemplateContent: createAction<UpdateTemplateContentAction>(
    'templates/updateTemplateContent'
  ),
};
