import { fork } from 'redux-saga/effects';
import { Location } from 'history';
import { teamInfoSettingsModel } from '../../../features/team-info-settings';
import {
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_STYLES_ROUTE,
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  TEAM_SETTINGS_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
} from '../../../entities/history';
import { teamMembersModel } from '../../../features/team-members';
import { subscriptionModel } from '../../../features/subscription';
import { teamBrandSettingsModel } from '../../../features/team-brand-settings';
import { teamBrandStylesModel } from '../../../features/team-brand-styles';
import { teamsModel } from '../../../features/teams';

export function* teamSettingsWorker(location: Location, route: string) {
  switch (route) {
    case TEAM_SETTINGS_ROUTE:
    case TEAM_SETTINGS_INFO_ROUTE:
      yield fork(teamInfoSettingsModel.sagas.teamInfoSettingsWorker);
      break;
    case TEAM_SETTINGS_BRAND_ROUTE:
      yield fork(teamBrandSettingsModel.sagas.teamBrandSettingsWorker);
      break;
    case TEAM_SETTINGS_STYLES_ROUTE:
      yield fork(teamBrandStylesModel.sagas.teamBrandStylesWorker);
      break;
    case TEAM_SETTINGS_MEMBERS_ROUTE:
      yield fork(teamMembersModel.sagas.teamMembersPageWorker);
      break;
    case TEAM_SETTINGS_SUBSCRIPTION_ROUTE:
      yield fork(subscriptionModel.sagas.subscriptionPageWorker);
      break;
    case TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE:
      yield fork(teamsModel.sagas.teamPermissionsWorker);
      break;
    default:
      return;
  }
}
