import React from 'react';
import { Modal, Button, Input } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { foldersModel } from '..';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type AddFolderModalForm = {
  folderName: string;
};

const validationSchema = object().shape({
  folderName: string().required('Folder Name is required'),
});

export const AddFolderModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    foldersModel.selectors.selectCreateFolderIsLoading
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddFolderModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = (data: AddFolderModalForm) => {
    dispatch(
      foldersModel.actions.createFolder({
        title: data.folderName,
        callback: () => {
          onModalClose();
        },
      })
    );
  };

  const onModalClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Add Folder"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Add Folder
        </Button>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            label="Folder Name"
            {...register('folderName')}
            isError={!!errors.folderName}
            messageText={errors.folderName?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
