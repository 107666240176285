import { useSelector } from 'react-redux';
import { teamsModel } from '../../teams';
import { FREE_SUBSCRIPTION_PLAN_ID, subscriptionPlans } from '../config';
import { SubscriptionPlan } from '../types';

export const useCurrentPlan = () => {
  const { stripeSubscription } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  return subscriptionPlans.find(
    (plan) =>
      plan.id === (stripeSubscription?.name ?? FREE_SUBSCRIPTION_PLAN_ID)
  ) as SubscriptionPlan;
};
