import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import classNames from 'classnames';

import { Icon } from '../Icon';
import { IconMap } from '../../sprite';

const Select = SelectPrimitive.Root;
const SelectValue = SelectPrimitive.SelectValue;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={classNames(
      'flex items-center justify-between gap-x-1 rounded-lg border border-gray-300 hover:bg-gray-50 bg-base-white px-4 py-2.5 h-10 text-sm text-gray-700 font-semibold truncate',
      'focus:outline-none focus:border-primary-600 disabled:cursor-not-allowed disabled:opacity-50 ',
      '[&>svg]:data-[state=open]:rotate-180',
      className
    )}
    {...props}
  >
    <SelectValue asChild>
      <span className="truncate">{children}</span>
    </SelectValue>
    <SelectPrimitive.Icon asChild>
      <Icon className="flex-shrink-0" glyph={IconMap.ChevronDown} width={20} />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    showIcon?: boolean;
    containerId?: string | null;
  }
>(
  (
    {
      className,
      children,
      position = 'popper',
      showIcon,
      containerId,
      ...props
    },
    ref
  ) => {
    const [container, setContainer] = React.useState<HTMLElement | undefined>();

    React.useEffect(() => {
      if (!containerId) return;

      const parent = document.getElementById(containerId);
      if (!parent) return;

      setContainer(parent);
    }, [containerId]);

    return (
      <SelectPrimitive.Portal container={container}>
        <SelectPrimitive.Content
          ref={ref}
          className={classNames(
            'relative z-50 max-h-50 w-[var(--radix-popper-anchor-width)] overflow-y-auto rounded-lg py-1 px-1.5 border border-gray-200 bg-base-white shadow-lg',
            { '[&>div>div>svg]:hidden': !showIcon },
            className
          )}
          position={position}
          {...props}
        >
          <SelectPrimitive.Viewport className="flex flex-col gap-y-1">
            {children}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    );
  }
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      'relative flex justify-between items-center gap-x-1 w-full cursor-pointer select-none rounded-md focus:bg-gray-50 hover:bg-gray-50 py-2.5 px-2.5 text-sm font-medium text-gray-700 outline-none',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText asChild>
      <div className="truncate">{children}</div>
    </SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator asChild className="flex-shrink-0">
      <Icon glyph={IconMap.GreenCheckMark} />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectPlaceholder = ({ value }: { value: string }) => (
  <span className="text-md text-gray-500 font-normal">{value}</span>
);

export { Select, SelectTrigger, SelectContent, SelectItem, SelectPlaceholder };
