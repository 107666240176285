import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'debounce';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import cn from 'classnames';
import { IconMap } from '../../../../../shared/sprite';
import {
  Button,
  Dropdown,
  Icon,
  SelectableInput,
  Tooltip,
} from '../../../../../shared/ui';

import { RecordDeleteModal } from '../../recordings/RecordDeleteModal';
import { RecordRenameModal } from '../../recordings/RecordRenameModal';
import { RecordShareModal } from '../../recordings/record-share-modal/RecordShareModal';

import { PaywallModal } from '../../../../subscription';

import { navigate } from '../../../../../processes/navigation';
import { QUICK_RECORDINGS_MY_ROUTE } from '../../../../../entities/history';

import { recordingsModel } from '../../../model';

import { createRecordLink } from '../../../lib/record-link';
import { useRecordDropdownItems, useRecordPermissions } from '../../../hooks';
import { useTimer } from '../../../../../shared/hooks/useTimer';
import {
  checkIsRecordConvertingFailed,
  checkIsRecordInProgress,
} from '../../../lib';

import { DEFAULT_NAME } from '../../../config';
import { authUserModel } from '../../../../../entities/auth-user';
import { CreatePageWorkflow } from '../../../../../widgets/create-page-workflow';
import { foldersModel } from '../../../../folders';

import {
  ChooseFolderModalForm,
  InsertToPageModal,
  pagesModel,
} from '../../../../../features/pages';
import { DEFAULT_RATIO, JSONContentFactory } from '@distribute/shared/utils';

export const RecordHeader: FC = () => {
  const [isInsertToPageModalOpen, setIsInsertToPageModalOpen] = useState(false);

  const isRecordInsertToPageLoading = useSelector(
    recordingsModel.selectors.selectIsRecordInsertToPageLoading
  );
  const isCreatePageLoading = useSelector(
    pagesModel.selectors.selectCreatePageIsLoading
  );
  const pages = useSelector(pagesModel.selectors.selectPagesToEdit);

  const [isActive, onActive] = useTimer();

  const dispatch = useDispatch();

  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const record = useSelector(recordingsModel.selectors.selectRecord);

  const recordIdToEdit = useSelector(
    recordingsModel.selectors.selectRecordIdToEdit
  );
  const recordIdToDelete = useSelector(
    recordingsModel.selectors.selectRecordIdToDelete
  );
  const recordIdToShare = useSelector(
    recordingsModel.selectors.selectRecordIdToShare
  );

  const folders = useSelector(foldersModel.selectors.selectFoldersToEditPage);

  const recordDropdownItems = useRecordDropdownItems({
    record,
  });

  const createPageItems = useMemo(
    () =>
      [
        {
          id: 'new-page',
          label: 'New page',
          onClick: () => dispatch(foldersModel.actions.setModalOpen(true)),
          isShow: true,
        },
        {
          id: 'existing-page',
          label: 'Existing page',
          onClick: () => setIsInsertToPageModalOpen(true),
          isShow: folders.length > 0,
        },
      ].filter((i) => i.isShow),
    //eslint-disable-next-line
    [record, folders]
  );

  const { isCanEdit } = useRecordPermissions({
    userId: user.id,
    userPermissionToVideoRecords: record.userPermissionToVideoRecords,
    teamMembersPermission: record.teamMembersPermission,
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(createRecordLink(record.prefix));
  };

  const handleInsertVideoToPage = ({ pageId }: ChooseFolderModalForm) => {
    const page = pages.find((p) => p.id === pageId);
    if (!page) return;

    const contentItem = page.content.contentItems.reduce((acc, val) =>
      acc['order'] < val['order'] ? acc : val
    );

    if (!contentItem) return;

    const newContent = [...(contentItem.contentJson.content ?? [])];

    newContent.unshift(
      JSONContentFactory.createVideoRecord({
        playbackId: record.playbackId || '',
        prefix: record.prefix,
        aspectRatio: record?.metadata?.mux?.aspectRatio || DEFAULT_RATIO,
      }),
      JSONContentFactory.createParagraph()
    );

    dispatch(
      recordingsModel.actions.insertVideoToPage({
        page,
        contentItemId: contentItem.id,
        data: {
          contentJson: {
            ...contentItem.contentJson,
            content: newContent,
          },
        },
      })
    );
  };

  //eslint-disable-next-line
  const handleChangeName = useCallback(
    debounce(
      (name: string) =>
        dispatch(
          recordingsModel.actions.updateRecordName({ id: record.id, name })
        ),
      300
    ),
    []
  );

  const isRecordInProgress = checkIsRecordInProgress(record);
  const isRecordConvertingFailed = checkIsRecordConvertingFailed(record);

  const isCreateVideoPageDisabled =
    isRecordInProgress || isCreatePageLoading || isRecordConvertingFailed;

  const filteredRecordDropdownItems = useMemo(() => {
    if (isRecordInProgress) {
      return recordDropdownItems.filter((item) => item.id === 'delete');
    }

    return recordDropdownItems;
  }, [recordDropdownItems, isRecordInProgress]);

  return (
    <>
      {isInsertToPageModalOpen && (
        <InsertToPageModal
          loading={isRecordInsertToPageLoading}
          onClose={() => setIsInsertToPageModalOpen(false)}
          onChoose={handleInsertVideoToPage}
        />
      )}
      <header className="px-4 h-16 border-b border-gray-200 flex items-center gap-x-2">
        <CreatePageWorkflow />
        <PaywallModal />
        {recordIdToDelete && <RecordDeleteModal />}
        {recordIdToEdit && <RecordRenameModal />}
        {recordIdToShare && <RecordShareModal />}
        <div className="flex flex-nowrap gap-x-3 items-center shrink-0 mr-1">
          <Button
            color="link-gray"
            variant="icon-text"
            size="sm"
            className="!font-semibold !text-gray-700"
            onClick={() =>
              dispatch(
                navigate({
                  to: QUICK_RECORDINGS_MY_ROUTE,
                  isKeepQueryParams: false,
                })
              )
            }
          >
            <Icon glyph={IconMap.ChevronLeft} width={20} />
          </Button>
          <div className="h-5 w-px bg-base-black/10 shrink-0" />
        </div>
        <SelectableInput
          variant="title"
          readonly={!isCanEdit}
          valueReadonly={record.name || DEFAULT_NAME}
          value={record.name}
          onChange={handleChangeName}
          placeholder="Untitled"
          classNames="w-full max-w-120"
        />
        <div className="ml-auto flex flex-nowrap items-center gap-x-2">
          {filteredRecordDropdownItems.length > 0 && (
            <Dropdown
              listStyles="shadow-lg"
              triggerComponent={
                <DropdownMenu.Trigger
                  className={cn(
                    'flex items-center justify-center w-9 h-9 rounded-lg focus:outline-none',
                    'border border-gray-300 bg-base-white text-gray-700',
                    'hover:bg-gray-50'
                  )}
                >
                  <Icon glyph={IconMap.DotsVertical} width={20} />
                </DropdownMenu.Trigger>
              }
              items={filteredRecordDropdownItems}
            />
          )}
          {!isRecordInProgress && (
            <Tooltip
              keepOpenOnTrigger
              trigger={
                <Button
                  variant="icon"
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    handleCopy();
                    onActive();
                  }}
                >
                  <Icon glyph={IconMap.Link} width={20} />
                </Button>
              }
              sideOffset={4}
            >
              <p className="p-1 font-semibold text-xs">
                {isActive ? 'Link copied!' : 'Copy link'}
              </p>
            </Tooltip>
          )}
          <Dropdown
            listStyles="shadow-lg"
            triggerComponent={
              <DropdownMenu.Trigger
                asChild
                className="focus:outline-none"
                disabled={isCreateVideoPageDisabled}
              >
                <Button
                  type="button"
                  variant="text"
                  color="primary"
                  size="sm"
                  loading={isCreatePageLoading}
                  disabled={isCreateVideoPageDisabled}
                >
                  <Icon glyph={IconMap.Plus} width={20} className="mr-1.5" />{' '}
                  Add to Page
                </Button>
              </DropdownMenu.Trigger>
            }
            items={createPageItems}
          />
        </div>
      </header>
    </>
  );
};
