import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';

const matrix: unknown[][] = new Array(10).fill(new Array(10).fill(0));

type Props = {
  onClose: () => void;
  onSelect: (rows: number, cols: number) => void;
};

export const TableSelector: FC<Props> = ({ onClose, onSelect }) => {
  const [activeTableIndexes, setActiveTableIndexes] = useState([0, 0]);
  const ref = useRef<HTMLDivElement | null>(null);
  const [x, y] = activeTableIndexes;

  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      ref={ref}
      className="bg-base-white rounded-lg shadow-lg py-3 px-2 border border-gray-200"
    >
      <div className="flex items-center justify-between mb-2.5 px-1">
        <p className="text-sm text-gray-900 font-semibold">Insert Table</p>
        <span className="text-sm text-gray-600">
          {x + 1}x{y + 1}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center p-0.5">
        {matrix.map((i, idxX) => (
          <div key={idxX} className="flex items-center justify-center">
            {i.map((_, idxY) => {
              const isActive = idxX <= x && idxY <= y;
              return (
                <button
                  onMouseEnter={() => {
                    setActiveTableIndexes([idxX, idxY]);
                  }}
                  onClick={() => {
                    onSelect(x + 1, y + 1);
                  }}
                  className="p-0.5"
                  key={`${idxX}-${idxY}`}
                >
                  <span
                    className={cn(
                      'w-6 h-6 block bg-base-white border border-gray-300 rounded-sm',
                      { 'bg-primary-50 border-primary-600': isActive }
                    )}
                  ></span>
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
