import {
  RegenerateApiKeyRequest,
  RegenerateApiKeyResponse,
} from '@distribute/shared/api-types/zapier';
import { api } from './instance';

export const integrationsApi = {
  async regenerateApiKey(data: RegenerateApiKeyRequest) {
    const { data: responseData } = await api.post<RegenerateApiKeyResponse>(
      'zapier/regenerate-api-key',
      data
    );

    return responseData;
  },
};
