import React from 'react';
import { useSelector } from 'react-redux';
import {
  editorSidebarModel,
  SidebarStepsEnum,
  TemplateSidebarTabsEnum,
} from '../../../../features/editor-sidebar';
import { EditorSidebarConversions } from './conversion-tab';
import { EditorSidebarSettings } from './EditorSidebarSettings';
import { EditorSidebarTemplateSettings } from './EditorSidebarTemplateSettings';
import { EditorSidebarDistribute } from './distribute-tab';

type Props = {
  isTemplateMode?: boolean;
};

export const EditorSidebarContent: React.FC<Props> = ({ isTemplateMode }) => {
  const selectedSidebarStep = useSelector(
    editorSidebarModel.selectors.selectSidebarStep
  );

  const selectedTemplateSidebarTab = useSelector(
    editorSidebarModel.selectors.selectTemplateSidebarTab
  );

  if (isTemplateMode) {
    switch (selectedTemplateSidebarTab) {
      case TemplateSidebarTabsEnum.CONVERSIONS:
        return <EditorSidebarConversions isTemplateMode={isTemplateMode} />;
      case TemplateSidebarTabsEnum.SETTINGS:
        return <EditorSidebarTemplateSettings />;
    }
  }

  switch (selectedSidebarStep) {
    case SidebarStepsEnum.CONVERSIONS:
      return <EditorSidebarConversions />;
    case SidebarStepsEnum.SETTINGS:
      return <EditorSidebarSettings />;
    case SidebarStepsEnum.DISTRIBUTE:
      return <EditorSidebarDistribute />;
  }
};
