import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Link } from '../../processes/navigation';
import { INDEX_ROUTE } from '../../entities/history';
import { Icon } from '../../shared/ui';
import { IconMap } from '../../shared/sprite';

export const TermsOfUse: React.FC = () => {
  return (
    <>
      <Helmet title="Terms Of Use" />
      <div className="flex flex-grow flex-col items-center">
        <header className="flex flex-col items-center border-b border-r-gray-200 w-full h-16 py-4 md:px-8 sm:px-4">
          <div className="max-w-304.5 w-full">
            <Link to={INDEX_ROUTE}>
              <Icon glyph={IconMap.DistributeLogo} />
            </Link>
          </div>
        </header>
        <div className="max-w-200 md:px-8 sm:px-4">
          <h1 className="text-display-lg font-display font-semibold text-gray-900 text-center py-24 tracking-tight md:text-display-md md:py-16">
            Terms of Use
          </h1>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            Thank you for using our software application (the "Application").
            The Application is provided by Distribute Labs, Inc. and is intended
            for use as is. By accessing or using the Application, you agree to
            be bound by these Terms of Use (the "Terms"). If you do not agree to
            these Terms, do not use the Application.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            1. License to Use the Application
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            Distribute Labs, Inc.grants you a limited, non-exclusive,
            non-transferable, revocable license to use the Application for your
            personal, non-commercial use. You may not use the Application for
            any other purpose, or in any other manner, without Distribute Labs,
            Inc.'s prior written consent.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            2. Modification of the Application and Terms
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            Distribute Labs, Inc. reserves the right to modify, suspend, or
            discontinue the Application or any portion thereof at any time, for
            any reason, and without notice. Distribute Labs, Inc.also reserves
            the right to modify these Terms at any time, and will post the
            modified Terms on this page. Your continued use of the Application
            after any such modification constitutes your acceptance of the
            modified Terms. It is your responsibility to review the Terms
            periodically for any changes.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            3. User Content
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            The Application allows you to create your own micro-websites and add
            conversions like "book a meeting" with you on the micro-websites. By
            using the Application to create your micro-website, you are solely
            responsible for the content that you post on your micro-website,
            including any text, images, or other materials. You represent and
            warrant that you have all necessary rights to the content that you
            post on your micro-website and that the content does not infringe on
            the intellectual property rights of any third party.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            4. Privacy
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            Distribute Labs, Inc.respects your privacy and is committed to
            protecting it. Our privacy policy, which is available at [Insert
            link to privacy policy], explains how we collect, use, and share
            information about you when you use the Application. By using the
            Application, you consent to the collection, use, and sharing of your
            information as described in our privacy policy.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            5. Disclaimer of Warranties
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            THE APPLICATION IS PROVIDED "AS IS" AND "AS AVAILABLE," AND
            Distribute Labs, Inc. EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
            KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT
            LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Distribute Labs, Inc.
            MAKES NO WARRANTY THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS,
            OR THAT THE APPLICATION WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            6. Limitation of Liability
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            IN NO EVENT SHALL Distribute Labs, Inc. BE LIABLE FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
            INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL,
            USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF Distribute Labs, Inc.
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM
            YOUR USE OF THE APPLICATION.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            7. Governing Law
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            These Terms and your use of the Application shall be governed by and
            construed in accordance with the laws of the State of Delaware
            without giving effect to any principles of conflicts of law.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            8. Dispute Resolution
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            Any dispute arising out of or relating to these Terms or the use of
            the Application shall be resolved through binding arbitration in
            accordance with the commercial arbitration rules of the American
            Arbitration Association. The arbitration shall be conducted in the
            State of Delaware.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            9. Severability
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            If any provision of these Terms is found to be invalid or
            unenforceable, that provision shall be enforced to the maximum
            extent possible, and the remaining provisions shall remain in full
            force and effect.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            10. Waiver
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            The failure of Distribute Labs, Inc. to exercise or enforce any
            right or provision of these Terms shall not constitute a waiver of
            such right or provision.
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            11. Entire Agreement
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            These Terms constitute the entire agreement between you and
            Distribute Labs, Inc. and govern your use of the Application,
            superseding any prior agreements between you and Distribute Labs,
            Inc. (including, but not limited to, any prior versions of the
            Terms).
          </p>

          <h2 className="text-2xl font-semibold text-gray-900 pt-10 pb-5 md:text-m md:pb-4">
            Contact Us
          </h2>
          <p className="text-lg font-normal text-gray-600 md:text-base">
            If you have any questions about these Terms, please contact us at{' '}
            <a
              className="text-primary-700 underline"
              href="mailto:legal@distribute.so"
            >
              legal@distribute.so
            </a>
            .
          </p>
        </div>

        <footer className="text-gray-500 border-t border-r-gray-200 pt-16 pb-12 mt-24 w-full text-center md:pt-12 md:mt-16">
          © 2023 Distribute. All rights reserved.
        </footer>
      </div>
    </>
  );
};
