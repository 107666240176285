import { fork, select, takeEvery } from 'redux-saga/effects';
import { User } from '@distribute/shared/types';
import { analyticsIdentify, analyticsIdentifyWorker } from '..';
import { authUserModel } from '../../auth-user';

export function* analyticsGlobalWorker() {
  const user: User = yield select(authUserModel.selectors.selectUserWithError);

  yield fork(analyticsIdentify, user);
  yield takeEvery(authUserModel.actions.setDBUser, analyticsIdentifyWorker);
}
