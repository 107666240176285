import cn from 'classnames';

import { usePlayer } from '../../../lib';
import { ActionKind } from '../../../provider';
import { PlayerButton } from './PlayerButton';
import { Slider } from '../../Slider';
import { Icon, Icons } from '../../../icons';
import { PlayerTooltip } from './PlayerTooltip';
import { PlayerKey } from './PlayerKey';

export const PlayerVolume = () => {
  const { state, config, dispatch, size } = usePlayer();
  const { volume, fullscreen } = state;
  const { hotKeys } = config;

  const sizeControl = fullscreen ? 'md' : 'sm';

  return (
    <div className="flex flex-nowrap gap-x-2 items-center group/volume">
      <PlayerTooltip
        isOpen={fullscreen || hotKeys ? undefined : false}
        size={sizeControl}
        trigger={
          <PlayerButton
            size={sizeControl}
            variant="transparent"
            onClick={() => dispatch({ type: ActionKind.TOGGLE_VOLUME })}
          >
            <Icon
              svg={volume === 0 ? Icons.VolumeOff : Icons.VolumeOn}
              size={sizeControl}
            />
          </PlayerButton>
        }
      >
        <span>{volume === 0 ? 'Unmute' : 'Mute'}</span>
        <PlayerKey size={sizeControl}>M</PlayerKey>
      </PlayerTooltip>

      <div
        className={cn('invisible group-hover/volume:visible', {
          'w-20': size === 'md',
          'w-10': size === 'sm',
        })}
      >
        <Slider
          variant="range"
          id="volume"
          min={0}
          max={1}
          step={0.05}
          value={[volume]}
          onValueChange={([value]) =>
            dispatch({ type: ActionKind.VOLUME, payload: value })
          }
          className="h-3"
          size={sizeControl}
        />
      </div>
    </div>
  );
};
