import React from 'react';
import cn from 'classnames';
import { PageCreationWorkflowsEnum } from '../../../../../../entities/create-page-modal/model/types';
import { useDispatch } from 'react-redux';
import { createPageModalModel } from '../../../../../../entities/create-page-modal';

type Props = {
  bgUrl: string;
  className: string;
  icon: any;
  name: string;
  description: string;
  workflowName: PageCreationWorkflowsEnum;
};

export const CreatePageWorkflowItem: React.FC<Props> = ({
  bgUrl,
  className,
  icon,
  name,
  description,
  workflowName,
}) => {
  const dispatch = useDispatch();

  const handleGoNext = () => {
    dispatch(createPageModalModel.actions.goNext(workflowName));
  };

  return (
    <div
      onClick={handleGoNext}
      className="w-58.5 h-58 border border-base-black-8 rounded-xl shadow-xs hover:shadow-lg transition cursor-pointer relative overflow-hidden"
    >
      <img
        src={bgUrl}
        alt="background"
        className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none"
      />
      <img
        src="../../../assets/images/vector.png"
        alt="background"
        className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none"
      />
      <div className="h-25 flex items-center justify-center mb-2 absolute top-0 left-0 right-0">
        <div
          className={cn(
            'w-12 h-12 rounded-xl border border-base-white bg-base-white flex items-center justify-center',
            className
          )}
        >
          {icon}
        </div>
      </div>
      <div className="mt-27 px-5">
        <h3 className="text-xl font-semibold text-gray-900 text-center mb-2">
          {name}
        </h3>
        <p className="text-gray-600 text-sm font-normal text-center">
          {description}
        </p>
      </div>
    </div>
  );
};
