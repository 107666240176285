import { call, put, select, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { popUpApi } from '../../../../shared/api';
import { PopUp } from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { UpdatePopUpImageResponseType } from '@distribute/shared/api-types/pop-up';
import { checkIsDraftTemplate, getPageIdByContentId } from '../../lib';
import { templatesModel } from '../../../templates';
import { selectPopUp } from '../selectors';
import { updatePopUpDB } from './updatePopUpDB';

const uploadFileChannel = channel<number>();

export function* updatePopUp({
  payload: { documentContentId, isRemoveImage, callback, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updatePopUp>) {
  try {
    yield put(actions.setUpdatePopUpIsLoading(true));

    const popUp: PopUp = yield select(conversionKitModel.selectors.selectPopUp);

    yield put(
      actions.setPopUp({
        ...popUp,
        ...rest,
        imageUrl: isRemoveImage ? undefined : popUp.imageUrl,
      })
    );

    if (isRemoveImage) {
      yield call(popUpApi.removePopUpImage, documentContentId);
    }

    const pageId: string | undefined = yield call(
      getPageIdByContentId,
      documentContentId
    );

    const isDraftTemplate: boolean = yield call(
      checkIsDraftTemplate,
      documentContentId
    );

    if (!pageId && !isDraftTemplate) {
      yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      yield call(updatePopUpDB, actions.updatePopUpDB({ documentContentId }));
    }

    if (callback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update pop-up`)
      )
    );
  } finally {
    yield put(actions.setUpdatePopUpIsLoading(false));
  }
}

export function* changePopUpImage({
  payload: { documentContentId, image },
}: ReturnType<typeof actions.changePopUpImage>) {
  try {
    yield put(actions.setIsUploadImageLoading(true));

    const popUp = (yield popUpApi.updatePopUpImage(
      documentContentId,
      image,
      (progress) => uploadFileChannel.put(progress)
    )) as UpdatePopUpImageResponseType;

    const popUpFromState: PopUp = yield select(selectPopUp);

    yield put(
      actions.setPopUp({ ...popUpFromState, imageUrl: popUp.imageUrl })
    );
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update pop-up image`)
      )
    );
  } finally {
    yield put(actions.setIsUploadImageLoading(false));
    uploadFileChannel.put(0);
  }
}

export function* removePopUpImage({
  payload: { documentContentId },
}: ReturnType<typeof actions.removePopUpImage>) {
  try {
    yield put(actions.setIsRemovePopUpImageLoading(true));
    yield put(actions.updatePopUpDB({ documentContentId }));

    const popUpFromState: PopUp = yield select(selectPopUp);

    yield put(actions.setPopUp({ ...popUpFromState, imageUrl: undefined }));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to remove pop-up image`)
      )
    );
  } finally {
    yield put(actions.setIsRemovePopUpImageLoading(false));
  }
}

export function* watchUploadPopUpImage() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;
    yield put(actions.setUploadPopUpImageProgress(progress));
  }
}
