import { boolean, object, string } from 'yup';

export const workspaceSchema = object().shape({
  name: string().required('Input team name'),
  isSubdomainValid: boolean().required().oneOf([true]),
});

export type WorkspaceForm = {
  name: string;
  isSubdomainValid: boolean;
};
