import { FC } from 'react';

import { PlayerError, PlayerLoader, PlayerPreview } from './';
import { Player } from './Player';

import { usePlayer } from '../lib';

export const PlayerManager: FC = () => {
  const { state, config } = usePlayer();

  if (config.loading) return <PlayerLoader />;

  if (config.error || state.error) return <PlayerError />;

  return state.start ? <Player /> : <PlayerPreview />;
};
