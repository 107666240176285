import React, { FC } from 'react';
import { Icon, SpriteGlyph } from '../../shared/ui/Icon';

type IProps = {
  title: string;
  icon: SpriteGlyph;
};

export const AlertBarTitle: FC<IProps> = ({ title, icon }) => {
  return (
    <div className="flex gap-2">
      <Icon glyph={icon} width={20} />
      {title}
    </div>
  );
};
