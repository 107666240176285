import { IconMap } from '../../../../../../shared/sprite';
import { Button, Select } from '../../../../../../shared/ui';
import React, { useCallback } from 'react';
import { SocialPostBlock } from './SocialPostBlock';
import { editorSidebarModel } from '../../../../../../features/editor-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../../../../features/pages';
import { SOCIAL_PLATFORMS_ENUM } from '../../../../../../features/editor-sidebar/model/reducer';
import { socialModel } from '../../../../../../entities/social-auth';
import { getSocialCoverImage } from '../helpers';
import {
  createNotification,
  snackbarModel,
} from '../../../../../../features/snackbar';
import cn from 'classnames';
import { SocialPlatformData } from './types';

type Props = {
  isActive: boolean;
};

export const SocialLinkMenu: React.FC<Props> = ({ isActive }) => {
  const dispatch = useDispatch();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const linkedinProfileId = useSelector(
    socialModel.selectors.selectLinkedinProfileId
  );

  const isLoggedIntoLinkedin = useSelector(
    socialModel.selectors.selectIsLoggedIntoLinkedin
  );

  const isLoggedIntoTwitter = useSelector(
    socialModel.selectors.selectIsLoggedIntoTwitter
  );

  const isConnectingToLinkedin = useSelector(
    socialModel.selectors.selectIsConnectingToLinkedin
  );

  const isConnectingToTwitter = useSelector(
    socialModel.selectors.selectIsConnectingToTwitter
  );

  const isRevokingLinkedinToken = useSelector(
    socialModel.selectors.selectIsRevokingLinkedinToken
  );

  const isRevokingTwitterToken = useSelector(
    socialModel.selectors.selectIsRevokingTwitterToken
  );

  const isSharingLinkedinPost = useSelector(
    socialModel.selectors.selectIsSharingLinkedinPost
  );

  const isSharingTwitterPost = useSelector(
    socialModel.selectors.selectIsSharingTwitterPost
  );

  const selectedSocialPlatform = useSelector(
    editorSidebarModel.selectors.selectSelectedSocialPlatform
  );

  const postContent = useSelector(
    socialModel.selectors.selectSocialPostContent
  );

  const personalizedLinkTitle = useSelector(
    editorSidebarModel.selectors.selectPersonalizedLinkTitle
  );

  const getPostText = () => {
    return postContent
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n')
      .replace(/^(<br>)+/, '')
      .replace(/(<br>)+$/, '')
      .replace(/<br>/g, '\n')
      .replace(/<br\/>/g, '\n')
      .replace(
        /<a href="(.+)" text="(.+)" class="custom-link">(.+)<\/a>/g,
        '$1'
      );
  };

  const SOCIAL_PLATFORMS: SocialPlatformData[] = [
    {
      id: SOCIAL_PLATFORMS_ENUM.LINKEDIN,
      title: 'Linkedin',
      btnTitle: 'Share to Linkedin',
      iconName: IconMap.Linkedin,
      disabledIconName: IconMap.LinkedinDisabled,
      isLoggedIn: isLoggedIntoLinkedin,
      maxCharacters: 3000,
      isLoading: isConnectingToLinkedin || isRevokingLinkedinToken,
      clickHandler: () => {
        dispatch(socialModel.actions.setIsSharingLinkedinPost(true));
        getSocialCoverImage()
          .catch((_) => {
            dispatch(
              snackbarModel.actions.addNotificationAction(
                createNotification(
                  'error',
                  'Failed to generate image for social'
                )
              )
            );
            socialModel.actions.setIsSharingLinkedinPost(false);
          })
          .then((imageData) => {
            dispatch(
              socialModel.actions.shareLinkedinPost({
                text: getPostText(),
                file: imageData as Blob,
                userId: linkedinProfileId,
              })
            );
          });
      },
    },
    {
      id: SOCIAL_PLATFORMS_ENUM.TWITTER,
      title: 'Twitter',
      btnTitle: 'Share to Twitter',
      iconName: IconMap.Twitter,
      disabledIconName: IconMap.TwitterDisabled,
      isLoggedIn: isLoggedIntoTwitter,
      isLoading: isConnectingToTwitter || isRevokingTwitterToken,
      maxCharacters: 280,
      clickHandler: () => {
        dispatch(socialModel.actions.setIsSharingTwitterPost(true));
        getSocialCoverImage()
          .catch((_) => {
            dispatch(
              snackbarModel.actions.addNotificationAction(
                createNotification(
                  'error',
                  'Failed to generate image for social'
                )
              )
            );
            socialModel.actions.setIsSharingTwitterPost(false);
          })
          .then((imageData) => {
            dispatch(
              socialModel.actions.shareTwitterPost({
                text: getPostText(),
                file: imageData as Blob,
                userId: linkedinProfileId,
              })
            );
          });
      },
    },
    {
      id: SOCIAL_PLATFORMS_ENUM.GMAIL,
      title: 'Gmail',
      btnTitle: 'Share to Gmail',
      iconName: IconMap.Gmail2,
      clickHandler: () => {
        const url = new URL('https://mail.google.com/mail/u/0/');
        url.searchParams.append('view', 'cm');
        url.searchParams.append(
          'su',
          personalizedLinkTitle !== null
            ? personalizedLinkTitle
            : currentPage.content.title ?? ''
        );
        url.searchParams.append('body', getPostText());
        window.open(url);
      },
    },
  ];

  const currentPlatform = SOCIAL_PLATFORMS.find(
    (el) => el.id === selectedSocialPlatform
  ) as SocialPlatformData;

  const setCurrentPlatform = (platform: SOCIAL_PLATFORMS_ENUM) => {
    dispatch(editorSidebarModel.actions.setSelectedSocialPlatform(platform));
  };

  const setPostContent = useCallback(
    (content: string) => {
      dispatch(socialModel.actions.setSocialPostContent(content));
    },
    [dispatch]
  );

  const handleEdit = () => {
    dispatch(editorSidebarModel.actions.setDistributeEditCoverPanelOpen(true));
  };

  const handleSocialLogout = () => {
    if (currentPlatform.id === SOCIAL_PLATFORMS_ENUM.LINKEDIN) {
      dispatch(socialModel.actions.revokeLinkedinToken());
    } else if (currentPlatform.id === SOCIAL_PLATFORMS_ENUM.TWITTER) {
      dispatch(socialModel.actions.revokeTwitterToken());
    }
  };

  const handleSocialSignin = () => {
    if (currentPlatform.id === SOCIAL_PLATFORMS_ENUM.LINKEDIN) {
      dispatch(socialModel.actions.connectToLinkedin());
    } else if (currentPlatform.id === SOCIAL_PLATFORMS_ENUM.TWITTER) {
      dispatch(socialModel.actions.connectToTwitter());
    }
  };

  const handleSocialPlatformChange = (id: string) => {
    const selectedValue = SOCIAL_PLATFORMS.find((el) => el.id === id);
    if (selectedValue) setCurrentPlatform(selectedValue.id);
  };

  if (!isActive) return null;

  return (
    <>
      <p className="text-sm text-gray-700">
        Share your page with your contacts by utilizing mails and social media
        platforms.
      </p>
      <div className="flex gap-2 items-end">
        <Select
          label="Select platform"
          value={currentPlatform.id}
          onChange={handleSocialPlatformChange}
          items={SOCIAL_PLATFORMS}
          currentValue={currentPlatform}
          buttonStyles="!min-h-11 !h-11 !mb-0 translate-y-0.5"
          listStyles="translate-y-1.5"
        />
        {currentPlatform.id !== SOCIAL_PLATFORMS_ENUM.GMAIL && (
          <Button
            variant="text"
            color="secondary"
            className="text-sm font-medium"
            onClick={
              currentPlatform.isLoggedIn
                ? handleSocialLogout
                : handleSocialSignin
            }
            loading={currentPlatform.isLoading}
          >
            {currentPlatform.isLoggedIn ? 'Disconnect' : 'Connect'}
          </Button>
        )}
      </div>
      <SocialPostBlock
        postContent={postContent}
        setPostContent={setPostContent}
        maxCharacters={currentPlatform.maxCharacters}
        getPostText={getPostText}
      />

      <div className="flex justify-between">
        <Button
          variant="icon-text"
          color="secondary"
          onClick={handleEdit}
          className="!px-4 !text-sm !font-semibold"
          iconLeftName={IconMap.Edit02}
          iconLeftWidth={20}
          size="md"
        >
          Edit cover
        </Button>
        <Button
          variant="icon-text"
          color="secondary"
          onClick={currentPlatform.clickHandler}
          className={cn('!px-4 !text-sm !font-semibold', {
            '!text-gray-500': !currentPlatform.isLoggedIn,
          })}
          iconLeftName={
            currentPlatform.isLoggedIn === false ||
            !!(
              currentPlatform.maxCharacters &&
              getPostText().length > currentPlatform.maxCharacters
            )
              ? currentPlatform.disabledIconName
              : currentPlatform.iconName
          }
          iconLeftWidth={20}
          size="md"
          loading={isSharingLinkedinPost || isSharingTwitterPost}
          disabled={
            currentPlatform.isLoggedIn === false ||
            !!(
              currentPlatform.maxCharacters &&
              getPostText().length > currentPlatform.maxCharacters
            )
          }
        >
          {currentPlatform.btnTitle}
        </Button>
      </div>
    </>
  );
};
