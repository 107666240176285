import {
  PageDuplicateFromPublicTemplateRequestType,
  PageDuplicateFromPublicTemplateResponseType,
} from '@distribute/shared/api-types/pages';
import { api } from './instance';

export const publicTemplatesApi = {
  async duplicatePage(
    pageId: string,
    data: PageDuplicateFromPublicTemplateRequestType
  ) {
    const { data: responseData } =
      await api.post<PageDuplicateFromPublicTemplateResponseType>(
        `pages/${pageId}/duplicate-from-public-template`,
        data
      );
    return responseData;
  },
};
