import {
  VideoRecordType,
  VideoRecordDetailType,
  UpdateVideoRecordRequestType,
} from '@distribute/shared/api-types/video-record';
import {
  DocumentContentItem,
  Page,
  UserPermissionToVideoRecordRole,
  VideoRecordTeamMembersPermission,
} from '@distribute/shared/types';

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { globalActions } from '../../../app/model/actions';
import { VideoRecordLoadingStatus } from './types';

import { ALL_OWNERS, SortType } from '../config';

type State = {
  records: VideoRecordType[];
  recordsStatus: VideoRecordLoadingStatus;
  record: VideoRecordDetailType;
  recordStatus: VideoRecordLoadingStatus;
  recordIdToEdit: string | null;
  isRecordInsertToPageLoading: boolean;
  isRecordEditLoading: boolean;
  recordIdToDelete: string | null;
  isRecordDeleteLoading: boolean;
  recordIdToShare: string | null;
  isRecordShareLoading: boolean;
  filterSearch: string;
  sort: SortType;
  filterOwner: string;
};

const initialState: State = {
  records: [],
  recordsStatus: VideoRecordLoadingStatus.Never,
  record: {} as VideoRecordDetailType,
  recordStatus: VideoRecordLoadingStatus.Never,
  recordIdToEdit: null,
  isRecordEditLoading: false,
  isRecordInsertToPageLoading: false,
  recordIdToDelete: null,
  isRecordDeleteLoading: false,
  recordIdToShare: null,
  isRecordShareLoading: false,
  filterSearch: '',
  sort: SortType.LAST_CREATED,
  filterOwner: ALL_OWNERS,
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    setIsRecordInsertToPageLoading: (
      state,
      { payload }: PayloadAction<State['isRecordInsertToPageLoading']>
    ) => ({
      ...state,
      isRecordInsertToPageLoading: payload,
    }),
    setIsRecordEditLoading: (
      state,
      { payload }: PayloadAction<State['isRecordEditLoading']>
    ) => ({
      ...state,
      isRecordEditLoading: payload,
    }),
    setRecordIdToEdit: (
      state,
      { payload }: PayloadAction<State['recordIdToEdit']>
    ) => ({
      ...state,
      recordIdToEdit: payload,
    }),
    setIsRecordDeleteLoading: (
      state,
      { payload }: PayloadAction<State['isRecordDeleteLoading']>
    ) => ({
      ...state,
      isRecordDeleteLoading: payload,
    }),
    setRecordIdToDelete: (
      state,
      { payload }: PayloadAction<State['recordIdToDelete']>
    ) => ({
      ...state,
      recordIdToDelete: payload,
    }),
    setRecordIdToShare: (
      state,
      { payload }: PayloadAction<State['recordIdToShare']>
    ) => ({
      ...state,
      recordIdToShare: payload,
    }),
    setIsRecordShareLoading: (
      state,
      { payload }: PayloadAction<State['isRecordShareLoading']>
    ) => ({
      ...state,
      isRecordShareLoading: payload,
    }),
    setRecordsStatus: (
      state,
      { payload }: PayloadAction<State['recordsStatus']>
    ) => ({
      ...state,
      recordsStatus: payload,
    }),
    setRecords: (state, { payload }: PayloadAction<State['records']>) => ({
      ...state,
      records: payload,
    }),
    setRecordStatus: (
      state,
      { payload }: PayloadAction<State['recordStatus']>
    ) => ({
      ...state,
      recordStatus: payload,
    }),
    setRecord: (state, { payload }: PayloadAction<State['record']>) => ({
      ...state,
      record: payload,
    }),
    setFilterSearch: (
      state,
      { payload }: PayloadAction<State['filterSearch']>
    ) => ({
      ...state,
      filterSearch: payload,
    }),
    setSort: (state, { payload }: PayloadAction<State['sort']>) => ({
      ...state,
      sort: payload,
    }),
    setFilterOwner: (
      state,
      { payload }: PayloadAction<State['filterOwner']>
    ) => ({
      ...state,
      filterOwner: payload,
    }),
    resetFilters: (state) => ({
      ...state,
      filterSearch: '',
      sort: SortType.LAST_CREATED,
      filterOwner: ALL_OWNERS,
    }),
  },

  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updateRecord: createAction<UpdateVideoRecordRequestType>(
    'recordings/updateRecord'
  ),
  updateRecordName: createAction<{ id: string; name: string }>(
    'recordings/updateRecordName'
  ),
  deleteRecord: createAction('recordings/deleteRecord'),
  downloadRecord: createAction<
    Pick<VideoRecordType, 'videoQuality' | 'prefix' | 'teamId'>
  >('recordings/downloadRecord'),
  updateTeamMemberPermission: createAction<{
    teamMembersPermission: VideoRecordTeamMembersPermission;
  }>('recordings/updateTeamMemberPermission'),
  createUserPermission: createAction<{
    userIds: string[];
    role: UserPermissionToVideoRecordRole;
    cb: () => void;
  }>('recordings/createUserPermission'),
  updateUserPermission: createAction<{
    userPermissionToVideoRecordId: number;
    role: UserPermissionToVideoRecordRole;
  }>('recordings/updateUserPermission'),
  deleteUserPermission: createAction<{ userPermissionToVideoRecordId: number }>(
    'recordings/deleteUserPermission'
  ),
  insertVideoToPage: createAction<{
    page: Page;
    contentItemId: number;
    data: Partial<DocumentContentItem>;
    cb?: () => void;
  }>('recordings/insertVideoToPage'),
};
