import React, { FC, useState } from 'react';
import { ValidationError, string } from 'yup';
import { Button, ItemValue, TagsInput } from '../../../../../shared/ui';

type IProps = {
  isLoading: boolean;
  className?: string;
  onInviteMembers: (emails: string[]) => void;
};
const validateEmailSchema = string().required().email();

export const InviteMembersForm: FC<IProps> = ({
  isLoading,
  className,
  onInviteMembers,
}) => {
  const [members, setMembers] = useState<ItemValue[]>([]);
  const [isErrorTagsInput, setIsErrorTagsInput] = useState(false);

  const validateMemberEmail = (name: string) => {
    try {
      validateEmailSchema.validateSync(name);
      return;
    } catch (error) {
      return (error as ValidationError).errors;
    }
  };

  const handleChangeMembers = (members: ItemValue[]) => {
    setMembers(members);
    setIsErrorTagsInput(false);
  };

  const handleSendInvitations = () => {
    const isAnyMemberInvalid = members.some((member) => member.isError);

    if (!members.length || isAnyMemberInvalid) {
      setIsErrorTagsInput(true);
      return;
    }

    const emails = members.map((member) => member.value);

    onInviteMembers(emails);
  };

  return (
    <div className={className}>
      <TagsInput
        label="Enter members emails here:"
        value={members}
        onChange={handleChangeMembers}
        validateItemValue={validateMemberEmail}
        isError={isErrorTagsInput}
      />
      <p className="mt-1.5 mb-4 text-sm font-normal text-gray-600">
        Please remember to press [Enter] after each email.
      </p>
      <Button
        fullWidth
        color="primary"
        size="lg"
        variant="text"
        onClick={handleSendInvitations}
        loading={isLoading}
        disabled={isLoading}
      >
        Send Invitations
      </Button>
    </div>
  );
};
