import * as moment from 'moment';

export const formatMsToTimePassed = (ms: number): string => {
  const duration = moment.duration(ms);
  const time = [
    {
      value: duration.days(),
      text: `${duration.days()}d`,
    },
    {
      value: duration.hours(),
      text: `${duration.hours()}h`,
    },
    {
      value: duration.minutes(),
      text: `${duration.minutes()}m`,
    },
    {
      value: duration.seconds(),
      text: `${duration.seconds()}s`,
    },
  ];

  return (
    time
      .filter(({ value }) => value > 0)
      .map(({ text }) => text)
      .join(' ') || `${Math.round((ms / 1000) * 100) / 100}s`
  );
};
