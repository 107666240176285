import React, { FC } from 'react';
import { Button, Modal } from '../../../../shared/ui';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const RequestTooLargeModal: FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="AI request is too large"
      isShowCancelButton={false}
      actionButton={
        <Button fullWidth color="primary" variant="text" onClick={onClose}>
          Got it
        </Button>
      }
    >
      <div className="text-sm text-gray-600">
        The message you submitted was too long, please submit something shorter.
      </div>
    </Modal>
  );
};
