import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Button, ButtonProps } from '../../../../shared/ui';
import {
  ButtonSize,
  ButtonSizeVariants,
  ButtonType,
  ButtonTypeVariants,
} from './constants';

type Props = Omit<ButtonProps, 'children' | 'size' | 'color'> & {
  icon?: string;
  label?: string;
  color: ButtonType;
  size: ButtonSize;
};

type Variant = 'icon-only' | 'default';

const additionalButtonSizeStyles: Record<
  ButtonSize,
  Record<Variant, string>
> = {
  [ButtonSize.Small]: {
    default: '!text-sm !h-9 !px-3 !py-2',
    'icon-only': '!text-sm !h-9 !w-9 !p-2',
  },
  [ButtonSize.Medium]: {
    default: '!text-base !h-12 !px-4 !py-3',
    'icon-only': '!text-base !h-12 !w-12 !p-3',
  },
  [ButtonSize.Large]: {
    default: '!text-xl !h-16 !px-5 !py-4',
    'icon-only': '!text-xl !h-16 !w-16 !p-4',
  },
};

const additionalIconSizeStyles: Record<ButtonSize, Record<Variant, string>> = {
  [ButtonSize.Small]: {
    default: 'w-5 h-5',
    'icon-only': 'w-5 h-5',
  },
  [ButtonSize.Medium]: {
    default: 'w-6 h-6',
    'icon-only': 'w-6 h-6',
  },
  [ButtonSize.Large]: {
    default: 'w-8 h-8',
    'icon-only': 'w-8 h-8',
  },
};

export const ButtonComponent = ({
  size,
  className,
  icon,
  color,
  label,
  ...props
}: PropsWithChildren<Props>) => {
  const variantKey = label ? 'default' : 'icon-only';
  return (
    <Button
      {...props}
      size={ButtonSizeVariants[size]}
      color={ButtonTypeVariants[color]}
      className={classNames(
        className,
        'shadow-xs font-semibold',
        additionalButtonSizeStyles[size ?? ButtonSize.Small][variantKey]
      )}
    >
      {icon && (
        <span
          className={classNames(
            additionalIconSizeStyles[size ?? ButtonSize.Small][variantKey],
            {
              'mr-1': label,
            },
            'pt-0.5'
          )}
        >
          {icon}
        </span>
      )}
      <span>{label}</span>
    </Button>
  );
};
