import React, { useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  ChartDataset,
} from 'chart.js';

import { Checkbox } from '../../../../shared/ui';
import { useSelector } from 'react-redux';
import { analyticsModel } from '../../model';
import {
  AnalyticsConversionSource,
  AnalyticsMetric,
  ConversionLine,
} from '../../types';
import { renderPoint, renderTooltip } from '../../lib';
import { TabContent } from '../../../../shared/ui';
import { NoData } from './NoData';
import { ChartTypeState, DateUnitState } from '../const';
import { DateRange } from '../../../../shared/ui/date-range-picker';
import {
  getCumulativeArray,
  getRangeData,
  checkIsAnalyticsConversionMetric,
} from '../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend
);

type Props = {
  toggleConversionLine: (id: AnalyticsConversionSource) => void;
  conversionLines: ConversionLine[];
  selectedItem: TabContent;
  dateUnit: DateUnitState;
  chartType: ChartTypeState;
  dateRange?: DateRange;
};

type AnalyticsChartType = 'line';
type AnalyticsChartData = ChartData<AnalyticsChartType, number[]>;
type AnalyticsChartDataset = ChartDataset<AnalyticsChartType, number[]>;

const LINE_COLORS = [
  '#079455',
  '#EAAA08',
  '#D92D20',
  '#1570EF',
  '#BA24D5',
  '#6938EF',
];

const getScaleYMaxValue = (chartData: AnalyticsChartData) => {
  const allValues = chartData.datasets.flatMap((item) => item.data);

  return Math.max(...allValues) + 1;
};

export const LinearChart: React.FC<Props> = ({
  toggleConversionLine,
  conversionLines,
  selectedItem,
  dateUnit,
  chartType,
  dateRange,
}) => {
  const tooltipElement = useRef<HTMLElement | null>(null);

  const emptyState = useSelector(analyticsModel.selectors.selectEmptyState);
  const analyticsData = useSelector(analyticsModel.selectors.selectChartData);
  const selectedItemName = selectedItem.name as AnalyticsMetric;

  const chartConfig = useMemo<{
    data: AnalyticsChartData;
    isDataEmpty: boolean;
  }>(() => {
    let datasets: AnalyticsChartDataset[];

    const getDataSet = (
      data: number[],
      lineIndex = 0
    ): AnalyticsChartDataset => ({
      data,
      borderColor: LINE_COLORS[lineIndex],
      borderWidth: 2,
      yAxisID: 'y',
      pointStyle: () => renderPoint(LINE_COLORS[lineIndex]),
    });

    let isDataEmpty = false;
    const rangeData = getRangeData(analyticsData, dateRange);

    if (checkIsAnalyticsConversionMetric(selectedItemName)) {
      datasets = conversionLines.reduce<AnalyticsChartDataset[]>(
        (acc, conversionLine, index) => {
          const data = rangeData.map(
            (item) => item[selectedItemName][conversionLine.id]
          );

          isDataEmpty = !data.some((item) => item > 0);

          if (!conversionLine.isShow) {
            return acc;
          }

          const resultData =
            chartType === ChartTypeState.CUMULATIVE
              ? getCumulativeArray(data)
              : data;
          acc.push(getDataSet(resultData, index));

          return acc;
        },
        []
      );
    } else {
      const data = rangeData.map((item) => item[selectedItemName]);
      isDataEmpty = !data.some((item) => item > 0);

      const resultData =
        chartType === ChartTypeState.CUMULATIVE
          ? getCumulativeArray(data)
          : data;
      datasets = [getDataSet(resultData)];
    }

    return {
      isDataEmpty,
      data: { labels: rangeData.map(({ date }) => date), datasets },
    };
  }, [analyticsData, selectedItemName, conversionLines, chartType, dateRange]);

  const selectedConversionLine = useMemo(
    () => conversionLines.filter((c) => c.isShow),
    [conversionLines]
  );

  const chartOptions = useMemo<ChartOptions<AnalyticsChartType>>(
    () => ({
      responsive: true,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      animations: { radius: { duration: 0 } },
      elements: {
        point: {
          radius: 0,
          hoverRadius: 1,
        },
      },
      ticks: {
        font: {
          size: 12,
          family: 'Open Sans',
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: (context) => {
            tooltipElement.current = renderTooltip(
              context,
              selectedItem.title,
              selectedItem.name as AnalyticsMetric,
              selectedConversionLine,
              selectedItem.additionalData
            );
          },
          yAlign: 'center',
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'MMM D, h a',
              day: 'MMM D',
              week: 'MMM D',
              month: 'MMM',
            },
            unit: dateUnit,
          },
          grid: {
            display: false,
            drawOnChartArea: false,
          },
        },
        y: {
          min: 0,
          max: getScaleYMaxValue(chartConfig.data),
          grid: {
            color: '#E5E5E5',
          },
        },
      },
    }),
    [
      selectedItem.title,
      selectedItem.name,
      selectedItem.additionalData,
      dateUnit,
      chartConfig,
      selectedConversionLine,
    ]
  );

  useEffect(
    () => () => {
      if (tooltipElement.current) {
        tooltipElement.current.style.display = 'none';
      }
    },
    []
  );

  if (emptyState[selectedItemName] || chartConfig.isDataEmpty) {
    return <NoData />;
  }

  return (
    <>
      <Line height={91} data={chartConfig.data} options={chartOptions} />
      {checkIsAnalyticsConversionMetric(selectedItem.name) && (
        <div className="flex justify-center gap-5 mt-5">
          {conversionLines.map((item, i) => (
            <Checkbox
              key={item.id}
              checked={item.isShow}
              onChange={() => toggleConversionLine(item.id)}
            >
              <h4 className="text-sm font-normal text-gray-600 flex">
                <div
                  className="w-2.5 h-2.5 rounded-full inline-block ml-3 mr-2 shrink-0 mt-[5px]"
                  style={{ backgroundColor: LINE_COLORS[i] }}
                />
                {item.label}
              </h4>
            </Checkbox>
          ))}
        </div>
      )}
    </>
  );
};
