import { FeatureFlags } from '../../../../../entities/feature-flag';
import { environment } from '../../../../../environments/environment';
import { socialApi } from '../../../../../shared/api/axios/social';
import {
  TWITTER_TOKEN_SECRET_STORAGE_KEY,
  TWITTER_TOKEN_STORAGE_KEY,
} from '../../../../../shared/constants';
import { call, put } from 'redux-saga/effects';
import {
  checkIfTwitterProfileDataExists,
  setTwitterProfileData,
  setTwitterProfileDataFromStorage,
} from './helpers';
import { GetTwitterProfileResponse } from '../../types';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';

export function* fetchTwitterProfileData() {
  const isDistributeTabEnabled =
    environment.featureFlags[FeatureFlags.DISTRIBUTE_TAB];

  if (!isDistributeTabEnabled) return;

  if (checkIfTwitterProfileDataExists()) {
    yield call(setTwitterProfileDataFromStorage);
    return;
  }

  try {
    const token = localStorage.getItem(TWITTER_TOKEN_STORAGE_KEY);
    const tokenSecret = localStorage.getItem(TWITTER_TOKEN_SECRET_STORAGE_KEY);

    if (!token || !tokenSecret) {
      return;
    }

    const data: GetTwitterProfileResponse = yield call(
      socialApi.getTwitterProfileData,
      token,
      tokenSecret
    );

    yield call(setTwitterProfileData, data);
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to fetch Twitter profile data')
      )
    );
  }
}
