import { useSelector } from 'react-redux';
import moment from 'moment';
import { SubscriptionStatus } from '@distribute/shared/types';
import { teamsModel } from '../../teams';
import { useMemo } from 'react';
import { checkIsCardExpireSoon } from '../lib';

type SubscriptionInfo = {
  isPaymentFailed: boolean;
  isPendingCancelation: boolean;
  isTrial: boolean;
  isHasOnboardingPromoCode: boolean;
  isHasRenewPromoCode: boolean;
  isShowAlertBannerOnboardingPromoCodeAvailable: boolean;
  isShowAlertBannerTrialExpireSoon: boolean;
  isCardExpireSoon: boolean;
  isNeedConfirmPaymentMethod: boolean;
};
export const useSubscriptionInfo = (): SubscriptionInfo => {
  const {
    stripeSubscription,
    subscriptionOnboardingPromoCode,
    subscriptionRenewPromoCode,
    subscriptionPaymentMethod,
    currentMember,
  } = useSelector(teamsModel.selectors.selectCurrentTeamWithError);

  return useMemo<SubscriptionInfo>(() => {
    const isHasOnboardingPromoCode =
      !!subscriptionOnboardingPromoCode &&
      moment().isBefore(moment(subscriptionOnboardingPromoCode.expiresAt));

    return {
      isPaymentFailed:
        stripeSubscription?.status === SubscriptionStatus.PAST_DUE,
      isPendingCancelation: !!stripeSubscription?.cancelAt,
      isTrial: stripeSubscription?.status === SubscriptionStatus.TRIALING,
      isHasOnboardingPromoCode,
      isHasRenewPromoCode:
        !!subscriptionRenewPromoCode &&
        moment().isBefore(moment(subscriptionRenewPromoCode.expiresAt)),
      isShowAlertBannerOnboardingPromoCodeAvailable:
        !stripeSubscription && isHasOnboardingPromoCode,
      isShowAlertBannerTrialExpireSoon:
        stripeSubscription?.status === SubscriptionStatus.TRIALING &&
        !stripeSubscription.cancelAt &&
        !subscriptionPaymentMethod,
      isCardExpireSoon: checkIsCardExpireSoon(subscriptionPaymentMethod),
      isNeedConfirmPaymentMethod:
        currentMember.isWaitingForUpdateSubscriptionPaymentMethod,
    };
  }, [
    stripeSubscription,
    subscriptionOnboardingPromoCode,
    subscriptionRenewPromoCode,
    subscriptionPaymentMethod,
    currentMember.isWaitingForUpdateSubscriptionPaymentMethod,
  ]);
};
