import React, { useMemo, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { snippetsModel } from '../../../../features/snippets';
import {
  SnippetsConfigBasicEnum,
  SnippetsSwitcherEnum,
  switcherButtons,
} from './lib/config';

type Props = {
  onClose(): void;
  sortMethod: SnippetsSwitcherEnum;
  setSortMethod: Dispatch<SetStateAction<SnippetsSwitcherEnum>>;
};

export const SnippetsModalHeader: React.FC<Props> = ({
  onClose,
  sortMethod,
  setSortMethod,
}) => {
  const currentFolder = useSelector(
    snippetsModel.selectors.selectCurrentModalFolder
  );

  const categories = useSelector(snippetsModel.selectors.selectCategories);

  const searchQuery = useSelector(
    snippetsModel.selectors.selectModalSearchQuery
  );

  const headerText = useMemo(() => {
    switch (currentFolder) {
      case SnippetsConfigBasicEnum.RECENT:
        return {
          title: 'Recently Used',
          desc: 'Snippets that you have recently utilized and may need to reuse in the near future. ',
        };

      case SnippetsConfigBasicEnum.ALL:
        return {
          title: 'All Snippets',
          desc: 'Snippets that help you to work faster and more efficiently.',
        };

      case SnippetsConfigBasicEnum.PERSONAL:
        return {
          title: 'Personal',
          desc: 'Craft snippets that match your unique style.',
        };

      default:
        return {
          title: categories.find((c) => c.id === currentFolder)?.name || '',
          desc:
            categories.find((c) => c.id === currentFolder)?.description || '',
        };
    }
  }, [categories, currentFolder]);

  return (
    <div className="flex items-start justify-between gap-8 pt-4 pb-4 pl-8 pr-4 overflow-hidden font-medium text-gray-900 shrink-0 text-display-xs font-display">
      <div className="flex items-start justify-between flex-grow gap-4 md:flex-col">
        <div className="flex flex-col">
          <p className="mb-1 font-semibold text-display-sm font-display">
            {searchQuery || headerText.title}
          </p>
          {(searchQuery || headerText.desc) && (
            <p className="text-sm font-normal text-gray-700 font-base">
              {searchQuery
                ? 'Templates based on your search results.'
                : headerText.desc}
            </p>
          )}
        </div>

        {currentFolder === SnippetsConfigBasicEnum.ALL && (
          <div className="flex gap-1 p-1 border border-gray-200 rounded-lg bg-gray-50 font-base">
            {switcherButtons.map((button) => (
              <button
                key={button.id}
                onClick={() => {
                  setSortMethod(button.id);
                }}
                className={cn(
                  'px-3 py-2 text-sm font-semibold rounded-md hover:bg-base-white hover:shadow-sm hover:text-gray-700',
                  {
                    'text-gray-500': sortMethod !== button.id,
                    'text-gray-700 bg-base-white shadow-sm':
                      sortMethod === button.id,
                  }
                )}
              >
                {button.title}
              </button>
            ))}
          </div>
        )}
      </div>

      <Button variant="icon" color="transparent" onClick={onClose} size="md">
        <Icon
          glyph={IconMap.XClose}
          className="text-gray-500 cursor-pointer"
          width={20}
        />
      </Button>
    </div>
  );
};
