import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '../../../../entities/auth-operation';
import { Input, Button } from '../../../../shared/ui';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { history, redirectActions } from '../../../../entities/history';

const MESSAGE_USER_ALREADY_EXISTS =
  'The email you entered is already associated with account. Try signing in or use a different email';

const validationSchema = object().shape({
  fullName: string().required('Input your full name').trim(),
  email: string()
    .required('Input your email')
    .email(
      'The email you entered seems invalid. Please check and enter a valid email address'
    ),
  password: string()
    .required('Input your password')
    .min(
      8,
      'Your password is too short. It should be at least 8 characters long'
    ),
});

type SignUpForm = {
  email: string;
  fullName: string;
  password: string;
};

const getEmailFromURL = () => {
  const { search } = history.location;
  const urlParams = new URLSearchParams(search);
  const email = decodeURIComponent(urlParams.get('email') || '');

  return email;
};

const CreateAppSumoAccountForm = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const authLoading = useSelector(authModel.selectors.selectLoading);
  const isWaitingToRedirectAfterAuth = useSelector(
    authModel.selectors.selectIsWaitingToRedirectAfterAuth
  );
  const emailFromURL = getEmailFromURL() || undefined;

  if (!emailFromURL) {
    dispatch(redirectActions.toSignup());
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: emailFromURL,
    },
  });
  const fullNameFieldProps = register('fullName');
  const emailFieldProps = register('email');
  const isAuthErrorUserAlreadyExist = authError === MESSAGE_USER_ALREADY_EXISTS;
  const isSignUpBtnLoading = authLoading || isWaitingToRedirectAfterAuth;

  const handleSubmitForm = (data: SignUpForm) => {
    if (isAuthErrorUserAlreadyExist) return;

    dispatch(
      authModel.actions.appsumoSignUpClick({
        email: data.email,
        password: data.password,
        fullName: data.fullName,
      })
    );
  };

  return (
    <form className="mb-6" onSubmit={handleSubmit(handleSubmitForm)} noValidate>
      <div className="mb-5">
        <Input
          {...fullNameFieldProps}
          type="text"
          autoComplete="name"
          label="Full Name"
          isError={!!errors.fullName}
          messageText={errors.fullName?.message}
        />
      </div>

      <div className="mb-5">
        <Input
          {...emailFieldProps}
          onChange={(e) => {
            emailFieldProps.onChange(e);
            if (isAuthErrorUserAlreadyExist) {
              dispatch(authModel.actions.setAuthError(''));
            }
          }}
          type="email"
          autoComplete="username"
          label="Email"
          isError={!!errors.email || isAuthErrorUserAlreadyExist}
          messageText={isAuthErrorUserAlreadyExist ? authError : undefined}
          disabled={!!emailFromURL}
        />
      </div>

      <Input
        autoComplete="new-password"
        {...register('password')}
        type="password"
        label="Password"
        isError={!!errors.password}
        messageText={
          errors.password?.message || 'Password must be at least 8 characters'
        }
      />

      {!isAuthErrorUserAlreadyExist && authError && (
        <p className="mt-6 px-4 py-1 text-xs rounded bg-error-100 text-error-700">
          {authError}
        </p>
      )}

      <div className="mt-6">
        <Button
          variant="text"
          color="primary"
          disabled={isSignUpBtnLoading}
          loading={isSignUpBtnLoading}
          type="submit"
          fullWidth
        >
          Get Started
        </Button>
      </div>
    </form>
  );
};

export default CreateAppSumoAccountForm;
