import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { aiModel } from '../../../../../features/ai';

export const useUserScrollHandler = (
  onScroll: () => void,
  handlingCondition: boolean,
  root: HTMLElement | null
) => {
  const responses = useSelector(aiModel.selectors.selectResponses);

  const [isStartedScrolling, setIsStartedScrolling] = useState(false);
  const listeningConditionRef = useRef(
    handlingCondition && !isStartedScrolling
  );

  useEffect(() => {
    setIsStartedScrolling(false);
  }, [responses.length]);

  useEffect(() => {
    listeningConditionRef.current = handlingCondition && !isStartedScrolling;
  }, [handlingCondition, isStartedScrolling]);

  useEffect(() => {
    if (!root) {
      return;
    }

    const scrollListener = () => {
      if (listeningConditionRef.current) {
        setIsStartedScrolling(true);
      }
    };

    const interval = setInterval(() => {
      if (!listeningConditionRef.current) {
        root.removeEventListener('scroll', scrollListener);
        return;
      }

      root.removeEventListener('scroll', scrollListener);

      onScroll();

      setTimeout(() => {
        root.addEventListener('scroll', scrollListener);
      }, 100);
    }, 500);

    return () => {
      clearInterval(interval);
      root.removeEventListener('scroll', scrollListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);
};
