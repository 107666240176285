import { eventChannel } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { navigate } from '../../../../processes/navigation';
import { createNotification, snackbarModel } from '../../../snackbar';
import { messages } from '../../config';
import { createRecordLink } from '../../lib/record-link';
import { logger, matchParams } from '../../../../shared/lib';
import { QUICK_RECORDING_ROUTE } from '../../../../entities/history';

const intervalChannel = eventChannel((emitter) => {
  const timer = setInterval(() => emitter(''), 300);
  return () => clearInterval(timer);
});

export function* autoCopyRecordLink() {
  yield takeLatest(intervalChannel, copyLink);
}

function* copyLink() {
  const { pathname, search } = window.location;

  const params = matchParams<{ prefix?: string }>(
    pathname,
    QUICK_RECORDING_ROUTE
  );

  if (!params?.prefix) {
    intervalChannel.close();
    return;
  }

  if (!parseInt(new URLSearchParams(search).get('copy') || '0')) {
    intervalChannel.close();
    return;
  }

  try {
    yield call(copy, createRecordLink(params.prefix));

    yield put(navigate({ to: pathname, isKeepQueryParams: false }));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', messages.record.copyLink)
      )
    );

    intervalChannel.close();
  } catch (e) {
    logger.error('Record Link copy error');
  }
}

async function copy(text: string) {
  await navigator.clipboard.writeText(text);
}
