import { useRef, useState } from 'react';

export const useTimer = (time?: number) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isActive, setIsActive] = useState(false);

  const onActive = () => {
    if (timer?.current) {
      clearTimeout(timer?.current);
    }

    setIsActive(true);
    timer.current = setTimeout(() => setIsActive(false), time ?? 3_000);
  };

  return [isActive, onActive] as const;
};
