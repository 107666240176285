import { UserToTeamRole } from '@distribute/shared/types';

export const getUserRoles = (role?: UserToTeamRole) => {
  return {
    isAdmin: role === UserToTeamRole.ADMIN,
    isOwner: role === UserToTeamRole.OWNER,
    isMember: role === UserToTeamRole.MEMBER,
    isGuest: role === UserToTeamRole.GUEST || !role,
  };
};
