import { call, put } from 'redux-saga/effects';
import { templatesApi } from '../../../shared/api/axios';
import { GetTemplatesResponseType } from '@distribute/shared/api-types/templates';
import { logger } from '../../../shared/lib';
import { templatesModel } from '../model';

export function* setTopTemplatesData() {
  try {
    const templates: GetTemplatesResponseType = yield call(
      templatesApi.getTopTemplates
    );

    yield put(templatesModel.actions.setTopTemplates(templates));
  } catch (err) {
    logger.error(err);
  }
}
