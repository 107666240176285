import { put, takeEvery } from 'redux-saga/effects';
import { AnalyticsEvents } from '@distribute/shared/types';

import { authModel } from '../../../entities/auth-operation';
import { redirect, redirectActions } from '../../../entities/history';
import { signUp } from '../../../features/auth/sign-up';
import { analytics } from '../../../entities/analytics';
import { googleSignUp } from '../../../features/auth/google-sign-up';

export function* signUpWorker() {
  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(authModel.actions.signUpClick, signUp);
  yield takeEvery(authModel.actions.googleSignupClick, googleSignUp);
  yield takeEvery(redirectActions.toLoginClick, redirect.toLogin);

  analytics.track(AnalyticsEvents.SIGN_UP_STARTED);
}
