import { Dispatch, FC, SetStateAction, useState } from 'react';
import cn from 'classnames';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { CreateSnippetButton } from '../../../features/snippets/ui/components';
import { IconMap } from '../../../shared/sprite';
import { Dropdown, Icon } from '../../../shared/ui';
import { SnippetsList } from './SnippetsList';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSnippetsPermissions } from '../../../features/teams';

type Props = {
  category: SnippetCategoryWithSnippets;
  setEditingCategory: Dispatch<
    SetStateAction<SnippetCategoryWithSnippets | undefined>
  >;
  setDeletingCategory: Dispatch<
    SetStateAction<SnippetCategoryWithSnippets | undefined>
  >;
};

export const CategoryItem: FC<Props> = ({
  category,
  setEditingCategory,
  setDeletingCategory,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const { isCanManageCategories } = useSnippetsPermissions();

  const options = [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => {
        setEditingCategory(category);
      },
      iconName: IconMap.Edit02,
      iconWidth: 16,
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        setDeletingCategory(category);
      },
      iconName: IconMap.Delete,
      iconWidth: 16,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between">
        <button
          className="flex justify-center items-center gap-x-2"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <Icon
            glyph={IconMap.ArrowDown}
            className={cn('transition-all text-gray-600', {
              '-rotate-90': !isOpen,
            })}
          />
          <div className="flex flex-col gap-y-1">
            <div className="flex justify-center items-center gap-x-3">
              <span className="text-gray-900 text-lg font-semibold">
                {category.name}
              </span>
              <div className="bg-gray-50 border border-gray-200 rounded-full text-xs font-medium flex justify-center items-center w-5.5 h-5.5">
                {category.snippets.length}
              </div>
            </div>
          </div>
        </button>
        {isCanManageCategories && (
          <Dropdown
            listStyles="shadow-lg"
            triggerComponent={
              <DropdownMenu.Trigger className="flex sm:hidden items-center justify-center w-9 h-9 rounded-lg focus:outline-none hover:bg-gray-50">
                <Icon glyph={IconMap.DotsVertical} width={20} />
              </DropdownMenu.Trigger>
            }
            items={options}
          />
        )}
      </div>

      {isOpen && (
        <div
          className={cn('pl-8', {
            'mt-3': !category.description,
            'mt-1': category.description,
          })}
        >
          {category.description && (
            <p className="text-gray-700 text-sm mb-3">{category.description}</p>
          )}
          {category.snippets.length > 0 ? (
            <SnippetsList snippets={category.snippets} />
          ) : (
            <div className="py-10 px-6 rounded-xl flex flex-col gap-y-3 justify-center items-center bg-gray-50 border border-dashed border-gray-300">
              <p className="text-sm text-gray-600">
                No snippets have been added to this category yet. Get started by
                adding your first one!
              </p>
              <CreateSnippetButton size="sm" category={category} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
