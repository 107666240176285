import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { recordingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';

import {
  checkIsRecordInProgress,
  getFilterOwnerResults,
  getFilterSearchResults,
  getSortResults,
} from '../../lib';

import { RecordSharedCard } from './RecordSharedCard';
import { RecordCardInProgress } from './RecordCardInProgress';
import { RecordCategory } from './types';

import {
  RecordsCount,
  RecordsOwnerFilter,
  RecordsSearchFilter,
  RecordsSort,
} from './RecordsFilters';

import { RecordsNotFound } from './RecordsNotFound';

export const RecordsShared: FC = () => {
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);

  const records = useSelector(
    recordingsModel.selectors.selectRecordsShared(currentUser.id)
  );

  const sort = useSelector(recordingsModel.selectors.selectSort);

  const filterSearch = useSelector(
    recordingsModel.selectors.selectFilterSearch
  );

  const filterOwner = useSelector(recordingsModel.selectors.selectFilterOwner);

  const filteredRecords = useMemo(() => {
    const searchResults = getFilterSearchResults(records, filterSearch);

    const searchOwnerResults = getFilterOwnerResults(
      searchResults,
      filterOwner
    );

    return getSortResults(searchOwnerResults, sort);
  }, [filterSearch, filterOwner, sort, records]);

  return (
    <>
      <div className="mb-8 sm:mb-6 sm:flex-col flex items-center justify-between gap-4">
        <div className="flex items-center flex-nowrap gap-x-4 w-full">
          <RecordsSearchFilter />
          <RecordsCount value={filteredRecords.length} />
        </div>
        <div className="flex flex-nowrap gap-2 items-center justify-end w-full">
          <RecordsSort />
          <RecordsOwnerFilter />
        </div>
      </div>
      {filteredRecords.length ? (
        <div className="flex flex-col flex-grow-1 mb-8 sm:mb-4">
          <div className="grid gap-5 grid-cols-[repeat(auto-fill,minmax(320px,1fr))]">
            {filteredRecords.map((v) =>
              checkIsRecordInProgress(v) ? (
                <RecordCardInProgress
                  record={v}
                  key={v.id}
                  category={RecordCategory.SHARED}
                />
              ) : (
                <RecordSharedCard record={v} key={v.id} />
              )
            )}
          </div>
        </div>
      ) : (
        <RecordsNotFound />
      )}
    </>
  );
};
