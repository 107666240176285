import { random } from 'lodash';
import { useState, useEffect } from 'react';

interface LoadingState {
  isLoading: boolean;
  percentage: number;
  startLoading: (newSpeed: number, until: number) => void;
  reset: () => void;
}

export const useFakeLoading = (initialSpeed = 1000): LoadingState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(initialSpeed);
  const [until, setUntil] = useState<number>(100);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading && percentage < 99) {
      timer = setTimeout(
        () => {
          setPercentage(
            (prevPercentage) =>
              prevPercentage +
              (percentage < until ? random(1, 20) : random(0.01, 0.1))
          );
        },
        percentage < until ? speed : speed * 2
      );
    }

    return () => clearTimeout(timer);
  }, [isLoading, percentage, speed, until]);

  const startLoading = (newSpeed: number, until: number) => {
    setSpeed(newSpeed);
    setUntil(until);
    setIsLoading(true);
  };

  const reset = () => {
    setIsLoading(false);
    setPercentage(0);
  };

  return { isLoading, percentage, startLoading, reset };
};
