import { SimpleLayout } from '../../../shared/ui';
import { Helmet } from 'react-helmet-async';

export const RecordingBlankPage = () => {
  return (
    <>
      <Helmet titleTemplate="Recordings - Distribute" />
      <SimpleLayout hideLogo hideImage />
    </>
  );
};
