import { ValidationError } from 'yup';
import { ButtonSchema } from './constants';
import { ButtonAttrs } from './types';

export type ButtonErrors = Partial<Record<keyof ButtonAttrs, string[]>>;

export const validate = (attrs: ButtonAttrs): ButtonErrors => {
  try {
    ButtonSchema.validateSync(attrs, { abortEarly: false });
  } catch (error) {
    if (error instanceof ValidationError) {
      const errorObject = error.inner.reduce((acc, curr) => {
        acc[curr.path as keyof ButtonAttrs] = curr.errors;
        return acc;
      }, {} as ButtonErrors);
      return errorObject;
    }
  }

  return {};
};
