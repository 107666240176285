import {
  SnippetCategoryWithSnippets,
  SnippetType,
} from '@distribute/shared/types';

export const getAvailableCategoriesBySnippetType = (
  categories: SnippetCategoryWithSnippets[],
  type: SnippetType
) => {
  return categories.filter((category) => category[type]);
};
