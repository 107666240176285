import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ConfigureSnippetForm } from '../../../../features/snippets/ui/components/create-snippet/ConfigureSnippetForm';
import { snippetsModel } from '../../../../features/snippets';
import { Snippet } from '@distribute/shared/types';
import { useWindowWidth } from '@distribute/frontend/utils';

type Props = {
  isOpen: boolean;
};

export const SnippetEditorSidebar: React.FC<Props> = ({ isOpen }) => {
  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  ) as Snippet;

  const { isMobile } = useWindowWidth();

  if (isMobile) return null;

  return (
    <aside
      id="editor-sidebar"
      className={cn(
        'w-120 bg-gray-75 fixed h-[calc(100vh-64px)] z-40 border-l border-gray-200 transition-all top-16',
        {
          'right-0': isOpen,
          '-right-120': !isOpen,
        }
      )}
    >
      <div className={cn('pt-6 pb-8 px-6 overflow-y-auto h-[calc(100%-64px)]')}>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Snippet Settings
        </h2>
        <hr className="border-gray-200 mt-5 mb-6" />
        <ConfigureSnippetForm
          isHideSubmitButtons
          type={currentSnippet.type}
          snippet={currentSnippet}
          isEditor
          onClose={() => {
            return;
          }}
        />
      </div>
    </aside>
  );
};
