import React, { forwardRef, useMemo } from 'react';
import { AIMenuCategory, Menu } from './Menu';
import { AIDraftItem, AIDraftsData } from '../../config';

type IProps = {
  isOpen: boolean;
  onChange: (item: AIDraftItem) => void;
  searchValue: string;
  onFocus: () => void;
};

export const AIDraftsMenu = forwardRef<HTMLDivElement, IProps>(
  ({ isOpen, onChange, searchValue, onFocus }, ref) => {
    const menuItems: AIMenuCategory[] = useMemo(
      () => [
        {
          id: 'ai-drafts',
          items: AIDraftsData,
        },
      ],
      []
    );

    return (
      <Menu
        ref={ref}
        isOpen={isOpen}
        items={menuItems}
        searchValue={searchValue}
        onChange={(item) =>
          onChange(AIDraftsData.find((i) => i.id === item.id) as AIDraftItem)
        }
        onFocus={onFocus}
      />
    );
  }
);
