import { authUserModel } from '../../../entities/auth-user';
import { SimpleLayout, Button } from '../../../shared/ui';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { authModel } from '../../../entities/auth-operation';
import { EMAIL_VERIFICATION_TIMESTAMP } from '../../../shared/constants';

export const EmailVerification: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserModel.selectors.selectUser);

  const [timer, setTimer] = useState(() => {
    const storedTimestamp = sessionStorage.getItem(
      EMAIL_VERIFICATION_TIMESTAMP
    );
    const timestamp = storedTimestamp
      ? JSON.parse(storedTimestamp)
      : new Date();
    const diff = Math.trunc(
      (new Date(timestamp).getTime() - new Date().getTime()) / 1000
    );
    return diff > 0 ? diff : 0;
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((value: number) => value - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleVerifyEmailClick = () => {
    dispatch(authModel.actions.verifyEmailClick());
    setTimer(59);
  };

  return (
    <>
      <Helmet title="Verify email" />
      <SimpleLayout classNameContent="!w-147.5 md:px-8 sm:!w-full">
        <div className="mb-3 flex justify-center items-center">
          <p className="text-display-md font-medium font-display text-gray-900 text-center">
            Check your email
          </p>
        </div>

        <div className="mb-6 flex flex-col justify-center items-center font-normal text-md text-center text-gray-600 ">
          <p>
            A verification email has been sent to{' '}
            <span className="font-semibold">{user?.email}</span>. Please click
            the link provided in the email to verify your email address.
          </p>
          <p className="mt-6">
            <b className="text-gray-800 font-semibold">
              If you do not see the email in a few minutes, check your “junk
              mail” folder or “spam” folder.
            </b>{' '}
            We make every effort to ensure that these emails are delivered.
          </p>
        </div>

        <div className="flex justify-center items-center">
          {timer ? (
            <p className="font-semibold text-sm text-center text-gray-300">
              Send Again in {timer}s
            </p>
          ) : (
            <Button
              variant="text"
              color="link"
              size="sm"
              className="!h-5 font-semibold"
              onClick={handleVerifyEmailClick}
            >
              Send Again
            </Button>
          )}
        </div>
      </SimpleLayout>
    </>
  );
};
