/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import cn from 'classnames';

import {
  ColorListPicker,
  ColorListPickerItem,
} from '../../../../../shared/ui/color-list-picker';
import { Editor } from '@tiptap/react';
import { DEFAULT_EDITOR_COLORS_CONFIG, EditorColor } from './config';

type Props = {
  editor: Editor;
  currentColor: string;
};

export const BubbleMenuColorPickerDropdown = React.forwardRef<
  HTMLDivElement,
  Props
>(({ editor, currentColor }, ref) => {
  const hashedColors = useMemo(() => {
    return DEFAULT_EDITOR_COLORS_CONFIG.reduce((prev, curr) => {
      prev.set(curr.value, curr);
      return prev;
    }, new Map<string, EditorColor>());
  }, []);
  const onClick = (color: string) => {
    editor.chain().focus().setColor(color).run();
  };

  const handleColorReset = () => {
    onClick(DEFAULT_EDITOR_COLORS_CONFIG[0].value);
  };

  const appliedColors = [
    ...((editor.view as any)?.docView.children.reduce(
      (prev: Set<string>, curr: any) => {
        const getColors = (curr: any) => {
          return curr.children?.reduce((innerPrev: Array<any>, el: any) => {
            if (!el.mark && el.children) {
              return [...innerPrev, ...getColors(el)];
            } else {
              innerPrev.push(el.mark?.attrs?.color);
              return innerPrev;
            }
          }, []);
        };

        getColors(curr)?.forEach((el: string) => prev.add(el));

        return prev;
      },
      new Set()
    ) ?? []),
  ]
    .filter((color) => !!color && hashedColors.has(color))
    .filter((color) => color !== DEFAULT_EDITOR_COLORS_CONFIG[0].value)
    .map((color) => hashedColors.get(color));

  return (
    <div
      ref={ref}
      className={cn(
        'absolute w-45 bg-base-white shadow-md p-4 top-9 right-7 border border-gray-200 rounded-lg'
      )}
    >
      <ColorListPicker
        isResetVisible
        onReset={handleColorReset}
        onSetColor={onClick}
        currentColor={currentColor}
        colorList={DEFAULT_EDITOR_COLORS_CONFIG}
      />
      {!!appliedColors.length && (
        <>
          <hr className="border-gray-200 mt-4 mb-[14px]" />
          <p className="text-gray-700 font-medium text-sm text-left mb-3">
            Document colors
          </p>
          <div className="flex items-center flex-wrap gap-1">
            {(appliedColors as EditorColor[]).map((color) => (
              <ColorListPickerItem
                key={color.value}
                color={color.value}
                name={color.name}
                onClick={onClick}
                active={currentColor === color.value}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
});
