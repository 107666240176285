import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { authUserModel } from '../../../../entities/auth-user';
import { DBUser } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { TeamFiltered } from '@distribute/shared/types';
import { logger } from '../../../../shared/lib';
import { snippetsApi } from '../../../../shared/api/axios/snippets';
import { snippetsModel } from '..';
import { setSnippetsData } from '../../lib';

export function* createCategory({
  payload: { callback, ...payloadData },
}: ReturnType<typeof snippetsModel.actions.createCategory>) {
  try {
    yield put(snippetsModel.actions.setIsCreateCategoryLoading(true));

    const { id: userId }: DBUser = yield select(
      authUserModel.selectors.selectUser
    );
    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeam
    );

    yield call(snippetsApi.createSnippetCategory, {
      ...payloadData,
      userId,
      teamId,
    });

    yield call(setSnippetsData);

    callback();
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to create snippet category')
      )
    );
  } finally {
    yield put(snippetsModel.actions.setIsManageCategoriesModalOpen(true));
    yield put(snippetsModel.actions.setIsCreateCategoryLoading(false));
  }
}
