import React, { FC, useState } from 'react';
import { IconMap } from '../../shared/sprite';
import {
  Button,
  Checkbox,
  Dropdown,
  FileInput,
  Icon,
  Input,
  Notification,
  RadioButton,
  Select,
  Toggle,
} from '../../shared/ui';
import { radioOptions, foldersOptions, checkboxOptions } from './const';
import { FilterState } from '../workspace';

export const ComponentsPage: FC = () => {
  const [selected, setSelected] = useState(radioOptions[0].id);
  const [isActive, setIsActive] = useState(false);
  const [closeError, setIsCloseError] = useState(false);
  const [closeWarning, setIsCloseWarning] = useState(false);
  const [closeSuccess, setIsCloseSuccess] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>(
    () => []
  );

  const dropdownItems = [
    {
      label: 'All',
      onClick: () => {
        setCurrentItemId(FilterState.All);
      },
      id: FilterState.All,
    },
    {
      label: 'Published',
      onClick: () => {
        setCurrentItemId(FilterState.Published);
      },
      id: FilterState.Published,
    },
    {
      label: 'Not Published',
      onClick: () => {
        setCurrentItemId(FilterState.Drafts);
      },
      id: FilterState.Drafts,
    },
  ];

  const [currentItemId, setCurrentItemId] = useState(dropdownItems[0].id);

  const [value, setValue] = useState(foldersOptions[0].id);
  const currentValue = foldersOptions.find((i) => i.id === value);

  return (
    <div className="py-6 px-20 whitespace-nowrap">
      <div className="text-display-md font-medium text-center">
        UI components
      </div>

      <div className="flex flex-col gap-y-4 items-start">
        <div className="flex flex-row gap-x-2">
          <Button
            variant="icon-text"
            color="primary"
            iconLeftName={IconMap.Plus}
          >
            Primary Button
          </Button>
          <Button
            variant="icon-text"
            color="secondary"
            iconLeftName={IconMap.Plus}
          >
            Secondary Button
          </Button>
          <Button
            variant="icon-text"
            color="destructive"
            iconLeftName={IconMap.Plus}
          >
            Destructive Button
          </Button>
          <Button variant="icon-text" color="link" iconLeftName={IconMap.Plus}>
            Link
          </Button>
          <Button
            variant="icon-text"
            color="link-gray"
            iconLeftName={IconMap.Plus}
          >
            Link Gray
          </Button>
          <Button
            variant="icon-text"
            color="tertiary-black"
            iconLeftName={IconMap.Plus}
          >
            Tertiary Black Button
          </Button>
        </div>

        <div className="flex flex-row gap-x-2">
          <Button variant="text" color="primary">
            Primary Button
          </Button>
          <Button variant="text" color="secondary">
            Secondary Button
          </Button>
          <Button variant="text" color="destructive">
            Destructive Button
          </Button>
          <Button variant="text" color="link">
            Link
          </Button>
          <Button variant="text" color="link-gray">
            Link Gray
          </Button>
          <Button variant="text" color="tertiary-black">
            Tertiary Black Button
          </Button>
          <Button loading variant="text" color="primary">
            Loading Button
          </Button>
        </div>

        <div className="flex flex-row gap-x-2">
          <Button variant="icon" color="primary">
            <Icon glyph={IconMap.Plus} />
          </Button>
          <Button variant="icon" color="secondary">
            <Icon glyph={IconMap.Plus} />
          </Button>
          <Button variant="icon" color="destructive">
            <Icon glyph={IconMap.Plus} />
          </Button>
          <Button variant="icon" color="link">
            <Icon glyph={IconMap.Plus} />
          </Button>
          <Button variant="icon" color="link-gray">
            <Icon glyph={IconMap.Plus} />
          </Button>
          <Button variant="icon" color="tertiary-black">
            <Icon glyph={IconMap.Plus} />
          </Button>
        </div>

        <div className="flex flex-row gap-x-2 items-center">
          <Input type="text" label="Input:" />
          <Input type="text" isError label="Error Input:" />
          <Input type="text" disabled label="Disabled Input:" />
          <Input type="password" label="Password Input:" />
          <Input type="password" isError label="Error Password Input:" />
        </div>

        <div className="flex flex-row gap-x-2">
          <Input
            isTextArea
            type="text"
            messageText="Message Text"
            label="TextArea:"
            placeholder="Enter a description..."
          />
          <Input
            isTextArea
            type="text"
            isError
            messageText="Error Message Text"
            label="Error TextArea:"
            placeholder="Enter a description..."
          />
          <Input
            isTextArea
            type="text"
            disabled
            messageText="Disabled Message Text"
            label="Disabled TextArea:"
            placeholder="Enter a description..."
          />
          <div className="min-w-42 h-11">
            <Select
              label="Folder"
              value={value}
              onChange={setValue}
              items={foldersOptions}
              currentValue={currentValue}
            />
          </div>
        </div>

        <div className="flex flex-row gap-x-8 items-center">
          <div className="flex flex-col gap-y-2">
            <span className="text-sm font-semibold text-gray-700">
              Checkboxes:
            </span>
            {checkboxOptions.map(({ id, label }) => (
              <Checkbox
                key={id}
                checked={selectedCheckboxes.includes(id)}
                onChange={() => {
                  if (selectedCheckboxes.includes(id)) {
                    setSelectedCheckboxes(
                      selectedCheckboxes.filter((item) => item !== id)
                    );
                  } else {
                    setSelectedCheckboxes([...selectedCheckboxes, id]);
                  }
                }}
              >
                <span className="ml-2 text-sm font-medium text-gray-700">
                  {label}
                </span>
              </Checkbox>
            ))}
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm font-semibold text-gray-700">
              RadioButtons:
            </span>
            {radioOptions.map(({ id, ...props }) => (
              <RadioButton
                {...props}
                key={id}
                name="radio-buttons"
                defaultChecked={selected === id}
                onChange={() => setSelected(id)}
              />
            ))}
          </div>

          <div className="flex flex-row gap-x-2 items-center">
            <span className="text-sm font-semibold text-gray-700">Toggle:</span>
            <Toggle
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          </div>

          <div className="flex flex-row gap-x-2 items-center">
            <span className="text-sm font-semibold text-gray-700">
              Disabled Checked Toggle:
            </span>
            <Toggle
              checked
              disabled
              onChange={() => {
                return;
              }}
            />
          </div>

          <div className="flex flex-row gap-x-2 items-center">
            <span className="text-sm font-semibold text-gray-700">
              Disabled Unchecked Toggle:
            </span>
            <Toggle
              checked={false}
              disabled
              onChange={() => {
                return;
              }}
            />
          </div>

          <div className="w-42 h-11 hover:bg-gray-50 z-50">
            <Dropdown
              listStyles="shadow-lg"
              items={dropdownItems}
              currentItemId={currentItemId}
              itemStyles="w-42"
              triggerStyles="w-42 h-11"
            />
          </div>
        </div>

        <div className="flex flex-row gap-x-2">
          <div className="w-120">
            <FileInput isUploading={false} onUpload={() => null} />
          </div>
          <div className="w-120">
            <FileInput isUploading={false} onUpload={() => null} disabled />
          </div>
        </div>

        <div className="flex flex-row gap-x-2">
          {!closeError && (
            <Notification type="error" onClick={() => setIsCloseError(true)}>
              Error Notification
            </Notification>
          )}
          {!closeWarning && (
            <Notification
              type="warning"
              onClick={() => setIsCloseWarning(true)}
            >
              Warning Notification
            </Notification>
          )}
          {!closeSuccess && (
            <Notification
              type="success"
              onClick={() => setIsCloseSuccess(true)}
            >
              Success Notification
            </Notification>
          )}
        </div>
      </div>
    </div>
  );
};
