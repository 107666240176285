import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { authApi } from '../../../../shared/api';
import { handleError } from '../../../../utils/handleError';
import { logger } from '../../../../shared/lib';
import { DBUser } from '../../../../shared/types';
import { UserOnboardingStatus } from '@distribute/shared/types';
import { authUserModel } from '../../../../entities/auth-user';
import { onboardingModel } from '../';
import { finishOnboarding } from './finishOnboarding';

export function* finishExtensionOnboarding() {
  try {
    yield put(onboardingModel.actions.setIsLoadingSkipExtension(true));

    const user: DBUser = yield authApi.updateProfile({
      onboardingStatus: UserOnboardingStatus.FINISHED,
    });

    yield call(finishOnboarding);
    yield put(authUserModel.actions.setDBUser(user));
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(error, 'Failed to update'))
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsLoadingSkipExtension(false));
  }
}
