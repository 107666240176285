import React, { FC } from 'react';
import { Icon } from './Icon';
import { IconMap } from '../sprite';
import classNames from 'classnames';

type Size = 'xs';

type IProps = {
  title: string;
  size?: Size;
  onDelete: () => void;
};

const classNameBySize: Record<Size, string> = {
  xs: 'gap-1.25 pl-2 pr-1.5 py-0.5 text-xs font-medium rounded-md',
};

export const TagItem: FC<IProps> = ({ title, size = 'xs', onDelete }) => {
  return (
    <div
      className={classNames(
        'flex items-center bg-base-white border border-gray-300 text-gray-700 overflow-hidden',
        classNameBySize[size]
      )}
    >
      <span className="truncate">{title}</span>
      <Icon
        glyph={IconMap.XClose}
        width={10}
        className="shrink-0 text-gray-400 cursor-pointer"
        onClick={onDelete}
      />
    </div>
  );
};
