import React, { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';
import { Badge, Button } from '../../../../shared/ui';
import { WatchVideoModal } from './WatchVideoModal';

export type GettingStartedStepItem = {
  id: string;
  title: string;
  description?: string;
  videoUrl?: string;
  time?: string;
  actionBtn?: ReactNode;
  actionBtnTitle?: string;
  actionBtnIcon?: SpriteGlyph;
  isCompleted?: boolean;
  isComingSoon?: boolean;
  isDisabled?: boolean;
  onClickAction?: () => void;
};

type IProps = GettingStartedStepItem & {
  isSelected: boolean;
  onClick: (id: string) => void;
};

export const GettingStartedStep: FC<IProps> = ({
  id,
  actionBtn,
  actionBtnIcon,
  actionBtnTitle,
  description,
  isComingSoon,
  isCompleted,
  onClickAction,
  time,
  title,
  videoUrl,
  isSelected,
  isDisabled,
  onClick,
}) => {
  const [isShowVideo, setIsShowVideo] = useState(false);
  const isCompletedOrComingSoon = isCompleted || isComingSoon;

  return (
    <div className="flex flex-col border-b border-gray-200 pb-6">
      <button
        className={cn('flex justify-between outline-none gap-x-2', {
          'cursor-auto': isDisabled,
        })}
        onClick={isDisabled ? undefined : () => onClick(id)}
      >
        <div className="flex items-center gap-3">
          <Icon
            glyph={
              isCompletedOrComingSoon
                ? IconMap.CheckCircle
                : IconMap.Placeholder
            }
            className={cn('text-primary-600', {
              '!text-gray-400': isCompletedOrComingSoon,
            })}
          />
          <div
            className={cn('text-md font-semibold text-gray-800 truncate', {
              '!text-gray-500 !line-through': isCompletedOrComingSoon,
            })}
          >
            {title}
          </div>
        </div>
        <div className="flex gap-3 items-center">
          {isCompletedOrComingSoon && (
            <Badge theme="success" size="sm">
              {isComingSoon ? 'Coming Soon!' : 'Completed'}
            </Badge>
          )}
          {!isCompletedOrComingSoon && (
            <div className="text-sm text-gray-600 font-medium truncate">
              {time}
            </div>
          )}
          <Icon
            glyph={isSelected ? IconMap.ChevronUp : IconMap.ChevronDown}
            className={cn('text-gray-700', {
              '!text-gray-300': isDisabled,
            })}
          />
        </div>
      </button>
      {isSelected && (
        <div className="pl-9 pt-4">
          <div
            className={cn('text-sm text-gray-600', {
              '!text-gray-400': isCompletedOrComingSoon,
            })}
          >
            {description}
          </div>
          <div className="flex gap-4 mt-4">
            {actionBtn ?? (
              <Button
                color="primary"
                variant="icon-text"
                size="sm"
                iconLeftName={actionBtnIcon}
                iconLeftWidth={20}
                onClick={onClickAction}
              >
                {actionBtnTitle}
              </Button>
            )}
            {videoUrl && (
              <Button
                color="secondary"
                variant="icon-text"
                size="sm"
                iconLeftName={IconMap.PlayCircle}
                iconLeftWidth={20}
                onClick={() => setIsShowVideo(true)}
              >
                Watch video
              </Button>
            )}
          </div>
        </div>
      )}
      {isShowVideo && videoUrl && (
        <WatchVideoModal
          isOpen
          videoUrl={videoUrl}
          onClose={() => setIsShowVideo(false)}
        />
      )}
    </div>
  );
};
