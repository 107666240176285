import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  SubscriptionInvoice,
  SubscriptionInvoiceStatus,
} from '@distribute/shared/types';
import { Table, Columns, Badge, LoaderDots } from '../../../../shared/ui';
import { subscriptionPlans } from '../../config';
import { formatAmountToCurrency } from '../../../../shared/lib';
import { subscriptionModel } from '../../model';
import { useSubscriptionInfo } from '../../hooks';
import { teamsModel } from '../../../teams';

export const InvoicesTable = () => {
  const isLoadingData = useSelector(
    subscriptionModel.selectors.selectIsLoadingInvoices
  );
  const invoices = useSelector(subscriptionModel.selectors.selectInvoices);
  const { isPaymentFailed } = useSubscriptionInfo();

  const getInvoiceStatus = useCallback(
    (invoice: SubscriptionInvoice) => {
      if (
        isPaymentFailed &&
        invoice.status === SubscriptionInvoiceStatus.OPEN
      ) {
        return 'Failed';
      }

      return invoice.status;
    },
    [isPaymentFailed]
  );

  const columns = useMemo<Columns<SubscriptionInvoice>[]>(
    () => [
      {
        id: 'plan',
        name: 'Plan',
        getValue: (item) =>
          subscriptionPlans.find((i) => i.id === item.planName)?.name,
        tdClassName: 'w-1/5',
      },
      {
        id: 'status',
        name: 'Status',
        getValue: (item) => (
          <Badge
            className="!inline-flex capitalize"
            size="sm"
            theme={
              item.status === SubscriptionInvoiceStatus.PAID
                ? 'success'
                : 'error'
            }
          >
            {getInvoiceStatus(item)}
          </Badge>
        ),
        tdClassName: 'w-1/5',
      },
      {
        id: 'date',
        name: 'Date',
        getValue: (item) => moment(item.createdAt).format('MMM D, YYYY'),
        tdClassName: 'w-1/5',
      },
      {
        id: 'charge',
        name: 'Charge',
        getValue: (item) => formatAmountToCurrency(item.amount / 100),
        tdClassName: 'w-1/5',
      },
      {
        id: 'invoice',
        name: 'Invoice',
        getValue: (item) =>
          item.pdfUrl ? (
            <a
              href={item.pdfUrl}
              target="_blank"
              className="text-primary-700"
              rel="noreferrer"
            >
              Download
            </a>
          ) : (
            ''
          ),
        tdClassName: 'w-1/5',
      },
    ],
    [getInvoiceStatus]
  );

  const getItemId = useCallback((item: SubscriptionInvoice) => item.id, []);

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  if (isLoadingData) {
    return <LoaderDots className="mt-6" />;
  }
  const filteredInvoices = invoices.filter(
    (invoice) => String(invoice.amount) !== '0'
  );

  if (!invoices.length || (currentTeam.isAppsumo && !filteredInvoices.length)) {
    return (
      <span className="text-md text-gray-600">
        No billing history is available yet
      </span>
    );
  }

  return (
    <div className="overflow-auto">
      <Table
        data={currentTeam.isAppsumo ? filteredInvoices : invoices}
        columns={columns}
        getItemId={getItemId}
        className="table-fixed sm:table-auto"
      />
    </div>
  );
};
