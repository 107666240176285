import { call, put } from 'redux-saga/effects';
import { DBUser } from '../../../../shared/types';
import { logger } from '../../../../shared/lib';
import { authApi } from '../../../../shared/api';
import { authUserModel } from '../../../../entities/auth-user';

export function* closeWalkthroughModal() {
  try {
    const updatedUser: DBUser = yield call(authApi.updateProfile, {
      isShowPageWalkthroughModal: false,
    });

    yield put(authUserModel.actions.setDBUser(updatedUser));
  } catch (e: unknown) {
    logger.error(e);
  }
}
